import moment from "moment"

/*
    Ordenamento Multiplo:
        Crie uma nova função de comparação e coloque-a no objeto funcComparacaoMulti
*/

const ordenar = (props) => {
    let arrayRetorno = [];
    switch (props.tipo) {
        case 'texto':
            arrayRetorno = ordenamentoTexto(props)
            break;
        case 'numero':
            arrayRetorno = ordenamentoNumerico(props)
            break;
        default:
            arrayRetorno = ordenamentoTexto(props)
    }

    return arrayRetorno
}

const ordenarMultiplo = (array, filtros) => {
    if (!array) return array;
    let arrayRetorno = array.map(item => item)
    arrayRetorno = arrayRetorno.sort((a, b) => metodosMultiplos(a, b, filtros, 0));
    return arrayRetorno
}

const metodosMultiplos = (a, b, colunas, n) => {

    const coluna = colunas[n]
    const metodo = funcComparacaoMulti[coluna && coluna.ordenamento.tipo ? coluna.ordenamento.tipo : 'texto']
    const retorno = metodo(a, b, coluna && (coluna.ordenamento && coluna.ordenamento?.ordenar ? coluna.ordenamento.ordenar : coluna.coluna), coluna && coluna.ordenamento.modo)
    if (retorno === 0 && (colunas.length - 1) > n)
        return metodosMultiplos(a, b, colunas, n + 1)

    return retorno
}

const tratarNumero = (numero) => {
    if (numero == null || numero.toString() == "")
        return 0;
    try {
        numero = numero.toString().replace(/[^0-9,-]/g, '')
        return parseFloat(numero.toString().replace(",", "."))
    } catch (error) {
        return 0;
    }
}

const funcaoNumericoMultiplo = (a, b, coluna, ordenamento) => {
    let aTratado = parseFloat(tratarNumero(a[coluna]));
    let bTratado = parseFloat(tratarNumero(b[coluna]));

    switch (ordenamento) {
        case 'asc':
            return aTratado - bTratado
        case 'desc':
            return bTratado - aTratado
        default:
            return funcaoNumericoMultiplo(a, b, coluna, 'asc')
    }
}

const funcaoTextoMultiplo = (a, b, coluna, ordenamento) => {

    if (a[coluna] === b[coluna]) {
        return 0;
    }
    else if (a[coluna] === null || a[coluna].length === 0 || a[coluna] === undefined || a[coluna] === "\"\"") {
        return 1;
    }
    else if (b[coluna] === null || b[coluna] === '' || b[coluna] === undefined || b[coluna] === "\"\"") {
        return -1;
    }
    switch (ordenamento) {
        case 'asc':
            return (a[coluna].toString() || '').localeCompare((b[coluna].toString() || ''));
        case 'desc':
            return (b[coluna].toString() || '').localeCompare((a[coluna].toString() || ''));
        default:
            return funcaoTextoMultiplo(a, b, coluna, 'asc')
    }
}

const funcaoDataMultiplo = (a, b, coluna, ordenamento) => {
    const af = prepararData(a[coluna])
    const bf = prepararData(b[coluna])

    switch (ordenamento) {
        case 'asc':
            if (af > bf)
                return 1

            if (af < bf)
                return -1

            return 0
        case 'desc':
            if (af > bf)
                return -1

            if (af < bf)
                return 1

            return 0
        default:
            return funcaoDataMultiplo(a, b, coluna, 'asc')
    }
}

const prepararData = (data) => {

    if (data && data.length === 16 && data[2] === "/" && data[5] === "/" && data[10] === " " && data[13] === ":" || data && data.length === 10 && data[2] === "/" && data[5] === "/") {
        return prepararDateTime(data)
    }
    data = data || ''
    const dataS = data.replace(/[/\:]/g, '').split(' ')
    const a = dataS[0]
    const b = dataS[1] || ''

    return Number(a + b)
}
const prepararDateTime = (data) => {
    let novaData = data.toString();
    let dia = novaData.substring(0, 2);
    let mes = novaData.substring(3, 5);
    let ano = novaData.substring(6, 10);
    let hora = novaData.substring(11, 13);
    let minuto = novaData.substring(14, 16);
    return Number(ano + mes + dia + hora + minuto)
}

/* Ordenamento Simples */

const ordenamentoNumerico = ({ array, ordenamento, coluna }) => {
    let arrayRetorno = [];
    switch (ordenamento) {
        case 'asc':
            arrayRetorno = array.sort((a, b) => { return a[coluna] - b[coluna] })
            break;
        case 'desc':
            arrayRetorno = array.sort((a, b) => { return b[coluna] - a[coluna] })
            break;
        default:
            arrayRetorno = ordenamentoNumerico({ array, ordenamento: 'asc', coluna })
    }

    return arrayRetorno
}

const ordenamentoTexto = ({ array, ordenamento, coluna }) => {
    let arrayRetorno = [];

    switch (ordenamento) {
        case 'asc':
            arrayRetorno = array.sort((a, b) => {
                if (a[coluna] > b[coluna])
                    return -1

                if (a[coluna] < b[coluna])
                    return 1

                return 0
            })
            break;
        case 'desc':
            arrayRetorno = array.sort((a, b) => {
                if (a[coluna] < b[coluna])
                    return -1

                if (a[coluna] > b[coluna])
                    return 1

                return 0
            })
            break;
        default:
            arrayRetorno = ordenamentoTexto({ array, ordenamento: 'asc', coluna })
    }
    return arrayRetorno
}

const ordenamentoData = (array, ordenamento, coluna) => {
    return array.sort((a, b) => funcaoDataMultiplo(a, b, coluna, ordenamento))
}

const metodoOrdenamento = {
    'texto': ordenamentoTexto,
    'numero': ordenamentoNumerico,
}

const funcaoHoraMultiplo = (a, b, coluna, ordenamento) => {
    switch (ordenamento) {
        case 'asc':
            return moment(a[coluna] || '', 'HH:mm') <= moment(b[coluna] || '', 'HH:mm') && -1 || 1
        case 'desc':
            return moment(b[coluna] || '', 'HH:mm') <= moment(a[coluna] || '', 'HH:mm') && -1 || 1
        default:
            return funcaoHoraMultiplo(a, b, coluna, 'asc')
    }
}


const funcComparacaoMulti = {
    'texto': funcaoTextoMultiplo,
    'numero': funcaoNumericoMultiplo,
    'data': funcaoDataMultiplo,
    'hora': funcaoHoraMultiplo
}

export { ordenar, metodoOrdenamento, ordenarMultiplo, ordenamentoData }