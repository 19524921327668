import { InputPadrao } from '../../../../components/Inputs'
import { InputValorNovo } from '../../../../components/InputsV2'
import { formatMoeda, formatTwoPlace } from '../../../../util/FuncoesComuns'
import { HeaderCadastro } from '../../../../components/Headers'
import { ModalCadastro } from '../../../../components/Modais'
import { BotaoPadrao } from '../../../../components/Botoes'
import React, { useState, useEffect } from 'react'
import Yup from '../../../../util/Validacoes'
import { Formik, Form } from 'formik'
import { BtnAcao } from '../../../../components/Acoes'
import Atalho from '../../../../util/Atalho'

export default props => {

    console.log('PROPS DAQUI ', props)
    const [pesquisa, setPesquisa] = useState('')
    const [salvando, setSalvando] = useState(false)

    const emptyValues = {
        id: null,
        tabela_procedimento: null,
        valor_porte: null,
        porte: null,
        urgencia: null,
        video: null,
    }

    const [values, setValues] = useState(emptyValues)

    const findIndexDados = obj => {
        const index = []
        index.push(props.arrayDados.findIndex(x => x.tabela_procedimento.id === parseInt(obj.id_tabela_procedimento)))
        index.push(props.arrayDados[index[0]].porte.findIndex(x => parseInt(x.porte) === parseInt(obj.porte)))

        return index
    }

    const changeArrayDados = obj => {
        let copiaArrayDados = [...props.arrayDados]
        const index = findIndexDados(obj)

        copiaArrayDados[index[0]].porte[index[1]] = obj

        props.setArrayDados(copiaArrayDados)
    }

    const Submit = (obj, { resetForm }) => {
        changeArrayDados(obj)
        resetForm({})
        props.setAbrirModal(!props.abrirModal)
    }

    const changeTabela = (btn, obj) => {
        const copiaArrayDados = [...props.arrayDados]
        const objtabela = { ...obj }

        const index = findIndexDados(obj)

        if (btn === 'next')
            objtabela.id_tabela_procedimento = parseInt(index[0] + 1) === copiaArrayDados.length ?
                copiaArrayDados[0].tabela_procedimento.id :
                copiaArrayDados[index[0] + 1].tabela_procedimento.id
        else
            objtabela.id_tabela_procedimento = parseInt(index[0]) === 0 ?
                copiaArrayDados[copiaArrayDados.length - 1].tabela_procedimento.id :
                copiaArrayDados[index[0] - 1].tabela_procedimento.id

        let newIndex = findIndexDados(objtabela)
        let newObj = copiaArrayDados[newIndex[0]].porte[newIndex[1]]

        setValues(newObj)
        changeArrayDados(obj)

        objtabela.porte = 8
        newIndex = findIndexDados(objtabela)
        newObj = copiaArrayDados[newIndex[0]].porte[newIndex[1]]
        changePorte('next', newObj)
    }

    const changePorte = (btn, obj) => {
        const copiaArrayDados = [...props.arrayDados]
        const objPorte = { ...obj }

        if (btn === 'next')
            objPorte.porte = parseInt(obj.porte) === 8 ? 0 : (parseInt(obj.porte) + 1)
        else
            objPorte.porte = parseInt(obj.porte) === 0 ? 8 : (parseInt(obj.porte) - 1)

        const index = findIndexDados(objPorte)
        const newObj = copiaArrayDados[index[0]].porte[index[1]]

        setValues(newObj)
        changeArrayDados(obj)
    }

    useEffect(() => {
        props.attValues()
    }, [props.tabela])

    return (
        <>
            <HeaderCadastro
                pesquisa={true}
                valorPesquisa={pesquisa}
                onChangePesquisa={pesquisa => setPesquisa(pesquisa)}
                classeChildren='space-between-100' >
            </HeaderCadastro>
            <div className="row">
                {
                    props.arrayDados.length && props.arrayDados.map(tabela =>
                        <div className={`${props.arrayDados.length > 1 && 'col-lg-6' || 'col-lg-12'} margin-top-1`}>
                            <div className='card mb-20'>
                                <div className='card-body'>
                                    <div className={'titulo-acomodacao'}><span>{tabela.tabela_procedimento.label && tabela.tabela_procedimento.label}</span></div>
                                    <table className='table tabela-porte-acomodacao table-responsive-md table-striped tabela' >
                                        <thead>
                                            {
                                                <tr>
                                                    <th style={{ width: '20%' }}>Porte</th>
                                                    <th style={{ width: '20%', textAlign: 'right' }}>Valor Porte</th>
                                                    <th style={{ width: '20%', textAlign: 'right' }}>Urgência</th>
                                                    <th style={{ width: '20%', textAlign: 'right' }}>Vídeo</th>
                                                    <th style={{ width: '20%' }} className='text-center'>Ações</th>
                                                </tr>
                                            }
                                        </thead>
                                        <tbody>
                                            {
                                                tabela.porte.map(z =>
                                                    <tr>
                                                        <td>{z.porte}</td>
                                                        <td style={{ textAlign: 'right' }}>{z.valor_porte && formatMoeda(z.valor_porte) || ''}</td>
                                                        <td style={{ textAlign: 'right' }}>{`${z.urgencia && formatTwoPlace(z.urgencia) + '%' || ''}`}</td>
                                                        <td style={{ textAlign: 'right' }}>{`${z.video && formatTwoPlace(z.video) + '%' || ''}`}</td>
                                                        <td className='text-center'>
                                                            <span className='icon-consulta icon-Pen'
                                                                onClick={_ => { setValues({ ...z }); props.setAbrirModal(true) }} />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) || <></>
                }
            </div>
            <ModalCadastro show={props.abrirModal} setShowModal={props.setAbrirModal} titulo={values.id_tabela_procedimento && props.arrayDados
                .find(x => x.tabela_procedimento.id === parseInt(values.id_tabela_procedimento)).tabela_procedimento.label || ''} >
                <Formik
                    enableReinitialize={true}
                    initialValues={values}
                    validationSchema={Yup.object({
                        id_tabela_procedimento: Yup.string().ensure().nullable(),
                        porte: Yup.string().ensure().nullable().required(),
                        valor_porte: Yup.string().ensure().required().nullable(),
                        urgencia: Yup.string().ensure().nullable(),
                        video: Yup.string().ensure().nullable(),
                    })}
                    onSubmit={Submit}>
                    {propsFormik => <Form>

                        <div className='modal-body-cadastro'>
                            <InputPadrao label='Id' name='id' type='hidden' />
                            <InputPadrao label='Tabela Procedimento' name='id_tabela_procedimento' type='hidden' />
                            <InputPadrao label='Porte' name='porte' type='hidden' />
                            <InputValorNovo value={propsFormik.values.valor_porte_formatado || propsFormik.values.valor_porte} label='Valor Porte' name={`valor_porte`} classDiv='col-lg-12' propsFormik={propsFormik} isDisable={false} max={999} prefix={'R$ '} autoFocus focarAposSubmit={true} />
                            <InputValorNovo value={propsFormik.values.urgencia_formatado || propsFormik.values.urgencia} label='Urgência' name={`urgencia`} classDiv='col-lg-12' propsFormik={propsFormik} isDisable={false} max={999} suffix={'%'}/>    
                            <InputValorNovo value={propsFormik.values.video_formatado || propsFormik.values.video} label='Vídeo' name={`video`} classDiv='col-lg-12' propsFormik={propsFormik} isDisable={false} max={999} suffix={'%'}/>    
                        </div>
                        <div className='separator mt-20 mb-20'></div>
                        <div className='subheader-porte-acomodacao row-space-between'>
                            <BtnAcao type={'button'} icone="icon-Arrow-Left focus-button" texto="Anterior" action={_ => changePorte('prev', propsFormik.values)} />
                            <span className='vertical-center'>Porte - {values.porte}</span>
                            <BtnAcao type={'button'} icone="icon-Arrow-Right focus-button" texto="Próximo" action={_ => changePorte('next', propsFormik.values)} />
                        </div>
                        <div className='subheader-porte-acomodacao row-space-between'>
                            <BtnAcao type={'button'} icone="icon-Arrow-Left focus-button" texto="Anterior" action={_ => changeTabela('prev', propsFormik.values)} />
                            <span className='vertical-center'>
                                {props.arrayDados.find(x => x.tabela_procedimento.id === parseInt(values.id_tabela_procedimento)).tabela_procedimento.label}
                            </span>
                            <BtnAcao type={'button'} icone="icon-Arrow-Right focus-button" texto="Próximo" action={_ => changeTabela('next', propsFormik.values)} />
                            <Atalho formik={propsFormik} salvando={salvando} />

                        </div>
                        <div className='direita container-botoes'>
                            <BotaoPadrao texto={'Salvar'} />
                        </div>
                    </Form>}
                </Formik>
            </ModalCadastro>
        </> || <></>
    )
}