import React, { useState, useEffect, useRef, useMemo } from 'react'
import { HeaderAgenda } from '../../../../components/Headers'
import { BotaoPadrao, BotaoMenu } from '../../../../components/Botoes'
import { BtnAcao, BtnAcaoLink, Visualizar } from '../../../../components/Acoes'
import { ModalPadraoCadastro } from '../../../../components/Modais'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens, useWindowSize, getBetweenDates, getUnique, useInterval } from '../../../../util/Utilitarios'
import { consultar, get, excluir, putComAcao, visualizar } from '../../../../util/AcessoApi'
import { InputSimples, InputSwitchSemFormik, InputCheckboxSm } from '../../../../components/Inputs'
import { removeAcentos } from '../../../../util/FuncoesComuns'
import { getUsuario } from '../../../../util/Configuracoes'
import moment from "moment"
import 'moment/locale/pt-br'
import '../../agenda-escalador/css/agenda.css'
import { Formik, Form } from "formik"
import CadastroPlantao from './Cadastro'
import { InputEstabelecimentoSaude, InputSocio, InputOrdenamento, InputEscalaTipo, InputIntervaloHorario } from '../../../../components/InputsAutoComplete'
import { ACOES } from '../../../../util/Enums'
import { ModalEscolha } from '../../../../components/Modais'
import { ordenarMultiplo } from '../../../../components/Ordenamentos'
import '../css/style.css'
import { Link } from 'react-router-dom'
import { CadastroTrocaSocio } from '../../../administracao/agenda-associado/pages/Cadastro'
import { DetalhesAgendamento } from '../../../administracao/agenda-associado/pages/Visualizacao'
import { CadastroEstabelecimentoPlantao } from '../../agenda-associado/pages/Cadastro'
import { TrocarStatus, ConfirmarAgendamentoSocio } from '../../plantao-alcancaveis/pages/Cadastro'
import { Excluir } from '../../../../components/Acoes'
import { useHistory } from "react-router-dom";
import { ModalCalendar } from '../../../../components/Modais'
import InfiniteCalendar from 'react-infinite-calendar';
import ManterTrocas from '../../agenda-troca/pages/Cadastro'
import ExclusaoSocios from '../../agenda-associado/pages/ExclusaoSocios'
import { CadastroModalLembrete } from '../../lembrete/pages/Cadastro'
import { CadastroModalRegistroAtividade } from '../../registro-atividades/pages/Cadastro'


const AgendaPlantaoLayout = props => {
    const [telas, setTelas] = useState([]);
    const [duplicar, setDuplicar] = useState(false)
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [itemDetalhes, setItemDetalhes] = useState([])
    const [permissoes, setPermissoes] = useState([])
    const user = getUsuario()
    const [modalCadastroPlantao, setModalCadastroPlantao] = useState(false)
    const [modalCadastroPlantaoES, setModalCadastroPlantaoES] = useState(false)
    const [modalCadastroEstabelecimento, setModalCadastroEstabelecimento] = useState(false)
    const [idAlterar, setIdAlterar] = useState(null)
    const [idExcluir, setIdExcluir] = useState(null)
    const [tituloVisualizacao, setTituloVisualizacao] = useState('')
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [showModalAdicionarSocio, setShowModalAdicionarSocio] = useState(false)
    const [itemAcao, setItemAcao] = useState({})
    const [itemAcaoConvertido, setItemAcaoConvertido] = useState({})
    const [tipoExclusao, setTipoExclusao] = useState()
    const [moduloUsado, setModuloUsado] = useState()
    const [showModalConfirmacao, setShowModalConfirmacao] = useState(false)
    const [itemStatus, setItemStatus] = useState([])
    const [showModalTrocarStatus, setShowModalTrocarStatus] = useState(false)
    const [modulo, setModulo] = useState(null)
    const [socioSelecionado, setSocioSelecionado] = useState([])
    const [dadosConfirmarES, setDadosConfirmarES] = useState([])
    const [confirmarTodosSocios, setConfirmarTodosSocios] = useState(false)
    const [idSociosNaoConfirmados, setIdSociosNaoConfirmados] = useState([])
    const [objExecutantes, setObjExecutantes] = useState([])
    const [showModalTrocaSocio, setShowModalTrocaSocio] = useState(false)
    const [plantaoEstabelecimento, setPlantaoEstabelecimento] = useState(false)
    const [width, height] = useWindowSize();
    const [showExclusaoSocios, setShowExclusaoSocios] = useState(false)

    useEffect(_ => {
        transformarEmItemDaConsultaAgendaAssociado(itemAcao)
    }, [itemAcao])

    const target = useRef(null)
    //tipoConsulta 1 = plantao
    //tipoConsulta 2 = estabelecimento
    //tipoConsulta não definido = os dois usarão
    const ordenamento = [
        {
            label: 'Tipo Plantão - Asc',
            value: 'tipo_escala',
            tipo: 'texto',
            modo: 'asc',
            tipoConsulta: 1
        }, {
            label: 'Tipo Plantão - Desc',
            value: 'tipo_escala',
            tipo: 'texto',
            modo: 'desc',
            tipoConsulta: 1
        }, {
            label: 'Ordem - Asc',
            value: 'ordem',
            tipo: 'numero',
            modo: 'asc',
            tipoConsulta: 1
        }, {
            label: 'Ordem - Desc',
            value: 'ordem',
            tipo: 'numero',
            modo: 'desc',
            tipoConsulta: 1
        }, {
            label: 'Sócio - Asc',
            value: 'socio_ordenamento',
            tipo: 'texto',
            modo: 'asc'
        }, {
            label: 'Sócio - Desc',
            value: 'socio_ordenamento',
            tipo: 'texto',
            modo: 'desc'
        }, {
            label: 'Status - Asc',
            value: 'status',
            tipo: 'texto',
            modo: 'asc'
        }, {
            label: 'Status - Desc',
            value: 'status',
            tipo: 'texto',
            modo: 'desc'
        }, {
            label: 'Horário - Asc',
            value: 'hora_inicial_completa',
            tipo: 'hora',
            modo: 'asc'
        }, {
            label: 'Horário - Desc',
            value: 'hora_inicial_completa',
            tipo: 'hora',
            modo: 'desc'
        }
    ]

    const filtroOrdenamento = (coluna, ordem) => {
        return {
            coluna: coluna,
            ordenamento: {
                tipo: 'data',
                modo: 'asc',
                ordem: ordem
            }
        }
    }

    useEffect(_ => {
        Promise.resolve(get('/controleAcesso/permissao/obterTodas', { usuario_id: user.id }, true)).then(val => {
            setPermissoes(val)
            setTelas([
                {
                    modulo: 49,
                    urlHash: '/administracao/obterHashPlantao',
                    botao: [<>{val.find(x => x.modulo_id === 49).permissoes.find(x => parseInt(x) === ACOES.INSERIR) ? <BotaoPadrao textoTooltip="Cadastrar" idTooltip={"padrao"} icone='icon-Add' classe={'btn btn-primary primary btn-filter'} click={_ => { setDuplicar(false); setIdAlterar(null); setModalCadastroPlantao(true); fecharModalTrocaSocio(false) }} /> : <></> }</>],
                    url: '/administracao/plantaoAlcancavel',
                    titulo: 'Escala Plantão',
                    icone: 'icon-Clock',
                    modalHeader: <CadastroPlantao target={target} abrirModal={modalCadastroPlantao} setAbrirModal={setModalCadastroPlantao} id={idAlterar} />,
                    abrirModal: modalCadastroPlantao,
                    modalCadastro: setModalCadastroEstabelecimento,
                    layoutLinha: (item, dia, width) => layoutLinhaPlantaoAlcancavel(item, dia, width, val.find(x => x.modulo_id === 49)),
                    obterDetalhes: item => obterDetalhesPlantao(item),
                    camposPesquisa: ['hora_inicial', 'hora_final', 'socio'],
                    colunas: [
                        { width: '2%', label: '#' },
                        { width: '18%', label: 'Sócio(s)' },
                        { width: '10%', label: 'Tipo de Plantão' },
                        { width: '15%', label: 'Mudança/Troca' },
                        { width: '40%', label: 'Justificativa' },
                        { width: '15%', label: 'Escalador' }
                    ],
                    funcaoFiltrar: (array, filtros) => {
                        const ordenamentoF = []
                        if (filtros.status)
                            array = array.filter(item => filtros.status.length === 0 || (filtros.status && filtros.status.findIndex(itemSts => itemSts === parseInt(item.status_id)) > -1))

                        if (filtros.socio)
                            array = array.filter(item => item.socio_id.find(x => filtros.socio.find(y => y.value === x)))

                        if (filtros.periodo)
                            array = array.filter(item => filtros.periodo.length === 0 ||
                                (filtros.periodo && filtros.periodo.findIndex(x => x.value === parseInt(item.periodo)) > -1))

                        if (filtros.tipo_escala)
                            array = array.filter(item => item.tipo_escala_id === filtros.tipo_escala.value)

                        if (filtros.ordenamento)
                            for (let i = 0; i < filtros.ordenamento.length; i++)
                                ordenamentoF.push({ coluna: filtros.ordenamento[i].value, ordenamento: { tipo: filtros.ordenamento[i].tipo, modo: filtros.ordenamento[i].modo, ordem: i + 1 } })
                        else
                            ordenamentoF.push({ coluna: 'status_ordem', ordenamento: { tipo: 'numero', modo: 'asc', ordem: 1 } }, filtroOrdenamento('hora_inicial', 2), filtroOrdenamento('hora_final', 3))

                        array = ordenarMultiplo(array, ordenamentoF)

                        return array
                    },
                    filtrosHTML: (filtrar, filtrosOff) => <>
                        <div className='filtro'>
                            <div className='titulo-filtro row-space-between'>
                                <span className='vertical-center'>Ordenamento</span>
                            </div >
                            <div className='content-filtro'>
                                <InputOrdenamento countDisplay={20} value={filtrosOff.ordenamento && filtrosOff.ordenamento || ''} multiplo={true} label="" name="ordenamento"
                                    tamanho='p-0 col-12' placeholder="Selecione" icone="icon-A-Z" {...props} data={ordenamento.filter(x => !x.tipoConsulta || x.tipoConsulta === 1)}
                                    onChange={value => {
                                        filtrar(value, "ordenamento")
                                    }} />
                            </div>
                        </div >
                        <div className='filtro mb-3'>
                            <div className='titulo-filtro row-space-between'>
                                <span className='vertical-center'>Sócio</span>
                            </div>
                            <div className='content-filtro'>
                                <InputSocio multiplo name="socio" placeholder="Sócio" value={filtrosOff.socio} tamanho='p-0 col-12' onChange={e => {
                                    filtrar(e, "socio")
                                }} minLength={1} />
                            </div>
                        </div>
                        <div className='filtro'>
                            <div className='titulo-filtro row-space-between'>
                                <span className='vertical-center'>Período</span>
                            </div>
                            <div className='content-filtro'>
                                <InputIntervaloHorario placeholder="Período" multiplo name={'periodo'}
                                    tamanho={'p-0 col-12'} botaoVisivel={false} value={filtrosOff.periodo}
                                    onChangeValue={(e) => {
                                        filtrar(e, "periodo")
                                    }} minLength={1} />
                            </div>
                        </div>
                        <div className='filtro mb-3'>
                            <div className='titulo-filtro row-space-between'>
                                <span className='vertical-center'>Tipo Escala</span>
                            </div>
                            <div className='content-filtro'>
                                <InputEscalaTipo placeholder="Tipo Escala" obrigatorio name={'tipo_escala'} classeTooltip={'tooltip-input-modal'}
                                    tamanho={'p-0 col-12'} value={filtrosOff.tipo_escala} onChange={e => {
                                        filtrar(e, "tipo_escala")
                                    }} />
                            </div>
                        </div>
                    </>
                },
                {
                    modulo: 53,
                    urlHash: '/administracao/obterHashPlantaoEstabelecimento',
                    botao: [<>{val.find(x => x.modulo_id === 53).permissoes.find(x => parseInt(x) === ACOES.INSERIR) ? <BotaoPadrao textoTooltip="Cadastrar" idTooltip={"padrao"} icone='icon-Add' classe={'btn btn-primary primary btn-filter'} click={_ => { setIdAlterar(null); setModalCadastroPlantaoES(true); fecharModalTrocaSocio(false) }} /> : <></>}</>],
                    url: '/administracao/plantaoestabelecimento',
                    titulo: 'Escala Estabelecimento',
                    icone: 'icon-Building',
                    modalHeader: <CadastroEstabelecimentoPlantao target={target} abrirModal={modalCadastroPlantaoES} setAbrirModal={setModalCadastroPlantaoES} />,
                    abrirModal: modalCadastroPlantaoES,
                    modalCadastro: setModalCadastroPlantaoES,
                    layoutLinha: (item, dia, width) => layoutLinhaPlantaoEstabelecimentoSaude(item, dia, width, val.find(x => x.modulo_id === 53)),
                    obterDetalhes: (id) => obterDetalhesPlantaoEstabelecimento(id),
                    camposPesquisa: ['hora_inicial', 'hora-final', 'estabelecimento_saude_descricao', 'socio'],
                    colunas: [
                        { width: '25%', label: 'Sócio(s)' },
                        { width: '20%', label: 'Mudança/Troca' },
                        { width: '40%', label: 'Justificativa' },
                        { width: '15%', label: 'Escalador' }
                    ],
                    funcaoFiltrar: (array, filtros) => {
                        const ordenamentoF = []

                        if (filtros.status)
                            array = array.filter(item => filtros.status.length === 0 || (filtros.status && filtros.status.findIndex(itemSts => itemSts === parseInt(item.status_id)) > -1))

                        if (filtros.estabelecimento_saude)
                            array = array.filter(item => filtros.estabelecimento_saude.length === 0 ||
                                (filtros.estabelecimento_saude && filtros.estabelecimento_saude.findIndex(x => x.value === parseInt(item.estabelecimento_saude_id)) > -1))

                        if (filtros.socio)
                            array = array.filter(item => item.socio_id && item.socio_id.find(x => filtros.socio.find(y => y.value === x)))

                        if (filtros.periodo)
                            array = array.filter(item => filtros.periodo.length === 0 ||
                                (filtros.periodo && filtros.periodo.findIndex(x => x.value === parseInt(item.periodo)) > -1))

                        if (filtros.ordenamento)
                            for (let i = 0; i < filtros.ordenamento.length; i++)
                                ordenamentoF.push({ coluna: filtros.ordenamento[i].value, ordenamento: { tipo: filtros.ordenamento[i].tipo, modo: filtros.ordenamento[i].modo, ordem: i + 1 } })
                        else
                            ordenamentoF.push(filtroOrdenamento('hora_inicial', 2), filtroOrdenamento('hora_final', 3))

                        array = ordenarMultiplo(array, ordenamentoF)

                        return array
                    }
                }])
        })
    }, [])

    const obterDetalhesPlantaoEstabelecimento = async (item) => {
        visualizar(`/administracao/plantaoestabelecimentovisualizacao`, item.id, obj => {
            let retorno = {
                agendamento: [
                    { titulo: 'Estabelecimento de Saúde', descricao: obj.estabelecimento_saude_descricao },
                    { titulo: 'Data Inicial', descricao: moment(obj.data_inicial, 'DD/MM/YYY às hh:mm').format('ddd') + ' ' + obj.data_inicial },
                    { titulo: 'Data Final', descricao: moment(obj.data_final, 'DD/MM/YYY às hh:mm').format('ddd') + ' ' + obj.data_final },
                    { titulo: 'Recorrência', descricao: item.recorrencia || '--' },
                    { titulo: 'Usuário/Escalador', descricao: item.escalador || '--' },

                    {
                        titulo: 'Sócio(s)', descricao: obj.executantes && obj.executantes.length ?
                            <ul className='lista-modal'>
                                {ordenarMultiplo(obj.executantes, [{ coluna: 'ordem', ordenamento: { tipo: 'numero', modo: 'asc', ordem: 1 } }]).map(item => {
                                    let hora = [];

                                    if (item.hora_final)
                                        hora.push(item.hora_inicial)
                                    if (item.hora_final)
                                        hora.push(item.hora_final)

                                    return <li className="mb-1">
                                        {item.status_descricao ?
                                            <div className='row-space-between'>
                                                <div></div>
                                                <span className={'tag tag-' + item.status_descricao}>{item.status_descricao}</span>
                                            </div>
                                            : <></>}
                                        <div className='mb-2'><span><b>Hora: </b>{hora.length ? hora.join(' - ') : '--'}</span></div>
                                        <div><span><b>Sócio: </b>{item.nome || '--'}</span></div>
                                        <span>{item.label}</span>

                                    </li>
                                })}</ul> : '--'
                    },
                ]
            }
            retorno.historico = obj.historico_status;
            retorno.trocaSocioHistorico = obj.historico_socio;
            retorno.historico_data = obj.historico_data;
            setItemDetalhes(retorno);
        })
    }

    const obterDetalhesPlantao = async linha => {
        visualizar(`/administracao/plantaoalcancavelvisualizacao`, linha.id, item => {
            let retorno = {
                agendamento: [
                    { titulo: 'Data Inicial', descricao: moment(item.data_inicial, 'DD/MM/YYY às hh:mm').format('ddd') + ' ' + item.data_inicial },
                    { titulo: 'Data Final', descricao: moment(item.data_final, 'DD/MM/YYY às hh:mm').format('ddd') + ' ' + item.data_final },
                    { titulo: 'Ordem', descricao: item.ordem || '--' },
                    { titulo: 'Tipo Escala', descricao: item.tipo_escala },
                    { titulo: 'Recorrência', descricao: item.recorrencia || '--' },
                    { titulo: 'Sócio(s)', descricao: item.socio && item.socio.length > 0 ? item.socio.map(itemSoc => itemSoc.label).join(', ') : '--' },
                    { titulo: 'Status', descricao: item.status },
                    { titulo: 'Usuário/Escalador', descricao: item.escalador },

                ]
            }

            retorno.historico = item.historico_status;
            retorno.trocaSocioHistorico = item.historico_socio;
            retorno.historico_data = item.historico_data;

            setItemDetalhes(retorno);
        })
    }
    useEffect(() => {
        objExecutantes.length === socioSelecionado.length ? setConfirmarTodosSocios(true) : setConfirmarTodosSocios(false)

    }, [socioSelecionado])

    const setSocioConfirmacao = (idSocio) => {

        let sociosConfirmados = dadosConfirmarES.socios_confirmados.split(',').map(x => parseInt(x))
        let socioIdClone = []
        socioIdClone.push(dadosConfirmarES.executantes.map(x => x.socio_id))
        if (!Number.isNaN(sociosConfirmados[0])) {
            var index = sociosConfirmados.map(x => socioIdClone[0].indexOf(x))
            for (let i in index)
                socioIdClone[0].splice(index[i], 1)
            setIdSociosNaoConfirmados([...socioIdClone[0]])
        }

        let cloneSocioSelecionado = socioSelecionado

        if (idSocio === 0) {
            if (confirmarTodosSocios === false) {
                setSocioSelecionado([...socioIdClone[0]])
                setConfirmarTodosSocios(true)
            }
            else {
                setSocioSelecionado([])
                setConfirmarTodosSocios(false)
            }
        }

        if (idSocio !== 0 && cloneSocioSelecionado.find(x => x === idSocio)) {
            let index = cloneSocioSelecionado.indexOf(idSocio)
            cloneSocioSelecionado.splice(index, 1)
            setSocioSelecionado([...cloneSocioSelecionado])
            cloneSocioSelecionado.length === socioIdClone[0].length ? setConfirmarTodosSocios(true) : setConfirmarTodosSocios(false)
        }
        else if (idSocio !== 0) {
            cloneSocioSelecionado.push(idSocio)
            setSocioSelecionado([...cloneSocioSelecionado])
            cloneSocioSelecionado.length === socioIdClone[0].length ? setConfirmarTodosSocios(true) : setConfirmarTodosSocios(false)
        }
    }

    const formatarItemConfirmarESPadrao = itens => {
        visualizar(`/administracao/plantaoestabelecimentovisualizacao`, itens.id, obj => {
            setDadosConfirmarES(obj)
            setItemStatus({ plantao: { sociosSelecionados: socioSelecionado }, valoresIniciais: { id: itens.id } })
        })
    }

    const formatarItemConfirmarES = itens => {
        visualizar(`/administracao/plantaoestabelecimentovisualizacao`, itens.id, obj => {


            let retorno = {
                valoresIniciais: { id: itens.id },
                plantao: [
                    { titulo: 'Estabelecimento de Saúde', descricao: obj.estabelecimento_saude_descrica, },
                    { titulo: 'Data Inicial', descricao: obj.data_inicial },
                    { titulo: 'Data Final', descricao: obj.data_final },
                ]
            };

            if (obj.executantes && obj.executantes.length > 0) {
                setObjExecutantes(obj.executantes)

                retorno.plantao.push({
                    titulo: 'Sócio(s)', descricao: obj.executantes.length ?
                        <ul className='lista-modal'>
                            {obj.executantes.map(item => {
                                let hora = [];
                                if (item.hora_final)
                                    hora.push(item.hora_inicial)
                                if (item.hora_final)
                                    hora.push(item.hora_final)
                                return <li onClick={_ => {}}>
                                    {/* {item.status_descricao ?
                                            <div className='row-space-between'>
                                                <div></div>
                                                <span className={'tag tag-' + item.status_descricao}>{item.status_descricao}</span>
                                            </div>
                                            : <></>} */}
                                    <div className='mb-2 espacamento'><span><b>Hora: </b>{hora.length ? hora.join(' - ') : '--'}</span></div>
                                    <div ><span><b>Sócio: </b>{item.nome || '--'}</span></div>
                                </li>
                            })}</ul> : '--'


                })
            }
            // retorno.plantao.push({
            //     titulo: 'Sócios Selecionados', descricao: socioSelecionado, visivel: false
            // })
            setItemStatus(retorno)
        })
    }

    const DetalhesHtml = _ => {
        var sociosConfirmados = dadosConfirmarES.socios_confirmados.split(',')
        sociosConfirmados = sociosConfirmados.map(x => parseInt(x))
        return <>
            <label className={'width-100 bold'}>{'Estabelecimento de Saúde'}</label>
            <label className={'width-100 mb-20'}>{dadosConfirmarES.estabelecimento_saude_descricao}</label>
            <label className={'width-100 bold'}>{'Data Inicialala'}</label>
            <label className={'width-100 mb-20'}>{dadosConfirmarES.data_inicial}</label>
            <label className={'width-100 bold'}>{'Data Final'}</label>
            <label className={'width-100 mb-20'}>{dadosConfirmarES.data_final}</label>
            <label className={'width-100 bold'}>{'Selecione os Sócios'}</label>
            <ul className='lista-modal'>
                <li onClick={_ => setSocioConfirmacao(0)} className={"mb-1" + (dadosConfirmarES && dadosConfirmarES.executantes.length === socioSelecionado.length || (idSociosNaoConfirmados.length > 0 && idSociosNaoConfirmados.length === socioSelecionado.length) ? ' socio-selecionado' : '')}>
                    <div className='mb-2 espacamento'><span></span> <i className={dadosConfirmarES && dadosConfirmarES.executantes.length === socioSelecionado.length || (idSociosNaoConfirmados.length > 0 && idSociosNaoConfirmados.length === socioSelecionado.length) ? ' icon-Yes selecao' : ' icon-Circle'}></i></div>
                    <div ><span><b>Todos</b></span></div>
                </li>
                {setObjExecutantes(dadosConfirmarES.executantes)}

                {dadosConfirmarES && dadosConfirmarES.executantes.map(item => {
                    let hora = [];
                    if (item.hora_final)
                        hora.push(item.hora_inicial)
                    if (item.hora_final)
                        hora.push(item.hora_final)
                    return <li onClick={_ => setSocioConfirmacao(item.socio_id)} className={"mb-1" + (socioSelecionado.filter(x => x === item.socio_id).length > 0 ? ' socio-selecionado' : '') + (sociosConfirmados.indexOf(item.socio_id) > -1 ? ' socio-confirmado ' : '')}>
                        {item.status_descricao && sociosConfirmados.indexOf(item.socio_id) > -1 ?
                            <div className='row-space-between'>
                                <div></div>
                                <span className={'tag tag-' + item.status_descricao}>{item.status_descricao}</span>
                            </div>
                            : <></>}
                        <div className='mb-2 espacamento'><span><b>Hora: </b>{hora.length ? hora.join(' - ') : '--'}</span><i className={socioSelecionado.filter(x => x === item.socio_id).length > 0 ? 'icon-Yes selecao' : item.status_descricao !== 'confirmado' ? 'icon-Circle' : ''}></i></div>
                        <div ><span><b>Sócio: </b>{item.nome || '--'}</span></div>
                    </li>
                })}</ul>
        </>
    }

    const formatarItemStatus = async item => {
        visualizar(`/administracao/plantaoalcancavelvisualizacao`, item.id, obj => {
            let retorno = {
                valoresIniciais: { id: item.id },
                plantao: [
                    { titulo: 'Estabelecimento de Saúde', descricao: obj.estabelecimento_saude_descricao },
                    { titulo: 'Data Inicial', descricao: obj.data_inicial },
                    { titulo: 'Data Final', descricao: obj.data_final },
                ]
            };

            setItemStatus(retorno)
        })
    }

    const layoutLinhaPlantaoEstabelecimentoSaude = (item, dia, width, permissao) => {
        const executantesIds = item.socio_id
        let executantes = ordenarMultiplo(item.socios, [{ coluna: 'ordem', ordenamento: { tipo: 'numero', modo: 'asc', ordem: 1 } }])
        return <div className={"card-row-agenda p-5-after card-envio-row"}>
            {
                executantes && target.current.enviar && (item.status_id === 2 || item.status_id === 3 || item.status_id === 5) && width > 767 ?
                    <div className={'switch-envio-card ' + (target.current.enviar ? '' : 'hide')} onClick={(e) => target.current.checkIndividual(e, item.id, executantesIds)}>
                        <InputSwitchSemFormik onClick={(e) => target.current.checkIndividual(e, item.id, executantesIds)} checked={target.current.verificarCheck(item.id)} classeDiv='form-group switch-center' name='plantao' size="md" tamanho={'col-lg-1'} classeTooltip={'tooltip-input-modal'} />
                    </div> : <></>
            }
            <div className='card card-envio'>
                <div className={'row ' + (width > 700 ? 'linha-plantao' : '')}>
                    {/* mobile */}
                    {(executantes && target.current.enviar && (item.status_id === 3 || item.status_id === 5)) && width < 768 ?
                        <InputSwitchSemFormik onClick={(e) => target.current.checkIndividual(undefined, item.id, executantesIds)}
                            checked={target.current.verificarCheck(item.id)} classeDiv='form-group' name='plantao' size="md" tamanho={'col-lg-1'} classeTooltip={'tooltip-input-modal'} /> : <></>}
                    <div className={width > 1100 ? 'col-lg-3 col-md-6 mb-1' : 'col-sm-12 row-space-between mb-1'}>
                        <span className='span-hora'>
                            <span className='span-hora'>{moment(item.data_final + ' ' + item.hora_final, 'DD/MM/YYYY HH:mm').diff(moment(item.data_inicial + ' ' + item.hora_inicial, 'DD/MM/YYYY HH:mm'), 'hours') > 30 ? <><span>{item.hora_inicial} </span><span style={{ fontWeight: 'bold' }}>{moment(item.data_inicial, 'DD/MM/YYYY').format('ddd')} - </span> {item.hora_final} <span style={{ fontWeight: 'bold' }}>{moment(item.data_final, 'DD/MM/YYYY').format('ddd')}</span></> : (item.hora_inicial + ' - ' + item.hora_final)}</span>
                        </span>
                        <span className={'tag tag-' + item.status}>{item.status}</span>
                    </div>
                    <div className={'col-lg-4 col-md-3 text-left ' + (width > 700 ? '' : 'column')}>
                        <div className='mb-1 col-hora'>
                            <span className={'procedimentos fs-13 color-gray ml-1'}><b>{(item.estabelecimento_saude_codigo ? item.estabelecimento_saude_codigo : item.estabelecimento_saude_id) + ' - ' + item.estabelecimento_saude_descricao}</b></span>
                        </div>
                    </div>

                    {width > 1100 ?
                        <div className={'col-lg-5 col-md-6 col-sm-12 text-right'}>
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.TROCAR_SOCIO) && item.qtd_trocas > 0 && <BtnAcao icone="icon-Affiliate" texto="Solicitações de Troca" action={_ => { setItemAcao(item); setShowModalTrocaSocio(true); setModuloUsado(53) }} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) && (user.tipo !== 4 && item.status_id !== 7) && <BtnAcao classeContent="ml-1-between" icone="icon-Arrow-Around" texto="Trocar Status" action={_ => validarPermissao(item, 20, _ => { formatarItemConfirmarES(item); setModulo(53); setShowModalTrocarStatus(true) })} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.TROCAR_SOCIO) && <BtnAcao icone="icon-Add-User" texto="Adicionar/Trocar Sócio" action={() => validarPermissao(item, 22, _ => { setItemAcao(item); setShowModalAdicionarSocio(true); fecharCadastrosParaTrocaSocio(false); setModuloUsado(53) })} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.EXCLUIR_SOCIOS) && item.socios && item.socios.length > 0 && item.socios && item.socios.length > 0 && <BtnAcao classeContent="ml-1-between" icone="icon-Remove-User" texto="Remover Sócios" action={_ => validarPermissao(item, 23, _ => { setItemAcao(item); setModuloUsado(53); setShowExclusaoSocios(true); })} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.INSERIR) && <BtnAcao icone="icon-Pen" texto="Alterar" action={_ => validarPermissao(item, 3, _ => { setDuplicar(false); alterarPlantaoES(item.id) })} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.VISUALIZAR) && <BtnAcao icone="icon-Books" texto="Detalhes" action={_ => { setTituloVisualizacao('Detalhes Plantão Estabelecimento'); obterDetalhesPlantaoEstabelecimento(item); setShowModalDetalhes(true) }} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.VISUALIZAR) && <BtnAcaoLink icone="icon-File-Search" texto="Visualizar" to={'/administracao/plantao-estabelecimento/visualizacao/' + item.id} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) && item && item.status_id !== 1 && item.status_id !== 2 && item.status_id !== 7 && <BtnAcao icone="icon-Yes" texto="Confirmar" action={() => { setPlantaoEstabelecimento(true); setShowModalConfirmacao(true); setModulo(53); formatarItemConfirmarESPadrao(item) }} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.EXCLUIR) && <>{item.recorrencia ? <BtnAcao icone="icon-New-Trash" texto="Excluir" action={_ => validarPermissao(item, 4, _ => validarExclusao(item))} {...props} /> :
                                <Excluir action={_ => validarPermissao(item, 4, _ => validarExclusao(item))} />}</>}
                        </div>
                        : <></>
                    }
                </div>
                {width > 1100 ? <>
                    <table className='table-socio-agenda'>
                        <thead>
                            <tr>
                                <th style={{ width: '25%' }}></th>
                                <th style={{ width: '20%' }}></th>
                                <th style={{ width: '40%' }}></th>
                                <th style={{ width: '15%' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {executantes && executantes.length > 0 ?
                                getUnique(executantes, 'id').map((x, idx, array) => idx === 0 ?
                                    <tr>
                                        <td className=''><div className={"mb-1" + (width < 1100 ? " pl-1" : '')}>
                                            {(target.current.enviar && (item.status_id === 2 || item.status_id === 3 || item.status_id === 5)) ?
                                                <InputCheckboxSm checked={target.current.verificarSocio(item.id, x.id)} onChange={() => target.current.checkSocio(item.id, x.id)} /> : <></>}
                                            <span><b>{x.hora_inicial && <>{x.hora_inicial}</>}{x.hora_final && <>{' - ' + x.hora_final}</>}</b>{x.sigla && <b>{' ' + x.sigla + ' - '}</b>}
                                                <span>{x.nome}</span>
                                            </span>
                                        </div>
                                        </td>
                                        <td className=''>
                                            {item.mudanca && item.mudanca.filter(y => y.executante_id === x.agenda_reserva_executante_id).map(y => <span>{y.nome}</span>)}
                                        </td>
                                        <td className='ellipsis-overflow'>
                                            {item.mudanca && item.mudanca.filter(y => y.executante_id === x.agenda_reserva_executante_id).map(y => <span>{y.justificativa}</span>)}
                                        </td>
                                        <td className='' rowSpan={array.length}>
                                            <div className={"mb-1"}>{item.escalador}</div>
                                        </td>
                                    </tr> :
                                    <tr>
                                        <td className=''>
                                            <div className={"mb-1" + (width < 1100 ? " pl-1" : '')}>
                                                {(target.current.enviar && (item.status_id === 2 || item.status_id === 3 || item.status_id === 5)) ?
                                                    <InputCheckboxSm checked={target.current.verificarSocio(item.id, x.id)} onChange={() => target.current.checkSocio(item.id, x.id)} /> : <></>}
                                                <span><b>{x.hora_inicial && <>{x.hora_inicial}</>}{x.hora_final && <>{' - ' + x.hora_final}</>}</b>{x.sigla && <b>{' ' + x.sigla + ' - '}</b>}
                                                    <span>{x.nome}</span>
                                                </span>
                                            </div>
                                        </td>
                                        <td className=''>
                                            {item.mudanca && item.mudanca.filter(y => y.executante_id === x.agenda_reserva_executante_id).map(y => <span>{y.nome}</span>)}
                                        </td>
                                        <td className='ellipsis-overflow'>
                                            {item.mudanca && item.mudanca.filter(y => y.executante_id === x.agenda_reserva_executante_id).map(y => <span>{y.justificativa}</span>)}
                                        </td>
                                    </tr>)
                                : <></>
                            }
                        </tbody>
                    </table>
                </>
                    :
                    <>
                        <div className={'row'}>
                            <div className={'col-12 ' + (width > 1100 ? 'display-flex' : 'row ml-0 mr-0 pr-0 pl-0')}>
                                <div className={'vertical-center ' + (width > 1100 ? '' : 'column col-12 fs-13 color-gray pl-2')} style={{ width: '25%' }}>
                                    {width < 1100 && <div className='pl-1 mb-2'><span><b>Socio(s): </b></span></div>}
                                    {executantes && getUnique(executantes, 'id').map(x => (<div className={"mb-1" + (width < 1100 ? " pl-1" : '')}>
                                        {(target.current.enviar && (item.status_id === 2 || item.status_id === 3 || item.status_id === 5)) ?
                                            <InputCheckboxSm checked={target.current.verificarSocio(item.id, x.id)} onChange={() => target.current.checkSocio(item.id, x.id)} /> : <></>}
                                        <span><b>{x.hora_inicial && <>{x.hora_inicial}</>}{x.hora_final && <>{' - ' + x.hora_final}</>}</b>{x.sigla && <b>{' ' + x.sigla + ' - '}</b>}
                                            <span>{x.nome}</span>
                                        </span>
                                    </div>
                                    ))}
                                </div>
                                <div className={(width > 1100 ? '' : 'column col-12 fs-13 color-gray')} style={{ width: '20%', alignItems: 'center' }}>
                                    {width < 1100 && <div className='pl-1 mb-2'><span><b>Mudança: </b></span></div>}
                                    <div className={"mb-1 pl-1"}>
                                        {executantes && getUnique(executantes, 'id').map(x =>
                                            item.mudanca && item.mudanca.filter(y => y.executante_id === x.agenda_reserva_executante_id).map(y => {
                                                return <div className="mb-2 pl-1">
                                                    <p className="mb-1"><b>{(x.sigla || x.nome) + '/' + (y.sigla || y.nome)}</b></p>
                                                    <span><i>{y.justificativa}</i></span>
                                                </div>
                                            })
                                        )}
                                    </div></div>
                                <div className={(width > 1100 ? '' : 'column col-12 fs-13 color-gray')} style={{ width: '15%', alignItems: 'center' }}>
                                    {width < 1100 && <div className='pl-1 mb-2'><span><b>Escalador: </b></span></div>}
                                    <div className={"mb-1" + (width < 1100 ? " pl-1" : '')}>{item.escalador}</div></div>
                            </div>
                        </div>
                        {width > 1100 ? <></> : <div className="row">
                            <div className={'col-12 text-center'}>
                                {permissao.permissoes.find(x => parseInt(x) === ACOES.TROCAR_SOCIO) && item.qtd_trocas > 0 && <BtnAcao icone="icon-Affiliate" texto="Solicitações de Troca" action={_ => { setItemAcao(item); setShowModalTrocaSocio(true); setModuloUsado(53) }} {...props} />}
                                {permissao.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) && (user.tipo !== 4 && item.status_id !== 7) && <BtnAcao classeContent="ml-1-between" icone="icon-Arrow-Around" texto="Trocar Status" action={_ => validarPermissao(item, 20, _ => { formatarItemConfirmarES(item); setModulo(53); setShowModalTrocarStatus(true) })} {...props} />}
                                {permissao.permissoes.find(x => parseInt(x) === ACOES.TROCAR_SOCIO) && <BtnAcao icone="icon-Add-User" texto="Adicionar/Trocar Sócio" action={() => validarPermissao(item, 22, _ => { setItemAcao(item); setShowModalAdicionarSocio(true); setModuloUsado(53); fecharCadastrosParaTrocaSocio(false) })} {...props} />}
                                {permissao.permissoes.find(x => parseInt(x) === ACOES.EXCLUIR_SOCIOS) && item.socios && item.socios.length > 0 && <BtnAcao classeContent="ml-1-between" icone="icon-Remove-User" texto="Remover Sócios" action={_ => validarPermissao(item, 23, _ => { setItemAcao(item); setModuloUsado(53); setShowExclusaoSocios(true); })} {...props} />}
                                {permissao.permissoes.find(x => parseInt(x) === ACOES.INSERIR) && <BtnAcao icone="icon-Pen" texto="Alterar" action={_ => validarPermissao(item, 3, _ => { setDuplicar(false); alterarPlantaoES(item.id) })} {...props} />}
                                {permissao.permissoes.find(x => parseInt(x) === ACOES.VISUALIZAR) && <BtnAcao icone="icon-Books" texto="Detalhes" action={_ => { setItemDetalhes({}); setTituloVisualizacao('Detalhes Plantão Estabelecimento'); obterDetalhesPlantaoEstabelecimento(item); setShowModalDetalhes(true) }} {...props} />}
                                {permissao.permissoes.find(x => parseInt(x) === ACOES.VISUALIZAR) && <BtnAcaoLink icone="icon-File-Search" texto="Visualizar" to={'/administracao/plantao-estabelecimento/visualizacao/' + item.id} />}
                                {permissao.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) && item && item.status_id !== 1 && item.status_id !== 2 && item.status_id !== 7 && <BtnAcao icone="icon-Yes" texto="Confirmar" action={() => { setPlantaoEstabelecimento(true); setShowModalConfirmacao(true); setModulo(53); formatarItemConfirmarESPadrao(item) }} {...props} />}
                                {permissao.permissoes.find(x => parseInt(x) === ACOES.EXCLUIR) && <>{item.recorrencia ? <BtnAcao icone="icon-New-Trash" texto="Excluir" action={_ => validarPermissao(item, 4, _ => validarExclusao(item))} {...props} /> :
                                    <Excluir action={_ => validarPermissao(item, 4, _ => validarExclusao(item))} />}</>}
                            </div>
                        </div>}
                    </>}
            </div>
        </div>
    }

    const excluirPlantao = async (id, tipo_exclusao, descricao_auditoria) => {
        setTipoExclusao(null)
        setShowModalValidacao(false)
        let urlExcluir = ''
        let validacao = null
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }

        if( objPermissao.modulo_id === 49) {
            switch (tipo_exclusao) {
                case 1:
                    urlExcluir = '/administracao/plantaoAlcancavelExcluirRecorrencia'
                    break
                case 2:
                    urlExcluir = '/administracao/plantaoAlcancavelExcluirProximaRecorrencia'
                    break
                case 3:
                    urlExcluir = '/administracao/plantaoAlcancavel'
                    break
            }
        } else {
            switch (tipo_exclusao) {
                case 1:
                    urlExcluir = '/administracao/plantaoEstabelecimentoExcluirRecorrencia'
                    break
                case 2:
                    urlExcluir = '/administracao/plantaoEstabelecimentoExcluirProximaRecorrencia'
                    break
                case 3:
                    urlExcluir = '/administracao/plantaoEstabelecimento'
                    break
            }

        }
        
        await get('/controleAcesso/permissao', objPermissao).then(val => validacao = !!val.length).catch(e => mostrarToast('erro', mensagens['erro']))

        if (validacao)
            await excluir(urlExcluir, id,
                e => { mostrarToast('sucesso', mensagens['sucesso']); target.current.atualizar() },
                e => {
                    if (e.response && e.response.data && e.response.data.erros)
                        e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                    else
                        mostrarToast('erro', mensagens.erro)
                }, descricao_auditoria)
        else
            mostrarToast('erro', mensagens['permissao'])
    }

    const validarExclusao = linha => {
        if (!linha.recorrencia)
            excluirPlantao(linha.id, 3, linha.descricao_auditoria)
        else {
            setIdExcluir(linha.id)
            setShowModalValidacao(true)
        }
    }

    const verificaMobileTroca = _ => {

        if (width < 1000)
            document.documentElement.scrollTop = 0
    }

    const layoutLinhaPlantaoAlcancavel = (item, dia, width, permissao) => {
        const executantesIds = item.socio_id
        let executantes = ordenarMultiplo(item.socios, [{ coluna: 'agenda_reserva_executante_id', ordenamento: { tipo: 'numero', modo: 'asc', ordem: 1 } }])

        return <div className={"card-row-agenda p-5-after card-envio-row"}>
            {
                executantes && target.current.enviar && (item.status_id === 2 || item.status_id === 3 || item.status_id === 5) && width > 767 ?
                    <div className={'switch-envio-card ' + (target.current.enviar ? '' : 'hide')} onClick={(e) => target.current.checkIndividual(e, item.id, executantesIds)}>
                        <InputSwitchSemFormik onClick={(e) => target.current.checkIndividual(e, item.id, executantesIds)} checked={target.current.verificarCheck(item.id)} classeDiv='form-group switch-center' name='plantao' size="md" tamanho={'col-lg-1'} classeTooltip={'tooltip-input-modal'} />
                    </div> : <></>
            }
            <div className='card card-envio'>
                <div className={'row ' + (width > 700 ? 'linha-plantao' : '')}>
                    {/* mobile */}
                    {(executantes && target.current.enviar && (item.status_id === 3 || item.status_id === 5)) && width < 768 ?
                        <InputSwitchSemFormik onClick={(e) => target.current.checkIndividual(undefined, item.id, executantesIds)}
                            checked={target.current.verificarCheck(item.id)} classeDiv='form-group' name='plantao' size="md" tamanho={'col-lg-1'} classeTooltip={'tooltip-input-modal'} /> : <></>}
                    <div className={width > 1100 ? 'col-lg-4 col-md-6 mb-1' : 'col-sm-12 row-space-between mb-1'}>
                        <span className='span-hora'>{moment(item.data_final + ' ' + item.hora_final, 'DD/MM/YYYY HH:mm').diff(moment(item.data_inicial + ' ' + item.hora_inicial, 'DD/MM/YYYY HH:mm'), 'hours') > 30 ? <><span>{item.hora_inicial} </span><span style={{ fontWeight: 'bold' }}>{moment(item.data_inicial, 'DD/MM/YYYY').format('ddd')} - </span> {item.hora_final} <span style={{ fontWeight: 'bold' }}>{moment(item.data_final, 'DD/MM/YYYY').format('ddd')}</span></> : (item.hora_inicial + ' - ' + item.hora_final)}</span>
                        <span className={'tag tag-' + item.status_descricao}>{item.status_descricao}</span>
                    </div>
                    {width > 1100 ?
                        <div className={'col-lg-8 col-md-6 col-sm-12 text-right'}>
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.TROCAR_SOCIO) && item.qtd_trocas > 0 && <BtnAcao icone="icon-Affiliate" texto="Solicitações de Troca" action={_ => { setItemAcao(item); setShowModalTrocaSocio(true); setModuloUsado(49) }} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) && (user.tipo !== 4 && item.status_id !== 7) && <BtnAcao classeContent="ml-1-between" icone="icon-Arrow-Around" texto="Trocar Status" action={_ => validarPermissao(item, 20, _ => { formatarItemStatus(item); setModulo(49); setShowModalTrocarStatus(true) })} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.TROCAR_SOCIO) && <BtnAcao icone="icon-Add-User" texto="Adicionar/Trocar Sócio" action={_ => validarPermissao(item, 22, _ => { setItemAcao(item); setShowModalAdicionarSocio(true); setModuloUsado(49); fecharCadastrosParaTrocaSocio(false) })} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.EXCLUIR_SOCIOS) && item.socios && item.socios.length > 0 && <BtnAcao classeContent="ml-1-between" icone="icon-Remove-User" texto="Remover Sócios" action={_ => validarPermissao(item, 23, _ => { setItemAcao(item); setModuloUsado(49); setShowExclusaoSocios(true); })} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) && <BtnAcao icone="icon-File-Copy2" texto="Duplicar" action={_ => { setDuplicar(true); alterarPlantao(item.id) }} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.VISUALIZAR) && <BtnAcao icone="icon-Books" texto="Detalhes" action={_ => { setItemDetalhes({}); setTituloVisualizacao('Detalhes Plantão'); obterDetalhesPlantao(item); setShowModalDetalhes(true) }} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) && <BtnAcao icone="icon-Pen" texto="Alterar" action={_ => validarPermissao(item, 3, _ => { setDuplicar(false); alterarPlantao(item.id) })} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.VISUALIZAR) && <BtnAcaoLink icone="icon-File-Search" texto="Visualizar" to={'/administracao/plantao-alcancaveis/visualizacao/' + item.id} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) && item && item.status_id !== 1 && item.status_id !== 2 && item.status_id !== 7 && <BtnAcao icone="icon-Yes" texto="Confirmar" action={() => { setShowModalConfirmacao(true); setModulo(49); formatarItemStatus(item) }} {...props} />}
                            {permissao.permissoes.find(x => parseInt(x) === ACOES.EXCLUIR) && <>{item.recorrencia ? <BtnAcao icone="icon-New-Trash" texto="Excluir" action={_ => validarPermissao(item, 4, _ => validarExclusao(item))} {...props} /> : <Excluir action={_ => validarPermissao(item, 4, _ => validarExclusao(item))} />}</>}
                        </div>
                        : <></>
                    }
                </div>
                <div className={'row'}>
                    <div className={'col-12 ' + (width > 1100 ? 'display-flex' : 'row pr-0 pl-0 ml-0 mr-0')}>
                        {width > 1100 ? <>
                            <table className='table-socio-agenda'>
                                <thead>
                                    <tr>
                                        <th style={{ width: '2%' }}></th>
                                        <th style={{ width: '18%' }}></th>
                                        <th style={{ width: '10%' }}></th>
                                        <th style={{ width: '15%' }}></th>
                                        <th style={{ width: '40%' }}></th>
                                        <th style={{ width: '15%' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {executantes && executantes.length > 0 ?
                                        getUnique(executantes, 'id').map((x, idx, array) => idx === 0 ?
                                            <tr>
                                                <td className={''} rowSpan={array.length}>
                                                    <div className='pl-1 mb-1'><span>{item.ordem}</span></div>
                                                </td>
                                                <td className=''><div className={"mb-1" + (width < 1100 ? " pl-1" : '')}>
                                                    {(target.current.enviar && (item.status_id === 2 || item.status_id === 3 || item.status_id === 5)) ?
                                                        <InputCheckboxSm checked={target.current.verificarSocio(item.id, x.id)} onChange={() => target.current.checkSocio(item.id, x.id)} /> : <></>}
                                                    <span>{x.sigla && <b>{x.sigla + ' - '}</b>}
                                                        <span>{x.nome}</span>
                                                    </span>
                                                </div>
                                                </td>
                                                <td className='' rowSpan={array.length}>
                                                    <div className={"mb-1"}>{item.tipo_escala}</div>
                                                </td>
                                                <td className=''>
                                                    {item.mudanca && item.mudanca.filter(y => y.executante_id === x.agenda_reserva_executante_id).map(y => <span>{y.nome}</span>)}
                                                </td>
                                                <td className='ellipsis-overflow'>
                                                    {item.mudanca && item.mudanca.filter(y => y.executante_id === x.agenda_reserva_executante_id).map(y => <span>{y.justificativa}</span>)}
                                                </td>
                                                <td className='' rowSpan={array.length}>
                                                    <div className={"mb-1"}>{item.escalador}</div>
                                                </td>
                                            </tr> :
                                            <tr>
                                                <td className=''>
                                                    <div className={"mb-1" + (width < 1100 ? " pl-1" : '')}>
                                                        {(target.current.enviar && (item.status_id === 2 || item.status_id === 3 || item.status_id === 5)) ?
                                                            <InputCheckboxSm checked={target.current.verificarSocio(item.id, x.id)} onChange={() => target.current.checkSocio(item.id, x.id)} /> : <></>}
                                                        <span>{x.sigla && <b>{x.sigla + ' - '}</b>}
                                                            <span>{x.nome}</span>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className=''>
                                                    {item.mudanca && item.mudanca.filter(y => y.executante_id === x.agenda_reserva_executante_id).map(y => <span>{y.nome}</span>)}
                                                </td>
                                                <td className='ellipsis-overflow'>
                                                    {item.mudanca && item.mudanca.filter(y => y.executante_id === x.agenda_reserva_executante_id).map(y => <span>{y.justificativa}</span>)}
                                                </td>
                                            </tr>)
                                        : <></>
                                    }
                                </tbody>
                            </table>
                        </>
                            : <>
                                <div className={(width > 1100 ? '' : 'column col-12 fs-13 color-gray')} style={{ width: '2%', alignItems: 'center' }}>
                                    <div className='pl-1 mb-1'><span>{(width < 1100 ? <b>Ordem: </b> : <></>)}{item.ordem}</span></div></div>
                                <div className={'vertical-center ' + (width > 1100 ? '' : 'column col-12 fs-13 color-gray pl-2')} style={{ width: '18%' }}>
                                    {width < 1100 && <div className='pl-1 mb-2'><span><b>Socio(s): </b></span></div>}
                                    {executantes && getUnique(executantes, 'id').map(x => (<div className={"mb-1" + (width < 1100 ? " pl-1" : '')}>
                                        {(target.current.enviar && (item.status_id === 2 || item.status_id === 3 || item.status_id === 5)) ?
                                            <InputCheckboxSm checked={target.current.verificarSocio(item.id, x.id)} onChange={() => target.current.checkSocio(item.id, x.id)} /> : <></>}
                                        <span>{x.sigla && <b>{x.sigla + ' - '}</b>}
                                            <span>{x.nome}</span>
                                        </span>
                                    </div>
                                    ))}
                                </div>

                                <div className={(width > 1100 ? '' : 'column col-12 fs-13 color-gray')} style={{ width: '10%', alignItems: 'center' }}>
                                    {width < 1100 && <div className='pl-1 mb-2'><span><b>Tipo Plantão: </b></span></div>}
                                    <div className={"mb-1" + (width < 1100 ? " pl-1" : '')}>{item.tipo_escala}</div></div>
                                <div className={(width > 1100 ? '' : 'column col-12 fs-13 color-gray')} style={{ width: '45%', alignItems: 'center' }}>
                                    {width < 1100 && <div className='pl-1 mb-2'><span><b>Mudança: </b></span></div>}
                                    <div className={"mb-1 pl-1"}>
                                        {executantes && getUnique(executantes, 'id').map(x =>
                                            item.mudanca && item.mudanca.filter(y => y.executante_id === x.agenda_reserva_executante_id).map(y => {
                                                return <div className="mb-2 pl-1">
                                                    <p className="mb-1"><b>{(x.sigla || x.nome) + '/' + (y.sigla || y.nome)}</b></p>
                                                    <span><i>{y.justificativa}</i></span>
                                                </div>
                                            })
                                        )}
                                    </div></div>
                                <div className={(width > 1100 ? '' : 'column col-12 fs-13 color-gray')} style={{ width: '15%', alignItems: 'center' }}>
                                    {width < 1100 && <div className='pl-1 mb-2'><span><b>Escalador: </b></span></div>}
                                    <div className={"mb-1" + (width < 1100 ? " pl-1" : '')}>{item.escalador}</div></div>
                            </>}
                    </div>
                </div>
                {width > 1100 ? <></> : <div className="row">
                    <div className={'col-12 text-center'}>
                        {permissao.permissoes.find(x => parseInt(x) === ACOES.TROCAR_SOCIO) && item.qtd_trocas > 0 && <BtnAcao icone="icon-Affiliate" texto="Solicitações de Troca" action={_ => { setItemAcao(item); setShowModalTrocaSocio(true); setModuloUsado(49) }} {...props} />}
                        {permissao.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) && (user.tipo !== 4 && item.status_id !== 7) && <BtnAcao classeContent="ml-1-between" icone="icon-Arrow-Around" texto="Trocar Status" action={_ => { setItemAcao(item); validarPermissao(item, 20, _ => { formatarItemStatus(item); setModulo(49); setShowModalTrocarStatus(true) }) }} {...props} />}
                        {permissao.permissoes.find(x => parseInt(x) === ACOES.TROCAR_SOCIO) && <BtnAcao icone="icon-Add-User" texto="Adicionar/Trocar Sócio" action={_ => validarPermissao(item, 22, _ => { setItemAcao(item); setShowModalAdicionarSocio(true); setModuloUsado(49); verificaMobileTroca(); fecharCadastrosParaTrocaSocio(false) })} {...props} />}
                        {permissao.permissoes.find(x => parseInt(x) === ACOES.EXCLUIR_SOCIOS) && item.socios && item.socios.length > 0 && <BtnAcao classeContent="ml-1-between" icone="icon-Remove-User" texto="Remover Sócios" action={_ => validarPermissao(item, 23, _ => { setItemAcao(item); setModuloUsado(49); setShowExclusaoSocios(true); })} {...props} />}
                        {permissao.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) && <BtnAcao icone="icon-File-Copy2" texto="Duplicar" action={_ => { setDuplicar(true); alterarPlantao(item.id) }} {...props} />}
                        {permissao.permissoes.find(x => parseInt(x) === ACOES.VISUALIZAR) && <BtnAcao icone="icon-Books" texto="Detalhes" action={_ => { setTituloVisualizacao('Detalhes Plantão/Alcançavel'); obterDetalhesPlantao(item); setShowModalDetalhes(true) }} {...props} />}
                        {permissao.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) && <BtnAcao icone="icon-Pen" texto="Alterar" action={_ => validarPermissao(item, 3, _ => alterarPlantao(item.id))} {...props} />}
                        {permissao.permissoes.find(x => parseInt(x) === ACOES.VISUALIZAR) && <BtnAcaoLink icone="icon-File-Search" texto="Visualizar" to={'/administracao/plantao-alcancaveis/visualizacao/' + item.id} />}
                        {permissao.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) && item && item.status_id !== 1 && item.status_id !== 2 && item.status_id !== 7 && <BtnAcao icone="icon-Yes" texto="Confirmar" action={() => { setShowModalConfirmacao(true); setModulo(49); formatarItemStatus(item) }} {...props} />}
                        {permissao.permissoes.find(x => parseInt(x) === ACOES.EXCLUIR) && <>{item.recorrencia ? <BtnAcao icone="icon-New-Trash" texto="Excluir" action={_ => validarPermissao(item, 4, _ => validarExclusao(item))} {...props} /> : <Excluir action={_ => validarPermissao(item, 4, _ => validarExclusao(item))} />}</>}
                    </div>
                </div>}
            </div>
        </div>
    }

    const alterarPlantao = id => {
        setIdAlterar(id)
        setModalCadastroPlantao(true)
        setShowModalAdicionarSocio(false)

    }

    const alterarPlantaoES = id => {
        setIdAlterar(id)
        setModalCadastroPlantaoES(true)
        setShowModalAdicionarSocio(false)
    }

    const validarPermissao = (linha, acao, action) => {
        if (moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY') > moment(linha.data_inicial, 'DD/MM/YYYY'))
            get('/administracao/permissaoAlterarAposData', { usuario: user.id, acao, usuario_tipo: user.tipo, id: linha.id, modulo: 75}, true)
                .then(result => !result || result.length > 0 ? action()
                    : mostrarToast('erro', 'Operação bloqueada! Data anterior a data atual.'))
                .catch(err => { })
        else
            action()
    }

    const transformarEmItemDaConsultaAgendaAssociado = async (item) => {
        const url = `/administracao/agendareservaParaConversao/${item.id}`;

        let ret = {};
        await get(url).then(val => {
            ret = val;
        })

        setItemAcaoConvertido(ret);
    }

    const fecharTodosCadastros = () => {
        setModalCadastroPlantao(false);
        setModalCadastroPlantaoES(false);
        setShowModalAdicionarSocio(false)
    }
    const fecharModalTrocaSocio = () => {
        setShowModalAdicionarSocio(false)

    }

    const fecharCadastrosParaTrocaSocio = () => {

        setModalCadastroPlantao(false);
        setModalCadastroPlantaoES(false);
    }


    const obterAcoes = (item) => {
        return (<BtnAcao type='button' icone="icon-Books" texto="Detalhes" classTooltip={'tooltip-input-modal'} action={() => {
            if (target.current.modulo == 53) {
                setTituloVisualizacao('Detalhes Plantão Estabelecimento');
                obterDetalhesPlantaoEstabelecimento(item.valoresIniciais);
            }
            else {
                setTituloVisualizacao('Detalhes Plantão');
                obterDetalhesPlantao(item.valoresIniciais);
            }
            setShowModalDetalhes(true)
        }} {...props} />)
    }

    return (
        telas.length > 0 ? <AgendaEscalador menuAberto={props.menuAberto} abrirFiltroModal={modalCadastroPlantao} configState={props.configState} telas={telas} ref={target} fecharCadastros={fecharTodosCadastros} showModalHeader={modalCadastroPlantao} modalHeader={<>
            <CadastroTrocaSocio rotaValidacao={'/administracao/verificaAdicionarSocioPlantao'}
                funcaoAposSalvar={(val) => { target.current.atualizar(); setShowModalAdicionarSocio(false) }} modulo_id={moduloUsado} fecharCadastros={fecharTodosCadastros} acaoProx={null} acaoAnte={null} titulo={'Adicionar/Trocar Sócio'} acoes={null} abrirModal={showModalAdicionarSocio} setAbrirModal={setShowModalAdicionarSocio} item={itemAcaoConvertido} setItem={setItemAcaoConvertido} itemAcao={itemAcao} setItemAcao={setItemAcao} fecharComEsc={true}>
            </CadastroTrocaSocio>
            <CadastroEstabelecimentoPlantao duplicar={duplicar} target={target} abrirModal={modalCadastroPlantaoES} setAbrirModal={setModalCadastroPlantaoES} id={idAlterar} />
            <CadastroPlantao duplicar={duplicar} target={target} abrirModal={modalCadastroPlantao} setAbrirModal={setModalCadastroPlantao} id={idAlterar} />
        </>} ordenamento={ordenamento} permissoes={permissoes}>
            {/* <ModalVisualizacao titulo={tituloVisualizacao} data={itemDetalhes} showModal={showModalDetalhes} setShowModal={setShowModalDetalhes} /> */}
            <ModalEscolha showModal={showModalValidacao} setShowModal={setShowModalValidacao} esconderFechar mensagem={'Selecione uma opção:'}
                confirmar={tipoExclusao} funcaoConfirmar={_ => excluirPlantao(idExcluir, tipoExclusao)} funcaoCancelar={_ => setTipoExclusao(null)}>
                <ul class="lista-modal text-center width-100">
                    <li className={tipoExclusao === 3 ? 'excluir-active' : ''} onClick={_ => setTipoExclusao(3)}>
                        <div className='mb-1 li-titulo'>Excluir somente este registro</div>
                    </li>
                    <div className='mt-1' />
                    <li className={tipoExclusao === 2 ? 'excluir-active' : ''} onClick={_ => setTipoExclusao(2)}>
                        <div className='mb-1 li-titulo'>Excluir próximas ocorrências</div>
                    </li>
                    <div className='mt-1' />
                    <li className={tipoExclusao === 1 ? 'excluir-active' : ''} onClick={_ => setTipoExclusao(1)}>
                        <div className='mb-1 li-titulo'>Excluir todas as ocorrências</div>
                    </li>
                </ul>
            </ModalEscolha>
            {/* <CadastroSocioAgenda justificativa={true} rotaValidacao={'/administracao/verificaAdicionarSocioPlantao'} funcaoAposSalvar={(val) => { target.current.atualizar(); setShowModalAdicionarSocio(false) }} modulo_id={moduloUsado} acaoProx={null} acaoAnte={null} titulo={'Trocar Sócio'} acoes={null} abrirModal={showModalAdicionarSocio} setAbrirModal={setShowModalAdicionarSocio} item={itemAcaoConvertido} fecharComEsc={true}>
            </CadastroSocioAgenda> */}
            {/* <ConfirmarAgendamento abrirModal={showModalConfirmacao}
                setAbrirModal={setShowModalConfirmacao} modulo={modulo} item={itemStatus} setItem={setItemStatus} funcaoAposSalvar={(val) => target.current.atualizar()} /> */}
            <ConfirmarAgendamentoSocio abrirModal={showModalConfirmacao} plantaoEstabelecimento={plantaoEstabelecimento} detalhesHtml={DetalhesHtml} sociosSelecionados={socioSelecionado} setSocioSelecionado={setSocioSelecionado}
                setAbrirModal={setShowModalConfirmacao} modulo={modulo} item={itemStatus} setItem={setItemStatus} funcaoAposSalvar={(val) => target.current.atualizar()} />
            <TrocarStatus abrirModal={showModalTrocarStatus} modulo={modulo} acoes={obterAcoes(itemStatus)}
                setAbrirModal={setShowModalTrocarStatus} item={itemStatus} setItem={setItemStatus} funcaoAposSalvar={(val) => { target.current.atualizar(); setShowModalTrocarStatus(false); }} />
            <ManterTrocas funcaoAposSalvar={(val) => { target.current.atualizar(); setShowModalTrocaSocio(false) }} modulo_id={moduloUsado} abrirModal={showModalTrocaSocio} setAbrirModal={setShowModalTrocaSocio} item={itemAcaoConvertido} fecharComEsc={true}></ManterTrocas>
            <DetalhesAgendamento setShowModal={setShowModalDetalhes} showModal={showModalDetalhes} itemDetalhes={itemDetalhes} titulo={tituloVisualizacao} />
            <ExclusaoSocios item={itemAcao} show={showExclusaoSocios} setShow={setShowExclusaoSocios} modulo_id={moduloUsado} funcaoAposSalvar={(val) => { target.current.atualizar(); }} />
        </AgendaEscalador> : <></>
    )
}

const AgendaEscalador = React.forwardRef((props, ref) => {
    const [tela, setTela] = useState();
    const [data, setData] = useState([])
    const [dadosFiltrados, setDadosFiltrados] = useState([])
    const [dias, setDias] = useState([])
    const [pesquisa, setPesquisa] = useState('')
    const [pesquisaChar, setPesquisaChar] = useState('')
    const [digitando, setDigitando] = useState(null)
    const [filtros, setFiltros] = useState({})
    const [dataAtual, setDataAtual] = useState([])
    const [filtroAberto, setFiltroAberto] = useState(false)
    const [descricaoFiltro, setDescricaoFiltro] = useState('')
    const [width, height] = useWindowSize();
    const [filtrosOff, setFiltrosOff] = useState({});
    const [arrayStatus, setArrayStatus] = useState([])
    const [enviar, setEnviar] = useState(false)
    const [arrayEnvio, setArrayEnvio] = useState([])
    const [enviando, setEnviando] = useState(false)
    const [todosMarcados, setTodosMarcados] = useState(false)
    const [possuiPermissaoEnvio, setPossuiPermissaoEnvio] = useState(false)
    const [scroll, setScroll] = useState(0)
    const user = getUsuario()
    const history = useHistory();
    const [showCalendar, setShowCalendar] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const refLoading = useRef(null)
    const [hash, setHash] = useState('')
    const [showRegistro, setShowRegistro] = useState(false)
    const [showLembrete, setShowLembrete] = useState(false)
    const [showModalCadastrarLembrete, setShowModalCadastrarLembrete] = useState(false)
    const [showModalCadastrarRegistroAtividade, setShowModalCadastrarRegistroAtividade] = useState(false)
    const [atualizarLembrete, setAtualizarLembrete] = useState(false)
    const [atualizarRegistro, setAtualizarRegistro] = useState(false)
    const [carregando, setCarregando] = useState(false)
    const [showResumoGrupos, setShowResumoGrupos] = useState(false)


    useEffect(() => {
        setCarregando(true)
        if (digitando) {
            clearTimeout(digitando);
        }

        setDigitando(setTimeout(function () {
            setPesquisa(pesquisaChar);
            setCarregando(false);
        }, 700))
    }, [pesquisaChar])

    const obterDados = (naoMostrarLoad) => {
        !naoMostrarLoad && setIsLoading(true)
        consultar(tela.url, (val) => { setData(val); setIsLoading(false); }, () => { setIsLoading(false); }, filtros)
        consultar(tela.urlHash, val => { setHash(val.hash) }, null, filtros)
    }

    const calcularData = (valor, modo_visualizacao) => {
        let novosFiltros = { modo_visualizacao: modo_visualizacao ? modo_visualizacao : filtros.modo_visualizacao }
        let tipoContagem = '';
        let today = moment(filtros.data_inicial, 'DD/MM/YYYY');

        switch (modo_visualizacao ? modo_visualizacao : filtros.modo_visualizacao) {
            case 1:
                tipoContagem = 'days'
                if (valor.toString().includes('/')) {
                    novosFiltros = {
                        data_inicial: valor,
                        data_final: valor, ...novosFiltros
                    }
                }
                else {
                    today = valor === 0 ? moment() : today;
                    novosFiltros = {
                        data_inicial: today.clone().add(valor, tipoContagem).format('DD/MM/YYYY'),
                        data_final: today.clone().add(valor, tipoContagem).format('DD/MM/YYYY'), ...novosFiltros
                    }
                }

                setDescricaoFiltro(moment(novosFiltros.data_inicial, 'DD/MM/YYYY').format('DD/MM/YYYY'))

                break;
            case 2:
                let from_date
                let to_date
                tipoContagem = 'weeks'

                if (valor.toString().includes('/')) {
                    today = moment(valor, 'DD/MM/YYYY');
                    from_date = today.startOf('week').toDate()
                    to_date = today.endOf('week').toDate()
                }
                else {
                    from_date = today.startOf('week').toDate()
                    to_date = today.endOf('week').toDate()
                }

                novosFiltros = {
                    data_inicial: moment(from_date).add(valor, tipoContagem).format('DD/MM/YYYY'),
                    data_final: moment(to_date).add(valor, tipoContagem).format('DD/MM/YYYY'), ...novosFiltros
                }
                setDescricaoFiltro(moment(novosFiltros.data_inicial, 'DD/MM/YYYY').format('DD/MM/YYYY') + ' - ' + moment(novosFiltros.data_final, 'DD/MM/YYYY').format('DD/MM/YYYY'))
                break;
            case 3:
                tipoContagem = 'months'
                let from_date_month
                let to_date_month

                if (valor.toString().includes('/')) {
                    today = moment(valor, 'DD/MM/YYYY');
                    from_date_month = today.startOf('month').toDate()
                    to_date_month = today.endOf('month').toDate()
                }
                else {
                    from_date_month = today.startOf('month').toDate()
                    to_date_month = today.endOf('month').toDate()
                }

                novosFiltros = {
                    data_inicial: moment(from_date_month).add(valor, tipoContagem).format('DD/MM/YYYY'),
                    data_final: moment(to_date_month).add(valor, tipoContagem).endOf('month').format('DD/MM/YYYY'), ...novosFiltros
                }

                setDescricaoFiltro(moment(novosFiltros.data_inicial, 'DD/MM/YYYY').format('MMMM - YYYY'))
                break;
            default:
                break;
        }
        setFiltros(novosFiltros)
    }

    useEffect(_ => {
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                setFiltroAberto(false)
        }, false)

        window.addEventListener('scroll', e => {
            setScroll(window.pageYOffset)
        })
    }, [])

    const filtrarCampo = (option, nomeCampo) => {
        let novoFiltro = { ...filtrosOff }
        novoFiltro[nomeCampo] = option
        setFiltrosOff(novoFiltro)
    }



    const obterAgendamentos = () => {
        let html = [];

        // if (isLoading)
        //     return <LoadRelatorio refLoading={refLoading} heigth={490} />

        dadosFiltrados && dias.map(item => {
            const hoje = moment(item, 'DD/MM/YYYY')
            const dataFiltroPesquisa = dadosFiltrados.filter(item => pesquisa ? removeAcentos(tela.camposPesquisa.map(itemPesquisa => item[itemPesquisa]).join(" ").toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())) : true)
            const dataFiltrada = dataFiltroPesquisa.filter(item2 => item2.data_inicial === item || (moment(item2.data_inicial, 'DD/MM/YYYY') <= moment(item, 'DD/MM/YYYY') && moment(item2.data_final, 'DD/MM/YYYY') >= moment(item, 'DD/MM/YYYY')));

            if (dataFiltrada.length > 0) {
                html.push(<>
                    <div className={'div-dia ' + (item === dataAtual ? 'hoje' : '')}><span>{item + moment(item, 'DD/MM/YYYY').format(' - dddd')}</span></div>
                    <div className="agenda">
                        <div key="10" className="card-pequeno  col-md-12 col-sm-12" onClick={() => { }}>
                            {
                                dataFiltrada.map(item2 => {
                                    return tela.layoutLinha(item2, hoje, width)
                                })
                            }
                        </div>
                    </div>
                </>)
            }
        })

        return html.length > 0 ? html : <div className={'div-dia'}><span>Nenhum Registro Encontrado.</span></div>
    }

    const check = (event, id, idsSocios) => {
        if (event != undefined) {
            event.preventDefault()
        }
        let cloneArray = [...arrayEnvio]
        if (arrayEnvio.findIndex(x => x.id === id) > -1) {
            cloneArray.splice(arrayEnvio.findIndex(x => x.id === id), 1)
        } else {
            cloneArray.push({ id: id, socios: idsSocios })
        }

        setArrayEnvio(cloneArray);
    }

    const checkSocio = (id, idSocio) => {
        let cloneArray = [...arrayEnvio]
        let cloneItem = cloneArray[cloneArray.findIndex(x => x.id === id)]
        if (!cloneItem) {
            check(undefined, id, [idSocio])
            return;
        }

        let cloneArraySocios = cloneItem ? [...cloneItem.socios] : []
        if (cloneArraySocios.includes(idSocio)) {
            cloneArraySocios.splice(cloneArraySocios.indexOf(idSocio), 1)
        } else {
            cloneArraySocios.push(idSocio)
        }

        if (cloneArraySocios.length === 0) {
            check(undefined, id, [])
        } else {
            cloneItem.socios = cloneArraySocios;
            cloneArray[cloneArray.findIndex(x => x.id === id)] = cloneItem;
            setArrayEnvio(cloneArray);
        }

    }

    const marcarTodos = (event) => {
        event.preventDefault()
        let cloneArray = []

        if (!todosMarcados) {
            cloneArray = (data.filter(item => item.status_id === 3 || item.status_id === 5).map(item => (
                {
                    id: item.id,
                    socios: item.socios ? item.socios.map(itemSocio => itemSocio.id) : []
                })))
        } else {
            cloneArray = []
        }
        setArrayEnvio(cloneArray);
    }

    const confirmarEnvio = () => {
        let obj = { envios: arrayEnvio };
        if (arrayEnvio.length === 0)
            return;

        setEnviando(true)
        putComAcao(
            ACOES.ENVIAR,
            tela.modulo,
            obj,
            '/administracao/agendareservaenvio',
            () => {
                mostrarToast('sucesso', mensagens.sucesso);
                setEnviar(false);
                setEnviando(false);
                setArrayEnvio([]);
                setTimeout(() => {
                    obterDados(filtros)
                }, 200)
            },
            (erro) => {
                mostrarToast('erro', mensagens.erro);
                setEnviando(false)
            })
    }

    const verificarSocio = (id, idSocio) => {
        let cloneItem = arrayEnvio[arrayEnvio.findIndex(x => x.id === id)]
        return cloneItem !== undefined && cloneItem.socios.includes(idSocio)
    }

    ref.current = {
        atualizar: _ => obterDados(),
        enviar: enviar,
        checkSocio: (id, idSocio) => checkSocio(id, idSocio),
        checkIndividual: check,
        verificarCheck: id => arrayEnvio.findIndex(x => x.id === id) > -1,
        verificarSocio: (id, idSocio) => verificarSocio(id, idSocio),
        modulo: tela && tela.modulo ? tela.modulo : null
    }

    const filtarStatus = (idStatus) => {
        let novoFiltro = { ...filtrosOff }

        if (novoFiltro.status) {
            let idx = novoFiltro.status.findIndex(item => item === idStatus)

            if (idx > -1)
                novoFiltro.status.splice(idx, 1)
            else
                novoFiltro.status.push(idStatus)
        } else
            novoFiltro.status = [idStatus]

        setFiltrosOff(novoFiltro)
    }

    useEffect(() => {
        if (data.length > 0 && arrayEnvio.length === data.filter(item => item.status_id === 3 || item.status_id === 5).length) {
            setTodosMarcados(true)
        } else {
            setTodosMarcados(false)
        }
    }, [arrayEnvio])

    useEffect(() => {
        const dataCompartilhada = (history.location.state && history.location.state.data) ? moment(history.location.state.data, 'DD/MM/YYYY').format("DD/MM/YYYY") : moment(new Date()).format("DD/MM/YYYY")

        setFiltros({ data_inicial: dataCompartilhada, data_final: dataCompartilhada, modo_visualizacao: 1 })
        setTela(history.location.state && props.telas[history.location.state.telaPadrao] || (props.configState && props.configState.telaPadrao ? props.telas[props.configState.telaPadrao] : undefined) || props.telas[0])
        setDescricaoFiltro(dataCompartilhada)
        setDataAtual(moment(new Date()).format("DD/MM/YYYY"))
        setFiltroAberto(false)
        Promise.resolve(get('/administracao/statusplantao')).then(val => setArrayStatus(val))
    }, [])

    useInterval(() => {
        if (tela && (history.location.pathname.includes('plantao-alcancaveis/consulta') || history.location.pathname.includes('plantao-estabelecimento/consulta')))
            consultar(tela.urlHash, val => {
                if (val && val.hash !== hash)
                    obterDados(true)
            }, null, filtros)
    }, 10000);

    useEffect(() => {
        if (filtros.modo_visualizacao) {
            obterDados();
        }
    }, [filtros, tela])

    useEffect(() => {
    }, [hash]);

    useEffect(() => {
        setPossuiPermissaoEnvio(false)
        if (tela) {
            tela.modulo === 49 && setFiltrosOff({ ordenamento: [{ label: <span><span>Tipo Plantão</span> <span className='icon-Up icone-ordenamento'></span></span>, modo: "desc", tipo: "texto", value: "tipo_escala" }, { label: <span><span>Ordem</span> <span className='icon-Down icone-ordenamento'></span></span>, modo: "asc", tipo: "numero", value: "ordem" }, { label: <span><span>Horário</span> <span className='icon-Down icone-ordenamento'></span></span>, modo: "asc", tipo: "hora", value: "hora_inicial_completa" }] })
            tela.modulo === 53 && setFiltrosOff({ ordenamento: [{ label: <span><span>Horário</span> <span className='icon-Down icone-ordenamento'></span></span>, modo: "asc", tipo: "hora", value: "hora_inicial" }] })
            Promise.resolve(get('/controleAcesso/permissao', { usuario_id: user.id, modulo_id: tela.modulo, acao_id: 10 })).then(function (val) {
                setPossuiPermissaoEnvio(val.length > 0)
            })
        }
    }, [tela]
    )

    useEffect(() => {

        if (tela && tela.funcaoFiltrar && filtrosOff && data)
            setDadosFiltrados(tela.funcaoFiltrar([...data], filtrosOff))
        else if (tela)
            setDadosFiltrados(data)

        setTimeout(() => setCarregando(false), 700)
    }, [data, filtrosOff])

    useEffect(() => {
        if (filtros.data_inicial && filtros.data_final)
            setDias(getBetweenDates(moment(filtros.data_inicial, 'DD/MM/YYYY'), moment(filtros.data_final, 'DD/MM/YYYY'), 'DD/MM/YYYY'))
        else
            setDias(dadosFiltrados.map((item) => item.data_inicial).filter((item, idx, self) => self.indexOf(item) === idx))
    }, [dadosFiltrados])


    var button1 = props.telas.filter(x => tela === x).map(itemTela => itemTela.botao && itemTela.botao).map(x => {
        return (

            {
                icone: x[0].props.icone,
                click: x[0].props.click,
                textoTooltip: x[0].props.textoTooltip
            }

        )
    })

    var button2 = props.telas.filter(x => tela !== x).map(x => {
        return (

            {
                icone: x.icone,
                textoTooltip: x.titulo,
                click: () => { setTela(x); x.modalCadastro(false) }
            }
        )
    })

    useEffect(() => {
        if (filtroAberto) {
            setShowLembrete(false)
            setShowRegistro(false)
            setShowResumoGrupos(false)
        }
    }, [filtroAberto])

    const ListaCompletaMemo = useMemo(() => obterAgendamentos(), [tela, dadosFiltrados, dias, pesquisa, enviar, arrayEnvio])

    return (
        <div ref={refLoading} className={'agenda-content ' + ((filtroAberto === true || showLembrete === true || showRegistro === true) && (width > 1200 && !props.abrirFiltroModal) ? ' filtro-ativo' : '')}>
            <div className={'card-right' + (filtroAberto === true && (width > 1200 && !props.abrirFiltroModal) ? ' filtro-ativo' : '')}>
                <FiltrosHtml className={'agenda-content'} setFiltroAberto={setFiltroAberto} filtarStatus={filtarStatus} filtrosOff={filtrosOff} setFiltrosOff={setFiltrosOff}
                    calcularData={calcularData} tela={tela} dadosStatus={dadosFiltrados && dadosFiltrados.map(x => { return { status_id: x.status_id } })}>
                    <FiltrosTela filtrosOff={filtrosOff} filtrar={filtrarCampo} ordenamento={props.ordenamento} tela={tela} />
                </FiltrosHtml>
            </div>
            {/* trocar header */}
            {/* possuiPermissaoEnvio */}
            {data && possuiPermissaoEnvio === true && data.filter(item => item.status_id === 2 || item.status_id === 3 || item.status_id === 5).length > 0 ? (enviar && !enviando ?
                <div className={'slide-up-bottom-div right-50' + (filtroAberto ? ' filtro-aberto' : '')}>
                    <BotaoPadrao click={_ => confirmarEnvio()} type={'button'} classe={'btn-primary primary'} texto={'Confirmar'} />
                    <BotaoPadrao click={_ => { setEnviar(false); setArrayEnvio([]); }} type={'button'} classe={'btn-primary primary'} texto={'Cancelar'} />
                </div >
                : <></>) : <></>}
            {
                tela && <HeaderAgenda showResumoGrupos={showResumoGrupos} setShowResumoGrupos={setShowResumoGrupos} className='header-agenda' titulo={(tela.titulo || 'Plantão')} icone={tela.icone || 'icon-Clock'} botoes={
                    width > 450 ?
                        <>
                            {data && possuiPermissaoEnvio && data.filter(item => item.socios && (item.status_id === 2 || item.status_id === 3 || item.status_id === 5)).length > 0 ? (!(enviar && !enviando) ?
                                <BotaoPadrao textoTooltip="Enviar" icone='icon-Mail-Send' classe=' btn btn-primary primary btn-filter '
                                    idTooltip={"padrao"} click={() => setEnviar(true)} disabled={enviando} />
                                : <></>
                            ) : <></>}
                            {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 17).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <Link to={{ pathname: '/administracao/socio-ocorrencia/ListagemDiaria', state: { data: descricaoFiltro } }}>
                                <BotaoPadrao click={_ => { }} icone='icon-Letter-Open' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Ocorrências'} idTooltip={"padrao"} />
                            </Link>}
                            {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 50).permissoes.filter(item => item).length > 0 && <BotaoPadrao textoTooltip="Lembrete" icone='icon-Bell' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={_ => setShowModalCadastrarLembrete(true)} /> }
                            {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 39).permissoes.filter(item => item).length > 0 && <BotaoPadrao textoTooltip="Registro Atividade" icone='icon-Address-Book2' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={_ => setShowModalCadastrarRegistroAtividade(true)} /> }
                            {props.telas.filter(x => tela === x).map(itemTela => itemTela.botao && itemTela.botao)}
                            <Link to={{ pathname: '/administracao/agendareserva/consulta', state: { data: descricaoFiltro } }}>
                                <BotaoPadrao click={_ => { }} icone='icon-Calendar-3' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Agenda'} idTooltip={"padrao"} />
                            </Link>
                            {props.telas.filter(x => tela !== x).map(itemTela =>
                                <Link to={{ state: { telaPadrao: props.telas.findIndex(x => x.modulo === itemTela.modulo) } }}>
                                    <BotaoPadrao textoTooltip={itemTela.titulo} icone={itemTela.icone} idTooltip={"padrao"} classe=' btn btn-primary primary btn-filter' click={() => { props.fecharCadastros(false); setTela(itemTela); setEnviar(false) }} />
                                </Link>)}
                            <Link to={{ pathname: '/administracao/rotinas/consulta', state: { data: descricaoFiltro } }}>
                                <BotaoPadrao click={_ => { }} icone='icon-Dec' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Rotinas'} idTooltip={"padrao"} />
                            </Link>
                            <BotaoPadrao textoTooltip="Filtrar" icone='icon-Filter-2' classe={'btn btn-primary primary btn-filter'} click={() => setFiltroAberto(!filtroAberto)} idTooltip={"padrao"} />
                        </>
                        :
                        <>
                            <BotaoMenu icone={'icon-ellipsi'}>
                                {data && possuiPermissaoEnvio && data.filter(item => item.socios && (item.status_id === 2 || item.status_id === 3 || item.status_id === 5)).length > 0 ? (!(enviar && !enviando) ?
                                    <BotaoPadrao textoTooltip="Enviar" icone='icon-Mail-Send' classe=' btn btn-primary primary btn-filter '
                                        idTooltip={"padrao"} click={() => setEnviar(true)} disabled={enviando} />
                                    : <></>
                                ) : <></>}
                                <Link to={{ pathname: '/administracao/socio-ocorrencia/ListagemDiaria', state: { data: descricaoFiltro } }}>
                                    <BotaoPadrao click={_ => { }} icone='icon-Letter-Open' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Ocorrências'} idTooltip={"padrao"} />
                                </Link>
                                <BotaoPadrao textoTooltip="Lembrete" icone='icon-Bell' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={_ => setShowModalCadastrarLembrete(true)} />
                                <BotaoPadrao textoTooltip="Registro Atividade" icone='icon-Address-Book2' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={_ => setShowModalCadastrarRegistroAtividade(true)} />
                                {props.telas.filter(x => tela === x).map(itemTela => itemTela.botao && itemTela.botao)}
                                <Link to={{ pathname: '/administracao/agendareserva/consulta', state: { data: descricaoFiltro } }}>
                                    <BotaoPadrao click={_ => { }} icone='icon-Calendar-3' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Agenda'} idTooltip={"padrao"} />
                                </Link>
                                {props.telas.filter(x => tela !== x).map(itemTela =>
                                    <Link to={{ state: { telaPadrao: props.telas.findIndex(x => x.modulo === itemTela.modulo) } }}>
                                        <BotaoPadrao textoTooltip={itemTela.titulo} icone={itemTela.icone} idTooltip={"padrao"} classe=' btn btn-primary primary btn-filter' click={() => { props.fecharCadastros(false); setTela(itemTela); setEnviar(false) }} />
                                    </Link>)}
                                <Link to={{ pathname: '/administracao/rotinas/consulta', state: { data: descricaoFiltro } }}>
                                    <BotaoPadrao click={_ => { }} icone='icon-Dec' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Rotinas'} idTooltip={"padrao"} />
                                </Link>
                                <BotaoPadrao textoTooltip="Filtrar" icone='icon-Filter-2' classe={'btn btn-primary primary btn-filter'} click={() => setFiltroAberto(!filtroAberto)} idTooltip={"padrao"} />
                            </BotaoMenu>
                        </>
                } showModalHeader={tela.abrirModal} setFiltroAberto={setFiltroAberto} modalHeader={props.modalHeader} showRegistro={showRegistro} setShowRegistro={setShowRegistro} showLembrete={showLembrete} setShowLembrete={setShowLembrete} clickCadastroLembrete={props.onClickCadastrarLembrete} filtros={filtros} atualizarLembrete={atualizarLembrete} />
            }
            <div className='row'>
                <div className={'col-sm-5 col-md-9 col-lg-6'}>
                    <InputSimples name="search1" type="text" onChange={e => setPesquisaChar(e.target.value)} placeholder={`Pesquisar...`} />
                    {/* limparFiltros() */}
                </div>
                <div className={'col-sm-2 col-md-3 col-lg-2'}>
                    <div className="">
                        {filtrosOff.ordenamento && filtrosOff.ordenamento.length > 0 && <BtnAcao icone='icon-A-Z' texto="Remover Ordenamento" cortarIcone action={() => {
                            let clone = { ...filtrosOff }
                            clone.ordenamento = null
                            setFiltrosOff(clone)
                            setCarregando(true)
                        }} />}
                        {Object.keys(filtrosOff).filter(key => key != 'ordenamento' && filtrosOff[key] != null).length > 0 && <BtnAcao icone='icon-Filter-2' texto={"Remover Filtros(" + Object.keys(filtrosOff).filter(key => key != 'ordenamento' && filtrosOff[key] != null).length + ")"} cortarIcone action={() => {setFiltrosOff(filtrosOff.ordenamento ? { ordenamento: filtrosOff.ordenamento } : {}); setCarregando(true)}} />}
                    </div>
                </div>
                <div className={'col-md-12 col-lg-4'}>
                    <div className={'paginacao-rotinas ' + (width < 990 || (width < 1488 && filtroAberto) ? ' text-center mb-3' : ' text-right')}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: (width < 990 || (width < 1488 && filtroAberto) ? 'center' : 'flex-end') }}>
                        {carregando && <span className="spinner-border spinner-border-md" role="status" aria-hidden="true" style={{ opacity: 0.2 }}></span>}
                            <button className="icon-Arrow-Left2 pagination-button icone icone-pagination d-none" onClick={() => { }}></button>
                            <button className="icon-Arrow-Left pagination-button icone icone-pagination" onClick={() => calcularData(-1)}></button>
                            <ModalCalendar show={showCalendar} setShowModal={setShowCalendar}>
                                <InfiniteCalendar className={showCalendar ? "calendario-ativo" : "calendario-oculto"}
                                    locale={{
                                        locale: require('date-fns/locale/pt'),
                                        blank: 'Selecione uma data',
                                        headerFormat: 'ddd, MMM Do',
                                        weekdays: ["Dom", "Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb"],
                                        todayLabel: {
                                            long: 'Hoje',
                                            short: 'Hoje'
                                        },
                                        weekStartsOn: 0,
                                    }}
                                    selected={descricaoFiltro.includes('-') ? new Date() : new Date(descricaoFiltro.split('/')[1] + '/' + descricaoFiltro.split('/')[0] + '/' + descricaoFiltro.split('/')[2])}
                                    onSelect={value => { calcularData(value.getDate().toString().padStart(2, '0') + '/' + (value.getMonth() + 1).toString().padStart(2, '0') + '/' + value.getFullYear()); setShowCalendar(!showCalendar); }} height={height - 150}
                                    width={width < 501 ? width : 403}
                                />
                            </ModalCalendar>
                            <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={_ => setShowCalendar(!showCalendar)}>{descricaoFiltro} </span>
                            <button className="icon-Arrow-Right pagination-button icone icone-pagination" onClick={() => calcularData(1)}></button>
                            <button className="icon-Arrow-Right2 pagination-button icone icone-pagination d-none" onClick={() => { }}></button>
                        </div>
                        {descricaoFiltro.includes('/') && !descricaoFiltro.includes('-') && <span style={{ marginRight: (width < 990 || (width < 1488 && filtroAberto) ? 'unset' : '95px') }}>{moment(descricaoFiltro, 'DD/MM/YYYY').format('ddd')}</span>}
                    </div>
                </div>
            </div>
            <div className={'card-row-agenda p-5-after mb-1' + (enviar ? ' card-envio-row' : '')}>
                {enviar ? <div className={'switch-envio-card'} onClick={(e) => marcarTodos(e)}>
                    <InputSwitchSemFormik onClick={(e) => marcarTodos(e)} checked={todosMarcados} classeDiv='form-group switch-center'
                        name='plantao' size="md" tamanho={'col-lg-1'} classeTooltip={'tooltip-input-modal'} />
                </div> : <></>}
                {width > 1100 ? <div className='card no-border justfy-center'>
                    <div className='row m-0'>
                        {
                            props.telas.find(x => tela === x) && props.telas.find(x => tela === x).colunas.map(coluna =>
                                <div className={'column no-border fs-13'} style={{ width: coluna.width }}>
                                    <b>{coluna.label}</b>
                                </div>)
                        }
                    </div>
                </div> : <></>}
            </div>
            {ListaCompletaMemo}
            {props.children}
            <ModalPadraoCadastro showModal={filtroAberto == true && (width <= 1200 || props.abrirFiltroModal)} setShowModal={setFiltroAberto} fecharComEsc={false}>
                <FiltrosHtml className={'agenda-content'} setFiltroAberto={setFiltroAberto} filtarStatus={filtarStatus} filtrosOff={filtrosOff} setFiltrosOff={setFiltrosOff}
                    calcularData={calcularData} tela={tela} dadosStatus={dadosFiltrados && dadosFiltrados.map(x => { return { status_id: x.status_id } })}>
                    <FiltrosTela filtrosOff={filtrosOff} filtrar={filtrarCampo} ordenamento={props.ordenamento} tela={tela} />
                </FiltrosHtml>
            </ModalPadraoCadastro>
            <CadastroModalLembrete dataAtual={dataAtual} modal abrirModal={showModalCadastrarLembrete} setAbrirModal={setShowModalCadastrarLembrete} funcaoAposSalvar={_ => { obterDados(); setAtualizarLembrete(true); }} />
            <CadastroModalRegistroAtividade modal abrirModal={showModalCadastrarRegistroAtividade} setAbrirModal={setShowModalCadastrarRegistroAtividade} funcaoAposSalvar={_ => { obterDados(); setAtualizarRegistro(true); }} />
            {width > 530 &&
                <>
                    <div style={{ top: height / 2 }} className={'slide-middle-div slide-middle-left-div ' + (props.menuAberto ? '' : 'btn-left-0')}>
                        <BotaoPadrao icone='icon-Arrow-Left' classe='btn-middle-left btn primary btn-filter btn-scroll-top' idTooltip={"padrao"} click={_ => calcularData(-1)} />
                    </div>
                    <div style={{ top: height / 2 }} className={'slide-middle-div slide-middle-right-div ' + (filtroAberto || showRegistro || showLembrete ? 'margin-r-279' : 'right-5-neg')}>
                        <BotaoPadrao icone='icon-Arrow-Right' classe='btn-middle-right btn primary btn-filter btn-scroll-top' idTooltip={"padrao"} click={_ => calcularData(1)} />
                    </div>
                </> || <></>}
            <div className={'slide-up-bottom-div ' + (filtroAberto || showRegistro || showLembrete ? 'margin-r-279' : 'right-5-neg')}>
                <BotaoPadrao icone='icon-Arrow-Up' classe=' btn primary btn-filter btn-scroll-top' idTooltip={"padrao"} click={_ => window.scrollTo(0, 0)} />
            </div>
        </div >
    )
})

const FiltrosTela = (props) => {
    useEffect(() => {
    }, [props.tela])

    return props.tela && props.tela.modulo === 53 ? <>
        <div className='filtro'>
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center'>Ordenamento</span>
            </div >
            <div className='content-filtro'>
                <InputOrdenamento countDisplay={20} value={props.filtrosOff.ordenamento && props.filtrosOff.ordenamento || ''} multiplo={true} label="" name="ordenamento"
                    tamanho='p-0 col-12' placeholder="Selecione" icone="icon-A-Z" {...props} data={props.ordenamento.filter(x => !x.tipoConsulta || x.tipoConsulta === 2)}
                    onChange={value => {
                        props.filtrar(value, "ordenamento")
                    }} />
            </div>
        </div >
        <div className='filtro mb-3'>
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center'>Estabelecimento</span>
            </div>
            <div className='content-filtro'>
                <InputEstabelecimentoSaude
                    placeholder="Estabelecimento Saúde" name="estabelecimento_saude" botaoVisivel={false}
                    tamanho={'p-0 col-12'} multiplo value={props.filtrosOff.estabelecimento_saude} onChangeCombo={(e) => {
                        props.filtrar(e, "estabelecimento_saude")
                    }} minLength={1} />
            </div>
        </div>
        <div className='filtro mb-3'>
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center'>Sócio</span>
            </div>
            <div className='content-filtro'>
                <InputSocio multiplo name="socio" placeholder="Sócio" value={props.filtrosOff.socio} tamanho='p-0 col-12' onChange={e => {
                    props.filtrar(e, "socio")
                }} minLength={1} />
            </div>
        </div>
        <div className='filtro'>
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center'>Período</span>
            </div>
            <div className='content-filtro'>
                <InputIntervaloHorario placeholder="Período" multiplo botaoVisivel={false} obrigatorio value={props.filtrosOff.periodo} name={'periodo'}
                    tamanho={'p-0 col-12'}
                    onChangeValue={(e) => {
                        props.filtrar(e, "periodo")
                    }} minLength={1} />
            </div>
        </div>
    </> : <>
        <div className='filtro'>
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center'>Ordenamento</span>
            </div >
            <div className='content-filtro'>
                <InputOrdenamento countDisplay={20} value={props.filtrosOff.ordenamento && props.filtrosOff.ordenamento || ''} multiplo={true} label="" name="ordenamento"
                    tamanho='p-0 col-12' placeholder="Selecione" icone="icon-A-Z" {...props} data={props.ordenamento.filter(x => !x.tipoConsulta || x.tipoConsulta === 1)}
                    onChange={value => {
                        props.filtrar(value, "ordenamento")
                    }} />
            </div>
        </div >
        <div className='filtro mb-3'>
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center'>Sócio</span>
            </div>
            <div className='content-filtro'>
                <InputSocio multiplo name="socio" placeholder="Sócio" value={props.filtrosOff.socio} tamanho='p-0 col-12' onChange={e => {
                    props.filtrar(e, "socio")
                }} minLength={1} />
            </div>
        </div>
        <div className='filtro'>
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center'>Período</span>
            </div>
            <div className='content-filtro'>
                <InputIntervaloHorario placeholder="Período" multiplo name={'periodo'}
                    tamanho={'p-0 col-12'} botaoVisivel={false} value={props.filtrosOff.periodo}
                    onChangeValue={(e) => {
                        props.filtrar(e, "periodo")
                    }} minLength={1} />
            </div>
        </div>
        <div className='filtro mb-3'>
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center'>Tipo Escala</span>
            </div>
            <div className='content-filtro'>
                <InputEscalaTipo placeholder="Tipo Escala" obrigatorio name={'tipo_escala'} classeTooltip={'tooltip-input-modal'}
                    tamanho={'p-0 col-12'} value={props.filtrosOff.tipo_escala} onChange={e => {
                        props.filtrar(e, "tipo_escala")
                    }} />
            </div>
        </div>
    </>
}

const FiltrosHtml = (props) => {
    const [width] = useWindowSize();
    const [arrayStatus, setArrayStatus] = useState([])

    useEffect(() => {
        Promise.resolve(get('/administracao/statusplantao')).then(val => setArrayStatus(val))
    }, [])

    return (
        <div className={props.modal ? 'agenda-content' : ''}>
            <div className={'filtros'}>
                <div className='filtro'>
                    {width > 1200 && !props.abrirFiltroModal && <button class="close" onClick={() => props.setFiltroAberto(false)}><span aria-hidden="true">×</span></button>}
                    <div className='titulo-filtro row-space-between'>
                        <span className='vertical-center'>Modo Visualização</span>
                    </div>
                </div>
                {
                    <div className='filtro'>
                        <div className='content-filtro'>
                            <ul class="lista-selecao mb-5">
                                <li class="active nav-item">
                                    <a href="#" onClick={() => { props.setFiltroAberto(!(width < 1200)); props.calcularData(0, 1) }}>
                                        <i class="simple-icon-reload"></i>
                                        <span className='span-clicavel'>Dia</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" onClick={() => { props.setFiltroAberto(!(width < 1200)); props.calcularData(0, 2) }}>
                                        <i class="simple-icon-refresh"></i>
                                        <span>Semana</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" onClick={() => { props.setFiltroAberto(!(width < 1200)); props.calcularData(0, 3) }}>
                                        <i class="simple-icon-check"></i>
                                        <span>Mês</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div className='filtro'>
                <div className='titulo-filtro row-space-between'>
                    <span className='vertical-center'>Status</span>
                </div>
                <div className='content-filtro'>
                    <ul class="lista-selecao mb-3 row pl-15">
                        {props.filtrosOff && arrayStatus.map(itemStatus => <li className='p-0 mr-1'>
                            <a href="#" onClick={_ => props.filtarStatus(itemStatus.id)}>
                                <span className={'tag tag-' + itemStatus.descricao + (props.filtrosOff.status && props.filtrosOff.status.findIndex(item => item === itemStatus.id) > -1 ? '' : '-outline') + ' active m-0 p-unset'}>{itemStatus.descricao + ' ( ' + props.dadosStatus.filter(item => item.status_id === itemStatus.id).length + ' )'}</span>
                            </a>
                        </li>)}
                    </ul>
                </div>
            </div>
            {props.children}
            <Formik
                initialValues={props.filtrosOff}
                onSubmit={(values) => props.setFiltrosOff(values)}>
                {propsFormik => (<Form>
                </Form>)}
            </Formik>
        </div>
    )
}

export default AgendaPlantaoLayout
