import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FieldArray, useFormikContext } from "formik";
import Yup, { validaCPF, validaData, periodoData, validaHora, validaEspaco, periodoHora, dataMenorAtual } from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { ModalDuasOpcoes, ModalEscolha } from '../../../../components/Modais';
import { get, salvar, validar, validarComPost } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadraoSimples, InputDatePicker, TextArea, InputTimePicker, InputSwitch, InputValorNovo, InputMascaraSimples, InputDateTimePicker, ControlledInput, InputCurrency, InputPadrao, InputPadraoSimplesAlternativo } from '../../../../components/InputsV2'
import {
    InputProcedimentosContrato, InputEstabelecimentoSaude, InputAcomodacaoContrato, InputTipoAtendimento, InputSocioAtivos, InputIntervaloHorario, InputTipoGuia,
    InputIndicacaoAcidente, InputCoberturaEspecial, InputRegimeAtendimento, InputSaudeOcupacional, InputCaraterAtendimento, InputTipoFaturamento, InputTipoInternacao, InputTipoAtendimentoTiss,
    InputTipoConsulta, InputMotivoEncerramento, InputRegimeInternacao, InputEmpresa, InputPorArray, InputPercentual, InputOperadoraSaudePorContrato, InputTipoAtendimentoEquipe, InputSocioGuia, InputEmpresaGuia,
    InputTipoExecutante,
    InputCirurgiaoCombo,
    InputLoteGuia,
    InputGuiaStatus,
    InputLoteGuiaConferencia
} from '../../../../components/InputsAutoCompleteV2'
import { BotaoPadrao } from '../../../../components/Botoes'
import { convertValueToFloat, formatarMoeda, mensagens } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import InputArquivoS3 from '../../../../components/InputArquivoS3V2'
import { GUIA_STATUS, MODULO } from '../../../../util/Enums'
import { BtnAcao } from '../../../../components/Acoes'
import { formatMoeda, isUrgency, isUrgencyOnHoliday, obterFeriados } from '../../../../util/FuncoesComuns';
import { ref } from 'yup';
import { Overlay, Tooltip } from 'react-bootstrap';
import { FiAlertTriangle } from 'react-icons/fi'
import moment from 'moment';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const CadastroGuia = (props) => {
    let { id } = useParams()

    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [showModalInsercaoMultiplo, setShowModalInsercaoMultiplo] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [dataArquivos, setDataArquivos] = useState(null)
    const [limparArquivos, setLimparArquivos] = useState(false)
    const [qtdInsercao, setQtdInsercao] = useState(0)
    const [showModalInterAgenda, setShowModalInterAgenda] = useState(false)
    const [showModalOpSaude, setShowModalOpSaude] = useState(false)
    const [msgValidacao, setMsgValidacao] = useState(<></>)
    const [disableCampos, setDisableCampos] = useState(true)
    const [dataProcedimento, setDataProcedimento] = useState('')
    const [procedimentoBloqueado, setProcedimentoBloqueado] = useState(true)
    const [totalProcedimentos, setTotalProcedimentos] = useState()
    const [contrato, setContrato] = useState({ contrato_id: 0, tabelas: [] })
    const [contratoConfig, setContratoConfig] = useState([])
    const refAcomodacao = useRef(null)
    const refProcedimentos = useRef(null)
    const refExec = useRef(null)
    const inputFocus = useRef(null)
    const [guiaTipoTiss, setGuiaTipoTiss] = useState()
    const [showModalDataEntrega, setShowModalDataEntrega] = useState(false)
    const [msgValidacaoDataEntrega, setMsgValidacaoDataEntrega] = useState(<></>)
    const [showModalAposSalvar, setShowModalAposSalvar] = useState(false)
    const [avisoSalvar, setAvisoSalvar] = useState()
    const [validarGuia, setValidarGuia] = useState()
    const [feriados, setFeriados] = useState([])
    const conferencia = useLocation()

    const [horaInicial, setHoraInicial] = useState()
    const [horaFinal, setHoraFinal] = useState()
    const [dataProced, setDataProced] = useState()
    const [horaInicialAlterada, setHoraInicialAlterada] = useState(false)
    const [horaFinalAlterada, setHoraFinalAlterada] = useState(false)
    const [dataProcedimentoAlterada, setDataProcimentoALterada] = useState(false)
    const [OperadoraSaudeId, setOperadoraSaude] = useState()
    const [empresa_id, setEmpresa] = useState()
    const [urgenciaProcedimento, setUrgenciaProcedimento] = useState(false)
    const [carregandoAlteracao, setCarregandoAlteracao] = useState(true)
    const [contratoAntigo, setContratoAntigo] = useState()




    const refInternos = useRef(null)

    let validacaoInicial = Yup.lazy(({ executantes_internos, procedimentos }) => Yup.object({
        codigo_barras: Yup.string().nullable().test('Código Barras', 'Código Barras é inválido!', function (value) { return validaCPF(this.parent.codigo_barras) }),
        data_procedimento: Yup.string().nullable().required()
            .test('Data', 'Data inválida!', function (value) { return validaData(this.parent.data_procedimento) })
            .test('Data Procedimento', 'Data Procedimento deve ser menor ou igual a Data Atual!', function (value) { return dataMenorAtual(this.parent.data_procedimento) }),
        empresa: Yup.string().nullable().required(),
        operadora_saude: Yup.string().nullable().required(),
        estabelecimento_saude: Yup.string().nullable().required(),
        nome_beneficiario: Yup.string().nullable().required(),
        data_nascimento: Yup.string().nullable()
            .test('Data', 'Data inválida!', function (value) { return validaData(this.parent.data_nascimento) }),
        hora_inicial: Yup.string().nullable().required()
            .test('Hora Inicial', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_inicial) }),
        hora_final: Yup.string().nullable().required()
            .test('Hora Final', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_final) }),
        arquivo: Yup.string().nullable(),
        acomodacao: Yup.object().nullable().required(),
        tipo_atendimento: Yup.string().nullable().required(),
        data_entrega: Yup.string().nullable()
            .test('Data', 'Data inválida!', function (value) { return validaData(this.parent.data_entrega) }),
        inicio_faturamento: Yup.string().nullable().when('tipo_guia', {
            is: (tipo_guia) => tipo_guia && tipo_guia.value === 4 || tipo_guia && tipo_guia.value === 2,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable()
        }),
        numero_internacao: Yup.string().nullable().when('tipo_guia', {
            is: (tipo_guia) => tipo_guia && tipo_guia.value === 2 || tipo_guia && tipo_guia.value === 4,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable()
        }),
        tipo_internacao: Yup.string().nullable().when('tipo_guia', {
            is: (tipo_guia) => tipo_guia && tipo_guia.value === 2,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable()
        }),
        tipo_atendimento_tiss: Yup.string().nullable().when('tipo_guia', {
            is: (tipo_guia) => tipo_guia && tipo_guia.value === 3,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable()
        }),
        regime_internacao: Yup.string().nullable().when('tipo_guia', {
            is: (tipo_guia) => tipo_guia && tipo_guia.value === 2,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable()
        }),
        indicador_acidente: Yup.string().nullable().when('tipo_guia', {
            is: (tipo_guia) => tipo_guia && (tipo_guia.value === 3 || tipo_guia.value === 1),
            then: Yup.string().required(),
            otherwise: Yup.string().nullable()
        }),
        regime_atendimento: Yup.string().nullable().when('tipo_guia', {
            is: (tipo_guia) => tipo_guia && (tipo_guia.value === 3 || tipo_guia.value === 1),
            then: Yup.string().required(),
            otherwise: Yup.string().nullable()
        }),
        tipo_consulta: Yup.string().nullable().when('tipo_guia', {
            is: (tipo_guia) => tipo_guia && tipo_guia.value === 1,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable()
        }),
        sequencia_hospital: Yup.number().nullable().positive('O número deve ser positivo'),

    }).shape({
        procedimentos: Yup.array()
            .test('Procedimentos', 'Insira ao menos 1 Procedimento', function (value) {
                return this.parent.procedimentos && this.parent.procedimentos[0].procedimento !== undefined
            })
            .of(
                Yup.object().shape({
                    procedimento: Yup.string().nullable(),
                    reducao_acrescimo: Yup.number().nullable().max(999).test('Redução Acréscimo', 'Campo Obrigatório!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '');
                        return !(procedimentos.length - 1 != index && !value)
                    }), // these constraints take precedence
                })
            ),
        executantes_externos: Yup.array()
            .of(
                Yup.object().shape({
                    executante: Yup.object().nullable().required(),
                    tipo_pessoa: Yup.object().nullable().required(),
                    empresa: Yup.object().nullable()
                        .when('tipo_pessoa', {
                            is: (tipo_pessoa) => {
                                return tipo_pessoa && tipo_pessoa.value === 2
                            },
                            then: Yup.object().required(),
                            otherwise: Yup.object()
                        }),
                })
            ),
        executantes_internos: Yup.array()
            .test('Executantes EQUIPE', 'Insira ao menos 1 Profissional Executante', function (value) {
                return this.parent.executantes_internos && this.parent.executantes_internos[0].executante !== undefined
            })
            .of(
                Yup.object().shape({
                    executante: Yup.object().nullable()
                        .test('Socio', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(executantes_internos.length - 1 != index && !value)
                        }),
                    data: Yup.string().nullable()
                        .test('Data', 'Data inválida!', function (value) {
                            return validaData(this.parent.data)
                        })
                        .test('Data', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(executantes_internos.length - 1 != index && !value)
                        }),

                    hora_inicial: Yup.string().nullable()
                        .test('Hora Inicial', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_inicial) })
                        .test('Hora Inicial', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(executantes_internos.length - 1 != index && !value)
                        }),

                    hora_final: Yup.string().nullable()
                        .test('Hora Final', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_final) })
                        .test('Hora Final', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(executantes_internos.length - 1 != index && !value)
                        }),
                    tipo_atendimento: Yup.object().nullable()
                        .test('Tipo Atendimento', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(executantes_internos.length - 1 != index && !value)
                        })
                })
            )
    }))


    const handleValidation = async (propsFormik) => {
        await propsFormik.validateForm()
            .then(errors => {
                propsFormik.setTouched({
                    data_procedimento: false, empresa: false, operadora_saude: false,
                    estabelecimento_saude: false, nome_beneficiario: false, hora_final: false, hora_inicial: false, acomodacao: false,
                    tipo_atendimento: false, procedimentos: [{ procedimento: false, reducao_acrescimo: false }], executantes_internos: [{ executante: false, tipo_pessoa: false, empresa: false }],
                    executantes_externos: [{ executante: false, data: false, hora_inicial: false, hora_final: false, tipo_atendimento: false }]
                })

            })
    };


    useEffect(() => {
        refAcomodacao.current.atualizar()

        // if()
    }, [contrato])

    const validarSubmit = async (values, propsFormik) => {
        let erroOpSaude = false
        setSalvando(true)
        await validar('/faturamento/validarOperadoraSaude', { operadora_saude: values.operadora_saude, data_procedimento: values.data_procedimento }, async (e) => {
            erroOpSaude = false;
        }, (e) => {
            erroOpSaude = true;
            setMsgValidacao(<>{e.response.data.alertas.map(x => x.indexOf('*') > -1 ? <>{x.replace('*', '')}<br /></> : <div className='mb-1'><b>{x}<br /></b></div>)}</>)
            setShowModalOpSaude(true)
            setSalvando(false)
        })

        if (!erroOpSaude) {
            const conferindo = GUIA_STATUS.CONFERIDA === values?.status_conferencia?.value;
            let msgValidacaoGuia = [];

            await validarComPost(`/faturamento/validarGuia`, { json_values: JSON.stringify(values) }, (e) => {
                values.pendente = false;
            },
                (e) => {
                    values.pendente = e?.response?.data?.pendente
                    msgValidacaoGuia.push(<>{e.response.data.alertas.map((x, idx) => x.indexOf('*') > -1 ? <>{x.replace('*', '')}<br /></> : <div className='mb-1'>{idx > 0 ? <br /> : <></>}<b>{x}</b><br /></div>)}</>)
                })

            if (conferindo && dataArquivos.length === 0)
                msgValidacaoGuia.push(<><div className='mb-1'><b>Guia não foi digitalizada<br /></b></div></>)

            if (msgValidacaoGuia.length > 0) {
                setMsgValidacao(msgValidacaoGuia)
                setShowModalInterAgenda(true)
                setSalvando(false)
            } else {
                setSalvando(false)
                Submit(values, propsFormik)
            }
        }
    }

    const funcaoAposSim = (formik) => {
        formik.values.pendente = true
        Submit(formik.values, formik.reseForm)
    }

    const resetInputs = []


    const Submit = (values, propsFormik) => {
        setSalvando(true)
        values.arquivo = dataArquivos
        salvar(
            values,
            '/faturamento/guia',
            (e) => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    setSalvando(false)
                    setSalvarENovo(false)
                    setLimparArquivos(true)
                    setShowModalValidacao(false)
                    setContrato({ contrato_id: 0, tabelas: [] })
                    propsFormik.setFieldValue('hora_inicial', undefined)
                    propsFormik.setFieldValue('hora_final', undefined)
                    setDataProced('')
                    setTimeout(_ => propsFormik.setFieldValue('executantes_internos', [{ executante: undefined, hora_inicial: undefined, hora_final: undefined, tipo_atendimento: { value: 1, label: 'Rotina' } }]), 500)
                    setDataProced('')
                    propsFormik.resetForm({})
                    document.getElementById("codigo_barras").focus()
                    setGuiaTipoTiss(undefined)
                    setShowModalAposSalvar(true)
                    setAvisoSalvar({ guia: e?.guia, paciente: e?.paciente })
                    handleValidation(propsFormik)
                }
                else {

                    if (conferencia?.state?.conferencia) {
                        props.history.push({ pathname: '/faturamento/conferencia/cadastro' })
                    } else if (id) {
                        props.history.push({ pathname: '/faturamento/guia/consulta' })
                    } else
                        props.history.push({ pathname: '/faturamento/guia/consulta', state: { guia: e?.guia, paciente: e?.paciente } })

                }

                setSalvando(false)
            },
            (err) => {
                setSalvando(false)
            })
    }

    useEffect(() => {
        var objListas = {
            procedimentos: [{ procedimento: undefined, quantidade: 1, porcentagem: id ? { value: 3, label: '50%' } : { value: 1, label: '100%' } }],
            executantes_externos: [{ executante: undefined, tipo_pessoa: { value: 2, label: 'Pessoa Jurídica' } }],
            executantes_internos: [{ executante: undefined, hora_inicial: undefined, hora_final: undefined, tipo_atendimento: { value: 1, label: 'Rotina' } }] //aq
        }
        if (id) {
            Promise.resolve(get(`/faturamento/guiaalteracao/${id}`)).then(val => {
                setDataArquivos([...val.arquivo] || [])
                delete val.arquivo

                objListas.executantes_internos = [{ executante: undefined, data: val.data_procedimento, hora_inicial: val.hora_inicial, hora_final: val.hora_final, tipo_atendimento: val.tipo_atendimento }];
                props.setStatus(val.status)
                props.setLote(val.lote)
                setDisableCampos(false)
                setGuiaTipoTiss(val.tipo_guia ? val.tipo_guia.value : undefined)
                val.procedimentos = [...val.procedimentos.map(x => ({ alteracao: true, ...x })), ...objListas.procedimentos];
                setTotalProcedimentos(formatMoeda(val.procedimentos.filter(x => x.procedimento).reduce((partialSum, a) => partialSum + parseFloat(a.valor_total), 0)))
                val.executantes_externos = val?.executantes_externos?.length > 0 ? val.executantes_externos : objListas.executantes_externos;
                val.executantes_internos = [...val.executantes_internos, ...objListas.executantes_internos];

                if (conferencia?.state?.conferencia) {
                    val.status_conferencia = { label: 'Conferida', value: 4 }
                }
                setUrgenciaProcedimento(val.procedimentos[0].urg)

                setValoresIniciais(val)
                setContrato({ contrato_id: val.operadora_saude.contrato_id, tabelas: val.operadora_saude.tabelas })
                obterContratoConfig(val.acomodacao, val.data_procedimento, val.operadora_saude.contrato_id)
                setProcedimentoBloqueado(false)
                setDataProced(val.data_procedimento)
                setHoraInicial(val.hora_inicial)
                setHoraFinal(val.hora_final)
                setOperadoraSaude(val.operadora_saude.operadora_saude_id)
                setEmpresa(val.empresa.value)
                val.inicio_faturamento = val.inicio_faturamento || ''
            })
        } else {
            setDataArquivos([])
            setCarregandoAlteracao(false)
            setValoresIniciais({
                codigo_barras: "",
                data_procedimento: "",
                operadora_saude: "",
                estabelecimento_saude: "",
                guia_principal: "",
                senha: "",
                matricula: "",
                cartao_sus: "",
                nome_beneficiario: "",
                data_nascimento: "",
                acomodacao: "",
                hora_inicial: "",
                hora_final: "",
                cirurgiao: "",
                atendimento_rn: "",
                tipo_atendimento: { value: 1, label: 'Rotina' },
                sequencia_hospital: "",
                procedimentos: objListas.procedimentos,
                executantes_externos: objListas.executantes_externos,
                executantes_internos: objListas.executantes_internos,
                numero_internacao: "",
                inicio_faturamento: "",
                tipo_internacao: "",
                regime_internacao: "",

            })

            setDataProced('')
            setHoraInicial('')
            setHoraFinal('')

            document.getElementById("codigo_barras").focus()
        }

    }, [id])

    useEffect(() => {
        if (valoresIniciais.hora_final)
            setTimeout(_ => setCarregandoAlteracao(false), 3000)
    }, [valoresIniciais])

    const onBlurHora = (name, hora, propsFormik) => {
        function ajustaHora() {
            if (hora.toString().includes('_'))
                return moment({ hour: hora }).format('HH:mm');

            return hora
        }

        let arrayExecutantes = [...propsFormik.values.executantes_internos]
        arrayExecutantes = arrayExecutantes.map(item => {
            if (horaInicialAlterada || horaFinalAlterada) {
                item[name] = ajustaHora()
                item[name + '_changed'] = true

                setHoraInicialAlterada(false)
                setHoraFinalAlterada(false)
            }

            return item
        })

        propsFormik.setFieldValue('executantes_internos', arrayExecutantes);
        if (horaInicialAlterada || horaFinalAlterada)
            setUrgencia(propsFormik, dataProced);
    }

    const setUrgencia = async (propsFormik, data_procedimento) => {
        var inputsBlur = document.getElementsByClassName('input-blur')

        const valores = propsFormik.values
        if (valores.data_procedimento && data_procedimento && valores.hora_inicial && valores.hora_final) {
            let urgencia = false
            if (isUrgencyOnHoliday(feriados, data_procedimento, valores.hora_inicial, valores.hora_final))
                urgencia = true
            else
                urgencia = await isUrgency(data_procedimento, valores.hora_inicial, valores.hora_final)

            let array = propsFormik.values.procedimentos
            array = array.map(ret => {
                ret.urg = urgencia;
                return ret
            });

            setUrgenciaProcedimento(urgencia)

            if (urgencia) {
                propsFormik.setFieldValue(`tipo_atendimento`, { value: 3, label: 'Voluntário' })

            } else {
                propsFormik.setFieldValue(`tipo_atendimento`, { value: 1, label: 'Rotina' })
            }
            propsFormik.setFieldValue('procedimentos[0].urg', urgencia);

            refInternos.current.atualizarAtendimento()
            // inputsBlur.blur()

            for (var i = 0; i < inputsBlur.length; i++) {
                inputsBlur[i].blur();
            }
            refProcedimentos.current.recalcular()
        }
    }

    const onChangeData = (propsFormik, value) => {
        // if (propsFormik.values.executantes_internos && propsFormik.values.executantes_internos.filter(item => item.executante).length === 0) {

        let arrayExecutantes = [...propsFormik.values.executantes_internos]
        arrayExecutantes = arrayExecutantes.map(item => {

            item['data'] = value || undefined
            item['data_changed'] = true


            return item
        })
        propsFormik.setFieldValue('executantes_internos', arrayExecutantes);
        // }
        propsFormik.setFieldValue('inicio_faturamento', moment(propsFormik.values.data_procedimento, 'DD/MM/YYYY').format('DD/MM/YYYY HH:mm'));
        setUrgencia(propsFormik, value);
    }
    useEffect(() => {
        obterFeriados((dados) => {
            setFeriados(dados)
        })
    }, [])

    const obterContratoConfigAsync = async (obj, data_procedimento, contrato_id) => {
        obterContratoConfig(obj, data_procedimento, contrato_id)
    }

    const obterContratoConfig = (obj, data_procedimento, contrato_id) => {
        if (!obj)
            return;
        Promise.resolve(get(`/faturamento/contratoporteanestesicocombo`, { contrato: contrato.contrato_id || contrato_id, acomodacao: obj.value, data_procedimento: dataProcedimento || data_procedimento })).then(val => {
            setContratoConfig(val)
        })
    }

    const LimparContrato = () => {
        setContrato({ contrato_id: 0, tabelas: [] })
    }

    const onBlurCamposTiss = (value, propsFormik) => {
        propsFormik.setFieldValue('numero_internacao', value)

        propsFormik.setFieldValue('numero_principal_sadt', value)

    }


    const validationTipoGuia = (option, propsFormik) => {

        switch (option.value) {
            case 2:
                propsFormik.setFieldTouched('inicio_faturamento', true)
                break;

            default:
                break;
        }
    }

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
            {propsFormik => (<Form>
                <div className={'card mb-20'}>
                    <div className={'card-body'}>
                        <div className='row'>
                            <InputPadraoSimples performance={true} label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                            <InputMascaraSimples
                                mascara="111.111.111-11"
                                tamanho="col-xl-2 col-lg-3 col-md-6"
                                label="Código Barras"
                                name="codigo_barras"
                                id="codigo_barras"
                                type="text"
                                placeholder="Código Barras"
                                autoFocus
                            />
                            <InputDatePicker
                                denyIndex
                                tamanho="col-xl-2 col-lg-3"
                                label="Data do Procedimento"
                                id="data_procedimento"
                                name="data_procedimento" obrigatorio
                                type="text"
                                setarValor={value => {
                                    setDataProcedimento(value)
                                    propsFormik.setFieldValue("data_procedimento", value)
                                    propsFormik.setFieldTouched("data_procedimento", true)
                                    setDisableCampos(false)

                                    if ((value != undefined && dataProced != undefined) && value !== dataProced) {
                                        setDataProced(value)
                                        setDataProcimentoALterada(true)
                                        // onBlurData(propsFormik, value)
                                        onChangeData(propsFormik, value)
                                        obterContratoConfigAsync(propsFormik.values.acomodacao, value)
                                    }
                                }}
                                onChange={value => {
                                    setDataProcedimento(value)
                                    propsFormik.setFieldValue("data_procedimento", value)
                                    if (contratoConfig.length != 0) {
                                        refProcedimentos.current.recalcular()
                                    }
                                    if (value) {
                                        setDisableCampos(false)
                                    } else {
                                        propsFormik.setFieldValue("operadora_saude", null)
                                        setDisableCampos(true)
                                    }
                                }}
                                placeholder="Data do Procedimento"
                            />

                            <InputEmpresa label={'Empresa'} placeholder='Empresa' classeTooltip={'tooltip-input-modal'} array onChange={option => {
                                setEmpresa(option.value)
                                propsFormik.setFieldValue('empresa', option)

                            }}
                                name={`empresa`} tamanho="col-xl-2 col-lg-6 col-md-6" obrigatorio />

                            <InputOperadoraSaudePorContrato data_procedimento={propsFormik.values.data_procedimento} placeholder={disableCampos && 'Selecione a Data do Procedimento' || 'Operadora de Saúde'} disabled={disableCampos} obrigatorio
                                label="Operadora de Saúde" name="operadora_saude" classeTooltip={props.modal && 'tooltip-input-modal'} tamanho="col-xl-3 col-lg-6 col-md-6"
                                cacheOptions={false} atualizar={propsFormik.touched.data_procedimento || false} onChange={option => {

                                    setContratoAntigo(propsFormik?.values?.operadora_saude?.contrato_id)
                                    propsFormik.setFieldTouched('operadora_saude', true)
                                    propsFormik.setFieldValue('operadora_saude', option)
                                    propsFormik.setFieldValue(`procedimentos[${0}].porcentagem`, { value: 1, label: '100%' }) // SETA 100% APENAS NO PRIMEIRO PROCEDIMENTO
                                    propsFormik.setFieldValue(`procedimentos.atualizar`, true) //ATUALIZA O ACRESCIMO REDUCAO 
                                    if (option && option.contrato_id) {
                                        setContrato({ contrato_id: option.contrato_id, tabelas: option.tabelas })
                                    }
                                    else
                                        setContrato({ contrato_id: 0, tabelas: [] })

                                    setProcedimentoBloqueado(!(option && propsFormik.values.acomodacao))
                                    if (option)
                                        setOperadoraSaude(option.operadora_saude_id)
                                    if (option && parseInt(option.contrato_status_id) === 10) {

                                        propsFormik.setFieldValue('nome_beneficiario', option.nome_paciente)
                                        propsFormik.setFieldValue('nome_setado_por_contrato', true)
                                    }
                                    else if (propsFormik.values?.nome_setado_por_contrato) {

                                        propsFormik.setFieldValue('nome_beneficiario', "")
                                    }
                                }
                                } array />
                            <InputEstabelecimentoSaude array label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" classeTooltip={props.modal && 'tooltip-input-modal'} tamanho="col-xl-3 col-lg-6" obrigatorio />
                            <InputPadraoSimples performance={true}
                                tamanho="col-xl-4 col-lg-6"
                                label="Guia Atribuída pela operadora (Guia Principal)"
                                name="guia_principal"
                                type="text"
                                onBlur={event => { propsFormik.setFieldValue('guia_principal', event?.target?.value); onBlurCamposTiss(event?.target?.value, propsFormik) }}
                                placeholder="Guia Atribuída pela operadora(Guia Principal)"
                                maxLength={20}
                            />
                            <InputPadraoSimples performance={true}
                                tamanho="col-xl-2 col-lg-4 col-md-6"
                                label="Senha"
                                name="senha"
                                type="text"
                                placeholder="Senha"
                                maxLength={20}
                            />
                            <InputPadraoSimples performance={true}
                                tamanho="col-xl-3 col-lg-4 col-md-6"
                                label="Carteira/Matrícula"
                                name="matricula"
                                type="text"
                                placeholder="Carteira/Matrícula"
                                maxLength={20}
                            />
                            <InputPadraoSimples performance={true}
                                tamanho="col-xl-3 col-lg-4"
                                label="Cartão Nacional de Saúde"
                                name="cartao_sus"
                                type="text"
                                placeholder="Cartão Nacional de Saúde"
                                maxLength={15}
                            />
                            <InputPadraoSimples performance={true}
                                tamanho="col-xl-4 col-lg-6"
                                label="Nome Social"
                                name="nome_social"
                                type="text"
                                placeholder="Nome Social"
                                maxLength={70}

                            />
                            <InputPadraoSimples
                                tamanho="col-xl-4 col-lg-6"
                                label="Nome Beneficiário"
                                name="nome_beneficiario"
                                type="text" obrigatorio
                                placeholder="Nome Beneficiário"
                                maxLength={70}
                            />
                            <InputDatePicker
                                denyIndex
                                tamanho="col-xl-2 col-lg-4 col-md-6"
                                label="Data de Nascimento"
                                name="data_nascimento"
                                type="text"
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_nascimento", value)
                                }}
                                placeholder="Data de Nascimento"
                            />
                            <InputAcomodacaoContrato ref={refAcomodacao} setarSeForUm={true} obterContratoConfig={obterContratoConfig} setProcedimentoBloqueado={setProcedimentoBloqueado} label='Acomodação' obrigatorio name="acomodacao" contrato={contrato.contrato_id} propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} modal={true}
                                tamanho="col-xl-2 col-lg-5 col-md-6" limparDados={propsFormik.values.operadora_saude ? true : false} isClearable={false}
                                id={id}
                                aposAtualizar={(value) => {
                                    if (value) {
                                        obterContratoConfig(value, valoresIniciais && valoresIniciais.data_procedimento)
                                    }
                                }}
                                onChange={option => {
                                    propsFormik.setFieldTouched('acomodacao', true)
                                    propsFormik.setFieldValue('acomodacao', option)
                                    obterContratoConfig(option, valoresIniciais && valoresIniciais.data_procedimento)
                                    setProcedimentoBloqueado(!(option && propsFormik.values.operadora_saude))
                                    propsFormik.setFieldValue('procedimentos', propsFormik.values.procedimentos.map(x => {
                                        x.alteracao = false; return x
                                    }))
                                }} array />

                            <InputTimePicker
                                denyIndex label="Hora Inicial" name="hora_inicial" type="text" placeholder="Hora Inicial" tamanho={!props.modal && "col-xl-2 col-lg-3 col-md-5"} classeTooltip={props.modal && 'tooltip-input-modal'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("hora_inicial", value)
                                    if ((value != undefined && horaInicial != undefined) && value !== horaInicial) {
                                        setHoraInicial(value)
                                        setHoraInicialAlterada(true)
                                    }
                                }}
                                obrigatorio myBlur={event => { onBlurHora('hora_inicial', horaInicial, propsFormik); }}
                            />
                            <InputTimePicker
                                denyIndex label="Hora Final" name="hora_final" type="text" placeholder="Hora Final" tamanho={!props.modal && "col-xl-2 col-lg-3 col-md-5"} classeTooltip={props.modal && 'tooltip-input-modal'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("hora_final", value)
                                    if ((value != undefined && horaFinal != undefined) && value !== horaFinal) {
                                        setHoraFinal(value)
                                        setHoraFinalAlterada(true)
                                    }
                                }}
                                obrigatorio propsFormik={propsFormik}
                                myBlur={event => {
                                    onBlurHora('hora_final', horaFinal, propsFormik);
                                }}
                            />
                            <InputCirurgiaoCombo tamanho="col-xl-4 col-lg-9 col-md-7" label="Cirurgião" name="cirurgiao" multiplo={false} placeholder="Cirurgião"
                                propsFormik={propsFormik} botaoVisivel={true} modal={true} focusAfter={inputFocus} salvarENovo={salvarENovo} {...props} />
                            <InputSwitch label='Atendimento RN' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch ' name='atendimento_rn' size="md" tamanho={!props.modal && 'col-lg-2 col-md-5'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputTipoAtendimento tamanho="col-xl-2 col-lg-5 col-md-6" label="Tipo de Atend. Guia" name="tipo_atendimento" multiplo={false} placeholder="Tipo de Atend. Guia"
                                propsFormik={propsFormik} botaoVisivel={true} modal obrigatorio />
                            <InputPadraoSimplesAlternativo performance={true}
                                tamanho="col-xl-1 col-lg-5 col-md-6"
                                label="Seq."
                                name="sequencia_hospital"
                                type="number"
                                placeholder="Seq."
                                maxLength={"3"}
                            />
                            <TextArea performance={true} label='Observação' name='observacao' type='text' placeholder='Observação' tamanho={'col-lg-6 col-md-12'} classeTooltip={'tooltip-input-modal'} />
                            <InputDatePicker
                                denyIndex
                                tamanho="col-xl-2 col-lg-4"
                                label="Data Entrega"
                                name="data_entrega"
                                type="text"
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_entrega", value)
                                }}
                                placeholder="Data Entrega"
                            />
                            <br />
                            <div className='col-12'>
                                <h3><span>{'Informação TISS'}</span></h3>
                            </div>
                            <br />
                            <InputTipoGuia tamanho="col-xl-3 col-lg-4" label="Tipo de Guia" name="tipo_guia" multiplo={false} placeholder="Tipo de Guia"
                                propsFormik={propsFormik} botaoVisivel={true} modal onChange={option => {
                                    if (option && option.value) {
                                        validationTipoGuia(option, propsFormik)
                                    }
                                    propsFormik.setFieldValue("tipo_guia", option)
                                    setGuiaTipoTiss(option && option.value ? option.value : undefined)
                                }} />
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 1 ? 'guia-contents' : 'guia_none'}>
                                <InputPadraoSimples performance={true}
                                    tamanho="col-xl-3 col-lg-4"
                                    label="Número Guia Prestador"
                                    name="numero_prestador"
                                    type="text"
                                    placeholder="Número Guia Prestador"
                                    maxLength={20} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 1 ? 'guia-contents' : 'guia_none'}>

                                <InputIndicacaoAcidente label="Indicação Acidente"
                                    placeholder="Indicação Acidente"
                                    name="indicador_acidente"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-4"
                                    obrigatorio={true} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 1 ? 'guia-contents' : 'guia_none'}>
                                <InputCoberturaEspecial label="Indicador de Cobertura Especial"
                                    placeholder="Indicador de Cobertura Especial"
                                    name="cobertura_especial"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-6" />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 1 ? 'guia-contents' : 'guia_none'}>
                                <InputRegimeAtendimento label="Regime atendimento"
                                    placeholder="Regime atendimento"
                                    name="regime_atendimento"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-6"
                                    obrigatorio={true} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 1 ? 'guia-contents' : 'guia_none'}>
                                <InputSaudeOcupacional label="Saúde ocupacional"
                                    placeholder="Saúde ocupacional"
                                    name="saude_ocupacional"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-6" />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 1 ? 'guia-contents' : 'guia_none'}>
                                <InputTipoConsulta
                                    label="Tipo Consulta"
                                    placeholder="Tipo Consulta"
                                    name="tipo_consulta"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-4"
                                    obrigatorio={true} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 2 ? 'guia-contents' : 'guia_none'}>
                                <InputPadraoSimples performance={true}
                                    tamanho="col-xl-3 col-lg-6"
                                    label="Número Guia Prestador"
                                    name="numero_prestador"
                                    type="text"
                                    placeholder="Número Guia Prestador"
                                    maxLength={20} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 2 ? 'guia-contents' : 'guia_none'}>
                                <InputPadrao
                                    tamanho="col-xl-3 col-lg-6"
                                    label="Número Guia Solicitação de Internação"
                                    name="numero_internacao"
                                    type="text"
                                    propsFormik={propsFormik}
                                    placeholder="Número Guia Solicitação de Internação"
                                    maxLength={20}
                                    obrigatorio={true} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 2 ? 'guia-contents' : 'guia_none'}>
                                <InputTipoFaturamento label="Tipo Faturamento"
                                    placeholder="Tipo Faturamento"
                                    name="tipo_faturamento"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-4" />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 2 ? 'guia-contents' : 'guia_none'}>
                                <InputDateTimePicker
                                    tamanho="col-xl-2 col-lg-3"
                                    label="Início Faturamento"
                                    name="inicio_faturamento"
                                    type="text"
                                    setarValor={value => {
                                        propsFormik.setFieldValue("inicio_faturamento", value)
                                    }}
                                    placeholder="Início Faturamento"
                                    propsFormik={propsFormik}
                                    obrigatorio={true} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 2 ? 'guia-contents' : 'guia_none'}>
                                <InputDateTimePicker
                                    tamanho="col-xl-2 col-lg-3"
                                    label="Fim do Faturamento"
                                    name="fim_faturamento"
                                    type="text"
                                    setarValor={value => {
                                        propsFormik.setFieldValue("fim_faturamento", value)
                                    }}
                                    placeholder="Fim do Faturamento" />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 2 ? 'guia-contents' : 'guia_none'}>
                                <InputTipoInternacao label="Tipo Internação"
                                    placeholder="Tipo Internação"
                                    name="tipo_internacao"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-4"
                                    obrigatorio={true} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 2 ? 'guia-contents' : 'guia_none'}>
                                <InputRegimeInternacao label="Regime Internação"
                                    placeholder="Regime Internação"
                                    name="regime_internacao"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-4"
                                    obrigatorio={true} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 2 ? 'guia-contents' : 'guia_none'}>
                                <InputIndicacaoAcidente label="Indicação Acidente"
                                    placeholder="Indicação Acidente"
                                    name="indicador_acidente"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-4" />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 2 ? 'guia-contents' : 'guia_none'}>
                                <InputMotivoEncerramento label="Motivo Encerramento"
                                    placeholder="Motivo Encerramento"
                                    name="motivo_encerramento"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-6" />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 3 ? 'guia-contents' : 'guia_none'}>
                                <InputPadraoSimples performance={true}
                                    tamanho="col-xl-3 col-lg-4"
                                    label="Número Guia Prestador"
                                    name="numero_prestador"
                                    type="text"
                                    placeholder="Número Guia Prestador"
                                    maxLength={20} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 3 ? 'guia-contents' : 'guia_none'}>
                                <InputPadrao
                                    tamanho="col-xl-3 col-lg-4"
                                    label="Número Guia Principal"
                                    placeholder="Número Guia Principal"
                                    name="numero_principal_sadt"
                                    type="text"
                                    maxLength={20} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 3 ? 'guia-contents' : 'guia_none'}>
                                <InputCoberturaEspecial
                                    label="Indicador de Cobertura Especial"
                                    placeholder="Indicador de Cobertura Especial"
                                    name="cobertura_especial"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-6" />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 3 ? 'guia-contents' : 'guia_none'}>
                                <InputTipoAtendimentoTiss label="Tipo Atendimento"
                                    placeholder="Tipo Atendimento"
                                    name="tipo_atendimento_tiss"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-4"
                                    obrigatorio={true} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 3 ? 'guia-contents' : 'guia_none'}>
                                <InputIndicacaoAcidente label="Indicação Acidente"
                                    placeholder="Indicação Acidente"
                                    name="indicador_acidente"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-4"
                                    obrigatorio={true} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 3 ? 'guia-contents' : 'guia_none'}>
                                <InputTipoConsulta label="Tipo Consulta"
                                    placeholder="Tipo Consulta"
                                    name="tipo_consulta"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-4" />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 3 ? 'guia-contents' : 'guia_none'}>
                                <InputMotivoEncerramento label="Motivo Encerramento"
                                    placeholder="Motivo Encerramento"
                                    name="motivo_encerramento"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-6" />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 3 ? 'guia-contents' : 'guia_none'}>
                                <InputRegimeAtendimento label="Regime atendimento"
                                    placeholder="Regime atendimento"
                                    name="regime_atendimento"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-6"
                                    obrigatorio={true} />
                            </div>
                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 3 ? 'guia-contents' : 'guia_none'}>
                                <InputSaudeOcupacional label="Saúde ocupacional"
                                    placeholder="Saúde ocupacional"
                                    name="saude_ocupacional"
                                    propsFormik={propsFormik}
                                    classeTooltip={'tooltip-input-modal'}
                                    tamanho="col-xl-3 col-lg-6" />
                            </div>

                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 4 ? 'guia-contents' : 'guia_none'}>

                                <InputPadraoSimples performance={true}
                                    tamanho="col-xl-3 col-lg-6"
                                    label="Número Guia Prestador"
                                    name="numero_prestador"
                                    type="text"
                                    placeholder="Número Guia Prestador"
                                    maxLength={20} />

                            </div>

                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 4 ? 'guia-contents' : 'guia_none'}>

                                <InputPadrao
                                    tamanho="col-xl-3 col-lg-6"
                                    label="Número Guia Solicitação de Internação"
                                    name="numero_internacao"
                                    type="text"
                                    propsFormik={propsFormik}
                                    placeholder="Número Guia Solicitação de Internação"
                                    maxLength={20}
                                    obrigatorio={true} />

                            </div>


                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 4 ? 'guia-contents' : 'guia_none'}>

                                <InputDateTimePicker
                                    tamanho="col-xl-2 col-lg-3"
                                    label="Início Faturamento"
                                    name="inicio_faturamento"
                                    type="text"
                                    setarValor={value => {
                                        propsFormik.setFieldValue("inicio_faturamento", value)
                                    }}
                                    placeholder="Início Faturamento"
                                    propsFormik={propsFormik}
                                    obrigatorio={true} />

                            </div>

                            <div className={guiaTipoTiss != undefined && guiaTipoTiss === 4 ? 'guia-contents' : 'guia_none'}>

                                <InputDateTimePicker
                                    tamanho="col-xl-2 col-lg-3"
                                    label="Fim do Faturamento"
                                    name="fim_faturamento"
                                    type="text"
                                    setarValor={value => {
                                        propsFormik.setFieldValue("fim_faturamento", value)
                                    }}
                                    placeholder="Fim do Faturamento" />

                            </div>
                        </div>
                    </div>
                </div>
                <CadastroProcedimentoGuia
                    LimparContrato={LimparContrato}
                    carregandoAlteracao={carregandoAlteracao}
                    contratoAntigo={contratoAntigo}
                    ref={refProcedimentos}
                    contratoConfig={contratoConfig}
                    contrato={contrato}
                    totalProcedimentos={totalProcedimentos}
                    abrirModal={true} setAbrirModal={() => { }}
                    abrirCadastro={props.abrirModal}
                    itemAlterar={{}} propsFormik={propsFormik} procedimentoBloqueado={procedimentoBloqueado}
                    urgenciaProcedimento={urgenciaProcedimento}
                />
                <CadastroExecutantesExternos ref={refExec} alteracao={false} propsFormik={propsFormik} disabled={disableCampos} data_socio={dataProcedimento} id={id} refInternos={refInternos} />
                <CadastroExecutantesInternos ref={refInternos} alteracao={false} disabled={disableCampos} data_socio={dataProcedimento} id={id} feriados={feriados} />

                <div className="mt-40">
                    <HeaderCadastro
                        titulo={'Anexar Arquivos'}
                        classeChildren='space-between-100' >
                    </HeaderCadastro>
                </div>
                <div className="card mb-20">
                    <div className="card-body">
                        {dataArquivos != null ?
                            <InputArquivoS3
                                name='arquivo'
                                placeholder='Arquivo'
                                tamanho='col-12'
                                diretorioS3='guia/DDMMYYYY/'
                                modulo={MODULO["/faturamento/guia"]}
                                tamanhoMinimo={0}
                                tamanhoMaximoEmMB={300}
                                setDataArquivos={setDataArquivos}
                                dataArquivos={dataArquivos != null ? dataArquivos : []}
                                setIsSubmit={dataArquivos.length > 0 ? setSalvando : () => { }}
                                limparCampos={limparArquivos}
                            /> : <></>}
                    </div>
                </div>
                {id != null ?
                    <>
                        <div className="mt-40">
                            <HeaderCadastro
                                titulo={'Conferência'}
                                classeChildren='space-between-100' >
                            </HeaderCadastro>
                        </div>
                        <div className="card mb-20">
                            <div className="card-body">

                                <div className='row'>
                                    <InputGuiaStatus label="Status" name="status_conferencia" placeholder="Status" propsFormik={propsFormik} tamanho={'col-lg-3'}
                                        modal={true} focusAfter={inputFocus} salvarENovo={salvarENovo} {...props} cadastroRapido />
                                    <InputLoteGuiaConferencia label="Lote" name="lote_conferencia" propsFormik={propsFormik} tamanho={'col-lg-3'}
                                        classeTooltip={'tooltip-input-modal'} placeholder="Lote" dados={{ contrato_id: contrato.contrato_id, empresa_id: empresa_id, operadora_saude_id: OperadoraSaudeId }} />
                                    <InputPadrao label='Justificativa' name='justificativa_conferencia' type='text' placeholder='Justificativa' tamanho={'col-xl-6 col-sm-6'} classeTooltip={'tooltip-input-modal'} />
                                </div>


                            </div>
                        </div>
                    </>
                    : <></>}
                <div className='separator mt-40 mb-5'></div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
                <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                    esconderFechar mensagem={mensagemModal}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
                <ModalEscolha showModal={showModalInsercaoMultiplo} setShowModal={setShowModalInsercaoMultiplo} esconderFechar mensagem={'Selecione uma opção:'}
                    confirmar={true} funcaoConfirmar={() => { validarSubmit(propsFormik.values, propsFormik, 3); setShowModalInsercaoMultiplo(false); }}
                    funcaoCancelar={() => { setShowModalInsercaoMultiplo(false); setSalvando(false) }}>
                    <ul class="lista-modal text-center width-100">
                        <div className='mt-1' />
                        <li className={propsFormik.values.tipo_insercao && propsFormik.values.tipo_insercao === 2 ? 'excluir-active' : ''} onClick={_ => propsFormik.setFieldValue('tipo_insercao', 2)}>
                            <div className='mb-1 li-titulo'>Criar {qtdInsercao} ocorrências.</div>
                        </li>
                        <div className='mt-1' />
                        <li className={!propsFormik.values.tipo_insercao || propsFormik.values.tipo_insercao === 1 ? 'excluir-active' : ''} onClick={_ => propsFormik.setFieldValue('tipo_insercao', 1)}>
                            <div className='mb-1 li-titulo'>Apenas uma ocorrência.</div>
                        </li>
                    </ul>
                </ModalEscolha>

                <ModalDuasOpcoes showModal={showModalInterAgenda} setShowModal={setShowModalInterAgenda}
                    funcaoOpcao2={() => {
                        setShowModalInterAgenda(false); propsFormik.values.status_id = 3;
                        // propsFormik.values.pendente = true;
                        Submit(propsFormik.values, propsFormik);
                    }}
                    funcaoOpcao1={() => { setSalvando(false); setShowModalInterAgenda(false) }}
                    esconderFechar mensagem={<>{<>{'Validações:'} <br /><br /></>} {msgValidacao}<br /></>}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />

                <ModalDuasOpcoes showModal={showModalOpSaude} setShowModal={setShowModalOpSaude}
                    funcaoOpcao2={() => { setShowModalOpSaude(false); propsFormik.values.status_id = 3; Submit(propsFormik.values, propsFormik); }}
                    funcaoOpcao1={() => { setSalvando(false); setShowModalOpSaude(false) }}
                    esconderFechar mensagem={<>{<>{'Validações:'} <br /><br /></>} {msgValidacao}<br /></>}
                    textoOpcao1={'Fechar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'} />
                <ModalDuasOpcoes showModal={showModalDataEntrega} setShowModal={setShowModalDataEntrega}
                    funcaoOpcao2={() => { setShowModalDataEntrega(false); propsFormik.values.status_id = 3; Submit(propsFormik.values, propsFormik); }}
                    funcaoOpcao1={() => { setSalvando(false); setShowModalDataEntrega(false) }}
                    esconderFechar mensagem={<>{<>{'Validações:'} <br /><br /></>} {msgValidacaoDataEntrega}<br /></>}
                    textoOpcao1={'Fechar'}
                    textoOpcao2={'Continuar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'} />

                <ModalDuasOpcoes showModal={showModalAposSalvar} setShowModal={setShowModalAposSalvar}
                    funcaoOpcao1={() => { setShowModalAposSalvar(false); }}
                    esconderFechar mensagem={<>
                        Guia:
                        <br />
                        <br />
                        <b>{avisoSalvar?.guia} </b> - {avisoSalvar?.paciente}
                        <br />

                    </>}
                    textoOpcao1={'OK'} esconderOpcao2
                    classeOpcao1={'btn-sm btn-primary-modal primary'} />
            </Form >)
            }
        </Formik >
    );
}

const subtitulo = (status, lote) => {
    return (
        <div className='guia-legenda'>
            <span className='legenda' style={{ background: status && status.cor }}>{status.descricao}</span>
            <span className='legenda' style={{ background: lote && lote.cor }} > {lote?.descricao}</span>
        </div>

    )
}

const Cadastro = (props) => {

    const [status, setStatus] = useState([])
    const [lote, setLote] = useState([])
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Faturamento</Link>,
        () => <Link className="link-active" to={'/faturamento/guia/consulta'}>Guia</Link>,
    ]
    return (<>
        <HeaderCadastro titulo={"Cadastro de Guia"} link={links} infoAdicional={subtitulo(status, lote)} />
        <CadastroGuia {...props} setStatus={setStatus} setLote={setLote} />
    </>
    )
}

const CadastroProcedimentoGuia = React.forwardRef(({ contrato, urgenciaProcedimento, ...props }, ref) => {
    const [totalProcedimentos, setTotalprocedimentos] = useState('0,00')
    const refProcedimentos = useRef(null)
    const [ultimoContrato, setUltimoContrato] = useState(0)
    const [procedimentosContrato, setProcedimentosContrato] = useState([])
    const propsFormik = useFormikContext();
    useEffect(() => {
        if (props.totalProcedimentos)
            setTotalprocedimentos(props.totalProcedimentos)
    }, [props.totalProcedimentos])

    useEffect(() => {
        if (propsFormik.touched.acomodacao === true || propsFormik.touched.operadora_saude === true)
            recalcular();

    }, [props.contratoConfig])



    useEffect(() => {

        if (!props.carregandoAlteracao) {

            if (props.contratoAntigo != undefined && props.contratoAntigo !== contrato?.contrato_id)
                limparApenasProcedimentos()
            else
                atualizarProcedimentos();
        }

    }, [contrato, props.contratoAntigo])

    // useEffect(() => {
    //     //Limpar procedimento somente após troca de contrato
    //     let contrato_id = propsFormik.initialValues?.operadora_saude?.contrato_id

    //     if (!props.carregandoAlteracao)
    //         if (contrato_id !== contrato?.contrato_id)
    //             limparApenasProcedimentos()
    //         else
    //             atualizarProcedimentos();


    // }, [contrato])

    useEffect(() => {
        if (!propsFormik.values.operadora_saude) {
            LimparProcedimentos()
        }
    }, [propsFormik.values.operadora_saude])

    useEffect(() => {
        if (ultimoContrato != contrato.contrato_id) {

            Promise.resolve(get('/faturamento/todosProcedimentosComboGuia', { pesquisa: '', contrato: contrato.contrato_id }, null, null)).then(value => {
                setProcedimentosContrato(value)
            })
            setUltimoContrato(contrato.contrato_id)
        }
    }, [contrato.contrato_id])

    const LimparProcedimentos = _ => {

        const procedimentos = propsFormik.values.procedimentos

        if (procedimentos && procedimentos.length > 1) {
            for (let i = 0; i < procedimentos.length; i++) {
                procedimentos.shift()
            }
            props.LimparContrato()
            propsFormik.setFieldValue('acomodacao', undefined);
        }
        return procedimentos
    }

    const limparApenasProcedimentos = _ => {
        setTotalprocedimentos('0,00')
        propsFormik.setFieldValue('procedimentos', [{ procedimento: undefined, quantidade: 1, porcentagem: { value: 1, label: '100%' }, urg: urgenciaProcedimento }])
    }

    const atualizarProcedimentos = async () => {

        if (propsFormik.values.procedimentos && propsFormik.values.procedimentos.length) {
            let listaProcedimentos = propsFormik.values.procedimentos.filter(x => x.procedimento && x.procedimento.value)
            listaProcedimentos = listaProcedimentos.map(x => x.procedimento.codigo)

            try {
                const val = await get(`/faturamento/obterProcedimentosPorContrato`, { contrato: contrato.contrato_id, procedimentos: listaProcedimentos });

                if (val) {
                    const procedimentos = propsFormik.values.procedimentos;

                    const novosProcedimentos = procedimentos.filter(x => x.procedimento && val.findIndex(y => y.codigo === x.procedimento.codigo) > -1).map(x => {
                        const idx = val.findIndex(y => y.codigo === x.procedimento.codigo)
                        const novoProcedimento = { ...val[idx] }

                        x.procedimento = novoProcedimento
                        x.porte = val[idx].porte_anestesico

                        if (propsFormik.touched.operadora_saude === true) {
                            if (val[0].acrescimo_reducao === null) {
                                x.reducao_acrescimo = 100
                            } else
                                x.reducao_acrescimo = val[idx].acrescimo_reducao
                        }
                        return x
                    })
                    novosProcedimentos.push(procedimentos[procedimentos.length - 1])
                    // novosProcedimentos[0].porcentagem = { value: 1, label: '100%' }
                    propsFormik.setFieldValue('procedimentos', novosProcedimentos)
                }
            } catch (error) {
                console.log('ERRO AO ATUALIZAR PROCEDIMENTOS')
            }
        }
    }

    const recalcular = () => {

        const procedimentos = propsFormik.values.procedimentos
        if (procedimentos) {
            let procedimentosNovo = []
            for (let index = 0; index < procedimentos.length; index++) {
                procedimentosNovo.push(metodoCalcular([...procedimentos], index))
            }
        }
    }
    if (ref) {
        ref.current = {
            recalcular: () => recalcular(propsFormik.values.procedimentos),
            limparProced: () => LimparProcedimentos()
        }
    }


    const calcularProcedimento = (procedimentos, index) => {
        const procedimentosNovo = metodoCalcular([...procedimentos], index)
        propsFormik.setFieldValue('procedimentos', procedimentosNovo)
    }

    const metodoCalcular = useCallback((procedimentos, index) => {
        if (!procedimentos[index].procedimento)
            return
        const baseCalculoProced = parseInt(procedimentos[index].procedimento.base_calculo) === 3 || procedimentos[index].procedimento.tabela_auxiliar === true
        if ((!props.contratoConfig || !props.contratoConfig.length) && !baseCalculoProced) {
            mostrarToast('erro', 'Contrato não encontrado!')
            return
        }

        const idx = props.contratoConfig.findIndex(x => parseInt(x.porte) === parseInt(procedimentos[index].procedimento.porte_anestesico) && parseInt(x.tabela) === procedimentos[index].procedimento.tabela_id)
        let dados = baseCalculoProced ? {
            ...props.contratoConfig[0],
            porte: procedimentos[index].procedimento.porte_anestesico,
            valor_porte: procedimentos[index].procedimento.valor_porte,
            reducao_acrescimo: procedimentos[index].procedimento.reducao_acrescimo,
        } : props.contratoConfig[idx]

        const porc_procedimento = procedimentos[index].porcentagem && procedimentos[index].porcentagem.label.replace('%', '') || 100
        const reducao_acrescimo = procedimentos[index].reducao_acrescimo || dados && dados.acrescimo_reducao || 100
        const quantidade = 1
        const urgencia = procedimentos[0].urg
        const porc_acomodacao = dados && dados.percentual || props.contratoConfig && props.contratoConfig[0]?.percentual || 100
        const porc_urgencia = dados && dados.urgencia || props.contratoConfig && props.contratoConfig[idx]?.urgencia || 30
        const valor_porte = (dados && parseFloat(dados.valor_porte) && !procedimentos[index]?.procedimento?.value?.toString()?.includes('UPD')) ? convertValueToFloat(dados.valor_porte) : convertValueToFloat(procedimentos[index].procedimento.valor_porte)
        const valor_consulta = dados && !procedimentos[index]?.procedimento?.value?.toString()?.includes('UPD') ? dados.valor_consulta || props.contratoConfig && props.contratoConfig[0].valor_consulta : parseFloat(procedimentos[index].valor?.toString().replace('R$', '').replace(/\./g, '').replace(',', '.').trim())
        let resultado

        if (dados && dados.acomodacao === '38' && valor_consulta || props.contratoConfig && props.contratoConfig[0].acomodacao === '38' && valor_consulta) {
            resultado = (valor_consulta * quantidade) * (porc_acomodacao / 100) * (porc_procedimento / 100)
            urgencia && (resultado += (resultado * porc_urgencia / 100))

            resultado *= (reducao_acrescimo / 100)

        } else {
            resultado = (valor_porte * quantidade) * (porc_acomodacao / 100) * (porc_procedimento / 100)
            urgencia && (resultado += (resultado * porc_urgencia / 100))

            resultado *= (reducao_acrescimo / 100)
        }

        procedimentos[index].porte = dados && dados.porte ? parseFloat(dados.porte) : parseFloat(procedimentos[index].procedimento.porte_anestesico)
        procedimentos[index].quantidade = 1
        procedimentos[index].reducao_acrescimo = procedimentos && procedimentos[index].reducao_acrescimo
        procedimentos[index].valor = formatMoeda(valor_porte)
        procedimentos[index].valor_total = resultado.toFixed(2)
        procedimentos[index].valor_total_formatado = formatMoeda(resultado)
        procedimentos[index].porcentagem_acm = porc_acomodacao
        procedimentos[index].urgencia_porcentagem = porc_urgencia
        procedimentos[index].urg = urgencia

        setTotalprocedimentos(formatMoeda(procedimentos.filter(x => x.procedimento).reduce((partialSum, a) => partialSum + parseFloat(a.valor_total), 0)))

        if (!procedimentos[index].porcentagem)
            procedimentos[index].porcentagem = { value: 1, label: '100%' }

        return procedimentos;
    }, [props.contratoConfig])

    const onChangeProced = useCallback(async (option, propsFormik, arrayHelpers, index) => {
        const arrayProcedimentos = [...propsFormik.values.procedimentos]

        propsFormik.values.procedimentos[index].reducao_acrescimo = (props.contratoConfig && props.contratoConfig[0] && props.contratoConfig[0].acrescimo_reducao) || 100
        if (option) {
            const valorAnterior = { ...propsFormik.values.procedimentos[index] }

            arrayProcedimentos[index].tabela_auxiliar = option?.tabela_auxiliar
            arrayProcedimentos[index].procedimento = option
            if (!arrayProcedimentos[index].quantidade)
                arrayProcedimentos[index].quantidade = 1



            await calcularProcedimento(arrayProcedimentos, index)

            if (!valorAnterior.procedimento || !valorAnterior.procedimento.value)
                adicionarLinha(arrayHelpers, propsFormik)
        }
        else {
            arrayProcedimentos[index].procedimento = undefined
            propsFormik.setFieldValue('procedimentos', arrayProcedimentos);
        }
    }, [props.contratoConfig])

    const onChangeValor = useCallback((propsFormik) => {
        const procedimentos = propsFormik.values.procedimentos
        let valorTotal = procedimentos.map(x => convertValueToFloat(x.valor_total || 0)).reduce((ant, atual) => ant + atual, 0);
        setTotalprocedimentos(formatMoeda(valorTotal))
    }, [props.contratoConfig])

    const adicionarLinha = useCallback((arrayHelpers, propsFormik) => {
        const arrayProced = propsFormik.values.procedimentos;
        if (arrayProced && arrayProced.findIndex(x => !x.procedimento || !x.procedimento.value || !x.quantidade) === -1) {
            let objProcedimento;
            if (propsFormik.values.procedimentos.length >= 1)
                objProcedimento = { procedimento: undefined, quantidade: 1, porcentagem: { value: 3, label: '50%' } }
            else
                objProcedimento = { procedimento: undefined, quantidade: 1, porcentagem: { value: 1, label: '100%' } }

            arrayHelpers.insert(propsFormik.values.procedimentos.length, objProcedimento)
        }
    }, [])

    const removerLinha = useCallback((arrayHelpers, propsFormik, index) => {
        const arrayProced = [...propsFormik.values.procedimentos];
        if (arrayProced[index].procedimento && arrayProced[index].procedimento.value) {
            // arrayHelpers.remove(index)
            propsFormik.values.procedimentos.splice(index, 1)
        }

        if (propsFormik.values.procedimentos.length === 1) {
            propsFormik.setFieldValue('procedimentos', [{ procedimento: undefined, quantidade: 1, porcentagem: { value: 1, label: '100%' } }])
        }

        if (arrayProced.length === 1 && arrayProced[index].procedimento && arrayProced[index].procedimento.value) {
            arrayHelpers.insert(propsFormik.values.procedimentos.length, { procedimento: undefined, quantidade: 1 })
        }

        setTotalprocedimentos(formatMoeda(propsFormik.values.procedimentos.filter(x => x.procedimento).reduce((partialSum, a) => partialSum + parseFloat(a.valor_total), 0)))
    }, [])
    const inputValorProcedimentos = useRef([]);
    return (
        <>
            <div className="mt-40">
                <HeaderCadastro
                    titulo={'Procedimentos'}
                    classeChildren='space-between-100' >
                </HeaderCadastro>
            </div>
            <div className="card mb-20">
                <div className="card-body" ref={refProcedimentos}>
                    <FieldArray name="procedimentos">
                        {(arrayHelpers => (
                            <>
                                {propsFormik.values.procedimentos ?
                                    propsFormik.values.procedimentos.map((item, index) => <div className='modal-body-cadastro row mr-0 ml-0'>
                                        <div className='row col-sm-11 col-xl-4 col-lg-6 col-md-8'>
                                            <div className={"form-group col-1 pl-0 text-center"}>
                                                <label htmlFor={props.id || props.name}>{index === 0 ? '#' : undefined}</label>
                                                <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{index + 1}</span></div>
                                            </div>

                                            <InputProcedimentosContrato dataProcedimentos={procedimentosContrato} contrato={contrato.contrato_id} label={index === 0 ? 'Procedimento' : undefined} name={`procedimentos[${index}].procedimento`} isDisabled={props.procedimentoBloqueado}
                                                tamanho="col-11 pl-0" {...props} classeTooltip={'tooltip-input-modal'} modal={true} isClearable={false}
                                                onChange={(option) => onChangeProced(option, propsFormik, arrayHelpers, index)} className={'procedimento-linha'} cacheOptions={false}
                                                placeholder={props.procedimentoBloqueado && 'Selecione a Operadora e Acomodação' || 'Procedimento'} obrigatorio />
                                        </div>

                                        <div className='row col-sm-11 col-xl-7 col-lg-11 col-md-12 pr-0 mr-2'>
                                            <div className={"form-group col-sm-1 col-xl-1 col-lg-1 col-md-2 pr-0 text-center"}>
                                                <label htmlFor={props.id || props.name}>{index === 0 ? 'Porte' : undefined}</label>
                                                <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{propsFormik.values.procedimentos[index].porte}</span></div>
                                            </div>

                                            <div className={"form-group col-8 col-xl-2 col-lg-2 col-md-3 pr-0 text-center"}>
                                                <label htmlFor={props.id || props.name}>{index === 0 ? 'Valor Porte' : undefined}</label>
                                                <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{propsFormik.values.procedimentos[index].valor}</span></div>
                                            </div>

                                            <div className={"form-group col-8 col-xl-1 col-lg-1 col-md-2 pr-0 text-center"}>
                                                <label htmlFor={props.id || props.name}>{index === 0 ? 'Acm(%)' : undefined}</label>
                                                <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{((propsFormik.values.procedimentos[index].porcentagem_acm || '') + '').replace('.', ',')}</span></div>
                                            </div>

                                            <InputPercentual label={index === 0 ? 'Perc.' : undefined} placeholder='Perc.' classeTooltip={'tooltip-input-modal'} isClearable={false}
                                                name={`procedimentos[${index}].porcentagem`} propsFormik={propsFormik} tamanho="col-sm-2 col-xl-2 col-lg-2 col-md-3 pr-0"
                                                onChange={option => { propsFormik.values.procedimentos[index].porcentagem = option; calcularProcedimento(propsFormik.values.procedimentos, index) }}
                                                isDisabled={!propsFormik.values.procedimentos[index].procedimento} />

                                            <InputPadraoSimples label={index === 0 ? 'Red./Acresc.' : undefined} name={`procedimentos[${index}].reducao_acrescimo`} type='number'
                                                placeholder='Red./Acresc.' classeTooltip={'tooltip-input-modal'} tamanho="col-8 col-xl-1 col-lg-1 col-md-2 pr-0"
                                                onChange={option => {
                                                    propsFormik.values.procedimentos[index].reducao_acrescimo = option.target.value
                                                    calcularProcedimento(propsFormik.values.procedimentos, index)
                                                }}
                                                disabled={!propsFormik.values.procedimentos[index].procedimento} />

                                            <InputSwitch label={index === 0 ? 'Urg' : undefined} checked={propsFormik.values.procedimentos[index].procedimento && propsFormik.values.procedimentos[0].urg} quebrarLinha
                                                propsFormik={propsFormik} classeLabel={(index === 0 ? '' : 'justify-center-vertical')} classeDiv='form-group ajusta-switch'
                                                name={`procedimentos[${index}].urg`} size="md" tamanho={!props.modal && 'col-lg-2 col-md-2 pr-0'} classeTooltip={props.modal && 'tooltip-input-modal'}
                                                onChange={option => {
                                                    let array = propsFormik.values.procedimentos
                                                    array.map(ret => ret.urg = option);
                                                    recalcular(propsFormik.values.procedimentos)
                                                }} />

                                            <InputPadraoSimples label={index === 0 ? 'Qtde' : undefined} disabled name={`procedimentos[${index}].quantidade`} type='number'
                                                placeholder='Qtde' classeTooltip={'tooltip-input-modal'} tamanho="col-8 col-xl-1 col-lg-1 col-md-2 pr-0" />
                                            <InputCurrency disabled={true} onUpdate={val => {
                                                let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                inputValorProcedimentos.current[index] = valor
                                            }}
                                                label={index === 0 ? 'Valor R$' : undefined} name={`procedimentos[${index}].valor_total`}
                                                value={inputValorProcedimentos.current[index] || propsFormik.values.procedimentos[index].valor_total_formatado || propsFormik.values.procedimentos[index].valor_total}
                                                classeTooltip={'tooltip-input-modal'}
                                                propsFormik={propsFormik}
                                                onBlur={(e) => {
                                                    if (inputValorProcedimentos.current[index] || inputValorProcedimentos.current[index] === 0) {
                                                        propsFormik.values.procedimentos[index].valor_total = formatarMoeda(inputValorProcedimentos.current && inputValorProcedimentos.current[index].toString());
                                                        propsFormik.values.procedimentos[index].valor_total_formatado = inputValorProcedimentos.current && inputValorProcedimentos.current[index].toString();
                                                        propsFormik.values.procedimentos[index].valor = formatarMoeda(inputValorProcedimentos.current[index] && inputValorProcedimentos.current[index].toString().trim())
                                                        onChangeValor(propsFormik)

                                                        if (propsFormik.values.procedimentos[index].procedimento)
                                                            propsFormik.values.procedimentos[index].procedimento.value = propsFormik.values.procedimentos[index].procedimento.value + 'UPD'

                                                    }
                                                    inputValorProcedimentos.current[index] = null;
                                                }}
                                                classDiv='col-8 col-xl-2 col-lg-2 col-md-2 text-center pr-0' />
                                        </div>

                                        <div className='col-4 col-xl-1 col-lg-1 col-md-2 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right'>
                                            <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, propsFormik, index)} />
                                        </div>
                                    </div>)
                                    :
                                    <></>}
                            </>
                        ))}
                    </FieldArray>
                    {propsFormik.touched.procedimentos && propsFormik.errors.procedimentos && !Array.isArray(propsFormik.errors.procedimentos) ?
                        <Overlay target={refProcedimentos.current} show={true} placement="bottom">
                            {props2 => {
                                return (
                                    <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                        <div className="error">
                                            <FiAlertTriangle className="icone" /> <span>{propsFormik.errors.procedimentos}</span></div>
                                    </Tooltip>)
                            }}
                        </Overlay> : <></>}
                    <div className='modal-body-cadastro row mr-0 ml-0'>
                        <div className={"col-11 text-right vertical-center"}>
                            <span>Total: <span className='ml-1'><b>{totalProcedimentos}</b></span></span>
                        </div>
                    </div>
                </div>
            </div>
        </>)
})

const CadastroExecutantesExternos = React.forwardRef((props, ref) => {
    const [atualizarEmpresa, setAtualizarEmpresa] = useState(false)
    const refEmpresa = useRef(null)
    const propsFormik = useFormikContext();

    const atualizar = useCallback((data) => {
        if (propsFormik.values.executantes_externos) {
            const arrayExecutantes = [...propsFormik.values.executantes_externos]
            funcAtualizarEmpresa({ socioId: arrayExecutantes[0].executante ? arrayExecutantes[0].executante.value : undefined, data: data, tipoPessoa: arrayExecutantes[0].tipo_pessoa ? arrayExecutantes[0].tipo_pessoa.value : undefined })
        }
    }, [])

    if (ref)
        ref.current = {
            atualizar: atualizar
        }

    const onChangeSocio = useCallback((option, propsFormik, arrayHelpers, index) => {
        const arrayExecutantes = [...propsFormik.values.executantes_externos]

        if (option && arrayExecutantes[index].executante && option.value === arrayExecutantes[index].executante.value) {
            propsFormik.setFieldValue(`executantes_externos[${index}].executante`, undefined);
            setTimeout(() => {
                propsFormik.setFieldValue(`executantes_externos[${index}].executante`, option);
            }, 0)
        } else if (option) {
            propsFormik.setFieldValue(`executantes_externos[${index}].executante`, option);
            if (propsFormik.values.executantes_internos.filter(item => item.executante).length === 0) {
                onChangeSocioInterno(option, propsFormik, arrayHelpers, index)
            }
        }
        else {
            arrayExecutantes[index].executante = undefined
            propsFormik.setFieldValue('executantes_externos', arrayExecutantes);
        }
        setTimeout(() => {
            funcAtualizarEmpresa({ socioId: option ? option.value : undefined, data: propsFormik.values.data_procedimento, tipoPessoa: arrayExecutantes[index].tipo_pessoa ? arrayExecutantes[index].tipo_pessoa.value : undefined })
        }, 50)
    }, [])

    const removerLinha = useCallback((arrayHelpers, propsFormik, index) => {
        const arrayProced = propsFormik.values.executantes_externos;
        if (arrayProced.length === 1) {
            propsFormik.setFieldValue('executantes_externos', [{ executante: undefined, tipo_pessoa: { value: 2, label: 'Pessoa Jurídica' } }])
        } else {
            if (arrayProced[index].executante && arrayProced[index].executante.value)
                arrayHelpers.remove(index)

            if (arrayProced.length === 1 && arrayProced[index].executante && arrayProced[index].executante.value)
                arrayHelpers.insert(propsFormik.values.executantes_externos.length, { executante: undefined, tipo_pessoa: undefined })
        }
    }, [])

    //socio cadastro interno
    const onChangeSocioInterno = (option, propsFormik, arrayHelpers, index) => {
        const arrayExecutantes = [...propsFormik.values.executantes_internos]
        if (option) {
            arrayExecutantes[index].executante = option
            arrayExecutantes.push({ data: propsFormik.values.data_procedimento || undefined, hora_inicial: propsFormik.values.hora_inicial, hora_final: propsFormik.values.hora_final, tipo_atendimento: propsFormik.values.tipo_atendimento })
            propsFormik.setFieldValue('executantes_internos', arrayExecutantes);
            props.refInternos.current.atualizar(index, { socioId: option.value, data: propsFormik.values.data_procedimento })
        }
        else {
            arrayExecutantes[index].executante = undefined
            propsFormik.setFieldValue('executantes_internos', arrayExecutantes);
        }
    }

    const funcAtualizarEmpresa = (obj) => {
        if (refEmpresa) {
            refEmpresa.current.atualizar(obj)
        }
    }

    const forcarAtualizacao = (data, atualizar, idx) => {
        const item = propsFormik.values.executantes_externos[idx]
        if ((!data || data.length === 0) && propsFormik.values.data_procedimento && item.executante) {
            atualizar({ data: propsFormik.values.data_procedimento, socioId: item.executante.value })
        }
    }

    return (
        <>
            <div className="mt-40">
                <HeaderCadastro
                    titulo={'Identificação do(s) Profissional(is) Executante(s) Cooperativa'}
                    classeChildren='space-between-100' >
                </HeaderCadastro>
            </div>
            <div className="card mb-20">
                <div className="card-body executantes_externos-float">
                    <FieldArray name="executantes_externos">
                        {(arrayHelpers => (
                            <>
                                {propsFormik.values.executantes_externos && propsFormik.values.executantes_externos.length > 0 ?
                                    propsFormik.values.executantes_externos.map((item, index) => <div className='modal-body-cadastro row mr-0 ml-0'>
                                        <InputSocioGuia data={propsFormik.values.data_procedimento} label={index === 0 ? 'Sócio' : undefined} placeholder={props.disabled && 'Selecione a Data do Procedimento' || 'Sócio'} name={`executantes_externos[${index}].executante`} propsFormik={propsFormik}
                                            tamanho={'col-sm-12 col-xl-5 col-lg-5 col-md-5 pl-0'} isDisabled={props.disabled} {...props} cacheOptions={false} obrigatorio value={propsFormik.values.executantes_externos[index].executante}
                                            classeTooltip={'tooltip-input-modal'} onChange={(option) => { setAtualizarEmpresa(true); onChangeSocio(option, propsFormik, arrayHelpers, index); }} isClearable={false}
                                            atualizar={propsFormik.touched.data_procedimento || false} funcaoAposObter={(option) => {
                                                setAtualizarEmpresa(true)
                                            }} />

                                        <InputTipoExecutante label={index === 0 ? 'Tipo Pessoa' : undefined} placeholder='Tipo Pessoa' classeTooltip={'tooltip-input-modal'} obrigatorio
                                            name={`executantes_externos[${index}].tipo_pessoa`} propsFormik={propsFormik} tamanho="col-sm-4 col-xl-2 col-lg-2 col-md-5 pl-0"
                                            onChange={option => {
                                                propsFormik.setFieldValue(`executantes_externos[${index}].tipo_pessoa`, option)
                                            }} value={propsFormik.values.executantes_externos[index].tipo_pessoa}
                                        />

                                        <InputEmpresaGuia ref={refEmpresa} nameSocio={`executantes_externos[${index}].executante`} obrigatorio={propsFormik.values.executantes_externos[index].tipo_pessoa && propsFormik.values.executantes_externos[index].tipo_pessoa.value === 2}
                                            datalabel={index === 0 ? 'Empresa' : undefined} placeholder='Empresa' classeTooltip={'tooltip-input-modal'}
                                            label={index === 0 ? 'Empresa' : undefined} name={`executantes_externos[${index}].empresa`} propsFormik={propsFormik} tamanho="col-8 col-xl-4 col-lg-4 col-md-5 pr-0 pl-0" cacheOptions={false} funcaoAoObter={(value) => {
                                                if (!value || value.length === 0) propsFormik.setFieldValue(`executantes_externos[${index}].tipo_pessoa`, { label: 'Pessoa Física', value: 1 })
                                            }} valor={propsFormik.values.executantes_externos[index].empresa} onMenuOpenPrev={(data, atualizar) => forcarAtualizacao(data, atualizar, index)}
                                            atualizar={atualizarEmpresa && (propsFormik.touched.data_procedimento || (propsFormik.touched.executantes_externos && propsFormik.touched.executantes_externos[index] && (propsFormik.touched.executantes_externos[index].executante || propsFormik.touched.executantes_externos[index].tipo_pessoa)))} />


                                        <div className='col-4 col-xl-1 col-lg-1 col-md-2 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right'>
                                            <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, propsFormik, index)} />
                                        </div>
                                    </div>)
                                    :
                                    <></>}
                            </>
                        ))}
                    </FieldArray>
                </div>
            </div>
        </>)
});

const CadastroExecutantesInternos = React.forwardRef((props, ref) => {
    const propsFormik = useFormikContext();
    const [data, setData] = useState([])
    const [atualizarEmpresa, setAtualizarEmpresa] = useState(false)
    const [arrayRef, setArrayRef] = useState(Array(propsFormik.values.executantes_internos ? propsFormik.values.executantes_internos.length : 1).fill(React.createRef()))
    const refExecutante = useRef(null)
    const refEmpresa = useRef(null)
    const [refAtualizadas, setRefAtualizadas] = useState(false)

    useEffect(() => {
        if (propsFormik.values.executantes_internos && propsFormik.values.executantes_internos.length > 0 && !refAtualizadas) {
            setArrayRef(Array(propsFormik.values.executantes_internos ? propsFormik.values.executantes_internos.length : 1).fill(React.createRef()))
            setRefAtualizadas(true)
        }
    }, [propsFormik.values.executantes_internos])

    const forcarAtualizacao = (data, atualizar, idx) => {

        const item = propsFormik.values.executantes_internos[idx]
        if ((!data || data.length === 0) && item.data && item.executante) {
            atualizar({ data: item.data, socioId: item.executante.value })
        }
    }

    const atualizar = (idx, obj) => {
        funcAtualizarEmpresa(idx, obj)
    }


    const onChangeSocio = (option, propsFormik, arrayHelpers, index) => {

        setAtualizarEmpresa(true)
        const arrayExecutantes = [...propsFormik.values.executantes_internos]
        if (option && arrayExecutantes[index].executante && option.value === arrayExecutantes[index].executante.value) {

            propsFormik.setFieldValue(`executantes_internos[${index}].executante`, undefined);
            setTimeout(() => {
                propsFormik.setFieldValue(`executantes_internos[${index}].executante`, option);
            }, 0)
        } else if (option) {

            const { empresa } = option

            var fatura_empresa_true = empresa.filter(x => x.fatura_empresa === true)
            var fatura_empresa_false = empresa.filter(x => x.fatura_empresa === false)

            if (fatura_empresa_true.length === 1) {
                setTimeout(() => {
                    propsFormik.setFieldValue(`executantes_internos[${index}].empresa`, fatura_empresa_true[0]);
                }, (50));

            } else if (fatura_empresa_false.length === 1) {
                setTimeout(() => {
                    propsFormik.setFieldValue(`executantes_internos[${index}].empresa`, fatura_empresa_false[0]);
                }, (50));

            }
            else if (fatura_empresa_true.length > 1) {
                setTimeout(() => {
                    propsFormik.setFieldValue(`executantes_internos[${index}].empresa`, undefined);
                }, (50))

            }
            else if (fatura_empresa_false.length > 1) {
                setTimeout(() => {
                    propsFormik.setFieldValue(`executantes_internos[${index}].empresa`, undefined);
                }, (50))

            }

            propsFormik.setFieldValue(`executantes_internos[${index}].empresa`, undefined);

            propsFormik.setFieldValue(`executantes_internos[${index}].executante`, option);


            const valorAnterior = { ...arrayExecutantes[index] }
            if (!valorAnterior.executante || !valorAnterior.executante.value) {
                adicionarLinha(arrayHelpers, propsFormik, option, index)
            }
        } else {
            propsFormik.setFieldValue(`executantes_internos[${index}].executante`, undefined);
        }

    }

    const onChangeHora = useCallback((name, option, propsFormik, index) => {
        const arrayExecutantes = [...propsFormik.values.executantes_internos]
        if (option) {
            arrayExecutantes[index][name] = option
            propsFormik.setFieldValue('executantes_internos', arrayExecutantes);
        }
        else {
            arrayExecutantes[index][name] = undefined
            propsFormik.setFieldValue('executantes_internos', arrayExecutantes);
        }
    }, [])

    const adicionarLinha = (arrayHelpers, propsFormik, option, index) => {
        const arrayProced = propsFormik.values.executantes_internos;
        if (option && (index + 1) === propsFormik.values.executantes_internos.length) {
            setArrayRef([...arrayRef, React.createRef()])
            arrayHelpers.insert(propsFormik.values.executantes_internos.length, { executante: undefined, tipo_atendimento: propsFormik.values.tipo_atendimento, data: propsFormik.values.data_procedimento, hora_inicial: propsFormik.values.hora_inicial, hora_final: propsFormik.values.hora_final })
        }
    }

    const removerLinha = useCallback((arrayHelpers, propsFormik, index) => {
        const arrayProced = propsFormik.values.executantes_internos;
        let cloneArrayRef = [...arrayRef]

        if (arrayProced.length === 1) {
            propsFormik.setFieldValue('executantes_internos', [{ executante: undefined, tipo_atendimento: { value: 1, label: 'Rotina' } }])
        } else {
            if (arrayProced.length > 1 && arrayProced.length !== index + 1) {
                cloneArrayRef.splice(index, 1)
                arrayHelpers.remove(index)
            }

            if (arrayProced.length === 1 && arrayProced[index].executante && arrayProced[index].executante.value) {
                cloneArrayRef.push(React.createRef())
                arrayHelpers.insert(propsFormik.values.executantes_internos.length, { executante: undefined, tipo_atendimento: { value: 1, label: 'Rotina' }, data: propsFormik.values.data_procedimento })
            }
        }
    }, [])

    const funcAtualizarEmpresa = useCallback((idx, obj) => {
        if (arrayRef[idx])
            arrayRef[idx].current.atualizar(obj)
    }, [arrayRef])


    const atualizarAtendimento = () => {
        setTipoAtendimento(propsFormik, 0, true)
    }

    const setTipoAtendimento = async (propsFormik, index, atendimentoGeral) => {
        const valores = propsFormik.values
        const feriados = props.feriados

        if (atendimentoGeral) {
            var executantes = valores.executantes_internos;
            for (let i = 0; i < executantes.length; i++) {
                let urgenciaLinha = false;
                if (isUrgencyOnHoliday(feriados, valores.executantes_internos[i].data, valores.executantes_internos[i].hora_inicial, valores.executantes_internos[i].hora_final)) {
                    urgenciaLinha = true
                } else {
                    urgenciaLinha = await isUrgency(valores.executantes_internos[i].data, valores.executantes_internos[i].hora_inicial, valores.executantes_internos[i].hora_final)
                }
                executantes[i].tipo_atendimento = urgenciaLinha ? { value: 3, label: 'Voluntário' } : { value: 1, label: 'Rotina' }
            }

            propsFormik.setFieldValue('executantes_internos', executantes);
        } else {
            if (valores.executantes_internos[index].data && valores.executantes_internos[index].hora_inicial && valores.executantes_internos[index].hora_final) {
                let urgencia = false

                if (isUrgencyOnHoliday(feriados, valores.executantes_internos[index].data, valores.executantes_internos[index].hora_inicial, valores.executantes_internos[index].hora_final))
                    urgencia = true
                else
                    urgencia = await isUrgency(valores.executantes_internos[index].data, valores.executantes_internos[index].hora_inicial, valores.executantes_internos[index].hora_final)

                if (urgencia)
                    propsFormik.setFieldValue(`executantes_internos[${index}].tipo_atendimento`, { value: 3, label: 'Voluntário' })
                else
                    propsFormik.setFieldValue(`executantes_internos[${index}].tipo_atendimento`, { value: 1, label: 'Rotina' })
            }
        }
        return;
    }

    if (ref)
        ref.current = {
            atualizar: atualizar,
            atualizarAtendimento: atualizarAtendimento
        }

    return (
        <>
            <div className="mt-40">
                <HeaderCadastro
                    titulo={'Identificação do(s) Profissional(is) Executante(s) EQUIPE'}
                    classeChildren='space-between-100' >
                </HeaderCadastro>
            </div>
            <div className="card mb-20 scroll-x">
                <div className="card-body  executantes-internos-float" ref={refExecutante}>
                    <FieldArray name="executantes_internos">
                        {(arrayHelpers => (
                            <>
                                {propsFormik.values.executantes_internos && propsFormik.values.executantes_internos.length > 0 ?
                                    propsFormik.values.executantes_internos.map((item, index) => <div className='modal-body-cadastro row mr-0 ml-0'>

                                        <div className='row col-sm-12 col-xl-4 col-lg-12 col-md-12'>
                                            <InputDatePicker
                                                denyIndex
                                                tamanho="col-4 pl-0"
                                                label={index === 0 ? "Data" : undefined}
                                                name={`executantes_internos[${index}].data`}
                                                type="text"
                                                setarValor={async (value, changed) => {
                                                    if (propsFormik.values.executantes_internos) {
                                                        propsFormik.values.executantes_internos[index].data = value
                                                        propsFormik.setFieldTouched(`executantes_internos[${index}].data`, true)
                                                        const socio = propsFormik.values.executantes_internos[index].executante
                                                        if (changed) {
                                                            // funcAtualizarEmpresa(index, { socioId: socio ? socio.value : undefined, data: value })
                                                            setTipoAtendimento(propsFormik, index)
                                                        }
                                                        propsFormik.values.executantes_internos[index].data_changed = false
                                                    }
                                                }}
                                                onBlurData={async (value, changed) => {
                                                    if (changed) {
                                                        await setTipoAtendimento(propsFormik, index)
                                                        propsFormik.values.executantes_internos[index].data_changed = false
                                                    }
                                                }}
                                                onChange={value => {
                                                    if (propsFormik.values.executantes_internos) {
                                                        propsFormik.setFieldValue(`executantes_internos[${index}].data`, value)
                                                        propsFormik.setFieldTouched(`executantes_internos[${index}].data`, true)
                                                        const socio = propsFormik.values.executantes_internos[index].executante
                                                        // funcAtualizarEmpresa(index, { socioId: socio ? socio.value : undefined, data: value })
                                                    }
                                                    propsFormik.values.executantes_internos[index].data_changed = false
                                                }}
                                                placeholder="Data" className='form-control input-blur'
                                                changed={propsFormik.values.executantes_internos[index].data_changed}
                                                obrigatorio />

                                            <InputTimePicker
                                                denyIndex
                                                id={`executantes_internos[${index}].hora_inicial`}
                                                label={index === 0 ? "Hora Inicial" : undefined} name={`executantes_internos[${index}].hora_inicial`} type="text" placeholder="Hora Inicial" tamanho={"col-4 pl-0"} classeTooltip={'tooltip-input-modal'}

                                                // setarValor={value => onChangeHora('hora_inicial', value, propsFormik, index)} 
                                                setarValor={value => {
                                                    propsFormik.setFieldValue(`executantes_internos[${index}].hora_inicial`, value)
                                                    propsFormik.values.executantes_internos[index].hora_inicial_changed = false
                                                }}
                                                myBlur={async event => {
                                                    if (event) {
                                                        await setTipoAtendimento(propsFormik, index);
                                                    }
                                                }}
                                                obrigatorio
                                                changed={propsFormik.values.executantes_internos[index].hora_inicial_changed}
                                                className='form-control input-blur'
                                            />

                                            <InputTimePicker
                                                denyIndex
                                                id={`executantes_internos[${index}].hora_final`}
                                                label={index === 0 ? "Hora Final" : undefined} name={`executantes_internos[${index}].hora_final`} type="text" placeholder="Hora Final" tamanho={"col-4 pl-0"} classeTooltip={'tooltip-input-modal'}
                                                // setarValor={value => onChangeHora('hora_final', value, propsFormik, index)} 
                                                setarValor={value => {
                                                    propsFormik.setFieldValue(`executantes_internos[${index}].hora_final`, value)
                                                    propsFormik.values.executantes_internos[index].hora_final_changed = false
                                                }}
                                                myBlur={async event => {
                                                    if (event)
                                                        await setTipoAtendimento(propsFormik, index);
                                                }}
                                                obrigatorio
                                                changed={propsFormik.values.executantes_internos[index].hora_final_changed}
                                                className='form-control input-blur'
                                            />

                                        </div>

                                        <InputSocioGuia data={propsFormik.values.executantes_internos[index].data} label={index === 0 ? 'Sócio' : undefined} placeholder={!propsFormik.values.executantes_internos[index].data && 'Selecione a Data' || 'Sócio'} name={`executantes_internos[${index}].executante`}
                                            id={`executantes_internos[${index}].executante`} propsFormik={propsFormik}
                                            tamanho={'col-sm-9 col-xl-3 col-lg-5 col-md-5 pl-0 pr-0 mb-0'} isDisabled={!propsFormik.values.executantes_internos[index].data} classeTooltip={'tooltip-input-modal'} value={propsFormik.values.executantes_internos[index].executante}
                                            onFocus={_ => propsFormik.setFieldTouched(`executantes_internos[${index}].executante`, true)}
                                            onChange={(option) => onChangeSocio(option, propsFormik, arrayHelpers, index)} isClearable={false} cacheOptions={false} funcaoAposObter={(option) => {
                                                setAtualizarEmpresa(true)
                                            }}
                                            atualizar={(propsFormik.touched.executantes_internos && propsFormik.touched.executantes_internos[index] && propsFormik.touched.executantes_internos[index].data) || false} />

                                        <InputTipoAtendimentoEquipe label={index === 0 ? 'Tipo Atend.' : undefined} isClearable={false} placeholder='Tipo Atend.' classeTooltip={'tooltip-input-modal'} obrigatorio
                                            name={`executantes_internos[${index}].tipo_atendimento`} propsFormik={propsFormik} tamanho="col-sm-3 col-xl-2 col-lg-3 col-md-2 pr-0" />

                                        <InputEmpresaGuia ref={arrayRef[index]} placeholder='Empresa' classeTooltip={'tooltip-input-modal'} label={index === 0 ? 'Empresa' : undefined}
                                            name={`executantes_internos[${index}].empresa`} propsFormik={propsFormik} tamanho="col-8 col-xl-2 col-lg-3 col-md-4 pr-0" valor={propsFormik.values.executantes_internos[index].empresa}
                                            atualizar={atualizarEmpresa && propsFormik.touched.executantes_internos && propsFormik.touched.executantes_internos[index] && (propsFormik.touched.executantes_internos[index].data || propsFormik.touched.executantes_internos[index].executante)}
                                            onMenuOpenPrev={(data, atualizar) => forcarAtualizacao(data, atualizar, index)} />
                                        <div className='col-4 col-xl-1 col-lg-1 col-md-1 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right'>
                                            <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, propsFormik, index)} />
                                        </div>
                                        : <></>
                                    </div>)
                                    :
                                    <></>}
                            </>
                        ))}
                    </FieldArray>
                    {propsFormik.touched.executantes_internos && propsFormik.touched.executantes_internos.filter(x => x && x.executante).length > 0 && propsFormik.errors.executantes_internos && !Array.isArray(propsFormik.errors.executantes_internos) ?
                        <Overlay target={refExecutante.current} show={true} placement="bottom">
                            {props2 => {
                                return (
                                    <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                        <div className="error">
                                            <FiAlertTriangle className="icone" /> <span>{propsFormik.errors.executantes_internos}</span></div>
                                    </Tooltip>)
                            }}
                        </Overlay> : <></>}
                </div>
            </div>
        </>)
});

const modalValidacaoConferido = React.forwardRef((props, ref) => {
    const [showModal, setShowModal] = useState(false);
    if (ref) {
        ref.current = {
            validarStatus: () => { },
        }
    }

    const funcaoOk = useCallback(() => {
        console.log('props: ', props);
        props.funcaoPrincipal();
        setShowModal(false);
    }, [])

    const funcaoCancel = useCallback(() => {
        setShowModal(false)
    }, [])

    let configuracoes = {
        textoOpcao1: "Continuar",
        funcaoOpcao1: funcaoOk,
        textoOpcao2: "Cancelar",
        funcaoOpcao2: funcaoCancel,
    }

    return <ModalDuasOpcoes showModal={showModal} setShowModal={setShowModal} {...configuracoes}
        mensagem={<>
            A guia selecionada não foi escaneada.
            <br />
            <br />
            <b>Deseja continuar?</b>
            <br />
        </>} />
})

export { CadastroGuia, CadastroExecutantesInternos }
export default Cadastro
