import React, { useState, useEffect, useRef } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'
import { Alterar, Excluir, Visualizar, Imprimir, BtnAcao, Btn, Detalhes, AlteracaoStatusLote, EnviarLoteCoop, VisualizarArquivo, VisualizarErro, VisualizarRelacaoGuias } from '../../../../components/Acoes'
import { BotaoPadrao } from '../../../../components/Botoes'
import moment from 'moment';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { get, excluir, salvar, validar } from '../../../../util/AcessoApi'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens, useWindowSize } from '../../../../util/Utilitarios.js'
import { InputPadrao, TextArea } from '../../../../components/Inputs'
import { InputLoteStatus } from '../../../../components/InputsAutoComplete'
import Yup from '../../../../util/Validacoes';
import { ModalCadastro, ModalDuasOpcoes } from '../../../../components/Modais'
import { Formik, Form } from "formik";
import { LoadRelatorio } from '../../../../components/Loading'
import { useHistory, Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';



export default (rProps) => {
    const [data, setData] = useState([])
    const target = useRef(null)
    const [width] = useWindowSize()
    const history = useHistory()


    // useEffect(_ => { consultar('/faturamento/lote', dados => setData(dados.sort((a, b) => b.id - a.id))) }, [rProps])

    const [dados, setDados] = useState({})

    useEffect(() => {
        get('/financeiro/subtituloCorCategoria', null, null, null).then(val => {
            if (val && val.length) {
                setDados(val)
            }
            else {
                setDados({})
            }
        })
    }, [])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-categoria',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    html: item => <span>{item.id}<div className='guia-complementar' style={{ background: item.cor }}></div></span>,
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Tipo Lançamento',
                    accessor: 'tipo_lancamento',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'tipo_lancamento',
                        idColuna: 'categoria.tipo_lancamento_id',

                    }
                },
                {
                    Header: 'Conta ',
                    accessor: 'conta',
                    ordenamento: {
                        tipo: 'numero'
                    },
                    classes: 'nowrap'
                },
                {
                    Header: 'Categoria',
                    accessor: 'categoria_filtro',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    visivel: false,
                    apenasDataGrid: true,
                    filtro: {
                        tipo: 'todas_categorias',
                        idColuna: 'categoria.id',

                    }
                },

                {
                    Header: 'Descrição',
                    accessor: 'descricao',
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Conta Principal',
                    accessor: 'conta_principal',
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Ativo?',
                    accessor: 'ativo',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'input_sim_nao',
                        idColuna: 'ativo',
                        boolean: true
                    },
                },
                {
                    Header: 'Utiliza Movimento?',
                    accessor: 'utiliza_lancamento_movimento',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'conta_ativa',
                        idColuna: 'utiliza_lancamento_movimento',
                        boolean: true
                    },
                },
                {
                    Header: 'Data Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    },
                },
                {
                    Header: 'Data Registro Inicial',
                    accessor: 'data_inicial',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'data_registro_inicial',
                        datePicker: true,
                        date: true

                    }
                }, {
                    Header: 'Data Registro Final',
                    accessor: 'data_final',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'data_registro_final',
                        datePicker: true,
                        date: true

                    }
                },
                // {
                //     Header: 'Hora Registro Inicial',
                //     accessor: 'hora_inicial_registro',
                //     visivel: false,
                //     ordenamento: {
                //         tipo: 'data'
                //     },
                //     filtro: {
                //         tipo: 'hora_inicial_registro',
                //         idColuna: 'hora_registro_inicial',
                //         timePicker: true
                //     }
                // }, {
                //     Header: 'Hora Registro Final',
                //     accessor: 'hora_final_registro',
                //     visivel: false,
                //     ordenamento: {
                //         tipo: 'data'
                //     },
                //     filtro: {
                //         tipo: 'hora_final_registro',
                //         idColuna: 'hora_registro_final',
                //         timePicker: true
                //     }
                // },
            ]
        }], []
    )

    const subtitulo = () => {

        return (
            <div className='guia-legenda'>
                {
                    dados && dados.length ?
                        dados.map(x => <><span className='legenda' style={{ background: x.cor }}>{x.descricao}</span></>) :
                        <></>
                }
            </div>
        )
    }
    const acoesObj = [
        (propsAcao) => <Visualizar to={'/financeiro/categoria/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/financeiro/categoria/alteracao/' + propsAcao.row.id} />,
        (propsAcao) => <Excluir to={'financeiro/categoria/consulta/'} action={() => exclusao(propsAcao)} />,
    ]

    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: rProps.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/financeiro/categoria', propsAcao.row.id,
                    () => {
                        // getApi(setData, '/faturamento/guia')
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    return (
        <>
            <div>
                <DataGridAlternativa target={target} acoesObj={acoesObj} data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/financeiro/categoria'
                    caminhoConsultaFiltros='/financeiro/categoriapaginacao' modulo={rProps.modulo} titulo={'Consulta Categoria'} tituloLista={'Lista Categoria'} subtitulo={subtitulo}
                    urlCadastro='/financeiro/categoria/cadastro' icone={'icon-Posterous'} />
            </div>
        </>
    )

}
