import { InputPadrao, InputValor, InputDecimal, InputNumero } from '../../../../components/Inputs'
import { InputTabelaProcedimento, InputTodosProcedimentos } from '../../../../components/InputsAutoComplete'
import React, { useState, useEffect, useRef } from 'react'
import Yup, { validaInteiro } from '../../../../util/Validacoes'
import { HeaderCadastro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form, FieldArray } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'
import { BtnAcao } from '../../../../components/Acoes'

const CadastroProcedimento = props => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const inputFocus = useRef(null);
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao] = useState(Yup.object({
        tabela_procedimento: Yup.string().required().nullable(),
        codigo: Yup.string().required().nullable().trim().max(10),
        codigo_tabela_tiss: Yup.string().nullable().trim().max(3),
        descricao: Yup.string().nullable().required().max(120),
        porte_fracao: Yup.string().nullable().max(50),
        porte: Yup.string().nullable().max(2),
        valor_porte: Yup.string().nullable().max(50),
        custo_operacional: Yup.string().nullable().max(50),
        numero_auxiliar: Yup.string().nullable().max(2).test('Número Auxiliar', 'Valor inválido!', function () { return validaInteiro(this.parent.numero_auxiliar) }),
        porte_anestesico: Yup.string().nullable().required().trim().max(50),
        incidencia: Yup.number().nullable(),
        filme: Yup.string().nullable(),
        unidade_radiofarmaco: Yup.string().nullable().max(70),
    }))

    const manter = ['tabela_procedimento', 'codigo_tabela_tiss',]

    const Submit = (values, propsFormik) => {

        setSalvando(true)

        salvar(
            { ...values },
            '/faturamento/procedimento',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)

                if (salvarENovo) {
                    propsFormik.resetForm({})
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                }
                else
                    props.history.push('/faturamento/procedimento/consulta')
            },
            () => setSalvando(false))
    }

    useEffect(_ => {
        Promise.resolve(get(`/faturamento/procedimento/${id}`)).then(obj => {
            if (id && !obj.id)
                props.history.push('/faturamento/procedimento/consulta')

            setValoresIniciais(obj)
        })
    }, [id])

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Faturamento</span>,
        () => <Link className='link-active' to={'/faturamento/procedimento/consulta'}>Procedimento</Link>,
    ]

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => {
                return (<Form>
                    <HeaderCadastro titulo={"Cadastro Procedimento"} link={links} />
                    <div className={props.lateral && props.lateral || 'card mb-20'}>
                        <div className={props.lateral || 'card-body'}>
                            <div className='row'>
                                <InputPadrao label='Id' name='id' type='hidden' />
                                <InputTabelaProcedimento label='Tabela' name="tabela_procedimento" propsFormik={propsFormik}
                                    obrigatorio={true} tamanho='col-lg-4' botaoVisivel={true} modal={true} focusAfter={inputFocus} autoFocus salvarENovo={salvarENovo}
                                    {...props} />
                                <InputPadrao ref={inputFocus} label='Código' name='codigo' type='text' obrigatorio={true} tamanho='col-lg-4' maxLength={10} />
                                <InputPadrao label='Código Tabela TISS' name='codigo_tabela_tiss' type='text' tamanho='col-lg-4' maxLength={3} />
                                <InputPadrao label='Descrição' name='descricao' type='text' obrigatorio={true} tamanho='col-lg-4' maxLength={120} />
                                <InputDecimal label='Porte Fração' name='porte_fracao' propsFormik={propsFormik} classDiv='col-lg-2' max={1000} />
                                <InputPadrao label='Porte Procedimento' name='porte' type='text' tamanho='col-lg-2' maxLength={2} />
                                <InputValor label='Valor Porte' name='valor_porte' classDiv='col-lg-2' propsFormik={propsFormik} max={100000000} />
                                <InputValor label='Custo Operacional' name='custo_operacional' classDiv='col-lg-2' propsFormik={propsFormik} max={100000000} />
                                <InputNumero label='Número Auxiliar' propsFormik={propsFormik} name='numero_auxiliar' classDiv='col-lg-2' max={99} />
                                <InputNumero label='Porte Anestésico' propsFormik={propsFormik} name='porte_anestesico' classDiv='col-lg-2' max={8} obrigatorio />
                                <InputNumero label='Incidência' propsFormik={propsFormik} name='incidencia' classDiv='col-lg-2' max={99} />
                                <InputDecimal label='Filme' name='filme' classDiv='col-lg-2' propsFormik={propsFormik} max={1000} />
                                <InputPadrao label='Unidade Radiofármaco' name='unidade_radiofarmaco' type='text' tamanho='col-lg-2' maxLength={70} obrigatorio />
                                <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                            </div>
                        </div>
                    </div>
                    <div className='separator mt-40 mb-5'></div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                        {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                    </div>
                </Form>)
            }}
        </Formik>
    )
}

export default CadastroProcedimento