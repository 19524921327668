import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaData } from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { get, salvar, acoesAgenda, validar,validarComPost } from '../../../../util/AcessoApi'
import { ACOES } from '../../../../util/Enums'
import { InputPadrao, InputDatePicker, InputNumero, TextArea } from '../../../../components/Inputs'
import { InputSocioAtivosComGrupos, InputSocioAtivos, InputIntervaloHorario, InputRecorrencio, InputEscalaTipo, InputStatusAgendamentoPlantao } from '../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import Atalho from '../../../../util/Atalho'
import { BtnAcao } from '../../../../components/Acoes'
import { ModalEscolha, ModalDuasOpcoes, ModalCadastro } from '../../../../components/Modais'
import { LoadRelatorio } from '../../../../components/Loading'
import '../css/style.css'
import moment from "moment"

export default props => {
    let { id } = props

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [showModalInterOcorrencia, setShowModalInterOcorrencia] = useState(false)
    const target = useRef(null)
    const [ordemAlteracao, setOrdemAlteracao] = useState(true)
    const [msgValidacaoOcorrencia, setMsgValidacaoOcorrencia] = useState([])
    const refDataFinal = useRef(null)
    const refRecorrencia = useRef(null)
    const [showModalOpcaoSalvarVariosSocios, setShowModalOpcaoSalvarVariosSocios] = useState(false)
    const [opcaoSalvar, setOpcao] = useState('normal')



    const [validacao] = useState(Yup.object({
        ordem: Yup.string().nullable(),
        data: Yup.string().nullable().required().test('Data', 'Data inválida!', function (value) { return validaData(this.parent.data) }),
        socio: Yup.string().nullable().required(),
        tipo_escala: Yup.string().nullable().required(),
        periodo: Yup.string().nullable().required(),
        recorrencia: Yup.string().nullable(),
        data_final: Yup.string().nullable().test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) }),
    }))

    useEffect(() => {
        if (id)
            Promise.resolve(get(`/administracao/plantaoAlcancavel/${id}`)).then(obj => {
                props.duplicar && delete obj.id
                id = null
                setOrdemAlteracao(false)
                setValoresIniciais(obj)
            })
        else
            setValoresIniciais({ ordem: '', data: '', socio: '', tipo_escala: '', periodo: '', recorrencia: '', data_final: '' })

    }, [props.abrirModal, props.id])

    const manter = ['data', 'tipo_escala', 'periodo', 'recorrencia']

    const obterOrdem = (data, tipo_escala, propsFormik) => Promise.resolve(get(`/administracao/obterOrdemNoDia`, { data: data || moment().format('DD/MM/YYYY'), tipo_escala: tipo_escala && tipo_escala.value || null })).then(val => propsFormik.setFieldValue("ordem", val && val.ordem || 1))

    const validacaoInterposicaoOcorrencia = (values, propsFormik) => {
        validar(`/administracao/socio-ocorrencia-verificacao`, { modulo: 49, ...values }, () => { setMsgValidacaoOcorrencia([]); validarSubmit(values, propsFormik, 2) }, (e) => {
            const alertas = e.response.data.alertas;
            setMsgValidacaoOcorrencia(<><div dangerouslySetInnerHTML={{ __html: alertas[0].replace(/"/g, '') }} /></>)
            setShowModalInterOcorrencia(true)
            setSalvando(false)
        })
    }

    const validarSubmit = (values, propsFormik, nValidacao) => {
        setSalvando(true)

        switch (nValidacao) {
            case 1:
                validacaoInterposicaoOcorrencia(values, propsFormik)
                break;
            case 2:
                if (values.recorrencia && values.id)
                    setShowModalValidacao(true)
                else
                    Submit(values, propsFormik, false)
                break;
            default:
                validacaoInterposicaoOcorrencia(values, propsFormik)
                break;
        }
    }

    const validacaoSubmit = async (values, propsFormik, alterarRecorrencia) => {
        if (values.socio.length > 1 && opcaoSalvar === 'normal' && !id) {
            setShowModalOpcaoSalvarVariosSocios(true);
            return;
        }

        values.opcao_salvar = opcaoSalvar;




        setShowModalValidacao(false)
        setSalvando(true)
        const msg = []
        const validacaoData = moment(values.data, 'DD/MM/YYYY')

        if (!alterarRecorrencia && values.recorrencia && values.id) {
            setShowModalValidacao(true)
            return
        }

        //VALIDACAO RECORRENCIA FIM DE SEMANA EM DIA UTIL
        if (values.recorrencia && values.recorrencia.value === 4 && (validacaoData.weekday() !== 6)) {
            msg.push(<div dangerouslySetInnerHTML={{ __html: 'O lançamento será realizado no próximo final de semana.' }} />)
            values.data = validacaoData.add(6 - validacaoData.weekday(), 'day').format('DD/MM/YYYY')
        }

        //VALIDACAO RECORRENCIA DIA UTIL EM FIM DE SEMANA
        if (values.recorrencia && values.recorrencia.value === 2 && (validacaoData.weekday() === 0 || validacaoData.weekday() === 6)) {
            msg.push(<div dangerouslySetInnerHTML={{ __html: 'O lançamento será realizado no próximo dia útil.' }} />)
            while (true) {
                values.data = validacaoData.add(1, 'day').format('DD/MM/YYYY')

                if (validacaoData.weekday() !== 6 && validacaoData.weekday() !== 0)
                    break

                validacaoData = moment(values.data, 'DD/MM/YYYY')
            }
        }
        await validarComPost(`/administracao/regrasnegocioplantao`, { modulo: 49, ...values }, null, (e) => {
            if (e.response && e.response.data && e.response.data.alertas && e.response.data.alertas[0])
                msg.push(<><div dangerouslySetInnerHTML={{ __html: e.response && e.response.data && e.response.data.alertas && e.response.data.alertas[0].replace(/"/g, '').replace(/\\/g, '').replace('Error: ', '') }} /></>)
        })

        if (msg.length) {
            msg.push('Deseja continuar?')
            setMsgValidacaoOcorrencia(msg.map(x => <>{x}<br /></>))
            setShowModalInterOcorrencia(true)
            setSalvando(false)
        }
        else
            Submit(values, propsFormik)
    }

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        values.ordem = values.ordem || null
        setShowModalInterOcorrencia(false)

        salvar(values, '/administracao/plantaoAlcancavel',
            _ => {
                mostrarToast('sucesso', mensagens.sucesso)
                propsFormik.resetForm({})
                setSalvando(false)
                setOpcao('normal')
                if (!values.id && !props.duplicar) {
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    obterOrdem(values.data, values.tipo_escala, propsFormik)
                    target.current.focus()
                }
                else
                    props.setAbrirModal(false)

                props.target.current.atualizar()
            },
            _ => setSalvando(false))
    }

    return (
        props.abrirModal ?
            <Formik
                initialValues={valoresIniciais}
                validationSchema={validacao}
                enableReinitialize={true}
                onSubmit={(values, propsFormik) => validacaoSubmit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row mr-0 ml-0'>
                        <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                        <InputDatePicker setFlag={setOrdemAlteracao} flag={ordemAlteracao} label="Data" name="data" type="text" obrigatorio autoFocus targetFocus={target} functionAposSetarValor={value => obterOrdem(value, propsFormik.values.tipo_escala, propsFormik)}
                            setarValor={value => propsFormik.setFieldValue("data", value)} tamanho={'col-lg-2'} mostrarFeriado={true} />
                        <InputEscalaTipo onChange={option => { propsFormik.setFieldValue('tipo_escala', option); obterOrdem(propsFormik.values.data, option, propsFormik) }} label="Tipo Escala" obrigatorio name={'tipo_escala'} propsFormik={propsFormik} salvarENovo={salvarENovo} tamanho={'col-lg-4'} />
                        <InputNumero label='Ordem' propsFormik={propsFormik} name='ordem' max={999} classDiv={'col-lg-2'} obrigatorio />
                        <InputSocioAtivosComGrupos data_inicial={(propsFormik.values && propsFormik.values.data) ? (propsFormik.values.data) : ''} agenda_id={propsFormik.values && propsFormik.values.id} label="Sócio" name="socio" propsFormik={propsFormik} salvarENovo={salvarENovo} multiplo
                            obrigatorio tamanho={'col-lg-4'} />
                        <InputIntervaloHorario botaoVisivel={true} label="Período" obrigatorio name={'periodo'} propsFormik={propsFormik} salvarENovo={salvarENovo} tamanho={'col-lg-4'} />
                        <InputDatePicker mostrarFeriado={true} disabled={valoresIniciais.recorrencia ? true : propsFormik.values['recorrencia'] ? true : false} label="Data Final" name="data_final" type="text" setarValor={value => { propsFormik.setFieldValue("data_final", value); propsFormik.setFieldValue('recorrencia', '') }} tamanho={'col-lg-2'} />
                        <InputRecorrencio isDisabled={props.duplicar ? false : id ? true : propsFormik.values['data_final'] ? true : false} label="Recorrência (Dentro do Mês)" name={'recorrencia'} propsFormik={propsFormik}
                            salvarENovo={salvarENovo} tamanho={'col-lg-3'} onChange={option => { propsFormik.setFieldValue('data_final', ''); propsFormik.setFieldValue('recorrencia', option) }} />
                        <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                        <div className='text-right container-botoes mb-3 group-salvar-novo mt-2 col-lg-3 container-botao-cadastro'>
                            <Atalho formik={propsFormik} salvando={salvando} />
                            <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} classeTooltip="tooltip-plantao-es" class="btn btn-primary-sm primary botao-cadastro" />
                            <BotaoPadrao texto='Cancelar' disabled={salvando} click={_ => props.setAbrirModal(false)} idTooltip={"cancelar"} classeTooltip="tooltip-plantao-es" class="btn btn-primary-sm primary botao-cadastro" />
                        </div>
                    </div>
                    <ModalEscolha funcaoCancelar={_ => setSalvando(false)} showModal={showModalValidacao} setShowModal={setShowModalValidacao} esconderFechar mensagem={'Selecione uma opção:'}>
                        <ul class="lista-modal text-center width-100">
                            <li onClick={_ => { propsFormik.values.alterar_recorrencia = 3; validacaoSubmit(propsFormik.values, propsFormik, true) }}>
                                <div className='mb-1 li-titulo'>Alterar somente este registro</div>
                            </li>
                            <div className='mt-1' />
                            <li onClick={_ => { propsFormik.values.alterar_recorrencia = 2; validacaoSubmit(propsFormik.values, propsFormik, true) }}>
                                <div className='mb-1 li-titulo'>Alterar também próximas recorrências</div>
                            </li>
                        </ul>
                    </ModalEscolha>
                    <ModalDuasOpcoes showModal={showModalInterOcorrencia} setShowModal={setShowModalInterOcorrencia}
                        funcaoOpcao1={() => { setSalvando(false); setShowModalInterOcorrencia(false); setOpcao('normal'); }}
                        esconderFechar mensagem={<>{msgValidacaoOcorrencia}<br /></>}
                        textoOpcao1={'Fechar'}
                        classeOpcao1={'btn-sm btn-primary-modal simples'}
                        textoOpcao2={'Continuar'}
                        funcaoOpcao2={_ => Submit(propsFormik.values, propsFormik)} />

                    <ModalEscolha showModal={showModalOpcaoSalvarVariosSocios} setShowModal={setShowModalOpcaoSalvarVariosSocios} esconderFechar mensagem={'Selecione uma opção:'}
                        confirmar={opcaoSalvar} funcaoConfirmar={() => { validacaoSubmit(propsFormik.values, propsFormik); setShowModalOpcaoSalvarVariosSocios(false); }} funcaoCancelar={() => { setShowModalOpcaoSalvarVariosSocios(false); setOpcao('normal'); }}>
                        <ul class="lista-modal text-center width-100">
                            <div className='mt-1' />
                            <li className={opcaoSalvar === 'varias' ? 'excluir-active' : ''} onClick={_ => setOpcao('varias')}>
                                <div className='mb-1 li-titulo'>Criar {propsFormik.values.socio && propsFormik.values.socio.length} escalas.</div>
                            </li>
                            <div className='mt-1' />
                            <li className={opcaoSalvar === 'muitos-em-um' || opcaoSalvar === 'normal' ? 'excluir-active' : ''} onClick={_ => setOpcao('muitos-em-um')}>
                                <div className='mb-1 li-titulo'>Apenas uma escala.</div>
                            </li>
                        </ul>
                    </ModalEscolha>

                </Form>)}
            </Formik>
            :
            <></>
    )
}

const ConfirmarAgendamento = (props) => {

    let id = {}

    const [salvando, setSalvando] = useState(false)
    const [item, setItem] = useState({})
    const [carregando, setCarregando] = useState(false)
    const refAtualizacao = useRef(null)
    const [valoresIniciais, setValoresIniciais] = useState({})

    const [validacao, setValidacao] = useState({})

    const Submit = (values, propsFormik) => {
        setSalvando(true)

        acoesAgenda(
            values,
            '/administracao/agendareservastatushistorico',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                setSalvando(false)
                props.setAbrirModal(false)
                props.funcaoAposSalvar()
            },
            () => { setSalvando(false) }, null, ACOES.CONFIRMAR_AGENDA, props.modulo)
    }

    useEffect(() => {
        if (props.item && props.item.plantao) {
            setValoresIniciais({ status: 2, ...props.item.valoresIniciais })
            setValidacao(Yup.object({
                observacao_status: Yup.string().nullable()
            }))
            setCarregando(false)
        }
        else
            setCarregando(true)
    }, [props.item])

    useEffect(() => {
        if (!props.abrirModal)
            props.setItem([])

    }, [props.abrirModal])

    return (
        <>
            <ModalCadastro titulo={"Confirmar Plantão"} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    initialValues={valoresIniciais}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                    {propsFormik => (
                        <div ref={refAtualizacao}>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <div className={'form-group col-12'}>
                                        {carregando === true ? <LoadRelatorio refLoading={refAtualizacao} heigth={320} /> : (props.item && props.item.plantao ? <>
                                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                                            <InputPadrao label="Status" name="status" type="hidden" placeholder="Status" obrigatorio={true} />
                                            {props.item && props.item.plantao && props.item.plantao.map(agenda => <>
                                                <label className={'width-100 bold'}>{agenda.titulo}</label>
                                                <label className={'width-100 mb-20'}>{agenda.descricao}</label>
                                            </>)}
                                            <TextArea label={<b>Observação</b>} name='observacao_status' type='text' placeholder='Observação' tamanho={'col-lg-12 p-0'} classeTooltip={'tooltip-input-modal'} />
                                        </> : <></>)}
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>
                                {carregando === true ? <></> :
                                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                                        <BotaoPadrao texto='Confirmar' disabled={salvando} click={_ => { }} />
                                        <BotaoPadrao texto='Cancelar' disabled={salvando} click={_ => props.setAbrirModal(false)} />
                                    </div>}
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    )
}

const TrocarStatus = (props) => {
    const [salvando, setSalvando] = useState(false)
    const [carregando, setCarregando] = useState(true)
    const refAtualizacao = useRef(null)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')

    const [validacao, setValidacao] = useState({})

    useEffect(() => {
        if (props.item && props.item.plantao) {
            setValoresIniciais({ status: '', ...props.item.valoresIniciais })
            setValidacao(Yup.object({
                observacao_status: Yup.string().nullable(),
                status: Yup.string().nullable().required(),
            }))
            setCarregando(false)
        }
        else
            setCarregando(true)
    }, [props.item])

    useEffect(() => {
        if (!props.abrirModal)
            props.setItem([])

    }, [props.abrirModal])

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        let valuesAjustados = { ...values }
        valuesAjustados.status = values.status ? values.status.id : ''

        acoesAgenda(
            valuesAjustados,
            '/administracao/agendareservastatushistorico',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                setSalvando(false)
                props.funcaoAposSalvar()
            },
            () => setSalvando(false), null, ACOES.ALTERAR_STATUS_AGENDA, props.modulo)

    }
    const funcaoAposSim = (formik) => {
        Submit(formik.values, formik.resetForm)
    }

    return (
        <>
            <ModalCadastro titulo={"Troca Status Plantão"} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                {
                    props.acaoAnte == undefined ? <></> :
                        <div className='subheader-porte-acomodacao row-space-between'>
                            <BtnAcao type={'button'} icone="icon-Arrow-Left focus-button" texto="Anterior" action={_ => { setValidacao({}); props.acaoAnte() }} />
                            <BtnAcao type={'button'} icone="icon-Arrow-Right focus-button" texto="Próximo" action={_ => { setValidacao({}); props.acaoProx() }} />
                        </div>
                }
                <Formik
                    initialValues={valoresIniciais}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                    {propsFormik => (
                        <div ref={refAtualizacao}>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <div className={'form-group col-12'}>
                                        {carregando === true ? <LoadRelatorio refLoading={refAtualizacao} heigth={320} /> : (props.item ? <>
                                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                                            {<label className={'width-100 bold'}>Ações</label>}
                                            <div style={{ zIndex: 9999 }}>{props.acoes}</div>
                                            {props.item && props.item.plantao && props.item.plantao.map(agenda => <>
                                                <label className={'width-100 bold'}>{agenda.titulo}</label>
                                                <label className={'width-100 mb-20'}>{agenda.descricao}</label>
                                            </>)}
                                            <InputStatusAgendamentoPlantao label={<b>Status Agendamento</b>} name="status" propsFormik={propsFormik} tamanho={'col-lg-12 p-0'}
                                                classeTooltip={'tooltip-input-modal'} autoFocus multiplo obrigatorio />
                                            <TextArea label={<b>Observação</b>} name='observacao' type='text' placeholder='Observação' tamanho={'col-lg-12 p-0'} classeTooltip={'tooltip-input-modal'} />
                                        </> : <></>)}
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>
                                {carregando === true ? <></> :
                                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                                        <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                    </div>}
                                <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => setSalvando(false)}
                                    esconderFechar mensagem={mensagemModal}
                                    textoOpcao1={'Cancelar'}
                                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                                    textoOpcao2={'Continuar'} />

                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

const ConfirmarAgendamentoSocio = (props) => {

    let id = {}

    const [salvando, setSalvando] = useState(false)
    const [item, setItem] = useState({})
    const [carregando, setCarregando] = useState(false)
    const refAtualizacao = useRef(null)
    const [valoresIniciais, setValoresIniciais] = useState({})

    const [validacao, setValidacao] = useState({})

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        props.plantaoEstabelecimento === false ?
            acoesAgenda(
                values,
                '/administracao/agendareservastatushistorico',
                () => {
                    mostrarToast('sucesso', mensagens.sucesso)
                    setSalvando(false)
                    props.setAbrirModal(false)
                    props.funcaoAposSalvar()
                },
                () => { setSalvando(false) }, null, ACOES.CONFIRMAR_AGENDA, props.modulo)
            :
            acoesAgenda(
                values,
                '/administracao/alterarStatusSocio',
                () => {
                    mostrarToast('sucesso', mensagens.sucesso)
                    setSalvando(false)
                    props.setAbrirModal(false)
                    props.funcaoAposSalvar()
                },
                () => { setSalvando(false) }, null, ACOES.CONFIRMAR_AGENDA, props.modulo)
    }

    useEffect(() => {
        if (props.item && props.item.plantao) {
            setValoresIniciais({ status: 2, sociosSelecionados: props.sociosSelecionados, ...props.item.valoresIniciais })
            setValidacao(Yup.object({
                observacao_status: Yup.string().nullable()
            }))
            setCarregando(false)
        }
        else
            setCarregando(true)
    }, [props.item, props.sociosSelecionados])

    useEffect(() => {
        if (!props.abrirModal) {
            props.setSocioSelecionado([])
            props.setItem([])
        }
    }, [props.abrirModal])

    return (
        <>
            <ModalCadastro titulo={"Confirmar Plantão"} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    initialValues={valoresIniciais}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                    {propsFormik => (
                        <div ref={refAtualizacao}>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <div className={'form-group col-12'}>
                                        {carregando === true ? <LoadRelatorio refLoading={refAtualizacao} heigth={320} /> : (props.item && props.item.plantao ? <>
                                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                                            <InputPadrao label="Status" name="status" type="hidden" placeholder="Status" obrigatorio={true} />
                                            {props.plantaoEstabelecimento === false && props.item && props.item.plantao ? props.item.plantao.map(agenda =>
                                                agenda.visivel !== false &&
                                                <>
                                                    <label className={'width-100 bold'}>{agenda.titulo}</label>
                                                    <label className={'width-100 mb-20'}>{agenda.descricao}</label>
                                                </>
                                            ) : <props.detalhesHtml />}
                                            <TextArea label={<b>Observação</b>} name='observacao_status' type='text' placeholder='Observação' tamanho={'col-lg-12 p-0'} classeTooltip={'tooltip-input-modal'} />
                                        </> : <></>)}
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>
                                {carregando === true ? <></> :
                                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                                        <BotaoPadrao texto='Confirmar' disabled={salvando} click={_ => { }} />
                                        <BotaoPadrao texto='Cancelar' disabled={salvando} click={_ => props.setAbrirModal(false)} />
                                    </div>}
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    )
}

export { ConfirmarAgendamento, TrocarStatus, ConfirmarAgendamentoSocio }