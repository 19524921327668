import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaData, periodoData } from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { ModalDuasOpcoes } from '../../../../components/Modais';
import { get, salvar, validar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao, InputDatePicker, TextArea } from '../../../../components/Inputs'
import { InputUsuarioSocio, InputTipoEscalador, InputUsuario } from '../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import Atalho from '../../../../util/Atalho'

const CadastroEscalador = (props) => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')

    const [validacao] = useState(Yup.object({
        socio: Yup.string().nullable().required(),
        tipo_escalador: Yup.string().nullable().required(),
        data_inicial: Yup.string().required()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) })
            .test('Data Inicial', 'Data Inicial deve ser anterior à Data Final.', function (value) { return periodoData(value, this.parent.data_final) }),
        data_final: Yup.string().required()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) })
            .test('Data Final', 'Data Inicial deve ser anterior à Data Final.', function (value) { return periodoData(this.parent.data_inicial, value) }),
        observacao: Yup.string().max(200)
    }))

    // const ValidarSubmit = (values, resetForm) => {
    //     setSalvando(true)
    //     validar('/administracao/escaladorVerificacao', values, () => Submit(values, resetForm), (e) => {
    //         setShowModalValidacao(true)
    //         setMensagemModal(e.response.data.alertas[0])
    //         setSalvando(false)
    //     })
    // }

    const funcaoAposSim = (formik) => {
        Submit(formik.values, formik.resetForm)
    }

    const manter = ['tipo_escalador',  'data_inicial', 'data_final']

    const Submit = (values, propsFormik) => {
        setSalvando(true)

        salvar(
            values,
            '/administracao/escalador',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)

                if (salvarENovo) {
                    propsFormik.resetForm({})
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                }
                else
                    props.history.push('/administracao/escalador/consulta')
            },
            () => {
                setSalvando(false)
            })
    }

    useEffect(() => {
        if (id) {
            Promise.resolve(get(`/administracao/escalador/${id}`)).then(val => {
                if (!val.data_registro)
                    props.history.push('/administracao/escalador/consulta')

                setValoresIniciais(val)
            })
        } else {
            setValoresIniciais({
                socio: "",
                tipo_escalador: "",
                data_inicial: "",
                data_final: "",
                observacao: ""
            })
        }

    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>
                <div className={props.modal ? '' : 'card mb-20'}>
                    <div className={props.modal ? '' : 'card-body'}>
                        <div className='row'>
                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                            <InputUsuarioSocio label="Sócio" name="socio" placeholder="Sócio" propsFormik={propsFormik} obrigatorio={true} tamanho='col-lg-6' autoFocus
                                salvarENovo={salvarENovo} />
                            <InputTipoEscalador label="Tipo Escalador" name="tipo_escalador" propsFormik={propsFormik} obrigatorio={true} botaoVisivel={true} tamanho='col-lg-6' />
                            <InputDatePicker tamanho="col-lg-6" label="Data Inicial" name="data_inicial" type="text" obrigatorio="true"
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_inicial", value)
                                }}
                            />
                            <InputDatePicker tamanho="col-lg-6" label="Data Final" name="data_final" type="text" obrigatorio="true"
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_final", value)
                                }}
                            />
                            <TextArea label='Observação' name='observacao' type='text' placeholder='Observação' tamanho='col-lg-6' />

                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo}  salvarENovo={true} />

                        </div>
                    </div>
                </div>
                <div className='separator mt-40 mb-5'></div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'}/>}
                </div>
                <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => setSalvando(false)}
                    esconderFechar mensagem={mensagemModal}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
            </Form>)}
        </Formik>
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Administrativo</Link>,
        () => <Link className="link-active" to={'/administracao/escalador/consulta'}>Escalador</Link>,
    ]
    return (<>
        <HeaderCadastro titulo={"Cadastro de Escalador"} link={links} />
        <CadastroEscalador {...props} />
    </>
    )
}
export { CadastroEscalador }
export default Cadastro
