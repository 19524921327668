import React, { useState} from 'react';
import { useWindowSize } from '../util/Utilitarios'
import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { Link } from 'react-router-dom'
import { getUsuario, logout } from '../util/Configuracoes'
import { ModalSenha } from './Modais'
import AlteracaoSenha from '../areas/controleAcesso/usuario/pages/AlteracaoSenha'

const TopBar = (props) => {
    const [width] = useWindowSize();
    const [showModal, setShowModal] = useState(false)
    const user = getUsuario()

    return (
        <>
            <nav className='navbar row mr-0'>
                <div className={width < 500 ? 'botao-menu-mobile' : 'botao-menu-desk'} onClick={() => props.onClick()}>
                    <svg className='icone-topbar' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17"><rect x="0.5" y="0.5" width="25" height="1"></rect><rect x="0.5" y="7.5" width="25" height="1"></rect><rect x="0.5" y="15.5" width="25" height="1"></rect></svg>
                </div>
                <div className={width < 500 ? 'logo-mobile logo-centro' : 'logo-centro logo-desk'}>
                    <Link to={"/dashboard"}>
                        <img alt='logo' className="logo-menu" src={require('../assets/img/logo-menu.png')}></img>
                    </Link>
                </div>
                <Dropdown>
                    <DropdownTrigger>
                        <div className="usuario-logado-clicavel">
                            <div className="usuario-logado">
                                <span className={width < 700 ? 'oculto' : 'nome-logado'}>{user && user.nome}</span>
                                <span className="email-logado icon-User"></span>
                            </div>
                        </div>
                    </DropdownTrigger>
                    <DropdownContent>
                        <div className="card-usuario-logado">
                            <div className="row">
                                <Link className="card-usuario-nav-link" to={"/controleAcesso/usuario/alteracao/" + (user ? user.id : 0) } >
                                    <span className="icone-card-usuario icon-Pencil"></span>
                                    <span className="item-titulo-card-usuario">Alterar dados</span>
                                </Link>
                                <ModalSenha titulo={'Alteração de senha'} showModal={showModal} setShowModal={setShowModal}>
                                    <AlteracaoSenha modal="true" setShow={setShowModal}></AlteracaoSenha>
                                </ModalSenha>
                                <Link className="card-usuario-nav-link" to={"#"}  onClick={async () => {
                                    await logout()
                                    window.location.reload()
                                    }} >
                                    <span className="icone-card-usuario icon-Power"></span>
                                    <span className="item-titulo-card-usuario">Logout</span>
                                </Link>
                            </div>
                        </div>
                    </DropdownContent>
                </Dropdown>
            </nav>
        </>
    )
}


export { TopBar } 