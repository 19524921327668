import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { visualizar } from '../../../../util/AcessoApi'
import { obterDataFormatada } from '../../../../util/Utilitarios'
import { ordenarArrayObj, downloadArquivoS3 } from '../../../../util/FuncoesComuns'



const VisualizacaoSocio = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})


    useEffect(() => {
        if (id) {
            visualizar(`/administracao/socio`, id, obj => {
                setDados(obj)
            })
        }
    }, [id])

    
    const data = dados.nome ? {
        id,
        modulo: props.modulo,
        titulo: 'Visualização do Sócio',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Nome',
                        descricao: dados.nome,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Sigla',
                        descricao: dados.sigla == null ? "" : dados.sigla,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Status',
                        descricao: dados.status == null ? "" : dados.status.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'CPF',
                        descricao: dados.cpf,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'E-mail',
                        descricao: dados.email,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'CRM',
                        descricao: (dados.crm) + "" + (dados.estado_crm == null ? "" : " - " + dados.estado_crm.label),
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Especialidade',
                        descricao: dados.socio_cbos == null ? "" : dados.socio_cbos.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'INSS',
                        descricao: dados.inss,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Título de Eleitor',
                        descricao: dados.titulo_eleitor,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Data de Nascimento',
                        descricao: obterDataFormatada(dados.data_nascimento),
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Município de Naturalidade',
                        descricao: dados.municipio_naturalidade == null ? '' : dados.municipio_naturalidade.label,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Nome do Pai',
                        descricao: dados.nome_pai,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Nome da Mãe',
                        descricao: dados.nome_mae,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'País',
                        descricao: dados.pais == null ? "" : dados.pais.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Raça',
                        descricao: dados.raca == null ? "" : dados.raca.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Estado Cívil',
                        descricao: dados.estado_civil == null ? "" : dados.estado_civil.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Tipo Documento',
                        descricao: dados.tipo_documento == null ? "" : dados.tipo_documento.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Nº Documento',
                        descricao: dados.numero_documento,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Estado Documento',
                        descricao: dados.estado_documento == null ? "" : dados.estado_documento.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'País Emissor',
                        descricao: dados.pais_emissor == null ? "" : dados.pais_emissor.label,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Data Expedição',
                        descricao: obterDataFormatada(dados.data_expedicao),
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Pais Emissor',
                        descricao: dados.pais_emissor == null ? "" : dados.pais_emissor.label,
                        tamanho: 'col-lg-6'
                    }

                ]
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Telefone (s)',
                thead:
                    <tr>
                        <td className='coluna-6'>Tipo</td>
                        <td className='coluna-6'>Telefone</td>
                    </tr>,
                tbody: dados.fone == undefined || dados.fone.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.fone, 'id').map(permissao =>
                        <tr>
                            <td>{permissao.tipo_telefone.label}</td>
                            <td>{permissao.telefone}</td>
                        </tr>)
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Endereço (s)',
                thead:
                    <tr>
                        <td width="5%">Tipo</td>
                        <td width="30%">Logradouro</td>
                        <td width="10%">Número</td>
                        <td width="10%">Bairro</td>
                        <td width="15%">CEP</td>
                        <td width="20%">Complemento</td>
                        <td width="20%">Município</td>
                    </tr>,
                tbody: dados.fone == undefined || dados.fone.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.enderecos, 'id').map(endereco =>
                        <tr>
                            <td>{endereco.tipo == null ? '' : endereco.tipo.label}</td>
                            <td>{endereco.logradouro}</td>
                            <td>{endereco.numero}</td>
                            <td>{endereco.bairro}</td>
                            <td>{endereco.cep}</td>
                            <td>{endereco.complemento}</td>
                            <td>{endereco.municipio.label}</td>

                        </tr>)
            }, {
                tipoGrupo: 'tabela',
                titulo: 'Arquivo(s)',
                thead:
                    <tr>
                        <td className='coluna-6'>Nome</td>
                        <td className='coluna-5'>Tamanho</td>
                        <td className='coluna-1 d-none-print'>Download</td>
                    </tr>,
                tbody: dados.arquivo == undefined || dados.arquivo.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.arquivo, 'nome').map(arquivo =>
                        <tr>
                            <td>{arquivo.name}</td>
                            <td>{arquivo.sizeReadable}</td>
                            <td className="d-none-print"><div className="icone"><button className="btn-acao icon-Download-fromCloud" onClick={() => downloadArquivoS3(arquivo.s3Key)}></button></div></td>
                        </tr>)
            }
            , {
                titulo: 'Dados Cônjuge',
                colunas: [
                    {
                        titulo: 'Nome',
                        descricao: dados.nome_conjuge,
                        tamanho: 'col-lg-12'
                    },
                    {
                        titulo: 'Sexo',
                        descricao: dados.sexo_conjuge == null ? '' : dados.sexo_conjuge.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'CPF',
                        descricao: dados.cpf_conjuge,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Data Nascimento',
                        descricao: obterDataFormatada(dados.data_nascimento_conjuge),
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Tipo Doc.',
                        descricao: dados.tipo_documento_conjuge == null ? '' : dados.tipo_documento_conjuge.label,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Número Doc.',
                        descricao: dados.numero_documento_conjuge,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Estado Doc.',
                        descricao: dados.estado_documento_conjuge == null ? '' : dados.estado_documento_conjuge.label,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Data Expedição',
                        descricao: obterDataFormatada(dados.data_expedicao_conjuge),
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'País Emissor Doc.',
                        descricao: dados.pais_emissor_conjuge == null ? '' : dados.pais_emissor_conjuge.label,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Profissão',
                        descricao: dados.profissao_conjuge == null ? '' : dados.profissao_conjuge.label,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'País',
                        descricao: dados.pais_conjuge == null ? '' : dados.pais_conjuge.label,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Município Naturalidade',
                        descricao: dados.municipio_naturalidade_conjuge == null ? '' : dados.municipio_naturalidade_conjuge.label,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Nome Mãe',
                        descricao: dados.nome_mae_conjuge,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Nome Pai',
                        descricao: dados.nome_pai_conjuge,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Telefone',
                        descricao: '',
                        tamanho: 'col-lg-6'
                    }

                ]
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Dependentes (s)',
                thead:
                    <tr>
                        <td width="40%">Nome</td>
                        <td width="20%">Parentesco</td>
                        <td width="20%">CPF</td>
                        <td width="10%">Início</td>
                        <td width="10%">Término</td>
                    </tr>,
                tbody: dados.dependentes == undefined || dados.dependentes.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.dependentes, 'id').map(dependente =>
                        <tr>
                            <td>{dependente.nome.toUpperCase()}</td>
                            <td>{dependente.parentesco == null ? '-' : dependente.parentesco.label}</td>
                            <td>{dependente.cpf}</td>
                            <td>{dependente.inicio}</td>
                            <td>{dependente.termino}</td>
                        </tr>)
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Sociedade com Empresa (s)',
                thead:
                    <tr>
                        <td width="50%">Empresa</td>
                        <td width="20%">Início</td>
                        <td width="20%">Término</td>
                        <td width ="10%">Fatura Empresa?</td>
                    </tr>,
                tbody: dados.empresa == undefined || dados.empresa.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.empresa, 'id').map(empresa =>
                        <tr>
                            <td>{empresa.empresa.label == undefined ? "" : empresa.empresa.label.toUpperCase()}</td>
                            <td>{obterDataFormatada(empresa.data_inicial)}</td>
                            <td>{obterDataFormatada(empresa.data_final)}</td>
                            <td>{empresa.fatura_empresa.label == undefined ? "" : empresa.fatura_empresa.label.toUpperCase()}</td>
                        </tr>)
            }
            ,
            {
                tipoGrupo: 'tabela',
                titulo: 'Dados do Grupo (s)',
                thead:
                    <tr>
                        <td width="50%">Grupo</td>
                        <td width="25%">Início</td>
                        <td width="25%">Término</td>
                    </tr>,
                tbody: dados.grupo == undefined || dados.grupo.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.grupo, 'id').map(grupo =>
                        <tr>
                            <td>{grupo.grupo == null ? "" : grupo.grupo.label}</td>
                            <td>{obterDataFormatada(grupo.data_inicial)}</td>
                            <td>{obterDataFormatada(grupo.data_final)}</td>
                        </tr>)
            }, {
                titulo: 'Dados da Admissão',
                colunas: [
                    {
                        titulo: 'Data Admissão',
                        descricao: obterDataFormatada(dados.data_admissao),
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data Demissao',
                        descricao: obterDataFormatada(dados.data_demissao),
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Observação Admissão',
                        descricao: dados.observacao_admissao,
                        tamanho: 'col-lg-12'
                    },
                    {
                        titulo: 'Data Hierarquização',
                        descricao: obterDataFormatada(dados.data_hierarquizacao),
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Posição',
                        descricao: dados.posicao_hierarquizacao,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Observação Hierarquização',
                        descricao: dados.observacao_hierarquizacao,
                        tamanho: 'col-lg-12'
                    },
                    {
                        titulo: 'Data Alinhamento',
                        descricao: obterDataFormatada(dados.data_alinhamento_economico),
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Observação Alinhamento',
                        descricao: dados.observacao_alinhamento,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Rotina Parcial?',
                        descricao: dados.participa_rotina ? 'SIM' : 'NÃO',
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Observação Outros',
                        descricao: dados.observacao_outros,
                        tamanho: 'col-lg-6'
                    }
                ]
            }, {
                tipoGrupo: 'tabela',
                titulo: 'Histórico Hierarquização',
                thead:
                    <tr>
                        <td className='coluna-3'>Data</td>
                        <td className='coluna-3'>Posição</td>
                        <td className='coluna-4'>Observação</td>
                        <td className='coluna-2'>Data Registro</td>
                    </tr>,
                tbody: dados.historico_hierarquizacao == undefined || dados.historico_hierarquizacao.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    dados.historico_hierarquizacao.map(val =>
                        <tr>
                            <td>{val.data}</td>
                            <td>{val.posicao}</td>
                            <td>{val.observacao}</td>
                            <td>{val.data_registro}</td>
                        </tr>)
            }, {
                tipoGrupo: 'tabela',
                titulo: 'Histórico Admissão',
                thead:
                    <tr>
                        <td className='coluna-3'>Data Admissão</td>
                        <td className='coluna-3'>Data Demissão</td>
                        <td className='coluna-4'>Observação</td>
                        <td className='coluna-2'>Data Registro</td>
                    </tr>,
                tbody: dados.historico_admissao == undefined || dados.historico_admissao.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    dados.historico_admissao.map(val =>
                        val.data_admissao || val.data_demissao || val.observacao_admissao ? <tr>
                            <td>{val.data_admissao}</td>
                            <td>{val.data_demissao}</td>
                            <td>{val.observacao_admissao}</td>
                            <td>{val.data_registro}</td>
                        </tr> : <></>)
            }, {
                tipoGrupo: 'tabela',
                titulo: 'Histórico Alinhamento Econômico',
                thead:
                    <tr>
                        <td className='coluna-4'>Data Alinhamento</td>
                        <td className='coluna-6'>Observação</td>
                        <td className='coluna-2'>Data Registro</td>
                    </tr>,
                tbody: dados.historico_alinhamento == undefined || dados.historico_alinhamento.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    dados.historico_alinhamento.map(val =>
                        val.data_alinhamento || val.observacao_alinhamento ? <tr>
                            <td>{val.data_alinhamento}</td>
                            <td>{val.observacao_alinhamento}</td>
                            <td>{val.data_registro}</td>
                        </tr> : <></>)
            }, {
                tipoGrupo: 'tabela',
                titulo: 'Histórico Outros',
                thead:
                    <tr>
                        <td className='coluna-4'>Participa Rotina?</td>
                        <td className='coluna-6'>Observação</td>
                        <td className='coluna-2'>Data Registro</td>
                    </tr>,
                tbody: dados.historico_outros == undefined || dados.historico_outros.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    dados.historico_outros.map(val =>
                        val.participa_rotina || val.observacao_outros ? <tr>
                            <td>{val.participa_rotina}</td>
                            <td>{val.observacao_outros}</td>
                            <td>{val.data_registro}</td>
                        </tr> : <></>)
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Dados do Ocorrência (s)',
                thead:
                    <tr>
                        <td width="40%">Tipo</td>
                        <td width="30%">Implica na Hierarquia?</td>
                        <td width="15%">Início</td>
                        <td width="15%">Término</td>
                    </tr>,
                tbody: dados.ocorrencias == undefined || dados.ocorrencias.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.ocorrencias, 'id').map(ocorrencia =>
                        <tr>
                            <td>{ocorrencia.tipo_ocorrencia == null ? "" : ocorrencia.tipo_ocorrencia.label}</td>
                            <td>{ocorrencia.implica_hierarquia == null ? "" : ocorrencia.implica_hierarquia.label}</td>
                            <td>{obterDataFormatada(ocorrencia.data_inicio)}</td>
                            <td>{obterDataFormatada(ocorrencia.data_fim)}</td>
                        </tr>)
            }
        ]
    } : { titulo: 'Visualização do Sócio' }

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoSocio