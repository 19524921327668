import React, { useState, useEffect, useRef } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'

const Consulta = (props) => {
    const [data, setData] = useState([])
    const [pageIndex, setPageIndex] = useState()
    const [qtdRegistros, setQtdRegistros] = useState()

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-auditoria',
                qtdRegistros: qtdRegistros,
                modulo: props.modulo,
                paginacaoManual: true,
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Usuário',
                        accessor: 'usuario',
                        filtro: {
                            combo: 'combo',
                            tipo: 'usuario_auditoria',
                            idColuna: 'usuario_id'
                        },
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Ação',
                        accessor: 'acao',
                        ordenamento: {
                            tipo: 'combo',
                        },
                        filtro: {
                            combo: 'combo-alternativa',
                            tipo: 'acoes',
                            idColuna: 'acao.id'
                        },
                    },
                    {
                        Header: 'Módulo',
                        accessor: 'modulo',
                        ordenamento: {
                            tipo: 'combo',
                        },
                        visivel: false,
                        filtro: {
                            combo: 'combo-alternativa',
                            tipo: 'modulo',
                            idColuna: 'modulo.id'
                        },
                        filtroSomenteModal: true
                    },
                    {
                        Header: 'IP Usuário',
                        accessor: 'ip_usuario',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        visivel: true,
                    },
                    {
                        Header: 'Data  Ação',
                        accessor: 'data_acao',
                        ordenamento: {
                            tipo: 'data',
                        },
                        classeThead: 'teste semAcoes',
                        classeTd: 'teste semAcoes',

                    },
                    {
                        Header: 'Data Inicial',
                        accessor: 'data_inicial',
                        ordenamento: {
                            tipo: 'data',
                        },
                        visivel: false,
                        filtro: {
                            tipo: 'data_inicial',
                            name: 'data_inicial',
                            idColuna: 'data_inicial'
                        }
                    },
                    {
                        Header: 'Data Final',
                        accessor: 'data_final',
                        ordenamento: {
                            tipo: 'data',
                        },
                        visivel: false,
                        filtro: {
                            tipo: 'data_final',
                            name: 'data_final',
                            idColuna: 'data_final'
                        }
                    },


                ]
            }], []
    )

    const semAcoesObj = []

    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} setData={setData} acoesObj={semAcoesObj} acoes={false} caminhoBase='/controleAcesso/auditoria' ImpressaoOff={true}
                    tituloLista={'Lista Auditoria'} setPageIndex={setPageIndex} setQtdRegistros={setQtdRegistros} caminhoConsultaFiltros='/controleAcesso/auditoria'
                    modulo={props.modulo} titulo={'Consulta Auditoria'} icone={'icon-Business-ManWoman'} removerTotalPaginas={true} />
            </div>
        </>
    )
}

export default Consulta