import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import '../assets/css/icones/iconsmind/style.css';
import { obterMenuComPermissoes, setSistemaVersao } from '../util/Configuracoes';
import packageJson from '../app_version.json';
import { useInterval } from '../util/Utilitarios';

const Menu = props => {
    const obterAtivo = (props, item) => {
        let path = props.children.props.active ? props.children.props.active : props.children.props.path

        if (props.ativo === item.id || (props.ativo === "" && item.caminho === props.children.props.path))
            return true

        if (item.submenu && item.submenu.map((subItem) => subItem.caminho).includes(path))
            return true

        return false
    }

    const [menu, setMenu] = useState([]);

    useEffect(async () => {
        setMenu(await obterMenuComPermissoes())
    }, [])
    const [versao, setVersao] = useState('')

    useEffect(async () => {
        await setSistemaVersao(setVersao)
    }, [])

    // useInterval(() => {
    //     setSistemaVersao(setVersao)
    // }, 20000);

    return (
        <ul className="nav menu table-wrapper-scroll-y custom-scrollbar" >

            {menu.filter(x => x.permitido).map((item, index, array) =>
                <li className="nav-item" key={index} >
                    <Link className={"nav-link" + (obterAtivo(props, item) ? ' menu-active' : '')} to={item.caminho ? item.caminho : '#'} data-flag={item.id} onClick={() => props.onClick(item.id)}>
                        <span className={"icone-menu " + item.icone}></span>
                        <span>{item.nome} </span>
                    </Link>
                    {index === array.length - 1 &&
                        <div style={{ padding: 10, marginLeft: 10 }}>
                            <span className="versao-app" >F: {packageJson.version} <br />B: {versao}</span>
                        </div>
                    }
                </li>
            )}
        </ul>
    )
}

export { Menu } 