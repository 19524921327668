import React, { useState, useEffect } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputDatePicker } from '../../../components/Inputs'
import { InputTipoRelatorio, InputSocio, InputPeriodoGozo, InputPeriodoFerias, InputTipoRelatorioPersonalizado } from '../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes'
import * as moment from 'moment'
import { Link } from 'react-router-dom'
import MensagemPadraoRelatorio from '../../../components/RelatorioEspecifico/RelatorioSemDados'
import { MODULOS_SISTEMA } from '../../../util/Enums';

export default props => {
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Procedimento')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '' })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)

    const tipoRelatorio = [{ label: 'Profissional Utilizado x Recebido', value: MODULOS_SISTEMA.RELATORIO_PROFISSIONAL_UTILIZADO_RECEBIDO }, { label: 'Profissional Utilizado x Recebido Resumo', value: MODULOS_SISTEMA.RELATORIO_PROFISSIONAL_UTILIZADO_RECEBIDO_RESUMO }]

    const validacao = Yup.object({
        periodo: Yup.string().nullable().required(),
        socio: Yup.string().nullable(),
        data_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) }),
        data_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) })
    })

    const relatorioProfissionalUtilizadoRecebido = (array, arrayConfigCol, configOrdenamento) => {
        return (
            array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) =>
                <table className='table-agrupamento'>
                    {(configOrdenamento.arrayOrdenamento[idx] && configOrdenamento.arrayOrdenamento[idx].filter(item => item === true).length > 0) && <tr><td colspan={`${x.colunas ? x.colunas.filter(y => y.visivel).length : 0}`}
                        className={'bold'}><Link to='#' className='d-none-print' onClick={() => configOrdenamento.limparOrdenamentos(idx)}>Remover ordenamentos</Link></td></tr>}
                    {
                        x.data && x.data.map(grupo => {
                            const total_marcacao = grupo.marcacao && (grupo.marcacao.reduce((a, b) => a + (parseFloat(b.quantidade) || 0), 0) / 12) || 0
                            const total_mercado = grupo.mercado && ((grupo.mercado.filter(x => !x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0)) - (grupo.mercado.filter(x => x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0
                            const total_venda = (grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 1 && x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0) * -1
                            const total_compra = grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 1 && !x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0
                            const total_devolucao_recebido = grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 2 && !x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0
                            const total_devolucao_enviado = (grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 2 && x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0) * -1
                            const total_doacao_recebido = grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 3 && !x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0
                            const total_doacao_enviado = (grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 3 && x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0) * -1
                            const total_emprestimo_recebido = grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 4 && !x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0
                            const total_emprestimo_enviado = (grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 4 && x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0) * -1

                            return (<>
                                <tr className="group-title">
                                    {x.colunas && x.colunas.map((y, idy) => {
                                        let classes = (y.ordenamento ? 'ordenavel ' : '') + (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].ordenamento.ativo && arrayConfigCol[idx][idy].coluna === y.name ? 'ordenamento_' + arrayConfigCol[idx][idy].ordenamento.modo : '');
                                        return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) && <td onClick={(event) => {
                                            if (y.ordenamento)
                                                configOrdenamento.setOrdenamento(idy, x.data, idx)
                                        }} style={{ width: y.width || 'auto' }} className={`bold coluna ${y.alinhamento && y.alinhamento || ''} ${classes}`}>{y.label}</td>
                                    })}
                                </tr>
                                <tr className={'titulo-1'}><td colspan={x.colunas.length}><div style={{ display: 'flex', justifyContent: 'space-between' }}><span>{grupo.titulo}</span> <span>Ano (Gozo): {grupo.ano + ' (' + grupo.periodo_inicial + ' a ' + grupo.periodo_final + ')'}</span><span>Dias Recebido: {grupo.dias_direito || 0}</span></div></td> </tr>
                                <tr><td colspan={x.colunas.length}><span></span></td> </tr>
                                <tr className={'titulo-2 bold'}><td colspan={x.colunas.length}>Marcação</td> </tr>
                                {
                                    grupo.marcacao && (configOrdenamento.arrayOrdenamento ? configOrdenamento.obterDataOrdenado(idx, grupo.marcacao).map((marcacao, index) => {
                                        return <tr>
                                            <td style={{ width: '50px' }} className={`td-data text-center`}>{(index + 1).toString().padStart(grupo.marcacao.length.toString().length, '0')}</td>
                                            <td className={`td-data`}>{marcacao.data}</td>
                                            <td className={`td-data`}>{marcacao.horario}</td>
                                            <td className={`td-data text-right`}>{marcacao.quantidade / 12}</td>
                                            <td></td>
                                            <td></td>
                                            <td className={`td-data`}>{marcacao.observacao}</td>
                                            <td className={`td-data`}>{marcacao.data_registro}</td>
                                        </tr>
                                    }) : <></>)
                                }
                                <tr><td className={`td-data text-center`} style={{ borderBottom: '1px #000 solid' }} colspan={x.colunas.length}></td></tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right bold`}>Total de Dias (Marcação): </td>
                                    <td className={`td-data text-right bold`}>{total_marcacao}</td>
                                </tr>
                                <tr className={'titulo-2 bold'}><td colspan={x.colunas.length}>Mercado</td> </tr>
                                {
                                    grupo.mercado && (configOrdenamento.arrayOrdenamento ? configOrdenamento.obterDataOrdenado(idx, grupo.mercado).map((mercado, index) => {
                                        return <tr>
                                            <td className={`td-data text-center`}>{(index + 1).toString().padStart(grupo.mercado.length.toString().length, '0')}</td>
                                            <td className={`td-data`}>{mercado.data}</td>
                                            <td className={`td-data`}>{mercado.uso}</td>
                                            <td className={`td-data text-right`}>{mercado.remetente ? mercado.total_horas * -1 : mercado.total_horas}</td>
                                            <td className={`td-data`}>{mercado.socio_remetente ? mercado.sigla_remetente + ' - ' + mercado.socio_remetente : 'CONSELHO'}</td>
                                            <td className={`td-data`}>{mercado.socio_destinatario ? mercado.sigla_destinatario + ' - ' + mercado.socio_destinatario : 'CONSELHO'}</td>
                                            <td className={`td-data`}>{mercado.observacao}</td>
                                            <td className={`td-data`}>{mercado.data_registro}</td>
                                        </tr>
                                    }) : <></>)
                                }
                                <tr><td className={`td-data text-center`} style={{ borderBottom: '1px #000 solid' }} colspan={x.colunas.length}></td></tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right bold`}>Total de Dias (Mercado): </td>
                                    <td className={`td-data text-right bold`}>{total_mercado}</td>
                                </tr>
                                <tr><td className={`td-data text-center`} style={{ borderBottom: '1px #000 dashed' }} colspan={x.colunas.length}></td></tr>
                                <tr className={'titulo-2 bold'}>
                                    <td colspan={x.colunas.length} className={`td-data text-right`}>Resumo(Ano(Gozo): {grupo.ano + ' (' + grupo.periodo_inicial + ' a ' + grupo.periodo_final + ')'}</td>
                                </tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right`}>Dias Recebido (+)</td>
                                    <td className={`td-data text-right`}>{grupo.dias_direito}</td>
                                </tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right`}>Marcado(-)</td>
                                    <td className={`td-data text-right`}>{grupo.total_marcacao / 12 * -1}</td>
                                </tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right`}>Compra (+)</td>
                                    <td className={`td-data text-right`}>{parseFloat(grupo.total_compra || 0, 0)}</td>
                                </tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right`}>Venda (-)</td>
                                    <td className={`td-data text-right`}>{parseFloat(grupo.total_venda * -1 || 0, 0)}</td>
                                </tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right`}>Devolução (+)</td>
                                    <td className={`td-data text-right`}>{parseFloat(grupo.total_devolucao_recebido, 0) || 0}</td>
                                </tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right`}>Devolução (-)</td>
                                    <td className={`td-data text-right`}>{parseFloat(grupo.total_devolucao_enviado, 0) * -1 || 0}</td>
                                </tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right`}>Doação (+)</td>
                                    <td className={`td-data text-right`}>{parseFloat(grupo.total_doacao_recebido, 0) || 0}</td>
                                </tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right`}>Doação (-)</td>
                                    <td className={`td-data text-right`}>{parseFloat(grupo.total_doacao_enviado, 0) * -1 || 0}</td>
                                </tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right`}>Empréstimo (+)</td>
                                    <td className={`td-data text-right`}>{parseFloat(grupo.total_emprestimo_recebido, 0) || 0} </td>
                                </tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right`}>Empréstimo (-)</td>
                                    <td className={`td-data text-right`}>{parseFloat(grupo.total_emprestimo_enviado, 0) * -1 || 0} </td>
                                </tr>
                                <tr>
                                    <td className={`td-data text-center`} colspan={x.colunas.length - 2}></td>
                                    <td className={`td-data text-right bold`}>Saldo</td>
                                    <td className={`td-data text-right bold`}>{parseFloat(grupo.dias_direito, 0) - parseFloat(grupo.total_marcacao / 12 || 0, 0) + parseFloat(grupo.total_compra || 0, 0) - parseFloat(grupo.total_venda || 0, 0) + parseFloat(grupo.total_devolucao_recebido || 0, 0) - parseFloat(grupo.total_devolucao_enviado || 0, 0) + parseFloat(grupo.total_doacao_recebido || 0, 0) - parseFloat(grupo.total_doacao_enviado || 0, 0) + parseFloat(grupo.total_emprestimo_recebido || 0, 0) - parseFloat(grupo.total_emprestimo_enviado || 0, 0)}</td>
                                </tr>
                            </>
                            )
                        })
                    }
                </table>
            ) : <MensagemPadraoRelatorio />


        )
    }

    const relatorioProfissionalUtilizadoRecebidoResumo = (array, arrayConfigCol, configOrdenamento) => {
        return (
            array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {
                return (
                    <table className='table-agrupamento'>
                        {
                            x.data && x.data.map(grupo => {
                                let data_atual = moment(grupo.periodo_inicial, 'DD/MM/YYYY')
                                const colunas = []

                                while (data_atual.format('MM/YYYY') !== moment(grupo.periodo_final, 'DD/MM/YYYY').add(1, 'months').format('MM/YYYY')) {
                                    colunas.push(data_atual.format('MM/YYYY'))
                                    data_atual = data_atual.add(1, 'months')
                                }

                                const total_marcacao = grupo.marcacao && (grupo.marcacao.reduce((a, b) => a + (parseFloat(b.quantidade) || 0), 0) / 12) || 0
                                const total_mercado = grupo.mercado && ((grupo.mercado.filter(x => !x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0)) - (grupo.mercado.filter(x => x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0
                                // const total_venda = (grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 1 && x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0) * -1
                                // const total_compra = grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 1 && !x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0
                                const total_devolucao_recebido = grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 2 && !x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0
                                const total_devolucao_enviado = (grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 2 && x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0) * -1
                                const total_doacao_recebido = grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 3 && !x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0
                                const total_doacao_enviado = (grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 3 && x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0) * -1
                                const total_emprestimo_recebido = grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 4 && !x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0
                                const total_emprestimo_enviado = (grupo.mercado && ((grupo.mercado.filter(x => x.mercado_tipo_id === 4 && x.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0) * -1

                                return (<>
                                    <tr className="group-title">
                                        {x.colunas && x.colunas.map((y, idy) => {
                                            let classes = (y.ordenamento ? 'ordenavel ' : '') + (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].ordenamento.ativo && arrayConfigCol[idx][idy].coluna === y.name ? 'ordenamento_' + arrayConfigCol[idx][idy].ordenamento.modo : '');
                                            return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) && <td style={{ width: y.width || 'auto' }} className={`bold coluna ${y.alinhamento && y.alinhamento || ''} ${classes}`}>{y.label}</td>
                                        })}
                                    </tr>
                                    <tr className={'titulo-1'}><td colspan={x.colunas.length}><div style={{ display: 'flex', justifyContent: 'space-between' }}><span>{grupo.titulo}</span> <span>Ano (Gozo): {grupo.ano + ' (' + grupo.periodo_inicial + ' a ' + grupo.periodo_final + ')'}</span><span>Dias Recebido: {grupo.dias_direito || 0}</span></div></td> </tr>
                                    <tr><td colspan={x.colunas.length}><span></span></td> </tr>
                                    <tr>
                                        <td className={`td-data`}>Marcado/Utilizado</td>
                                        {colunas.map(data => {
                                            return (
                                                <td className={`td-data text-right`}>{grupo.marcacao && (grupo.marcacao.filter(z => moment(z.data, 'DD/MM/YYYY').format('MM/YYYY') === data).reduce((a, b) => a + (parseFloat(b.quantidade) || 0), 0) / 12) * -1 || 0}</td>
                                            )
                                        })}
                                    </tr>
                                    <tr><td colspan={x.colunas.length}><span></span></td> </tr>
                                    <tr>
                                        <td className={`td-data`}>Venda/Compra</td>
                                        {colunas.map(data => {
                                            return (
                                                <td className={`td-data text-right`}>{grupo.mercado && ((grupo.mercado.filter(z => moment(z.data, 'DD/MM/YYYY').format('MM/YYYY') === data && z.mercado_tipo_id === 1 && !z.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0) - grupo.mercado.filter(z => moment(z.data, 'DD/MM/YYYY').format('MM/YYYY') === data && z.mercado_tipo_id === 1 && z.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0))) || 0}</td>
                                            )
                                        })}
                                    </tr>
                                    <tr><td colspan={x.colunas.length}><span></span></td> </tr>
                                    <tr>
                                        <td className={`td-data`}>Devolução</td>
                                        {colunas.map(data => {
                                            return (
                                                <td className={`td-data text-right`}>{grupo.mercado && (grupo.mercado.filter(z => moment(z.data, 'DD/MM/YYYY').format('MM/YYYY') === data && z.mercado_tipo_id === 2 && !z.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0) - grupo.mercado.filter(z => moment(z.data, 'DD/MM/YYYY').format('MM/YYYY') === data && z.mercado_tipo_id === 2 && z.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0)) || 0}</td>
                                            )
                                        })}
                                    </tr>
                                    <tr><td colspan={x.colunas.length}><span></span></td> </tr>
                                    <tr>
                                        <td className={`td-data`}>Doação </td>
                                        {colunas.map(data => {
                                            return (
                                                <td className={`td-data text-right`}>{grupo.mercado && (grupo.mercado.filter(z => moment(z.data, 'DD/MM/YYYY').format('MM/YYYY') === data && z.mercado_tipo_id === 3 && !z.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0) - grupo.mercado.filter(z => moment(z.data, 'DD/MM/YYYY').format('MM/YYYY') === data && z.mercado_tipo_id === 3 && z.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0)) || 0}</td>
                                            )
                                        })}
                                    </tr>
                                    <tr><td colspan={x.colunas.length}><span></span></td> </tr>
                                    <tr>
                                        <td className={`td-data`}>Empréstimo  </td>
                                        {colunas.map(data => {
                                            return (
                                                <td className={`td-data text-right`}>{grupo.mercado && (grupo.mercado.filter(z => moment(z.data, 'DD/MM/YYYY').format('MM/YYYY') === data && z.mercado_tipo_id === 4 && !z.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0) - grupo.mercado.filter(z => moment(z.data, 'DD/MM/YYYY').format('MM/YYYY') === data && z.mercado_tipo_id === 4 && z.remetente).reduce((a, b) => a + (parseFloat(b.total_horas) || 0), 0)) || 0}</td>
                                            )
                                        })}
                                    </tr>
                                    <tr><td className={`td-data text-center`} style={{ borderBottom: '1px #000 solid' }} colspan={x.colunas.length}></td></tr>
                                    <tr>
                                        <td className={`td-data text-right bold`} colspan={x.colunas.length - 1}>Total Marcado/Utilizado: </td>
                                        <td className={`td-data text-right bold`}>{total_marcacao}</td>
                                    </tr>
                                    <tr><td className={`td-data text-center`} style={{ borderBottom: '1px #000 dashed' }} colspan={x.colunas.length}></td></tr>
                                    <tr className={'titulo-2 bold'}>
                                        <td colspan={x.colunas.length} className={`td-data text-right`}>Resumo(Ano(Gozo): {grupo.ano + ' (' + grupo.periodo_inicial + ' a ' + grupo.periodo_final + ')'}</td>
                                    </tr>
                                    <tr>
                                        <td className={`td-data text-right`} colspan={x.colunas.length - 1}>Dias Recebido (+)</td>
                                        <td className={`td-data text-right`}>{grupo.dias_direito || 0}</td>
                                    </tr>
                                    <tr>
                                        <td className={`td-data text-right`} colspan={x.colunas.length - 1}>Marcado(-)</td>
                                        <td className={`td-data text-right`}>{grupo.total_marcacao / 12 * -1}</td>
                                    </tr>
                                    <tr>
                                        <td className={`td-data text-right`} colspan={x.colunas.length - 1}>Compra (+)</td>
                                        <td className={`td-data text-right`}>{parseFloat(grupo.total_compra || 0, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td className={`td-data text-right`} colspan={x.colunas.length - 1}>Venda (-)</td>
                                        <td className={`td-data text-right`}>{parseFloat(grupo.total_venda * -1 || 0, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td className={`td-data text-right`} colspan={x.colunas.length - 1}>Devolução (+)</td>
                                        <td className={`td-data text-right`}>{parseFloat(grupo.total_devolucao_recebido, 0) || 0}</td>
                                    </tr>
                                    <tr>
                                        <td className={`td-data text-right`} colspan={x.colunas.length - 1}>Devolução (-)</td>
                                        <td className={`td-data text-right`}>{parseFloat(grupo.total_devolucao_enviado, 0) * -1 || 0}</td>
                                    </tr>
                                    <tr>
                                        <td className={`td-data text-right`} colspan={x.colunas.length - 1}>Doação (+)</td>
                                        <td className={`td-data text-right`}>{parseFloat(grupo.total_doacao_recebido, 0) || 0}</td>
                                    </tr>
                                    <tr>
                                        <td className={`td-data text-right`} colspan={x.colunas.length - 1}>Doação (-)</td>
                                        <td className={`td-data text-right`}>{parseFloat(grupo.total_doacao_enviado, 0) * -1 || 0}</td>
                                    </tr>
                                    <tr>
                                        <td className={`td-data text-right`} colspan={x.colunas.length - 1}>Empréstimo (+)</td>
                                        <td className={`td-data text-right`}>{parseFloat(grupo.total_emprestimo_recebido, 0) || 0} </td>
                                    </tr>
                                    <tr>
                                        <td className={`td-data text-right`} colspan={x.colunas.length - 1}>Empréstimo (-)</td>
                                        <td className={`td-data text-right`}>{parseFloat(grupo.total_emprestimo_enviado, 0) * -1 || 0} </td>
                                    </tr>
                                    <tr>
                                        <td className={`td-data text-right bold`} colspan={x.colunas.length - 1}>Saldo</td>
                                        <td className={`td-data text-right bold`}>{parseFloat(grupo.dias_direito, 0) - parseFloat(grupo.total_marcacao / 12 || 0, 0) + parseFloat(grupo.total_compra || 0, 0) - parseFloat(grupo.total_venda || 0, 0) + parseFloat(grupo.total_devolucao_recebido || 0, 0) - parseFloat(grupo.total_devolucao_enviado || 0, 0) + parseFloat(grupo.total_doacao_recebido || 0, 0) - parseFloat(grupo.total_doacao_enviado || 0, 0) + parseFloat(grupo.total_emprestimo_recebido || 0, 0) - parseFloat(grupo.total_emprestimo_enviado || 0, 0)}</td>

                                    </tr>
                                </>
                                )
                            })
                        }
                    </table>
                )
            }
            ) :
                <MensagemPadraoRelatorio />


        )
    }

    const relatoriosPorTipo = propsFormik => [
        {
            titulo: 'Profissional Utilizado X Recebido',
            tipo: MODULOS_SISTEMA.RELATORIO_PROFISSIONAL_UTILIZADO_RECEBIDO,
            url: '/administracao/ferias/relatorioprofissionalutilizadorecebido',
            campos: [
                <InputPeriodoGozo label='Ano (Gozo)' name="periodo" propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} obrigatorio />,
                <InputSocio label='Sócio(s)' name="socio_id" propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputDatePicker label="Data Inicial" name="data_inicial" type="text" autoFocus
                    setarValor={value => { propsFormik.setFieldValue("data_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final" name="data_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputPeriodoFerias label="Período" name="periodo_ferias" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            ],
            colunas: [
                { label: 'Data', name: 'data', visivel: true, ordenamento: { tipo: 'data', ativo: true }, width: '8%' },
                { label: 'Período', name: 'horario', visivel: true, ordenamento: { tipo: 'data', ativo: true }, width: '10%' },
                { label: 'Qtde Dias', name: 'quantidade', visivel: true, ordenamento: { tipo: 'numero', ativo: false, }, width: '9%', alinhamento: 'text-right' },
                { label: 'Remetente(De)', name: 'remetente', visivel: true, ordenamento: { tipo: 'texto', ativo: false, }, width: '19.5%' },
                { label: 'Destinatário(Para)', name: 'destinatario', visivel: true, ordenamento: { tipo: 'texto', ativo: false, }, width: '19.5%' },
                { label: 'Observacao', name: 'observacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false, }, width: '22%' },
                { label: 'Data Registro', name: 'data_registro', visivel: true, ordenamento: { tipo: 'data', ativo: false, }, width: '12%' },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Ano (Gozo)', name: 'periodo', name_coluna: 'periodo' },
                { label: 'Sócio(s)', name: 'socio', name_coluna: 'socio_id' },
                { label: 'Período', name: 'periodo_ferias', name_coluna: 'periodo_ferias' },
                { label: 'Data Inicial', name: 'data_inicial', name_coluna: 'data_inicial' },
                { label: 'Data Final', name: 'data_final', name_coluna: 'data_final' },
            ],
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento) => relatorioProfissionalUtilizadoRecebido(dados, arrayConfigCol, configOrdenamento)
        },
        {
            titulo: 'Profissional Utilizado X Recebido Resumo',
            tipo: MODULOS_SISTEMA.RELATORIO_PROFISSIONAL_UTILIZADO_RECEBIDO_RESUMO,
            url: '/administracao/ferias/relatorioprofissionalutilizadorecebidoResumo',
            campos: [
                <InputPeriodoGozo label='Ano (Gozo)' name="periodo" propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} obrigatorio />,
                <InputSocio label='Sócio(s)' name="socio_id" propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputDatePicker label="Data Inicial" name="data_inicial" type="text" autoFocus
                    setarValor={value => { propsFormik.setFieldValue("data_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final" name="data_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputPeriodoFerias label="Período" name="periodo_ferias" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />
            ],
            colunas: [],
            data: [],
            colunasFiltros: [
                { label: 'Ano (Gozo)', name: 'periodo', name_coluna: 'periodo' },
                { label: 'Sócio(s)', name: 'socio', name_coluna: 'socio_id' },
                { label: 'Período', name: 'periodo_ferias', name_coluna: 'periodo_ferias' },
                { label: 'Data Inicial', name: 'data_inicial', name_coluna: 'data_inicial' },
                { label: 'Data Final', name: 'data_final', name_coluna: 'data_final' },
            ],
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento) => relatorioProfissionalUtilizadoRecebidoResumo(dados, arrayConfigCol, configOrdenamento),
        }
    ]


    const submit = async (values, propsFormik) => {
        setCarregando(true)
        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]

        setShowModalFiltros(false)
        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio) {
            if (values.tipo_relatorio.value === MODULOS_SISTEMA.RELATORIO_PROFISSIONAL_UTILIZADO_RECEBIDO_RESUMO) {

                await Promise.resolve(get('/administracao/ferias/intervaloperiodogozo', { value: values.periodo.value })).then(periodo => {
                    let data_atual = moment(periodo.periodo_inicial, 'DD/MM/YYYY')
                    const colunas = []

                    while (data_atual.format('MM/YYYY') !== moment(periodo.periodo_final, 'DD/MM/YYYY').add(1, 'months').format('MM/YYYY')) {
                        colunas.push({ alinhamento: 'text-right', label: data_atual.format('MM/YYYY'), name: data_atual.format('MM/YYYY'), visivel: true, width: '' })
                        data_atual = data_atual.add(1, 'months')
                    }

                    colunas.map(x => x.width = (85 / colunas.length) + '%')

                    novoRelatorio.colunas = colunas
                    setRelatorioAtual({ ...novoRelatorio })

                })
            }
            else {

                setRelatorioAtual({ ...novoRelatorio })
            }

            setFiltros(Object.keys(values).map((x, idx, array) => {
                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: x === 'duracao_minima' || x === 'duracao_maxima' ? valorAtual.split(':')[0].replace(/_/g, '').toString().padStart(2, '0') + 'h ' +
                        valorAtual.split(':')[1].split(/_/g, '').toString().padStart(2, '0') + 'min' : typeof valorAtual === 'string' ? valorAtual : valorAtual.length ?
                            valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                })
            }
            ).filter(x => x))
        }
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }
        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{ numerarLinha: cloneRelatorioAtual.numerarLinha !== undefined ? cloneRelatorioAtual.numerarLinha : true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data, relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento) : null }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: filtros })
    }, [filtros])

    const changeTipo = (value) => {
        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({})
        // const index = tipoRelatorio.findIndex(x => x.value === value)
        setValuesFiltros({ tipo_relatorio: value })
        setRelatorioSelecionado(value?.value)
    }

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })}
            agrupamento={[{ coluna: 'nome', descricao: 'nome' }]}>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        <InputTipoRelatorioPersonalizado label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                            propsFormik={propsFormik} modulos={relatoriosPorTipo(propsFormik).map(x => x.tipo)} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} />
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}
                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}

