
import React, { useState, useEffect } from 'react'
import Yup from '../../../../util/Validacoes'
import { HeaderCadastro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { formatarMoeda, mensagens } from '../../../../util/Utilitarios'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { putComAcao, validarComPost } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'
import { ModalDuasOpcoes } from '../../../../components/Modais'
import { obterFeriados, obterGuiasConferenciaMapaHoras } from '../../../../util/FuncoesComuns'
import { ACOES } from '../../../../util/Enums'
import Interweave from 'interweave'
import { CadastroExecutantesInternos } from '../../../faturamento/guia/pages/Cadastro'
import { getUsuario } from '../../../../util/Configuracoes'

export default props => {
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [guias, setGuias] = useState([])
    const [dados, setDados] = useState()
    const [minimizar] = useState([])
    const location = useLocation()
    const [carregando, setCarregando] = useState(false);
    const [feriados, setFeriados] = useState([])
    const [validacao] = useState(Yup.lazy(({ modalAberto }) => Yup.object({
        status: Yup.string().nullable().required(),
    })))

    useEffect(() => {
        if (location?.state?.id) {
            let executantes_internos = [{ executante: undefined, hora_inicial: undefined, hora_final: undefined, tipo_atendimento: { value: 1, label: 'Rotina' } }]
            setCarregando(true)

            obterGuiasConferenciaMapaHoras({ mapa_hora_id: location.state?.mapa_hora_id, codigo: location.state.id, mapa_horas: location.state?.producao_mapa_horas ? true : false, particular: location?.state?.particular }, val => {
                if (val.id) {
                    setGuias(val)
                    setCarregando(false)
                    let teste = val.executantes_internos.map(x => ({ data: x.data, hora_inicial: x.hora_inicial, hora_final: x.hora_final, executante: x.executante, tipo_atendimento: x.tipo_atendimento || x.executante_tipo_atendimento, empresa: x.empresa, procedimento_id: val.procedimentos.map(x => x.id) }))
                    let aqui = [...teste, ...executantes_internos];
                    let idsProcedimentos = val.procedimentos.map(x => x.id)
                    setValoresIniciais({
                        lote: "",
                        status: { value: 4, label: 'Conferida' },
                        justificativa: '',
                        codigo_id: location.state.id,
                        executantes_internos: location.state?.alteracao ? aqui : null,
                        procedimentos: idsProcedimentos
                    })
                }
            })
        }
    }, [location])

    const Submit = (values, propsFormik) => {
        let rotaConsultaAposSubmit = props.location?.state?.rotaConsulta || null
        setSalvando(true)
        values.id = guias.id
        values.usuario_id_alteracao = getUsuario().id

        putComAcao(
            ACOES.ALTERAR_SOCIO_GUIA_MAPA_HORA,
            props.modulo,
            values,
            '/controleproducao/guiaAlterarExecutante',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    propsFormik.setFieldValue('codigo_id', '')
                    propsFormik.setFieldValue('codigo_barras', '')
                    setSalvando(false)
                    document.getElementById('codigo_id').focus()
                    setGuias({})
                }
                else if (rotaConsultaAposSubmit)
                    props.history.push(rotaConsultaAposSubmit)
                else
                    props.history.push('/controleproducao/guiamapahoras/consulta')
            },
            (err) => {
                setSalvando(false)
            })
    }

    useEffect(_ => {
        setValoresIniciais({
            lote: "",
            status: { value: 4, label: 'Conferida' },
            justificativa: ''
        })
    }, [])

    const funcaoAposSim = (formik) => {
        setShowModalValidacao(false)
        Submit(formik.values, formik)
    }

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Controle Produção</span>,
        () => <Link className='link-active' to={'/controleproducao/guiamapahoras/consulta'}>Mapa Hora </Link>,
    ]

    useEffect(() => {
        if (guias) {
            const obj = guias

            setDados(obj.id ? {
                id: obj.id,
                modulo: 145,
                titulo: 'Visualização',
                grupos: [
                    {
                        titulo: 'Dados Gerais',
                        colunas: [
                            { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-3' },
                            { titulo: 'Código Mapa Hora', descricao: obj.mapa_hora?.mapa_horas_guias_id, tamanho: 'col-lg-3' },
                            { titulo: 'Ref. Produção', descricao: obj.mapa_hora?.referencia, tamanho: 'col-lg-3' },
                            { titulo: 'Código Barras', descricao: obj.codigo_barras, tamanho: 'col-lg-3' },
                            { titulo: 'Data Procedimento', descricao: obj.data_hora_procedimento, tamanho: 'col-lg-3' },
                            { titulo: 'Empresa', descricao: obj.empresa.label, tamanho: 'col-lg-3' },
                            { titulo: 'Status', descricao: obj.status?.descricao, tamanho: 'col-lg-3', cor: obj.status.cor },
                            { titulo: 'Operadora de Saúde', descricao: obj.operadora_saude?.label, tamanho: 'col-lg-3' },
                            { titulo: 'Estabelecimento de Saúde', descricao: obj.estabelecimento_saude?.label, tamanho: 'col-lg-3' },
                            { titulo: 'Acomodação', descricao: obj.acomodacao?.label, tamanho: 'col-lg-3' },
                            { titulo: 'Guia Principal', descricao: obj.guia_principal, tamanho: 'col-lg-3' },
                            { titulo: 'Senha', descricao: obj.senha, tamanho: 'col-lg-3' },
                            { titulo: 'Carteira/Matrícula', descricao: obj.matricula, tamanho: 'col-lg-3' },
                            { titulo: 'Nome Beneficiário', descricao: obj.nome_beneficiario, tamanho: 'col-lg-3' },
                            { titulo: 'Data Nascimento', descricao: obj.data_nascimento, tamanho: 'col-lg-3' },
                            { titulo: 'Cirurgião', descricao: obj.cirurgiao?.label, tamanho: 'col-lg-3' },
                            { titulo: 'Sequencial', descricao: obj?.sequencia_hospital, tamanho: 'col-lg-3' },
                            { titulo: 'Data Registro', descricao: obj.data_registro, tamanho: 'col-lg-3' },
                        ]
                    },
                    {
                        titulo: 'Informações TISS',
                        visivel: obj.tipo_guia === null ? false : obj.tipo_guia && obj.tipo_guia === 2 ? true : false,
                        colunas: [
                            {
                                titulo: 'Tipo Guia',
                                descricao: obj.tipo_guia && obj.tipo_guia,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Prestador',
                                descricao: obj.numero_prestador,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Solicitação Internação',
                                descricao: obj.numero_internacao,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Início Faturamento',
                                descricao: obj.inicio_faturamento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Fim Faturamento',
                                descricao: obj.fim_faturamento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Motivo Encerramento',
                                descricao: obj.motivo_encerramento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Indicador Acidente',
                                descricao: obj.indicador_acidente,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Tipo Faturamento',
                                descricao: obj.tipo_faturamento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Tipo Internação',
                                descricao: obj.tipo_internacao,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Regime Internação',
                                descricao: obj.regime_internacao,
                                tamanho: 'col-lg-3'
                            },
                        ]
                    },
                    {
                        titulo: 'Informações TISS',
                        visivel: obj.tipo_guia_id === null ? false : obj.tipo_guia_id && obj.tipo_guia_id === 3 ? true : false,
                        colunas: [
                            {
                                titulo: 'Tipo Guia',
                                descricao: obj.tipo_guia && obj.tipo_guia,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Prestador',
                                descricao: obj.numero_prestador,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Principal',
                                descricao: obj.numero_principal_sadt,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Tipo Atendimento',
                                descricao: obj.tipo_atendimento_tiss,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Indicação Acidente',
                                descricao: obj.indicador_acidente,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Tipo Consulta',
                                descricao: obj.tipo_consulta,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Motivo Encerramento',
                                descricao: obj.motivo_encerramento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Regime Atendimento',
                                descricao: obj.regime_atendimento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Saúde Ocupacional',
                                descricao: obj.saude_ocupacional,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Indicador Cobertura Especial',
                                descricao: obj.cobertura_especial,
                                tamanho: 'col-lg-3'
                            },

                        ]
                    },
                    {
                        titulo: 'Informações TISS',
                        visivel: obj.tipo_guia_id === null ? false : obj.tipo_guia_id && obj.tipo_guia_id === 4 ? true : false,
                        colunas: [
                            {
                                titulo: 'Tipo Guia',
                                descricao: obj.tipo_guia && obj.tipo_guia,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Prestador',
                                descricao: obj.guia_tiss && obj.guia_tiss.numero_prestador,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Solicitação Internação',
                                descricao: obj.guia_tiss && obj.guia_tiss.numero_internacao,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Início Faturamento',
                                descricao: obj.guia_tiss && obj.guia_tiss.inicio_faturamento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Fim Faturamento',
                                descricao: obj.guia_tiss && obj.guia_tiss.fim_faturamento,
                                tamanho: 'col-lg-3'
                            },

                        ]
                    },
                    {
                        titulo: 'Informações TISS',
                        visivel: obj.tipo_guia_id === null ? false : obj.tipo_guia_id && obj.tipo_guia_id === 1 ? true : false,
                        colunas: [
                            {
                                titulo: 'Tipo Guia',
                                descricao: obj.tipo_guia && obj.tipo_guia,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Prestador',
                                descricao: obj.guia_tiss && obj.guia_tiss.numero_prestador,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Indicação Acidente',
                                descricao: obj.guia_tiss && obj.guia_tiss.indicacao_acidente,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Indicador Cobertura Especial',
                                descricao: obj.guia_tiss && obj.guia_tiss.indicador_cobertura_especial,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Regime Atendimento',
                                descricao: obj.guia_tiss && obj.guia_tiss.regime_atendimento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Saúde Ocupacional',
                                descricao: obj.guia_tiss && obj.guia_tiss.saude_ocupacional,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Tipo Consulta',
                                descricao: obj.guia_tiss && obj.guia_tiss.tipo_consulta,
                                tamanho: 'col-lg-3'
                            },

                        ]
                    },
                    {
                        tipoGrupo: 'tabela',
                        titulo: 'Procedimento(s)',
                        visivel: obj.procedimentos.length > 0,
                        aposGrupo: <tr>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={13}>{formatarMoeda(obj.procedimentos.map(item => item.valor_total).reduce((total, num) => parseFloat(total) + parseFloat(num)))}</td>
                        </tr>,
                        thead:
                            <tr>
                                <td className='coluna-1' colspan="1">Código</td>
                                <td className='coluna-3' colspan="3">Procedimento</td>
                                <td className='coluna-1' colspan="1">Porte</td>
                                <td className='coluna-1' colspan="1">Valor</td>
                                <td className='coluna-1' colspan="1">Acm(%)</td>
                                <td className='coluna-1' colspan="1">Perc(%)</td>
                                <td className='coluna-1' colspan="1">Red/Acrésc.</td>
                                <td className='coluna-1' colspan="1">Urg.</td>
                                <td className='coluna-1' colspan="1">Qtde.</td>
                                <td className='coluna-3' colspan="3" style={{ textAlign: 'right' }}>Valor Total</td>
                            </tr>,
                        tbody: obj?.procedimentos == undefined || obj?.procedimentos.length < 1 || obj?.procedimentos.filter(x => x.id !== null).length === 0 ? (<><tr>
                            <td colspan="3">Sem Registro!</td>
                        </tr></>) :

                            obj?.procedimentos.map(x => {
                                return (
                                    <tr>
                                        <td colspan="1">{x?.codigo}</td>
                                        <td colspan="3">{x?.procedimento?.label}</td>
                                        <td colspan="1">{x?.porte_anestesico}</td>
                                        <td colspan="1">{x?.procedimento?.valor_porte && formatarMoeda(x?.procedimento?.valor_porte)}</td>
                                        <td colspan="1">{x?.porcentagem_acm && x?.porcentagem_acm + '%'}</td>
                                        <td colspan="1">{x?.porcentagem && x?.porcentagem?.label}</td>
                                        <td colspan="1">{x?.reducao_acrescimo && x?.reducao_acrescimo + '%'}</td>
                                        <td colspan="1">{x?.urgencia === true ? x?.urgencia_porcentagem + '%' : ''}</td>
                                        <td colspan="1">{x?.quantidade}</td>
                                        <td colspan="3" style={{ textAlign: 'right' }}>{x?.valor_total && formatarMoeda(x?.valor_total)}</td>
                                    </tr>)
                            })
                    },
                    !location.state?.producao_mapa_horas ?
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Identificação do(s) Profissional(is) Executante(s) Cooperativa',
                            visivel: obj?.executantes_externos?.length > 0 ? true : false,
                            thead:
                                <tr>
                                    {/* <td className='coluna-1'>Item Ref.</td> */}
                                    <td className='coluna-6'>Sócio</td>
                                    <td className='coluna-2'>Tipo Pessoa</td>
                                    <td className='coluna-3'>Empresa</td>

                                </tr>,
                            tbody: obj.executantes_externos == undefined || obj.executantes_externos.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.executantes_externos.filter(x => x.id).map(x =>
                                    <tr>
                                        {/* <td>{x.proced_ref || ''}</td> */}
                                        <td><b style={{ color: '#1E90FF' }}>{x.executante?.label}</b></td>
                                        <td style={{ color: '#1E90FF' }}>{x.tipo_pessoa?.label}</td>
                                        <td style={{ color: '#1E90FF' }}>{x.empresa?.label}</td>
                                    </tr>)
                        } : null,
                    !location.state?.visualizacao ? {
                        tipoGrupo: 'tabela',
                        titulo: 'Identificação do(s) Profissional(is) Executante(s) Equipe',
                        visivel: !location.state?.alteracao ? true : false,
                        thead:
                            <tr>
                                <td className='coluna-2'>Data</td>
                                <td className='coluna-2'>Hora Inicial</td>
                                <td className='coluna-2'>Hora Final</td>
                                <td className='coluna-3'>Sócio</td>
                                <td className='coluna-2'>Tipo Atendimento</td>
                                <td className='coluna-3'>Empresa</td>
                            </tr>,
                        tbody: obj.executantes_internos == undefined || obj.executantes_internos.length < 1 ? (<><tr>
                            <td colspan="3">Sem Registro!</td>
                        </tr></>) :
                            obj.executantes_internos.filter(x => x.id).map(x =>
                                <tr>
                                    <td style={{ color: '#1E90FF' }}>{x.data}</td>
                                    <td style={{ color: '#1E90FF' }}>{x.hora_inicial}</td>
                                    <td style={{ color: '#1E90FF' }}>{x.hora_final}</td>
                                    <td><b style={{ color: '#1E90FF' }}>{x.executante?.label}</b></td>
                                    <td style={{ color: '#1E90FF' }}>{x.tipo_atendimento?.label}</td>
                                    <td style={{ color: '#1E90FF' }}>{x.empresa?.label}</td>
                                </tr>)
                    } : null,
                    {
                        titulo: 'Lote',
                        visivel: obj.guia_lote_id ? true : false,
                        colunas: [
                            { titulo: 'Codigo', descricao: obj.guia_lote_id, tamanho: 'col-lg-3' },
                            { titulo: 'Número do Lote', descricao: obj.lote?.numero_lote, tamanho: 'col-lg-3', cor: obj.cor },
                            { titulo: 'Empresa', descricao: obj.lote?.empresa_lote, tamanho: 'col-lg-3' },
                            { titulo: 'Referência', descricao: obj.lote?.referencia_lote, tamanho: 'col-lg-3' },
                            { titulo: 'Status do Lote', descricao: obj.lote?.lote_status, tamanho: 'col-lg-3' },
                            { titulo: 'Op. de Sáude', descricao: obj.lote?.operadora_saude_lote, tamanho: 'col-lg-3' },

                        ]
                    },
                    location.state?.visualizacao ? {
                        tipoGrupo: 'tabela',
                        titulo: 'Identificação do(s) Profissional(is) no Mapa de Horas',
                        visivel: location.state?.visualizacao,
                        thead:
                            <tr>
                                <td className='coluna-2'>Data</td>
                                <td className='coluna-2'>Hora Inicial</td>
                                <td className='coluna-2'>Hora Final</td>
                                <td className='coluna-3'>Sócio</td>
                                <td className='coluna-2'>Tipo Atendimento</td>
                            </tr>,
                        tbody: obj.visualizacao == undefined || obj.visualizacao.length < 1 ? (<><tr>
                            <td colspan="3">Sem Registro!</td>
                        </tr></>) :
                            obj.visualizacao.map(x =>
                                <tr>
                                    <td style={{ color: '#1E90FF' }}>{x.data}</td>
                                    <td style={{ color: '#1E90FF' }}>{x.hora_inicial}</td>
                                    <td style={{ color: '#1E90FF' }}>{x.hora_final}</td>
                                    <td><b style={{ color: '#1E90FF' }}>{x.socio}</b></td>
                                    <td style={{ color: '#1E90FF' }}>{x.tipo_arquivo}</td>
                                </tr>)
                    } : null,
                ]
            } : { titulo: 'Visualização Guia' })
        }
    }, [guias])

    const tipoGrupo = grupo => {
        const obj = {
            'tabela': <table className="table table-responsive-md table-visualizacao" >
                <thead>
                    {grupo.thead}
                </thead>
                <tbody>
                    {(grupo.tbody && grupo.tbody.length && grupo.tbody) || <tr><td className='div-center' colSpan={grupo.thead && grupo.thead.props.children.length}>Sem Registros!</td></tr>}
                    {(grupo.aposGrupo ? grupo.aposGrupo : <></>)}
                </tbody>
            </table>,
            default: () => grupo.colunas && grupo.colunas.map((coluna, index) => coluna &&
                <div key={index} className={coluna.tamanho + ' col-sm-12 mb-20'}>
                    <span className="data-title">{coluna.titulo}</span>
                    {coluna.htmlDireto ? coluna.htmlDireto :
                        (coluna.html ? <Interweave content={coluna.descricao.toString()} /> : coluna.cor ? <b><span style={{ color: coluna.cor }} >{coluna.descricao}</span></b> : <span style={{ color: coluna.cor }} >{coluna.descricao}</span>)}
                </div>
            )
        }

        return obj[grupo.tipoGrupo] || obj.default()
    }

    const limparConferencia = (propsFormik) => {
        setGuias([]);
        propsFormik.setFieldValue('codigo_barras', '');
        propsFormik.setFieldValue('codigo_id', '');
        propsFormik.setFieldValue('lote', undefined);
        propsFormik.setFieldValue('status', { value: 4, label: 'Conferida' })
        document.getElementById('codigo_barras').focus();
    }

    useEffect(() => {
        obterFeriados((dados) => {
            setFeriados(dados)
        })
    }, [])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => {
                return (<>
                    <Form>
                        <div className="mt-30">
                            <HeaderCadastro
                                titulo={'Guia'}
                                link={links}>
                            </HeaderCadastro>
                        </div>
                        <div className={props.modal && props.modal || 'card mb-20'}>
                            <div className={props.modal || 'card-body'}>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-8 row'>
                                            {carregando && <span className="spinner-border spinner-border-md dp-flex" role="status" aria-hidden="true" style={{ opacity: 0.2 }}></span>}
                                        </div>
                                    </div>
                                    {
                                        dados && dados.id ?
                                            <div className="card-body" style={{ padding: 0 }}>
                                                {dados?.grupos ? dados?.grupos.filter(x => x?.visivel !== false).map((grupo, index, arrayAtual) => grupo &&
                                                    <div className={"dados-visualizacao-conferencia " + (minimizar[index] === false ? "d-none-print" : "")} key={index}>

                                                        <div className="group-title row-space-between ">
                                                            <h5> {grupo.titulo} </h5>

                                                        </div>
                                                        <div className="row col-12">
                                                            {tipoGrupo(grupo)}
                                                        </div>
                                                    </div>


                                                ) : <div className="dados-visualizacao">
                                                    <div className="group-title">
                                                        <h5>Não há dados!</h5>
                                                    </div>
                                                </div>}
                                                {location.state?.alteracao ?
                                                    <CadastroExecutantesInternos conferencia feriados={feriados} /> : <></>}
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                                <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                            </div>
                        </div>
                        {!props.modal && <div className='separator mt-40 mb-5'></div>}
                        {location.state?.alteracao ?

                            <div className='direita container-botoes mb-3 group-salvar-novo'>
                                <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                            </div> : <></>}

                    </Form>
                    <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                        esconderFechar mensagem={<> {mensagemModal}<br />
                            {'Deseja Continuar?'}</>}
                        textoOpcao1={'Cancelar'}
                        classeOpcao1={'btn-sm btn-primary-modal simples'}
                        textoOpcao2={'Continuar'} /></>)
            }}
        </Formik>
    )
}