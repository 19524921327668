import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FieldArray } from "formik";
import Yup, { validaData, periodoData, validaHora } from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { ModalDuasOpcoes, ModalEscolha } from '../../../../components/Modais';
import { get, salvar, validar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao, InputDatePicker, TextArea, InputTimePicker, InputSwitch, InputTelefone, InputValor, InputSwitchSemFormik, InputValorNovo } from '../../../../components/Inputs'
import {
    InputTodosProcedimentos, InputEstabelecimentoSaude, InputAcomodacao, InputTipoAtendimento, InputSocioAtivos, InputIntervaloHorario, InputTipoGuia,
    InputIndicacaoAcidente, InputCoberturaEspecial, InputRegimeAtendimento, InputSaudeOcupacional, InputCaraterAtendimento, InputTipoFaturamento, InputTipoInternacao, InputTipoAtendimentoTiss,
    InputTipoConsulta, InputMotivoEncerramento, InputRegimeInternacao, InputEmpresa, InputPorArray, InputPercentual, InputOperadoraSaudeContrato, InputTipoAtendimentoEquipe, InputPercentualAcomodacao, InputBeneficiario, InputMunicipio,
    InputPacote,
    InputTipoAtendimentoOdonto,
    InputTabelaProcedimento
} from '../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../components/Botoes'
import { buscarCep, mensagens } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import InputArquivoS3 from '../../../../components/InputArquivoS3'
import { MODULO } from '../../../../util/Enums'
import { BtnAcao } from '../../../../components/Acoes'
import { formatMoeda } from '../../../../util/FuncoesComuns';
import moment from 'moment';
import { Overlay, Tooltip } from 'react-bootstrap';
import { FiAlertTriangle } from 'react-icons/fi'


const CadastroAnestesiaOdonto = (props) => {
    let { id } = useParams()

    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [showModalInsercaoMultiplo, setShowModalInsercaoMultiplo] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [dataArquivos, setDataArquivos] = useState(null)
    const [limparArquivos, setLimparArquivos] = useState(false)
    const [qtdInsercao, setQtdInsercao] = useState(0)
    const [showModalInterAgenda, setShowModalInterAgenda] = useState(false)
    const [msgValidacao, setMsgValidacao] = useState(<></>)
    const [disableCampos, setDisableCampos] = useState(true)
    const [dataProcedimento, setDataProcedimento] = useState('')
    const [procedimentoBloqueado, setProcedimentoBloqueado] = useState(true)
    const [dataTelefone, setDataTelefone] = useState([])
    const [minimizar, setMinimizar] = useState([true, false])
    const [showModalValidacaoTabela, setShowModalValidacaoTabela] = useState(false)


    let validacaoInicial = Yup.lazy(({ valores_honorario }) => Yup.object({
        ano: Yup.string().nullable().required(),
        data_inicio: Yup.string().nullable().required()
            .test('Data', 'Data inválida!', function (value) {
                return validaData(this.parent.data_inicio)
            })
        // .test('Data Início', 'Data Inicial deve ser anterior à Data Final.', function (value) {
        //     return periodoData(this.parent.data_fim, value)
        // })
        ,
        data_fim: Yup.string().nullable().required()
            .test('Data', 'Data inválida!', function (value) {
                return validaData(this.parent.data_fim)
            })
        // .test('Data Fim', 'Data Inicial deve ser anterior à Data Final.', function (value) {
        //     return periodoData(this.parent.data_inicio, value)
        // })
        ,
    })
        .shape({
            valores_honorario: Yup.array().test('Valores', 'Insira ao menos 1 Valor Honorário', function (value) {
                return this.parent.valores_honorario && this.parent.valores_honorario[0].local_atendimento !== undefined
            }).of(
                Yup.object().shape({
                    hora_inicial: Yup.string().test('Hora Inicial', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_inicial) })
                        .test('Hora Inicial', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '')
                            return !(valores_honorario.length - 1 != index && !value)
                        }), // these constraints take precedence
                    hora_final: Yup.string().nullable()
                        .test('Hora Final', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_final) })
                        .test('Hora Final', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '')
                            return !(valores_honorario.length - 1 != index && !value)
                        }),
                    local_atendimento: Yup.string().nullable().test('Local Atendimento', 'Campo Obrigatório!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '')
                        return !(valores_honorario.length - 1 != index && !value)
                    }),
                    valor: Yup.string(),
                    valor_med_ox: Yup.string()
                })
            )
        })
    )

    const validarSubmit = (values, propsFormik, nValidacao) => {
        validar('/faturamento/validarTabelaAnestesia', values, () => Submit(values, propsFormik), (e) => {
            let erros = e.response.data.alertas
            setShowModalValidacaoTabela(true)
            setMensagemModal(erros.map(x => <div className='mb-1'><b>{x}<br /></b></div>))
            setSalvando(false)
        })
        setSalvando(true)
        // Submit(values, propsFormik)
    }

    const funcaoAposSim = (formik) => {
        Submit(formik.values, formik.reseForm)
    }

    // const manter = ['socio', 'tipo_ocorrencia', 'implica_hierarquia', 'interfere_agendamento']

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        setSalvando(false)
        salvar(
            values,
            '/faturamento/anestesiaOdontologia',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    // manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                    setLimparArquivos(true)
                    setShowModalValidacao(false)
                }
                else
                    props.history.push('/faturamento/anestesiaodontologia/consulta')

                setSalvando(false)
            },
            (e) => {
                console.log('ERRO ', e)
                setSalvando(false)
            })
    }

    useEffect(() => {
        var objListas = {
            valores_honorario: [{ hora_inicial: undefined, hora_final: undefined, local_atendimento: undefined, valor: undefined }],
        }
        if (id) {
            Promise.resolve(get(`/faturamento/anestesiaOdontologia/${id}`)).then(val => {
                val.valores_honorario = [...val.valores_honorario.map(x => ({ alteracao: true, ...x })), ...objListas.valores_honorario];
                val.tabela_procedimento = val.tabelas.map(x => ({ value: x.id, label: x.descricao }))

                setValoresIniciais(val)
            })
        } else {
            setDataArquivos([])

            setValoresIniciais({
                ano: '',
                data_inicio: '',
                data_fim: '',
                valores_honorario: [{ hora_inicial: undefined, hora_final: undefined, local_atendimento: undefined, valor_med_ox: undefined }]
            })

            // document.getElementById("data_procedimento").focus()
        }

    }, [id])

    useEffect(() => {
    }, [valoresIniciais])

    const atualizarDatas = (propsFormik) => {

        const ano = propsFormik.values.ano

        if (moment(ano).isValid()) {
            let dataInicio = moment(ano).clone().format('DD/MM/YYYY')
            let dataFim = moment(ano).endOf('year').format('DD/MM/YYYY');
            propsFormik.setFieldValue('data_inicio', dataInicio)
            propsFormik.setFieldValue('data_fim', dataFim)
        }

    }

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
            {propsFormik => (<Form>
                <div className={'card mb-20'}>
                    <div className={'card-body'}>
                        <div className='row'>
                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                            <InputPadrao label="Ano" name="ano" type="numeric" placeholder="Ano" tamanho={'col-lg-2'} maxLength={"4"} onBlur={_ => atualizarDatas(propsFormik)} obrigatorio autoFocus />
                            <InputDatePicker
                                tamanho="col-xl-2 col-lg-4 col-md-6"
                                label="Data Início"
                                name="data_inicio"
                                type="text"
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_inicio", value)
                                }}
                                placeholder="Data Início"
                            />
                            <InputDatePicker
                                tamanho="col-xl-2 col-lg-4 col-md-6"
                                label="Data Fim"
                                name="data_fim"
                                type="text"
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_fim", value)
                                }}
                                placeholder="Data Fim"
                            />
                            <InputTabelaProcedimento label='Tabela' name="tabela_procedimento" propsFormik={propsFormik} obrigatorio={true} salvarENovo={salvarENovo}
                                tamanho={!props.modal && 'col-lg-6' || 'col-lg-12'} {...props} multiplo={true} />
                            <TextArea label='Observação' name='observacao' type='text' placeholder='Observação' tamanho={!props.modal && 'col-lg-6 col-md-6'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <br />

                        </div>
                    </div>
                </div>
                <CadastroValoresHonorario alteracao={false} propsFormik={propsFormik} data_socio={dataProcedimento} />
                <div className='separator mt-40 mb-5'></div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
                <ModalDuasOpcoes showModal={showModalValidacaoTabela} setShowModal={setShowModalValidacaoTabela} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacaoTabela(false) }}
                    esconderFechar mensagem={mensagemModal}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
                <ModalDuasOpcoes showModal={showModalInterAgenda} setShowModal={setShowModalInterAgenda}
                    funcaoOpcao2={() => Submit(propsFormik.values, propsFormik)}
                    funcaoOpcao1={() => { setSalvando(false); setShowModalInterAgenda(false) }}
                    esconderFechar mensagem={<>{<>{'Há Agenda(s)/Escala(s):'} <br /><br /></>} {msgValidacao}<br /></>}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
            </Form >)}
        </Formik >
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Faturamento</Link>,
        () => <Link className="link-active" to={'/faturamento/anestesiaodontologia/consulta'}>Tabela de Anestesia Odontologia</Link>,
    ]
    return (<>
        <HeaderCadastro titulo={"Cadastro Tabela de Anestesia Odontologia"} link={links} />
        <CadastroAnestesiaOdonto {...props} />
    </>
    )
}


const CadastroValoresHonorario = (props) => {

    const refValores = useRef(null)

    const onBlurValor = (option, propsFormik, arrayHelpers, index) => {

        if (option) {
            const valorAnterior = { ...propsFormik.values.valores_honorario[index] }
            propsFormik.setFieldValue(`valores_honorario[${index}].local_atendimento`, option);
            if (!valorAnterior.local_atendimento || !(valorAnterior.local_atendimento && valorAnterior.local_atendimento.value)) {
                adicionarLinha(arrayHelpers, propsFormik)
            }
        }
        else {
            propsFormik.setFieldValue(`valores_honorario[${index}].local_atendimento`, undefined);
        }
    }

    const adicionarLinha = (arrayHelpers, propsFormik) => {
        arrayHelpers.insert(propsFormik.values.valores_honorario.length, { hora_inicial: undefined, local_atendimento: undefined, valor: undefined, valor_med_ox: undefined })
    }

    const removerLinha = (arrayHelpers, propsFormik, index) => {
        const arrayValoresHonorario = propsFormik.values.valores_honorario;
        if (arrayValoresHonorario.length === 1) {
            propsFormik.setFieldValue('valores_honorario', [{ hora_inicial: undefined, hora_final: undefined, local_atendimento: undefined, valor: undefined, valor_med_ox: undefined }])
        } else {

            // if (arrayValoresHonorario[index].local_atendimento && arrayValoresHonorario[index].local_atendimento.value)
            if ((index + 1) !== arrayValoresHonorario.length)
                arrayHelpers.remove(index)
            if (arrayValoresHonorario.length === 1 && arrayValoresHonorario[index].local_atendimento && arrayValoresHonorario[index].local_atendimento.value)
                arrayHelpers.insert(propsFormik.values.valores_honorario.length, { hora_inicial: undefined, local_atendimento: undefined, valor: undefined, valor_med_ox: undefined })
        }
    }
    // props.propsFormik.valuesprops.propsFormik.values

    return (
        <>
            <div className="mt-40">
                <HeaderCadastro
                    titulo={'Tabela Valores Honorários'}
                    classeChildren='space-between-100'
                >
                </HeaderCadastro>
            </div>
            <div className="card mb-20 scroll-x">
                <div className="card-body  executantes-internos-float" ref={refValores}>
                    <FieldArray name="valores_honorario">
                        {(arrayHelpers => (
                            <>
                                {props.propsFormik.values.valores_honorario && props.propsFormik.values.valores_honorario.length > 0 ?
                                    props.propsFormik.values.valores_honorario.map((item, index) => <div className='modal-body-cadastro row mr-0 ml-0'>
                                        <div className='row col-sm-11 col-xl-4 col-lg-6 col-md-8'>

                                            <div className={"form-group col-1 pl-0 text-left"}>
                                                <label htmlFor={props.id || props.name}>{index === 0 ? '#' : undefined}</label>
                                                <div className={'text-left vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{index + 1}</span></div>
                                            </div>

                                            <InputPadrao label="Hora Inicial" mascara={'11:11'} classeTooltip={'tooltip-input-modal'} name={`valores_honorario[${index}].hora_inicial`} placeholder="Hora Inicial" tamanho={'col-5 col-lg-5'} obrigatorio />

                                            <InputPadrao label="Hora Final" mascara={'11:11'} classeTooltip={'tooltip-input-modal'} name={`valores_honorario[${index}].hora_final`} placeholder="Hora Final" tamanho={'col-5 col-lg-5'} obrigatorio />
                                        </div>
                                        <div className='row col-sm-11 col-xl-7 col-lg-11 col-md-12 pr-0 mr-2'>

                                            <InputTipoAtendimentoOdonto label={'Local de Atendimento'} placeholder='Local de Atendimento' classeTooltip={'tooltip-input-modal'} isClearable={false}
                                                name={`valores_honorario[${index}].local_atendimento`} onChange={(e) => onBlurValor(e, props.propsFormik, arrayHelpers, index)} propsFormik={props.propsFormik} tamanho="col-sm-3 8 col-xl-4 col-lg-2 col-md-2 pr-0" />
                                            <InputValorNovo obrigatorio label='Valor R$' value={props.propsFormik.values.valores_honorario[index].valor_formatado || props.propsFormik.values.valores_honorario[index].valor} name={`valores_honorario[${index}].valor`} classeTooltip={'tooltip-input-modal'} classDiv='col-lg-4' propsFormik={props.propsFormik} isDisable={false} max={100000000} />
                                            <InputValorNovo obrigatorio label='Valor R$ (Med./Oxig.)' value={props.propsFormik.values.valores_honorario[index].valor_med_ox_formatado || props.propsFormik.values.valores_honorario[index].valor_med_ox} name={`valores_honorario[${index}].valor_med_ox`} classeTooltip={'tooltip-input-modal'} classDiv='col-lg-4' propsFormik={props.propsFormik} isDisable={false} max={100000000} />
                                            {/* <InputValorNovo label='Valor R$ (Med./Oxig.)' value={props.propsFormik.values.valores_honorario[index].valor_med_ox_formatado || props.propsFormik.values.valores_honorario[index].valor_med_ox} name={`valores_honorario[${index}].valor_med_ox `} classeTooltip={'tooltip-input-modal'} onBlur={(e) => onBlurValor(e.target.value, props.propsFormik, arrayHelpers, index)} classDiv='col-lg-4' propsFormik={props.propsFormik} isDisable={false} max={100000000} /> */}
                                        </div>
                                        <div className='col-4 col-xl-1 col-lg-1 col-md-1 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right'>
                                            <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, props.propsFormik, index)} />
                                        </div>
                                    </div>)
                                    :
                                    <></>}
                            </>
                        ))}
                    </FieldArray>
                    {props.propsFormik.touched.valores_honorario && props.propsFormik.errors.valores_honorario && !Array.isArray(props.propsFormik.errors.valores_honorario) ?
                        <Overlay target={refValores.current} show={true} placement="bottom">
                            {props2 => {
                                return (
                                    <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                        <div className="error">
                                            <FiAlertTriangle className="icone" /> <span>{props.propsFormik.errors.valores_honorario}</span></div>
                                    </Tooltip>)
                            }}
                        </Overlay> : <></>}
                </div>
            </div>
            :
        </>)
}

export { CadastroAnestesiaOdonto }
export default Cadastro
