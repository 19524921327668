import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { putComAcao, get } from '../../../../util/AcessoApi'
import { mensagens, useWindowSize, getUnique } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import { ModalDuasOpcoes } from '../../../../components/Modais'
import { ACOES } from '../../../../util/Enums'
import { mostrarToast } from '../../../../components/Toasts'


const ExclusaoSocios = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [objAgenda, setObjAgenda] = useState(null)
    const [objSelecionados, setObjSelecionados] = useState([])
    const [todos, setTodos] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [width, height] = useWindowSize();


    useEffect(() => {
        if (props.item && props.item.id) {
            setShowModal(props.show);
            Promise.resolve(get(`/administracao/obterDadosExecutantesParaExclusao/${props.item.id}`)).then(obj => {
                if (obj) {
                    setObjAgenda(obj);
                    if (obj.length == 1)
                        setObjSelecionados(obj.map(x => x.id_tabela_executante))
                }
            })
        }
    }, [props.item, props.show])


    const clickConfirmar = () => {
        setSalvando(true);
        if (objSelecionados.length == 0) {
            mostrarToast('erro', "Selecione ao menos um sócio para realizar a exclusão.");
            setSalvando(false);
            return;
        }
        putComAcao(
            ACOES.EXCLUIR_SOCIOS,
            props.modulo_id,
            { agenda: objAgenda, executantes: objSelecionados, controle: objSelecionados.join(',') },
            '/administracao/excluirTodosExecutantesDaAgenda',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (props.funcaoAposSalvar)
                    props.funcaoAposSalvar();

                setSalvando(false)
                limparFechar();
            },
            (erro) => {
                mostrarToast('erro', mensagens.erro);
                setSalvando(false);
            })
    }
    const limparFechar = () => {
        setObjSelecionados([]); setTodos(false); setObjAgenda(null); setShowModal(false); props.setShow(false);

    }

    return (
        <>
            <ModalDuasOpcoes showModal={showModal} setShowModal={setShowModal}
                funcaoOpcao1={() => { limparFechar(); }}
                funcaoOpcao2={() => { clickConfirmar(); }}
                naoFecharNoClick={true}
                salvando={salvando}
                esconderFechar mensagem={
                    objAgenda == null ? <></> :
                        <div className="corpo-com-scroll custom-scrollbar" style={{ maxHeight: height < 600 ? '200px' : height < 800 ? '300px' : '400px', overflow: 'scroll' }}>
                            {!props.modulo_id ? <i>IMPLEMENTE CORRETAMENTE, ENVIO O MODULO ID</i> : <></>}
                            <p className={'titulo-modal'}>Escolha o(s) sócio(s) abaixo, e confirme a exclusão.</p>
                            <ul className='lista-modal' style={{ display: 'relative' }}>
                                {
                                    objAgenda.length == 1 ? <></>
                                        : <li onClick={_ => {
                                            let setarTodos = todos ? false : true;
                                            setTodos(setarTodos)
                                            if (setarTodos) {
                                                let todosIds = [];
                                                objAgenda.map(item => {
                                                    todosIds.push(item.id_tabela_executante);
                                                });
                                                setObjSelecionados(todosIds);
                                            }
                                            else
                                                setObjSelecionados([]);

                                        }
                                        } className={todos ? 'mb-1 socio-selecionado ' : 'mb-1'}>
                                            <div className='mb-2 espacamento'><span></span> <i className={todos ? 'icon-Yes selecao' : 'icon-Circle'}></i></div>
                                            <div ><span><b>Todos</b></span></div>
                                        </li>
                                }
                                {objAgenda && objAgenda.map(item => {
                                    return <li onClick={_ => {
                                        let copy = objSelecionados.map(function (e) { return e; });
                                        if (objSelecionados.filter(x => x === item.id_tabela_executante).length > 0)
                                            copy = copy.filter(function (item2) {
                                                return item2 !== item.id_tabela_executante
                                            })
                                        else
                                            copy.push(item.id_tabela_executante);
                                        setObjSelecionados(copy);
                                    }
                                    } className={objSelecionados.filter(x => x === item.id_tabela_executante).length > 0 ? 'mb-1 socio-selecionado ' : 'mb-1'}>
                                        <div className='row-space-between'>
                                            <div></div>
                                            <span className={'tag tag-' + item.descricao}>{item.descricao}</span>
                                        </div>
                                        <div className='mb-2 espacamento'><span><b>Hora: </b>{item.hora_inicial}</span><i className={objSelecionados.filter(x => x === item.id_tabela_executante).length > 0 ? 'selecao icon-Yes' : 'icon-Circle'}></i></div>
                                        <div ><span><b>Sócio: </b>{item.nome}</span></div>
                                    </li>
                                })}
                            </ul>
                        </div>
                }
                textoOpcao1={'Fechar'}
                textoOpcao2={'Confirmar exclusão'}
                classeOpcao1={'btn-sm btn-primary-modal simples'} />
        </>
    );
}

export default ExclusaoSocios
