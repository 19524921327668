import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputTipoRelatorio, InputEstabelecimentoSaude, InputSimNao, InputOperadoraSaude, InputSocio, InputContratoFiltro, InputStatusEnvio, InputLoteGuiaFiltro } from '../../../components/InputsAutoComplete'
import { InputDatePicker, InputPadrao } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes';

export default props => {
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório de Guias Enviadas')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '' })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)

    const inputRef = useRef(null);

    const validacao = Yup.object({
        data_aniversario_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) }),
        data_aniversario_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) })
    })

    const relatoriosPorTipo = propsFormik => [
        {

            titulo: 'Relatório de Guias Enviadas',
            tipo: 1,
            url: '/relatorio/GuiasEnviadas',
            campos: [
                <InputStatusEnvio label="Guia Status Envio" placeholder="Guia Status Envio" name="guia_status_envio" propsFormik={propsFormik}
                classeTooltip={'tooltip-input-modal'}/>,
                <InputOperadoraSaude label="Operadora Saúde" placeholder="Operadora Saúde" name="operadora_saude" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputEstabelecimentoSaude label="Estabelecimento Saúde" placeholder="Estabelecimento Saúde" name="estabelecimento_saude" propsFormik={propsFormik}
                classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSocio label="Sócio" placeholder="Sócio" name="socio" propsFormik={propsFormik}
                classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputLoteGuiaFiltro multiplo label="Lote" name="lote" placeholder="Lote" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='bottom' />,
                <InputDatePicker label="Data Procedimento Inicial" name="data_procedimento_inicial" type="text" autoFocus
                    setarValor={value => { propsFormik.setFieldValue("data_procedimento_inicial", value) }} />,
                <InputDatePicker label="Data Procedimento Final" name="data_procedimento_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_procedimento_final", value) }} />,
                <InputDatePicker label="Data Envio/Digitação Inicial" name="data_envio_inicio" type="text" autoFocus
                    setarValor={value => { propsFormik.setFieldValue("data_envio_inicio", value) }} />,
                <InputDatePicker label="Data Envio/Digitação Final" name="data_envio_fim" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_envio_fim", value) }} />,
                <InputContratoFiltro label="Contrato" placeholder="Contrato" name="contrato" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo  menuPlacement='top'/>
                
            ],
            numerarLinha: true,
            agrupamento: [{ coluna: 'contrato', descricao: 'contrato' }],
            colunas: [
                { label: 'Codigo', name: 'id', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Cod. de Barras', name: 'codigo_barras', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Paciente', name: 'paciente', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Guia', name: 'guia', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Senha', name: 'senha', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Matricula', name: 'matricula', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Digitação', name: 'data_digitacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Procedimento', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Cooperado', name: 'cooperado', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo', name: 'sigla', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Enviado', name: 'status_envio_id', name_coluna: 'guia_status_envio' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Sócio', name: 'socio_id', name_coluna: 'socio' },
                { label: 'Lote', name: 'guia_lote_id', name_coluna: 'lote' },
                { label: 'Data Procedimento Inicial', name: 'data_procedimento_inicial', name_coluna: 'data_procedimento_inicial' },
                { label: 'Data Procedimento Final', name: 'data_procedimento_final', name_coluna: 'data_procedimento_final' },
                { label: 'Data Envio/Digitação Inicial', name: 'data_envio_inicio', name_coluna: 'data_envio_inicio' },
                { label: 'Data Envio/Digitação Final', name: 'data_envio_fim', name_coluna: 'data_envio_fim' },
                { label: 'Contrato', name: 'contrato_id', name_coluna: 'contrato' },

            ]
        },
    ]
    // console.log(relatoriosPorTipo(propsFormik).find(x => x.tipo === 1), 'novo relatório')


    const submit = (values, propsFormik) => {
        setCarregando(true)
        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === 1)
        values.tipo_relatorio = 1
        setShowModalFiltros(false)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]

        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio)
            setFiltros(Object.keys(values).map((x, idx, array) => {
                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                })
            }
            ).filter(x => x))
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }


    useEffect(() => {

        let cloneRelatorioAtual = { ...relatorioAtual }

        Object.keys(cloneRelatorioAtual).length > 0 &&
        consultarRelatorio(cloneRelatorioAtual.url, array => {

            cloneRelatorioAtual.data = array
            setTitulo(cloneRelatorioAtual.titulo)
            setDados([{ numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data }])
            setCarregando(false)
            setRelatorioAtual(cloneRelatorioAtual)


                // const arrayDistinct = (value, index, self) => {
                //     return self.findIndex(x => x.cirurgiao === value.cirurgiao) === index;
                // }

                // var arrayCirurgiaoSemRepeticao = array && array.map(x => ({ cirurgiao_id: x.cirurgiao_id, cirurgiao: x.cirurgiao })).filter(arrayDistinct)
                // cloneRelatorioAtual.data = array
                // setTitulo(cloneRelatorioAtual.titulo)
                // const novoTeste = arrayCirurgiaoSemRepeticao ? arrayCirurgiaoSemRepeticao.map(item => ({ agrupamento: item.cirurgiao, colunas: cloneRelatorioAtual.colunas, numerarLinha: true, data: array.filter((item2) => item2.cirurgiao_id === item.cirurgiao_id) })) : ''
                // console.log('novoTeste: ', novoTeste);
                // // setDados([{ agrupamento: '31/03/2021 - Quinta-feira', data: novoTeste }])
                // // setDados([{ numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data, classeTr: cloneRelatorioAtual.classeTr, aposAgrupamento: cloneRelatorioAtual.aposAgrupamento, aposRelatorio: cloneRelatorioAtual.aposRelatorio }])
                // setDados(novoTeste)
                // setCarregando(false)
                // setRelatorioAtual(cloneRelatorioAtual)
            },null,  { value: filtros })

    }, [filtros])

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} agrupamento={[{ coluna: 'contrato', descricao: 'contrato' }]}
            loading={carregando} orientacao={'A4 landscape'}  orientacaoPadrao = {'landscape'} repetirColunas>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === 1) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === 1).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}

                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}
