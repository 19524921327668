import { InputPadrao, InputDatePicker, InputTelefone } from '../../../../components/Inputs'
import { InputMunicipio, InputOperadoraSaude } from '../../../../components/InputsAutoComplete'
import React, { useState, useEffect } from 'react'
import Yup, { validaCPF, validaData } from '../../../../util/Validacoes'
import { HeaderCadastro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { ajustarDadosTelefone, buscarCep, mensagens } from '../../../../util/Utilitarios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'

const CadastroPaciente = (props) => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})

    const [validacao] = useState(Yup.object({
        nome: Yup.string().required().nullable().max(200).trim(),
        nome_social: Yup.string().nullable().max(200).trim(),
        cpf: Yup.string().test('CPF', 'CPF inválido!', function (value) { return validaCPF(this.parent.cpf) })
            .nullable()
            .max(100),
        rg: Yup.string().nullable(),
        cns: Yup.string().nullable().max(200).trim(),
        plano: Yup.string().nullable().max(200).trim(),
        numero_carteira: Yup.string().nullable().max(200).trim(),
        validade_carteira: Yup.string().nullable().max(200).trim()
            .test('Validade Carteira', 'Data inválida!', function (value) { return validaData(this.parent.validade_carteira) }),
        operadora_saude_id: Yup.string().nullable().max(200).trim(),

    }))

    const manter = []
    const [dataTelefone, setDataTelefone] = useState([])


    const Submit = (values, propsFormik) => {
        setSalvando(true)
        values.telefone = dataTelefone.length > 0 ? dataTelefone : values.telefone;
        values.fone = ajustarDadosTelefone(values.telefone)
        salvar(
            { ...values },
            '/faturamento/beneficiario',
            obj => {
                if (props.modal) {
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else {
                        props.setItemSelecionado({ value: obj.id, label: obj.nome })
                        props.setShow && props.setShow(false)
                    }
                }
                else {
                    mostrarToast('sucesso', mensagens.sucesso)
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        setDataTelefone([])
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                    }
                    else
                        props.history.push('/faturamento/beneficiario/consulta')
                }
            },
            () => setSalvando(false))
    }

    useEffect(_ => {

        if (!props.modal && id)
            Promise.resolve(get(`/faturamento/beneficiario/${id}`)).then(obj => {
                if (id && !obj.id)
                    props.history.push('/faturamento/beneficiario/consulta')
                setDataTelefone(obj.fone)
                setValoresIniciais(obj)
            })
    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => {
                return (<Form>
                    <div className={props.modal && props.modal || 'card mb-20'}>
                        <div className={props.modal || 'card-body'}>
                            <div className='row'>
                                <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' />
                                <InputPadrao label='Nome' name='nome' type='text' placeholder='Nome' obrigatorio={true}
                                    tamanho={!props.modal && 'col-sm-12 col-lg-6 col-xl-6'} classeTooltip={props.modal && 'tooltip-input-modal'} autoFocus salvarENovo={salvarENovo} />
                                <InputPadrao label='Nome Social' name='nome_social' type='text' placeholder='Nome Social'
                                    tamanho={!props.modal && 'col-sm-12 col-lg-6 col-xl-6'} classeTooltip={props.modal && 'tooltip-input-modal'} salvarENovo={salvarENovo} />
                                <InputDatePicker tamanho={!props.modal && "col-sm-12 col-lg-6 col-md-7 col-xl-3"} label="Data de Nascimento" name="data_nascimento" type="text"
                                    setarValor={value => {
                                        propsFormik.setFieldValue("data_nascimento", value)
                                    }} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputPadrao mascara="111.111.111-11" tamanho={!props.modal && "col-sm-12 col-lg-6 col-md-7 col-xl-3"} classeTooltip={props.modal && 'tooltip-input-modal'} label="CPF" name="cpf" type="text" placeholder="CPF" />
                                <InputPadrao label='RG' name='rg' type='text' placeholder='RG'
                                    tamanho={!props.modal && 'col-sm-12 col-lg-6 col-md-7 col-xl-3'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputPadrao label='CNS' name='cns' type='text' placeholder='CNS'
                                    tamanho={!props.modal && 'col-sm-12 col-lg-6 col-md-7 col-xl-3'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputPadrao label='Plano' name='plano' type='text' placeholder='Plano'
                                    tamanho={!props.modal && 'col-sm-12 col-xl-3 col-lg-5'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputPadrao label='Número Carteira' name='numero_carteira' type='text' placeholder='Número Carteira'
                                    tamanho={!props.modal && 'col-sm-12 col-xl-3 col-lg-4'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputDatePicker tamanho={!props.modal && "col-sm-12 col-lg-6 col-md-7 col-xl-3"} label="Validade Carteira" name="validade_carteira" type="text"
                                    setarValor={value => {
                                        propsFormik.setFieldValue("validade_carteira", value)
                                    }} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputPadrao
                                    mascara="11111-111"
                                    tamanho={!props.modal && "col-lg-2 col-sm-12 col-xl-3"}
                                    label="CEP"
                                    name="cep"
                                    type="text"
                                    placeholder="CEP" onBlur={e => buscarCep(e, propsFormik)} classeTooltip={props.modal && 'tooltip-input-modal'}
                                />
                                <InputPadrao
                                    tamanho={!props.modal && "col-lg-4 col-sm-12"}
                                    label="Logradouro"
                                    name="logradouro"
                                    type="text"
                                    placeholder="Logradouro" classeTooltip={props.modal && 'tooltip-input-modal'}
                                />
                                <InputPadrao
                                    tamanho={!props.modal && "col-lg-4 col-sm-12"}
                                    label="Complemento"
                                    name="complemento"
                                    type="text"
                                    placeholder="Complemento"
                                />
                                <InputPadrao
                                    tamanho={!props.modal && "col-lg-2 col-sm-12"}
                                    label="Número"
                                    name="numero"
                                    type="text"
                                    placeholder="Número" classeTooltip={props.modal && 'tooltip-input-modal'}
                                />
                                <InputPadrao
                                    tamanho={!props.modal && "col-lg-2 col-sm-12"}
                                    label="Bairro"
                                    name="bairro"
                                    type="text"
                                    placeholder="Bairro" classeTooltip={props.modal && 'tooltip-input-modal'}
                                />
                                <InputMunicipio tamanho={!props.modal && "col-lg-3 col-sm-12"} label='Município - UF' name='municipio' placeholder='Município - UF' obrigatorio={true}
                                    propsFormik={propsFormik} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputTelefone
                                    tamanho={!props.modal && "col-lg-2 col-xl-2 col-sm-12"}
                                    label="Telefone"
                                    name="telefone"
                                    type="text"
                                    placeholder="Telefone"
                                    obrigatorio="true"
                                    data={dataTelefone}
                                    setData={setDataTelefone} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputPadrao
                                    tamanho={!props.modal && "col-lg-3 col-xl-2 col-sm-12"}
                                    label="E-mail"
                                    name="email"
                                    type="text"
                                    placeholder="E-mail" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputOperadoraSaude label="Operadora Saúde" name="operadora_saude" propsFormik={propsFormik} tamanho={!props.modal && 'col-sm-12 col-xl-5 col-lg-5'} />

                                <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />

                            </div>
                        </div>
                    </div>
                    {!props.modal && <div className='separator mt-40 mb-5'></div>}
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                        {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                    </div>
                </Form>)
            }}
        </Formik>
    )
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>faturamento</span>,
        () => <Link className='link-active' to={'/faturamento/acomodacao/consulta'}>Acomodação</Link>,
    ]
    return (<>
        {!props.modal && <HeaderCadastro titulo={"Cadastro Beneficiário"} link={links} />}
        <CadastroPaciente {...props} />
    </>
    )
}
export { CadastroPaciente }
export default Cadastro