import React, { useState, useEffect, useRef } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { get, excluir, putComAcao } from '../../../../util/AcessoApi'
import { AlterarPersonalizado, Excluir, Visualizar, EnviarNotificacao } from '../../../../components/Acoes'
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { mostrarToast } from '../../../../components/Toasts'
import { enviarNotificacao, mensagens } from '../../../../util/Utilitarios.js'

const ConsultaNotificacao = (props) => {
    const [data, setData] = useState([])

    const user = getUsuario()
    const target = useRef(null)
    const [objCarregar, setObjCarregar] = useState({ id: 0, carregando: false })

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-notificacao',
                columns: [
                    {
                        Header: 'Id',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Título',
                        accessor: 'titulo',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Descrição',
                        accessor: 'descricao',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        html: item => <div dangerouslySetInnerHTML={{ __html: item.descricao.toString() }} />
                    },
                    {
                        Header: 'Enviado ?',
                        accessor: 'enviado',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'input_sim_nao',
                            idColuna: 'enviado'
                        }
                    },
                    {
                        Header: 'Data Envio',
                        accessor: 'data_envio_formatada',
                        ordenamento: {
                            tipo: 'data'
                        },
                    }, 
                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro_formatada',
                        ordenamento: {
                            tipo: 'data'
                        },
                    }, 
                    {
                        Header: 'Data Registro Inicial',
                        accessor: 'data_inicial',
                        visivel: false,
                        ordenamento: {
                            tipo: 'data'
                        },
                        filtro: {
                            tipo: 'data_inicial',
                            idColuna: 'data_registro_inicial',
                            datePicker: true,
                            date: true
                        }
                    }, {
                        Header: 'Data Registro Final',
                        accessor: 'data_final',
                        visivel: false,
                        ordenamento: {
                            tipo: 'data'
                        },
                        filtro: {
                            tipo: 'data_final',
                            idColuna: 'data_registro_final',
                            datePicker: true,
                            date: true
    
                        }
                    },
                    {
                        Header: 'Data Envio Inicial',
                        accessor: 'data_envio_inicial',
                        visivel: false,
                        ordenamento: {
                            tipo: 'data'
                        },
                        filtro: {
                            tipo: 'data_envio_inicial',
                            idColuna: 'data_envio_inicial',
                            datePicker: true,
                            date: true
                        }
                    }, {
                        Header: 'Data Envio Final',
                        accessor: 'data_envio_final',
                        visivel: false,
                        ordenamento: {
                            tipo: 'data'
                        },
                        filtro: {
                            tipo: 'data_envio_final',
                            idColuna: 'data_envio_final',
                            datePicker: true,
                            date: true
    
                        }
                    },
                ],

            },

        ],
        []
    )

    // const exclusao = (propsAcao) => {
    //     const objPermissao = {
    //         usuario_id: getUsuario().id,
    //         modulo_id: props.modulo,
    //         acao_id: ACOES.EXCLUIR
    //     }

    //     get('/controleAcesso/permissao', objPermissao).then(function (val) {
    //         if (val.length)
    //             excluir('/administracao/notificacao',  propsAcao.id,
    //                 () => {
    //                     getApi(setData, '/administracao/notificacao')
    //                     mostrarToast('sucesso', mensagens['sucesso'])
    //                 },
    //                 (e) => {
    //                     if (e.response && e.response.data && e.response.data.erros)
    //                         e.response.data.erros.forEach((item) => mostrarToast('erro', item))
    //                     else
    //                         mostrarToast('erro', mensagens.erro)
    //                 })
    //         else
    //             mostrarToast('erro', mensagens['permissao'])

    //     }).catch((e) => mostrarToast('erro', mensagens['erro']))
    // }

    const exclusao = propsAcao => {

        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }
        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length) {
                excluir('/administracao/notificacao', propsAcao.id,
                    () => {
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    })
            }
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const envioNotificacao = (obj) => {

        let envio = {
            noti_id: obj.id,
            socio_id: obj.row.socio_id.split(','),
            titulo: obj.row.titulo,
            mensagem: obj.row.descricao.replace(/<[^>]*>?/gm, ''),
            usuario: obj.row.nome
        }
        // setEnviando(true);
        putComAcao(
            ACOES.ENVIAR,
            undefined,
            envio,
            '/administracao/notificacaoenvio',
            () => {
                target.current.atualizar();
                setObjCarregar({ id: 0, carregando: false })
                mostrarToast('sucesso', mensagens.sucesso);
                // setEnviar(false);
                // setEnviando(false);
                // setArrayEnvio([]);
                // setTimeout(() => {
                //     obterDados(filtros);
                // }, 200)
            },
            (erro) => {
                mostrarToast('erro', mensagens.erro);
                // setEnviando(false);
            })
    }

    const maisAcoesObj = [

        (propsAcao) => <Visualizar to={'/administracao/notificacao/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <AlterarPersonalizado icone={'icon-Pen'} to={propsAcao.row.usuario_id === user.id && '/administracao/notificacao/alteracao/' + propsAcao.id} action={() => { propsAcao.row.usuario_id !== user.id && mostrarToast('erro', 'Ação permitida somente para o responsável da notificação') }} />,
        (propsAcao) => <Excluir to={'/administracao/notificacao/consulta/'} action={() => propsAcao.row.notificacao_enviada ? mostrarToast('erro', 'Ação não permitida para notificação já enviada') : exclusao(propsAcao)} />,
        (propsAcao) => propsAcao.id === objCarregar.id && objCarregar.carregando ? <span className="spinner-border spinner-border-md" style={{ height: 20, width: 20,  opacity: 0.2 }} role="status" aria-hidden="true"></span> : <EnviarNotificacao to={'/administracao/notificacao/alteracao/'} action={() => { setObjCarregar({ id: propsAcao.id, carregando: true }); envioNotificacao(propsAcao) }} />
    ]
    return (
        <>
            <div>
                <DataGridAlternativa target={target} data={data} columns={columns} setData={setData} acoes={true} acoesObj={maisAcoesObj} caminhoBase='/administracao/notificacao' modulo={props.modulo} titulo={'Consulta Notificação'}
                    tituloLista={'Lista Notificação'}
                    urlCadastro='/administracao/notificacao/cadastro' icone={'icon-New-Mail'} caminhoConsultaFiltros='/administracao/notificacaoPaginacao' />

            </div>
        </>
    )
}

export default ConsultaNotificacao