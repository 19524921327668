import React, { useState, useEffect } from 'react'
import { ModalPadraoCadastro } from '../../../../components/Modais'
import { reduzTexto } from '../../../../util/FuncoesComuns';

const DetalhesLote = (props) => {
    const [itemDetalhes, setItemDetalhes] = useState({});

    useEffect(() => {
        setItemDetalhes(props.data)
    }, [props.data])

    return <ModalPadraoCadastro titulo={props.titulo || 'Detalhes'} showModal={props.showModal} setShowModal={props.setShowModal}>
        <div><div className='detalhes'>
            {itemDetalhes.lote && itemDetalhes.lote.map((item, idx) => {
                return (
                    <>
                        {idx === 0 ? <></> : <hr />}
                        <div className='item-detalhes'>
                            <h6><b>{item.titulo}</b></h6>
                            <h6>{item.descricao}</h6>
                            {item.html}
                        </div></>
                )
            })}
        </div>
            {
                itemDetalhes && itemDetalhes.historico_status && itemDetalhes.historico_status.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Histórico de Status</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes && itemDetalhes.historico_status && itemDetalhes.historico_status.map(item => <li className="mb-1">
                            <div className='col-hora row-space-between'>

                                <span className='span-hora'>{item.data_registro}</span>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span className={'tag'} style={{ backgroundColor: item.cor_status, color: 'white', marginLeft: 0 }}>{item.descricao}</span>
                            </div>

                            <div className='mt-2'>
                                <i><p>{item.justificativa_status}</p></i>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span></span>
                                <span className='span-nome'><b>{`${item.usuario ? item.usuario.toUpperCase() : ''}`}</b></span>
                            </div>
                        </li>)
                    }
                </ul>
            </div>
            {
                itemDetalhes && itemDetalhes.historico_status && itemDetalhes.historico_status.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Dados Envio Lote</span></h5>
                    </div>
                    : <></>
            }
           <div className='detalhes'>
            {itemDetalhes.envio && itemDetalhes.envio.map((item, idx) => {
                return (
                    <>
                        {idx === 0 ? <></> : <hr />}
                        <div className='item-detalhes'>
                            <h6><b>{item.titulo}</b></h6>
                            <h6>{item.descricao}</h6>
                            {/* {item.html} */}
                        </div></>
                )
            })}
        </div>
        </div>
    </ModalPadraoCadastro >
}


export default DetalhesLote