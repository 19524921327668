import { InputPadrao } from '../../../../components/Inputs'
import { InputSimNao } from '../../../../components/InputsAutoComplete'
import React, { useState, useEffect } from 'react'
import Yup from '../../../../util/Validacoes'
import { HeaderCadastro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'

export default props => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})

    const [validacao] = useState(Yup.object({
        descricao: Yup.string().required().nullable().max(200).trim(),
        codigo: Yup.string().required().nullable().max(50).trim(),
    }))

    const manter = []

    const Submit = (values, propsFormik) => {
        setSalvando(true)

        salvar(
            { ...values },
            '/faturamento/cid10',
            obj => {
                if (props.modal) {
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else {
                        props.setItemSelecionado({ value: obj.id, label: obj.descricao })
                        props.setShow && props.setShow(false)
                    }
                }
                else {
                    mostrarToast('sucesso', mensagens.sucesso)
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                    }
                    else
                        props.history.push('/faturamento/cid10/consulta')
                }
            },
            () => setSalvando(false))
    }

    useEffect(_ => {

        if (!props.modal)
            Promise.resolve(get(`/faturamento/cid10/${id}`)).then(obj => {
                if (id && !obj.id)
                    props.history.push('/faturamento/cid10/consulta')

                setValoresIniciais(obj)
            })
    }, [id])

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>faturamento</span>,
        () => <Link className='link-active' to={'/faturamento/cid10/consulta'}>CID-10</Link>,
    ]

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => {
                return (<Form>
                    {!props.modal && <HeaderCadastro titulo={"Cadastro CID-10"} link={links} />}
                    <div className={props.modal && props.modal || 'card mb-20'}>
                        <div className={props.modal || 'card-body'}>
                            <div className='row'>
                                <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' />
                                <InputPadrao label='Código' name='codigo' type='text' placeholder='Código' obrigatorio={true}
                                    tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} autoFocus salvarENovo={salvarENovo} />
                                <InputPadrao label='Descrição' name='descricao' type='text' placeholder='Descrição' obrigatorio={true}
                                    tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo}  salvarENovo={true} />

                            </div>
                        </div>
                    </div>
                    {!props.modal && <div className='separator mt-40 mb-5'></div>}
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'}/>
                        {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                    </div>
                </Form>)
            }}
        </Formik>
    )
}