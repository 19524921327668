import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputEmpresa, InputSocioStatus, InputTipoRelatorio, InputGrupoEmpresa, InputEstadoCivil, InputRaca, InputSexo, InputParentesco, InputTipoEndereco, InputBairro, InputMunicipio, InputCBOS, InputPais, InputEstado, InputTipoRelatorioPersonalizado } from '../../../components/InputsAutoComplete'
import { InputDatePicker, InputMonthPicker, InputPadrao } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes';
import { MODULOS_SISTEMA } from '../../../util/Enums';

export default props => {
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Sócio')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '' })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)

    const tipoRelatorio = [
        { label: 'Geral', value: MODULOS_SISTEMA.RELATORIO_SOCIO_GERAL },
        { label: 'Cônjuge', value: MODULOS_SISTEMA.RELATORIO_SOCIO_CONJUGE },
        { label: 'Dependente', value: MODULOS_SISTEMA.RELATORIO_SOCIO_DEPENDENTE },
        { label: 'Endereço', value: MODULOS_SISTEMA.RELATORIO_SOCIO_ENDERECO },
        { label: 'Empresa', value: MODULOS_SISTEMA.RELATORIO_SOCIO_EMPRESA },
        { label: 'Grupo', value: MODULOS_SISTEMA.RELATORIO_SOCIO_GRUPO },
        { label: 'Admissão/Demissão/Hierquização', value: MODULOS_SISTEMA.RELATORIO_SOCIO_HIERARQUIZACAO },
    ]
    const inputRef = useRef(null);

    const validacao = Yup.object({
        data_aniversario_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_aniversario_inicial ? '01/' + this.parent.data_aniversario_inicial : this.parent.data_aniversario_inicial) }),
        data_aniversario_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_aniversario_final ? '01/' + this.parent.data_aniversario_final : this.parent.data_aniversario_final) }),
        data_hierarquizacao_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_hierarquizacao_inicial) }),
        data_hierarquizacao_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_hierarquizacao_final) }),
        data_nascimento_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_nascimento_inicial) }),
        data_nascimento_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_nascimento_final) })
    })


    const relatoriosPorTipo = propsFormik => [
        {
            titulo: 'Relatorio Sócio - Geral',
            tipo: MODULOS_SISTEMA.RELATORIO_SOCIO_GERAL,
            url: '/relatorio/socio',
            campos: [
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputSocioStatus label="Status" placeholder="Status" name="status" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputGrupoEmpresa label="Grupo" placeholder="Grupo" name="grupo" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputDatePicker label="Data Hierarquização Inicial" name="data_hierarquizacao_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_hierarquizacao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Hierarquização Final" name="data_hierarquizacao_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_hierarquizacao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputSexo label="Sexo" placeholder="Sexo" name="sexo" propsFormik={propsFormik} multiplo />,
                <InputRaca label="Raça" placeholder="Raça" name="raca" propsFormik={propsFormik} multiplo />,
                <InputEstadoCivil label="Estado Cívil" placeholder="Estado Cívil" name="estado_civil" propsFormik={propsFormik} multiplo />,
                <InputDatePicker label="Data de Nascimento Inicial" name="data_nascimento_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_nascimento_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data de Nascimento Final" name="data_nascimento_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_nascimento_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputMonthPicker label="Data de Aniversário Inicial" name="data_aniversario_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_aniversario_inicial", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputMonthPicker label="Data de Aniversário Final" name="data_aniversario_final" type="text" classeTooltip={'tooltip-input-modal'}
                    setarValor={value => { propsFormik.setFieldValue("data_aniversario_final", value) }} />
            ],
            colunas: [
                { label: 'Código', name: 'id', visivel: false, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Sigla', name: 'sigla', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Nome', name: 'nome', visivel: true, ordenamento: { tipo: 'texto', ativo: true, modo: 'asc' } },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Sexo', name: 'sexo', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Raça', name: 'raca', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CPF', name: 'cpf', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estado Civil', name: 'estado_civil', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data de Nascimento', name: 'data_nascimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'CRM', name: 'crm', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estado(CRM)', name: 'estado_crm', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Especialidade Médica', name: 'cbos', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Documento', name: 'tipo_documento_identificacao', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Nº Doc', name: 'numero_documento', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'UF', name: 'estado_orgao_emissor', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Expedição', name: 'data_expedicao', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'País Emissor', name: 'pais_emissor', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'NIT/PIS/PASEP', name: 'inss', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Titulo de Eleitor', name: 'titulo_eleitor', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Nacionalidade', name: 'pais_nacionalidade', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Naturalidade', name: 'municipio_naturalidade', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Nome da Mãe', name: 'nome_mae', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Nome do Pai', name: 'nome_pai', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'E-mail', name: 'email', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Telefone', name: 'telefone', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data de Registro', name: 'data_registro', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Grupo', name: 'grupo_id', name_coluna: 'grupo' },
                { label: 'Data Hierarquização Inicial', name: 'socio_hierarquizacao.data', name_coluna: 'data_hierarquizacao_inicial' },
                { label: 'Data Hierarquização Final', name: 'socio_hierarquizacao.data', name_coluna: 'data_hierarquizacao_final' },
                { label: 'Sexo', name: 'sexo_id', name_coluna: 'sexo' },
                { label: 'Raça', name: 'raca_id', name_coluna: 'raca' },
                { label: 'Estado Cívil', name: 'estado_civil_id', name_coluna: 'estado_civil' },
                { label: 'Data de Nascimento Inicial', name: 'data_nascimento', name_coluna: 'data_nascimento_inicial' },
                { label: 'Data de Nascimento Final', name: 'data_nascimento', name_coluna: 'data_nascimento_final' },
                { label: 'Data de Aniversário Inicial', name: 'data_aniversario', name_coluna: 'data_aniversario_inicial' },
                { label: 'Data de Aniversário Final', name: 'data_aniversario', name_coluna: 'data_aniversario_final' },
            ]
        },
        {
            titulo: 'Relatorio Sócio - Cônjuge',
            tipo: MODULOS_SISTEMA.RELATORIO_SOCIO_CONJUGE,
            url: '/relatorio/socioConjuge',
            campos: [
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputSocioStatus label="Status" placeholder="Status" name="status" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputSexo label="Sexo" placeholder="Sexo" name="sexo" propsFormik={propsFormik} multiplo />,
                <InputMonthPicker label="Data de Aniversário Inicial" name="data_aniversario_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_aniversario_inicial", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputMonthPicker label="Data de Aniversário Final" name="data_aniversario_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_aniversario_final", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputCBOS label="Profissão" name="profissao_cbos_id" placeholder="Profissão" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputPais label="Nacionalidade" placeholder="Nacionalidade" name="nacionalidade" propsFormik={propsFormik} multiplo />,
                <InputMunicipio label="Naturalidade" placeholder="Naturalidade" name="naturalidade" propsFormik={propsFormik} multiplo menuPlacement='top' />
            ],
            colunas: [
                { label: 'Código', name: 'id', visivel: false, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Sigla', name: 'sigla', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Sócio', name: 'socio', visivel: true, ordenamento: { tipo: 'texto', ativo: true, modo: 'asc' } },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Nome Cônjuge', name: 'nome_conjuge', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CPF', name: 'cpf', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Nome Pai', name: 'nome_pai', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Nome Mãe', name: 'nome_mae', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data de Nascimento', name: 'data_nascimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Profissão', name: 'profissao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Nacionalidade', name: 'nacionalidade', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Naturalidade', name: 'naturalidade', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Telefone', name: 'telefone', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro', name: 'data_registro', visivel: false, ordenamento: { tipo: 'data', ativo: false } },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Sexo', name: 'socio_conjuge.sexo_id', name_coluna: 'sexo' },
                { label: 'Data de Aniversário Inicial', name: 'data_aniversario', name_coluna: 'data_aniversario_inicial' },
                { label: 'Data de Aniversário Final', name: 'data_aniversario', name_coluna: 'data_aniversario_final' },
                { label: 'Profissão', name: 'profissao_cbos_id', name_coluna: 'profissao_cbos_id' },
                { label: 'Nacionalidade', name: 'socio_conjuge.pais_id', name_coluna: 'nacionalidade' },
                { label: 'Naturalidade', name: 'socio_conjuge.naturalidade_codigo_ibge', name_coluna: 'naturalidade' },
            ]
        },
        {
            titulo: 'Relatorio Sócio - Dependente',
            tipo: MODULOS_SISTEMA.RELATORIO_SOCIO_DEPENDENTE,
            url: '/relatorio/socioDependente',
            campos: [
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputSocioStatus label="Status" placeholder="Status" name="status" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputSexo label="Sexo" placeholder="Sexo" name="sexo" propsFormik={propsFormik} multiplo />,
                <InputParentesco label="Parentesco" placeholder="Parentesco" name="parentesco" propsFormik={propsFormik} multiplo />,
                <InputDatePicker label="Data de Nascimento Inicial" name="data_nascimento_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_nascimento_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data de Nascimento Final" name="data_nascimento_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_nascimento_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputMonthPicker label="Data de Aniversário Inicial" name="data_aniversario_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_aniversario_inicial", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputMonthPicker label="Data de Aniversário Final" name="data_aniversario_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_aniversario_final", value) }} classeTooltip={'tooltip-input-modal'} />
            ],
            colunas: [
                { label: 'Código', name: 'id', visivel: false, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Sigla', name: 'sigla', visivel: true, ordenamento: { tipo: 'texto', ativo: false, modo: 'asc' } },
                { label: 'Sócio', name: 'socio', visivel: true, ordenamento: { tipo: 'texto', ativo: true, modo: 'asc' } },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Dependente', name: 'nome', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CPF', name: 'cpf', visivel: true },
                { label: 'Parentesco', name: 'parentesco', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data de Nascimento', name: 'data_nascimento_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Início Dependencia', name: 'inicio_dependencia_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Fim Dependencia', name: 'termino_dependencia_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'data', ativo: false } }
            ],
            data: [],
            colunasFiltros: [
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Sexo', name: 'sexo_id', name_coluna: 'sexo' },
                { label: 'Parentesco', name: 'parentesco_id', name_coluna: 'parentesco' },
                { label: 'Data de Nascimento Inicial', name: 'data_nascimento', name_coluna: 'data_nascimento_inicial' },
                { label: 'Data de Nascimento Final', name: 'data_nascimento', name_coluna: 'data_nascimento_final' },
                { label: 'Data de Aniversário Inicial', name: 'data_aniversario', name_coluna: 'data_aniversario_inicial' },
                { label: 'Data de Aniversário Final', name: 'data_aniversario', name_coluna: 'data_aniversario_final' }
            ]
        },
        {
            titulo: 'Relatorio Sócio - Endereço',
            tipo: MODULOS_SISTEMA.RELATORIO_SOCIO_ENDERECO,
            url: '/relatorio/socioEndereco',
            campos: [
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputSocioStatus label="Status" placeholder="Status" name="status" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputTipoEndereco label="Tipo Endereço" placeholder="Tipo Endereço" name="tipo_endereco" propsFormik={propsFormik} multiplo />,
                <InputBairro label="Setor" placeholder="Setor" name="setor" propsFormik={propsFormik} multiplo />,
                <InputMunicipio label='Município' name='municipio' placeholder='Município - UF'
                    propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputEstado label='Estado' name='estado' placeholder='Estado'
                    propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />
            ],
            colunas: [
                { label: 'Código', name: 'id', visivel: false, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Sigla', name: 'sigla', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Sócio', name: 'socio', visivel: true, ordenamento: { tipo: 'texto', ativo: true, modo: 'asc' } },
                { label: 'Empresa', name: 'empresa', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Endereço', name: 'tipo_endereco', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Logradouro', name: 'endereco_logradouro', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Complemento', name: 'endereco_complemento', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Número', name: 'endereco_numero', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Setor', name: 'setor', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Município/UF', name: 'municipio', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Data Registro', name: 'data_registro', visivel: false, ordenamento: { tipo: 'data', ativo: false } },

            ],
            data: [],
            colunasFiltros: [
                { label: 'Empresa', name: 'empresa.id', name_coluna: 'empresa' },
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Tipo Endereço', name: 'endereco_tipo.ID', name_coluna: 'tipo_endereco' },
                { label: 'Setor', name: 'endereco.bairro', name_coluna: 'setor' },
                { label: 'Município', name: 'endereco.codigo_ibge', name_coluna: 'municipio' },
                { label: 'Estado', name: 'estado.ID', name_coluna: 'estado' },
            ]
        },
        {
            titulo: 'Relatorio Sócio - Empresa',
            tipo: MODULOS_SISTEMA.RELATORIO_SOCIO_EMPRESA,
            url: '/relatorio/socioEmpresa',
            campos: [
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputSocioStatus label="Status" placeholder="Status" name="status" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
            ],
            colunas: [
                { label: 'Código', name: 'id', visivel: false, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Sigla', name: 'sigla', visivel: true, ordenamento: { tipo: 'texto', ativo: false, modo: 'asc' } },
                { label: 'Sócio', name: 'socio', visivel: true, ordenamento: { tipo: 'texto', ativo: true, modo: 'asc' } },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Empresa', name: 'empresa', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Início', name: 'data_inicial_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Fim', name: 'data_final_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false } }
            ],
            data: [],
            colunasFiltros: [
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' }
            ]
        },
        {
            titulo: 'Relatorio Sócio - Grupo',
            tipo: MODULOS_SISTEMA.RELATORIO_SOCIO_GRUPO,
            url: '/relatorio/socioGrupo',
            campos: [
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputGrupoEmpresa label="Grupo" placeholder="Grupo" name="grupo" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
                <InputSocioStatus label="Status" placeholder="Status" name="status" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
            ],
            colunas: [
                { label: 'Código', name: 'codigo_socio', visivel: false, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Sigla', name: 'sigla_socio', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Sócio', name: 'nome_socio', visivel: true, ordenamento: { tipo: 'texto', ativo: true, modo: 'asc' } },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Grupo', name: 'grupo', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Empresa', name: 'nome_empresa', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Início', name: 'data_inicial_grupo', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Fim', name: 'data_final_grupo', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Observação', name: 'obs', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data de Registro', name: 'data_registro_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false } }
            ],
            data: [],
            colunasFiltros: [
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Grupo', name: 'grupo_id', name_coluna: 'grupo' },

            ]
        },
        {
            titulo: 'Relatório de Admissão/Demissão/Hierquização',
            tipo: MODULOS_SISTEMA.RELATORIO_SOCIO_HIERARQUIZACAO,
            url: '/relatorio/socioHierarquizacao',
            campos: [
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,

                <InputGrupoEmpresa label="Grupo" placeholder="Grupo" name="grupo" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,

                <InputDatePicker label="Data Hierarquização Inicial" name="data_hierarquizacao_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_hierarquizacao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,

                <InputDatePicker label="Data Hierarquização Final" name="data_hierarquizacao_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_hierarquizacao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,

                <InputDatePicker label="Data Admissão Inicial" name="data_admissao_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_admissao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,

                <InputDatePicker label="Data Admissão Final" name="data_admissao_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_admissao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,

                <InputDatePicker label="Data Demissão Inicial" name="data_demissao_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_demissao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,

                <InputDatePicker label="Data Demissão Final" name="data_demissao_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_demissao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,

                <InputSocioStatus label="Status" placeholder="Status" name="status" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} multiplo />,
            ],
            colunas: [
                { label: 'Código', name: 'codigo_socio', visivel: false, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Sigla', name: 'sigla', visivel: true, ordenamento: { tipo: 'texto', ativo: true, modo: 'asc' } },
                { label: 'Sócio', name: 'nome_socio', visivel: true, ordenamento: { tipo: 'texto', ativo: true, modo: 'asc' } },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Hierarquização', name: 'data_hierarquizacao', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Data Admissão', name: 'data_admissao', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Data Demissão', name: 'data_demissao', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Posição', name: 'posicao', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Empresa', name: 'empresa', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Grupo', name: 'grupo', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Observação', name: 'observacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro', name: 'data_registro', visivel: true, ordenamento: { tipo: 'data', ativo: false } }

            ],
            data: [],
            colunasFiltros: [
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Grupo', name: 'grupo_id', name_coluna: 'grupo' },
                { label: 'Data Hierarquização Inicial', name: 'socio_hierarquizacao.data', name_coluna: 'data_hierarquizacao_inicial' },
                { label: 'Data Hierarquização Final', name: 'socio_hierarquizacao.data', name_coluna: 'data_hierarquizacao_final' },
                { label: 'Data Admissão Inicial', name: 'socio_hierarquizacao.data_admissao', name_coluna: 'data_admissao_inicial' },
                { label: 'Data Admissão Final', name: 'socio_hierarquizacao.data_admissao', name_coluna: 'data_admissao_final' },
                { label: 'Data Demissão Inicial', name: 'socio_hierarquizacao.data_demissao', name_coluna: 'data_demissao_inicial' },
                { label: 'Data Demissão Final', name: 'socio_hierarquizacao.data_demissao', name_coluna: 'data_demissao_final' },
            ]
        }
    ]

    const submit = (values, propsFormik) => {
        setCarregando(true)
        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)

        setShowModalFiltros(false)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]

        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio)
            setFiltros(Object.keys(values).map((x, idx, array) => {
                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                })
            }
            ).filter(x => x))
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }

        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{ numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: filtros })
    }, [filtros])

    const changeTipo = (value) => {
        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({})
        // const index = tipoRelatorio.findIndex(x => x.value === value)
        setValuesFiltros({ tipo_relatorio: value })
        setRelatorioSelecionado(value?.value)
    }

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })}>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        <InputTipoRelatorioPersonalizado label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                            propsFormik={propsFormik} modulos={relatoriosPorTipo(propsFormik).map(x => x.tipo)} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} />
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}
                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio>
    )
}