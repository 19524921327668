import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { ordenarArrayObj, downloadArquivoS3 } from '../../../../util/FuncoesComuns'
import { formatarMoeda } from '../../../../util/Utilitarios';

const VisualizacaoAnestesiaOdonto = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/faturamento/anestesiaOdontologia', id,
        
        obj => {
                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização',
                    grupos: [
                        {
                            titulo: 'Dados',
                            colunas: [
                                { titulo: 'Ano', descricao: obj.ano, tamanho: 'col-lg-3' },
                                { titulo: 'Data Inicial', descricao: obj.data_inicio, tamanho: 'col-lg-3' },
                                { titulo: 'Data Final', descricao: obj.data_fim, tamanho: 'col-lg-3' },
                                { titulo: 'Observação', descricao: obj.observacao, tamanho: 'col-lg-3' },

                            ]
                        }, {
                            tipoGrupo: 'tabela',
                            titulo: 'Tabela(s)',
                            visivel: obj.tabelas.length > 0,
                            thead:
                                <tr>
                                    <td className='coluna-3'>Código</td>
                                    <td className='coluna-1'>Tabela</td>
                                </tr>,
                            tbody: obj.tabelas == undefined || obj.tabelas.length < 1 || obj.tabelas.filter(x => x.id !== null).length === 0 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.tabelas.map(x => {
                                    return (
                                        <tr>
                                            <td>{x.id}</td>
                                            <td>{x.descricao}</td>
                                        </tr>)
                                })
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Valores Honorário Consultório',
                            minimizar: true,
                            thead:
                                <tr>
                                    <td className='coluna-2'>Hora Inicial</td>
                                    <td className='coluna-2'>Hora Final</td>
                                    <td className='coluna-2'>Local Atendimento</td>
                                    <td className='coluna-2'>Valor R$</td>
                                    <td className='coluna-2'>Valor R$ (Med./Oxig.)</td>
                                    <td className='coluna-2'>Valor Total R$</td>
                                </tr>,
                            tbody:
                                obj.valores_honorario_consultorio && obj.valores_honorario_consultorio.sort().map(x =>
                                    <tr>
                                        <td>{x.hora_inicial}</td>
                                        <td>{x.hora_final}</td>
                                        <td>{x.local_atendimento.label}</td>
                                        <td>{formatarMoeda(x.valor)}</td>
                                        <td>{formatarMoeda(x.valor_med_ox)}</td>
                                        <td>{formatarMoeda(x.valor_total)}</td>
                                    </tr>)
                        },
                        {   
                            tipoGrupo: 'tabela',
                            titulo: 'Valores Honorário Internação',
                            minimizar: true,
                            thead:
                                <tr>
                                    <td className='coluna-2'>Hora Inicial</td>
                                    <td className='coluna-2'>Hora Final</td>
                                    <td className='coluna-2'>Local Atendimento</td>
                                    <td className='coluna-2'>Valor R$</td>
                                    <td className='coluna-2'>Valor R$ (Med./Oxig.)</td>
                                    <td className='coluna-2'>Valor Total R$</td>
                                </tr>,
                            tbody:
                                obj.valores_honorario_internacao && obj.valores_honorario_internacao.sort().map(x =>
                                    <tr>
                                        <td>{x.hora_inicial}</td>
                                        <td>{x.hora_final}</td>
                                        <td>{x.local_atendimento.label}</td>
                                        <td>{formatarMoeda(x.valor)}</td>
                                        <td>{formatarMoeda(x.valor_med_ox)}</td>
                                        <td>{formatarMoeda(x.valor_total)}</td>
                                    </tr>)
                        }
                    ]
                } : { titulo: 'Visualização Anestesia Odontológica' })
            })
    }, [id])
    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default VisualizacaoAnestesiaOdonto