import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputTipoRelatorio, InputEstabelecimentoSaude, InputSimNao, InputOperadoraSaude, InputSocio, InputCirurgiaoCombo, InputTipoExecutante, InputTipoCliente, InputMunicipioGOPrimeiro } from '../../../components/InputsAutoComplete'
import { InputDatePicker, InputPadrao } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes';

export default props => {
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Cliente')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '' })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)

    const inputRef = useRef(null);

    const validacao = Yup.object({
        data_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) }),
        data_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) })
    })

    const relatoriosPorTipo = propsFormik => [
        {

            titulo: 'Relatório Cliente',
            tipo: 1,
            url: '/financeiro/relatoriocliente',
            campos: [

                <InputTipoCliente label="Tipo Cliente" placeholder="Tipo Cliente" name="tipo_cliente" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputTipoExecutante label="Tipo Pessoa" placeholder="Tipo Pessoa" name="tipo_pessoa" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputMunicipioGOPrimeiro label="Município" placeholder="Município" name="municipio" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputDatePicker label="Data Registro Inicial" name="data_inicial" type="text" autoFocus
                    setarValor={value => { propsFormik.setFieldValue("data_inicial", value) }} />,
                <InputDatePicker label="Data Registro Final" name="data_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_final", value) }} />,
            ],
            numerarLinha: true,
            agrupamento: [],
            colunas: [
                { label: 'Código', name: 'id', visivel: false, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Sigla/Fantasia/Nome Social', name: 'nome_social_fantasia_sigla',  width: '15%', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Nome/Razão Social', name: 'nome', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CPF/CNPJ', name: 'cpf_cnpj', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Cliente', name: 'tipo_cliente', visivel: true,  width: '6%', ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Pessoa', name: 'tipo_pessoa', visivel: true, width: '10%', ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro', name: 'data_registro', visivel: false, width: '10%', ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Município', name: 'municipio', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
            ],
            data: [],
            colunasFiltros: [

                { label: 'Tipo Cliente', name: 'id_tipo_cliente', name_coluna: 'tipo_cliente' },
                { label: 'Tipo Pessoa', name: 'id_tipo_pessoa', name_coluna: 'tipo_pessoa' },
                { label: 'Município', name: 'municipio_codigo_ibge', name_coluna: 'municipio' },
                { label: 'Data Registro Inicial', name: 'data_inicial', name_coluna: 'data_inicial' },
                { label: 'Data Registro Final', name: 'data_final', name_coluna: 'data_final' },

            ]
        },
    ]
    // console.log(relatoriosPorTipo(propsFormik).find(x => x.tipo === 1), 'novo relatório')


    const submit = (values, propsFormik) => {
        setCarregando(true)
        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === 1)
        values.tipo_relatorio = 1
        setShowModalFiltros(false)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]

        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio) {
            setFiltros(Object.keys(values).map((x, idx, array) => {
                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value) && valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                })
            }).filter(x => x && x.value))
        }
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }

        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)

                setDados([{ numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: filtros })
    }, [filtros])

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros}
            loading={carregando}>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === 1) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === 1).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}

                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}
