import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'

export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/faturamento/acomodacao', id,
            obj =>
                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Acomodação',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Descrição', descricao: obj.descricao, tamanho: 'col-lg-4' },
                                { titulo: 'Código TISS', descricao: obj.codigo_tiss, tamanho: 'col-lg-4' },
                                { titulo: 'Utiliza?', descricao: obj.utiliza.label, tamanho: 'col-lg-4' },
                                { titulo: 'Abreviação', descricao: obj.abreviacao, tamanho: 'col-lg-4' },
                                { titulo: 'Ordenamento', descricao: obj.ordenamento, tamanho: 'col-lg-4' },
                                { titulo: 'Codigo Cooperativa', descricao: obj.codigo_coopanest, tamanho: 'col-lg-4'},
                                { titulo: 'Data de Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                            ]
                        }
                    ]
                } : { titulo: 'Visualização Acomodação' })
        )
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}