import React, { useState } from 'react';
import { FaTimes, FaCheck, FaPrint } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { FiAlertTriangle } from 'react-icons/fi';

const mostrarToast = (type, msg, time) => {
    switch (type) {
        case 'sucesso':
            toast.success(<><FaCheck /> {msg}</>, {
                autoClose: time || 1200
            })
            break;
        case 'erro':
            toast.error(<><FaTimes /> {msg}</>)
            break;
        case 'alerta':
            toast.warning(<><FiAlertTriangle /> {msg}</>,)
            break;
        case 'print-msg':
            toast(<><FaPrint /> {msg}</>, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            break;
        default:
            toast.info(<><FaPrint /> {msg}</>)
    }
}

const mostrarToastNf = (type, msg, time) => {
    switch (type) {
        case 'nfe_autorizada':
            toast.success(<><FaCheck /> {msg}</>, {
                autoClose: time || 1200
            })
            break;
        case 'erro':
        case 'nfe_cancelada':
        case 'nao_encontrado':
        case 'nfe_nao_autorizada':
        case 'requisicao_invalida':
        case 'empresa_nao_habilitada':
        case 'certificado_vencido':
        case 'nfe_cancelada':
            toast.error(<><FaTimes /> {msg}</>)
            break;
        case 'em_processamento':
        case 'permissao_negada':
            toast.info(<><FaTimes /> {msg}</>)
            break;
    }
}

const mostrarToastWebService = (type, msg, time) => {
    // const [message, setMessage] = useState(msg)

    switch (type) {
        case 'sucesso':
            toast.success(<><FaCheck /> {msg}</>, {
                autoClose: time || 1200
            })
            break;
        case 'erro':
            toast.error(<><FaTimes /> {msg}</>)
            break;
        case 'alerta':
            toast.warning(<><FiAlertTriangle /> {msg}</>,)
            break;
        case 'print-msg':
            toast(<><FaPrint /> {msg}</>, {
                position: "bottom-right",
                // autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
            break;
        case 'progress':
            toast(msg, {
                position: "bottom-right",
                // autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                closeButton: false,
                progress: 1
            });
            break;
        default:
            toast.info(<><FaPrint /> {msg}</>)
    }
}

const toastPrintMessage = (msg) => {
    const toastify = toast(<><FaPrint /> {msg}</>, {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        autoClose: false
    })
    return toastify;
}

export { mostrarToast, mostrarToastWebService, toastPrintMessage, mostrarToastNf }
