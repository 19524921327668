import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaData, periodoData, validaHora } from '../../../../../util/Validacoes';
import { mostrarToast } from '../../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { get, salvar, validar } from '../../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao, InputDatePicker, TextArea, InputTimePicker, InputDecimal } from '../../../../../components/Inputs'
import { InputMercadoTipo, InputSocioAtivosComConselho } from '../../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../../components/Botoes'
import { mensagens } from '../../../../../util/Utilitarios'
import InputArquivoS3 from '../../../../../components/InputArquivoS3'
import { MODULO } from '../../../../../util/Enums'
import Atalho from '../../../../../util/Atalho'
import { ModalCadastro } from '../../../../../components/Modais'
import { ModalDuasOpcoes } from '../../../../../components/Modais'


const CadastroMercado = (props) => {
    let { id } = useParams()
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [dataArquivos, setDataArquivos] = useState(null)
    const [limparArquivos, setLimparArquivos] = useState(false)

    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [msgModal, setMsgModal] = useState(<></>)
    const [esconderPodeContinuar, setEsconderPodeContinuar] = useState(false)



    let validacaoInicial = Yup.object({
        data_transferencia: Yup.string().nullable().required()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_transferencia) }),
        mercado_tipo: Yup.string().nullable().required(),
        remetente: Yup.string().nullable().required(),
        destinatario: Yup.string().nullable().required(),
        dias: Yup.string().nullable().required(),

    })


    const manter = ['data_transferencia', 'mercado_tipo']

    const getValidacao = (values, propsFormik) => {
        setSalvando(true)
        Promise.resolve(get(`/administracao/ferias/obtervalidacoesmercado`, { ...values })).then(obj => {
            if (obj.length > 0) {
                setSalvando(false);
                setShowModalValidacao(obj.length > 0);
                setMsgModal(obj.map(x => <p>- {x.msg}</p>));
                setEsconderPodeContinuar(obj.filter(x => x.tipo == "bloqueio").length > 0)
            }
            else {
                funcaoAposSim(values, propsFormik)
            }
        })
    }

    const Submit = (values, propsFormik) => {
        getValidacao(values, propsFormik)
    }

    const funcaoAposSim = (values, propsFormik) => {

        setSalvando(true)
        values.arquivo = dataArquivos
        setShowModalValidacao(false)
        salvar(
            values,
            '/administracao/ferias/mercado',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                }
                else if (!props.setAbrirModal)
                    props.history.push('/administracao/ferias/mercado/consulta')
                else if (props.setAbrirModal) {
                    props.funcaoAposSalvar();
                    props.setAbrirModal(false);
                }

                props.funcaoAposSalvar();
            },
            () => {
                setSalvando(false)
            })
    }

    useEffect(() => {
        if (props.dataAtual) {
            //let valores = { data_transferencia: props.dataAtual, destinatario: props.socioAtual.label && props.socioAtual };
            let valores = { data_transferencia: props.dataAtual };
            valores.data = props.dataAtual;
            setValoresIniciais(valores)
        }
    }, [])


    useEffect(() => {
        let idAqui = props.idAlteracao ? props.idAlteracao : id;
        if (idAqui > 0) {
            Promise.resolve(get(`/administracao/ferias/mercado/${idAqui}`)).then(val => {
                if (val)
                    setValoresIniciais(val.lancamentos_mercado)
            })
        } else {
        }

    }, [props.idAlteracao, id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>
                <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik.values, propsFormik)} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false); }}
                    esconderFechar mensagem={msgModal} esconderOpcao2={esconderPodeContinuar}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
                <div className={props.modal ? '' : 'card mb-20'}>
                    <div className={props.modal ? '' : 'card-body'}>
                        <div className='row'>
                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                            <InputDatePicker navigation tamanho={props.modal ? 'col-lg-12' : 'col-xl-3 col-md-6'} label="Data da transferência" name="data_transferencia" type="text" obrigatorio="true"
                                autoFocus={true}
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_transferencia", value)
                                }} classeTooltip={props.modal && 'tooltip-input-modal'}
                            />
                            <InputMercadoTipo label="Tipo de Transferência" name="mercado_tipo" propsFormik={propsFormik} tamanho={props.modal ? 'col-lg-12 col-md-12' : 'col-xl-3 col-md-6'}
                                classeTooltip={props.modal && 'tooltip-input-modal'} salvarENovo={salvarENovo} obrigatorio />
                            <InputSocioAtivosComConselho label="Remetente (de:)" name="remetente" propsFormik={propsFormik} tamanho={props.modal ? 'col-lg-12 col-md-12' : 'col-xl-3 col-md-6'}
                                classeTooltip={props.modal && 'tooltip-input-modal'} salvarENovo={salvarENovo} obrigatorio />
                            <InputSocioAtivosComConselho label="Destinatário (para:)" name="destinatario" propsFormik={propsFormik} tamanho={props.modal ? 'col-lg-12 col-md-12' : 'col-xl-3 col-md-6'}
                                classeTooltip={props.modal && 'tooltip-input-modal'} salvarENovo={salvarENovo} obrigatorio />
                            <InputDecimal label='Dias a utilizar' casasDecimais={1} propsFormik={propsFormik} name='dias' max={999} classDiv={props.modal ? 'col-lg-12 col-md-12' : 'col-xl-3 col-md-4'} obrigatorio />
                            <TextArea label='Observação' name='observacao' type='text' palceholder='Descrição' tamanho={props.modal ? 'col-lg-12' : 'col-xl-3 col-md-6'} />
                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                        </div>
                    </div>
                </div>
                <div className='separator mt-40 mb-5'></div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)}
        </Formik>
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Férias</Link>,
        () => <Link className="link-active" to={'/administracao/ferias/mercado/consulta'}>Mercado</Link>,
    ]
    return (<>
        <HeaderCadastro titulo={"Cadastro de Mercado"} link={links} />
        <CadastroMercado {...props} />
    </>
    )
}


const CadastroModalMercado = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Administrativo</Link>,
        () => <Link className="link-active" to={'#'}>Ocorrência</Link>,
    ]
    return (<>
        <ModalCadastro titulo={'Mercado de Férias'} show={props.abrirModal} setShowModal={props.setAbrirModal}>
            <CadastroMercado {...props} />
        </ModalCadastro>
    </>
    )
}

export { CadastroMercado, CadastroModalMercado }
export default Cadastro
