import React from 'react'

const SemPermissao = (props) => (
    <div className='row justify-content-center mt-100'>
        <div className='col-sm-12 col-md-12 col-lg-6'>
                    <div className="form-side">
                        <div className="row justify-content-center mb-1">
                            <p className="icon-Lock fs-40 mb-2"></p>
                        </div>
                        <div className="row justify-content-center mb-1">
                                <h2 className="h2-sm"><b>Acesso Negado!</b></h2>
                        </div>
                        <div className="row justify-content-center mb-1">
                            <div className="col-12 text-center">
                                <span>Você não possui permissão para esse acesso.</span>
                            </div>
                        </div>
                    </div>
        </div>
    </div>
)

export default SemPermissao
