import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../../../components/VisualizacaoPadrao'
import { get, visualizar } from '../../../../../util/AcessoApi'
import { obterDataFormatada } from '../../../../../util/Utilitarios'
import { ordenarArrayObj } from '../../../../../util/FuncoesComuns'


const VisualizacaoUsuario = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})

    useEffect(() => {
        visualizar('/administracao/ferias/marcacao', id, obj => {
            setDados(obj)
        })
    }, [id])
    
    const data = dados ? {
        id,
        modulo: props.modulo,
        titulo: 'Visualização da Marcação',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Código',
                        descricao: dados.id,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Sócio',
                        descricao: dados.socio && dados.socio.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Período',
                        descricao: dados.periodo && dados.periodo.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Hora inicial',
                        descricao: dados.horario_inicial,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Hora final',
                        descricao: dados.horario_final,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'observacao',
                        descricao: dados.observacao,
                        tamanho: 'col-lg-6'
                    }
                ]
            }
        ]
    } : { titulo: 'Visualização da Marcação' }

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoUsuario