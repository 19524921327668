import React, { useState, useEffect, useRef } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'
import { Alterar, Excluir, Visualizar, Imprimir, BtnAcao, Btn, Detalhes, AlteracaoStatusLote, EnviarLoteCoop, VisualizarArquivo, VisualizarErro, VisualizarRelacaoGuias } from '../../../../components/Acoes'
import { BotaoPadrao } from '../../../../components/Botoes'
import moment from 'moment';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { get, excluir, salvar, validar } from '../../../../util/AcessoApi'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens, useWindowSize } from '../../../../util/Utilitarios.js'
import { InputPadrao, TextArea } from '../../../../components/Inputs'
import { InputLoteStatus } from '../../../../components/InputsAutoComplete'
import Yup from '../../../../util/Validacoes';
import { ModalCadastro, ModalDuasOpcoes } from '../../../../components/Modais'
import { Formik, Form } from "formik";
import { LoadRelatorio } from '../../../../components/Loading'
import { useHistory, Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';



const Consulta = rProps => {
    const [data, setData] = useState([])
    const target = useRef(null)
    const [modalAlteracaoStatus, setModalAlteracaoStatus] = useState(false)
    const [itemTrocarStauts, setItemTrocarStatus] = useState({})
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [itemDetalhes, setItemDetalhes] = useState([])
    const [width] = useWindowSize()
    const history = useHistory()


    // useEffect(_ => { consultar('/faturamento/lote', dados => setData(dados.sort((a, b) => b.id - a.id))) }, [rProps])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-lote',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Descrição',
                    accessor: 'descricao',
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Tributo Esfera ',
                    accessor: 'tributo_esfera',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'tributo_esfera',
                        idColuna: 'tributo_esfera_id'
                    }
                },
                {
                    Header: 'Ordenamento',
                    accessor: 'ordenamento',
                    ordenamento: {
                        tipo: 'numero'
                    }
                },
                {
                    Header: 'Data de Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    }
                },
            ]
        }], []
    )

    const acoesObj = [
        (propsAcao) => <Visualizar to={'/financeiro/tributo/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/financeiro/tributo/alteracao/' + propsAcao.row.id} />,
        (propsAcao) => <Excluir to={'financeiro/tributo/consulta/'} action={() => exclusao(propsAcao)} />,
    ]

    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: rProps.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/financeiro/tributo', propsAcao.row.id,
                    () => {
                        // getApi(setData, '/faturamento/guia')
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    return (
        <>
            <div>
                <DataGridAlternativa target={target} acoesObj={acoesObj} data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/financeiro/tributo'
                    caminhoConsultaFiltros='/financeiro/tributopaginacao' modulo={rProps.modulo} titulo={'Consulta Tributo'} tituloLista={'Lista Tributo'}
                    urlCadastro='/financeiro/tributo/cadastro' icone={'icon-Bank'} />
            </div>
        </>
    )

}

export default Consulta