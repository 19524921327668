import { InputPadrao, InputNumero, InputMonthPicker, InputCheckboxSm, InputSwitchSemFormik, InputDatePicker, TextArea, InputMascaraSimples } from '../../../../components/Inputs'
import { InputLoteGuia, InputOperadoraSaude, InputGuiaStatus, InputEstabelecimentoSaude, InputTipoExecutante, InputSocio, InputStatusEnvio, InputEmpresa, InputGuiaStatusParticular } from '../../../../components/InputsAutoComplete'
import React, { useState, useEffect, useRef } from 'react'
import Yup, { validaData } from '../../../../util/Validacoes'
import { HeaderCadastro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form, FieldArray } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { formatarMoeda, mensagens } from '../../../../util/Utilitarios'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get, validar, postComAcao, putComAcao, validarComPost } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'
import { ModalCadastro, ModalDuasOpcoes, ModalPadraoCadastro } from '../../../../components/Modais'
// import { tipoGrupo } from '../../../../components/VisualizacaoPadrao'
import { OverlayTrigger, Overlay, Tooltip } from 'react-bootstrap';
import { FiAlertTriangle } from 'react-icons/fi'
import { incluirGuiaTelaManutencao, filtragemTelaManutencao, incluirGuiaBdManutencao, obterGuiasManutencao, obterGuiasConferencia } from '../../../../util/FuncoesComuns'
import { MinimizarDados } from '../../../../components/Acoes'
import { ACOES, GUIA_STATUS, MODULO } from '../../../../util/Enums'
import { getUsuario } from '../../../../util/Configuracoes'
import Interweave from 'interweave'
import moment from 'moment';
import InputArquivoS3 from '../../../../components/InputArquivoS3V2'


export default props => {
    let { id } = useParams()
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [guias, setGuias] = useState([])
    const [dados, setDados] = useState()
    const [minimizar, setMinimizar] = useState([])
    const location = useLocation()
    const history = useHistory()
    const [carregando, setCarregando] = useState(false);
    const inputFocus = useRef(null)
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [podeEnviarForm, setPodeEnviarForm] = useState(false);

    const [validacao] = useState(Yup.lazy(({ modalAberto }) => Yup.object({
        status: Yup.string().nullable().required(),
    })))


    useEffect(() => {
        if (location?.state?.id) {
            obterGuiasConferencia({ codigo: location.state.id }, val => {
                if (val.id) {
                    setGuias(val)
                    setCarregando(false)
                    setValoresIniciais({
                        lote: "",
                        status: { value: 4, label: 'Conferida' },
                        justificativa: '',
                        codigo_id: location.state.id
                    })

                }
            })
        } else {

            setValoresIniciais({
                lote: "",
                status: { value: 4, label: 'Conferida' },
                justificativa: ''
            })
            document.getElementById('codigo_id').focus();

        }
    }, [location])



    const validarSubmit = async (values, propsFormik) => {
        if (!values.guia) {
            values.guia = JSON.stringify({ id: guias.id, nome_beneficiario: guias.nome_beneficiario, lote_id: guias.guia_lote_id })
        }
        const conferindo = GUIA_STATUS.CONFERIDA === values?.status?.value;
        let msgValidacao = [];
        setSalvando(true)

        const digitalizada = await obterArquivo(guias.id)

        if (guias.id) {
            await validarComPost(`/faturamento/validarLoteConferencia`, { ...values }, (e) => Submit(values, propsFormik), (e) => {
                msgValidacao.push(<>{e?.response?.data?.alertas.map(x => <div className='mb-1'><b>{x}<br /><br /></b></div>)}</>)
            })

            if (conferindo && !digitalizada)
                msgValidacao.push(<><div className='mb-1'><b>Guia não foi digitalizada<br /></b></div></>)

            if (msgValidacao.length > 0) {
                setMensagemModal(msgValidacao)
                setShowModalValidacao(true)
                setSalvando(false)
                setCarregando(false)
            }
        } else {
            setSalvando(false)
            mostrarToast('erro', 'Operação Bloqueada! Selecione uma Guia.')
        }

    }

    const obterArquivo = async (id) => {
        setCarregando(true)
        return await Promise.resolve(get(`/faturamento/guiaArquivos/${id}`)).then(val => {
            if (val.length > 0) 
                return true
            else
                return false
        })

    }

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        values.id = guias.id

        putComAcao(
            ACOES.CONFERENCIA_GUIA,
            props.modulo,
            values,
            '/faturamento/guiaAlterarConferencia',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    propsFormik.setFieldValue('codigo_id', '')
                    propsFormik.setFieldValue('codigo_barras', '')
                    setSalvando(false)
                    setCarregando(false)
                    document.getElementById('codigo_id').focus()
                    setGuias({})
                }
                else
                    props.history.push('/faturamento/guia/consulta')
            },
            (err) => {
                console.log('err: ', err);
                setSalvando(false)
            })
    }

    const funcaoAposSim = (formik) => {
        setShowModalValidacao(false)
        Submit(formik.values, formik)
    }

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Faturamento</span>,
        () => <Link className='link-active' to={'/faturamento/guia/consulta'}>Guia</Link>,
    ]


    useEffect(() => {
        console.log('guias: ', guias);
        if (guias) {

            const obj = guias

            setDados(obj.id ? {
                id: obj.id,
                modulo: 76,
                titulo: 'Visualização',
                grupos: [
                    {
                        titulo: 'Dados Gerais',
                        colunas: [
                            { titulo: 'Codigo', descricao: obj.id, tamanho: 'col-lg-3' },
                            { titulo: 'Código Barras', descricao: obj.codigo_barras, tamanho: 'col-lg-3' },
                            { titulo: 'Data Procedimento', descricao: obj.data_hora_procedimento, tamanho: 'col-lg-3' },
                            { titulo: 'Empresa', descricao: obj.empresa.label, tamanho: 'col-lg-3' },
                            { titulo: 'Status', descricao: obj.status?.descricao, tamanho: 'col-lg-3', cor: obj.status.cor },
                            { titulo: 'Operadora de Saúde', descricao: obj.operadora_saude?.label, tamanho: 'col-lg-3' },
                            { titulo: 'Estabelecimento de Saúde', descricao: obj.estabelecimento_saude?.label, tamanho: 'col-lg-3' },
                            { titulo: 'Acomodação', descricao: obj.acomodacao?.label, tamanho: 'col-lg-3' },
                            { titulo: 'Guia Principal', descricao: obj.guia_principal, tamanho: 'col-lg-3' },
                            { titulo: 'Senha', descricao: obj.senha, tamanho: 'col-lg-3' },
                            { titulo: 'Carteira/Matrícula', descricao: obj.matricula, tamanho: 'col-lg-3' },
                            { titulo: 'Nome Beneficiário', descricao: obj.nome_beneficiario, tamanho: 'col-lg-3' },
                            { titulo: 'Data Nascimento', descricao: obj.data_nascimento, tamanho: 'col-lg-3' },
                            { titulo: 'Cirurgião', descricao: obj.cirurgiao?.label, tamanho: 'col-lg-3' },
                            { titulo: 'Sequencial', descricao: obj?.sequencia_hospital, tamanho: 'col-lg-3' },
                            { titulo: 'Data Registro', descricao: obj.data_registro, tamanho: 'col-lg-3' },
                        ]
                    },
                    {
                        titulo: 'Informações TISS',
                        visivel: obj.tipo_guia === null ? false : obj.tipo_guia && obj.tipo_guia === 2 ? true : false,
                        colunas: [
                            {
                                titulo: 'Tipo Guia',
                                descricao: obj.tipo_guia && obj.tipo_guia,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Prestador',
                                descricao: obj.numero_prestador,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Solicitação Internação',
                                descricao: obj.numero_internacao,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Início Faturamento',
                                descricao: obj.inicio_faturamento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Fim Faturamento',
                                descricao: obj.fim_faturamento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Motivo Encerramento',
                                descricao: obj.motivo_encerramento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Indicador Acidente',
                                descricao: obj.indicador_acidente,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Tipo Faturamento',
                                descricao: obj.tipo_faturamento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Tipo Internação',
                                descricao: obj.tipo_internacao,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Regime Internação',
                                descricao: obj.regime_internacao,
                                tamanho: 'col-lg-3'
                            },
                        ]
                    },
                    {
                        titulo: 'Informações TISS',
                        visivel: obj.tipo_guia_id === null ? false : obj.tipo_guia_id && obj.tipo_guia_id === 3 ? true : false,
                        colunas: [
                            {
                                titulo: 'Tipo Guia',
                                descricao: obj.tipo_guia && obj.tipo_guia,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Prestador',
                                descricao: obj.numero_prestador,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Principal',
                                descricao: obj.numero_principal_sadt,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Tipo Atendimento',
                                descricao: obj.tipo_atendimento_tiss,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Indicação Acidente',
                                descricao: obj.indicador_acidente,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Tipo Consulta',
                                descricao: obj.tipo_consulta,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Motivo Encerramento',
                                descricao: obj.motivo_encerramento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Regime Atendimento',
                                descricao: obj.regime_atendimento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Saúde Ocupacional',
                                descricao: obj.saude_ocupacional,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Indicador Cobertura Especial',
                                descricao: obj.cobertura_especial,
                                tamanho: 'col-lg-3'
                            },

                        ]
                    },
                    {
                        titulo: 'Informações TISS',
                        visivel: obj.tipo_guia_id === null ? false : obj.tipo_guia_id && obj.tipo_guia_id === 4 ? true : false,
                        colunas: [
                            {
                                titulo: 'Tipo Guia',
                                descricao: obj.tipo_guia && obj.tipo_guia,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Prestador',
                                descricao: obj.guia_tiss && obj.guia_tiss.numero_prestador,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Solicitação Internação',
                                descricao: obj.guia_tiss && obj.guia_tiss.numero_internacao,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Início Faturamento',
                                descricao: obj.guia_tiss && obj.guia_tiss.inicio_faturamento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Fim Faturamento',
                                descricao: obj.guia_tiss && obj.guia_tiss.fim_faturamento,
                                tamanho: 'col-lg-3'
                            },

                        ]
                    },
                    {
                        titulo: 'Informações TISS',
                        visivel: obj.tipo_guia_id === null ? false : obj.tipo_guia_id && obj.tipo_guia_id === 1 ? true : false,
                        colunas: [
                            {
                                titulo: 'Tipo Guia',
                                descricao: obj.tipo_guia && obj.tipo_guia,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Número Guia Prestador',
                                descricao: obj.guia_tiss && obj.guia_tiss.numero_prestador,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Indicação Acidente',
                                descricao: obj.guia_tiss && obj.guia_tiss.indicacao_acidente,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Indicador Cobertura Especial',
                                descricao: obj.guia_tiss && obj.guia_tiss.indicador_cobertura_especial,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Regime Atendimento',
                                descricao: obj.guia_tiss && obj.guia_tiss.regime_atendimento,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Saúde Ocupacional',
                                descricao: obj.guia_tiss && obj.guia_tiss.saude_ocupacional,
                                tamanho: 'col-lg-3'
                            },
                            {
                                titulo: 'Tipo Consulta',
                                descricao: obj.guia_tiss && obj.guia_tiss.tipo_consulta,
                                tamanho: 'col-lg-3'
                            },

                        ]
                    },
                    {
                        tipoGrupo: 'tabela',
                        titulo: 'Procedimento(s)',
                        visivel: obj.procedimentos.length > 0,
                        aposGrupo: <tr>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={13}>{formatarMoeda(obj.procedimentos.map(item => item.valor_total).reduce((total, num) => parseFloat(total) + parseFloat(num)))}</td>
                        </tr>,
                        thead:
                            <tr>
                                <td className='coluna-1' colspan="1">Código</td>
                                <td className='coluna-3' colspan="3">Procedimento</td>
                                <td className='coluna-1' colspan="1">Porte</td>
                                <td className='coluna-1' colspan="1">Valor</td>
                                <td className='coluna-1' colspan="1">Acm(%)</td>
                                <td className='coluna-1' colspan="1">Perc(%)</td>
                                <td className='coluna-1' colspan="1">Red/Acrésc.</td>
                                <td className='coluna-1' colspan="1">Urg.</td>
                                <td className='coluna-1' colspan="1">Qtde.</td>
                                <td className='coluna-3' colspan="3" style={{ textAlign: 'right' }}>Valor Total</td>
                            </tr>,
                        tbody: obj?.procedimentos == undefined || obj?.procedimentos.length < 1 || obj?.procedimentos.filter(x => x.id !== null).length === 0 ? (<><tr>
                            <td colspan="3">Sem Registro!</td>
                        </tr></>) :

                            obj?.procedimentos.map(x => {
                                return (
                                    <tr>
                                        <td colspan="1">{x?.codigo}</td>
                                        <td colspan="3">{x?.procedimento?.label}</td>
                                        <td colspan="1">{x?.porte_anestesico}</td>
                                        <td colspan="1">{x?.procedimento?.valor_porte && formatarMoeda(x?.procedimento?.valor_porte)}</td>
                                        <td colspan="1">{x?.porcentagem_acm && x?.porcentagem_acm + '%'}</td>
                                        <td colspan="1">{x?.porcentagem && x?.porcentagem?.label}</td>
                                        <td colspan="1">{x?.reducao_acrescimo && x?.reducao_acrescimo + '%'}</td>
                                        <td colspan="1">{x?.urgencia === true ? x?.urgencia_porcentagem + '%' : ''}</td>
                                        <td colspan="1">{x?.quantidade}</td>
                                        <td colspan="3" style={{ textAlign: 'right' }}>{x?.valor_total && formatarMoeda(x?.valor_total)}</td>
                                    </tr>)
                            })
                    },
                    {
                        tipoGrupo: 'tabela',
                        titulo: 'Identificação do(s) Profissional(is) Executante(s) Cooperativa',
                        thead:
                            <tr>
                                {/* <td className='coluna-1'>Item Ref.</td> */}
                                <td className='coluna-6'>Sócio</td>
                                <td className='coluna-2'>Tipo Pessoa</td>
                                <td className='coluna-3'>Empresa</td>

                            </tr>,
                        tbody: obj.executantes_externos == undefined || obj.executantes_externos.length < 1 ? (<><tr>
                            <td colspan="3">Sem Registro!</td>
                        </tr></>) :
                            obj.executantes_externos.filter(x => x.id).map(x =>
                                <tr>
                                    {/* <td>{x.proced_ref || ''}</td> */}
                                    <td><b style={{ color: '#1E90FF' }}>{x.executante?.label}</b></td>
                                    <td style={{ color: '#1E90FF' }}>{x.tipo_pessoa?.label}</td>
                                    <td style={{ color: '#1E90FF' }}>{x.empresa?.label}</td>
                                </tr>)
                    },
                    {
                        tipoGrupo: 'tabela',
                        titulo: 'Identificação do(s) Profissional(is) Executante(s) Equipe',
                        thead:
                            <tr>
                                {/* <td className='coluna-6'>Item Ref.</td> */}
                                <td className='coluna-2'>Data</td>
                                <td className='coluna-2'>Hora Inicial</td>
                                <td className='coluna-2'>Hora Final</td>
                                <td className='coluna-3'>Sócio</td>
                                <td className='coluna-2'>Tipo Atendimento</td>
                                <td className='coluna-3'>Empresa</td>

                            </tr>,
                        tbody: obj.executantes_internos == undefined || obj.executantes_internos.length < 1 ? (<><tr>
                            <td colspan="3">Sem Registro!</td>
                        </tr></>) :
                            obj.executantes_internos.filter(x => x.id).map(x =>
                                <tr>
                                    <td style={{ color: '#1E90FF' }}>{x.data}</td>
                                    <td style={{ color: '#1E90FF' }}>{x.hora_inicial}</td>
                                    <td style={{ color: '#1E90FF' }}>{x.hora_final}</td>
                                    <td><b style={{ color: '#1E90FF' }}>{x.executante?.label}</b></td>
                                    <td style={{ color: '#1E90FF' }}>{x.tipo_atendimento?.label}</td>
                                    <td style={{ color: '#1E90FF' }}>{x.empresa?.label}</td>
                                </tr>)
                    },
                    {
                        titulo: 'Lote',
                        visivel: obj.guia_lote_id ? true : false,
                        colunas: [
                            { titulo: 'Codigo', descricao: obj.guia_lote_id, tamanho: 'col-lg-3' },
                            { titulo: 'Número do Lote', descricao: obj.lote?.numero_lote, tamanho: 'col-lg-3', cor: obj.cor },
                            { titulo: 'Empresa', descricao: obj.lote?.empresa_lote, tamanho: 'col-lg-3' },
                            { titulo: 'Referência', descricao: obj.lote?.referencia_lote, tamanho: 'col-lg-3' },
                            { titulo: 'Status do Lote', descricao: obj.lote?.lote_status, tamanho: 'col-lg-3' },
                            { titulo: 'Op. de Sáude', descricao: obj.lote?.operadora_saude_lote, tamanho: 'col-lg-3' },

                        ]
                    },
                ]
            } : { titulo: 'Visualização Guia' })
        }
    }, [guias])

    const tipoGrupo = grupo => {
        const obj = {
            'tabela': <table className="table table-responsive-md table-visualizacao" >
                <thead>
                    {grupo.thead}
                </thead>
                <tbody>
                    {(grupo.tbody && grupo.tbody.length && grupo.tbody) || <tr><td className='div-center' colSpan={grupo.thead && grupo.thead.props.children.length}>Sem Registros!</td></tr>}
                    {(grupo.aposGrupo ? grupo.aposGrupo : <></>)}
                </tbody>
            </table>,
            default: () => grupo.colunas && grupo.colunas.map((coluna, index) => coluna &&
                <div key={index} className={coluna.tamanho + ' col-sm-12 mb-20'}>
                    <span className="data-title">{coluna.titulo}</span>
                    {coluna.htmlDireto ? coluna.htmlDireto :
                        (coluna.html ? <Interweave content={coluna.descricao.toString()} /> : coluna.cor ? <b><span style={{ color: coluna.cor }} >{coluna.descricao}</span></b> : <span style={{ color: coluna.cor }} >{coluna.descricao}</span>)}
                </div>
            )
        }

        return obj[grupo.tipoGrupo] || obj.default()
    }


    const limparConferencia = (propsFormik) => {
        setGuias([]);
        propsFormik.setFieldValue('codigo_barras', '');
        propsFormik.setFieldValue('codigo_id', '');
        propsFormik.setFieldValue('lote', undefined);
        propsFormik.setFieldValue('status', { value: 4, label: 'Conferida' })
        document.getElementById('codigo_id').focus();
    }

    const resultConferencia = (val, propsFormik) => {
        if (val.id) {
            setGuias(val)
            setCarregando(false)
            propsFormik.values.guia = JSON.stringify(val)
            document.getElementById('codigo_id').focus();

        } else {
            setCarregando(false)
            limparConferencia(propsFormik)
            mostrarToast('erro', 'Guia não encontrada!')
        }
    }

    const buscarGuia = (e, propsFormik, principal) => {//Parâmetro principal para saber se é o input código de barras 

        if (e.target.value && e.target.value.length > 0) {
            setCarregando(true)

            if (principal) {
                if (e.target.value !== guias.codigo_barras)
                    obterGuiasConferencia({ codigo_barras: e.target.value }, val => resultConferencia(val, propsFormik))
                else
                    setCarregando(false)

            }
            else
                if (parseInt(e.target.value) !== guias.id)
                    obterGuiasConferencia({ codigo: parseInt(e.target.value) }, val => resultConferencia(val, propsFormik))
                else
                    setCarregando(false)

        }
        else
            setCarregando(false)
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={valoresIniciais}
                validationSchema={validacao}
                enableReinitialize={true}
                onSubmit={(values, propsFormik) => { if (podeEnviarForm) { validarSubmit(values, propsFormik) } }}>
                {propsFormik => {
                    return (<>
                        <Form>
                            {!props.modal && <HeaderCadastro titulo={"Conferência Guia"} link={links} />}
                            <div className={props.modal && props.modal || 'card mb-20'}>
                                <div className={props.modal || 'card-body'}>
                                    <div className='row'>
                                        <InputGuiaStatus label="Status" name="status" placeholder="Status" propsFormik={propsFormik} tamanho={'col-lg-3'} obrigatorio={true}
                                            modal={true} focusAfter={inputFocus} salvarENovo={salvarENovo} {...props} cadastroRapido />
                                        <InputLoteGuia label="Lote" name="lote" placeholder="Lote" propsFormik={propsFormik} tamanho={'col-lg-3'}
                                            botaoVisivel={true} modal={true} focusAfter={inputFocus} salvarENovo={salvarENovo} {...props} cadastroRapido />
                                        <InputPadrao label='Justificativa' name='justificativa' type='text' placeholder='Justificativa' tamanho={'col-xl-6 col-sm-6'} classeTooltip={'tooltip-input-modal'} />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-30">
                                <HeaderCadastro
                                    titulo={'Guia'}
                                    classeChildren='space-between-100' >
                                </HeaderCadastro>
                            </div>
                            <div className={props.modal && props.modal || 'card mb-20'}>
                                <div className={props.modal || 'card-body'}>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-8 row'>

                                                <InputMascaraSimples
                                                    autoFocus
                                                    disabled={carregando}
                                                    mascara="111.111.111-11"
                                                    tamanho="col-3"
                                                    name="codigo_barras"
                                                    id="codigo_barras"
                                                    type="text"
                                                    placeholder="Código Barras"
                                                    onBlur={(e) => {
                                                        setPodeEnviarForm(true)
                                                        buscarGuia(e, propsFormik, true)
                                                    }}
                                                    onKeyUp={(e) => {
                                                        e.preventDefault()
                                                        setPodeEnviarForm(false)
                                                        if (e.keyCode === 13) {
                                                            buscarGuia(e, propsFormik, true)
                                                        }
                                                    }}
                                                />

                                                <InputPadrao
                                                    disabled={carregando}
                                                    tamanho="col-3"
                                                    name="codigo_id"
                                                    id="codigo_id"
                                                    type="number"
                                                    placeholder="Código"
                                                    onBlur={(e) => {
                                                        setPodeEnviarForm(true)
                                                        buscarGuia(e, propsFormik, false)
                                                    }}
                                                    onKeyUp={(e) => {
                                                        e.preventDefault()
                                                        setPodeEnviarForm(false)
                                                        if (e.keyCode === 13) {
                                                            buscarGuia(e, propsFormik, false)
                                                        }
                                                    }}
                                                />



                                                {carregando && <span className="spinner-border spinner-border-md" role="status" aria-hidden="true" style={{ opacity: 0.2 }}></span>}
                                            </div>
                                            <div className='col-4 text-right' style={{ marginLeft: 30 }}>
                                                {
                                                    guias.id ?

                                                        <BotaoPadrao texto='Detalhes' type={'button'} click={_ => {
                                                            setShowModalDetalhes(true)
                                                        }}
                                                            idTooltip={'Filtros'} /> : <></>
                                                }
                                                <BotaoPadrao texto='Limpar' type={'button'} click={_ => {
                                                    limparConferencia(propsFormik)
                                                }}
                                                    idTooltip={'Filtros'} />
                                                {/* <Link to= { dados && dados.id ? { pathname: '/faturamento/guia/alteracao/' + dados.id } : '/faturamento/conferencia/cadastro'} ><BotaoPadrao texto='Alterar' type={'button'} click={_ => { }} idTooltip={'Alterar'} /></Link> */}
                                                <BotaoPadrao texto='Alterar' type={'button'} click={_ => {
                                                    if (dados && dados.id)
                                                        return history.push({ pathname: '/faturamento/guia/alteracao/' + dados.id, state: { conferencia: true } })

                                                }} idTooltip={'Alterar'} />

                                            </div>
                                        </div>

                                        {
                                            dados && dados.id ?
                                                <div className="card-body" style={{ padding: 0 }}>
                                                    {dados?.grupos ? dados?.grupos.filter(x => x.visivel !== false).map((grupo, index, arrayAtual) => grupo &&
                                                        <div className={"dados-visualizacao-conferencia " + (minimizar[index] === false ? "d-none-print" : "")} key={index}>

                                                            <div className="group-title row-space-between ">
                                                                <h5> {grupo.titulo} </h5>

                                                            </div>
                                                            <div className="row col-12">
                                                                {tipoGrupo(grupo)}
                                                            </div>
                                                        </div>
                                                    ) : <div className="dados-visualizacao">
                                                        <div className="group-title">
                                                            <h5>Não há dados!</h5>
                                                        </div>
                                                    </div>}
                                                </div>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                                </div>
                            </div>
                            {!props.modal && <div className='separator mt-40 mb-5'></div>}
                            <div className='direita container-botoes mb-3 group-salvar-novo'>
                                <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                                {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                            </div>

                        </Form>

                        <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                            esconderFechar mensagem={<> {mensagemModal}<br />
                                {'Deseja Continuar?'}</>}
                            textoOpcao1={'Cancelar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} /></>)
                }}
            </Formik>
            <DetalhesHistorico titulo={'Detalhes'} data={guias} showModal={showModalDetalhes} setShowModal={setShowModalDetalhes} />

        </React.Fragment>
    )
}


const DetalhesHistorico = (props) => {

    const [itemDetalhes, setItemDetalhes] = useState({});
    const refArquivo = useRef(null)
    const refAtualizacao = useRef(null)
    const [valoresIniciais, setValoresIniciais] = useState({})

    const [dataArquivos, setDataArquivos] = useState(null)
    const [salvando, setSalvando] = useState(false)
    const [carregando, setCarregando] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const [validacao] = useState(Yup.object({}))

    const Submit = (values, propsFormik) => {
        values.arquivo = dataArquivos
        setSalvando(true)
        let valuesAjustados = { ...values }
        valuesAjustados.status = values.status ? values.status.id : ''
        putComAcao(
            ACOES.INSERIR_ARQUIVOS_GUIA,
            76,
            { id: props.data.id, ...values },
            '/faturamento/guiaInserirArquivos',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                props.setShowModal(false)
                setSalvando(false)
                // props.setItem(null)
                obterArquivo()
                setDataArquivos(null)
                // target.current.atualizar()
            },
            (erro) => {
                setSalvando(false)
                mostrarToast('erro', mensagens.erro);
            })
    }

    useEffect(() => {
        if (props.data && props.data.id) {
            obterArquivo()
        }
    }, [props.data])

    const obterArquivo = async () => {
        setCarregando(true)
        await Promise.resolve(get(`/faturamento/guiaArquivos/${props.data.id}`)).then(val => {
            setDataArquivos([...val] || [])
            setCarregando(false)
        })

    }
    useEffect(_ => {
        if (!props.showModal && refArquivo.current)
            refArquivo.current.limparArquivos()

    }, [props.showModal])

    useEffect(_ => {
        setSubmitting(dataArquivos?.filter(x => x.s3Key === undefined).length > 0)
    }, [dataArquivos, submitting])
    useEffect(() => {

        let dados = {}
        dados.guia = [
            { titulo: 'Código', descricao: props.data.id },
            { titulo: 'Código Barras', descricao: props.data.codigo_barras },
            { titulo: 'Paciente', descricao: props.data.nome_beneficiario },
            { titulo: 'Data Procedimento', descricao: props.data.data_procedimento },
        ]

        dados.historico_status = props.data.historico_status
        dados.historico_lote = props.data.historico_lote


        setItemDetalhes(dados)
    }, [props.data])

    return <ModalPadraoCadastro titulo={props.titulo || 'Detalhes'} showModal={props.showModal} setShowModal={props.setShowModal}>
        <div><div className='detalhes'>
            {itemDetalhes.guia && itemDetalhes.guia.map((item, idx) => {
                return (
                    <>
                        {idx === 0 ? <></> : <hr />}
                        <div className='item-detalhes'>
                            <h6><b>{item.titulo}</b></h6>
                            <h6>{item.descricao}</h6>
                            {item.html}
                        </div></>
                )
            })}
        </div>
            {
                itemDetalhes && itemDetalhes.historico_lote && itemDetalhes.historico_lote.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Histórico de Lote</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes && itemDetalhes.historico_lote && itemDetalhes.historico_lote.map(item => <li className="mb-1">
                            <div className='col-hora row-space-between'>

                                <span className='span-hora'>{item.data_registro}</span>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span className={'tag'} style={{ backgroundColor: item.status.cor, color: 'white', marginLeft: 0 }}>{item.status.descricao}</span>
                            </div>
                            <div className='col-hora row-space-between mt-2'>
                                <i><p>{item.lote.numero}</p></i>
                                <i><p>{item.lote.descricao || '---'}</p></i>
                            </div>
                            <div className='mt-2 row-space-between mb-2' style={{ flexDirection: 'row' }}>
                                <div className='item-detalhes' style={{ flex: 1 }}>
                                    <h6><b>Referência</b></h6>
                                    <h6 className='mb-1'>{moment(item.referencia, 'DD/MM/YYYY').format('MM/YYYY')}</h6>
                                </div>
                                <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                    <h6><b>Qtd </b></h6>
                                    <h6 className='mb-1'>{item.quantidade_guia}</h6>
                                </div>
                                <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                    <h6><b>Op. Saúde</b></h6>
                                    <h6 className='mb-1'>{item.operadora_saude}</h6>
                                </div>
                            </div>

                            <div className='mt-2'>
                                <i><p>{item.justificativa_lote}</p></i>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span></span>
                                <span className='span-nome'><b>{`${item.usuario ? item.usuario.toUpperCase() : ''}`}</b></span>
                            </div>
                        </li>)
                    }
                </ul>
            </div>
            {
                itemDetalhes && itemDetalhes.historico_status && itemDetalhes.historico_status.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Histórico de Status</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes && itemDetalhes.historico_status && itemDetalhes.historico_status.map(item => <li className="mb-1">
                            <div className='col-hora row-space-between'>
                                <span className={'tag'} style={{ backgroundColor: item.cor, color: 'white' }}>{item.descricao}</span>
                            </div >
                            <div className='mt-2' style={{ marginLeft: 10 }}>
                                <span className='span-hora'>{item.data_registro}</span>
                            </div>
                            <div className='mt-2'>
                                <i><p>{item.justificativa_status}</p></i>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span></span>
                                <span className='span-nome'><b>{`${(item.usuario || item.usuario_app) ? (item.usuario || item.usuario_app).toUpperCase() : ''}`}</b></span>
                            </div>
                        </li>)
                    }
                </ul>
            </div>

            <div>
                <ul className='lista-modal'>
                    <Formik
                        initialValues={valoresIniciais}
                        validationSchema={validacao}
                        enableReinitialize={true}
                        onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                        {_ => (
                            <div ref={refAtualizacao}>
                                <Form>
                                    <div className='modal-body-cadastro'>
                                        <div className="modal-header">
                                            <h5 className="modal-title"><span>Arquivos</span></h5>
                                        </div>
                                        <div className={'form-group col-12'}>

                                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                                            <div className="">
                                                {dataArquivos != null ?
                                                    <InputArquivoS3
                                                        ref={refArquivo}
                                                        name='arquivo'
                                                        placeholder='Arquivo'
                                                        tamanho='col-12'
                                                        diretorioS3='guia/DDMMYYYY/'
                                                        modulo={MODULO["/faturamento/guia"]}
                                                        tamanhoMinimo={0}
                                                        tamanhoMaximoEmMB={300}
                                                        setDataArquivos={setDataArquivos}
                                                        dataArquivos={dataArquivos != null ? dataArquivos : []}
                                                        modal={true}
                                                        setIsSubmit={dataArquivos.length > 0 ? setSalvando : () => { }}
                                                        limparCampos={false}
                                                    /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='separator mt-40 mb-2'></div>

                                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                                        <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                    </div>
                                </Form>
                            </div>
                        )}
                    </Formik>
                </ul>
            </div>

        </div>
    </ModalPadraoCadastro >
}