import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaCNPJ, validaData, validaEspaco } from '../../../../util/Validacoes';
import { InputPadrao, InputTelefone, InputDatePicker, InputGroupSenha } from '../../../../components/Inputs'
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { useParams } from "react-router-dom";
import { salvar, get } from '../../../../util/AcessoApi'
import axios from 'axios'
import { mensagens, ajustarDadosTelefone } from '../../../../util/Utilitarios'
import 'moment/locale/pt-br'
import { InputMunicipio } from '../../../../components/InputsAutoComplete'
import { Link } from 'react-router-dom'
import { HeaderCadastro } from '../../../../components/Headers'
import Atalho from '../../../../util/Atalho'

export default props => {
    let { id } = useParams()
    const [isSubmit, setIsSubmit] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [dataTelefone, setDataTelefone] = useState([])
    const [salvarENovo, setSalvarENovo] = useState(false)

    const manter = []

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Administração</span>,
        () => <Link className='link-active' to={'/administracao/empresa/consulta'}>Empresa</Link>,
    ]

    let validacaoInicial = Yup.object({
        razao_social: Yup.string()
            .required()
            .max(100),
        nome: Yup.string()
            .required()
            .max(100),
        cnpj: Yup.string()
            .max(20).test('CNPJ', 'CNPJ inválido!', function (value) { return validaCNPJ(this.parent.cnpj) }),
        cep: Yup.string().required()
            .max(20),
        logradouro: Yup.string()
            .max(30),
        complemento: Yup.string()
            .max(30),
        numero: Yup.string()
            .max(30),
        bairro: Yup.string()
            .max(60),
        municipio: Yup.string().nullable().required(),
        fone: Yup.string().required(),
        email: Yup.string()
            .max(200).email(),
        login_coopanest: Yup.string().max(20).test('Caractere Inválido', 'Usuário não pode ter espaço!', function (value) { return validaEspaco(this.parent.login) }),
        senha_coopanest: Yup.string().nullable(),

        inicio_funcionamento: Yup.string().nullable().required().test('Data', 'Data inválida!', function (value) { return validaData(this.parent.inicio_funcionamento) }),
    })

    const emptyEmpresa = {
        nome: "",
        razao_social: "",
        cnpj: "",
        cep: "",
        logradouro: "",
        complemento: "",
        numero: "",
        bairro: "",
        municipio: "",
        fone: "",
        email: "",
        inicio_funcionamento: null,
    }

    const { history } = props

    const buscarCep = (e, propsFormik) => {
        if (propsFormik.values.cep.length > 0)
            axios.get('https://viacep.com.br/ws/' + propsFormik.values.cep + '/json/')
                .then(function (response) {
                    if (response.data.erro) {
                        mostrarToast('erro', 'CEP não encontrado')
                        return;
                    }
                    propsFormik.setFieldValue('logradouro', response.data.logradouro)
                    let obj = {
                        value: response.data.ibge,
                        label: response.data.localidade.toString().toUpperCase() + " - " + response.data.uf
                    }
                    propsFormik.setFieldValue('municipio', obj)
                    propsFormik.setFieldValue('bairro', response.data.bairro)
                });
    }

    const Submit = (values, propsFormik) => {
        values.fone = dataTelefone.length > 0 ? dataTelefone : values.fone;
        ajustarDadosTelefone(values.fone)
        setIsSubmit(true)

        salvar(values, '/administracao/empresa', (ret) => {
            mostrarToast('sucesso', mensagens.sucesso)

            if (props.modal) {
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    setDataTelefone([])
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setIsSubmit(false)
                    setSalvarENovo(false)
                }
                else {
                    props.setItemSelecionado({ value: values.id, label: values.nome })
                    props.setShow && props.setShow(false)
                }
            }
            else {
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    setDataTelefone([])
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setIsSubmit(false)
                    setSalvarENovo(false)
                }
                else
                    history.push('/administracao/empresa/consulta')
            }
        }, err => {
            mostrarToast('erro', mensagens.erro)
            setIsSubmit(false)
        })
    }

    useEffect(() => {
        if (id) {
            Promise.resolve(get('/administracao/empresa/' + id)).then(function (obj) {
                if (obj.length === 0)
                    history.push('/administracao/empresa/consulta')

                setDataTelefone(obj.fone)

                delete obj.fone
                obj.fone = ""
                setValoresIniciais(obj)
            }).catch((e) => {
            });
        } else {
            setValoresIniciais({
                nome: "",
                razao_social: "",
                cnpj: "",
                cep: "",
                logradouro: "",
                complemento: "",
                numero: "",
                bairro: "",
                municipio: "",
                fone: "",
                email: "",
                login_coopanest: "",
                senha_coopanest: "",
                inicio_funcionamento: null,
                inscricao_municipal: "",
                codigo_tributario_municipio: ""
            })
        }

    }, [history, id])

    if (valoresIniciais.nome == undefined)
        return <></>
    else
        return (
            <>
                {!props.modal && <HeaderCadastro titulo={"Cadastro Empresa"} link={links} />}
                <Formik
                    initialValues={valoresIniciais}
                    enableReinitialize={true}
                    validationSchema={validacaoInicial}

                    onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                    {propsFormik => (
                        <Form>
                            <div className={props.modal && props.modal || 'card mb-20'}>
                                <div className={props.modal || 'card-body'}>
                                    <div className="row">
                                        <InputPadrao tamanho="col-0" label="Id" name="id" type="hidden" placeholder="Id" obrigatorio="false" />
                                        <InputPadrao tamanho={!props.modal && 'col-lg-4'} label="Razão Social" name="razao_social" type="text" obrigatorio="true" autoFocus
                                            salvarENovo={salvarENovo} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputPadrao tamanho={!props.modal && 'col-lg-4'} label="Nome Fantasia" name="nome" type="text" obrigatorio="true"
                                            classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputPadrao mascara="11.111.111/1111-11" tamanho={!props.modal && 'col-lg-2'} label="CNPJ" name="cnpj" type="text" placeholder="CNPJ"
                                            obrigatorio="true" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputPadrao mascara="11111-111" tamanho={!props.modal && 'col-lg-2'} label="CEP" name="cep" type="text" placeholder="CEP" obrigatorio="true"
                                            onBlur={e => buscarCep(e, propsFormik)} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputPadrao tamanho={!props.modal && 'col-lg-4'} label="Logradouro" name="logradouro" type="text" placeholder="Logradouro"
                                            classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputPadrao tamanho={!props.modal && 'col-lg-4'} label="Complemento" name="complemento" type="text" placeholder="Complemento"
                                            classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputPadrao tamanho={!props.modal && 'col-lg-2'} label="Número" name="numero" type="text" placeholder="Número"
                                            classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputPadrao tamanho={!props.modal && 'col-lg-2'} label="Bairro" name="bairro" type="text" placeholder="Bairro"
                                            classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputMunicipio tamanho={!props.modal && 'col-lg-4'} label='Município - UF' name='municipio' placeholder='Município - UF' obrigatorio={true}
                                            propsFormik={propsFormik} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputTelefone tamanho={!props.modal && 'col-lg-2'} label="Fone" name="fone" type="text" placeholder="Fone" obrigatorio="true" data={dataTelefone}
                                            setData={setDataTelefone} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputPadrao tamanho={!props.modal && 'col-lg-4'} label="E-mail" name="email" type="text" placeholder="E-mail"
                                            classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputPadrao tamanho={!props.modal && 'col-lg-2'} label="Inscrição Municipal" name="inscricao_municipal" type="text" placeholder="Inscrição Municipal"
                                            classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputPadrao tamanho={!props.modal && 'col-lg-4'} label="Código Tributação Município" name="codigo_tributario_municipio" type="text" placeholder="Código Tributação Município"
                                            classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        <InputDatePicker tamanho={!props.modal && 'col-lg-2'} label="Início Faturamento" name="inicio_funcionamento" type="text" obrigatorio="true"
                                            classeTooltip={props.modal && 'tooltip-input-modal'} placeholder="Início Faturamento"
                                            setarValor={value => { propsFormik.setFieldValue("inicio_funcionamento", value) }} />
                                        <Atalho formik={propsFormik} salvando={isSubmit} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                                    </div>
                                </div>

                            </div>
                            {!props.modal && <HeaderCadastro titulo={"Acesso Coopanest"} />}
                            <div className={props.modal && props.modal || 'card mb-20'}>
                                <div className={props.modal || 'card-body'}>
                                    <div className="row">
                                        <InputPadrao label="Usuário" name="login_coopanest" type="text" placeholder="Usuário" style={{ textTransform: 'lowercase' }} tamanho='col-lg-4' lower={true} maxLength={20} />
                                        <InputGroupSenha label="Senha" name="senha_coopanest" placeholder="Senha" tamanho={'col-lg-4 '} maxLength={30} />
                                    </div>
                                </div>
                            </div>

                            {!props.modal && <div className='separator mt-40 mb-5'></div>}
                            <div className='direita container-botoes mb-3 group-salvar-novo'>
                                <BotaoPadrao texto='Salvar' disabled={isSubmit} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                                {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={isSubmit} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                            </div>
                        </Form>
                    )}


                </Formik>
            </>
        )
}