import React, { useState, useRef } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { getUsuario } from '../../../../util/Configuracoes'

const ConsultaEstabelecimentoSaude = (props) => {
    const [data, setData] = useState([])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-estabelecimento-saude',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            modo: 'desc',
                            ativo: true
                        }
                    }, {
                        Header: 'Identificação',
                        accessor: 'codigo',
                        ordenamento: { tipo: 'numero', }
                    }, {
                        Header: 'Razão Social',
                        accessor: 'razao_social',
                        visivel: false,
                        ordenamento: { tipo: 'texto' }
                    }, {
                        Header: 'Nome Fantasia',
                        accessor: 'nome_fantasia',
                        ordenamento: { tipo: 'texto' }
                    }, {
                        Header: 'CNPJ',
                        accessor: 'cnpj',
                        ordenamento: { tipo: 'texto' }
                    }, {
                        Header: 'CNES',
                        accessor: 'cnes',
                        ordenamento: { tipo: 'texto' }
                    }, {
                        Header: 'Tipo',
                        accessor: 'tipo_estabelecimento_descricao',
                        ordenamento: { tipo: 'texto' },
                        filtro: {
                            tipo: 'tipo_estabelecimento',
                            idColuna: 'tipo_estabelecimento_id'
                        }
                    }, {
                        Header: 'Cidade',
                        accessor: 'nome_municipio',
                        visivel: false,
                        ordenamento: { tipo: 'texto' },
                        filtro: {
                            tipo: 'municipio',
                            idColuna: 'municipio_codigo_ibge'
                        }
                    }, {
                        Header: 'Bairro',
                        accessor: 'bairro',
                        filtro: {
                            tipo: 'pesquisa-geral',
                            idColuna: 'bairro',
                        }
                    }, {
                        Header: 'Urgência?',
                        accessor: 'urgencia',
                        visivel: false,
                        ordenamento: { tipo: 'texto' },
                        filtro: {
                            tipo: 'input_sim_nao',
                            idColuna: 'estabelecimento_saude_urgencia',
                            name: `input_sim_nao_${Math.floor(Math.random() * 1000)}`
                        }
                    }, {
                        Header: 'Ativo?',
                        accessor: 'ativo',
                        visivel: true,
                        ordenamento: { tipo: 'texto' },
                        filtro: {
                            tipo: 'input_sim_nao',
                            idColuna: 'ativo',
                            name: `input_sim_nao_${Math.floor(Math.random() * 1000)}`,
                            valor: { value: 'true', label: 'Sim' },
                            valor_inicial: { value: 'true', label: 'Sim' }
                        }
                    },{
                        Header: 'Data Atualização',
                        accessor: 'data_atualizacao',
                        ordenamento: {
                            tipo: 'data'
                        },
                    }, {
                        Header: 'Data Registro',
                        accessor: 'data_registro',
                        ordenamento: { tipo: 'data' }
                    },
                ],
            },
        ],
        []
    )

    

    return (
        <>
            <DataGridAlternativa data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/administracao/estabelecimentoSaude'
                tituloLista={'Lista Estabelecimento Saúde'} caminhoConsultaFiltros='/administracao/estabelecimentoSaudePaginacao'
                urlCadastro='/administracao/estabelecimentoSaude/cadastro' modulo={props.modulo} titulo={'Consulta Estabelecimento Saúde'} icone={'icon-Hospital-2'}
                atualizarEstabelecimento={() => props.obterWebService(JSON.stringify({"integrationType": "estabelecimento_saude", "key": "10", "usuarioId": getUsuario().id, "moduloId": 9, "acaoId" : 30}))} />
        </>
    )
}

export default ConsultaEstabelecimentoSaude