import React, { useState, useEffect } from 'react'
import { useWindowSize } from '../util/Utilitarios'
import moment from "moment"
import '../assets/css/theme.css';

const Calendario = (props) => {
    const [diasMes, setDiasMes] = useState([])
    const [calendario, setCalendario] = useState([])
    const [arrayQtdEventos, setArrayQtdEventos] = useState([])
    const [width] = useWindowSize();
    let cloneArrayQtd = Array(5).fill(width > 500 ? 5 : 3)
    const [dataAtual, setDataAtual] = useState({});
    let calendar = []

    useEffect(() => {
        cloneArrayQtd = Array(5).fill(width > 500 ? 5 : 3)
        let eventos = [];
        const dataAtualC = props.dataAtual ? moment(props.dataAtual, 'DD/MM/YYYY') : moment();
        const dataInicialMes = moment(dataAtualC).startOf('month');
        const dataFinalMes = moment(dataAtualC).endOf('month');

        const mesAtual = dataAtualC.format('MM');
        const startWeek = dataInicialMes.week();
        let endWeek = dataFinalMes.week();
        eventos = props.data

        let eventosMes = eventos.map(item => {
            const novoItem = { ...item }
            novoItem.data_inicial_formatada = moment(item.data_inicial, 'DD/MM/YYYY') <= dataInicialMes ? dataInicialMes.format('DD/MM/YYYY') : item.data_inicial
            novoItem.data_final_formatada = moment(item.data_final, 'DD/MM/YYYY') >= dataFinalMes ? dataFinalMes.format('DD/MM/YYYY') : item.data_final

            return novoItem
        })

        if (startWeek > endWeek)
            endWeek = moment(dataFinalMes).add(-1, 'weeks').week() + 1;

        for (var week = startWeek; week <= endWeek; week++) {
            calendar.push({
                week: week,
                days: Array(7).fill(0).map((n, i) => moment(dataAtualC).week(week).startOf('week').clone().add(n + i, 'day'))
            })
        }

        setDiasMes(calendar.map((semana, idx) => {
            // console.log('props.layoutDiaCalendario: ', props.layoutDiaCalendario);
            // console.log('dataAtual: ', dataAtual);
            // console.log('props.dataAtual: ', props.dataAtual);
            var dataFinalSemana = semana.days[6];
            var dataInicioSemana = semana.days[0];
            var maisEventosData = width > 500 ? 20 : 3;

            // ? dataAtual === props.dataAtual && 

            const retorno = (<div className='semana' style={{ height: props.alturaSemana ? props.alturaSemana : ((arrayQtdEventos[idx] * 16.5) + 45) + 'px' }}>
                {semana.days.map(dia => {
                    return (<div className={'dia' + (dia.format('MM') == mesAtual ? '' : ' outro-mes') + (dia.format('DD/MM/YYYY') == moment().format('DD/MM/YYYY') ? ' hoje' : '')}><span>{dia.format('DD')}</span></div>)
                })}

                <div className={'semana-eventos '} style={{ gridTemplateRows: 'repeat(' + arrayQtdEventos[idx] + ', 1fr)', height: 'calc(' + ((arrayQtdEventos[idx] * 16) + 45) + 'px - 30px)' }}>
                    {props.layoutDiaCalendario ? semana.days.map(dia => props.layoutDiaCalendario(dia)) : dataAtual === props.dataAtual && semana.days.map(dia => {
                        let eventosPorData = eventosMes.filter(item2 => item2.data_inicial_formatada === dia.format('DD/MM/YYYY'));
                        maisEventosData = eventosPorData.length > maisEventosData ? eventosPorData.length : maisEventosData

                        return (
                            eventosPorData.map((item, idx) => {
                                const maiorFinalSemana = moment(item.data_final_formatada, 'DD/MM/YYYY') > dataFinalSemana
                                const menorInicioSemana = moment(item.data_inicial_formatada, 'DD/MM/YYYY') < dataInicioSemana
                                if (menorInicioSemana)
                                    item.data_inicial_formatada = dataInicioSemana.format('DD/MM/YYYY')

                                if (maiorFinalSemana) {
                                    let novoEvento = { ...item }
                                    novoEvento.data_inicial_formatada = moment(dataFinalSemana).add(1, 'days').format('DD/MM/YYYY')
                                    eventosMes.push(novoEvento)
                                }

                                let numDiaFinal = (maiorFinalSemana ? (dataFinalSemana.day()) : (moment(item.data_final_formatada, 'DD/MM/YYYY').day())) + (item.data_final_formatada === item.data_inicial_formatada ? 1 : 2)

                                return <div onClick={() => props.clickEvento(item)} className={"acontecimento " + (item.cor ? '' : ' cor-padrao ')} style={{ backgroundColor: item.cor,gridColumn: (moment(item.data_inicial_formatada, 'DD/MM/YYYY').day() + 1) + ' / ' + numDiaFinal }}>
                                    <span class="acontecimento-titulo">{item.nome}</span>
                                </div>
                            }
                            )
                        )
                    })}

                </div>

            </div>)

            cloneArrayQtd[idx] = maisEventosData
            setArrayQtdEventos(cloneArrayQtd)
            setDataAtual(props.dataAtual)
            return retorno;
        }))
    }, [props.data])

    return (
        <div className='calendario'>
            <div className='mes'>
                <div className='semana-titulo'>
                    <div className='dia'>Dom</div>
                    <div className='dia'>Seg</div>
                    <div className='dia'>Ter</div>
                    <div className='dia'>Qua</div>
                    <div className='dia'>Qui</div>
                    <div className='dia'>Sex</div>
                    <div className='dia'>Sab</div>
                </div>
                {diasMes}
            </div>
        </div>
    )
}

export default Calendario