import React, { useState, useEffect } from 'react';
import { Menu } from '../components/Menu'
import { TopBar } from '../components/TopBar'
import { useWindowSize, useInterval } from '../util/Utilitarios'

import { obterMenuComPermissoes, setSistemaStatus, MENU, setSistemaVersao, logout,reload, minutosEmInatividade } from '../util/Configuracoes';
import { SubMenu } from '../components/SubMenu';
import { ModalBloqueado } from '../components/Modais';
import ls from 'local-storage'
import moment from "moment"

import { Redirect } from 'react-router-dom';
import SemAcesso from '../areas/shared/SemAcesso'
import packageJson from '../app_version.json';
const Layout = (props) => {
    const [width, height] = useWindowSize();
    const [fechado, setFechado] = useState(true);
    const [fechadoSubmenu, setFechadoSubmenu] = useState(true);
    const [submenu, setSubmenu] = useState([]);
    const [menuAtivo, setMenuAtivo] = useState("");
    const [idSubmenuAberto, setIdSubmenuAberto] = useState("");
    const [alturaContent, setAlturaContent] = useState(0)
    const [menu, setMenu] = useState([]);
    const [showModalOffline, setShowModalOffline] = useState(false)
    const [versao, setVersao] = useState('')



    useInterval(() => {
        //setSistemaStatus(setShowModalOffline)
        if (minutosEmInatividade() > 240) {
            logout()
            reload()
            return;
        }
    }, 10000);


    useEffect(() => {
        if (width > 0) {
            setFechado(width < 500)
            props.setMenuAberto(!(width < 500))
        }

        if (height > 0) {
            setAlturaContent(height - 70)
        }
    }, [width, height])

    useEffect(() => {
        setFechadoSubmenu(true)
        props.setMenuAberto(false)
        Promise.resolve(obterMenuComPermissoes()).then((val) => {
            setMenu(val)
        }).catch(setMenu(MENU))
    }, [])

    function setFechadoTodos(fecharTodos) {
        setFechado(fecharTodos)
        props.setMenuAberto(!fecharTodos)
        setFechadoSubmenu(fecharTodos === false ? idSubmenuAberto === "" : true)
    }

    function chamarSubmenu(id, props) {
        setMenuAtivo("")
        if (id === idSubmenuAberto) {
            setSubmenu([])
            setFechadoSubmenu(true);
            setIdSubmenuAberto("");
        } else {
            setSubmenu([])
            setFechadoSubmenu(true);
            var arrayMenu = menu.length == 0 ? MENU.filter((item) => item.id === id && item.submenu) : menu.filter((item) => item.id === id && item.submenu)

            setSubmenu(arrayMenu.length > 0 ? arrayMenu[0].submenu : [])
            if (arrayMenu.length > 0) {
                setIdSubmenuAberto(id)
                setFechadoSubmenu(false)
                setMenuAtivo(id)
            } else {
                setFechadoSubmenu(true)
                setIdSubmenuAberto("")
                if (width < 500) {
                    setFechado(true)
                    props.setMenuAberto(false)
                }
            }

        }
    }

    function fecharSubMenu() {
        setSubmenu([])
        setFechadoSubmenu(true);
        setIdSubmenuAberto("");
        if (width < 500) {
            setFechado(true)
            props.setMenuAberto(false)
        }
    }

    if (props.temPermissao == null && setShowModalOffline == false)
        return <></>
    else {
        return (
            <div className='container-layout'>
                <TopBar {...props} onClick={() => setFechadoTodos(!fechado)} />
                <div className={'sidebar' + (fechado ? ' remove-margin ' : ' ')}>
                    <div className={'menu-div' + (fechadoSubmenu ? ' ' : ' remove-margin-menu')}>
                        <Menu {...props} ativo={menuAtivo} onClick={(id) => chamarSubmenu(id, props)} />
                    </div>
                    <div className={'submenu' + (fechadoSubmenu ? ' remove-margin-submenu' : ' ')}>
                        <SubMenu {...props} data={submenu} onClick={() => fecharSubMenu()} />
                    </div>
                </div>
                <div className={'p-0 container-body ' + (fechado ? ' ' : (width < 500 ? '' : 'ml-menu '))}>
                    <div className="pt-7">
                        <div className="content " onClick={() => fecharSubMenu()} style={{ height: alturaContent }}>
                            {props.temPermissao ? props.children : <Redirect to={{ pathname: '/semPermissao', state: { from: props.location } }} />}
                        </div>
                    </div>
                </div>
                <ModalBloqueado showModal={showModalOffline} setShowModal={setShowModalOffline}>
                    <SemAcesso />
                </ModalBloqueado>

            </div>)
    }
}

export default Layout;

