import React, { useState, useEffect, useMemo } from 'react'
import { BotaoPadrao, BotaoMenu } from './Botoes'
import { InputSimples, InputSwitchSemFormik } from './Inputs'
import { BtnAcao } from './Acoes'
import { HeaderAgenda } from './Headers'
import { useWindowSize, getBetweenDates } from '../util/Utilitarios'
import { ModalPadraoCadastro, ModalCalendar } from './Modais'
import { calcularData } from '../util/FuncoesComuns'
import { pesquisaGlobal } from './Filtragens'
import { ordenarMultiplo } from './Ordenamentos'
import { Link } from 'react-router-dom'
import Calendario from './Calendario'
import InfiniteCalendar from 'react-infinite-calendar';
import moment from 'moment'
import { getUsuario } from '../util/Configuracoes'
import { useHistory } from "react-router-dom"
import { get } from '../util/AcessoApi'

const AgendaPadrao = React.forwardRef((props, ref) => {
    const [pesquisa, setPesquisa] = useState('')
    const [pesquisaChar, setPesquisaChar] = useState('')
    const [dias, setDias] = useState([])
    const [filtros, setFiltros] = useState({})
    const [idInterval, setIdInterval] = useState(undefined)
    const [contadorPesquisa, setContadorPesquisa] = useState(0)
    const [filtroAberto, setFiltroAberto] = useState(false)
    const [verCalendario, setVerCalendario] = useState(false)
    const [msScroll, setMsScroll] = useState((60 - parseInt(moment().format('mm'))) * 1000 * 60)
    const [arrayRefP, setArrayRefP] = useState([])
    const [listaCompleta, setListaCompleta] = useState(false)
    const [data, setData] = useState([])
    const user = getUsuario()
    const [ultimaData, setUltimaData] = useState({})
    const [atualizarLembrete, setAtualizarLembrete] = useState(false)
    const [atualizarRegistro, setAtualizarRegistro] = useState(false)
    const [width, height] = useWindowSize();
    const history = useHistory();
    const [showCalendar, setShowCalendar] = useState(false)
    const [digitando, setDigitando] = useState(null)
    const [carregando, setCarregando] = useState(false)
    const [showLembrete, setShowLembrete] = useState(false)
    const [showRegistro, setShowRegistro] = useState(false)
    const [possuiPermissaoLembrete, setPossuiPermissaoLembrete] = useState(false);
    const [possuiListaOcorrencia, setPossuiListaOcorrencia] = useState(false);
    const [possuiPermissaoCadastro, setPossuiPermissaoCadastro] = useState(false);
    const [possuiPermissaoRegistroAtividade, setPossuiPermissaoRegistroAtividade] = useState(false);
    const [showResumo, setShowResumo] = useState(false)
    const [showResumoGrupos, setShowResumoGrupos] = useState(false)
    const [showResumoEscalas, setShowResumoEscalas] = useState(false)

    ref.current = {
        carregando: carregando,
        setCarregando: setCarregando,
        iniciarLoading: _ => setCarregando(true),
        finalizarLoading: _ => setCarregando(false),
        filtros: filtros
    }

    useEffect(() => {
        setCarregando(true)
        if (digitando) {
            clearTimeout(digitando);
        }

        setDigitando(setTimeout(function () {
            setPesquisa(pesquisaChar);
            setCarregando(false);
        }, 700))
    }, [pesquisaChar])

    const rolar = () => {
        if (props.rolagemAutomatica) {
            callScroll();
            setTimeout(() => {
                callScroll();
                setInterval(() => {
                    callScroll();
                }, 3600000)
            }, msScroll)
        }
    }

    useEffect(() => {
        //props.setPodeRenderizar(filtroAberto);
        if (props.setFiltroAberto) {
            props.setFiltroAberto(filtroAberto)
        }
    }, [filtroAberto])

    useEffect(() => {
        rolar();
    }, [props.rolagemAutomatica])


    useEffect(() => {
        setAtualizarLembrete(props.atualizarLembrete);
    }, [props.atualizarLembrete])

    useEffect(() => {
        setAtualizarRegistro(props.atualizarRegistro);
    }, [props.atualizarRegistro])

    const callScroll = () => {
        const horaAtual = moment().format('HHmm')
        let haRegistroAntes = false;
        let element = arrayRefP.filter(item => {
            return item.hora.split(':')[0] === horaAtual
        })[0]


        if (!element || element === null) {
            let menor = 0;
            let maior = 0;

            arrayRefP.map((item, idx) => {
                let hora = parseInt(item.hora.split(':').join(''))
                menor = parseInt(horaAtual) > hora && hora > menor ? hora : menor;
                maior = parseInt(horaAtual) < hora && hora > maior ? hora : maior;

                if (!haRegistroAntes) {
                    haRegistroAntes = hora <= parseInt(horaAtual)
                }
            })

            const nArray = menor > 0 ? arrayRefP.filter(item => {
                return item.hora.split(':').join('') === menor.toString().padStart(2, '0')
            }) : arrayRefP.filter(item => {
                return item.hora.split(':').join('') === maior.toString().padStart(2, '0')
            })

            element = menor > 0 ? nArray[nArray.length - 1] : nArray[0]
        }

        if (haRegistroAntes && element && element.reference.current != null && props.rolagemAutomatica) {
            window.scrollTo({
                top: element.reference.current.offsetTop - 150,
                left: 0,
                behavior: 'smooth'
            })
        }
    }

    useEffect(() => {
        let dataCompartilhada = (history.location.state && history.location.state.data) ? moment(history.location.state.data, 'DD/MM/YYYY').format("DD/MM/YYYY") : moment(new Date()).format("DD/MM/YYYY")
        let dadosLocais = null
        try {
            //datas do cadastro de agenda
            let data = JSON.parse(sessionStorage.getItem('ultima_data_consulta_agenda'))

            if (data && data.modo_visualizacao) {
                dadosLocais = { ...data }
                // dataCompartilhada = moment(data, 'DD/MM/YYYY');
            }
        } catch (error) {
            //console.log(error)
        }
        setFiltros(dadosLocais ? dadosLocais : { data_inicial: dataCompartilhada, data_final: dataCompartilhada, modo_visualizacao: 1 })
        Promise.resolve(get('/controleAcesso/permissao', { usuario_id: user.id, modulo_id: 39, acao_id: 2 })).then(function (val) {
            setPossuiPermissaoRegistroAtividade(val.length > 0);
        })
        Promise.resolve(get('/controleAcesso/permissao', { usuario_id: user.id, modulo_id: 50, acao_id: 2 })).then(function (val) {
            setPossuiPermissaoLembrete(val.length > 0);
        })
        Promise.resolve(get('/controleAcesso/permissao', { usuario_id: user.id, modulo_id: 17, acao_id: 5 })).then(function (val) {
            setPossuiListaOcorrencia(val.length > 0);
        })
        Promise.resolve(get('/controleAcesso/permissao', { usuario_id: user.id, modulo_id: 38, acao_id: 2 })).then(function (val) {
            setPossuiPermissaoCadastro(val.length > 0);
        })
    }, [])

    useEffect(() => {
        if (verCalendario)
            mudarData(0, 3)
        // else if(verCalendario === false && ultimaData.modo_visualizacao){
        //     mudarData(0, ultimaData.modo_visualizacao)
        // }

    }, [verCalendario])

    useEffect(() => {
        props.setPodeRenderizar(false);
        props.onChangeFiltro(filtros)
        //salvando na session
        sessionStorage.setItem('ultima_data_consulta_agenda', JSON.stringify(filtros));

        if (filtros.modo_visualizacao === 1 || filtros.modo_visualizacao === 2) {
            setUltimaData({ modo_visualizacao: filtros.modo_visualizacao, data_inicial: filtros.data_inicial, data_final: filtros.data_final });
        }
    }, [filtros])

    useEffect(() => {
        rolar();
    }, [arrayRefP])

    useEffect(() => {
        if (props.data !== null) {
            if (filtros.data_inicial && filtros.data_final)
                setDias(getBetweenDates(moment(filtros.data_inicial, 'DD/MM/YYYY'), moment(filtros.data_final, 'DD/MM/YYYY'), 'DD/MM/YYYY'))
            else
                setDias(props.data.map((item) => item.data_inicial).filter((item, idx, self) => self.indexOf(item) === idx))

            let resOrdenado = props.data

            if (props.ordenar) {
                const ordenamento = []
                ordenamento.push(filtroOrdenamento('status_ordem', 1), filtroOrdenamento('hora_inicial', 2), filtroOrdenamento('hora_final', 3))
                resOrdenado = ordenarMultiplo(props.data, ordenamento)
            }

            setData(resOrdenado)

            if (resOrdenado && resOrdenado.length > 0) {
                const arrayRef = resOrdenado.map((item, idx) => ({ id: item.id, hora: item.hora_inicial, reference: React.createRef() }))
                setArrayRefP(arrayRef);
                setListaCompleta(true);
            }

            setTimeout(() => setCarregando(false), 700)
        }

    }, [props.data])
    //teste
    /* Pesquisa inicio */
    useEffect(() => {
        setContadorPesquisa(0)
        if (pesquisa.length > 0) {
            let teste = contadorPesquisa;
            if (idInterval) {
                clearInterval(idInterval)
                const id = setInterval(() => {
                    teste++;
                    setContadorPesquisa(teste)
                }, 100)
                setIdInterval(id)
            } else {
                const id = setInterval(() => {
                    teste++;
                    setContadorPesquisa(teste)
                }, 100)
                setIdInterval(id)
            }
        }
    }, [pesquisa])

    useEffect(() => {
        if (contadorPesquisa === 5) {
            setContadorPesquisa(0)
            clearInterval(idInterval)
        }
    }, [contadorPesquisa])

    /* Pesquisa fim */

    const mudarData = (valor, modo_visualizacao) => {
        const novosFiltros = calcularData(filtros.data_inicial, valor, modo_visualizacao || filtros.modo_visualizacao)

        props.setPodeRenderizar(false)

        setFiltros(novosFiltros);
    }

    const obterDescricao = () => {
        switch (filtros.modo_visualizacao) {
            case 1:
                return moment(filtros.data_inicial, 'DD/MM/YYYY').format('DD/MM/YYYY');
            case 2:
                return moment(filtros.data_inicial, 'DD/MM/YYYY').format('DD/MM/YYYY') + ' - ' + moment(filtros.data_final, 'DD/MM/YYYY').format('DD/MM/YYYY');
            case 3:
                return moment(filtros.data_inicial, 'DD/MM/YYYY').format('MMMM - YYYY');
            default:
                break;
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape") {
                setFiltroAberto(false)
            }
        }, false);
    }, []);

    const filtroOrdenamento = (coluna, ordem) => {
        return {
            coluna: coluna,
            ordenamento: {
                tipo: 'data',
                modo: 'asc',
                ordem: ordem
            }
        }
    }

    const retornarLista = () => {
        let qtdRegistro = 0
        let html = [];

        listaCompleta && data && dias.map(item => {
            const hoje = moment(item, 'DD/MM/YYYY')
            const dataFiltroPesquisa = pesquisaGlobal(data, props.colunas.map(item => item.nome), pesquisa)
            const dataFiltrada = dataFiltroPesquisa.filter(item2 => item2.data_inicial === item || (moment(item2.data_inicial, 'DD/MM/YYYY') <= moment(item, 'DD/MM/YYYY') && moment(item2.data_final, 'DD/MM/YYYY') >= moment(item, 'DD/MM/YYYY')));

            if (dataFiltrada.length > 0) {
                qtdRegistro++;
                html.push(<>
                    <div className={'div-dia ' + (item === moment(new Date()).format("DD/MM/YYYY") ? 'hoje' : '')}><span>{item + moment(item, 'DD/MM/YYYY').format(' - dddd')}</span></div>
                    <div className="agenda">
                        {
                            dataFiltrada.map((linha, idxl, array) => props.layoutLinha(linha, arrayRefP.filter(item => item.id === linha.id)[0].reference, array))
                        }
                    </div>
                </>)
            }
        })
        return html;
    }

    const obterAgendamentos = () => {
        let html = [];
        html = retornarLista();
        html = html.length > 0 ? html : <div className={'div-dia'}><span>Nenhum Registro Encontrado.</span></div>;
        return html;
    }

    const ListaCompletaMemo = useMemo(() => props.permissao ? obterAgendamentos() : <></>, [data, pesquisa, props.enviar, props.arrayEnvio, props.permissoes, props.copiado, props.permissao])

    useEffect(() => {
        if (filtroAberto) {
            setShowLembrete(false)
            setShowRegistro(false)
            setShowResumo(false)
            setShowResumoGrupos(false)
            setShowResumoEscalas(false)
        }
    }, [filtroAberto])

    return <>
        <div className={'agenda-content ' + (filtroAberto === true || showLembrete === true || showRegistro === true || showResumo === true || showResumoGrupos === true && (width > 1200 && !props.abrirFiltroModal) ? ' filtro-ativo' : '')}>
            <div className={'card-right custom-scrollbar' + (filtroAberto === true && (width > 1200 && !props.abrirFiltroModal) ? ' filtro-ativo' : '')}>
                <Configuracoes setVerCalendario={setVerCalendario} verCalendario={verCalendario} setFiltroAberto={setFiltroAberto} mudarData={mudarData} modulo={props.modulo}>{props.children}</Configuracoes>
            </div>
            <HeaderAgenda showResumo={showResumo} setShowResumo={setShowResumo} showResumoGrupos={showResumoGrupos} setShowResumoGrupos={setShowResumoGrupos} setShowResumoEscalas={setShowResumoEscalas} showResumoEscalas={showResumoEscalas} showRegistro={showRegistro} setFiltroAberto={setFiltroAberto} setShowRegistro={setShowRegistro} showLembrete={showLembrete} setShowLembrete={setShowLembrete} clickCadastroLembrete={props.onClickCadastrarLembrete} filtros={filtros} atualizarLembrete={atualizarLembrete} atualizarRegistro={atualizarRegistro} className='header-agenda' titulo={'Agenda/Central de Reserva'} botoes={<>
                {width > 450 ?
                    <div className='ajusta-botoes'>
                        {props.possuiPermissaoEnvio && props.dados && props.dados.filter(item => item.status_id === 2 || item.status_id === 3 || item.status_id === 5).length > 0 ? (!(props.enviar && !props.enviando) ?
                            <BotaoPadrao textoTooltip="Enviar" icone='icon-Mail-Send' classe=' btn btn-primary primary btn-filter '
                                idTooltip={"padrao"} click={() => props.setEnviar(true)} disabled={props.enviando} />
                            : <></>
                        ) : <></>}
                        {
                            user.tipo !== 4 && user.tipo !== 3 && <Link to={{ pathname: '/administracao/socio-ocorrencia/ListagemDiaria', state: { data: obterDescricao() } }}>
                                <BotaoPadrao click={_ => { }} icone='icon-Letter-Open' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Ocorrências'} idTooltip={"padrao"} />
                            </Link>
                        }
                        {possuiPermissaoLembrete === false ? <></> : <BotaoPadrao textoTooltip="Lembrete" icone='icon-Bell' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={() => props.onClickCadastrarLembrete()} />}
                        {possuiPermissaoRegistroAtividade === false ? <></> : <BotaoPadrao textoTooltip="Registro Atividade" icone='icon-Address-Book2' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={() => props.onClickCadastrarRegistroAtividade()} />}

                        <Link to={'/administracao/agendareserva/cadastro'}>
                            <BotaoPadrao textoTooltip="Cadastro" icone='icon-Add' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} />
                        </Link>
                        {possuiPermissaoCadastro === false ? <></> :
                            <BotaoPadrao textoTooltip="Cadastro Rápido" icone='icon-Clock-Forward' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={() => props.onClickCadastrar()} />}

                        <Link to={{ pathname: '/administracao/plantao-alcancaveis/consulta', state: { data: obterDescricao() } }}>
                            <BotaoPadrao click={_ => { }} icone='icon-Clock' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Escala Plantão'} idTooltip={"padrao"} />
                        </Link>
                        <Link to={{ pathname: '/administracao/plantao-estabelecimento/consulta', state: { data: obterDescricao() } }}>
                            <BotaoPadrao click={_ => { }} icone='icon-Building' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Escala Estabelecimento'} idTooltip={"padrao"} />
                        </Link>
                        <Link to={{ pathname: '/administracao/rotinas/consulta', state: { data: obterDescricao() } }}>
                            <BotaoPadrao click={_ => { }} icone='icon-Dec' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Rotinas'} idTooltip={"padrao"} />
                        </Link>
                        <BotaoPadrao icone='icon-Filter-2' textoTooltip="Filtrar" classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={_ => setFiltroAberto(!filtroAberto)} {...props} />
                    </div>
                    :
                    <BotaoMenu icone={'icon-ellipsi'} style={{ paddingLeft: '10px' }} iniciarAberto={true}>
                        {props.possuiPermissaoEnvio && props.dados && props.dados.filter(item => item.status_id === 2 || item.status_id === 3 || item.status_id === 5).length > 0 ? (!(props.enviar && !props.enviando) ?
                            <BotaoPadrao textoTooltip="Enviar" icone='icon-Mail-Send' classe=' btn btn-primary primary btn-filter '
                                idTooltip={"padrao"} click={() => props.setEnviar(true)} disabled={props.enviando} />
                            : <></>
                        ) : <></>}
                        {
                            user.tipo !== 4 && <Link to={{ pathname: '/administracao/socio-ocorrencia/ListagemDiaria', state: { data: obterDescricao() } }}>
                                <BotaoPadrao click={_ => { }} icone='icon-Letter-Open' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Ocorrências'} idTooltip={"padrao"} />
                            </Link>
                        }
                        <BotaoPadrao textoTooltip="Lembrete" icone='icon-Bell' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={() => props.onClickCadastrarLembrete()} />
                        <BotaoPadrao textoTooltip="Registro Atividade" icone='icon-Address-Book2' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={() => props.onClickCadastrarRegistroAtividade()} />
                        <Link to={'/administracao/agendareserva/cadastro'}>
                            <BotaoPadrao textoTooltip="Cadastro" icone='icon-Add' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} />
                        </Link>
                        <BotaoPadrao textoTooltip="Cadastro Rápido" icone='icon-Clock-Forward' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={() => props.onClickCadastrar()} />
                        <Link to={{ pathname: '/administracao/plantao-alcancaveis/consulta', state: { data: obterDescricao() } }}>
                            <BotaoPadrao click={_ => { }} icone='icon-Clock' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Plantão'} idTooltip={"padrao"} />
                        </Link>
                        <Link to={{ pathname: '/administracao/plantao-estabelecimento/consulta', state: { data: obterDescricao() } }}>
                            <BotaoPadrao click={_ => { }} icone='icon-Building' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Plantão Estabelecimento Saúde'} idTooltip={"padrao"} />
                        </Link>
                        <Link to={{ pathname: '/administracao/rotinas/consulta', state: { data: obterDescricao() } }}>
                            <BotaoPadrao click={_ => { }} icone='icon-Dec' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Rotinas'} idTooltip={"padrao"} />
                        </Link>
                        <BotaoPadrao icone='icon-Filter-2' textoTooltip="Filtrar" classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={() => setFiltroAberto(!filtroAberto)} {...props} />
                    </BotaoMenu>
                }
            </>} modalHeader={props.modalHeader} showModalHeader={props.showModalHeader} />
            <div className='row'>
                {!verCalendario ? <><div className={(props.btnRmvFiltros.qtdFiltro > 0 ? 'col-sm-10' : 'col-sm-12') + ' col-md-9 col-lg-6'}>
                    <InputSimples name="search1" type="text" onChange={e => setPesquisaChar(e.target.value)} placeholder={`Pesquisar...`} />
                </div>
                    <div className={'col-sm-2 col-md-3 col-lg-2'}>
                        <>{props.btnRmvFiltros ? <div className="">
                            {props.btnRmvFiltros.showRmvOrd && <BtnAcao icone='icon-A-Z' texto="Remover Ordenamento" cortarIcone action={() => { props.btnRmvFiltros.acaoRmvOrd(); setCarregando(true) }} />}
                            {props.btnRmvFiltros.qtdFiltro > 0 && <BtnAcao icone='icon-Filter-2' texto={"Remover Filtros(" + props.btnRmvFiltros.qtdFiltro + ")"} cortarIcone action={() => { props.btnRmvFiltros.acaoRmvFiltro(); setCarregando(true) }} />}
                        </div> : <div className=""><span>Sem Filtro</span></div>}</>
                    </div>
                </> : <></>}
                <div className={verCalendario ? 'col-12' : 'col-md-12 col-lg-4'}>
                    <div className={'paginacao-rotinas ' + (width < 990 || (width < 1488 && filtroAberto) ? ' text-center mb-3' : ' text-right')}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: (width < 990 || (width < 1488 && filtroAberto) ? 'center' : 'flex-end') }}>
                            {carregando && <span className="spinner-border spinner-border-md" role="status" aria-hidden="true" style={{ opacity: 0.2 }}></span>}
                            <button className="icon-Arrow-Left2 pagination-button icone icone-pagination d-none" onClick={() => { }}></button>
                            <button className="icon-Arrow-Left pagination-button icone icone-pagination" onClick={() => mudarData(-1)}></button>
                            <ModalCalendar show={showCalendar} setShowModal={setShowCalendar}>
                                <InfiniteCalendar className={showCalendar ? "calendario-ativo" : "calendario-oculto"}
                                    locale={{
                                        locale: require('date-fns/locale/pt'),
                                        blank: 'Selecione uma data',
                                        headerFormat: 'ddd, MMM Do',
                                        weekdays: ["Dom", "Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb"],
                                        todayLabel: {
                                            long: 'Hoje',
                                            short: 'Hoje'
                                        },
                                        weekStartsOn: 0,
                                    }}
                                    selected={obterDescricao() ? obterDescricao().includes('-') ? new Date() : new Date(obterDescricao().split('/')[1] + '/' + obterDescricao().split('/')[0] + '/' + obterDescricao().split('/')[2]) : ''}
                                    onSelect={value => { mudarData(value.getDate().toString().padStart(2, '0') + '/' + (value.getMonth() + 1).toString().padStart(2, '0') + '/' + value.getFullYear()); setShowCalendar(!showCalendar); }} height={height - 150}
                                    width={width < 501 ? width : 403}
                                />
                            </ModalCalendar>
                            <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={_ => setShowCalendar(!showCalendar)}>{obterDescricao()} </span>
                            <button className="icon-Arrow-Right pagination-button icone icone-pagination" onClick={() => mudarData(1)}></button>
                            <button className="icon-Arrow-Right2 pagination-button icone icone-pagination d-none" onClick={() => { }}></button>
                        </div>
                        {obterDescricao() && obterDescricao().includes('/') && !obterDescricao().includes('-') && <span style={{ marginRight: (width < 990 || (width < 1488 && filtroAberto) ? 'unset' : '95px') }}>{moment(obterDescricao(), 'DD/MM/YYYY').format('ddd')}</span>}
                    </div>
                </div>
            </div>
            {width > 530 &&
                <>
                    <div style={{ top: height / 2 }} className={'slide-middle-div slide-middle-left-div ' + (props.menuAberto ? '' : 'btn-left-0')}>
                        <BotaoPadrao icone='icon-Arrow-Left' classe='btn-middle-left btn primary btn-filter btn-scroll-top' idTooltip={"padrao"} click={_ => mudarData(-1)} />
                    </div>
                    <div style={{ top: height / 2 }} className={'slide-middle-div slide-middle-right-div ' + (filtroAberto || showLembrete || showRegistro ? 'margin-r-279' : 'right-5-neg')}>
                        <BotaoPadrao icone='icon-Arrow-Right' classe='btn-middle-right btn primary btn-filter btn-scroll-top' idTooltip={"padrao"} click={_ => mudarData(1)} />
                    </div>
                </>
                || <></>
            }
            <>
                <div className={'card-row-agenda p-5-after mb-1' + (props.enviar ? ' card-envio-row' : '')}>
                    {props.enviar ? <div className={'switch-envio-card'} onClick={(e) => props.marcarTodos(e)}>
                        <InputSwitchSemFormik onClick={(e) => props.marcarTodos(e)} checked={props.todosMarcados} classeDiv='form-group switch-center' name='plantao' size="md" tamanho={'col-lg-1'} classeTooltip={'tooltip-input-modal'} />
                    </div> : <></>}
                    {!verCalendario && width > 767 ?
                        <div className='card no-border justfy-center'>
                            <div className='row m-0'>
                                {props.colunas && props.colunas.filter(coluna => coluna.apenasPesquisa === undefined || !coluna.apenasPesquisa).map(coluna =>
                                    <div className={'column no-border ' + coluna.classe} style={{ width: coluna.tamanho }}>
                                        <b>{coluna.label}</b>
                                    </div>)}
                            </div>
                        </div> : <></>}
                </div></>

            {dias ?
                <>{
                    verCalendario === true ?
                        <Calendario dataAtual={filtros.data_inicial} clickEvento={item => { }} data={[]} onClickDia={() => { props.onClickDiaCalendario() }} {...props} />
                        : ListaCompletaMemo}
                </> : <></>}
            <ModalPadraoCadastro showModal={filtroAberto == true && (width <= 1200 || props.abrirFiltroModal)} setShowModal={setFiltroAberto} fecharComEsc={false}>
                <Configuracoes abrirFiltroModal={props.abrirFiltroModal} setVerCalendario={setVerCalendario} verCalendario={verCalendario} setFiltroAberto={setFiltroAberto} mudarData={mudarData} modulo={props.modulo}>{props.children}</Configuracoes>
            </ModalPadraoCadastro>
        </div>
    </>
});

const Configuracoes = (props) => {
    const [width] = useWindowSize();

    return (
        <div className={''}>
            <div className={'filtros '}>
                <div className='filtro'>
                    {width > 1200 && !props.abrirFiltroModal && <button class="close" onClick={() => props.setFiltroAberto(false)}><span aria-hidden="true">×</span></button>}
                    <div className='titulo-filtro row-space-between'>
                        <span className='vertical-center'>Organizar por:</span>
                    </div>

                    <div className='content-filtro text-left'>
                        <BtnAcao icone="icon-Bulleted-List" classTooltip='tooltip-input-modal' texto="Lista" action={() => { props.setFiltroAberto(!(width < 1200)); props.setVerCalendario(false) }} {...props} />
                        {/* <BtnAcao icone="icon-Calendar-3" classTooltip='tooltip-input-modal' texto="Calendário" action={() => { props.setFiltroAberto(!(width < 1200)); props.setVerCalendario(true) }} {...props} /> */}
                    </div>
                </div>

                {
                    !props.verCalendario ? <div className='filtro'>
                        <div className='titulo-filtro row-space-between'>
                            <span className='vertical-center'>Modo Visualização</span>
                        </div>
                        <div className='content-filtro'>
                            {props.modulo === 38 ? <ul class="lista-selecao mb-3 row pl-5px">
                                <li class="active nav-item">
                                    <a href="#" onClick={() => { props.setFiltroAberto(!(width < 1200)); props.mudarData(0, 1) }}>
                                        <i class="simple-icon-reload"></i>
                                        <span className='span-clicavel'>Dia</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="#" onClick={() => { props.setFiltroAberto(!(width < 1200)); props.mudarData(0, 2) }}>
                                        <i class="simple-icon-refresh"></i>
                                        <span>Semana</span>
                                    </a>
                                </li>
                            </ul> :
                                <ul class="lista-selecao mb-3 row pl-5px">
                                    <li class="active nav-item">
                                        <a href="#" onClick={() => { props.setFiltroAberto(!(width < 1200)); props.mudarData(0, 1) }}>
                                            <i class="simple-icon-reload"></i>
                                            <span className='span-clicavel'>Dia</span>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" onClick={() => { props.setFiltroAberto(!(width < 1200)); props.mudarData(0, 2) }}>
                                            <i class="simple-icon-refresh"></i>
                                            <span>Semana</span>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" onClick={() => { props.setFiltroAberto(!(width < 1200)); props.mudarData(0, 3) }}>
                                            <i class="simple-icon-check"></i>
                                            <span>Mês</span>
                                        </a>
                                    </li>
                                </ul>
                            }
                        </div>
                    </div> : <></>
                }
                {props.children}
            </div>
        </div>
    )
}

export default AgendaPadrao