import { InputTabelaProcedimento, InputContratoBaseCalculo } from '../../../../components/InputsAutoComplete'
import { HeaderCadastro } from '../../../../components/Headers'
import { removeAcentos } from '../../../../util/FuncoesComuns'
import { ModalCadastro } from '../../../../components/Modais'
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { InputPadrao } from '../../../../components/Inputs'
import Yup from '../../../../util/Validacoes'
import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import Atalho from '../../../../util/Atalho'
import { BtnAcao } from '../../../../components/Acoes'

export default props => {
    const [pesquisa, setPesquisa] = useState('')
    const [salvando, setSalvando] = useState(false)

    const [msgBotao, setMsgBotao] = useState('Adicionar')

    const emptyValues = {
        id: null,
        verificador: null,
        tabela_procedimento: null,
        base_calculo: null,
    }

    const [values, setValues] = useState(emptyValues)

    const validar = obj => {
        if (props.arrayDados.length &&
            props.arrayDados.filter(x => x.tabela_procedimento.value === obj.tabela_procedimento.value && x.verificador !== obj.verificador) &&
            props.arrayDados.filter(x => x.tabela_procedimento.value === obj.tabela_procedimento.value && x.verificador !== obj.verificador).length)
            return 'Tabela já parametrizado!'
    }

    const Submit = (obj, { resetForm }) => {
        const resultadoValidacao = validar(obj)
        setSalvando(true);


        if (resultadoValidacao) {
            mostrarToast('erro', resultadoValidacao)
            return
        }

        let copiaArrayDados = [...props.arrayDados]

        if (obj.verificador)
            copiaArrayDados[copiaArrayDados.findIndex(x => x.verificador === obj.verificador)] = obj
        else {
            obj.verificador = Math.random()
            copiaArrayDados.push(obj)
        }

        props.setArrayDados(copiaArrayDados)
        resetForm({})

        if (msgBotao === 'Alterar')
            props.setAbrirModal(!props.abrirModal)

        setSalvando(false)
    }

    const Delete = index => {
        let copiaArrayDados = [...props.arrayDados]
        copiaArrayDados.splice(index, 1);
        props.setArrayDados(copiaArrayDados)
    }

    return (
        <>
            <HeaderCadastro
                pesquisa={true}
                valorPesquisa={pesquisa}
                onChangePesquisa={pesquisa => setPesquisa(pesquisa)}
                classeChildren='space-between-100' >
                <BotaoPadrao type='button' classe='btn-primary primary p-5rem height-auto m-r-15' texto={<><span> Adicionar</span></>}
                    click={_ => { setValues(emptyValues); props.setAbrirModal(!props.abrirModal); setMsgBotao('Adicionar') }} />
            </HeaderCadastro>
            <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                <thead>
                    <tr>
                        <th style={{ width: '5%' }}>#</th>
                        <th style={{ width: '40%' }}>Tabela</th>
                        <th style={{ width: '40%' }}>Base Cálculo</th>
                        <th style={{ width: '15%' }} className='text-center'>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.arrayDados && props.arrayDados.length ?
                            props.arrayDados.filter(x =>
                            (removeAcentos(x.tabela_procedimento && x.tabela_procedimento.label.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase()))
                                || removeAcentos(x.base_calculo.label.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase()))))
                                .map((x, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{x.tabela_procedimento && x.tabela_procedimento.label}</td>
                                            <td>{x.base_calculo.label}</td>
                                            <td className='text-center'>
                                                <BtnAcao type={'button'} icone="icon-Pen" texto="Alterar" action={() => {
                                                    console.log('ACAO ', x)
                                                    if (!x?.tabela_procedimento?.tabela_auxiliar) {
                                                        setValues(x);
                                                        props.setAbrirModal(true);
                                                        setMsgBotao('Alterar');
                                                    } else mostrarToast('erro', 'Ação não permitida para tabela auxiliar')
                                                }} />

                                                <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => {
                                                    if (!x?.tabela_procedimento?.tabela_auxiliar)
                                                        Delete(index)

                                                    else mostrarToast('erro', 'Ação não permitida para tabela auxiliar')

                                                }} />

                                            </td>
                                        </tr>
                                    )
                                }
                                )
                            : <tr><td colspan='4' className='text-center'>Nenhum Registro Cadastrado</td></tr>
                    }
                </tbody>
            </table>
            <ModalCadastro titulo={'Tabela'} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    enableReinitialize={true}
                    initialValues={values}
                    validationSchema={Yup.object({
                        tabela_procedimento: Yup.string().ensure().required().nullable(),
                        base_calculo: Yup.string().ensure().required().nullable(),
                    })}
                    onSubmit={Submit}>
                    {propsFormik => <Form>
                        <div className='modal-body-cadastro'>
                            <InputPadrao label='Id' name='id' type='hidden' />
                            <InputTabelaProcedimento label='Tabela' name="tabela_procedimento" propsFormik={propsFormik} obrigatorio {...props}
                                classeTooltip={'tooltip-input-modal'} autoFocus focarAposSubmit={true} botaoVisivel={true} modal={true}
                                onChange={(option) => {
                                    propsFormik.setFieldValue('tabela_procedimento', option)
                                }} />
                            <InputContratoBaseCalculo label='Base Cálculo' name="base_calculo" propsFormik={propsFormik} obrigatorio {...props}
                                classeTooltip={'tooltip-input-modal'} onChange={(option) => {
                                    propsFormik.setFieldValue('base_calculo', option)
                                }} />
                            <Atalho formik={propsFormik} salvando={salvando} />

                        </div>
                        <div className='direita container-botoes'>
                            <BotaoPadrao texto={msgBotao} />
                        </div>
                    </Form>}
                </Formik>
            </ModalCadastro>
        </>)
}