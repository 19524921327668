import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FieldArray, FastField } from "formik";
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { ModalCadastro, ModalPadraoCadastro, ModalEscolha, ModalDuasOpcoes } from '../../../../components/Modais';
import { get, putComAcao, salvar, validar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao, InputDatePicker, TextArea, InputTimePicker, InputSwitch, InputTelefone, InputValor, InputSwitchSemFormik, InputValorNovo, InputPadraoSimples, InputMascaraSimples, ControlledInput, InputCurrency, InputCpfCnpj } from '../../../../components/Inputs'
import {
    InputEntidade, InputFormaReceita, InputRetencao, InputStatusMovimentoFinanceiro
} from '../../../../components/InputsAutoComplete'
import '../../../../assets/css/icones/iconsmind/style.css'
import { formatMoeda } from '../../../../util/FuncoesComuns';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { convertValueToFloat, formatarMoeda, mensagens } from '../../../../util/Utilitarios';
import { BtnAcao } from '../../../../components/Acoes';
import { BotaoPadrao } from '../../../../components/Botoes';
import moment from 'moment';
import 'moment/locale/pt-br'
import { ACOES, RETENCOES } from '../../../../util/Enums';
import { getUsuario } from '../../../../util/Configuracoes';
import { FiTarget } from 'react-icons/fi';
import { values } from 'lodash';
// import { Overlay, Popover } from 'react-bootstrap';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { object } from 'yup';

const ValorRecebido = ({ propsFormik, ...props }) => {
    const [totalReceita, setTotalReceita] = useState(0)
    const [totalRetencao, setTotalRetencao] = useState(0)
    const [totalGeral, setTotalGeral] = useState()
    const inputValorReceita = useRef([]);
    const inputValorRetencao = useRef([]);

    useEffect(() => {
        setTotalReceita(props.valorTotalReceita)
    }, [props.valorTotalReceita])

    useEffect(() => {
        setTotalRetencao(props.valorTotalRetencao)
    }, [props.valorTotalRetencao])

    useEffect(() => {
        setTotalGeral(totalReceita + totalRetencao)
    }, [totalReceita, totalRetencao])

    useEffect(() => {
        calcularTotalGeral(propsFormik)
    }, [propsFormik?.values.receita, propsFormik.values?.retencao,])


    const calcularTotalGeral = (propsFormik) => {

        let valoresReceita = propsFormik.values?.receita?.filter(x => x.valor_receita != undefined)

        let valoresRetencao = propsFormik.values?.retencao?.filter(x => x.valor_retencao != undefined)


        let valorTotalRecebido = valoresReceita?.reduce((result, obj) => {
            return (result + parseFloat(obj.valor_receita || 0))
        }, 0)

        setTotalReceita(valorTotalRecebido)

        let valorTotalRetencao = valoresRetencao?.reduce((result, obj) => {
            return (result + parseFloat(obj.valor_retencao || 0))
        }, 0)

        setTotalRetencao(valorTotalRetencao)

    }



    const onChangeFormaReceita = (option, propsFormik, arrayHelpers, index) => {
        if (option) {
            propsFormik.setFieldValue(`receita[${index}].forma_receita`, option);
            if ((index + 1) === propsFormik.values.receita.length) {
                arrayHelpers.insert(propsFormik.values.receita.length, { forma_receita: undefined, valor_receita: undefined })
            }
        } else {
            propsFormik.setFieldValue(`receita[${index}].forma_receita`, undefined);
        }

    }

    const onChangeFormaRetencao = (option, propsFormik, arrayHelpers, index) => {
        if (option) {
            propsFormik.setFieldValue(`retencao[${index}].forma_retencao`, option);
            if ((index + 1) === propsFormik.values.retencao.length) {
                arrayHelpers.insert(propsFormik.values.retencao.length, { forma_retencao: undefined, valor_retencao: undefined })
            }
        } else {
            propsFormik.setFieldValue(`retencao[${index}].forma_retencao`, undefined);
        }
    }

    const removerLinhaReceita = (arrayHelpers, propsFormik, index) => {

        const arrayReceita = [...propsFormik.values.receita];
        if (arrayReceita[index]?.forma_receita && arrayReceita[index]?.forma_receita.value || !arrayReceita[index]?.forma_receita && !arrayReceita[index]?.forma_receita?.value && !(arrayReceita.length === index + 1)) {
            arrayHelpers.remove(index)
            arrayReceita.splice(index, 1)
        }
        if (propsFormik.values.receita.length === 1) {
            propsFormik.setFieldValue('receita', [{ forma_receita: undefined }])
        }
        if (arrayReceita.length === 1 && arrayReceita[index]?.forma_receita && arrayReceita[index]?.forma_receita.value) {
            arrayHelpers.insert(arrayReceita.length, { forma_receita: undefined })
        }

        if (arrayReceita.length === index + 1 && arrayReceita[index].valor_receita) {
            propsFormik.setFieldValue(`receita[${index}]`, { forma_receita: undefined, valor_receita: undefined })
            arrayReceita[index] = { forma_receita: undefined, valor_receita: undefined }
            // revisar posteriormente
        }

        onChangeValorReceita(arrayReceita)

    }

    const removerLinhaRetencao = (arrayHelpers, propsFormik, index) => {

        const arrayRetencao = [...propsFormik.values.retencao];

        if (arrayRetencao[index]?.forma_retencao && arrayRetencao[index]?.forma_retencao.value) {
            arrayHelpers.remove(index)
            arrayRetencao.splice(index, 1)
        }
        if (propsFormik.values.retencao.length === 1) {
            propsFormik.setFieldValue('retencao', [{ forma_retencao: undefined }])
        }
        if (arrayRetencao.length === 1 && arrayRetencao[index]?.forma_retencao && arrayRetencao[index]?.forma_retencao.value) {
            arrayHelpers.insert(propsFormik.values.retencao.length, { forma_retencao: undefined })
        }

        onChangeValorRetencao(arrayRetencao)

    }


    const onChangeValorReceita = (arrayRetencao) => {

        const valoresReceita = arrayRetencao
        let valorTotal = valoresReceita?.map(x => parseFloat(x.valor_receita || 0)).reduce((ant, atual) => ant + atual, 0);
        setTotalReceita((valorTotal))

    }

    const onChangeValorRetencao = (arrayRetencao) => {

        const valoresRetencao = arrayRetencao
        let valorTotal = valoresRetencao?.map(x => parseFloat(x.valor_retencao || 0)).reduce((ant, atual) => ant + atual, 0);
        setTotalRetencao((valorTotal))

    }
    // border-select
    return (
        <div className='card-body'>
            <div className='dp-flex'>
                <div className='valores-recebidos box-liquidacao-parcelado'>

                    <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                        <thead>
                            {
                                <tr>
                                    <th style={{ width: '15%', textAlign: 'center' }}>#</th>
                                    <th style={{ width: '45%' }}>Formas de Receita</th>
                                    <th style={{ width: '25%' }}>Valor (R$)</th>
                                    <th style={{ width: '15%' }}>Ações</th>

                                </tr>
                            }
                        </thead>
                        <tbody>
                            <FieldArray name="receita">
                                {(arrayHelpers => {
                                    return (
                                        <>
                                            {
                                                propsFormik.values.receita ?
                                                    propsFormik.values.receita.map((item, index) => (
                                                        <tr>
                                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
                                                            <td style={{ textAlign: 'right' }} >
                                                                <InputFormaReceita
                                                                    className='mt-20'
                                                                    name={`receita[${index}].forma_receita`}
                                                                    placeholder="Forma de Receita"
                                                                    propsFormik={propsFormik}
                                                                    botaoVisivel={true}
                                                                    onChange={(opiton) => onChangeFormaReceita(opiton, propsFormik, arrayHelpers, index)} />
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                <InputCurrency
                                                                    onUpdate={val => {
                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                        inputValorReceita.current[index] = valor
                                                                    }}
                                                                    className='mt-20'
                                                                    name={`receita[${index}].valor_receita`}
                                                                    classeTooltip={'tooltip-input-modal'}
                                                                    propsFormik={propsFormik}
                                                                    max={100000000}
                                                                    value={inputValorReceita.current[index] || propsFormik.values.receita[index].valor_total_formatado || propsFormik.values.receita[index].valor_receita}
                                                                    onBlur={(e) => {
                                                                        if (inputValorReceita.current[index] || inputValorReceita.current[index] === 0) {
                                                                            propsFormik.values.receita[index].valor_receita = inputValorReceita.current[index] && inputValorReceita.current[index].toString().trim();
                                                                            propsFormik.values.receita[index].valor_receita_formatado = formatarMoeda(inputValorReceita.current && inputValorReceita.current[index].toString());

                                                                            onChangeValorReceita(propsFormik.values.receita)
                                                                        }
                                                                        inputValorReceita.current[index] = null;
                                                                    }
                                                                    } />
                                                            </td>
                                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                                {/* <span className='icon-consulta icon-New-Trash' onClick={target => { removerLinhaReceita(arrayHelpers, propsFormik, index) }} /> */}
                                                                <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinhaReceita(arrayHelpers, propsFormik, index)} />
                                                            </td>
                                                        </tr>
                                                    )) : <></>
                                            }  </>
                                    )
                                }

                                )}
                            </FieldArray>
                        </tbody>
                    </table>

                    <div className='valor_total'>
                        <span><b style={{ color: 'black' }}>Total: {formatMoeda(totalReceita)}</b></span>
                    </div>

                </div>
                <div className='valores-recebidos box-liquidacao-parcelado'>

                    <table className='table tabela-endereco table-responsive-md table-striped tabela ' >
                        <thead>
                            {
                                <tr>
                                    <th style={{ width: '15%', textAlign: 'center' }}>#</th>
                                    <th style={{ width: '45%' }}>Retenções</th>
                                    <th style={{ width: '25%' }}>Valor (R$)</th>
                                    <th style={{ width: '15%' }} className='text-center'>Ações</th>
                                </tr>
                            }
                        </thead>
                        <tbody>
                            <FieldArray name="retencao">
                                {(arrayHelpers => (
                                    <>
                                        {
                                            propsFormik.values.retencao ?
                                                propsFormik.values.retencao.map((item, index) =>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
                                                        <td >
                                                            <InputRetencao
                                                                className='mt-20'
                                                                name={`retencao[${index}].forma_retencao`}
                                                                placeholder="Retenção"
                                                                propsFormik={propsFormik}
                                                                botaoVisivel={true}
                                                                onChange={(opiton) => onChangeFormaRetencao(opiton, propsFormik, arrayHelpers, index)} />
                                                        </td>
                                                        <td style={{ textAlign: 'right' }}>
                                                            <InputCurrency
                                                                onUpdate={val => {
                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                    inputValorRetencao.current[index] = valor
                                                                }}
                                                                className='mt-20'
                                                                name={`retencao[${index}].valor_retencao`}
                                                                classeTooltip={'tooltip-input-modal'}
                                                                propsFormik={propsFormik}
                                                                max={100000000}
                                                                value={inputValorRetencao.current[index] || propsFormik.values.retencao[index].valor_total_formatado || propsFormik.values.retencao[index].valor_retencao}
                                                                onBlur={(e) => {
                                                                    if (inputValorRetencao.current[index] || inputValorRetencao.current[index] === 0) {
                                                                        propsFormik.values.retencao[index].valor_retencao = inputValorRetencao.current[index] && inputValorRetencao.current[index].toString().trim();
                                                                        propsFormik.values.retencao[index].valor_retencao_formatado = formatarMoeda(inputValorRetencao.current && inputValorRetencao.current[index].toString());

                                                                        onChangeValorRetencao(propsFormik.values.retencao)
                                                                    }
                                                                    inputValorRetencao.current[index] = null;
                                                                }

                                                                } />
                                                        </td>
                                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                            {/* <span className='icon-consulta icon-New-Trash' onClick={target => { removerLinhaRetencao(arrayHelpers, propsFormik, index); }} /> */}
                                                            <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinhaRetencao(arrayHelpers, propsFormik, index)} />
                                                        </td>
                                                    </tr>
                                                ) : <></>
                                        }
                                    </>
                                ))}
                            </FieldArray>
                        </tbody>
                    </table>

                    <div className='valor_total'>
                        <span><b style={{ color: 'black' }}>Total: {formatMoeda(totalRetencao)}</b></span>
                    </div>

                </div>


            </div>
            <div className={'valor_total_geral  ' + (formatMoeda(totalGeral) == formatMoeda(propsFormik.values.valor) ? 'valor-indicador-certo' : 'valor-indicador-errado')}>
                <span><b>Total Geral: {formatMoeda(totalGeral)}</b></span>
            </div>

        </div>
    )

}

const Fechamento = ({ propsFormik, valorTotalLancamento, ...props }) => {

    const [showModalFechamento, setShowModalFechamento] = useState(false)
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)

    const [itemDetalhes, setItemDetalhes] = useState()

    const [detalhesValoresReceita, setDetalhesValoresReceita] = useState()
    const [detalhesValoresRetencao, setDetalhesValoresRetencao] = useState()
    const [detalhesLancamento, setDetalhesLancamento] = useState()
    const [acrescimoRetencaoLancamento, setAcrescimoRetencaoLancamento] = useState()
    const [despesasLancamento, setDespesasLancamento] = useState()


    const [diferencaModoLiquidacao, setDiferencaModoLiquidacao] = useState()

    const [valorTotalReceitaFechamento, setValorTotalReceitaFechamento] = useState(0)
    const [valorTotalRetencaoFechamento, setValorTotalRetencaoFechamento] = useState(0)
    const [valorTotalLancamentoFechamento, setValorTotalLancamentoFechamento] = useState(0)
    const [valorTotalRetencoesLancamento, setValorTotalRetencoesLancamento] = useState(0)
    const [valorTotalDespesasLancamento, setValorTotalDespesasLancamento] = useState(0)


    const refFechamento = useRef(null)

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);

    const [totalRecebido, setTotalRecebido] = useState()

    const comparaReceitaLancamento = (detalhesValoresReceita, detalhesLancamento) => {

        let diferenca = "";
        let array = []
        for (let i = 0; i < detalhesLancamento.length; i++) {
            const modoLiquidacao = detalhesLancamento[i].modo_liquidacao;
            let formaReceitaEncontrada = false;

            for (let j = 0; j < detalhesValoresReceita.length; j++) {
                const formaReceita = detalhesValoresReceita[j].forma_receita;

                if (modoLiquidacao === formaReceita) {
                    formaReceitaEncontrada = true;
                    break;
                }
            }

            if (!formaReceitaEncontrada) {
                array.push(modoLiquidacao)
                diferenca += `${modoLiquidacao}, `;
            }
        }

        if (diferenca !== "") {
            diferenca = diferenca.slice(0, -2);
            setDiferencaModoLiquidacao(`O(s) Modo(s) de Liquidação ${diferenca} são diferentes das Formas de Receita declaradas.`);
            setShow(true)

        }

    }

    useEffect(() => {
        if (detalhesLancamento && detalhesLancamento.length > 0 && detalhesValoresReceita && detalhesValoresReceita.length > 0)
            comparaReceitaLancamento(detalhesValoresReceita, detalhesLancamento)
    }, [detalhesValoresReceita, detalhesLancamento])



    const obterDetalhes = async (props, propsFormik) => {
        let { id } = propsFormik.values
        setItemDetalhes({ mf_historico_status: [{ titulo: 'Carregando...' }] })
        let movimento = {}

        await Promise.resolve(get(`/financeiro/obterhistoricostatusmovimento/${id}`)).then(val2 => {
            movimento.mf_historico_status = val2;
        })

        setItemDetalhes(movimento)
    }


    const obterDetalhesLancamento = (detalhesLancamento) => {


        let resultado = [];
        let resultado_despesa = [];

        let objeto_lancamento = detalhesLancamento && detalhesLancamento.length > 0 && detalhesLancamento.filter(x => x.tipo_lancamento?.value === 1).reduce((soma, conta) => {
            let listaModos;
            if (conta.parcelado === true && conta.aux_parcelado === true) {
                listaModos = conta?.lancamento_movimento_liquidacao.filter(x => x.modo_liquidacao?.label).reduce((prev, currentObj) => {
                    let modoInterno = currentObj.modo_liquidacao.label;

                    return {
                        ...prev,
                        [modoInterno]: (prev[modoInterno] || 0) + convertValueToFloat(currentObj.valor)
                    }
                }, {})
            } else {
                if (conta.parcelado == false) {
                    listaModos = conta?.lancamento_movimento_liquidacao.filter(x => x.modo_liquidacao?.label).reduce((prev, currentObj) => {
                        let modoInterno = currentObj.modo_liquidacao.label;

                        return {
                            ...prev,
                            [modoInterno]: (prev[modoInterno] || 0) + convertValueToFloat(currentObj.valor)
                        }
                    }, {})
                } else {
                    listaModos = conta?.lancamento_movimento_liquidacao_parcelado.filter(x => x.modo_liquidacao?.label).reduce((prev, currentObj) => {
                        let modoInterno = currentObj.modo_liquidacao.label;

                        return {
                            ...prev,
                            [modoInterno]: (prev[modoInterno] || 0) + convertValueToFloat(currentObj.valor)
                        }
                    }, {})
                }
            }

            Object.keys(listaModos).map(x => {
                listaModos[x] = (soma[x] || 0) + listaModos[x]
            })
            return {
                ...soma,
                // [modo2]: (soma[modo2] || 0) + valor,
                ...listaModos
            };
        }, {})

        let objeto_desconto_lancamento = detalhesLancamento && detalhesLancamento.length > 0 && detalhesLancamento.filter(x => x.tipo_lancamento?.value === 2).reduce((soma, conta) => {
            let listaModos;
            if (conta.parcelado === true && conta.aux_parcelado === true) {
                listaModos = conta?.lancamento_movimento_liquidacao.filter(x => x.modo_liquidacao?.label).reduce((prev, currentObj) => {
                    let modoInterno = currentObj.modo_liquidacao.label;

                    return {
                        ...prev,
                        [modoInterno]: (prev[modoInterno] || 0) + convertValueToFloat(currentObj.valor)
                    }
                }, {})
            } else {
                if (conta.parcelado == false) {
                    listaModos = conta?.lancamento_movimento_liquidacao.filter(x => x.modo_liquidacao?.label).reduce((prev, currentObj) => {
                        let modoInterno = conta.descricao || conta.categoria?.label;

                        return {
                            ...prev,
                            [modoInterno]: (prev[modoInterno] || 0) + convertValueToFloat(currentObj.valor),
                        }
                    }, {})
                } else {
                    listaModos = conta?.lancamento_movimento_liquidacao_parcelado.filter(x => x.modo_liquidacao?.label).reduce((prev, currentObj) => {
                        let modoInterno = currentObj.modo_liquidacao.label;

                        return {
                            ...prev,
                            [modoInterno]: (prev[modoInterno] || 0) + convertValueToFloat(currentObj.valor)
                        }
                    }, {})
                }
            }

            Object.keys(listaModos).map(x => {
                listaModos[x] = (soma[x] || 0) + listaModos[x]
            })
            return {
                ...soma,
                // [modo2]: (soma[modo2] || 0) + valor,
                ...listaModos
            };
        }, {})

        for (let modo in objeto_lancamento) {
            resultado.push({ modo_liquidacao: modo, valor_somado: objeto_lancamento[modo] });
        }
        for (let modo in objeto_desconto_lancamento) {
            resultado_despesa.push({ modo_liquidacao: modo, valor_somado: objeto_desconto_lancamento[modo] });
        }


        const valor_total = resultado.reduce((acc, x) => acc + x.valor_somado, 0)
        const valor_total_despesa = resultado_despesa.reduce((acc, x) => acc + x.valor_somado, 0)

        setValorTotalLancamentoFechamento(valor_total)
        setValorTotalDespesasLancamento(valor_total_despesa)

        setDetalhesLancamento(resultado)
        setDespesasLancamento(resultado_despesa)

    }

    const obterAcrescimoRetencaoLancamento = (detalhesLancamento) => {

        let arrayRet = detalhesLancamento && detalhesLancamento.length > 0 && detalhesLancamento?.filter(x => x?.lancamento_movimento_liquidacao).flatMap(x => x.lancamento_movimento_liquidacao.flatMap(y => y.retencoes_acrescimos))


        let aggRetencoes = arrayRet && arrayRet?.reduce((agrupado, transacao) => {
            // agrupado["acrescimo"] = (agrupado["acrescimo"] || 0) + (transacao?.acrescimo || 0)
            agrupado["desconto"] = (agrupado["desconto"] || 0) + (convertValueToFloat(transacao?.desconto) || 0)
            // agrupado["juros_multas"] = (agrupado["juros_multas"] || 0) + (transacao?.juros_multas || 0)
            agrupado["imposto_nfse"] = (agrupado["imposto_nfse"] || 0) + (convertValueToFloat(transacao?.imposto_nfse) || 0)
            agrupado["glosas"] = (agrupado["glosas"] || 0) + (transacao?.glosas || 0)
            agrupado["outros_impostos"] = (agrupado["outros_impostos"] || 0) + (convertValueToFloat(transacao?.outros_impostos) || 0)
            agrupado["total_retencao"] = (agrupado["total_retencao"] || 0) + (convertValueToFloat(transacao?.total_retencao) || 0)
            return agrupado;
        }, {});
        const aggKeysRet = Object.keys(aggRetencoes);

        const desconto = parseFloat(aggRetencoes['desconto'])
        const imposto_nfse = parseFloat(aggRetencoes['imposto_nfse'])
        const glosas = parseFloat(aggRetencoes['glosas'])
        const outros_impostos = parseFloat(aggRetencoes['outros_impostos'])

        let total = (desconto + glosas + imposto_nfse + outros_impostos).toFixed(2)

        setValorTotalRetencoesLancamento(total)

        aggRetencoes && delete aggRetencoes.total_retencao

        setAcrescimoRetencaoLancamento({ keys: aggKeysRet, retencoes: aggRetencoes })

    }


    const obterDetalhesReceita = (detalhesReceita) => {

        let resultado = [];

        let objeto_receita = detalhesReceita && detalhesReceita.length > 0 &&
            detalhesReceita.filter(x => x.forma_receita != undefined && x.valor_receita != undefined).reduce((soma, conta) => {
                let modo = conta.forma_receita.label;
                let valor = parseFloat(conta.valor_receita);

                return {
                    ...soma,
                    [modo]: (soma[modo] || 0) + valor
                };
            }, {})

        for (let modo in objeto_receita) {
            resultado.push({ forma_receita: modo, valor_receita: objeto_receita[modo] });
        }

        const valor_total = resultado.reduce((acc, x) => acc + x.valor_receita, 0)

        setDetalhesValoresReceita(resultado)
        setValorTotalReceitaFechamento(valor_total)

    }

    const obterDetalhesRetencao = (detalhesRetencao) => {

        let resultado = [];

        let objeto_retencao = detalhesRetencao && detalhesRetencao.length > 0 &&
            detalhesRetencao.filter(x => x.forma_retencao != undefined && x.valor_retencao != undefined).reduce((soma, conta) => {
                let modo = conta.forma_retencao.label;
                let valor = parseFloat(conta.valor_retencao);

                return {
                    ...soma,
                    [modo]: (soma[modo] || 0) + valor
                };
            }, {})



        for (let modo in objeto_retencao) {
            resultado.push({ forma_retencao: modo, valor_retencao: objeto_retencao[modo] });
        }

        const valor_total = resultado.reduce((acc, x) => acc + x.valor_retencao, 0)

        setDetalhesValoresRetencao(resultado)
        setValorTotalRetencaoFechamento(valor_total)


    }

    useEffect(() => {
        obterDetalhesLancamento(props.dadosMovimentoLancamento)
    }, [props.dadosMovimentoLancamento])

    useEffect(() => {
        obterAcrescimoRetencaoLancamento(props.dadosMovimentoLancamento)
    }, [props.dadosMovimentoLancamento])

    useEffect(() => {
        obterDetalhesReceita(propsFormik.values.receita)
    }, [propsFormik.values.receita])

    useEffect(() => {
        obterDetalhesRetencao(propsFormik.values.retencao)
    }, [propsFormik.values.retencao])


    useEffect(() => {
        calcularTotalGeral()
    }, [propsFormik.values.receita, propsFormik.values.retencao])


    const calcularTotalGeral = () => {

        let valoresReceita = propsFormik && propsFormik?.values?.receita?.filter(x => x.valor_receita != undefined)
        let valoresRetencao = propsFormik && propsFormik?.values?.retencao?.filter(x => x.valor_retencao != undefined)


        let valorTotalRecebido = valoresReceita?.reduce((result, obj) => {
            return (result + parseFloat(obj.valor_receita || 0))
        }, 0)

        let valorTotalRetencao = valoresRetencao?.reduce((result, obj) => {
            return (result + parseFloat(obj.valor_retencao || 0))
        }, 0)

        setTotalRecebido(valorTotalRecebido)
    }

    let dados = propsFormik.values


    const dadosMovimento = [
        {
            entidade: dados && dados?.entidade?.label,
            valor_total_recebido: convertValueToFloat(totalRecebido).toFixed(2),
            valor_total_lancamento: valorTotalLancamento || 0,
            valor_verificado: (convertValueToFloat(totalRecebido).toFixed(2) - convertValueToFloat(valorTotalLancamento).toFixed(2)),
        }
    ]

    return (
        <div>
            <div className='card-body'>
                <div className='row'>
                    <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                        <thead>
                            {
                                <tr>
                                    <th style={{ width: '10%', textAlign: 'center' }}>#</th>
                                    <th style={{ width: '24%' }}>Entidade (Empresa/Sócio)</th>
                                    <th style={{ width: '9.5%', }}> Valor Total Recebido (R$)</th>
                                    <th style={{ width: '11%' }}>Valor Total Lançamentos (R$)</th>
                                    <th style={{ width: '8%' }}>Valor Verificado (R$)</th>
                                    <th style={{ width: '14%', textAlign: 'center' }}>Ações</th>

                                </tr>
                            }
                        </thead>
                        <tbody>
                            {
                                dadosMovimento && dadosMovimento.map((item, index) =>
                                    <tr>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{item.entidade}</td>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>{formatarMoeda(item.valor_total_recebido)}</td>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>{formatarMoeda(item.valor_total_lancamento)}</td>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'right' }}> <b><span className={item.valor_verificado == 0 ? 'valor-indicador-certo' : 'valor-indicador-errado'}>{formatarMoeda(item.valor_verificado)}</span></b></td>

                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                            <BtnAcao type={'button'} icone="icon-Check" texto="Fechamento do Movimento Financeiro" action={() => { setShowModalFechamento(true); }} />
                                            <BtnAcao type={'button'} icone="icon-Books-2" texto="Detalhes" action={() => { setShowModalDetalhes(true); obterDetalhes(props, propsFormik) }} />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                </div>

                <div className='fechamento-container'>
                    <div className='tabelas-fechamento'>
                        <div className='valores-recebidos box-liquidacao-parcelado'>
                            <h6 style={{ color: 'black' }}><b>Receita(s)</b></h6>
                            <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                                <thead>
                                    {
                                        <tr>
                                            <th style={{ width: '10%', textAlign: 'center' }}>#</th>
                                            <th style={{ width: '24%' }}>Forma Receita</th>
                                            <th style={{ width: '9.5%', textAlign: 'right' }}> Valor(R$)</th>

                                        </tr>
                                    }
                                </thead>
                                <tbody>
                                    {
                                        detalhesValoresReceita && detalhesValoresReceita.length > 0 ?
                                            detalhesValoresReceita.map((item, index) =>
                                                <tr>
                                                    {/* <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><span className={detalhesLancamento && detalhesLancamento.find(x => x.modo_liquidacao === item.forma_receita)?.valor_somado !== item.valor_receita ? 'valor-indicador-errado' : ''}> {index + 1}</span></td>
                                                    <td style={{ verticalAlign: 'middle' }}><span className={detalhesLancamento && detalhesLancamento.find(x => x.modo_liquidacao === item.forma_receita)?.valor_somado !== item.valor_receita ? 'valor-indicador-errado' : ''}>{item.forma_receita}</span></td>
                                                    <td style={{ verticalAlign: 'middle', textAlign: 'right' }}><span className={detalhesLancamento && detalhesLancamento.find(x => x.modo_liquidacao === item.forma_receita)?.valor_somado !== item.valor_receita ? 'valor-indicador-errado' : ''}>{formatarMoeda(item.valor_receita)}</span></td>
                                                     */}
                                                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><span className={detalhesLancamento && convertValueToFloat(detalhesLancamento.find(x => x.modo_liquidacao === item.forma_receita)?.valor_somado).toFixed(2) !== convertValueToFloat(item.valor_receita).toFixed(2) ? 'valor-indicador-errado' : ''}> {index + 1}</span></td>
                                                    <td style={{ verticalAlign: 'middle' }}><span className={detalhesLancamento && convertValueToFloat(detalhesLancamento.find(x => x.modo_liquidacao === item.forma_receita)?.valor_somado).toFixed(2) !== convertValueToFloat(item.valor_receita).toFixed(2) ? 'valor-indicador-errado' : ''}>{item.forma_receita}</span></td>
                                                    <td style={{ verticalAlign: 'middle', textAlign: 'right' }}><span className={detalhesLancamento && convertValueToFloat(detalhesLancamento.find(x => x.modo_liquidacao === item.forma_receita)?.valor_somado).toFixed(2) !== convertValueToFloat(item.valor_receita).toFixed(2) ? 'valor-indicador-errado' : ''}>{formatarMoeda(item.valor_receita)}</span></td>
                                                </tr>
                                            ) :
                                            <tr className='tr-data'>
                                                <td colSpan={3} className='text-center td-data'><span>Não há Receita</span> </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                            {
                                detalhesValoresReceita && detalhesValoresReceita.length > 0 ?
                                    <div className='flex-direita-margin-right'>
                                        <span ><b style={{ color: 'black' }} className={convertValueToFloat(valorTotalReceitaFechamento).toFixed(2) - convertValueToFloat(valorTotalLancamentoFechamento).toFixed(2) !== 0 ? 'valor-indicador-errado' : 'valor-indicador-certo'} >Total: {formatMoeda(valorTotalReceitaFechamento)}</b></span>
                                    </div>
                                    : <></>
                            }
                        </div>

                    </div>


                    <div className='tabelas-fechamento'>
                        <div ref={refFechamento}>
                            <div className='valores-recebidos box-liquidacao-parcelado'>
                                <h6 style={{ color: 'black' }}><b>Lançamento(s)</b></h6>
                                <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                                    <thead>
                                        {
                                            <tr>
                                                <th style={{ width: '10%', textAlign: 'center' }}>#</th>
                                                <th style={{ width: '24%' }}>Modo Liquidação</th>
                                                {/* <th style={{ width: '24%' }}>Data Liquidacao</th> */}
                                                <th style={{ width: '9.5%', textAlign: 'right' }}>Valor(R$)</th>

                                            </tr>
                                        }
                                    </thead>
                                    <tbody>
                                        {
                                            detalhesLancamento && detalhesLancamento.length > 0 ?
                                                detalhesLancamento.map((item, index) =>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><span className={detalhesValoresReceita && convertValueToFloat(detalhesValoresReceita.find(x => x.forma_receita === item.modo_liquidacao)?.valor_receita).toFixed(2) !== convertValueToFloat(item.valor_somado).toFixed(2) ? 'valor-indicador-errado' : ''}>{index + 1}</span></td>
                                                        <td style={{ verticalAlign: 'middle' }}><span className={detalhesValoresReceita && convertValueToFloat(detalhesValoresReceita.find(x => x.forma_receita === item.modo_liquidacao)?.valor_receita).toFixed(2) !== convertValueToFloat(item.valor_somado).toFixed(2) ? 'valor-indicador-errado' : ''}>{item.modo_liquidacao}</span></td>
                                                        <td style={{ verticalAlign: 'middle', textAlign: 'right' }}><span className={detalhesValoresReceita && convertValueToFloat(detalhesValoresReceita.find(x => x.forma_receita === item.modo_liquidacao)?.valor_receita).toFixed(2) !== convertValueToFloat(item.valor_somado).toFixed(2) ? 'valor-indicador-errado' : ''}>{formatarMoeda(item.valor_somado)}</span></td>
                                                    </tr>
                                                ) :
                                                <tr className='tr-data'>
                                                    <td colSpan={3} className='text-center td-data'><span>Não há Lançamentos</span> </td>
                                                </tr>
                                        }
                                    </tbody>

                                </table>
                                <div>

                                    {
                                        detalhesLancamento && detalhesLancamento.length > 0 ?
                                            <div className='flex-direita-margin-right'>

                                                <span ><b style={{ color: 'black' }} className={convertValueToFloat(valorTotalReceitaFechamento).toFixed(2) - convertValueToFloat(valorTotalLancamentoFechamento).toFixed(2) !== 0 ? 'valor-indicador-errado' : 'valor-indicador-certo'} >Total: {formatMoeda(valorTotalLancamentoFechamento)}</b></span>

                                            </div>
                                            : <></>
                                    }
                                    <span style={{ fontSize: 12, marginTop: 20, display: 'flex', textAlign: 'left' }}>{diferencaModoLiquidacao}</span>
                                </div>
                            </div>
                            {/* 
                            <Overlay show={show} target={refFechamento} placement={'bottom'}>
                                <Popover className='tooltip-input-modal'>
                                    <Popover.Title as="h3">Atenção:</Popover.Title>
                                    <Popover.Content>
                                        {diferencaModoLiquidacao}
                                    </Popover.Content>
                                </Popover>
                            </Overlay> */}
                        </div>

                    </div>

                    <div className='tabelas-fechamento'>
                        <div className='valores-recebidos box-liquidacao-parcelado'>
                            <h6 style={{ color: 'black' }}><b>Retenção(ões)</b></h6>
                            <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                                <thead>
                                    {
                                        <tr>
                                            <th style={{ width: '10%', textAlign: 'center' }}>#</th>
                                            <th style={{ width: '24%' }}>Forma Retenção</th>
                                            <th style={{ width: '9.5%', textAlign: 'right' }}> Valor(R$)</th>

                                        </tr>
                                    }
                                </thead>
                                <tbody>
                                    {
                                        detalhesValoresRetencao && detalhesValoresRetencao.length > 0 ?
                                            detalhesValoresRetencao.map((item, index) =>
                                                <tr>
                                                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{item.forma_retencao}</td>
                                                    <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>{formatarMoeda(item.valor_retencao)}</td>
                                                </tr>
                                            ) :
                                            <tr className='tr-data'>
                                                <td colSpan={3} className='text-center td-data'><span>Não há Retenções</span> </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                            {
                                detalhesValoresRetencao && detalhesValoresRetencao.length > 0 ?
                                    <div className='flex-direita-margin-right'>
                                        <span ><b style={{ color: 'black' }} className={convertValueToFloat(valorTotalRetencaoFechamento).toFixed(2) - convertValueToFloat(valorTotalRetencoesLancamento).toFixed(2) !== 0 ? 'valor-indicador-errado' : 'valor-indicador-certo'} >Total: {formatMoeda(valorTotalRetencaoFechamento)}</b></span>
                                    </div>
                                    : <></>
                            }
                        </div>

                    </div>


                    <div className='tabelas-fechamento'>
                        <div className='valores-recebidos box-liquidacao-parcelado'>
                            <h6 style={{ color: 'black' }}><b>Retenção(ões) Lancamento(s)</b></h6>
                            <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                                <thead>
                                    {
                                        <tr>
                                            <th style={{ width: '10%', textAlign: 'center' }}>#</th>
                                            <th style={{ width: '24%' }}>Tipo Retenção/Acrescimo </th>
                                            {/* <th style={{ width: '24%' }}>Data Liquidacao</th> */}
                                            <th style={{ width: '9.5%', textAlign: 'right' }}>Valor(R$)</th>

                                        </tr>
                                    }
                                </thead>
                                <tbody>
                                    {
                                        acrescimoRetencaoLancamento && acrescimoRetencaoLancamento.keys?.length > 0 ?
                                            acrescimoRetencaoLancamento.keys.filter(name => acrescimoRetencaoLancamento.retencoes[name] > 0).map((item, index) =>
                                                <tr>
                                                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{RETENCOES[item]}</td>
                                                    <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>{formatarMoeda(acrescimoRetencaoLancamento.retencoes[item])}</td>
                                                </tr>
                                            ) :
                                            <tr className='tr-data'>
                                                <td colSpan={3} className='text-center td-data'><span>Não há Lançamentos</span> </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>

                            {
                                acrescimoRetencaoLancamento && acrescimoRetencaoLancamento.keys?.length > 0 ?
                                    <div className='flex-direita-margin-right'>
                                        <span><b style={{ color: 'black' }} className={convertValueToFloat(valorTotalRetencaoFechamento || 0).toFixed(2) - convertValueToFloat(valorTotalRetencoesLancamento || 0).toFixed(2) !== 0 ? 'valor-indicador-errado' : 'valor-indicador-certo'} >Total: {formatMoeda(convertValueToFloat(valorTotalRetencoesLancamento || 0))}</b></span>
                                    </div>
                                    : <></>
                            }
                        </div>

                    </div>
                    <div className='fechamento-container-personalizado ' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div className='tabelas-fechamento'>
                            <div ref={refFechamento}>
                                <div className='valores-recebidos box-liquidacao-parcelado'>
                                    <h6 style={{ color: 'black' }}><b>Retenção(ões) Débito(s)</b></h6>
                                    <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                                        <thead>
                                            {
                                                <tr>
                                                    <th style={{ width: '10%', textAlign: 'center' }}>#</th>
                                                    <th style={{ width: '24%' }}>Tipo de Pagamento</th>
                                                    <th style={{ width: '9.5%', textAlign: 'right' }}>Valor(R$)</th>

                                                </tr>
                                            }
                                        </thead>
                                        <tbody>
                                            {console.log('despesasLancamento: ', despesasLancamento)}
                                            {
                                                despesasLancamento && despesasLancamento.length > 0 ?
                                                    despesasLancamento.map((item, index) =>
                                                        <tr>
                                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><span>{index + 1}</span></td>
                                                            <td style={{ verticalAlign: 'middle' }}><span>{item.modo_liquidacao}</span></td>
                                                            <td style={{ verticalAlign: 'middle', textAlign: 'right' }}><span>{formatarMoeda(item.valor_somado)}</span></td>
                                                        </tr>
                                                    ) :
                                                    <tr className='tr-data'>
                                                        <td colSpan={3} className='text-center td-data'><span>Não há Despesas</span> </td>
                                                    </tr>
                                            }
                                        </tbody>

                                    </table>
                                    <div>

                                        {
                                            despesasLancamento && despesasLancamento.length > 0 ?
                                                <div className='flex-direita-margin-right'>

                                                    <span ><b style={{ color: 'black' }} >
                                                        Total: {formatMoeda(valorTotalDespesasLancamento)}</b></span>

                                                </div>
                                                : <></>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <FechamentoModal titulo={'Fechamento do Movimento Financeiro'} showModal={showModalFechamento} setShowModal={setShowModalFechamento} propsFormik={propsFormik} dadosFechamento={dadosMovimento} totalLancamento={valorTotalLancamento || 0}
                aplicarValor acaoSalvar={(valuesFechamento) => {
                    propsFormik.setFieldValue('fechamento', valuesFechamento)
                }} valoresIniciais={propsFormik.values.fechamento} totalRecebido={totalRecebido} />
            <DetalhesModal titulo={'Detalhes'} data={itemDetalhes} showModal={showModalDetalhes} setShowModal={setShowModalDetalhes} />
        </div >
    )
}

const FechamentoModal = ({ propsFormik, ...props }) => {
    const [showModal, setShowModal] = useState(false)
    const [msgValidacao, setMsgValidacao] = useState('')

    let { id } = useParams()

    const validarSubmit = (values, propsFormik2) => {

        setSalvando(true)

        if (props.acaoSalvar) {
            props.acaoSalvar(values)
        }

        if (props.aplicarValor === true) {

            propsFormik.values.fechamento = values

            values.valor_total_recebido = props.totalRecebido
            values.valor_total_lancamento = props.totalLancamento
            values.fechamento_alteracao = true
            validar(`/financeiro/validarfechamento`, { id: props.rowId || id, ...values }, () => Submit(values, propsFormik2), (e) => {
                const alertas = e.response.data.alertas;
                setMsgValidacao(<><div dangerouslySetInnerHTML={{ __html: alertas.map(x => x.replace(/"/g, '') + '<br/><br/>').join('') }} /></>)
                setShowModal(true)
                setSalvando(false)
            })


            return;
        }


        if (!props.rowId) values.totalRecebido = parseFloat(props.totalRecebido || 0).toFixed(2)

        validar(`/financeiro/validarfechamento`, { id: props.rowId || id, ...values }, () => Submit(values, propsFormik2), (e) => {
            const alertas = e.response.data.alertas;
            setMsgValidacao(<><div dangerouslySetInnerHTML={{ __html: alertas.map(x => x.replace(/"/g, '') + '<br/><br/>').join('') }} /></>)
            setShowModal(true)
            setSalvando(false)
        })

    }


    const [salvando, setSalvando] = useState(false)

    const valoresIniciais = props.valoresIniciais || { status: !props.rowId ? { value: 3, label: 'FECHADO' } : undefined, anotacoes: '', data_status: new moment().format('DD/MM/YYYY') }

    const Submit = (values, propsFormik) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: 114,
            acao_id: ACOES.FECHAR_MOVIMENTO_FINANCEIRO
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {

            if (val.length) {
                setSalvando(true)
                putComAcao(ACOES.FECHAR_MOVIMENTO_FINANCEIRO, 114, { id: props.rowId ? props.rowId : id, ...values }, '/financeiro/fechamento',
                    () => {
                        if (props.rowId) props.target.current.atualizar()
                        mostrarToast('sucesso', mensagens.sucesso)
                        propsFormik.resetForm({})
                        props.setShowModal(false)
                        setSalvando(false)

                    }, (err) => {
                        mostrarToast('erro', mensagens.erro);
                        setSalvando(false)
                    })
            }
            else {
                mostrarToast('erro', 'O usuário não possui permissão para esta ação! Contate um administrador.')
            }

        }).catch((e) => {
            mostrarToast('erro', mensagens['erro'])
        })
    }

    return (
        <>
            <ModalCadastro titulo={props.titulo} show={props.showModal} setShowModal={props.setShowModal}>
                <Formik
                    initialValues={valoresIniciais}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <div className={'form-group col-12'}>
                                        {<>
                                            <div>
                                                <InputStatusMovimentoFinanceiro
                                                    name='status'
                                                    label={<b>Status</b>}
                                                    placeholder='Selecione...'
                                                    propsFormik={propsFormik}
                                                    obrigatorio
                                                    isDisabled={!props.rowId}
                                                    tamanho={'col-lg-12 p-0'}
                                                    autoFocus
                                                    onChange={(option) => {
                                                        propsFormik.setFieldValue('status', option)

                                                    }}

                                                />
                                                <TextArea
                                                    label={'Anotações'}
                                                    name='anotacoes'
                                                    type='text'
                                                    placeholder='Anotações'
                                                    tamanho={!props.modal && 'col-lg-12 p-0'}
                                                    rows={4}
                                                    classeTooltip={props.modal && 'tooltip-input-modal'}
                                                />

                                                <InputDatePicker
                                                    label='Data'
                                                    name='data_status'
                                                    placeholder='Data'
                                                    tamanho={'col-lg-12 p-0'}
                                                    propsFormik={propsFormik}
                                                    setarValor={value => {
                                                        propsFormik.setFieldValue("data_status", value)
                                                    }} />

                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>
                                <ModalDuasOpcoes showModal={showModal} setShowModal={setShowModal} funcaoOpcao2={_ => { setShowModal(false); Submit(propsFormik.values, propsFormik) }}
                                    funcaoOpcao1={_ => { setSalvando(false); setShowModal(false) }}
                                    esconderFechar mensagem={<>{msgValidacao}<br />{'Deseja Continuar?'} </>}
                                    textoOpcao1={'Cancelar'}
                                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                                    textoOpcao2={'Continuar'} />
                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={'salvar'} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    )
}

const DetalhesModal = (props) => {

    const [itemDetalhes, setItemDetalhes] = useState()

    useEffect(() => {
        setItemDetalhes(props.data)
    }, [props.data])

    return (
        <>
            <ModalPadraoCadastro titulo={'Histórico de Status'} showModal={props.showModal} setShowModal={props.setShowModal}>
                <div>
                    {/* {
                        itemDetalhes && itemDetalhes.mf_historico_status && itemDetalhes.mf_historico_status.length > 0 ?
                            <div className="modal-header">
                                <h5 className="modal-title"><span>Histórico de Status</span></h5>
                            </div>
                            : <></>
                    } */}
                    <div>
                        <ul className='lista-modal'>
                            {
                                itemDetalhes && itemDetalhes.mf_historico_status && itemDetalhes.mf_historico_status.map(item => <li className="mb-1">
                                    <div className='col-hora row-space-between'>
                                        <span className={'tag'} style={{ backgroundColor: item.cor, color: 'white' }}>{item.descricao}</span> <span className='span-hora'>{item.data_registro}</span>
                                    </div>
                                    {
                                        item.data_fechamento ?
                                            <div className='mt-2'>
                                                <i><p><b>{item.data_fechamento}</b></p></i>
                                            </div>
                                            :
                                            <></>
                                    }
                                    <div className='mt-2'>
                                        <i><p>{item.anotacoes}</p></i>
                                    </div>
                                    <div className='col-hora row-space-between'>
                                        <span></span>
                                        <span className='span-nome'><b>{`${(item.usuario || item.usuario_app) ? (item.usuario || item.usuario_app).toUpperCase() : ''}`}</b></span>
                                    </div>
                                </li>)
                            }
                        </ul>
                    </div>
                </div>
            </ModalPadraoCadastro >
        </>
    )
}




export { ValorRecebido, Fechamento, FechamentoModal, DetalhesModal }