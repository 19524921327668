import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup from '../../../../util/Validacoes';
import { InputPadrao, InputTelefone, InputTimePicker } from '../../../../components/Inputs'
import { InputEstabelecimentoSaude, InputCBOS, InputEstado, InputCro } from '../../../../components/InputsAutoComplete'
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { useParams } from "react-router-dom";
import { get, salvar } from '../../../../util/AcessoApi'
import { mensagens, ajustarDadosTelefone } from '../../../../util/Utilitarios'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { removeAcentos, removerCaracteresEspeciais } from '../../../../util/FuncoesComuns'
import { useField, useFormikContext } from "formik";
import Atalho from '../../../../util/Atalho'
import ls from 'local-storage'


const manter = ['cirurgiaoEstabelecimento']

const Submit = (values, setIsSubmit, history, telefones, salvarNovoObj) => {
    values.telefone = telefones.length > 0 ? telefones : values.telefone;
    ajustarDadosTelefone(values.telefone)
    setIsSubmit(true)
    values.nome_cirurgiao_ajustado = removerCaracteresEspeciais(removeAcentos(values.nome_cirurgiao)).toUpperCase()
    values.nome_cirurgiao = removerCaracteresEspeciais(values.nome_cirurgiao)
    salvar(values, '/administracao/cirurgiao', (ret) => {
        if (salvarNovoObj.props.modal) {        
            if (salvarNovoObj.salvarENovo) {
                salvarNovoObj.propsFormik.resetForm({})
                salvarNovoObj.setDataTelefone([])
                manter.forEach(x => salvarNovoObj.propsFormik.setFieldValue(x, values[x]))
                setIsSubmit(false)
                salvarNovoObj.setSalvarENovo(false)
            }
            else {
                let option = { value: ret.id, label: ret.cro + " " + ret.crm + ' - ' + ret.nome_cirurgiao_ajustado}
                let arrayCirurgiao = ls.get("cache_cirurgiao")
                arrayCirurgiao.push(option)
                ls.set('cache_cirurgiao', arrayCirurgiao )
                salvarNovoObj.props.setItemSelecionado({ value: ret.id, label: ret.cro + " " + ret.crm + ' - ' + ret.nome_cirurgiao_ajustado})
                salvarNovoObj.props.setShow && salvarNovoObj.props.setShow(false)
            }
        } else {
            mostrarToast('sucesso', mensagens['sucesso'])
            if (salvarNovoObj.salvarENovo) {
                salvarNovoObj.propsFormik.resetForm({})
                salvarNovoObj.setDataTelefone([])
                manter.forEach(x => salvarNovoObj.propsFormik.setFieldValue(x, values[x]))
                setIsSubmit(false)
                salvarNovoObj.setSalvarENovo(false)
            }
            else
                history.push('/administracao/cirurgiao/consulta')
        }
    }, () => {
        setIsSubmit(false)
    })
}

const links = [
    () => <Link to={'/dashboard'}>Dashboard</Link>,
    () => <span>Administrativo</span>,
    () => <Link className='link-active' to={'/administracao/cirurgiao/consulta'}>Cirurgião</Link>,
]

const CadastroCirurgiao = (props) => {
    let { id } = useParams();
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [dataTelefone, setDataTelefone] = useState([])
    const [salvarENovo, setSalvarENovo] = useState(false)

    let validacaoInicial = Yup.object({
        crm: Yup.string()
            .required().trim()
            .max(200),
        rqe: Yup.string().trim()
            .max(200).nullable(),
        apelido: Yup.string()
            .trim().nullable()
            .max(200),
        nome_cirurgiao: Yup.string()
            .required().trim()
            .max(200),
        cirurgiaoCbos: Yup.string()
            .nullable()
            .max(200),
        telefone: Yup.string(),
        estado: Yup.string()
            .required().nullable()
            .max(200),
        cirurgiaoEstabelecimento: Yup.string()
            .max(200)
            .nullable(),
        cro: Yup.string().required()
            .max(200)
            .nullable()
    })

    const { history } = props

    useEffect(() => {
        if (!props.modal && id) {
            Promise.resolve(get(`/administracao/cirurgiao/${id}`)).then(function (obj) {
                if (id && !obj.nome_cirurgiao) {
                    history.push('/administracao/cirurgiao/consulta')
                }

                setDataTelefone(obj.telefone)
                delete obj.telefone
                obj.telefone = ""
                setValoresIniciais(obj)
            }).catch((e) => {
            });
        } else {
            let obj = {
                value: "GO",
                label: "Goiás"
            }
            delete obj.telefone
            obj.telefone = ""
            setValoresIniciais({
                crm: "",
                estado: obj,
                apelido: "",
                nome_cirurgiao: "",
                cirurgiaoCbos: "",
                telefone: "",
                cirurgiaoEstabelecimento: ""
            })
        }
    }, [history, id])

    useEffect(() => {
        // document.onkeyup = function(e) {
        //     if (e.which == 77) {
        //       alert("M key was pressed");
        //     } else if (e.ctrlKey && e.which == 83) {
        //       alert("Ctrl + S shortcut combination was pressed");
        //     } else if (e.ctrlKey && e.altKey && e.which == 89) {
        //       alert("Ctrl + Alt + Y shortcut combination was pressed");
        //     } else if (e.ctrlKey && e.altKey && e.shiftKey && e.which == 85) {
        //       alert("Ctrl + Alt + Shift + U shortcut combination was pressed");
        //     }
        //   };
        document.addEventListener("keydown", function (e) {
            if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                e.preventDefault();
                //   alert("SALVAR");
            }
        }, false);
    }, [])

    if (valoresIniciais.nome_cirurgiao == undefined)
        return <></>
    return (
        <>
            {!props.modal && <HeaderCadastro titulo={"Cadastro de Cirurgião"} link={links} />}
            <Formik
                initialValues={valoresIniciais}
                validationSchema={validacaoInicial}
                enableReinitialize={true}
                onSubmit={(values, propsFormik) => {
                    const salvarNovoObj = {
                        setSalvarENovo,
                        salvarENovo,
                        propsFormik,
                        setDataTelefone,
                        props
                    }
                    Submit(values, setIsSubmit, history, dataTelefone, salvarNovoObj)
                }}>
                {propsFormik => (
                    <Form>
                        <div className={props.modal && props.modal || 'card mb-20'}>
                            <div className={props.modal || 'card-body'}>
                                <div className="row">
                                    <InputPadrao tamanho="col-0" label="Id" name="id" type="hidden" placeholder="Id" obrigatorio="false" />
                                    <InputPadrao tamanho={!props.modal && 'col-sm-12 col-lg-7 col-xl-7'} classeTooltip={props.modal && 'tooltip-input-modal'}
                                        label="Nome Cirurgião" name="nome_cirurgiao" type="text" placeholder="Nome Cirurgião" obrigatorio="true" autoFocus salvarENovo={salvarENovo} />
                                    <InputPadrao tamanho={!props.modal && 'col-sm-12 col-lg-5 col-xl-5'} classeTooltip={props.modal && 'tooltip-input-modal'} label="Apelido"
                                        name="apelido" type="text" placeholder="Apelido"/>
                                    <InputCro tamanho={!props.modal && 'col-sm-12 col-lg-4 col-xl-4'} classeTooltip={props.modal && 'tooltip-input-modal'} label="Conselho Profissional" name="cro"
                                        placeholder="Conselho Profissional" obrigatorio="true" propsFormik={propsFormik} />
                                    <InputPadrao tamanho={!props.modal && 'col-sm-12 col-lg-3 col-xl-3'} classeTooltip={props.modal && 'tooltip-input-modal'} label="N° Conselho" name="crm"
                                        type="text" placeholder="N° Conselho" obrigatorio="true" PropsFormik={propsFormik} />
                                    <InputEstado tamanho={!props.modal && 'col-sm-12 col-lg-3 col-xl-3'} classeTooltip={props.modal && 'tooltip-input-modal'} label="UF" name="estado"
                                        placeholder="Estado" obrigatorio="true" propsFormik={propsFormik} />
                                    <InputPadrao tamanho={!props.modal && 'col-sm-12 col-lg-2 col-xl-2'} classeTooltip={props.modal && 'tooltip-input-modal'} label="RQE" name="rqe"
                                        type="text" placeholder="RQE" />
                                    <InputCBOS tamanho={!props.modal && 'col-sm-12 col-lg-4 col-xl-4'} classeTooltip={props.modal && 'tooltip-input-modal'} label="CBOS" name="cirurgiaoCbos"
                                        placeholder="CBOS" propsFormik={propsFormik} multiplo={true} />
                                    <InputTelefone tamanho={!props.modal && 'col-sm-12 col-lg-2 col-xl-2'} classeTooltip={props.modal && 'tooltip-input-modal'}
                                        label="Telefone" name="telefone" type="text" placeholder="Telefone" data={dataTelefone} setData={setDataTelefone} />
                                    <InputEstabelecimentoSaude tamanho={!props.modal && 'col-sm-12 col-lg-6 col-xl-6'} classeTooltip={props.modal && 'tooltip-input-modal'}
                                        label="Estabelecimento(s) de Saúde" name="cirurgiaoEstabelecimento" placeholder="Estabelecimento de Saúde" propsFormik={propsFormik}
                                        multiplo={true} />
                                    <Atalho formik={propsFormik} salvando={isSubmit} setSalvareNovo={setSalvarENovo} salvarENovo={true}/>
                                </div>
                            </div>
                        </div>
                        {!props.modal && <div className='separator mt-40 mb-5'></div>}
                        <div className='direita container-botoes mb-3 group-salvar-novo'>
                            <BotaoPadrao texto='Salvar' disabled={isSubmit} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                            {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={isSubmit} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default CadastroCirurgiao