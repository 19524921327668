import React, { useCallback, useEffect, useState } from 'react';
import { convertValueToFloat, formatarMoeda } from '../../util/Utilitarios';
import { TableVirtuoso } from 'react-virtuoso';
import MensagemPadraoRelatorio from '../../components/RelatorioEspecifico/RelatorioSemDados'
import { BrowserRouter, Link } from 'react-router-dom'
import { useSortBy } from 'react-table';
import { getDistinctPropertyValues, limitarCaracteres } from '../../util/FuncoesComuns';

const relatorioEstabelecimentoEspecifico = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {
    const limitarCaracteres = (str, limite) => {
        if (str && str?.length <= limite) {
            return str;
        } else if (str && str.length) {
            return str.slice(0, limite) + "...";
        }

        return
    }

    return (
        array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {
            const idxLinha = arrayConfigCol[idx].findIndex(y => y.coluna === '#');
            const idxCodigo = arrayConfigCol[idx].findIndex(y => y.coluna === 'codigo');
            const idxCodBarras = arrayConfigCol[idx].findIndex(y => y.coluna === 'cod_barras');
            const idxData = arrayConfigCol[idx].findIndex(y => y.coluna === 'data');
            const idxHoraProc = arrayConfigCol[idx].findIndex(y => y.coluna === 'hora_proc');
            const idxPaciente = arrayConfigCol[idx].findIndex(y => y.coluna === 'paciente');
            const idxEstabelecimentoSaude = arrayConfigCol[idx].findIndex(y => y.coluna === 'estabelecimento_saude');
            const idxOperadoraSaude = arrayConfigCol[idx].findIndex(y => y.coluna === 'operadora_saude');
            const idxStatus = arrayConfigCol[idx].findIndex(y => y.coluna === 'status');

            var estabelecimentos = getDistinctPropertyValues(x.data, 'estabelecimento_id');
            var valorTotalPagamentoGeral = x.data.reduce((total, guia) => {
                const totalGuia = guia.pagamentos.reduce((subtotal, pagamento) => {
                    return subtotal + pagamento.valor_pagamento;
                }, 0);
                return total + totalGuia;
            }, 0);

            var valorTotalProcedimentoGeral = x.data.reduce((total, guia) => {
                const totalGuia = guia.procedimentos.reduce((subtotal, procedimento) => {
                    return subtotal + procedimento.valor_total;
                }, 0);
                return total + totalGuia;
            }, 0);

            return (
                <>
                    <div className='delete-starts' />
                    {
                        estabelecimentos.map(estab_id => {
                            var arrayGuias = x.data?.filter(guia => guia.estabelecimento_id == estab_id)

                            var valorTotalPagamento = arrayGuias.reduce((total, guia) => {
                                const totalGuia = guia.pagamentos.reduce((subtotal, pagamento) => {
                                    return subtotal + pagamento.valor_pagamento;
                                }, 0);
                                return total + totalGuia;
                            }, 0);

                            var valorTotalProcedimento = arrayGuias.reduce((total, guia) => {
                                const totalGuia = guia.procedimentos.reduce((subtotal, procedimento) => {
                                    return subtotal + procedimento.valor_total;
                                }, 0);
                                return total + totalGuia;
                            }, 0);

                            return <>
                                <h6>{arrayGuias[0].estabelecimento_saude}</h6>
                                {arrayGuias.map((item, index) => {
                                    const valorTotalProcedimento = item.procedimentos.filter(item => item.valor_total).map(x => parseFloat(x.valor_total)).reduce((ant, atual) => ant + atual, 0);
                                    const valorTotalPagamento = item.pagamentos.filter(item => item.valor_pagamento).map(x => parseFloat(x.valor_pagamento)).reduce((ant, atual) => ant + atual, 0);

                                    return (
                                        <>
                                            {index > 0 ? htmlHeader : <></>}
                                            <table className='table-agrupamento mb-2'>

                                                <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                                    {x.colunas && arrayConfigCol[idx][idxLinha]?.visivel ? <td className='text-center bold td-data group-title' width={'1%'}>#</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxCodigo]?.visivel ? <td className='bold td-data text-left' style={{ width: '4%' }}>Código</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxCodBarras]?.visivel ? <td className='bold td-data text-left' style={{ width: '8%' }}>Cód. Barras</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxData]?.visivel ? <td className='bold td-data text-left' style={{ width: '5%' }}>Data</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxHoraProc]?.visivel ? <td className='bold td-data text-left' style={{ width: '7%' }}>Hora Proc.</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxPaciente]?.visivel ? <td className={`bold td-data text-left`} >Paciente</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxPaciente]?.visivel ? <td className={`bold td-data text-left`} >ACM</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxEstabelecimentoSaude]?.visivel ? <td className={`bold td-data text-left`} >Estabelecimento Saúde</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxOperadoraSaude]?.visivel ? <td className={`bold td-data text-left`} >Operadora Saúde</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxStatus]?.visivel ? <td className={`bold td-data text-left`} style={{ width: '15%' }}>Status</td> : <></>}
                                                </tr>
                                                <tr className={'tr-data titulo-1'}>
                                                    {x.colunas && arrayConfigCol[idx][idxLinha]?.visivel ? <td className={` bold td-data text-left`}>{index + 1}</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxCodigo]?.visivel ? <td className={` bold td-data text-left`}>{item.id}</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxCodBarras]?.visivel ? <td className={` bold td-data text-left`}>{item.codigo_barras}</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxData]?.visivel ? <td className={` bold td-data text-left`}>{item.data_procedimento}</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxHoraProc]?.visivel ? <td className={` bold td-data text-left`}>{item.horario}</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxPaciente]?.visivel ? <td className={` bold td-data text-left`}>{item.nome_beneficiario}</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxPaciente]?.visivel ? <td className={` bold td-data text-left`}>{item.acomodacao}</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxEstabelecimentoSaude]?.visivel ? <td className={` bold td-data text-left`}>{limitarCaracteres(item.estabelecimento_saude, 85)}</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxOperadoraSaude]?.visivel ? <td className={` bold td-data text-left`}>{limitarCaracteres(item.operadora_saude, 85)}</td> : <></>}
                                                    {x.colunas && arrayConfigCol[idx][idxStatus]?.visivel ? <td className={` bold td-data text-left`}>{item.status}</td> : <></>}
                                                </tr>
                                            </table>

                                            <table style={{ width: '100%' }} className='table-agrupamento'>

                                                <tr class="group-title color-gray-relatorio" bgcolor='black'>
                                                    <td class="bold coluna color-gray-coluna" width={'5%'}>Procedimento</td>
                                                    <td class="bold coluna color-gray-coluna" width={'45%'}>Descrição</td>
                                                    <td class="bold coluna color-gray-coluna" width={'5%'}>Porte</td>
                                                    <td class="bold coluna color-gray-coluna text-right" width={'10%'}>Valor Porte</td>
                                                    <td class="bold coluna color-gray-coluna" width={'5%'}>ACM</td>
                                                    <td class="bold coluna color-gray-coluna" width={'5%'}>Perc.</td>
                                                    <td class="bold coluna color-gray-coluna" width={'5%'}>Red.Acre.</td>
                                                    <td class="bold coluna color-gray-coluna" width={'5%'}>Urg.</td>
                                                    <td class="bold coluna color-gray-coluna text-right" width={'10%'}>Valor Total</td>
                                                </tr>
                                                {
                                                    item.procedimentos.map((proced, index2) => {
                                                        return (
                                                            <>
                                                                <tr class="tr-data">
                                                                    <td class="td-data ">{proced.codigo}</td>
                                                                    <td class="td-data ">{proced.procedimento}</td>
                                                                    <td class="td-data ">{proced.porte}</td>
                                                                    <td class="td-data text-right ">{formatarMoeda(proced.valor_unitario)}</td>
                                                                    <td class="td-data ">{proced.acomodacao ? proced.acomodacao + '%' : ''}</td>
                                                                    <td class="td-data ">{proced.percentual}</td>
                                                                    <td class="td-data ">{proced.reducao_acrescimo ? proced.reducao_acrescimo + '%' : ''}</td>
                                                                    <td class="td-data ">{proced.urgencia === true ? proced.urgencia_porcentagem + '%' : ''}</td>
                                                                    <td class="td-data text-right">{formatarMoeda(proced.valor_total)}</td>
                                                                </tr>
                                                            </>)
                                                    })
                                                }
                                            </table>

                                            <table>
                                                <tr><td className={'td-data pr-0'}></td></tr>
                                                <tr className={'tr-data text-right bold bt-1'}>
                                                    <td className={'td-data pr-0 text-right'}><span>Valor Total Guia: </span></td>
                                                    <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalProcedimento)}</td>
                                                </tr>
                                            </table>
                                            <table style={{ width: '100%' }} className='table-agrupamento mb-1'>
                                                <tr><td><b>Detalhes(Pagamentos)</b></td></tr>
                                            </table>
                                            <table style={{ width: '100%' }} className='table-agrupamento'>
                                                <tr className='titulo-1'>
                                                    <td className='text-left bold td-data' width={'10%'}>Código</td>
                                                    <td className='text-left bold td-data' width={'60%'}>Tipo</td>
                                                    <td className='text-right bold td-data' width={'15%'}>Data Pagamento</td>
                                                    <td className='text-right bold td-data' width={'15%'}>Valor Pagamento</td>
                                                </tr>

                                                {
                                                    item.pagamentos.map((pag, index3) => {
                                                        return (
                                                            <>
                                                                <tr className={'tr-data bt-1'}>
                                                                    <td className='text-left td-data'>{pag.pagamento_id}</td>
                                                                    <td className='text-left td-data'>{pag.tipo || ''}</td>
                                                                    <td className='text-right td-data'>{pag.data_pagamento}</td>
                                                                    <td className='text-right td-data'>{formatarMoeda(pag.valor_pagamento)}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })

                                                }

                                            </table>

                                            <table className='mb-2'>
                                                <tr><td className={'td-data pr-0'}></td></tr>
                                                <tr className={'tr-data text-right bold bt-1'}>
                                                    <td className={'td-data pr-0 text-right'}><span>Valor Total Recebido: </span></td>
                                                    <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalPagamento)}</td>
                                                </tr>
                                            </table>
                                        </>
                                    )
                                })}

                                <table>
                                    <tr><td className={'td-data pr-0'}></td></tr>
                                    <tr className={'tr-data text-right bold bt-1'}>
                                        <td className={'td-data pr-0 text-right'}><span>Valor Apresentado Estabelecimento: </span></td>
                                        <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalProcedimento)}</td>
                                    </tr>
                                    <tr className={'tr-data text-right bold'}>
                                        <td className={'td-data pr-0 text-right'}><span>Valor Recebido Estabelecimento: </span></td>
                                        <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalPagamento)}</td>
                                    </tr>
                                </table >
                            </>
                        })
                    }
                    <table>
                        <tr><td className={'td-data pr-0'}></td></tr>
                        <tr className={'tr-data text-right bold bt-1 bt-black-1'}>
                            <td className={'td-data pr-0 text-right'}><span>Valor Total Apresentado: </span></td>
                            <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalProcedimentoGeral)}</td>
                        </tr>
                        <tr className={'tr-data text-right bold'}>
                            <td className={'td-data pr-0 text-right'}><span>Valor Total Recebido: </span></td>
                            <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalPagamentoGeral)}</td>
                        </tr>
                    </table>
                    <div className='delete-ends' />
                </>

            )
        }
        )
            :
            <MensagemPadraoRelatorio />
    )
}

const relatorioEstabelecimentoEspecificoVirtuoso = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {
    const limitarCaracteres = (str, limite) => {
        if (str && str?.length <= limite) {
            return str;
        } else if (str && str.length) {
            return str.slice(0, limite) + "...";
        }

        return
    }

    return (
        array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {
            const idxLinha = arrayConfigCol[idx].findIndex(y => y.coluna === '#');
            const idxCodigo = arrayConfigCol[idx].findIndex(y => y.coluna === 'codigo');
            const idxCodBarras = arrayConfigCol[idx].findIndex(y => y.coluna === 'cod_barras');
            const idxData = arrayConfigCol[idx].findIndex(y => y.coluna === 'data');
            const idxHoraProc = arrayConfigCol[idx].findIndex(y => y.coluna === 'hora_proc');
            const idxPaciente = arrayConfigCol[idx].findIndex(y => y.coluna === 'paciente');
            const idxEstabelecimentoSaude = arrayConfigCol[idx].findIndex(y => y.coluna === 'estabelecimento_saude');
            const idxOperadoraSaude = arrayConfigCol[idx].findIndex(y => y.coluna === 'operadora_saude');
            const idxStatus = arrayConfigCol[idx].findIndex(y => y.coluna === 'status');

            var estabelecimentos = getDistinctPropertyValues(x.data, 'estabelecimento_id');

            var valorTotalPagamentoGeral = x.data.reduce((total, guia) => {
                const totalGuia = guia.pagamentos.reduce((subtotal, pagamento) => {
                    return subtotal + pagamento.valor_pagamento;
                }, 0);
                return total + totalGuia;
            }, 0);

            var valorTotalProcedimentoGeral = x.data.reduce((total, guia) => {
                const totalGuia = guia.procedimentos.reduce((subtotal, procedimento) => {
                    return subtotal + procedimento.valor_total;
                }, 0);
                return total + totalGuia;
            }, 0);

            return (
                <>
                    <div className='delete-starts' />
                    {
                        estabelecimentos.map(estab_id => {
                            var arrayGuias = x.data?.filter(guia => guia.estabelecimento_id == estab_id)

                            var valorTotalPagamento = arrayGuias.reduce((total, guia) => {
                                const totalGuia = guia.pagamentos.reduce((subtotal, pagamento) => {
                                    return subtotal + pagamento.valor_pagamento;
                                }, 0);
                                return total + totalGuia;
                            }, 0);

                            var valorTotalProcedimento = arrayGuias.reduce((total, guia) => {
                                const totalGuia = guia.procedimentos.reduce((subtotal, procedimento) => {
                                    return subtotal + procedimento.valor_total;
                                }, 0);
                                return total + totalGuia;
                            }, 0);

                            return <>
                                <h6>{arrayGuias[0].estabelecimento_saude}</h6>
                                <TableVirtuoso
                                    style={{ height: 1000, }}
                                    useWindowScroll
                                    data={arrayGuias}
                                    itemContent={(index, item) => {
                                        const valorTotalProcedimento = item.procedimentos.filter(item => item.valor_total).map(x => parseFloat(x.valor_total)).reduce((ant, atual) => ant + atual, 0);
                                        const valorTotalPagamento = item.pagamentos.filter(item => item.valor_pagamento).map(x => parseFloat(x.valor_pagamento)).reduce((ant, atual) => ant + atual, 0);
                                        console.log('arrayConfigCol[idx]: ', arrayConfigCol[idx]);
                                        return (
                                            <>
                                                {index > 0 ? htmlHeader : <></>}
                                                <table className='table-agrupamento mb-2'>

                                                    <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                                        {x.colunas && arrayConfigCol[idx][idxLinha]?.visivel ? <td className='text-center bold td-data group-title' width={'1%'}>#</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxCodigo]?.visivel ? <td className='bold td-data text-left' style={{ width: '4%' }}>Código</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxCodBarras]?.visivel ? <td className='bold td-data text-left' style={{ width: '8%' }}>Cód. Barras</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxData]?.visivel ? <td className='bold td-data text-left' style={{ width: '5%' }}>Data</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxHoraProc]?.visivel ? <td className='bold td-data text-left' style={{ width: '7%' }}>Hora Proc.</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxPaciente]?.visivel ? <td className={`bold td-data text-left`} >Paciente</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxPaciente]?.visivel ? <td className={`bold td-data text-left`} >ACM</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxEstabelecimentoSaude]?.visivel ? <td className={`bold td-data text-left`} >Estabelecimento Saúde</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxOperadoraSaude]?.visivel ? <td className={`bold td-data text-left`} >Operadora Saúde</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxStatus]?.visivel ? <td className={`bold td-data text-left`} style={{ width: '15%' }}>Status</td> : <></>}
                                                    </tr>
                                                    <tr className={'tr-data titulo-1'}>
                                                        {x.colunas && arrayConfigCol[idx][idxLinha]?.visivel ? <td className={` bold td-data text-left`}>{index + 1}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxCodigo]?.visivel ? <td className={` bold td-data text-left`}>{item.id}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxCodBarras]?.visivel ? <td className={` bold td-data text-left`}>{item.codigo_barras}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxData]?.visivel ? <td className={` bold td-data text-left`}>{item.data_procedimento}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxHoraProc]?.visivel ? <td className={` bold td-data text-left`}>{item.horario}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxPaciente]?.visivel ? <td className={` bold td-data text-left`}>{item.nome_beneficiario}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxPaciente]?.visivel ? <td className={` bold td-data text-left`}>{item.acomodacao}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxEstabelecimentoSaude]?.visivel ? <td className={` bold td-data text-left`}>{limitarCaracteres(item.estabelecimento_saude, 85)}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxOperadoraSaude]?.visivel ? <td className={` bold td-data text-left`}>{limitarCaracteres(item.operadora_saude, 85)}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxStatus]?.visivel ? <td className={` bold td-data text-left`}>{item.status}</td> : <></>}
                                                    </tr>
                                                </table>

                                                <table style={{ width: '100%' }} className='table-agrupamento'>

                                                    <tr class="group-title color-gray-relatorio" bgcolor='black'>
                                                        <td class="bold coluna color-gray-coluna" width={'5%'}>Procedimento</td>
                                                        <td class="bold coluna color-gray-coluna" width={'45%'}>Descrição</td>
                                                        <td class="bold coluna color-gray-coluna" width={'5%'}>Porte</td>
                                                        <td class="bold coluna color-gray-coluna text-right" width={'10%'}>Valor Porte</td>
                                                        <td class="bold coluna color-gray-coluna" width={'5%'}>ACM</td>
                                                        <td class="bold coluna color-gray-coluna" width={'5%'}>Perc.</td>
                                                        <td class="bold coluna color-gray-coluna" width={'5%'}>Red.Acre.</td>
                                                        <td class="bold coluna color-gray-coluna" width={'5%'}>Urg.</td>
                                                        <td class="bold coluna color-gray-coluna text-right" width={'10%'}>Valor Total</td>
                                                    </tr>
                                                    {
                                                        item.procedimentos.map((proced, index2) => {
                                                            return (
                                                                <>
                                                                    <tr class="tr-data">
                                                                        <td class="td-data ">{proced.codigo}</td>
                                                                        <td class="td-data ">{proced.procedimento}</td>
                                                                        <td class="td-data ">{proced.porte}</td>
                                                                        <td class="td-data text-right ">{formatarMoeda(proced.valor_unitario)}</td>
                                                                        <td class="td-data ">{proced.acomodacao ? proced.acomodacao + '%' : ''}</td>
                                                                        <td class="td-data ">{proced.percentual}</td>
                                                                        <td class="td-data ">{proced.reducao_acrescimo ? proced.reducao_acrescimo + '%' : ''}</td>
                                                                        <td class="td-data ">{proced.urgencia === true ? proced.urgencia_porcentagem + '%' : ''}</td>
                                                                        <td class="td-data text-right">{formatarMoeda(proced.valor_total)}</td>
                                                                    </tr>
                                                                </>)
                                                        })
                                                    }
                                                </table>

                                                <table>
                                                    <tr><td className={'td-data pr-0'}></td></tr>
                                                    <tr className={'tr-data text-right bold bt-1'}>
                                                        <td className={'td-data pr-0 text-right'}><span>Valor Total Guia: </span></td>
                                                        <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalProcedimento)}</td>
                                                    </tr>
                                                </table>
                                                <table style={{ width: '100%' }} className='table-agrupamento mb-1'>
                                                    <tr><td><b>Detalhes(Pagamentos)</b></td></tr>
                                                </table>
                                                <table style={{ width: '100%' }} className='table-agrupamento'>
                                                    <tr className='titulo-1'>
                                                        <td className='text-left bold td-data' width={'10%'}>Código</td>
                                                        <td className='text-left bold td-data' width={'60%'}>Tipo</td>
                                                        <td className='text-right bold td-data' width={'15%'}>Data Pagamento</td>
                                                        <td className='text-right bold td-data' width={'15%'}>Valor Pagamento</td>
                                                    </tr>

                                                    {
                                                        item.pagamentos.map((pag, index3) => {
                                                            return (
                                                                <>
                                                                    <tr className={'tr-data bt-1'}>
                                                                        <td className='text-left td-data'>
                                                                            <Link to={{ pathname: '/auditoria/guia-pagamento/visualizacao/' + pag.pagamento_id }} target={'_blank'}>{pag.pagamento_id}</Link>
                                                                        </td>
                                                                        <td className='text-left td-data'>{pag.tipo || ''}</td>
                                                                        <td className='text-right td-data'>{pag.data_pagamento}</td>
                                                                        <td className='text-right td-data'>{formatarMoeda(pag.valor_pagamento)}</td>
                                                                    </tr>


                                                                </>
                                                            )
                                                        })

                                                    }

                                                </table>

                                                <table className='mb-2'>
                                                    <tr><td className={'td-data pr-0'}></td></tr>
                                                    <tr className={'tr-data text-right bold bt-1'}>
                                                        <td className={'td-data pr-0 text-right'}><span>Valor Total Recebido: </span></td>
                                                        <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalPagamento)}</td>
                                                    </tr>
                                                </table>
                                            </>
                                        )
                                    }
                                    }
                                />

                                <table>
                                    <tr><td className={'td-data pr-0'}></td></tr>
                                    <tr className={'tr-data text-right bold bt-1'}>
                                        <td className={'td-data pr-0 text-right'}><span>Valor Apresentado Estabelecimento: </span></td>
                                        <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalProcedimento)}</td>
                                    </tr>
                                    <tr className={'tr-data text-right bold'}>
                                        <td className={'td-data pr-0 text-right'}><span>Valor Recebido Estabelecimento: </span></td>
                                        <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalPagamento)}</td>
                                    </tr>
                                </table>
                            </>
                        })
                    }
                    <table>
                        <tr><td className={'td-data pr-0'}></td></tr>
                        <tr className={'tr-data text-right bold bt-1 bt-black-1'}>
                            <td className={'td-data pr-0 text-right'}><span>Valor Total Apresentado: </span></td>
                            <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalProcedimentoGeral)}</td>
                        </tr>
                        <tr className={'tr-data text-right bold'}>
                            <td className={'td-data pr-0 text-right'}><span>Valor Total Recebido: </span></td>
                            <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalPagamentoGeral)}</td>
                        </tr>
                    </table>
                    <div className='delete-ends' />
                </>

            )
        }
        )
            :
            <MensagemPadraoRelatorio />
    )
}

export { relatorioEstabelecimentoEspecificoVirtuoso, relatorioEstabelecimentoEspecifico }