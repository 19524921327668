import React, { useState, useEffect } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { getApi } from '../../../../util/Configuracoes'
import { consultar } from '../../../../util/AcessoApi'

const ConsultaUsuario = (props) => {
    const [data, setData] = useState([])
    useEffect(() => {
        consultar('/administracao/empresa', (dados) => setData(dados))
        // getApi(setData, '/administracao/empresa', null, props.modulo)
    }, [])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-empresa',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Razão Social',
                        accessor: 'razao_social',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Nome Fantasia',
                        accessor: 'nome',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'CNPJ',
                        accessor: 'cnpj',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Munícipio',
                        accessor: 'nome_municipio',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Estado',
                        accessor: 'nome_estado',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Telefones',
                        accessor: 'telefones',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'E-mail',
                        accessor: 'email',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro_formatada',
                        ordenamento: {
                            tipo: 'data',
                        }
                    }
                ],
            },
        ],
        []
    )



    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} setData={setData} titulo={'Consulta de Empresa'}
                tituloLista={'Lista Empresa'} caminhoConsultaFiltros= '/administracao/empresapaginacao'
                acoes={true} caminhoBase='/administracao/empresa' icone={'icon-Building'} modulo={props.modulo} urlCadastro="/administracao/empresa/cadastro"/>
            </div>
        </>
    )
}

export default ConsultaUsuario