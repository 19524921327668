import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { ordenarArrayObj, downloadArquivoS3 } from '../../../../util/FuncoesComuns'

const VisualizacaoCbos = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})

    useEffect(() => {
        if (id) {
            visualizar(`/administracao/registroatividades`, id, obj => {
                setDados(obj)
            })
        }
    }, [id])


    const data = dados.id ? {
        id,
        modulo: props.modulo,
        titulo: 'Visualização de Registro Atividades',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    { titulo: 'Usuário', descricao: dados.usuario, tamanho: 'col-lg-6' },
                    { titulo: 'Data', descricao: dados.data + ' ' + dados.hora, tamanho: 'col-lg-6' },
                    { titulo: 'Data Registro', descricao: dados.data_registro, tamanho: 'col-lg-6' },
                    { titulo: 'Conteúdo', descricao: <div dangerouslySetInnerHTML={{ __html: dados.conteudo }}></div>, tamanho: 'col-lg-12' },
                ]
            }, {
                tipoGrupo: 'tabela',
                titulo: 'Arquivo(s)',
                thead:
                    <tr>
                        <td className='coluna-6'>Nome</td>
                        <td className='coluna-5'>Tamanho</td>
                        <td className='coluna-1 d-none-print'>Download</td>
                    </tr>,
                tbody: dados.arquivos == undefined || dados.arquivos.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.arquivos, 'nome').map(arquivo =>
                        <tr>
                            <td>{arquivo.name}</td>
                            <td>{arquivo.sizeReadable}</td>
                            <td className="d-none-print"><div className="icone"><button className="btn-acao icon-Download-fromCloud" onClick={() => downloadArquivoS3(arquivo.s3Key)}></button></div></td>
                        </tr>)
            }
        ]
    } : { titulo: 'Visualização de Registro de Atividades' }

    return dados ? <VisualizacaoPadrao data={data} {...props} /> : null
}

export default VisualizacaoCbos