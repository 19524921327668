import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputTipoRelatorio, InputEstabelecimentoSaude, InputSimNao, InputOperadoraSaude, InputSocio, InputCirurgiaoCombo } from '../../../components/InputsAutoComplete'
import { InputDatePicker, InputPadrao } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes';
import { mostrarToast } from '../../../components/Toasts';

export default props => {
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Procedimentos por Cirurgião/Sócio')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '' })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)

    const tipoRelatorio = [
        { label: 'Por Cirurgião/Sócio', value: 1 },

    ]

    const inputRef = useRef(null);

    const validacao = Yup.object({
        data_aniversario_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) }),
        data_aniversario_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) })
    })

    const relatoriosPorTipo = propsFormik => [
        {

            titulo: 'Relatório Procedimentos por Cirurgião/Sócio',
            tipo: 1,
            url: '/relatorio/ProcedimentosCirurgiaoSocio',
            campos: [
                <InputDatePicker label="Data Agendamento Inicial" name="data_inicial" type="text" autoFocus
                    setarValor={value => { propsFormik.setFieldValue("data_inicial", value) }} />,
                <InputDatePicker label="Data Agendamento Final" name="data_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_final", value) }} />,
                <InputCirurgiaoCombo label="Cirurgião" placeholder="Cirurgião" name="cirurgiao" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputEstabelecimentoSaude label="Estabelecimento Saúde" placeholder="Estabelecimento Saúde" name="estabelecimento_saude" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputOperadoraSaude label="Operadora Saúde" placeholder="Operadora Saúde" name="operadora_saude" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSocio label="Sócio" placeholder="Sócio" name="socio" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />
            ],
            numerarLinha: true,
            agrupamento: [],
            colunas: [
                { label: 'Sócio', name: 'socio', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Código Procedimento', name: 'codigo_procedimento', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Procedimento', name: 'procedimentos', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Inicial Agendamento', name: 'data_inicial', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Final Agendamento', name: 'data_final', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Data Inicial', name: 'data_inicial', name_coluna: 'data_inicial' },
                { label: 'Data Final', name: 'data_final', name_coluna: 'data_final' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Sócio', name: 'socio_id', name_coluna: 'socio' },

            ]
        },
    ]
    // console.log(relatoriosPorTipo(propsFormik).find(x => x.tipo === 1), 'novo relatório')


    const submit = (values, propsFormik) => {

        const { socio, cirurgiao, data_inicial, data_final } = values;

        if (!socio && !cirurgiao && (!data_inicial && !data_final)) {
            mostrarToast('erro', 'Escolha ao menos um filtro! Data do Agendamento Inicial e Final ou Sócio ou Cirurgião');
        } else if ((data_inicial && !data_final) || (!data_inicial && data_final)) {
            mostrarToast('erro', 'Você deve inserir tanto a Data Inicial quanto a Data Final.');
        }
        else {

            setCarregando(true)
            let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === 1)
            values.tipo_relatorio = 1
            setShowModalFiltros(false)

            for (let i = Object.keys(values).length; i > 0; i--)
                if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                    delete values[Object.keys(values)[i]]

            setValuesFiltros(values)
            setRelatorioAtual({ ...novoRelatorio })

            if (values.tipo_relatorio)
                setFiltros(Object.keys(values).map((x, idx, array) => {
                    const valorAtual = values[x]

                    return (x !== 'tipo_relatorio'
                        && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                    {
                        coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                        filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                        name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                        value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                        dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                    })
                }
                ).filter(x => x))
            else {
                setRelatorioAtual({})
                setCarregando(false)
                setDados([])
                setFiltros([])
            }
        }
    }


    useEffect(() => {

        let cloneRelatorioAtual = { ...relatorioAtual }

        Object.keys(cloneRelatorioAtual).length > 0 &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {

                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{ numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)


                // const arrayDistinct = (value, index, self) => {
                //     return self.findIndex(x => x.cirurgiao === value.cirurgiao) === index;
                // }

                // var arrayCirurgiaoSemRepeticao = array && array.map(x => ({ cirurgiao_id: x.cirurgiao_id, cirurgiao: x.cirurgiao })).filter(arrayDistinct)
                // cloneRelatorioAtual.data = array
                // setTitulo(cloneRelatorioAtual.titulo)
                // const novoTeste = arrayCirurgiaoSemRepeticao ? arrayCirurgiaoSemRepeticao.map(item => ({ agrupamento: item.cirurgiao, colunas: cloneRelatorioAtual.colunas, numerarLinha: true, data: array.filter((item2) => item2.cirurgiao_id === item.cirurgiao_id) })) : ''
                // console.log('novoTeste: ', novoTeste);
                // // setDados([{ agrupamento: '31/03/2021 - Quinta-feira', data: novoTeste }])
                // // setDados([{ numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data, classeTr: cloneRelatorioAtual.classeTr, aposAgrupamento: cloneRelatorioAtual.aposAgrupamento, aposRelatorio: cloneRelatorioAtual.aposRelatorio }])
                // setDados(novoTeste)
                // setCarregando(false)
                // setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: filtros })

    }, [filtros])

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} agrupamento={[{ coluna: 'cirurgiao', descricao: 'cirurgiao' }]}
            loading={carregando} orientacao={'A4 landscape'} repetirColunas>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === 1) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === 1).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}

                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}
