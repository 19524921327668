import React, { useState, useEffect } from 'react'
import DataGrid from '../../../../../components/DataGrid'
import { getApi } from '../../../../../util/Configuracoes'
import { consultar } from '../../../../../util/AcessoApi'
import DataGridAlternativa from '../../../../../components/DataGridAlternativa'

const Consulta = (props) => {
    const [data, setData] = useState([])
    useEffect(() => {
        consultar('/administracao/ferias/mercado', (dados) => setData(dados))
        // getApi(setData, '/administracao/ferias/marcacao', null, props.modulo)
    }, [])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-mercado',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Data',
                        accessor: 'data',
                        ordenamento: {
                            tipo: 'data',
                        }
                    },
                    {
                        Header: 'Remetente',
                        accessor: 'remetente',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'remetente',
                            idColuna: "ferias_mercado.socio_remetente_id"
                            
                        }
                    },
                    {
                        Header: 'Destinatário',
                        accessor: 'destinatario',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'destinatario',
                            idColuna: "ferias_mercado.socio_destinatario_id"
                        }
                    },
                    {
                        Header: 'Tipo',
                        accessor: 'descricao',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: "mercado_tipo",
                            idColuna: "ferias_mercado_tipo.id"
                        }
                    },
                    {
                        Header: 'Dias',
                        accessor: 'dias_utilizar',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Data Transferência Inicial',
                        accessor: 'data_inicial',
                        visivel: false,
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_inicial',
                            idColuna: 'data_inicial',
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data Transferência Final',
                        accessor: 'data_final',
                        visivel: false,
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_final',
                            idColuna: 'data_final',
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data',
                            idColuna: 'data_registro',
                            datePicker: true,
                            date: true
                        }
                    }
                ],
            },
        ],
        []
    )



    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} setData={setData} titulo={'Consulta de Mercado'}
                tituloLista={'Lista Mercado'} caminhoConsultaFiltros= '/administracao/ferias/mercadopaginacao'
                acoes={true} caminhoBase='/administracao/ferias/mercado' icone={'icon-Add-Cart'} modulo={props.modulo} urlCadastro="/administracao/ferias/mercado/cadastro"/>
            </div>
        </>
    )
}

export default Consulta