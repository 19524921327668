import React, { useState, useRef } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'

const ConsultaCbos = (props) => {
    const [data, setData] = useState([])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-cbos',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'codigo',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Descrição',
                        accessor: 'descricao',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Especialidade Médica?',
                        accessor: 'especialidade',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'input_sim_nao',
                            idColuna: 'especialidade_medica',
                            name: `input_sim_nao_${Math.floor(Math.random() * 1000)}`
                        }
                    },
                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro_formatada',
                        ordenamento: {
                            tipo: 'data'
                        },
                    },
                ],
            },
        ],
        []
    )

    return (
        <div>
            <DataGridAlternativa data={data} columns={columns} caminhoBase='/parametros/cbos' setData={setData} acoes={true} modulo={props.modulo}
                titulo={'Consulta CBOS'} tituloLista={'Lista CBOS'} urlCadastro='/parametros/cbos/cadastro' icone={'icon-Medical-Sign'} caminhoConsultaFiltros='/parametros/cbosPaginacao' />
        </div>
    )
}

export default ConsultaCbos