import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { formatarMoeda, isJsonString } from '../../../../util/Utilitarios';
import { capitalize } from 'lodash';
import { formatarErroNota } from '../../../../util/FuncoesComuns';
import { ModalPadraoCadastro } from '../../../../components/Modais';
var format = require('xml-formatter');


const VisualizacaoNota = props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/financeiro/notafiscal', id,
            obj => {

                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Nota Fiscal',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj?.id, tamanho: 'col-lg-4' },
                                { titulo: 'Número NFS-e', descricao: obj?.numero, tamanho: 'col-lg-4' },
                                { titulo: 'Código Verificação', descricao: obj?.codigo_verificacao, tamanho: 'col-lg-4' },
                                { titulo: 'Data Emissão', descricao: obj?.data_emissao, tamanho: 'col-lg-4' },
                                { titulo: 'Tomador/Cliente', descricao: obj?.cliente?.label, tamanho: 'col-lg-4' },
                                { titulo: 'Empresa/Prestador', descricao: obj?.empresa?.label, tamanho: 'col-lg-4' },
                                { titulo: 'Data Registro', descricao: obj?.data_registro, tamanho: 'col-lg-4' },
                            ]
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Lançamento(s)',
                            visivel: obj.lancamentos.length > 0,
                            // aposGrupo: <tr>
                            //     <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={13}>{formatarMoeda(obj.lancamentos.map(item => item.valor_total).reduce((total, num) => parseFloat(total) + parseFloat(num)))}</td>
                            // </tr>,
                            thead:
                                <tr>
                                    <td className='coluna-1' colspan="1">Lançamento</td>
                                    <td className='coluna-3' colspan="3" style={{ textAlign: 'right' }}>Valor Lançamento</td>
                                </tr>,
                            tbody: obj?.lancamentos == undefined || obj?.lancamentos.length < 1 || obj?.lancamentos.filter(x => x.id !== null).length === 0 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :

                                obj?.lancamentos.map(x => {
                                    return (
                                        <tr>
                                            <td colspan="3">{x?.lancamento?.label}</td>
                                            <td colspan="1" style={{ textAlign: 'right' }}>{x?.valor_lancamento && formatarMoeda(x?.valor_lancamento)}</td>
                                        </tr>)
                                })
                        },
                        {
                            titulo: 'Dados Serviço',
                            colunas: [
                                { titulo: 'Município-UF', descricao: obj.municipio?.label, tamanho: 'col-lg-4' },
                                { titulo: 'Discriminação Serviços', descricao: obj.discriminacao_servicos, tamanho: 'col-lg-4' },
                                { titulo: 'Informações Adicionais', descricao: obj.informacoes_adicionais, tamanho: 'col-lg-4' },
                                { titulo: 'Valor R$', descricao: obj.valor_servico_formatado, tamanho: 'col-lg-4' },
                                { titulo: 'Valor Desconto R$', descricao: obj.valor_desconto_formatado, tamanho: 'col-lg-4' },
                                { titulo: 'Base Cálculo R$', descricao: obj.valor_base_calculo_formatado, tamanho: 'col-lg-4' },
                                { titulo: 'Valor Dedução R$', descricao: obj.valor_deducao_formatado, tamanho: 'col-lg-4' },
                                { titulo: 'Valor Nota R$', descricao: obj.valor_nota_formatado, tamanho: 'col-lg-4' },
                            ]
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Tributo(s)',
                            visivel: obj.tributos.length > 0,
                            // aposGrupo: <tr>
                            //     <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={13}>{formatarMoeda(obj.lancamentos.map(item => item.valor_total).reduce((total, num) => parseFloat(total) + parseFloat(num)))}</td>
                            // </tr>,
                            thead:
                                <tr>
                                    <td className='coluna-1' colspan="1">Situação</td>
                                    <td className='coluna-1' colspan="1">Recolhimento</td>
                                    <td className='coluna-1' colspan="1">Tributo</td>
                                    <td className='coluna-1' colspan="1">Local Incidência</td>
                                    <td className='coluna-3' colspan="3" style={{ textAlign: 'right' }}>Valor Tributo</td>
                                </tr>,
                            tbody: obj?.tributos == undefined || obj?.tributos.length < 1 || obj?.tributos.filter(x => x.id !== null).length === 0 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :

                                obj?.tributos.map(x => {
                                    return (
                                        <tr>
                                            <td colspan="1">{x?.situacao?.label}</td>
                                            <td colspan="1">{x?.recolhimento?.label}</td>
                                            <td colspan="1">{x?.tributo?.label}</td>
                                            <td colspan="1">{x?.local_incidencia?.label}</td>
                                            <td colspan="3 " style={{ textAlign: 'right' }}>{x?.valor_tributo && formatarMoeda(x?.valor_tributo)}</td>
                                        </tr>)
                                })
                        },
                        // {
                        //     tipoGrupo: 'tabela',
                        //     titulo: 'Visualizar Notas',
                        //     visivel: true,
                        //     aposGrupo: <tr>
                        //         <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={13}>http://www2.goiania.go.gov.br/sistemas/snfse/asp/snfse00200w0.asp?inscricao=1300687&nota=370&verificador=MB94-C3ZA</td>
                        //     </tr>,
                        //     tbody: <tr>
                        //         <td colspan="3">http://www2.goiania.go.gov.br/sistemas/snfse/asp/snfse00200w0.asp?inscricao=1300687&nota=370&verificador=MB94-C3ZA!</td>
                        //     </tr>
                        // },

                    ]
                } : { titulo: 'Visualização Nota Fiscal' })

            })

    }, [id])


    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

const VisualizacaoErrosNota = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    const mensagens = obj => {

        let arrayVisualizacao = []

        arrayVisualizacao.push({
            tipoGrupo: 'tabela',
            titulo: 'Erro Nota',
            thead:
                <tr>
                    <td className='coluna-1'>Código</td>
                    <td className='coluna-5'>Mensagem</td>
                </tr>,
            tbody:
                obj.xml_erro &&
                montarRetornoErro(obj)
        })
        return arrayVisualizacao
    }

    const montarRetornoErro = obj => {
        try {
            if (isJsonString(obj.xml_erro)) {

                let json = JSON.parse(obj.xml_erro)
                const propriedades = Object.keys(json);
                return propriedades.map((propriedade, index) => (
                    <tr>
                        <td className=''>{capitalize(propriedades[index])}</td>
                        <td className=''>{'Preencher campo(s) ' + formatarErroNota(json[propriedade])}</td>
                    </tr>
                ))

            }
            else {
                return (
                    [
                        <tr>
                            <td className=''>{obj.xml_erro && obj.xml_erro.split('Código:')[1]?.split(',')[0]?.trim()}</td>
                            <td className=''>{obj.xml_erro && obj.xml_erro.split('Mensagem:')[1]?.split(',')[0]?.trim()}</td>
                        </tr>
                    ]
                )
            }
        } catch (e) {
            throw e
        }
    }

    useEffect(() => {
        visualizar('/financeiro/obterErrosEnvioNota', id, obj => {
            setDados(obj ? {
                id,
                modulo: props.modulo,
                titulo: 'Visualização de Erro Nota',
                grupos: [
                    {
                        titulo: 'Dados Nota Fiscal',
                        colunas: [
                            { titulo: 'Número', descricao: obj[0].numero, tamanho: 'col-lg-3' },
                            { titulo: 'Prestador/Empresa', descricao: obj[0].prestador, tamanho: 'col-lg-3' },
                            { titulo: 'Data Emissão', descricao: obj[0].data_emissao, tamanho: 'col-lg-3' },
                            { titulo: 'Valor Nota', descricao: obj[0].valor_nota_formatado, tamanho: 'col-lg-3' },
                            { titulo: 'Tomador/Cliente', descricao: obj[0].cliente?.label, tamanho: 'col-lg-3' },
                        ]
                    }
                ].concat(mensagens(obj[0]))
            } : { titulo: 'Visualização de Erro Nota' })
        })
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

const DetalhesNota = (props) => {
    const [itemDetalhes, setItemDetalhes] = useState({});

    useEffect(() => {
        setItemDetalhes(props.itemDetalhes)
    }, [props.itemDetalhes])

    return <ModalPadraoCadastro titulo={props.titulo || 'Detalhes Nota Fiscal'} showModal={props.showModal} setShowModal={props.setShowModal}>
        <div><div className='detalhes'>
            {itemDetalhes.nota_fiscal && itemDetalhes.nota_fiscal.map((item, idx) => {
                return (
                    <>
                        {idx === 0 ? <></> : <hr />}
                        <div className='item-detalhes'>
                            <h6><b>{item?.titulo}</b></h6>
                            <h6>{item?.descricao}</h6>
                            {item.html}
                        </div></>
                )
            })}

        </div>
            {
                itemDetalhes.nota_lancamento && itemDetalhes.nota_lancamento.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Lançamento(s)</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes.nota_lancamento && itemDetalhes.nota_lancamento.map(item => <li className="mb-1">
                            <div className='item-detalhes row-space-between'>
                                <h6><b>Cód</b></h6>
                                <h6><b>Data Registro</b></h6>
                            </div>
                            <div className='item-detalhes row-space-between'>
                                <h6>{item.id}</h6>
                                <h6>{item.data_registro}</h6>
                            </div>
                            <hr />
                            <div className='item-detalhes'>
                                <h6><b>Empresa</b></h6>
                            </div>
                            <div className='item-detalhes'>
                                <h6>{item.empresa || '---'}</h6>
                            </div>
                            <hr />
                            <div className='item-detalhes'>
                                <h6><b>Cliente</b></h6>
                            </div>
                            <div className='item-detalhes'>
                                <h6>{(item?.cpf_cnpj + ' - ' + item.cliente) || '---'}</h6>
                            </div>
                            <hr />
                            <div className='item-detalhes'>
                                <h6><b>Estabelecimento Saúde</b></h6>
                            </div>
                            <div className='item-detalhes'>
                                <h6>{item.estabelecimento_saude || '---'}</h6>
                            </div>
                            <hr />
                            <div className='item-detalhes'>
                                <h6><b>Descrição</b></h6>
                            </div>
                            <div className='item-detalhes'>
                                <h6>{item.descricao || '---'}</h6>
                            </div>
                            <hr />
                            <div className='item-detalhes row-space-between'>
                                <h6><b>Tipo Pagamento</b></h6>
                                <h6><b>Tipo Documento</b></h6>
                            </div>
                            <div className='item-detalhes row-space-between'>
                                <h6>{item.modo_pagamento_sigla || '---'}</h6>
                                <h6>{item.tipo_documento_lancamento || '---'}</h6>
                            </div>
                            <hr />
                            <div className='item-detalhes row-space-between'>
                                <h6><b>Data Liquidação</b></h6>
                                <h6><b>Valor R$</b></h6>
                            </div>
                            <div className='item-detalhes row-space-between'>
                                <h6>{item.data_liquidacao || '---'}</h6>
                                <h6>{item.valor_liquidacao || '---'}</h6>
                            </div>
                            <hr />

                        </li>)
                    }
                </ul>
            </div>
            {
                itemDetalhes.nota_guia && itemDetalhes.nota_guia.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Guia</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes?.nota_guia && itemDetalhes?.nota_guia?.map(item => <li className="mb-1">
                            <div className='item-detalhes row-space-between'>
                                <h6><b>Código</b></h6>
                                <h6><b>Data Registro</b></h6>
                            </div>
                            <div className='item-detalhes row-space-between'>
                                <h6>{item.id}</h6>
                                <h6>{item.data_registro}</h6>
                            </div>
                            <hr />
                            <div className='item-detalhes'>
                                <h6><b>Paciente</b></h6>
                            </div>
                            <div className='item-detalhes'>
                                <h6>{item.nome_beneficiario || '---'}</h6>
                            </div>
                            <hr />
                            <div className='item-detalhes'>
                                <h6><b>Profissional(is) Executante(s) Cooperativa (CONVÊNIO)</b></h6>
                            </div>
                            <div className='item-detalhes'>
                                <h6>{item?.socio_externo || '---'}</h6>
                            </div>
                            <hr />
                            <div className='item-detalhes'>
                                <h6><b>Profissional(is) Executante(s) EQUIPE</b></h6>
                            </div>
                            <div className='item-detalhes'>
                                <h6>{item?.socio_interno || '---'}</h6>
                            </div>
                            <hr />
                            <div className='item-detalhes row-space-between'>
                                <h6><b>Data Procedimento</b></h6>
                                <h6><b>Valor R$</b></h6>
                            </div>
                            <div className='item-detalhes row-space-between'>
                                <h6>{item.data_procedimento || '---'}</h6>
                                <h6>{item.valor_total || '---'}</h6>
                            </div>
                            <hr />

                        </li>)
                    }
                </ul>
            </div>
            {
                itemDetalhes.historico && itemDetalhes.historico.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Histórico de Status</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes.historico && itemDetalhes.historico.map(item => <li className="mb-1">
                            <div className='col-hora row-space-between'>
                                <span className={'tag'} style={{ background: item.cor_id, color: 'white' }}>{item.descricao_status}</span> <span className='span-hora'>{item.data_registro}</span>
                            </div>
                            <div className='mt-2'>
                                <i><p>{item.anotacao}</p></i>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span></span>
                                <span className='span-nome'><b>{`${item.usuario ? item.usuario.toUpperCase() : ''} `}</b></span>
                            </div>
                        </li>)
                    }
                </ul>
            </div>
            {
                itemDetalhes.historico_envio && itemDetalhes.historico_envio.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Histórico de Status Envio</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes.historico_envio && itemDetalhes.historico_envio.map(item => <li className="mb-1">
                            <div className='col-hora row-space-between'>
                                <span className={'tag'} style={{ background: item.cor_id, color: 'white' }}>{item.descricao_status}</span> <span className='span-hora'>{item.data_registro}</span>
                            </div>
                            <div className='mt-2'>
                                <i><p>{item.anotacao}</p></i>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span></span>
                                <span className='span-nome'><b>{`${item.usuario ? item.usuario.toUpperCase() : ''} `}</b></span>
                            </div>
                        </li>)
                    }
                </ul>
            </div>
        </div>
    </ModalPadraoCadastro >
}



export default VisualizacaoNota
export { VisualizacaoErrosNota, DetalhesNota }
