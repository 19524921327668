import React, { useState, useEffect } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputDatePicker, InputMonthPicker, InputNumero } from '../../../components/Inputs'
import { InputTipoRelatorio, InputEntidade, InputCategoriaAlternativo, InputStatusMovimentoFinanceiro, InputUsuario, InputMovimentoFinanceiro, InputSimNao, InputTipoRelatorioPersonalizado } from '../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes'
import { convertValueToFloat, formatarMoeda } from '../../../util/Utilitarios'
import * as moment from 'moment'
import { Link } from 'react-router-dom'
import { mostrarToast } from '../../../components/Toasts';
import { ordenarArrayObj } from '../../../util/FuncoesComuns';
import { MODULOS_SISTEMA, RETENCOES } from '../../../util/Enums';
import MensagemPadraoRelatorio from '../../../components/RelatorioEspecifico/RelatorioSemDados'
import { InputClienteFinanceiroV3, InputEmpresa, InputStatusEnvioNota, InputStatusNota } from '../../../components/InputsAutoCompleteV2';


export default props => {
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Movimento Financeiro')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '', referencia: '01/2023' })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)

    const tipoRelatorio = [{ label: 'Movimento Financeiro - Sintético', value: MODULOS_SISTEMA.RELATORIO_MOVIMENTO_SINTETICO }, { label: 'Movimento Financeiro - Analítico', value: MODULOS_SISTEMA.RELATORIO_MOVIMENTO_ANALITICO }, { label: 'Movimento Receita', value: MODULOS_SISTEMA.RELATORIO_MOVIMENTO_RECEITA }]

    const validacao = Yup.object({})

    const relatorioMovimentoAnalitico = (array, arrayConfigCol, configOrdenamento) => {
        return (
            array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {
                var colspanlg = arrayConfigCol[idx].filter(y => y.visivel).length
                return (
                    <table className='table-agrupamento'>
                        {
                            x.data && x.data.map((grupo, idxG) => {
                                return (<>
                                    <tr className="group-title">
                                        {idx === 0 && x.colunas && x.colunas.map((y, idy) => {
                                            let classes = (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].ordenamento.ativo && arrayConfigCol[idx][idy].coluna === y.name ? 'ordenamento_' + arrayConfigCol[idx][idy].ordenamento.modo : '');
                                            return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) && <td style={{ width: y.width || 'auto' }} className={`bold coluna ${y.alinhamento && y.alinhamento || ''} ${classes}`}>{y.label}</td>
                                        })}
                                    </tr>
                                    <tr className={'tr-data titulo-1'}>
                                        {x.colunas && x.colunas.map((y, idy) => {
                                            return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) && <td style={{ width: y.width || 'auto' }} className={`td-data ${y.alinhamento && y.alinhamento || ''}`}>{grupo[y.name]}</td>
                                        })}

                                    </tr>
                                    <tr><td colspan={colspanlg}><span></span></td> </tr>
                                    <tr width={'100%'}>
                                        <td colspan={colspanlg}>
                                            <table style={{ width: '100%' }}>
                                                <tr style={{ width: '100%', marginBottom: '15px' }}>
                                                    <td width={'47%'} style={{ verticalAlign: 'baseline' }}>
                                                        <table style={{ width: '100%' }} className='table-agrupamento'>
                                                            <tr className={'tr-data'}><td colSpan={3} className='td-data'><span className='bold' style={{ width: '100%' }}>Valores Recebidos</span></td></tr>
                                                            <tr className='titulo-1'>
                                                                <td className='text-center bold td-data' width={'5%'}>#</td>
                                                                <td className='bold td-data' width={'65%'}>Forma de Receita</td>
                                                                <td className='text-right bold td-data' width={'30%'}>Valor(R$)</td>
                                                            </tr>
                                                            {grupo.valores_recebidos && grupo.valores_recebidos.length > 0 ? grupo.valores_recebidos.map((x, idx) => {
                                                                return <tr className={'tr-data'}>
                                                                    <td className='text-center td-data'>{idx + 1}</td>
                                                                    <td className='td-data'>{x.forma_pagamento}</td>
                                                                    <td className='text-right td-data'>{x.valor}</td>
                                                                </tr>
                                                            }) :
                                                                <tr className='tr-data'>
                                                                    <td colSpan={3} className='text-center td-data'><span>Não há valores recebidos</span></td>
                                                                </tr>
                                                            }
                                                            <tr><td class="td-data text-center" colspan={3} style={{ borderTop: '1px #000 dashed' }}></td></tr>
                                                            <tr className={'tr-data'}><td className="bold text-right td-data" colspan={2}>Total Valores Recebidos:</td>
                                                                <td className="bold text-right td-data">{grupo.total_valores || 'R$ 0,00'}</td></tr>
                                                        </table>
                                                    </td>
                                                    <td width={'6%'}></td>
                                                    <td width={'47%'} style={{ verticalAlign: 'baseline' }}>
                                                        <table style={{ width: '100%' }}>
                                                            <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Retenções</span></td></tr>
                                                            <tr className='tr-data titulo-1'>
                                                                <td className='text-center bold td-data' width={'5%'}>#</td>
                                                                <td className='bold td-data' width={'65%'}>Forma de Receita</td>
                                                                <td className='text-right bold td-data' width={'30%'}>Valor(R$)</td>
                                                            </tr>
                                                            {grupo.retencoes && grupo.retencoes.length > 0 ? grupo.retencoes.map((x, idx) => {
                                                                return <tr className='tr-data'>
                                                                    <td className='text-center td-data'>{idx + 1}</td>
                                                                    <td className='td-data'>{x.forma_retencao}</td>
                                                                    <td className='text-right td-data'>{x.valor}</td>
                                                                </tr>
                                                            }) :
                                                                <tr className='tr-data'>
                                                                    <td colSpan={3} className='text-center td-data'><span>Não há retenções</span></td>
                                                                </tr>
                                                            }
                                                            <tr><td className="td-data text-center" colspan={3} style={{ borderTop: '1px #000 dashed' }}></td></tr>
                                                            <tr className='tr-data'><td className="bold text-right td-data" colspan={2}>Total Retenções:</td>
                                                                <td className="bold text-right td-data">{grupo.total_retencoes || 'R$ 0,00'}</td></tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr><td colspan={colspanlg}><span></span></td> </tr>
                                    <tr><td colspan={colspanlg}><span></span></td> </tr>
                                    <tr><td colspan={colspanlg} style={{ borderTop: '1px #000 dashed' }}><span></span></td> </tr>
                                    <tr className='tr-data'><td className="bold text-right td-data" colspan={colspanlg - 1}>Total Recebido + Retenções:</td>
                                        <td className="bold text-right td-data" colspan={1}>{grupo.total_geral || 'R$ 0,00'}</td></tr>
                                    <tr><td colspan={colspanlg}><span></span></td> </tr>
                                    <tr><td colspan={colspanlg}><span></span></td> </tr>
                                </>
                                )
                            })
                        }
                        <tr><td colspan={colspanlg} style={{ borderTop: '1px #000 dashed' }}><span></span></td> </tr>
                        <tr className='tr-data'><td className="bold text-right td-data" colspan={colspanlg - 1}>Total Lançado:</td>
                            <td className="bold text-right td-data" colspan={1}>{formatarMoeda(x.data && x.data.length > 0 ? (x.data.map(y => y.valor_total).reduce((total, num) => parseFloat(total) + parseFloat(num)) || 0) : 0)}</td></tr>
                        <tr className='tr-data'><td className="bold text-right td-data" colspan={colspanlg - 1}>Total Geral Recebido + Retenções:</td>
                            <td className="bold text-right td-data" colspan={1}>{formatarMoeda(x.data && x.data.length > 0 ? (x.data.map(y => y.total_geral_float).reduce((total, num) => parseFloat(total) + parseFloat(num)) || 0) : 0)}</td></tr>
                    </table>
                )
            }
            ) :
                <MensagemPadraoRelatorio />

        )
    }

    const relatorioMovimentoReceita = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {

        const obterColSpan = (arr) => {

            let novoArr = arr.filter(x => x.coluna !== 'visto' && x.coluna !== 'retencoes');

            return novoArr.length - 1

        }

        return (
            array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {
                const qtdColunas = x.colunas.filter(y => y.visivel && !y.lista).length
                const idxVisto = arrayConfigCol[idx].findIndex(y => y.coluna == 'visto')
                const idxRet = arrayConfigCol[idx].findIndex(y => y.coluna == 'retencoes')
                const idxCod = arrayConfigCol[idx].findIndex(y => y.coluna == 'codigo_lancamento')
                const idxDesc = arrayConfigCol[idx].findIndex(y => y.coluna == 'descricao_lancamento')

                return (
                    <div>
                        {
                            x.data && x.data.map((grupo, idxG) => {

                                let despesas = grupo.lancamentos ? grupo.lancamentos.flatMap(x => x).filter(x => x.tipo_lancamento === 2) : []
                                const transacoes = grupo.lancamentos ? grupo.lancamentos.filter(x => x.tipo_lancamento === 1)?.flatMap(lanc => lanc.tipo_pagamento_liquidado) : []
                                const transacoesRA = grupo.lancamentos ? grupo.lancamentos.flatMap(lanc => lanc.retencoes_acrescimos) : []
                                let agrupamentoResumo = transacoes.filter(trans => trans).reduce((agrupado, transacao) => {
                                    if (!agrupado[transacao.sigla_modo]) {
                                        agrupado[transacao.sigla_modo] = {
                                            sigla_modo: transacao.sigla_modo,
                                            tipo_pagamento: transacao.tipo_pagamento,
                                            valor_liquidado: 0,
                                            ordenamento: transacao.ordenamento
                                        };
                                    }
                                    agrupado[transacao.sigla_modo].valor_liquidado += transacao.valor_liquidado;
                                    return agrupado;
                                }, {});

                                let aggRetencoes = transacoesRA.reduce((agrupado, transacao) => {
                                    agrupado["acrescimo"] = (agrupado["acrescimo"] || 0) + (transacao.acrescimo || 0)
                                    agrupado["desconto"] = (agrupado["desconto"] || 0) + (transacao.desconto || 0)
                                    agrupado["juros_multas"] = (agrupado["juros_multas"] || 0) + (transacao.juros_multas || 0)
                                    agrupado["imposto_nfse"] = (agrupado["imposto_nfse"] || 0) + (transacao.imposto_nfse || 0)
                                    agrupado["glosas"] = (agrupado["glosas"] || 0) + (transacao.glosas || 0)
                                    agrupado["outros_impostos"] = (agrupado["outros_impostos"] || 0) + (transacao.outros_impostos || 0)
                                    agrupado["total_retencao"] = (agrupado["total_retencao"] || 0) + (transacao.total_retencao || 0)
                                    return agrupado;
                                }, {});
                                agrupamentoResumo = Object.values(agrupamentoResumo);
                                const aggKeysRet = Object.keys(aggRetencoes);

                                const totalLancamento = grupo?.lancamentos && grupo?.lancamentos.length > 0 ? formatarMoeda(grupo.lancamentos.filter(x => x.tipo_lancamento === 1).reduce((total, transacao) => total + transacao.valor_total, 0)) : 'R$ 0,00';
                                const totalLRetencoes = formatarMoeda(aggRetencoes["total_retencao"]);
                                const totalLiquidacao = agrupamentoResumo && agrupamentoResumo.length > 0 ? formatarMoeda(agrupamentoResumo.reduce((total, transacao) => total + transacao.valor_liquidado, 0)) : 'R$ 0,00';

                                const limitarCaracteres = (str, limite) => {
                                    if (str && str?.length <= limite) {
                                        return str;
                                    } else if (str && str.length) {
                                        return str.slice(0, limite) + "...";
                                    }

                                    return
                                }

                                return (<>
                                    {idxG > 0 ? htmlHeader : <></>}
                                    <table className='table-agrupamento mb-2'>
                                        <tr className='titulo-1'>
                                            <td colspan='13' className=' bold '>Data Movimento: {grupo.data}</td>
                                        </tr>

                                        <tr className="group-title">
                                            {idx === 0 && x.colunas && x.colunas.filter(y => !y.lista).map((y, idy) => {
                                                let classes = (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].ordenamento.ativo && arrayConfigCol[idx][idy].coluna === y.name ? 'ordenamento_' + arrayConfigCol[idx][idy].ordenamento.modo : '');
                                                return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) && <td style={{ width: y.width || 'auto' }} className={`bold coluna ${y.alinhamento && y.alinhamento || ''} ${classes}`}>{y.label}</td>
                                            })}
                                        </tr>
                                        <tr className={'tr-data titulo-1'}>
                                            {x.colunas && x.colunas.filter(y => !y.lista).map((y, idy) => {
                                                return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) ? <td style={{ width: y.width || 'auto' }} className={` bold td-data ${y.alinhamento && y.alinhamento || ''}`}>{grupo[y.name]}</td> : <></>
                                            })}

                                        </tr>
                                    </table>
                                    <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                                        <tr className={'tr-data'}><td colSpan={3} className='td-data'><span className='bold' style={{ width: '100%' }}>Lançamentos</span></td></tr>
                                        <tr className='titulo-1'>
                                            <td className='text-center bold td-data' width={'3%'}>#</td>
                                            {x.colunas && arrayConfigCol[idx][idxCod]?.visivel ? <td className='text-center bold td-data' width={'8%'}>Cód.</td> : <></>}
                                            {/* <td className='text-center bold td-data' width={'3%'}>P</td> */}
                                            <td className='text-center bold td-data' width={'4%'}>Atend.</td>
                                            <td className='bold td-data' width={'13%'}>Cliente</td>
                                            <td className='bold td-data' width={'8%'}>Sócio(s)</td>
                                            <td className='bold td-data' width={'13%'}>Estab./Op. de Saúde</td>
                                            {x.colunas && arrayConfigCol[idx][idxDesc]?.visivel ? <td className='bold td-data' width={'8%'}>Descrição</td> : <></>}
                                            <td className='text-center bold td-data' width={'8%'}>Data Liquidação</td>
                                            <td className='text-right bold td-data' width={'10%'}>Valor Apres. R$</td>
                                            {x.colunas && arrayConfigCol[idx][idxRet]?.visivel ? <td className='text-right bold td-data' width={'10%'}>Retenções R$</td> : <></>}
                                            <td className='text-right bold td-data' width={'15%'}>Valor Liquidado R$</td>
                                            <td className='bold td-data' width={'2%'}>TP</td>
                                            <td className='bold td-data' width={'12%'}>NFSe</td>
                                            {x.colunas && arrayConfigCol[idx][idxVisto]?.visivel ? <td className='text-right bold td-data text-center' width={'2%'}><div className='retangulo'></div></td> : <></>}
                                        </tr>
                                        {grupo.lancamentos && grupo.lancamentos.length > 0 ? <>
                                            {
                                                ordenarArrayObj(grupo.lancamentos.filter(x => x.tipo_lancamento === 1), 'ordenamento').map((lanc, idl) => {
                                                    return <tr className={'tr-data tr-movimento-receita'}>
                                                        <td className='text-center td-data'>{idl + 1}</td>
                                                        {x.colunas && arrayConfigCol[idx][idxCod]?.visivel ? <td className='text-center td-data'>{lanc.id}</td> : <></>}
                                                        {/* <td className='text-center td-data'>{lanc.numero_parcela}</td> */}
                                                        <td className='text-center td-data'>{lanc.atendimento}</td>
                                                        <td className='td-data'>{limitarCaracteres(lanc.entidade, 29)}</td>
                                                        <td className='td-data'>{lanc.socio}</td>
                                                        <td className='td-data'>{limitarCaracteres(lanc.estab_op_saude, 30)}</td>
                                                        {x.colunas && arrayConfigCol[idx][idxDesc]?.visivel ? <td className='td-data'>{limitarCaracteres(lanc.descricao_lancamento, 20)}</td> : <></>}
                                                        <td className='text-center td-data'>{lanc.data_liquidacao}</td>
                                                        <td className='text-right td-data'>{lanc.valor_total_formatado}</td>
                                                        {x.colunas && arrayConfigCol[idx][idxRet]?.visivel ? <td className='text-right td-data'>{lanc.total_retencao}</td> : <></>}
                                                        <td className='text-right td-data'>{lanc.valor_liquidado}</td>
                                                        <td className='td-data'>{lanc.tipo_pagamento}</td>
                                                        <td className='td-data'>{lanc?.nota_fiscal}</td>

                                                        {x.colunas && arrayConfigCol[idx][idxVisto]?.visivel ? <td className='text-right td-data text-center'><div className='retangulo'></div></td> : <></>}

                                                    </tr>
                                                })
                                            }

                                            <tr>
                                                <td colSpan={obterColSpan(arrayConfigCol[idx].filter(coluna => coluna.visivel))} className="text-right td-data"> <span style={{ minWidth: '120px', fontWeight: 'bold', display: 'inline-block' }}>{totalLancamento}</span></td>
                                                {x.colunas && arrayConfigCol[idx][idxRet]?.visivel ? <td className="text-right td-data"><span style={{ minWidth: '120px', fontWeight: 'bold', display: 'inline-block' }}>{totalLRetencoes}</span></td> : <></>}
                                                <td className="text-right td-data"> <span style={{ minWidth: '120px', fontWeight: 'bold', display: 'inline-block' }}>{totalLiquidacao}</span></td>

                                            </tr>


                                            {/* <tr style={{ borderBottom: '1px #000 dashed' }}><td colspan={100} className={'bold'}></td></tr> */}
                                            {/* <tr>
                                                <td colSpan={100} className="text-right td-data"><div>Total Lançamento: <span style={{ minWidth: '120px', fontWeight: 'bold', display: 'inline-block' }}>{totalLancamento}</span></div></td>
                                            </tr>

                                            <tr>
                                                <td colSpan={100} className="text-right td-data">Total Retenções: <span style={{ minWidth: '120px', fontWeight: 'bold', display: 'inline-block' }}>{totalLRetencoes}</span></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={100} className="text-right td-data">Total Liquidado: <span style={{ minWidth: '120px', fontWeight: 'bold', display: 'inline-block' }}>{totalLiquidacao}</span></td>
                                            </tr> */}
                                            {/* <td colSpan={2}></td>
                                                {x.colunas && arrayConfigCol[idx][idxRet]?.visivel ? <td className="bold text-right td-data">{totalLRetencoes}</td> : <></>}
                                                <td className="bold text-right td-data">{totalLiquidacao}</td> */}
                                        </> :
                                            <tr className='tr-data'>
                                                <td colSpan={qtdColunas} className='text-center td-data'><span>Não há lançamentos</span></td>
                                            </tr>
                                        }
                                    </table>
                                    <table style={{ width: '100%' }} className='nao-quebrar mb-2'>
                                        <tr width={'100%'}>
                                            <td colspan={arrayConfigCol[idx].filter(y => y.visivel).length}>
                                                <table style={{ width: '100%' }}>
                                                    <tr style={{ width: '100%', marginBottom: '15px' }}>
                                                        <td width={'43%'} style={{ verticalAlign: 'baseline' }}>
                                                            <table style={{ width: '100%' }}>
                                                                <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Resumo dos Lançamentos(Liquidados)</span></td></tr>
                                                                <tr className='tr-data titulo-1'>
                                                                    <td className='text-center bold td-data' width={'5%'}>#</td>
                                                                    <td className='bold td-data' width={'65%'}>Tipo de Pagamento</td>
                                                                    <td className='text-right bold td-data' width={'30%'}>Valor(R$)</td>
                                                                </tr>
                                                                {agrupamentoResumo && agrupamentoResumo.length > 0 ? ordenarArrayObj(agrupamentoResumo, 'ordenamento').map((trans, idTrans) => {
                                                                    return <tr className='tr-data tr-movimento-receita'>
                                                                        <td className='text-center td-data'>{idTrans + 1}</td>
                                                                        <td className='td-data'>{trans.tipo_pagamento}</td>
                                                                        <td className='text-right td-data'>{formatarMoeda(trans.valor_liquidado)}</td>
                                                                    </tr>
                                                                }) :
                                                                    <tr className='tr-data tr-movimento-receita'>
                                                                        <td colSpan={3} className='text-center td-data'><span>Não há liquidações</span></td>
                                                                    </tr>
                                                                }
                                                                {/* <tr><td className="td-data text-center" colspan={3} style={{ borderTop: '1px #000 dashed' }}></td></tr> */}
                                                                <tr className='tr-data'><td className="bold text-right td-data" colspan={2}>Total Liquidado:</td>
                                                                    <td className="bold text-right td-data">{formatarMoeda(agrupamentoResumo.reduce((total, transacao) => total + transacao.valor_liquidado, 0))}</td></tr>
                                                            </table>
                                                        </td>
                                                        <td width={'14%'}></td>
                                                        <td width={'43%'} style={{ verticalAlign: 'baseline' }}>
                                                            <table style={{ width: '100%' }}>
                                                                <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Resumo Retenções</span></td></tr>
                                                                <tr className='tr-data titulo-1'>
                                                                    <td className='text-center bold td-data' width={'5%'}>#</td>
                                                                    <td className='bold td-data' width={'65%'}>Tipo de Retenção</td>
                                                                    <td className='text-right bold td-data' width={'30%'}>Valor(R$)</td>
                                                                </tr>
                                                                {aggKeysRet && aggKeysRet.filter(name => aggRetencoes[name] > 0).length > 0 ? aggKeysRet.filter(name => aggRetencoes[name] > 0).map((name, idName) => {
                                                                    return <tr className='tr-data tr-movimento-receita'>
                                                                        <td className='text-center td-data'>{idName + 1}</td>
                                                                        <td className='td-data'>{RETENCOES[name]}</td>
                                                                        <td className='text-right td-data'>{formatarMoeda(aggRetencoes[name])}</td>
                                                                    </tr>
                                                                }) :
                                                                    <tr className='tr-data tr-movimento-receita'>
                                                                        <td colSpan={3} className='text-center td-data'><span>Não há liquidações</span></td>
                                                                    </tr>
                                                                }
                                                                {/* <tr><td className="td-data text-center" colspan={3} style={{ borderTop: '1px #000 dashed' }}></td></tr> */}
                                                                <tr className='tr-data'><td className="bold text-right td-data" colspan={2}>Total Retenções:</td>
                                                                    <td className="bold text-right td-data">{formatarMoeda(aggRetencoes["total_retencao"])}</td></tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr width={'14%'}></tr>
                                                    <tr>
                                                        <td width={'43%'} style={{ verticalAlign: 'baseline' }}>
                                                            <table style={{ width: '100%' }}>
                                                                <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Resumo Lançamentos Débitos</span></td></tr>
                                                                <tr className='tr-data titulo-1'>
                                                                    <td className='text-center bold td-data' width={'5%'}>#</td>
                                                                    <td className='bold td-data' width={'65%'}>Tipo de Pagamento</td>
                                                                    <td className='text-right bold td-data' width={'30%'}>Valor(R$)</td>
                                                                </tr>
                                                                {despesas && despesas.length > 0 ? despesas.map((trans, idTrans) => {
                                                                    return <tr className='tr-data tr-movimento-receita'>
                                                                        <td className='text-center td-data'>{idTrans + 1}</td>
                                                                        <td className='td-data'>{trans.descricao_lancamento || trans.categoria_descricao}</td>
                                                                        <td className='text-right td-data'>{formatarMoeda(-1 * convertValueToFloat(trans.valor_liquidado))}</td>
                                                                    </tr>
                                                                }) :
                                                                    <tr className='tr-data tr-movimento-receita'>
                                                                        <td colSpan={3} className='text-center td-data'><span>Não há despesas</span></td>
                                                                    </tr>
                                                                }
                                                                {/* <tr><td className="td-data text-center" colspan={3} style={{ borderTop: '1px #000 dashed' }}></td></tr> */}
                                                                <tr className='tr-data'><td className="bold text-right td-data" colspan={2}>Total Despesas:</td>
                                                                    <td className="bold text-right td-data">{formatarMoeda(-1 * despesas.reduce((total, transacao) => total + convertValueToFloat(transacao.valor_liquidado), 0))}</td></tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr width='100%'>
                                            <td colspan={arrayConfigCol[idx].filter(y => y.visivel).length}>
                                                <table>
                                                    <tr className='tr-visto'>
                                                        <td width={'90%'}></td>
                                                        <td width={'10%'}>
                                                            <div className='div-assinatura'>
                                                                <div className='retangulo-ass'><span className='fs-11'>Caixa</span></div>
                                                                <div className='retangulo-ass'><span className='fs-11'>Visto</span></div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                    {idxG < x.data.length - 1 ? <div className='quebra-de-pagina'></div> : <></>}
                                </>
                                )
                            })
                        }
                    </div >
                )
            }
            ) :
                <MensagemPadraoRelatorio />

        )
    }

    const relatoriosPorTipo = propsFormik => [
        {
            titulo: 'Movimento Financeiro - Sintético',
            tipo: MODULOS_SISTEMA.RELATORIO_MOVIMENTO_SINTETICO,
            url: '/financeiro/relatorioMovimentoSintetico',
            numerarLinha: true,
            campos: [
                <InputMonthPicker label="Referência" name="referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} max={new Date().getFullYear()} min={2022} />,
                <InputMovimentoFinanceiro label='Movimento' name='movimento' placeholder='Movimento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} />,
                <InputEntidade label='Entidade' name='entidade' placeholder='Entidade' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputCategoriaAlternativo label='Categoria Movimento' name='categoria' placeholder='Categoria  Movimento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputCategoriaAlternativo label='Categoria Lançamento' name='categoria_lancamento' placeholder='Categoria Lançamento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputStatusMovimentoFinanceiro label='Status' name='status' placeholder='Status' multiplo propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Movimento Inicial" name="data_inicial_movimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_movimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Movimento Final" name="data_final_movimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_movimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro Inicial" name="data_inicial_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro Final" name="data_final_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_final_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputUsuario label="Usuário Responsável" name="usuario" placeholder='Usuário Responsável' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />
            ],
            colunas: [
                { label: 'Cod.', name: 'id', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Data', name: 'data', visivel: true, ordenamento: { tipo: 'data', ativo: true } },
                { label: 'Entidade', name: 'entidade', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Descrição', name: 'descricao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Status', name: 'data_status', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor (R$)', width: '8%', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', totalizar: true },
                { label: 'Valor Recebido (R$)', width: '8%', name: 'total_geral', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', totalizar: true },
                { label: 'Valor Lançamento (R$)', width: '8%', name: 'valor_lancamento', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', totalizar: true },
                { label: 'Valor Liquidação (R$)', width: '8%', name: 'valor_liquidacao', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', totalizar: true },
                { label: 'TP', width: '8%', name: 'tipo_pagamento', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro', name: 'data_registro', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Usuário', name: 'usuario', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Referência', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Entidade', name: 'entidade', name_coluna: 'entidade' },
                { label: 'Categoria Movimento', name: 'categoria', name_coluna: 'categoria' },
                { label: 'Categoria Lançamento', name: 'categoria_lancamento', name_coluna: 'categoria_lancamento' },
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Movimento', name: 'movimento', name_coluna: 'movimento' },
                { label: 'Data Movimento Inicial', name: 'data_inicial_movimento', name_coluna: 'data_inicial_movimento' },
                { label: 'Data Movimento Final', name: 'data_final_movimento', name_coluna: 'data_final_movimento' },
                { label: 'Data Registro Inicial', name: 'data_inicial_registro', name_coluna: 'data_inicial_registro' },
                { label: 'Data Registro Final', name: 'data_final_registro', name_coluna: 'data_final_registro' },
                { label: 'Usuário', name: 'usuario', name_coluna: 'usuario' },
            ],
        },
        {
            titulo: 'Movimento Financeiro - Analítico',
            tipo: MODULOS_SISTEMA.RELATORIO_MOVIMENTO_ANALITICO,
            url: '/financeiro/relatorioMovimentoAnalitico',
            numerarLinha: false,
            campos: [
                <InputMonthPicker label="Referência" name="referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} max={new Date().getFullYear()} min={2022} />,
                <InputMovimentoFinanceiro label='Movimento' name='movimento' placeholder='Movimento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} />,
                <InputEntidade label='Entidade' name='entidade' placeholder='Entidade' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputCategoriaAlternativo label='Categoria Movimento' name='categoria' placeholder='Categoria Movimento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputCategoriaAlternativo label='Categoria Lançamento' name='categoria_lancamento' placeholder='Categoria Lançamento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputStatusMovimentoFinanceiro label='Status' name='status' placeholder='Status' multiplo propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Movimento Inicial" name="data_inicial_movimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_movimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Movimento Final" name="data_final_movimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_movimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Fechamento Inicial" name="data_inicial_fechamento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_fechamento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Fechamento Final" name="data_final_fechamento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_fechamento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputUsuario label="Usuário Responsável" name="usuario" placeholder='Usuário Responsável' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />
            ],
            colunas: [
                { label: 'Cod.', name: 'id', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, classe: 'bold' },
                { label: 'Entidade', name: 'entidade', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Conta Bancária', name: 'conta', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Categoria', name: 'categoria', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data', name: 'data', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor (R$)', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Lançamento (R$)', name: 'valor_lancamento', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Liquidação (R$)', name: 'valor_liquidacao', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Descrição', name: 'descricao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Número do Doc', name: 'numero_documento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Usuário Responsável', name: 'usuario', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Referência', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Entidade', name: 'entidade', name_coluna: 'entidade' },
                { label: 'Categoria Movimento', name: 'categoria', name_coluna: 'categoria' },
                { label: 'Categoria Lançamento', name: 'categoria_lancamento', name_coluna: 'categoria_lancamento' },
                { label: 'Movimento', name: 'movimento', name_coluna: 'movimento' },
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Data Movimento Inicial', name: 'data_inicial_movimento', name_coluna: 'data_inicial_movimento' },
                { label: 'Data Movimento Final', name: 'data_final_movimento', name_coluna: 'data_final_movimento' },
                { label: 'Data Fechamento Inicial', name: 'data_inicial_fechamento', name_coluna: 'data_inicial_fechamento' },
                { label: 'Data Fechamento Final', name: 'data_final_fechamento', name_coluna: 'data_final_fechamento' },
                { label: 'Usuário', name: 'usuario', name_coluna: 'usuario' },
            ],
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento) => relatorioMovimentoAnalitico(dados, arrayConfigCol, configOrdenamento),
        },
        {
            titulo: 'Movimento Receita',
            tipo: MODULOS_SISTEMA.RELATORIO_MOVIMENTO_RECEITA,
            agrupado: true,
            url: '/financeiro/relatorioMovimentoReceita',
            retiraDataHeader: true,
            numerarLinha: false,
            campos: [
                // <InputMonthPicker label="Referência" name="referencia" type="text"
                //     setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} max={new Date().getFullYear()} min={2022} />,
                <InputMovimentoFinanceiro label='Movimento' name='movimento' placeholder='Movimento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo={false} obrigatorio />,
                <InputEntidade label='Entidade' name='entidade' placeholder='Entidade' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputEmpresa label="Prestador/Empresa" placeholder="Prestador/Empresa" name="nota_fiscal_empresa" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputClienteFinanceiroV3 label="Tomador/Cliente" placeholder="Tomador/Cliente" name="nota_fiscal_cliente" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputCategoriaAlternativo label='Categoria Movimento' name='categoria' placeholder='Categoria Movimento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputCategoriaAlternativo label='Categoria Lançamento' name='categoria_lancamento' placeholder='Categoria Lançamento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputStatusMovimentoFinanceiro label='Status' name='status' placeholder='Status' multiplo propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} />,
                <InputStatusNota label="Status NFSe" placeholder="Status NFSe" name="nota_fiscal_status_nota" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputStatusEnvioNota label="Status Envio" placeholder="Status Envio" name="nota_fiscal_status_envio" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSimNao label="Possui NFSe?" name="nota_fiscal_possui_nfse" type="select" placeholder="Possui NFSe?" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro NFSe Inicial" name="nota_fiscal_data_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("nota_fiscal_data_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro NFSe Final" name="nota_fiscal_data_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("nota_fiscal_data_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Emissão Inicial" name="nota_fiscal_data_emissao_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("nota_fiscal_data_emissao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Emissão Final" name="nota_fiscal_data_emissao_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("nota_fiscal_data_emissao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Movimento Inicial" name="data_inicial_movimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_movimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Movimento Final" name="data_final_movimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_movimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Fechamento Inicial" name="data_inicial_fechamento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_fechamento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Fechamento Final" name="data_final_fechamento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_fechamento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputUsuario label="Usuário Responsável" name="usuario" placeholder='Usuário Responsável' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />
            ],
            colunasCsv: [
                { label: 'Entidade ', name: 'entidade_movimento', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data Movimento', name: 'data_movimento', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Atendimento', name: 'atendimento', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cliente', name: 'entidade', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Sócio(s)', name: 'socio', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Estab./Op. de Saúde', name: 'estab_op_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Descrição', name: 'descricao_lancamento', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data Liquidação', name: 'data_liquidacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Apresentado', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Retenções', name: 'total_retencao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Liquidado', name: 'valor_liquidado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Tipo Pagamento', name: 'tipo_pagamento', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
            ],
            colunas: [
                { label: 'Cod.', name: 'id', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, classe: 'bold' },
                { label: 'Entidade', name: 'entidade', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Conta Bancária', name: 'conta', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Categoria', name: 'categoria', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data Movimento', name: 'data', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor (R$)', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Recebido (R$)', name: 'valor_recebido', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Descrição', name: 'descricao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Número do Doc', name: 'numero_documento', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Status', name: 'status', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Usuário Responsável', name: 'usuario', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                // { label: 'Cod.', name: 'visto', visivel: false, classe: 'bold', lista: true },
                // { label: 'P', name: 'visto', visivel: false, classe: 'bold', lista: true },
                // { label: 'Aten.', name: 'visto', visivel: false, classe: 'bold', lista: true },
                // { label: 'Cliente', name: 'visto', visivel: false, classe: 'bold', lista: true },
                // { label: 'Sócio(s)', name: 'visto', visivel: false, classe: 'bold', lista: true },
                // { label: 'Estab./OP. de Saúde', name: 'visto', visivel: false, classe: 'bold', lista: true },
                // { label: 'Valor', name: 'visto', visivel: false, classe: 'bold', lista: true },
                // { label: 'Data Liquidacao', name: 'visto', visivel: false, classe: 'bold', lista: true },
                // { label: 'Valor Liquidado', name: 'visto', visivel: false, classe: 'bold', lista: true },
                { label: 'Cod.', name: 'codigo_lancamento', visivel: false, classe: 'bold', lista: true },
                { label: 'Retenções', name: 'retencoes', visivel: true, classe: 'bold', lista: true },
                { label: 'Visto', name: 'visto', visivel: false, classe: 'bold', lista: true },
                { label: 'Descrição', name: 'descricao_lancamento', visivel: true, classe: 'bold', lista: true },
            ],
            data: [],
            colunasFiltros: [
                // { label: 'Referência', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Movimento', name: 'movimento', name_coluna: 'movimento' },
                { label: 'Entidade', name: 'entidade', name_coluna: 'entidade' },
                { label: 'Categoria Movimento', name: 'categoria', name_coluna: 'categoria' },
                { label: 'Categoria Lançamento', name: 'categoria_lancamento', name_coluna: 'categoria_lancamento' },
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Data Movimento Inicial', name: 'data_inicial_movimento', name_coluna: 'data_inicial_movimento' },
                { label: 'Data Movimento Final', name: 'data_final_movimento', name_coluna: 'data_final_movimento' },
                { label: 'Data Fechamento Inicial', name: 'data_inicial_fechamento', name_coluna: 'data_inicial_fechamento' },
                { label: 'Data Fechamento Final', name: 'data_final_fechamento', name_coluna: 'data_final_fechamento' },
                { label: 'Usuário', name: 'usuario', name_coluna: 'usuario' },
                { label: 'Prestador/Empresa', name: 'nota_fiscal_empresa_id', name_coluna: 'nota_fiscal_empresa' },
                { label: 'Tomador/Cliente', name: 'nota_fiscal_cliente_id', name_coluna: 'nota_fiscal_cliente' },
                { label: 'Data Registro NFSe Inicial', name: 'nota_fiscal_data_inicial', name_coluna: 'nota_fiscal_data_inicial' },
                { label: 'Data Registro NFSe Final', name: 'nota_fiscal_data_final', name_coluna: 'nota_fiscal_data_final' },
                { label: 'Data Emissão Inicial', name: 'nota_fiscal_data_emissao_inicial', name_coluna: 'nota_fiscal_data_emissao_inicial' },
                { label: 'Data Emissão Final', name: 'nota_fiscal_data_emissao_final', name_coluna: 'nota_fiscal_data_emissao_final' },
                { label: 'Status Envio', name: 'nota_fiscal_status_envio_id', name_coluna: 'nota_fiscal_status_envio' },
                { label: 'Status Nota', name: 'nota_fiscal_status_id', name_coluna: 'nota_fiscal_status_nota' },
                { label: 'Possui NFSe?', name: 'nota_fiscal_possui_nfse', name_coluna: 'nota_fiscal_possui_nfse' },
            ],
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioMovimentoReceita(dados, arrayConfigCol, configOrdenamento, htmlHeader),
        }
    ]


    const submit = async (values, propsFormik) => {
        if (relatorioSelecionado === MODULOS_SISTEMA.RELATORIO_MOVIMENTO_SINTETICO) {
            if (values && !values.referencia && (!values.data_inicial_movimento && !values.data_final_movimento && !values.data_inicial_registro && !values.data_final_registro)) {
                mostrarToast('erro', 'Escolha ao menos um filtro! Referência, Data Movimento ou Data Registro')
                return;
            }
        } else if (relatorioSelecionado === MODULOS_SISTEMA.RELATORIO_MOVIMENTO_RECEITA) {
            if (values && !values.movimento && (!values.data_inicial_movimento && !values.data_final_movimento)) {
                mostrarToast('erro', 'Escolha ao menos um Movimneto, ou Data Movimento')
                return

            } else {

                let inicio = moment(values.data_inicial_movimento, "DD/MM/YYYY");
                let fim = moment(values.data_final_movimento, "DD/MM/YYYY");

                var diferenca = fim.diff(inicio, 'days');

                if (diferenca > 31) {
                    mostrarToast('erro', 'A diferença entre as datas é maior que 31 dias.')
                    return
                }
            }
        }
        else {
            if (values && !values.referencia && !values.data_inicial_movimento && !values.data_final_movimento && !values.data_inicial_fechamento && !values.data_final_fechamento && !values.movimento) {
                mostrarToast('erro', 'Escolha ao menos um filtro! Referência, Movimento, Data Movimento ou Data Fechamento')
                return;
            }
        }
        setCarregando(true)
        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]

        setShowModalFiltros(false)
        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio) {
            setFiltros(Object.keys(values).map((x, idx, array) => {
                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: x === 'duracao_minima' || x === 'duracao_maxima' ? valorAtual.split(':')[0].replace(/_/g, '').toString().padStart(2, '0') + 'h ' +
                        valorAtual.split(':')[1].split(/_/g, '').toString().padStart(2, '0') + 'min' : typeof valorAtual === 'string' ? valorAtual : valorAtual.length ?
                            valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                })
            }
            ).filter(x => x))
        }
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }
        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{ aposRelatorio: cloneRelatorioAtual.aposRelatorio, retiraDataHeader: cloneRelatorioAtual.retiraDataHeader !== undefined ? cloneRelatorioAtual.retiraDataHeader : false, numerarLinha: cloneRelatorioAtual.numerarLinha !== undefined ? cloneRelatorioAtual.numerarLinha : true, colunas: cloneRelatorioAtual.colunas, colunasCsv: cloneRelatorioAtual.colunasCsv ? cloneRelatorioAtual.colunasCsv : null, data: cloneRelatorioAtual.data, agrupado: cloneRelatorioAtual?.agrupado || false, relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico ? (dados, arrayConfigCol, configOrdenamento, htmlHeader) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento, htmlHeader) : null }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: filtros })
    }, [filtros])

    const changeTipo = (value) => {

        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({})
        // const index = tipoRelatorio.findIndex(x => x.value === value)
        setValuesFiltros({ tipo_relatorio: value, referencia: value?.value === MODULOS_SISTEMA.RELATORIO_MOVIMENTO_RECEITA ? '' : moment().format('MM/YYYY') })
        setRelatorioSelecionado(value?.value)
    }

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })}
            agrupamento={[{ coluna: 'nome', descricao: 'nome' }]}>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        <InputTipoRelatorioPersonalizado label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                            propsFormik={propsFormik} modulos={relatoriosPorTipo(propsFormik).map(x => x.tipo)} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} />
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}
                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}

