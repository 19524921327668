import React, { useState, useEffect, useRef } from 'react'
import { Alterar, AlterarPersonalizado, EnviarNotificacao, Excluir, LinkBotao, Visualizar, MarcarFolga } from '../../../../components/Acoes'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { mostrarToast } from '../../../../components/Toasts'
import { consultar, excluir, get } from '../../../../util/AcessoApi'
import { getUsuario } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { mensagens } from '../../../../util/Utilitarios'
import { useHistory } from 'react-router-dom'

const ConsultaFeriados = (props) => {
    const [data, setData] = useState([])
    const target = useRef(null)
    const history = useHistory()

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-direito-folga',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Sigla',
                        accessor: 'sigla',
                    },
                    {
                        Header: 'Sócio',
                        accessor: 'nome',
                        filtro: {
                            tipo: 'socio',
                            idColuna: 'direito_folga.socio_id'
                        },
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtroSomenteModal: true
                    },
                    {
                        Header: 'Tipo Direito',
                        accessor: 'tipo_direito_folga',
                        filtro: {
                            tipo: 'tipo_direito_folga',
                            idColuna: 'direito_folga_tipo_id'
                        },
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtroSomenteModal: true
                    },
                    {
                        Header: 'Data',
                        accessor: 'data_formatada',
                        ordenamento: {
                            tipo: 'data'
                        },
                    },
                    {
                        Header: 'Data Inicial',
                        accessor: 'data_inicial',
                        ordenamento: {
                            tipo: 'data',
                        },
                        visivel: false,
                        filtro: {
                            tipo: 'data_inicial',
                            idColuna: 'direito_folga.data_inicio',
                            date: true
                        }
                    },
                    {
                        Header: 'Data Final',
                        accessor: 'data_final',
                        ordenamento: {
                            tipo: 'data',
                        },
                        visivel: false,
                        filtro: {
                            tipo: 'data_final',
                            idColuna: 'direito_folga.data_fim',
                            date: true
                        }
                    },
                    {
                        Header: 'Mudança/Troca',
                        accessor: 'troca',
                        ordenamento: {
                            tipo: 'texto'
                        },
                    },
                    {
                        Header: 'Utilizado/Marcado',
                        accessor: 'marcacao',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'input_sim_nao',
                            multiplo: false,
                            idColuna: 'utilizado_marcado'
                        }
                    },
                    {
                        Header: 'Ano',
                        accessor: 'ano',
                        visivel: false,
                        filtro: {
                            tipo: 'ano_direito_folga',
                            idColuna: 'ano'
                        },
                        filtroSomenteModal: true
                    },
                    {
                        Header: 'Mês',
                        accessor: 'mes',
                        visivel: false,
                        filtro: {
                            tipo: 'mes_direito_folga',
                            idColuna: 'data_mes'
                        },
                        filtroSomenteModal: true
                    },
                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro_formatada',
                        ordenamento: {
                            tipo: 'data'
                        },
                    },
                ],
            },
        ],
        []
    )

    const caminho = {
        cadastro: '/administracao/direitofolga/cadastro',
    }

    const exclusao = propsAcao => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }
        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length) {
                excluir('/administracao/direitofolga', propsAcao.id,
                    () => {
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        console.log('erro ', e)
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            }
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const maisAcoesObj = [
        (propsAcao) => <Visualizar to={'/administracao/direitofolga/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/administracao/direitofolga/alteracao/' + propsAcao.id} />,
        (propsAcao) => <Excluir to={'/administracao/direitofolga/consulta/'} action={() => exclusao(propsAcao)} />,
        // (propsAcao) => !propsAcao.row.direito_folga_id ? <MarcarFolga icone={'icon-Check'} texto={'Marcar Folga'} to={{ pathname: '/administracao/ferias/marcacao/cadastro', params: { direito_folga: { data: propsAcao.row.data_formatada, socio: propsAcao.row.socio, id: propsAcao.id } } }} /> : <></>,
        (propsAcao) => !propsAcao.row.direito_folga_id ? <MarcarFolga icone={'icon-Check'} texto={'Marcar Folga'} action={() => { history.push({pathname: '/administracao/ferias/marcacao/cadastro', state: {item: propsAcao.row}}) }} /> : <></>
        
    ]

    return (
        <>
            <div>
                <DataGridAlternativa target={target} data={data} acoesObj={maisAcoesObj} columns={columns} caminhoBase='/administracao/direitofolga' setData={setData} acoes={true} url={caminho} modulo={props.modulo}
                    titulo={'Consulta Direito Folga'} tituloLista={'Lista Direito Folga'} urlCadastro='/administracao/direitofolga/cadastro' icone={'icon-Air-Balloon'} caminhoConsultaFiltros='/administracao/direitofolgapaginacao' />
            </div>
        </>
    )
}

export default ConsultaFeriados