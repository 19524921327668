import React, { useState } from 'react'
import { InputSocioAtivos, InputSimNao } from '../../../../components/InputsAutoComplete'
import { HeaderCadastro } from '../../../../components/Headers'
import { ModalCadastro } from '../../../../components/Modais'
import { Formik, Form } from 'formik'
import { InputPadrao, InputNumero, InputDatePicker } from '../../../../components/Inputs'
import { BotaoPadrao } from '../../../../components/Botoes'
import Yup, { validaData, dataMenorAtual, periodoData } from '../../../../util/Validacoes'
import { removeAcentos } from '../../../../util/FuncoesComuns'
import Atalho from '../../../../util/Atalho'
import { sortTable } from '../../../../util/Utilitarios'
import { mostrarToast } from '../../../../components/Toasts'

const Cadastro = props => {
    const [pesquisa, setPesquisa] = useState('')
    const [msgBotao, setMsgBotao] = useState('Adicionar')
    const [salvando, setSalvando] = useState(false)
    const [emptyValues, setDefaults] = useState({
        trabalha_final_de_semana: { value: 'true', label: 'Sim' },
        ordenamento: props.data && props.data.length > 0 ? parseInt(props.data[props.data.length - 1].ordenamento) + 1 : 1

    })




    const [values, setValues] = useState(emptyValues)
    const Submit = (array, { resetForm }) => {
        setSalvando(true);
        let copiaDependentes = [...props.data]
        if (!array.socio.length)
            array.socio = [array.socio];
        for (let index = 0; index < array.socio.length; index++) {
            const socioGrupo = { ...array };
            socioGrupo.socio = socioGrupo.socio[index];
            socioGrupo.ordenamento = socioGrupo.ordenamento + index;
            if (copiaDependentes.findIndex(x => x.socio.value === socioGrupo.socio.value && x.verificador !== socioGrupo.verificador) > -1) {
                mostrarToast('erro', `Sócio ${socioGrupo.socio.label} já pertencente ao Grupo.`);
                return;
            }
            if (copiaDependentes.findIndex(x => x.ordenamento === socioGrupo.ordenamento && x.verificador !== socioGrupo.verificador) > -1) {
                mostrarToast('erro', `A Ordem ${socioGrupo.ordenamento} informada já existe.`);
                return;
            }
            if (socioGrupo.verificador) {
                let index = copiaDependentes.findIndex(x => x.verificador === socioGrupo.verificador)
                copiaDependentes[index] = socioGrupo
            }
            else {
                socioGrupo.verificador = Math.random()
                copiaDependentes.push(socioGrupo)
            }

            resetForm({})



            if (msgBotao === 'Alterar')
                props.setAbrirModal(!props.abrirModal)



            setSalvando(false)
            const copia = { ...emptyValues };
            copia.ordenamento = parseInt(socioGrupo.ordenamento) + 1;
            setDefaults(copia)
            setValues(copia)
        }
        mostrarToast('sucesso', 'Operação realizada com sucesso!');
        props.setData(copiaDependentes)
    }
    const Delete = index => {
        let copiaDependentes = [...props.data]
        copiaDependentes.splice(index, 1);
        props.setData(copiaDependentes)
    }

    return (
        <>
            <HeaderCadastro
                titulo={'Sócio da Grupo'}
                pesquisa={true}
                valorPesquisa={pesquisa}
                onChangePesquisa={pesquisa => setPesquisa(pesquisa)}
                classeChildren='space-between-100' >
                <BotaoPadrao
                    type='button'
                    click={_ => {
                        setValues(emptyValues)
                        props.setAbrirModal(!props.abrirModal);
                        setMsgBotao('Adicionar')
                    }}
                    classe='btn-primary primary p-5rem height-auto m-r-15'
                    texto={<><span> Adicionar</span> <span class='icon-Users fw'>+</span></>} />
            </HeaderCadastro>
            <div className='card mb-20'>
                <div className='card-body'>
                    <table className='table tabela-endereco table-responsive-md table-striped tabela' id='tbl-socio-grupo'>
                        <thead>
                            <tr>
                                <th onClick={() => sortTable('tbl-socio-grupo', 0, 'numero')} style={{ width: '5%' }}>#</th>
                                <th onClick={() => sortTable('tbl-socio-grupo', 1)} style={{ width: '35%' }}>Sócio</th>
                                <th onClick={() => sortTable('tbl-socio-grupo', 2)} style={{ width: '10%' }}>Trabalha final de semana?</th>
                                <th onClick={() => sortTable('tbl-socio-grupo', 3, 'numero')} style={{ width: '10%' }}>Ordem</th>
                                <th onClick={() => sortTable('tbl-socio-grupo', 4)} style={{ width: '20%' }}>Observacao</th>
                                <th style={{ width: '20%' }} className='text-center'>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.length ?
                                props.data.filter(x => removeAcentos(x.observacao ? x.observacao.toLowerCase() : "").includes(removeAcentos(pesquisa.toLowerCase()))
                                    || removeAcentos(x.grupo.label.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())))
                                    .map((socioGrupo, index) =>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{socioGrupo.socio.label}</td>
                                            <td>{socioGrupo.trabalha_final_de_semana.label}</td>
                                            <td>{socioGrupo.ordenamento}</td>
                                            <td>{socioGrupo.observacao}</td>
                                            <td className='text-center'>
                                                <span className='icon-consulta icon-Pen'
                                                    onClick={_ => { setValues(socioGrupo); props.setAbrirModal(true); setMsgBotao('Alterar') }} />
                                                <span className='icon-consulta icon-New-Trash' onClick={target => Delete(index)} />
                                            </td>
                                        </tr>
                                    ) : <tr><td colspan='6' className='text-center'>Nenhum Registro Cadastrado</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <ModalCadastro titulo={'Dados do Grupo'} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    enableReinitialize={true}
                    initialValues={values}
                    validationSchema={Yup.object({
                        id: Yup.string().nullable(),
                        socio: Yup.string().nullable().required(),
                        trabalha_final_de_semana: Yup.string().nullable().required(),
                        ordenamento: Yup.string().nullable()
                    })}
                    onSubmit={Submit}>
                    {propsFormik => <Form>
                        <div className='modal-body-cadastro'>
                            <InputPadrao label='Id' name='id' type='hidden' obrigatorio={false} />
                            {propsFormik.values.verificador ?
                                (<InputSocioAtivos obrigatorio={true} label="Sócio" name="socio" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} autoFocus focarAposSubmit={true} />)
                                : (<InputSocioAtivos obrigatorio={true} label="Sócio" name="socio" multiplo classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} autoFocus focarAposSubmit={true} />)
                            }
                            <InputSimNao label="Trabalha final de semana?" name="trabalha_final_de_semana" placeholder="Trabalha final de semana?" obrigatorio={true} classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} />
                            <InputNumero propsFormik={propsFormik} max={99} label='Ordem' name='ordenamento' type='text' classeTooltip={'tooltip-input-modal'} classDiv='col-12' />
                            <InputPadrao label='Observacao' name='observacao' type='text' classeTooltip={'tooltip-input-modal'} />
                            <Atalho formik={propsFormik} salvando={salvando} />
                        </div>
                        <div className='direita container-botoes'>
                            <BotaoPadrao texto={msgBotao} />
                        </div>
                    </Form>}
                </Formik>
            </ModalCadastro>
        </>)
}

export default Cadastro