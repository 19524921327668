import React, { useState, useEffect, useRef } from 'react'
import DataGridAlternativa from '../../../components/DataGridAlternativa'
import { Alterar, AlterarLiberacaoFerias, Excluir, Visualizar } from '../../../components/Acoes'
import { mostrarToast } from '../../../components/Toasts'
import { mensagens } from '../../../util/Utilitarios'
import { excluir, get } from '../../../util/AcessoApi'
import { getUsuario } from '../../../util/Configuracoes'
import { ACOES } from '../../../util/Enums'

const ConsultaLiberacaoFerias = (props) => {
    const [data, setData] = useState([])
    const target = useRef(null)

    const columns = React.useMemo(
        () => [
            {
                id: 'liberacao-ferias',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Sócio',
                        accessor: 'socio_nome',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'socio',
                            idColuna: 'ferias_liberacao.socio_id'
                        }
                    },
                    {
                        Header: 'Referência',
                        accessor: 'periodo_gozo',
                        ordenamento: {
                            tipo: 'numero',
                        },
                        filtro: {
                            tipo: 'periodo_ano_gozo',
                            idColuna: 'periodo_gozo_id'
                        }
                    },
                    {
                        Header: 'Período Férias',
                        accessor: 'periodo_ferias',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Data Início',
                        accessor: 'data_inicio_formatada',
                        ordenamento: {
                            tipo: 'data'
                        },
                    },
                    {
                        Header: 'Data Término',
                        accessor: 'data_termino_formatada',
                        ordenamento: {
                            tipo: 'data'
                        },
                    },
                    {
                        Header: 'Usuário Escalador',
                        accessor: 'escalador_nome',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro_formatada',
                        ordenamento: {
                            tipo: 'data'
                        },
                    },

                ],

            },
        ],
        []
    )

    const exclusao = propsAcao => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }
        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length) {

                Promise.resolve(get('/administracao/verificarliberacaoferias', { id: propsAcao.row.id })).then(res => {
                    if (res.marcado) {
                        mostrarToast('erro', 'Operação não permitida! Registro já utilizado em uma marcação')
                    } else {
                        excluir('/administracao/liberacaoferias', propsAcao.id,
                            (val) => {
                                console.log('val: ', val);
                                target.current.atualizar();
                                mostrarToast('sucesso', mensagens['sucesso'])
                            },
                            (e) => {
                                console.log('E ', e)
                                if (e.response && e.response.data && e.response.data.erros)
                                    e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                                else
                                    mostrarToast('erro', mensagens.erro)
                            })

                    }
                }).catch(error => mostrarToast('erro', mensagens['erro']))

            }
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const alteracao = async (propsAcao) => {

        await Promise.resolve(get('/administracao/verificarliberacaoferias', { id: propsAcao.row.id })).then(res => {
            if (res.marcado) {
                mostrarToast('erro', 'Operação não permitida! Registro já utilizado em uma marcação')
            } else {
                props.history.push('/administracao/ferias/liberacaoferias/alteracao/' + propsAcao.row.id)

            }
        }).catch(error => mostrarToast('erro', mensagens['erro']))

    }

    const maisAcoesObj = [

        (propsAcao) => <Visualizar to={'/administracao/ferias/liberacaoferias/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <AlterarLiberacaoFerias action={() => alteracao(propsAcao)} />,
        (propsAcao) => <Excluir to={'/administracao/ferias/liberacaoferias/consulta/'} action={() => exclusao(propsAcao)} />,
    ]

    return (
        <>
            <div>
                <DataGridAlternativa target={target} data={data} columns={columns} setData={setData} acoes={true} acoesObj={maisAcoesObj} caminhoBase='/administracao/ferias/liberacaoferias' modulo={props.modulo} titulo={'Consulta Liberação Férias'}
                    tituloLista={'Lista Liberação Férias'} ImpressaoOff={true}
                    urlCadastro='/administracao/ferias/liberacaoferias/cadastro' icone={'icon-File-ClipboardFileText'} caminhoConsultaFiltros='/administracao/liberacaoferiaspaginacao' />
            </div>
        </>
    )
}

export default ConsultaLiberacaoFerias