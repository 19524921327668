import React, { useRef, useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { InputSearch, InputSwitch } from './Inputs'
import { useWindowSize } from '../util/Utilitarios'
import { Link } from 'react-router-dom'
import { BotaoPadrao, BotaoMenu } from './Botoes'
import { LembretesBadget, RegistroAtividadeBadget, ResumoBadget, ResumoEscalasBadget, ResumoGruposBadget, ResumoAgrupadoBadget } from '../areas/administracao/lembrete/pages/LembreteNaAgenda'
import { get } from '../util/AcessoApi'
import { getUsuario } from '../util/Configuracoes'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { MODULO } from '../util/Enums';

const HeaderCadastro = (props) => {
    return (<div className="row">
        <div className="col-12">
            <div className="mb-2 row-space-between">
                <div className={`display-flex ${props.classeChildren || ''}`}>
                    <div className='row ' style={{ marginLeft: 0, cursor: props.minimizar ? 'pointer' : '' }} onClick={() => {
                        if (props.minimizar) {

                            let cloneArray = [...props.arrayMinimizar]
                            cloneArray[props.idxMinimizar] = !cloneArray[props.idxMinimizar]
                            props.setArrayMinimizar(cloneArray)
                        }
                    }}>
                        <h3>
                            <span>{props.titulo}</span>
                            {
                                props.infoAdicional ?
                                    props.infoAdicional :
                                    <></>
                            }
                            {props.validacaoRegistroUtilizado &&
                                <h6>
                                    <span class="" style={{ fontSize: 15, color: ' #d50000' }} >{props.msgValidacaoRegistroUtilizado}</span>
                                </h6>
                            }

                        </h3>

                        {props.minimizar &&
                            <>
                                <div className='icone-header'> {props.arrayMinimizar && props.arrayMinimizar[props.idxMinimizar] ? <FaAngleDown style={{ color: '#c9c9c9', fontSize: 20 }}></FaAngleDown>
                                    :
                                    <FaAngleUp style={{ color: '#c9c9c9', fontSize: 20 }}></FaAngleUp>
                                }
                                </div>
                            </>}
                        {props.switch &&
                            <>
                                <div className='icone-header-switch'>
                                    <InputSwitch
                                        tamanho={'no-class'}
                                        name={"acoes"}
                                        size="md"
                                        checked={props.liquidar}
                                        onChange={() => {
                                            props.setLiquidar(!props.liquidar)
                                        }}
                                    />
                                </div>
                            </>}
                        {!props.subtitulo && <label>&nbsp;</label> || <label>{props.subtitulo}</label>}
                        {props.pesquisa && <InputSearch value={props.valorPesquisa} onChange={(value) => props.onChangePesquisa(value.target.value)} />}
                    </div>


                    <div className='lh-2 ml-3'>
                        {props.children}
                    </div>
                </div>
                {props.link && <nav className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {props.link.map((Link, index) => {
                            return <li key={index} className="breadcrumb-item titulo-link"><a aria-current="page" className="active" href="/"><span><Link /></span></a></li>
                        })}
                    </ol>
                </nav>}
            </div>
            <div className="separator mb-3"></div>
        </div>
    </div>)
}

const HeaderH5Cadastro = (props) => {
    return (<div className="row">
        <div className="col-12">
            <div className="mb-2 row-space-between">
                <div className={`display-flex ${props.classeChildren || ''}`}>
                    <div>
                        <h5><span>{props.titulo}</span></h5>
                        {props.subtitulo && <label>{props.subtitulo}</label>}
                        {props.pesquisa && <InputSearch value={props.valorPesquisa} onChange={(value) => props.onChangePesquisa(value.target.value)} />}
                    </div>
                    <div className='lh-2 ml-3'>
                        {props.children}
                    </div>
                </div>
                {props.link && <nav className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {props.link.map((Link, index) => {
                            return <li key={index} className="breadcrumb-item titulo-link"><a aria-current="page" className="active" href="/"><span><Link /></span></a></li>
                        })}
                    </ol>
                </nav>}
            </div>
            <div className="separator mb-3"></div>
        </div>
    </div>)
}

const HeaderConsulta = (props) => {
    const size = useWindowSize();
    return (<div className="row">
        <div className="col-12">
            <div className={`mb-2 ${size[0] > 780 && 'row-space-between' || ''}`}>
                <div className={`display-flex ${props.classeChildren || ''}`}>
                    <h3 className={`${size[0] <= 781 ? 'titulo-header-responsivo' : 'mb-2'}`}>
                        <span className={`${props.icone} mr-3`}></span>
                        <span>{props.titulo}</span>
                        {
                            props.subtitulo ?
                                <props.subtitulo /> :
                                <></>
                        }
                    </h3>
                    {props.pesquisa && <InputSearch value={props.valorPesquisa} onChange={(value) => props.onChangePesquisa(value.target.value)} />}
                    <div className='lh-2 ml-3'>
                        {props.children}
                    </div>
                </div>
                {props.link && <nav className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {props.link.map((Link, index) => {
                            return <li key={index} className="breadcrumb-item titulo-link"><a aria-current="page" className="active" href="/"><span><Link /></span></a></li>
                        })}
                    </ol>
                </nav>}
                {
                    props.urlCadastro && <div className={`header-botoes  ${size[0] < 781 && 'text-right' || ''}`}>
                        {
                            props.actionImportacao &&
                            <BotaoPadrao texto="Importar" classe={`${size[0] <= 781 ? 'btn-responsive width-100 btn btn-primary primary' : ''}`}
                                click={_ => props.actionImportacao()} /> || ''
                        }
                        {
                            props.conferencia &&
                            <Link to={props.conferencia}>
                                <BotaoPadrao texto="Conferência" classe={`${size[0] <= 781 ? 'btn-responsive width-100 btn btn-primary primary' : ''}`} />
                            </Link>

                        }
                        {
                            props.manutencaoLote &&
                            <Link to={props.manutencaoLote}>
                                <BotaoPadrao texto="Manutenção de Lote" classe={`${size[0] <= 781 ? 'btn-responsive width-100 btn btn-primary primary' : ''}`} />
                            </Link>

                        }
                        {
                            props.manutencaoStatus &&
                            <Link to={props.manutencaoStatus}>
                                <BotaoPadrao texto="Manutenção Status" classe={`${size[0] <= 781 ? 'btn-responsive width-100 btn btn-primary primary' : ''}`} />
                            </Link>
                        }
                        {
                            props.manutencaoStatusParticular &&
                            <Link to={props.manutencaoStatusParticular}>
                                <BotaoPadrao texto="Manutenção Status" classe={`${size[0] <= 781 ? 'btn-responsive width-100 btn btn-primary primary' : ''}`} />
                            </Link>
                        }
                        {
                            props.atualizarOperadora &&
                            <BotaoPadrao texto="Importar Operadoras" classe={`${size[0] <= 781 ? 'btn-responsive width-100 btn btn-primary primary' : ''}`}
                                click={_ => props.atualizarOperadora()} /> || ''
                        }
                        {
                            props.atualizarEstabelecimento &&
                            <BotaoPadrao texto="Importar Estabelecimentos" classe={`${size[0] <= 781 ? 'btn-responsive width-100 btn btn-primary primary' : ''}`}
                                click={_ => props.atualizarEstabelecimento()} /> || ''
                        }
                        {
                            props.gerarLotes &&
                            <BotaoPadrao texto="Gerar Lotes" classe={`${size[0] <= 781 ? 'btn-responsive width-100 btn btn-primary primary' : ''}`}
                                click={_ => props.gerarLotes()} /> || ''
                        }
                        <Link to={props.urlCadastro}>
                            <BotaoPadrao texto="Cadastrar" classe={`${size[0] <= 781 ? 'btn-responsive width-100 btn btn-primary primary' : ''}`} />
                        </Link>
                    </div>
                }
            </div>
            <div className="separator mb-3"></div>
        </div>
    </div>)
}

const HeaderAgenda = (props) => {
    const header = useRef(null)
    const [className, setClassName] = useState([]);
    const body = document.getElementsByTagName('body')[0];
    const [width, height] = useWindowSize();
    const [possuiPermissaoLembrete, setPossuiPermissaoLembrete] = useState(false);
    const [possuiPermissaoRegistroAtividade, setPossuiPermissaoRegistroAtividade] = useState(false);
    const [possuiPermissaoResumo, setPossuiPermissaoResumo] = useState(false);
    const [possuiPermissaoResumoGrupos, setPossuiPermissaoResumoGrupos] = useState(false);
    const user = getUsuario()


    useEffect(() => {
        Promise.resolve(get('/controleAcesso/permissao', { usuario_id: user.id, modulo_id: 50, acao_id: 6 })).then(function (val) {
            setPossuiPermissaoLembrete(val.length > 0)
        })
        Promise.resolve(get('/controleAcesso/permissao', { usuario_id: user.id, modulo_id: 39, acao_id: 6 })).then(function (val) {
            setPossuiPermissaoRegistroAtividade(val.length > 0)
        })
        Promise.resolve(get('/controleAcesso/permissao', { usuario_id: user.id, modulo_id: 38, acao_id: 24 })).then(function (val) {
            setPossuiPermissaoResumo(val.length > 0)
        })
        let pathname = window.location.pathname.split("/")
        let modulo = pathname.slice(1, 3)
        Promise.resolve(get('/controleAcesso/permissao', { usuario_id: user.id, modulo_id: MODULO['/' + modulo.join('/')] || 38, acao_id: 25 })).then(function (val) {
            setPossuiPermissaoResumoGrupos(val.length > 0)
        })
    }, [])

    window.onscroll = function () { myFunction() };

    useEffect(() => {
        if (props.showModalHeader === true) {
            body.classList.add("modal-header-cadastro");
        } else {
            body.classList.remove("modal-header-cadastro");
        }
    }, [props.showModalHeader])

    const myFunction = () => {
        if (header.current && width > 900) {
            var sticky = header.current.offsetTop;
            if (window.pageYOffset > sticky) {
                body.classList.add("float-header");
                document.getElementById("headerBlank").style.height = header.current.scrollHeight + "px !important";
                setClassName(['sticky']);
            } else {
                body.classList.remove("float-header");
                setClassName([]);
            }
        }
    }

    return (
        <>
            <div className={(className.length > 0 ? ' headerBlank ' : '')} id='headerBlank' style={{ height: header.current || className.length > 0 ? header.current.scrollHeight : 0, position: className.length === 0 ? 'absolute' : 'unset' }}></div>
            <div className={"" + className.join(' ')} id="myHeader" ref={header}>

                <div className={"row " + (className.length > 0 ? ' mr-0 ml-0 ' : '')} style={{ width: '100%' }}>
                    <div className="col-12 pr-0">
                        <div className="mb-2 row-space-between row">
                            <div className={`display-flex pr-0 ${props.titulo.length > 26 ? 'col-lg-6' : 'col-lg-4'} col-sm-12 col-md-12 ${props.classeChildren || ''}`}>
                                <div>
                                    <h4>
                                        <div>
                                            <span className={"mr-2 " + (props.icone || 'icon-Calendar-3')} />
                                            <span style={width < 400 ? { fontSize: '20px' } : {}}>{props.titulo}</span>
                                        </div>
                                        {possuiPermissaoLembrete && props.setShowLembrete && <LembretesBadget filtros={props.filtros} clickCadastroLembrete={props.clickCadastroLembrete} atualizarLembrete={props.atualizarLembrete} {...props} />}
                                        {possuiPermissaoRegistroAtividade && props.setShowRegistro && <RegistroAtividadeBadget filtros={props.filtros} clickCadastroLembrete={props.clickCadastroLembrete} atualizarRegistro={props.setAtualizarRegistro} {...props} />}
                                        {/* {possuiPermissaoResumo && props.setShowResumo && <ResumoBadget filtros={props.filtros} {...props} />} */}
                                        {possuiPermissaoResumo && props.setShowResumo && <ResumoAgrupadoBadget filtros={props.filtros} {...props} />}
                                        {possuiPermissaoResumoGrupos && props.setShowResumoGrupos && <ResumoGruposBadget filtros={props.filtros} {...props} />}
                                        {possuiPermissaoResumoGrupos && props.setShowResumoEscalas && <ResumoEscalasBadget filtros={props.filtros} {...props} />}
                                    </h4>
                                    {props.textoComplemento}
                                    {props.pesquisa && <InputSearch value={props.valorPesquisa} onChange={(value) => props.onChangePesquisa(value.target.value)} />}
                                </div>
                                <div className='lh-2 ml-3'>
                                    {props.children}
                                </div>
                            </div>
                            <div className={`header-botoes botoes-animation pl-0 ${props.titulo.length > 26 ? 'col-lg-6' : 'col-lg-8'} col-sm-12 col-md-12 text-right-md`}>
                                {props.urlCadastro ? <Link to={props.urlCadastro}><BotaoPadrao texto="Cadastrar" /></Link> : <></>}
                                {props.botoes}
                            </div>
                        </div>
                        <div className={className.length > 0 ? "" : "separator mb-5"}></div>
                    </div>
                </div>
                {props.modalHeader}
            </div>
        </>)
}

const HeaderOcorrencia = (props) => {

    const [width, height] = useWindowSize();
    const size = useWindowSize();

    return (<div className="row">
        <div className="col-12">
            <div className={`mb-2 ${size[0] > 780 && 'row-space-between' || ''}`}>
                <div className={`display-flex ${props.classeChildren || ''}`}>
                    <h3 className={`${size[0] <= 781 ? 'titulo-header-responsivo' : 'mb-2'}`}>
                        <span className={`${props.icone} mr-3`}></span>
                        <span>{props.titulo}</span>
                    </h3>
                    {props.pesquisa && <InputSearch value={props.valorPesquisa} onChange={(value) => props.onChangePesquisa(value.target.value)} />}
                    <div className='lh-2 ml-3'>
                        {props.children}
                    </div>
                </div>
                {props.link && <nav className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {props.link.map((Link, index) => {
                            return <li key={index} className="breadcrumb-item titulo-link"><a aria-current="page" className="active" href="/"><span><Link /></span></a></li>
                        })}
                    </ol>
                </nav>}
                {
                    props.urlCadastro && <div className={`header-botoes  ${size[0] < 781 && 'text-right' || ''}`}>
                        <>
                            <div className='ajusta-botoes'>
                                <Link to={`${props.urlCadastro}`}>
                                    <BotaoPadrao textoTooltip="Cadastrar" icone='icon-Add' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} />
                                </Link>
                                <Link to={'/administracao/agendareserva/consulta'}>
                                    <BotaoPadrao textoTooltip="Agenda Reserva" icone='icon-Calendar-3' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} />
                                </Link>
                                <Link to={{ pathname: '/administracao/plantao-alcancaveis/consulta' }}>
                                    <BotaoPadrao click={_ => { }} icone='icon-Clock' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Plantão'} idTooltip={"padrao"} />
                                </Link>
                                <Link to={{ pathname: '/administracao/plantao-estabelecimento/consulta' }}>
                                    <BotaoPadrao click={_ => { }} icone='icon-Building' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Plantão Estabelecimento Saúde'} idTooltip={"padrao"} />
                                </Link>
                                <Link to={{ pathname: '/administracao/rotinas/consulta' }}>
                                    <BotaoPadrao click={_ => { }} icone='icon-Dec' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Rotinas'} idTooltip={"padrao"} />
                                </Link>
                            </div>
                        </>
                    </div>
                }
            </div>
            <div className="separator mb-3"></div>
        </div>
    </div>)
}


export { HeaderCadastro, HeaderConsulta, HeaderAgenda, HeaderH5Cadastro, HeaderOcorrencia }
