import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { formatarMoeda } from '../../../../util/Utilitarios';

export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/financeiro/movimentovisualizacaoassinatura', id,
            obj => {

                setDados(obj.id ? {

                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Movimento Financeiro',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Entidade', descricao: obj.entidade.label, tamanho: 'col-lg-4' },
                                { titulo: 'Data  ', descricao: obj.data, tamanho: 'col-lg-4' },
                                { titulo: 'Valor', descricao: obj.valor_formatado, tamanho: 'col-lg-4' },
                                { titulo: 'Descrição', descricao: obj.descricao, tamanho: 'col-lg-4' },
                                { titulo: 'Número do Documento', descricao: obj.numero_documento, tamanho: 'col-lg-4' },
                                {
                                    titulo: 'Conta Bancária', descricao: `<span class="texto-superior-descricao"> Banco: ${obj.conta_bancaria.codigo_banco + ' - ' + obj.conta_bancaria.banco} | Agência: ${obj.conta_bancaria.agencia} | Conta: ${obj.conta_bancaria.conta}</span>
                                <br />
                                <span>${obj.conta_bancaria.label}</span>`, tamanho: 'col-lg-4', html: true
                                },
                                { titulo: 'Status', descricao: obj.status, tamanho: 'col-lg-4' },
                                { titulo: 'Anotações', descricao: obj.observacao, tamanho: 'col-lg-4' },
                                { titulo: 'Data de Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                            ]
                        },
                        

                    ]
                } : { titulo: 'Visualização Movimento Financeiro' })
            })
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} visualizacaoAssinatura = {true} /> : null
}

