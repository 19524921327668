import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHotkeys, useIsHotkeyPressed } from 'react-hotkeys-hook';

const Atalho = (props) => {
    const [salvando, setSalvando] = useState(false)
    const [lastSave, setLastSave] = useState(new moment('01/01/0001', 'DD/MM/YYYY'))
    const isPressed = useIsHotkeyPressed();

    useHotkeys('ctrl+alt+s', () => {
        let segundosDesdeUltimoHandle = new moment().diff(lastSave, 'seconds');
        if (props.salvando == false && segundosDesdeUltimoHandle > 5) {
            props.formik.submitForm();
            setLastSave(new moment());
        }
    }, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] })

    useHotkeys('ctrl+alt+n', () => {
        let segundosDesdeUltimoHandle = new moment().diff(lastSave, 'seconds');
        if (props.salvando == false && segundosDesdeUltimoHandle > 5 && props.setSalvareNovo != undefined) {
            props.setSalvareNovo(true)
            props.formik.submitForm();
            setLastSave(new moment());
        }
    }, { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] })

    useEffect(() => {
        if (props.salvando) {
            setSalvando(props.salvando);
        }
    }, [props.salvando])

    let qtdSubmit = 0

    return <></>
}

export default Atalho