import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { formatarMoeda } from '../../../../util/Utilitarios';
import { Link } from 'react-router-dom'
const VisualizacaoGuiaConvenioPagamento = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/faturamento/visualizarguiapagamento', id,
            obj => {
                setDados(obj.codigo_coopanest ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Guia Pagamento',
                    grupos: [
                        {
                            titulo: 'Dados Paciente',
                            colunas: [
                                { titulo: 'Paciente', descricao: obj.nome_paciente, tamanho: 'col-lg-3' },
                                { titulo: 'Código de Barras', descricao: obj.codigo_barras, tamanho: 'col-lg-3' },
                                { titulo: 'Data Procedimento', descricao: obj.data_procedimento, tamanho: 'col-lg-3' },
                                { titulo: 'Status', descricao: obj.status, tamanho: 'col-lg-3' },
                                { titulo: 'Código Coopanest', descricao: obj.codigo_coopanest, tamanho: 'col-lg-3' },
                                { titulo: 'Código Grupo', descricao: obj.codigo_grupo, tamanho: 'col-lg-3' },
                                { titulo: 'Operadora Saúde', descricao: obj.operadora_saude, tamanho: 'col-lg-3' },
                                { titulo: 'Código Subdivisão', descricao: obj.codigo_subdivisao, tamanho: 'col-lg-3' },
                                { titulo: 'Estabelecimento Saúde', descricao: obj.estabelecimento_saude, tamanho: 'col-lg-3' },
                                { titulo: 'Empresa', descricao: obj.empresa, tamanho: 'col-lg-3' },
                                { titulo: 'Tipo Cooperado', descricao: obj.tipo_cooperado, tamanho: 'col-lg-3' },
                                { titulo: 'CRM', descricao: obj.crm, tamanho: 'col-lg-3' },
                                // { titulo: 'Tipo Pagamento', descricao: obj.tipo_pagamento, tamanho: 'col-lg-3' },
                                // { titulo: 'Data Pagamento', descricao: obj.data_pagamento, tamanho: 'col-lg-3' },
                                // { titulo: 'Valor Comprovante', descricao: formatarMoeda(obj.soma_detalhe_pagamento.guia), tamanho: 'col-lg-3' },
                                // { titulo: 'Valor Recurso', descricao: formatarMoeda(obj.soma_detalhe_pagamento.recurso), tamanho: 'col-lg-3' },
                            ]
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Pagamentos',
                            visivel: obj.detalhe_pagamento.length > 0,
                            aposGrupo: <tr>
                            <td className='coluna-1'></td>
                            <td className='coluna-1'></td>
                            <td className='coluna-1'></td>
                            <td className='coluna-1 text-right' style={{ fontWeight: 'bold' }}>{obj.detalhe_pagamento && obj.detalhe_pagamento.length > 0 ? formatarMoeda(obj.detalhe_pagamento.map(item => item.valor_pagamento).reduce((total, num) => parseFloat(total) + parseFloat(num))) : ''}</td>
                        </tr>,
                            thead:
                                <tr>
                                    <td className='coluna-1'>Cod. Pagamento</td>
                                    <td className='coluna-1'>Tipo Pagamento</td>
                                    <td className='coluna-1' style={{ textAlign: 'center' }}>Data Pagamento</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>Valor Pagamento (R$)</td>
                                    {/* <td className='coluna-1' style={{ textAlign: 'right' }}>Ação</td> */}
                                </tr>,
                            tbody: obj.detalhe_pagamento.map(x =>
                                <>
                                    <tr>
                                        <td><Link to={{ pathname: '/auditoria/guia-pagamento/visualizacao/' + x.pagamento_id }} target={'_blank'}>{x.pagamento_id}</Link></td>
                                        <td>{x.tipo_pagamento === 'ambos' ? 'Guia/Recurso' : x.tipo_pagamento.toUpperCase()}</td>
                                        <td style={{ textAlign: 'center' }}>{x.data_pagamento}</td>
                                        <td style={{ textAlign: 'right' }}>{formatarMoeda(x.valor_pagamento)}</td>
                                    </tr>
                                </>)
                        }
                    ]
                } : { titulo: 'Visualização Guia Pagamento' })
            })
    }, [id, props.modulo])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default VisualizacaoGuiaConvenioPagamento