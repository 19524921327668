import React, { useState, useEffect } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { Alterar, Excluir, Visualizar } from '../../../../components/Acoes'
import { getApi } from '../../../../util/Configuracoes'
import { excluir } from '../../../../util/FuncoesComuns'
import { HeaderCadastro } from '../../../../components/Headers'
import { consultar } from '../../../../util/AcessoApi'

const ConsultaSocio = (props) => {
    const [data, setData] = useState([])
    useEffect(() => {
        consultar('/administracao/socio', (dados) => setData(dados))
    }, [])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-empresa',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Sigla',
                        accessor: 'sigla',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Nome',
                        accessor: 'nome',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'consulta_socio',
                            idColuna: 'socio.id'
                        }
                    },
                    {
                        Header: 'CRM',
                        accessor: 'crm',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Empresa',
                        accessor: 'empresa_nome',
                        ordenamento: {
                            tipo: 'texto'
                        }, 
                        filtro: {
                            tipo: 'empresa',
                            idColuna: "t2.empresa"
                        }
                    },
                    {
                        Header: 'Telefone(s)',
                        accessor: 'telefones',
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'status_socio',
                            idColuna: "status"
                        }
                    },
                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro_str',
                        ordenamento: {
                            tipo: 'data'
                        },
                        filtro: {
                            tipo: 'data',
                            idColuna: "data_registro_str",
                            datePicker: true,
                            date: true
                        }
                    }
                ],
            },
        ],
        []
    )

    const acoesObj = [
        (props) => <Visualizar to={'/administracao/socio/visualizacao/' + props.id} />,
        (props) => <Alterar to={'/administracao/socio/alteracao/' + props.id} />,
        (props) => <Excluir to={'/administracao/socio/consulta/'} action={() => excluir(props.id, setData, '/socio/excluir', '/socio')} />
    ]

    const caminho = {
        cadastro: '/administracao/socio/cadastro',
    }

    return (
        <>
            <div>
                <DataGridAlternativa
                    data={data}
                    columns={columns}
                    setData={setData}
                    caminhoBase='/administracao/socio'
                    caminhoConsultaFiltros='/administracao/sociopaginacao'
                    acoes={true}
                    url={{ cadastro: '/administracao/socio/cadastro', }}
                    modulo={props.modulo}
                    titulo={'Consulta Sócio'}
                    urlCadastro='/administracao/socio/cadastro'
                    icone={'icon-Doctor'} />
            </div>
        </>
    )
}

export default ConsultaSocio