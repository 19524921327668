import React, { useState, useEffect } from 'react'
import { HeaderAgenda } from '../../../../components/Headers'
import { BotaoPadrao } from '../../../../components/Botoes'
import { BtnAcao, Excluir } from '../../../../components/Acoes'
import { ModalPadraoCadastro, ModalVisualizacao, ModalCalendar } from '../../../../components/Modais'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens } from '../../../../util/Utilitarios'
import { consultar, get, excluir } from '../../../../util/AcessoApi'
import { InputSimples } from '../../../../components/Inputs'
import { removeAcentos } from '../../../../util/FuncoesComuns'
import { getUsuario } from '../../../../util/Configuracoes'
import { useWindowSize, getBetweenDates } from '../../../../util/Utilitarios'
import { CadastroAgendaEscalador } from './Cadastro'
import { ACOES } from '../../../../util/Enums'
import moment from "moment"
import 'moment/locale/pt-br'
import '../css/agenda.css'
import Calendario from '../../../../components/Calendario'
import { ModalDuasOpcoes } from '../../../../components/Modais'
import InfiniteCalendar from 'react-infinite-calendar';

const AgendaEscalador = (props) => {
    const [data, setData] = useState([])
    const [idDuplicar, setIdDuplicar] = useState(0)
    const [dias, setDias] = useState([])
    const [pesquisa, setPesquisa] = useState('')
    const [filtros, setFiltros] = useState({})
    const [dataAtual, setDataAtual] = useState([])
    const [filtroAberto, setFiltroAberto] = useState(false)
    const [descricaoFiltro, setDescricaoFiltro] = useState('')
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [showModalDuplicar, setShowModalDuplicar] = useState(false)
    const [itemDetalhes, setItemDetalhes] = useState([])
    const [width, height] = useWindowSize();
    const [verCalendario, setVerCalendario] = useState(false)
    const [showModalDuasOpcoes, setShowModalDuasOpcoes] = useState(false)
    const [showModalConfirmarExclusao, setShowModalConfirmarExclusao] = useState(false)
    const [itemExcluir, setItemExcluir] = useState({})
    const [alteracao, setAlteracao] = useState(false)
    const [possuiPermissao, setPossuiPermissao] = useState(false)
    const user = getUsuario()
    const [showCalendar, setShowCalendar] = useState(false)

    const obterDados = () => {
        consultar('/administracao/agendaescalador', (val) => {
            setData(val)
        }, () => { }, filtros)
    }

    useEffect(() => {
        setFiltros({ data_inicial: moment(new Date()).format("DD/MM/YYYY"), data_final: moment(new Date()).format("DD/MM/YYYY"), modo_visualizacao: 1 })
        setDescricaoFiltro(moment(new Date()).format("DD/MM/YYYY"))
        setDataAtual(moment(new Date()).format("DD/MM/YYYY"))
        setFiltroAberto(true)

        const objPermissao = {
            usuario_id: getUsuario().id
        }

        get('/administracao/escaladorChefeUsuario', objPermissao).then(function (val) {
            setPossuiPermissao(val.possuiPermissao)
        })
    }, [])

    useEffect(() => {
        if (width > 0) {
            setFiltroAberto(width > 1200)
        }
    }, [width])

    useEffect(() => {
        if (filtros.modo_visualizacao) {
            obterDados();
        }
    }, [filtros])

    useEffect(() => {
        if (!showModalDuplicar) {
            setIdDuplicar(0)
            setAlteracao(false)
        }
    }, [showModalDuplicar])

    useEffect(() => {
        if (filtros.data_inicial && filtros.data_final)
            setDias(getBetweenDates(moment(filtros.data_inicial, 'DD/MM/YYYY'), moment(filtros.data_final, 'DD/MM/YYYY'), 'DD/MM/YYYY'))
        else
            setDias(data.map((item) => item.data_inicial).filter((item, idx, self) => self.indexOf(item) === idx))
    }, [data])

    useEffect(() => {
        if (idDuplicar)
            setShowModalDuplicar(true)
    }, [idDuplicar])

    useEffect(() => {
        if (verCalendario)
            calcularData(0, 3)
    }, [verCalendario])

    const calcularData = (valor, modo_visualizacao) => {
        let novosFiltros = { modo_visualizacao: modo_visualizacao ? modo_visualizacao : filtros.modo_visualizacao }
        let tipoContagem = '';
        let today = moment(filtros.data_inicial, 'DD/MM/YYYY');
        switch (modo_visualizacao ? modo_visualizacao : filtros.modo_visualizacao) {
            case 1:
                tipoContagem = 'days'
                if (valor.toString().includes('/')) {
                    novosFiltros = {
                        data_inicial: valor,
                        data_final: valor, ...novosFiltros
                    }
                }
                else {
                    today = valor === 0 ? moment() : today;
                    novosFiltros = {
                        data_inicial: today.clone().add(valor, tipoContagem).format('DD/MM/YYYY'),
                        data_final: today.clone().add(valor, tipoContagem).format('DD/MM/YYYY'), ...novosFiltros
                    }
                }

                setDescricaoFiltro(moment(novosFiltros.data_inicial, 'DD/MM/YYYY').format('DD/MM/YYYY'))
                break;
            case 2:
                let from_date
                let to_date
                tipoContagem = 'weeks'

                if (valor.toString().includes('/')) {
                    today = moment(valor, 'DD/MM/YYYY');
                    from_date = today.startOf('week').toDate()
                    to_date = today.endOf('week').toDate()
                }
                else {
                    from_date = today.startOf('week').toDate()
                    to_date = today.endOf('week').toDate()
                }

                novosFiltros = {
                    data_inicial: moment(from_date).add(valor, tipoContagem).format('DD/MM/YYYY'),
                    data_final: moment(to_date).add(valor, tipoContagem).format('DD/MM/YYYY'), ...novosFiltros
                }
                setDescricaoFiltro(moment(novosFiltros.data_inicial, 'DD/MM/YYYY').format('DD/MM/YYYY') + ' - ' + moment(novosFiltros.data_final, 'DD/MM/YYYY').format('DD/MM/YYYY'))
                break;
            case 3:
                tipoContagem = 'months'
                let from_date_month
                let to_date_month

                if (valor.toString().includes('/')) {
                    today = moment(valor, 'DD/MM/YYYY');
                    from_date_month = today.startOf('month').toDate()
                    to_date_month = today.endOf('month').toDate()
                }
                else {
                    from_date_month = today.startOf('month').toDate()
                    to_date_month = today.endOf('month').toDate()
                }

                novosFiltros = {
                    data_inicial: moment(from_date_month).add(valor, tipoContagem).format('DD/MM/YYYY'),
                    data_final: moment(to_date_month).add(valor, tipoContagem).endOf('month').format('DD/MM/YYYY'), ...novosFiltros
                }

                setDescricaoFiltro(moment(novosFiltros.data_inicial, 'DD/MM/YYYY').format('MMMM - YYYY'))
                break;
            default:
                break;
        }
        setFiltros(novosFiltros)
    }

    const verificarExclusao = (item, caminhoExcluir, dataExcluir, descricao_auditoria) => {

        if (item.data_inicial !== item.data_final) {
            const novoItem = { data_excluir: dataExcluir, ...item }
            setItemExcluir(novoItem)
            setShowModalDuasOpcoes(true);
        } else {
            exclusao(item, caminhoExcluir, descricao_auditoria)
        }
    }

    const exclusao = (item, caminhoExcluir, dataExcluir, descricao_auditoria) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir(caminhoExcluir, dataExcluir ? { id: item.id, data_excluir: dataExcluir } : item.id,
                    () => {
                        obterDados();
                        mostrarToast('sucesso', mensagens['sucesso'])
                        setShowModalDuasOpcoes(false)
                    },
                    (e) => {
                        try {
                            JSON.parse(e.response.data.erros).forEach((item) => mostrarToast('erro', item))
                        } catch (e2) {
                            if (e.response && e.response.data && e.response.data.erros)
                                e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                            else
                                mostrarToast('erro', mensagens.erro)
                        }
                    }, descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => { mostrarToast('erro', mensagens['erro']) })
    }

    const obterDetalhes = (id) => {
        setItemDetalhes([{ titulo: 'Carregando...' }])
        Promise.resolve(get(`/administracao/agendaescalador/${id}`)).then(val => {
            setItemDetalhes([
                {
                    titulo: <>Escalador <div style={{
                        height: '10px',
                        width: '10px',
                        marginLeft: '10px',
                        borderRadius: '10px',
                        backgroundColor: val.cor,
                        display: 'inline-block'
                    }}></div></>, descricao: val.nome
                },
                { titulo: 'Data Inicial', descricao: val.data_inicial_formatada + ' ' + val.hora_inicial },
                { titulo: 'Data Final', descricao: val.data_final_formatada + ' ' + val.hora_final },
                { titulo: 'Observação', descricao: val.observacao || <i>Não há Observações</i> },
                {
                    titulo: 'Ações', descricao: '',
                    html: <div className='detalhes-acoes text-left'>
                        <BtnAcao classTooltip='tooltip-input-modal' classeContent="ml-1-between" icone="icon-File-Copy2" texto="Duplicar" action={() => validarPermissao(() => setIdDuplicar(id))} {...props} />
                        <BtnAcao classTooltip='tooltip-input-modal' classeContent="ml-1-between" icone="icon-Pen" texto="Alterar" action={() => validarPermissao(() => { setIdDuplicar(id); setAlteracao(true) })} {...props} />
                        <Excluir classTooltip='tooltip-input-modal' classeContent="ml-1-between" icone="icon-New-Trash" texto="Excluir" action={() => validarPermissao(() => { setShowModalDetalhes(false); exclusao({ id }, '/administracao/agendaescalador', val.descricao_auditoria) })} />
                    </div>
                },
            ])
        })
    }

    const FiltrosHtml = (propsF) => {
        return (
            <div className={propsF.className}>
                <div className={'filtros ' + propsF.className}>
                    <div className='filtro'>
                        <div className='titulo-filtro row-space-between'>
                            <span className='vertical-center'>Organizar por:</span>
                        </div>
                        <div className='content-filtro text-left'>
                            <BtnAcao icone="icon-Bulleted-List" classTooltip='tooltip-input-modal' texto="Lista" action={() => { setFiltroAberto(!(width < 1200)); setVerCalendario(false) }} {...props} />
                            <BtnAcao icone="icon-Calendar-3" classTooltip='tooltip-input-modal' texto="Calendário" action={() => { setFiltroAberto(!(width < 1200)); setVerCalendario(true) }} {...props} />
                        </div>
                    </div>
                    {
                        !verCalendario ? <div className='filtro'>
                            <div className='titulo-filtro row-space-between'>
                                <span className='vertical-center'>Modo Visualização</span>
                            </div>
                            <div className='content-filtro'>
                                <ul class="lista-selecao mb-5">
                                    <li class="active nav-item">
                                        <a href="#" onClick={() => { setFiltroAberto(!(width < 1200)); calcularData(0, 1) }}>
                                            <i class="simple-icon-reload"></i>
                                            <span className='span-clicavel'>Dia</span>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" onClick={() => { setFiltroAberto(!(width < 1200)); calcularData(0, 2) }}>
                                            <i class="simple-icon-refresh"></i>
                                            <span>Semana</span>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" onClick={() => { setFiltroAberto(!(width < 1200)); calcularData(0, 3) }}>
                                            <i class="simple-icon-check"></i>
                                            <span>Mês</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> : <></>
                    }
                </div>
            </div>
        )
    }

    const obterAgendamentos = () => {
        let qtdRegistro = 0
        let html = [];

        dias.map(item => {
            const hoje = moment(item, 'DD/MM/YYYY')
            const dataFiltroPesquisa = data.filter(item => removeAcentos(item.nome.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())))
            const dataFiltrada = dataFiltroPesquisa.filter(item2 => item2.data_inicial === item || (moment(item2.data_inicial, 'DD/MM/YYYY') <= moment(item, 'DD/MM/YYYY') && moment(item2.data_final, 'DD/MM/YYYY') >= moment(item, 'DD/MM/YYYY')));

            if (dataFiltrada.length > 0) {
                qtdRegistro++;
                html.push(<>
                    <div className={'div-dia ' + (item === dataAtual ? 'hoje' : '')}><span>{item + moment(item, 'DD/MM/YYYY').format(' - dddd')}</span></div>
                    <div className="agenda">
                        <div key="10" className="card-pequeno col-md-12 col-sm-12" onClick={() => { }}>
                            {
                                dataFiltrada.map(item2 => {
                                    return (
                                        <div className={"card margin-pequena row-space-between"}>
                                            <div className="row">
                                                <div className="col-lg-12 vertical-center">
                                                    <span className='span-hora'>
                                                        {moment(item2.data_inicial, 'DD/MM/YYYY') < hoje ? '00:00' : item2.hora_inicial} - {
                                                            moment(item2.data_final, 'DD/MM/YYYY') > hoje ? '23:59' : item2.hora_final}</span>
                                                </div>
                                                <div className="col-md-10 col-lg-8 vertical-center">
                                                    <span>{item2.nome}</span>
                                                </div>
                                                <div className="col-md-12  col-lg-4 text-right">
                                                    <BtnAcao icone="icon-File-Copy2" texto="Duplicar" action={() => validarPermissao(() => setIdDuplicar(item2.id), item2, 2)} {...props} />
                                                    <BtnAcao icone="icon-Books" texto="Detalhes" action={() => {
                                                        obterDetalhes(item2.id);
                                                        setShowModalDetalhes(true)
                                                    }} {...props} />
                                                    <BtnAcao classTooltip='tooltip-input-modal' icone="icon-Pen" texto="Alterar" action={() => validarPermissao(() => { setIdDuplicar(item2.id); setAlteracao(true) }, item2, 3)} {...props} />
                                                    {item2.data_inicial !== item2.data_final ?
                                                        <BtnAcao icone="icon-New-Trash" texto="Excluir" action={() => validarPermissao(() => verificarExclusao(item2, '/administracao/agendaescalador', item, item2.descricao), item2, 4)} {...props} />
                                                        : <Excluir classTooltip='tooltip-input-modal' classeContent="ml-1-between" icone="icon-New-Trash" texto="Excluir" action={() => validarPermissao(() => { setShowModalDetalhes(false); exclusao({ id: item2.id }, '/administracao/agendaescalador', item2.descricao_auditoria) }, item2, 4)} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </>)
            }
        })

        return html.length > 0 ? html : <div className={'div-dia'}><span>Nenhum Registro Encontrado.</span></div>
    }

    const clickEvento = (item) => {
        obterDetalhes(item.id)
        setShowModalDetalhes(true)
    }

    const validarPermissao = (acaoAposOk, linha, acao) => {
        if (!possuiPermissao)
            mostrarToast('erro', mensagens['permissao'])
        else if (linha && moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY') > moment(linha.data_final, 'DD/MM/YYYY'))
            get('/administracao/permissaoAlterarAposDataEscalador', { usuario: user.id, acao, usuario_tipo: user.tipo, id: linha.id }, true)
                .then(result => result.length === 0 ?
                    mostrarToast('erro', 'Operação bloqueada! Data anterior a data atual.') : acaoAposOk())
                .catch(err => console.log(err))
        else
            acaoAposOk()
    }

    return (
        <div className={'agenda-content ' + (filtroAberto === true && width > 1200 ? ' filtro-ativo' : '')}>
            <div className={'card-right' + (filtroAberto === true && width > 1200 ? ' filtro-ativo' : '')}>
                <FiltrosHtml />
            </div>
            <HeaderAgenda className='header-agenda' titulo={'Agenda Escalador'} botoes={
                <><BotaoPadrao texto="Cadastrar" click={() => validarPermissao(() => { setIdDuplicar(undefined); setShowModalDuplicar(true) })} />
                    <BotaoPadrao icone='icon-Filter-2' classe=' btn btn-primary primary btn-filter ' click={() => setFiltroAberto(!filtroAberto)} {...props} /></>} />
            <div className='row'>
                <div className={'col-md-12 col-lg-7 ' + (width < 1488 && filtroAberto ? ' col-xl-12' : ' col-xl-8')}>
                    <InputSimples name="search1" type="text" onChange={e => setPesquisa(e.target.value)} placeholder={`Pesquisar...`} />
                </div>
                <div className={'col-md-12 col-lg-5 ' + (width < 1488 && filtroAberto ? ' col-xl-12' : ' col-xl-4')}>
                    <div className={(width < 990 || (width < 1488 && filtroAberto) ? ' text-center mb-3' : ' text-right')}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: (width < 990 || (width < 1488 && filtroAberto) ? 'center' : 'flex-end') }}>
                            <button className="icon-Arrow-Left2 pagination-button icone icone-pagination d-none" onClick={() => { }}></button>
                            <button className="icon-Arrow-Left pagination-button icone icone-pagination" onClick={() => calcularData(-1)}></button>
                            <ModalCalendar show={showCalendar} setShowModal={setShowCalendar}>
                                <InfiniteCalendar className={showCalendar ? "calendario-ativo" : "calendario-oculto"}
                                    locale={{
                                        locale: require('date-fns/locale/pt'),
                                        blank: 'Selecione uma data',
                                        headerFormat: 'ddd, MMM Do',
                                        weekdays: ["Dom", "Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb"],
                                        todayLabel: {
                                            long: 'Hoje',
                                            short: 'Hoje'
                                        },
                                        weekStartsOn: 0,
                                    }}
                                    selected={descricaoFiltro.includes('-') ? new Date() : new Date(descricaoFiltro.split('/')[1] + '/' + descricaoFiltro.split('/')[0] + '/' + descricaoFiltro.split('/')[2])}
                                    onSelect={value => { calcularData(value.getDate().toString().padStart(2, '0') + '/' + (value.getMonth() + 1).toString().padStart(2, '0') + '/' + value.getFullYear()); setShowCalendar(!showCalendar); }} height={height - 150}
                                    width={width < 501 ? width : 403}
                                />
                            </ModalCalendar>
                            <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={_ => setShowCalendar(!showCalendar)}>{descricaoFiltro} </span>
                            <button className="icon-Arrow-Right pagination-button icone icone-pagination" onClick={() => calcularData(1)}></button>
                            <button className="icon-Arrow-Right2 pagination-button icone icone-pagination d-none" onClick={() => { }}></button>
                        </div>
                        {descricaoFiltro.includes('/') && !descricaoFiltro.includes('-') && <span style={{ marginRight: (width < 990 || (width < 1488 && filtroAberto) ? 'unset' : '95px') }}>{moment(descricaoFiltro, 'DD/MM/YYYY').format('ddd')}</span>}
                    </div>
                </div>
            </div>
            {
                verCalendario === true ?
                    <Calendario dataAtual={filtros.data_inicial} clickEvento={item => clickEvento(item)} data={data.filter(item => removeAcentos(item.nome.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())))} /> :
                    obterAgendamentos()
            }
            <ModalVisualizacao titulo={'Detalhes Agendamento'} data={itemDetalhes} showModal={showModalDetalhes} setShowModal={setShowModalDetalhes} />

            <ModalPadraoCadastro showModal={filtroAberto == true && width <= 1200} setShowModal={setFiltroAberto} fecharComEsc={false}>
                <FiltrosHtml className={'agenda-content'} />
            </ModalPadraoCadastro>

            <ModalPadraoCadastro titulo={idDuplicar ? (!alteracao ? 'Duplicar Agenda' : 'Alteração Agenda') : 'Cadastro Agenda'} showModal={showModalDuplicar} setShowModal={setShowModalDuplicar}>
                <CadastroAgendaEscalador duplicar={idDuplicar && !alteracao} id={idDuplicar} modal={true} setShow={setShowModalDuplicar} funcaoAposFechar={() => obterDados()} />
            </ModalPadraoCadastro>

            <ModalDuasOpcoes showModal={showModalDuasOpcoes} setShowModal={setShowModalDuasOpcoes}
                titulo={showModalConfirmarExclusao ? 'Deseja confirmar a exclusão?' : 'Selecione uma opção:'}
                escoderFechar
                textoOpcao1={'Cancelar'}
                textoOpcao2={'Continuar'}
                funcaoOpcao1={() => showModalConfirmarExclusao ? setShowModalConfirmarExclusao(false) : setShowModalDuasOpcoes(false)}
                funcaoOpcao2={showModalConfirmarExclusao ? () => { exclusao(itemExcluir, '/administracao/agendaescalador', itemExcluir.data_excluir, itemExcluir.descricao_auditoria); setShowModalConfirmarExclusao(false) } : () => { }}
                classeOpcao1={'btn-sm btn-primary-modal simples'}
                classeOpcao2={showModalConfirmarExclusao ? '' : 'd-none'}>
                {
                    <ul class="lista-modal">
                        <li className={showModalConfirmarExclusao && !itemExcluir.data_excluir ? 'd-none' : ''} onClick={() => showModalConfirmarExclusao ? {} : setShowModalConfirmarExclusao(true)}>
                            <div className='mb-1 li-titulo'>Excluir apenas para o dia</div>
                            <b>
                                <span>{itemExcluir.nome}</span>
                                <br />
                                <span>{itemExcluir.data_excluir}</span>
                            </b>
                        </li>
                        <div className='mt-1' />
                        <li className={showModalConfirmarExclusao && itemExcluir.data_excluir ? 'd-none' : ''} onClick={showModalConfirmarExclusao ? () => { } : () => {
                            let novoItem = { ...itemExcluir }
                            delete novoItem.data_excluir
                            setItemExcluir(novoItem)
                            setShowModalConfirmarExclusao(true)
                        }}>
                            <div className='mb-1 li-titulo'>Excluir registro completo</div>
                            <b>
                                <span className='mb-1'>{itemExcluir.nome}</span>
                                <br />
                                <span>{itemExcluir.data_inicial} - {itemExcluir.data_final}</span>
                            </b>
                        </li>
                    </ul>
                }
            </ModalDuasOpcoes>
            { width > 530 &&
                <>
                    <div style={{ top: height / 2 }} className={'slide-middle-div slide-middle-left-div ' + (props.menuAberto ? '' : 'btn-left-0')}>
                        <BotaoPadrao icone='icon-Arrow-Left' classe='btn-middle-left btn primary btn-filter btn-scroll-top' idTooltip={"padrao"} click={_ => calcularData(-1)} />
                    </div>
                    <div style={{ top: height / 2 }} className={'slide-middle-div slide-middle-right-div ' + (filtroAberto ? 'margin-r-279' : 'right-5-neg')}>
                        <BotaoPadrao icone='icon-Arrow-Right' classe='btn-middle-right btn primary btn-filter btn-scroll-top' idTooltip={"padrao"} click={_ => calcularData(1)} />
                    </div>
                </> || <></>
            }
            <div className={'slide-up-bottom-div ' + (filtroAberto ? 'margin-r-279' : 'right-5-neg')}>
                <BotaoPadrao icone='icon-Arrow-Up' classe=' btn primary btn-filter btn-scroll-top' idTooltip={"padrao"} click={_ => window.scrollTo(0, 0)} />
            </div>
        </div>
    )
}

export default AgendaEscalador