import React, { useEffect, useState } from "react";
import { HeaderCadastro } from "../../../components/Headers";
import { get } from "../../../util/AcessoApi";

const GraficoProducaoFaturamento = (props) => {

    const [urlProducaoFaturamento, setUrlProducaoFaturamento] = useState()


    useEffect(() => {
        Promise.resolve(get('/parametros/analisedados/urlProducaoFaturamento', null, null)).then(
            (val) => {
                setUrlProducaoFaturamento(val.url)
            })
    }, [])

    return (
        <>

            <HeaderCadastro titulo={"Produção Faturamento"} />
            <div className={'col-lg-12 col-md-12'}>
                <div className='card b-radius-10  mb-3'>
                    <div className='card-body'>
                        <div className="iframe-container">
                            {
                                urlProducaoFaturamento ?
                                    <iframe
                                        title="producao_faturamento_equipe_anestesia"
                                        src={`${urlProducaoFaturamento}`}
                                        frameBorder="0"
                                        allowFullScreen={true}
                                    ></iframe> : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default GraficoProducaoFaturamento