import { BotaoPadrao } from '../../../../components/Botoes'
import React, { useState, useEffect } from 'react'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form } from "formik"
import { InputPadrao } from '../../../../components/Inputs'
import { getUsuario } from '../../../../util/Configuracoes'
import { mensagens } from '../../../../util/Utilitarios'
import { post } from '../../../../util/AcessoApi'
import Yup from '../../../../util/Validacoes'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/login.css'
import Atalho from '../../../../util/Atalho'

const Submit = (values, setIsSubmit, history, setShow) => {
    setIsSubmit(true)
    Promise.resolve(post('/controleAcesso/usuario/alteracaoSenha', values)).then(function (val) {
        mostrarToast('sucesso', mensagens['sucesso'])
        setShow(false)
    }).catch((e) => {
        setIsSubmit(false)
    });
}
const AlteracaoSenha = (props) => {
    let validacaoInicial = Yup.object({
        senhaAntiga: Yup.string()
            .required()
            .max(30),
        novaSenha: Yup.string()
            .required()
            .max(30),
        confirmacaoSenha: Yup.string()
            .required()
            .max(30).oneOf([Yup.ref('novaSenha'), null], 'Confirmação de senha deve ser igual a Senha')
    })

    const [isSubmit, setIsSubmit] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})

    const { history } = props

    useEffect(() => {
        setValoresIniciais({
            id: getUsuario().id,
            senhaAntiga: "",
            novaSenha: "",
            confirmacaoSenha: "",
        })
    }, [])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={(values) => Submit(values, setIsSubmit, history, props.setShow)}>
            {propsFormik => (
                <Form>
                    <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio="false" classeTooltip={props.modal ? "tooltip-input-modal" : ""} />
                    <InputPadrao label="Senha antiga" name="senhaAntiga" type="password" placeholder="Senha Antiga" obrigatorio="true"
                        classeTooltip={props.modal ? "tooltip-input-modal" : ""} autoFocus />
                    <InputPadrao label="Nova senha" name="novaSenha" type="password" placeholder="Nova senha" obrigatorio="true"
                        classeTooltip={props.modal ? "tooltip-input-modal" : ""} />
                    <InputPadrao label="Confirmação de Senha" name="confirmacaoSenha" type="password" placeholder="Confirmação de Senha" obrigatorio="true"
                        classeTooltip={props.modal ? "tooltip-input-modal" : ""} />

                    <Atalho formik={propsFormik} salvando={false} />

                    <div className="row justify-content-center">
                        <div className="container-botoes direita">
                            <BotaoPadrao classe="btn btn-secundario btn-sm m-1" type="button" texto="Cancelar" disabled={isSubmit} click={() => props.setShow(false)} />
                            <BotaoPadrao texto="Salvar" disabled={isSubmit} idTooltip={'salvar'} />
                        </div>
                    </div>
                </Form>)
            }
        </Formik>
    )
}

export default AlteracaoSenha