import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/icones/iconsmind/style.css';
import { Link } from 'react-router-dom'
import moment from 'moment';
import { get } from '../util/AcessoApi'
import { getUsuario } from '../util/Configuracoes'
import { ACOES } from '../util/Enums'
import { BtnAcao } from './Acoes';



const AcessoRapido = (props) => {
    return (

        <div className={'col-lg-12 col-md-12'}>
            <div className='row' >
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 38).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/administracao/agendareserva/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="administrativo" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-Calendar-3" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Agenda Central</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 49).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/administracao/plantao-alcancaveis/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="administrativo" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-Clock" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Escala</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 53).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/administracao/plantao-estabelecimento/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="administrativo" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-Building" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Escala Estabelecimento</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 54).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/administracao/rotinas/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="administrativo" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-Dec" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Rotinas</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 17).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/administracao/socio-ocorrencia/ListagemDiaria' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="administrativo" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-Letter-Open" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Ocorrências</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 6).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/administracao/socio/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="administrativo" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-Doctor" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Sócio</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 59).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/administracao/ferias/marcacao/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="ferias" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-Yacht" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Marcação Férias</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 72).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/administracao/direitofolga/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="administrativo" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-Air-Balloon" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Direito Folga</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 14).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/faturamento/procedimento/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="faturamento" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-File-ClipboardFileText" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Procedimentos</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 34).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/faturamento/contrato/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="faturamento" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-Professor" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Contrato</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 111).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/faturamento/contratoparticular/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="faturamento" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-Professor" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Contrato Particular</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 76).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/faturamento/guia/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="faturamento" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-File-TextImage" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Guia</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 107).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/faturamento/guia-particular/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="faturamento" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-File-TextImage" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Guia Particular</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
                {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 35).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) && <div className='col-lg-1 custom-col mb-3'>
                    <Link to={{ pathname: '/faturamento/lote/consulta' }} style={{ textDecoration: 'none' }}>
                        <div className='dashboard-small-card card b-radius-10'>
                            <a class="a-acesso-rapido" data-flag="faturamento" href="/dashboard">
                                <div class="text-center card-body flex-vertical card-acesso">
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <i class="icon-File-TextImage" style={{ fontSize: '40px' }}></i>
                                    </div>
                                    <div className='content-center' style={{ flex: 1, justifyContent: 'center' }}>
                                        <p class="card-text font-weight-semibold mb-0">Lote</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Link>
                </div>}
            </div>
        </div>)
}

const GraficosFaturamento = (props) => {
    return (
        <>
            {props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 146).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) &&
                <div className={'col-lg-12 col-md-12'}>
                    <div className='card b-radius-10  mb-3'>
                        <div className='card-body'>
                            {/* <div className={'div-botao-tipo_grafico'}>
                                <BtnAcao icone='icon-Filter-2 filtro-datagrid-responsive' action={_ => { }} />
                            </div> */}
                            <div className="iframe-container">
                                {
                                    props.urlProducaoFaturamento ?
                                        <iframe
                                            title="producao_faturamento_equipe_anestesia"
                                            src={`${props.urlProducaoFaturamento}`}
                                            frameBorder="0"
                                            allowFullScreen={true}
                                        ></iframe>
                                        : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}

const AuditoriaAgenda = (props) => {
    const [data, setData] = useState([])

    useEffect(() => {
        if (props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 42).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR)) {
            Promise.resolve(get(`/controleAcesso/auditoriaDashboard`)).then(obj => setData(obj))
        }
    }, [props.permissoes])

    return (props.permissoes.length > 0 && props.permissoes.find(x => x.modulo_id === 42).permissoes.find(x => parseInt(x) === ACOES.CONSULTAR) ?
        <div className={'col-lg-8 col-md-6'}>
            <div className='card b-radius-10'>
                <div className='card-body'>
                    <div className='mb-2 row-space-between'>
                        <div class="card-title">Auditoria Agenda</div>
                    </div>
                    <div className='dashboard-auditoria'>
                        <div className='scrollbar-container custom-scrollbar'>
                            <table className="table table-sm table-borderless">
                                <thead>
                                    <th>Usuário</th>
                                    <th>Módulo</th>
                                    <th>Ação</th>
                                    <th>Data</th>
                                </thead>
                                <tbody style={{ fontSize: 12 }}>
                                    {data && data.map(x =>
                                        <tr>
                                            <td><span class="font-weight-medium">{x.nome}</span></td>
                                            <td><span class="font-weight-medium">{x.modulo}</span></td>
                                            <td><span class="font-weight-medium">{x.acao}</span></td>
                                            <td class="text-left"><span class="text-muted">{x.data}</span></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div> : <></>)
}

const ProximosAgendamentos = (props) => {
    const [data, setData] = useState([])
    const user = getUsuario()


    useEffect(() => {
        Promise.resolve(get(`/administracao/agendasDashboard`, { data: moment().format('DD/MM/YYYY'), usuario: user })).then(obj => {
            setData(obj)
        })
    }, [])


    return (
        <div className={'col-lg-4 col-md-6'}>
            <div className='card b-radius-10'>
                <div className='card-body'>
                    <div className='mb-2 row-space-between'>
                        <div className="card-title">Próximos Agendamentos</div>
                    </div>
                    <div className='dashboard-agendamento'>
                        <div className='scrollbar-container custom-scrollbar'>
                            {data && data.map(x => {
                                return (
                                    <div class="display-flex flex-row mb-3 pb-3 border-bottom">
                                        <i className={x.icone} style={{ fontSize: '27px' }}></i>
                                        <div className="pl-3 pr-2">
                                            <p className="font-weight-medium mb-0 ">{x.socio}</p>
                                            <p className="text-muted mb-0 text-small">{x.data_inicial && (moment(x.data_inicial, 'DD/MM/YYYY').format('ddd') + ' às ' + x.horario_inicial) + (x.data_final ? ' - ' + moment(x.data_final, 'DD/MM/YYYY').format('ddd') + ' às ' + x.horario_final : '')}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export { AcessoRapido, AuditoriaAgenda, ProximosAgendamentos, GraficosFaturamento }