import React, { useEffect, useState, useRef } from 'react'
import { Redirect } from 'react-router-dom';
import styled from 'styled-components'
import { useTable, useFilters, useGlobalFilter, usePagination } from 'react-table'
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { BotaoPadrao } from './Botoes';
import { InputSimplesPesquisaConsulta } from './Inputs'
import { FiltroObjeto, FiltroDataGrid, Ordenaveis } from './Filtragens'
import '../assets/css/data-grid.css';
import '../assets/css/icones/iconsmind/style.css';
import { Alterar, Excluir, Visualizar, Imprimir, BtnAcao, Btn } from './Acoes'
import { ACOES } from '../util/Enums'
import { getUsuario } from '../util/Configuracoes'
import { get, excluir, pesquisarDataGrid } from '../util/AcessoApi'
import { mostrarToast } from './Toasts'
import { mensagens, useWindowSize, useInterval } from '../util/Utilitarios.js'
import { HeaderConsulta, HeaderOcorrencia } from './Headers'
import { ModalCadastro } from './Modais'
import ContentLoader from "react-content-loader"
import { Formik, Form } from "formik";
import Yup, { validaData } from '../util/Validacoes'
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment'

/* Opções:
    Header: Define o título
    accessor: Define o nome da coluna para acesso no data,
    filtros: define os filtros as serem utilizados, baseando se no tipo definido dentro de filtragens
    apenasDataGrid: A coluna aparecerá apenas na Data Grid
    apenasRelatorio: A coluna aparecerá apenas no Relatório
*/

const Styles = styled.div``

const Table = React.forwardRef((props, ref) => {
    const { columns, data, acoes, acoesObj, tituloLista, status, colunaStatus, idStatus } = props
    const [newData, setNewData] = useState([])
    const [dadosTela, setDadosTela] = useState([])
    const [filtrosAbertos, setFiltrosAbertos] = useState([])
    const [pesquisa, setPesquisa] = useState([])
    const [pesquisaChar, setPesquisaChar] = useState([])
    const [digitando, setDigitando] = useState(null)
    const [impressao, setImpressao] = useState(false)
    const [filtrosColuna, setFiltroColuna] = useState([])
    const [colunasOrdenaveis, setColunasOrdenaveis] = useState([])
    const [arrayFiltros, setArrayFiltros] = useState([])
    const [abrirModal, setAbrirModal] = useState(false)
    const [parametrosLoading, setParametrosLoading] = useState({})
    const [carregando, setCarregando] = useState(true)
    const [iniciandoValores, setIniciandoValores] = useState(true)
    const [semRegistro, setSemRegistro] = useState(false)
    const [arrayOrdenamento, setArrayOrdenamento] = useState([])
    const [arrayOrdenamentoModal, setArrayOrdenamentoModal] = useState([])
    const [arrayConfigCol, setArrayConfigCol] = useState([])
    const [dadosFiltros, setDadosFiltros] = useState([])
    const [qtdRegistros, setQtdRegistro] = useState()
    const [index, setIndex] = useState(0)
    const [colunaOrdenada, setColunaOrdenada] = useState([])
    const [tipoOrdenamento, setTipoOrdenamento] = useState([])
    const [idFiltroColuna, setIdFiltroColuna] = useState([])
    const [valuesFiltro, setValuesFiltro] = useState([])
    const [valoresPadrao, setValoresPadrao] = useState([])
    const [colunaFiltrada, setColunaFiltrada] = useState([])
    const [validacao, setValidacao] = useState({})
    const [optionsOrdenamentoModal, setOptionsOrdenamentoModal] = useState([])
    const [conteudoPesquisa, setConteudoPesquisa] = useState('')
    const [ultimaAtualizacao, setUltimaAtualizacao] = useState(0)
    const [atualizado, setAtualizado] = useState(true)
    const [pesquisaFiltro, setPesquisaFiltro] = useState('')
    const [limparValueCombo, setLimparValueCombo] = useState(false)

    const refWidth = useRef({ current: { clientWidth: 0 } })
    const refDivTable = useRef(null)
    const [width] = useWindowSize()

    useInterval(() => {
        if (!atualizado && parseFloat(moment().diff(ultimaAtualizacao)) > 700) {
            setNovoFiltro(conteudoPesquisa.coluna, conteudoPesquisa.value)
            setAtualizado(true)
            setPesquisaFiltro(conteudoPesquisa)
        }
    }, 700)

    function distinct(value, index, self) {
        return self.indexOf(value) === index;
    }

    ref.current = {
        atualizar: _ => atualizar()
    }

    useEffect(() => {
        atualizar()
    }, [colunaOrdenada, tipoOrdenamento, index, idFiltroColuna, optionsOrdenamentoModal, pesquisa, pesquisaFiltro])

    useEffect(() => {
        setParametrosLoading(refWidth.current ? { width: refWidth.current.clientWidth - 30, heigth: 600, qtdLinhas: 13, colspan: props.columns[0].columns.length + 1 } : { width: 0, height: 0 })
    }, [refWidth.current.clientWidth, width])



    useEffect(() => {
        const dados = [...newData];

        if (!props.removerTotalPaginas && newData && newData.length > 0) {
            setQtdRegistro(newData && newData.map(x => x.qtd).filter(y => y !== undefined))
            dados.pop();
        }

        setDadosTela(dados);
    }, [newData])

    useEffect(() => {
        if (columns[0].columns.length > 0)
            setFiltrosAbertos(Array(columns[0].columns.length).fill(false))

        setFiltroColuna(columns[0].columns.filter(coluna => coluna.filtro).map(coluna => {
            return {
                aberto: true,
                titulo: coluna.Header,
                tipo: coluna.filtro && coluna.filtro.tipo ? coluna.filtro.tipo : '',
                lista: data.map(item => item[coluna.accessor]).filter(distinct),
                colunaID: coluna.filtro,
                combo: coluna.filtro.combo ? coluna.filtro.combo : 'combo',
                accessor: coluna.accessor
            }
        }
        ))

        setColunasOrdenaveis(columns[0].columns.filter(coluna => coluna.ordenamento).map(coluna => {
            return {
                aberto: true,
                titulo: coluna.Header,
                nome: coluna.accessor,
                tipo: coluna.ordenamento && coluna.ordenamento.tipo ? coluna.ordenamento.tipo : '',
                lista: data.map(item => item[coluna.accessor]).filter(distinct)
            }
        }
        ))

        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                setFiltrosAbertos(Array(columns[0].columns.length).fill(false))
        }, false);
        let preArrayOrdenamento = columns.length ? Array(columns[0].columns).fill({}) : []
        preArrayOrdenamento = columns.length && columns[0].columns.map((ag, idxAg) => {
            let contador = 0;
            if (ag.ordenamento && ag.ordenamento.ativo)
                contador++
            return {
                coluna: ag.accessor,
                ordenamento: ag.ordenamento && ag.ordenamento.ativo ? {
                    modo: ag.ordenamento.modo || 'asc',
                    tipo: ag.ordenamento.tipo || 'texto',
                    ativo: true,
                    ordem: contador
                } : {
                    modo: 'asc',
                    tipo: ag.ordenamento ? ag.ordenamento.tipo || 'texto' : 'texto',
                    ativo: false
                }
            }
        })
        setArrayOrdenamento(preArrayOrdenamento.length && preArrayOrdenamento.map(item => item && item.ordenamento.ativo))
        setArrayOrdenamentoModal(preArrayOrdenamento.length && preArrayOrdenamento.map(item => item))
        setArrayConfigCol(preArrayOrdenamento)
    }, [data])

    const limparOrdenamentos = () => {
        setColunaOrdenada([])
        setTipoOrdenamento([])
        setOptionsOrdenamentoModal([])
        setIndex(0)
    }

    const verificaPaginacaoManual = () => {
        let retorno = columns[0].paginacaoManual
        return retorno
    }

    const manualGlobalFilter = () => true

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        page,
        headerGroups,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex }
    } = useTable(
        {
            columns,
            data: dadosTela,
            manualGlobalFilter,
            initialState: {
                defaultPageSize: 20,
                hiddenColumns: columns.map(item => item.columns.filter(coluna => (
                    (coluna.apenasRelatorio === true) && (coluna.apenasDataGrid === undefined || coluna.apenasDataGrid === true))).map(coluna => coluna.accessor)).flat(1)
            },
            manualPagination: verificaPaginacaoManual(),

        },
        useFilters,
        useGlobalFilter,
        usePagination
    )

    let validacaoInicial = Yup.object({
        data: Yup.string().nullable().required()
            .test('Data', 'Data inválida!', function (value) { return validaData(this.parent.data) }),
        data_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) }),
        data_final: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_final) }),

    })

    const setNovoFiltro = (coluna, valor) => {
        if (coluna.filtro && coluna.filtro.datePicker && valor && !moment(valor, 'DD/MM/YYYY', true).isValid())
            return;
        if (coluna.filtro && coluna.filtro.timePicker && valor && !moment(valor, 'HH:mm', true).isValid())
            return;
        if (coluna.filtro && coluna.filtro.monthPicker && valor && !moment(valor, 'MM/YYYY', true).isValid())
            return;
        var valueFiltro

        if (valor && typeof valor === 'object' && (valor.data || valor.data_inicial || valor.data_final || valor.data_inicial_procedimento || valor.data_final_procedimento || valor.referencia_lote))
            valueFiltro = valor[coluna.filtro.name || coluna.filtro.tipo]
        else if (valor && valor.length > 0 && typeof valor !== 'string')
            valueFiltro = valor.map(x => x.value)
        else if (typeof valor === 'string')
            valueFiltro = valor
        else if (valor && typeof valor === 'object' && valor.value)
            valueFiltro = valor
        else
            valueFiltro = ''

        var idFiltro = [...idFiltroColuna]
        var colunaFiltradaClone = [...colunaFiltrada]
        if (!idFiltroColuna.find(x => x.idFiltro === coluna.filtro.idColuna)) {
            idFiltro.push({ idFiltro: coluna.filtro.idColuna, value: valueFiltro })
            colunaFiltradaClone.push({ name: coluna.filtro.name || coluna.filtro.tipo, idFiltro: coluna.filtro.idColuna, value: coluna.filtro.tipo.includes('data') ? valueFiltro : valor })

            setIdFiltroColuna(idFiltro)
            setColunaFiltrada(colunaFiltradaClone)
        }
        else {
            if (idFiltroColuna.find(x => x.idFiltro === coluna.filtro.idColuna)) {

                const index = idFiltroColuna.findIndex(x => x.idFiltro === coluna.filtro.idColuna)
                idFiltro[index] = { idFiltro: coluna.filtro.idColuna, value: valueFiltro }
                colunaFiltradaClone[index] = { name: coluna.filtro.name || coluna.filtro.tipo, idFiltro: coluna.filtro.idColuna, value: coluna.filtro.tipo.includes('data') ? valueFiltro : valor }
                if (coluna.filtro.date) {//VERIFICA SE COLUNA DE FILTRO CONTÉM DATA
                    if (moment(valor, 'DD/MM/YYYY', true).isValid() || valor === '')//CASO SIM E DATA SEJA VÁLIDA SETAR ID FILTRO OU VALOR ESTEJA VAZIO (CASO USUÁRIO LIMPE A DATA DO FILTRO) 
                        setIdFiltroColuna(idFiltro)//USEEFFECT SERÁ ACIONADO CHAMANDO O MÉTODO DE BUSCAR NA DATAGRID SOMENTE CASO DATA ESTEJA VÁLIDA
                }
                else {
                    setIdFiltroColuna(idFiltro)
                }

                setColunaFiltrada(colunaFiltradaClone)
            }
            else {
                idFiltro.push({ idFiltro: coluna.filtro.idColuna, value: valueFiltro })
                colunaFiltradaClone.push({ name: coluna.filtro.name || coluna.filtro.tipo, idFiltro: coluna.filtro.idColuna, value: coluna.filtro.tipo.includes('data') ? valueFiltro : valor })
                setIdFiltroColuna(idFiltro)
                setColunaFiltrada(colunaFiltradaClone)
            }
            setValidacao(Yup.object(cloneDeep(validacaoInicial)))
        }
    }

    const triggerFiltro = (idx) => {
        let cloneFiltrosAbertos = [...filtrosAbertos]
        cloneFiltrosAbertos.splice(idx, 1, !cloneFiltrosAbertos[idx])
        setFiltrosAbertos(cloneFiltrosAbertos)
    }

    const limparFiltros = () => {
        setIdFiltroColuna([])
        setValuesFiltro(valoresPadrao);
        setColunaFiltrada([])
        setIndex(0)
        setLimparValueCombo(true)
    }

    const obterValoresFiltros = () => {
        let retorno = {}

        colunaFiltrada.map((item, idx) => {
            retorno[item.name] = item.value
            return
        })

        if (pesquisa && pesquisa.length > 0)
            retorno['pesquisa'] = pesquisa || ''

        return retorno
    }

    const obterLinhaLoading = () => {
        let linhas = [];
        let valorY = 20;
        for (let index = 0; index < parametrosLoading.qtdLinhas; index++) {
            linhas.push((<rect x={0} y={valorY} rx="3" ry="3" width={parametrosLoading.width} height="20" />))
            valorY = valorY + 50;
        }
        return linhas;
    }

    const obterFiltrosUtilizados = () => {
        const filtrosUtilizados = [...filtrosColuna]

        filtrosUtilizados.push({
            titulo: 'Pesquisa',
            nome: 'pesquisa',
            colunas: columns[0].columns && columns[0].columns.map(item => item.accessor),
            tipo: 'texto-global'
        })

        return filtrosUtilizados
    }

    const obterLoading = () => {
        if (carregando) {

            return (<tr>
                <td colspan={parametrosLoading.colspan}>

                    <ContentLoader
                        speed={3}
                        width={parametrosLoading.width}
                        height={parametrosLoading.heigth}
                        viewBox={"0 0 " + parametrosLoading.width + " " + parametrosLoading.heigth}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#e4e4e4"
                    >
                        {obterLinhaLoading()}

                    </ContentLoader>
                </td>
            </tr>)

        }
    }

    const firstPageRows = page

    const headerGroupsFiltered = headerGroups.map(headerGroup => headerGroup.headers.filter(column => (typeof column.Header == "string")).length > 0 ? headerGroup : null)

    const obterColunasOrdenaveis = () => {

        let comboAsc = columns[0].columns.filter(coluna => coluna.ordenamento).map((column, idx) => ({ label: column.Header + '- Asc', value: column.accessor + '_asc' }))
        let comboDesc = columns[0].columns.filter(coluna => coluna.ordenamento).map((column, idx) => ({ label: column.Header + "- Desc", value: column.accessor + '_desc' }))
        var combo = comboAsc.concat(comboDesc)
        combo.sort((a, b) => {
            return +(a.value > b.value) || (a.value === b.value && a.label === a.label) - 1
        })
        return combo;

    }

    const setOrdenamento = (idxColuna) => {

        if (!colunaOrdenada.find(x => x === idxColuna[0].accessor)) {
            const colunaOrdenadaClone = [...colunaOrdenada]
            colunaOrdenadaClone.push(idxColuna[0].accessor)
            setColunaOrdenada(colunaOrdenadaClone)
            const colunaTipoOrdenamentoClone = [...tipoOrdenamento]
            colunaTipoOrdenamentoClone.push(false)
            setTipoOrdenamento(colunaTipoOrdenamentoClone)

        }
        else {
            var index = colunaOrdenada.findIndex(x => x === idxColuna[0].accessor)
            const colunaTipoOrdenamentoClone = [...tipoOrdenamento]
            if (colunaTipoOrdenamentoClone[index] === false)
                colunaTipoOrdenamentoClone[index] = true
            else
                colunaTipoOrdenamentoClone[index] = false
            setTipoOrdenamento(colunaTipoOrdenamentoClone)
        }
    }

    const verificaFiltro = () => {
        var retorno = true
        for (let i in idFiltroColuna) {
            if (idFiltroColuna && idFiltroColuna.length > 0) {
                if (idFiltroColuna[i].value !== '')
                    retorno = false
            }
        }
        return retorno
    }

    const validarImpressao = _ => {
        if (qtdRegistros && Math.ceil(qtdRegistros / 10) > 1000) {
            setAbrirModal(true)
            mostrarToast('erro', <><b>Ação não permitida!</b><br /> Grande quantidade de registros. Utilize os filtros para que a consulta tenha menos do que 1000 páginas</>)
        } else
            setImpressao(true)
    }

    const atualizar = _ => {
        let arrayIdFiltro = []
        let newArrayIdFiltro = [];

        if (iniciandoValores) {
            let filtrosLs = props.filtroAtivo === true && JSON.parse(sessionStorage.getItem(`${props.nomeSession}`))

            let arrayFiltrado = columns[0].columns.filter(coluna => coluna.filtro && coluna.filtro.valor)
                .map(coluna => ({ name: coluna.filtro.name || coluna.filtro.tipo, idFiltro: coluna.filtro.idColuna, value: coluna.filtro.valor }))
            arrayIdFiltro = columns[0].columns.filter(coluna => coluna.filtro && coluna.filtro.valor)
                .map(coluna => ({ idFiltro: coluna.filtro.idColuna, value: coluna.filtro.valor }))

            if (filtrosLs && filtrosLs.length > 0) {
                const filtroModulado = filtrosLs.map(item => ({
                    idFiltro: item.idFiltro,
                    value: item.value_formatado,
                    name: item.name
                }))
                arrayFiltrado = [...filtrosLs, ...arrayFiltrado]
                arrayIdFiltro = [...filtroModulado, ...arrayIdFiltro]
            }

            let newArrayFiltrado = [];

            if (arrayFiltrado.length > 1 && props.inicializarFiltrado) { // CONDIFCAO PARA REMEOVER FILTROS REPETIDOS 

                const uniqueNames = {};

                arrayFiltrado.forEach(obj => { // PERCORRE O ARRAY PARA VERIFICAR SE O MESMO FILTRO ESTA SETADO MAIS DE UMA VEZ E MONTA UM ARRAY NOVO SEM REPETICAO
                    if (!uniqueNames[obj.name]) {
                        uniqueNames[obj.name] = true;
                        newArrayFiltrado.push(obj);
                    }
                });

            }


            if (arrayIdFiltro.length > 1 && props.inicializarFiltrado) { // CHECA O ARRAY E RETORNA SOMENTE A ULTIMA OCORRENCIA 
                newArrayIdFiltro = Object.values(arrayIdFiltro.reduce((x, item) => {
                    x[item.idFiltro] = item
                    return x
                }, {}))
            }

            setIniciandoValores(false)
            setColunaFiltrada(newArrayFiltrado.length > 0 ? newArrayFiltrado : arrayFiltrado)
            setIdFiltroColuna(newArrayIdFiltro.length > 0 ? newArrayIdFiltro : arrayIdFiltro)
        }

        if (!iniciandoValores) {
            var nameColuna = {}
            if (colunaFiltrada.length > 0) {
                for (let i = 0; i < colunaFiltrada.length; i++) {
                    nameColuna[colunaFiltrada[i].name] = colunaFiltrada[i].value && colunaFiltrada[i].value || ''
                }
            }

            if (columns[0].columns.filter(x => x.filtro)) {
                for (let colunaSelecionada in columns[0].columns.filter(x => x.filtro))
                    if (columns[0].columns.filter(x => x.filtro)[colunaSelecionada].filtro.valor !== undefined)
                        nameColuna[columns[0].columns.filter(x => x.filtro)[colunaSelecionada].filtro.name || columns[0].columns.filter(x => x.filtro)[colunaSelecionada]
                            .filtro.tipo] = columns[0].columns.filter(x => x.filtro)[colunaSelecionada].filtro.valor_inicial
            }

            setValuesFiltro(nameColuna)

            var colunaFiltro = colunaOrdenada.map(x => ({ coluna: x }))
            var ordenamentoTipo = tipoOrdenamento.map(x => ({ tipo_ordenamento: x }))
            var ordenamento = []

            for (let i = 0; i < colunaFiltro.length; i++)
                ordenamento[i] = colunaFiltro.length > 0 && ordenamentoTipo.length > 0 ? colunaFiltro[i].coluna + (props.columns[0].columns.find(x => x.accessor === colunaFiltro[i].coluna).ordenamento.tipo === 'data' ? '::timestamp' : '') + ' ' + (ordenamentoTipo[i].tipo_ordenamento === false ? 'ASC' : 'DESC') : ''


            setCarregando(true)
            const filtrosSS = colunaFiltrada.map((item) => ({
                value_formatado: idFiltroColuna[idFiltroColuna.findIndex(x => x.idFiltro === item.idFiltro)].value,
                ...item
            }))

            const newFiltroSS = [];

            if (filtrosSS.length > 1 && props.inicializarFiltrado) {// CONDIFCAO PARA REMEOVER FILTROS REPETIDOS 

                const uniqueNames = {};

                filtrosSS.forEach(obj => { // PERCORRE O ARRAY PARA VERIFICAR SE O MESMO FILTRO ESTA SETADO MAIS DE UMA VEZ E MONTA UM  ARRAY NOVO SEM REPETICAO
                    if (!uniqueNames[obj.name]) {
                        uniqueNames[obj.name] = true;
                        newFiltroSS.push(obj);
                    }
                });

            }

            props.filtroAtivo === true && sessionStorage.setItem(`${props.nomeSession}`, JSON.stringify(newFiltroSS.length > 0 ? newFiltroSS : filtrosSS))
            pesquisarDataGrid(props.caminhoConsultaComFiltros + `/${index}`, (e) => { setNewData(e); setCarregando(false) }, () => setCarregando(false), pesquisa, ordenamento, [...arrayIdFiltro, ...idFiltroColuna], optionsOrdenamentoModal)
        }
    }

    const subtmitPesquisa = () => {

        setCarregando(true)

        digitando && clearTimeout(digitando)

        if (!Array.isArray(pesquisaChar)) {
            setIndex(0)
            gotoPage(0)
            setPesquisa(pesquisaChar)
        }
        setCarregando(false)

    }

    return (
        <>
            {impressao ? <Redirect push to={{
                pathname: '/listagemAlternativa',
                listagem: props.listaPersonalizada ? props.listaPersonalizada : {
                    dados: props.listaPersonalizada && props.listaPersonalizada.dados ? props.listaPersonalizada.dados : [{
                        agrupamento: '',
                        colunas: [{ label: '#', name: '#', visivel: true, alinhamento: 'text-center', }, ...(columns[0].columns && columns[0].columns.filter(item => (item.apenasRelatorio !== false && item.apenasDataGrid !== true))
                            .map(item => {
                                return {
                                    label: item.Header,
                                    name: item.accessor,
                                    totalizar: item.totalizar,
                                    alinhamento: 'text-left ' + (item.classes ? item.classes : ''),
                                    visivel: true,
                                    ordenamento: item.ordenamento
                                }
                            }))],
                        data: []
                    }],
                    titulo: tituloLista,
                    filtros: colunaFiltrada,
                    filtrosBanco: idFiltroColuna,
                    caminhoBase: props.caminhoBase,
                    filtrosObj: obterFiltrosUtilizados(),
                    filtrosValoresIniciais: obterValoresFiltros(),
                    filtrar: () => { },
                    caminho: props.caminhoConsultaComFiltros
                }
            }} /> : <></>}
            <div className="row-space-between row">
                {
                    props.removerTotalPaginas === true ?
                        <div className="col-lg-6 col-sm-12">
                            <InputSimplesPesquisaConsulta onEnterPress={subtmitPesquisa} name="search1" type="text" value={pesquisaChar || ''} onChange={e => setPesquisaChar(e.target.value)} placeholder={`Pressione Enter para buscar...`} />
                        </div> :
                        <div className="col-lg-6 col-sm-12">
                            <InputSimplesPesquisaConsulta onEnterPress={subtmitPesquisa} name="search1" type="text" value={pesquisaChar || ''} onChange={e => setPesquisaChar(e.target.value)} placeholder={`Pressione Enter para buscar...`} />
                        </div>
                }
                <div className="col-lg-6 col-sm-12">
                    {
                        props.removerTotalPaginas === true ?
                            <div className={(width < 990 ? ' text-center mb-3' : ' text-right')}>
                                <div className="row d-none d-md-block">
                                    <div className="col-12">
                                        <button className="icon-Arrow-Left2 pagination-button icone icone-pagination" onClick={() => { gotoPage(0); setIndex(0) }} disabled={false}></button><button className="icon-Arrow-Left pagination-button icone icone-pagination" onClick={() => { previousPage(); setIndex(index - 1) }} disabled={index > 0 ? false : true}></button> {index + 1}<button className="icon-Arrow-Right pagination-button icone icone-pagination" onClick={() => { nextPage(); setIndex(index + 1) }} disabled={firstPageRows.length === 0 ? true : false}></button><button className="icon-Arrow-Right2 pagination-button icone icone-pagination" onClick={() => { nextPage(); setIndex(index === 0 ? index + 9 : index + 10) }} disabled={firstPageRows.length === 0 ? true : false}></button>
                                    </div>
                                </div>
                            </div> :
                            <div className={(width < 990 ? ' text-center mb-3' : ' text-right')}>
                                <div className="row d-none d-md-block">
                                    <div className="col-12">
                                        <button className="icon-Arrow-Left2 pagination-button icone icone-pagination" onClick={() => { gotoPage(0); setIndex(0) }} disabled={index === 0 ? true : false}></button><button className="icon-Arrow-Left pagination-button icone icone-pagination" onClick={() => { previousPage(); setIndex(index - 1) }} disabled={index > 0 ? false : true}></button> {index + 1}<button className="icon-Arrow-Right pagination-button icone icone-pagination" onClick={() => { nextPage(); setIndex(index + 1) }} disabled={index + 1 >= Math.ceil(qtdRegistros / 10) ? true : false}></button><button className="icon-Arrow-Right2 pagination-button icone icone-pagination" onClick={() => { gotoPage(qtdRegistros && Math.ceil(qtdRegistros / 10) - 1 === -1 ? 1 : Math.ceil(qtdRegistros / 10)); setIndex(Math.ceil(qtdRegistros / 10) - 1 === -1 ? 0 : Math.ceil(qtdRegistros / 10) - 1) }} disabled={index <= Math.ceil(qtdRegistros / 10) ? false : true}></button>
                                        <span className="numero-pagina">Total de páginas {qtdRegistros ? Math.ceil(qtdRegistros / 10) : pageOptions.length} </span>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <div className="card inline-table">
                <div className="card-body">
                    <div className="row-space-between">
                        <div className="">{(arrayOrdenamento.length !== -1 && arrayOrdenamento.filter(item => item === false).length)
                            && <BtnAcao icone='icon-A-Z' texto="Remover Ordenamento" cortarIcone action={() => limparOrdenamentos()} /> || <></>}{verificaFiltro() === false ? <BtnAcao icone='icon-Filter-2' texto={"Remover Filtros(" + idFiltroColuna.length + ")"} cortarIcone action={() => limparFiltros()} /> : <></>}</div>
                        <div className="">
                            {
                                props.updateStatus ?
                                    <BtnAcao icone={'icon-Repeat-5'} texto="Atualizar Status Coopanest" action={_ => props.updateStatus.action()} loading={props.loading} {...props} />
                                    :
                                    <></>
                            }
                            {
                                props.helpComponent ?
                                    <BtnAcao icone={props.helpComponent.icone} texto="Legenda" action={_ => props.helpComponent.action()} loading={props.loading} {...props} />
                                    :
                                    <></>
                            }
                            {props.ImpressaoOff ? <></> : <Imprimir action={_ => validarImpressao()} />}
                        </div>
                    </div>
                    <div ref={refDivTable} className={refWidth?.current && refDivTable?.current && refWidth.current.offsetWidth > refDivTable.current.offsetWidth ? "div-tabela custom-scrollbar" : ""}>
                        <table {...getTableProps()} className="table data-grid table-responsive-md" id="tabela-data-grid" ref={refWidth}>
                            <thead>
                                {headerGroupsFiltered.map((headerGroup, idx) => headerGroup != null ? (
                                    <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                                        {headerGroup.headers.map((column, idx) => {
                                            const colunaAtual = { idy: columns[0].columns.findIndex(item => item && item.accessor === column.id), ...columns[0].columns[columns[0].columns.findIndex(item => item.accessor === column.id)] }
                                            const idxFiltro = arrayFiltros.findIndex(item => item.coluna === column.id)
                                            const colunaFiltro = columns[0].columns.filter(item => item.accessor === column.id)
                                            const filtroExiste = arrayFiltros[idxFiltro] && arrayFiltros[idxFiltro].filtro
                                            let classes
                                            let labelOrdenamento
                                            return (
                                                <>
                                                    <th {...column.getHeaderProps()} onClick={_ => colunaAtual.ordenamento && setOrdenamento(colunaFiltro, idx)} className={colunaAtual.visivel === false ? 'none-coluna ' : ''}>
                                                        {columns && columns[0].columns.map((y, idy) => {
                                                            if (y.accessor === column.id) {

                                                                classes = (y.alinhamento || '') + ' ' + (y.ordenamento ? 'ordenavel ' : '') + (colunaOrdenada.find(x => x === y.accessor) ? ' ordenamento_' + (tipoOrdenamento[colunaOrdenada.findIndex(x => x === y.accessor)] === true ? 'desc' : 'asc') : '')

                                                                labelOrdenamento = () => arrayConfigCol.length && arrayConfigCol[idy] &&
                                                                    <label style={{ width: y.width || 'auto' }}
                                                                        className={`bold coluna ${y.alinhamento && y.alinhamento || ''} ${classes}`}>{y.label}</label>
                                                            }
                                                        })}
                                                        <div className={`th-filter ${classes}    ${colunaAtual.visivel === false ? 'none-coluna ' : ''}`}>
                                                            <div className={'group-title-consulta'}>
                                                                {colunaAtual.filtro && !colunaAtual.filtroSomenteModal && <Dropdown key={idx} active={filtrosAbertos.length > 0 && filtrosAbertos[idx]}>
                                                                    <DropdownTrigger>
                                                                        <i className={'cursor-pointer ' + (filtroExiste ? 'filtrado' : '')/* ------- */} onClick={() => triggerFiltro(idx)} />
                                                                    </DropdownTrigger>
                                                                    <DropdownContent>
                                                                        <div className="card-filtro-dropdown">
                                                                            <div>
                                                                                {colunaAtual.filtro && filtrosColuna.length > 0 ? <FiltroObjeto placeholder={colunaAtual.Header ?
                                                                                    colunaAtual.Header : 'Selecione...'} tipo={colunaAtual.filtro.tipo ? colunaAtual.filtro.tipo : ''} tamanho='-'
                                                                                    value={filtroExiste ? arrayFiltros[idxFiltro].filtro : ''} data={dadosFiltros} multiplo={true}
                                                                                    onChange={option => { }} autoFocus /> : <></>}
                                                                            </div>
                                                                            <div className='mt-2 text-center'>
                                                                                <BotaoPadrao classe='btn-sm btn-primary-filtro simples' texto='Limpar' click={() => limparFiltros(idxFiltro)} />
                                                                                <BotaoPadrao classe='btn-sm btn-primary-filtro simples' texto='Fechar' click={() => triggerFiltro(idx)} />
                                                                            </div>
                                                                        </div>
                                                                    </DropdownContent>
                                                                </Dropdown>}
                                                                {column.render('Header')}
                                                                {labelOrdenamento()}
                                                            </div>
                                                        </div>
                                                    </th>
                                                </>
                                            )
                                        })}
                                        {acoes ? <th className="th-acoes">Ações</th> : <></>}
                                    </tr>
                                ) : null)}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {obterLoading()}
                                {

                                    firstPageRows && firstPageRows.length === 0 && !carregando ? <tr><td className='text-center pt-2 pb-2' colSpan={columns[0].columns.filter(x => x.visivel != false).length}><span>Nenhum registro encontrado!</span></td></tr>
                                        :

                                        firstPageRows.map((row, i) => {
                                            var verificaLinha = true
                                            for (let i in columns[0].columns) {
                                                if (row.values[columns[0].columns[i].accessor] === undefined)
                                                    verificaLinha = false
                                            }
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()} key={i}>
                                                    {row.cells.map((cell, i) => {
                                                        return <td className={'responsive-td ' + ' ' + (row.cells[i].column.classeTd || '') + (row.cells[i].column.visivel === false ? ' none-coluna' : '') + (row.cells[i].column.alinhamento || '')}

                                                            {...cell.getCellProps()} key={i}><div className={'responsive-header'}>{cell.column.Header}</div>
                                                            {<div className='responsive-content'> {cell.column.html ? cell.column.html(row.original) : cell.render('Cell')}
                                                                <span className={status && cell.column.id === colunaStatus ? cell.row.original[idStatus] === 1 && 'tag-data-grid-pendente' : ''}>{status && cell.column.id === colunaStatus ? cell.row.original[status] : ''}</span> </div>}</td>
                                                    })}
                                                    {(acoes && width > 1822 || width <= 1334 || columns[0].columns.length < 8 || row.cells[0].column.ajuste === false) && props.acoesObj.length > 0 ?
                                                        <td className={`${acoesObj.length <= 3 ? 'text-center' : ' responsive-actions testando'}`}>
                                                            {acoesObj.map((Acao, i) => {
                                                                // if (verificaLinha){
                                                                return <Acao id={row.original.id} row={row.original} key={i} />
                                                                // }
                                                            })}
                                                        </td>
                                                        : (acoes && width <= 1822 && (width > 1334 && width < 1350 || columns[0].columns.length >= 8 || row.cells[0].column.ajuste === true)) && props.acoesObj.length > 0 ?
                                                            <td className="text-center responsive-actions">
                                                                {<Dropdown key={i}>
                                                                    <DropdownTrigger>
                                                                        <Btn icone='icon-ellipsi' texto="Mais Ações" />
                                                                    </DropdownTrigger>
                                                                    <DropdownContent>
                                                                        <div className="card-acoes-dropdown">
                                                                            <div className="ajusta-dropdown">
                                                                                {acoesObj.map((Acao, i) => {
                                                                                    return <Acao id={row.original.id} row={row.original} key={i} />
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </DropdownContent>
                                                                </Dropdown>}
                                                            </td> : null}
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
                    <br />
                </div>
            </div>
            <div className='row mt-20 paginacao-responsive'>
                <div className="col-lg-6 col-sm-12">
                    <div className={(width < 990 ? ' text-center mb-3' : ' text-right')}>
                        <div className="row d-none d-md-block">
                            <div className="col-12">
                                <button className="icon-Arrow-Left2 pagination-button icone icone-pagination" onClick={() => { gotoPage(0) && setIndex(0) }} disabled={index === 0 ? true : false}></button><button className="icon-Arrow-Left pagination-button icone icone-pagination" onClick={() => { previousPage(); setIndex(index - 1) }} disabled={index > 0 ? true : false}></button> {index + 1}<button className="icon-Arrow-Right pagination-button icone icone-pagination" onClick={() => { nextPage(); setIndex(index + 1) }} disabled={index < Math.ceil(qtdRegistros / 10) ? false : true}></button><button className="icon-Arrow-Right2 pagination-button icone icone-pagination" onClick={() => { gotoPage(qtdRegistros && Math.ceil(qtdRegistros / 10) - 1 === -1 ? 1 : Math.ceil(qtdRegistros / 10)); setIndex(Math.ceil(qtdRegistros / 10) - 1 === -1 ? 0 : Math.ceil(qtdRegistros / 10)) }} disabled={index < Math.ceil(qtdRegistros / 10) ? false : true}></button>
                                <span className="numero-pagina">Total de páginas {qtdRegistros ? Math.round(qtdRegistros / 10) : pageOptions.length} </span>
                            </div>
                        </div>
                        <div className="row d-block d-md-none d-lg-none d-xl-none">
                            <div className="col-12">
                                <button className="icon-Arrow-Left2 pagination-button icone icone-pagination" onClick={() => { gotoPage(0) && setIndex(0) }} disabled={pageIndex === 0 ? true : false}></button><button className="icon-Arrow-Left pagination-button icone icone-pagination" onClick={() => { previousPage(); setIndex(index - 1) }} disabled={index > 0 ? true : false}></button> {index - 1}<button className="icon-Arrow-Right pagination-button icone icone-pagination" onClick={() => { nextPage(); setIndex(index + 1) }} disabled={index < Math.ceil(qtdRegistros / 10) ? false : true}></button><button className="icon-Arrow-Right2 pagination-button icone icone-pagination" onClick={() => { gotoPage(qtdRegistros && Math.ceil(qtdRegistros / 10) - 1 === -1 ? 1 : Math.ceil(qtdRegistros / 10)); setIndex(Math.ceil(qtdRegistros / 10) - 1 === -1 ? 0 : Math.ceil(qtdRegistros / 10)) }} disabled={index < Math.ceil(qtdRegistros / 10) ? false : true}></button>
                            </div>
                            <div className="col-12 mt-1">
                                <span className="numero-pagina">Total de páginas {qtdRegistros ? Math.round(qtdRegistros / 10) : pageOptions.length} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                <div className={'div-filtro-datagrid'}>
                    <BtnAcao icone='icon-Filter-2 filtro-datagrid-responsive' action={_ => setAbrirModal(true)} />
                </div>
            }
            <ModalCadastro titulo={'Filtros'} show={abrirModal} setShowModal={setAbrirModal} >
                {
                    <Formik
                        initialValues={valuesFiltro}
                        validationSchema={validacaoInicial}
                        onSubmit={() => { }}>
                        {propsFormik => (
                            <Form>
                                {headerGroupsFiltered.map(headerGroup => headerGroup != null &&
                                    headerGroup.headers.map((column, index) => {
                                        const colunaAtual = columns[0].columns[columns[0].columns.findIndex(item => item.accessor === column.id)]
                                        if (colunaAtual.filtro && filtrosColuna.length) {
                                            if (colunaAtual.filtro.tipo === 'pesquisa-geral')
                                                return <FiltroDataGrid propsFormik={propsFormik} tipo={colunaAtual.filtro.tipo ? colunaAtual.filtro.tipo : ''} label={column.render('Header')} name={colunaAtual.filtro.name || colunaAtual.filtro.tipo} type='text'
                                                    placeholder={colunaAtual.Placeholder || colunaAtual.Header} classeTooltip={'tooltip-input-modal'} tamanho='-' value={propsFormik.values[colunaAtual.filtro.name || colunaAtual.filtro.tipo]}
                                                    onBlur={option => {
                                                        gotoPage(0)
                                                        setIndex(0)
                                                        propsFormik.setFieldValue(colunaAtual.filtro.name || colunaAtual.filtro.tipo, option.target.value)
                                                        setConteudoPesquisa({ value: option.target.value, coluna: column })
                                                        setAtualizado(false)
                                                        setUltimaAtualizacao(moment())
                                                    }} />
                                            else if (!colunaAtual.filtro.datePicker && !colunaAtual.filtro.monthPicker) {
                                                return (<>
                                                    <FiltroDataGrid placeholder={colunaAtual.Header ?
                                                        colunaAtual.Header : 'Selecione...'} tipo={colunaAtual.filtro.tipo ? colunaAtual.filtro.tipo : ''} tamanho='-'
                                                        multiplo={colunaAtual.filtro.multiplo === undefined || colunaAtual.filtro.multiplo === true ? true : false} propsFormik={propsFormik} name={colunaAtual.filtro.name || colunaAtual.filtro.tipo}
                                                        colunaFiltros={colunaAtual.filtro}
                                                        label={column.render('Header')} onChange={option => {
                                                            if (option == null) {
                                                                setLimparValueCombo(true)
                                                            }
                                                            gotoPage(0)
                                                            setIndex(0)
                                                            setNovoFiltro(column, option); propsFormik.setFieldValue(colunaAtual.filtro.name || colunaAtual.filtro.tipo, option)
                                                        }} limparValueCombo={limparValueCombo} setarValor={value => {
                                                            setNovoFiltro(column, value); propsFormik.setFieldValue(colunaAtual.filtro.name || colunaAtual.filtro.tipo, value)
                                                        }} onSelect={() => {
                                                            setNovoFiltro(column, propsFormik.values[colunaAtual.filtro.name || colunaAtual.filtro.tipo]);
                                                        }} classeTooltip={'tooltip-input-modal'} /></>)
                                            }
                                            else
                                                return (<><FiltroDataGrid modal placeholder={colunaAtual.Header ?
                                                    colunaAtual.Header : 'Selecione...'} tipo={colunaAtual.filtro.tipo ? colunaAtual.filtro.tipo : ''} tamanho='-' name={colunaAtual.filtro.name || colunaAtual.filtro.tipo}
                                                    label={column.render('Header')} colunaFiltros={colunaAtual.filtro} propsFormik={propsFormik}
                                                    onChange={option => {
                                                        if (option) {
                                                            setNovoFiltro(column, option)
                                                            propsFormik.setFieldValue(colunaAtual.filtro.name || colunaAtual.filtro.tipo, option)
                                                        }
                                                    }}
                                                    onSelect={value => {
                                                        gotoPage(0)
                                                        setIndex(0)
                                                        if (propsFormik.values[colunaAtual.filtro.name || colunaAtual.filtro.tipo] !== undefined) {
                                                            // propsFormik.setFieldValue(colunaAtual.filtro.name || colunaAtual.filtro.tipo, value) 
                                                            setNovoFiltro(column, propsFormik.values[colunaAtual.filtro.name || colunaAtual.filtro.tipo]);
                                                        }
                                                    }}
                                                    setarValor={value => {
                                                        gotoPage(0)
                                                        setIndex(0); setNovoFiltro(column, value); propsFormik.setFieldValue(colunaAtual.filtro.name || colunaAtual.filtro.tipo, value)
                                                    }} classeTooltip={'tooltip-input-modal'} /></>)
                                        }
                                        return <></>
                                    })
                                )}
                            </Form>
                        )}
                    </Formik>
                }
                {width <= 1334 ?
                    <>
                        <div className="ajusta-modal-ordenamento">
                            <h5>Ordenamento</h5>
                        </div>
                        <Ordenaveis placeholder={'Selecione...'} id="ordenaveis" tamanho='-' value={optionsOrdenamentoModal ? optionsOrdenamentoModal : ''} data={obterColunasOrdenaveis()} multiplo={true} icone={'icon-A-Z '} onChange={option => setOptionsOrdenamentoModal(option)} />
                    </>
                    : <></>}
            </ModalCadastro>
        </>
    )
})

function DataGrid(props) {
    const target = useRef(null)

    const exclusao = propsAcao => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir(props.caminhoBase, propsAcao.id,
                    () => {
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const acoesObj = [
        (propsAcao) => <Visualizar to={props.caminhoBase + '/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={props.caminhoBase + '/alteracao/' + propsAcao.id} />,
        (propsAcao) => <Excluir to={props.caminhoBase + '/consulta/'} action={() => exclusao(propsAcao)} />
    ]

    return (
        <Styles>
            {props.headerOcorrencia ? <HeaderOcorrencia titulo={props.titulo} urlCadastro={props.urlCadastro} icone={props.icone} {...props} /> :
                <HeaderConsulta titulo={props.titulo} urlCadastro={props.urlCadastro} icone={props.icone} {...props} />}
            <Table ref={props.target || target} columns={props.columns} data={props.data && props.data.length > 0 ? props.data : []} acoes={props.acoes} acoesObj={props.acoesObj ? props.acoesObj : acoesObj} caminhoBase={props.caminhoBase} caminhoConsultaComFiltros={props.caminhoConsultaFiltros}
                status={props.status} idStatus={props.idStatus} colunaStatus={props.colunaStatus} setPageIndex={props.setPageIndex} cadastro={`${props.caminhoBase}/cadastro`} tituloLista={props.tituloLista || props.titulo} listaPersonalizada={props.listaPersonalizada} removerTotalPaginas={props.removerTotalPaginas
                } filtroAtivo={props.filtroAtivo} nomeSession={props.nomeSession} helpComponent={props.helpComponent} updateStatus={props.updateStatus} inicializarFiltrado={props.inicializarFiltrado} ImpressaoOff={props.ImpressaoOff} />
            {/* <Atalho formik={propsFormik} cadastro={props}/> */}
        </Styles>
    )
}

export default DataGrid