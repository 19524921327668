import React, { useState } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'

const ConsultaAnestesiaOdonto = props => {
    const [data, setData] = useState([])
    const columns = React.useMemo(
        () => [{
            id: 'consulta-anestesia-odontologia',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                }, {
                    Header: 'Ano',
                    accessor: 'ano',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'ano_anestesia',
                        idColuna: 'ano'
                    }
                }, {
                    Header: 'Data Inicial',
                    accessor: 'data_inicio',
                    ordenamento: {
                        tipo: 'data'
                    }
                }, {
                    Header: 'Data Final',
                    accessor: 'data_fim',
                    ordenamento: {
                        tipo: 'data'
                    }
                },
                {
                    Header: 'Local Atendimento',
                    accessor: 'local_atendimento',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Tabela(s)',
                    accessor: 'tabelas',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'tabela',
                        idColuna: 'tabela',
                    }
                },
               {
                    Header: 'Data Registro',
                    accessor: 'data_registro_formatada',
                    ordenamento: {
                        tipo: 'data'
                    }
                }, 
            ]
        }], []
    )

    return (
        <div>
            <DataGridAlternativa data={data} columns={columns} caminhoBase='/faturamento/anestesiaodontologia' setData={setData} acoes={true} modulo={props.modulo}
                titulo={'Consulta Tabela Anestesia Odontológica'} tituloLista={'Lista Tabela Anestesia Odontológica'} urlCadastro='/faturamento/anestesiaodontologia/cadastro' icone={'icon-Professor'} caminhoConsultaFiltros='/faturamento/anestesiaOdontologiaPaginacao' />
        </div>
    )
}

export default ConsultaAnestesiaOdonto