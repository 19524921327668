import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FieldArray, FastField, useFormikContext } from "formik";
import Yup, { validaData, periodoData, validaHora, validaCPF, validaCNPJ, periodoHora, dataMenorAtual } from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { ModalDuasOpcoes, ModalEscolha } from '../../../../components/Modais';
import { get, salvar, validar, validarComPost, getComAuditoria } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao, InputDatePicker, TextArea, InputTimePicker, InputSwitch, InputTelefone, InputValor, InputSwitchSemFormik, InputValorNovo, InputPadraoSimples, InputMascaraSimples, ControlledInput, InputCurrency, InputCpfCnpj, InputPadraoSimplesAlternativo } from '../../../../components/InputsV2'
import {
    InputTipoAtendimento, InputEmpresa, InputPercentual, InputTipoAtendimentoEquipe, InputBeneficiarioGuia, InputMunicipio,
    InputPacote,
    InputTipoAtendimentoEquipeParticular,
    InputSocioGuia,
    InputEmpresaGuia,
    InputProcedimentosGuiaParticular,
    InputEstabelecimentoSaudeContrato,
    InputAcomodacaoContratoParticular,
    InputPacoteContrato,
    InputCirurgiaoCombo,
    InputGuiaStatus
} from '../../../../components/InputsAutoCompleteV2'
import { BotaoPadrao } from '../../../../components/Botoes'
import { ajustarDadosTelefone, convertValueToFloat, formatarMoeda, mensagens, useWindowSize } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import InputArquivoS3 from '../../../../components/InputArquivoS3V2'
import { MODULO } from '../../../../util/Enums'
import { BtnAcao } from '../../../../components/Acoes'
import { formatMoeda, isUrgency, isUrgencyOnHoliday, obterFeriados } from '../../../../util/FuncoesComuns';
import { cloneDeep } from 'lodash';
import { Overlay, Tooltip } from 'react-bootstrap';
import { FiAlertTriangle } from 'react-icons/fi';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { getApiExterna } from '../../../../util/AcessoApi';

const CadastroGuiaParticular = (props) => {
    let { id } = useParams()

    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [dataArquivos, setDataArquivos] = useState(null)
    const [limparArquivos, setLimparArquivos] = useState(false)
    const [disableCampos, setDisableCampos] = useState(true)
    const [dataProcedimento, setDataProcedimento] = useState('')
    const [defaultOptionsPaciente, setDefaultOptionsPaciente] = useState([])
    const [refPaciente, setRefPaciente] = useState({})
    const [procedimentoBloqueado, setProcedimentoBloqueado] = useState(true)
    const [dataTelefone, setDataTelefone] = useState([])
    const [minimizar, setMinimizar] = useState([true, false])
    const refProced = useRef(null)
    const [totalProcedimentos, setTotalProcedimentos] = useState()
    const [contrato, setContrato] = useState({ contrato_id: 0, tabelas: [] })
    const [contratoConfig, setContratoConfig] = useState([])
    const refAcomodacao = useRef(null)
    const inputFocus = useRef(null)
    const history = useHistory()
    const idComplementar = history.location.state && history.location.state.idComplementar ? history.location.state.idComplementar : null
    const [carregandoAlteracao, setCarregandoAlteracao] = useState(true)
    const [showModalDataEntrega, setShowModalDataEntrega] = useState(false)
    const [msgValidacaoDataEntrega, setMsgValidacaoDataEntrega] = useState(<></>)
    const [showModalAposSalvar, setShowModalAposSalvar] = useState(false)
    const [avisoSalvar, setAvisoSalvar] = useState()
    const [feriados, setFeriados] = useState([])
    const refInternos = useRef(null)
    const [dataProced, setDataProced] = useState()
    const [horaInicial, setHoraInicial] = useState()
    const [horaFinal, setHoraFinal] = useState()
    const [horaInicialAlterada, setHoraInicialAlterada] = useState(false)
    const [horaFinalAlterada, setHoraFinalAlterada] = useState(false)
    const [dataProcedimentoAlterada, setDataProcimentoALterada] = useState(false)
    const [urgenciaProcedimento, setUrgenciaProcedimento] = useState(false)
    const [honorarios, setHonorarios] = useState()

    let validacaoInicial = Yup.lazy(({ procedimentos, executantes_internos }) => Yup.object({
        data_procedimento: Yup.string().nullable().required()
            .test('Data', 'Data inválida!', function (value) { return validaData(this.parent.data_procedimento) })
            .test('Data Procedimento', 'Data Procedimento deve ser menor ou igual a Data Atual!', function (value) { return dataMenorAtual(this.parent.data_procedimento) }),
        empresa: Yup.object().nullable().required(),
        estabelecimento_saude: Yup.string().nullable().required(),
        nome_paciente: Yup.string().nullable().required(),
        hora_inicial: Yup.string().nullable().required().test('Hora Inicial', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_inicial) }),
        hora_final: Yup.string().nullable().required().test('Hora Final', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_final) }),
        arquivo: Yup.string().nullable(),
        acomodacao: Yup.object().nullable().required(),
        data_nasc: Yup.string().nullable().test('Data', 'Data inválida!', function (value) { return validaData(this.parent.data_nasc) }),
        tipo_atendimento: Yup.string().nullable().required(),
        sequencia_hospital: Yup.number().nullable().positive('O número deve ser positivo'),
        data_entrega: Yup.string().nullable()
            .test('Data', 'Data inválida!', function (value) { return validaData(this.parent.data_entrega) }),
        // .test('Data Entrega', 'Data Entrega não pode ser maior que Data do Procedimento', function (value) {
        //     return periodoData(value, this.parent.data_procedimento)
        // }),
        cpf_cnpj: Yup.string().nullable().test('CPF/CNPJ', 'CPF/CNPJ inválido', function (value) { return value && value.replace(/\D/g, '').length > 11 ? validaCNPJ(this.parent.cpf_cnpj) : validaCPF(this.parent.cpf_cnpj) })
    }).shape({
        procedimentos: Yup.array()
            .test('Procedimentos', 'Insira ao menos 1 Procedimento', function (value) {
                return this.parent.procedimentos && this.parent.procedimentos[0]?.procedimento !== undefined
            })
            .of(
                Yup.object().shape({
                    reducao_acrescimo: Yup.number().nullable().max(999).test('Redução Acréscimo', 'Campo Obrigatório!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '');
                        return !(procedimentos.length - 1 != index && !value)
                    }), // these constraints take precedence
                    porcentagem_acm: Yup.number().nullable().max(999).test('Porcentagem Acm', 'Campo Obrigatório!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '');
                        return !(procedimentos.length - 1 != index && !value)
                    })
                })
            ),
        executantes_internos: Yup.array()
            .test('Executantes EQUIPE', 'Insira ao menos 1 Profissional Executante', function (value) {
                return this.parent.executantes_internos && this.parent.executantes_internos[0]?.executante !== undefined
            }).of(
                Yup.object().shape({
                    executante: Yup.object().nullable()
                        .test('Socio', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(executantes_internos.length - 1 != index && !value)
                        }),
                    data: Yup.string().nullable()
                        .test('Data', 'Data inválida!', function (value) {
                            return validaData(this.parent.data)
                        })
                        .test('Data', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(executantes_internos.length - 1 != index && !value)
                        }),

                    hora_inicial: Yup.string().nullable()
                        .test('Hora Inicial', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_inicial) })
                        .test('Hora Inicial', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(executantes_internos.length - 1 != index && !value)
                        }),

                    hora_final: Yup.string().nullable()
                        .test('Hora Final', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_final) })
                        .test('Hora Final', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(executantes_internos.length - 1 != index && !value)
                        }),
                    executante_tipo_atendimento: Yup.string().nullable().max(999).test('Tipo Atendimento', 'Campo Obrigatório!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '');
                        return !(executantes_internos.length - 1 != index && !value)
                    }),
                })
            )
    }))

    const validarSubmit = async (values, propsFormik, nValidacao) => {
        setSalvando(true)
        var msgRetorno = [];
        if (moment(values.hora_inicial, 'HH:mm') > moment(values.hora_final, 'HH:mm'))
            msgRetorno.push(<p><b>Hora Final anterior a Hora inicial</b></p>)


        if (values?.data_procedimento && values?.data_entrega) {
            let dtProcedimento = moment(values?.data_procedimento, 'DD/MM/YYYY').toDate();
            let dtEntrega = moment(values?.data_entrega, 'DD/MM/YYYY').toDate();
            if (!(dtEntrega >= dtProcedimento))
                msgRetorno.push(<p><b>Data Entrega inferior ou igual a Data Procedimento</b></p>)
        }

        await validarComPost('/faturamento/validarGuiaParticular', { id: values.id, codigo_barras: values.codigo_barras, executantes_internos: values.executantes_internos }, async (e) => { },
            (e) => {
                msgRetorno.push(<>{e.response.data.alertas.map((x, idx) => x.indexOf('*') > -1 ? <>{x.replace('*', '')}<br /></> : <div className='mb-1'>{idx > 0 ? <br /> : <></>}<b>{x}</b><br /></div>)}</>)
            })

        if (msgRetorno.length > 0) {
            setMsgValidacaoDataEntrega(<><div className='mb-1'>{msgRetorno}<br />Deseja Continuar ? </div></>)
            setShowModalDataEntrega(true)
        } else {
            Submit(values, propsFormik)
        }


    }

    const funcaoAposSim = (formik) => {
        setShowModalAposSalvar(false)
        setShowModalDataEntrega(false)
        setShowModalValidacao(false)
        Submit(formik.values, formik)
    }



    const manter = ['socio', 'tipo_ocorrencia', 'implica_hierarquia', 'interfere_agendamento']

    const handleValidation = async (propsFormik) => {
        await propsFormik.validateForm()
            .then(errors => {

                propsFormik.setTouched({
                    data_procedimento: false, empresa: false, operadora_saude: false,
                    estabelecimento_saude: false, nome_beneficiario: false, hora_final: false, hora_inicial: false, acomodacao: false,
                    tipo_atendimento: false, procedimentos: [{ procedimento: false, reducao_acrescimo: false }], executantes_internos: [{ executante: false, tipo_pessoa: false, empresa: false }],
                    executantes_externos: [{ executante: false, data: false, hora_inicial: false, hora_final: false, tipo_atendimento: false }]
                })

            })
    };

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        values.arquivo = dataArquivos
        values.telefone = dataTelefone;
        values.telefone = ajustarDadosTelefone(values.telefone)
        values.idComplementar = idComplementar

        salvar(
            values,
            '/faturamento/guiaParticular',
            (e) => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    setSalvando(false)
                    var objListas = {
                        executantes_internos: [{ data: undefined, hora_inicial: undefined, hora_final: undefined, executante: undefined, empresa: undefined, executante_tipo_atendimento: { value: 1, label: 'Rotina' } }],
                        procedimentos: [{ procedimento: undefined, porte: undefined, porte_anestesico: undefined, valor: undefined, valor_total: undefined, reducao_acrescimo: '100', urgencia: false, urg: false, quantidade: 1 }],
                    }
                    propsFormik.setFieldValue('hora_inicial', undefined)
                    propsFormik.setFieldValue('hora_final', undefined)
                    setDataProced('')
                    propsFormik.resetForm({})
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    propsFormik.setFieldValue('procedimentos', objListas.procedimentos)
                    propsFormik.setFieldValue('executantes_internos', objListas.executantes_internos)
                    // propsFormik.setFieldValue('executantes_internos', objListas.executantes_internos)
                    setContrato({ contrato_id: 0, tabelas: [] })
                    // setSalvando(false)
                    setSalvarENovo(false)
                    setLimparArquivos(true)
                    setShowModalValidacao(false)
                    setShowModalDataEntrega(false)
                    refProced.current.setTotalprocedimentos('R$ 0,00');
                    document.getElementById("data_procedimento").focus()
                    setShowModalAposSalvar(true)
                    setAvisoSalvar({ guia: e?.guia, paciente: e?.paciente })
                    handleValidation(propsFormik)
                }
                else
                    // props.history.push('/faturamento/guia-particular/consulta')
                    if (id) {
                        props.history.push({ pathname: '/faturamento/guia-particular/consulta' })
                    } else
                        props.history.push({ pathname: '/faturamento/guia-particular/consulta', state: { guia: e?.guia, paciente: e?.paciente } })


                // setSalvando(false)
            },
            (e) => {

                setSalvando(false)
            })
    }

    useEffect(() => {
        if (refPaciente.current && Array.isArray(defaultOptionsPaciente) && defaultOptionsPaciente.length > 1) {
            refPaciente.current.focus();
        }
    }, [refPaciente, defaultOptionsPaciente])

    useEffect(() => {

        var objListas = {
            executantes_internos: [{ data: undefined, hora_inicial: undefined, hora_final: undefined, executante: undefined, empresa: undefined, executante_tipo_atendimento: { value: 1, label: 'Rotina' } }],
            pacote: [{ pacote_descricao: undefined, pacote_id: undefined, pacote_valor: undefined }],
            procedimentos: [{ procedimento: undefined, porte: undefined, porte_anestesico: undefined, valor: undefined, valor_total: undefined, reducao_acrescimo: '100', urgencia: false, urg: false, quantidade: 1 }],
        }

        if (idComplementar != null) {

            Promise.resolve(get(`/faturamento/guiacomplementar/${idComplementar}`)).then(val => {
                const pacientes = val.nome_paciente
                if (pacientes && pacientes != true && pacientes.length === 1) {
                    val.nome_paciente = pacientes[0]
                }
                else if (!pacientes)
                    setDefaultOptionsPaciente(true)
                else {
                    setDefaultOptionsPaciente(val.nome_paciente)
                    val.nome_paciente = undefined
                }

                setProcedimentoBloqueado(false)
                val.cores_id = 1
                val.executantes_internos = [...val.executantes_internos.map(x => ({ alteracao: true, ...x })), ...objListas.executantes_internos];
                val.procedimentos = [{ procedimento: undefined, quantidade: 1, urg: false, porcentagem: { value: 1, label: '100%' }, porcentagem_acm: 100, reducao_acrescimo: 100 }]
                val.tipo_honorario = { value: 1, label: 'Honorário Médico' }
                val.cirurgiao = val.cirurgiao.value ? val.cirurgiao : val.cirurgiao = ''
                // setTotalProcedimentos(formatMoeda(val.procedimentos.filter(x => x.procedimento).reduce((partialSum, a) => partialSum + parseFloat(a.valor_total), 0)))


                setValoresIniciais(val)
                setDataProced(val.data_procedimento)
                setHoraInicial(val.hora_inicial)
                setHoraFinal(val.hora_final)

                setContrato({ contrato_id: 0, tabelas: [] })

                document.getElementById("codigo_barras").focus()

            })
        }
        if (id) {
            Promise.resolve(getComAuditoria(`/faturamento/guiaParticular/${id}`, { modulo: MODULO['/faturamento/guiaparticular'], id: id })).then(val => {

                setDataArquivos([...val.arquivo] || [])
                objListas.executantes_internos = [{ executante: undefined, data: val.data_procedimento, hora_inicial: val.hora_inicial, hora_final: val.hora_final, executante_tipo_atendimento: val.tipo_atendimento }];

                setProcedimentoBloqueado(false)
                props.setStatus(val.status)
                val.pacote = [...val.pacote.map(x => ({ alteracao: true, ...x })), ...objListas.pacote];
                val.procedimentos = [...val.procedimentos.map(x => ({ alteracao: true, ...x })), ...objListas.procedimentos];
                val.paciente = { 'nome_nfse': val.nome_nfse, 'data_nasc_nfse': val.data_nasc_nfse, 'cpf_cnpj': val.cpf_cnpj, 'cep': val.cep, 'complemento': val.complemento, 'logradouro': val.logradouro, 'numero': val.numero, 'bairro': val.bairro, 'email': val.email, 'municipio': val.municipio?.value ? val.municipio : undefined, 'telefone': val.telefone, 'nfse_numero': val.nfse_numero, 'codigo_verificacao': val.codigo_verificacao }
                setTotalProcedimentos(formatMoeda(val.procedimentos.filter(x => x.procedimento).reduce((partialSum, a) => partialSum + parseFloat(a.valor_total), 0)))
                setUrgenciaProcedimento(val.procedimentos[0].urg)
                setContrato({ contrato_id: val.estabelecimento_saude.contrato_id })
                setDataTelefone(val.telefone)
                let objetoContratoConfig = { ...val.acomodacao, hora_inicial: val.hora_inicial, hora_final: val.hora_final }
                obterContratoConfig(objetoContratoConfig, val.data_procedimento, val.estabelecimento_saude.contrato_id)

                val.executantes_internos = [...val.executantes_internos.map(x => ({ alteracao: true, ...x })), ...objListas.executantes_internos];
                if (val.cirurgiao?.value == null)
                    val.cirurgiao = null
                setValoresIniciais(val)
                setHonorarios(val.tipo_honorario)
                refAcomodacao.current.atualizar()
                setDataProced(val.data_procedimento)
                setHoraInicial(val.hora_inicial)
                setHoraFinal(val.hora_final)
            })
        } else {
            setDataArquivos([])

            setValoresIniciais({
                codigo_barras: "",
                data_procedimento: "",
                operadora_saude: "",
                estabelecimento_saude: "",
                guia_principal: "",
                senha: "",
                matricula: "",
                cartao_sus: "",
                nome_beneficiario: "",
                data_nasc: "",
                acomodacao: "",
                hora_inicial: "",
                hora_final: "",
                cirurgiao: "",
                atendimento_rn: "",
                tipo_atendimento: { value: 1, label: 'Rotina' },
                sequencia_hospital: "",
                nfse: false,
                tipo_honorario: { value: 1, label: 'Honorário Médico' },
                tipo_atendimento_tiss: { value: 1, label: 'Honorário Médico' },
                procedimentos: [{ procedimento: undefined, quantidade: 1, urg: false, porcentagem: { value: 1, label: '100%' }, porcentagem_acm: 100, reducao_acrescimo: 100 }],
                executantes_externos: [{ executante: undefined, tipo_pessoa: { value: 2, label: 'Pessoa Jurídica' } }],
                executantes_internos: [{ executante: undefined, executante_tipo_atendimento: { value: 1, label: 'Rotina' } }],
                pacote: [{ pacote_id: undefined, pacote_descricao: undefined, pacote_valor: undefined }],
            })

            setDataProced('')
            setHoraInicial('')
            setHoraFinal('')

            document.getElementById("codigo_barras").focus()
            setCarregandoAlteracao(false)
        }

    }, [id])

    useEffect(() => {
        if (idComplementar)
            setTimeout(() => {
                refAcomodacao.current.atualizar()
                setContrato({ contrato_id: 0, tabelas: [] })
            }, 1000);
    }, [idComplementar])

    useEffect(() => {
        if (valoresIniciais.hora_final)
            setTimeout(_ => setCarregandoAlteracao(false), 3000)
    }, [valoresIniciais])

    const onBlurHora = useCallback((name, hora, propsFormik) => {
        function ajustaHora() {
            if (hora.toString().includes('_'))
                return moment({ hour: hora }).format('HH:mm');

            return hora
        }

        let arrayExecutantes = [...propsFormik.values.executantes_internos]
        arrayExecutantes = arrayExecutantes.map(item => {
            if (horaInicialAlterada || horaFinalAlterada) {
                item[name] = ajustaHora() || propsFormik.values[name]
                item[name + "_changed"] = true

                setHoraInicialAlterada(false)
                setHoraFinalAlterada(false)
            }

            return item
        })

        propsFormik.setFieldValue('executantes_internos', arrayExecutantes);
        if (horaInicialAlterada || horaFinalAlterada)
            setUrgencia(propsFormik, dataProced);
    }, [feriados, horaInicialAlterada, horaFinalAlterada])

    const setUrgencia = async (propsFormik, data_procedimento) => {

        const valores = propsFormik.values
        if (data_procedimento && valores.data_procedimento && valores.hora_inicial && valores.hora_final) {

            let urgencia = false

            if (isUrgencyOnHoliday(feriados, data_procedimento, valores.hora_inicial, valores.hora_final))
                urgencia = true
            else
                urgencia = await isUrgency(data_procedimento, valores.hora_inicial, valores.hora_final)

            let array = propsFormik.values.procedimentos
            array = array.map(ret => {
                ret.urg = urgencia;
                return ret
            });

            setUrgenciaProcedimento(urgencia)
            if (urgencia) {
                propsFormik.setFieldValue(`tipo_atendimento`, { value: 3, label: 'Voluntário' })

            } else {
                propsFormik.setFieldValue(`tipo_atendimento`, { value: 1, label: 'Rotina' })
            }
            propsFormik.setFieldValue('procedimentos[0].urg', urgencia);


            if (propsFormik.values.tipo_honorario.value != 2) {
                // refProcedimento.current.recalcular();
                refProced.current.recalcular()
                refInternos.current.atualizarAtendimento()
            }

        }
    }

    // const onChangeData = useCallback((value, propsFormik) => {
    //     if (propsFormik.values.executantes_internos && propsFormik.values.executantes_internos.filter(item => item.executante).length === 0) {
    //         let arrayExecutantes = [...propsFormik.values.executantes_internos]
    //         arrayExecutantes = arrayExecutantes.map((item, index) => {
    //             item.data = value || undefined
    //             item.data_changed = true
    //             return item
    //         })
    //         propsFormik.setFieldValue('executantes_internos', arrayExecutantes);
    //     }
    // }, [feriados])

    const onChangeData = (propsFormik, value) => {

        let arrayExecutantes = [...propsFormik.values.executantes_internos]
        arrayExecutantes = arrayExecutantes.map(item => {

            item['data'] = value || undefined
            item['data_changed'] = true


            return item
        })
        propsFormik.setFieldValue('executantes_internos', arrayExecutantes);
        propsFormik.setFieldValue('inicio_faturamento', moment(propsFormik.values.data_procedimento, 'DD/MM/YYYY').format('DD/MM/YYYY HH:mm'));
        setUrgencia(propsFormik, value);
    }

    const buscarDadosPaciente = async (option, propsFormik) => {

        await Promise.resolve(get(`/faturamento/obterPacienteGuia`, { paciente: option })).then(val => {
            if (!val) {
                // mostrarToast('erro', 'Dados do paciente selecionado não encontrado!')
                let limpar = ['paciente.nome_nfse', 'paciente.data_nasc_nfse', 'cpf_cnpj', 'paciente.cep', 'paciente.complemento', 'paciente.logradouro', 'paciente.numero', 'paciente.bairro', 'paciente.email', 'paciente.municipio', 'paciente.telefone', 'paciente.nfse_numero']
                limpar.map(x => propsFormik.setFieldValue(x, ''))
                setDataTelefone([])
                return
            }
            else {
                setDataTelefone(val.fone)
                val.municipio = !val.municipio?.value ? null : val.municipio
                propsFormik.setFieldValue('paciente.cep', val.cep)
                propsFormik.setFieldValue('cpf_cnpj', val.cpf_cnpj)
                propsFormik.setFieldValue('paciente.data_nasc_nfse', val.data_nasc_nfse)
                propsFormik.setFieldValue('paciente.email', val.email)
                propsFormik.setFieldValue('paciente.logradouro', val.logradouro)
                propsFormik.setFieldValue('paciente.complemento', val.complemento)
                propsFormik.setFieldValue('paciente.bairro', val.bairro)
                propsFormik.setFieldValue('paciente.municipio', val.municipio)
                propsFormik.setFieldValue('paciente.nome_nfse', val.nome_nfse)
                propsFormik.setFieldValue('paciente.numero', val.numero)
                propsFormik.setFieldValue('data_nasc', val.data_nasc_nfse)


            }
        })
    }

    const buscarCep = (e, propsFormik) => {
        getApiExterna(`https://viacep.com.br/ws/${propsFormik.values.paciente.cep}/json/`, response => {
            if (response.data.erro) {
                mostrarToast('erro', 'CEP não encontrado')
                return;
            }

            propsFormik.setFieldValue('paciente.logradouro', response.data.logradouro)

            let obj = {
                value: response.data.ibge,
                label: response.data.localidade.toString().toUpperCase() + " - " + response.data.uf
            }

            propsFormik.setFieldValue('paciente.municipio', obj)
            propsFormik.setFieldValue('paciente.bairro', response.data.bairro)
        })
    }

    const obterContratoConfig = (obj, data_procedimento, contrato_id) => {
        if (!obj)
            return;
        Promise.resolve(get(`/faturamento/contratoporteanestesicocombo`, { contrato: contrato_id || contrato.contrato_id, acomodacao: obj.value, data_procedimento: data_procedimento || dataProcedimento, hora_inicial: obj.hora_inicial, hora_final: obj.hora_final })).then(val => {
            setContratoConfig(val)
        })
    }

    const LimparContrato = () => {
        setContrato({ contrato_id: 0, tabelas: [] })
    }

    const inputCpfCnpj = useRef();

    useEffect(() => {

        refAcomodacao.current.atualizar()
    }, [contrato?.contrato_id, honorarios?.value, dataProced])

    useEffect(() => {
        obterFeriados((dados) => {
            setFeriados(dados)
        })
    }, [])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
            {propsFormik => (<Form>
                <div className={'card mb-20'}>
                    <div className={'card-body'}>
                        <div className='row'>
                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />

                            <InputMascaraSimples
                                mascara="111.111.111-11"
                                tamanho="col-xl-3 col-lg-3 col-md-12"
                                label="Código Barras"
                                name="codigo_barras"
                                id="codigo_barras"
                                type="text"
                                placeholder="Código Barras"
                                autoFocus
                            />
                            <InputDatePicker
                                denyIndex
                                tamanho="col-xl-3 col-lg-3 col-md-12"
                                label="Data do Procedimento"
                                name="data_procedimento" id='data_procedimento' obrigatorio
                                type="text"
                                setarValor={value => {
                                    setDataProcedimento(value)
                                    propsFormik.setFieldTouched("data_procedimento", true)

                                    propsFormik.setFieldValue("data_procedimento", value)
                                    setDisableCampos(false)
                                    if ((value != undefined && dataProced != undefined) && value !== dataProced) {
                                        setDataProced(value)
                                        setDataProcimentoALterada(true)

                                        onChangeData(propsFormik, value)

                                    }
                                }}
                                onChange={value => {
                                    setDataProcedimento(value)
                                    propsFormik.setFieldValue("data_procedimento", value)
                                    if (contratoConfig && contratoConfig.length != 0) {
                                        refProced.current.recalcular()
                                    }
                                    if (value) {
                                        setDisableCampos(false)
                                    } else {
                                        propsFormik.setFieldValue("operadora_saude", null)
                                        setDisableCampos(true)
                                    }
                                    // onChangeData(value, propsFormik)
                                }}

                                placeholder="Data do Procedimento"
                            />

                            <InputEmpresa label={'Empresa'} placeholder='Empresa' classeTooltip={'tooltip-input-modal'} array
                                name={`empresa`} propsFormik={propsFormik} tamanho="col-xl-2 col-lg-2 col-md-12" obrigatorio />

                            <InputEstabelecimentoSaudeContrato data_procedimento={dataProcedimento} placeholder={disableCampos && 'Selecione a Data do Procedimento' || 'Estabelecimento de Saúde'} disabled={disableCampos} obrigatorio
                                label="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={props.modal && 'tooltip-input-modal'} tamanho="col-xl-4 col-lg-4 col-md-12"
                                cacheOptions={false} onChange={option => {
                                    propsFormik.setFieldValue('estabelecimento_saude', option)

                                    if (option && option.contrato_id)
                                        setContrato({ contrato_id: option.contrato_id, tabelas: option.tabelas })
                                    else
                                        setContrato({ contrato_id: 0, tabelas: [] })

                                    setProcedimentoBloqueado(!(option && propsFormik.values.acomodacao))
                                }} array />
                            {/* <InputEstabelecimentoSaude label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={props.modal && 'tooltip-input-modal'} tamanho="col-xl-4 col-lg-4 col-md-12" obrigatorio /> */}

                            <InputPadraoSimples
                                tamanho="col-xl-4 col-lg-4 col-md-12"
                                label="Nome Social"
                                name="nome_social"
                                type="text"
                                placeholder="Nome Social"
                                performance={true}
                                maxLength={70}
                            />
                            <InputBeneficiarioGuia setReference={setRefPaciente} defaultOptions={defaultOptionsPaciente} setDefaultOptionsPaciente={setDefaultOptionsPaciente} label="Nome Paciente" onChange={option => { buscarDadosPaciente(option, propsFormik); setDefaultOptionsPaciente(true) }} placeholder="Nome Paciente" name="nome_paciente" propsFormik={propsFormik} botaoVisivel={true} modal classeTooltip={props.modal && 'tooltip-input-modal'} tamanho="col-xl-4 col-lg-4"
                                obrigatorio idGuiaComplementar={idComplementar} openMenuOnFocus={Array.isArray(defaultOptionsPaciente) && defaultOptionsPaciente.length > 1} />

                            <InputSwitch label='NFS-e Paciente?' propsFormik={propsFormik} onChange={(option) => {
                                propsFormik.setFieldValue('nfse', option)
                                let cloneArray = [...minimizar]
                                cloneArray[0] = !cloneArray[0]
                                setMinimizar(cloneArray)
                            }}
                                quebrarLinha classeDiv='form-group ajusta-switch ' name='nfse' size="md" tamanho={!props.modal && 'col-xl-1 col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputDatePicker
                                denyIndex
                                tamanho="col-xl-3 col-lg-3 col-md-6"
                                label="Data de Nascimento"
                                name="data_nasc"
                                type="text"
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_nasc", value)
                                }}
                                placeholder="Data de Nascimento"
                            />
                            <InputTipoAtendimentoEquipeParticular label="Tipo Honorário"
                                placeholder={!propsFormik.values.data_procedimento && 'Selecione a Data do Procedimento' || 'Tipo Honorário'}
                                name="tipo_honorario"
                                isDisabled={!propsFormik.values.data_procedimento}
                                onChange={option => {
                                    setHonorarios(option)
                                    propsFormik.setFieldValue('tipo_honorario', option)
                                    if (propsFormik.values.tipo_honorario && option && option.value !== (propsFormik.values.tipo_honorario.value)) {
                                        propsFormik.setFieldValue('procedimentos', [{ procedimento: undefined, quantidade: 1, porte: undefined, valor_porte: undefined, valor_total: undefined, porcentagem: { value: 1, label: '100%' }, porcentagem_acm: 100, reducao_acrescimo: 100 }])
                                        setTotalProcedimentos('R$ 0,00');
                                        refProced.current.setTotalprocedimentos('R$ 0,00');

                                    }
                                }}
                                propsFormik={propsFormik}
                                classeTooltip={'tooltip-input-modal'}
                                tamanho="col-xl-4 col-lg-4 col-md-6" />
                            <InputTimePicker
                                denyIndex label="Hora Inicial" name="hora_inicial" type="text" placeholder="Inicial" tamanho={!props.modal && "col-xl-2 col-lg-2 col-md-5"} classeTooltip={props.modal && 'tooltip-input-modal'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("hora_inicial", value)
                                    if ((value != undefined && horaInicial != undefined) && value !== horaInicial) {
                                        setHoraInicial(value)
                                        setHoraInicialAlterada(true)
                                    }
                                }}
                                obrigatorio myBlur={event => onBlurHora('hora_inicial', horaInicial, propsFormik)}
                            />
                            <InputTimePicker
                                denyIndex label="Hora Final" name="hora_final" type="text" placeholder="Final" tamanho={!props.modal && "col-xl-2 col-lg-2 col-md-5"} classeTooltip={props.modal && 'tooltip-input-modal'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("hora_final", value)
                                    if ((value != undefined && horaFinal != undefined) && value !== horaFinal) {

                                        setHoraFinal(value)
                                        setHoraFinalAlterada(true)
                                    }
                                }} myBlur={event => onBlurHora('hora_final', horaFinal, propsFormik)}
                                obrigatorio
                            />
                            <InputAcomodacaoContratoParticular setarSeForUm={true} ref={refAcomodacao} label='Acomodação' obrigatorio name="acomodacao" contrato={contrato.contrato_id} propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} modal={true}
                                tamanho="col-xl-4 col-lg-4 col-md-6" limparDados={propsFormik.values.estabelecimento_saude ? true : false} isClearable={false}
                                id={id} honorarios={honorarios}
                                aposAtualizar={(value) => {
                                    if (value) {
                                        obterContratoConfig(value, valoresIniciais && valoresIniciais.data_procedimento)
                                    }
                                }}
                                onChange={option => {
                                    propsFormik.setFieldTouched('acomodacao', true)
                                    propsFormik.setFieldValue('acomodacao', option)
                                    obterContratoConfig(option, valoresIniciais && valoresIniciais.data_procedimento)
                                    propsFormik.setFieldValue('procedimentos', propsFormik.values.procedimentos.map(x => {
                                        x.alteracao = false;
                                        x.reducao_acrescimo = 100
                                        return x
                                    }))
                                }} array />
                            <InputCirurgiaoCombo tamanho="col-xl-6 col-lg-6 col-md-12" label="Cirurgião" name="cirurgiao" multiplo={false} placeholder="Cirurgião"
                                propsFormik={propsFormik} botaoVisivel={true} modal={true} focusAfter={inputFocus} salvarENovo={salvarENovo} {...props} />
                            <InputSwitch label='Atendimento RN?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch ' name='atendimento_rn' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputTipoAtendimento tamanho="col-xl-4 col-lg-5 col-md-6" label="Tipo de Atend. Guia" name="tipo_atendimento" multiplo={false} placeholder="Tipo de Atend. Guia"
                                propsFormik={propsFormik} botaoVisivel={true} modal obrigatorio />
                            <InputPadraoSimplesAlternativo performance={true}
                                tamanho="col-xl-1 col-lg-5 col-md-6"
                                label="Seq."
                                name="sequencia_hospital"
                                type="number"
                                placeholder="Seq."
                                maxLength={"3"}
                            />
                            <TextArea performance={true} label='Observação' name='observacao' type='text' placeholder='Observação' tamanho={'col-xl-5 col-lg-6 col-md-12'} classeTooltip={'tooltip-input-modal'} />
                            <InputDatePicker
                                denyIndex
                                tamanho="col-xl-3 col-lg-4"
                                label="Data Entrega"
                                name="data_entrega"
                                type="text"
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_entrega", value)
                                }}
                                placeholder="Data Entrega"
                            />
                            <br />
                        </div>
                    </div>
                </div>
                <div className="mt-40">
                    <HeaderCadastro
                        titulo={'Dados NFS-e'}
                        classeChildren='space-between-100'
                        minimizar
                        idxMinimizar={0}
                        arrayMinimizar={minimizar}
                        setArrayMinimizar={setMinimizar} >
                    </HeaderCadastro>
                </div>
                {!minimizar[0] ?
                    <div className="card mb-20">
                        <div className="card-body">
                            <div className='row'>
                                <InputPadrao performance={true} label='Nome NFS-e' name='paciente.nome_nfse' type='text' placeholder='Nome' obrigatorio={true}
                                    tamanho={!props.modal && 'col-xl-5 col-sm-12'} classeTooltip={props.modal && 'tooltip-input-modal'} salvarENovo={salvarENovo} />
                                <InputDatePicker denyIndex tamanho={!props.modal && "col-lg-4 col-md-7 col-xl-3"} label="Data Nascimento" name="paciente.data_nasc_nfse" type="text"
                                    setarValor={value => {
                                        propsFormik.setFieldValue("data_nasc_nfse", value)
                                    }} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputCpfCnpj
                                    tamanho={!props.modal && "col-lg-3 col-xl-2"}
                                    label="CPF/CNPJ"
                                    name="cpf_cnpj"
                                    type="text"
                                    mascara
                                    value={propsFormik.values.cpf_cnpj}
                                    placeholder="CPF/CNPJ"
                                    classeTooltip={props.modal && 'tooltip-input-modal'} />

                                <InputPadrao
                                    mascara="11111-111"
                                    tamanho="col-lg-2 col-sm-6 col-xl-2"
                                    label="CEP"
                                    name="paciente.cep"
                                    type="text"
                                    performance={true}
                                    placeholder="CEP" onBlur={e => buscarCep(e, propsFormik)} classeTooltip={props.modal && 'tooltip-input-modal'}
                                />
                                <InputPadrao
                                    tamanho="col-lg-4 col-sm-6"
                                    label="Logradouro"
                                    name="paciente.logradouro"
                                    type="text"
                                    performance={true}
                                    placeholder="Logradouro" classeTooltip={props.modal && 'tooltip-input-modal'}
                                />
                                <InputPadrao
                                    performance={true}
                                    tamanho="col-lg-4 col-sm-6"
                                    label="Complemento"
                                    name="paciente.complemento"
                                    type="text"
                                    placeholder="Complemento"
                                />
                                <InputPadrao
                                    performance={true}
                                    tamanho="col-lg-2 col-sm-6"
                                    label="Número"
                                    name="paciente.numero"
                                    type="text"
                                    placeholder="Número" classeTooltip={props.modal && 'tooltip-input-modal'}
                                />
                                <InputPadrao
                                    tamanho="col-lg-2 col-sm-6"
                                    label="Bairro"
                                    name="paciente.bairro"
                                    type="text"
                                    performance={true}
                                    placeholder="Bairro" classeTooltip={props.modal && 'tooltip-input-modal'}
                                />
                                <InputMunicipio tamanho="col-lg-3 col-sm-12" label='Município - UF' name='paciente.municipio' placeholder='Município - UF' obrigatorio={true}
                                    propsFormik={propsFormik} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputPadrao
                                    tamanho="col-lg-3 col-xl-2"
                                    label="E-mail"
                                    name="paciente.email"
                                    type="text"
                                    performance={true}
                                    placeholder="E-mail" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputTelefone
                                    tamanho="col-lg-2 col-xl-2 col-sm-6"
                                    label="Telefone"
                                    name="paciente.telefone"
                                    type="text"
                                    placeholder="Telefone"
                                    obrigatorio="true"
                                    data={dataTelefone}
                                    setData={setDataTelefone} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputPadrao
                                    tamanho="col-lg-2 col-xl-2 col-sm-6"
                                    label="Código de Verificação"
                                    name="paciente.codigo_verificacao"
                                    type="text"
                                    performance={true}
                                    placeholder="Código de Verificação" classeTooltip={props.modal && 'tooltip-input-modal'}
                                />
                                <InputPadrao
                                    tamanho="col-lg-3 col-xl-3"
                                    label="Número NFS-e"
                                    name="paciente.nfse_numero"
                                    type="text"
                                    performance={true}
                                    placeholder="Número NFS-e" classeTooltip={props.modal && 'tooltip-input-modal'} />
                            </div>
                        </div>
                    </div>
                    : <></>}
                <CadastroPacote alteracao={false} propsFormik={propsFormik} contrato={contrato} disabled={disableCampos} targetProced={refProced} />
                <CadastroProcedimentoGuia
                    LimparContrato={LimparContrato}
                    idGuiaComplementar={idComplementar}
                    contratoConfig={contratoConfig}
                    contrato={contrato}
                    abrirModal={true} setAbrirModal={() => { }} ref={refProced}
                    totalProcedimentos={totalProcedimentos}
                    abrirCadastro={props.abrirModal}
                    carregandoAlteracao={carregandoAlteracao}
                    itemAlterar={{}} propsFormik={propsFormik} procedimentoBloqueado={procedimentoBloqueado}
                    urgenciaProcedimento={urgenciaProcedimento}
                    salvarENovo={salvarENovo} />
                <CadastroExecutantesInternos alteracao={false} propsFormik={propsFormik} disabled={disableCampos} data_socio={dataProcedimento} ref={refInternos} feriados={feriados} />
                <div className="mt-40">
                    <HeaderCadastro
                        titulo={'Anexar Arquivos'}
                        classeChildren='space-between-100'
                        minimizar
                        idxMinimizar={1}
                        arrayMinimizar={minimizar}
                        setArrayMinimizar={setMinimizar} >
                    </HeaderCadastro>
                </div>
                {!minimizar[1] ?
                    <div className="card mb-20">
                        <div className="card-body">
                            {dataArquivos != null ?
                                <InputArquivoS3
                                    name='arquivo'
                                    placeholder='Arquivo'
                                    tamanho='col-12'
                                    diretorioS3='guia/DDMMYYYY/'
                                    modulo={MODULO["/faturamento/guiaparticular"]}
                                    tamanhoMinimo={0}
                                    tamanhoMaximoEmMB={300}
                                    setDataArquivos={setDataArquivos}
                                    dataArquivos={dataArquivos != null ? dataArquivos : []}
                                    setIsSubmit={dataArquivos.length > 0 ? setSalvando : () => { }}
                                    limparCampos={limparArquivos}
                                /> : <></>}
                        </div>
                    </div> :
                    <></>}

                {id != null ?
                    <>
                        <div className="mt-40">
                            <HeaderCadastro
                                titulo={'Conferência'}
                                classeChildren='space-between-100' >
                            </HeaderCadastro>
                        </div>
                        <div className="card mb-20">
                            <div className="card-body">

                                <div className='row'>
                                    <InputGuiaStatus label="Status" name="status_conferencia" placeholder="Status" propsFormik={propsFormik} tamanho={'col-lg-4'}
                                        modal={true} focusAfter={inputFocus} salvarENovo={salvarENovo} {...props} cadastroRapido />
                                    <InputPadrao label='Justificativa' name='justificativa_conferencia' type='text' placeholder='Justificativa' tamanho={'col-xl-8 col-sm-6'} classeTooltip={'tooltip-input-modal'} />
                                </div>


                            </div>
                        </div>
                    </>
                    : <></>}
                <div className='separator mt-40 mb-5'></div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
                <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                    esconderFechar mensagem={mensagemModal}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
                <ModalDuasOpcoes showModal={showModalDataEntrega} setShowModal={setShowModalDataEntrega} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => { setSalvando(false); setShowModalDataEntrega(false) }}
                    esconderFechar mensagem={msgValidacaoDataEntrega}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
                <ModalDuasOpcoes showModal={showModalAposSalvar} setShowModal={setShowModalAposSalvar}
                    funcaoOpcao1={() => { setShowModalAposSalvar(false); }}
                    esconderFechar mensagem={<>
                        Guia:
                        <br />
                        <br />
                        <b>{avisoSalvar?.guia} </b> - {avisoSalvar?.paciente}
                        <br />

                    </>}
                    textoOpcao1={'OK'} esconderOpcao2
                    classeOpcao1={'btn-sm btn-primary-modal primary'} />
            </Form >)}
        </Formik >
    );
}

const subtitulo = (status) => {
    return (
        <div className='guia-legenda'>
            <span className='legenda' style={{ background: status && status.cor }}>{status.descricao}</span>
        </div>

    )
}

const Cadastro = (props) => {

    const [status, setStatus] = useState([])

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Faturamento</Link>,
        () => <Link className="link-active" to={'/faturamento/guia-particular/consulta'}>Guia</Link>,
    ]
    return (<>
        <HeaderCadastro titulo={"Cadastro de Guia Particular"} link={links} infoAdicional={subtitulo(status)} />
        <CadastroGuiaParticular {...props} setStatus={setStatus} />
    </>
    )
}

const CadastroProcedimentoGuia = React.forwardRef(({ contrato, urgenciaProcedimento, ...props }, ref) => {
    const propsFormik = useFormikContext();
    const [totalProcedimentos, setTotalprocedimentos] = useState('0,00')
    const [minimizar, setMinimizar] = useState([false, false, false, false])
    const [width] = useWindowSize()
    const history = useHistory()
    const firstRender = useRef(false);
    const inputValorProcedimento = useRef();
    const inputValorProcedimentos = useRef([]);
    const refProcedimento = useRef(null)


    const calcularProcedimento = (procedimentos, index) => {
        const procedimentosNovo = metodoCalcular([...procedimentos], index)

        propsFormik.setFieldValue('procedimentos', procedimentosNovo)
    }

    useEffect(() => {
        if (props.totalProcedimentos) {
            setTotalprocedimentos(props.totalProcedimentos)
        }
    }, [props.totalProcedimentos])

    useEffect(() => {
        // if (propsFormik.touched.acomodacao === true || propsFormik.touched.estabelecimento_saude === true)
        //     recalcular();

    }, [props.contratoConfig])

    useEffect(() => {
        if (!propsFormik.values.estabelecimento_saude)
            LimparProcedimentos()
    }, [propsFormik.values.estabelecimento_saude])

    const LimparProcedimentos = _ => {
        const procedimentos = propsFormik.values.procedimentos
        const contrato = propsFormik.values.estabelecimento_saude

        if (!contrato) {
            props.LimparContrato()
            propsFormik.setFieldValue('acomodacao', undefined);
        }
        if (procedimentos && procedimentos.length > 1) {
            for (let i = 0; i < procedimentos.length; i++) {
                procedimentos.shift()
            }
        }

        return procedimentos
    }

    const recalcular = _ => {

        const procedimentos = propsFormik.values.procedimentos
        if (procedimentos) {
            let procedimentosNovo = []
            for (let index = 0; index < procedimentos.length; index++) {
                procedimentosNovo.push(metodoCalcular([...procedimentos], index))
            }
        }
    }

    if (ref) {
        ref.current = {
            recalcular: () => recalcular(),
            limparProced: () => LimparProcedimentos(),
            calcularTodosProcedimentos: (procedimentos) => calcularTodosProcedimentos(procedimentos),
            setTotalprocedimentos: (valor) => setTotalprocedimentos(valor)
        }
    }

    const calcularTodosProcedimentos = (procedimentos) => setTotalprocedimentos(formatMoeda(procedimentos.filter(x => x.procedimento).reduce((partialSum, a) => partialSum + parseFloat(a.valor_total), 0)))

    const contratoConfigFiltrado = (procedimento) => {

        let urgenciaRetorno = 0

        if (props.contratoConfig && props.contratoConfig.length > 0) {
            let contratoConfigFiltrado;

            if (props.contratoConfig.length == 1 && props.contratoConfig[0].base_calculo == 3) {

                contratoConfigFiltrado = [...props.contratoConfig]

            } else {

                contratoConfigFiltrado = props.contratoConfig.filter(x => parseInt(x?.porte) == parseInt(procedimento?.procedimento?.porte_anestesico) && parseInt(x?.tabela) == procedimento?.procedimento?.tabela_procedimento_id)
            }

            urgenciaRetorno = contratoConfigFiltrado?.length > 0 ? contratoConfigFiltrado[0].urgencia : procedimento?.urgencia_porcentagem
        } else {
            urgenciaRetorno = procedimento?.urgencia_porcentagem
        }

        return urgenciaRetorno

        // const porc_urgencia =  props.contratoConfig.filter() && props.contratoConfig[0]?.urgencia ? props.contratoConfig[0]?.urgencia : procedimentos[index]?.urgencia_porcentagem
    }

    const metodoCalcular = (procedimentos, index) => {

        const isOdonto = propsFormik.values.tipo_honorario.value == 2 ? true : false

        if (!procedimentos[index].procedimento)
            return
        const procedimentoConfig = props.contratoConfig.find(x => parseInt(x.porte) === parseInt(procedimentos[index].procedimento.porte_anestesico) && parseInt(x.tabela) === procedimentos[index].procedimento.tabela_id)

        // let dados = {
        //     // ...props.contratoConfig[0],
        //     porte: procedimentos[index].porte,
        //     valor_porte: procedimentos[index].valor_porte && procedimentos[index].valor_porte.includes('R$') ? procedimentos[index].valor_porte.replace('R$', '').replace('.', '').replace(',', '.').trim() : procedimentos[index].valor_porte || 1000,
        //     urgencia: procedimentos[index].urgencia_porcentagem ? parseFloat(procedimentos[index].urgencia_porcentagem) : procedimentos[index].urgencia || null,
        //     percentual: procedimentos[index].percentual || 100,
        //     porcentagem_acm: procedimentos[index].porcentagem_acm,
        //     reducao_acrescimo: procedimentos[index].reducao_acrescimo,
        //     porc_urgencia: procedimentos[index].procedimento?.acomodacao?.find(x => x.acomodacao = props.propsFormik?.values?.acomodacao?.value)?.urgencia
        // }
        // (procedimentos[index].procedimento?.acomodacao?.find(x => x.acomodacao === props.propsFormik?.values?.acomodacao?.value)?.percentual) ?? procedimentoConfig != undefined ? parseInt(procedimentoConfig?.percentual) : procedimentos[index].porcentagem_acm,

        let dados = {
            // ...props.contratoConfig[0],
            porte: procedimentos[index]?.procedimento?.porte_anestesico !== undefined ? procedimentos[index].procedimento.porte_anestesico : procedimentos[index]?.procedimento?.porte ?? procedimentos[index].porte,
            valor_porte: procedimentos[index].valor_porte && procedimentos[index].valor_porte.includes('R$') ? procedimentos[index].valor_porte.replace('R$', '').replace('.', '').replace(',', '.').trim() : procedimentos[index].valor_porte || 1000,
            urgencia: procedimentos[index].urgencia_porcentagem ? parseFloat(procedimentos[index].urgencia_porcentagem) : procedimentos[index].urgencia || null,
            percentual: procedimentos[index].percentual || 100,
            porcentagem_acm: (procedimentos[index].procedimento?.acomodacao?.find(x => x.acomodacao === props.propsFormik?.values?.acomodacao?.value)?.percentual) ?? procedimentos[index].porcentagem_acm,
            // porcentagem_acm: procedimentos[index].porcentagem_acm,
            reducao_acrescimo: procedimentos[index].reducao_acrescimo || 100,
            porc_urgencia: (procedimentos[index].procedimento?.acomodacao?.find(x => x.acomodacao === props.propsFormik?.values?.acomodacao?.value)?.urgencia) ?? procedimentoConfig?.urgencia ?? procedimentos[index].urgencia_porcentagem ?? procedimentos[index].urgencia
        }

        const porc_procedimento = procedimentos[index].porcentagem && procedimentos[index].porcentagem.label.replace('%', '') || 100
        const reducao_acrescimo = procedimentos[index].reducao_acrescimo || dados.reducao_acrescimo || 100
        const quantidade = 1
        // const urgencia = urgenciaProcedimento
        const urgencia = procedimentos[0].urg

        const porc_acomodacao = dados.porcentagem_acm
        const percentual = dados.percentual
        const porc_urgencia = isOdonto ? 0 : contratoConfigFiltrado(procedimentos[index])
        const valor_porte = parseFloat(dados.valor_porte)

        let resultado = (valor_porte * quantidade) * (porc_acomodacao / 100) * (porc_procedimento / 100)

        urgencia && (resultado += (resultado * porc_urgencia / 100))
        resultado *= (reducao_acrescimo / 100)

        procedimentos[index].porte = dados.porte
        procedimentos[index].quantidade = 1
        procedimentos[index].reducao_acrescimo = reducao_acrescimo
        procedimentos[index].valor = formatMoeda(valor_porte)
        procedimentos[index].valor_total = resultado.toFixed(2)
        procedimentos[index].valor_total_formatado = formatMoeda(resultado)
        procedimentos[index].porcentagem_acm = porc_acomodacao
        procedimentos[index].percentual = percentual
        procedimentos[index].urgencia_porcentagem = porc_urgencia
        procedimentos[index].urg = urgencia

        setTotalprocedimentos(formatMoeda(procedimentos.filter(x => x.procedimento).reduce((partialSum, a) => partialSum + parseFloat(a.valor_total), 0)))
        if (!procedimentos[index].porcentagem)
            procedimentos[index].porcentagem = { value: 1, label: '100%' }

        return procedimentos;
    }

    const onChangeProced = async (option, propsFormik, arrayHelpers, index) => {

        propsFormik.values.procedimentos[index].reducao_acrescimo = 100

        const arrayProcedimentos = [...propsFormik.values.procedimentos]

        if (option) {
            propsFormik.values.procedimentos[index].valor_porte = formatarMoeda(option.valor_porte)
            propsFormik.values.procedimentos[index].porte = option.porte || option.porte_anestesico
            propsFormik.values.procedimentos[index].porcentagem_acm = parseFloat(propsFormik.values.acomodacao.percentual) || 100
            propsFormik.values.procedimentos[index].urgencia = (option.acomodacao && option.acomodacao[0].urgencia) || option?.urgencia
            propsFormik.values.procedimentos[index].urgencia_porcentagem = (option.acomodacao && option.acomodacao[0].urgencia) || option?.urgencia
            propsFormik.values.procedimentos[index].pacote_id = null
            if (propsFormik.values.procedimentos[index].procedimento) {
                propsFormik.values.procedimentos[index].procedimento.value = propsFormik.values.procedimentos[index].procedimento?.value?.toString()?.includes('UPD') ? propsFormik.values.procedimentos[index].procedimento.value.toString().slice(-3) : propsFormik.values.procedimentos[index].procedimento.value
            }

            const valorAnterior = { ...propsFormik.values.procedimentos[index] }

            arrayProcedimentos[index].procedimento = option
            if (!arrayProcedimentos[index].quantidade)
                arrayProcedimentos[index].quantidade = 1

            await calcularProcedimento(arrayProcedimentos, index, propsFormik, setTotalprocedimentos)

            if (!valorAnterior.procedimento || !valorAnterior.procedimento.value)
                adicionarLinha(arrayHelpers, propsFormik)

        }
        else {
            arrayProcedimentos[index].procedimento = undefined
            propsFormik.setFieldValue('procedimentos', arrayProcedimentos);
        }
    }

    const onChangeValor = (propsFormik) => {

        const procedimentos = propsFormik.values.procedimentos
        let valorTotal = procedimentos.map(x => parseFloat(x.valor_total || 0)).reduce((ant, atual) => ant + atual, 0);
        setTotalprocedimentos(formatMoeda(valorTotal))
    }

    const adicionarLinha = (arrayHelpers, propsFormik) => {
        const arrayProced = propsFormik.values.procedimentos;
        if (arrayProced && arrayProced.findIndex(x => !x.procedimento || !x.procedimento.value || !x.quantidade) === -1) {
            let objProcedimento;
            if (propsFormik.values.procedimentos.length >= 1)
                objProcedimento = { procedimento: undefined, quantidade: 1, porcentagem: { value: 1, label: '100%' }, porcentagem_acm: 100 }
            else
                objProcedimento = { procedimento: undefined, quantidade: 1, porcentagem: { value: 1, label: '100%' }, porcentagem_acm: 100 }

            arrayHelpers.insert(propsFormik.values.procedimentos.length, objProcedimento)
        }
    }

    // const removerLinha = (arrayHelpers, propsFormik, index) => {

    //     const arrayProced = [...propsFormik.values.procedimentos];
    //     if (arrayProced[index].procedimento && arrayProced[index].procedimento.value) {
    //         // arrayHelpers.remove(index)
    //         propsFormik.values.procedimentos.splice(index, 1)
    //     }

    //     if (propsFormik.values.procedimentos.length === 1) {
    //         propsFormik.setFieldValue('procedimentos', [{ procedimento: undefined, quantidade: 1, porcentagem: { value: 1, label: '100%' } }])
    //     }

    //     if (arrayProced.length === 1 && arrayProced[index].procedimento && arrayProced[index].procedimento.value) {
    //         arrayHelpers.insert(propsFormik.values.procedimentos.length, { procedimento: undefined, quantidade: 1 })
    //     }
    //     setTotalprocedimentos(formatMoeda(propsFormik.values.procedimentos.filter(x => x.procedimento).reduce((partialSum, a) => partialSum + parseFloat(Number(a.valor_total).toFixed(2)), 0)))
    // }

    const removerLinha = (arrayHelpers, propsFormik, index) => {

        const arrayProced = [...propsFormik.values.procedimentos];

        if (arrayProced[index].procedimento && arrayProced[index].procedimento.value) {
            propsFormik.values.procedimentos.splice(index, 1);
            propsFormik.setFieldValue('procedimentos', [...propsFormik.values.procedimentos]);
        }

        if (propsFormik.values.procedimentos.length === 1) {
            propsFormik.setFieldValue('procedimentos', [{ procedimento: undefined, quantidade: 1, porcentagem: { value: 1, label: '100%' } }]);
        }

        if (arrayProced.length === 1 && arrayProced[index].procedimento && arrayProced[index].procedimento.value) {
            arrayHelpers.insert(propsFormik.values.procedimentos.length, { procedimento: undefined, quantidade: 1 });
        }

        const totalProcedimentos = formatMoeda(
            propsFormik.values.procedimentos
                .filter(x => x.procedimento)
                .reduce((partialSum, a) => partialSum + parseFloat(a.valor_total), 0)
        );
        setTotalprocedimentos(totalProcedimentos);
    }

    const placeholderInput = (propsFormik) => {
        if (propsFormik.values.data_procedimento === '')
            return 'Selecione a Data do Procedimento'
        else if (!propsFormik.values.estabelecimento_saude)
            return 'Selecione um Estabelecimento de Saúde'
        else if (!propsFormik.values.tipo_honorario)
            return 'Selecione o Tipo de Honorário'
        else if (!propsFormik.values.acomodacao)
            return 'Selecione a Acomodação'
        else if (propsFormik.values.tipo_honorario && propsFormik.values.tipo_honorario.value === 2 && (propsFormik.values.hora_inicial === '' || propsFormik.values.hora_final === ''))
            return 'Preencha os campos de Hora Inicial e Hora Final da Guia'
        else
            return 'Procedimento'
    }

    useEffect(() => {
        const { tipo_honorario, acomodacao, data_procedimento, hora_inicial, hora_final, procedimentos } = propsFormik.values



        let hora_inicial_moment = moment(hora_inicial, 'HH:mm')
        let hora_final_moment = moment(hora_final, 'HH:mm')
        let verifica_hora = (!hora_inicial?.includes('_') && !hora_final?.includes('_') && hora_inicial_moment.isValid() && hora_final_moment.isValid()) || tipo_honorario?.value === 1

        let verfica_data = moment(data_procedimento, 'DD/MM/YYYY', true).isValid()

        let params = {
            tipo_honorario_id: tipo_honorario && tipo_honorario?.value,
            data_procedimento: data_procedimento,
            acomodacao_id: acomodacao && acomodacao?.value || null,
            local_atendimento_id: acomodacao && acomodacao?.local_atendimento_id || null,
            acomodacao_contrato_id: acomodacao && acomodacao?.contrato_id || null,
            contrato: contrato && contrato.contrato_id,
            hora_procedimento: hora_inicial !== '' && hora_final !== '' ? moment(hora_final, 'HH:mm').diff(moment(hora_inicial, 'HH:mm'), 'minutes') : '',
            // procedimento_id: procedimentos && procedimentos.filter(x => !x.pacote_id).map(x => x.procedimento && x.procedimento.value.toString().replace(/\D+/g, '')).filter(x => x),
            procedimento_id: procedimentos?.map(x => x.procedimento && x.procedimento.value.toString().replace(/\D+/g, '')).filter(x => x),

        }
        if (!props.carregandoAlteracao && !props.salvarENovo) {

            if (params.procedimento_id && params.procedimento_id.length > 0 && !props.idGuiaComplementar && verifica_hora && verfica_data) {

                Promise.resolve(get('/faturamento/procedimentosParticularPorId', params, null, null)).then(value => {

                    if ((value?.length === 0) || !value) {

                        let contratoEstabTabela = propsFormik.values?.estabelecimento_saude?.tabelas
                        let procedimnetosTabela = propsFormik.values?.procedimentos

                        // Verificar e armazenar os itens que as tabelas forem iguais
                        let existeCorrespondencia = contratoEstabTabela?.some(contrato => {


                            return procedimnetosTabela?.some(procedimento => {

                                return contrato?.tabela === procedimento?.procedimento?.tabela_procedimento_id
                            })
                        }
                        );

                        if (propsFormik.values.tipo_honorario.value == 2 && existeCorrespondencia && propsFormik.values?.estabelecimento_saude?.odontologica == true) {

                            let procedimentos = propsFormik.values.procedimentos

                            procedimentos = procedimentos.map(objeto => {
                                return { ...objeto, valor_total: '0', valor_total_formatado: '0', urg: false };
                            });

                            propsFormik.setFieldValue('procedimentos', procedimentos)
                            setTotalprocedimentos('R$ 0.00')

                        }
                        else {
                            propsFormik.setFieldValue('acomodacao', undefined);
                            propsFormik.setFieldValue('procedimentos', [{ procedimento: undefined, quantidade: 1, porcentagem: { value: 1, label: '100%' }, porcentagem_acm: 100, reducao_acrescimo: 100, urg: urgenciaProcedimento }])

                            propsFormik.setFieldValue('pacote', [{ pacote_id: undefined, pacote_descricao: undefined, pacote_valor: undefined }])

                            setTotalprocedimentos('R$ 0.00')
                        }
                    }
                    else {

                        const isOdonto = propsFormik.values.tipo_honorario.value == 2 ? true : false

                        let procedimentosNaoAlterados = procedimentos?.filter(x => {
                            let cleanValue = x?.procedimento?.value.toString().includes('UPD') ? x?.procedimento?.value?.replace(/UPD$/, '') : x?.procedimento?.value;

                            return value.some(y => y?.value == cleanValue);
                        });

                        const unique = [...new Map(value.filter(x => x.acomodacao).map((item) => [item["value"], item])).values()]
                        // let procedimentos = propsFormik.values.procedimentos
                        let procedimentos2 = procedimentosNaoAlterados

                        let procedimentosFormik = procedimentos2.map(x => {
                            // let novoProced = unique[unique.findIndex(y => parseInt(y.value) === (x.procedimento && parseInt(x.procedimento.value)))]
                            let novoProced = unique[unique.findIndex(y => parseInt(y.value) === (x.procedimento && parseInt(x?.procedimento?.value?.toString().replace(/\D+/g, ''))))]
                            if (x?.procedimento?.label)
                                x.procedimento.label = novoProced ? novoProced.label : x?.procedimento?.label
                            x.valor_porte = x?.procedimento?.value?.toString()?.includes('UPD') || !novoProced ? x.valor_porte : novoProced ? formatarMoeda(novoProced.valor_porte) : x.valor_porte
                            x.pertence = (novoProced || x?.pacote_id || (x?.procedimento && x?.procedimento?.value?.toString()?.includes('UPD'))) ? true : false
                            x.porte = novoProced ? novoProced.porte : x.porte
                            x.urgencia = isOdonto ? 0 : novoProced ? (novoProced.acomodacao ? novoProced.acomodacao[0]?.urgencia : parseInt(propsFormik.values.acomodacao?.urgencia)) : x.urgencia
                            x.urgencia_porcentagem = isOdonto ? 0 : novoProced ? (novoProced.acomodacao ? novoProced.acomodacao[0]?.urgencia : parseInt(propsFormik.values.acomodacao?.urgencia)) : x.urgencia
                            x.porcentagem_acm = novoProced?.acomodacao ? novoProced.acomodacao[0]?.percentual : parseFloat(propsFormik.values.acomodacao?.percentual) || 100
                            x.valor_total = isOdonto ? convertValueToFloat(x.valor_porte) : calculaValor(x, novoProced, 'vt')
                            x.valor_total_formatado = isOdonto ? x.valor_porte : calculaValor(x, novoProced, 'vtf')
                            // x.valor_total = x?.procedimento?.value?.toString()?.includes('UPD') || !novoProced ? parseFloat(((parseFloat(x.valor_porte) * 1) * ((x.porcentagem_acm || 100) / 100)).toFixed(2)) : novoProced ? ((novoProced.valor_porte * 1) * ((x.porcentagem_acm || 100) / 100)).toFixed(2) : x.valor_total
                            // x.valor_total_formatado = x?.procedimento?.value?.toString()?.includes('UPD') || !novoProced ? formatarMoeda(((x.valor_total * 1) * ((x.porcentagem_acm || 100) / 100)).toFixed(2)) : novoProced ? formatarMoeda(((novoProced.valor_porte * 1) * ((x.porcentagem_acm || 100) / 100)).toFixed(2)) : x.valor_total_formatado
                            // x.valor_total_formatado = x?.procedimento?.value?.includes('UPD') || !novoProced ? novoProced ? formatarMoeda(((novoProced.valor_porte * 1) * ((x.porcentagem_acm || 100) / 100)).toFixed(2)) : x.valor_total_formatado
                            return x
                        })

                        // let procedimentosFormik = procedimentos.map(x => {

                        //     // let novoProced = unique[unique.findIndex(y => parseInt(y.value) === (x.procedimento && parseInt(x.procedimento.value)))]
                        //     let novoProced = unique[unique.findIndex(y => parseInt(y.value) === (x.procedimento && parseInt(x.procedimento.value.toString().replace(/\D+/g, ''))))]

                        //     if (x?.procedimento?.label)
                        //         x.procedimento.label = novoProced ? novoProced.label : x?.procedimento?.label
                        //     x.valor_porte = x?.procedimento?.value?.toString()?.includes('UPD') || !novoProced ? x.valor_porte : novoProced ? formatarMoeda(novoProced.valor_porte) : x.valor_porte
                        //     x.pertence = (novoProced || x.pacote_id || (x.procedimento && x.procedimento.value.includes('UPD'))) ? true : false
                        //     x.porte = novoProced?.porte_anestesico ? novoProced?.porte_anestesico : x.porte
                        //     x.urgencia = novoProced ? (novoProced.acomodacao ? novoProced.acomodacao[0]?.urgencia : parseInt(propsFormik.values.acomodacao?.urgencia)) : x.urgencia
                        //     // x.porcentagem_acm = x.porcentagem_acm != undefined ? x.porcentagem_acm : novoProced?.acomodacao ? novoProced.acomodacao[0]?.percentual : parseFloat(propsFormik.values.acomodacao?.percentual) || 100
                        //     x.porcentagem_acm = novoProced?.acomodacao ? novoProced.acomodacao[0]?.percentual : x.porcentagem_acm != undefined ? x.porcentagem_acm : parseFloat(propsFormik.values.acomodacao?.percentual) || 100
                        //     x.valor_total = x.valor_total
                        //     x.valor_total_formatado = x.valor_total_formatado
                        //     // x.valor_total = calculaValor(x, novoProced, 'vt')
                        //     // x.valor_total_formatado = calculaValor(x, novoProced, 'vtf')
                        //     // x.valor_total = x?.procedimento?.value?.toString()?.includes('UPD') || !novoProced ? parseFloat(((parseFloat(x.valor_porte) * 1) * ((x.porcentagem_acm || 100) / 100)).toFixed(2)) : novoProced ? ((novoProced.valor_porte * 1) * ((x.porcentagem_acm || 100) / 100)).toFixed(2) : x.valor_total
                        //     // x.valor_total_formatado = x?.procedimento?.value?.toString()?.includes('UPD') || !novoProced ? formatarMoeda(((x.valor_total * 1) * ((x.porcentagem_acm || 100) / 100)).toFixed(2)) : novoProced ? formatarMoeda(((novoProced.valor_porte * 1) * ((x.porcentagem_acm || 100) / 100)).toFixed(2)) : x.valor_total_formatado
                        //     // x.valor_total_formatado = x?.procedimento?.value?.includes('UPD') || !novoProced ? novoProced ? formatarMoeda(((novoProced.valor_porte * 1) * ((x.porcentagem_acm || 100) / 100)).toFixed(2)) : x.valor_total_formatado
                        //     return x
                        // })

                        procedimentosFormik = procedimentosFormik.filter(x => x.pertence === true)
                        procedimentosFormik.push({ procedimento: undefined, quantidade: 1, porcentagem: { value: 1, label: '100%' }, porcentagem_acm: 100, reducao_acrescimo: 100 })
                        propsFormik.setFieldValue('procedimentos', procedimentosFormik)
                        calcularTodosProcedimentos(procedimentosFormik)

                    }
                })
            }
        }
    }, [propsFormik.values.acomodacao, propsFormik.values.data_procedimento, propsFormik.values.estabelecimento_saude, propsFormik.values.hora_inicial, propsFormik.values.hora_final])

    const calculaValor = (itemLinha, novoProced, tipo_valor) => {

        if ((itemLinha?.procedimento?.value?.toString()?.includes('UPD')) || !novoProced) {
            let valor_porte_sem_formatacao = itemLinha.valor_porte?.toString()?.replace('R$', '')?.replace(/\./g, '')?.replace(',', '.')

            if (!itemLinha.urg) {
                if (tipo_valor === 'vt')
                    return parseFloat(((parseFloat(valor_porte_sem_formatacao) * 1) * ((itemLinha.porcentagem_acm || 100) / 100))).toFixed(2)

                else
                    return formatarMoeda(((valor_porte_sem_formatacao * 1) * ((itemLinha.porcentagem_acm || 100) / 100)).toFixed(2))
            }

            else {

                let valor_com_acomodacao = (parseFloat(valor_porte_sem_formatacao) * 1) * (itemLinha.porcentagem_acm / 100)
                let valor_total = valor_com_acomodacao + (valor_com_acomodacao * (itemLinha.urgencia / 100))

                if (tipo_valor === 'vt')
                    return parseFloat(valor_total.toFixed(2))

                else
                    return formatarMoeda(valor_total.toFixed(2))
            }
        }

        else if (novoProced && !itemLinha.urg) {
            if (tipo_valor === 'vt')
                return ((novoProced.valor_porte * 1) * ((itemLinha.porcentagem_acm || 100) / 100)).toFixed(2)
            else
                return formatarMoeda(((novoProced.valor_porte * 1) * ((itemLinha.porcentagem_acm || 100) / 100)).toFixed(2))
        }

        else if (novoProced && itemLinha.urg) {
            let valor_com_acomodacao = (parseFloat(novoProced.valor_porte) * 1) * (itemLinha.porcentagem_acm / 100)
            let valor_total = valor_com_acomodacao + (valor_com_acomodacao * (itemLinha.urgencia / 100))

            if (tipo_valor === 'vt')
                return (valor_total.toFixed(2))
            else
                return formatarMoeda(valor_total.toFixed(2))

        }

        else
            return itemLinha.valor_total
    }

    let procedimentos =
        (
            <>
                <div className="mt-40">
                    <HeaderCadastro
                        titulo={'Procedimentos'}
                        classeChildren='space-between-100'
                        minimizar
                        idxMinimizar={1}
                        arrayMinimizar={minimizar}
                        setArrayMinimizar={setMinimizar}
                    >
                    </HeaderCadastro>
                </div>
                {!minimizar[1] ?
                    <div className="card mb-20">
                        <div className="card-body" style={{ paddingRight: 0 }} ref={refProcedimento}>
                            <FieldArray name="procedimentos">
                                {(arrayHelpers => (
                                    <>
                                        {propsFormik.values.procedimentos ?
                                            propsFormik.values.procedimentos.map((item, index) => {
                                                if (width >= 1200) {
                                                    return (
                                                        <>
                                                            <div className='modal-body-cadastro row mr-0 ml-0'>

                                                                <div className='row col-sm-11 col-xl-3 col-lg-3 col-md-8'>
                                                                    <div className={"form-group col-xl-1 col-lg-1 col-1 pl-0 text-center"}>
                                                                        <label htmlFor={props.id || props.name}>{index === 0 ? '#' : undefined}</label>
                                                                        <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{index + 1}</span></div>
                                                                    </div>

                                                                    <InputProcedimentosGuiaParticular contrato={contrato && contrato.contrato_id} label={index === 0 ? 'Procedimento' : undefined} name={`procedimentos[${index}].procedimento`}
                                                                        propsFormik={propsFormik} tamanho="col-xl-11 col-lg-11 col-11 pl-0" {...props} classeTooltip={'tooltip-input-modal'} modal={true} isClearable={false}
                                                                        onChange={(option) => onChangeProced(option, propsFormik, arrayHelpers, index)} className={'procedimento-linha'}
                                                                        placeholder={placeholderInput(propsFormik)}
                                                                        isDisabled={propsFormik.values.data_procedimento === '' ? true :
                                                                            !propsFormik.values.estabelecimento_saude ? true : !propsFormik.values.tipo_honorario ? true
                                                                                : !propsFormik.values.acomodacao ? true : propsFormik.values.tipo_honorario && propsFormik.values.tipo_honorario.value === 2 && (propsFormik.values.hora_inicial === '' || propsFormik.values.hora_final === '') ? true : false} />
                                                                </div>

                                                                <div className='row col-sm-11 col-xl-8 col-lg-8 col-md-12 pr-0 mr-2'>
                                                                    <div className={"form-group col-sm-1 col-xl-1 col-lg-1 col-md-2 pr-0 text-center"}>
                                                                        <label htmlFor={props.id || props.name}>{index === 0 ? 'Porte' : undefined}</label>
                                                                        <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{propsFormik.values.procedimentos[index].porte || 0}</span></div>
                                                                    </div>

                                                                    <div className={"form-group col-8 col-xl-1 col-lg-1 col-md-3 pr-0 text-center"}>
                                                                        <label htmlFor={props.id || props.name}>{index === 0 ? 'Valor Porte' : undefined}</label>
                                                                        <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{propsFormik.values.procedimentos[index].valor_porte}</span></div>
                                                                    </div>

                                                                    <InputPadrao label={index === 0 ? 'Acm(%)' : undefined} name={`procedimentos[${index}].porcentagem_acm`} type='number'
                                                                        placeholder='Acm(%)' classeTooltip={'tooltip-input-modal'} tamanho="col-8 col-xl-2 col-lg-2 col-md-2 pr-0"
                                                                        onBlur={option => {
                                                                            propsFormik.values.procedimentos[index].porcentagem_acm = option.target.value
                                                                            calcularProcedimento(propsFormik.values.procedimentos, index, propsFormik, setTotalprocedimentos)
                                                                        }} disabled={!propsFormik.values.procedimentos[index].procedimento} />

                                                                    <InputPercentual label={index === 0 ? 'Perc.' : undefined} placeholder='Perc.' classeTooltip={'tooltip-input-modal'} isClearable={false}
                                                                        name={`procedimentos[${index}].porcentagem`} propsFormik={propsFormik} tamanho="col-sm-2 col-xl-2 col-lg-2 col-md-3 pr-0"
                                                                        onChange={option => { propsFormik.values.procedimentos[index].porcentagem = option; calcularProcedimento(propsFormik.values.procedimentos, index, propsFormik, setTotalprocedimentos) }}
                                                                        isDisabled={!propsFormik.values.procedimentos[index].procedimento} />

                                                                    <InputPadrao performance={true} label={index === 0 ? 'Red./Acresc.' : undefined} name={`procedimentos[${index}].reducao_acrescimo`} type='number'
                                                                        placeholder='Red./Acresc.' classeTooltip={'tooltip-input-modal'} tamanho="col-8 col-xl-2 col-lg-1 col-md-2 pr-0"
                                                                        onBlur={option => {
                                                                            propsFormik.values.procedimentos[index].reducao_acrescimo = option.target.value
                                                                            calcularProcedimento(propsFormik.values.procedimentos, index, propsFormik, setTotalprocedimentos)
                                                                        }} disabled={!propsFormik.values.procedimentos[index].procedimento} />

                                                                    <InputSwitch label={index === 0 ? 'Urg' : undefined} checked={propsFormik.values.procedimentos[index].procedimento && propsFormik.values.procedimentos[0].urg && propsFormik?.values?.tipo_honorario?.value != 2} // SE A GUIA É ODONTO, NAO POSSUI URGÊNCIA 
                                                                        quebrarLinha
                                                                        propsFormik={propsFormik} classeLabel={(index === 0 ? '' : 'justify-center-vertical')} classeDiv='form-group ajusta-switch'
                                                                        name={`procedimentos[${index}].urg`} size="md" tamanho={!props.modal && 'col-xl-1 col-lg-2 col-md-2 pr-0'} classeTooltip={props.modal && 'tooltip-input-modal'}
                                                                        onChange={option => {
                                                                            let array = propsFormik.values.procedimentos
                                                                            array.map(ret => ret.urg = option);
                                                                            recalcular(array)
                                                                            propsFormik.setFieldValue(`procedimentos[${index}].urg`, option)

                                                                        }}
                                                                    />
                                                                    <InputPadraoSimples performance={true} label={index === 0 ? 'Qtde' : undefined} name={`procedimentos[${index}].quantidade`} type='number'
                                                                        placeholder='Qtde' disabled classeTooltip={'tooltip-input-modal'} tamanho="col-8 col-xl-1 col-lg-1 col-md-2 pr-0" />
                                                                    <InputCurrency onUpdate={val => {
                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                        inputValorProcedimentos.current[index] = valor
                                                                    }}
                                                                        label={index === 0 ? 'Valor R$' : undefined} name={`procedimentos[${index}].valor_total`}
                                                                        value={inputValorProcedimentos.current[index] || propsFormik.values.procedimentos[index].valor_total_formatado || propsFormik.values.procedimentos[index].valor_total}
                                                                        classeTooltip={'tooltip-input-modal'}
                                                                        propsFormik={propsFormik}
                                                                        onBlur={(e) => {
                                                                            if (inputValorProcedimentos.current[index] || inputValorProcedimentos.current[index] === 0) {
                                                                                propsFormik.values.procedimentos[index].valor_total = inputValorProcedimentos.current[index] && inputValorProcedimentos.current[index].toString().trim();
                                                                                propsFormik.values.procedimentos[index].valor_total_formatado = formatarMoeda(inputValorProcedimentos.current && inputValorProcedimentos.current[index].toString());
                                                                                propsFormik.values.procedimentos[index].valor_porte = formatarMoeda(inputValorProcedimentos.current[index] && inputValorProcedimentos.current[index].toString().trim())
                                                                                onChangeValor(propsFormik)
                                                                                propsFormik.values.procedimentos[index].procedimento.value = propsFormik.values.procedimentos[index].procedimento.value + 'UPD'

                                                                            }
                                                                            inputValorProcedimentos.current[index] = null;
                                                                        }}
                                                                        classDiv='col-8 col-xl-2 col-lg-2 col-md-2 text-center pr-0' />
                                                                    {/* <InputValorNovo label={index === 0 ? 'Valor R$' : undefined} value={propsFormik.values.procedimentos[index].valor_total_formatado || propsFormik.values.procedimentos[index].valor_total}
                                                                        name={`procedimentos[${index}].valor_total`} classeTooltip={'tooltip-input-modal'} classDiv='col-8 col-xl-2 col-lg-2 col-md-2 text-center pr-0'
                                                                        propsFormik={propsFormik} isDisable={false} max={100000000} onBlur={(e) => onChangeValor(propsFormik)} /> */}
                                                                </div>

                                                                <div className='col-4 col-xl-1 col-lg-1 col-md-2 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right'>
                                                                    <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, propsFormik, index)} />
                                                                </div>
                                                            </div></>)
                                                }
                                                else {
                                                    return (
                                                        <>

                                                            <div className='modal-body-cadastro mr-0 ml-0'>

                                                                <div className={"form-group col-xl-12 col-lg-12 col-12 pl-0 text-center"}>
                                                                    <div className={'text-center vertical-center mt-3'}><span style={{ fontWeight: 'bold' }}>{index + 1}</span></div>
                                                                </div>

                                                                <div className='row col-sm-12 col-xl-12 col-lg-12 col-md-12 pr-0 mr-2'>
                                                                    <InputProcedimentosGuiaParticular label={index === 0 ? 'Procedimento' : undefined} name={`procedimentos[${index}].procedimento`}
                                                                        isDisabled={propsFormik.values.data_procedimento === '' ? true :
                                                                            !propsFormik.values.estabelecimento_saude ? true : !propsFormik.values.tipo_honorario ? true
                                                                                : !propsFormik.values.acomodacao ? true : propsFormik.values.tipo_honorario && propsFormik.values.tipo_honorario.value === 2 && (propsFormik.values.hora_inicial === '' || propsFormik.values.hora_final === '') ? true : false} propsFormik={propsFormik} tamanho="col-xl-5 col-lg-6 col-5 col-sm-12 col-12 pl-0" {...props} classeTooltip={'tooltip-input-modal'} modal={true} isClearable={false}
                                                                        onChange={(option) => onChangeProced(option, propsFormik, arrayHelpers, index)} className={'procedimento-linha'}
                                                                        placeholder={placeholderInput(propsFormik)} />
                                                                    <div className={"form-group col-8 col-xl-1 col-lg-2 col-md-3 col-sm-12 col-12 pr-0 text-center"}>
                                                                        <label htmlFor={props.id || props.name}>Porte</label>
                                                                        <div className={'text-center vertical-center mt-3'}><span>{propsFormik.values.procedimentos[index].porte || 0}</span></div>
                                                                    </div>

                                                                    <div className={"form-group col-8 col-xl-1 col-lg-2 col-md-3 col-sm-12 col-12 pr-0 text-center"}>
                                                                        <label htmlFor={props.id || props.name}>Valor Porte</label>
                                                                        <div className={'text-center vertical-center mt-3'}><span>{propsFormik.values.procedimentos[index].valor_porte}</span></div>
                                                                    </div>

                                                                    <InputPadraoSimples performance={true} label={index === 0 ? 'Acm(%)' : undefined} name={`procedimentos[${index}].porcentagem_acm`}
                                                                        placeholder='Acm(%)' classeTooltip={'tooltip-input-modal'} tamanho="col-8 col-xl-1 col-sm-12 col-lg-2 col-12 col-md-2 pr-0"
                                                                        onBlur={option => {
                                                                            propsFormik.values.procedimentos[index].porcentagem_acm = option.target.value
                                                                            calcularProcedimento(propsFormik.values.procedimentos, index, propsFormik, setTotalprocedimentos)
                                                                        }} disabled={!propsFormik.values.procedimentos[index].procedimento} />

                                                                    <InputPercentual label={'Perc.'} placeholder='Perc.' classeTooltip={'tooltip-input-modal'} isClearable={false}
                                                                        name={`procedimentos[${index}].porcentagem`} propsFormik={propsFormik} tamanho="col-sm-2 col-xl-2 col-lg-3 col-md-3 col-sm-12 col-12 pr-0"
                                                                        onChange={option => { propsFormik.values.procedimentos[index].porcentagem = option; calcularProcedimento(propsFormik.values.procedimentos, index, propsFormik, setTotalprocedimentos) }}
                                                                        isDisabled={!propsFormik.values.procedimentos[index].procedimento} />

                                                                    <InputPadraoSimples performance={true} label={index === 0 ? 'Red./Acresc.' : undefined} name={`procedimentos[${index}].reducao_acrescimo`}
                                                                        placeholder='Red./Acresc.' classeTooltip={'tooltip-input-modal'} tamanho="col-8 col-xl-2 col-lg-3 col-md-2 col-sm-12 col-12 pr-0"
                                                                        onBlur={option => {
                                                                            propsFormik.values.procedimentos[index].reducao_acrescimo = option.target.value
                                                                            calcularProcedimento(propsFormik.values.procedimentos, index, propsFormik, setTotalprocedimentos)
                                                                        }} disabled={!propsFormik.values.procedimentos[index].procedimento} />

                                                                    <InputSwitch label='Urg' checked={propsFormik.values.procedimentos[index].procedimento && propsFormik.values.procedimentos[0].urg && propsFormik?.values?.tipo_honorario?.value != 2} quebrarLinha
                                                                        propsFormik={propsFormik} classeLabel={(index === 0 ? '' : 'justify-center-vertical')} classeDiv='form-group ajusta-switch'
                                                                        name={`procedimentos[${index}].urg`} size="md" tamanho={!props.modal && 'col-xl-1 col-lg-2 col-md-2 col-sm-12 col-12 pr-0'} classeTooltip={props.modal && 'tooltip-input-modal'}
                                                                        onChange={option => { propsFormik.values.procedimentos[0].urg = option; recalcular() }} />

                                                                    <InputPadraoSimples performance={true} label={index === 0 ? 'Qtde' : undefined} name={`procedimentos[${index}].quantidade`} type='number'
                                                                        placeholder='Qtde' disabled classeTooltip={'tooltip-input-modal'} tamanho="col-8 col-xl-1 col-lg-1 col-md-2 col-sm-12 col-12 pr-0" />

                                                                    <InputCurrency onUpdate={val => {
                                                                        inputValorProcedimentos.current[index] = val
                                                                        inputValorProcedimento.current = val;
                                                                    }}
                                                                        label={index === 0 ? 'Valor R$' : undefined} name={`procedimentos[${index}].valor_total`}
                                                                        value={propsFormik.values.procedimentos[index].valor_total_formatado || propsFormik.values.procedimentos[index].valor_total}
                                                                        classeTooltip={'tooltip-input-modal'}
                                                                        propsFormik={propsFormik}
                                                                        onBlur={(e) => {
                                                                            if (inputValorProcedimentos.current[index] || inputValorProcedimentos.current[index] === 0) {
                                                                                propsFormik.values.procedimentos[index].valor_total = inputValorProcedimentos.current[index] && inputValorProcedimentos.current[index].toString(); propsFormik.values.procedimentos[index].valor_total_formatado = inputValorProcedimentos.current && inputValorProcedimentos.current[index].toString();
                                                                                onChangeValor(propsFormik)
                                                                                propsFormik.values.procedimentos[index].procedimento.value = propsFormik.values.procedimentos[index].procedimento.value + 'UPD'
                                                                            }

                                                                        }}
                                                                        classDiv='col-8 col-xl-2 col-lg-2 col-md-2 text-center pr-0' />
                                                                </div>

                                                                <div className='col-12 col-xl-12 col-lg-12 col-md-12 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right ' style={{ borderBottom: '2px solid #ccc', marginBottom: 12 }}>
                                                                    <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, propsFormik, index)} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            })
                                            :
                                            <></>}
                                    </>
                                ))}
                            </FieldArray>
                            <div className='modal-body-cadastro row mr-0 ml-0 mt-2'>
                                <div className={"col-11 text-right vertical-center"}>
                                    <span>Total: <span className='ml-1'><b>{totalProcedimentos}</b></span></span>
                                </div>
                            </div>
                            {propsFormik.touched.procedimentos && propsFormik.touched.procedimentos.filter(x => x && x.procedimento).length > 0 && propsFormik.errors.procedimentos && !Array.isArray(propsFormik.errors.procedimentos) ?
                                <Overlay target={refProcedimento.current} show={true} placement="bottom">
                                    {props2 => {
                                        return (
                                            <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                                <div className="error">
                                                    <FiAlertTriangle className="icone" /> <span>{propsFormik.errors.procedimentos}</span></div>
                                            </Tooltip>)
                                    }}
                                </Overlay> : <></>}
                        </div>
                    </div>
                    : <></>}
            </>)

    return procedimentos
})

const CadastroExecutantesInternos = React.forwardRef((props, ref) => {
    const propsFormik = useFormikContext();
    const [data, setData] = useState([])
    const [minimizar, setMinimizar] = useState([false])
    const [atualizarEmpresa, setAtualizarEmpresa] = useState(false)
    const [arrayRef, setArrayRef] = useState(Array(propsFormik.values.executantes_internos ? propsFormik.values.executantes_internos.length : 1).fill(React.createRef()))
    const refEmpresa = useRef(null)
    const refExecutante = useRef(null)
    const [refAtualizadas, setRefAtualizadas] = useState(false)


    useEffect(() => {
        if (propsFormik.values.executantes_internos && propsFormik.values.executantes_internos.length > 0 && !refAtualizadas) {
            setArrayRef(Array(propsFormik.values.executantes_internos ? propsFormik.values.executantes_internos.length : 1).fill(React.createRef()))
            setRefAtualizadas(true)
        }
    }, [propsFormik.values.executantes_internos])

    const forcarAtualizacao = (data, atualizar, idx) => {
        const item = propsFormik.values.executantes_internos[idx]
        if ((!data || data.length === 0) && item.data && item.executante) {
            atualizar({ data: item.data, socioId: item.executante.value })
        }
    }

    const onChangeSocio = (option, propsFormik, arrayHelpers, index) => {
        const arrayExecutantes = [...propsFormik.values.executantes_internos]
        setAtualizarEmpresa(true)
        funcAtualizarEmpresa(index, { socioId: option ? option.value : undefined, data: arrayExecutantes[index].data })

        if (option) {
            const valorAnterior = { ...propsFormik.values.executantes_internos[index] }

            arrayExecutantes[index].executante = option
            // arrayExecutantes[index].executante_tipo_atendimento = { value: 1, label: 'Rotina' }
            const { empresa } = option


            var fatura_empresa_true = empresa.filter(x => x.fatura_empresa === true)
            var fatura_empresa_false = empresa.filter(x => x.fatura_empresa === false)

            if (fatura_empresa_true.length === 1) {
                setTimeout(() => {
                    propsFormik.setFieldValue(`executantes_internos[${index}].empresa`, fatura_empresa_true[0]);
                }, (50));

            } else if (fatura_empresa_false.length === 1) {
                setTimeout(() => {
                    propsFormik.setFieldValue(`executantes_internos[${index}].empresa`, fatura_empresa_false[0]);
                }, (50));

            }
            else if (fatura_empresa_true.length > 1) {
                setTimeout(() => {
                    propsFormik.setFieldValue(`executantes_internos[${index}].empresa`, undefined);
                }, (50))

            }
            else if (fatura_empresa_false.length > 1) {
                setTimeout(() => {
                    propsFormik.setFieldValue(`executantes_internos[${index}].empresa`, undefined);
                }, (50))

            }

            propsFormik.setFieldValue(`executantes_internos[${index}].empresa`, undefined);

            // propsFormik.setFieldValue(`executantes_internos[${index}].executante`, option);


            propsFormik.setFieldValue('executantes_internos', arrayExecutantes);

            if (!valorAnterior.executante || !valorAnterior.executante.value) {
                adicionarLinha(arrayHelpers, propsFormik)
            }
        }
        else {
            arrayExecutantes[index].executante = undefined
            propsFormik.setFieldValue('executantes_internos', arrayExecutantes);

        }
    }

    const onChangeHora = (name, option, propsFormik, index) => {
        const arrayExecutantes = [...propsFormik.values.executantes_internos]
        if (option) {
            arrayExecutantes[index][name] = option
            propsFormik.setFieldValue('executantes_internos', arrayExecutantes);
        }
        else {
            arrayExecutantes[index][name] = undefined
            propsFormik.setFieldValue('executantes_internos', arrayExecutantes);
        }
    }

    const adicionarLinha = (arrayHelpers, propsFormik) => {
        const arrayProced = propsFormik.values.executantes_internos;
        if (arrayProced && arrayProced.findIndex(x => !x.executante || !x.executante.value) === -1) {
            setArrayRef([...arrayRef, React.createRef()])
            // arrayHelpers.insert(propsFormik.values.executantes_internos.length, { executante: undefined, executante_tipo_atendimento: { value: 1, label: 'Rotina' }, data: propsFormik.values.data_procedimento, empresa: undefined, hora_inicial: propsFormik.values.hora_inicial, hora_final: propsFormik.values.hora_final })
            arrayHelpers.insert(propsFormik.values.executantes_internos.length, { executante: undefined, executante_tipo_atendimento: propsFormik.values.tipo_atendimento, data: propsFormik.values.data_procedimento, hora_inicial: propsFormik.values.hora_inicial, hora_final: propsFormik.values.hora_final })

        }
    }

    const removerLinha = (arrayHelpers, propsFormik, index) => {
        const arrayProced = propsFormik.values.executantes_internos;
        let cloneArrayRef = [...arrayRef]
        if (arrayProced.length === 1) {
            propsFormik.setFieldValue('executantes_internos', [{ executante: undefined, tipo_atendimento: undefined, empresa: undefined }])
        } else {
            if (arrayProced[index].executante && arrayProced[index].executante.value) {
                cloneArrayRef.splice(index, 1)
                arrayHelpers.remove(index)
            }

            if (arrayProced.length === 1 && arrayProced[index].executante && arrayProced[index].executante.value) {
                cloneArrayRef.push(React.createRef())
                arrayHelpers.insert(propsFormik.values.executantes_internos.length, { executante: undefined, tipo_atendimento: undefined, data: propsFormik.values.data_procedimento, empresa: undefined })
            }
        }
    }

    const funcAtualizarEmpresa = (idx, obj) => {
        if (arrayRef[idx])
            arrayRef[idx].current.atualizar(obj)
    }

    const atualizarAtendimento = async () => {

        setTipoAtendimento(propsFormik, 0, true)
    }

    const setTipoAtendimento = async (propsFormik, index, atendimentoGeral) => {

        const valores = propsFormik.values
        const feriados = props.feriados

        if (atendimentoGeral) {

            var executantes = valores.executantes_internos;
            for (let i = 0; i < executantes.length; i++) {
                let urgenciaLinha = false;
                if (isUrgencyOnHoliday(feriados, valores.executantes_internos[i].data, valores.executantes_internos[i].hora_inicial, valores.executantes_internos[i].hora_final)) {
                    urgenciaLinha = true
                } else {
                    urgenciaLinha = await isUrgency(valores.executantes_internos[i].data, valores.executantes_internos[i].hora_inicial, valores.executantes_internos[i].hora_final)
                }


                executantes[i].executante_tipo_atendimento = urgenciaLinha ? { value: 3, label: 'Voluntário' } : { value: 1, label: 'Rotina' }
            }
            propsFormik.setFieldValue('executantes_internos', executantes);
        } else {
            if (valores.executantes_internos[index].data && valores.executantes_internos[index].hora_inicial && valores.executantes_internos[index].hora_final) {
                let urgencia = false

                if (isUrgencyOnHoliday(feriados, valores.executantes_internos[index].data, valores.executantes_internos[index].hora_inicial, valores.executantes_internos[index].hora_final))
                    urgencia = true
                else
                    urgencia = await isUrgency(valores.executantes_internos[index].data, valores.executantes_internos[index].hora_inicial, valores.executantes_internos[index].hora_final)

                if (urgencia)
                    propsFormik.setFieldValue(`executantes_internos[${index}].executante_tipo_atendimento`, { value: 3, label: 'Voluntário' })
                else
                    propsFormik.setFieldValue(`executantes_internos[${index}].executante_tipo_atendimento`, { value: 1, label: 'Rotina' })
            }
        }
    }

    if (ref)
        ref.current = {
            atualizarAtendimento: atualizarAtendimento
        }


    let executantes =
        (
            <>
                <div className="mt-40">
                    <HeaderCadastro
                        titulo={'Identificação do(s) Profissional(is) Executante(s) EQUIPE'}
                        classeChildren='space-between-100'
                        minimizar
                        idxMinimizar={0}
                        arrayMinimizar={minimizar}
                        setArrayMinimizar={setMinimizar}
                    >
                    </HeaderCadastro>
                </div>
                {!minimizar[0] ?
                    <div className="card mb-20 scroll-x">
                        <div className="card-body  executantes-internos-float" ref={refExecutante}>
                            <FieldArray name="executantes_internos">
                                {(arrayHelpers => (
                                    <>
                                        {propsFormik.values.executantes_internos && propsFormik.values.executantes_internos.length > 0 ?
                                            propsFormik.values.executantes_internos.map((item, index) => <div className='modal-body-cadastro row mr-0 ml-0'>
                                                <div className='row col-sm-12 col-xl-4 col-lg-12 col-md-12'>
                                                    <InputDatePicker
                                                        denyIndex
                                                        tamanho="col-4 pl-0"
                                                        label={index === 0 ? "Data" : undefined}
                                                        name={`executantes_internos[${index}].data`}
                                                        type="text"
                                                        setarValor={(value, changed) => {
                                                            propsFormik.setFieldValue(`executantes_internos[${index}].data`, value)
                                                            propsFormik.setFieldTouched(`executantes_internos[${index}].data`, true)
                                                            const socio = propsFormik.values.executantes_internos[index].executante
                                                            if (changed) {
                                                                funcAtualizarEmpresa(index, { socioId: socio ? socio.value : undefined, data: value })
                                                                setTipoAtendimento(props.propsFormik, index)
                                                            }
                                                            propsFormik.values.executantes_internos[index].data_changed = false
                                                        }}
                                                        onBlurData={async (value, changed) => {
                                                            if (changed) {
                                                                await setTipoAtendimento(propsFormik, index)
                                                                propsFormik.values.executantes_internos[index].data_changed = false

                                                            }
                                                        }}
                                                        onChange={value => {
                                                            propsFormik.setFieldValue(`executantes_internos[${index}].data`, value)
                                                            propsFormik.setFieldTouched(`executantes_internos[${index}].data`, true)
                                                            const socio = propsFormik.values.executantes_internos[index].executante
                                                            funcAtualizarEmpresa(index, { socioId: socio ? socio.value : undefined, data: value })
                                                            propsFormik.values.executantes_internos[index].data_changed = false
                                                            // arrayRef[index].current.atualizar({ socioId: socio ? socio.value : undefined, data: value })
                                                        }}
                                                        placeholder="Data"
                                                        changed={propsFormik.values.executantes_internos[index].data_changed} obrigatorio
                                                    />

                                                    <InputTimePicker
                                                        denyIndex label={index === 0 ? "Hora Inicial" : undefined} name={`executantes_internos[${index}].hora_inicial`} type="text" placeholder="Hora Inicial" tamanho={"col-4 pl-0"} classeTooltip={'tooltip-input-modal'}
                                                        setarValor={value => {
                                                            propsFormik.setFieldValue(`executantes_internos[${index}].hora_inicial`, value)
                                                            propsFormik.values.executantes_internos[index].hora_inicial_changed = false
                                                        }}
                                                        myBlur={async event => {
                                                            if (event) {
                                                                await setTipoAtendimento(propsFormik, index);
                                                            }
                                                        }}
                                                        changed={propsFormik.values.executantes_internos[index].hora_inicial_changed} obrigatorio
                                                    />

                                                    <InputTimePicker
                                                        denyIndex label={index === 0 ? "Hora Final" : undefined} name={`executantes_internos[${index}].hora_final`} type="text" placeholder="Hora Final" tamanho={"col-4 pl-0"} classeTooltip={'tooltip-input-modal'}
                                                        setarValor={value => {
                                                            propsFormik.setFieldValue(`executantes_internos[${index}].hora_final`, value)
                                                            propsFormik.values.executantes_internos[index].hora_final_changed = false
                                                        }}
                                                        myBlur={async event => {
                                                            if (event)
                                                                await setTipoAtendimento(propsFormik, index);
                                                        }}
                                                        changed={propsFormik.values.executantes_internos[index].hora_final_changed} obrigatorio
                                                    />

                                                </div>
                                                {/* {} */}
                                                <InputSocioGuia data={propsFormik.values.executantes_internos[index].data} label={index === 0 ? 'Sócio' : undefined} placeholder={!propsFormik.values.executantes_internos[index].data && 'Selecione a Data' || 'Sócio'} name={`executantes_internos[${index}].executante`} propsFormik={propsFormik}
                                                    tamanho={'col-sm-9 col-xl-3 col-lg-5 col-md-5 pl-0 pr-0 mb-0'} isDisabled={!propsFormik.values.executantes_internos[index].data} {...props} classeTooltip={'tooltip-input-modal'}
                                                    value={propsFormik.values.executantes_internos[index].executante}
                                                    funcaoAposObter={(option) => {
                                                        setAtualizarEmpresa(true)
                                                    }}
                                                    onFocus={_ => propsFormik.setFieldTouched(`executantes_internos[${index}].executante`, true)}
                                                    onChange={(option) => onChangeSocio(option, propsFormik, arrayHelpers, index)} isClearable={false} cacheOptions={false} />

                                                <InputTipoAtendimentoEquipe label={index === 0 ? 'Tipo Atend.' : undefined} obrigatorio={true} placeholder='Tipo Atend.' classeTooltip={'tooltip-input-modal'}
                                                    name={`executantes_internos[${index}].executante_tipo_atendimento`} propsFormik={propsFormik} tamanho="col-sm-3 8 col-xl-2 col-lg-2 col-md-2 pr-0" />

                                                <InputEmpresaGuia ref={arrayRef[index]} label={index === 0 ? 'Empresa' : undefined} placeholder='Empresa' classeTooltip={'tooltip-input-modal'}
                                                    valor={propsFormik.values.executantes_internos[index].empresa} onMenuOpenPrev={(data, atualizar) => forcarAtualizacao(data, atualizar, index)}
                                                    name={`executantes_internos[${index}].empresa`} propsFormik={propsFormik} tamanho="col-8 col-xl-2 col-lg-3 col-md-4 pr-0"
                                                    atualizar={atualizarEmpresa && propsFormik.touched.executantes_internos && propsFormik.touched.executantes_internos[index] && (propsFormik.touched.executantes_internos[index].data)} />

                                                <div className='col-4 col-xl-1 col-lg-1 col-md-1 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right'>
                                                    <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, propsFormik, index)} />
                                                </div>
                                            </div>)
                                            :
                                            <></>}
                                    </>
                                ))}
                            </FieldArray>
                            {propsFormik.touched.executantes_internos && propsFormik.touched.executantes_internos.filter(x => x && x.executante).length > 0 && propsFormik.errors.executantes_internos && !Array.isArray(propsFormik.errors.executantes_internos) ?
                                <Overlay target={refExecutante.current} show={true} placement="bottom">
                                    {props2 => {
                                        return (
                                            <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                                <div className="error">
                                                    <FiAlertTriangle className="icone" /> <span>{propsFormik.errors.executantes_internos}</span></div>
                                            </Tooltip>)
                                    }}
                                </Overlay> : <></>}
                        </div>
                    </div> :
                    <></>
                }
            </>)

    return executantes
})
const CadastroPacote = React.forwardRef(({ contrato, ...props }, ref) => {
    const propsFormik = useFormikContext();
    const [data, setData] = useState([])
    const [minimizar, setMinimizar] = useState([true])

    const carregarProcedimentos = async (option, propsFormik, arrayHelpers, index) => {

        let valorAnterior = { ...propsFormik.values.pacote[index] }
        let pacotes = cloneDeep(propsFormik.values.pacote)
        pacotes[index].pacote_id = option
        propsFormik.setFieldValue(`pacote[${index}].pacote_valor`, formatarMoeda(option.valor_pacote))

        await Promise.resolve(get(`/faturamento/obterPacoteProcedimentos`, { acomodacao_id: propsFormik.values.acomodacao.value, pacote_selecionado: option, contrato_id: contrato?.contrato_id })).then(val => {
            if (!val || !val.length) {
                mostrarToast('erro', 'Procedimento(s) não encontrado(s)!')
                return
            }
            else {
                let mudouPacote = false
                let procedimentos = [...propsFormik.values.procedimentos]
                let novosProced = val.map(item => ({ pacote_idx: index, procedimento: { value: item.procedimento.value, label: item.procedimento.label }, quantidade: 1, porte: item.porte, valor_porte: item.valor_porte_formatado, valor_total: item.valor_total, porcentagem: { value: 1, label: '100%' }, porcentagem_acm: item.acomodacao, reducao_acrescimo: 100, pacote_id: val[0]?.pacote_id, urgencia: item.urgencia }))
                if (valorAnterior && valorAnterior.pacote_id) {
                    mudouPacote = true
                    procedimentos.splice(procedimentos.findIndex(item => item.pacote_idx === index), procedimentos.filter(item => item.pacote_idx === index).length, ...novosProced)
                    propsFormik.setFieldValue('procedimentos', procedimentos)
                } else {
                    procedimentos.splice(procedimentos.length - 1, 1)
                    propsFormik.setFieldValue('procedimentos', [...procedimentos, ...novosProced, { procedimento: undefined, quantidade: 1, porte: undefined, valor_porte: undefined, valor_total: undefined, porcentagem: { value: 1, label: '100%' }, porcentagem_acm: 100, reducao_acrescimo: 100 }])
                }
                let procedimentosGeral = mudouPacote ? procedimentos : [...procedimentos, ...novosProced]
                // let valorPorte = procedimentosGeral.map(x => x.valor_total && x.valor_total.replace('R$', '').replace('.', '').replace(',', '.').trim())
                // for (let i in procedimentosGeral) {
                //     procedimentosGeral[i].valor_porte = valorPorte[i]
                //     // procedimentosGeral[i].valor_total = valorPorte[i]
                // }

                props.targetProced.current.calcularTodosProcedimentos(procedimentosGeral)
            }
        })
        if (option && (index + 1) === propsFormik.values.pacote.length) {
            adicionarLinha(arrayHelpers, propsFormik)
        }

    }

    const onBlurPacote = (option, propsFormik, arrayHelpers, index) => {

        // let pacotes = propsFormik.values.pacote
        // pacotes = pacotes.map(x => x && x.pacote_id && x.pacote_id.value)
        // let existeDuplicidade = (new Set(pacotes)).size !== pacotes.length;
        // if (option && (index + 1) === propsFormik.values.pacote.length && !existeDuplicidade) {
        //     adicionarLinha(arrayHelpers, propsFormik)
        // }
        // else {
        //     mostrarToast('erro', 'Pacote já inserido!')
        //     //  arrayHelpers.remove(index)
        // }
    }

    const adicionarLinha = (arrayHelpers, propsFormik, index) => {
        arrayHelpers.insert(propsFormik.values.pacote.length, { pacote_id: undefined, pacote_descricao: undefined, pacote_valor: undefined })
    }

    const removerLinha = (arrayHelpers, propsFormik, index) => {

        const arrayProced = propsFormik.values.pacote;
        if (arrayProced.length === 1) {
            propsFormik.setFieldValue('pacote', [{ pacote_id: undefined, pacote_descricao: undefined }])
        } else {
            if (arrayProced[index].pacote_id && arrayProced[index].pacote_id.value)
                arrayHelpers.remove(index)

            if (arrayProced.length === 1 && arrayProced[index].pacote_id && arrayProced[index].pacote_id.value)
                arrayHelpers.insert(propsFormik.values.pacote.length, { pacote_id: undefined, pacote_descricao: undefined })
        }
    }
    // propsFormik.valuespropsFormik.values

    const placeholderInputPacote = propsFormik => {
        if (!propsFormik.values.data_procedimento) {
            return 'Selecione a Data do Procedimento'
        } else {
            if (!(propsFormik.values.estabelecimento_saude && propsFormik.values.estabelecimento_saude.value)) {
                return 'Selecione o Estabelecimento Saúde'
            } else {
                return 'Pacote'
            }
        }
    }
    return (
        <>
            <div className="mt-40">
                <HeaderCadastro
                    titulo={'Pacote'}
                    classeChildren='space-between-100'
                    minimizar
                    idxMinimizar={0}
                    arrayMinimizar={minimizar}
                    setArrayMinimizar={setMinimizar}
                >
                </HeaderCadastro>
            </div>
            {!minimizar[0] ?
                <div className="card mb-20 scroll-x">
                    <div className="card-body  executantes-internos-float">
                        <FieldArray name="pacote">
                            {(arrayHelpers => (
                                <>
                                    {propsFormik.values.pacote && propsFormik.values.pacote.length > 0 ?
                                        propsFormik.values.pacote.map((item, index) => <div className='modal-body-cadastro row mr-0 ml-0'>

                                            <div className='row col-sm-11 col-xl-10 col-lg-6 col-md-8'>
                                                <div className={"form-group col-1 pl-0 text-center"}>
                                                    <label htmlFor={props.id || props.name}>{index === 0 ? '#' : undefined}</label>
                                                    <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{index + 1}</span></div>
                                                </div>
                                                <div className={"row col-sm-11 col-xl-11 col-lg-6 col-md-8"}>
                                                    <InputPacoteContrato label={index === 0 ? ' Pacote' : undefined} isDisabled={!propsFormik.values.data_procedimento || !propsFormik.values.acomodacao ? true : !propsFormik.values.estabelecimento_saude ? true : false} contrato={contrato && contrato.contrato_id}
                                                        placeholder={placeholderInputPacote(propsFormik)} name={`pacote[${index}].pacote_id`} propsFormik={propsFormik}
                                                        tamanho={'col-sm-9 col-xl-8 col-lg-6 col-md-5 pl-0 pr-0 mb-0'}  {...props} data={props.data_socio} classeTooltip={'tooltip-input-modal'}
                                                        onChange={(option) => carregarProcedimentos(option, propsFormik, arrayHelpers, index)} isClearable={false} />

                                                    <InputValorNovo label={index === 0 ? 'Valor R$' : undefined} value={propsFormik.values.pacote[index].pacote_valor_formatado || propsFormik.values.pacote[index].pacote_valor}
                                                        name={`pacote[${index}].pacote_valor`} classeTooltip={'tooltip-input-modal'} classDiv='col-8 col-xl-4 col-lg-2 col-md-2 text-center pr-0'
                                                        propsFormik={propsFormik} isDisable={false} max={100000000} />
                                                </div>

                                            </div>
                                            <div className='col-4 col-xl-2 col-lg-1 col-md-1 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right'>
                                                <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, propsFormik, index)} />
                                            </div>
                                        </div>)
                                        :
                                        <></>}
                                </>
                            ))}
                        </FieldArray>
                    </div>
                </div> :
                <></>
            }
        </>)
})

export { CadastroGuiaParticular }
export default Cadastro
