import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaCPF, validaData, dataMenorAtual, validaConjuge } from '../../../../util/Validacoes';
import { InputPadrao, InputTelefone, InputDatePicker, InputSwitch, TextArea } from '../../../../components/Inputs'
import { InputEstado, InputSexo, InputEspecialidadeMedica, InputMunicipio, InputPais, InputTipoDocumento, InputEstadoCivil, InputRaca, InputCBOS, InputSocioStatus } from '../../../../components/InputsAutoComplete'
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { useParams } from "react-router-dom";
import { get, salvar } from '../../../../util/AcessoApi'
import { mensagens, ajustarDadosTelefone, sortTable } from '../../../../util/Utilitarios'
import { Link } from 'react-router-dom'
import { HeaderCadastro } from '../../../../components/Headers'
import CadastroSocioGrupo from './SocioGrupo'
import InputArquivoS3 from '../../../../components/InputArquivoS3'
import { MODULO } from '../../../../util/Enums'
import cloneDeep from 'lodash/cloneDeep';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../../../assets/css/tabs.css'
import Atalho from '../../../../util/Atalho'


const CadastroGrupoEscala = (props) => {
    let { id } = useParams();
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [isSubmit, setDisable] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({
        descricao: '',
        observacao: '',
        socios: [],
    })
    const [dadosSocios, setDadosSocios] = useState(null)
    const [abrirModalSocio, setAbrirModalSocio] = useState(false)


    const [validation, setValidation] = useState(Yup.object({
        descricao: Yup.string()
            .required()
            .max(100),
        observacao: Yup.string()
            .max(400),
    }))



    const { history } = props

    const manter = []

    const Submit = (values, dadosGrupo, propsFormik) => {
        setDisable(true);
        try {
            salvar(
                { ...values, socio: dadosGrupo },
                '/administracao/grupo-escala',
                () => {
                    mostrarToast('sucesso', mensagens.sucesso)
                    setDisable(false);
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setDadosSocios([])
                    }
                    else
                        props.history.push('/administracao/grupo-escala/consulta')
                },
                (err) => {
                    let obj = JSON.parse(err.response.data.erros);
                    setDisable(false);
                    for (let i = 0; i < obj.length; i++) {
                        const item = obj[i];
                        if (item.mensagem) {
                            propsFormik.setFieldError(item.campo, item.mensagem)
                            mostrarToast('erro', item.mensagem)
                        }
                        else
                            mostrarToast('erro', item)
                    }
                }, true)
        } catch (ex) {
            alert(ex.toString())
        }
    }

    useEffect(() => {
        if (id) {
            Promise.resolve(get(`/administracao/grupo-escala/${id}`)).then(function (val) {
                if (val.length === 0)
                    history.push('/administracao/grupo-escala/consulta')

                let obj = { ...val }
                setValoresIniciais(obj)

                if (obj.socios && obj.socios.length > 0) {
                    setDadosSocios(obj.socios)
                }
                else {
                    setDadosSocios([])
                }

            }).catch((e) => {
                console.log('e', e)
            });

        } else {
            setValoresIniciais({
                descricao: '',
                observacao: '',
                socios: [],
            })
            setDadosSocios([])

        }

    }, [history, id])

    if (dadosSocios === null)
        return <></>
    else
        return (
            <Formik validationSchema={validation}
                initialValues={valoresIniciais} enableReinitialize={true}
                onSubmit={(values, propsFormik) => Submit(values, dadosSocios, propsFormik)}>
                {propsFormik => (
                    <Form className={props.modal !== undefined ? "full" : ""}>
                        <div className='card mb-20'>
                            <div className='card-body'>
                                <h2><span>Dados do Grupo</span></h2>
                                <div className="row">
                                    <InputPadrao
                                        tamanho="col-0"
                                        label="Id"
                                        name="id"
                                        type="hidden"
                                        placeholder="Id" />
                                    <InputPadrao
                                        tamanho="col-xl-3 col-lg-6"
                                        label="Descrição"
                                        name="descricao"
                                        type="text"
                                        placeholder="Descrição" obrigatorio autoFocus
                                        salvarENovo={salvarENovo}
                                    />
                                    <TextArea
                                        tamanho="col-xl-3 col-lg-6"
                                        label="Observação"
                                        name="observacao"
                                        rows="3"
                                        type="text"
                                        placeholder="Observação"
                                        salvarENovo={salvarENovo}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <CadastroSocioGrupo setData={setDadosSocios} data={dadosSocios} abrirModal={abrirModalSocio} setAbrirModal={setAbrirModalSocio} />
                        <Atalho formik={propsFormik} salvando={isSubmit} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                        <div className='separator mt-40 mb-5'></div>
                        <div className='direita container-botoes mb-3 group-salvar-novo'>
                            <BotaoPadrao texto='Salvar' disabled={isSubmit} click={() => { setSalvarENovo(false) }} idTooltip={'salvar'} />
                            {!id && <BotaoPadrao texto='Salvar e Novo' disabled={isSubmit} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                        </div>
                    </Form >
                )}
            </Formik >);
}

const Cadastro = (props) => {
    const [subtitulo, setSubtitulo] = useState('')
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Administração</span>,
        () => <Link className="link-active" to={'/administracao/grupo-escala/consulta'}>Sócio</Link>,
    ]

    return (
        <>
            <HeaderCadastro titulo={"Cadastro de Grupo Escala"} link={links} subtitulo={subtitulo} />
            <CadastroGrupoEscala {...props} setSubtitulo={setSubtitulo} />
        </>
    )
}

export { CadastroGrupoEscala }
export default Cadastro