import { InputSimNao, InputOperadoraSaude, InputContratoStatus, InputVersaoTISS } from '../../../../components/InputsAutoComplete'
import { InputPadrao, InputDatePicker, InputValor, InputSwitch, TextArea, InputPercentual } from '../../../../components/Inputs'
import { InputValorNovo } from '../../../../components/InputsV2'
import Yup, { validaData, periodoData } from '../../../../util/Validacoes'
import ContratoPorteAnestesico from './ContratoPorteAnestesico'
import { HeaderCadastro } from '../../../../components/Headers'
import ContratoPorteAcomodacao from './ContratoPorteAcomodacao'
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import ContratoPorteConsulta from './ContratoPorteConsulta'
import React, { useState, useEffect, useRef } from 'react'
import '../../../../assets/css/icones/iconsmind/style.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { CadastroProcedimento } from '../../contrato-particular/pages/Tab'
import { salvar, get, validar } from '../../../../util/AcessoApi'
import { mensagens } from '../../../../util/Utilitarios'
import ContratoAcomodacao from './ContratoAcomodacao'
import { Link, useParams } from 'react-router-dom'
import ContratoTabela from './ContratoTabela'
import { Formik, Form } from 'formik'
import moment from "moment"
import Atalho from '../../../../util/Atalho'
import { ModalDuasOpcoes } from '../../../../components/Modais'

export default props => {
    let { id } = useParams()

    //MODAIS
    const [modalContratoTabela, setModalContratoTabela] = useState(false)
    const [modalContratoAcomodacao, setModalContratoAcomodacao] = useState(false)
    const [modalContratoPorteAcomodacao, setModalContratoPorteAcomodacao] = useState(false)
    const [modalContratoPorteAnestesico, setModalContratoPorteAnestesico] = useState(false)
    const [modalContratoConsulta, setModalContratoConsulta] = useState(false)

    //ARRAYS DADOS
    const [contratoAcomodacao, setContratoAcomodacao] = useState([])
    const [contratoTabela, setContratoTabela] = useState([])
    const [contratoPorteAcomodacao, setContratoPorteAcomodacao] = useState([])
    const [contratoPorteAnestesico, setContratoPorteAnestesico] = useState([])
    const [contratoConsulta, setContratoConsulta] = useState([])
    const [contratoProcedimento, setContratoProcedimento] = useState([])

    const [valoresIniciais, setValoresIniciais] = useState({ anestesista: { value: true, label: 'Sim' }, vigencia_inicial: "", vigencia_final: "", acrescimo_reducao: 100, procedimentos: [{ procedimento: undefined, valor: undefined, valor_diferenciado: undefined }] })
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)
    const inputFocus = useRef(null)
    const [erroPorte, setErroPorte] = useState([false, false, false])
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')

    const [validacao] = useState(Yup.lazy(({ procedimentos }) => Yup.object({
        operadora_saude: Yup.string().required().nullable(),
        contrato_status: Yup.string().required().nullable(),
        numero: Yup.string().nullable().max(15),
        vigencia_inicial: Yup.string().required().nullable()
            .test('Vigência Inicial', 'Data inválida!', function (value) { return validaData(this.parent.vigencia_inicial) })
            .test('Vigência Inicial', 'Vigência Inicial deve ser anterior à Vigência Final.', function (value) { return periodoData(value, this.parent.vigencia_final) }),
        vigencia_final: Yup.string().required().nullable()
            .test('Vigência Final', 'Data inválida!', function (value) { return validaData(this.parent.vigencia_final) })
            .test('Vigência Final', 'Vigência Inicial deve ser anterior à Vigência Final.', function (value) { return periodoData(this.parent.vigencia_inicial, value) }),
        anestesista: Yup.string().required().nullable(),
        consulta: Yup.string().required().nullable(),
        acrescimo_reducao: Yup.string().nullable().max(50),
        valor_uco: Yup.string().nullable().max(50),
        valor_filme: Yup.string().nullable().max(50),
        tabela: Yup.string().nullable(),
        acomodacao: Yup.string().nullable(),
        acomodacao_porte: Yup.string().nullable(),
        contato_consulta: Yup.string().nullable(),
    })
        .shape({
            procedimentos: Yup.array()
                .of(
                    Yup.object().shape({
                        procedimento: Yup.object().nullable()
                            .test('Código', 'Campo Obrigatório', function (value) {
                                const index = this.path.replace(/[^0-9]+/g, '');
                                const valor = procedimentos[index].procedimento
                                return !(procedimentos.length - 1 != index && !valor)
                            }),
                        descricao: Yup.object().nullable()
                            .test('Descrição', 'Campo Obrigatório', function (value) {
                                const index = this.path.replace(/[^0-9]+/g, '');
                                const valor = procedimentos[index].descricao
                                return !(procedimentos.length - 1 != index && !valor)
                            }),
                        porte: Yup.object().nullable().typeError('Porte de ver numérico.')
                            .test('Porte', 'Campo Obrigatório', function (value) {
                                const index = this.path.replace(/[^0-9]+/g, '');
                                const valor = procedimentos[index].porte
                                return !(procedimentos.length - 1 != index && !valor)
                            })
                    }))
        })
    ))

    const manter = []

    const validarContrato = (values, porte) => {
        setErroPorte([false, false, false])
        let erro = false

        let IdsContratoTabelaSemTabelaAuxiliar = contratoTabela.filter(x => x?.tabela_procedimento?.tabela_auxiliar == false).map(x => x?.tabela_procedimento?.value)

        let tabelaSemTabelaAuxiliar = contratoTabela.filter(x => !x.tabela_procedimento.tabela_auxiliar)

        let contratoPorteAcomodacaoSemTabelaAuxiliar = contratoPorteAcomodacao.filter(x => IdsContratoTabelaSemTabelaAuxiliar.includes(x?.tabela_procedimento?.id))

        if (contratoAcomodacao.length && contratoTabela.filter(x => parseInt(x.base_calculo.value) === 1).length) {

            if (contratoPorteAcomodacaoSemTabelaAuxiliar.filter(x => x.array_acomodacao.length !== contratoAcomodacao.length).length > 0 ||
                contratoPorteAcomodacaoSemTabelaAuxiliar.length !== tabelaSemTabelaAuxiliar.filter(x => parseInt(x.base_calculo.value) === 1).length ||
                contratoPorteAcomodacaoSemTabelaAuxiliar.filter(x => x.array_acomodacao.filter(y => y.array_porte.filter(z => !z.valor_porte).length).length).length > 0) {
                setErroPorte([true, erroPorte[1], erroPorte[2]])
                mostrarToast('erro', 'Preencha todos os valores de portes para acomodação!')
                erro = true
            }
        }

        if (contratoAcomodacao.length === 0) {
            mostrarToast('erro', 'Insira ao menos uma acomodação!')
            erro = true
        }
        if (contratoTabela.length === 0 && contratoProcedimento.length < 2) {
            mostrarToast('erro', 'Insira ao menos uma Tabela ou um Procedimento!')
            erro = true
        }

        if (tabelaSemTabelaAuxiliar.filter(x => !x?.tabela_procedimento?.tabela_auxiliar && parseInt(x.base_calculo.value) === 2).length) {
            if (porte.length !== tabelaSemTabelaAuxiliar.filter(x => !x?.tabela_procedimento?.tabela_auxiliar && parseInt(x.base_calculo.value) === 2).length ||
                porte.filter(x => x.porte.filter(y => !y.valor_porte).length).length > 0) {
                setErroPorte([erroPorte[0], true, erroPorte[2]])
                mostrarToast('erro', 'Preencha todos os valores de portes anestésico!')
                erro = true
            }
        }
        if (values.consulta.value === 'true' && contratoConsulta.length === 0) {
            setErroPorte([erroPorte[0], erroPorte[1], true])
            mostrarToast('erro', 'Preencha a consulta!')
            erro = true
        }

        if (contratoConsulta.length) {
            const inicio = moment(values.vigencia_inicial, 'DD/MM/YYYY')
            const fim = moment(values.vigencia_final, 'DD/MM/YYYY')

            const result = contratoConsulta.filter(x => {
                const consultaInicio = moment(x.data_inicio, 'DD/MM/YYYY')
                const consultaFim = moment(x.data_fim, 'DD/MM/YYYY')

                return consultaInicio < inicio || consultaInicio > fim || consultaFim < inicio || consultaFim > fim
            })

            if (result.length) {
                setErroPorte([erroPorte[0], erroPorte[1], true])
                mostrarToast('erro', 'Datas da consulta devem estar dentro da vigência do contrato!')
                erro = true
            }
        }

        return erro
    }

    const atualizarPorteAcomodacao = _ => {
        if (contratoTabela.length && contratoAcomodacao.length && contratoTabela.filter(x => x.base_calculo.value === 1).length) {
            let acomodacaoPorte = []
            if (!contratoPorteAcomodacao.length) {

                acomodacaoPorte = contratoTabela.filter(x => x.base_calculo.id === 1).map(x => {
                    return {
                        tabela_procedimento: { id: x.tabela_procedimento.value, label: x.tabela_procedimento.label },
                        array_acomodacao: contratoAcomodacao.map(y => {
                            return {
                                acomodacao: { id: y.acomodacao.value, label: y.acomodacao.label },
                                array_porte: [0, 1, 2, 3, 4, 5, 6, 7, 8].map(z => {
                                    return {
                                        porte: z,
                                        valor_porte: null,
                                        urgencia: null,
                                        video: null,
                                        id_tabela_procedimento: x.tabela_procedimento.value,
                                        id_acomodacao: y.acomodacao.value
                                    }
                                }),
                            }
                        })
                    }
                })
            }
            else {
                acomodacaoPorte = [...contratoPorteAcomodacao]
                const adicionarTabela = contratoTabela.filter(x => !(contratoPorteAcomodacao.find(y => y.tabela_procedimento.id === x.tabela_procedimento.id)))
                    .filter(x => x.base_calculo.id === 1)
                const removerTabela = contratoPorteAcomodacao.filter(x => !(contratoTabela.find(y => y.tabela_procedimento.id === x.tabela_procedimento.id)))

                const adicionarAcomodacao = contratoAcomodacao.filter(x => !(contratoPorteAcomodacao[0].array_acomodacao.find(y => y.acomodacao.id === x.acomodacao.id)))
                const removerAcomodacao = contratoPorteAcomodacao[0].array_acomodacao.filter(x => !(contratoAcomodacao.find(y => y.acomodacao.id === x.acomodacao.id)))

                if (removerAcomodacao.length)
                    for (let i = 0; i < removerAcomodacao.length; i++)
                        acomodacaoPorte.map(x => x.array_acomodacao.splice(x.array_acomodacao.findIndex(y => y.acomodacao.id === removerAcomodacao[i].acomodacao.id), 1))

                if (adicionarAcomodacao.length) {
                    adicionarAcomodacao.map(x =>
                        acomodacaoPorte.map(y =>
                            y.array_acomodacao.push({
                                acomodacao: { id: x.acomodacao.value, label: x.acomodacao.label },
                                array_porte: [0, 1, 2, 3, 4, 5, 6, 7, 8].map(z => {
                                    return {
                                        porte: z,
                                        valor_porte: null,
                                        urgencia: null,
                                        video: null,
                                        id_tabela_procedimento: y.tabela_procedimento.value,
                                        id_acomodacao: x.acomodacao.value
                                    }
                                }),
                            })
                        )
                    )
                }

                if (removerTabela.length) {
                    for (let i = 0; i < removerTabela.length; i++)
                        acomodacaoPorte.splice(acomodacaoPorte.findIndex(x => x.tabela_procedimento.id === removerTabela[i].tabela_procedimento.id), 1)
                }

                if (adicionarTabela.length) {

                    adicionarTabela.map(x =>
                        acomodacaoPorte.push({
                            tabela_procedimento: { id: x.tabela_procedimento.value, label: x.tabela_procedimento.label },
                            array_acomodacao: contratoAcomodacao.map(y => {
                                return {
                                    acomodacao: { id: y.acomodacao.value, label: y.acomodacao.label },
                                    array_porte: [0, 1, 2, 3, 4, 5, 6, 7, 8].map(z => {
                                        return {
                                            porte: z,
                                            valor_porte: null,
                                            urgencia: null,
                                            video: null,
                                            id_tabela_procedimento: x.tabela_procedimento.value,
                                            id_acomodacao: y.acomodacao.value
                                        }
                                    }),
                                }
                            })
                        })
                    )
                }
            }

            setContratoPorteAcomodacao(acomodacaoPorte)

            return acomodacaoPorte
        }
        else
            setContratoPorteAcomodacao([])

        return []
    }

    const atualizarPorteAnestesico = _ => {
        if (contratoTabela.length && contratoTabela.filter(x => x.base_calculo.value === 2 && !x?.tabela_procedimento?.tabela_auxiliar).length) {
            let porte = []
            if (!contratoPorteAnestesico.length)
                porte = contratoTabela.filter(x => x.base_calculo.id === 2 && !x?.tabela_procedimento?.tabela_auxiliar).map(x => {
                    return {
                        tabela_procedimento: { id: x.tabela_procedimento.value, label: x.tabela_procedimento.label },
                        porte: [0, 1, 2, 3, 4, 5, 6, 7, 8].map(z => {
                            return {
                                porte: z,
                                valor_porte: null,
                                urgencia: null,
                                video: null,
                                id_tabela_procedimento: x.tabela_procedimento.value,
                            }
                        }),
                    }
                })
            else {
                porte = [...contratoPorteAnestesico]
                const adicionarTabela = contratoTabela.filter(x => !x?.tabela_procedimento?.tabela_auxiliar && !(contratoPorteAnestesico.find(y => y.tabela_procedimento.id === x.tabela_procedimento.id)))
                    .filter(x => x.base_calculo.id === 2);
                const removerTabela = contratoPorteAnestesico.filter(x => !(contratoTabela.find(y => y.tabela_procedimento.id === x.tabela_procedimento.id)));

                if (removerTabela.length)
                    for (let i = 0; i < removerTabela.length; i++)
                        porte.splice(porte.findIndex(x => x.tabela_procedimento.id === removerTabela[i].tabela_procedimento.id), 1)

                if (adicionarTabela.length)
                    adicionarTabela.map(x =>
                        porte.push({
                            tabela_procedimento: { id: x.tabela_procedimento.value, label: x.tabela_procedimento.label },
                            porte: [0, 1, 2, 3, 4, 5, 6, 7, 8].map(z => {
                                return {
                                    porte: z,
                                    valor_porte: null,
                                    urgencia: null,
                                    video: null,
                                    id_tabela_procedimento: x.tabela_procedimento.value,
                                }
                            }),
                        })
                    )
            }
            setContratoPorteAnestesico(porte)

            return porte
        }
        else
            setContratoPorteAnestesico([])

        return []
    }

    const validarSubmit = (values, propsFormik) => {
        setSalvando(true)
        validar('/faturamento/validaContrato', values, () => Submit(values, propsFormik), (e) => {
            if (e && e.response) {
                setShowModalValidacao(true)
                setMensagemModal(e.response.data.alertas[0])
            } else {
                Submit(values, propsFormik)
            }
        })
    }

    const Submit = (values, propsFormik) => {
        const porte = atualizarPorteAnestesico()
        const porteAcom = atualizarPorteAcomodacao()
        setSalvando(true)

        if (validarContrato(values, porte)) {
            setSalvando(false)
            return
        }

        values.contrato_acomodacao = contratoAcomodacao
        values.contrato_tabela = contratoTabela
        values.contrato_porte_acomodacao = porteAcom
        values.contrato_porte_anestesico = porte
        values.contrato_porte_consulta = contratoConsulta

        salvar(
            { ...values },
            '/faturamento/contrato',
            obj => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    propsFormik.setFieldValue('procedimentos', [{ procedimento: undefined, valor: undefined, valor_diferenciado: undefined }])

                    propsFormik.resetForm({})
                    setContratoAcomodacao([])
                    setContratoTabela([])
                    setContratoPorteAcomodacao([])
                    setContratoPorteAnestesico([])
                    setModalContratoConsulta([])
                    erroPorte = [false, false, false]
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                }
                else
                    props.history.push('/faturamento/contrato/consulta')
            },
            () => setSalvando(false))
    }

    const funcaoAposSim = (values, propsFormik) => Submit(values, propsFormik)


    useEffect(_ => {
        var objListas = {
            procedimentos: [{ procedimento: undefined, valor: undefined, valor_diferenciado: undefined }],
        }
        if (id)
            Promise.resolve(get(`/faturamento/contrato/${id}`)).then(obj => {
                if (id && !obj.id)
                    props.history.push('/faturamento/contrato/consulta')

                obj.procedimentos = [...(obj.procedimentos || []), ...objListas.procedimentos];

                // obj.acrescimo_reducao = parseFloat(obj.acrescimo_reducao)

                setValoresIniciais(obj)
                setContratoAcomodacao(obj.contrato_acomodacao)
                setContratoTabela(obj.contrato_tabela)
                setContratoPorteAcomodacao(obj.contrato_porte_acomodacao)
                setContratoPorteAnestesico(obj.contrato_porte_anestesico)
                setContratoConsulta(obj.contrato_porte_consulta)
            })
    }, [id])

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Faturamento</span>,
        () => <Link className='link-active' to={'/faturamento/contrato/consulta'}>Contrato</Link>,
    ]

    const erroContrato = (propsFormik) => {
        const erros = propsFormik.errors;
        return (erros.operadora_saude || erros.contrato_status || erros.vigencia_inicial || erros.vigencia_final || erros.anestesista || erros.consulta)
    }

    const setValueNumero = (propsFormik, operadoraSaudeValue) => {
        const date = new Date()

        if (operadoraSaudeValue)
            propsFormik.setFieldValue('numero',
                date.getFullYear().toString() + date.getMonth().toString().padStart(2, '0') + operadoraSaudeValue.value)
        else
            propsFormik.setFieldValue('numero', '')
    }

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
            {propsFormik => {
                return (
                    <Form className={props.modal !== undefined ? "full" : ""}>
                        <HeaderCadastro titulo={"Cadastro Contrato"} link={links} />
                        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                            <TabList>
                                <Tab className={"react-tabs__tab " + (erroContrato(propsFormik) ? 'tab-error' : '')} style={{ padding: 15 }}><span className={"icon-File-ClipboardFileText"}></span> Contrato</Tab>
                                <Tab className={"react-tabs__tab"}><span className={"icon-bed"}></span>&nbsp;&nbsp;Acomodação</Tab>
                                <Tab className={"react-tabs__tab"}><span className={"icon-table"}></span>&nbsp;&nbsp;Tabela</Tab>
                                <Tab className={"react-tabs__tab"}><span className={" icone-tab icon-table"}></span>&nbsp;&nbsp;Procedimento</Tab>
                                <Tab className={`react-tabs__tab ${!(contratoTabela.filter(x => x.base_calculo.value === 1).length && contratoAcomodacao.length) && 'disabled' || ''}`}>
                                    <span className={'icon-bed'}></span> &nbsp;&nbsp;Porte Acomodação
                                    {erroPorte[0] && <><span className={'ml-2'}></span><span className={'icon-error error'}></span></> || ''}
                                </Tab>
                                <Tab className={`react-tabs__tab ${!(contratoTabela.find(x => x.base_calculo.value === 2)) && 'disabled' || ''}`}>
                                    <span className={" icon-Bulleted-List "}></span>&nbsp;&nbsp;Porte Anestésico
                                    {erroPorte[1] && <><span className={'ml-2'}></span><span className={'icon-error error'}></span></> || ''}
                                </Tab>
                                <Tab className={"react-tabs__tab"}>
                                    <span className={"icon-table "}></span>&nbsp;&nbsp;Consulta
                                    {erroPorte[2] && <><span className={'ml-2'}></span><span className={'icon-error error'}></span></> || ''}
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <div className='card mb-20'>
                                    <div className='card-body'>
                                        <div className="row">
                                            <InputPadrao label='Id' name='id' type='hidden' />
                                            <InputOperadoraSaude label='Operadora Saúde' name="operadora_saude" propsFormik={propsFormik} obrigatorio tamanho='col-lg-6' autoFocus removerInativos={true}
                                                botaoVisivel={true} modal={true} focusAfter={inputFocus} salvarENovo={salvarENovo} {...props} functionOnChange={setValueNumero} />
                                            <InputContratoStatus label='Status' name="contrato_status" propsFormik={propsFormik} obrigatorio tamanho='col-lg-3' {...props} />
                                            <InputPadrao label='Número' name='numero' type='text' placeholder='Número' tamanho={!props.modal && 'col-lg-3'}
                                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                                            <InputDatePicker label="Vigência Inicial" name="vigencia_inicial" type="text" classeTooltip={'tooltip-input-modal'} obrigatorio
                                                setarValor={value => { propsFormik.setFieldValue("vigencia_inicial", value) }} placeholder="Vigência Inicial" tamanho='col-lg-3' />
                                            <InputDatePicker label="Vigência Final" name="vigencia_final" type="text" classeTooltip={'tooltip-input-modal'} obrigatorio
                                                setarValor={value => { propsFormik.setFieldValue("vigencia_final", value) }} placeholder="Vigência Final" tamanho='col-lg-3' />
                                            <InputSimNao label="Anestesista?" name="anestesista" type="select" placeholder="Anestesista?" tamanho={'col-lg-3'} isClearable={true}
                                                noOptionsMessage={_ => ""} propsFormik={propsFormik} obrigatorio />
                                            <InputSimNao label="Consulta?" name="consulta" type="select" placeholder="Consulta?" tamanho={'col-lg-3'} isClearable={true}
                                                noOptionsMessage={_ => ""} propsFormik={propsFormik} obrigatorio />
                                            <InputValorNovo value={propsFormik.values.acrescimo_reducao_formatado || propsFormik.values.acrescimo_reducao} label='Acréscimo/Redução' name={`acrescimo_reducao`} classDiv='col-lg-2' propsFormik={propsFormik} isDisable={false} max={100000000} suffix={'%'} />
                                            <InputValorNovo prefix={''} label={'Valor UCO'} value={propsFormik.values.valor_uco} name={`valor_uco`} classDiv='col-lg-2'
                                                propsFormik={propsFormik} isDisable={false} max={100000000} />
                                            <InputValorNovo prefix={''} label={'Valor Filme'} value={propsFormik.values.valor_filme} name={`valor_filme`} classDiv='col-lg-2'
                                                propsFormik={propsFormik} isDisable={false} max={100000000} />
                                            <InputPadrao label='Identificador Subdivisão' name='codigo_subdivisao' type='number' placeholder='Identificador Subdivisão' tamanho={'col-lg-2'}
                                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                                            <InputPadrao label='Descrição' name='descricao' type='text' placeholder='Descrição' tamanho={!props.modal && 'col-lg-4'}
                                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                                            <InputPadrao label='Cód. Prestador Operadora' name='codigo_prestador' type='number' placeholder='Cód. Prestador Operadora' tamanho={!props.modal && 'col-lg-3'}
                                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                                            <InputPadrao label='Qtde. Máxima de Guias Por Lote' name='qtd_guias_por_fatura' type='number' placeholder='Qtde. Máxima de Guias Por Lote' tamanho={!props.modal && 'col-lg-3'}
                                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                                            <InputPadrao label='Máscara Carteira/Matricula TISS' name='mascara_carteira' type='text' placeholder='Máscara Carteira/Matricula TISS' maxlength="20" value={propsFormik.values.mascara_carteira} onChange={(imput) => {
                                                propsFormik.setFieldValue('mascara_carteira', '#'.repeat(imput.target.value.length))
                                            }} tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                            <InputPadrao label='Limite para Faturamento (Dias)' name='dias_limite_faturamento' type='number' placeholder='Limite para Faturamento (Dias)' tamanho={!props.modal && 'col-lg-3'}
                                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                                            <InputVersaoTISS label='Versão TISS' name="versao_tiss" placeholder="Versão TISS" propsFormik={propsFormik} tamanho='col-lg-2' {...props} />
                                            <InputPadrao label='Nome Paciente' name='paciente' type='text' placeholder='Nome Paciente' tamanho={!props.modal && 'col-lg-3'}
                                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                                            <InputSwitch label='Envia Diversos?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='envia_diversos' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                            <div class="display-flex mt-4 mb-4 col-12">
                                                <h4 class="mb-2"><span>Parâmetros Comprovante de Anestesia</span></h4>
                                            </div>
                                            <div className='row'>
                                                <InputSwitch label='Utiliza Guia ?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='utiliza_guia' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Proced. "Não" Ordenados?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='proced_nao_ordenados' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Valida Maior Porte?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='valida_maior_porte' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Valida Interposição?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='valida_interposicao' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Utiliza Senha?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='utiliza_senha' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Valida Duplicidade?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='valida_duplicidade' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Procedimento Único(50/70/100%)?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='procedimento_unico' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Liberar Percentual?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='liberar_percentual' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Utiliza Tiss ?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='utiliza_tiss' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Carteira/Matrícula?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='utiliza_matricula' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Utiliza DN ?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='utiliza_dn' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                            </div>
                                            <div class="display-flex mt-4 mb-4 col-12">
                                                <h4 class="mb-2"><span>Duplicidade de Guias (Campos)</span></h4>
                                            </div>
                                            <div className='row'>
                                                <InputSwitch label='Sócio Coopanest' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='socio' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Esbelecimento Sáude' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='estabelecimento_saude' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Acomodação' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='acomodacao' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Guia' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='guia' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Carteira/Matrícula' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='matricula' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Nome Paciente' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='nome_paciente' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Data Procedimento' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='data_procedimento' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Hora Inicial' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='hora_inicial' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Hora Final' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='hora_final' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Senha' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='senha_tiss' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                <InputSwitch label='Procedimento' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='procedimento' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                            </div>
                                        </div>
                                        <TextArea label='Observação' name='observacao' type='text' placeholder='Observação' tamanho={!props.modal && 'col-lg-12'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='padding-40'>
                                    <ContratoAcomodacao setArrayDados={setContratoAcomodacao} arrayDados={contratoAcomodacao} abrirModal={modalContratoAcomodacao}
                                        setAbrirModal={setModalContratoAcomodacao} />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='padding-40'>
                                    <ContratoTabela setArrayDados={setContratoTabela} arrayDados={contratoTabela} abrirModal={modalContratoTabela}
                                        setAbrirModal={setModalContratoTabela} propsFormik={propsFormik} />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='padding-40'>
                                    <CadastroProcedimento setArrayDados={setContratoProcedimento} propsFormik={propsFormik} />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='padding-40'>
                                    <ContratoPorteAcomodacao attValues={atualizarPorteAcomodacao} tabela={contratoTabela} arrayDados={contratoPorteAcomodacao}
                                        acomodacao={contratoAcomodacao} setArrayDados={setContratoPorteAcomodacao} abrirModal={modalContratoPorteAcomodacao}
                                        setAbrirModal={setModalContratoPorteAcomodacao} />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='padding-40'>
                                    <ContratoPorteAnestesico attValues={atualizarPorteAnestesico} arrayDados={contratoPorteAnestesico} setArrayDados={setContratoPorteAnestesico}
                                        abrirModal={modalContratoPorteAnestesico} tabela={contratoTabela} setAbrirModal={setModalContratoPorteAnestesico} />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='padding-40'>
                                    <ContratoPorteConsulta arrayDados={contratoConsulta} setArrayDados={setContratoConsulta} abrirModal={modalContratoConsulta}
                                        setAbrirModal={setModalContratoConsulta} dataInicio={propsFormik.values["vigencia_inicial"]} dataFim={propsFormik.values["vigencia_final"]} />
                                </div>
                            </TabPanel>
                        </Tabs>
                        <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                        <div className='separator mt-40 mb-5'></div>
                        <div className='direita container-botoes mb-3 group-salvar-novo'>
                            <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                            {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                        </div>
                        <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { funcaoAposSim(propsFormik.values, propsFormik); setShowModalValidacao(false); }} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                            esconderFechar mensagem={mensagemModal}
                            textoOpcao1={'Cancelar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} />
                    </Form>
                )
            }}
        </Formik>
    )
}