import React, { useState, useEffect, useRef } from 'react'
import { consultar, get, atulizarWs } from '../../../../util/AcessoApi'
import { excluir } from '../../../../util/FuncoesComuns'
import socketIo from "socket.io-client";
import ProgressBar from 'react-bootstrap/ProgressBar'
import { BotaoPadrao } from '../../../../components/Botoes'
import { ModalCadastro } from '../../../../components/Modais'
import { Alterar, Excluir, Visualizar, BtnAcao } from '../../../../components/Acoes'
import { getApi, getUsuario } from '../../../../util/Configuracoes'
import { LoadRelatorio } from '../../../../components/Loading'
import { MODULO } from '../../../../util/Enums';
import { mostrarToast } from '../../../../components/Toasts'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'

export default props => {
    const [data, setData] = useState([])

    useEffect(() => {
        consultar('/administracao/operadoraSaude', (dados) => setData(dados.sort((a, b) => b.id - a.id)))
    }, [])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-operadora-saude',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                }, {
                    Header: 'Identificação',
                    accessor: 'codigo',
                    ordenamento: {
                        tipo: 'numero'
                    }
                }, {
                    Header: 'Razão Social',
                    accessor: 'razao_social',
                    ordenamento: {
                        tipo: 'texto',
                    }
                }, {
                    Header: 'Nome Fantasia',
                    accessor: 'nome_fantasia',
                    ordenamento: {
                        tipo: 'texto',
                    }
                }, {
                    Header: 'CNPJ',
                    accessor: 'cnpj',
                    ordenamento: {
                        tipo: 'texto',
                    }
                }, {
                    Header: 'Ativo?',
                    accessor: 'ativo',
                    ordenamento: {
                        tipo: 'texto',
                    },
                    filtro: {
                        tipo: 'input_sim_nao',
                        idColuna: 'ativo',
                        name: `input_sim_nao_${Math.floor(Math.random() * 1000)}`,
                        valor: 'true',
                        valor_inicial: { value: 'true', label: 'Sim' }
                    }
                }, {
                    Header: 'Código Cooperativa',
                    accessor: 'codigo_coopanestgo',
                    ordenamento: {
                        tipo: 'texto',
                    },
                },{
                    Header: 'Data Atualização',
                    accessor: 'data_atualizacao',
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'operadora_saude.data_atualizacao'
                    }
                }, {
                    Header: 'Data de Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    }
                }
            ]
        }], []
    )
    
    return (
        <>
            <DataGridAlternativa data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/administracao/operadoraSaude' tituloLista={'Lista Operadora de Saúde'}
                caminhoConsultaFiltros='/administracao/operadoraSaudePaginacao' urlCadastro='/administracao/operadoraSaude/cadastro' modulo={props.modulo}
                titulo={'Consulta Operadora de Saúde'} icone={'icon-Geo3-'} atualizarOperadora={() => props.obterWebService(JSON.stringify({"integrationType": "operadora_saude", "key": "10","usuarioId": getUsuario().id, "moduloId": 8, "acaoId" : 30}))}/>

            {/* <ModalCadastro titulo={operadoraCoopanest && operadoraCoopanest.label || 'Atualização Operadora'} show={showModal} setShowModal={setShowModal}>
                <div className='modal-body-cadastro'>
                    <div className='separator mt-20 mb-20'></div>
                    <div className={'form-group col-12'}>
                        <label className={'width-100 bold'}>Código</label>
                        <label className={'width-100 mb-20'}>{operadoraCoopanest && operadoraCoopanest.codigo || <div>&nbsp;</div>}</label>
                        <label className={'width-100 bold'}>Registro ANS</label>
                        <label className={'width-100 mb-20'}>{operadoraCoopanest && operadoraCoopanest.registro_ans || <div>&nbsp;</div>}</label>
                        <label className={'width-100 bold'}>Razão Social</label>
                        <label className={'width-100 mb-20'}>{operadoraCoopanest && operadoraCoopanest.razao_social || <div>&nbsp;</div>}</label>
                        <label className={'width-100 bold'}>CNPJ</label>
                        <label className={'width-100 mb-20'}>{operadoraCoopanest && operadoraCoopanest.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") || <div>&nbsp;</div>}</label>
                        <label className={'width-100 bold'}>Última Atualização</label>
                        <label className={'width-100 mb-20'}>{operadoraCoopanest && operadoraCoopanest.data_atualizacao || <div>&nbsp;</div>}</label>
                    </div>
                </div>
                <div className='separator mt-40 mb-5'></div>
                {
                    !inProcess &&
                    <div className='direita container-botoes'>
                        <BotaoPadrao click={_ => { submit('att_operadora') }} type={'button'} classe={'btn-primary primary att-operadora'} texto={'Atualizar Operadora'} />
                    </div> ||
                    <div>
                        <label>Atualizando...</label>
                        <ProgressBar className={'progressbar-default'} animated now={update} />
                    </div>
                }
            </ModalCadastro>
            <ModalCadastro titulo={'Importação Operadora'} show={showModalImportacao} setShowModal={setShowModalImportacao}>
                <div ref={refImportacao}>
                    {
                        load && <LoadRelatorio refLoading={refImportacao} heigth={490} /> ||
                        <>
                            <div className='modal-body-cadastro'>
                                <div className='separator mt-20 mb-20'></div>
                                <div className={'form-group col-12'}>
                                    <label className={'width-100 bold'}>Total de Tabelas</label>
                                    <label className={'width-100 mb-20'}>{operadoraCoopanestImportacao && operadoraCoopanestImportacao.qtd || <div>&nbsp;</div>}</label>
                                    <label className={'width-100 bold'}>Última Atualização</label>
                                    <label className={'width-100 mb-20'}>{operadoraCoopanestImportacao && operadoraCoopanestImportacao.data_atualizacao || <div>&nbsp;</div>}</label>
                                </div>
                            </div>
                            <div className='separator mt-40 mb-5'></div>
                            {
                                !inProcess &&
                                <div className='direita container-botoes'>
                                    <BotaoPadrao click={_ => submit('importacao_operadora')} type={'button'} classe={'btn-primary primary att-operadora'} texto={'Importar Operadora'} />
                                </div> ||
                                <div>
                                    <div className={'row-space-between'}>
                                        <label>Atualizando...</label>
                                        {update && update.texto && <label>{update.texto}</label> || ''}
                                    </div>
                                    <ProgressBar className={'progressbar-default'} animated now={update && update.porcentagem} />
                                </div>
                            }
                        </>
                    }
                </div>
            </ModalCadastro> */}
        </>
    )
}