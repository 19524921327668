import React, { useEffect, useState } from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import { BtnAcao } from './Acoes';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';

export default props => {
    return props.dados && props.dados[0]?.csvMultiplo ? <CSVMultiplo {...props} /> : <CSVUnico {...props} />
}

const CSVUnico = (props) => {
    const [agrupado, setAgrupado] = useState(props.dados[0]?.agrupado)
    const [colunas, setColunas] = useState(props.dados[0]?.colunasCsv || props.dados[0]?.colunas)

    useEffect(_ => {
        setAgrupado(props.dados[0]?.agrupado)
        setColunas(props.dados[0]?.colunasCsv || props.dados[0]?.colunas)

    }, [props.dados[0]])

    const montarCsv = dados => {
        let flag = dados[0]?.coluna_agrupar ? true : false //Limitar a quantidade de agrupamentos em um para relatórios que possuam mais de um array 
        const csvData = dados.map(itens => {
            let retorno = []
            itens.data.map(item => {
                Object.entries(item).forEach(([key, value]) => {
                    //RELATÓRIOS COM AGRUPAMENTO
                    if (Array.isArray(value) && agrupado && key === itens.coluna_agrupar && flag) {
                        retorno.push(item[itens.coluna_agrupar])
                        flag = true
                    }

                    else if (Array.isArray(value) && agrupado && !flag) {
                        retorno.push(item[key])

                    }
                })
                if (!agrupado) {
                    retorno.push(item)
                }
            })
            return agrupado ? ajustaArray(retorno).flatMap(x => x).flatMap(item => Array.isArray(item) ? item.flat() : item) : ordenarPropriedades(retorno.filter(onlyUnique), colunas)
        })
        return csvData
    }

    function onlyUnique(value, index, self) {
        const prop = Object.keys(value)[0]; // Pega a primeira propriedade do objeto
        return self.findIndex(obj => obj[prop] === value[prop]) === index;
    }

    function ajustaArray(dados) {
        // let colunas = props.dados[0].colunas
        return dados.map(array => {
            let retorno = []

            array.map(item =>
                Object.entries(item).map(([key, value]) =>

                    item[key] = value,
                    retorno.push(item)
                )
            )
            return ordenarPropriedades(retorno, colunas.map(x => x))
        })
    }

    function ordenarPropriedades(array, ordem) {
        return array.map(objeto => {
            const novoObjeto = {};
            ordem.forEach(propriedade => {
                if (objeto.hasOwnProperty(propriedade.name)) {
                    novoObjeto[propriedade.label] = objeto[propriedade.name];
                }
            });
            return novoObjeto;
        });
    }

    if (agrupado !== undefined) {
        let dados = montarCsv(props.dados)
        dados = dados.length > 0 ? dados[0] : null

        if (dados && props.autoDownload) //DOWNLOAD AUTOMATICO
            return <CSVDownload target='_blank' data={dados} separator={';'} />
        else
            if (dados)
                return <CSVLink target='_blank' data={dados} separator={';'}><BtnAcao icone="icon-File-CSV" texto="Gerar CSV" action={() => { }}></BtnAcao></CSVLink>
            else
                return null
    } else {
        return null;
    }
}

const CSVMultiplo = (props) => {
    const [fazerDownload, setFazerDownload] = useState(false)

    const handleDownload = () => {
        setFazerDownload(true);
        setTimeout(() => {
            setFazerDownload(false);
        }, 500);
    };

    return <>
        <BtnAcao icone="icon-File-CSV" texto="Gerar CSV" action={handleDownload}></BtnAcao>
        {
            fazerDownload ?
                props.dados[0].colunasCsv.map((x, idx, array) => {
                    var dadosUnico = { ...props.dados[0] }
                    dadosUnico.colunasCsv = x.colunas;
                    dadosUnico.coluna_agrupar = x.nome;

                    var propsUnico = { ...props };
                    propsUnico.dados = [dadosUnico]

                    return <CSVUnico {...propsUnico} autoDownload={true} />
                })
                : <></>
        }
    </>
}
