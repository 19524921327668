import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
var format = require('xml-formatter');


export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/financeiro/categoria', id,
            obj => {

                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Categoria',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj?.id, tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Lançamento', descricao: obj?.tipo_lancamento.label, tamanho: 'col-lg-4' },
                                { titulo: 'Categoria Principal', descricao: obj?.conta_principal, tamanho: 'col-lg-4' },
                                { titulo: 'Conta', descricao: obj?.conta, tamanho: 'col-lg-4' },
                                { titulo: 'Descrição', descricao: obj?.descricao, tamanho: 'col-lg-4' },
                                { titulo: 'Ativo', descricao: obj?.ativo_visu, tamanho: 'col-lg-4' },
                                { titulo: 'Lanc/Mov?', descricao: obj?.utiliza_lancamento_movimento_visu, tamanho: 'col-lg-4' },
                                { titulo: 'Observação', descricao: obj?.observacao, tamanho: 'col-lg-4' },
                                { titulo: 'Data Registro', descricao: obj?.data_registro, tamanho: 'col-lg-4' },
                            ]
                        }
                    ]
                } : { titulo: 'Visualização Categoria' })

            })

    }, [id])


    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}
