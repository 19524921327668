import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'

const VisualizacaoCbos = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        if (id) {
            visualizar('/parametros/cbos', id,
                obj =>
                {setDados(obj.codigo ? {
                        id,
                        modulo: props.modulo,
                        titulo: 'Visualização de CBOS',
                        grupos: [
                            {
                                titulo: 'Dados Gerais',
                                colunas: [
                                    { titulo: 'Código', descricao: obj.codigo, tamanho: 'col-lg-6' },
                                    { titulo: 'Descrição', descricao: obj.descricao, tamanho: 'col-lg-6' },
                                    { titulo: 'Especialidade Médica?', descricao: obj.especialidade_medica.label , tamanho: 'col-lg-6' },
                                    { titulo: 'Data Registro', descricao: obj.data_registro_formatada, tamanho: 'col-lg-6' },
                                ]
                            },
                        ]
                    } : { titulo: 'Visualização de CBOS' })}
            )
        }
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default VisualizacaoCbos