import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputTipoRelatorio, InputEstabelecimentoSaude, InputSimNao, InputOperadoraSaude, InputSocio, InputCirurgiaoCombo, InputTipoExecutante, InputTipoCliente, InputMunicipioGOPrimeiro, InputLoteGuiaFiltro, InputLoteStatus, InputTipoGuia, InputGuiaStatus } from '../../../components/InputsAutoComplete'
import { InputDatePicker, InputPadrao } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ModalDuasOpcoes } from '../../../components/Modais';
import { checkAllUndefined } from '../../../util/Utilitarios';
import { mostrarToast } from '../../../components/Toasts';
import { InputMonthPicker } from '../../../components/InputsV2';

export default props => {

    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Lote')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '' })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [lote, setLote] = useState()

    const history = useHistory()

    useEffect(() => {
        if (history?.location?.state?.idLote)
            setLote(history?.location?.state && history?.location?.state)
    }, [])


    const validacao = Yup.object({
        data_registro_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_registro_inicial) }),
        data_registro_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_registro_final) })
    })

    const relatoriosPorTipo = propsFormik => [
        {

            titulo: 'Relatório Lote',
            tipo: 1,
            url: '/faturamento/relatorioLote',
            campos: [

                // <InputMonthPicker label="Referência" placeholder="Referência" name="referencia" propsFormik={propsFormik}
                //     classeTooltip={'tooltip-input-modal'} />,

                <InputLoteGuiaFiltro label="Lote" placeholder="Lote" name="lote" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputOperadoraSaude label="Operadora de Saúde" placeholder="Operadora de Saúde" name="operadora_saude" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputEstabelecimentoSaude label="Estabelecimento Saúde" placeholder="Estabelecimento Saúde" name="estabelecimento_saude" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputGuiaStatus label="Status" placeholder="Status" name="status" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputTipoExecutante label="Tipo Executante" placeholder="Tipo Executante" name="tipo_executante" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputTipoGuia label="Tipo Guia" placeholder="Tipo Guia" name="tipo_guia" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputDatePicker label="Data Procedimento Inicial" name="data_procedimento_inicial" type="text" autoFocus
                    setarValor={value => { propsFormik.setFieldValue("data_procedimento_inicial", value) }} />,

                <InputDatePicker label="Data Procedimento Final" name="data_procedimento_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_procedimento_final", value) }} />,

                <InputDatePicker label="Data Registro Inicial" name="data_registro_inicial" type="text" autoFocus
                    setarValor={value => { propsFormik.setFieldValue("data_registro_inicial", value) }} />,

                <InputDatePicker label="Data Registro Final" name="data_registro_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_registro_final", value) }} />,
            ],
            numerarLinha: true,
            agrupamento: [],
            colunas: [
                { label: 'Código', name: 'id', visivel: false, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Código de Barras', name: 'codigo_barras', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Paciente', name: 'paciente', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Guia Principal', name: 'guia_principal', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Senha', name: 'senha', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Carteira/Matricula', name: 'carteira', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Op. Sáude', name: 'operadora_saude', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estab. Sáude', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro', name: 'data_registro', visivel: true, width: '13%', ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Socio', name: 'socio', visivel: false, width: '10%', ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status', name: 'status', visivel: false, width: '10%', ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor R$', name: 'valor_total_formatado', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, width: '10%', alinhamento: 'text-right', totalizar: true },
                { label: 'Tipo Exec.', name: 'tipo_executante', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Guia', name: 'guia_tipo', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Referência', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Lote', name: 'guia_lote_id', name_coluna: 'lote' },
                { label: 'Operadora de Saúde', name: 'operadora_saude', name_coluna: 'operadora_saude' },
                { label: 'Estabelecimento de Saúde', name: 'estabelecimento_saude', name_coluna: 'estabelecimento_saude' },
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Tipo Executante', name: 'tipo_executante', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'tipo_guia', name_coluna: 'tipo_guia' },
                { label: 'Data Procedimento Inicial', name: 'data_procedimento_inicial', name_coluna: 'data_procedimento_inicial' },
                { label: 'Data Procedimento Final', name: 'data_procedimento_final', name_coluna: 'data_procedimento_final' },
                { label: 'Data Registro Inicial', name: 'data_registro_inicial', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro_final', name_coluna: 'data_registro_final' },
            ]
        },
    ]

    useEffect(() => {
        // console.log('WINDOW ', window.performance)
        // if (window.performance && window.performance.navigation.type == 1) {

        let values = {}
        if (lote != undefined) {

            values.lote = [{ value: lote.idLote, label: `Lote: ${lote.item.numero_lote} - Ref: ${lote.item.referencia} ${lote.item.operadora_saude ? ' - Op. Sáude: ' + `${lote.item.operadora_saude} ${lote.item.codigo_subdivisao ? '(' + lote.item.codigo_subdivisao + ')' : ''}` : ''} ${lote.item.empresa ? ' - Empresa: ' + `${lote.item.empresa}` : ''}` }]
            submit(values)
        }
        // }
    }, [lote])

    const validarSubmit = (values, propsFormik) => {

        if (values && checkAllUndefined(values.lote, values.operadora_saude, values.estabelecimento_saude, values.status, values.tipo_executante,
            values.tipo_guia, values.data_procedimento_inicial, values.data_procedimento_final, values.data_registro_inicial, values.data_registro_final)) {
            mostrarToast('erro', 'Operação não permitida! Nenhum filtro escolhido!')

        } else {

            submit(values, propsFormik)
        }

    }



    const submit = (values, propsFormik) => {

        setCarregando(true)
        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === 1)

        values.tipo_relatorio = 1
        setShowModalFiltros(false)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]

        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio) {

            setFiltros(Object.keys(values).map((x, idx, array) => {
                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value) && valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label

                })
            }).filter(x => x && x.value))

            filtros.push({ idLote: lote.idLote })

        }
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }

        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)

                setDados([{ numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: filtros })
    }, [filtros])

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })} orientacaoPadrao={'landscape'} classeRelatorio="relatorio-lote"
            agrupamento={[{ coluna: 'nome', descricao: 'nome' }]}>

            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
                {propsFormik => (
                    <div>
                        <Form>
                            <div className='row'>
                                {relatoriosPorTipo(propsFormik).find(x => x.tipo === 1) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                                    {relatoriosPorTipo(propsFormik).find(x => x.tipo === 1).campos.map(
                                        (item, idx, array) => {
                                            if (idx === (array.length - 1)) {
                                                setTimeout(() => {
                                                    setCarregandoFiltros(false)
                                                }, 1000)
                                            }
                                            return item
                                        }
                                    )}

                                </div>}
                                {carregandoFiltros === true ?
                                    <ContentLoader
                                        speed={2}
                                        width='100%'
                                        height='400'
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#e4e4e4"
                                        {...props} >
                                        <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                        <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                        <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                        <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                                    </ContentLoader>
                                    : <></>}
                            </div>
                            <div className='direita container-botoes mb-3 group-salvar-novo'>
                                <BotaoPadrao texto='Filtrar' disabled={false} />
                            </div>
                        </Form>
                        {/* <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { submit(propsFormik.values, propsFormik); setShowModalValidacao(false) }} funcaoOpcao1={() => { setCarregando(false); setShowModalValidacao(false) }}
                            esconderFechar mensagem={<>{<>{'Validações Filtro:'} <br /><br /></>} {mensagemModal}<br />
                                {'Deseja Continuar?'}</>}
                            textoOpcao1={'Cancelar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} /> */}

                    </div>
                )}
            </Formik>
        </ConsultaRelatorio >

    )
}
