import React, { useState, useEffect, useRef } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { Alterar, Excluir, Visualizar } from '../../../../components/Acoes'
import { getApi, getUsuario } from '../../../../util/Configuracoes'
import { excluir } from '../../../../util/FuncoesComuns'
import { HeaderCadastro } from '../../../../components/Headers'
import { consultar, get } from '../../../../util/AcessoApi'
import { mensagens } from '../../../../util/Utilitarios'
import { mostrarToast } from '../../../../components/Toasts'
import { ACOES } from '../../../../util/Enums'

const ConsultaSocioTipoOcorrencia = (props) => {
    const [data, setData] = useState([])
    const target = useRef(null)
    useEffect(() => {
        consultar('/administracao/socio', (dados) => setData(dados))
    }, [])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-empresa',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Tipo Ocorrência',
                        accessor: 'descricao',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Sigla',
                        accessor: 'sigla',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Mapa de Horas?',
                        accessor: 'compoe_mapa_horas_visu',
                        ordenamento: {
                            tipo: 'texto'
                        }, 
                        filtro: {
                            tipo: 'input_sim_nao',
                            idColuna: 'compoe_mapa_horas'
                        }
                    },

                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro',
                        ordenamento: {
                            tipo: 'data'
                        },
                    },
                    {
                        Header: 'Data Registro Inicial',
                        accessor: 'data_inicial',
                        visivel: false,
                        ordenamento: {
                            tipo: 'data'
                        },
                        filtro: {
                            tipo: 'data_inicial',
                            idColuna: 'data_registro_inicial',
                            datePicker: true,
                            date: true

                        }
                    }, {
                        Header: 'Data Registro Final',
                        accessor: 'data_final',
                        visivel: false,
                        ordenamento: {
                            tipo: 'data'
                        },
                        filtro: {
                            tipo: 'data_final',
                            idColuna: 'data_registro_final',
                            datePicker: true,
                            date: true

                        }
                    },
                ],
            },
        ],
        []
    )

    const acoesObj = [
        (propsAcao) => <Visualizar to={'/administracao/tipo-ocorrencia/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/administracao/tipo-ocorrencia/alteracao/' + propsAcao.row.id} />,
        (propsAcao) => <Excluir to={'administracao/tipo-ocorrencia/consulta/'} action={() => exclusao(propsAcao)} />,
    ]

    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: 124,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/administracao/tipo-ocorrencia', propsAcao.row.id,
                    () => {
                        // getApi(setData, '/faturamento/guia')
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        console.log('e: ', e);
                       
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    return (
        <>
            <div>
                <DataGridAlternativa
                    target={target}
                    data={data}
                    acoesObj={acoesObj}
                    columns={columns}
                    setData={setData}
                    caminhoBase='/administracao/tipo-ocorrencia'
                    caminhoConsultaFiltros='/administracao/tipoocorrenciapaginacao'
                    acoes={true}
                    url={{ cadastro: '/administracao/tipo-ocorrencia/cadastro', }}
                    modulo={124}
                    titulo={'Consulta Tipo Ocorrência'}
                    urlCadastro='/administracao/tipo-ocorrencia/cadastro'
                    icone={'icon-Doctor'} />
            </div>
        </>
    )
}

export default ConsultaSocioTipoOcorrencia