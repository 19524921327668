import { Alterar, Excluir, Visualizar } from '../../../../components/Acoes'
import React, { useState, useEffect } from 'react'
import { excluir } from '../../../../util/FuncoesComuns'
import { getApi } from '../../../../util/Configuracoes'
import DataGrid from '../../../../components/DataGrid'

const Consulta = (props) => {
    const [data, setData] = useState([])

    useEffect(() => {
        getApi(setData, '/controleAcesso/modulo/obterTodos', null, props.modulo)
    }, [])
    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-modulo',
                columns: [
                    {
                        Header: 'Descrição',
                        accessor: 'descricao',
                    }
                ],
            },
        ],
        []
    )
    const acoesObj = [
        (propsAcao) => <Alterar to={'/controleAcesso/modulo/alteracao/' + propsAcao.id} />,
        (propsAcao) => <Excluir to={'/controleAcesso/modulo/consulta/'} action={() => excluir(propsAcao.id, props.setData, props.caminhoBase + '/excluir', props.caminhoBase + '/obterTodos', props.modulo)} />
    ]

    return (
        <div className="card">
            <div className="card-body">
                <div className="card-title">
                    <span>Módulo</span>
                </div>
                <DataGrid
                    data={data}
                    columns={columns}
                    caminhoBase='/controleAcesso/modulo'
                    acoesObj={acoesObj}
                    setData={setData}
                    acoes={true}
                    url={{ cadastro: '/controleAcesso/modulo/cadastro' }}
                    modulo={props.modulo} />
            </div>
        </div>
    )
}

export default Consulta