import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { distinctArr, formatarMoeda } from '../../../../util/Utilitarios';
import { downloadArquivoS3, ordenarArrayObj } from '../../../../util/FuncoesComuns';

export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/financeiro/movimentofinanceirovisualizacao', id,
            obj => {
                console.log('obj.lancamento: ', obj.lancamento);
                obj.lancamento = obj.lancamento.filter(x => x.tipo_lancamento_id === 1)
                setDados(obj.id ? {

                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Movimento Financeiro',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Entidade', descricao: obj.entidade.label, tamanho: 'col-lg-4' },
                                { titulo: 'Data  ', descricao: obj.data, tamanho: 'col-lg-4' },
                                { titulo: 'Valor R$', descricao: obj.valor_formatado, tamanho: 'col-lg-4' },
                                { titulo: 'Descrição', descricao: obj.descricao, tamanho: 'col-lg-4' },
                                { titulo: 'Número do Documento', descricao: obj.numero_documento, tamanho: 'col-lg-4' },
                                { titulo: 'Conta Bancária', descricao: obj.conta_bancaria_completa, tamanho: 'col-lg-4' },
                                // {
                                //     titulo: 'Conta Bancária', descricao: `<span class="texto-superior-descricao"> Banco: ${obj.conta_bancaria.codigo_banco + ' - ' + obj.conta_bancaria.banco} | Agência: ${obj.conta_bancaria.agencia} | Conta: ${obj.conta_bancaria.conta}</span>
                                // <br />
                                // <span>${obj.conta_bancaria.label}</span>`, tamanho: 'col-lg-4', html: true
                                // },
                                { titulo: 'Status', descricao: obj.status, tamanho: 'col-lg-4' },
                                { titulo: 'Anotações', descricao: obj.observacao, tamanho: 'col-lg-4' },
                                { titulo: 'Usuário Inserção', descricao: obj.usuario, tamanho: 'col-lg-4' },
                                { titulo: 'Data de Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                            ]
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Receitas',
                            aposGrupo: <tr>
                                <td className='coluna-2'></td>
                                <td className='coluna-2 text-right' style={{ fontWeight: 'bold' }}>{obj.receita && obj.receita.length > 0 ? formatarMoeda(obj.receita.map(item => item.valor_receita).reduce((total, num) => parseFloat(total) + parseFloat(num))) : ''}</td>
                            </tr>,
                            thead:
                                <tr>
                                    {/* <td className='coluna-6'>Item Ref.</td> */}
                                    <td className='coluna-2'>Forma Receita</td>
                                    <td className='coluna-2 text-right'>Valor R$</td>
                                </tr>,
                            tbody: obj.receita == undefined || obj.receita.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.receita.filter(x => x.id).map(x =>
                                    <tr>
                                        <td>{x.forma_receita && x.forma_receita.label || '---'}</td>
                                        <td className='text-right'>{formatarMoeda(x.valor_receita)}</td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Retenções',
                            aposGrupo: <tr>
                                <td className='coluna-2'></td>
                                <td className='coluna-2 text-right' style={{ fontWeight: 'bold' }}>{obj.retencao && obj.retencao.length > 0 ? formatarMoeda(obj.retencao.map(item => item.valor_retencao).reduce((total, num) => parseFloat(total) + parseFloat(num))) : ''}</td>
                            </tr>,
                            thead:
                                <tr>
                                    {/* <td className='coluna-6'>Item Ref.</td> */}
                                    <td className='coluna-2'>Retenção</td>
                                    <td className='coluna-2 text-right'>Valor R$</td>
                                </tr>,
                            tbody: obj.retencao == undefined || obj.retencao.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.retencao.filter(x => x.id).map(x =>
                                    <tr>
                                        <td>{x.forma_retencao && x.forma_retencao.label || '---'}</td>
                                        <td className='text-right'>{formatarMoeda(x.valor_retencao)}</td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Lançamentos',
                            aposGrupo: <tr>
                                {
                                    obj.lancamento.length > 0 ?
                                        <>
                                            <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={9}>{formatarMoeda(obj.lancamento.map(item => item.valor_total_formatado).reduce((total, num) => parseFloat(total) + parseFloat(num)))}</td>
                                            <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={2}>{formatarMoeda(obj.lancamento.map(item => item.total_retencoes).reduce((total, num) => parseFloat(total) + parseFloat(num)))}</td>
                                            <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={11}>{formatarMoeda(obj.lancamento.map(item => item.valor_liquidado).reduce((total, num) => parseFloat(total) + parseFloat(num)))}</td>
                                        </>
                                        : <></>
                                }
                            </tr>,
                            thead:
                                <tr>
                                    <td className=''>#</td>
                                    <td className='coluna-1' style={{ textAlign: 'center' }}>Cód.</td>
                                    <td className='' style={{ textAlign: '' }}>Atend.</td>
                                    <td className=''>P</td>
                                    <td className='coluna-1'>Data</td>
                                    <td className='coluna-2'>Cliente</td>
                                    <td className='coluna-1'>Sócio(s)</td>
                                    <td className='coluna-2'>Estab/Op. Sáude</td>
                                    <td className='coluna-1 text-right '>Valor R$</td>
                                    <td className='coluna-1 text-center'>Data Liq.</td>
                                    <td className='coluna-1 text-right '>Ret/Acrésc. R$</td>
                                    <td className='coluna-1 text-right '>Valor Liq. R$</td>
                                </tr>,
                            tbody: obj.lancamento === undefined && obj.lancamento.length < 1 ? (<>
                                <tr>
                                    <td colspan="3">Sem Registro!</td>
                                </tr>
                            </>) :
                                obj.lancamento.map((x, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td style={{ textAlign: 'center' }}>{x.lancamento_id}</td>
                                            <td>{x.guia_id}</td>
                                            <td>{x.numero_parcela}</td>
                                            <td>{x.data_prevista}</td>
                                            <td>{x.entidade}</td>
                                            <td>{distinctArr(x.socio).join(', ')}</td>
                                            <td>{x.estab_op_saude}</td>
                                            <td className='text-right'>{formatarMoeda(x.valor_total_formatado)}</td>
                                            <td className='text-center' >{x.data_liquidacao}</td>
                                            <td className='text-right'>{formatarMoeda(x.total_retencoes)}</td>
                                            <td className='text-right'>{formatarMoeda(x.valor_liquidado)}</td>
                                        </tr>)
                                })
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Histórico de Status',
                            thead:
                                <tr>
                                    <td className='coluna-2'>Status</td>
                                    <td className='coluna-2'>Data</td>
                                    <td className='coluna-4'>Anotações</td>
                                    <td className='coluna-2'>Usuário</td>
                                    <td className='coluna-1'>Data Registro</td>
                                </tr>,
                            tbody: obj.historico_status == undefined || obj.historico_status.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.historico_status.filter(x => x.status).map(x =>
                                    <tr>
                                        <td>{x.status}</td>
                                        <td>{x.data}</td>
                                        <td>{x.anotacao}</td>
                                        <td>{x.usuario}</td>
                                        <td>{x.data_registro}</td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Arquivo(s)',
                            thead:
                                <tr>
                                    <td className='coluna-6'>Nome</td>
                                    <td className='coluna-5'>Tamanho</td>
                                    <td className='coluna-1 d-none-print'>Download</td>
                                </tr>,
                            tbody: obj.arquivo == undefined || obj.arquivo.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                ordenarArrayObj(obj.arquivo, 'nome').map(arquivo =>
                                    <tr>
                                        <td>{arquivo.name}</td>
                                        <td>{arquivo.sizeReadable}</td>
                                        <td className="d-none-print"><div className="icone"><button className="btn-acao icon-Download-fromCloud" onClick={() => downloadArquivoS3(arquivo.s3Key)}></button></div></td>
                                    </tr>)
                        },
                    ]
                } : { titulo: 'Visualização Movimento Financeiro' })
            })
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

