import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { ordenarArrayObj, downloadArquivoS3 } from '../../../../util/FuncoesComuns'
import { formatarMoeda } from '../../../../util/Utilitarios';

const VisualizacaoGuiaParticular = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/auditoria/guiapagamento', id,
            obj => {
                setDados(obj.id_pagamento ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Guia Pagamento',
                    grupos: [
                        {
                            titulo: 'Dados Paciente',
                            colunas: [
                                { titulo: 'Paciente', descricao: obj.nome_paciente, tamanho: 'col-lg-3' },
                                { titulo: 'Código de Barras', descricao: obj.codigo_barras, tamanho: 'col-lg-3' },
                                { titulo: 'Data Procedimento', descricao: obj.data_procedimento, tamanho: 'col-lg-3' },
                                { titulo: 'Status', descricao: obj.status, tamanho: 'col-lg-3' },
                                { titulo: 'Acomodação', descricao: obj?.acomodacao, tamanho: 'col-lg-3' },
                                { titulo: 'Código Coopanest', descricao: obj.codigo_coopanest, tamanho: 'col-lg-3' },
                                { titulo: 'Código Grupo', descricao: obj.codigo_grupo, tamanho: 'col-lg-3' },
                                { titulo: 'Operadora Saúde', descricao: obj.operadora_saude, tamanho: 'col-lg-3' },
                                { titulo: 'Código Subdivisão', descricao: obj.codigo_subdivisao, tamanho: 'col-lg-3' },
                                { titulo: 'Estabelecimento Saúde', descricao: obj.estabelecimento_saude, tamanho: 'col-lg-3' },
                                { titulo: 'Empresa', descricao: obj.empresa, tamanho: 'col-lg-3' },
                                { titulo: 'Tipo Cooperado', descricao: obj.tipo_cooperado, tamanho: 'col-lg-3' },
                                { titulo: 'CRM', descricao: obj.crm, tamanho: 'col-lg-3' },
                                { titulo: 'Tipo Pagamento', descricao: obj.tipo_pagamento, tamanho: 'col-lg-3' },
                                { titulo: 'Data Pagamento', descricao: obj.data_pagamento, tamanho: 'col-lg-3' },
                                { titulo: 'Valor Comprovante', descricao: obj.comprovante, tamanho: 'col-lg-3' },
                                { titulo: 'Valor Recurso', descricao: obj.recurso, tamanho: 'col-lg-3' },
                            ]
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Detalhes (Comprovante/Procedimentos)',
                            visivel: obj.detalhe_procedimento.length > 0,
                            thead:
                                <tr>
                                    <td className='coluna-1'>Item</td>
                                    <td className='coluna-1'>Codigo</td>
                                    <td className='coluna-1'>Tipo</td>
                                    <td className='coluna-1'>Data Pagamento</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>Valor Proc. (R$)</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>Valor Repassado (R$)</td>
                                </tr>,
                            tbody: obj.detalhe_procedimento.map(x =>
                                <>
                                    <tr>
                                        <td>{x.item}</td>
                                        <td>{x.codigo}</td>
                                        <td>{x.tipo}</td>
                                        <td>{x.data_pagamento}</td>
                                        <td style={{ textAlign: 'right' }}>{x.valor_procedimento}</td>
                                        <td style={{ textAlign: 'right' }}>{x.valor_final}</td>
                                    </tr>
                                </>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Glosas/Acréscimos',
                            visivel: obj.detalhe_procedimento_glosa.length > 0,
                            thead:
                                <tr>
                                    <td className='coluna-1'>Procedimento</td>
                                    <td className='coluna-1'>Código</td>
                                    <td className='coluna-1'>Descrição</td>
                                    <td className='coluna-1'>Status</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>Valor (R$)</td>
                                </tr>,
                            tbody: obj.detalhe_procedimento_glosa.map(x =>
                                <>
                                    <tr>
                                        <td>{x.codigo_procedimento}</td>
                                        <td>{x.codigo_glosa}</td>
                                        <td>{x.descricao}</td>
                                        <td>{x.status}</td>
                                        <td style={{ textAlign: 'right' }}>{x.valor_glosa}</td>
                                    </tr>
                                </>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Recursos',
                            visivel: obj.detalhe_procedimento_recurso.length > 0,
                            thead:
                                <tr>
                                    <td className='coluna-1'>Procedimento</td>
                                    <td className='coluna-1'>Data</td>
                                    <td className='coluna-1'>Status</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>Valor Recursado (R$)</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>Valor Recuperado (R$)</td>
                                    <td className='coluna-2'>Observação</td>
                                </tr>,
                            tbody: obj.detalhe_procedimento_recurso.map(x =>
                                <>
                                    <tr>
                                        <td>{x.codigo_procedimento}</td>
                                        <td>{x.data_recurso}</td>
                                        <td>{x.status}</td>
                                        <td style={{ textAlign: 'right' }}>{x.valor_solicitado}</td>
                                        <td style={{ textAlign: 'right' }}>{x.valor_acatado}</td>
                                        <td>{x.observacao}</td>
                                    </tr>
                                </>)
                        },
                    ]
                } : { titulo: 'Visualização Guia Pagamento' })
            })
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default VisualizacaoGuiaParticular