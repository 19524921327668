import React, { useEffect, useState, useCallback } from 'react'
import { mostrarToast, mostrarToastWebService } from './Toasts';
import { toast } from 'react-toastify';
import { get } from '../util/AcessoApi';
import { mensagens } from '../util/Utilitarios';
import { BtnAcao } from './Acoes';
import { forceDownload } from '../util/FuncoesComuns';
import { SERVICE } from '../util/Enums';
const AWS = require('aws-sdk');
const { Consumer } = require('sqs-consumer');
// const { Consumer } = require('sqs-consumer');

AWS.config.update({
    region: process.env.REACT_APP_SERVICES_AWS_REGION,
    accessKeyId: process.env.REACT_APP_SERVICES_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SERVICES_AWS_SECRET_ACCESS_KEY
});

const WebService = React.forwardRef((props, ref) => {
    const [messageQueue, setMessageQueue] = useState("Aguarde...")
    const [queue, setQueue] = useState('-')
    const [showBtn, setShowBtn] = useState(true)
    let funcaoDownloadOK = () => { };

    const Msg = (obj) => {
        return <>
            <div>
                <h6><b>{obj.obj.title}</b></h6>
                <span>{obj.obj.subtitle}</span>
            </div>
        </>
    }

    const handleClick = useCallback(
        (body, serviceUrl, funcSuccess) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("x-api-key", "cVPkE1n6gT4VKYjX31sj35num7TCtdot618mO3yb");
            myHeaders.append("Access-Control-Allow-Origin", "*");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: body,
                redirect: 'follow'
            };

            const toastify = toast('Aguarde...', { isLoading: true })

            let obj = JSON.parse(body)

            const objPermissao = {
                modulo_id: obj.moduloId,
                acao_id: obj.acaoId,
                usuario_id: obj.usuarioId
            }

            get('/controleAcesso/permissao', objPermissao).then(function (val) {
                if (val.length) {
                    fetch(serviceUrl || SERVICE.ENGINE_INTEGRATION, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            funcaoDownloadOK = (obj) => funcSuccess(obj)
                            setShowBtn(false);
                            setQueue(JSON.parse(result).queueUrl)
                            toast.update(toastify, { render: 'Aguarde...', toastId: JSON.parse(result).processId });
                            instanceQueue(JSON.parse(result).queueUrl)
                        })
                        .catch(error => console.log('error', error));
                }
                else
                    toast.update(toastify, { autoClose: 5000, type: 'error', render: mensagens['permissao'], isLoading: false, progress: 0 });

            }).catch((e) => mostrarToast('erro', mensagens['erro']))
        },
        [],
    )

    const instanceQueue =
        (queueUrl) => {
            let errorId = (Math.random() + 1).toString(36).substring(7)
            if (queueUrl != "-") {
                const app = Consumer.create({
                    queueUrl: queueUrl,
                    handleMessage: async (message) => {
                        setMessageQueue(message.Body);
                        let objRetorno = JSON.parse(message.Body)
                        errorId = objRetorno.processId
                        const progress = parseInt(objRetorno['progress'])
                        if (objRetorno.download === "True" && objRetorno.url) {
                            toast.update(objRetorno.processId, { autoClose: 5000, type: objRetorno.type, render: <Msg obj={objRetorno} />, isLoading: false, progress: 0 });
                            forceDownload(objRetorno.url, objRetorno.fileName)
                            funcaoDownloadOK();
                        } else {
                            switch (objRetorno.type) {
                                case 'error':
                                case 'success':
                                case 'info':
                                    toast.update(objRetorno.processId, { autoClose: 5000, type: objRetorno.type, render: <Msg obj={objRetorno} />, isLoading: false, progress: 0 });
                                    funcaoDownloadOK(objRetorno)
                                    break;
                                default:
                                    toast.update(objRetorno.processId, { progress: progress / 100, render: <Msg obj={objRetorno} />, type: objRetorno.type });
                                    break;
                            }
                        }

                    }
                });
                app.on('error', (err) => {
                    toast('Ocorreu um erro inesperado. Tente novamente mais tarde.', { autoClose: 5000, type: 'error', toastId: errorId })
                });

                app.on('processing_error', (err) => {
                    toast('Ocorreu um erro inesperado. Tente novamente mais tarde.', { autoClose: 5000, type: 'error', toastId: errorId })
                });
                app.start();
            }

        }

    const handleClickNoQueue = useCallback(
        (body, serviceUrl, config) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("x-api-key", "cVPkE1n6gT4VKYjX31sj35num7TCtdot618mO3yb");
            myHeaders.append("Access-Control-Allow-Origin", "*");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: body,
                redirect: 'follow'
            };

            const toastify = toast('Aguarde...', { isLoading: true })
            
            let obj = JSON.parse(body)
            
            const objPermissao = {
                modulo_id: obj.moduloId,
                acao_id: obj.acaoId,
                usuario_id: obj.usuarioId
            }
            
            get('/controleAcesso/permissao', objPermissao).then(function (val) {
                toast.update(toastify, { autoClose: config.tempo || 5000, type: 'info', render: <Msg obj={{ title: config.titulo, subtitle: config.mensagem }} />, isLoading: false });

                if (val.length) {
                    fetch(serviceUrl, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            //
                        })
                        .catch(error => {
                            toast.update(toastify, { autoClose: 5000, type: 'error', render: mensagens['erro'], isLoading: false, progress: 0 });
                        });
                }
                else
                    toast.update(toastify, { autoClose: 5000, type: 'error', render: mensagens['permissao'], isLoading: false, progress: 0 });

            }).catch((e) => mostrarToast('erro', mensagens['erro']))
        },
        [],
    )

    if (ref) {
        ref.current = {
            processar: handleClick,
            processarSemFila: handleClickNoQueue,
        }
    }
});

const WebServiceSimples = React.forwardRef((props, ref) => {
    const [messageQueue, setMessageQueue] = useState("Aguarde...")
    const [queue, setQueue] = useState('-')
    const [showBtn, setShowBtn] = useState(true)

    const Msg = (obj) => {
        return <>
            <div>
                <h6><b>{obj.obj.title}</b></h6>
                <span>{obj.obj.subtitle}</span>
            </div>
        </>
    }


    const handleClick = useCallback(
        (body, serviceUrl) => {
            console.log('serviceUrl: ', serviceUrl);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("x-api-key", "cVPkE1n6gT4VKYjX31sj35num7TCtdot618mO3yb");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: body,
                redirect: 'follow'
            };

            const toastify = toast('Aguarde...', { isLoading: true })

            let obj = JSON.parse(body)

            const objPermissao = {
                modulo_id: obj.moduloId,
                acao_id: obj.acaoId,
                usuario_id: obj.usuarioId
            }

            var objMessage = {
                title: 'Gerando Arquivo',
                subtitle: 'Gerando Arquivo Mapa de Horas'
            }

            get('/controleAcesso/permissao', objPermissao).then(function (val) {
                if (val.length) {
                    fetch(serviceUrl, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            console.log('result: ', result);
                            // setShowBtn(false);
                            // setQueue(JSON.parse(result).queueUrl)
                            toast.update(toastify, { render: 'Aguarde...', toastId: JSON.parse(result).processId });
                            // toast.update(objRetorno.processId, { type: 'default', render: <Msg obj={objMessage} />, isLoading: false, progress: 100 });
                        })
                        .catch(error => console.log('error', error));
                }
                else
                    toast.update(toastify, { autoClose: 5000, type: 'error', render: mensagens['permissao'], isLoading: false, progress: 0 });

            }).catch((e) => mostrarToast('erro', mensagens['erro']))
        },
        [],
    )

    const instanceQueue =
        (queueUrl) => {
            let errorId = (Math.random() + 1).toString(36).substring(7)
            if (queueUrl != "-") {
                const app = Consumer.create({
                    queueUrl: queueUrl,
                    handleMessage: async (message) => {
                        setMessageQueue(message.Body);
                        let objRetorno = JSON.parse(message.Body)
                        errorId = objRetorno.processId
                        const progress = parseInt(objRetorno['progress'])

                        switch (objRetorno.type) {
                            case 'error':
                            case 'success':
                            case 'info':
                                toast.update(objRetorno.processId, { autoClose: 5000, type: objRetorno.type, render: <Msg obj={objRetorno} />, isLoading: false, progress: 0 });
                                break;
                            default:
                                toast.update(objRetorno.processId, { progress: progress / 100, render: <Msg obj={objRetorno} />, type: objRetorno.type });
                                break;
                        }
                    }
                });
                app.on('error', (err) => {
                    toast('Ocorreu um erro inesperado. Tente novamente mais tarde.', { autoClose: 5000, type: 'error', toastId: errorId })
                });

                app.on('processing_error', (err) => {
                    toast('Ocorreu um erro inesperado. Tente novamente mais tarde.', { autoClose: 5000, type: 'error', toastId: errorId })
                });
                app.start();
            }

        }

    if (ref) {
        ref.current = {
            processar: handleClick
        }
    }
});

export default WebService;

export { WebServiceSimples }
