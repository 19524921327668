import { InputCpfCnpj, InputDatePicker, InputPadrao, InputSwitch, InputValor, TextArea } from '../../../../components/Inputs'
import React, { useState, useEffect, useRef, useMemo, useDebugValue } from 'react'
import { Formik, Form } from "formik"
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { get, salvar } from '../../../../util/AcessoApi'
import { Link, useParams } from "react-router-dom"
import { buscarCep, mensagens } from '../../../../util/Utilitarios'
import Yup from '../../../../util/Validacoes'
import '../../../../assets/css/ios-switch/clean-switch.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Atalho from '../../../../util/Atalho'
import { HeaderCadastro } from '../../../../components/Headers'
import { InputBanco } from '../../../../components/InputsAutoComplete'
import moment from 'moment'

const CadastroCheque = (props) => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [devolvido, setDevolvido] = useState(false)
    const [validacao] = useState(Yup.lazy(({ devolvido }) => Yup.object({
        banco: Yup.string().required().nullable(),
        agencia: Yup.string().required().nullable(),
        agencia_digito: Yup.string().required().nullable(),
        conta: Yup.string().required().nullable(),
        conta_digito: Yup.string().required().nullable(),
        numero: Yup.string().required().nullable(),
        valor: Yup.string().required().nullable(),
        nominal: Yup.string().nullable(),
        devolvido: Yup.boolean().nullable(),
        data_devolucao: Yup.string().nullable().when('devolvido', {
            is: (devolvido) => devolvido,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable()
        }),
        motivo_devolucao: Yup.string().nullable().when('devolvido', {
            is: (devolvido) => devolvido,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable()
        }),
    })))


    const Submit = (values, propsFormik) => {

        setSalvando(true)

        salvar(
            values,
            '/financeiro/cheque',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    // manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                }
                else
                    props.history.push('/financeiro/cheque/consulta')

                setSalvando(false)
            }, (err) => {
                console.log('err: ', err);
                mostrarToast('erro', mensagens.erro)
                setSalvando(false)
            })
    }


    useEffect(_ => {
        if (!props.modal && id) {

            Promise.resolve(get(`/financeiro/cheque/${id}`)).then(obj => {
                obj.devolvido = moment(obj.data_devolucao).isValid ? true : false
                setDevolvido(obj.devolvido)
                setValoresIniciais(obj)
            })
        } else {
            setValoresIniciais({
                banco: '',
                agencia: '',
                agencia_digito: '',
                conta: '',
                conta_digito: '',
                numero: '',
                valor: '',
                nominal: '',
                data_emissao: '',
                data_predatado: '',
                data_compensado: '',
                data_devolucao: '',
                devolvido: false

            })
        }
    }, [id])

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Financeiro</span>,
        () => <Link className='link-active' to={'/financeiro/cheque/consulta'}>Cheque</Link>,
    ]



    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>
                {!props.modal && <HeaderCadastro titulo={"Cadastro Cheque"} link={links} />}
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={'card-body'}>
                        <div className='row'>
                            <InputBanco label='Banco' name='banco' placeholder="Banco" propsFormik={propsFormik}
                                classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} tamanho={!props.modal && 'col-lg-3'} />

                            <InputPadrao label='Agência' name='agencia' placeholder="Agência" propsFormik={propsFormik}
                                maxLength={15}
                                classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} tamanho={!props.modal && 'col-lg-2'} />

                            <InputPadrao label='Ag. Digito' placeholder="Ag. Digito" name='agencia_digito' propsFormik={propsFormik} classDiv={!props.modal && 'col-lg-1' || 'col-lg-12'} max={100}
                                classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} tamanho={!props.modal && 'col-lg-1'}
                                maxLength={3}
                            />

                            <InputPadrao label='Conta' name='conta' placeholder="Conta" propsFormik={propsFormik} classDiv={!props.modal && 'col-lg-2' || 'col-lg-12'}
                                classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} tamanho={!props.modal && 'col-lg-2'} 
                                maxLength={30}
                                />

                            <InputPadrao label='Conta. Digito' placeholder="Conta. Digito" name='conta_digito' propsFormik={propsFormik} classDiv={!props.modal && 'col-lg-1' || 'col-lg-12'} max={100}
                                classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} tamanho={!props.modal && 'col-lg-1'} 
                                maxLength={3}
                                />


                            <InputPadrao label='Número' placeholder="Número" name='numero' propsFormik={propsFormik} classDiv={!props.modal && 'col-lg-1' || 'col-lg-12'} max={100}
                                classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} tamanho={!props.modal && 'col-lg-1'}
                                maxLength={30}
                                 />

                            <InputValor label='Valor' placeholder='R$ 0,00' name='valor' propsFormik={propsFormik} classDiv={!props.modal && 'col-lg-2' || 'col-lg-12'}
                                classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} tamanho={!props.modal && 'col-lg-2'} />

                            <InputPadrao label='Nominal' placeholder="Nominal" name='nominal' propsFormik={propsFormik} maxLength={70}
                                classeTooltip={props.modal && 'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-3'} />

                            <InputDatePicker label='Data Emissão' placeholder='Data Emissão' name='data_emissao' propsFormik={propsFormik} tamanho={!props.modal && 'col-lg-2'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_emissao", value)
                                }} />

                            <InputDatePicker label='Data Predatado' placeholder='Data Predatado' name='data_predatado' propsFormik={propsFormik} tamanho={!props.modal && 'col-lg-2'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_predatado", value)
                                }} />

                            <InputDatePicker label='Data Compensado' placeholder='Data Compensado' name='data_compensado' propsFormik={propsFormik} tamanho={!props.modal && 'col-lg-2'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_compensado", value)
                                }} />
                            <InputSwitch label='Devolvido ?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch ' name='devolvido' size="md" tamanho={'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'}
                                onChange={value => { setDevolvido(!devolvido); propsFormik.setFieldValue('devolvido', value); propsFormik.setFieldValue('data_devolucao', ''); propsFormik.setFieldValue('motivo_devolucao', '') }}
                            />

                            <InputDatePicker label='Data Devolução' placeholder='Data Devolução' name='data_devolucao' propsFormik={propsFormik} tamanho={!props.modal && 'col-lg-2'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_devolucao", value)
                                }}
                                disabled={!devolvido} obrigatorio={devolvido}
                            />


                            <TextArea name='motivo_devolucao' label='Motivo Devolução' placeholder='Motivo Devolução' propsFormik={propsFormik} disabled={!devolvido} obrigatorio={devolvido} />


                        </div>
                    </div>
                </div>
                {!props.modal && <div className='separator mt-40 mb-5'></div>}
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)}
        </Formik>
    );
}

export default CadastroCheque 
