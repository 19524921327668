import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaDateTime }from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { get, salvar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao, InputDatePicker, InputTimePicker, InputSummernote, InputDateTimePicker } from '../../../../components/Inputs'
import InputArquivoS3 from '../../../../components/InputArquivoS3'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import { MODULO } from '../../../../util/Enums'
import moment from 'moment'
import Atalho from '../../../../util/Atalho'
import { ModalCadastro } from '../../../../components/Modais'

const CadastroRegistroAtividade = (props) => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [dataArquivos, setDataArquivos] = useState(null)
    const [limparArquivos, setLimparArquivos] = useState(false)
    const [validacao] = useState(Yup.object({
        data: Yup.string().required()
        .test('Data', 'Data ou Hora inválida!', function (value) { return validaDateTime(this.parent.data) }),
        conteudo: Yup.string()
            .required()
    }))

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        values.arquivos = dataArquivos
        salvar(
            values,
            '/administracao/registroatividades',
            _ => {
                mostrarToast('sucesso', mensagens.sucesso)
                props.funcaoAposSalvar && props.funcaoAposSalvar()
                if (salvarENovo)
                    try {
                        propsFormik.resetForm({})
                        setSalvando(false)
                        setSalvarENovo(false)
                    } catch (e) { console.log('errro', e) }
                else if (props.setAbrirModal)
                    props.setAbrirModal(false)
                else
                    props.history.push('/administracao/registroatividades/consulta')
            },
            _ => { setSalvando(false) })
    }

    useEffect(() => {
        if (id || props.id) {
            const url = `/administracao/registroatividades/${id || props.id}`;
            Promise.resolve(get(url)).then(val => {
                val.data += ' ' + val.hora 
                setDataArquivos(val.arquivos)
                setValoresIniciais(val)
            })
        } else {
            setDataArquivos([])
            setValoresIniciais({
                data: moment().format('DD/MM/YYYY HH:mm'),
                hora: moment().format('HH:mm'),
                conteudo: ''
            })
        }
    }, [id, props.id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => { Submit(values, propsFormik) }}>
            {propsFormik => (<Form>
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={props.modal || 'card-body'}>
                        <div className='row'>
                            <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' obrigatorio={false} />
                            <InputDateTimePicker autoFocus classeTooltip={props.modal && 'tooltip-input-modal'} tamanho={!props.modal && "col-lg-4 col-md-7"}
                                label="Data Inicial" name="data" type="text" obrigatorio="true" setarValor={value => {
                                    propsFormik.setFieldValue("data", value)
                                }} salvarENovo />
                            <InputSummernote label="Conteúdo" placeholder='Conteúdo' obrigatorio={true} name="conteudo" propsFormik={propsFormik} tamanho={"col-lg-12"} config={{ heightMin: 500 }} {...props} />
                            {dataArquivos != null ?
                                <InputArquivoS3
                                    label='Arquivos'
                                    name='arquivos'
                                    placeholder='Arquivos'
                                    tamanho='col-lg-12'
                                    diretorioS3='resgistroatividades/DDMMYYYY/'
                                    modulo={MODULO["/administracao/registroatividades"]}
                                    /*tiposAceitos={['image/png', '.pdf', 'audio/*']} */
                                    tamanhoMinimo={0}
                                    tamanhoMaximoEmMB={300}
                                    /*qtdMaximaArquivos={2} */
                                    setDataArquivos={setDataArquivos}
                                    dataArquivos={dataArquivos != null ? dataArquivos : []}
                                    setIsSubmit={setSalvando}
                                    limparCampos={limparArquivos}
                                /> : <></>}
                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />

                        </div>
                    </div>
                </div>
                {!props.modal ? <div className='separator mt-40 mb-5'></div> : <></>}
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {(!id && !props.id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)
            }
        </Formik >
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Administrativo</Link>,
        () => <Link className="link-active" to={'/administracao/registroatividades/consulta'}>Registro de Atividades</Link>,
    ]
    return (<>
        {!props.modal && <HeaderCadastro titulo={"Cadastro de Registro de Atividades"} link={links} />}
        <CadastroRegistroAtividade {...props} />
    </>
    )
}

const CadastroModalRegistroAtividade = (props) => {
    return (<>
        <ModalCadastro titulo={'Cadastro Registro de Atividades'} show={props.abrirModal} setShowModal={props.setAbrirModal}>
            <CadastroRegistroAtividade {...props} />
        </ModalCadastro>
    </>
    )
}


export { CadastroRegistroAtividade, CadastroModalRegistroAtividade }
export default Cadastro
