import React, { useState, useEffect } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'

const ConsultaCirurgiao = (props) => {
    const [data, setData] = useState([])
    const [filtros, setFiltros] = useState([])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-cirurgiao',
                columns: [
                    {
                        Header: 'Id',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Apelido',
                        accessor: 'apelido',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Conselho Profissional',
                        accessor: 'conselho_profissional',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'cro',
                            idColuna: 'cro_id'
                        }
                    },
                    {
                        Header: 'N° Conselho',
                        accessor: 'crm',
                        ordenamento: {
                            tipo: 'texto'
                        }

                    },
                    {
                        Header: 'Nome Cirurgião',
                        accessor: 'nome_cirurgiao',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Telefone(s)',
                        accessor: 'telefones',
                        ordenamento: {
                            tipo: 'texto'
                        },

                    },
                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro_formatada',
                        ordenamento: {
                            tipo: 'data'
                        },
                        // filtro:{
                        //     tipo: 'data',
                        //     idColuna: 'cirurgiao.data_registro'
                        // }
                    }
                ],
                
            },
            
        ],
        []
    )

    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/administracao/cirurgiao' modulo={props.modulo} titulo={'Consulta Cirurgião'}
                    tituloLista={'Lista Cirurgião'}  ImpressaoOff = {true}
                    urlCadastro='/administracao/cirurgiao/cadastro' icone={'icon-File-ClipboardFileText'} caminhoConsultaFiltros='/administracao/cirurgiaoPaginacao' />
                    
            </div>
        </>
    )
}

export default ConsultaCirurgiao