import React, { useState, useEffect, useRef } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { HeaderCadastro } from '../../../../components/Headers'
import { Alterar, Excluir, Visualizar, Validar, Reenvio } from '../../../../components/Acoes'
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { get, excluir, consultar, validaCadastro, reenvioDados } from '../../../../util/AcessoApi'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens, useWindowSize, useInterval } from '../../../../util/Utilitarios.js'

const Consulta = props => {
    const [data, setData] = useState([])
    const [pageIndex, setPageIndex] = useState()
    const target = useRef(null)

    const columns = React.useMemo(
        () => [
            {
            id: 'consulta-procedimento',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id_procedimento',
                    ajuste: false,
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                }, {
                    Header: 'Descrição',
                    accessor: 'procedimento',
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, {
                    Header: 'Tabela',
                    accessor: 'tabela',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'tabela',
                        idColuna: 'tabela_procedimento.id'
                    }
                }, {
                    Header: 'Código Proc.',
                    accessor: 'codigo',
                    filtro: {
                        tipo: 'codigo_procedimento',
                        idColuna: 'codigo'
                    },
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, {
                    Header: 'Porte',
                    accessor: 'porte',

                    ordenamento: {
                        tipo: 'texto'
                    },
                }, {
                    Header: 'Valor Porte',
                    accessor: 'valor_porte',
                    ordenamento: {
                        tipo: 'numero'
                    }
                }, {
                    Header: 'Porte Anestésico',
                    accessor: 'porte_anestesico',
                    filtro: {
                        tipo: 'porte_anestesico',
                        idColuna: 'porte_anestesico'
                    },
                    ordenamento: {
                        tipo: 'numero'
                    }
                },{
                    Header: 'Data Atualização',
                    accessor: 'data_atualizacao_formatada',
                    ordenamento: {
                        tipo: 'data'
                    },
                },{
                    Header: 'Data de Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    }
                }
            ]
        }], []
    )

    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/faturamento/procedimento',  propsAcao.row.id_procedimento,
                    () => {
                        // getApi(setData, '/faturamento/procedimento')
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const acoesObj = [

        (propsAcao) => <Visualizar to={'/faturamento/procedimento/visualizacao/' + propsAcao.row.id_procedimento} />,
        (propsAcao) => <Alterar to={'/faturamento/procedimento/alteracao/' +  propsAcao.row.id_procedimento} />,
        (propsAcao) => <Excluir to={'/faturamento/procedimento/consulta/'} action={() => exclusao(propsAcao)} />,
       
    ]


    return (
        <>
            <div>

                <DataGridAlternativa target={target} data={data} columns={columns} setData={setData} acoes={true} acoesObj={acoesObj} caminhoBase='/faturamento/procedimento' modulo={props.modulo} titulo={'Consulta Procedimento'}
                    tituloLista={'Lista Procedimento'} ImpressaoOff = {true}
                    urlCadastro='/faturamento/procedimento/cadastro' icone={'icon-File-ClipboardFileText'} caminhoConsultaFiltros='/faturamento/procedimentoPaginacao' />
            </div>
        </>
    )
}

export default Consulta