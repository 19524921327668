import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { get } from '../../../../util/AcessoApi'
import { ordenarArrayObj } from '../../../../util/FuncoesComuns'
import { visualizar } from '../../../../util/AcessoApi'

const VisualizacaoUsuarioApp = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})


    useEffect(() => {
        if (id) {
            visualizar(`/controleAcesso/usuarioApp`, id, obj => {
                setDados(obj)
            })
        }
    }, [id])
    const data = dados.id ? {
        titulo: 'Visualização de Usuário App',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Código',
                        descricao: dados.id,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Login',
                        descricao: dados.login,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Sócio',
                        descricao: dados.socio_nome,
                        tamanho: 'col-lg-6'
                    },

                    {
                        titulo: 'Data Registro',
                        descricao: (dados.data_registro),
                        tamanho: 'col-lg-6'
                    }
                ]
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Módulos Permitidos',
                thead:
                    <tr>
                        <td className='coluna-12'>Módulo</td>
                    </tr>,
                tbody: dados.modulos[0] == undefined || dados.modulos[0].length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.modulos, 'id').map(modulos =>
                        <tr>
                            <td>{modulos.descricao}</td>
                        </tr>)
            }
        ]
    } : { titulo: 'Visualização de Usuário App' }

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoUsuarioApp