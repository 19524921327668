import React, { useState, useEffect, useRef } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'

const Consulta = (props) => {
    const [data, setData] = useState([])
    const [pageIndex, setPageIndex] = useState()
    const [qtdRegistros, setQtdRegistros] = useState()

    // useEffect(() => {
    //     consultar(`/controleAcesso/auditoriaApp/${pageIndex}`, dados => {setData(dados.sort((a, b) => b.id - a.id))})
    // }, [pageIndex])


    // useEffect(()=>{
    //     setQtdRegistros(data && data[0] && data[0].qtdRegistros)
    // },[data])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-auditoria',
                qtdRegistros: qtdRegistros,
                paginacaoManual: true,
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Usuário',
                        accessor: 'nome',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro:{
                            tipo:'usuario_app',
                            idColuna:'usuario_id'
                        },
                        filtroSomenteModal: true
                    },
                    {
                        Header: 'Ação',
                        accessor: 'acao',
                        filtro:{
                            combo: 'combo-alternativa',
                            tipo:'acoes',
                            idColuna:'acao_id'
                        },
                        ordenamento: {
                            tipo: 'combo',
                        },
                    },
                    {
                        Header: 'Módulo',
                        accessor: 'modulo',
                        ordenamento: {
                            tipo: 'combo',
                        },
                        visivel: false,
                        filtro: {
                            tipo: 'combo'
                        },
                        filtroSomenteModal: true
                    },
                    {
                        Header: 'Data  Ação',
                        accessor: 'data_acao',
                        ordenamento: {
                            tipo: 'data',
                        },
                        classeThead: 'teste semAcoes',
                        classeTd: 'teste semAcoes',
                        filtro: {
                            tipo: 'combo'
                        },
                        filtroSomenteModal: true

                    }
                ]
            }], []
    )

    const semAcoesObj = []

    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} setData={setData} acoesObj={semAcoesObj} acoes={false} caminhoBase='/controleAcesso/auditoriaApp' ImpressaoOff = {true}
                    tituloLista={'Lista Auditoria'} caminhoConsultaFiltros='/controleAcesso/auditoriaApp'
                    modulo={props.modulo} titulo={'Consulta Auditoria App'} icone={'icon-Business-ManWoman'} />
            </div>
        </>
    )
}

export default Consulta