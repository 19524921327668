import axios from 'axios'
import { URL_BACKEND, getUsuario } from './Configuracoes'
import { mostrarToast } from '../components/Toasts'
import { mensagens } from '../util/Utilitarios'
import { getConfig, logout, reload, minutosEmInatividade } from '../util/Configuracoes'
import { ACOES, MODULO } from './Enums'
import ls from 'local-storage'
import moment from "moment"

const obterInstancia = () => {
    if (window.location.href.indexOf('recuperarsenha') < 0 && window.location.href.indexOf('usuarioexterno') < 0 && minutosEmInatividade() > 60) {
        logout()
        reload()
        return;
    }
    let user = getUsuario();
    let instance = axios.create({
        headers: {
            'Authorization': user && user.token ? getUsuario().token : ''
        }
    })

    return instance
}

const obterInstanciaExterno = () => {
    let instance = axios.create({
        headers: {
            'Access-Control-Allow-Origin': 'http://localhost:3000',
        }
    })

    return instance
}

const atulizarWs = (data, url, modulo) => {
    const user = getUsuario()

    data.auditoria = {
        usuario: user && user.id,
        modulo: modulo,
        controle: data.id,
        acao: ACOES.IMPORTAR
    }

    put(url, data, true)
}

const salvar = (data, url, sucessoFunction, erroFunction, naoMostrarErro) => {
    setarUltimaHoraLogin();
    const user = getUsuario()

    data.auditoria = {
        usuario: user && user.id,
        modulo: MODULO[url.toLowerCase()],
        controle: data.id
    }

    if (data.id) {
        data.auditoria.acao = ACOES.ALTERAR
        Promise.resolve(
            put(url, data, true, naoMostrarErro))
            .then(data => sucessoFunction && sucessoFunction(data))
            .catch(erro => erroFunction && erroFunction(erro))
    } else {
        data.auditoria.acao = ACOES.INSERIR
        Promise.resolve(
            post(url, data, true, naoMostrarErro))
            .then(data => sucessoFunction && sucessoFunction(data))
            .catch(erro => { erroFunction && erroFunction(erro); })
    }
}

const acoesAgenda = (data, url, sucessoFunction, erroFunction, naoMostrarErro, acao, modulo) => {
    const user = getUsuario()

    data.auditoria = {
        usuario: user && user.id,
        modulo: modulo && modulo || MODULO[url.toLowerCase()],
        controle: data.id
    }

    switch (acao) {
        case ACOES.CANCELAR_AGENDA:
            data.auditoria.acao = ACOES.CANCELAR_AGENDA
            Promise.resolve(
                put(url, data, true, naoMostrarErro))
                .then(data => sucessoFunction && sucessoFunction(data))
                .catch(erro => erroFunction && erroFunction(erro))
            break
        case ACOES.PREFERENCIAL:
            data.auditoria.acao = ACOES.PREFERENCIAL
            Promise.resolve(
                post(url, data, true, naoMostrarErro))
                .then(data => sucessoFunction && sucessoFunction(data))
                .catch(erro => erroFunction && erroFunction(erro))
            break
        case ACOES.OBSERVACAO_AGENDA:
            data.auditoria.acao = ACOES.OBSERVACAO_AGENDA
            Promise.resolve(
                put(url, data, true, naoMostrarErro))
                .then(data => sucessoFunction && sucessoFunction(data))
                .catch(erro => erroFunction && erroFunction(erro))
            break
        case ACOES.FINALIZAR_AGENDA:
            data.auditoria.acao = ACOES.FINALIZAR_AGENDA
            Promise.resolve(
                put(url, data, true, naoMostrarErro))
                .then(data => sucessoFunction && sucessoFunction(data))
                .catch(erro => erroFunction && erroFunction(erro))
            break
        case ACOES.CONFIRMAR_AGENDA:
            data.auditoria.acao = ACOES.CONFIRMAR_AGENDA
            Promise.resolve(
                put(url, data, true, naoMostrarErro))
                .then(data => sucessoFunction && sucessoFunction(data))
                .catch(erro => erroFunction && erroFunction(erro))
            break
        case ACOES.ALTERAR_STATUS_AGENDA:
            data.auditoria.acao = ACOES.ALTERAR_STATUS_AGENDA
            Promise.resolve(
                put(url, data, true, naoMostrarErro))
                .then(data => sucessoFunction && sucessoFunction(data))
                .catch(erro => erroFunction && erroFunction(erro))
            break
    }
}

const setarUltimaHoraLogin = () => {
    try {
        ls.set('ultimo_hora_login', moment())
    } catch (error) {
        console.log('error', error)
    }
}

const postComAcao = (acao, modulo, data, url, sucessoFunction, erroFunction, naoMostrarErro) => {
    setarUltimaHoraLogin();

    const user = getUsuario()

    data.auditoria = {
        usuario: user && user.id,
        modulo: modulo || MODULO[url.toLowerCase()],
        controle: data.id || data.controle
    }
    data.auditoria.acao = acao
    Promise.resolve(
        post(url, data, true, naoMostrarErro))
        .then(data => sucessoFunction && sucessoFunction(data))
        .catch(erro => erroFunction && erroFunction(erro))
}

const putComAcao = (acao, modulo, data, url, sucessoFunction, erroFunction, naoMostrarErro) => {
    setarUltimaHoraLogin();

    const user = getUsuario()

    data.auditoria = {
        usuario: user && user.id,
        modulo: modulo || MODULO[url.toLowerCase()],
        controle: data.id || data.controle
    }
    data.auditoria.acao = acao
    Promise.resolve(
        put(url, data, true, naoMostrarErro))
        .then(data => sucessoFunction && sucessoFunction(data))
        .catch(erro => erroFunction && erroFunction(erro))
}

const excluir = async (url, id, sucessoFunction, erroFunction, descricao) => {
    setarUltimaHoraLogin();
    const user = getUsuario()
    const data = {
        auditoria: {
            usuario: user && user.id,
            modulo: MODULO[url.toLowerCase()],
            acao: ACOES.EXCLUIR,
            controle: id,
            descricao: descricao || undefined
        },
        ...id
    }

    let instance = obterInstancia();
    await instance.delete(URL_BACKEND + url + "/" + id, { params: { ...data, ...id } }, getConfig())
        .then(data => sucessoFunction && sucessoFunction(data))
        .catch(erro => erroFunction && erroFunction(erro))
}

const validaCadastro = async (url, finalUrl, dados, sucessoFunction, erroFunction) => {
    const user = getUsuario()
    dados.auditoria = {
        usuario: user && user.id,
        modulo: MODULO[url.toLowerCase()],
        acao: ACOES.VALIDAR,
        controle: dados.id
    }

    Promise.resolve(
        put(url + finalUrl, dados, true))
        .then(data => { sucessoFunction && sucessoFunction(data) })
        .catch(erro => { erroFunction && erroFunction(erro) })

}

const reenvioDados = async (url, finalUrl, dados, sucessoFunction, erroFunction) => {
    const user = getUsuario()
    dados.auditoria = {
        usuario: user && user.id,
        modulo: MODULO[url.toLowerCase()],
        acao: ACOES.REENVIAR,
        controle: dados.id
    }

    Promise.resolve(
        put(finalUrl, dados, true))
        .then(data => sucessoFunction && sucessoFunction(data))
        .catch(erro => erroFunction && erroFunction(erro))

}


const visualizar = (url, id, sucess, error) => {
    const user = getUsuario()
    const data = {
        id,
        auditoria: {
            usuario: user && user.id,
            modulo: MODULO[url.toLowerCase()],
            acao: ACOES.VISUALIZAR,
            controle: id
        }
    }

    get(url + '/' + id, data, true).then(data => sucess && sucess(data)).catch(erro => error && error(erro))
}

const consultar = (url, sucessoFunction, erroFunction, parametros) => {
    const user = getUsuario()
    const data = {
        auditoria: {
            usuario: user && user.id,
            modulo: MODULO[url.toLowerCase()],
            acao: ACOES.CONSULTAR,
            tipo: user && user.tipo,
            pagina: parametros?.pagina
        },
        ...parametros
    }

    get(url, data, true)
        .then(data => sucessoFunction && sucessoFunction(data, parametros))
        .catch(erro => erroFunction && erroFunction(erro))
}

const listar = (url, sucessoFunction, erroFunction, parametros) => {
    const user = getUsuario()
    const data = {
        auditoria: {
            usuario: user && user.id,
            modulo: MODULO[url.toLowerCase()],
            acao: ACOES.LISTAR,
            tipo: user && user.tipo
        },
        ...parametros
    }

    get(url, data, true)
        .then(data => sucessoFunction && sucessoFunction(data, parametros))
        .catch(erro => erroFunction && erroFunction(erro))
}

const consultarRelatorio = (url, sucessoFunction, erroFunction, parametros) => {
    const user = getUsuario()
    const data = {
        auditoria: {
            usuario: user && user.id,
            modulo: MODULO[url.toLowerCase()],
            acao: ACOES.EMITIR,
            tipo: user && user.tipo
        },
        ...parametros
    }
    get(url, data, true)
        .then(data => sucessoFunction && sucessoFunction(data, parametros))
        .catch(erro => erroFunction && erroFunction(erro))
}

const consultarFiltros = (url, sucessoFunction, erroFunction, filtros) => {
    const user = getUsuario()
    const data = {
        colunasFiltros: {
            filtros
        }
        // auditoria: {
        //     usuario: user && user.id,
        //     modulo: MODULO[url.toLowerCase()],
        //     acao: ACOES.CONSULTAR,
        //     tipo: user && user.tipo
        // },
        // ...parametros
    }

    get(url, data, true)
        .then(data => sucessoFunction && sucessoFunction(data))
        .catch(erro => erroFunction && erroFunction(erro))
}




const pesquisarDataGrid = (url, sucessoFunction, erroFunction, pesquisa, ordenamento, filtros, ordenamentoModal, limit) => {

    let idx = url.split('/')?.length
    const data = {
        parametroPesquisa: { pesquisa },
        parametroOrdenamento: { ordenamento },
        parametroFiltro: { filtros, limit: limit },
        parametrosOrdenamentoModal: { ordenamentoModal },
        pagina: parseInt(url.split('/')[idx - 1])
    }

    consultar(url, dataNew => sucessoFunction && sucessoFunction(dataNew), erro => erroFunction && erroFunction(erro), data)
}

const listarDataGrid = (url, sucessoFunction, erroFunction, pesquisa, ordenamento, filtros, ordenamentoModal, limit) => {
    console.log('url: ', url);


    const data = {
        parametroPesquisa: { pesquisa },
        parametroOrdenamento: { ordenamento },
        parametroFiltro: { filtros, limit: limit },
        parametrosOrdenamentoModal: { ordenamentoModal }
    }

    listar(url, dataNew => sucessoFunction && sucessoFunction(dataNew), erro => erroFunction && erroFunction(erro), data)
}

const post = async (url, body, autenticado, naoMostrarErro, externo) => {
    setarUltimaHoraLogin();
    naoMostrarErro = naoMostrarErro === true ? true : false
    let instance = externo ? obterInstanciaExterno() : obterInstancia();
    let login = url?.includes('/controleAcesso/usuario/signin');
    if (login) {
        const proxyUrl = 'https://api.ipify.org?format=json';
        await fetch(proxyUrl)
            .then(response => response.json())
            .then(data => {
                body.ip_usuario = data?.ip
            })
            .catch(error => { });
    }
    // if (ip_usuario)
    //     body.ip_usuario = ip_usuario
    return await instance.post(URL_BACKEND + url, body, autenticado && getConfig())
        .then(res => {
            return res.data
        })
        .catch(e => {
            try {
                JSON.parse(e.response.data.erros).forEach((item) => naoMostrarErro === false && mostrarToast('erro', item))
            } catch (e2) {
                if (e.response && e.response.data && e.response.data.erros)
                    e.response.data.erros.forEach((item) => naoMostrarErro === false && mostrarToast('erro', item))
                else
                    naoMostrarErro === false && mostrarToast('erro', mensagens.erro)

                throw e
            }
            throw e
        })
}



const put = async (url, data, autenticacao, naoMostrarErro) => {
    setarUltimaHoraLogin();
    naoMostrarErro = naoMostrarErro === true ? true : false
    let instance = obterInstancia();
    return await instance.put(URL_BACKEND + url, data, autenticacao && getConfig())
        .then(res => res.data)
        .catch(e => {
            try {
                JSON.parse(e.response.data.erros).forEach((item) => naoMostrarErro === false && mostrarToast('erro', item))
            } catch (e2) {
                if (e.response && e.response.data && e.response.data.erros)
                    e.response.data.erros.forEach((item) => naoMostrarErro === false && mostrarToast('erro', item))
                else
                    naoMostrarErro === false && mostrarToast('erro', mensagens.erro)

                throw e
            }
            throw e
        })
}

const get = (url, data, autenticacao) => {

    let instance = obterInstancia()

    return instance.get(URL_BACKEND + url, data && { params: data }, autenticacao && getConfig())
        .then(res => {
            return res.data;
        }).catch(e => {
            throw e
        })
    // .catch(errorFunction ? errorFunction() : e => {
    //     if (e.response.status === 401) {
    //         logout()
    //         reload()
    //     }
    //     if (e.response && e.response.data && e.response.data.erros || e.response.status === 401) {
    //         e.response.data.erros.forEach((item) => mostrarToast('erro', item))

    //     }
    //     else
    //         mostrarToast('erro', mensagens.erro)
    //     return {}
    // })
}

const getComAuditoria = (url, data, autenticacao) => {

    const user = getUsuario()
    let instance = obterInstancia()

    if (data != undefined) {
        data.auditoria =
        {
            usuario: user && user.id,
            modulo: data.modulo,
            controle: data.id
        }
    }

    return instance.get(URL_BACKEND + url, data && { params: data }, autenticacao && getConfig())
        .then(res => {
            return res.data;
        }).catch(e => {
            console.log('', URL_BACKEND + url + e)
        })

}


const validar = async (url, data, callbackSuccess, callbackError) => {
    let instance = obterInstancia();

    await instance.get(URL_BACKEND + url, data && { params: data }, getConfig())
        .then(e => callbackSuccess && callbackSuccess(e)).catch(e => { console.log('ERRO', e); callbackError && callbackError(e) })
}
const validarComPost = async (url, data, callbackSuccess, callbackError) => {
    let instance = obterInstancia();

    await instance.post(URL_BACKEND + url, data, getConfig())
        .then(e => callbackSuccess && callbackSuccess(e)).catch(e => callbackError && callbackError(e))
}

const getApiExterna = (url, callbackSuccess, callbackError) => {
    const instance = obterInstanciaExterno()

    return axios.get(url)
        .then(e => callbackSuccess && callbackSuccess(e))
        .catch(e => callbackError && callbackError(e))
}

const postApiExterna = (url, data, callbackSuccess, callbackError) => {
    const instance = obterInstanciaExterno()

    return instance.post(url, data, instance)
        .then(e => callbackSuccess && callbackSuccess(e))
        .catch(e => callbackError && callbackError(e))
}

export {
    post, put, get, getApiExterna, salvar, putComAcao, excluir, visualizar, consultar, validar, obterInstancia, validaCadastro, postComAcao, getComAuditoria,
    postApiExterna, reenvioDados, atulizarWs, consultarFiltros, pesquisarDataGrid, acoesAgenda, validarComPost, consultarRelatorio, listarDataGrid
}