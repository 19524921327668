import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FieldArray } from "formik";
import Yup, { validaData, periodoData, validaHora } from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { ModalDuasOpcoes, ModalEscolha } from '../../../../components/Modais';
import { get, salvar, validar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao, InputDatePicker } from '../../../../components/Inputs'
import { InputFiltroEstabelecimentoSaude, InputEstabelecimentoSaude, InputStatusContratoParticular, InputSimNao } from '../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { CadastroAcomodacao, CadastroTabela, CadastroProcedimento, CadastroPorteAnestesico, CadastroPorteAcomodacao, CadastroProcedimentoAcomodacao, CadastroPacoteProcedimento, CadastroOdontologia } from './Tab'
import moment from 'moment';

const CadastroContratoParticular = (props) => {
    let { id } = useParams()

    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [showModalValidacaoTabela, setShowModalValidacaoTabela] = useState(false)
    const [mensagemModalTabela, setMensagemModalTabela] = useState('')
    const [mensagemModal, setMensagemModal] = useState('')

    let validacaoInicial = Yup.lazy(({ pacote_procedimentos, descricao_pacote, odontologico, tabelas, acomodacoes, procedimentos }) => Yup.object({
        estabelecimento_saude: Yup.object().nullable().required(),
        status: Yup.string().nullable().required(),
        // numero_contrato: Yup.string().nullable().required(),
        vigencia_inicial: Yup.string().nullable().required()
            .test('Vigência Inicial', 'Data inválida!', function (value) { return validaData(this.parent.vigencia_inicial) })
            .test('Vigência Inicial', 'Vigência Inicial deve ser anterior à Vigência Final.', function (value) { return periodoData(value, this.parent.vigencia_final) }),
        vigencia_final: Yup.string().nullable().required()
            .test('Vigência Final', 'Data inválida!', function (value) { return validaData(this.parent.vigencia_final) })
            .test('Vigência Final', 'Vigência Inicial deve ser anterior à Vigência Final.', function (value) { return periodoData(this.parent.vigencia_inicial, value) }),
        odontologica: Yup.string().nullable().required(),
        descricao_pacote: Yup.string().nullable()
            .test('Descricao Pacote', 'Campo Obrigatório!', function (value) {
                return descricao_pacote || (pacote_procedimentos && pacote_procedimentos.length < 2)
            })
    })
        .shape({
            acomodacoes: Yup.array()
                .of(
                    Yup.object().shape({
                        acomodacao: Yup.object().nullable()
                            .test('Tabela', 'Duplicidade de Acomodação', function (value) {
                                const filterAcm = acomodacoes.filter(x => value && x.acomodacao && x.acomodacao.value === value.value);
                                return filterAcm.length < 2
                            }),
                        // base_calculo: Yup.object()
                        // .test('Base Cálculo', 'Campo Obrigatório!', function (value) {
                        //     const index = this.path.replace(/[^0-9]+/g, '');
                        //     const valor = tabelas[index].tabela
                        //     return index === tabelas.length - 1 || (value && valor)
                        // }),
                    })),
            procedimentos: Yup.array()
                .of(
                    Yup.object().shape({
                        procedimento: Yup.object().nullable()
                            .test('Código', 'Campo Obrigatório', function (value) {
                                const index = this.path.replace(/[^0-9]+/g, '');
                                const valor = procedimentos[index].procedimento
                                return !(procedimentos.length - 1 != index && !valor)
                            }),
                        descricao: Yup.object().nullable()
                            .test('Descrição', 'Campo Obrigatório', function (value) {
                                const index = this.path.replace(/[^0-9]+/g, '');
                                const valor = procedimentos[index].descricao
                                return !(procedimentos.length - 1 != index && !valor)
                            }),
                        porte: Yup.object().nullable()
                            .test('Porte', 'Campo Obrigatório', function (value) {
                                const index = this.path.replace(/[^0-9]+/g, '');
                                const valor = procedimentos[index].porte
                                return !(procedimentos.length - 1 != index && !valor)
                            })
                    })),
            tabelas: Yup.array()
                .test('Tabelas', 'Insira ao menos uma tabela', function (value) {
                    return tabelas && tabelas !== undefined && tabelas.filter(x => x.tabela).length > 0
                })
                .of(
                    Yup.object().shape({
                        tabela: Yup.object().nullable()
                            .test('Tabela', 'Duplicidade de tabela', function (value) {
                                const filterTabela = tabelas.filter(x => value && x.tabela && x.tabela.value === value.value);
                                return filterTabela.length < 2
                            })
                    })),
            pacote_procedimentos: Yup.array()
                .test('Procedimentos', 'Insira ao menos 1 Procedimento', function (value) {
                    return !descricao_pacote || (pacote_procedimentos && pacote_procedimentos[0].procedimento !== undefined)
                }),
            odontologico: Yup.array()
                .of(
                    Yup.object().shape({
                        hora_inicial: Yup.object().nullable()
                            .test('Hora Inicial', 'Campo Obrigatório!', function (value) {
                                const index = this.path.replace(/[^0-9]+/g, '');
                                const valor = odontologico[index].hora_inicial
                                return !(odontologico.length - 1 != index && !valor)
                            })
                            .test('Hora Inicial', 'Hora inválida!', function (value) {
                                const index = this.path.replace(/[^0-9]+/g, '');
                                const valor = odontologico[index].hora_inicial
                                const novoValor = valor?.toString().split(':')[1];
                                return validaHora('01:' + novoValor)
                            })
                            .test('Hora Inicial', 'Horário com interposição!', function (value) {
                                const index = this.path.replace(/[^0-9]+/g, '');
                                const valor = odontologico[index].hora_inicial
                                const tipoAtend = odontologico[index].local_atendimento
                                let retorno = []

                                if (tipoAtend && tipoAtend.value) {
                                    retorno = odontologico.filter((item, idxOd) => {
                                        const atual = moment(valor, 'hh:mm')
                                        const h_in = moment(item.hora_inicial, 'hh:mm')
                                        const h_fi = moment(item.hora_final, 'hh:mm')

                                        if (item.local_atendimento && item.local_atendimento.value && item.local_atendimento.value === tipoAtend.value)
                                            return idxOd !== parseInt(index) && (valor === item.hora_inicial || valor === item.hora_final || atual.isBetween(h_in, h_fi))
                                        else
                                            return false
                                    })
                                }

                                return retorno.length < 1
                            }),
                        hora_final: Yup.object().nullable()
                            .test('Hora Final', 'Campo Obrigatório!', function (value) {
                                const index = this.path.replace(/[^0-9]+/g, '');
                                const valor = odontologico[index].hora_final
                                return !(odontologico.length - 1 != index && !valor)
                            })
                            .test('Hora Final', 'Hora inválida!', function (value) {
                                const index = this.path.replace(/[^0-9]+/g, '');
                                const valor = odontologico[index].hora_final
                                const novoValor = valor?.toString().split(':')[1];
                                return validaHora('01:' + novoValor)
                            })
                            .test('Hora Final', 'Horário com interposição!', function (value) {
                                const index = this.path.replace(/[^0-9]+/g, '');
                                const valor = odontologico[index].hora_final
                                const tipoAtend = odontologico[index].local_atendimento
                                let retorno = []
                                if (tipoAtend && tipoAtend.value) {
                                    retorno = odontologico.filter((item, idxOd) => {
                                        const atual = moment(valor, 'hh:mm')
                                        const h_in = moment(item.hora_inicial, 'hh:mm')
                                        const h_fi = moment(item.hora_final, 'hh:mm')

                                        if (item.local_atendimento && item.local_atendimento.value && item.local_atendimento.value === tipoAtend.value)
                                            return idxOd !== parseInt(index) && (valor === item.hora_inicial || valor === item.hora_final || atual.isBetween(h_in, h_fi))
                                        else
                                            return false
                                    })
                                }

                                return retorno.length < 1
                            })
                    })
                )
        })
    )

    const validarSubmit = (values, propsFormik) => {
        setSalvando(true)
        let procedimentos = []
        let tabelas = values.tabelas.filter(x => x.tabela).map(x => x.tabela.value)
        if (values.pacotes && values.pacotes.length)
            values.pacotes.map(x => x.procedimentos.map(y => procedimentos.push({ id_aba: 1, aba: 'Pacotes', ...y.procedimento })))

        if (values.procedimentos_acomodacoes && values.procedimentos_acomodacoes.length)
            values.procedimentos_acomodacoes.map(x => x.procedimentos.map(y => procedimentos.push({ id_aba: 2, aba: 'Procedimentos Acomodação', ...y.procedimento })))

        if (procedimentos && procedimentos.length) {
            procedimentos = procedimentos.filter((x, idx, self) => x && x.value && tabelas.findIndex(y => y === x.tabela_id) === -1)
            let codProceds = procedimentos.filter(x => x && tabelas.findIndex(y => y === x.tabela_id) === -1).map(x => x.codigo)
            //trazendo apenas os procedimentos que não possuem tabelas

            Promise.resolve(get('/faturamento/verificarProcedimentoTabela', { procedimentos: codProceds, tabelas: tabelas })).then(val => {
                let procedsRet = val ? val.map(x => x.codigo) : []
                let diff = codProceds.filter(x => !procedsRet.includes(x));
                if (val && val.length) {
                    let novoPacote = values.pacotes.map(x => {
                        x.procedimentos = x.procedimentos.map(y => {

                            if (y.procedimento) {
                                let valIdx = val ? val.findIndex(j => j.codigo === y.procedimento.codigo) : -1
                                y.procedimento = valIdx > -1 ? val[valIdx] : y.procedimento
                            }
                            return y
                        })
                        return x
                    })

                    propsFormik.setFieldValue('pacotes', novoPacote);

                    let novoProcedAcms = values.procedimentos_acomodacoes.map(x => {
                        x.procedimentos = x.procedimentos.map(y => {

                            if (y.procedimento) {
                                let valIdx = val ? val.findIndex(j => j.codigo === y.procedimento.codigo) : -1
                                y.procedimento = valIdx > -1 ? val[valIdx] : y.procedimento
                            }
                            return y
                        })
                        return x
                    })
                    propsFormik.setFieldValue('procedimentos_acomodacoes', novoProcedAcms);
                }

                if (diff && diff.length) {
                    procedimentos = procedimentos.filter((x, idx, self) => self.findIndex(y => (y.value.toString() + y.id_aba) === (x.value.toString() + x.id_aba)) === idx)
                    let procPacote = procedimentos.filter(x => diff.findIndex(y => y === x.codigo) > -1 && x.id_aba === 1)
                    let procAcm = procedimentos.filter(x => diff.findIndex(y => y === x.codigo) > -1 && x.id_aba === 2)
                    let strValidacao = []

                    if (procPacote && procPacote.length) {
                        strValidacao.push(<><b>Pacotes:</b><br /> </>)
                        procPacote.map((x, idx) => {
                            strValidacao.push(<><br /><b>{(idx + 1) + ' - '}</b>{x.label}<br /></>)
                        })
                    }

                    if (procAcm && procAcm.length) {
                        strValidacao.push(<><br /><br /><b>Procedimentos Acomodações:</b><br /> </>)
                        procAcm.map((x, idx) => {
                            strValidacao.push(<><br /><b>{(idx + 1) + ' - '}</b>{x.label}<br /></>)
                        })
                    }


                    setMensagemModalTabela(<>{strValidacao}</>)
                    setShowModalValidacaoTabela(true)
                    setSalvando(false)
                } else {
                    validar('/faturamento/validaContratoParticular', values, () => Submit(values, propsFormik), (e) => {
                        if (e && e.response) {
                            setShowModalValidacao(true)
                            setMensagemModal(e.response.data.alertas[0])
                        } else {
                            Submit(values, propsFormik)
                        }
                    })
                }

            })
        } else {
            validar('/faturamento/validaContratoParticular', values, () => Submit(values, propsFormik), (e) => {
                if (e && e.response) {
                    setShowModalValidacao(true)
                    setMensagemModal(e.response.data.alertas[0])
                } else {
                    Submit(values, propsFormik)
                }
            })
        }

    }

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        salvar(
            values,
            '/faturamento/contratoParticular',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    // manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                }
                else
                    props.history.push('/faturamento/contratoparticular/consulta')

                setSalvando(false)
            },
            (err) => {
                setSalvando(false)
            })
    }

    const funcaoAposSim = (values, propsFormik) => Submit(values, propsFormik)

    useEffect(() => {
        var objListas = {
            pacote_procedimentos: [{ procedimento: undefined, acomodacao: undefined, valor: 0 }],
            procedimentos: [{ procedimento: undefined, porte: undefined, valor: undefined, valor_diferenciado: undefined }],
            procedimentos_acomodacao: [{ procedimento: undefined, acomodacao: undefined, valor: undefined }],
            odontologico: [{ hora_inicial: undefined, hora_final: undefined, local_atendimento: undefined, valor: undefined }],
            acomodacoes: [{ acomodacao: undefined, percentual: undefined, urgencia: undefined, video: undefined }],
            tabelas: [{ tabela: undefined, base_calculo: undefined }]
        }
        if (id) {
            Promise.resolve(get(`/faturamento/contratoparticular/${id}`)).then(val => {
                val.pacote_procedimentos = objListas?.pacote_procedimentos
                val.procedimentos = [...(val.procedimentos || []), ...objListas.procedimentos];
                if (val.procedimentos_acomodacoes && val.procedimentos_acomodacoes.length > 0) {
                    val.procedimentos_acomodacoes = val.procedimentos_acomodacoes.map(item => {
                        item.procedimentos.sort((a, b) => {
                            if (a.id < b.id)
                                return -1
                            if (a.id > b.id)
                                return 1;

                            return 0
                        })
                        item.procedimentos.push({ procedimento: undefined, valor: undefined })
                        return item
                    })
                } else if (val.acomodacoes) {
                    val.procedimentos_acomodacoes = val.acomodacoes.map(x => ({ acomodacao: x.acomodacao, procedimentos: [{ procedimento: undefined, valor: undefined }] }))
                }
                val.odontologico = [...(val.odontologico || []), ...objListas.odontologico];
                val.acomodacoes = [...(val.acomodacoes || []), ...objListas.acomodacoes];
                if (val.tabelas) {
                    val.tabelas = val.tabelas.filter(x => !x.tabela_auxiliar)
                    objListas.tabelas[0].base_calculo = val.tabelas[0]?.base_calculo
                }
                val.tabelas = [...(val.tabelas || []), ...objListas.tabelas];

                setValoresIniciais(val)
            })
        } else {
            setValoresIniciais({
                estabelecimento_saude: "",
                status: "",
                numero_contrato: "",
                vigencia_inicial: "",
                vigencia_final: "",
                odontologica: "",
                pacote_procedimentos: objListas.pacote_procedimentos,
                procedimentos: objListas.procedimentos,
                odontologico: objListas.odontologico,
                acomodacoes: objListas.acomodacoes,
                tabelas: objListas.tabelas,
            })
        }

    }, [id])

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Faturamento</span>,
        () => <Link className='link-active' to={'/faturamento/contrato-particular/consulta'}>Contrato Particular</Link>,
    ]

    const onSelectTab = (index, propsFormik) => {
        switch (index) {
            case 2:
                propsFormik.setFieldTouched('tabelas', true)
                break;
            case 3:
                propsFormik.setFieldTouched('pacote_procedimentos', true)
                break;
            case 8:
                propsFormik.setFieldTouched('odontologico', true)
                break;
            default:
                break;
        }
    }

    const erroContrato = (propsFormik) => {
        const erros = propsFormik.errors;
        return (erros.estabelecimento_saude || erros.status || erros.vigencia_inicial || erros.vigencia_final || erros.odontologica)
    }

    const setValueNumero = (estabelecimentoSaude, propsFormik) => {
        const date = new Date()

        if (estabelecimentoSaude)
            propsFormik.setFieldValue('numero_contrato',
                date.getFullYear().toString() + date.getMonth().toString().padStart(2, '0') + estabelecimentoSaude.value)
        else
            propsFormik.setFieldValue('numero_contrato', '')
    }

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
            {propsFormik => (<Form>
                <HeaderCadastro titulo={"Cadastro Contrato Particular"} link={links} />
                <Tabs style={{ backgroundColor: '#F8F8F8' }} onSelect={index => onSelectTab(index, propsFormik)}>
                    <TabList>
                        <Tab className={"react-tabs__tab " + (erroContrato(propsFormik) ? 'tab-error' : '')}><span className={"icone-tab icon-File-ClipboardFileText"}></span>Contrato</Tab>
                        <Tab className={"react-tabs__tab"}><span className={"icone-tab icon-bed "}></span>Acomodação</Tab>
                        <Tab className={"react-tabs__tab " + (propsFormik.touched.tabelas && propsFormik.errors.tabelas && !Array.isArray(propsFormik.errors.tabelas) ? 'tab-error' : '')}>
                            <span className={"icone-tab icon-table"}></span>Tabela</Tab>
                        <Tab className={"react-tabs__tab " + (propsFormik.touched.pacote_procedimentos && ((propsFormik.errors.pacote_procedimentos && !Array.isArray(propsFormik.errors.pacote_procedimentos)) || propsFormik.errors.descricao_pacote) ? 'tab-error' : '')}><span className={"icone-tab icon-Box-Full"}></span>Pacote</Tab>
                        <Tab className={"react-tabs__tab " + (propsFormik.values.odontologica && propsFormik.values.odontologica.value === 'true' ? 'disabled' : '')}><span className={"icone-tab icon-File-ClipboardFileText "}></span>Procedimento</Tab>
                        <Tab className={"react-tabs__tab " + (propsFormik.values.acomodacoes && propsFormik.values.acomodacoes.filter(item => item.acomodacao && item.acomodacao.value).length &&
                            propsFormik.values.odontologica && propsFormik.values.odontologica.value === 'false' && propsFormik.values.tabelas && propsFormik.values.tabelas.filter(item => item.base_calculo && item.base_calculo.value === 1).length > 0 ? '' : 'disabled')}>
                            <span className={"icone-tab icon-bed"}></span>Procedimento Acomodação</Tab>
                        <Tab className={"react-tabs__tab " + (propsFormik.values.tabelas && propsFormik.values.tabelas.filter(item => item.base_calculo && item.base_calculo.value === 2).length > 0 && propsFormik.values.odontologica?.value === 'false' ? '' : 'disabled')}>
                            <span className={"icone-tab icon-Bulleted-List"}></span>Porte</Tab>
                        <Tab className={"react-tabs__tab " + (propsFormik.values.tabelas && propsFormik.values.tabelas.filter(item => item.base_calculo && item.base_calculo.value === 1).length > 0 && propsFormik.values.odontologica?.value === 'false' ? '' : 'disabled')}>
                            <span className={"icone-tab icon-bed"}></span>Porte Acomodação</Tab>
                        <Tab className={"react-tabs__tab " + (propsFormik.values.odontologica && propsFormik.values.odontologica.value === 'true' ? '' : 'disabled')}><span className={"icone-tab icon-Barricade-2 "}></span>Odontológica</Tab>
                    </TabList>
                    <TabPanel>
                        <div className='padding-40'>
                            <div className='row'>
                                <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />

                                <InputEstabelecimentoSaude botaoVisivel={false} label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={props.modal && 'tooltip-input-modal'} tamanho="col-xl-3 col-lg-6" obrigatorio={true}
                                    onChange={(option) => { setValueNumero(option, propsFormik); propsFormik.setFieldValue('estabelecimento_saude', option) }} />

                                <InputStatusContratoParticular label="Status" placeholder="Status" name="status" propsFormik={propsFormik} classeTooltip={props.modal && 'tooltip-input-modal'} tamanho="col-xl-3 col-lg-6" obrigatorio />

                                <InputPadrao
                                    tamanho="col-xl-2 col-lg-6"
                                    label="Número do Contrato"
                                    name="numero_contrato"
                                    type="text"
                                    placeholder="Número do Contrato"
                                    maxLength={20}
                                />

                                <InputDatePicker
                                    tamanho="col-xl-2 col-lg-4 col-md-6"
                                    label="Vigência Inicial"
                                    name="vigencia_inicial"
                                    type="text"
                                    setarValor={value => {
                                        propsFormik.setFieldValue("vigencia_inicial", value)
                                    }}
                                    placeholder="Vigência Inicial"
                                    obrigatorio={true}
                                />

                                <InputDatePicker
                                    tamanho="col-xl-2 col-lg-4 col-md-6"
                                    label="Vigência Final"
                                    name="vigencia_final"
                                    type="text"
                                    setarValor={value => {
                                        propsFormik.setFieldValue("vigencia_final", value)
                                    }}
                                    placeholder="Vigência Final"
                                    obrigatorio={true}
                                />

                                <InputSimNao label="Odontologica?" name="odontologica" type="select" placeholder="Odontologica?" tamanho={'col-lg-3'} isClearable={true}
                                    noOptionsMessage={_ => ""} propsFormik={propsFormik} obrigatorio />

                                <InputPadrao label='Descrição' name='descricao' type='text' placeholder='Descrição' tamanho={!props.modal && 'col-lg-5'} maxLength={140}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} />

                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className='padding-40'>
                            <CadastroAcomodacao propsFormik={propsFormik} />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className='padding-40'>
                            <CadastroTabela propsFormik={propsFormik} />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className='padding-40'>
                            <CadastroPacoteProcedimento propsFormik={propsFormik} />
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className='padding-40'>
                            <CadastroProcedimento propsFormik={propsFormik} />
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className='padding-40'>
                            <CadastroProcedimentoAcomodacao propsFormik={propsFormik} />

                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className='padding-40'>
                            {/* attValues={atualizarPorteAcomodacao}  */}
                            <CadastroPorteAnestesico propsFormik={propsFormik} />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className='padding-40'>
                            {/* attValues={atualizarPorteAnestesico} */}
                            <CadastroPorteAcomodacao propsFormik={propsFormik} />
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className='padding-40'>
                            {/* attValues={atualizarPorteAnestesico} */}
                            <CadastroOdontologia propsFormik={propsFormik} />
                        </div>
                    </TabPanel>
                </Tabs>
                <div className='separator mt-40 mb-5'></div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
                <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { funcaoAposSim(propsFormik.values, propsFormik); setShowModalValidacao(false); }} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                    esconderFechar mensagem={mensagemModal}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
                <ModalDuasOpcoes showModal={showModalValidacaoTabela} setShowModal={setShowModalValidacaoTabela} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacaoTabela(false) }}
                    esconderFechar mensagem={<>{<>{'Os procedimentos a seguir devem ser alterados, pois não estão presentes nas tabelas selecionadas:'} <br /><br /></>} {mensagemModalTabela}<br /></>}
                    textoOpcao1={'Fechar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    esconderOpcao2={true} />
            </Form >)}
        </Formik >
    );
}

const Cadastro = (props) => {
    return (<>
        <CadastroContratoParticular {...props} />
    </>
    )
}

export { CadastroContratoParticular }
export default Cadastro
