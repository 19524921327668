import React, { useState, useEffect, useRef } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'
import { Alterar, Excluir, Visualizar, Imprimir, BtnAcao, Btn, Detalhes, AlteracaoStatusLote, EnviarLoteCoop, VisualizarArquivo, VisualizarErro, VisualizarRelacaoGuias } from '../../../../components/Acoes'
import { BotaoPadrao } from '../../../../components/Botoes'
import moment from 'moment';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { get, excluir, salvar, validar } from '../../../../util/AcessoApi'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens, useWindowSize } from '../../../../util/Utilitarios.js'
import { InputPadrao, TextArea } from '../../../../components/Inputs'
import { InputLoteStatus } from '../../../../components/InputsAutoComplete'
import Yup from '../../../../util/Validacoes';
import { ModalCadastro, ModalDuasOpcoes } from '../../../../components/Modais'
import { Formik, Form } from "formik";
import { LoadRelatorio } from '../../../../components/Loading'
import { useHistory, Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';



const Consulta = rProps => {
    const [data, setData] = useState([])
    const target = useRef(null)
    const [modalAlteracaoStatus, setModalAlteracaoStatus] = useState(false)
    const [itemTrocarStauts, setItemTrocarStatus] = useState({})
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [itemDetalhes, setItemDetalhes] = useState({})
    const [width] = useWindowSize()
    const history = useHistory()

    const columns = React.useMemo(
        () => [{
            id: 'consulta-aliquota',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Descrição',
                    accessor: 'descricao',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Tipo Tributo',
                    accessor: 'tributo_tipo',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'tributo_tipo',
                        idColuna: 'tributo_tipo_id'
                    }
                },
                {
                    Header: 'Alíquota (%)',
                    accessor: 'aliquota',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Limite Isenção',
                    accessor: 'limite_isencao',
                    ordenamento: {
                        tipo: 'numero'
                    }
                },
                {
                    Header: 'Variável?',
                    accessor: 'variavel',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'input_sim_nao',
                        idColuna: 'variavel'
                    }
                },
                {
                    Header: 'Data de Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    }
                },
                {
                    Header: 'Tributo Esfera',
                    accessor: 'tributo_esfera',
                    visivel: false,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'tributo_esfera',
                        idColuna: 'tributo.tributo_esfera_id'
                    }
                },
                {
                    Header: 'Data Registro Inicial',
                    accessor: 'data_inicial',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'data_registro_inicial',
                        datePicker: true,
                        date: true

                    }
                }, {
                    Header: 'Data Registro Final',
                    accessor: 'data_final',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'data_registro_final',
                        datePicker: true,
                        date: true

                    }
                },
            ]
        }], []
    )

    const acoesObj = [
        (propsAcao) => <Visualizar to={'/financeiro/tributoaliquota/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/financeiro/tributoaliquota/alteracao/' + propsAcao.row.id} />,
        (propsAcao) => <Excluir to={'financeiro/tributoaliquota/consulta/'} action={() => exclusao(propsAcao)} />,
        (propsAcao) => <Detalhes action={() => {
            setShowModalDetalhes(true); setItemDetalhes(propsAcao.row);
        }} />,

    ]

    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: rProps.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/financeiro/tributoaliquota', propsAcao.row.id,
                    () => {
                        // getApi(setData, '/faturamento/guia')
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    return (
        <>
            <div>
                <DataGridAlternativa target={target} acoesObj={acoesObj} data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/financeiro/tributoaliquota'
                    caminhoConsultaFiltros='/financeiro/tributoaliquotapaginacao' modulo={rProps.modulo} titulo={'Consulta Tributo Alíquota'} tituloLista={'Lista Tributo Alíquota'}
                    urlCadastro='/financeiro/tributoaliquota/cadastro' icone={'icon-Basket-Coins'} />
                <ModalTributoVariavel showModal={showModalDetalhes} setShowModal={setShowModalDetalhes} item={itemDetalhes} setItemDetalhes={setItemDetalhes} />
            </div>
        </>
    )

}

const ModalTributoVariavel = ({ idLancamento, target, item, ...props }) => {

    const [salvando, setSalvando] = useState(false)
    const [validacao] = useState(Yup.object({
        anotacoes: Yup.object().nullable()
    }))

    const dados = {
        dados_lancamento: [
            { titulo: 'Código', descricao: item?.id },
            { titulo: 'Descrição', descricao: item?.descricao },
            { titulo: 'Tipo Tributo', descricao: item?.tributo_tipo },
            { titulo: 'Categoria', descricao: item?.categoria || '--' },
            { titulo: 'Alíquota', descricao: item?.aliquota },
            { titulo: 'Limite Isenção', descricao: item?.limite_isencao },
            { titulo: 'Variável?', descricao: item?.variavel },
            { titulo: 'Incide Folha?', descricao: item?.incide_folha },
        ]
    }


    return (
        <>
            <ModalCadastro titulo={'Tributo Alíquota'} show={props.showModal} setShowModal={props.setShowModal}>
                <div className='modal-body-cadastro'>
                    <div className='col-12 mb-2'>
                        <div className='detalhes '>
                            <div className='item-detalhes display-flex col-12 row'>
                                {dados?.dados_lancamento ?
                                    dados?.dados_lancamento.map((item, idx) => {
                                        return (
                                            <>
                                                <div className={'col-6'}>
                                                    <h6><b>{item.titulo}</b></h6>
                                                    {item?.titulo?.includes('Alíquota') || item.titulo.includes('Limite Isenção') ?
                                                        <h6 >
                                                            <b className='valor-indicador-certo'>{item.descricao}</b>
                                                        </h6>
                                                        :
                                                        <h6 >{item.descricao}</h6>}
                                                    {item.html}
                                                </div>

                                            </>
                                        )
                                    })
                                    : <></>
                                }
                            </div>

                        </div>
                    </div>
                    {item?.tributo_variavel && item?.tributo_variavel.length > 0 ? <div className='col-12 '>
                        <div class="modal-header"><h5 class="modal-title"><span>Tributo Variável</span></h5></div>
                        <div className='detalhes '>
                            <div className='item-detalhes'>
                                <div className='row'>
                                    <div className='col-9'><h6><b>Município</b></h6></div>
                                    <div className='col-3 pr-0'><h6><b>Alíq.(%)</b></h6></div>
                                </div>
                                {item?.tributo_variavel ?
                                    item?.tributo_variavel.map((item, idx) => {
                                        return (
                                            <>
                                                <hr className='mt-1 mb-2' />
                                                <div className='row'>
                                                    <div className='col-9'><h6>{item.municipio}</h6></div>
                                                    <div className='col-3 pr-0'><h6>{item.aliquota}</h6></div>
                                                </div>
                                            </>
                                        )
                                    })
                                    : <></>
                                }
                            </div>

                        </div>
                    </div> : <></>}
                </div>
            </ModalCadastro>
        </>
    );
}

export default Consulta