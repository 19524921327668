import { InputCpfCnpj, InputDatePicker, InputPadrao, InputSwitch, InputValor, TextArea } from '../../../../components/Inputs'
import React, { useState, useEffect, useRef, useMemo, useDebugValue } from 'react'
import { Formik, Form } from "formik"
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { get, salvar } from '../../../../util/AcessoApi'
import { Link, useParams } from "react-router-dom"
import { buscarCep, mensagens } from '../../../../util/Utilitarios'
import Yup from '../../../../util/Validacoes'
import '../../../../assets/css/ios-switch/clean-switch.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Atalho from '../../../../util/Atalho'
import { HeaderCadastro } from '../../../../components/Headers'
import { InputBanco, InputCategoriaFinanceiro, InputTipoLancamentoFinanceiro } from '../../../../components/InputsAutoComplete'
import moment from 'moment'

export default (props) => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [devolvido, setDevolvido] = useState(false)
    const [validacao] = useState(Yup.lazy(({ devolvido }) => Yup.object({
        tipo_lancamento: Yup.object().required().nullable(),
        descricao: Yup.string().required().nullable(),
    })))


    const Submit = (values, propsFormik) => {

        setSalvando(true)

        salvar(
            values,
            '/financeiro/categoria',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    // manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                }
                else
                    props.history.push('/financeiro/categoria/consulta')

                setSalvando(false)
            }, (err) => {
                console.log('err: ', err);
                mostrarToast('erro', mensagens.erro)
                setSalvando(false)
            })
    }


    useEffect(_ => {
        if (!props.modal && id) {

            Promise.resolve(get(`/financeiro/categoria/${id}`)).then(obj => {
                obj.categoria = obj.categoria?.categoria
                setValoresIniciais(obj)
            })
        } else {
            setValoresIniciais({
                tipo_lancamento: '',
                categoria: '',
                conta: '',
                ativo: true,
                utiliza_lancamento_movimento: '',
                observacao: ''

            })
        }
    }, [id])

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Financeiro</span>,
        () => <Link className='link-active' to={'/financeiro/categoria/consulta'}>Categoria</Link>,
    ]

    const obterProximaConta = (categoria, propsFormik) => {
        
        Promise.resolve(get('/financeiro/obterProximaConta', { categoria_id: categoria?.value }).then(value => {

            if (value && value.length) {
                let novaConta = parseInt(value[0].conta.replace('-', '')) + 1

                let valorNovaConta = novaConta.toString().padStart(6, "0");

                let parte1 = valorNovaConta.toString().substring(0, 1);
                let parte2 = valorNovaConta.toString().substring(1, 3);
                let parte3 = valorNovaConta.toString().substring(3);

                let resultado = parte1 + parte2 + "-" + parte3;

                propsFormik.setFieldValue('conta', resultado)
            } else {
                propsFormik.setFieldValue('conta', '')
            }

        }))
    }

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>
                {!props.modal && <HeaderCadastro titulo={"Cadastro Categoria"} link={links} />}
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={'card-body'}>
                        <div className='row'>
                            <InputTipoLancamentoFinanceiro label='Tipo Lançamento' name="tipo_lancamento" placeholder="Tipo Lançamento" propsFormik={propsFormik} obrigatorio={true}
                                tamanho={!props.modal && 'col-lg-2'} classeTooltip={props.modal && 'tooltip-input-modal'} onChange={(option) => {
                                    propsFormik.setFieldValue('conta', undefined)
                                    propsFormik.setFieldValue('categoria', undefined)
                                }} />

                            <InputCategoriaFinanceiro label='Categoria Principal' name="categoria" placeholder="Categoria" propsFormik={propsFormik}
                                tamanho={!props.modal && 'col-lg-2'} array={true} classeTooltip={props.modal && 'tooltip-input-modal'} botaoVisivel={true} disabled={!propsFormik.values.tipo_lancamento}
                                onChange={(option) => {
                                    if(option != null) {
                                        obterProximaConta(option, propsFormik)
                                    } else {
                                        propsFormik.setFieldValue('conta', '')
                                    }
                                }} />

                            <InputPadrao label='Descrição' name='descricao' placeholder="Descrição" propsFormik={propsFormik}
                                classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} tamanho={!props.modal && 'col-lg-3'} />

                            <InputPadrao label='Conta' name='conta' placeholder="Conta" propsFormik={propsFormik}
                                classeTooltip={props.modal && 'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2'} />

                            <InputSwitch label='Ativo' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='ativo' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />

                            <InputSwitch label='Lanc/Mov?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='utiliza_lancamento_movimento' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />

                            <TextArea name='observacao' label='Observação' placeholder='Observação' propsFormik={propsFormik} />


                        </div>
                    </div>
                </div>
                {!props.modal && <div className='separator mt-40 mb-5'></div>}
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)}
        </Formik>
    );
}


