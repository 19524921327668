import { InputSwitch, InputPadrao, InputGroupSenha } from '../../../../components/Inputs'
import { InputSocio } from '../../../../components/InputsAutoComplete'
import { removeAcentos, ordenarArrayObj, emptyObject } from '../../../../util/FuncoesComuns'
import React, { useState, useEffect } from 'react'
import { HeaderCadastro } from '../../../../components/Headers'
import { Formik, Form } from "formik"
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { get, salvar } from '../../../../util/AcessoApi'
import { useParams } from "react-router-dom"
import { mensagens } from '../../../../util/Utilitarios'
import { Link } from 'react-router-dom'
import Yup, { validaEspaco } from '../../../../util/Validacoes'
import '../../../../assets/css/ios-switch/clean-switch.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Atalho from '../../../../util/Atalho'
import { InputCorteImagemS3 } from '../../../../components/InputCorteImagemS3'

export default props => {
    let { id } = useParams()

    const [valoresIniciais, setValoresIniciais] = useState({})
    const [grupoObj, setGrupoObj] = useState({})
    const [moduloAtual, setModuloAtual] = useState({})
    const [pesquisaModulo, setPesquisaModulo] = useState('')
    const [acoesPorModulo, setAcoesPorModulo] = useState([])
    const [salvando, setSalvando] = useState(false)
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [modulo, setModulo] = useState(false)
    const [limparFoto, setLimparFoto] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)

    const [validacaoInicial, setValidacaoInicial] = useState(Yup.object({
        login: Yup.string().required().max(200).test('Caractere Inválido', 'Login não pode ter espaço!', function (value) { return validaEspaco(this.parent.login) }),
        senha: Yup.string().required().max(30).oneOf([Yup.ref('confirmarSenha'), null], 'Confirmação de senha deve ser igual a Senha'),
        confirmarSenha: Yup.string().required().max(30).oneOf([Yup.ref('senha'), null], 'Confirmação de senha deve ser igual a Senha')
    }))

    const setUseStatePermissoes = (copiaGrupoObj) => {
        setGrupoObj(copiaGrupoObj)
    }

    useEffect(() => {
        setModulo(modulo)
    }, [])

    const setPorModulo = (idModulo) => {
        let copiaGrupoObj = { ...grupoObj }
        let indexModulo = copiaGrupoObj.modulos.findIndex(x => x.id === idModulo)
        let moduloAtual = { ...copiaGrupoObj.modulos[indexModulo] }
        copiaGrupoObj.modulos[indexModulo] = moduloAtual

        if (copiaGrupoObj.modulos[indexModulo].permitido === true)
            copiaGrupoObj.modulos[indexModulo].permitido = false

        else
            copiaGrupoObj.modulos[indexModulo].permitido = true

        setUseStatePermissoes(copiaGrupoObj)
    }

    const setTodosModulos = (obj = { ...grupoObj }) => {
        let copiaGrupoObj = obj
        var permitido = copiaGrupoObj.modulos && copiaGrupoObj.modulos.every(x => x.permitido === true ? false : true)

        copiaGrupoObj.modulos && copiaGrupoObj.modulos.map(array => array.permitido = permitido)

        setUseStatePermissoes(copiaGrupoObj)
    }

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Controle Acesso</span>,
        () => <Link className="link-active" to={'/controleAcesso/usuarioApp/consulta'}>Usuário App</Link>,
    ]

    const Submit = (values, setIsSubmit, props, salvarNovoObj) => {
        setSalvando(true)

        const copiaUsuarioAppObj = { ...grupoObj }

        copiaUsuarioAppObj.foto_perfil = values.foto_perfil
        copiaUsuarioAppObj.login = values.login
        copiaUsuarioAppObj.senha = values.senha
        copiaUsuarioAppObj.usuario_socio_id = values.socio.value
        copiaUsuarioAppObj.socio = values.socio
        copiaUsuarioAppObj.socio_id = values.socio.value
        copiaUsuarioAppObj.socio_nome = values.socio.label

        const manter = []
        salvar(
            copiaUsuarioAppObj,
            '/controleAcesso/usuarioApp',
            () => {
                mostrarToast('sucesso', mensagens['sucesso'])
                if (salvarENovo) {
                    salvarNovoObj.setLimparFoto(true)
                    salvarNovoObj.propsFormik.resetForm({})
                    manter.forEach(x => salvarNovoObj.propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                    setIsSubmit(false)
                    salvarNovoObj.setSalvarENovo(false)
                    salvarNovoObj.setLimparFoto(false)
                }
                else
                    props.history.push('/controleAcesso/usuarioApp/consulta')
            },
            () => {
                setSalvando(false)
                setIsSubmit(false)
            })
    }

    useEffect(() => {
        if (id) {
            setValidacaoInicial(Yup.object({
                login: Yup.string().required().max(200).test('Caractere Inválido', 'Login não pode ter espaço!', function (value) { return validaEspaco(this.parent.login) }),
                senha: Yup.string().max(30).oneOf([Yup.ref('confirmarSenha'), null], 'Senha deve ser igual a Confirmação de Senha'),
                confirmarSenha: Yup.string().max(30).oneOf([Yup.ref('senha'), null], 'Confirmação de Senha deve ser igual a Senha'),
                foto_perfil: Yup.string().nullable(),

            }))
        }

        Promise.resolve(get(`/controleAcesso/usuarioApp/${id}`)).then(usuarioApp => {
            if (emptyObject(usuarioApp))
                props.history.push('/controleAcesso/usuarioApp/consulta')
            else {
                if (!usuarioApp.id)
                    setTodosModulos(usuarioApp)
                else {
                    setGrupoObj(usuarioApp)
                    setAcoesPorModulo(usuarioApp.modulos.map(modulo => {
                        return {
                            id: modulo.id,
                            descricao: modulo.descricao
                        }
                    }))
                }
                setValoresIniciais({ id: usuarioApp.id || 0, login: usuarioApp.login || '', socio: usuarioApp.socio || '', foto_perfil: usuarioApp.foto_perfil })
            }
        })
    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => {

                const salvarNovoObj = {
                    setSalvarENovo,
                    salvarENovo,
                    propsFormik,
                    setLimparFoto
                }
                Submit(values, setIsSubmit, props, salvarNovoObj)
            }}>
            {propsFormik => (<Form>
                <HeaderCadastro titulo={'Cadastro Usuário App'} link={links} />
                <div className="card mb-20">
                    <div className="card-body">
                        <div className="row">
                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={false} />
                            <InputCorteImagemS3 width={300} height={300} classe='display-block' label="Foto de Perfil" name="foto_perfil" setSalvando={setIsSubmit} diretorioS3="usuarioApp/DDMMYYYY/"
                                tamanho='col-12' limparFoto={limparFoto} />
                            <InputPadrao label="Login" name="login" type="text" placeholder="Nome" obrigatorio={true} tamanho='col-lg-4' autoFocus maxLength={100}
                                salvarENovo={salvarENovo} lower />
                            <InputGroupSenha obrigatorio={id ? false : true} label="Senha" name="senha" placeholder="Senha" tamanho={'col-lg-4 '} maxLength={30} />
                            <InputGroupSenha obrigatorio={id ? false : true} label="Confirmar Senha" name="confirmarSenha" placeholder="Confirmar Senha" tamanho={'col-lg-4'} maxLength={30} />
                            <InputSocio label="Sócio" name="socio" placeholder="Sócio" propsFormik={propsFormik} obrigatorio={true} tamanho='col-lg-4' />
                        </div>
                    </div>
                </div>
                <div className="mt-40">
                    <HeaderCadastro titulo={'Permissões'}
                        pesquisa={true}
                        valorPesquisa={pesquisaModulo}
                        onChangePesquisa={(pesquisa) => setPesquisaModulo(pesquisa)}>
                        <InputSwitch
                            name='pesquisa-geral'
                            onChange={() => setTodosModulos()}
                            checked={grupoObj.modulos && grupoObj.modulos.filter(x => x.permitido).length === grupoObj.modulos.length}
                        />
                    </HeaderCadastro>
                </div>
                <div className='row'>
                    {
                        grupoObj.modulos && ordenarArrayObj(grupoObj.modulos, 'descricao')
                            .filter(item => removeAcentos(item.descricao.toLowerCase()).includes(removeAcentos(pesquisaModulo.toLowerCase())))
                            .map((item, index) => {
                                return <div key={index} className="card-pequeno col-md-6 col-sm-12" >
                                    <div className={"card row-space-between " + (moduloAtual.id === item.id ? "ativo" : "")}>
                                        <div className="row-space-between modulos-permissao">
                                            <div>
                                                <h4>{item.descricao}</h4>
                                            </div>
                                            <div className="row-end">
                                                <div className="row-start">
                                                    <InputSwitch
                                                        name={item.id}
                                                        key={item.id}
                                                        onChange={() => setPorModulo(item.id)}
                                                        checked={item.permitido} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                    }
                </div>
                <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                <div className="separator mt-40 mb-5"></div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)}
        </Formik>
    )
}