import React, { useState } from 'react';
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form } from "formik";
import { BotaoPadrao } from '../../../../components/Botoes'
import { InputPadrao } from '../../../../components/Inputs'
import { mensagens } from '../../../../util/Utilitarios'
import { post } from '../../../../util/AcessoApi'
import { Link } from 'react-router-dom'
import * as Yup from "yup"
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/login.css'

const Submit = (values, setIsSubmit, history) => {
    setIsSubmit(true)

    Promise.resolve(post('/controleAcesso/usuario/recuperarSenha', values, false)).then(function (val) {
        mostrarToast('sucesso', mensagens['sucesso'])
        history.push('/login')
    }).catch((e) => {
        setIsSubmit(false)
    });
}
const Login = ({ history }) => {
    const [isSubmit, setIsSubmit] = useState(false)

    return (
        <div className='background-img'>
            <div className='overlay'>
                <div className='container-img height content-login container'>
                    <div className='row d-flex justify-content-center page-login'>
                        <div className='card-img align-items-center border-radius-bottom-left-50 col-xl-3 col-md-12 content-logo'>
                            <img src={require('../../../../assets/img/logo.png')} alt='logo' className='img-logo' />
                        </div>
                        <div className='card-form border-radius-top-right-50 col-xl-5 col-md-12 form-login'>
                            <Formik
                                initialValues={{ email: "" }}
                                validationSchema={Yup.object({ email: Yup.string().required().email() })}
                                onSubmit={(values) => Submit(values, setIsSubmit, history)}>
                                <Form>
                                    <div className='col-lg-12'> <h4>Recuperação de Senha</h4><br /></div>
                                    <InputPadrao label='E-mail' name='email' type='text' placeholder='E-mail' obrigatorio={true} tamanho='col-lg-12' autoFocus />
                                    <div className='col-lg-12'>  <Link to='/login' className='forgot-password' >Lembrou? Fazer login</Link></div>
                                    <div className='direita container-botoes'>
                                        <div className='col-lg-12 mt-8'>
                                            <BotaoPadrao texto='Recuperar' disabled={isSubmit} />
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                    <div className='copyright'>
                        © Copyright {new Date().getFullYear()}  <a className='link-login' target='_blank' rel='noopener noreferrer' href='http://www.selectasistemas.com.br/'> Selecta Sistemas.</a> Todos os direitos reservados.
                    </div>
                </div>
            </div>
        </div>)
}

export default Login