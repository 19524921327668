import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup from '../../../../util/Validacoes';
import { InputPadrao, InputSummernote } from '../../../../components/Inputs'
import {InputSocio } from '../../../../components/InputsAutoComplete'
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { useParams } from "react-router-dom";
import { get, salvar } from '../../../../util/AcessoApi'
import { mensagens, ajustarDadosTelefone } from '../../../../util/Utilitarios'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import Atalho from '../../../../util/Atalho'
import { getUsuario } from '../../../../util/Configuracoes';

const Submit = (values, setIsSubmit, history, salvarNovoObj) => {

    setIsSubmit(true)
    if (values && !values.id)
        values.usuario_id = getUsuario().id
   
    salvar(values, '/administracao/notificacao', (ret) => {
        if (salvarNovoObj.props.modal) {
            if (salvarNovoObj.salvarENovo) {
                salvarNovoObj.propsFormik.resetForm({})
                setIsSubmit(false)
                salvarNovoObj.setSalvarENovo(false)
            }
            else {
                // salvarNovoObj.props.setItemSelecionado({ value: ret.id, label: ret.nome_cirurgiao })
                salvarNovoObj.props.setShow && salvarNovoObj.props.setShow(false)
            }
        } else {
            mostrarToast('sucesso', mensagens['sucesso'])
            if (salvarNovoObj.salvarENovo) {
                salvarNovoObj.propsFormik.resetForm({})
                setIsSubmit(false)
                salvarNovoObj.setSalvarENovo(false)
            }
            else
                history.push('/administracao/notificacao/consulta')
        }
    }, () => {
        setIsSubmit(false)
    })
}

const links = [
    () => <Link to={'/dashboard'}>Dashboard</Link>,
    () => <span>Administrativo</span>,
    () => <Link className='link-active' to={'/administracao/notificacao/consulta'}>Notificação</Link>,
]

const CadastroNotificacao = (props) => {
    let { id } = useParams();
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [salvarENovo, setSalvarENovo] = useState(false)

    let validacaoInicial = Yup.object({
        titulo: Yup.string().required().max(100),
        descricao: Yup.string().required(),
        socio_id: Yup.string().nullable(),
    })

    const { history } = props

    useEffect(() => {
        if (!props.modal && id) {
            Promise.resolve(get(`/administracao/notificacao/${id}`)).then(function (obj) {
                if (id && !obj.titulo) {
                    history.push('/administracao/notificacao/consulta')
                }

                setValoresIniciais(obj)
            }).catch((e) => {
            });
        } else {
            setValoresIniciais({
                titulo: '',
                descricao: '',
                socio_id: ''
            })
        }
    }, [history, id])

    useEffect(() => {
        // document.onkeyup = function(e) {
        //     if (e.which == 77) {
        //       alert("M key was pressed");
        //     } else if (e.ctrlKey && e.which == 83) {
        //       alert("Ctrl + S shortcut combination was pressed");
        //     } else if (e.ctrlKey && e.altKey && e.which == 89) {
        //       alert("Ctrl + Alt + Y shortcut combination was pressed");
        //     } else if (e.ctrlKey && e.altKey && e.shiftKey && e.which == 85) {
        //       alert("Ctrl + Alt + Shift + U shortcut combination was pressed");
        //     }
        //   };
        document.addEventListener("keydown", function (e) {
            if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                e.preventDefault();
                //   alert("SALVAR");
            }
        }, false);
    }, [])

    return (
        <>
            {!props.modal && <HeaderCadastro titulo={"Cadastro Notificação"} link={links} />}
            <Formik
                initialValues={valoresIniciais}
                validationSchema={validacaoInicial}
                enableReinitialize={true}
                onSubmit={(values, propsFormik) => {
                    const salvarNovoObj = {
                        setSalvarENovo,
                        salvarENovo,
                        propsFormik,
                        props
                    }
                    Submit(values, setIsSubmit, history, salvarNovoObj)
                }}>
                {propsFormik => (
                    <Form>
                        <div className={props.modal && props.modal || 'card mb-20'}>
                            <div className={props.modal || 'card-body'}>
                                <div className="row">
                                    <InputPadrao tamanho="col-0" label="Id" name="id" type="hidden" placeholder="Id" obrigatorio="false" />
                                    <InputPadrao label="Título" name="titulo" type="text" placeholder="Título" obrigatorio={true} tamanho='col-lg-6' autoFocus maxLength={100}
                                        salvarENovo={salvarENovo} />
                                    <InputSocio label="Sócio" name="socio_id" placeholder="Todos" propsFormik={propsFormik} multiplo={true}
                                        tamanho={'col-lg-6'} />
                                    <InputSummernote label="Descrição" placeholder='Descrição' obrigatorio={true} name="descricao" propsFormik={propsFormik} tamanho={"col-lg-12"} config={{ heightMin: 500 }} {...props} />
                                    <Atalho formik={propsFormik} salvando={isSubmit} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                                </div>
                            </div>
                        </div>
                        {!props.modal && <div className='separator mt-40 mb-5'></div>}
                        <div className='direita container-botoes mb-3 group-salvar-novo'>
                            <BotaoPadrao texto='Salvar' disabled={isSubmit} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                            {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={isSubmit} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default CadastroNotificacao