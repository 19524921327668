import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { ordenarArrayObj,downloadArquivoS3 } from '../../../../util/FuncoesComuns'

const VisualizacaoSocioOcorrencia = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})

    useEffect(() => {
        if (id) {
            visualizar(`/administracao/socio-ocorrencia`, id, obj => {
                setDados(obj)
            })
        }
    }, [id])

    const data = dados.tipo_ocorrencia ? {
        titulo: 'Visualização de Ocorrência',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Código',
                        descricao: dados.id,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Sócio',
                        descricao: dados.socio.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Tipo Ocorrência',
                        descricao: dados.tipo_ocorrencia.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data e Hora Inicial',
                        descricao: dados.data_e_hora_inicial,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data e Hora Final',
                        descricao: dados.data_e_hora_final,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Observação',
                        descricao: dados.observacao,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data de Registro',
                        descricao: dados.data_registro,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Implica na Hierarquia?',
                        descricao: dados.implica_hierarquia.label,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Impacto na Hierarquização',
                        descricao: dados.dias_impactantes ? dados.dias_impactantes + ' Dia(s)' : '--',
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Estabelecimento Saúde',
                        descricao: dados.estabelecimento_saude?.label,
                        tamanho: 'col-lg-6'
                    }
                ]
            }, {
                tipoGrupo: 'tabela',
                titulo: 'Arquivo(s)',
                thead:
                    <tr>
                        <td className='coluna-6'>Nome</td>
                        <td className='coluna-5'>Tamanho</td>
                        <td className='coluna-1 d-none-print'>Download</td>
                    </tr>,
                tbody: dados.arquivo == undefined || dados.arquivo.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.arquivo, 'nome').map(arquivo =>
                        <tr>
                            <td>{arquivo.name}</td>
                            <td>{arquivo.sizeReadable}</td>
                            <td className="d-none-print"><div className="icone"><button className="btn-acao icon-Download-fromCloud" onClick={()=> downloadArquivoS3(arquivo.s3Key)}></button></div></td>
                        </tr>)
            }
        ]
    } : { titulo: 'Visualização de Ocorrência'}

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoSocioOcorrencia