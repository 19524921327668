import React, { useState, useEffect } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'

const Consulta = props => {
    const [data, setData] = useState([])


    const columns = React.useMemo(
        () => [{
            id: 'consulta-beneficiario',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                }, {
                    Header: 'Nome',
                    accessor: 'nome',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'CPF',
                    accessor: 'cpf',
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, 
                {
                    Header: 'Nome Social',
                    accessor: 'nome_social',
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, 
                 {
                    Header: 'Número Carteira',
                    accessor: 'numero_carteira',
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, {
                    Header: 'Validade Carteira',
                    accessor: 'validade_carteira',
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, {
                    Header: 'Operadora Saúde',
                    accessor: 'operadora_saude',
                    visivel: false,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'operadora_saude',
                        idColuna: 'operadora_saude_id'
                    }
                }, {
                    Header: 'Data de Registro',
                    accessor: 'data_registro_formatada',
                    ordenamento: {
                        tipo: 'data'
                    }
                }
            ]
        }], []
    )

    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/faturamento/beneficiario' caminhoConsultaFiltros='/faturamento/beneficiarioPaginacao' modulo={props.modulo} titulo={'Consulta Beneficiário'}
                    tituloLista={'Lista Beneficiário'}
                    urlCadastro='/faturamento/beneficiario/cadastro' icone={'icon-File-ClipboardFileText'} />
            </div>
        </>
    )
}

export default Consulta