import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../../../components/VisualizacaoPadrao'
import { visualizar } from '../../../../../util/AcessoApi'
import { obterDataFormatada } from '../../../../../util/Utilitarios'
import { ordenarArrayObj } from '../../../../../util/FuncoesComuns'


const VisualizacaoUsuario = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})

    useEffect(() => {
        visualizar('/administracao/ferias/mercado', id, obj => {
            
            setDados(obj.lancamentos_mercado)
        })
    }, [id])
    
    const data = dados ? {
        id,
        modulo: props.modulo,
        titulo: 'Visualização da Mercado',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Código',
                        descricao: dados.id,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data',
                        descricao: dados.data_transferencia,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Remetente',
                        descricao: dados.remetente && dados.remetente.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Destinatário',
                        descricao: dados.destinatario && dados.destinatario.label,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Tipo',
                        descricao: dados.mercado_tipo && dados.mercado_tipo.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Dias',
                        descricao: dados.dias,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data Registro',
                        descricao: dados.data_registro,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'observacao',
                        descricao: dados.observacao,
                        tamanho: 'col-lg-6'
                    }
                ]
            }
        ]
    } : { titulo: 'Visualização da Mercado' }

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoUsuario