import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputEstabelecimentoSaude, InputSimNao, InputOperadoraSaude, InputTipoExecutante, InputTipoGuia, InputGuiaStatus } from '../../../components/InputsAutoComplete'
import { InputDatePicker } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes';
import { useHistory } from 'react-router-dom';
import { checkAllUndefined, formatarMoeda } from '../../../util/Utilitarios';
import { mostrarToast } from '../../../components/Toasts';
import { InputReferenciaProducao, InputTipoArquivo, InputSocioAtivos } from '../../../components/InputsAutoCompleteV2';
import { getUsuario } from '../../../util/Configuracoes';
import MensagemPadraoRelatorio from '../../../components/RelatorioEspecifico/RelatorioSemDados'


export default props => {

    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Guias Mapa Horas')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '' })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [mapaHoras, setMapaHoras] = useState()
    const ref = useRef(null)
    const history = useHistory()
    const user = getUsuario()
    useEffect(() => {
        if (history?.location?.state?.id_ref_producao)
            setMapaHoras(history?.location?.state && history?.location?.state)
    }, [history?.location?.state?.id_ref_producao])


    const validacao = Yup.object({
        data_registro_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_registro_inicial) }),
        data_registro_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_registro_final) })
    })

    const relatoriosPorTipo = propsFormik => [
        {

            titulo: 'Relatório Guias Mapa Horas',
            tipo: 1,
            url: '/relatorio/relatorioGuiaMapa',
            campos: [
                <InputReferenciaProducao label="Referência Produção" placeholder="Referência Produção" name="referencia_producao" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} autoFocus />,

                <InputOperadoraSaude label="Operadora de Saúde" placeholder="Operadora de Saúde" name="operadora_saude" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputEstabelecimentoSaude label="Estabelecimento Saúde" placeholder="Estabelecimento Saúde" name="estabelecimento_saude" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputSocioAtivos label="Sócio" placeholder="Sócio" name="socio" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputSimNao label="Particular" placeholder="Particular" name="guia_particular" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} />,

                <InputGuiaStatus label="Status" placeholder="Status" name="status" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputTipoExecutante label="Tipo Executante" placeholder="Tipo Executante" name="tipo_executante" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputTipoGuia label="Tipo Guia" placeholder="Tipo Guia" name="tipo_guia" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputTipoArquivo label="Tipo Arquivo" placeholder="Tipo Arquivo" name="tipo_arquivo" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} />,

                <InputDatePicker label="Data Procedimento Inicial" name="data_procedimento_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_procedimento_inicial", value) }} />,

                <InputDatePicker label="Data Procedimento Final" name="data_procedimento_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_procedimento_final", value) }} />,

                <InputDatePicker label="Data Registro Inicial" name="data_registro_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_registro_inicial", value) }} />,

                <InputDatePicker label="Data Registro Final" name="data_registro_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_registro_final", value) }} />,
            ],
            classeTr: 'linha-bottom-relatorio',
            repetirColunas: true,
            colunas: [
                { label: 'Guia', name: 'id', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Nome Paciente', name: 'nome_beneficiario', visivel: true, width: '60%', ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Tipo Arquivo', name: 'tipo_arquivo', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Hora Inicial', name: 'hora_inicial', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Hora Final', name: 'hora_final', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro(Guia)', name: 'data_registro', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
            ],
            data: [],
            agrupamento: [{ coluna: 'executantes_internos', descricao: 'executantes_internos' }],
            colunasFiltros: [
                { label: 'Referência Produção', name: 'referencia_producao', name_coluna: 'referencia_producao' },
                { label: 'Operadora de Saúde', name: 'operadora_saude', name_coluna: 'operadora_saude' },
                { label: 'Estabelecimento de Saúde', name: 'estabelecimento_saude', name_coluna: 'estabelecimento_saude' },
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Socio', name: 'socio', name_coluna: 'socio' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Tipo Executante', name: 'tipo_executante', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'tipo_guia', name_coluna: 'tipo_guia' },
                { label: 'Tipo Arquivo', name: 'tipo_arquivo', name_coluna: 'tipo_arquivo' },
                { label: 'Data Procedimento Inicial', name: 'data_procedimento_inicial', name_coluna: 'data_procedimento_inicial' },
                { label: 'Data Procedimento Final', name: 'data_procedimento_final', name_coluna: 'data_procedimento_final' },
                { label: 'Data Registro Inicial', name: 'data_registro_inicial', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro_final', name_coluna: 'data_registro_final' },

            ],
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioAgrupadoPorSocioEspecifico(dados, arrayConfigCol, configOrdenamento, htmlHeader),

        },
    ]


    const relatorioAgrupadoPorSocioEspecifico = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {

        return (
            array && array.map((x, idx) => {

                const idxGuia = arrayConfigCol[idx].findIndex(y => y.coluna == 'id')
                const idxPaciente = arrayConfigCol[idx].findIndex(y => y.coluna == 'nome_beneficiario')
                const idxTipoArquivo = arrayConfigCol[idx].findIndex(y => y.coluna == 'tipo_arquivo')
                const idxDataProcedimento = arrayConfigCol[idx].findIndex(y => y.coluna == 'data_procedimento')
                const idxHoraInicial = arrayConfigCol[idx].findIndex(y => y.coluna == 'hora_inicial')
                const idxHoraFinal = arrayConfigCol[idx].findIndex(y => y.coluna == 'hora_final')
                const idxDataRegistro = arrayConfigCol[idx].findIndex(y => y.coluna == 'data_registro')

                let guias = x.data.map(g => g.guias)

                // const testeGuiasRepetidas = () => {

                //     const idsRepetidos = new Set();
                //     const idsVistos = new Set();

                //     for (const arrayInterno of guias) {
                //         for (const objeto of arrayInterno) {
                //             if (objeto && objeto.id !== undefined) {
                //                 if (idsVistos.has(objeto.id)) {
                //                     idsRepetidos.add(objeto.id);
                //                 } else {
                //                     idsVistos.add(objeto.id);
                //                 }
                //             }
                //         }
                //     }

                //     const idsRepetidosArray = Array.from(idsRepetidos);

                //     console.log(idsRepetidosArray);
                // }

                const obterTotalConvenio = () => {
                    let totalConvenio = 0;
                    let idsContabilizados = new Set();

                    for (const arrayInterno of guias) {
                        for (const objeto of arrayInterno) {
                            if (objeto && objeto.guia_particular === false && !idsContabilizados.has(objeto.id)) {
                                totalConvenio++;
                                idsContabilizados.add(objeto.id);
                            }
                        }
                    }
                    return totalConvenio
                }

                const obterTotalParticular = () => {
                    let totalParticular = 0;

                    let idsContabilizados = new Set();

                    for (const arrayInterno of guias) {
                        for (const objeto of arrayInterno) {
                            if (objeto && objeto.guia_particular === true && !idsContabilizados.has(objeto.id)) {
                                totalParticular++;
                                idsContabilizados.add(objeto.id);
                            }
                        }
                    }

                    return totalParticular
                }

                return (
                    <div>
                        {
                            x.data.length > 0 ? x.data.map((grupo, idxG) => {

                                return (<>
                                    {idxG > 0 ? htmlHeader : <></>}
                                    <table className='table-agrupamento mb-2'>
                                        <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                            <td className={` bold td-data text-left`}>{grupo.executantes_internos}</td>

                                        </tr>
                                    </table>

                                    <table style={{ width: '100%' }} className='table-agrupamento mb-2'>

                                        <tr className='titulo-1'>
                                            <td className='text-center bold td-data' width={'1%'}>#</td>

                                            {x.colunas && arrayConfigCol[idx][idxGuia]?.visivel ? <td className='text-left bold td-data' width={'5%'}>Guia</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxPaciente]?.visivel ? <td className='text-left bold td-data' width={'40%'}>Paciente</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxTipoArquivo]?.visivel ? <td className='text-left bold td-data' width={'15%'}>Tipo Arquivo</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxDataProcedimento]?.visivel ? <td className='bold td-data' width={'10%'}>Data Proced</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxHoraInicial]?.visivel ? <td className='bold td-data' width={'10%'}>Hora Inicial</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxHoraFinal]?.visivel ? <td className='bold td-data' width={'10%'}>Hora Final</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxDataRegistro]?.visivel ? <td className='bold td-data' width={'10%'}>Data Registro(Guia)</td> : <></>}

                                        </tr>
                                        {grupo.guias.map((guia, index) => {
                                            return (

                                                <>
                                                    <tr className={'tr-data bt-1'}>
                                                        <td className='text-center td-data'>{index + 1}</td>
                                                        {x.colunas && arrayConfigCol[idx][idxGuia]?.visivel ? <td className='text-left td-data'>{guia.id}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxPaciente]?.visivel ? <td className='text-left td-data'>{guia.nome_beneficiario}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxTipoArquivo]?.visivel ? <td className='text-left td-data'>{guia.tipo_arquivo}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxDataProcedimento]?.visivel ? <td className='td-data'>{guia.data_procedimento}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxHoraInicial]?.visivel ? <td className='td-data'>{guia.hora_inicial || ''}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxHoraFinal]?.visivel ? <td className='td-data text-left'>{guia.hora_final}</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxDataRegistro]?.visivel ? <td className='td-data'>{guia.data_registro || ' '}</td> : <></>}

                                                    </tr>
                                                </>
                                            )
                                        })}

                                    </table>

                                    {idxG < x.data.length - 1 ? <div className='quebra-de-pagina'></div> : <></>}
                                </>
                                )
                            }) :
                                <MensagemPadraoRelatorio />



                        }



                        <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                            <tr>
                                <td colspan={12} className={'td-data pr-0 text-right'}>Total Guias Convênio: <b>{obterTotalConvenio()}</b> </td>
                            </tr>

                        </table>
                        <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                            <tr>
                                <td colspan={12} className={'td-data pr-0 text-right'}>Total Guias Particular: <b>{obterTotalParticular()}</b>  </td>
                            </tr>

                        </table>

                    </div >
                )
            }
            )
        )
    }

    useEffect(() => {
        // console.log('WINDOW ', window.performance)
        // if (window.performance && window.performance.navigation.type == 1) {

        let values = {}
        if (mapaHoras != undefined) {
            let tipo_arquivo = mapaHoras?.tipo_arquivo && JSON.parse(mapaHoras?.tipo_arquivo)
            values.referencia_producao = [{ value: mapaHoras.id_ref_producao, label: `${mapaHoras.item.anotacoes} ${mapaHoras.item.referencia}` }]
            values.tipo_arquivo = tipo_arquivo
            submit(values)
        }
        // }
    }, [mapaHoras])

    const validarSubmit = (values, propsFormik) => {

        if (values && checkAllUndefined(values.referencia_producao, values.operadora_saude, values.estabelecimento_saude, values.status, values.tipo_executante,
            values.tipo_guia, values.data_procedimento_inicial, values.data_procedimento_final, values.data_registro_inicial, values.data_registro_final)) {
            mostrarToast('erro', 'Operação não permitida! Nenhum filtro escolhido!')

        } else {

            submit(values, propsFormik)
        }

    }



    const submit = (values, propsFormik) => {

        setCarregando(true)
        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === 1)

        values.tipo_relatorio = 1
        setShowModalFiltros(false)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]

        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio) {

            setFiltros(Object.keys(values)?.map((x, idx, array) => {
                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual?.map(y => y.value) && valorAtual?.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual?.map(y => y.value) : valorAtual.value,
                    dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual?.map(y => y.label).toString().split(',').join(', ') : valorAtual.label

                })
            }).filter(x => x && x.value))

            filtros.push({ id_ref_producao: mapaHoras.id_ref_producao })

        }
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }

    // useEffect(() => {
    //     let cloneRelatorioAtual = { ...relatorioAtual }

    //     cloneRelatorioAtual && cloneRelatorioAtual.url &&
    //         consultarRelatorio(cloneRelatorioAtual.url, array => {
    //             cloneRelatorioAtual.data = array
    //             setTitulo(cloneRelatorioAtual.titulo)

    //             setDados([{ numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data }])
    //             setCarregando(false)
    //             setRelatorioAtual(cloneRelatorioAtual)
    //         }, null, { value: filtros })
    // }, [filtros])

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }
        const newFiltro = [...filtros]

        if (user.tipo === 4)
            newFiltro.push({ coluna: 'usuario', value: user.id }, { coluna: 'tipo', value: user.tipo })

        const isEmpty = Object.keys(cloneRelatorioAtual).length === 0
        !isEmpty &&
            consultarRelatorio(cloneRelatorioAtual.url, (array) => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{ numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data, classeTr: cloneRelatorioAtual.classeTr, aposAgrupamento: cloneRelatorioAtual.aposAgrupamento, aposRelatorio: cloneRelatorioAtual.aposRelatorio, relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento) : null }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: newFiltro, usuario_tipo: user.tipo, usuario_id: user.id })
    }, [filtros])

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })} agrupamento={relatorioAtual && relatorioAtual.agrupamento ? relatorioAtual.agrupamento : undefined}
            repetirColunas={relatorioAtual && relatorioAtual.repetirColunas} ref={ref} classePersonalizada={relatorioAtual && relatorioAtual.classePersonalizada} ordenarGeral={relatorioAtual && relatorioAtual.ordenarGeral}>

            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
                {propsFormik => (
                    <div>
                        <Form>
                            <div className='row'>
                                {relatoriosPorTipo(propsFormik).find(x => x.tipo === 1) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                                    {relatoriosPorTipo(propsFormik).find(x => x.tipo === 1).campos?.map(
                                        (item, idx, array) => {
                                            if (idx === (array.length - 1)) {
                                                setTimeout(() => {
                                                    setCarregandoFiltros(false)
                                                }, 1000)
                                            }
                                            return item
                                        }
                                    )}

                                </div>}
                                {carregandoFiltros === true ?
                                    <ContentLoader
                                        speed={2}
                                        width='100%'
                                        height='400'
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#e4e4e4"
                                        {...props} >
                                        <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                        <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                        <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                        <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                                    </ContentLoader>
                                    : <></>}
                            </div>
                            <div className='direita container-botoes mb-3 group-salvar-novo'>
                                <BotaoPadrao texto='Filtrar' disabled={false} />
                            </div>
                        </Form>
                        {/* <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { submit(propsFormik.values, propsFormik); setShowModalValidacao(false) }} funcaoOpcao1={() => { setCarregando(false); setShowModalValidacao(false) }}
                            esconderFechar mensagem={<>{<>{'Validações Filtro:'} <br /><br /></>} {mensagemModal}<br />
                                {'Deseja Continuar?'}</>}
                            textoOpcao1={'Cancelar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} /> */}

                    </div>
                )}
            </Formik>
        </ConsultaRelatorio >

    )
}
