import { InputPadrao, InputNumero, InputValor, InputSwitch } from '../../../../components/Inputs'
import { InputBanco, InputTipoConta, InputPorTipoClienteFinanceiro } from '../../../../components/InputsAutoComplete'
import React, { useState, useEffect } from 'react'
import Yup, { validaData } from '../../../../util/Validacoes'
import { HeaderCadastro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'

const CadastroContaBancaria = props => {
    let { id } = useParams()
    const date = new Date()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [mudouStatus, setMudouStatus] = useState(false)
    const [contaEmpresa, setContaEmpresa] = useState(true)

    useEffect(_ => {
        if (!props.modal && id) {

            Promise.resolve(get(`/financeiro/contabancaria/${id}`)).then(obj => {
                setContaEmpresa(obj.conta_empresa)
                setValoresIniciais(obj)
            })
        } else {
            setValoresIniciais({
                banco: '',
                tipo_conta: '',
                cliente: props.cliente_id || '',
                agencia: '',
                agencia_digito: '',
                conta: '',
                conta_digito: '',
                saldo_inicial: '',
                saldo_atual: '',
                descricao: '',
                ativo: props.cliente_id ? true : false,
                conta_empresa: true,
                // conta_empresa: props.cliente_id ? true : false,


            })
        }
    }, [id])



    const [validacao] = useState(
        Yup.lazy(({ }) => Yup.object({

            banco: Yup.object().nullable().required(),
            tipo_conta: Yup.object().nullable().required(),
            cliente: Yup.object().nullable().required(),
            agencia: Yup.string().nullable().required(),
            agencia_digito: Yup.string().nullable().required(),
            conta: Yup.string().nullable().required(),
            conta_digito: Yup.string().nullable().required(),
            descricao: Yup.string().nullable().required(),

        })
        ))


    const manter = []


    const Submit = (values, propsFormik) => {
        setSalvando(true)
        salvar(values,
            '/financeiro/contabancaria',
            obj => {
                if (props.modal) {
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else {
                        props.setItemSelecionado({ value: obj.item_selecionado.value, label: obj.item_selecionado.label })
                        props.setShow && props.setShow(false)
                    }
                }
                else {
                    mostrarToast('sucesso', mensagens.sucesso)
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                    }
                    else
                        props.history.push('/financeiro/contabancaria/consulta')
                }
            },

            (err) => {
                console.log('err: ', err);
                setSalvando(false)
            })
    }

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Financeiro</span>,
        () => <Link className='link-active' to={'/financeiro/contabancaria/consulta'}>Lote</Link>,
    ]

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => {
                return (<Form>
                    <div className={props.modal && props.modal || 'card mb-20'}>
                        <div className={props.modal || 'card-body'}>
                            <div className='row'>

                                <InputBanco label='Banco' name='banco' placeholder='Banco' obrigatorio={true} propsFormik={propsFormik} autoFocus
                                    tamanho={!props.modal && 'col-lg-4'} classeTooltip={props.modal && 'tooltip-input-modal'} />

                                <InputTipoConta label='Tipo Conta' name='tipo_conta' placeholder='Tipo Conta' obrigatorio={true} propsFormik={propsFormik}
                                    tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} />

                                <InputSwitch label='Conta Empresa' disabled={props.cliente_id} propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='conta_empresa' size="md" tamanho={!props.modal && 'col-lg-1'}
                                    onChange={value => {
                                        propsFormik.setFieldValue('conta_empresa', value)
                                        propsFormik.setFieldValue('cliente', null)
                                        setContaEmpresa(value)
                                    }} classeTooltip={props.modal && 'tooltip-input-modal'} />

                                <InputPorTipoClienteFinanceiro label='Empresa/Cliente' name="cliente" placeholder="Empresa/Cliente" propsFormik={propsFormik} obrigatorio={true} conta_empresa={contaEmpresa}
                                    botaoVisivel={!props.cliente_id} disabled={props.cliente_id} tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} />

                                <InputPadrao label='Agência' name='agencia' placeholder="Agência" propsFormik={propsFormik}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} tamanho={!props.modal && 'col-lg-2'} />

                                <InputPadrao label='Ag. Digito' placeholder="Ag. Digito" name='agencia_digito' propsFormik={propsFormik} classDiv={!props.modal && 'col-lg-1' || 'col-lg-12'} max={100}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} tamanho={!props.modal && 'col-lg-1'} />

                                <InputPadrao label='Conta' name='conta' placeholder="Conta" propsFormik={propsFormik} classDiv={!props.modal && 'col-lg-2' || 'col-lg-12'}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} tamanho={!props.modal && 'col-lg-2'} />

                                <InputPadrao label='Conta. Digito' placeholder="Conta. Digito" name='conta_digito' propsFormik={propsFormik} classDiv={!props.modal && 'col-lg-1' || 'col-lg-12'} max={100}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} tamanho={!props.modal && 'col-lg-1'} />

                                <InputValor
                                    label='Saldo Inicial (R$)' name='saldo_inicial' placeholder='R$ 0,00' classDiv={!props.modal && 'col-lg-2' || 'col-lg-12'} tamanho={!props.modal && 'col-lg-2'} propsFormik={propsFormik} />

                                <InputValor
                                    label='Saldo Atual (R$)' name='saldo_atual' placeholder='R$ 0,00' classDiv={!props.modal && 'col-lg-2' || 'col-lg-12'} tamanho={!props.modal && 'col-lg-2'} propsFormik={propsFormik} />

                                <InputPadrao
                                    label='Descrição'
                                    name='descricao'
                                    type='text'
                                    placeholder='Descrição'
                                    tamanho={!props.modal && 'col-lg-5'}
                                    classeTooltip={props.modal && 'tooltip-input-modal'}
                                    obrigatorio={true} />

                                <InputSwitch label='Ativo' disabled={props.cliente_id} propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='ativo' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />

                                <InputSwitch label='Conta Principal' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='conta_principal' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />


                            </div>
                        </div>
                    </div>
                    {!props.modal && <div className='separator mt-40 mb-5'></div>}
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                        {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                    </div>
                </Form>)
            }}
        </Formik>
    )
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Financeiro</span>,
        () => <Link className='link-active' to={'/financeiro/conta-bancaria/consulta'}>Conta Bancária</Link>,
    ]
    return (<>
        {!props.modal && <HeaderCadastro titulo={"Cadastro Conta Bancária"} link={links} />}
        <CadastroContaBancaria {...props} />
    </>
    )
}
export { CadastroContaBancaria }
export default Cadastro