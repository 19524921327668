import React, { useState, useEffect, useRef } from 'react'
import { Alterar, Excluir, Visualizar, BtnAcao } from '../../../../components/Acoes'
import { excluir } from '../../../../util/FuncoesComuns'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar, get, atulizarWs } from '../../../../util/AcessoApi'
import { ModalCadastro, ModalCenter } from '../../../../components/Modais'
import { InputPadrao } from '../../../../components/Inputs'
import { BotaoPadrao } from '../../../../components/Botoes'
import ProgressBar from 'react-bootstrap/ProgressBar'
import socketIo from "socket.io-client";
import { mostrarToast } from '../../../../components/Toasts'
import { MODULO } from '../../../../util/Enums'
import { LoadRelatorio } from '../../../../components/Loading'
import { getUsuario } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { mensagens } from '../../../../util/Utilitarios'

const Consulta = props => {
    const [data, setData] = useState([])

    const target = useRef(null)

    useEffect(() => {
        consultar('/faturamento/tabelaprocedimentopaginacao', (dados) => setData(dados.sort((a, b) => b.id - a.id)))
    }, [])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-tabela-procedimento',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Tipo',
                    accessor: 'tipo',
                    ordenamento: {
                        tipo: 'texto'
                    },
                     filtro: {
                        tipo: 'tipo_tabela_procedimento',
                        idColuna: 'tipo_tabela_procedimento_id'
                    },
                },
                {
                    Header: 'Descrição',
                    accessor: 'descricao',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    
                    
                },
                {
                    Header: 'Tabela',
                    accessor: 'tabela',
                    visivel: false,
                    filtroSomenteModal: true,
                     filtro: {
                       tipo: 'tabela',
                       idColuna: 'tabela_procedimento.id' 
                     }
                },
                {
                    Header: 'TISS',
                    accessor: 'codigo_tiss',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Utiliza Agendamento?',
                    accessor: 'utiliza_agendamento',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'input_sim_nao',
                        idColuna: 'utiliza_agendamento',
                        boolean: true
                    },
                },
                {
                    Header: 'Data Atualização',
                    accessor: 'data_atualizacao',
                    ordenamento: {
                        tipo: 'data'
                    },
                },
                {
                    Header: 'Data de Registro',
                    accessor: 'data_registro_formatada',
                    ordenamento: {
                        tipo: 'data'
                    }
                }
            ]
        }], []
    )

    const exclusao = propsAcao => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }
        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length){
                excluir('/faturamento/tabelaProcedimento', propsAcao.id,
                    () => {
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
                }
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }
    
    const acoesObj = [
        propsAcao => <Visualizar to={'/faturamento/tabelaProcedimento/visualizacao/' + propsAcao.id} />,
        propsAcao => <Alterar to={'/faturamento/tabelaProcedimento/alteracao/' + propsAcao.id} />,
        propsAcao => <Excluir to={'/faturamento/tabelaProcedimento/consulta/'} action={ () => exclusao(propsAcao)} />,
        propsAcao => propsAcao.row.codigo_coopanestgo ? <BtnAcao icone="icon-Repeat-2" texto="Atualizar"
            action={_ => { 
                props.obterWebService(JSON.stringify({"integrationType": "tabela_procedimentos", "key": `${propsAcao.id}`, "usuarioId": getUsuario().id, "moduloId": 12, "acaoId" : 30}))
            }} {...props} /> : <></>
    ]

    return (
        <>
            <DataGridAlternativa target={target} data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/faturamento/tabelaProcedimento' caminhoConsultaFiltros='/faturamento/tabelaProcedimentoPaginacao' modulo={props.modulo }
                titulo={'Consulta Tabela Procedimento'} tituloLista={'Lista Tabela Procedimento'} acoesObj={acoesObj}
                urlCadastro='/faturamento/tabelaProcedimento/cadastro' icone={'icon-File-ClipboardFileText'}
                actionImportacao={() => props.obterWebService(JSON.stringify({"integrationType": "inserir_todas_tabelas", "key": "10", "usuarioId": getUsuario().id, "moduloId": 12, "acaoId" : 9}))} />
        </>
    )
}

export default Consulta