import React, { useState, useEffect } from 'react'
import { getApi } from '../../../../util/Configuracoes'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { HeaderCadastro } from '../../../../components/Headers'
import { removeAcentos } from '../../../../util/FuncoesComuns'
import { consultar } from '../../../../util/AcessoApi'

const ConsultaUsuarioApp = (props) => {
    const [data, setData] = useState([])
    useEffect(() => {
        consultar('/controleAcesso/usuarioApp', (dados) => setData(dados))
        // getApi(setData, '/controleAcesso/usuarioApp', null, props.modulo)
    }, [])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-app',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            modo: 'desc',
                            ativo: true
                        }
                    },
                    {
                        Header: 'Login',
                        accessor: 'login',
                        ordenamento: {
                            tipo: 'texto',
                            modo: 'asc'
                        }
                    },
                    {
                        Header: 'Sócio',
                        accessor: 'nome',
                        ordenamento: {
                            tipo: 'texto',
                            modo: 'asc'
                        }
                    },
                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro',
                        ordenamento: {
                            tipo: 'data'
                        }
                    }
                ],
            },
        ],
        []
    )
    return (
        <>
            <div>
                <DataGridAlternativa
                    data={data}
                    columns={columns}
                    setData={setData}
                    caminhoBase='/controleAcesso/usuarioApp'
                    caminhoConsultaFiltros= '/controleAcesso/usuarioapppaginacao'
                    titulo={'Consulta Usuário App'}
                    tituloLista={'Lista Usuário App'}
                    acoes={true}
                    modulo={props.modulo}
                    urlCadastro='/controleAcesso/usuarioApp/cadastro'
                    icone={'icon-Smartphone-2'} />
            </div>
        </>

    )
}

export default ConsultaUsuarioApp