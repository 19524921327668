import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../../util/AcessoApi'

const VisualizacaoCbos = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        if (id) {
            visualizar('/administracao/ferias/lancamentosaldo', id,
                obj =>
                {setDados(obj.id ? {
                        id,
                        modulo: props.modulo,
                        titulo: 'Visualização de Lançamento (Dias para Gozo)',
                        grupos: [
                            {
                                titulo: 'Dados Gerais',
                                colunas: [
                                    { titulo: 'Sócio', descricao: obj.socio.label, tamanho: 'col-lg-6' },
                                    { titulo: 'Ano', descricao: obj.ano , tamanho: 'col-lg-6' },
                                    { titulo: 'Dias Direito', descricao: obj.dias , tamanho: 'col-lg-6' },
                                    { titulo: 'Período Inicial', descricao: obj.data_inicial_gozo , tamanho: 'col-lg-6' },
                                    { titulo: 'Período Final', descricao: obj.data_final_gozo , tamanho: 'col-lg-6' },
                                    { titulo: 'Data Limite', descricao: obj.data_limite_gozo , tamanho: 'col-lg-6' },
                                    { titulo: 'Data Registro', descricao: obj.data_registro, tamanho: 'col-lg-6' },
                                ]
                            },
                        ]
                    } : { titulo: 'Visualização de Lançamento (Dias para Gozo)' })}
            )
        }
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default VisualizacaoCbos