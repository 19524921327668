import { InputPadrao, InputSwitch } from '../../../../components/Inputs'
import React, { useState, useEffect } from 'react'
import { Formik, Form } from "formik"
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { get, salvar } from '../../../../util/AcessoApi'
import { Link, useParams } from "react-router-dom"
import { mensagens } from '../../../../util/Utilitarios'
import Yup from '../../../../util/Validacoes'
import '../../../../assets/css/ios-switch/clean-switch.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Atalho from '../../../../util/Atalho'
import { HeaderCadastro } from '../../../../components/Headers'

const CadastroTipoOcorrencia = (props) => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao] = useState(Yup.object({
        codigo: Yup.string().required().nullable(),
        banco: Yup.string()
            .required().trim()
            .max(200)
    }))

    const manter = []

    const Submit = (values, propsFormik) => {
        setSalvando(true)

        salvar(
            values,
            '/financeiro/banco',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    // manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                }
                else
                    props.history.push('/financeiro/banco/consulta')

                setSalvando(false)
            }, (err) => {
                console.log('err: ', err);
                mostrarToast('erro', mensagens.erro)
                setSalvando(false)
            })
    }


    useEffect(_ => {
        if (!props.modal && id) {

            Promise.resolve(get(`/financeiro/banco/${id}`)).then(obj => {
                setValoresIniciais(obj)
            })
        } else {
            setValoresIniciais({
                codigo: '',
                banco: ''

            })
        }
    }, [id])

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Financeiro</span>,
        () => <Link className='link-active' to={'/financeiro/banco/consulta'}>Banco</Link>,
    ]



    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>
                {!props.modal && <HeaderCadastro titulo={"Cadastro Banco"} link={links} />}
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={'card-body'}>
                        <div className='row'>
                            <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' obrigatorio={false} />
                            <InputPadrao autoFocus salvarENovo={salvarENovo} tamanho={!props.modal && 'col-lg-2'} label="Cod. Banco" name="codigo" type="text" placeholder="Cod. Banco" classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} />
                            <InputPadrao label='Banco' name='banco' type='text' placeholder='Banco' obrigatorio={true} tamanho={!props.modal && 'col-lg-6'} classeTooltip={props.modal && 'tooltip-input-modal'}/>

                        </div>
                    </div>
                </div>
                {!props.modal && <div className='separator mt-40 mb-5'></div>}
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)}
        </Formik>
    );
}

export default CadastroTipoOcorrencia 
