import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaData, periodoData } from '../../../../../util/Validacoes';
import { mostrarToast } from '../../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { ModalDuasOpcoes } from '../../../../../components/Modais';
import { get, salvar, validar } from '../../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao, InputDatePicker, TextArea } from '../../../../../components/Inputs'
import { InputUsuarioSocio, InputTipoEscalador, InputUsuario, InputSocioAtivos } from '../../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../../components/Botoes'
import { mensagens } from '../../../../../util/Utilitarios'
import '../../../../../assets/css/icones/iconsmind/style.css'
import Atalho from '../../../../../util/Atalho'
import moment from 'moment';

const CadastroLancamentoGozo = (props) => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [datas, setDatas] = useState({})
    const [msgValidacaoExistente, setMsgValidacaoExistente] = useState('');
    const [showModalExistente, setShowModalExistente] = useState(false)
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')

    const [validacao] = useState(Yup.object({
        dias: Yup.string().nullable().required(),
        ano: Yup.string().nullable().required(),
        socio: Yup.string().nullable().required()
    }))

    const validacaoSocioExistente = (values, propsFormik) => {
        validar(`/administracao/socio-ano-verificacao`, { periodo_id: datas.id, ...values }, () => { setMsgValidacaoExistente(''); validarSubmit(values, propsFormik, 2) }, (e) => {
            const alertas = e.response.data.alertas;
            setMsgValidacaoExistente(<><div dangerouslySetInnerHTML={{ __html: alertas[0].replace(/"/g, '') }} /></>)
            setShowModalExistente(true)
            setSalvando(false)
        })
    }

    const validarSubmit = (values, propsFormik, nValidacao) => {
        setSalvando(true)

        switch (nValidacao) {
            case 1:
                validacaoSocioExistente(values, propsFormik)
                break;
            case 2:
                if (values.ano < moment().format('YYYY')) {
                    setMensagemModal(`Você está ${id ? 'alterando' : 'inserindo'} um registro que é anterior ao ano atual.`)
                    setShowModalValidacao(true);
                } else {
                    setMensagemModal('')
                    Submit(values, propsFormik, false)
                }
                break;
            default:
                validacaoSocioExistente(values, propsFormik)
                break;
        }
    }

    const Submit = (values, propsFormik) => {
        setSalvando(true)

        salvar(
            { periodo_id: datas.id, ...values },
            '/administracao/ferias/lancamentosaldo',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)

                if (salvarENovo) {
                    propsFormik.resetForm({})
                    setDatas({
                        id: '',
                        data_inicial: '',
                        data_final: ''
                    })
                    setSalvando(false)
                    setSalvarENovo(false)
                }
                else
                    props.history.push('/administracao/ferias/lancamento-saldo/consulta')
            },
            () => {
                setSalvando(false)
            })
    }

    useEffect(() => {
        if (id) {
            Promise.resolve(get(`/administracao/ferias/lancamentosaldo/${id}`)).then(val => {
                if (!val.data_registro)
                    props.history.push('/administracao/ferias/lancamento-saldo/consulta')

                setDatas({
                    id: val.periodo_id,
                    data_inicial: val.data_inicial_gozo,
                    data_final: val.data_final_gozo
                })
                setValoresIniciais(val)
            })
        } else {
            setValoresIniciais({
                socio: "",
                ano: "",
                data_inicial_gozo: "",
                data_final_gozo: "",
                dias: ""
            })
        }
    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
            {propsFormik => (<Form>
                <div className={'card mb-20'}>
                    <div className={'card-body'}>
                        <div className='row'>
                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />

                            {id ? <>
                                <div className={"form-group col-lg-4"}>
                                    <label>Sócio</label>
                                    <span style={{ display: 'flex' }}><b>{valoresIniciais.socio ? valoresIniciais.socio.label : '--'}</b></span>
                                </div>

                                <div className={"form-group col-lg-2"}>
                                    <label>Ano</label>
                                    <span style={{ display: 'flex' }}><b>{valoresIniciais.ano || '--'}</b></span>
                                </div>
                            </> : <>
                                <InputSocioAtivos label='Sócio' name="socio" propsFormik={propsFormik} tamanho={'col-lg-4'}
                                    classeTooltip={'tooltip-input-modal'} autoFocus obrigatorio disabled={id} />

                                <InputPadrao label="Ano" name="ano" type="decimal" obrigatorio disabled={id} placeholder="Ano" tamanho={'col-lg-2'} onBlur={(value) => {
                                    if (propsFormik.values.ano > 3) {
                                        Promise.resolve(get(`/administracao/ferias/periodogozoano/${propsFormik.values.ano}`)).then(val => {
                                            if (val.length > 0) {
                                                const obj = val[0]
                                                setDatas({
                                                    id: obj.id,
                                                    data_inicial: obj.periodo_inicial,
                                                    data_final: obj.periodo_final
                                                })
                                            }
                                        })
                                    } else {
                                        setDatas({
                                            data_inicial: '',
                                            data_final: ''
                                        })
                                    }
                                }} />

                            </>}


                            <InputPadrao label="Dias para gozo" name="dias" type="text" placeholder="Dias para gozo" obrigatorio={true} autoFocus={id} tamanho={'col-lg-2'} />

                            <div className={"form-group col-lg-4"}>
                                <label>Período Gozo</label>
                                <span style={{ display: 'flex' }}>{!datas.data_inicial ? <i>Nenhum ano selecionado</i> : <b>{datas.data_inicial + ' - ' + datas.data_final}</b>}</span>
                            </div>

                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                        </div>
                    </div>
                </div>
                <div className='separator mt-40 mb-5'></div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
                <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => Submit(propsFormik.values, propsFormik)} funcaoOpcao1={() => setSalvando(false)}
                    esconderFechar mensagem={<>{mensagemModal + ' Deseja Continuar?'} </>}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />

                <ModalDuasOpcoes showModal={showModalExistente} setShowModal={setShowModalExistente}
                    funcaoOpcao1={() => { setSalvando(false); setShowModalExistente(false) }}
                    esconderFechar mensagem={<>{msgValidacaoExistente}<br /></>}
                    textoOpcao1={'Fechar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'} />
            </Form>)}
        </Formik>
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Férias</Link>,
        () => <Link className="link-active" to={'/administracao/escalador/consulta'}>Lançamentos Gozo</Link>,
    ]
    return (<>
        <HeaderCadastro titulo={"Cadastro de Lançamentos (Dias para Gozo)"} link={links} />
        <CadastroLancamentoGozo {...props} />
    </>
    )
}
export { CadastroLancamentoGozo }
export default Cadastro
