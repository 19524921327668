import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaData, periodoData, validaHora } from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { ModalDuasOpcoes } from '../../../../components/Modais';
import { get, salvar, validar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao, InputDatePicker, TextArea, InputTimePicker } from '../../../../components/Inputs'
import { InputSocio, InputTipoOcorrencia, InputSimNao } from '../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import InputArquivoS3 from '../../../../components/InputArquivoS3'
import { MODULO } from '../../../../util/Enums'
import Atalho from '../../../../util/Atalho'
import { ModalCadastro } from '../../../../components/Modais'


const CadastroLembrete = (props) => {
    let { id } = useParams()

    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [dataArquivos, setDataArquivos] = useState(null)
    const [limparArquivos, setLimparArquivos] = useState(false)

    let validacaoInicial = Yup.object({
        descricao: Yup.string().nullable().required(),
        data: Yup.string().nullable().required()

    })

    const manter = ['implica_hierarquia']

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        values.arquivo = dataArquivos
        salvar(
            values,
            '/administracao/lembrete',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                }
                else if (!props.setAbrirModal)
                    props.history.push('/administracao/lembrete/consulta')
                else if (props.setAbrirModal) {
                    props.funcaoAposSalvar();
                    props.setAbrirModal(false);
                }

                props.funcaoAposSalvar();
            },
            () => {
                setSalvando(false)
            })
    }

    useEffect(() => {
        let valores = {};
        valores.data = props.dataAtual;
        setValoresIniciais(valores)
    }, [])

    useEffect(() => {
        if (props.idAlteracao) {
            Promise.resolve(get(`/administracao/lembrete/${props.idAlteracao}`)).then(val => {
                setValoresIniciais(val)
            })
        } 
    }, [props.idAlteracao])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>
                <div className={props.modal ? '' : 'card mb-20'}>
                    <div className={props.modal ? '' : 'card-body'}>
                        <div className='row'>
                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                            <InputDatePicker tamanho='col-lg-12' label="Data" name="data" type="text" obrigatorio="true"
                                setarValor={value => {
                                    propsFormik.setFieldValue("data", value)
                                }} classeTooltip={props.modal && 'tooltip-input-modal'}
                            />
                            <InputPadrao label="tipo-lembrete" name="lembrete-tipo" type="hidden" value="1" />
                            <TextArea autoFocus={true} label='Descrição' name='descricao' type='text' palceholder='Descrição' tamanho='col-lg-12' />
                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                        </div>
                    </div>
                </div>
                <div className='separator mt-40 mb-5'></div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)}
        </Formik>
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Administrativo</Link>,
        () => <Link className="link-active" to={'#'}>Ocorrência</Link>,
    ]
    return (<>
        <HeaderCadastro titulo={"Cadastro de Ocorrência"} link={links} />
        <CadastroLembrete {...props} />
    </>
    )
}


const CadastroModalLembrete = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Administrativo</Link>,
        () => <Link className="link-active" to={'#'}>Ocorrência</Link>,
    ]
    return (<>
        <ModalCadastro titulo={'Cadastro Lembrete'} show={props.abrirModal} setShowModal={props.setAbrirModal}>
            <CadastroLembrete {...props} />
        </ModalCadastro>
    </>
    )
}

export { CadastroLembrete, CadastroModalLembrete }
export default Cadastro
