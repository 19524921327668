import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import moment from "moment"

const VisualizacaoAgenda = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})
    const historico_socio = []

    useEffect(() => id && visualizar(`/administracao/plantaoalcancavelvisualizacao`, id, obj => setDados(obj)), [id])

    if (dados && dados.historico_socio) {
        for (let index = 0; index < dados.historico_socio.length; index++) {
            historico_socio.push(
                { titulo: 'Data', descricao: dados.historico_socio[index].data_registro, tamanho: 'col-lg-6' },
                { titulo: 'Usuário', descricao: dados.historico_socio[index].usuario, tamanho: 'col-lg-6' },
                { titulo: 'Trocas', descricao: dados.historico_socio[index].historico.map(y => y.nome_anterior).join(', '), tamanho: 'col-lg-6' },
                { titulo: 'Justificativa', descricao: dados.historico_socio[index].justificativa, tamanho: 'col-lg-6' },
            )
        }
    }

    const data = dados ? {
        titulo: 'Visualização de Escala Plantão',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    { titulo: 'Data Inicial', descricao: moment(dados.data_inicial, 'DD/MM/YYYY às HH:mm').format('ddd') + ' ' + dados.data_inicial, tamanho: 'col-lg-6' },
                    { titulo: 'Data Final', descricao: moment(dados.data_final, 'DD/MM/YYYY às HH:mm').format('ddd') + ' ' + dados.data_final, tamanho: 'col-lg-6' },
                    { titulo: 'Ordem', descricao: dados.ordem && dados.ordem || '--', tamanho: 'col-lg-6' },
                    { titulo: 'Sócio(s)', descricao: dados.socio && dados.socio.length > 0 ? dados.socio.map(itemSoc => itemSoc.label).join(', ') : '--', tamanho: 'col-lg-6' },
                    { titulo: 'Tipo Escala', descricao: dados.tipo_escala, tamanho: 'col-lg-6' },
                    { titulo: 'Recorrência', descricao: dados.recorrencia && dados.recorrencia || '--', tamanho: 'col-lg-6' },
                    { titulo: 'Escalador', descricao: dados.escalador, tamanho: 'col-lg-6' },
                    { titulo: 'Status', descricao: dados.status, tamanho: 'col-lg-6' },
                ]
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Histórico Troca de Sócios',
                thead:
                    <tr>
                        <td className='coluna-3'>Socio Atual</td>
                        <td className='coluna-3'>Sócio Anterior</td>
                        <td className='coluna-3'>Justificativa</td>
                        <td className='coluna-2'>Usuário</td>
                        <td className='coluna-1'>Data Registro</td>
                    </tr>,
                tbody: !dados.historico_socio ? (<><tr>
                    <td colspan="4">Sem Registro!</td>
                </tr></>) :
                    dados.historico_socio.map(item =>
                        <tr>
                            <td>{item.historico[0].nome_atual || '--'}</td>
                            <td>{item.historico[0].nome_anterior || '--'}</td>
                            <td>{item.justificativa || '--'}</td>
                            <td>{item.usuario || '--'}</td>
                            <td>{item.data_registro || '--'}</td>
                        </tr>)
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Histórico de Status',
                thead:
                    <tr>
                        <td className='coluna-2'>Status</td>
                        <td className='coluna-3'>Usuário</td>
                        <td className='coluna-2'>Data Alteração</td>
                        <td className='coluna-5'>Observação</td>
                    </tr>,
                tbody: !dados.historico_status ? (<><tr>
                    <td colspan="4">Sem Registro!</td>
                </tr></>) :
                    dados.historico_status.map(item =>
                        <tr>
                            <td>{item.descricao || '--'}</td>
                            <td>{item.usuario || '--'}</td>
                            <td>{item.data_registro || '--'}</td>
                            <td>{item.observacao || '--'}</td>
                        </tr>)
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Histórico de Data',
                thead:
                    <tr>
                        <td className='coluna-2'>Data Inicial</td>
                        <td className='coluna-2'>Data Final</td>
                        <td className='coluna-3'>Usuário</td>
                        <td className='coluna-2'>Data Registro</td>
                    </tr>,
                tbody: dados.historico_data == undefined || dados.historico_data.length < 1 ? (<><tr>
                    <td colspan="4">Sem Registro!</td>
                </tr></>) :
                    dados.historico_data.map(item =>
                        <tr>
                            <td>{item.data_inicial || '--'}</td>
                            <td>{item.data_final || '--'}</td>
                            <td>{item.usuario || '--'}</td>
                            <td>{item.data_registro || '--'}</td>
                        </tr>)
            }
        ]
    } : { titulo: 'Visualização de Escala Plantão' }

    return <VisualizacaoPadrao data={data} id={id} {...props} />
}

export default VisualizacaoAgenda