import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import ls from 'local-storage'
import { Link } from 'react-router-dom';
import AgendaPadrao from '../../../../components/AgendaPadrao'
import { BtnAcao, BtnAcaoLink, Excluir, PopoverPadrao } from '../../../../components/Acoes'
import { InputSimples, InputAutocompleteGenerico, InputTimePicker, InputCheckboxSm } from '../../../../components/Inputs'
import { InputPorArray, InputSocio, InputOrdenamento, InputEstabelecimentoSaude, InputCirurgiaoCombo } from '../../../../components/InputsAutoComplete'
import { useWindowSize, mensagens, useInterval } from '../../../../util/Utilitarios'
import { ModalPadraoCadastro } from '../../../../components/Modais'
import { BotaoPadrao } from '../../../../components/Botoes'
import { InputSwitchSemFormik } from '../../../../components/Inputs'
import { ordenarMultiplo } from '../../../../components/Ordenamentos'
import { consultar, get, excluir, putComAcao } from '../../../../util/AcessoApi'
import { getUsuario } from '../../../../util/Configuracoes'
import { removeAcentos } from '../../../../util/FuncoesComuns'
import { CancelarAgenda, CadastroSocioAgenda, ConfirmarAgendamento, CadastroPreferencial, FinalizarAgenda, CadastroObservacao, TrocarStatus, CadastroRapido, CadastroRapidoSuspensa } from '../pages/Cadastro'
import ExclusaoSocios from '../pages/ExclusaoSocios'
import { CadastroModalLembrete } from '../../lembrete/pages/Cadastro'
import { useHistory } from "react-router-dom";
import { DetalhesAgendamento } from '../pages/Visualizacao'
import { mostrarToast } from '../../../../components/Toasts'
import { ACOES } from '../../../../util/Enums'
import { IconeTooltip } from '../../../../components/Icones'
import { Formik, Form } from "formik";
import Yup, { validaHora } from '../../../../util/Validacoes'
import { CadastroModalRegistroAtividade } from '../../registro-atividades/pages/Cadastro'
import copy from 'copy-to-clipboard'

import moment from 'moment'

const AgendaAssociado = (props) => {
    const [dados, setDados] = useState([])
    const [width, height] = useWindowSize();
    const [dataResumoDia, setDataResumoDia] = useState({})
    const [verOcorrencias, setVerOcorrencias] = useState(false)
    const [dadoFiltrado, setDadoFiltrado] = useState([])
    const [arrayStatus, setArrayStatus] = useState([])
    const [showModalCadastrar, setShowModalCadastrar] = useState(false)
    const [showModalCadastrarLembrete, setShowModalCadastrarLembrete] = useState(false)
    const [showModalCadastrarRegistroAtividade, setShowModalCadastrarRegistroAtividade] = useState(false)
    const [showModalDia, setShowModalDia] = useState(false)
    const [showModalAdicionarSocio, setShowModalAdicionarSocio] = useState(false)
    const [showModalPreferencial, setShowModalPreferencial] = useState(false)
    const [showModalFinalizar, setShowModalFinalizar] = useState(false)
    const [showModalObservacao, setShowModalObservacao] = useState(false)
    const [showModalCancelar, setShowModalCancelar] = useState(false)
    const [showModalConfirmacao, setShowModalConfirmacao] = useState(false)
    const [rolagemAutomatica, setRolagemAutomatica] = useState(false)
    const [filtros, setFiltros] = useState({})
    const [filtrosOff, setFiltrosOff] = useState({})
    const [filtrosOff2, setFiltrosOff2] = useState({})
    const [filtroAberto, setFiltroAberto] = useState(false)
    const [filtrosResumoDia, setFiltrosResumoDia] = useState([])
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [itemDetalhes, setItemDetalhes] = useState({})
    const [itemAcao, setItemAcao] = useState({})
    const [itemConfirmar, setItemConfirmar] = useState({})
    const [enviar, setEnviar] = useState(false)
    const [arrayEnvio, setArrayEnvio] = useState([])
    const [enviando, setEnviando] = useState(false)
    const [todosMarcados, setTodosMarcados] = useState(false)
    const [possuiPermissaoEnvio, setPossuiPermissaoEnvio] = useState(false)
    const [showModalTrocarStatus, setShowModalTrocarStatus] = useState(false)
    const user = getUsuario()
    const [permissoes, setPermissoes] = useState(null)
    const [atualizarLembrete, setAtualizarLembrete] = useState(false)
    const [atualizarRegistro, setAtualizarRegistro] = useState(false)
    const refAgenda = useRef()
    const [hash, setHash] = useState('')
    const history = useHistory();
    const [valid, setValid] = useState(false);
    const [showExclusaoSocios, setShowExclusaoSocios] = useState(false)
    const [copiado, setCopiado] = useState(false)

    const filtrarHora = (validacao, nomeCampo) => {
        let novoFiltro = { ...setValid }
        novoFiltro[nomeCampo] = validacao
        setValid(novoFiltro)
    }

    const handleChange = (e, name) => {
        const value = e.currentTarget.value;
        filtrarHora(Yup.string()
            .test('Hora Inicial', 'Hora inválida!', function (value) { return validaHora(this.parent[name]) }), name)
    };

    const [validacaoFiltros] = useState(Yup.object({
        hora_inicial: Yup.string()
            .test('Hora Inicial', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_inicial) }),
        hora_final: Yup.string().nullable()
            .test('Hora Final', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_final) }),
    }))

    useEffect(() => {
        if (enviar)
            setPodeRenderizar(true);
    }, [enviar])

    const [colunas, setColunas] = useState([
        {
            nome: 'executantes_v2',
            label: 'Sócio(s)',
            tamanho: '29%',
            classe: 'fs-13',
            mostrarSimples: true
        },
        {
            nome: 'procedimentos_v2',
            label: 'Procedimento(s)',
            tamanho: '40%',
            classe: 'fs-13 color-gray'
        },
        {
            nome: 'cirurgiao_descricao_personalizada',
            label: 'Cirurgião',
            tamanho: '25%',
            classe: 'fs-13 color-gray'
        },
        {
            nome: 'preferencias',
            label: 'Pref.',
            tamanho: '6%',
            classe: 'fs-13 color-gray bold ' + (window.innerWidth > 767 ? 'text-center' : '')
        }])

    const obterDados = (filtragem) => {
        if (filtragem.modo_visualizacao) {
            refAgenda.current.iniciarLoading();
            consultar('/administracao/agendareserva', (val, filtragemParam) => {
                let t1 = new Date();
                if (refAgenda.current.filtros.data_inicial === filtragemParam.data_inicial && refAgenda.current.filtros.modo_visualizacao === filtragemParam.modo_visualizacao) {
                    setDados(val)
                }
                let t2 = new Date();
            }, () => { }, filtragem)
            consultar('/administracao/obterHashAgendaReserva', val => { setHash(val.hash) }, null, filtragem)
        }
    }

    useInterval(() => {
        if (history.location.pathname.includes('agendareserva/consulta'))
            consultar('/administracao/obterHashAgendaReserva', val => {
                if (val && val.hash !== hash) {
                    obterDados(filtros)
                    setPodeRenderizarInterval(true)
                }
            }, null, filtros)
    }, 10000)

    const obterStatusCalendario = () => {
        Promise.resolve(get('/administracao/agendareservastatus')).then(val => {
            setArrayStatus(val)
        })
    }
    useEffect(() => {



        Promise.resolve(get('/controleAcesso/permissao', { usuario_id: user.id, modulo_id: 38, acao_id: 10 })).then(function (val) {
            setPossuiPermissaoEnvio(val.length > 0)
        })

        Promise.resolve(get('/controleAcesso/permissao/obterTodas', { usuario_id: user.id })).then(function (val) {
            setPermissoes(val.find(x => x.modulo_id === 38))
        })

        let filtrosLs = JSON.parse(sessionStorage.getItem('filtros_consulta_agenda'))
        obterDados({});
        if (filtrosLs && Object.keys(filtrosLs)) {
            if (filtrosLs.ordenamento && filtrosLs.ordenamento.length > 0) {
                const ordFiltro = filtrosLs.ordenamento.map(x => {
                    delete x.label
                    return {
                        label: <div>{x.label2.toString().replace("- Asc", " ").replace("- Desc", " ")}{x.label2.toString().toLowerCase().indexOf("asc") > 0 ? <span className="icon-Down icone-ordenamento"></span> : <span className="icon-Up icone-ordenamento"></span>}</div>,
                        ...x
                    }
                })
                filtrosLs.ordenamento = ordFiltro
            }
            setFiltrosOff(filtrosLs)
        }
        obterStatusCalendario();
        const ra = ls.get('rolagem_automatica')
        setTimeout(() => {
            setRolagemAutomatica(ra)
        }, 1000)
    }, [])



    const [PREFERENCIAL, setPreferencial] = useState(false)
    const [OBSERVACAO_AGENDA, setObservacaoAgenda] = useState(false)
    const [FINALIZAR_AGENDA, setFinalizarAgenda] = useState(false)
    const [CONFIRMAR_AGENDA, setConfirmarAgenda] = useState(false)
    const [TROCAR_SOCIO, setTrocarSocio] = useState(false)

    useEffect(() => {
        if (permissoes == null || permissoes.length < 1)
            return;

        setConfirmarAgenda(permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.CONFIRMAR_AGENDA) > 0)
        setFinalizarAgenda(permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.FINALIZAR_AGENDA) > 0)
        setObservacaoAgenda(permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.OBSERVACAO_AGENDA) > 0)
        setPreferencial(permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.PREFERENCIAL) > 0)
        setTrocarSocio(permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.TROCAR_SOCIO) > 0)


    }, [permissoes])

    useEffect(() => {
        setPodeRenderizar(true);
        if (dados.length > 0 && arrayEnvio.length === dados.filter(item => item.status_id === 2 || item.status_id === 3 || item.status_id === 5).length) {
            setTodosMarcados(true)
        } else {
            setTodosMarcados(false)
        }
    }, [arrayEnvio])

    const [podeRenderizar, setPodeRenderizar] = useState(false)
    const [podeRenderizarInterval, setPodeRenderizarInterval] = useState(false)
    useEffect(() => {
        if (itemAcao && itemAcao.id)
            atualizarItem()

        setPodeRenderizar(true);
    }, [dadoFiltrado])

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    useEffect(() => {
        setPodeRenderizar(false);
        if (filtros.modo_visualizacao) {
            obterDados(filtros);
        }
    }, [filtros])

    useEffect(() => {
        refAgenda.current.iniciarLoading();
        //salvando na session
        sessionStorage.setItem('filtros_consulta_agenda', JSON.stringify(filtrosOff));

        let dadosNovos = [...dados]
        if (filtrosOff.status) {
            dadosNovos = dadosNovos.filter(item => filtrosOff.status.length === 0 ||
                (filtrosOff.status && filtrosOff.status.findIndex(itemSts => itemSts === item.status_id) > -1))
        }

        if (filtrosOff.socio) {
            dadosNovos = dadosNovos.filter(item => filtrosOff.socio.length === 0 ||
                JSON.parse('[' + item.executantes_ids + ']').filter(id => filtrosOff.socio.findIndex(executante => executante.value === id) > -1).length > 0)
        }

        if (filtrosOff.cirurgiao) {
            dadosNovos = dadosNovos.filter(item => filtrosOff.cirurgiao.length === 0 ||
                JSON.parse('[' + item.cirurgiao_id + ']').filter(id => filtrosOff.cirurgiao.findIndex(executante => executante.value === id) > -1).length > 0)
        }

        if (filtrosOff.solicitante) {
            dadosNovos = dadosNovos.filter(item => filtrosOff.solicitante.length === 0 ||
                JSON.parse('[' + item.cirurgiao_id + ']').filter(id => filtrosOff.solicitante.findIndex(solicitante => solicitante.value === id) > -1).length > 0)
        }

        if (filtrosOff.estabelecimento_saude) {
            dadosNovos = dadosNovos.filter(item => filtrosOff.estabelecimento_saude.length === 0 ||
                JSON.parse('[' + item.estabelecimento_saude_id + ']').filter(id => filtrosOff.estabelecimento_saude.findIndex(estabelecimento_saude => estabelecimento_saude.value === id) > -1).length > 0)
        }

        if (filtrosOff.plantao) {
            dadosNovos = dadosNovos.filter(item => filtrosOff.plantao.length === 0 ||
                (filtrosOff.plantao && filtrosOff.plantao.findIndex(pergunta => pergunta.value === item.plantao) > -1))
        }

        if (filtrosOff.hora_inicial) {
            dadosNovos = dadosNovos.filter(item => {
                let horaInicial = item.hora_inicial;

                if (!horaInicial)
                    return false

                if (filtrosOff.hora_inicial) {
                    return moment(filtrosOff.hora_inicial, 'HH:mm') <= moment(horaInicial, 'HH:mm')
                }

                return false;
            })
        }

        if (filtrosOff.hora_final) {
            dadosNovos = dadosNovos.filter(item => {
                let horaFinal = item.hora_inicial;

                if (!horaFinal)
                    return false

                if (filtrosOff.hora_final) {
                    return moment(filtrosOff.hora_final, 'HH:mm') >= moment(horaFinal, 'HH:mm')
                }

                return false;
            })
        }

        const ordenamento = []

        if (filtrosOff.ordenamento)
            for (let i = 0; i < filtrosOff.ordenamento.length; i++) {
                ordenamento.push({
                    coluna: filtrosOff.ordenamento[i].value,
                    ordenamento: {
                        tipo: filtrosOff.ordenamento[i].tipo,
                        modo: filtrosOff.ordenamento[i].modo,
                        ordem: i + 1
                    }
                })
            }
        else
            ordenamento.push({
                coluna: 'status_ordem',
                ordenamento: {
                    tipo: 'numero',
                    modo: 'asc',
                    ordem: 1
                }
            }, filtroOrdenamento('hora_inicial', 2), filtroOrdenamento('hora_final', 3))

        dadosNovos = ordenarMultiplo(dadosNovos, ordenamento)

        setDadoFiltrado(dadosNovos)
        if (podeRenderizarInterval) {
            setPodeRenderizar(true)
            setPodeRenderizarInterval(false)
        }
        else
            setPodeRenderizar(false)
    }, [filtrosOff, dados])

    const filtroOrdenamento = (coluna, ordem) => {
        return {
            coluna: coluna,
            ordenamento: {
                tipo: 'data',
                modo: 'asc',
                ordem: ordem
            }
        }
    }

    const acaoClickSocioProxAnt = (proximo) => {
        let itemParam = { ...itemAcao }
        let idx = dadoFiltrado.findIndex(x => x.id === itemParam.id)

        if (idx > -1) {
            if (proximo) {
                setItemAcao(dadoFiltrado[(idx + 1) < dadoFiltrado.length ? idx + 1 : 0])
            } else {
                setItemAcao(dadoFiltrado[idx === 0 ? dadoFiltrado.length - 1 : idx - 1])
            }
        }
    }

    const exclusao = (item, caminhoExcluir, descricao_auditoria, dataExcluir) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir(caminhoExcluir, dataExcluir ? { id: item.id, data_excluir: dataExcluir } : item.id,
                    () => {
                        obterDados(filtros);
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        try {
                            JSON.parse(e.response.data.erros).forEach((item) => mostrarToast('erro', item))
                        } catch (e2) {
                            if (e.response && e.response.data && e.response.data.erros)
                                e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                            else
                                mostrarToast('erro', mensagens.erro)
                        }
                    }, descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => { mostrarToast('erro', mensagens['erro']) })
    }

    const atualizarItem = () => {
        let itemParam = { ...itemAcao }
        setItemAcao({})
        let idx = dadoFiltrado.findIndex(x => x.id === itemParam.id)

        if (idx > -1) {
            setItemAcao(dadoFiltrado[idx])
        } else {
            setShowModalAdicionarSocio(false)
            // mostrarToast('erro', 'Registro inexistente!')
        }
    }

    const onChangeFiltro = (filtrosTotal) => {
        setFiltros(filtrosTotal)
    }

    const obterDetalhes = async (id) => {
        setItemDetalhes({ agendamento: [{ titulo: 'Carregando...' }] })
        let dados = {}

        await consultar(`/administracao/trocasociohistorico`, (val) => {
            dados.trocaSocioHistorico = val;
        }, () => { }, { id })

        await Promise.resolve(get(`/administracao/agendareserva/${id}`)).then(val => {
            dados.agendamento = [
                { titulo: 'Estabelecimento de Saúde', descricao: val.estabelecimento_saude.label },
                { titulo: 'Data Inicial', descricao: val.data_inicial + ' ' + val.hora_inicial },
                { titulo: 'Data Final', descricao: val.data_final && val.data_final + ' ' + val.hora_final },
                { titulo: 'Cirurgião', descricao: val.cirurgiao ? val.cirurgiao.label || '--' : '--' },
                { titulo: 'Plantão?', descricao: val.plantao ? "Sim" : "Não" },
                { titulo: 'Sequencial?', descricao: val.sequencial ? "Sim" : "Não" },
                { titulo: 'Observação', descricao: val.observacao || <i>Não há Observações</i> },
                {
                    titulo: 'Sócio(s)', descricao: val.executante.length ? <ul className='lista-modal'>{val.executante.map(item => <li className="mb-1">
                        <span>{item.label}</span>
                        {item.status ?
                            <div className='row-space-between'>
                                <div></div>
                                <span className={'tag tag-' + item.status}>{item.status}</span>
                            </div>
                            : <></>}
                    </li>)}</ul> : '--'
                },
                {
                    titulo: 'Sócio Preferencial', descricao: val.preferencia.length ? <ul className='lista-modal'>{val.preferencia.map(item => <li className="mb-1">
                        <span>{item.label}</span>
                    </li>)}</ul> : '--'
                },
                {
                    titulo: 'Procedimentos', descricao: val.procedimentos.length ?
                        <ul className='lista-modal'>
                            {val.procedimentos.map(item => {
                                let hora = [];

                                if (item.hora_final)
                                    hora.push(item.hora_inicial)
                                if (item.hora_final)
                                    hora.push(item.hora_final)

                                return <li className="mb-1">
                                    <div className='mb-2'><span><b>Hora: </b>{hora.length ? hora.join(' - ') : '--'}</span></div>
                                    <div className='mb-2'><span><b>Quantidade: </b>{item.quantidade || '--'}</span></div>
                                    <div className='mb-2'><span><b>Paciente: </b>{item.paciente && item.paciente.label ? item.paciente.label : '--'}</span></div>
                                    <div><span><b>Procedimento: </b>{item.procedimento.label}</span></div>
                                </li>
                            })}</ul> : '--'
                },
                {
                    titulo: 'Pacientes', descricao: val.pacientes.length ?
                        <ul className='lista-modal'>
                            {val.pacientes.map(item => <li className="mb-1">
                                <div className='mb-2'><span><b>Beneficiario: </b>{item.beneficiario && item.beneficiario.label ? item.beneficiario.label : '--'}</span></div>
                                <div className='mb-2'><span><b>Atendimento: </b>{item.atendimento || '--'}</span></div>
                                <div className='mb-2'><span><b>Matrícula: </b>{item.matricula || '--'}</span></div>
                                <div className='mb-2'><span><b>Contrato: </b>{item.contrato && item.contrato.label ? item.contrato.label : '--'}</span></div>
                                <div className='mb-2'><span><b>Operadora de Saúde: </b>{item.operadora_saude && item.operadora_saude.label ? item.operadora_saude.label : '--'}</span></div>
                                <div className='mb-2'><span><b>Particular? </b>{item.particular ? 'Sim' : 'Não'}</span></div>
                            </li>
                            )}</ul> : '--'
                },
                { titulo: 'Equipe/Repasse', descricao: val.equipe_repasse.length ? <ul className='lista-modal'>{val.equipe_repasse.map(item => <li className="mb-1"><span>{item.label}</span></li>)}</ul> : '--' },
            ]
        })

        await Promise.resolve(get(`/administracao/agendareservastatushistorico/${id}`)).then(val2 => {
            dados.historico = val2;
        })

        await Promise.resolve(get(`/administracao/agendareservahistoricodata/${id}`)).then(val2 => {
            dados.historico_data = val2;
        })

        setItemDetalhes(dados)
    }

    const validarPermissao = (linha, acao, action) => {
        if (moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY') > moment(linha.data_inicial, 'DD/MM/YYYY')) {
            get('/administracao/permissaoAlterarAposData', { usuario: user.id, acao, usuario_tipo: user.tipo, id: linha.id, modulo: 45 }, true)
                .then(result => !result || result.length > 0 ? action()
                    : mostrarToast('erro', 'Operação bloqueada! Data anterior a data atual.'))
                .catch(err => { })
        }
        else
            action()
    }

    const obterAcoes = (linha, modal) => {

        return linha && permissoes ?
            <>
                {modal === true ? <></> :
                    (user.tipo !== 4 && permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.ALTERAR_STATUS_AGENDA) > 0) && <BtnAcao classTooltip={modal ? 'tooltip-input-modal' : ''} classeContent="ml-1-between" icone="icon-Arrow-Around" texto="Trocar Status" action={_ => validarPermissao(linha, 20, _ => { setItemAcao(linha); setShowModalTrocarStatus(true) })} {...props} />}
                {modal === true ? <></> :
                    (user.tipo !== 4 && linha.status_id !== 7 && linha.status_id !== 6) && TROCAR_SOCIO && <BtnAcao classTooltip={modal ? 'tooltip-input-modal' : ''} classeContent="ml-1-between" icone="icon-Add-User" texto="Adicionar/Trocar Sócio" action={_ => validarPermissao(linha, 22, _ => { setItemAcao(linha); setShowModalAdicionarSocio(true) })} {...props} />}
                {!PREFERENCIAL || modal === true ? <></> : linha.status_id !== 7 && linha.status_id !== 6 ? <BtnAcao classTooltip={modal ? 'tooltip-input-modal' : ''} classeContent="ml-1-between" icone="icon-Add-UserStar" texto="Adicionar Preferencial" action={_ => { setItemAcao(linha); setShowModalPreferencial(true) }} {...props} /> : <></>}

                {modal === true ? <></> : (user.tipo !== 4 && linha.status_id !== 7 && linha.status_id !== 6) && linha.executantes_ids && linha.executantes_ids.length > 0 && permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.EXCLUIR_SOCIOS) > 0
                    && <BtnAcao classTooltip={modal ? 'tooltip-input-modal' : ''} classeContent="ml-1-between" icone="icon-Remove-User" texto="Remover Sócios" action={_ => validarPermissao(linha, 23, _ => { setItemAcao(linha); setShowExclusaoSocios(true); })} {...props} /> || <></>}

                {!(permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.OBSERVACAO_AGENDA) > 0) ? <></> : <BtnAcao classTooltip={modal ? 'tooltip-input-modal' : ''} classeContent="ml-1-between" icone="icon-Speach-Bubble3" texto="Adicionar Observação" action={_ => { setItemAcao(linha); setShowModalObservacao(true) }} {...props} />}
                {user.tipo !== 4 && linha.status_id !== 7 && permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.INSERIR) > 0 ? <BtnAcao classTooltip={modal ? 'tooltip-input-modal' : ''} classeContent="ml-1-between" icone="icon-File-Copy2" texto="Duplicar" action={_ => validarPermissao(linha, 2, _ => props.history.push('/administracao/agendareserva/duplicar/' + linha.id))} {...props} /> : <></>}
                {(user.tipo === 4 && linha.status_id !== 1) || linha.status_id !== 7 && permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.ALTERAR) > 0 && <BtnAcao classTooltip={modal ? 'tooltip-input-modal' : ''} classeContent="ml-1-between" icone="icon-Pen" texto="Alterar" action={_ => validarPermissao(linha, 3, _ => props.history.push('/administracao/agendareserva/alteracao/' + linha.id))} {...props} /> || <></>}
                {permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.VISUALIZAR) > 0 && <BtnAcao icone="icon-Books" texto="Detalhes" classTooltip={modal ? 'tooltip-input-modal' : ''} action={() => {
                    obterDetalhes(linha.id)
                    setShowModalDetalhes(true)
                }} {...props} />}
                {permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.VISUALIZAR) > 0 && <BtnAcaoLink classTooltip={modal ? 'tooltip-input-modal' : ''} icone="icon-File-Search" texto="Visualizar" to={'/administracao/agendareserva/visualizacao/' + linha.id} />}
                {linha && (linha.status_id === 6 || linha.status_id === 7 || linha.status_id === 1 || !FINALIZAR_AGENDA) ? <></> : <BtnAcao classTooltip={modal ? 'tooltip-input-modal' : ''} classeContent="ml-1-between" icone="icon-Check" texto="Finalizar" action={_ => { setItemAcao(linha); setShowModalFinalizar(true) }} {...props} />}
                {linha && linha.status_id !== 1 && linha.status_id !== 2 && linha.status_id !== 7 && linha.status_id !== 6 && linha.executantes_ids.length && CONFIRMAR_AGENDA ? <BtnAcao classTooltip={modal ? 'tooltip-input-modal' : ''} icone="icon-Yes" texto="Confirmar" action={() => { setShowModalConfirmacao(true); setItemConfirmar(linha) }} {...props} /> : <></>}
                {(user.tipo === 4 && linha.status_id !== 1) || linha.status_id !== 7 && permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.CANCELAR_AGENDA) > 0 && <BtnAcao classTooltip={modal ? 'tooltip-input-modal' : ''} classeContent="ml-1-between" icone="icon-Close" texto="Cancelar" action={_ => validarPermissao(linha, 17, _ => { setItemAcao(linha); setShowModalCancelar(true) })} {...props} /> || <></>}
                {/* {modal === true || !FINALIZAR_AGENDA ? <></> :
                    (user.tipo !== 4 && linha.status_id !== 7 && linha.status_id !== 6) && <BtnAcao classTooltip={modal ? 'tooltip-input-modal' : ''} classeContent="ml-1-between" icone="icon-Close" texto="Cancelar" action={_ => validarPermissao(linha, 17, _ => { setItemAcao(linha); setShowModalCancelar(true) })} {...props} />} */}
                {(user.tipo === 4 && linha.status_id !== 1) || linha.status_id !== 7 && permissoes && permissoes.permissoes.find(x => parseInt(x) === ACOES.EXCLUIR) > 0 && <Excluir icone="icon-New-Trash" classTooltip={modal ? 'tooltip-input-modal' : ''} texto="Excluir" action={() => validarPermissao(linha, 4, _ => exclusao(linha, '/administracao/agendareserva', linha.descricao_auditoria))} {...props} /> || <></>}
            </>
            : <></>

    }

    const layoutDiaCalendario = (dia) => {
        let dadosDia = dadoFiltrado.filter(item => item.data_inicial === dia.format("DD/MM/YYYY"))
        return <div style={{ backgroundColor: 'transparent', gridColumn: (dia.day() + 1) + ' / ' + (dia.day() + 1) }}>
            <div className="calendario-agendamentos" onClick={() => clickDiaCalendario(dia)}>
                <ul class="lista-selecao mb-3">
                    {arrayStatus.map(itemStatus => {
                        let dados = dadosDia.filter(item => item.status_id === itemStatus.id).length
                        return <li class="nav-item p-0 text-center"><span className={'tag'} style={dados ? { background: itemStatus.cor, color: 'white' } : { display: 'none' }}>{width > 1150 && dados ? itemStatus.descricao + '(' + dados + ')' : ''}</span></li>
                    })}
                    <li class="nav-item p-0">
                        <p className="text-center">total: {dadosDia.length}</p>
                    </li>
                </ul>

            </div>
        </div>
    }

    const clickDiaCalendario = (dia) => {
        setShowModalDia(true);
        const resumoDia = {}
        resumoDia.data = dia.format('LL')
        resumoDia.dados = dadoFiltrado.filter(item => item.data_inicial === dia.format("DD/MM/YYYY"))
        setDataResumoDia(resumoDia)
    }

    const check = (event, id, idsSocios) => {
        if (event != undefined) {
            event.preventDefault()
        }
        let cloneArray = [...arrayEnvio]
        if (arrayEnvio.findIndex(x => x.id === id) > -1) {
            cloneArray.splice(arrayEnvio.findIndex(x => x.id === id), 1)
        } else {
            cloneArray.push({ id: id, socios: idsSocios })
        }

        setArrayEnvio(cloneArray);
    }

    const checkSocio = (id, idSocio) => {
        let cloneArray = [...arrayEnvio]
        let cloneItem = cloneArray[cloneArray.findIndex(x => x.id === id)]
        if (!cloneItem) {
            check(undefined, id, [idSocio])
            return;
        }

        let cloneArraySocios = cloneItem ? [...cloneItem.socios] : []
        if (cloneArraySocios.includes(idSocio)) {
            cloneArraySocios.splice(cloneArraySocios.indexOf(idSocio), 1)
        } else {
            cloneArraySocios.push(idSocio)
        }

        if (cloneArraySocios.length === 0) {
            check(undefined, id, [])
        } else {
            cloneItem.socios = cloneArraySocios;
            cloneArray[cloneArray.findIndex(x => x.id === id)] = cloneItem;
            setArrayEnvio(cloneArray);
        }

    }

    const verificarCheck = (id, idSocio) => {
        let cloneItem = arrayEnvio[arrayEnvio.findIndex(x => x.id === id)]
        return cloneItem !== undefined && cloneItem.socios.includes(idSocio)
    }

    const copyToClipboard = (text) => {
        setCopiado(true)
        copy(text)
    }

    const copyHtml = useCallback(() => copiado ? <b>Copiado!</b> : <>Clique para copiar</>, [copiado]);

    const layoutLinha = (linha, ref, idx) => {
        const hora = []
        const executantes = JSON.parse('[' + linha.executantes_v2 + ']')
        const widthWindow = window.innerWidth
        const estabelecimento = { ...linha.estabelecimento_obs }
        const estabelecimento_saude_nome = (linha.estabelecimento_saude_codigo ? linha.estabelecimento_saude_codigo : linha.estabelecimento_saude_id) + ' - ' + linha.estabelecimento_saude_descricao
        hora.push(linha.hora_inicial)

        if (linha.hora_final)
            hora.push(linha.hora_final)

        return (<>
            <div className='card-row-agenda p-5-after card-envio-row' ref={ref}>
                {
                    enviar && (linha.status_id === 2 || linha.status_id === 3 || linha.status_id === 5) && widthWindow > 767 ?
                        <div className={'switch-envio-card ' + (enviar ? '' : 'hide')} onClick={(e) => check(e, linha.id, executantes.map(item => item.id))}>
                            <InputSwitchSemFormik onClick={(e) => check(e, linha.id, executantes.map(item => item.id))} checked={arrayEnvio.findIndex(x => x.id === linha.id) > -1} classeDiv='form-group switch-center' name='plantao' size="md" tamanho={'col-lg-1'} classeTooltip={'tooltip-input-modal'} />
                        </div> : <></>
                }
                <div className={'card card-envio'}>
                    <div className='row m-0'>
                        <div className='col-lg-2 col-md-4 text-left column'>

                            {(enviar && (linha.status_id === 3 || linha.status_id === 5)) && widthWindow < 768 ? <InputSwitchSemFormik onClick={(e) => check(undefined, linha.id, executantes.map(item => item.id))} checked={arrayEnvio.findIndex(x => x.id === linha.id) > -1} classeDiv='form-group' name='plantao' size="md" tamanho={'col-lg-1'} classeTooltip={'tooltip-input-modal'} /> : <></>}
                            <div className={'mb-1 col-hora ' + (widthWindow > 767 ? '' : 'row-space-between')}>

                                <div>

                                    {linha.plantao ? <IconeTooltip icone="icon-Clock" className='mr-1 color-red' texto="Plantão" /> : <></>}
                                    <span style={{ whiteSpace: 'nowrap' }}><b>{hora.join(' - ')}</b></span>
                                    {linha.sequencial === true ? <IconeTooltip className='sequencial' texto="Sequencial" char="S" /> : <></>}
                                    {linha.observacao && linha.observacao.length > 0 ?

                                        <PopoverPadrao id={linha.id} copiado={copiado} onToggle={() => setCopiado(false)} titulo={<>
                                            <div className='text-center color-gray mb-1' style={{ fontSize: 13, borderBottomWidth: 1, borderBottomColor: '#ddd', borderBottomStyle: 'solid', paddingBottom: 5 }}><span>{copiado === true ? <b>Copiado!</b> : <>Clique para copiar</>}</span></div>
                                            <span className={'procedimentos fs-13 color-gray ml-1'}><b>
                                                {linha.observacao}</b>
                                            </span></>}
                                        >
                                            <span className={'procedimentos fs-13 color-gray ml-1 cursor-pointer'} onClick={() => copyToClipboard(linha.observacao)}><b>
                                                {<span className='icon-observacao ml-1 red-color flip-icon'></span>}</b>
                                            </span>
                                        </PopoverPadrao>
                                        // <IconeTooltip icone="icon-Speach-Bubble3" className='ml-1' texto="Observação" />
                                        : <></>}
                                    {widthWindow > 767 && <span className={'tag'} style={{ background: linha.cor, color: 'white' }}>{linha.status}</span>}
                                </div>
                                {widthWindow < 768 && <><span className={'tag'} style={{ background: linha.cor, color: 'white' }}>{linha.status}</span></>}
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-3 text-left column'>
                            <div className='mb-1 col-hora'>
                                <PopoverPadrao id={linha.id} copiado={copiado} onToggle={() => setCopiado(false)} titulo={<>
                                    <div className='text-center color-gray mb-1' style={{ fontSize: 13, borderBottomWidth: 1, borderBottomColor: '#ddd', borderBottomStyle: 'solid', paddingBottom: 5 }}><span>{copiado === true ? <b>Copiado!</b> : <>Clique para copiar</>}</span></div>
                                    <span className={'procedimentos fs-13 color-gray ml-1'}><b>
                                        {estabelecimento_saude_nome}</b>
                                    </span></>}
                                    conteudo={<div id='desc-estabelecimento'><div><span className="color-gray"><b>Tel: </b>{estabelecimento.telefone || '--'}</span></div>
                                        <div><span className="color-gray"><b>Logradouro: </b>{estabelecimento.logradouro || '--'}</span></div>
                                        {estabelecimento.observacao && <div className="bt-1 mt-1"><span className="color-gray">{estabelecimento.observacao || '--'}</span></div>}
                                    </div>}>

                                    <span className={'procedimentos fs-13 color-gray ml-1 cursor-pointer'} onClick={() => copyToClipboard(estabelecimento_saude_nome + '\r\nTel: ' + (estabelecimento.telefone || '--') +
                                        '\r\nEndereço: ' + (estabelecimento.logradouro || '--') + (estabelecimento.observacao ? '\r\nObservação: ' + estabelecimento.observacao : ''))}><b>
                                            {estabelecimento_saude_nome}</b>
                                    </span>
                                </PopoverPadrao>
                            </div>
                        </div>
                        <div className={'col-lg-6 col-md-5 column ' + (widthWindow > 767 ? 'text-right' : 'text-center')}>
                            {obterAcoes(linha)}
                        </div>
                    </div>
                    <div className='row m-0'>
                        {widthWindow > 767 ? colunas && colunas.map(coluna => {
                            let jsonRet = []
                            let cssExecutante = {
                                paddingLeft: coluna.nome === 'executantes_v2' ? '15px !important' : 'unset'
                            }
                            if (coluna.nome === 'procedimentos_v2' || coluna.nome === 'executantes_v2') {
                                jsonRet = JSON.parse('[' + linha[coluna.nome] + ']');
                            }
                            if (coluna.nome === 'procedimentos_v2')
                                jsonRet.sort((a, b) => (a.idProcedimento > b.idProcedimento) ? 1 : ((b.idProcedimento > a.idProcedimento) ? -1 : 0))
                            return <div className={'column ' + coluna.classe} style={{ width: coluna.tamanho }}>
                                {coluna.nome === 'procedimentos_v2' || coluna.nome === 'executantes_v2' ? jsonRet.map(item =>
                                    <div className="mb-1" style={{ paddingLeft: '10px' }}>
                                        {(enviar && (linha.status_id === 2 || linha.status_id === 3 || linha.status_id === 5)) && coluna.nome === 'executantes_v2' ?
                                            <InputCheckboxSm checked={verificarCheck(linha.id, item.id)} onChange={() => checkSocio(linha.id, item.id)} /> : <></>}
                                        <span className={'mb-1'}>
                                            {(item.quantidade !== undefined && item.quantidade.length > 0 ? <b>{`( ${item.quantidade} ) `}</b> : '')}
                                            {item.codigo ? (coluna.nome === 'executantes_v2' ? <><b>{item.codigo}</b> - </> : <>{item.codigo} - </>) : <></>}
                                            {item.descricao}
                                        </span>
                                    </div>) :
                                    coluna.nome === 'preferencias' ?
                                        < span className={'mb-1'} style={{ textDecoration: 'underline' }}>
                                            {linha[coluna.nome]}
                                        </span>
                                        :
                                        linha[coluna.nome]}
                            </div>
                        }) : colunas && colunas.map(coluna => {
                            let jsonRet = []
                            if (coluna.nome === 'procedimentos_v2' || coluna.nome === 'executantes_v2') {
                                jsonRet = linha[coluna.nome.split('_')[0]].replace('""', '').length ? JSON.parse('[' + linha[coluna.nome] + ']') : [];
                            }
                            return <div className={'column col-12 ' + coluna.classe}>
                                <div className='mb-2'><span><b>{coluna.label + ': '} </b></span></div>
                                {coluna.nome === 'procedimentos_v2' || coluna.nome === 'executantes_v2' ? (jsonRet.length > 0 ? jsonRet.map(item => <><p className='mb-1'>
                                    {(item.quantidade !== undefined && item.quantidade.length > 0 ? <b>{`( ${item.quantidade} ) `}</b> : '')}
                                    {item.codigo ? (coluna.nome === 'executantes_v2' ? <><b>{item.codigo}</b> - </> : <>{item.codigo} - </>) : <></>}
                                    {item.descricao}</p></>) : <>--</>) || <>--</> :
                                    coluna.nome === 'preferencias' ?
                                        <span className={'mb-1'} style={{ textDecoration: 'underline' }}>
                                            {linha[coluna.nome]}
                                        </span>
                                        :
                                        (linha[coluna.nome] || <>--</>)}
                            </div>
                        })}
                    </div>
                </div>
            </div></>
        )
    }

    const marcarTodos = (event) => {
        event.preventDefault()
        let cloneArray = []

        if (!todosMarcados) {
            cloneArray = (dados.filter(item => item.status_id === 2 || item.status_id === 3 || item.status_id === 5).map(item => (
                {
                    id: item.id,
                    socios: item.executantes_v2 ? JSON.parse('[' + item.executantes_v2 + ']').map(itemSocio => itemSocio.id) : []
                })))
        } else {
            cloneArray = []
        }
        setArrayEnvio(cloneArray);
    }

    const confirmarEnvio = () => {
        let obj = { envios: arrayEnvio };
        if (arrayEnvio.length === 0)
            return;

        setEnviando(true);
        putComAcao(
            ACOES.ENVIAR,
            undefined,
            obj,
            '/administracao/agendareservaenvio',
            () => {
                mostrarToast('sucesso', mensagens.sucesso);
                setEnviar(false);
                setEnviando(false);
                setArrayEnvio([]);
                setTimeout(() => {
                    obterDados(filtros);
                }, 200)
            },
            (erro) => {
                mostrarToast('erro', mensagens.erro);
                setEnviando(false);
            })
    }

    const settarRolagemAutomatica = () => {
        setRolagemAutomatica(!rolagemAutomatica)
        ls.set('rolagem_automatica', !rolagemAutomatica)
    }

    const filtarStatus = (idStatus) => {
        let novoFiltro = { ...filtrosOff }
        if (novoFiltro.status) {
            let idx = novoFiltro.status.findIndex(item => item === idStatus)
            if (idx > -1) {
                novoFiltro.status.splice(idx, 1)
            } else {
                novoFiltro.status.push(idStatus)
            }
        } else {
            novoFiltro.status = [idStatus]
        }
        setFiltrosOff(novoFiltro)
    }

    const filtrarCampo = (option, nomeCampo) => {
        let novoFiltro = { ...filtrosOff }
        novoFiltro[nomeCampo] = option

        setFiltrosOff(novoFiltro)
    }

    const ordenamento = [
        {
            label: 'Sócio - Asc',
            value: 'executantes_nome',
            tipo: 'texto',
            modo: 'asc'
        }, {
            label: 'Sócio - Desc',
            value: 'executantes_nome',
            tipo: 'texto',
            modo: 'desc'
        }, {
            label: 'Status - Asc',
            value: 'status',
            tipo: 'texto',
            modo: 'asc'
        }, {
            label: 'Status - Desc',
            value: 'status',
            tipo: 'texto',
            modo: 'desc'
        }, {
            label: 'Horário - Asc',
            value: 'hora_inicial',
            tipo: 'hora',
            modo: 'asc'
        }, {
            label: 'Horário - Desc',
            value: 'hora_inicial',
            tipo: 'hora',
            modo: 'desc'
        }, {
            label: 'Estabelecimento Saúde - Asc',
            value: 'estabelecimento_saude_descricao',
            tipo: 'texto',
            modo: 'asc'
        }, {
            label: 'Estabelecimento Saúde - Desc',
            value: 'estabelecimento_saude_descricao',
            tipo: 'texto',
            modo: 'desc'
        }, {
            label: 'Cirurgião - Asc',
            value: 'cirurgiao_nome',
            tipo: 'texto',
            modo: 'asc'
        }, {
            label: 'Cirurgião - Desc',
            value: 'cirurgiao_nome',
            tipo: 'texto',
            modo: 'desc'
        }
    ]

    return <div>
        {possuiPermissaoEnvio && dados && dados.filter(item => item.status_id === 2 || item.status_id === 3 || item.status_id === 5).length > 0 ? (enviar && !enviando ?
            <div className={'slide-up-bottom-div right-50' + (filtroAberto ? ' filtro-aberto' : '')}>
                <BotaoPadrao click={_ => confirmarEnvio()} type={'button'} classe={'btn-primary primary'} texto={'Confirmar'} />
                <BotaoPadrao click={_ => { setEnviar(false); setArrayEnvio([]) }} type={'button'} classe={'btn-primary primary'} texto={'Cancelar'} />
            </div>
            : <></>) : <></>}
        <div className={'slide-up-bottom-div ' + (filtroAberto ? 'margin-r-279' : 'right-5-neg')}>
            <BotaoPadrao icone='icon-Arrow-Up' classe=' btn primary btn-filter btn-scroll-top' idTooltip={"padrao"} click={_ => window.scrollTo(0, 0)} />
        </div>
        <AgendaPadrao ref={refAgenda} copiado={copiado} menuAberto={props.menuAberto} colunas={[{ nome: "estabelecimento_saude_pesquisa", apenasPesquisa: true }, ...colunas]} data={dadoFiltrado} possuiPermissaoEnvio={possuiPermissaoEnvio} dados={dados}
            onChangeFiltro={onChangeFiltro} obterAcoes={obterAcoes} enviando={enviando} setEnviar={setEnviar} permissoes={TROCAR_SOCIO} arrayEnvio={arrayEnvio} permissao={permissoes}
            onClickCadastrar={() => setShowModalCadastrar(!showModalCadastrar)}
            podeRenderizar={podeRenderizar} setPodeRenderizar={setPodeRenderizar}
            onClickCadastrarLembrete={() => setShowModalCadastrarLembrete(true)} layoutLinha={layoutLinha}
            onClickCadastrarRegistroAtividade={() => setShowModalCadastrarRegistroAtividade(true)} modulo={props.modulo}
            layoutDiaCalendario={layoutDiaCalendario} rolagemAutomatica={rolagemAutomatica} alturaSemana='200px'
            filtros={filtros} setFiltros={setFiltros} ordenar={false} enviarAgendamentos={() => setEnviar(true)} setFiltroAberto={setFiltroAberto} marcarTodos={marcarTodos} todosMarcados={todosMarcados} enviar={enviar}
            atualizarLembrete={atualizarLembrete} setAtualizarLembrete={setAtualizarLembrete} abrirFiltroModal={showModalCadastrar}
            atualizarRegistro={atualizarRegistro} setAtualizarRegistro={setAtualizarRegistro}
            showModalHeader={showModalCadastrar} modalHeader={<CadastroRapidoSuspensa abrirModal={showModalCadastrar}
                setAbrirModal={setShowModalCadastrar} funcaoAposSalvar={(val) => obterDados(filtros)} />}
            btnRmvFiltros={{
                showRmvOrd: filtrosOff.ordenamento, acaoRmvOrd: () => {
                    let clone = { ...filtrosOff }
                    clone.ordenamento = null
                    setFiltrosOff(clone)
                }, qtdFiltro: Object.keys(filtrosOff).filter(key => key != 'ordenamento' && filtrosOff[key] != null).length,
                acaoRmvFiltro: () => setFiltrosOff(filtrosOff.ordenamento ? { ordenamento: filtrosOff.ordenamento } : {})
            }}
        >
            {<>
                <div className='filtro mb-3'>
                    <div className='titulo-filtro row-space-between'>
                        <span className='vertical-center'>Rolagem Automática</span>
                    </div>
                    <div className='content-filtro'>
                        <InputSwitchSemFormik onClick={() => settarRolagemAutomatica()} checked={rolagemAutomatica} classeDiv='form-group' name='plantao' size="md" tamanho={'col-lg-1'} classeTooltip={'tooltip-input-modal'} />
                    </div>
                </div>
                <div className='filtro'>
                    <div className='titulo-filtro row-space-between'>
                        <span className='vertical-center'>Status</span>
                    </div>
                    <div className='content-filtro'>
                        <ul class="lista-selecao mb-3 row pl-15">
                            {arrayStatus.map(itemStatus => <li className='p-0 mr-1'>
                                <a href="#" onClick={() => filtarStatus(itemStatus.id)}>
                                    <span className={'tag active m-0 p-unset'}
                                        style={filtrosOff.status && filtrosOff.status.findIndex(item => item === itemStatus.id) > -1 ? { background: itemStatus.cor, color: 'white' } : { border: 1, borderColor: itemStatus.cor, borderStyle: 'solid', color: itemStatus.cor }} >{itemStatus.descricao + ' ( ' + dados.filter(item => item.status_id === itemStatus.id).length + ' )'}</span>
                                </a>
                            </li>)}
                        </ul>
                    </div>
                </div>
                <div className='filtro'>
                    <div className='titulo-filtro row-space-between'>
                        <span className='vertical-center'>Ordenamento</span>
                    </div>
                    <div className='content-filtro'>
                        <InputOrdenamento countDisplay={20} value={filtrosOff.ordenamento && filtrosOff.ordenamento || ''} multiplo={true} label="" name="ordenamento" tamanho='p-0 col-12' placeholder="Selecione" icone="icon-A-Z" {...props} data={ordenamento}
                            onChange={value => {
                                filtrarCampo(value, 'ordenamento')
                            }} />
                    </div>
                </div>
                <div className='filtro'>
                    <div className='titulo-filtro row-space-between'>
                        <span className='vertical-center'>Sócio</span>
                    </div>
                    <div className='content-filtro'>
                        <InputSocio multiplo name="socio" placeholder="Sócio" value={filtrosOff.socio} tamanho='p-0 col-12' onChange={(e) => {
                            filtrarCampo(e, 'socio')
                        }} minLength={1} />
                    </div>
                </div>
                <div className='filtro'>
                    <div className='titulo-filtro row-space-between'>
                        <span className='vertical-center'>Cirurgião</span>
                    </div>
                    <div className='content-filtro'>
                        <InputCirurgiaoCombo multiplo name="solicitante" botaoVisivel={false} placeholder="Cirurgião" value={filtrosOff.solicitante} tamanho='p-0 col-12' onChange={(e) => {
                            filtrarCampo(e, 'solicitante')
                        }} minLength={1} />
                    </div>
                </div>
                <div className='filtro'>
                    <div className='titulo-filtro row-space-between'>
                        <span className='vertical-center'>Estabelecimento Saúde</span>
                    </div>
                    <div className='content-filtro'>
                        <InputEstabelecimentoSaude multiplo name="estabelecimento_saude" botaoVisivel={false} placeholder="Estabelecimento Saúde" value={filtrosOff.estabelecimento_saude} tamanho='p-0 col-12' onChange={(e) => {
                            filtrarCampo(e, 'estabelecimento_saude')
                        }} minLength={1} />
                    </div>
                </div>
                <div className='filtro mb-3'>
                    <div className='titulo-filtro row-space-between'>
                        <span className='vertical-center'>Plantão</span>
                    </div>
                    <div className='content-filtro'>
                        <InputPorArray
                            tamanho='p-0 col-12'
                            placeholder='Plantão'
                            multiplo
                            label=''
                            name='plantao'
                            value={filtrosOff.plantao ? filtrosOff.plantao : []}
                            onChange={option => filtrarCampo(option, 'plantao')}
                            data={[{ value: true, label: 'SIM' }, { value: false, label: 'NÃO' }]} />
                    </div>
                </div>
                <Formik
                    initialValues={filtrosOff}
                    validationSchema={validacaoFiltros}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => { setFiltrosOff(values); }}>
                    {propsFormik => (<Form className="no-animation">
                        <div className='filtro'>
                            <div className='titulo-filtro row-space-between'>
                                <span className='vertical-center'>Hora Inicial</span>
                            </div>
                            <div className='content-filtro'>
                                <InputTimePicker
                                    label='' mascara="11:11" name="hora_inicial" type="text" placeholder="Hora Inicial" tamanho='p-0 col-12' propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'}
                                    value={filtrosOff.hora_inicial}
                                    myBlur={(valor) => {
                                        if (!valor || valor.length === 0 || valor.replace(/[_]/g, '').length === 5) {
                                            propsFormik.submitForm();
                                        }
                                    }}
                                    setarValor={value => {
                                        propsFormik.setFieldValue("hora_inicial", value, false)
                                        propsFormik.submitForm();
                                    }}
                                />
                            </div>
                        </div>
                        <div className='filtro'>
                            <div className='titulo-filtro row-space-between'>
                                <span className='vertical-center'>Hora Final</span>
                            </div>
                            <div className='content-filtro'>
                                <InputTimePicker
                                    label='' mascara="11:11" name="hora_final" type="text" placeholder="Hora Final" tamanho='p-0 col-12' propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'}
                                    value={filtrosOff.hora_inicial}
                                    myBlur={(valor) => {
                                        if (!valor || valor.length === 0 || valor.replace(/[_]/g, '').length === 5) {
                                            propsFormik.submitForm();
                                        }
                                    }}
                                    setarValor={value => {
                                        propsFormik.setFieldValue("hora_final", value, false)
                                        propsFormik.submitForm();

                                    }}
                                />
                            </div>
                        </div>
                    </Form>)}
                </Formik>
            </>}
        </AgendaPadrao>
        <div className='height-7'></div>
        <CadastroModalLembrete abrirModal={showModalCadastrarLembrete} dataAtual={filtros.data_inicial}
            setAbrirModal={setShowModalCadastrarLembrete} item={itemAcao}
            funcaoAposSalvar={(val) => { obterDados(filtros); setAtualizarLembrete(!atualizarLembrete); }}
            justificativa={itemAcao && itemAcao.executantes_ids && itemAcao.executantes_ids.length > 0} acoes={(item) => obterAcoes(itemAcao, true)} />
        <CadastroModalRegistroAtividade abrirModal={showModalCadastrarRegistroAtividade} dataAtual={filtros.data_inicial} modal
            setAbrirModal={setShowModalCadastrarRegistroAtividade} item={itemAcao}
            funcaoAposSalvar={(val) => { obterDados(filtros); setAtualizarRegistro(!atualizarRegistro); }}
            justificativa={itemAcao && itemAcao.executantes_ids && itemAcao.executantes_ids.length > 0} acoes={(item) => obterAcoes(itemAcao, true)} />
        <CancelarAgenda titulo={'Cancelar Agendamento'} abrirModal={showModalCancelar}
            setAbrirModal={setShowModalCancelar} item={itemAcao} acaoProx={_ => acaoClickSocioProxAnt(true, setItemAcao)} acaoAnte={_ => acaoClickSocioProxAnt(false, setItemAcao)}
            justificativa={itemAcao && itemAcao.executantes_ids && itemAcao.executantes_ids.length > 0} funcaoAposSalvar={(val) => { obterDados(filtros); setShowModalCancelar(false) }} acoes={(item) => obterAcoes(itemAcao, true)} />
        <CadastroSocioAgenda titulo={'Adicionar Sócio'} abrirModal={showModalAdicionarSocio} modulo_id={38}
            setAbrirModal={setShowModalAdicionarSocio} item={itemAcao} acaoProx={_ => acaoClickSocioProxAnt(true, setItemAcao)} acaoAnte={_ => acaoClickSocioProxAnt(false, setItemAcao)}
            justificativa={itemAcao && itemAcao.executantes_ids && itemAcao.executantes_ids.length > 0} funcaoAposSalvar={(val) => { obterDados(filtros); setShowModalAdicionarSocio(false) }} acoes={(item) => obterAcoes(itemAcao, true)} />
        <TrocarStatus titulo={'Trocar Status'} abrirModal={showModalTrocarStatus}
            setAbrirModal={setShowModalTrocarStatus} item={itemAcao} acaoProx={_ => acaoClickSocioProxAnt(true, setItemAcao)} acaoAnte={_ => acaoClickSocioProxAnt(false, setItemAcao)}
            justificativa={itemAcao && itemAcao.executantes_ids && itemAcao.executantes_ids.length > 0} funcaoAposSalvar={(val) => { obterDados(filtros); setShowModalTrocarStatus(false); }} acoes={(item) => obterAcoes(itemAcao, true)} />
        <CadastroObservacao titulo={'Adicionar Observação'} abrirModal={showModalObservacao}
            setAbrirModal={setShowModalObservacao} item={itemAcao} acaoProx={_ => acaoClickSocioProxAnt(true, setItemAcao)} acaoAnte={_ => acaoClickSocioProxAnt(false, setItemAcao)}
            funcaoAposSalvar={(val) => { obterDados(filtros); setShowModalObservacao(false) }} acoes={(item) => obterAcoes(itemAcao, true)} />
        <CadastroPreferencial titulo={'Adicionar Preferencial'} abrirModal={showModalPreferencial}
            setAbrirModal={setShowModalPreferencial} item={itemAcao} acaoProx={_ => acaoClickSocioProxAnt(true, setItemAcao)} acaoAnte={_ => acaoClickSocioProxAnt(false, setItemAcao)}
            justificativa={itemAcao && itemAcao.executantes_ids && itemAcao.executantes_ids.length > 0} funcaoAposSalvar={(val) => { obterDados(filtros); setShowModalPreferencial(false) }} acoes={(item) => obterAcoes(itemAcao, true)} />
        <FinalizarAgenda titulo={'Finalizar Agenda'} abrirModal={showModalFinalizar}
            setAbrirModal={setShowModalFinalizar} item={itemAcao} acaoProx={_ => acaoClickSocioProxAnt(true, setItemAcao)} acaoAnte={_ => acaoClickSocioProxAnt(false, setItemAcao)}
            justificativa={itemAcao && itemAcao.executantes_ids && itemAcao.executantes_ids.length > 0} funcaoAposSalvar={(val) => { obterDados(filtros); setShowModalFinalizar(false) }} acoes={(item) => obterAcoes(itemAcao, true)} />
        <ConfirmarAgendamento abrirModal={showModalConfirmacao}
            setAbrirModal={setShowModalConfirmacao} item={itemConfirmar} funcaoAposSalvar={(val) => obterDados(filtros)} />
        <ExclusaoSocios item={itemAcao} show={showExclusaoSocios} setShow={setShowExclusaoSocios} modulo_id={38} funcaoAposSalvar={(val) => obterDados(filtros)} />
        <ModalPadraoCadastro titulo={dataResumoDia.data || 'Carregando...'} showModal={showModalDia} setShowModal={setShowModalDia}>
            <div className='filtros agenda-content'>
                <div className='filtro'>
                    <div className='titulo-filtro row-space-between'>
                        <span className='vertical-center'>Status</span>
                    </div>
                    <div className='content-filtro'>
                        <ul class="lista-selecao mb-5">
                            {arrayStatus.map(itemStatus => <li className='p-0'>
                                <a href="#" onClick={() => {
                                    let novoFiltro = { ...filtrosResumoDia }
                                    novoFiltro.status = itemStatus.id
                                    setFiltrosResumoDia(novoFiltro)
                                }}>
                                    <span style={filtrosResumoDia.status === itemStatus.id ? { background: itemStatus.cor, color: 'white' } : { border: 1, borderColor: itemStatus.cor, borderStyle: 'solid', color: itemStatus.cor }}
                                        className={'tag active m-0 p-unset'}>{itemStatus.descricao + '(' + (dataResumoDia.dados ? dataResumoDia.dados.filter(item => item.status_id === itemStatus.id).length : 0) + ')'}</span>
                                </a>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
            <h5>Agendamentos</h5>
            <InputSimples name="search1" type="text" onChange={e => {
                let novoFiltro = { ...filtrosResumoDia }
                novoFiltro.pesquisa = e.target.value
                setFiltrosResumoDia(novoFiltro)
            }} placeholder={`Pesquisar...`} />
            <ul className='lista-simples-agendamentos'>
                {dataResumoDia.dados && (filtrosResumoDia.status ? dataResumoDia.dados
                    .filter(item => item.status_id === filtrosResumoDia.status) : dataResumoDia.dados).filter(item => removeAcentos((item.estabelecimento_saude_descricao + ' ' + item.hora_inicial + ' - ' + item.hora_final + ' ' + item.status).toLowerCase()).includes(removeAcentos(filtrosResumoDia.pesquisa ? filtrosResumoDia.pesquisa.toLowerCase() : '')))
                    .map(item => {
                        const hora = []
                        hora.push(item.hora_inicial)

                        if (item.hora_final)
                            hora.push(item.hora_final)

                        return <li>
                            <div className='col-hora'>
                                <span style={{ whiteSpace: 'nowrap' }} className='span-hora'>{hora.join(' - ')}</span> <span className={'tag tag-' + item.status}>{item.status}</span>
                            </div>
                            <span className='span-nome'>{item.estabelecimento_saude_descricao}</span>
                        </li>
                    })}
            </ul>
        </ModalPadraoCadastro>
        <DetalhesAgendamento setShowModal={setShowModalDetalhes} showModal={showModalDetalhes} itemDetalhes={itemDetalhes} />
        {verOcorrencias ? <Link target='_blank' to={{
            pathname: '/listagem',
            listagem: {
                dados: props.listaPersonalizada && props.listaPersonalizada.dados ? props.listaPersonalizada.dados : [{
                    agrupamento: '',
                    colunas: [{
                        label: 'Descrição',
                        name: 'descricao',
                        totalizar: false,
                        alinhamento: 'text-left',
                        visivel: true
                    },
                    {
                        label: 'Data',
                        name: 'data',
                        totalizar: false,
                        alinhamento: 'text-left',
                        visivel: true
                    }],
                    data: []
                }],
                titulo: 'Ocorrências',
                filtros: [],
                filtrosObj: [],
                filtrosValoresIniciais: [],
                dadosFiltrados: [{ descricao: 'Ferias', data: '30/07/2020' }, { descricao: 'Teste', data: '30/07/2020' }]
            }
        }} /> : <></>}

        {/* <ModalPadraoCadastro titulo={!alteracao ? 'Cadastro Agenda' : 'Alteração Agenda'} showModal={showModalCadastrar} setShowModal={setShowModalCadastrar}>
            <CadastroAgendaEscalador id={undefined} modal={true} setShow={setShowModalCadastrar} funcaoAposFechar={() => { }} />
        </ModalPadraoCadastro> */}
    </div>
}

const PopoverEstabelecimento = (props) => {

}

export default AgendaAssociado