import { InputGroupSenha, InputPadrao } from '../../../../components/Inputs'
import { InputTipoUsuario, InputDepartamento, InputEstabelecimentoSaude, InputCirurgiaoCombo } from '../../../../components/InputsAutoComplete'
import { InputCorteImagemS3 } from '../../../../components/InputCorteImagemS3'
import React, { useState, useEffect } from 'react'
import { HeaderCadastro } from '../../../../components/Headers'
import { Formik, Form } from "formik";
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { useParams } from "react-router-dom"
import { mensagens } from '../../../../util/Utilitarios'
import { post, postApiExterna } from '../../../../util/AcessoApi'
import Yup, { validaData, periodoData } from '../../../../util/Validacoes'
import { secretKey } from '../../../../util/Utilitarios'
import ls from 'local-storage'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/login.css'
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom'
import Atalho from '../../../../util/Atalho'

const manter = []
const recaptchaRef = React.createRef();

const Submit = (dadosSalvar, token) => {
    const url = 'https://www.google.com/recaptcha/api/siteverify'

    dadosSalvar.values.token = token

    Promise.resolve(post('/controleAcesso/usuarioExterno', dadosSalvar.values, false, false))
        .then(data => {
            dadosSalvar.salvarNovoObj.setLimparFoto(true)
            dadosSalvar.setIsSubmit(false)
            dadosSalvar.salvarNovoObj.propsFormik.resetForm({})
            dadosSalvar.salvarNovoObj.setSalvarENovo(false)
            mostrarToast('sucesso', mensagens.sucesso)
            dadosSalvar.setIsSubmit(false)
            recaptchaRef.current.reset()
            dadosSalvar.salvarNovoObj.setLimparFoto(false)
        }).catch(data => { dadosSalvar.setIsSubmit(false); recaptchaRef.current.reset() })
}

const CamposCadastro = props => {
    const [salvarENovo, setSalvarENovo] = useState(false)
    let { id } = useParams()
    const [isSubmit, setIsSubmit] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao, setValidacao] = useState({})
    const [usuarioTipo, setUsuarioTipo] = useState(null)
    const [dadosSalvar, setDadosSalvar] = useState(null)
    const [limparFoto, setLimparFoto] = useState(false)

    useEffect(() => {
        setValoresIniciais({
            nome: "",
            login: "",
            email: "",
            senha: "",
            confirmar_senha: "",
            foto_perfil: '',
            usuario_tipo: '',
            estabelecimento_saude: '',
            cirurgiao: '',
            link_externo: true,
            grupos: [{ value: 79 }]
        })

        setValidacao(Yup.object({
            nome: Yup.string().required().max(100),
            login: Yup.string().required().max(20),
            email: Yup.string().required().email(),
            senha: Yup.string().required().max(30),
            confirmar_senha: Yup.string().required()
                .max(30).test('confirmar-senha', 'Confirmação de senha deve ser igual a Senha', function (value) { return this.parent.senha === value }),
            foto_perfil: Yup.string().nullable(),
            usuario_tipo: Yup.string().nullable(),
            estabelecimento_saude: Yup.string().nullable(),
            cirurgiao: Yup.string().nullable(),
        }));

        if (id === 'colaborador')
            setUsuarioTipo({ value: 1, label: 'Colaborador' })
        else if (id === 'diretor')
            setUsuarioTipo({ value: 2, label: 'Diretor' })
        else if (id === 'socio')
            setUsuarioTipo({ value: 3, label: 'Sócio' })
        else if (id === 'externo')
            setUsuarioTipo({ value: 4, label: 'Externo' })

        Yup.object(validacao)
    }, [])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => {
                setIsSubmit(true)
                values.usuario_tipo = usuarioTipo
                const salvarNovoObj = {
                    setSalvarENovo,
                    salvarENovo,
                    propsFormik,
                    setLimparFoto
                }

                setDadosSalvar({
                    values,
                    setIsSubmit,
                    props,
                    salvarNovoObj
                })

                recaptchaRef.current.execute()
            }}>
            {propsFormik => (
                <Form>
                    <div className={props.modal ? '' : 'card mb-20'}>
                        <div className={props.modal ? '' : 'card-body'}>
                            <div className='row'>
                                <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={false} />
                                <InputCorteImagemS3 width={300} height={300} label="Foto de Perfil" name="foto_perfil" setSalvando={setIsSubmit} diretorioS3="usuario/DDMMYYYY/"
                                    tamanho='col-12' limparFoto={limparFoto} />
                                <InputPadrao label="Nome" name="nome" type="text" placeholder="Nome" obrigatorio={true} tamanho='col-lg-6' autoFocus maxLength={100}
                                    salvarENovo={salvarENovo} />
                                <InputPadrao label="Usuário" name="login" type="text" placeholder="Usuário" obrigatorio={true} tamanho='col-lg-3' lower={true} maxLength={20} />
                                <InputPadrao label="E-mail" name="email" type="mail" placeholder="E-mail" obrigatorio={true} tamanho='col-lg-3' lower={true} maxLength={100} />
                                <InputGroupSenha label="Senha" name="senha" placeholder="Senha" tamanho={'col-lg-4'} maxLength={30} />
                                <InputGroupSenha label="Confirmar Senha" name="confirmar_senha" placeholder="Confirmar Senha"
                                    tamanho={'col-lg-4'} maxLength={30} />
                                <InputTipoUsuario usuarioTipo={usuarioTipo} setUsuarioTipo={setUsuarioTipo} label="Tipo" tamanho={'d-none'}
                                    name="usuario_tipo" obrigatorio propsFormik={propsFormik} />
                                <InputDepartamento label="Departamento" placeholder="Departamento" tamanho={usuarioTipo && usuarioTipo.value === 1 && 'col-lg-4' || 'd-none'}
                                    name="departamento" multiplo propsFormik={propsFormik} />
                                <InputEstabelecimentoSaude tamanho={usuarioTipo && usuarioTipo.value === 4 && 'col-lg-4' || 'd-none'} label="Estabelecimento(s) de Saúde"
                                    name="estabelecimento_saude" placeholder="Estabelecimento de Saúde" propsFormik={propsFormik} multiplo={true} />
                                <InputCirurgiaoCombo multiplo={true} tamanho={usuarioTipo && usuarioTipo.value === 4 && 'col-lg-4' || 'd-none'} label="Cirurgião" name="cirurgiao" placeholder="Cirurgião"
                                    propsFormik={propsFormik} />
                                <Atalho formik={propsFormik} salvando={isSubmit} setSalvareNovo={setSalvarENovo}  salvarENovo={true} />

                            </div>
                        </div>
                    </div>
                    <div className='separator mt-40 mb-5'></div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Salvar e Novo' disabled={isSubmit} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />
                    </div>
                    <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6Le9ywAVAAAAAI5sjhsXfcYte_CyyW_cUJU8brwV" onChange={x => Submit(dadosSalvar, x)} />
                </Form>)}
        </Formik>
    )
}

const Cadastro = props => {
    return (
        <div className={'p-40'}>
            <HeaderCadastro titulo={'Cadastro de Usuário'} classeChildren={'space-between-100'}>
                <Link to={"/dashboard"}>
                    <img alt='logo' className="logo-menu" src={require('../../../../assets/img/logo-menu.png')}></img>
                </Link>
            </HeaderCadastro>
            <CamposCadastro {...props} />
        </div>
    )
}

export default Cadastro
export { CamposCadastro }