import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
var format = require('xml-formatter');


export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/financeiro/contabancaria', id,
            obj => {

                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Conta Bancária',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Banco', descricao: obj.banco && obj.banco.label, tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Conta', descricao: obj.tipo_conta && obj.tipo_conta.label, tamanho: 'col-lg-4' },
                                { titulo: 'Empresa/Cliente', descricao: obj.cliente && obj.cliente.label, tamanho: 'col-lg-4' },
                                { titulo: 'Agência', descricao: obj.agencia && obj.agencia_formatada, tamanho: 'col-lg-4' },
                                { titulo: 'Conta', descricao: obj.conta_formatada, tamanho: 'col-lg-4' },
                                { titulo: 'Saldo Inicial R$', descricao: obj.saldo_inicial_formatado, tamanho: 'col-lg-4' },
                                { titulo: 'Saldo Atual R$', descricao: obj.saldo_atual_formatado, tamanho: 'col-lg-4' },
                                { titulo: 'Descricao', descricao: obj.descricao, tamanho: 'col-lg-4' },
                                { titulo: 'Ativo', descricao: obj.visu_ativo, tamanho: 'col-lg-4' },
                                { titulo: 'Conta Principal', descricao: obj.visu_conta_principal, tamanho: 'col-lg-4' },
                                { titulo: 'Conta Empresa', descricao: obj.visu_conta_empresa, tamanho: 'col-lg-4' },
                                { titulo: 'Data de Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                            ]
                        },
                    ]
                } : { titulo: 'Visualização Conta Bancária' })

            })

    }, [id])


    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}
