import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import {
    InputEmpresa,
    InputTipoRelatorioPersonalizado,
    InputContasBancariaEmpresa
} from '../../../components/InputsAutoComplete'
import { InputDatePicker } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes'
import { getUsuario } from '../../../util/Configuracoes'
import moment from "moment"
import { formatarMoeda } from '../../../util/Utilitarios';
import { ordenarMultiplo } from '../../../components/Ordenamentos';
import { mostrarToast } from '../../../components/Toasts';
import { MODULOS_SISTEMA, RETENCOES } from '../../../util/Enums';
import { InputTipoConta, InputTipoLancamentoFinanceiro, InputTodasCategorias } from '../../../components/InputsAutoCompleteV2';
import { InputSimNao } from '../../../components/InputsAutoComplete';
import { relatorioExtratoBancarioDiarioVirtuoso, relatorioExtratoBancarioDiarioEspecifico } from '../../../components/RelatorioEspecifico/RelatorioExtratoBancario';
import { relatorioMovimentacoesBancariasVirtuoso, relatorioMovimentacoesBancariasEspecifico } from '../../../components/RelatorioEspecifico/RelatorioMovimentacoesBancarias';
import { relatorioFluxoCaixa, relatorioFluxoCaixaVirtuoso } from '../../../components/RelatorioEspecifico/RelatorioFluxoCaixa';
import { InputMonthPicker, InputPadraoSimples } from '../../../components/InputsV2';


export default props => {
    const actualYear = moment().clone().format('YYYY')
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Resumo')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '', referencia: moment().format('MM/YYYY'), ano: actualYear })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)
    const user = getUsuario()
    const ref = useRef(null)
    const [ordenamento, setOrdenamento] = useState([])
    const [ano, setAno] = useState()

    useEffect(() => {
        if (actualYear) {
            setAno(actualYear)
        }
    }, [actualYear])

    const tipoRelatorio = [
        { label: 'Relatório de Extrato Bancário Diário', value: MODULOS_SISTEMA.RELATORIO_EXTRATO_BANCARIO_DIARIO },
        { label: 'Relatório de Contas e Movimentações Bancárias', value: MODULOS_SISTEMA.RELATORIO_MOVIMENTACOES_BANCARIAS },
        { label: 'Relatório Fluxo de Caixa', value: MODULOS_SISTEMA.RELATORIO_FLUXO_CAIXA },
    ]


    const [validacao, setValidacao] = useState(Yup.object({
        data_extrato: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_extrato) }),
    }))


    const filtrosPadrao = (propsFormik) => [
        <InputDatePicker label="Data Extrato" name="data_extrato" type="text" setarValor={val => propsFormik.setFieldValue("data_extrato", val)} obrigatorio modal classeTooltip={'tooltip-input-modal'} />,
        <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
        <InputContasBancariaEmpresa label="Conta Bancária Empresa" placeholder="Conta Bancária Empresa" name="conta_bancaria_empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
        <InputTipoLancamentoFinanceiro label={'Tipo Lançamento'} placeholder='Tipo Lançamento' classeTooltip={'tooltip-input-modal'} name={'tipo_lancamento'} propsFormik={propsFormik} />,
        <InputTipoConta label={'Tipo Conta'} placeholder='Tipo Conta' classeTooltip={'tooltip-input-modal'} name={'tipo_conta'} propsFormik={propsFormik} />,
        <InputSimNao label="Conta Ativa" placeholder="Conta Ativa" name="conta_ativa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputSimNao label="Conta Empresa" placeholder="Conta Empresa" name="conta_empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputSimNao label="Conta Principal" placeholder="Conta Principal" name="conta_principal" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,

    ]

    useEffect(() => {
        if (relatorioAtual.data && relatorioAtual.data[0]) {
            let cloneRelatorioAtual = { ...relatorioAtual }
            const dados = cloneRelatorioAtual.data
            cloneRelatorioAtual.data = ordenarMultiplo(dados, ref.current.obterOrdenamentosMultiplos(0))
            setDados([{
                numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: dados, classeTr: cloneRelatorioAtual.classeTr,
                aposAgrupamento: cloneRelatorioAtual.aposAgrupamento, aposRelatorio: cloneRelatorioAtual.aposRelatorio,
                relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico
                    ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento) : null
            }])
            setRelatorioAtual(cloneRelatorioAtual)
        }
    }, [ordenamento])

    const relatoriosPorTipo = propsFormik => [
        {
            titulo: 'Relatório de Extrato Bancário Diário',
            tipo: MODULOS_SISTEMA.RELATORIO_EXTRATO_BANCARIO_DIARIO,
            url: '/relatorio/extratobancariodiario',
            campos: filtrosPadrao(propsFormik),
            classeTr: 'linha-bottom-relatorio',
            coluna_agrupar: 'todas_contas',
            agrupado: true,
            colunas: [
                { label: 'Descrição Conta', name: 'banco_conta_descricao', visivel: true },
                { label: 'Agência', name: 'agencia', visivel: true },
                { label: 'Conta', name: 'conta', visivel: true },
                { label: 'Referência', name: 'referencia', visivel: true },
                { label: 'Saldo', name: 'saldo', visivel: true, totalizar: true },
                { label: 'Tipo Conta', name: 'tipo_conta', visivel: true },
                { label: 'Ativo', name: 'ativo', visivel: true },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Data Extrato', name: 'data_extrato', name_coluna: 'data_extrato' },
                { label: 'Empresa', name: 'empresa', name_coluna: 'empresa' },
                { label: 'Conta Bancária Empresa', name: 'conta_bancaria_empresa', name_coluna: 'conta_bancaria_empresa' },
                { label: 'Tipo Lançamento', name: 'tipo_lancamento', name_coluna: 'tipo_lancamento' },
                { label: 'Tipo Conta', name: 'tipo_conta', name_coluna: 'tipo_conta' },
                { label: 'Conta Ativa', name: 'conta_ativa', name_coluna: 'conta_ativa' },
                { label: 'Conta Empresa', name: 'conta_empresa', name_coluna: 'conta_empresa' },
                { label: 'Conta Principal', name: 'conta_principal', name_coluna: 'conta_principal' },
            ],
            csvMultiplo: false,
            colunasCsv: [
                { label: 'Empresa', name: 'empresa_CSV', visivel: true },
                { label: 'Banco', name: 'banco_CSV', visivel: true },
                { label: 'Banco Conta', name: 'conta_CSV', visivel: true },
                { label: 'Agência', name: 'agencia_CSV', visivel: true },
                { label: 'Conta', name: 'conta_CSV', visivel: true },
                { label: 'Referência', name: 'referencia_CSV', visivel: true },
                { label: 'Saldo', name: 'saldo_bancario_CSV', visivel: true, totalizar: true },
                { label: 'Tipo Conta', name: 'tipo_conta_CSV', visivel: true },
                { label: 'Status Conta', name: 'ativo_CSV', visivel: true },
            ],
            virtuoso: true,
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioExtratoBancarioDiarioVirtuoso(dados, arrayConfigCol, configOrdenamento, htmlHeader),
            relatorioEspecificoImpressao: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioExtratoBancarioDiarioEspecifico(dados, arrayConfigCol, configOrdenamento, htmlHeader)
        },
        {
            titulo: 'Relatório de Contas e Movimentações Bancárias',
            tipo: MODULOS_SISTEMA.RELATORIO_MOVIMENTACOES_BANCARIAS,
            url: '/relatorio/movimentacoesbancarias',
            campos: [
                <InputMonthPicker label="Referência" name="referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} max={new Date().getFullYear()} min={2022} />,
                <InputDatePicker label="Data Extrato" name="data_extrato" type="text" setarValor={val => propsFormik.setFieldValue("data_extrato", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputContasBancariaEmpresa label="Conta Bancária Empresa" placeholder="Conta Bancária Empresa" name="conta_bancaria_empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputTodasCategorias label="Categoria" name="categoria" type="text" placeholder='Categoria' autoFocus propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                // <InputTipoLancamentoFinanceiro label={'Tipo Lançamento'} placeholder='Tipo Lançamento' classeTooltip={'tooltip-input-modal'} name={'tipo_lancamento'} propsFormik={propsFormik} />,
                // <InputTipoConta label={'Tipo Conta'} placeholder='Tipo Conta' classeTooltip={'tooltip-input-modal'} name={'tipo_conta'} propsFormik={propsFormik} />,
                <InputSimNao label="Conta Ativa" placeholder="Conta Ativa" name="conta_ativa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Conta Empresa" placeholder="Conta Empresa" name="conta_empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Conta Principal" placeholder="Conta Principal" name="conta_principal" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
            ],
            classeTr: 'linha-bottom-relatorio',
            coluna_agrupar: 'dados_csv',
            agrupado: true,
            colunas: [
                { label: 'Descrição Conta', name: 'banco_conta_descricao', visivel: true },
                { label: 'Agência', name: 'agencia', visivel: true },
                { label: 'Conta', name: 'conta', visivel: true },
                // { label: 'Referência', name: 'referencia', visivel: true },
                { label: 'Saldo', name: 'saldo', visivel: true, totalizar: true },
                { label: 'Tipo Conta', name: 'tipo_conta', visivel: true },
                { label: 'Ativo', name: 'ativo', visivel: true },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Data Extrato', name: 'data_extrato', name_coluna: 'data_extrato' },
                { label: 'Referência', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa', name_coluna: 'empresa' },
                { label: 'Conta Bancária Empresa', name: 'conta_bancaria_empresa', name_coluna: 'conta_bancaria_empresa' },
                { label: 'Categoria', name: 'categoria', name_coluna: 'categoria' },
                // { label: 'Tipo Lançamento', name: 'tipo_lancamento', name_coluna: 'tipo_lancamento' },
                // { label: 'Tipo Conta', name: 'tipo_conta', name_coluna: 'tipo_conta' },
                { label: 'Conta Ativa', name: 'conta_ativa', name_coluna: 'conta_ativa' },
                { label: 'Conta Empresa', name: 'conta_empresa', name_coluna: 'conta_empresa' },
                { label: 'Conta Principal', name: 'conta_principal', name_coluna: 'conta_principal' },
            ],
            csvMultiplo: false,
            colunasCsv: [
                { label: 'Empresa', name: 'empresa_csv', visivel: true },
                { label: 'Banco', name: 'banco_csv', visivel: true },
                { label: 'Agência', name: 'agencia_csv', visivel: true },
                { label: 'Conta', name: 'conta_csv', visivel: true },
                { label: 'Saldo Anterior', name: 'saldo_anterior_csv', visivel: true },
                { label: 'Tipo Conta', name: 'tipo_conta_csv', visivel: true },
                { label: 'Ativo', name: 'ativo_csv', visivel: true },
                { label: 'Descrição Conta', name: 'banco_conta_descricao_csv', visivel: true },
                // { label: 'Referência', name: 'referencia_csv', visivel: true },
                { label: 'Data Liquidação', name: 'data_liquidacao_csv', visivel: true },
                // { label: 'Cod. Lançamento', name: 'lancamento_id_csv', visivel: true, totalizar: true },
                { label: 'Catagoria Lançamento', name: 'categoria_csv', visivel: true, totalizar: true },
                { label: 'Desc. Lançamento', name: 'descricao_lancamento_csv', visivel: true, totalizar: true },
                { label: 'Cliente', name: 'cliente_csv', visivel: true, totalizar: true },
                { label: 'CPF/CNPJ', name: 'cpf_cnpj', visivel: true, totalizar: true },
                { label: 'Valor', name: 'valor_csv', visivel: true, totalizar: true },
                { label: 'Aplicação', name: 'aplicacao_csv', visivel: true },
                { label: 'Resgate', name: 'resgate_csv', visivel: true },
            ],
            virtuoso: true,
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioMovimentacoesBancariasVirtuoso(dados, arrayConfigCol, configOrdenamento, htmlHeader),
            relatorioEspecificoImpressao: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioMovimentacoesBancariasEspecifico(dados, arrayConfigCol, configOrdenamento, htmlHeader)
        },
        {
            titulo: 'Relatório Fluxo de Caixa',
            tipo: MODULOS_SISTEMA.RELATORIO_FLUXO_CAIXA,
            url: '/relatorio/fluxocaixa',
            campos: [
                <InputPadraoSimples label="Ano" obrigatorio placeholder="Ano" name="ano" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} maxLength={4} onBlur={e => {
                    setAno(e.target.value)
                    propsFormik.setFieldValue("ano", e.target.value)
                }} />,
                <InputContasBancariaEmpresa label="Conta Bancária Empresa" placeholder="Conta Bancária Empresa" name="conta_bancaria_empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa_fluxo_caixa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputTodasCategorias label="Categoria" name="categoria" type="text" placeholder='Categoria' autoFocus propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />
            ],
            classeTr: 'linha-bottom-relatorio',
            coluna_agrupar: 'dados_csv',
            agrupado: true,
            colunas: [
                // { label: 'Categoria', name: 'categoria', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '8%' },
                { label: `Jan/${ano} `, name: 'janeiro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Fev/${ano} `, name: 'fevereiro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Mar/${ano} `, name: 'marco', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Abr/${ano} `, name: 'abril', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Mai/${ano} `, name: 'maio', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Jun/${ano} `, name: 'junho', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Jul/${ano} `, name: 'julho', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Ago/${ano} `, name: 'agosto', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Set/${ano} `, name: 'setembro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Out/${ano} `, name: 'outubro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Nov/${ano} `, name: 'novembro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Dez/${ano} `, name: 'dezembro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Ano', name: 'ano', name_coluna: 'ano' },
                { label: 'Empresa', name: 'empresa_fluxo_caixa', name_coluna: 'empresa_fluxo_caixa' },
                { label: 'Conta Bancária Empresa', name: 'conta_bancaria_empresa', name_coluna: 'conta_bancaria_empresa' },
                { label: 'Categoria', name: 'categoria', name_coluna: 'categoria' },
            ],
            csvMultiplo: false,
            colunasCsv: [
                { label: 'Empresa', name: 'empresa', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '8%' },
                { label: 'Categoria', name: 'categoria', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '8%' },
                { label: 'Tipo Valor', name: 'tipo_valor', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '8%' },
                { label: `Jan/${ano} `, name: 'janeiro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Fev/${ano} `, name: 'fevereiro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Mar/${ano} `, name: 'marco', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Abr/${ano} `, name: 'abril', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Mai/${ano} `, name: 'maio', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Jun/${ano} `, name: 'junho', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Jul/${ano} `, name: 'julho', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Ago/${ano} `, name: 'agosto', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Set/${ano} `, name: 'setembro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Out/${ano} `, name: 'outubro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Nov/${ano} `, name: 'novembro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Dez/${ano} `, name: 'dezembro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
            ],
            virtuoso: true,
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioFluxoCaixaVirtuoso(dados, arrayConfigCol, configOrdenamento, htmlHeader),
            relatorioEspecificoImpressao: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioFluxoCaixa(dados, arrayConfigCol, configOrdenamento, htmlHeader)
        },

    ]

    const submit = (values, propsFormik) => {

        if (relatorioSelecionado == MODULOS_SISTEMA.RELATORIO_EXTRATO_BANCARIO_DIARIO && !values.data_extrato) {
            mostrarToast('erro', 'Escolha uma Data Extrato')
        } else if (relatorioSelecionado == MODULOS_SISTEMA.RELATORIO_MOVIMENTACOES_BANCARIAS && values.data_extrato && values.referencia || !values.data_extrato && !values.referencia) {
            mostrarToast('erro', 'Escolha uma Referência ou Data Extrato')

        } else if (relatorioSelecionado == MODULOS_SISTEMA.RELATORIO_FLUXO_CAIXA && !values.ano) {
            mostrarToast('erro', 'Escolha um ano')

        }
        else {
            setCarregando(true)
            let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)

            for (let i = Object.keys(values).length; i > 0; i--)
                if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                    delete values[Object.keys(values)[i]]

            setShowModalFiltros(false)
            setValuesFiltros(values)
            setRelatorioAtual({ ...novoRelatorio })

            if (values.tipo_relatorio) {
                setFiltros(Object.keys(values).map((x, idx, array) => {

                    const valorAtual = values[x]

                    return (x !== 'tipo_relatorio'
                        && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value) && valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                    {
                        coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                        filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                        name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                        value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                        dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                    })
                }).filter(x => x.coluna !== false && x.value))
            }
            else {
                setRelatorioAtual({})
                setCarregando(false)
                setDados([])
                setFiltros([])
            }
        }
    }

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }
        const newFiltro = [...filtros]

        if (user.tipo === 4)
            newFiltro.push({ coluna: 'usuario', value: user.id }, { coluna: 'tipo', value: user.tipo })


        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{
                    numerarLinha: true, colunas: cloneRelatorioAtual.colunas, csvMultiplo: cloneRelatorioAtual.csvMultiplo, colunasCsv: cloneRelatorioAtual.colunasCsv ? cloneRelatorioAtual.colunasCsv : null, coluna_agrupar: cloneRelatorioAtual?.coluna_agrupar || null, data: cloneRelatorioAtual.data, classeTr: cloneRelatorioAtual.classeTr, aposAgrupamento: cloneRelatorioAtual.aposAgrupamento, aposRelatorio: cloneRelatorioAtual.aposRelatorio, agrupado: cloneRelatorioAtual?.agrupado || false, relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento) : null,
                    relatorioEspecificoImpressao: !!cloneRelatorioAtual.relatorioEspecificoImpressao ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecificoImpressao(dados, arrayConfigCol, configOrdenamento) : null, virtuoso: !!cloneRelatorioAtual.virtuoso, ajustaEscala: !!cloneRelatorioAtual.ajustaEscala
                }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: newFiltro, usuario_tipo: user.tipo, usuario_id: user.id })
    }, [filtros])

    const changeTipo = (value) => {
        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({ })
        // const index = tipoRelatorio.findIndex(x => x.value === value)
        setValuesFiltros({ tipo_relatorio: value, conta_empresa: { value: 'true', label: 'Sim' }, conta_ativa: { value: 'true', label: 'Sim' }, conta_principal: { value: 'true', label: 'Sim' }, referencia: moment().format('MM/YYYY'), ano: actualYear })
        setRelatorioSelecionado(value?.value)

    }
    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros} virtuoso={relatorioAtual.virtuoso} ajustaEscala={relatorioAtual.ajustaEscala} orientacao={relatorioSelecionado == 5 ? 'A4 landscape' : 'portrait'} orientacaoPadrao={relatorioSelecionado == 5 ? 'landscape' : 'portrait'}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })} ref={ref} classePersonalizada={relatorioAtual && relatorioAtual.classePersonalizada} ordenarArrayCompleto={relatorioAtual && relatorioAtual.ordenarArrayCompleto} coluna_agrupar={relatorioAtual?.coluna_agrupar}>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        <InputTipoRelatorioPersonalizado label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                            propsFormik={propsFormik} modulos={relatoriosPorTipo(propsFormik).map(x => x.tipo)} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} />
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}
                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}