import React, { useState, useRef } from 'react'
import { Alterar, BtnAcao, Excluir, Visualizar } from '../../../../components/Acoes'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { excluir, get } from '../../../../util/AcessoApi'
import { getUsuario } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { mensagens, useWindowSize } from '../../../../util/Utilitarios.js'
import { mostrarToast } from '../../../../components/Toasts'

const Consulta = props => {
    const [data, setData] = useState([])
    const target = useRef(null)


    const columns = React.useMemo(
        () => [{
            id: 'consulta-contrato',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Número',
                    accessor: 'numero',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Operadora Saúde',
                    accessor: 'operadora_saude',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'operadora_saude',
                        idColuna: 'operadora_saude_id'
                    }
                }, {
                    Header: 'Identificação Subdivisão',
                    accessor: 'codigo_subdivisao',
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, {
                    Header: 'Status',
                    accessor: 'contrato_status',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'contrato_status',
                        idColuna: 'contrato_status.id',
                    }
                }, {
                    Header: 'Particular?',
                    accessor: 'particular',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'input_sim_nao',
                        idColuna: 'particular',
                        name: `input_sim_nao_${Math.floor(Math.random() * 1000)}`,
                    }
                }, {
                    Header: 'Vigência Inicial',
                    accessor: 'vigencia_inicial',
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'vigencia_inicial',
                        name: `data_inicial_${Math.floor(Math.random() * 1000)}`,
                        datePicker: true,
                        date: true
                    }
                }, {
                    Header: 'Vigência Final',
                    accessor: 'vigencia_final',
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'vigencia_final',
                        datePicker: true,
                        date: true
                    }
                }, {
                    Header: 'Data Atualização Web Service',
                    accessor: 'data_atualizacao',
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'contrato.data_atualizacao',
                        name: `data_inicial_${Math.floor(Math.random() * 1000)}`,
                        datePicker: true,
                        date: true
                    }
                }, {
                    Header: 'Consulta?',
                    accessor: 'consulta',
                    visivel: false,
                    ordenamento: { tipo: 'texto' },
                    filtro: {
                        tipo: 'input_sim_nao',
                        idColuna: 'consulta',
                        name: `input_sim_nao_${Math.floor(Math.random() * 1000)}`,
                    }
                }, {
                    Header: 'Data de Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    }
                }
            ]
        }], []
    )
    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/faturamento/contrato', propsAcao.row.id,
                    () => {
                        // getApi(setData, '/faturamento/guia')
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))

    }
    const acoesObj = [
        propsAcao => <Visualizar to={'/faturamento/contrato/visualizacao/' + propsAcao.row.id} />,
        propsAcao => <Alterar to={'/faturamento/contrato/alteracao/' + propsAcao.row.id} />,
        propsAcao => <Excluir to={'/faturamento/contrato/consulta/'} action={() => exclusao(propsAcao)} />,
        propsAcao => propsAcao.row.id_coopanestgo ? <BtnAcao icone="icon-Repeat-2" texto="Atualizar"
            action={_ => {
                props.obterWebService(JSON.stringify({ "integrationType": "contrato_unico", "key": `${propsAcao.id}`, "usuarioId": getUsuario().id, "moduloId": 34, "acaoId": 30 }))
            }} {...props} /> : <></>,


    ]
    return (
        <div>
            <DataGridAlternativa target={target} data={data} columns={columns} caminhoBase='/faturamento/contrato' setData={setData} acoes={true} modulo={props.modulo} acoesObj={acoesObj}
                titulo={'Consulta Contrato'} tituloLista={'Lista Contrato'} urlCadastro='/faturamento/contrato/cadastro' icone={'icon-Professor'} caminhoConsultaFiltros='/faturamento/contratoPaginacao'
                actionImportacao={() => props.obterWebService(JSON.stringify({ "integrationType": "contrato_geral", "key": "10", "usuarioId": getUsuario().id, "moduloId": 34, "acaoId": 9 }))} />
        </div>
    )
}

export default Consulta