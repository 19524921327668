import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputTipoRelatorio, InputEstabelecimentoSaude, InputSimNao, InputOperadoraSaude, InputSocio, InputCirurgiaoCombo, InputTipoExecutante, InputTipoCliente, InputMunicipioGOPrimeiro, InputClienteFinanceiro, InputUsuario, InputTodasCategorias, InputContaBancaria, InputStatusCorLancamento, InputStatusLancamento, InputTipoRelatorioPersonalizado } from '../../../components/InputsAutoComplete'
import { InputDatePicker, InputPadrao } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes';
import moment from 'moment';
import { ModalDuasOpcoes } from '../../../components/Modais';
import { checkAllUndefined } from '../../../util/Utilitarios';
import { MODULOS_SISTEMA } from '../../../util/Enums';
import { InputEmpresa, InputTipoMovimento } from '../../../components/InputsAutoCompleteV2';

export default props => {
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Contas a Pagar/Receber')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '' })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')

    const inputRef = useRef(null);

    const tipoRelatorio = [{ label: 'Contas a Receber', value: MODULOS_SISTEMA.RELATORIO_DE_CONTAS_A_RECEBER }, { label: 'Contas a Pagar', value: MODULOS_SISTEMA.RELATORIO_DE_CONTAS_A_PAGAR }]


    const validacao = Yup.object({
        data_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) }),
        data_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) })
    })

    const relatoriosPorTipo = propsFormik => [
        {

            titulo: 'Relatório Contas a Receber',
            tipo: MODULOS_SISTEMA.RELATORIO_DE_CONTAS_A_RECEBER,
            url: '/financeiro/relatoriopagarreceber',
            campos: [
                <InputEmpresa label='Empresa' name='empresa' placeholder='Empresa' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputClienteFinanceiro label="Cliente" name="cliente" type="text" placeholder='Cliente' autoFocus propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputTodasCategorias label="Categoria" name="categoria" type="text" placeholder='Categoria' autoFocus propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputContaBancaria label="Conta Bancária Empresa" name="conta" type="text" placeholder='Conta Bancária Empresa' autoFocus propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputDatePicker label="Data Prevista Inicial" name="data_prevista_inicial" type="text" placeholder='Data Prevista Inicial' propsFormik={propsFormik}
                    setarValor={value => { propsFormik.setFieldValue("data_prevista_inicial", value) }} />,

                <InputDatePicker label="Data Prevista Final" name="data_prevista_final" type="text" placeholder='Data Prevista Final' propsFormik={propsFormik}
                    setarValor={value => { propsFormik.setFieldValue("data_prevista_final", value) }} />,

                <InputDatePicker label="Data Liquidação Inicial" name="data_liquidacao_inicial" type="text" placeholder='Data Liquidação Inicial' propsFormik={propsFormik}
                    setarValor={value => { propsFormik.setFieldValue("data_liquidacao_inicial", value) }} />,

                <InputDatePicker label="Data Liquidação Final" name="data_liquidacao_final" type="text" placeholder='Data Liquidação Final' propsFormik={propsFormik}
                    setarValor={value => { propsFormik.setFieldValue("data_liquidacao_final", value) }} />,

                <InputDatePicker label="Data Registro Inicial" name="data_registro_inicial" type="text" placeholder='Data Registro Inicial' propsFormik={propsFormik}
                    setarValor={value => { propsFormik.setFieldValue("data_registro_inicial", value) }} />,

                <InputDatePicker label="Data Registro Final" name="data_registro_final" type="text" placeholder='Data Registro Final' propsFormik={propsFormik}
                    setarValor={value => { propsFormik.setFieldValue("data_registro_final", value) }} />,

                <InputSimNao label="Liquidado ?" name="liquidado" type="text" placeholder='Liquidado ?' propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} />,

                <InputUsuario menuPlacement={'top'} label="Usuário Inserção" name="usuario_insercao" type="text" placeholder='Usuário Inserção' propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} />,

                <InputStatusCorLancamento label='Estado Lançamento' name='estado' placeholder='Estado Lançamento' propsFormik={propsFormik} {...props}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputStatusLancamento label='Status' name='status' placeholder='Status' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'}
                    multiplo />,

                <InputTipoMovimento label='Tipo Movimento' name="tipo_movimento" placeholder="Tipo Movimento" propsFormik={propsFormik} multiplo
                    classeTooltip={'tooltip-input-modal'} menuPlacement='top' />
            ],
            numerarLinha: true,
            colunas: [
                { label: 'Código', name: 'id', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Descrição', name: 'descricao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Categoria', name: 'categoria', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Cliente', name: 'entidade', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Prev. Vencimento', name: 'data_prevista', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '10%', alinhamento: 'text-right', totalizar: true },
                { label: 'Data Liquidação', name: 'data_liquidacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor Liquidacao', name: 'valor_liquidado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '10%', alinhamento: 'text-right', totalizar: true },
                { label: 'Liquidado?', name: 'liquidado', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro', name: 'data_registro', visivel: false, width: '10%', ordenamento: { tipo: 'texto', ativo: false } },
            ],
            data: [],
            colunasFiltros: [

                { label: 'Empresa', name: 'empresa', name_coluna: 'empresa' },
                { label: 'Cliente', name: 'cliente', name_coluna: 'cliente' },
                { label: 'Categoria', name: 'categoria', name_coluna: 'categoria' },
                { label: 'Conta Bancária Empresa', name: 'conta', name_coluna: 'conta' },
                { label: 'Data Prevista Inicial', name: 'data_prevista_inicial', name_coluna: 'data_prevista_inicial' },
                { label: 'Data Prevista Final', name: 'data_prevista_inicial', name_coluna: 'data_prevista_final' },
                { label: 'Data Liquidacao Inicial', name: 'data_liquidacao_inicial', name_coluna: 'data_liquidacao_inicial' },
                { label: 'Data Liquidacao Final', name: 'data_liquidacao_final', name_coluna: 'data_liquidacao_final' },
                { label: 'Data Registro Inicial', name: 'data_registro_inicial', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro_final', name_coluna: 'data_registro_final' },
                { label: 'Liquidado', name: 'data_liquidacao', name_coluna: 'liquidado' },
                { label: 'Usuario Inserção', name: 'usuario_id', name_coluna: 'usuario_insercao' },
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Estado Lançamento', name: 'estado', name_coluna: 'estado' },
                { label: 'Tipo Movimento', name: 'tipo_movimento', name_coluna: 'tipo_movimento' },



            ]
        },
        {

            titulo: 'Relatório Contas a Pagar',
            tipo: MODULOS_SISTEMA.RELATORIO_DE_CONTAS_A_PAGAR,
            url: '/financeiro/relatoriopagarreceber',
            campos: [

                <InputEmpresa label='Empresa' name='empresa' placeholder='Empresa' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputClienteFinanceiro label="Cliente" name="cliente" type="text" placeholder='Cliente' autoFocus propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputTodasCategorias label="Categoria" name="categoria" type="text" placeholder='Categoria' autoFocus propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputContaBancaria label="Conta Bancária Empresa" name="conta" type="text" placeholder='Conta Bancária Empresa' autoFocus propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputDatePicker label="Data Prevista Inicial" name="data_prevista_inicial" type="text" placeholder='Data Prevista Inicial' propsFormik={propsFormik}
                    setarValor={value => { propsFormik.setFieldValue("data_prevista_inicial", value) }} />,

                <InputDatePicker label="Data Prevista Final" name="data_prevista_final" type="text" placeholder='Data Prevista Final' propsFormik={propsFormik}
                    setarValor={value => { propsFormik.setFieldValue("data_prevista_final", value) }} />,

                <InputDatePicker label="Data Liquidação Inicial" name="data_liquidacao_inicial" type="text" placeholder='Data Liquidação Inicial' propsFormik={propsFormik}
                    setarValor={value => { propsFormik.setFieldValue("data_liquidacao_inicial", value) }} />,

                <InputDatePicker label="Data Liquidação Final" name="data_liquidacao_final" type="text" placeholder='Data Liquidação Final' propsFormik={propsFormik}
                    setarValor={value => { propsFormik.setFieldValue("data_liquidacao_final", value) }} />,

                <InputDatePicker label="Data Registro Inicial" name="data_registro_inicial" type="text" placeholder='Data Registro Inicial' propsFormik={propsFormik}
                    setarValor={value => { propsFormik.setFieldValue("data_registro_inicial", value) }} />,

                <InputDatePicker label="Data Registro Final" name="data_registro_final" type="text" placeholder='Data Registro Final' propsFormik={propsFormik}
                    setarValor={value => { propsFormik.setFieldValue("data_registro_final", value) }} />,

                <InputSimNao label="Liquidado ?" name="liquidado" type="text" placeholder='Liquidado ?' propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} />,

                <InputUsuario menuPlacement={'top'} label="Usuário Inserção" name="usuario_insercao" type="text" placeholder='Usuário Inserção' propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} />,

                <InputStatusCorLancamento label='Estado Lançamento' name='estado' placeholder='Estado Lançamento' propsFormik={propsFormik} {...props}
                    classeTooltip={'tooltip-input-modal'} multiplo />,

                <InputStatusLancamento label='Status' name='status' placeholder='Status' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'}
                    multiplo />,
                <InputTipoMovimento label='Tipo Movimento' name="tipo_movimento" placeholder="Tipo Movimento" propsFormik={propsFormik} multiplo
                    classeTooltip={'tooltip-input-modal'} menuPlacement='top' />
            ],
            numerarLinha: true,
            colunas: [
                { label: 'Código', name: 'id', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Descrição', name: 'descricao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Categoria', name: 'categoria', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Cliente', name: 'entidade', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Prev. Vencimento', name: 'data_prevista', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '10%', alinhamento: 'text-right', totalizar: true },
                { label: 'Data Liquidação', name: 'data_liquidacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor Liquidacao', name: 'valor_liquidado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '10%', alinhamento: 'text-right', totalizar: true },
                { label: 'Liquidado?', name: 'liquidado', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro', name: 'data_registro', visivel: false, width: '10%', ordenamento: { tipo: 'texto', ativo: false } },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Empresa', name: 'empresa', name_coluna: 'empresa' },
                { label: 'Cliente', name: 'cliente', name_coluna: 'cliente' },
                { label: 'Categoria', name: 'categoria', name_coluna: 'categoria' },
                { label: 'Conta Bancária Empresa', name: 'conta', name_coluna: 'conta' },
                { label: 'Data Prevista Inicial', name: 'data_prevista_inicial', name_coluna: 'data_prevista_inicial' },
                { label: 'Data Prevista Final', name: 'data_prevista_inicial', name_coluna: 'data_prevista_final' },
                { label: 'Data Liquidacao Inicial', name: 'data_liquidacao_inicial', name_coluna: 'data_liquidacao_inicial' },
                { label: 'Data Liquidacao Final', name: 'data_liquidacao_final', name_coluna: 'data_liquidacao_final' },
                { label: 'Data Registro Inicial', name: 'data_registro_inicial', name_coluna: 'data_registro_inicial' },
                { label: 'Data Registro Final', name: 'data_registro_final', name_coluna: 'data_registro_final' },
                { label: 'Liquidado', name: 'liquidado', name_coluna: 'liquidado' },
                { label: 'Usuario Inserção', name: 'usuario_insercao', name_coluna: 'usuario_insercao' },
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Estado Lançamento', name: 'estado', name_coluna: 'estado' },
                { label: 'Tipo Movimento', name: 'tipo_movimento', name_coluna: 'tipo_movimento' },
            ]
        },
    ]

    const validarSubmit = (values, propsFormik) => {

        if (values && checkAllUndefined(values.cliente, values.categoria, values.conta, values.data_prevista_inicial, values.data_prevista_final,
            values.data_liquidacao_inicial, values.data_liquidacao_final, values.liquidado, values.usuario_insercao, values.estado, values.status, values.tipo_movimento, values.categoria)) {

            const alertas = ['Atenção! Nenhum filtro escolhido!']
            setMensagemModal(<><div dangerouslySetInnerHTML={{ __html: alertas.map(x => x.replace(/"/g, '') + '<br/><br/>').join('') }} /></>)
            setShowModalValidacao(true)
            setCarregando(false)

        } else {

            submit(values, propsFormik)
        }

    }


    const submit = (values, propsFormik) => {

        setCarregando(true)

        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)
        setShowModalFiltros(false)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]

        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio)
            setFiltros(Object.keys(values).map((x, idx, array) => {
                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                })
            }
            ).filter(x => x))
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }


    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }
        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{ aposRelatorio: cloneRelatorioAtual.aposRelatorio, numerarLinha: cloneRelatorioAtual.numerarLinha !== undefined ? cloneRelatorioAtual.numerarLinha : true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data, relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico ? (dados, arrayConfigCol, configOrdenamento, htmlHeader) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento, htmlHeader) : null }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: filtros, tipo_relatorio: relatorioSelecionado })
    }, [filtros])

    const changeTipo = (value) => {
        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({})
        // const index = tipoRelatorio.findIndex(x => x.value === value)
        setValuesFiltros({ tipo_relatorio: value })
        setRelatorioSelecionado(value?.value)
    }

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })}
            agrupamento={[{ coluna: 'nome', descricao: 'nome' }]}>

            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
                {propsFormik => (
                    <div>
                        <Form>
                            <div className='row'>
                                <InputTipoRelatorioPersonalizado label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                                    propsFormik={propsFormik} modulos={relatoriosPorTipo(propsFormik).map(x => x.tipo)} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} />
                                {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                                    {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                        (item, idx, array) => {
                                            if (idx === (array.length - 1)) {
                                                setTimeout(() => {
                                                    setCarregandoFiltros(false)
                                                }, 1000)
                                            }
                                            return item
                                        }
                                    )}
                                </div>}
                                {carregandoFiltros === true ?
                                    <ContentLoader
                                        speed={2}
                                        width='100%'
                                        height='400'
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#e4e4e4"
                                        {...props} >
                                        <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                        <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                        <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                        <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                                    </ContentLoader>
                                    : <></>}
                            </div>
                            <div className='direita container-botoes mb-3 group-salvar-novo'>
                                <BotaoPadrao texto='Filtrar' disabled={false} />
                            </div>
                        </Form>
                        <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { submit(propsFormik.values, propsFormik); setShowModalValidacao(false) }} funcaoOpcao1={() => { setCarregando(false); setShowModalValidacao(false) }}
                            esconderFechar mensagem={<>{<>{'Validações Filtro:'} <br /><br /></>} {mensagemModal}<br />
                                {'Deseja Continuar?'}</>}
                            textoOpcao1={'Cancelar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} />

                    </div>
                )}
            </Formik>
        </ConsultaRelatorio >
    )
}
