import React, { useState, useEffect, useRef, useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FieldArray } from "formik";
import Yup, { validaData, periodoData, validaHora } from '../../../../util/Validacoes';
import { Link } from 'react-router-dom'
import { InputPadrao, InputValorNovo, InputPadraoSimples } from '../../../../components/Inputs'
import {
    InputProcedimentosTabela, InputTodosProcedimentos, InputAcomodacao, InputTipoAtendimentoOdonto, InputTabelaProcedimento, InputContratoBaseCalculo
} from '../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../components/Botoes'
import '../../../../assets/css/icones/iconsmind/style.css'
import { BtnAcao, PopoverPadrao } from '../../../../components/Acoes'
import { formatMoeda } from '../../../../util/FuncoesComuns';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Overlay, Tooltip } from 'react-bootstrap';
import { FiAlertTriangle } from 'react-icons/fi'
import { mostrarToast } from '../../../../components/Toasts';

const CadastroOdontologia = (props) => {

    const onBlurValor = (option, propsFormik, arrayHelpers, index) => {
        if (option) {
            const valorAnterior = { ...propsFormik.values.odontologico[index] }
            propsFormik.setFieldValue(`odontologico[${index}].local_atendimento`, option);
            if (!valorAnterior.local_atendimento || !valorAnterior.local_atendimento.value) {
                adicionarLinha(arrayHelpers, propsFormik)
            }
        }
        else {
            propsFormik.setFieldValue(`odontologico[${index}].local_atendimento`, undefined);
        }
    }

    const adicionarLinha = (arrayHelpers, propsFormik) => {
        arrayHelpers.insert(propsFormik.values.odontologico.length, { hora_inicial: undefined, local_atendimento: undefined, valor: undefined, valor_med: undefined })
    }

    const removerLinha = (arrayHelpers, propsFormik, index) => {
        const arrayValoresHonorario = propsFormik.values.odontologico;
        if (arrayValoresHonorario.length === 1) {
            propsFormik.setFieldValue('odontologico', [{ hora_inicial: undefined, hora_final: undefined, local_atendimento: undefined, valor: undefined, valor_med: undefined }])
        } else {
            if (arrayValoresHonorario[index].local_atendimento && arrayValoresHonorario[index].local_atendimento.value)
                arrayHelpers.remove(index)

            if (arrayValoresHonorario.length === 1 && arrayValoresHonorario[index].local_atendimento && arrayValoresHonorario[index].local_atendimento.value)
                arrayHelpers.insert(propsFormik.values.odontologico.length, { hora_inicial: undefined, local_atendimento: undefined, valor: undefined, valor_med: undefined })
        }
    }

    return (
        <table className='table tabela-endereco table-responsive-md table-striped tabela' >
            <thead>
                {
                    <tr>
                        <th style={{ width: '5%', textAlign: 'center' }}>#</th>
                        <th style={{ width: '12%' }}>Hora Inicial</th>
                        <th style={{ width: '12%' }}>Hora Final</th>
                        <th style={{ width: '36%' }}>Tipo Atendimento</th>
                        <th style={{ width: '10%' }}>Valor</th>
                        <th style={{ width: '10%' }}>Valor (Med./Oxig.)</th>
                        <th style={{ width: '15%' }} className='text-center'>Ações</th>
                    </tr>
                }
            </thead>
            <tbody>
                <FieldArray name="odontologico">
                    {(arrayHelpers => (
                        <>
                            {props.propsFormik.values.odontologico && props.propsFormik.values.odontologico.length > 0 ?
                                props.propsFormik.values.odontologico.map((item, index) =>
                                    <tr>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
                                        <td>
                                            <InputPadrao mascara={'11:11'} classeTooltip={'tooltip-input-modal'} name={`odontologico[${index}].hora_inicial`} placeholder="Hora Inicial" tamanho={'mb-0'} />
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                            <InputPadrao mascara={'11:11'} classeTooltip={'tooltip-input-modal'} name={`odontologico[${index}].hora_final`} placeholder="Hora Final" tamanho={'mb-0'} />
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                            <InputTipoAtendimentoOdonto classeTooltip={'tooltip-input-modal'} tamanho='mb-0' placeholder='Local de Atendimento' isClearable={false}
                                                name={`odontologico[${index}].local_atendimento`} onChange={(e) => onBlurValor(e, props.propsFormik, arrayHelpers, index)} propsFormik={props.propsFormik} />
                                        </td>
                                        <td>
                                            <InputValorNovo classeTooltip={'tooltip-input-modal'} classDiv='mb-0' value={props.propsFormik.values.odontologico[index].valor_formatado || props.propsFormik.values.odontologico[index].valor} name={`odontologico[${index}].valor`} propsFormik={props.propsFormik} isDisable={false} max={100000000} />
                                        </td>
                                        <td>
                                            <InputValorNovo classeTooltip={'tooltip-input-modal'} classDiv='mb-0' value={props.propsFormik.values.odontologico[index].valor_med_ox_formatado || props.propsFormik.values.odontologico[index].valor_med_ox} name={`odontologico[${index}].valor_med_ox`} propsFormik={props.propsFormik} isDisable={false} max={100000000} />
                                        </td>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                            <span className='icon-consulta icon-New-Trash' onClick={target => removerLinha(arrayHelpers, props.propsFormik, index)} />
                                        </td>
                                    </tr>
                                )
                                :
                                <></>}
                        </>
                    ))}
                </FieldArray>
            </tbody>
        </table>)
}

const CadastroAcomodacao = (props) => {

    const onChangeAcomodacao = (option, propsFormik, arrayHelpers, index) => {
        if (option) {
            const valorAnterior = { ...propsFormik.values.acomodacoes[index] }
            propsFormik.setFieldValue(`acomodacoes[${index}].acomodacao`, option);
            if (!valorAnterior.acomodacao || !valorAnterior.acomodacao.value) {
                adicionarLinha(arrayHelpers, propsFormik)
            }
            let procedimentos = propsFormik.values.procedimentos_acomodacoes && propsFormik.values.procedimentos_acomodacoes.length >= 1 ?
                propsFormik.values.procedimentos_acomodacoes[0].procedimentos.map(item => ({ procedimento: item.procedimento, valor: undefined })) : [{ procedimento: undefined, valor: undefined }]

            if (propsFormik.values.tabelas.filter(item => item.base_calculo && item.base_calculo.value === 1).length > 0) {

                propsFormik.values.tabelas.filter(x => x.tabela).map((item, idx) => {

                    if (item.base_calculo && item.base_calculo.value === 1 && propsFormik.values.tabelas[idx].acomodacoes && propsFormik.values.tabelas[idx].acomodacoes.findIndex(x => x.acomodacao.value === option.value) === -1) {
                        let portes = Array(9).fill(0).map((item, idy) => ({ acomodacao: option, porte: idy, valor: 0, urgencia: 0, video: 0 }))
                        propsFormik.setFieldValue(`tabelas[${idx}].acomodacoes`, [...portes, ...propsFormik.values.tabelas[idx].acomodacoes])
                    }
                    return item
                })
            }

            propsFormik.setFieldValue(`procedimentos_acomodacoes[${index}]`, { acomodacao: option, procedimentos: procedimentos });

        }
        else {
            propsFormik.setFieldValue(`acomodacoes[${index}].acomodacao`, undefined);
            propsFormik.setFieldValue(`procedimentos_acomodacoes[${index}]`, undefined);
        }
    }

    const adicionarLinha = (arrayHelpers, propsFormik) => {
        arrayHelpers.insert(propsFormik.values.acomodacoes.length, { acomodacao: undefined, percentual: undefined, urgencia: undefined, video: undefined })
    }

    const removerLinha = (arrayHelpers, propsFormik, index) => {
        const arrayValoresHonorario = propsFormik.values.acomodacoes;

        if (arrayValoresHonorario.length === 1) {
            propsFormik.setFieldValue('acomodacoes', [{ acomodacao: undefined, percentual: undefined, urgencia: undefined, video: undefined }])
        } else {
            if (arrayValoresHonorario[index].acomodacao && arrayValoresHonorario[index].acomodacao.value)
                arrayHelpers.remove(index)

            if (arrayValoresHonorario.length === 1 && arrayValoresHonorario[index].acomodacao && arrayValoresHonorario[index].acomodacao.value)
                arrayHelpers.insert(propsFormik.values.acomodacoes.length, { acomodacao: undefined, percentual: undefined, urgencia: undefined, video: undefined })
        }

        if (propsFormik.values.tabelas.filter(item => item.base_calculo && item.base_calculo.value === 1).length > 0) {
            propsFormik.values.tabelas.map((item, idx) => {
                if (item.base_calculo && item.base_calculo.value === 1 && arrayValoresHonorario.filter(acm => acm.value === arrayValoresHonorario[index].value).length === 1) {
                    let tabAcomodacoes = propsFormik.values.tabelas[idx].acomodacoes ? [...propsFormik.values.tabelas[idx].acomodacoes] : []
                    tabAcomodacoes.splice(index, 9)
                    propsFormik.setFieldValue(`tabelas[${idx}].acomodacoes`, tabAcomodacoes)
                }
                return item
            })
        }

        let procedimentos_acomodacoes = propsFormik.values.procedimentos_acomodacoes ? [...propsFormik.values.procedimentos_acomodacoes] : []
        procedimentos_acomodacoes.splice(index, 1)
        propsFormik.setFieldValue(`procedimentos_acomodacoes`, procedimentos_acomodacoes);
    }

    return (
        <>
            <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                <thead>
                    {
                        <tr>
                            <th style={{ width: '5%', textAlign: 'center' }}>#</th>
                            <th style={{ width: '35%' }}>Acomodação</th>
                            <th style={{ width: '15%' }}>Percentual</th>
                            <th style={{ width: '15%' }}>Urgência</th>
                            <th style={{ width: '15%' }}>Vídeo</th>
                            <th style={{ width: '15%' }} className='text-center'>Ações</th>
                        </tr>
                    }
                </thead>
                <tbody>
                    <FieldArray name="acomodacoes">
                        {(arrayHelpers => (
                            <>
                                {props.propsFormik.values.acomodacoes && props.propsFormik.values.acomodacoes.length > 0 ?
                                    props.propsFormik.values.acomodacoes.map((item, index) =>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
                                            <td>
                                                <InputAcomodacao placeholder='Acomodação' classeTooltip={'tooltip-input-modal'} isClearable={false}
                                                    name={`acomodacoes[${index}].acomodacao`} onChange={(e) => onChangeAcomodacao(e, props.propsFormik, arrayHelpers, index)} propsFormik={props.propsFormik} tamanho="mb-0" />
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <InputValorNovo
                                                    value={props.propsFormik.values.acomodacoes[index].percentual_formatado || props.propsFormik.values.acomodacoes[index].percentual}
                                                    name={`acomodacoes[${index}].percentual`} classeTooltip={'tooltip-input-modal'} classDiv='mb-0'
                                                    propsFormik={props.propsFormik} isDisable={false} max={100000000} suffix={'%'} />
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <InputValorNovo
                                                    value={props.propsFormik.values.acomodacoes[index].urgencia_formatado || props.propsFormik.values.acomodacoes[index].urgencia}
                                                    name={`acomodacoes[${index}].urgencia`} classeTooltip={'tooltip-input-modal'} classDiv='mb-0'
                                                    propsFormik={props.propsFormik} isDisable={false} max={100000000} suffix={'%'} />
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <InputValorNovo
                                                    value={props.propsFormik.values.acomodacoes[index].video_formatado || props.propsFormik.values.acomodacoes[index].video}
                                                    name={`acomodacoes[${index}].video`} classeTooltip={'tooltip-input-modal'} classDiv='mb-0'
                                                    propsFormik={props.propsFormik} isDisable={false} max={100000000} suffix={'%'} />
                                            </td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                <span className='icon-consulta icon-New-Trash' onClick={target => removerLinha(arrayHelpers, props.propsFormik, index)} />
                                            </td>
                                        </tr>
                                    )
                                    :
                                    <></>}
                            </>
                        ))}
                    </FieldArray>
                </tbody>
            </table>
        </>)
}

const CadastroTabela = (props) => {
    const refTabelas = useRef(null)

    const onChangeAcomodacao = (option, propsFormik, arrayHelpers, index) => {
        propsFormik.values.tabelas.map((item, idx) => {
            propsFormik.setFieldValue(`tabelas[${index}].acomodacoes`, undefined)
        })
        if (option) {
            const valorAnterior = { ...propsFormik.values.tabelas[index] }
            propsFormik.setFieldValue(`tabelas[${index}].tabela`, option);
            if (!valorAnterior.tabela || !valorAnterior.tabela.value) {
                adicionarLinha(arrayHelpers, propsFormik)
            }
        }
        else {
            propsFormik.setFieldValue(`tabelas[${index}].tabela`, undefined);
        }
    }

    const adicionarAcomodacoes = (op, propsFormik, arrayHelpers, index) => {
        const option = op || propsFormik.values.tabelas[index].base_calculo

        if (option) {
            propsFormik.values.tabelas.map((item, idx) => {
                propsFormik.setFieldValue(`tabelas[${index}].acomodacoes`, undefined)
            })
            if (option.value === 1) {
                let portes = []
                propsFormik.values.acomodacoes.filter(item => item.acomodacao).filter((value, index, self) => self.findIndex(xa => xa.acomodacao.value === value.acomodacao.value) === index).map((acomodacao, idxAcom) => {
                    portes = [...portes, ...Array(9).fill(0).map((item, idx) => ({ acomodacao: acomodacao.acomodacao, porte: idx, valor: 0, urgencia: 0, video: 0 }))]
                })
                let tabelasAcomodacoes = propsFormik.values.tabelas[index].acomodacoes || []
                propsFormik.values.tabelas.map((item, idx) => {
                    propsFormik.setFieldValue(`tabelas[${idx}].acomodacoes`, [...portes, ...tabelasAcomodacoes])
                })
            }
            if (option.value === 2) {
                let portes = Array(9).fill(0).map((item, idx) => ({ porte: idx, valor: 0, urgencia: 0, video: 0 }))
                propsFormik.setFieldValue(`tabelas[${index}].portes`, portes)
            }
            // quando troca acomodação não está adicionando em porte acomodacao
        }
    }

    const onChangeBaseCalculo = (option, propsFormik, arrayHelpers, index) => {
        let tabelas = propsFormik.values.tabelas.map(x => {
            x.base_calculo = option
            return x
        })
        propsFormik.setFieldValue(`tabelas`, tabelas);
        if (option) {
            adicionarAcomodacoes(option, propsFormik, arrayHelpers, index)
        }
    }

    const adicionarLinha = (arrayHelpers, propsFormik) => {
        let objAdd = { tabela: undefined, base_calculo: undefined }
        objAdd.base_calculo = propsFormik.values.tabelas && propsFormik.values.tabelas[0] && propsFormik.values.tabelas[0].base_calculo ? propsFormik.values.tabelas[0].base_calculo : undefined
        arrayHelpers.insert(propsFormik.values.tabelas.length, objAdd)
    }

    const removerLinha = (arrayHelpers, propsFormik, index) => {
        const arrayValoresHonorario = propsFormik.values.tabelas;
        if (arrayValoresHonorario.length === 1) {
            propsFormik.setFieldValue('tabelas', [{ tabela: undefined, base_calculo: undefined }])
        } else {
            if (arrayValoresHonorario[index].tabela && arrayValoresHonorario[index].tabela.value)
                arrayHelpers.remove(index)

            if (arrayValoresHonorario.length === 1 && arrayValoresHonorario[index].tabela && arrayValoresHonorario[index].tabela.value)
                arrayHelpers.insert(propsFormik.values.tabelas.length, { tabela: undefined, base_calculo: undefined })
        }
    }

    return (
        <>
            <table className='table tabela-endereco table-responsive-md table-striped tabela' ref={refTabelas}>
                <thead>
                    {
                        <tr>
                            <th style={{ width: '5%', textAlign: 'center' }}>#</th>
                            <th style={{ width: '40%' }}>Tabela</th>
                            <th style={{ width: '40%' }}>Base Cálculo</th>
                            <th style={{ width: '15%' }} className='text-center'>Ações</th>
                        </tr>
                    }
                </thead>
                <tbody>
                    <FieldArray name="tabelas">
                        {(arrayHelpers => (
                            <>
                                {props.propsFormik.values.tabelas && props.propsFormik.values.tabelas.length > 0 ?
                                    props.propsFormik.values.tabelas.filter(x => !x.tabela_auxiliar).map((item, index) =>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
                                            <td>
                                                <InputTabelaProcedimento placeholder='Tabela' classeTooltip={'tooltip-input-modal'} isClearable={false}
                                                    name={`tabelas[${index}].tabela`} onChange={(e) => onChangeAcomodacao(e, props.propsFormik, arrayHelpers, index)} onBlur={() => adicionarAcomodacoes(undefined, props.propsFormik, arrayHelpers, index)} propsFormik={props.propsFormik} tamanho="mb-0" />
                                            </td>
                                            <td>
                                                <InputContratoBaseCalculo placeholder='Base Cálculo' classeTooltip={'tooltip-input-modal'} isDisabled={index > 0}
                                                    name={`tabelas[${index}].base_calculo`} onChange={(e) => onChangeBaseCalculo(e, props.propsFormik, arrayHelpers, index)} propsFormik={props.propsFormik} tamanho="mb-0" />
                                            </td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                <span className='icon-consulta icon-New-Trash' onClick={target => removerLinha(arrayHelpers, props.propsFormik, index)} />
                                            </td>
                                        </tr>
                                    )
                                    :
                                    <></>}
                            </>
                        ))}
                    </FieldArray>
                </tbody>
            </table>
            {props.propsFormik.touched.tabelas && props.propsFormik.errors.tabelas && !Array.isArray(props.propsFormik.errors.tabelas) ?
                <Overlay target={refTabelas.current} show={true} placement="bottom">
                    {props2 => {
                        return (
                            <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                <div className="error">
                                    <FiAlertTriangle className="icone" /> <span>{props.propsFormik.errors.tabelas}</span></div>
                            </Tooltip>)
                    }}
                </Overlay> : <></>}
        </>)
}

const CadastroProcedimento = (props) => {
    const onChangeAcomodacao = (option, propsFormik, arrayHelpers, index) => {
        if (option) {
            if ((index + 1) === propsFormik.values.procedimentos.length) {
                adicionarLinha(arrayHelpers, propsFormik)
            }
        }
    }

    if (props.setArrayDados) {
        props.setArrayDados(props.propsFormik.values.procedimentos)
    }
    const adicionarLinha = (arrayHelpers, propsFormik) => {
        arrayHelpers.insert(propsFormik.values.procedimentos.length, { procedimento: undefined, valor_padrao: undefined, valor_diferenciado: undefined })
    }

    const removerLinha = (arrayHelpers, propsFormik, index) => {
        const arrayValoresHonorario = propsFormik.values.procedimentos;
        
        let id = propsFormik.values?.id
        if (!id)
            if (arrayValoresHonorario.length === 1) {
                propsFormik.setFieldValue('procedimentos', [{ procedimento: undefined, valor_padrao: undefined, valor_diferenciado: undefined }])
            } else {
                if (arrayValoresHonorario[index])
                    arrayHelpers.remove(index)

                if (arrayValoresHonorario.length === 1 && arrayValoresHonorario[index].procedimento)
                    arrayHelpers.insert(propsFormik.values.procedimentos.length, { procedimento: undefined, valor_padrao: undefined, valor_diferenciado: undefined })
            }
        else
            mostrarToast('erro', 'Ação não permitida')   
    }
    document.onselectionchange = function () {
        let selection = document.getSelection();
    }
    return (
        <>
            <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                <thead>
                    {
                        <tr>
                            <th style={{ width: '5%', textAlign: 'center' }}>#</th>
                            <th style={{ width: '10%' }}>Código</th>
                            <th style={{ width: '35%' }}>Descrição</th>
                            <th style={{ width: '10%' }}>Porte*</th>
                            <th style={{ width: '15%' }}>Valor Padrão</th>
                            <th style={{ width: '15%' }}>Valor Diferenciado</th>
                            <th style={{ width: '10%' }} className='text-center'>Ações</th>
                        </tr>
                    }
                </thead>
                <tbody>
                    <FieldArray name="procedimentos">
                        {(arrayHelpers => (
                            <>
                                {props.propsFormik.values.procedimentos && props.propsFormik.values.procedimentos.length > 0 ?
                                    props.propsFormik.values.procedimentos.map((item, index) =>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
                                            <td>
                                                <InputPadraoSimples
                                                    tamanho="mb-0"
                                                    name={`procedimentos[${index}].procedimento`}
                                                    type="text"
                                                    placeholder="Código"
                                                    classeTooltip={'tooltip-input-modal'} />
                                            </td>
                                            <td>
                                                <InputPadraoSimples
                                                    tamanho="mb-0"
                                                    name={`procedimentos[${index}].descricao`}
                                                    type="text"
                                                    placeholder="Descrição"
                                                    classeTooltip={'tooltip-input-modal'}
                                                    onBlur={(value) => { onChangeAcomodacao(value.target.value, props.propsFormik, arrayHelpers, index) }} />
                                            </td>
                                            <td>
                                                <InputPadraoSimples
                                                    tamanho="mb-0"
                                                    name={`procedimentos[${index}].porte`}
                                                    type="text"
                                                    maxLength="1"
                                                    placeholder="Porte"
                                                    classeTooltip={'tooltip-input-modal'}
                                                    obrigatorio={true} />
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <InputValorNovo
                                                    value={props.propsFormik.values.procedimentos[index].valor_padrao_formatado || props.propsFormik.values.procedimentos[index].valor_padrao}
                                                    name={`procedimentos[${index}].valor_padrao`} classeTooltip={'tooltip-input-modal'} classDiv='mb-0'
                                                    propsFormik={props.propsFormik} isDisable={false} max={100000000} />
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <InputValorNovo
                                                    value={props.propsFormik.values.procedimentos[index].valor_diferenciado_formatado || props.propsFormik.values.procedimentos[index].valor_diferenciado}
                                                    name={`procedimentos[${index}].valor_diferenciado`} classeTooltip={'tooltip-input-modal'} classDiv='mb-0'
                                                    propsFormik={props.propsFormik} isDisable={false} max={100000000} />
                                            </td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                <span className='icon-consulta icon-New-Trash' onClick={target => removerLinha(arrayHelpers, props.propsFormik, index)} />
                                            </td>
                                        </tr>
                                    )
                                    :
                                    <></>}
                            </>
                        ))}
                    </FieldArray>
                </tbody>
            </table>
        </>)
}

const CadastroPorteAnestesico = (props) => {
    return (
        <div className="row">
            <FieldArray name="portes">
                {(arrayHelpers => (
                    <>
                        {
                            props.propsFormik.values.tabelas && props.propsFormik.values.tabelas.length && props.propsFormik.values.tabelas.map((tabela, tbIdx) => tabela.tabela && tabela.base_calculo && tabela.base_calculo.value === 2 &&
                                <div className={`${props.propsFormik.values.tabelas.filter((item) => item.tabela && item.base_calculo && item.base_calculo.value === 2).length === 1 ? 'col-lg-12' : 'col-lg-6'} margin-top-1`}>
                                    <div className={'titulo-acomodacao'}><span>{tabela.tabela.label && tabela.tabela.label}</span></div>
                                    <table className='table tabela-porte-acomodacao table-responsive-md table-striped tabela' >
                                        <thead>
                                            {
                                                <tr>
                                                    <th style={{ width: '10%', textAlign: 'center' }}>Porte</th>
                                                    <th style={{ width: '30%' }}>Valor</th>
                                                    <th style={{ width: '30%' }}>Urgência</th>
                                                    <th style={{ width: '30%' }}>Vídeo</th>
                                                </tr>
                                            }
                                        </thead>
                                        <tbody>
                                            {props.propsFormik.values.tabelas[tbIdx].portes && props.propsFormik.values.tabelas[tbIdx].portes.length > 0 ?
                                                props.propsFormik.values.tabelas[tbIdx].portes.map((item, index) =>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{item.porte}</td>
                                                        <td style={{ textAlign: 'right' }}>
                                                            <InputValorNovo
                                                                value={props.propsFormik.values.tabelas[tbIdx].portes[index].valor_formatado || props.propsFormik.values.tabelas[tbIdx].portes[index].valor}
                                                                name={`tabelas[${tbIdx}].portes[${index}].valor`} classeTooltip={'tooltip-input-modal'} classDiv='mb-0'
                                                                propsFormik={props.propsFormik} isDisable={false} max={100000000} prefix={'R$ '} />
                                                        </td>
                                                        <td style={{ textAlign: 'right' }}>
                                                            <InputValorNovo
                                                                value={props.propsFormik.values.tabelas[tbIdx].portes[index].urgencia_formatado || props.propsFormik.values.tabelas[tbIdx].portes[index].urgencia}
                                                                name={`tabelas[${tbIdx}].portes[${index}].urgencia`} classeTooltip={'tooltip-input-modal'} classDiv='mb-0'
                                                                propsFormik={props.propsFormik} isDisable={false} max={100000000} suffix={'%'} />
                                                        </td>
                                                        <td style={{ textAlign: 'right' }}>
                                                            <InputValorNovo
                                                                value={props.propsFormik.values.tabelas[tbIdx].portes[index].video_formatado || props.propsFormik.values.tabelas[tbIdx].portes[index].video}
                                                                name={`tabelas[${tbIdx}].portes[${index}].video`} classeTooltip={'tooltip-input-modal'} classDiv='mb-0'
                                                                propsFormik={props.propsFormik} isDisable={false} max={100000000} suffix={'%'} />
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <></>}

                                        </tbody>
                                    </table>
                                </div>)
                        }
                    </>
                ))}
            </FieldArray>
        </div>)
}

const CadastroPorteAcomodacao = (props) => {
    const [tabIndex, setTabIndex] = useState(0)

    return (
        <div className="row">

            <div className='col-12'><Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>
                    {
                        props.propsFormik.values.tabelas && props.propsFormik.values.tabelas.length && props.propsFormik.values.tabelas.filter(item => item.base_calculo && item.base_calculo.value === 1).map((tabela, tbIdx) =>
                            <Tab className={"react-tabs__tab tab-porte-acomodacao"}> {tabela.tabela && tabela.tabela.label} </Tab>)
                    }
                </TabList>
                {
                    props.propsFormik.values.tabelas && props.propsFormik.values.tabelas.length && props.propsFormik.values.tabelas.findIndex((tabela) => tabela.tabela && tabela.base_calculo && tabela.base_calculo.value === 1) > -1 && props.propsFormik.values.tabelas.map((tabela, tbIdx) => tabela.tabela &&
                        <TabPanel>
                            <div className='row'> {tabela.acomodacoes && tabela.acomodacoes.length &&
                                props.propsFormik.values.acomodacoes.filter(acomodacao => acomodacao.acomodacao).map((acomodacao, acomIdx) =>
                                    <div className={`${props.propsFormik.values.acomodacoes.filter((item) => item.acomodacao).length === 1 ? 'col-lg-12' : 'col-lg-6'} margin-top-1`}>
                                        <div className={'titulo-acomodacao'}><span>{acomodacao.acomodacao.label && acomodacao.acomodacao.label}</span></div>
                                        <table className='table tabela-porte-acomodacao table-responsive-md table-striped tabela' >
                                            <thead>
                                                {
                                                    <tr>
                                                        <th style={{ width: '10%', textAlign: 'center' }}>Porte</th>
                                                        <th style={{ width: '30%' }}>Valor</th>
                                                        <th style={{ width: '30%' }}>Urgência</th>
                                                        <th style={{ width: '30%' }}>Vídeo</th>
                                                    </tr>
                                                }
                                            </thead>
                                            <tbody>
                                                <FieldArray name={`tabelas[${tbIdx}].acomodacoes`}>
                                                    {(arrayHelpers => (
                                                        <>
                                                            {tabela.acomodacoes.map((item, index) => {

                                                                return item.acomodacao.value === acomodacao.acomodacao.value ? <tr>
                                                                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{item.porte}</td>
                                                                    <td style={{ textAlign: 'right' }}>
                                                                        <InputValorNovo
                                                                            value={tabela.acomodacoes[index].valor_formatado || tabela.acomodacoes[index].valor}
                                                                            name={`tabelas[${tbIdx}].acomodacoes[${index}].valor`} classeTooltip={'tooltip-input-modal'} classDiv='mb-0'
                                                                            propsFormik={props.propsFormik} isDisable={false} max={100000000} prefix={'R$ '} />
                                                                    </td>
                                                                    <td style={{ textAlign: 'right' }}>
                                                                        <InputValorNovo
                                                                            value={item.urgencia_formatado || item.urgencia}
                                                                            name={`tabelas[${tbIdx}].acomodacoes[${index}].urgencia`} classeTooltip={'tooltip-input-modal'} classDiv='mb-0'
                                                                            propsFormik={props.propsFormik} isDisable={false} max={100000000} suffix={'%'} />
                                                                    </td>
                                                                    <td style={{ textAlign: 'right' }}>
                                                                        <InputValorNovo
                                                                            value={item.video_formatado || item.video}
                                                                            name={`tabelas[${tbIdx}].acomodacoes[${index}].video`} classeTooltip={'tooltip-input-modal'} classDiv='mb-0'
                                                                            propsFormik={props.propsFormik} isDisable={false} max={100000000} suffix={'%'} />
                                                                    </td>
                                                                </tr> : <></>
                                                            }
                                                            )}
                                                        </>
                                                    ))
                                                    }
                                                </FieldArray >
                                            </tbody>
                                        </table>
                                    </div>)}</div></TabPanel>
                    )
                }
            </Tabs></div>

        </div >)
}

const CadastroProcedimentoAcomodacao = (props) => {

    const onChangeProcedimento = (option, propsFormik, arrayHelpers, index, acomIdx) => {

        if (option) {
            const valorAnterior = { ...propsFormik.values.procedimentos_acomodacoes[acomIdx].procedimentos[index] }

            propsFormik.values.procedimentos_acomodacoes.map((acom, acmIdx) => {

                let novoElemento = { ...acom }
                novoElemento.procedimentos[index].procedimento = option
                if (!valorAnterior.procedimento || !valorAnterior.procedimento.value) {
                    novoElemento.procedimentos.push({ procedimento: undefined, valor: undefined })
                }
                arrayHelpers.replace(acmIdx, novoElemento)
            })

        }
        else {
            propsFormik.setFieldValue(`procedimentos_acomodacoes[${acomIdx}].procedimentos[${index}].procedimento`, undefined);
        }
    }

    const removerLinha = (arrayHelpers, propsFormik, index, acomIdx, helpers2) => {
        //remoção é feita por map em cada array
        let arrayValoresHonorario = propsFormik.values.procedimentos_acomodacoes[acomIdx].procedimentos;

        if (arrayValoresHonorario.length === 1) {
            let arrayInsert = propsFormik.values.procedimentos_acomodacoes.map((acomodacoes, idx) => {
                acomodacoes.procedimentos = [{ procedimento: undefined, valor_padrao: undefined, valor_diferenciado: undefined }]
                return acomodacoes
            })
            propsFormik.setFieldValue(`procedimentos_acomodacoes`, arrayInsert)
        } else {
            if (arrayValoresHonorario[index].procedimento && arrayValoresHonorario[index].procedimento.value) {
                let arrayRemove = [...propsFormik.values.procedimentos_acomodacoes].map((acomodacoes, idx) => {
                    acomodacoes.procedimentos.splice(index, 1)
                    return acomodacoes
                })
                propsFormik.setFieldValue(`procedimentos_acomodacoes`, arrayRemove)
            }

            if (arrayValoresHonorario.length === 1 && arrayValoresHonorario[index].procedimento && arrayValoresHonorario[index].procedimento.value) {
                let arrayInsert = [...propsFormik.values.procedimentos_acomodacoes].map((acomodacoes, idx) => {
                    acomodacoes.procedimentos.push({ procedimento: undefined, valor_padrao: undefined, valor_diferenciado: undefined })
                    return acomodacoes
                })
                propsFormik.setFieldValue(`procedimentos_acomodacoes`, arrayInsert)
            }
        }
    }

    return (
        <div className="row">
            <FieldArray name="procedimentos_acomodacoes">
                {(arrayHelpers => (
                    <>
                        {
                            props.propsFormik.values.procedimentos_acomodacoes && props.propsFormik.values.procedimentos_acomodacoes.length
                            && props.propsFormik.values.procedimentos_acomodacoes.map((acomodacao, acomIdx) => acomodacao.acomodacao &&
                                <div className={`${props.propsFormik.values.procedimentos_acomodacoes.filter((item) => item.acomodacao).length === 1 ? 'col-lg-12' : 'col-lg-6'} margin-top-1`}>
                                    <div className={'titulo-acomodacao'}><span>{acomodacao.acomodacao.label && acomodacao.acomodacao.label}</span></div>
                                    <table className='table tabela-porte-acomodacao table-responsive-md table-striped tabela' >
                                        <thead>
                                            {
                                                <tr>
                                                    <th style={{ width: '5%' }}>#</th>
                                                    <th style={{ width: '55%' }}>Procedimentos</th>
                                                    <th style={{ width: '25%' }}>Valor</th>
                                                    <th style={{ width: '20%', textAlign: 'center' }}>Ações</th>
                                                </tr>
                                            }
                                        </thead>
                                        <tbody>
                                            <FieldArray name={`procedimentos_acomodacoes[${acomIdx}].procedimentos`}>
                                                {(arrayHelpersProc => (<>
                                                    {acomodacao.procedimentos && acomodacao.procedimentos.length > 0 ?
                                                        acomodacao.procedimentos.map((item, index) =>
                                                            <tr>
                                                                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    <InputProcedimentosTabela tabelas={props.propsFormik.values.tabelas.filter(x => x.tabela).map(x => x.tabela.value)} placeholder='Procedimento' classeTooltip={'tooltip-input-modal'} isClearable={false}
                                                                        name={`procedimentos_acomodacoes[${acomIdx}].procedimentos[${index}].procedimento`} onChange={(e) => onChangeProcedimento(e, props.propsFormik, arrayHelpers, index, acomIdx)} propsFormik={props.propsFormik} tamanho="mb-0" />
                                                                </td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    <InputValorNovo
                                                                        value={acomodacao.procedimentos[index].valor_formatado || acomodacao.procedimentos[index].valor}
                                                                        name={`procedimentos_acomodacoes[${acomIdx}].procedimentos[${index}].valor`} classeTooltip={'tooltip-input-modal'} classDiv='mb-0'
                                                                        propsFormik={props.propsFormik} isDisable={false} max={100000000} prefix={'R$ '} />
                                                                </td>
                                                                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                                    <span className='icon-consulta icon-New-Trash' onClick={target => removerLinha(arrayHelpersProc, props.propsFormik, index, acomIdx, arrayHelpers)} />
                                                                </td>
                                                            </tr>
                                                        )
                                                        :
                                                        <></>}
                                                </>
                                                ))}
                                            </FieldArray>
                                        </tbody>
                                    </table>
                                </div>)
                        }
                    </>
                ))}
            </FieldArray>
        </div>)
}

const CadastroPacoteProcedimento = (props) => {
    const [totalProcedimentos, setTotalprocedimentos] = useState('R$ 0,00')
    const [idxAlteracao, setIdxAlteracao] = useState(null)
    const refProcedimentos = useRef(null)

    useEffect(() => {
        if (props.totalProcedimentos)
            setTotalprocedimentos(props.totalProcedimentos)
    }, [props.totalProcedimentos])

    const onChangeProced = async (option, propsFormik, arrayHelpers, index) => {
        const arraypacote_procedimentos = [...propsFormik.values.pacote_procedimentos]
        if (option) {
            const valorAnterior = { ...propsFormik.values.pacote_procedimentos[index] }

            arraypacote_procedimentos[index].procedimento = option

            if (!valorAnterior.procedimento || !valorAnterior.procedimento.value)
                adicionarLinha(arrayHelpers, propsFormik)
        }
        else {
            arraypacote_procedimentos[index].procedimento = undefined
            propsFormik.setFieldValue('pacote_procedimentos', arraypacote_procedimentos);
        }
    }

    const alteracao = (idx, item) => {
        cancelar()
        setIdxAlteracao(idx)
        props.propsFormik.setFieldValue('descricao_pacote', item.descricao)
        props.propsFormik.setFieldValue('pacote_procedimentos', [...item.procedimentos, { procedimento: undefined, acomodacao: undefined, valor: 0 }])

        const procedimentos = item.procedimentos
        let valorTotal = procedimentos.map(x => parseFloat(x.valor || 0)).reduce((ant, atual) => ant + atual, 0);
        setTotalprocedimentos(formatMoeda(valorTotal))
    }

    const onChangeValor = async (propsFormik) => {
        const procedimentos = propsFormik.values.pacote_procedimentos
        let valorTotal = procedimentos.map(x => parseFloat(x.valor || 0)).reduce((ant, atual) => ant + atual, 0);
        setTotalprocedimentos(formatMoeda(valorTotal))
    }

    const adicionarLinha = (arrayHelpers, propsFormik) => {
        const arrayProced = propsFormik.values.pacote_procedimentos;
        if (arrayProced && arrayProced.findIndex(x => !x.procedimento || !x.procedimento.value) === -1) {

            arrayHelpers.insert(propsFormik.values.pacote_procedimentos.length, { procedimento: undefined, acomodacao: undefined, valor: 0 })
        }
    }

    const removerLinha = (arrayHelpers, propsFormik, index) => {
        const arrayValoresHonorario = propsFormik.values.pacote_procedimentos;
        if (arrayValoresHonorario.length === 1) {
            propsFormik.setFieldValue('pacote_procedimentos', [{ procedimento: undefined, acomodacao: undefined, valor: 0 }])
        } else {
            if ((arrayValoresHonorario[index].procedimento && arrayValoresHonorario[index].procedimento.value) || arrayValoresHonorario.length === index + 1)
                arrayHelpers.remove(index)

            if ((arrayValoresHonorario.length === 1 && arrayValoresHonorario[index].procedimento && arrayValoresHonorario[index].procedimento.value) || arrayValoresHonorario.length === index + 1)
                arrayHelpers.insert(propsFormik.values.pacote_procedimentos.length, { procedimento: undefined, acomodacao: undefined, valor: 0 })
        }

        setTotalprocedimentos(formatMoeda(propsFormik.values.pacote_procedimentos.filter(x => x.procedimento).reduce((partialSum, a) => partialSum + parseFloat(a.valor), 0)))
    }

    const adicionar = () => {
        if (props.propsFormik.values.descricao_pacote && props.propsFormik.values.pacote_procedimentos.filter(item => item.procedimento && item.procedimento.value).length > 0) {
            let pacotes = props.propsFormik.values.pacotes ? props.propsFormik.values.pacotes : []
            let procedimentosValor = totalProcedimentos

            if (idxAlteracao !== undefined && idxAlteracao !== null) {
                pacotes.splice(idxAlteracao, 1, { descricao: props.propsFormik.values.descricao_pacote, procedimentos: props.propsFormik.values.pacote_procedimentos.filter(item => item.procedimento && item.procedimento.value), valor_total: procedimentosValor })
                setIdxAlteracao(null)
            } else {
                pacotes.push({ descricao: props.propsFormik.values.descricao_pacote, procedimentos: props.propsFormik.values.pacote_procedimentos.filter(item => item.procedimento && item.procedimento.value), valor_total: procedimentosValor })
            }

            props.propsFormik.setFieldValue('pacotes', pacotes)

            props.propsFormik.setFieldValue('descricao_pacote', undefined)
            props.propsFormik.setFieldValue('pacote_procedimentos', [{ procedimento: undefined, acomodacao: undefined, valor: 0 }])
            setTotalprocedimentos('R$ 0,00')
            props.propsFormik.setFieldTouched('pacote_procedimentos', false)
        } else {
            props.propsFormik.setFieldTouched('descricao_pacote', true)
            props.propsFormik.setFieldTouched('pacote_procedimentos', true)
        }

    }

    const cancelar = () => {
        props.propsFormik.setFieldTouched('pacote_procedimentos', false)
        props.propsFormik.setFieldValue('descricao_pacote', undefined)
        props.propsFormik.setFieldValue('pacote_procedimentos', [{ procedimento: undefined, acomodacao: undefined, valor: 0 }])
        setTotalprocedimentos('R$ 0,00')
        setIdxAlteracao(null)
    }

    const removerLinhaPacote = (index) => {
        let listaPacotes = [...props.propsFormik.values.pacotes]
        listaPacotes.splice(index, 1)
        props.propsFormik.setFieldValue('pacotes', listaPacotes)
    }

    let procedimentos = (<FieldArray name="pacote_procedimentos">
        {(arrayHelpers => (
            <>
                {props.propsFormik.values.pacote_procedimentos ?
                    props.propsFormik.values.pacote_procedimentos.map((item, index) => <div className='modal-body-cadastro row mr-0 ml-0' ref={refProcedimentos}>
                        {/* col-sm-11 col-xl-4 col-lg-6 */}
                        <div className='row col-7'>
                            <div className={"form-group col-1 pl-0 text-center"}>
                                <label htmlFor={props.id || props.name}>{index === 0 ? '#' : undefined}</label>
                                <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{index + 1}</span></div>
                            </div>

                            <InputProcedimentosTabela tabelas={props.propsFormik.values.tabelas.filter(x => x.tabela).map(x => x.tabela.value)} label={index === 0 ? 'Procedimento' : undefined} name={`pacote_procedimentos[${index}].procedimento`}
                                propsFormik={props.propsFormik} tamanho="col-11 pl-0" {...props} classeTooltip={'tooltip-input-modal'} modal={true} isClearable={false}
                                onChange={(option) => onChangeProced(option, props.propsFormik, arrayHelpers, index)} className={'procedimento-linha'} cacheOptions={false}
                                placeholder={'Procedimento'} obrigatorio />
                        </div>

                        <InputAcomodacao label={index === 0 ? 'Acomodação' : undefined} multiplo={true} placeholder='Acomodação' classeTooltip={'tooltip-input-modal'} isClearable={true}
                            name={`pacote_procedimentos[${index}].acomodacao`} propsFormik={props.propsFormik} tamanho="col-sm-2 col-xl-2 col-lg-2 col-md-3 pr-0"
                            onChange={option => { props.propsFormik.setFieldValue(`pacote_procedimentos[${index}].acomodacao`, option); }} />

                        <InputValorNovo prefix={''} label={index === 0 ? 'Valor R$' : undefined} value={props.propsFormik.values.pacote_procedimentos[index].valor_formatado || props.propsFormik.values.pacote_procedimentos[index].valor}
                            name={`pacote_procedimentos[${index}].valor`} classeTooltip={'tooltip-input-modal'} classDiv='col-8 col-xl-2 col-lg-2 col-md-2 text-center pr-0' onBlur={(e) => onChangeValor(props.propsFormik)}
                            propsFormik={props.propsFormik} isDisable={false} max={100000000} />

                        <div className='col-4 col-xl-1 col-lg-1 col-md-2 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-center'>
                            <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, props.propsFormik, index)} />
                        </div>
                    </div>)
                    :
                    <></>}
            </>
        ))}
    </FieldArray>)

    const listaProcedimentos = useMemo(() => procedimentos, [props.propsFormik.values.pacote_procedimentos, props.propsFormik.values.tabelas])

    return (
        <>
            <div className="mb-20">
                <InputPadrao
                    tamanho="col-xl-4 col-lg-6"
                    label="Descrição Pacote"
                    name="descricao_pacote"
                    type="text"
                    placeholder="Descrição Pacote"
                    classeTooltip={'tooltip-input-modal'}
                    obrigatorio
                />
                <div className="mt-40">
                    {listaProcedimentos}
                    {props.propsFormik.touched.pacote_procedimentos && props.propsFormik.errors.pacote_procedimentos && !Array.isArray(props.propsFormik.errors.pacote_procedimentos) ?
                        <Overlay target={refProcedimentos.current} show={true} placement="bottom">
                            {props2 => {
                                return (
                                    <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                        <div className="error">
                                            <FiAlertTriangle className="icone" /> <span>{props.propsFormik.errors.pacote_procedimentos}</span></div>
                                    </Tooltip>)
                            }}
                        </Overlay> : <></>}
                    <div className='modal-body-cadastro row mr-0 ml-0'>
                        <div className={"col-11 text-right vertical-center"}>
                            <span>Total: <span className='ml-1'><b>{totalProcedimentos}</b></span></span>
                        </div>
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo mt-1'>
                        {idxAlteracao !== null && idxAlteracao !== undefined ? <BotaoPadrao texto='Cancelar' type='button' click={_ => cancelar()} /> : <></>}
                        <BotaoPadrao texto='Adicionar' type='button' click={_ => adicionar()} />
                    </div>
                    <div className='mt-40 mb-40'><span>Pacotes</span></div>
                    <div>
                        <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                            <thead>
                                {
                                    <tr>
                                        <th style={{ width: '5%', height: '5px' }}>#</th>
                                        <th style={{ width: '30%' }}>Descrição</th>
                                        <th style={{ width: '35%' }}>Procedimentos</th>
                                        <th style={{ width: '15%', textAlign: 'right' }}>Valor Total</th>
                                        <th style={{ width: '15%' }} className='text-center'>Ações</th>
                                    </tr>
                                }
                            </thead>
                            <tbody>
                                {
                                    props.propsFormik.values.pacotes && props.propsFormik.values.pacotes.length ?
                                        props.propsFormik.values.pacotes.map((x, index) =>
                                            <tr height='10px'>
                                                <td><span>{index + 1}</span></td>
                                                <td><span>{x.descricao}</span></td>
                                                <td>
                                                    <PopoverPadrao id={index} titulo={<>Procedimentos</>} conteudo={<span>{x.procedimentos.map(item => <>
                                                        <span><b>{item.procedimento.codigo + ': '}</b></span><br />
                                                        <span>{item.acomodacao && item.acomodacao.length > 0 ? item.acomodacao.map(acom => acom.label).join(', ') : 'Sem acomodacao'}</span><br />
                                                    </>)}</span>}>
                                                        <span>{x.procedimentos.length > 3 ? x.procedimentos.slice(0, 3).map(item => item.procedimento.codigo).join(', ') + '...' : x.procedimentos.map(item => item.procedimento.codigo).join(', ')}</span>
                                                    </PopoverPadrao>
                                                </td>
                                                <td style={{ textAlign: 'right' }}><span>{x.valor_total}</span></td>
                                                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                    <span className='icon-consulta icon-Pen'
                                                        onClick={_ => { alteracao(index, x) }} />
                                                    <span className='icon-consulta icon-New-Trash' onClick={target => removerLinhaPacote(index)} />
                                                </td>
                                            </tr>
                                        )
                                        : <tr><td colSpan='8' className='text-center'>Nenhum Registro Cadastrado</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
}

export { CadastroOdontologia, CadastroAcomodacao, CadastroTabela, CadastroProcedimento, CadastroPorteAnestesico, CadastroPorteAcomodacao, CadastroProcedimentoAcomodacao, CadastroPacoteProcedimento }