import { InputPadrao, InputNumero, InputMonthPicker, InputDatePicker, InputValorNovo } from '../../../../components/Inputs'
import { InputAcomodacao, InputEmpresa, InputOperadoraSaude, InputTabelaProcedimento, InputTipoGuia } from '../../../../components/InputsAutoComplete'
import React, { useState, useEffect } from 'react'
import Yup, { validaData } from '../../../../util/Validacoes'
import { HeaderCadastro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form, FieldArray } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get, validar } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'
import { ModalDuasOpcoes } from '../../../../components/Modais'

export default props => {
    let { id } = useParams()
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [msgValidacao, setMsgValidacao] = useState('')
    const [valoresIniciais, setValoresIniciais] = useState({
        id: '',
        ano: '',
        inicio: '',
        fim: '',
        acomodacoes: [],
        tabela_procedimento: '',
    })
    const [validacao] = useState(Yup.object({
        ano: Yup.string().nullable().required(),
        inicio: Yup.string().nullable().required().test('Inicio', 'Data inválida!', function (value) { return validaData(this.parent.inicio) }),
        fim: Yup.string().nullable().required().test('Fim', 'Data inválida!', function (value) { return validaData(this.parent.fim) }),
        acomodacoes: Yup.string().nullable().required(),
        tabela_procedimento: Yup.string().nullable().required(),
    }))

    const manter = []

    const validarSubmit = (values, propsFormik) => {
        setSalvando(true)

        const obj = {
            id: values.id,
            inicio: values.inicio,
            fim: values.fim,
            acomodacao: values.acomodacoes.map(x => x.value).join(','),
            tabela: values.tabela_procedimento.map(x => x.value).join(',')
        }

        validar(`/faturamento/validarinterposicaoporte`, { ...obj }, () => Submit(values, propsFormik), (e) => {
            const alertas = e.response.data.alertas;
            setMsgValidacao(<><div dangerouslySetInnerHTML={{ __html: alertas[0].replace(/"/g, '') }} /></>)
            setShowModal(true)
            setSalvando(false)
        })

        console.log(values)
    }

    const Submit = (values, propsFormik) => {
        salvar(
            { ...values },
            '/faturamento/porteanestesicoparticular',
            obj => {
                if (props.modal) {
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else
                        props.setShow && props.setShow(false)
                }
                else {
                    mostrarToast('sucesso', mensagens.sucesso)
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                    }
                    else
                        props.history.push('/faturamento/porteanestesicoparticular/consulta')
                }
            },
            () => setSalvando(false))
    }

    useEffect(_ => {
        if (!props.modal && id)
            Promise.resolve(get(`/faturamento/porteanestesicoparticular/${id}`)).then(obj => {
                if (id && !obj.id)
                    props.history.push('/faturamento/porteanestesicoparticular/consulta')

                const values = {
                    id: obj.id,
                    ano: obj.ano,
                    inicio: obj.inicio,
                    fim: obj.fim,
                    acomodacoes: obj.acomodacoes.map(x => ({ value: x.id, label: x.acomodacao, portes: x.portes })),
                    tabela_procedimento: obj.tabelas.map(x => ({ value: x.id, label: x.descricao }))
                }
                setValoresIniciais(values)
            })
    }, [id])

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Faturamento</span>,
        () => <Link className='link-active' to={'/faturamento/porteanestesicoparticular/consulta'}>Porte Anestésico</Link>,
    ]

    const gerenciarAcomodacao = (propsFormik, option) => {
        let newOption = []
        if (option && (propsFormik.values.acomodacoes.length === 0 || option.length > propsFormik.values.acomodacoes.length)) {
            const acomodacao = option.filter(x => !propsFormik.values.acomodacoes.find(y => y.value === x.value))[0]
            newOption = [...propsFormik.values.acomodacoes, { ...acomodacao, portes: [...Array(9).keys()].map(x => { return { porte: x, valor_porte: 0, urgencia: 0 } }) }]
        } else {
            if (option) {
                const acomodacao = propsFormik.values.acomodacoes.filter(x => !option.find(y => y.value === x.value))[0]
                newOption = propsFormik.values.acomodacoes.filter(x => x.value !== acomodacao.value)
            }
        }
        propsFormik.setFieldValue('acomodacoes', newOption);
    }

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
            {propsFormik => {
                return (<Form>
                    {!props.modal && <HeaderCadastro titulo={"Cadastro Porte Anestésico Particular"} link={links} />}
                    <div className={props.modal && props.modal || 'card mb-20'}>
                        <div className={props.modal || 'card-body'}>
                            <div className='row'>
                                <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' />
                                <InputNumero label='Ano' propsFormik={propsFormik} name='ano' classDiv={!props.modal && 'col-lg-1' || 'col-lg-12'} max={9999}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} onBlur={_ => { propsFormik.setFieldValue("inicio", '01/01/' + propsFormik.values["ano"]); propsFormik.setFieldValue("fim", '31/12/' + propsFormik.values["ano"]) }} />
                                <InputDatePicker label="Inicio" name="inicio" type="text" setarValor={value => { propsFormik.setFieldValue("inicio", value) }}
                                    classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2' || 'col-lg-12'} />
                                <InputDatePicker label="Fim" name="fim" type="text" setarValor={value => { propsFormik.setFieldValue("fim", value) }}
                                    classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2' || 'col-lg-12'} />
                                <InputAcomodacao label='Acomodação' name="acomodacoes" propsFormik={propsFormik} obrigatorio {...props}
                                    tamanho={!props.modal && 'col-lg-3' || 'col-lg-12'} classeTooltip={props.modal && 'tooltip-input-modal'} multiplo={true}
                                    onChange={option => { gerenciarAcomodacao(propsFormik, option) }} />
                                <InputTabelaProcedimento label='Tabela' name="tabela_procedimento" propsFormik={propsFormik} obrigatorio={true} salvarENovo={salvarENovo}
                                    tamanho={!props.modal && 'col-lg-4' || 'col-lg-12'} {...props} multiplo={true} />
                                <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <FieldArray name="acomodacao">
                            {arrayHelpers => (
                                propsFormik.values && propsFormik.values.acomodacoes && propsFormik.values.acomodacoes.map((acomodacao, index) =>
                                    <div className={`${propsFormik.values.acomodacoes.length > 1 && 'col-lg-6' || 'col-lg-12'} margin-top-1`}>
                                        <div className='card mb-20'>
                                            <div className='card-body'>
                                                <div className={'titulo-acomodacao'}><span>{acomodacao.label}</span></div>
                                                <table className='table tabela-porte-acomodacao table-responsive-md table-striped tabela' >
                                                    <thead>
                                                        {
                                                            <tr>
                                                                <th style={{ width: '20%' }}>Porte</th>
                                                                <th style={{ width: '20%', textAlign: 'right' }}>Valor Porte</th>
                                                                <th style={{ width: '20%', textAlign: 'right' }}>Urgência</th>
                                                            </tr>
                                                        }
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(9).keys()].map((x, indexPorte) =>
                                                            <tr>
                                                                <>
                                                                    <td>{x}</td>
                                                                    <td>
                                                                        <div className='row justify-content-end'>
                                                                            <InputValorNovo
                                                                                value={propsFormik.values.acomodacoes[index].portes[indexPorte].valor_porte_formatado || propsFormik.values.acomodacoes[index].portes[indexPorte].valor_porte}
                                                                                name={`acomodacoes[${index}].portes[${indexPorte}].valor_porte`} classeTooltip={'tooltip-input-modal'}
                                                                                classDiv='col-lg-6 m-0' propsFormik={propsFormik} isDisable={false} max={100000000} prefix={'R$ '} />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className='row justify-content-end'>
                                                                            <InputValorNovo
                                                                                value={propsFormik.values.acomodacoes[index].portes[indexPorte].urgencia_formatado || propsFormik.values.acomodacoes[index].portes[indexPorte].urgencia}
                                                                                name={`acomodacoes[${index}].portes[${indexPorte}].urgencia`} classeTooltip={'tooltip-input-modal'}
                                                                                classDiv='col-lg-6 m-0' propsFormik={propsFormik} isDisable={false} max={100000000} suffix={'%'} />
                                                                        </div>
                                                                    </td>
                                                                </>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ) || <></>
                            )}
                        </FieldArray>
                    </div>
                    <ModalDuasOpcoes showModal={showModal} setShowModal={setShowModal} funcaoOpcao2={_ => { setShowModal(false); Submit(propsFormik.values, propsFormik) }}
                        funcaoOpcao1={_ => { setSalvando(false); setShowModal(false) }}
                        esconderFechar mensagem={<>{msgValidacao}<br />{'Deseja Continuar?'} </>}
                        textoOpcao1={'Cancelar'}
                        classeOpcao1={'btn-sm btn-primary-modal simples'}
                        textoOpcao2={'Continuar'} />
                    {!props.modal && <div className='separator mt-40 mb-5'></div>}
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                        {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                    </div>
                </Form>)
            }}
        </Formik>
    )
}