import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputTipoRelatorio, InputProcedimento, InputSocio, InputEstabelecimentoSaude, InputOperadoraSaude, InputCirurgiaoCombo, InputTipoRelatorioPersonalizado } from '../../../components/InputsAutoComplete'
import { InputPadrao, InputDatePicker } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes';
import { MODULOS_SISTEMA } from '../../../util/Enums';

export default props => {
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Procedimento')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '' })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)

    const tipoRelatorio = [
        { label: 'Duração Procedimento', value: MODULOS_SISTEMA.RELATORIO_DURACAO_PROCEDIMENTO },
        { label: 'Duração Procedimento/Cirurgia', value: MODULOS_SISTEMA.RELATORIO_DURACAO_PROCEDIMENTO_CIRURGIA },

    ]

    const inputRef = useRef(null);

    const validacao = Yup.object({
        data_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) }),
        data_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) })
    })

    const relatoriosPorTipo = propsFormik => [
        {
            titulo: 'Relatorio Duração Procedimento (Agenda)',
            tipo: MODULOS_SISTEMA.RELATORIO_DURACAO_PROCEDIMENTO,
            url: '/relatorio/duracaoProcedimento',
            campos: [
                <InputProcedimento label='Procedimento' name="procedimento" propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} autoFocus multiplo />,
                <InputPadrao label="Duração Mínima" mascara="11:11" name="duracao_minima" type="text" placeholder="Duração Mínima" tamanho={"col-lg-12 "}
                    classeTooltip={'tooltip-input-modal'} />,
                <InputPadrao label="Duração Máxima" mascara="11:11" name="duracao_maxima" type="text" placeholder="Duração Máxima" tamanho={"col-lg-12 "}
                    classeTooltip={'tooltip-input-modal'} />,
            ],
            colunas: [
                { label: 'Tabela', name: 'descricao', visivel: true, ordenamento: { tipo: 'texto', ativo: true } },
                { label: 'Código Procedimento', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: true } },
                { label: 'Procedimento', name: 'procedimento', visivel: true, ordenamento: { tipo: 'texto', ativo: true } },
                { label: 'Duração', name: 'media_duracao', visivel: true, ordenamento: { tipo: 'texto', ativo: false, } },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Procedimento', name: 'procedimento', name_coluna: 'procedimento' },
                { label: 'Duração Mínima', name: 'duracao_minima', name_coluna: 'duracao_minima' },
                { label: 'Duração Máxima', name: 'duracao_maxima', name_coluna: 'duracao_maxima' },
            ]
        },
        {
            titulo: 'Relatório Duração Procedimento/Cirurgia (Agenda)',
            tipo: MODULOS_SISTEMA.RELATORIO_DURACAO_PROCEDIMENTO_CIRURGIA,
            url: '/relatorio/duracaoTodosProcedimentos',
            campos: [
                <InputDatePicker label="Data Inicial" name="data_inicial" type="text" autoFocus
                    setarValor={value => { propsFormik.setFieldValue("data_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final" name="data_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputSocio label='Sócio' name="socio_id" propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} autoFocus multiplo />,
                <InputEstabelecimentoSaude label='Estabelecimento Saúde' name="estabelecimento_saude_id" propsFormik={propsFormik} {...props} placeholder={'Estabelecimento Sáude'} classeTooltip={'tooltip-input-modal'} autoFocus multiplo />,
                <InputOperadoraSaude label='Operadora Saúde' name="operadora_saude_id" propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} autoFocus multiplo />,
                <InputProcedimento label='Procedimento' name="procedimento_id" propsFormik={propsFormik} {...props} obrigatorio classeTooltip={'tooltip-input-modal'} autoFocus multiplo />,
                <InputCirurgiaoCombo label="Cirurgião" name="cirurgiao_id" propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} autoFocus multiplo menuPlacement='top' />,
            ],
            colunas: [
                { label: 'Data Procedimento', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: true } },
                { label: 'Procedimento', name: 'procedimento', visivel: true, ordenamento: { tipo: 'texto', ativo: true } },
                { label: 'Sócio', name: 'socio', visivel: true, ordenamento: { tipo: 'texto', ativo: true } },
                { label: 'Tempo Procedimento', name: 'tempo_procedimento_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: true } },
                { label: 'Cirurgião', name: 'cirurgiao', visivel: true, ordenamento: { tipo: 'texto', ativo: false, } },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Data Inicial', name: 'data_inicial', name_coluna: 'data_inicial' },
                { label: 'Data Final', name: 'data_final', name_coluna: 'data_final' },
                { label: 'Sócio', name: 'socio_id', name_coluna: 'socio_id' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude_id' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude_id' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento_id' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao_id' },
            ]
        },
    ]

    const submit = (values, propsFormik) => {
        setCarregando(true)
        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)

        setShowModalFiltros(false)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]

        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio)
            setFiltros(Object.keys(values).map((x, idx, array) => {
                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: x === 'duracao_minima' || x === 'duracao_maxima' ? valorAtual.split(':')[0].replace(/_/g, '').toString().padStart(2, '0') + 'h ' +
                        valorAtual.split(':')[1].split(/_/g, '').toString().padStart(2, '0') + 'min' : typeof valorAtual === 'string' ? valorAtual : valorAtual.length ?
                            valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                })
            }
            ).filter(x => x))
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }

        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{ numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: filtros })
    }, [filtros])

    const changeTipo = (value) => {
        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({})
        // const index = tipoRelatorio.findIndex(x => x.value === value)
        setValuesFiltros({ tipo_relatorio: value })
        setRelatorioSelecionado(value?.value)
    }

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })}>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        <InputTipoRelatorioPersonalizado label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                            propsFormik={propsFormik} modulos={relatoriosPorTipo(propsFormik).map(x => x.tipo)} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} />
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}
                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}