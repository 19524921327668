import React, { useState } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'

const Consulta = props => {
    const [data, setData] = useState([])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-contrato',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Número',
                    accessor: 'numero',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                }, {
                    Header: 'Estabelecimento de Saúde',
                    accessor: 'estabelecimento_saude',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'estabelecimento_saude',
                        idColuna: 'estabelecimento_saude_id'
                    }
                }, {
                    Header: 'Descrição',
                    accessor: 'descricao',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'contrato_status',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'status_contrato_particular',
                        idColuna: 'contrato_status.id',
                    }
                }, {
                    Header: 'Vigência Inicial',
                    accessor: 'vigencia_inicial',
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'vigencia_inicial',
                        name: `data_inicial_${Math.floor(Math.random() * 1000)}`,
                        datePicker: true,
                        date: true
                    }
                }, {
                    Header: 'Vigência Final',
                    accessor: 'vigencia_final',
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'vigencia_final',
                        datePicker: true,
                        date: true
                    }
                },
                // {
                //     Header: 'Data Atualização Web Service',
                //     accessor: 'data_atualizacao',
                //     ordenamento: {
                //         tipo: 'data'
                //     },
                //     filtro: {
                //         tipo: 'data_inicial',
                //         idColuna: 'contrato.data_atualizacao',
                //         name: `data_inicial_${Math.floor(Math.random() * 1000)}`,
                //     }
                // }, {
                //     Header: 'Consulta?',
                //     accessor: 'consulta',
                //     visivel: false,
                //     ordenamento: { tipo: 'texto' },
                //     filtro: {
                //         tipo: 'input_sim_nao',
                //         idColuna: 'consulta',
                //         name: `input_sim_nao_${Math.floor(Math.random() * 1000)}`,
                //     }
                // },
                {
                    Header: 'Data de Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    }
                }, {
                    Header: 'Odontologico?',
                    accessor: 'odontologica',
                    visivel: false,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'input_sim_nao',
                        idColuna: 'odontologica'
                    }
                },

            ]
        }], []
    )

    return (
        <div>
            <DataGridAlternativa data={data} columns={columns} caminhoBase='/faturamento/contratoparticular' setData={setData} acoes={true} modulo={props.modulo}
                titulo={'Consulta Contrato Particular'} tituloLista={'Lista Contrato Particular'} urlCadastro='/faturamento/contratoparticular/cadastro' icone={'icon-Professor'} caminhoConsultaFiltros='/faturamento/contratoparticularpaginacao' />
        </div>
    )
}

export default Consulta