import React, { useState, useRef, useEffect } from 'react';
import { FiAlertTriangle } from "react-icons/fi";
import { Overlay, Tooltip } from 'react-bootstrap';
import { post } from '../util/AcessoApi'
import { useInterval, useWindowSize, useHover, removerAcentos } from '../util/Utilitarios'
import axios from 'axios'
import { mostrarToast } from '../components/Toasts'
import { useFormikContext, useField } from "formik";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import './../assets/css/upload-component.css'
import 'react-dropzone-uploader/dist/styles.css'
import Files from 'react-files'
import cloneDeep from 'lodash/cloneDeep';
import ReactTooltip from 'react-tooltip'

const BotaoExclusao = (props) => {
  const [contador, setContador] = useState(0)
  const [hoverRef, isHovered] = useHover();
  const { texto, icone } = props
  const [id, setId] = useState(Math.random().toString().replace(".", ""))

  useEffect(() => {
    if (!isHovered) {
      setContador(0)
    }
  }, [isHovered])
  return (
    <>
      <button id={"btn" + id} ref={hoverRef} data-tip data-for={'msg-exclusao' + id} type="button" onClick={
        () => {
          if ((contador + 1) == 2) {
            setContador(0);
            props.action();
          }
          else {
            setContador(contador + 1);
          }

        }} className={props.className}>
        <span class={(contador == 1 ? " icon-Yes icon-Yes-Style" : " " + icone)}></span>
      </button>
      <ReactTooltip id={'msg-exclusao' + id} place="bottom" type="dark" effect="solid">
        <span>{(contador == 1 ? " Tem certeza que deseja excluir?" : texto)}</span>
      </ReactTooltip>
    </>
  )
}



const InputArquivoS3 = React.forwardRef((props, ref) => {
  const [field, meta] = useField(props);
  const target = useRef(null);
  const targetInputText = useRef(null);
  const form = useFormikContext();
  let classeTooltip = props.classeTooltip || ''
  classeTooltip = classeTooltip ? classeTooltip.toString() : ""
  let placeholder = props.placeholder || props.label;
  const [estado, setEstado] = useState({})
  let classTamanho = props.tamanho ? props.tamanho : "col-12";
  const [arquivos, setArquivos] = useState([])
  const [primeiroLoad, setPrimeiroLoad] = useState(false)
  const [width, height] = useWindowSize();
  const [emProcessamento, setEmProcessamento] = useState(false)

  useEffect(() => {
    setPrimeiroLoad(false)
    setEstado({ success: false, url: "", uploading: true, mensagem: "teste", percentual: 15, snackClass: "oculto" })
    if (props.dataArquivos) {
      target.current.state.files = props.dataArquivos;
      setArquivos(props.dataArquivos)
    }
    else {
      setArquivos([])
    }
  }, [props.setDataArquivos])

  useEffect(() => {
    props.setDataArquivos(arquivos)
  }, [arquivos, props.setDataArquivos])

  const limparArquivos = () => {
    setArquivos([])
    form.setFieldValue(props.name, '')
    target.current.state.files = []
  }

  if (ref) {
    ref.current = {
      limparArquivos: () => limparArquivos()
    }
  }

  useEffect(() => {
    if (props.limparCampos) {
      limparArquivos();
    }
  }, [props.limparCampos])


  useInterval(() => {
    if (props.dataArquivos.length == 0 && arquivos.length == 0)
      return false;

    if (!primeiroLoad && props.dataArquivos.length > 0) {
      setPrimeiroLoad(true)
      target.current.state.files = props.dataArquivos;
    }
    upload()

    if(arquivos.filter(x => x.s3Key === undefined).length > 0) {
      props.setIsSubmit(true)
      setEmProcessamento(true);
    } else if(emProcessamento) {
      props.setIsSubmit(false)
      setEmProcessamento(false);
    }
    form.setFieldValue(props.name, arquivos.length > 0 ? "ok" : "")
  }, 500);






  const upload = (files) => {
    setEstado({ success: false, url: "", uploading: true, mensagem: "Iniciando upload!", percentual: 10, snackClass: "show" });
    let arquivosCopia = cloneDeep(arquivos)
    for (let index = 0; index < arquivosCopia.length; index++) {
      let file = arquivosCopia[index]
      file.diretorioS3 = props.diretorioS3

      if (file == undefined || file.percentualUpload != undefined)
        continue;

      file.percentualUpload = 0;
      setArquivos(arquivosCopia)

      let fileName = removerAcentos(file.name);
      let fileType = file.type;

      Promise.resolve(post('/sistema/aws-s3', {
        fileName: fileName,
        fileType: fileType,
        fileExtension: file.extension,
        diretorioS3: file.diretorioS3,
      })).then(val => {
        var returnData = val.data.returnData;
        var signedRequest = returnData.signedRequest;
        var url = returnData.url;
        setEstado({ success: false, url: url, uploading: true, mensagem: "Permissão com AWS S3 obtida.", percentual: 60, snackClass: "show" });
        file.percentualUpload = 0;
        setArquivos(arquivosCopia)

        // Put the fileType in the headers for the upload
        var options = {
          headers: {
            'Content-Type': fileType
          },
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            file.percentualUpload = percentCompleted;
            setArquivos(arquivosCopia)
          }
        };
        setEstado({ success: false, url: url, uploading: true, mensagem: "Iniciando upload para AWS.", percentual: 70, snackClass: "show" });
        file.percentualUpload = 0;
        setArquivos(arquivosCopia)

        axios.put(signedRequest, file, options)
          .then(result => {
            file.percentualUpload = 100;
            file.s3Key = returnData.fileKey;
            file.s3Bucket = returnData.bucket;
            file.modulo = props.modulo;
            file.nome_arquivo = file.name;
            file.tamanho_bytes = file.size;
            file.tipo = fileType;
            setArquivos(arquivosCopia)

            setEstado({ success: true, url: url, uploading: false, mensagem: "Upload realizado com sucesso!", percentual: 100, snackClass: "show" });
            setTimeout(function () {
              setEstado({ success: true, url: url, uploading: false, mensagem: "Upload realizado com sucesso!", percentual: 100, snackClass: "ocultando" })
            }, 2500)
            setTimeout(function () {
              setEstado({ success: true, url: url, uploading: false, mensagem: "Upload realizado com sucesso!", percentual: 100, snackClass: "oculto" })
            }, 5000)
          })
          .catch(error => {
            //mostrarToast('erro', JSON.stringify(error));
          })

        // mostrarToast('sucesso', mensagens['sucesso'])
      }).catch((e) => mostrarToast('erro', e))
    }

  }


  const download = (index) => {
    let file = arquivos[index]
    Promise.resolve(post('/sistema/aws-s3-obter-arquivo', {
      fileKey: file.s3Key
    })).then(val => {
      window.open(val.data.returnData, "_blank")
    }).catch((e) => mostrarToast('erro', e))
  }

  const obterIcones = () => [
    { icone: "icon-File-Excel", extensao: ["xls", "xlsx"] },
    { icone: "icon-File-ClipboardFileText", extensao: ["pdf"] },
    { icone: "icon-File-CSV", extensao: ["csv"] },
    { icone: "icon-File-HTML", extensao: ["html"] },
    { icone: "icon-File-JPG", extensao: ["jpg"] },
    { icone: "icon-File-Music", extensao: ["wav", "mp3", "ogg"] },
    { icone: "icon-File-TXT", extensao: ["txt"] },
    { icone: "icon-File-Word", extensao: ["doc", "docx"] },
    { icone: "icon-File-Zip", extensao: ["rar", "zip", "7z"] },
    { icone: "icon-File-Pictures", extensao: ["png", "btmp", "gif"] },
  ]

  const removerArquivo = (index) => {
    let file = cloneDeep(arquivos)[index]
    let arquivosCopia = cloneDeep(arquivos)
    delete arquivosCopia[index]
    arquivosCopia = arquivosCopia.filter(x => x !== undefined)
    target.current.state.files = arquivosCopia;
    setArquivos(arquivosCopia)
    if (file.arquivoId)
      return;

    Promise.resolve(post('/sistema/aws-s3-excluir-arquivo', {
      fileKey: file.s3Key,
      arquivoId: file.arquivoId
    })).catch((e) => {
      mostrarToast('erro', 'Ocorreu um erro ao tentar excluir')
      arquivosCopia.push(file)
      target.current.state.files = arquivosCopia;
      setArquivos(arquivosCopia)
    })
  }

  const obterArquivos = () => {
    let li = [];
    let icones = obterIcones()

    for (let index = 0; index < arquivos.length; index++) {
      let file = arquivos[index]
      let res = icones.find(x => x.extensao.find(z => z === file.extension))
      li.push(
        <li className="li-arquivos">
          <div className="row sem-margin row-arquivos align-items-center">
            <div className={width < 1400 ? " center text-center col-lg-12 text-mobile" : "col-lg-9 " + " col-md-8 col-sm-12 sem-margin sem-padding"}>
              <span className={(res ? res.icone : "icon-File-Upload") + " icone-list"}></span>
              <span className="label-nome-arquivo">{arquivos[index].name}</span>
            </div>
            <div className={width < 1400 ? "col-lg-12" : "col-lg-3 " + "col-md-4 col-sm-12 sem-margin sem-padding direita pull-right"}>
              <div class={width < 1400 ? "center text-center" : "float-right"}>
                <CircularProgressbar
                  value={file.percentualUpload ? file.percentualUpload : 0}
                  maxValue={100}
                  text={file.percentualUpload ? `${file.percentualUpload}%` : '0%'}
                  background={file.percentualUpload == 100 ? true : false}
                  styles={buildStyles({
                    backgroundColor: "transparent",
                    pathColor: file.percentualUpload == 100 ? "4BB543" : "020029",
                    textColor: "black",
                  })}
                  className={(width < 1400 ? "widget-mobile " : "") + "progresso-menor" + (file.percentualUpload === 100 ? " progresso-menor-sucesso" : "")}
                />
                <button type="button" onClick={() => download(index)} className={(width < 1400 ? "widget-mobile " : "") + " btn-download-arquivo" + (arquivos[index].s3Key ? "" : " oculto")}><span class="icon-Download-fromCloud"></span></button>
                <BotaoExclusao texto={"Excluir"} icone={"icon-New-Trash"} type="button" action={() => removerArquivo(index)} className={(width < 1400 ? "widget-mobile" : "") + " btn-excluir-arquivo"} />
              </div>
            </div>
          </div>
        </li >)
    }
    return (<ul className="ul-arquivos">
      {li}
    </ul>)
  }
  return (<>
    <div className={"form-group " + classTamanho}>
      {props.label ? <label htmlFor={props.id || props.name}>{props.label}{props.obrigatorio != undefined ? "*" : ""}</label> : <></>}
      {
        <>
          <div className="files" >
            <Files
              name={props.name + Math.random().toString()}
              className='files-dropzone custom-scrollbar'
              onChange={(files) => { setArquivos(files); }}
              onError={(err) => {
                mostrarToast('erro', err.message.replace("is too large", "é muito grande.").replace("maximum file count reached", "Quantidade máxima de arquivos atingida.").replace("is not a valid file type", "não é um arquivo com tipo válido."))
              }}
              multiple
              maxFiles={props.qtdMaximaArquivos}
              accepts={props.tiposAceitos}
              maxFileSize={(props.tamanhoMaximoEmMB * 1024 * 1024)}
              minFileSize={props.tamanhoMinimo}
              clickable={false}
              ref={target}
            >
              <div className="titulos" onClick={() => {
                target.current.openFileChooser();

              }}><span className="icon-Upload-toCloud icone-upload"></span> <span className="titulo-arquivos">Arraste ou Clique aqui</span></div>
              {
                obterArquivos()
              }
            </Files>
            <input type="hidden" name={props.name} {...field}  ></input>
            <div ref={targetInputText}></div>
          </div>
          {meta.error ? (
            <>
              <Overlay target={targetInputText.current} show={true} placement="bottom">
                {props => {
                  return (
                    <Tooltip id={Math.random()} className={"error-tooltip " + classeTooltip} {...props}>
                      <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                    </Tooltip>)
                }}
              </Overlay>
            </>
          ) : null}
        </>
      }
    </div></>);
})

export default InputArquivoS3