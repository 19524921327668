import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../../../components/VisualizacaoPadrao'
import { visualizar } from '../../../../../util/AcessoApi'
import { obterDataFormatada } from '../../../../../util/Utilitarios'
import { ordenarArrayObj } from '../../../../../util/FuncoesComuns'


const VisualizacaoUsuario = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})

    useEffect(() => {
        if (id) {
            visualizar(`/administracao/ferias/periodo-gozo`, id, obj => {
                setDados(obj)
            })
        }
    }, [id])

    const data = dados ? {
        id,
        modulo: props.modulo,
        titulo: 'Visualização de Ano (Gozo)/Parâmetros',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Ano',
                        descricao: dados.ano,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Período de Gozo Inicial',
                        descricao: dados.periodo_inicial,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Período de Gozo Final',
                        descricao: dados.periodo_final,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Limite para Gozo',
                        descricao: dados.data_limite_gozo,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Antecedência Mínima de Solicitação de Férias',
                        descricao: dados.antecedencia_minima_dias,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Observação',
                        descricao: dados.observacao,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data Registro',
                        descricao: dados.data_registro,
                        tamanho: 'col-lg-6'
                    }
                ]
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Parâmetro de gozo (dias) por tempo',
                thead:
                    <tr>
                        <td className='coluna-4'>Início</td>
                        <td className='coluna-4'>Fim</td>
                        <td className='coluna-4'>Dias</td>
                    </tr>,
                tbody: dados.regras == undefined || dados.regras.length < 1 ? (<><tr>
                    <td colspan="4">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.regras, 'id').map(permissao =>
                        <tr>
                            <td>{permissao.inicio + ' ' + permissao.type_inicio.label}</td>
                            <td>{permissao.termino + ' ' + permissao.type_fim.label}</td>
                            <td>{permissao.dias_direito}</td>
                        </tr>)
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Quantidade Maxima Sócio por Mês',
                thead:
                    <tr>
                        <td className='coluna-1'>Referência</td>
                        <td className='coluna-1 text-center'>Quantidade Máxima</td>
                    </tr>,
                tbody: dados.qtd_maxima_periodo_socio == undefined || dados.qtd_maxima_periodo_socio.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    dados.qtd_maxima_periodo_socio.filter(x => x.id).map(x =>
                        <tr>
                            <td>{x.periodo_inicial + ' - ' + x.periodo_final}</td>
                            <td className='text-center'>{x.quantidade_maxima}</td>
                        </tr>)
            },
        ]
    } : { titulo: 'Visualização de Ano (Gozo)/Parâmetros' }

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoUsuario