import { InputCpfCnpj, InputCurrency, InputDatePicker, InputDecimal, InputPadrao, InputSwitch, InputValor, InputValorNovo, TextArea } from '../../../../components/Inputs'
import React, { useState, useEffect, useRef, useMemo, useDebugValue, useCallback } from 'react'
import { Formik, Form, FieldArray } from "formik"
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { get, salvar } from '../../../../util/AcessoApi'
import { Link, useParams } from "react-router-dom"
import { buscarCep, convertValueToFloat, formatarMoeda, mensagens } from '../../../../util/Utilitarios'
import Yup from '../../../../util/Validacoes'
import '../../../../assets/css/ios-switch/clean-switch.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Atalho from '../../../../util/Atalho'
import { HeaderCadastro } from '../../../../components/Headers'
import { InputBanco, InputClienteFinanceiro, InputEmpresa, InputEmpresaCliente, InputLancamentoFinanceiro, InputLancamentoFinanceiroPorCliente, InputLancamentoSemMovimento, InputMunicipio, InputRecolhimentoTributo, InputSituacaoTributo, InputTributo } from '../../../../components/InputsAutoComplete'
import moment from 'moment'
import { Overlay, Tooltip } from 'react-bootstrap'
import { FiAlertTriangle } from 'react-icons/fi'
import { BtnAcao } from '../../../../components/Acoes'
import { InputClienteFinanceiroV2, InputClienteFinanceiroV3, InputLocalIncidencia, InputTodosLancamentoFinanceiroPorCliente } from '../../../../components/InputsAutoCompleteV2'
import { getUsuario } from '../../../../util/Configuracoes'

const CadastroNotaFiscal = (props) => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [devolvido, setDevolvido] = useState(false)
    const inputValor = useRef();
    const inputValorLancamento = useRef([]);
    const inputValorDesconto = useRef();
    const inputBaseCalculo = useRef();
    const inputValorDeducao = useRef();
    const inputValorNota = useRef();
    const inputValorTributo = useRef([]);
    const refTributos = useRef(null)
    const refLancamentos = useRef(null)


    const validacao = Yup.lazy(({ lancamentos, tributos }) => Yup.object({
        numero: Yup.string().nullable(),
        codigo_verificacao: Yup.string().nullable(),
        cliente: Yup.object().required().nullable(),
        empresa: Yup.object().required().nullable(),
        municipio: Yup.object().required().nullable(),
        informacoes_adicionais: Yup.string().nullable(),
        discriminacao_servicos: Yup.string().required().nullable()
    }).shape({
        lancamentos: Yup.array()
            .test('Lançamentos', 'Insira ao menos 1 Lançamentos', function (value) {
                return this.parent?.lancamentos && this.parent?.lancamentos[0]?.lancamento !== null
            })
            .of(
                Yup.object().shape({
                    lancamento: Yup.object().nullable().test('Lançamento', 'Campo Obrigatório!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '');
                        return !(lancamentos.length - 1 != index && !value)
                    }),
                    valor_lancamento: Yup.number().nullable().test('Valor Acréscimo', 'Campo Obrigatório!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '');
                        return !(lancamentos.length - 1 != index && !value)
                    })
                })
            ),
        tributos: Yup.array()
            .test('Tributos', 'Insira ao menos 1 Tributo', function (value) {
                return this.parent.tributos && this.parent.tributos[0].situacao !== null
            })
            .of(
                Yup.object().shape({
                    recolhimento: Yup.object().nullable().test('Recolhimento', 'Campo Obrigatório!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '');
                        return !(tributos.length - 1 != index && !value)
                    }),
                    situacao: Yup.object().nullable().test('Situação', 'Campo Obrigatório!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '');
                        return !(tributos.length - 1 != index && !value)
                    }),
                    tributo: Yup.object().nullable().test('Tributo', 'Campo Obrigatório!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '');
                        return !(tributos.length - 1 != index && !value)
                    }).test('Tributo Repetido', 'Tributo já cadastrado!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '');
                        let tributoArray = tributos.length > 0 ? tributos?.filter(x => !x?.alteracao).map(x => x.tributo).filter(x => x) : [];
                        let idTributo = tributoArray.map(x => x.value)
                        let possui_duplicidade = new Set(idTributo).size !== idTributo.length;
                        return !possui_duplicidade;
                    })
                })
            ),
    }))

    const [valorTotalLancamentos, setValorTotalLancamentos] = useState(0)

    const Submit = (values, propsFormik) => {

        setSalvando(true)
        values.usuario_id = getUsuario().id
        salvar(
            values,
            '/financeiro/notafiscal',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    // manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                }
                else
                    props.history.push('/financeiro/notafiscal/consulta')

                setSalvando(false)
            }, (err) => {
                mostrarToast('erro', mensagens.erro)
                setSalvando(false)
            })
    }


    useEffect(_ => {
        var objListas = {
            lancamentos: [{ lancamento: null, valor_lancamento: 0 }],
            tributos: [{ situacao: { label: 'Tributável', value: 1 }, recolhimento: { label: 'Retido na Fonte', value: 1 }, tributo: null, valor_tributo: 0, local_incidencia: { value: 2, label: 'Tomador' } }]
            ,
        }
        if (!props.modal && id) {

            Promise.resolve(get(`/financeiro/notafiscal/${id}`)).then(val => {
                val.lancamentos = [...val.lancamentos.map(x => ({ alteracao: true, ...x })), ...objListas.lancamentos];
                val.tributos = [...val.tributos.map(x => ({ alteracao: true, ...x })), ...objListas.tributos];

                setValoresIniciais(val)
                setValorTotalLancamentos(convertValueToFloat(val.valor_base_calculo))

            })
        } else {
            setValoresIniciais({
                banco: '',
                agencia: '',
                agencia_digito: '',
                conta: '',
                conta_digito: '',
                numero: '',
                valor: '',
                nominal: '',
                data_emissao: '',
                data_predatado: '',
                data_compensado: '',
                data_devolucao: '',
                devolvido: false,
                municipio: { label: 'GOIANIA - GO', value: 5208707 },
                lancamentos: [{ lancamento: null, valor_lancamento: 0 }],
                tributos: [{ situacao: { label: 'Tributável', value: 1 }, recolhimento: { label: 'Retido na Fonte', value: 1 }, tributo: null, valor_tributo: 0, local_incidencia: { value: 2, label: 'Tomador' } }]

            })
        }
    }, [id])

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Financeiro</span>,
        () => <Link className='link-active' to={'/financeiro/notafiscal/consulta'}>Nota Fiscal</Link>,
    ]
    const adicionarLinhaLancamento = (arrayHelpers, propsFormik, index) => {

        if (propsFormik.values.lancamentos.length - 1 === index)
            arrayHelpers.insert(propsFormik.values.lancamentos.length, { lancamento: undefined, valor_lancamento: undefined })
    };

    const adicionarLinhaTributo = (arrayHelpers, propsFormik, index) => {
        if (propsFormik.values.tributos.length > 0) {
            // propsFormik.values.tributos.push({ situacao: propsFormik.values.tributos[0]?.situacao, recolhimento: propsFormik.values.tributos[0]?.recolhimento, local_incidencia: propsFormik.values.tributos[0]?.local_incidencia, tributo: undefined, valor: 0 })
            arrayHelpers.insert(propsFormik.values.tributos.length, { situacao: propsFormik.values.tributos[0]?.situacao, recolhimento: propsFormik.values.tributos[0]?.recolhimento, local_incidencia: propsFormik.values.tributos[0]?.local_incidencia, tributo: undefined, valor: 0 })

        }
        else if (propsFormik.values.tributos.length - 1 === index)
            arrayHelpers.insert(propsFormik.values.tributos.length, { situacao: undefined, recolhimento: undefined, local_incidencia: { value: 2, label: 'Tomador' }, tributo: undefined, valor: 0 })

    }

    const removerLinhaLancamento = (arrayHelpers, propsFormik, index) => {

        const arrayLancamento = propsFormik.values.lancamentos;
        if (arrayLancamento.length === 1) {
            propsFormik.setFieldValue('lancamentos', [{ lancamento: undefined, valor_lancamento: undefined }])
            propsFormik.values.lancamentos = [{ lancamento: undefined, valor_lancamento: undefined }]
        } else {
            if (index + 1 !== arrayLancamento.length) {

                let copiaObj = [...propsFormik.values.lancamentos]
                copiaObj.splice(index, 1)
                propsFormik.values.lancamentos = copiaObj
                // arrayHelpers.remove(index)
                inputValorLancamento.current.splice(index, 1)
            }

            if (arrayLancamento.length === 1 && arrayLancamento[index].lancamento && arrayLancamento[index].lancamento.value) {
                let copiaObj = [...propsFormik.values.lancamentos]
                copiaObj.splice(index, 1)
                copiaObj = [{ lancamento: undefined, valor_lancamento: undefined }]
                propsFormik.values.lancamentos = copiaObj
                arrayHelpers.remove(index)
                arrayHelpers.insert(propsFormik.values.lancamentos.length, { lancamento: undefined, valor_lancamento: undefined })
            }
        }
        somarValorLancamentos(propsFormik)

    }

    const removerLinhaTributo = (arrayHelpers, propsFormik, index) => {

        const arrayTributo = propsFormik.values.tributos;
        if (arrayTributo.length === 1) {
            propsFormik.setFieldValue('tributos', [{
                situacao: undefined, recolhimento: undefined, tributo: undefined, valor_tributo: 0, valor_tributo_formatado
                    : 'R$ 0,00'
            }])
        } else {

            if (index + 1 !== arrayTributo.length) {
                let copiaObj = [...propsFormik.values.tributos]
                copiaObj.splice(index, 1)
                propsFormik.values.tributos = copiaObj
                // arrayHelpers.remove(index)
            }

            if (arrayTributo.length === 1 && arrayTributo[index].tributo && arrayTributo[index].tributo.value) {
                let copiaObj = [...propsFormik.values.tributos]
                copiaObj.splice(index, 1)
                copiaObj = [{
                    situacao: undefined, recolhimento: undefined, tributo: undefined, valor_tributo: 0, valor_tributo_formatado
                        : 'R$ 0,00'
                }]
                propsFormik.values.tributos = copiaObj
                arrayHelpers.insert(propsFormik.values.tributos.length, {
                    situacao: undefined, recolhimento: undefined, tributo: undefined, valor_tributo: 0, valor_tributo_formatado
                        : 'R$ 0,00'
                })
            }
        }
    }

    const somarValorLancamentos = (propsFormik) => {
        let desconto = 0

        let valorLancamentos = propsFormik.values.lancamentos.filter(x => x.valor_lancamento).map(x => convertValueToFloat(x.valor_lancamento))
        let valorTotal = valorLancamentos.reduce((acc, valor) => acc + valor, 0)
        desconto = convertValueToFloat(propsFormik.values.valor_desconto || 0)
        valorTotal = valorTotal - desconto
        propsFormik.setFieldValue('valor_servico', valorTotal)
        propsFormik.setFieldValue('valor_servico_formatado', formatarMoeda(valorTotal))
        propsFormik.setFieldValue('valor_base_calculo', valorTotal)
        propsFormik.setFieldValue('valor_base_calculo_formatado', formatarMoeda(valorTotal))

        //GERAR O VALOR DA NOTA INSTANTANEAMENTE
        propsFormik.values.valor_base_calculo = convertValueToFloat(valorTotal)
        propsFormik.values.valor_base_calculo_formatado = formatarMoeda(valorTotal)

        inputBaseCalculo.current = valorTotal
        // calculaTodosTributos(propsFormik)
        setValorTotalLancamentos(valorTotal)
        calcularValorTributo(propsFormik)
    }

    const calcularValorTributo = (propsFormik, idx) => {
        let aliquota, valorTributo = 0

        if (idx) {

            aliquota = convertValueToFloat(propsFormik.values.tributos[idx]?.tributo?.aliquota || 0) / 100
            valorTributo = (valorTotalLancamentos * aliquota) || 0
            propsFormik.setFieldValue(`tributos[${idx}].valor_tributo`, valorTributo)
            propsFormik.setFieldValue(`tributos[${idx}].valor_tributo_formatado`, formatarMoeda(valorTributo))
            propsFormik.values.tributos[idx].valor_tributo = valorTributo
            inputValorTributo.current[idx] = valorTributo
        } else {
            propsFormik.values.tributos.filter(x => x.tributo).map((x, idx) => {
                valorTributo = (convertValueToFloat(propsFormik.values.valor_base_calculo) * convertValueToFloat(x?.tributo?.aliquota || 0) / 100) || 0
                propsFormik.setFieldValue(`tributos[${idx}].valor_tributo`, valorTributo)
                propsFormik.setFieldValue(`tributos[${idx}].valor_tributo_formatado`, formatarMoeda(valorTributo))
                propsFormik.values.tributos[idx].valor_tributo = valorTributo
                inputValorTributo.current[idx] = valorTributo

            })

        }
        calculaTodosTributos(propsFormik)
    }
    const calculaTodosTributos = (propsFormik) => {

        let valoresTributos = propsFormik.values.tributos.filter(x => x?.tributo).map(x => convertValueToFloat(x.valor_tributo))
        let valorTodosTributos = valoresTributos.reduce((acc, valor) => acc + valor, 0)
        propsFormik.setFieldValue('valor_deducao', convertValueToFloat(valorTodosTributos))
        propsFormik.setFieldValue('valor_deducao_formatado', formatarMoeda(convertValueToFloat(valorTodosTributos)))

        propsFormik.values.valor_deducao = valorTodosTributos
        propsFormik.values.valor_deducao_formatado = formatarMoeda(valorTodosTributos)

        propsFormik.values.valor_deducao = valorTodosTributos
        let valorNota = convertValueToFloat(propsFormik.values.valor_base_calculo) - convertValueToFloat(valorTodosTributos)
        propsFormik.setFieldValue('valor_nota', valorNota)
        propsFormik.setFieldValue('valor_nota_formatado', formatarMoeda(convertValueToFloat(valorNota)))
    }

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>
                {!props.modal && <HeaderCadastro titulo={"Cadastro Nota Fiscal"} link={links} />}
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={'card-body'}>
                        <div className='row'>
                            <InputPadrao tamanho={!props.modal && 'col-sm-12 col-lg-3 col-xl-3'} classeTooltip={props.modal && 'tooltip-input-modal'}
                                label="Número NFS-e" name="numero" type="text" placeholder="Número NFS-e" autoFocus salvarENovo={salvarENovo} maxLength={20}
                            />

                            <InputPadrao label='Código Verificação' name='codigo_verificacao' placeholder="Código Verificação" propsFormik={propsFormik} maxLength={20}
                                classeTooltip={props.modal && 'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2'} />

                            <InputDatePicker label='Data Emissão' placeholder='Data Emissão' name='data_emissao' propsFormik={propsFormik} tamanho={!props.modal && 'col-lg-2'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_emissao", value)
                                }} />

                            <InputClienteFinanceiroV3 label='Tomador/Cliente' name="cliente" placeholder="Tomador/Cliente" propsFormik={propsFormik} obrigatorio={true}
                                onChange={(value) => {
                                    propsFormik.setFieldValue('cliente', value);
                                    propsFormik.setFieldValue('lancamentos', [{ lancamento: undefined, valor_lancamento: 0, valor_lancamento_formatado: 'R$ 0,00' }])
                                }} idTela={id} rotaTela='/financeiro/alterarTomadorNotaFiscal'
                                botaoVisivel={true} changeIcon={'icon-Pencil'}
                                tamanho={!props.modal && 'col-lg-5'} classeTooltip={props.modal && 'tooltip-input-modal'} additionalValues={'identificacao'} />

                            <InputEmpresaCliente label='Prestador/Empresa' name="empresa" placeholder="Prestador/Empresa" propsFormik={propsFormik} obrigatorio={true}
                                tamanho={!props.modal && 'col-lg-5'} classeTooltip={props.modal && 'tooltip-input-modal'} />

                        </div>
                    </div>
                </div>
                <div className={'mt-40'}>
                    <HeaderCadastro
                        titulo={'Lançamento(s)'}
                        classeChildren='space-between-100'
                    />
                </div>
                <div className="card mt-2">
                    <div className="card-body socio-float custom-scrollbar" ref={refLancamentos}>
                        <FieldArray name="lancamentos">
                            {(arrayHelpers => (
                                <>
                                    {propsFormik.values.lancamentos && propsFormik.values.lancamentos.length > 0 ?
                                        propsFormik.values.lancamentos.map((item, index) => <div className='modal-body-cadastro row mr-0 ml-0'>
                                            <div className={"form-group col-1 pl-0 text-center"}>
                                                <label htmlFor={props.id || props.name}>{index === 0 ? '#' : undefined}</label>
                                                <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{index + 1}</span></div>
                                            </div>
                                            <InputTodosLancamentoFinanceiroPorCliente valor_setado={propsFormik.values.lancamentos.map(x => x.lancamento?.value)} label={index === 0 ? 'Lançamento' : undefined} placeholder={!propsFormik.values.cliente ? 'Adicione o Tomador/Cliente' : 'Lançamento'} name={`lancamentos[${index}].lancamento`} propsFormik={propsFormik}
                                                disabled={!propsFormik.values.cliente} tamanho={'col-sm-12 col-xl-6 col-lg-6 col-md-5 pl-0'} {...props} obrigatorio
                                                classeTooltip={'tooltip-input-modal'} onChange={(value) => {
                                                    propsFormik.setFieldValue(`lancamentos[${index}].lancamento`, value);
                                                    propsFormik.setFieldValue(`lancamentos[${index}].valor_lancamento`, convertValueToFloat(value?.valor_lancamento));
                                                    propsFormik.setFieldValue(`lancamentos[${index}].valor_lancamento_formatado`, value?.valor_lancamento);
                                                    propsFormik.setFieldValue('empresa', value?.empresa)
                                                    propsFormik.values.lancamentos[index].valor_lancamento = convertValueToFloat(value?.valor_lancamento)
                                                    propsFormik.values.lancamentos[index].valor_lancamento_formatado = formatarMoeda(value?.valor_lancamento)
                                                    inputValorLancamento.current[index] = convertValueToFloat(value?.valor_lancamento)
                                                    somarValorLancamentos(propsFormik)
                                                    adicionarLinhaLancamento(arrayHelpers, propsFormik, index)
                                                }} />

                                            <InputCurrency
                                                onUpdate={val => {
                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                    inputValorLancamento.current[index] = valor
                                                }}
                                                label={index === 0 ? 'Valor R$' : undefined}
                                                name={`lancamentos[${index}].valor_lancamento`}
                                                classeTooltip={'tooltip-input-modal'}
                                                propsFormik={propsFormik}
                                                max={100000000}
                                                value={inputValorLancamento.current[index] || propsFormik.values.lancamentos[index].valor_lancamento_formatado || propsFormik.values.lancamentos[index].valor_lancamento}
                                                onBlur={(e) => {
                                                    if (inputValorLancamento.current[index] || inputValorLancamento.current[index] === 0) {
                                                        propsFormik.values.lancamentos[index].valor_lancamento = inputValorLancamento.current[index] && inputValorLancamento.current[index].toString().trim();
                                                        propsFormik.values.lancamentos[index].valor_lancamento_formatado = formatarMoeda(inputValorLancamento.current[index] && inputValorLancamento.current[index].toString());
                                                        somarValorLancamentos(propsFormik, true)

                                                    }
                                                    inputValorLancamento.current[index] = null;
                                                }

                                                } classDiv='col-4' />

                                            <div className='col-4 col-xl-1 col-lg-2 col-md-2 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right'>
                                                <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => { removerLinhaLancamento(arrayHelpers, propsFormik, index) }} />
                                            </div>
                                        </div>)
                                        :
                                        <></>}
                                </>
                            ))}
                        </FieldArray>
                        {propsFormik.touched.lancamentos && propsFormik.errors.lancamentos && !Array.isArray(propsFormik.errors.lancamentos) ?
                            <Overlay target={refLancamentos.current} show={true} placement="bottom">
                                {props2 => {
                                    return (
                                        <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                            <div className="error">
                                                <FiAlertTriangle className="icone" /> <span>{propsFormik.errors.lancamentos}</span></div>
                                        </Tooltip>)
                                }}
                            </Overlay> : <></>}
                    </div>
                </div>
                <div className={'mt-40'}>
                    <HeaderCadastro titulo={"Dados Serviço"} />
                </div>
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={'card-body'}>
                        <div className='row'>
                            <InputMunicipio tamanho={!props.modal && 'col-lg-3'} label='Município - UF' name='municipio' placeholder='Município - UF' obrigatorio={true}
                                propsFormik={propsFormik} classeTooltip={props.modal && 'tooltip-input-modal'} />

                            <TextArea label={<span>Discriminação Serviços</span>} obrigatorio={true} propsFormik={propsFormik} name='discriminacao_servicos' type='text' placeholder='Discriminação Serviços' tamanho={!props.modal && 'col-lg-5 p-0'} rows={3}
                                classeTooltip={'tooltip-input-modal'} />
                            <TextArea label={<span>Informações Adicionais</span>} name='informacoes_adicionais' type='text' placeholder='Informações Adicionais' tamanho={'col-lg-4 '} classeTooltip={'tooltip-input-modal'} />

                            <InputCurrency
                                onUpdate={val => {
                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                    inputValor.current = valor
                                }}
                                label='Valor R$'
                                name={`valor_servico`}
                                classeTooltip={'tooltip-input-modal'}
                                disabled={true}
                                propsFormik={propsFormik}
                                max={100000000}
                                value={inputValor.current || propsFormik.values.valor_servico_formatado || propsFormik.values.valor_servico}
                                onBlur={(e) => {
                                    if (inputValor.current || inputValor.current === 0) {
                                        propsFormik.values.valor_servico = inputValor.current && inputValor.current.toString().trim();
                                        propsFormik.values.valor_servico_formatado = formatarMoeda(inputValor.current && inputValor.current.toString());
                                    }
                                    inputValor.current = null;
                                }

                                } classDiv='col-4' />
                            <InputCurrency

                                onUpdate={val => {
                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                    inputValorDesconto.current = valor
                                }}
                                label='Valor Desconto R$'
                                name={`valor_desconto`}
                                classeTooltip={'tooltip-input-modal'}
                                propsFormik={propsFormik}
                                max={100000000}
                                value={inputValorDesconto.current || propsFormik.values.valor_desconto_formatado || propsFormik.values.valor_desconto}
                                onBlur={(e) => {
                                    if (inputValorDesconto.current || inputValorDesconto.current === 0) {
                                        propsFormik.values.valor_desconto = inputValorDesconto.current && inputValorDesconto.current.toString().trim();
                                        propsFormik.values.valor_desconto_formatado = formatarMoeda(inputValorDesconto.current && inputValorDesconto.current.toString());
                                        somarValorLancamentos(propsFormik)
                                        //onChangeValorLiquidacaoNovo(propsFormik.values.liquidacao, index, propsFormik)
                                    }
                                    inputValorDesconto.current = null;
                                }

                                } classDiv='col-4 ' />
                            <InputCurrency
                                onUpdate={val => {
                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                    inputBaseCalculo.current = valor
                                }}
                                label={`Base Cálculo R$`}
                                negrito={true}
                                name={`valor_base_calculo`}
                                classeTooltip={'tooltip-input-modal'}
                                propsFormik={propsFormik}
                                disabled={true}
                                max={100000000}
                                value={inputBaseCalculo.current || propsFormik.values.valor_base_calculo_formatado || propsFormik.values.valor_base_calculo}
                                onBlur={(e) => {
                                    if (inputBaseCalculo.current || inputBaseCalculo.current === 0) {
                                        propsFormik.values.valor_base_calculo = inputBaseCalculo.current && inputBaseCalculo.current.toString().trim();
                                        propsFormik.values.valor_base_calculo_formatado = formatarMoeda(inputBaseCalculo.current && inputBaseCalculo.current.toString());

                                    }
                                    inputBaseCalculo.current = null;
                                }

                                } classDiv='col-4 ' />
                            <InputCurrency
                                onUpdate={val => {
                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                    inputValorDeducao.current = valor
                                }}
                                label='Valor Dedução R$'
                                name={`valor_deducao`}
                                classeTooltip={'tooltip-input-modal'}
                                propsFormik={propsFormik}
                                disabled={true}
                                max={100000000}
                                value={inputValorDeducao.current || propsFormik.values.valor_deducao || propsFormik.values.valor_deducao_formatado}
                                onBlur={(e) => {
                                    if (inputValorDeducao.current || inputValorDeducao.current === 0) {
                                        propsFormik.values.valor_deducao = inputValorDeducao.current && inputValorDeducao.current.toString().trim();
                                        propsFormik.values.valor_deducao_formatado = formatarMoeda(inputValorDeducao.current && inputValorDeducao.current.toString());

                                        //onChangeValorLiquidacaoNovo(propsFormik.values.liquidacao, index, propsFormik)
                                    }
                                    inputValorDeducao.current = null;
                                }

                                } classDiv='col-4 ' />
                            <InputCurrency
                                onUpdate={val => {
                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                    inputValorNota.current = valor
                                }}
                                label='Valor Nota R$'
                                negrito={true}
                                name={`valor_nota`}
                                classeTooltip={'tooltip-input-modal'}
                                propsFormik={propsFormik}
                                disabled={true}
                                max={100000000}
                                value={inputValorNota.current || propsFormik.values.valor_nota || propsFormik.values.valor_nota_formatado}
                                onBlur={(e) => {
                                    if (inputValorNota.current || inputValorNota.current === 0) {
                                        propsFormik.values.valor_nota = inputValorNota.current && inputValorNota.current.toString().trim();
                                        propsFormik.values.valor_nota_formatado = formatarMoeda(inputValorNota.current && inputValorNota.current.toString());

                                        //onChangeValorLiquidacaoNovo(propsFormik.values.liquidacao, index, propsFormik)
                                    }
                                    inputValorNota.current = null;
                                }

                                } classDiv='col-4' />

                        </div>
                    </div>
                </div>
                <div className={'mt-40'}>
                    <HeaderCadastro
                        titulo={'Tributo(s)'}
                        classeChildren='space-between-100'
                    />
                </div>
                <div className="card mt-2">
                    <div className="card-body socio-float custom-scrollbar" ref={refTributos}>
                        <FieldArray name="tributos">
                            {(arrayHelpers => (
                                <>
                                    {propsFormik.values.tributos && propsFormik.values.tributos.length > 0 ?
                                        propsFormik.values.tributos.map((item, index) => <div className='modal-body-cadastro row mr-0 ml-0'>
                                            <div className={"form-group col-1 pl-0 text-center"}>
                                                <label htmlFor={props.id || props.name}>{index === 0 ? '#' : undefined}</label>
                                                <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{index + 1}</span></div>
                                            </div>

                                            <InputSituacaoTributo label={index === 0 ? 'Situação' : undefined} placeholder='Situação' name={`tributos[${index}].situacao`} propsFormik={propsFormik}
                                                tamanho={'col-sm-12 col-xl-2 col-lg-6 col-md-5 pl-0'} {...props} obrigatorio={propsFormik.values.tributos[index]?.tributo?.value ? true : false || propsFormik.values.tributos?.length > 1}
                                                classeTooltip={'tooltip-input-modal'} />
                                            <InputRecolhimentoTributo label={index === 0 ? 'Recolhimento' : undefined} placeholder='Recolhimento' name={`tributos[${index}].recolhimento`} propsFormik={propsFormik}
                                                tamanho={'col-sm-12 col-xl-2 col-lg-6 col-md-5 pl-0'} {...props} obrigatorio={propsFormik.values.tributos[index]?.tributo?.value ? true : false || propsFormik.values.tributos?.length > 1}
                                                classeTooltip={'tooltip-input-modal'} />
                                            <InputTributo label={index === 0 ? 'Tributo' : undefined} municipio={propsFormik.values.municipio} placeholder='Tributo' name={`tributos[${index}].tributo`} propsFormik={propsFormik}
                                                tamanho={'col-sm-12 col-xl-2 col-lg-6 col-md-5 pl-0'} {...props} obrigatorio={propsFormik.values.tributos[index]?.tributo?.value ? true : false || propsFormik.values.tributos?.length > 1}
                                                onChange={(value) => { propsFormik.values.tributos[index].tributo = value; adicionarLinhaTributo(arrayHelpers, propsFormik, index); calcularValorTributo(propsFormik, index, inputValorTributo) }}
                                                classeTooltip={'tooltip-input-modal'} />
                                            <InputLocalIncidencia label={index === 0 ? 'Local Inc.' : undefined} placeholder='Local Inc.' name={`tributos[${index}].local_incidencia`} propsFormik={propsFormik}
                                                tamanho={'col-sm-12 col-xl-2 col-lg-6 col-md-5 pl-0'} {...props} obrigatorio={propsFormik.values.tributos[index]?.tributo?.value ? true : false || propsFormik.values.tributos?.length > 1} isClearable={false}
                                                classeTooltip={'tooltip-input-modal'} />
                                            <InputCurrency
                                                onUpdate={val => {
                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                    inputValorTributo.current[index] = valor
                                                }}
                                                label={index === 0 ? 'Valor Tributo R$' : undefined}
                                                name={`tributos[${index}].valor_tributo`}
                                                classeTooltip={'tooltip-input-modal'}
                                                propsFormik={propsFormik}
                                                disabled={true}
                                                max={100000000}
                                                value={inputValorTributo.current[index] || propsFormik.values.tributos[index].valor_tributo || propsFormik.values.tributos[index].valor_tributo_formatado}
                                                onBlur={(e) => {
                                                    if (inputValorTributo.current[index] || inputValorTributo.current[index] === 0) {
                                                        propsFormik.values.tributos[index].valor_tributo = inputValorTributo.current[index] && inputValorTributo.current[index].toString().trim();
                                                        propsFormik.values.tributos[index].valor_tributo_formatado = formatarMoeda(inputValorTributo.current[index] && inputValorTributo.current[index].toString());

                                                        //onChangeValorLiquidacaoNovo(propsFormik.values.liquidacao, index, propsFormik)
                                                    }
                                                    inputValorTributo.current[index] = null;
                                                }

                                                } classDiv='col-2' />

                                            <div className='col-4 col-xl-1 col-lg-2 col-md-2 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right'>
                                                <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => { removerLinhaTributo(arrayHelpers, propsFormik, index); calculaTodosTributos(propsFormik, index, inputValorTributo) }} />
                                            </div>
                                        </div>)
                                        :
                                        <></>}
                                </>
                            ))}
                        </FieldArray>
                        {propsFormik.touched.tributos && propsFormik.errors.tributos && !Array.isArray(propsFormik.errors.tributos) ?
                            <Overlay target={refTributos.current} show={true} placement="bottom">
                                {props2 => {
                                    return (
                                        <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                            <div className="error">
                                                <FiAlertTriangle className="icone" /> <span>{propsFormik.errors.tributos}</span></div>
                                        </Tooltip>)
                                }}
                            </Overlay> : <></>}
                    </div>
                </div>
                {!props.modal && <div className='separator mt-40 mb-5'></div>}
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)}
        </Formik>
    );
}

export default CadastroNotaFiscal 
