import React, { useState, useEffect } from 'react'
import DataGrid from '../../../../../components/DataGrid'
import DataGridAlternativa from '../../../../../components/DataGridAlternativa'
import { consultar } from '../../../../../util/AcessoApi'

const Consulta = props => {
    const [data, setData] = useState([])

    useEffect(_ => { consultar('/administracao/ferias/periodo-gozo', dados => setData(dados.sort((a, b) => b.id - a.id))) }, [props])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-periodo-gozo',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Ano',
                        accessor: 'ano',
                        ordenamento: {
                            tipo: 'numero',
                        },
                        filtro: {
                            tipo: 'ano',
                            idColuna: 'ano'
                        }
                    },
                    {
                        Header: 'Período de Gozo Inicial',
                        accessor: 'periodo_inicial',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'periodo_inicial',
                            idColuna: 'periodo_inicial'
                        }
                    },
                    {
                        Header: 'Período de Gozo Final',
                        accessor: 'periodo_final',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'periodo_final',
                            idColuna: 'periodo_final'
                        }
                    },
                    {
                        Header: 'Limite para Gozo',
                        accessor: 'data_limite_gozo',
                        ordenamento: {
                            tipo: 'data',
                        }
                    },
                    {
                        Header: 'Antecedência Mínima de Solicitação de Férias',
                        accessor: 'antecedencia_minima_dias',
                        ordenamento: {
                            tipo: 'numero',
                        }
                    },
                    // {
                    //     Header: 'Qtde. Max. Sócio por Período',
                    //     accessor: 'qtd_maxima_periodo',
                    //     ordenamento: {
                    //         tipo: 'numero',
                    //     }
                    // },
                    {
                        Header: 'Data de Registro',
                        accessor: 'data_registro',
                        ordenamento: {
                            tipo: 'data',
                        }
                    }
                ],
            },
        ],
        []
    )

    return (
        <DataGridAlternativa data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/administracao/ferias/periodo-gozo' modulo={props.modulo} titulo={'Consulta Ano (Gozo)/Parâmetros'}
            tituloLista={'Lista Ano (Gozo)/Parâmetros'} caminhoConsultaFiltros= '/administracao/ferias/periodogozopaginacao'
            urlCadastro='/administracao/ferias/periodo-gozo/cadastro' icone={'icon-File-ClipboardFileText'} />
    )
}

export default Consulta