import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import './assets/css/theme.css';
import Routes from './util/Routes'
import { ToastContainer } from 'react-toastify'
import WebService, { WebServiceSimples } from './components/WebService'

const Close = ({ closeToast }) => <b onClick={closeToast}>x</b>

const Render = () => {
    const refWebService = useRef(null)
    const refWebServiceSimples = useRef(null)

    const obterWebService = (body, serviceUrl, funcSuccess) => {
        if (refWebService && refWebService.current)
            refWebService.current.processar(body, serviceUrl, funcSuccess)
    }

    const chamarWebService = (body, serviceUrl, config) => {
        if (refWebService && refWebService.current)
            refWebService.current.processarSemFila(body, serviceUrl, config)
    }

    // window.addEventListener('beforeunload', function (e) {
        
    //     if (window.location.href.includes('financeiro/lancamento/cadastro') || window.location.href.includes('financeiro/movimento/cadastro')) {
    //         e.preventDefault(); // Cancela o evento padrão
    //         e.returnValue = ''; // Define uma mensagem em branco (pode ser personalizada)
    //         return 'É possível que as alterações feitas não sejam salvas.';
    //     }
    //     // Exiba a mensagem personalizada
    // });

    return <>
        <ToastContainer className="toast-container" closeButton={<Close />} closeOnClick draggable={false} position="bottom-right" />
        <WebService ref={refWebService} />
        <WebServiceSimples ref={refWebServiceSimples} />
        <Routes obterWebService={obterWebService} chamarWebService={chamarWebService} />
    </>
}

ReactDOM.render(
    <Render />,
    document.getElementById('root')
)