import React, { useState, useLayoutEffect, useRef, useEffect, useMemo } from 'react';
import { getApiExterna } from './AcessoApi'
import { mostrarToast } from '../components/Toasts'
import moment from "moment"
import 'moment/locale/pt-br'
import { Tooltip } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const obterDataFormatada = val => {
  if (val == null || val === '')
    return '';

  if (val.toString().length === 10)
    return val;

  return moment(val).utc().format('DD/MM/YYYY')
}

const meses = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
  default: 'Mês Inexistente'
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
function sortTable(table_id, sortColumn, tipo) {
  var tableData = document.getElementById(table_id).getElementsByTagName('tbody').item(0);
  var thread = document.getElementById(table_id).getElementsByTagName('thead').item(0);
  let temContador = false;
  let indexContador = -1;

  let qtdThs = thread.getElementsByTagName('th').length;
  for (let index = 0; index < qtdThs; index++) {
    if (index === sortColumn)
      continue;

    if (thread.getElementsByTagName('th').item(index).innerText.indexOf('↑') > -1) {
      thread.getElementsByTagName('th').item(index).innerText = thread.getElementsByTagName('th').item(index).innerText.replace('↑', '');
    }
    else if (thread.getElementsByTagName('th').item(index).innerText.indexOf('↓') > -1) {
      thread.getElementsByTagName('th').item(index).innerText = thread.getElementsByTagName('th').item(index).innerText.replace('↓', '');
    }

    if (thread.getElementsByTagName('th').item(index).className === 'contador') {
      temContador = true;
      indexContador = index;
    }
  }

  let innerText = thread.getElementsByTagName('th').item(sortColumn).innerText;
  let order = 'ASC'
  if (innerText.indexOf('↑') > -1) {
    order = 'DESC'
    thread.getElementsByTagName('th').item(sortColumn).innerText = innerText.replace('↑', '');
  }
  else if (innerText.indexOf('↓') > -1) {
    order = 'ASC'
    thread.getElementsByTagName('th').item(sortColumn).innerText = innerText.replace('↓', '');
  }
  thread.getElementsByTagName('th').item(sortColumn).innerText += order == 'ASC' ? '↑' : '↓';
  var rowData = tableData.getElementsByTagName('tr');
  for (var i = 0; i < rowData.length - 1; i++) {
    for (var j = 0; j < rowData.length - (i + 1); j++) {
      if (tipo && tipo == 'data') {
        if (order == 'ASC' ?
          moment(rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY') < moment(rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY')
          : moment(rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY') > moment(rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY')
        ) {
          tableData.insertBefore(rowData.item(j + 1), rowData.item(j));
        }
      }
      else if (tipo && tipo == 'data_hora') {
        if (order == 'ASC' ?
          moment(rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY HH:mm') < moment(rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY HH:mm')
          : moment(rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY HH:mm') > moment(rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY HH:mm')
        ) {
          tableData.insertBefore(rowData.item(j + 1), rowData.item(j));
        }
      }
      else if (tipo && tipo == 'numero') {
        var a = rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText;
        var b = rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText;
        if (order == 'ASC' ?
          Number(a) < Number(b)
          : Number(a) > Number(b)
        ) {
          tableData.insertBefore(rowData.item(j + 1), rowData.item(j));
        }
      }
      else if (tipo && tipo == 'socio') {
        var a = rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText;
        a = a.split(/\s+/).join('')
        var b = rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText;
        b = b.split(/\s+/).join('')
        a = a.indexOf('-') > -1 ? a.split('-')[1] : a;
        b = b.indexOf('-') > -1 ? b.split('-')[1] : b;
        if (order == 'ASC' ?
          a < b
          : a > b
        ) {
          tableData.insertBefore(rowData.item(j + 1), rowData.item(j));
        }
      }
      else if (order == 'ASC' ?
        rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText < rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText
        : rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText > rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText
      ) {
        tableData.insertBefore(rowData.item(j + 1), rowData.item(j));
      }
    }
  }


  if (temContador && rowData.length > 1) {
    for (var i = 0; i < rowData.length; i++) {
      rowData.item(i).getElementsByTagName('td').item(indexContador).innerText = i + 1
    }
  }



}
const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    if (!Array.isArray(items)) {
      return [];
    }
    let sortableItems = [...items];

    if (sortConfig !== null && Array.isArray(items)) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};


function mascaraData(val) {
  var pass = val.value;
  var expr = /[0123456789]/;

  for (let i = 0; i < pass.length; i++) {
    // charAt -> retorna o caractere posicionado no índice especificado
    var lchar = val.value.charAt(i);
    var nchar = val.value.charAt(i + 1);

    if (i == 0) {
      // search -> retorna um valor inteiro, indicando a posição do inicio da primeira
      // ocorrência de expReg dentro de instStr. Se nenhuma ocorrencia for encontrada o método retornara -1
      // instStr.search(expReg);
      if ((lchar.search(expr) != 0) || (lchar > 3)) {
        val.value = "";
      }

    } else if (i == 1) {

      if (lchar.search(expr) != 0) {
        // substring(indice1,indice2)
        // indice1, indice2 -> será usado para delimitar a string
        var tst1 = val.value.substring(0, (i));
        val.value = tst1;
        continue;
      }

      if ((nchar != '/') && (nchar != '')) {
        var tst1 = val.value.substring(0, (i) + 1);

        if (nchar.search(expr) != 0)
          var tst2 = val.value.substring(i + 2, pass.length);
        else
          var tst2 = val.value.substring(i + 1, pass.length);

        val.value = tst1 + '/' + tst2;
      }

    } else if (i == 4) {

      if (lchar.search(expr) != 0) {
        var tst1 = val.value.substring(0, (i));
        val.value = tst1;
        continue;
      }

      if ((nchar != '/') && (nchar != '')) {
        var tst1 = val.value.substring(0, (i) + 1);

        if (nchar.search(expr) != 0)
          var tst2 = val.value.substring(i + 2, pass.length);
        else
          var tst2 = val.value.substring(i + 1, pass.length);

        val.value = tst1 + '/' + tst2;
      }
    }

    if (i >= 6) {
      if (lchar.search(expr) != 0) {
        var tst1 = val.value.substring(0, (i));
        val.value = tst1;
      }
    }
  }

  if (pass.length > 10)
    val.value = val.value.substring(0, 10);
  return true;
}

function useHover() {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}


function removerAcentos(s) {
  var map = { "â": "a", "Â": "A", "à": "a", "À": "A", "á": "a", "Á": "A", "ã": "a", "Ã": "A", "ê": "e", "Ê": "E", "è": "e", "È": "E", "é": "e", "É": "E", "î": "i", "Î": "I", "ì": "i", "Ì": "I", "í": "i", "Í": "I", "õ": "o", "Õ": "O", "ô": "o", "Ô": "O", "ò": "o", "Ò": "O", "ó": "o", "Ó": "O", "ü": "u", "Ü": "U", "û": "u", "Û": "U", "ú": "u", "Ú": "U", "ù": "u", "Ù": "U", "ç": "c", "Ç": "C" };
  return s.replace(/[\W\[\] ]/g, function (a) { return map[a] || a });
}

const mensagens = {
  'erro': 'Ocorreu um erro inesperado! Tente novamente mais tarde.',
  'sucesso': 'Operação efetuada com sucesso!',
  'permissao': 'Você não possui permissão.'
}

function isNumber(s) {
  s = s.toString();
  var rgx = /^[0-9]*\.?[0-9]*$/;
  return rgx.test(s)
}
const ajustarDadosTelefone = (telefones) => {

  if ((typeof telefones) == "string" && telefones.length) {
    telefones = [{
      "numero": telefones,
      "registro_id": "",
      "tipo_telefone_id": 1
    }]
  }
  else if (telefones)
    for (let i = 0; i < telefones.length; i++) {

      if (telefones[i].tipo_telefone != undefined)
        telefones[i].tipo_telefone_id = telefones[i].tipo_telefone.value;
      if (telefones[i].telefone != undefined)
        telefones[i].numero = telefones[i].telefone;
      delete telefones[i].descricao
      delete telefones[i].tipo_telefone
    }
  else
    telefones = []

  return telefones
}

const buscarCep = (e, propsFormik) => {
  getApiExterna(`https://viacep.com.br/ws/${propsFormik.values.cep}/json/`, response => {
    if (response.data.erro) {
      mostrarToast('erro', 'CEP não encontrado')
      return;
    }

    propsFormik.setFieldValue('logradouro', response.data.logradouro)

    let obj = {
      value: response.data.ibge,
      label: response.data.localidade.toString().toUpperCase() + " - " + response.data.uf
    }

    propsFormik.setFieldValue('municipio', obj)
    propsFormik.setFieldValue('bairro', response.data.bairro)
  })
}
const UpdatingTooltip = React.forwardRef(
  ({ scheduleUpdate, children, ...props }, ref) => {
    useEffect(() => {
      if (scheduleUpdate)
        scheduleUpdate();
    }, [children, scheduleUpdate]);
    return (
      <Tooltip ref={ref} {...props}>
        {children}
      </Tooltip>
    );
  },
);

const getBetweenDates = (dataInicial, dataFinal, formatoFinal) => {
  //Data inicial e final devem ser moment
  //Formato final é o formato que será entregue, caso não preenchido o retorno será moment
  return Array((dataFinal.diff(dataInicial, 'days') + 1)).fill({}).map((n, i) => dataInicial.clone().add(i, 'day').format(formatoFinal))
}

const secretKey = '6Le9ywAVAAAAAOhOvtXOVMPyAoPhRyhI9swU1dd6'

const enviarNotificacao = async data => {
  const message = {
    to: data.token,
    sound: 'default',
    title: data.titulo || 'Plasma',
    body: data.msg || '',
    data: data.data || { data: 'goes here' },
  }

  try {
    await fetch('https://exp.host/--/api/v2/push/send', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    })
  } catch (err) { console.log(err) }
}


const getUnique = (array, key) => {
  const distinct = []

  for (let i = 0; i < array.length; i++) {
    if (distinct.filter(x => array[i][key] === x[key]).length === 0)
      distinct.push(array[i])
  }

  return distinct
}

const getPositionScroll = _ => {
  if (window.pageYOffset != undefined) {
    return [window.pageXOffset, window.pageYOffset];
  } else {
    var sx, sy, d = document,
      r = d.documentElement,
      b = d.body;
    sx = r.scrollLeft || b.scrollLeft || 0;
    sy = r.scrollTop || b.scrollTop || 0;
    return [sx, sy];
  }
}

function abridgedControl(fullName, limit) {
  if (fullName.length > limit) {
    return toAbridged(fullName, limit);
  }
  return fullName;
}


function toAbridged(fullName, limit) {
  const separator = ' ';
  let names = fullName.split(separator);
  let newName = '';
  names.map((partName, index) => {
    if (index == 0)
      newName += partName;
    else if (partName.length < 4) {
      newName += ' ' + partName;
    }
    else {
      newName += ' ' + (newName.length > limit ? partName[0] + '.' : partName);
    }
  })
  return newName;
}

const formatarMoeda = (valor) => valor ? 'R$ ' + parseFloat(valor).toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : 'R$ 0,00'

const abreviarNomeSocio = (nome, tamanho) => {
  return abridgedControl(nome, tamanho)
}

const checkAllUndefined = (...args) => !(args.filter(x => x).length > 0);

const convertValueToFloat = (value) => parseFloat(value && value.toString().includes('R$') ? value.replace('R$', '').replace(/\./g, '').replace(',', '.') : value)

const distinctArr = (arr) => {
  let arrN = arr

  if (!Array.isArray(arr) && typeof arr === "string") {
    arrN = arrN.split(', ')
  } else {
    return []
  }

  return arrN && arrN.length > 0 ? arrN.filter((elemento, indice, array) => array.indexOf(elemento) === indice) : [];
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function generateGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}


export {
  useWindowSize, meses, mensagens, mascaraData, obterDataFormatada, buscarCep, ajustarDadosTelefone, useInterval, useHover, UpdatingTooltip, getBetweenDates, removerAcentos,
  secretKey, enviarNotificacao, getUnique, getPositionScroll, abreviarNomeSocio, isNumber, useSortableData, sortTable, formatarMoeda, checkAllUndefined, convertValueToFloat, distinctArr, useQuery,
  generateGuid, isJsonString
}