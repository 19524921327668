import React, { useState, useEffect } from 'react';
import '../../assets/css/sem-acesso.css'
import { Redirect } from 'react-router-dom';
import { setSistemaStatus } from './../../util/Configuracoes';

const SemAcesso = (props) => {
    const [status, setStatus] = useState("off")

    if (status == "off")
        return (
            <>
                <div className='row justify-content-center '>
                    <div className='col-sm-12 col-md-12 col-lg-6'>
                        <div className="form-side">
                            <div className="row justify-content-center mb-1">
                                <p className="titulo">Conexão perdida!</p>
                            </div>
                            <div className="row justify-content-center mb-1">
                                <div className="col-12 text-center">
                                    <p className="texto">A Conexão com o Servidor foi interrompida!</p>
                                    <p className="texto">Aguarde alguns instantes e caso essa tela permaneça, entre em contato com o suporte.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center row-sem-conexao'>
                    <div className='col-sm-12'>
                            <img alt='sem-conexao' className="img-sem-conexao" src={require('../../assets/img/conexao2.png')}></img>
                    </div>
                </div>

            </>
        )

}

export default SemAcesso
