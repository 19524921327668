import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputTipoRelatorio, InputEstabelecimentoSaude, InputSimNao, InputOperadoraSaude, InputSocio, InputCirurgiaoCombo, InputTipoExecutante, InputTipoCliente, InputMunicipioGOPrimeiro, InputClienteFinanceiro, InputUsuario, InputTodasCategorias, InputContaBancaria, InputStatusCorLancamento, InputStatusLancamento, InputTipoRelatorioPersonalizado, InputTipoLancamento } from '../../../components/InputsAutoComplete'
import { InputDatePicker, InputPadrao } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes';
import moment from 'moment';
import { ModalDuasOpcoes } from '../../../components/Modais';
import { checkAllUndefined } from '../../../util/Utilitarios';
import { MODULOS_SISTEMA } from '../../../util/Enums';
import { InputEmpresa, InputTipoMovimento } from '../../../components/InputsAutoCompleteV2';
import { relatorioContaReceberSintetico } from '../../../components/RelatorioEspecifico/RelatorioContasReceberSintetico';
import { InputMonthPicker } from '../../../components/InputsV2';

export default props => {
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Contas a Pagar/Receber')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(MODULOS_SISTEMA.RELATORIO_DE_CONTAS_PAGAR_RECEBER)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: { value: MODULOS_SISTEMA.RELATORIO_DE_CONTAS_PAGAR_RECEBER, label: 'Relatório Contas a Pagar/Receber' }, referencia_liquidacao: moment().format('MM/yyyy'), tipo_lancamento: { value: 1, label: 'RECEITA' }, data_liquidacao_final: '', data_liquidacao_inicial: '' })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const tipoRelatorio = [{ label: 'Contas a Pagar/Receber', value: MODULOS_SISTEMA.RELATORIO_DE_CONTAS_PAGAR_RECEBER }]


    const validacao = Yup.object().shape({
        tipo_lancamento: Yup.string().nullable().required(),
        data_liquidacao_final: Yup.string().when(['data_liquidacao_inicial', 'referencia_liquidacao'], {
            is: (data_liquidacao_inicial, referencia_liquidacao) => {
                return !data_liquidacao_inicial && !referencia_liquidacao;
            },
            then: Yup.string().required()
        }),
        data_liquidacao_inicial: Yup.string().when(['data_liquidacao_final', 'referencia_liquidacao'], {
            is: (data_liquidacao_final, referencia_liquidacao) => {
                return !data_liquidacao_final && !referencia_liquidacao;
            },
            then: Yup.string().required()
        }),
        referencia_liquidacao: Yup.string().when(['data_liquidacao_final', 'data_liquidacao_inicial'], {
            is: (data_liquidacao_inicial, data_liquidacao_final) => {
                return !data_liquidacao_inicial && !data_liquidacao_final;
            },
            then: Yup.string().required()
        }),
    }, [
        ['data_liquidacao_inicial', 'data_liquidacao_final'],
        ['data_liquidacao_inicial', 'referencia_liquidacao'],
        ['data_liquidacao_final', 'referencia_liquidacao'],
    ])

    const filtrosPadrao = (propsFormik) => [
        <InputMonthPicker label="Referência Liquidação" name="referencia_liquidacao" type="text"
            setarValor={value => { propsFormik.setFieldValue("referencia_liquidacao", value) }} classeTooltip={'tooltip-input-modal'} />,

        <InputTipoLancamento type="text" label='Tipo Lançamento' name='tipo_lancamento' placeholder='Tipo Lançamento' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} />,

        <InputEmpresa label='Empresa' name='empresa' placeholder='Empresa' propsFormik={propsFormik} {...props} classeTooltip={'tooltip-input-modal'} multiplo />,

        <InputClienteFinanceiro label="Cliente" name="cliente" type="text" placeholder='Cliente' autoFocus propsFormik={propsFormik}
            classeTooltip={'tooltip-input-modal'} multiplo />,

        <InputTodasCategorias label="Categoria" name="categoria" type="text" placeholder='Categoria' autoFocus propsFormik={propsFormik}
            classeTooltip={'tooltip-input-modal'} multiplo />,

        <InputContaBancaria label="Conta Bancária Empresa" name="conta" type="text" placeholder='Conta Bancária Empresa' autoFocus propsFormik={propsFormik}
            classeTooltip={'tooltip-input-modal'} multiplo />,

        <InputDatePicker label="Data Liquidação Inicial" name="data_liquidacao_inicial" type="text" placeholder='Data Liquidação Inicial' propsFormik={propsFormik}
            setarValor={value => { propsFormik.setFieldValue("data_liquidacao_inicial", value) }} />,

        <InputDatePicker label="Data Liquidação Final" name="data_liquidacao_final" type="text" placeholder='Data Liquidação Final' propsFormik={propsFormik}
            setarValor={value => { propsFormik.setFieldValue("data_liquidacao_final", value) }} />,
    ]

    const relatoriosPorTipo = propsFormik => [
        {

            titulo: 'Relatório Contas Pagar/Receber Sintético',
            tipo: MODULOS_SISTEMA.RELATORIO_DE_CONTAS_PAGAR_RECEBER,
            url: '/financeiro/relatoriopagarrecebersintetico',
            campos: filtrosPadrao(propsFormik),
            classeTr: 'linha-bottom-relatorio',
            coluna_agrupar: 'categorias_json',
            agrupado: true,
            numerarLinha: true,
            colunas: [
                // { label: 'Código', name: 'id', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Número Conta', name: 'numero_conta', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Conta Grupo', name: 'subcategoria', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'total_valor', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
            ],
            data: [],
            colunasFiltros: [

                { label: 'Referência Liquidação', name: 'referencia_liquidacao', name_coluna: 'referencia_liquidacao' },
                { label: 'Empresa', name: 'empresa', name_coluna: 'empresa' },
                { label: 'Tipo Lançamento', name: 'tipo_lancamento', name_coluna: 'tipo_lancamento' },
                { label: 'Cliente', name: 'cliente', name_coluna: 'cliente' },
                { label: 'Categoria', name: 'categoria', name_coluna: 'categoria' },
                { label: 'Conta Bancária Empresa', name: 'conta', name_coluna: 'conta' },
                { label: 'Data Liquidacao Inicial', name: 'data_liquidacao_inicial', name_coluna: 'data_liquidacao_inicial' },
                { label: 'Data Liquidacao Final', name: 'data_liquidacao_final', name_coluna: 'data_liquidacao_final' },
            ],
            colunasCsv: [
                { label: 'Empresa', name: 'empresa', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Grupo Principal Conta', name: 'categoria_principal', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Número Conta', name: 'numero_conta', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Grupo Conta', name: 'subcategoria', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor', name: 'total_valor', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
            ],
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioContaReceberSintetico(dados, arrayConfigCol, configOrdenamento, htmlHeader),

        },

    ]

    const submit = (values, propsFormik) => {

        setCarregando(true)

        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)
        setShowModalFiltros(false)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]

        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio)
            setFiltros(Object.keys(values).map((x, idx, array) => {
                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                })
            }
            ).filter(x => x))
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }


    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }
        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{ aposRelatorio: cloneRelatorioAtual.aposRelatorio, numerarLinha: cloneRelatorioAtual.numerarLinha !== undefined ? cloneRelatorioAtual.numerarLinha : true, colunas: cloneRelatorioAtual.colunas, colunasCsv: cloneRelatorioAtual.colunasCsv ? cloneRelatorioAtual.colunasCsv : null, agrupado: cloneRelatorioAtual?.agrupado || false, data: cloneRelatorioAtual.data, relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico ? (dados, arrayConfigCol, configOrdenamento, htmlHeader) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento, htmlHeader) : null }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: filtros, tipo_relatorio: relatorioSelecionado })
    }, [filtros])

    const changeTipo = (value) => {
        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({})
        // const index = tipoRelatorio.findIndex(x => x.value === value)
        setValuesFiltros({ tipo_relatorio: value })
        setRelatorioSelecionado(value?.value)
    }

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })}
            agrupamento={[{ coluna: 'nome', descricao: 'nome' }]}>

            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (
                    <div>
                        <Form>
                            <div className='row'>
                                <InputTipoRelatorioPersonalizado label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                                    propsFormik={propsFormik} modulos={relatoriosPorTipo(propsFormik).map(x => x.tipo)} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} />
                                {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                                    {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                        (item, idx, array) => {
                                            if (idx === (array.length - 1)) {
                                                setTimeout(() => {
                                                    setCarregandoFiltros(false)
                                                }, 1000)
                                            }
                                            return item
                                        }
                                    )}
                                </div>}
                                {carregandoFiltros === true ?
                                    <ContentLoader
                                        speed={2}
                                        width='100%'
                                        height='400'
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#e4e4e4"
                                        {...props} >
                                        <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                        <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                        <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                        <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                        <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                                    </ContentLoader>
                                    : <></>}
                            </div>
                            <div className='direita container-botoes mb-3 group-salvar-novo'>
                                <BotaoPadrao texto='Filtrar' disabled={false} />
                            </div>
                        </Form>
                        <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { submit(propsFormik.values, propsFormik); setShowModalValidacao(false) }} funcaoOpcao1={() => { setCarregando(false); setShowModalValidacao(false) }}
                            esconderFechar mensagem={<>{<>{'Validações Filtro:'} <br /><br /></>} {mensagemModal}<br />
                                {'Deseja Continuar?'}</>}
                            textoOpcao1={'Cancelar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} />

                    </div>
                )}
            </Formik>
        </ConsultaRelatorio >
    )
}
