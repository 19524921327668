import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { mostrarToast } from '../../../../components/Toasts'
import { get, putComAcao } from '../../../../util/AcessoApi'
import { ACOES } from '../../../../util/Enums'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import Atalho from '../../../../util/Atalho'
import { BtnAcao } from '../../../../components/Acoes'
import { ModalCadastro } from '../../../../components/Modais'
import '../css/style.css'

export default props => {
    const [item, setItem] = useState({})
    const [trocas, setTrocas] = useState([])

    useEffect(() => {
        refreshData();
    }, [props.item])


    const refreshData = ()=>{
        
        Promise.resolve(get(`/administracao/obterTrocasParaAgenda/${props.item.id}`)).then(val => {
            setTrocas(val)
        })
    }

    const clickCancelarTroca = (item) => {
        putComAcao(
            ACOES.TROCAR_SOCIO,
            props.modulo_id,
            item,
            '/administracao/cancelartrocasocio',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                props.funcaoAposSalvar()
            },
            (erro) => {
                mostrarToast('erro', mensagens.erro);
            })
    }
    const clickConfirmarTroca = (item) => {
        putComAcao(
            ACOES.TROCAR_SOCIO,
            props.modulo_id,
            item,
            '/administracao/aceitartrocasocio',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                props.funcaoAposSalvar()
            },
            (erro) => {
                mostrarToast('erro', mensagens.erro);
            })
    }

    const clickNegarTroca = (item) => {
        putComAcao(
            ACOES.TROCAR_SOCIO,
            props.modulo_id,
            item,
            '/administracao/negartrocasocio',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                props.funcaoAposSalvar()
            },
            (erro) => {
                mostrarToast('erro', mensagens.erro);
            })
    }





    useEffect(() => {

    }, [props.abrirModal])

    return (
        <>
            <ModalCadastro titulo={"Solicitações de Trocas (APP)"} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                {trocas.length > 0 ?
                    <>
                        {
                            trocas.map((x) =>
                                <div className="detalhes" key={Math.random()}>
                                    <div className="item-detalhes">
                                        <b>Remetente</b>
                                        <h6>{x.troca.remetente.nome}</h6>
                                    </div>
                                    <div className="item-detalhes">
                                        <b>Destinatario</b>
                                        <h6>{x.troca.destinatario.nome}</h6>
                                    </div>
                                    <div className="item-detalhes">
                                        <b>Justificativa</b>
                                        <h6>{x.troca.justificativa}</h6>
                                    </div>
                                    <div className="item-detalhes">
                                        <b>Status</b>
                                        <h6>{x.troca.status.descricao}</h6>
                                    </div>
                                    <hr />
                                    <div>
                                        <center>
                                            <BotaoPadrao click={_ => { clickConfirmarTroca(x) }} icone='icon-Yes' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Aceitar Troca'} idTooltip={"padrao"} />
                                            <BotaoPadrao click={_ => { clickNegarTroca(x) }} icone='icon-Close' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Negar Troca'} idTooltip={"padrao"} />
                                            <BotaoPadrao click={_ => { clickCancelarTroca(x.troca) }} icone='icon-New-Trash' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Cancelar Troca'} idTooltip={"padrao"} />
                                        </center>
                                    </div>
                                </div>
                            )
                        }
                    </>
                    : <><h3>Nenhum registro encontrado...</h3></>}

            </ModalCadastro>
        </>
    )
}

