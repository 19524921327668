import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Formik, useFormikContext } from 'formik'
import { generateGuid, useInterval } from '../util/Utilitarios'
import { ACOES } from '../util/Enums'
import { getUsuario } from '../util/Configuracoes'


const Rascunho = (props, ref) => {

    const { history, modulo, additionalValues, identificador, formik, idle } = props
    const [guid, setGuid] = useState(null)
    const previousAdditionalValues = useRef();

    const montarRascunho = _ => {

        let objeto_montar = {
            id: guid,
            modulo: { id: modulo },
            acao: { id: !formik.values.id ? ACOES.INSERIR : ACOES.ALTERAR, },
            controle: !formik.values.id ? 0 : formik.values.id,
            urlBase: process.env.REACT_APP_URL_FRONT_DEVELOPMENT,
            identificador: identificador || '',
            path: history?.location?.pathname,
            objeto: {
                additionalValues: null,
                user_name: getUsuario()?.id,
                ...formik.values
            }

        }

        if (additionalValues)
            objeto_montar.objeto.additionalValues = [...additionalValues]



        objeto_montar = JSON.stringify(JSON.stringify(objeto_montar))


        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' // Especifica o tipo de conteÃºdo como JSON
            },
            body: objeto_montar
        };


        fetch(process.env.REACT_APP_DRAFT_API + '/drafter/draft', options)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro ao enviar os dados');
                }
                return response.json();
            })
            .catch(error => {
                console.error('Erro:', error);
            });
    }

    useEffect(_ => {

        if (!history?.location?.state?.guid)
            setGuid(generateGuid())
        else
            setGuid(history?.location?.state?.guid)
    }, [history?.location.key])

    useInterval(_ => {
        if (guid && !idle)
            formik?.values && montarRascunho()


    }, 5000, [idle])


    return <></>

}

export default Rascunho