import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { get } from '../../../../util/AcessoApi'
import { ordenarArrayObj } from '../../../../util/FuncoesComuns'
import { visualizar } from '../../../../util/AcessoApi'

const VisualizacaoEstabelecimentoSaude = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})


    useEffect(() => {
        if (id) {
            visualizar(`/faturamento/parametroOperadoraSaude`, id, obj => {
                setDados(obj)
            })
        }
    }, [id])

    const data = dados.data_registro ? {
        titulo: 'Visualização Parâmetro Operadora Saúde',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Operadora de Saúde',
                        descricao: dados.operadora_saude_id[0].label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Codigo Prestador na Operadora',
                        descricao: dados.codigo_prestador,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Limite Faturamento (dias)',
                        descricao: dados.limite_faturamento,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Qtde. Máxima de Guias por Lote',
                        descricao: dados.quantidade_guia_lote,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Utiliza Guia?',
                        descricao: dados.guia ? "Sim" : "Não",
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Valida Maior Porte?',
                        descricao: dados.valida_maior_porte ? "Sim" : "Não",
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Utiliza Senha?',
                        descricao: dados.utiliza_senha ? "Sim" : "Não",
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Libera Percentual?',
                        descricao: dados.libera_percentual ? "Sim" : "Não",
                        tamanho: 'col-lg-6'
                    },

                    {
                        titulo: 'Valida Interposição?',
                        descricao: dados.valida_interposicao ? "Sim" : "Não",
                        tamanho: 'col-lg-6'
                    },

                    {
                        titulo: 'Validade Duplicidade?',
                        descricao: dados.valida_duplicidade ? "Sim" : "Não",
                        tamanho: 'col-lg-6'
                    },

                    {
                        titulo: 'Procedimento "Não" Ordenados?',
                        descricao: dados.procedimento_ordenados ? "Sim" : "Não",
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Procedimento Único (50/70/100%)?',
                        descricao: dados.procedimento_unico ? "Sim" : "Não",
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data Registro',
                        descricao: (dados.data_registro_formatada),
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Observação',
                        descricao: dados.observacao,
                        tamanho: 'col-lg-6'
                    },
                ]
            },
            {

                titulo: 'Duplicidade De Guias (CAMPOS)',
                colunas: [
                    {
                        titulo: 'Sócio',
                        descricao: dados.socio ? "Sim" : "Não",
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Estabelecimento de Saúde',
                        descricao: dados.estabelecimento_saude ? "Sim" : "Não",
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Acomodação',
                        descricao: dados.acomodacao ? "Sim" : "Não",
                        tamanho: 'col-lg-6'
                    },
                    ,
                    {
                        titulo: 'Guia',
                        descricao: dados.guia ? "Sim" : "Não",
                        tamanho: 'col-lg-6'
                    },
                    ,
                    {
                        titulo: 'Matrícula/Carteira',
                        descricao: dados.matricula_carteira ? "Sim" : "Não",
                        tamanho: 'col-lg-6'

                    },
                    {
                        titulo: 'Nome Paciente',
                        descricao: dados.nome_paciente ? "Sim" : "Não",
                        tamanho: 'col-lg-6'

                    },
                    {
                        titulo: 'Hora Inicial',
                        descricao: dados.hora_inicial ? "Sim" : "Não",
                        tamanho: 'col-lg-6'

                    },
                    {
                        titulo: 'Hora Final',
                        descricao: dados.hora_final ? "Sim" : "Não",
                        tamanho: 'col-lg-6'

                    },
                    {
                        titulo: 'Senha TISS',
                        descricao: dados.senha_tiss ? "Sim" : "Não",
                        tamanho: 'col-lg-6'

                    },
                    {
                        titulo: 'Carteira TISS',
                        descricao: dados.carteira_tiss ? "Sim" : "Não",
                        tamanho: 'col-lg-6'

                    },
                ]
            }
        ]

    } : { titulo: 'Visualização de Estabelecimento Saúde' }

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoEstabelecimentoSaude