import React, { useState, useRef, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/icones/iconsmind/style.css';
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { useHover, UpdatingTooltip, mensagens } from './../util/Utilitarios';
import { excluir, get, putComAcao } from './../util/AcessoApi'

import { set } from 'local-storage';
import { date } from 'yup';
import { ACOES, MODULO } from '../util/Enums';
import { mostrarToast } from './Toasts';
import { getUsuario } from '../util/Configuracoes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function renderTooltip(props, nome, classTooltip) {
    delete props.show;
    return <Tooltip id={'tooltip ' + props.id ? props.id : 'tooltip ' + Math.random()} className={classTooltip} {...props}>{nome}</Tooltip>;
}

const BtnAcaoLink = (props) => {
    const { texto, icone, to } = props
    const classTooltip = props.classTooltip

    return (
        <div className="icone ptb-10">
            <OverlayTrigger
                popperConfig={{
                    modifiers: {
                        preventOverflow: {
                            enabled: false
                        }
                    }
                }}
                placement="bottom"
                delay={{ show: 25, hide: 25 }}
                overlay={(props) => renderTooltip(props, texto, classTooltip)}>
                <Link to={to} className={"icon-consulta btn-acao " + icone} target={props.target || 'blank'} />
            </OverlayTrigger>
        </div>
    )
}

const BtnAcaoLinkPermissao = (props) => {
    const history = useHistory();
    const { to } = props

    const objPermissao = {
        usuario_id: getUsuario().id,
        modulo_id: props.modulo,
        acao_id: props.acao
    }
    const validarPermissao = () => {
        Promise.resolve(get('/controleAcesso/permissao', objPermissao)).then(function (val) {
            if (val?.length)
                window.open(to, props.target || '_blank');
            else {
                mostrarToast('erro', mensagens['permissao'])
            }
        }).catch((e) => {
            mostrarToast('erro', mensagens['erro'])
        })
    }

    return (
        <BtnAcao {...props} action={validarPermissao} />
    )
}

const BtnAcao = (props) => {
    const { texto, icone, type } = props
    const classTooltip = props.classTooltip
    let nomeBotao = props.disabled?.disabledRow && props.disabled?.id === props.id ? 'Aguarde' : texto
    const buttonStyle = {
        opacity: props.disabled?.disabledRow && props.disabled?.id === props.id ? 0.4 : 1,
        cursor: props.disabled?.disabledRow && props.disabled?.id === props.id ? 'default' : 'pointer',
    };
    return (
        <div className={`icone ` + props.className} style={props.ajusta_padding && { padding: 20 }}>
            {
                texto && <OverlayTrigger
                    popperConfig={{
                        modifiers: {
                            preventOverflow: {
                                enabled: false
                            }
                        }
                    }}
                    id={props.id ? props.id : Math.random()}
                    placement="bottom"
                    delay={{ show: 25, hide: 25 }}
                    overlay={(props) => renderTooltip(props, nomeBotao, classTooltip)}>

                    {props.loading ? <span className="spinner-border spinner-border-md" style={{ height: 20, width: 20, opacity: 0.2 }} role="status" aria-hidden="true"></span> :
                        <button type={type && type || 'submit'} style={buttonStyle} disabled={props.disabled?.disabledRow && props.disabled?.id === props.id || false} className={"btn-acao-alternativo " + icone + " " + (props.cortarIcone ? "strikediag" : "")} onClick={() => props.action()} />}

                </OverlayTrigger>
                || <button type={type && type || 'submit'} disabled={props.disabled?.disabledRow && props.disabled?.id === props.id || false} style={buttonStyle} className={"btn-acao-alternativo " + icone + " " + (props.cortarIcone ? "strikediag" : "")} onClick={() => props.action()} />
            }
        </div>)
}

const Btn = (props) => {
    const { texto, icone } = props
    const classTooltip = props.classTooltip

    return (
        <div className="icone">
            {
                texto && <OverlayTrigger
                    popperConfig={{
                        modifiers: {
                            preventOverflow: {
                                enabled: false
                            }
                        }
                    }}
                    placement="bottom"
                    delay={{ show: 25, hide: 25 }}
                    overlay={(props) => renderTooltip(props, texto, classTooltip)}>
                    <button className={"btn-acao " + icone + " " + (props.cortarIcone ? "strikediag" : "")} />
                </OverlayTrigger>
                || <button className={"btn-acao " + icone + " " + (props.cortarIcone ? "strikediag" : "")} />
            }
        </div>)
}

const BtnAcaoConfirmar = (props) => {
    const [contador, setContador] = useState(0)
    const { texto, icone, type, mensagemConfirmacao } = props
    const [hoverRef, isHovered] = useHover();

    useEffect(() => {
        if (!isHovered) {
            setContador(0)
        }
    }, [isHovered])

    return (<div className="icone ml-10">

        <OverlayTrigger
            popperConfig={{
                modifiers: {
                    preventOverflow: {
                        enabled: false
                    }
                }
            }}
            placement={"bottom"}
            delay={{ show: 25, hide: 25 }}
            overlay={
                <UpdatingTooltip className={props.classTooltip + (contador === 1 ? " bottom-tooltip" : "")} id="popover-contained">{(contador === 1 ? mensagemConfirmacao || "Tem certeza que deseja excluir?" : texto)}</UpdatingTooltip>
            }
        >

            <button ref={hoverRef} className={"btn-acao " + (contador == 1 ? " icon-Yes" : icone)} type={type && type || 'submit'} onClick={() => {
                if ((contador + 1) == 2) {
                    props.action();
                    setContador(0);
                }
                setContador(contador + 1);
            }
            } /></OverlayTrigger>

    </div>)
}

const LinkExclusaoTabelaPequena = (props) => {
    const [contador, setContador] = useState(0)
    const { texto, icone } = props
    const [hoverRef, isHovered] = useHover();

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!loading) {
            setContador(0)
        }
    }, [loading])

    useEffect(() => {
        if (!isHovered) {
            setContador(0)
        }
    }, [isHovered])


    return (<div style={{ display: 'inline' }}>
        <a ref={hoverRef} onClick={() => {
            props.action && props.action();
            if ((contador + 1) == 2) {
                setLoading(true);
                excluir(props.url, props.idItem, () => { props.acaoAposExclusao(); setLoading(false); }, () => { props.acaoAposErro && props.acaoAposErro(); setLoading(false); }, props.descricao)
                setContador(0);
            }
            setContador(contador + 1);
        }} style={{ paddingLeft: '10px' }} class='link'><span className={(contador == 1 ? " icon-Yes" : icone)}></span> {loading ? 'aguarde...' : (contador == 0 ? 'excluir' : 'confirme')}</a>
    </div>)
}

const LinkAlteracaoTabelaPequena = (props) => {
    const { texto, icone } = props
    const [loading, setLoading] = useState(false)


    return (<div style={{ display: 'inline' }}>
        <OverlayTrigger
            popperConfig={{
                modifiers: {
                    preventOverflow: {
                        enabled: false
                    }
                }
            }}
            placement="bottom"
            delay={{ show: 25, hide: 25 }}
            overlay={
                <UpdatingTooltip className={props.classTooltip} id="popover-contained">{texto}</UpdatingTooltip>
            }
        >
            <a href="#" onClick={() => {
                props.action();
            }} style={{ paddingLeft: '10px' }} class='link'><span className={icone}></span> {loading ? 'aguarde...' : (props.textoInline && props.textoInline || 'alterar')}</a>
        </OverlayTrigger></div>)
}
const LinkAlteracaoTabelaPequenaPersonalizado = (props) => {
    const { texto, icone } = props
    const [loading, setLoading] = useState(false)


    return (<div style={{ display: 'inline' }}>
        <OverlayTrigger
            popperConfig={{
                modifiers: {
                    preventOverflow: {
                        enabled: false
                    }
                }
            }}
            placement="bottom"
            delay={{ show: 25, hide: 25 }}
            overlay={
                <UpdatingTooltip className={props.classTooltip} id="popover-contained">{texto}</UpdatingTooltip>
            }
        >
            <a onClick={() => {
                props.action();
            }} style={{ paddingLeft: '10px' }} class='link'><span className={icone}></span> {loading ? 'aguarde...' : (props.textoInline && props.textoInline || 'alterar')}</a>
        </OverlayTrigger></div>)
}
const LinkExclusaoTabelaPequenaPersonalizado = (props) => { // aq

    const [contador, setContador] = useState(0)
    const { texto, icone } = props
    const [hoverRef, isHovered] = useHover();

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!loading) {
            setContador(0)
        }
    }, [loading])

    useEffect(() => {
        if (!isHovered) {
            setContador(0)
        }
    }, [isHovered])

    return (<div style={{ display: 'inline' }}>
        <a ref={hoverRef} onClick={() => {

            if ((contador + 1) == 2) {

                setLoading(true);
                // excluir(props.url, props.idItem, () => { props.acaoAposExclusao(); setLoading(false); }, (e) => { props.acaoAposErro && props.acaoAposErro(e); setLoading(false); }, props.descricao)
                if (!props.idItem) {
                    props.action && props.action();
                    setLoading(false)

                } else {
                    putComAcao(
                        ACOES.REMOVER_LIQUIDACAO,
                        MODULO["/financeiro/lancamentofinanceiro"],
                        { id: props.idItem, apenasMovimento: true },
                        '/financeiro/removerliquidacao',
                        () => {
                            props.acaoAposExclusao(); setLoading(false);
                        },
                        (e) => { props.acaoAposErro && props.acaoAposErro(e); setLoading(false); })
                }

                setContador(0);
            }
            setContador(contador + 1);
        }} style={{ paddingLeft: '10px' }} class='link'><span className={(contador == 1 ? " icon-Yes" : icone)}></span> {loading ? 'aguarde...' : (contador == 0 ? 'excluir' : 'confirme')}</a>
    </div>)
}

const LinkVisualizacaoTabelaPequena = (props) => {
    const { texto, icone } = props
    const [loading, setLoading] = useState(false)


    return (<div style={{ display: 'inline' }}>
        <OverlayTrigger
            popperConfig={{
                modifiers: {
                    preventOverflow: {
                        enabled: false
                    }
                }
            }}
            placement="bottom"
            delay={{ show: 25, hide: 25 }}
            overlay={
                <UpdatingTooltip className={props.classTooltip} id="popover-contained">{texto}</UpdatingTooltip>
            }
        >
            <a onClick={() => {

                if (props.action)
                    props.action();
            }} style={{ paddingLeft: '10px' }} class='link'><span className={icone}></span> {loading ? 'aguarde...' : (props.textoInline && props.textoInline || 'visualizar')}</a>
        </OverlayTrigger></div>)
}
const LinkVisualizacaoTabelaPequenaPersonalizado = (props) => {
    const { texto, icone } = props
    const [loading, setLoading] = useState(false)


    return (<div style={{ display: 'inline' }}>
        <OverlayTrigger
            popperConfig={{
                modifiers: {
                    preventOverflow: {
                        enabled: false
                    }
                }
            }}
            placement="bottom"
            delay={{ show: 25, hide: 25 }}
            overlay={
                <UpdatingTooltip className={props.classTooltip} id="popover-contained">{texto}</UpdatingTooltip>
            }
        >
            <a href="#" onClick={() => {

                if (props.action)
                    props.action();
            }} style={{ paddingLeft: '10px' }} class='link'><span className={icone}></span> {loading ? 'aguarde...' : (props.textoInline && props.textoInline || 'visualizar')}</a>
        </OverlayTrigger></div>)
}
const LinkVisualizacaoTabelaPequenaPersonalizadoLancamento = (props) => {
    const { texto, icone } = props
    const [loading, setLoading] = useState(false)


    return (<div style={{ display: 'inline' }}>
        <OverlayTrigger
            popperConfig={{
                modifiers: {
                    preventOverflow: {
                        enabled: false
                    }
                }
            }}
            placement="bottom"
            delay={{ show: 25, hide: 25 }}
            overlay={
                <UpdatingTooltip className={props.classTooltip} id="popover-contained">{texto}</UpdatingTooltip>
            }
        >
            <a onClick={() => {

                if (props.action)
                    props.action();
            }} style={{ paddingLeft: '10px' }} class='link'><span className={icone}></span> {loading ? 'aguarde...' : (props.textoInline && props.textoInline || 'visualizar')}</a>
        </OverlayTrigger></div>)
}

const BtnAcaoAlternativa = (props) => {

    const { texto, icone, to } = props
    const classTooltip = props.classTooltip

    return (
        <div className="icone">
            <OverlayTrigger
                popperConfig={{
                    modifiers: {
                        preventOverflow: {
                            enabled: false
                        }
                    }
                }}
                placement="bottom"
                delay={{ show: 25, hide: 25 }}
                overlay={(props) => renderTooltip(props, texto, classTooltip)}>
                <Link to={to} className={"icon-consulta btn-acao " + icone} onClick={() => props.action()} />
            </OverlayTrigger>
        </div>
    )
}
const PopoverPadrao = (props) => {
    return (
        <OverlayTrigger
            trigger={["hover", "focus"]}
            placement={"bottom"}
            onToggle={() => props.onToggle && props.onToggle()}
            target={`popover-positioned-${props.id}`}
            overlay={
                <Popover id={`popover-positioned-${props.id}`}>
                    <Popover.Title as="h3">{props.titulo}</Popover.Title>
                    <Popover.Content>
                        {props.conteudo}
                    </Popover.Content>
                    {props.info}
                </Popover>
            }
        >
            {props.children}
        </OverlayTrigger>
    )
}

const PopoverConsultaLancamento = (props) => {
    return (
        <OverlayTrigger
            trigger={["hover", "focus"]}
            placement={"bottom"}
            onToggle={() => props.onToggle && props.onToggle()}
            target={`popover-positioned-${props.id}`}
            overlay={
                <Popover id={`popover-positioned-${props.id}`}>
                    <Popover.Title as="h3">{props.titulo}</Popover.Title>
                    <Popover.Content>
                        {props.conteudo}
                    </Popover.Content>
                    {props.info}
                </Popover>
            }
        >
            {props.children}
        </OverlayTrigger>
    )
}

const PopoverConsultaLote = (props) => {
    return (
        <OverlayTrigger
            trigger={["hover", "focus"]}
            placement={"bottom"}
            onToggle={() => props.onToggle && props.onToggle()}
            target={`popover-positioned-${props.id}`}
            overlay={
                <Popover id={`popover-positioned-${props.id}`}>
                    <Popover.Title as="h3">{props.titulo}</Popover.Title>
                    <Popover.Content>
                        {props.conteudo}
                    </Popover.Content>
                    {props.info}
                </Popover>
            }
        >
            {props.children}
        </OverlayTrigger>
    )
}

const BtnAcaoReverterExclusao = (props) => {
    const [contador, setContador] = useState(0)
    const { texto, icone, type } = props
    const [hoverRef, isHovered] = useHover();

    useEffect(() => {
        if (!isHovered) {
            setContador(0)
        }
    }, [isHovered])

    return (<div className="icone">

        <OverlayTrigger
            popperConfig={{
                modifiers: {
                    preventOverflow: {
                        enabled: false
                    }
                }
            }}
            placement={"bottom"}
            delay={{ show: 25, hide: 25 }}
            overlay={
                <UpdatingTooltip className={props.classTooltip + (contador === 1 ? " bottom-tooltip" : "")} id="popover-contained">{(contador === 1 ? "Tem certeza que deseja reverter?" : texto)}</UpdatingTooltip>
            }
        >

            <button ref={hoverRef} className={"btn-acao " + (contador == 1 ? " icon-Yes" : icone)} type={type && type || 'submit'} onClick={() => {
                if ((contador + 1) == 2) {
                    props.action();
                    setContador(0);
                }
                setContador(contador + 1);
            }
            } /></OverlayTrigger>

    </div>)
}

const UpdatingPopover = React.forwardRef(
    ({ popper, children, show: _, ...props }, ref) => {
        useEffect(() => {
            popper.scheduleUpdate();
        }, [props.copiado, popper]);

        return (
            <Popover ref={ref} body {...props}>
                {children}
            </Popover>
        );
    },
);

const BtnAcaoConfirmarModal = (props) => {
    const [contador, setContador] = useState(0)
    const { texto, icone, type, mensagemConfirmacao } = props
    const [hoverRef, isHovered] = useHover();

    useEffect(() => {
        if (!isHovered) {
            setContador(0)
        }
    }, [isHovered])

    return (<div className="icone">

        <OverlayTrigger
            popperConfig={{
                modifiers: {
                    preventOverflow: {
                        enabled: false
                    }
                }
            }}
            placement={"bottom"}
            delay={{ show: 25, hide: 999999999999 }}
            overlay={
                <UpdatingTooltip className={props.classTooltip + 'tooltip-input-modaltestr' + (contador === 1 ? " bottom-tooltip" : "")} id="popover-contained-teste">{(contador === 1 ? mensagemConfirmacao || "Tem certeza que deseja excluir?" : texto)}</UpdatingTooltip>
            }
        >

            <button ref={hoverRef} className={"btn-acao-alternativo testetesteste " + (contador == 1 ? " icon-Yes" : icone)} type={type && type || 'submit'} onClick={() => {
                if ((contador + 1) == 2) {
                    props.action();
                    setContador(0);
                }
                setContador(contador + 1);
            }
            } /></OverlayTrigger>

    </div>)
}


const BtnGroup = (props) => <div className="btn-group"> {props.children} </div>
const Alterar = (props) => <BtnAcaoLink icone="icon-Pen" texto="Alterar" to={props.to} target={props.target || 'blank'} />
const AlterarComPermissao = (props) => <BtnAcaoLinkPermissao icone="icon-Pen" texto="Alterar" to={props.to} id={props.id} target={props.target || 'blank'} {...props} />
const Excluir = (props) => <BtnAcaoConfirmar icone="icon-New-Trash" texto="Excluir" action={props.action} {...props} />
const Visualizar = (props) => <BtnAcaoLink icone="icon-File-Search" texto="Visualizar" to={props.to} {...props} />
const Imprimir = (props) => <BtnAcao icone="icon-Printer" texto="Imprimir" action={props.action} loading={props.loading} {...props} />
const Download = (props) => <BtnAcao icone="icon-Download" texto="Download PDF" to={props.to} {...props} />
const Voltar = (props) => <BtnAcao icone="icon-Arrow-Left" texto="Voltar" to={props.to} {...props} />
const Validar = (props) => <BtnAcao icone="icon-Checked-User" texto={props.texto || "Validar"} action={props.action} {...props} />
const Reenvio = (props) => <BtnAcao icone="icon-Mail-Send" texto="Reenviar" action={props.action} {...props} />
const AlteracaoLote = (props) => <BtnAcao icone="icon-Big-Data" texto="Inserir/Alterar Lote" action={props.action} {...props} />
const AlteracaoStatus = (props) => <BtnAcao icone="icon-Check" texto="Alterar Status" action={props.action} {...props} />
const HistoricoStatus = (props) => <BtnAcao icone="icon-Books-2" texto="Visualização Detalhes (Lote/Status) " action={props.action} {...props} />
const LinkExclusaoTabela = (props) => <LinkExclusaoTabelaPequena icone="icon-New-Trash" texto="Excluir" action={props.action} {...props} />
const LinkAlteracaoTabela = (props) => <LinkAlteracaoTabelaPequena icone="icon-Pen" texto="Alterar" action={props.action} {...props} />
const LinkAlteracaoTabelaLocal = (props) => <LinkAlteracaoTabelaPequenaPersonalizado icone="icon-Pen" texto="Alterar" action={props.action} {...props} />
const LinkExclusaoTabelaLocal = (props) => <LinkExclusaoTabelaPequenaPersonalizado icone="icon-New-Trash" texto="Excluir" action={props.action} {...props} /> //aq
const LinkVisualizacaoTabela = (props) => <LinkVisualizacaoTabelaPequena icone="icon-File-Search" texto="Visualizar" action={props.action} {...props} />
const LinkVisualizacaoTabelaLocal = (props) => <LinkVisualizacaoTabelaPequenaPersonalizado icone="icon-File-Search" texto="Visualizar" action={props.action} {...props} />
const LinkVisualizacaoTabelaLocalLancamento = (props) => <LinkVisualizacaoTabelaPequenaPersonalizadoLancamento textoInline={''} icone="icon-File-Search" texto="Visualizar" action={props.action} {...props} />
const LinkExclusaoArrayTabela = (props) => <LinkAlteracaoTabelaPequena icone="icon-New-Trash" textoInline='excluir' texto="Excluir" action={props.action} {...props} />
const LinkAdicionarArrayTabela = (props) => <LinkAlteracaoTabelaPequena icone="icon-Add" textoInline='adicionar' texto="Adicionar" action={props.action} {...props} />
const EnviarNotificacao = (props) => <BtnAcao icone="icon-Mail-Send" texto="Enviar Notificação" action={props.action} {...props} />
const AlterarPersonalizado = (props) => <BtnAcaoAlternativa texto="Alterar" action={props.action} {...props} to={props.to} />
const ReverterExclusao = (props) => <BtnAcaoReverterExclusao icone="icon-Repeat-3" texto="Reverter Exclusão" action={props.action} {...props} />
const LinkBotao = (props) => <BtnAcaoLink icone={props.icone} texto={props.texto} to={props.to} />
const MarcarFolga = (props) => <BtnAcao icone={props.icone} texto={props.texto} to={props.to} action={props.action}  {...props} />
const LinkArquivos = (props) => <BtnAcao icone="icon-Upload-toCloud" texto="Inserir/Alterar Arquivos" action={props.action} {...props} />

//botoes lote guia
const Detalhes = (props) => <BtnAcao icone="icon-Books-2" texto="Detalhes" action={props.action} {...props} />
const AlteracaoStatusLote = (props) => <BtnAcao icone="icon-Check" texto="Alteração de Status com Justificativa" action={props.action} {...props} />
const EnviarLoteCoop = (props) => <BtnAcao icone="icon-Mail-Send" texto={props.texto || "Enviar Lote para COOPANESTGO"} action={props.action} disabled={props.disabled} {...props} />
const VisualizarArquivo = (props) => <BtnAcao icone="icon-File-TXT" texto="Visualizar Arquivo Gerado Pelo Sistema" action={props.action} {...props} />
const VisualizarErro = (props) => <BtnAcao icone="icon-Warning-Window" texto="Visualizar Erro" action={props.action} {...props} />
const VisualizarRelacaoGuias = (props) => <BtnAcao icone="icon-File-Search" texto="Visualizar Relação Guia(s)" action={props.action} {...props} />
const VisualizarGuiasMapaHora = (props) => <BtnAcao icone="icon-File-Search" texto="Visualizar Guia(s) no Mapa Horas" action={props.action} {...props} />
const RelatorioLote = (props) => <BtnAcao icone="icon-File-ClipboardFileText" texto="Relatório de Lotes" to={props.to} action={props.action} {...props} />
const RemoverGuias = (props) => <BtnAcaoConfirmar icone="icon-Remove" texto="Remover Guias" to={props.to} action={props.action} {...props} mensagemConfirmacao='Tem certeza que deseja Remover Guais do Lote?' />
const RemoverLote = (props) => <BtnAcaoConfirmar icone="icon-Remove" texto="Remover Lote" to={props.to} action={props.action} {...props} mensagemConfirmacao='Tem certeza que deseja Remover o Lote?' />
const AdicionarGuiaLote = (props) => <BtnAcao icone="icon-File-TextImage" texto="Adicionar Guia" to={props.to} action={props.action} {...props} />
const VisualizarPagamento = (props) => <BtnAcao icone="icon-Receipt-3" texto="Visualizar Pagamento" action={props.action} {...props} />

const AlterarSocioGuia = (props) => <BtnAcao icone={"icon-Doctor"} texto='Alterar Sócio Guia' to={props.to} action={props.action} {...props} />

//
const MinimizarDados = (props) => <BtnAcao icone={"icon-Eye-Visible " + props.className} texto={props.texto} action={props.action} {...props} />
const GuiaComplementar = (props) => <BtnAcaoAlternativa icone={"icon-Retweet"} texto='Guia Complementar' to={props.to} action={props.action} {...props} />
const Conferencia = (props) => <BtnAcao icone={"icon-Repair"} texto='Conferência' to={props.to} action={props.action} {...props} />
const RemoverGuiaComplementar = (props) => <BtnAcaoAlternativa icone={"icon-Remove"} texto='Remover Guia Complementar' to={props.to} action={props.action} {...props} />

// ações movimento financeiro
const DetalhesMovimentoFinanceiro = (props) => <BtnAcaoAlternativa icone="icon-Books-2" texto="Detalhes" to={props.to} action={props.action} {...props} />
const AlterarMovimentoFinanceiro = (props) => <BtnAcaoAlternativa icone="icon-Pen" texto="Alterar" to={props.to} action={props.action} {...props} />
const FechamentoMovimentoFinanceiro = (props) => <BtnAcaoAlternativa icone="icon-Check" texto="Fechamento/Status" to={props.to} action={props.action} {...props} />
// const AlteracaoStatusMovimento = (props) => <BtnAcaoAlternativa icone={"icon-Check"} texto='Alteração de Status Movimento Financeiro' to={props.to} action={props.action} {...props} />
const VisualizacaoComAssinatura = (props) => <BtnAcaoLink icone="icon-File-Edit" texto="Visualização com Assinatura" to={props.to} {...props} />
const LançamentoFinanceiro = (props) => <BtnAcaoAlternativa icone="icon-Receipt-3" texto="Lançamento" to={props.to} action={props.action} {...props} />

//Ações Nota Fiscal
const AlterarNota = (props) => <BtnAcao icone="icon-Pen" texto="Alterar" to={props.to} action={props.action} {...props} />
const TrocarStatusNota = (props) => <BtnAcaoAlternativa icone="icon-Arrow-Around" texto="Trocar Status Nota" to={props.to} action={props.action} {...props} />
const TrocarStatusEnvio = (props) => <BtnAcaoAlternativa icone="icon-Arrow-Around" texto="Trocar Status Envio" to={props.to} action={props.action} {...props} />
const EnviarNota = (props) => <BtnAcaoAlternativa icone="icon-Receipt-3" texto="Enviar Nota" to={props.to} action={props.action} {...props} />
const EnviarNotaEmail = (props) => <BtnAcaoAlternativa icone="icon-Email-Send" texto="Enviar Nota Email" to={props.to} action={props.action} {...props} />
const AtualizarStatusNota = (props) => <BtnAcaoAlternativa icone="icon-Refresh" texto="Atualizar Status Nota" to={props.to} action={props.action} {...props} />
const CancelarNota = (props) => <BtnAcaoAlternativa icone="icon-Delete-Window" texto="Cancelar Nota" to={props.to} action={props.action} {...props} />
const SubstituirNota = (props) => <BtnAcaoAlternativa icone="icon-Arrow-Around" texto="Substituir Nota" to={props.to} action={props.action} {...props} />
const VisualizarErroNota = (props) => <BtnAcaoAlternativa icone="icon-Magnifi-Glass2" texto="Visualizar Erros Nota" to={props.to} action={props.action} {...props} />
const VisualizarNotaFiscal = (props) => <BtnAcaoAlternativa icone="icon-File-Search" texto="Visualizar" to={props.to} action={props.action}{...props} />

// Ações lançamento 
const LiquidacaoLancamento = (props) => <BtnAcaoAlternativa icone="icon-Cash-Register" texto="Liquidar" to={props.to} action={props.action} {...props} />
const RemoverLiquidacao = (props) => <BtnAcaoAlternativa icone="icon-Remove" texto="Remover Liquidação" action={props.action} {...props} />
const AlterarLancamentoFinanceiro = (props) => <BtnAcaoAlternativa icone="icon-Pen" texto="Alterar" to={props.to} action={props.action} {...props} />
const CancelarLancamento = (props) => <BtnAcaoAlternativa icone="icon-Close" texto="Cancelar Lançamento" to={props.to} action={props.action} {...props} />
const AdicionarMovimento = (props) => <BtnAcaoAlternativa icone="icon-Receipt" texto="Adicionar Movimento" to={props.to} action={props.action} {...props} />
const AdicionarCheque = (props) => <BtnAcaoAlternativa icone="icon-File-HorizontalText" texto="Adicionar Cheque" to={props.to} action={props.action} {...props} />
const AdicionarGuia = (props) => <BtnAcaoAlternativa icone="icon-File-TextImage" texto="Adicionar Atendimento(Guia)" to={props.to} action={props.action} {...props} />
const VisualizarNota = (props) => <BtnAcaoAlternativa icone="icon-Receipt-3" texto="Visualizar" to={props.to} action={props.action} {...props} />
const EmitirRecibo = (props) => <BtnAcaoAlternativa icone="icon-Receipt-3" texto="Recibo" to={props.to} action={props.action} {...props} />
const ExportarLancamento = (props) => <BtnAcaoAlternativa icone={"icon-Retweet"} texto='Exportar Lançamento' to={props.to} action={props.action} {...props} />

// Ações Rascunho
const RecuperarRascunho = (props) => <BtnAcaoReverterExclusao icone="icon-Data-Backup" texto="Recuperar Rascunho" action={props.action} {...props} />
const VersaoRascunho = (props) => <BtnAcao icone={"icon-Files"} texto='Versões Rascunho' to={props.to} action={props.action} {...props} />
const RecuperarVersaoRascunho = (props) => <BtnAcao icone={"icon-Data-Backup"} texto='Recuperar Versão Rascunho' to={props.to} action={props.action} {...props} />

//Liberação Férias
const AlterarLiberacaoFerias = (props) => <BtnAcaoAlternativa icone="icon-Pen" texto="Alterar" to={props.to} action={props.action} {...props} />


export {
    BtnGroup, BtnAcao, Alterar, Excluir, Visualizar, Imprimir, Download, BtnAcaoLink, Voltar, Validar, Reenvio, Btn, LinkExclusaoTabela, LinkAlteracaoTabela, LinkExclusaoArrayTabela, PopoverPadrao, EnviarNotificacao, AlterarPersonalizado, LinkBotao,
    ReverterExclusao, AlteracaoLote, AlteracaoStatus, HistoricoStatus, Detalhes, AlteracaoStatusLote, EnviarLoteCoop, VisualizarArquivo, VisualizarErro, VisualizarRelacaoGuias, MinimizarDados, GuiaComplementar, RemoverGuiaComplementar,
    FechamentoMovimentoFinanceiro, LinkVisualizacaoTabela, DetalhesMovimentoFinanceiro, VisualizacaoComAssinatura, LançamentoFinanceiro, AlterarMovimentoFinanceiro, LiquidacaoLancamento, RemoverLiquidacao, AlterarLancamentoFinanceiro, PopoverConsultaLancamento,
    LinkExclusaoTabelaLocal, LinkExclusaoTabelaPequenaPersonalizado, LinkAlteracaoTabelaLocal, LinkVisualizacaoTabelaLocal, CancelarLancamento, LinkVisualizacaoTabelaLocalLancamento, AdicionarMovimento, AdicionarCheque, BtnAcaoAlternativa,
    RelatorioLote, MarcarFolga, Conferencia, BtnAcaoConfirmar, AdicionarGuia, LinkArquivos, VisualizarGuiasMapaHora, RecuperarRascunho, RemoverGuias, RemoverLote, AlterarComPermissao, PopoverConsultaLote, VersaoRascunho, RecuperarVersaoRascunho,
    AdicionarGuiaLote, BtnAcaoConfirmarModal, AlterarSocioGuia, LinkAdicionarArrayTabela, TrocarStatusNota, TrocarStatusEnvio, AlterarNota, EnviarNota, CancelarNota, VisualizarErroNota, SubstituirNota, VisualizarNota, AtualizarStatusNota,
    VisualizarPagamento, EmitirRecibo, EnviarNotaEmail, VisualizarNotaFiscal, ExportarLancamento, AlterarLiberacaoFerias
}

