import React, { useState, useRef } from 'react'
import { InputSexo, InputEstado, InputSimNao, InputTipoDocumento, InputPais, InputParentesco, InputMunicipio } from '../../../../components/InputsAutoComplete'
import { HeaderCadastro } from '../../../../components/Headers'
import { ModalCadastro } from '../../../../components/Modais'
import { Formik, Form } from 'formik'
import { InputPadrao, InputDatePicker } from '../../../../components/Inputs'
import { BotaoPadrao } from '../../../../components/Botoes'
import Yup, { validaCPF, validaData, periodoData, dataMenorAtual } from '../../../../util/Validacoes'
import { removeAcentos } from '../../../../util/FuncoesComuns'
import Atalho from '../../../../util/Atalho'

const Cadastro = props => {
    const [pesquisa, setPesquisa] = useState('')
    const [salvando, setSalvando] = useState(false)
    
    const [msgBotao, setMsgBotao] = useState('Adicionar')
    const emptyValues = {
        id: null,
        nome: '',
        data_nascimento: null,
        cpf: '',
        sexo: '',
        parentesco: '',
        numero_documento: '',
        data_expedicao: null,
        pis: '',
        irf: '',
        salario_familia: '',
        incapacidade_fisica: '',
        inicio_dependencia: null,
        termino_dependencia: null,
        tipo_documento: '',
        estado: '',
        pais_emissor: '',
        pais_nacionalidade: {
            value: 1703,
            label: "Brasil"
        },
        naturalidade_codigo_ibge: ''
    }

    const inputRef = useRef(null);
    const [values, setValues] = useState(emptyValues)
    const Submit = (dependente, { resetForm }) => {

        let copiaDependentes = [...props.dependentes]

        if (dependente.verificador) {
            let index = copiaDependentes.findIndex(x => x.verificador === dependente.verificador)
            copiaDependentes[index] = dependente
        }
        else {
            dependente.verificador = Math.random()
            copiaDependentes.push(dependente)
        }

        props.setDependentes(copiaDependentes)
        resetForm({})

        if (msgBotao === 'Alterar')
            props.setAbrirModal(!props.abrirModal)
    }
    const Delete = index => {
        let copiaDependentes = [...props.dependentes]
        copiaDependentes.splice(index, 1);
        props.setDependentes(copiaDependentes)
    }

    return (
        <>
            <HeaderCadastro
                titulo={'Dependentes'}
                pesquisa={true}
                valorPesquisa={pesquisa}
                onChangePesquisa={pesquisa => setPesquisa(pesquisa)}
                classeChildren='space-between-100' >
                <BotaoPadrao
                    type='button'
                    click={_ => {
                        setValues(emptyValues)
                        props.setAbrirModal(!props.abrirModal);
                        setMsgBotao('Adicionar')
                    }}
                    classe='btn-primary primary p-5rem height-auto m-r-15'
                    texto={<><span> Adicionar</span> <span class='icon-Users fw'>+</span></>} />
            </HeaderCadastro>
            <div className='card mb-20'>
                <div className='card-body'>
                    <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                        <thead>
                            <tr>
                                <th style={{ width: '5%' }}>#</th>
                                <th style={{ width: '40%' }}>Nome</th>
                                <th style={{ width: '40%' }}>Parentesco</th>
                                <th style={{ width: '15%' }} className='text-center'>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.dependentes.length ?
                                props.dependentes.filter(x => removeAcentos(x.nome.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase()))
                                    || removeAcentos(x.parentesco.label.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())))
                                    .map((dependente, index) =>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{dependente.nome}</td>
                                            <td>{dependente.parentesco ? dependente.parentesco.label : ""}</td>
                                            <td className='text-center'>
                                                <span className='icon-consulta icon-Pen'
                                                    onClick={_ => { setValues(dependente); props.setAbrirModal(true); setMsgBotao('Alterar') }} />
                                                <span className='icon-consulta icon-Column-2' onClick={target => Delete(index)} />
                                            </td>
                                        </tr>
                                    ) : <tr><td colspan='6' className='text-center'>Nenhum Registro Cadastrado</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <ModalCadastro titulo={'Dependente'} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    enableReinitialize={true}
                    initialValues={values}
                    validationSchema={Yup.object({
                        nome: Yup.string().trim().ensure().required(),
                        data_nascimento: Yup.string().nullable().test('Data', 'Data inválida!', function (value) { return validaData(this.parent.data_nascimento) })
                            .test('Data Nascimento', 'Data deve ser menor ou igual a Data Atual!', function (value) { return dataMenorAtual(this.parent.data_nascimento) }),
                        cpf: Yup.string().test('CPF', 'CPF inválido!', function (value) { return validaCPF(this.parent.cpf) }).nullable(),
                        sexo: Yup.string().nullable(),
                        parentesco: Yup.string().nullable().required(),
                        numero_documento: Yup.string().ensure(),
                        data_expedicao: Yup.string().nullable().test('Data', 'Data inválida!', function (value) { return validaData(this.parent.data_expedicao) })
                            .test('Data Expedição', 'Data deve ser menor ou igual a Data Atual!', function (value) { return dataMenorAtual(this.parent.data_expedicao) }),
                        pis: Yup.string().ensure(),
                        irf: Yup.string().nullable(),
                        salario_familia: Yup.string().nullable(),
                        incapacidade_fisica: Yup.string().nullable(),
                        inicio_dependencia: Yup.string().nullable().test('Início Dependencia', 'Data inválida!', function (value) { return validaData(this.parent.inicio_dependencia) })
                            .test('Início Dependencia', 'Início Dependencia deve ser anterior ao Término Dependencia.', function (value) { return periodoData(value, this.parent.termino_dependencia) }),
                        termino_dependencia: Yup.string().nullable().test('Término Dependencia', 'Data inválida!', function (value) { return validaData(this.parent.termino_dependencia) })
                            .test('Término Dependencia', 'Início Dependencia deve ser anterior ao Término Dependencia.', function (value) { return periodoData(this.parent.inicio_dependencia, value) }),
                        tipo_documento: Yup.string().nullable(),
                        estado: Yup.string().nullable(),
                        pais_emissor: Yup.string().nullable(),
                        pais_nacionalidade: Yup.string().nullable(),
                        naturalidade_codigo_ibge: Yup.string().nullable()
                    })}
                    onSubmit={Submit}>
                    {propsFormik => <Form>
                        <div className='modal-body-cadastro'>
                            <InputPadrao label='Id' name='id' type='hidden' />
                            <InputPadrao label='Nome' name='nome' type='text' obrigatorio={true} classeTooltip={props.modal && 'tooltip-input-modal'} autoFocus />
                            <InputDatePicker label="Data Nascimento" name="data_nascimento" type="text" setarValor={value => { propsFormik.setFieldValue("data_nascimento", value) }}
                                placeholder="Data Nascimento" classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputParentesco label="Parentesco" name="parentesco" placeholder="Parentesco" obrigatorio={true} classeTooltip={props.modal && 'tooltip-input-modal'} propsFormik={propsFormik} />
                            <InputSexo label="Sexo" name="sexo" placeholder="Sexo" propsFormik={propsFormik} />
                            <InputPadrao mascara="111.111.111-11" label="CPF" name="cpf" type="text" classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputTipoDocumento label="Tipo Documento" name="tipo_documento" placeholder="Tipo Documento" propsFormik={propsFormik} />
                            <InputPadrao label='Número Documento' name='numero_documento' type='text' classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputEstado label="UF" name="estado" placeholder="Estado" propsFormik={propsFormik} />
                            <InputDatePicker label="Data Expedição" classeTooltip={props.modal && 'tooltip-input-modal'} name="data_expedicao" type="text" setarValor={value => { propsFormik.setFieldValue("data_expedicao", value) }}
                                placeholder="Data Expedição" />
                            <InputPais label="País Emissor" name="pais_emissor" placeholder="País Emissor" propsFormik={propsFormik} />
                            <InputPais label="Nacionalidade" name="pais_nacionalidade" placeholder="Nacionalidade" propsFormik={propsFormik} />
                            {propsFormik.values.pais_nacionalidade && propsFormik.values.pais_nacionalidade.value == 36 ? <InputMunicipio label='Naturalidade' name='naturalidade_codigo_ibge' placeholder='Naturalidade' propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} /> : <></>}
                            <InputPadrao label='PIS' name='pis' type='text' classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSimNao label='IRF' placeholder="Selecione" name='irf' propsFormik={propsFormik} type='text' classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSimNao label='Salário Família' placeholder="Selecione" name='salario_familia' propsFormik={propsFormik} type='text' classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSimNao label='Incapacidade Física' placeholder="Selecione" name='incapacidade_fisica' propsFormik={propsFormik} type='text' />
                            <InputDatePicker label="Início Dependencia" name="inicio_dependencia" type="text" setarValor={value => { propsFormik.setFieldValue("inicio_dependencia", value) }}
                                placeholder="Início Dependencia" classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputDatePicker label="Termino Dependencia" name="termino_dependencia" type="text" setarValor={value => { propsFormik.setFieldValue("termino_dependencia", value) }}
                                placeholder="Termino Dependencia" classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <Atalho formik={propsFormik} salvando={salvando} />

                        </div>
                        <div className='direita container-botoes'>
                            <BotaoPadrao texto={msgBotao} />
                        </div>
                    </Form>}
                </Formik>
            </ModalCadastro>
        </>)
}

export default Cadastro