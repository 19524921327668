import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { formatarMoeda } from '../../../../util/Utilitarios';

export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/faturamento/porteanestesicoparticular', id,
            obj => {
                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Porte Anestésico Particular',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Ano', descricao: obj.ano, tamanho: 'col-lg-4' },
                                { titulo: 'Inicio', descricao: obj.inicio, tamanho: 'col-lg-4' },
                                { titulo: 'Fim', descricao: obj.fim, tamanho: 'col-lg-4' },
                                { titulo: 'Data Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                            ]
                        }, {
                            tipoGrupo: 'tabela',
                            titulo: 'Tabela(s)',
                            visivel: obj.tabelas.length > 0,
                            thead:
                                <tr>
                                    <td className='coluna-3'>Código</td>
                                    <td className='coluna-1'>Tabela</td>
                                </tr>,
                            tbody: obj.tabelas == undefined || obj.tabelas.length < 1 || obj.tabelas.filter(x => x.id !== null).length === 0 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.tabelas.map(x => {
                                    return (
                                        <tr>
                                            <td>{x.id}</td>
                                            <td>{x.descricao}</td>
                                        </tr>)
                                })
                        }
                    ].concat(obj.acomodacoes.length && obj.acomodacoes.map(x => ({
                        tipoGrupo: 'tabela',
                        titulo: 'Acomodação - ' + x.acomodacao,
                        visivel: x.portes.length > 0,
                        thead:
                            <tr>
                                <td className='coluna-1'>Porte</td>
                                <td className='coluna-1'>Valor Porte</td>
                                <td className='coluna-1'>Urgência</td>
                            </tr>,
                        tbody:
                            x.portes.map(y => {
                                return (
                                    <tr>
                                        <td >{y.porte}</td>
                                        <td >{formatarMoeda(y.valor_porte)}</td>
                                        <td >{y.urgencia + '%'}</td>
                                    </tr>)
                            })
                    })))
                } : { titulo: 'Visualização Porte Anestésico Particular' })
            }
        )
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}