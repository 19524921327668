import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../components/VisualizacaoPadrao'
import { visualizar } from '../../../util/AcessoApi'
import { obterDataFormatada } from '../../../util/Utilitarios'
import { ordenarArrayObj } from '../../../util/FuncoesComuns'


const VisualizacaoUsuario = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})


    useEffect(() => {
        if (id) {
            visualizar(`/administracao/liberacaoferias`, id, obj => {
                console.log('obj: ', obj);
                setDados(obj)
            })
        }
    }, [id])


    const data = dados ? {
        id,
        modulo: props.modulo,
        titulo: 'Visualização Liberação Férias',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Código',
                        descricao: dados.id,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Sócio',
                        descricao: dados.socio?.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Período Férias',
                        descricao: dados.periodo?.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Código/Token',
                        htmlDireto: (
                            <div className="guia-legenda">
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        color: dados.token ? '#609eff' : 'inherit',
                                    }}
                                >
                                    {dados.token || '---'}
                                </span>
                            </div>
                        ),
                        tamanho: 'col-lg-6'
                    },

                    {
                        titulo: 'Data Início',
                        descricao: dados.data_inicio,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data Término',
                        descricao: dados.data_termino,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Justificativa',
                        descricao: dados.justificativa,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Usuário Escalador',
                        descricao: dados.usuario?.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data Registro',
                        descricao: dados.data_registro_formatada,
                        tamanho: 'col-lg-6'
                    }
                ]
            }
        ]
    } : { titulo: 'Visualização Liberação Férias' };

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    );
}

export default VisualizacaoUsuario