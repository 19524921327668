/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import moment from "moment"
import 'moment/locale/pt-br'

Yup.setLocale({
  mixed: {
    default: 'é inválido',
    required: 'Campo obrigatório!',
    oneOf: 'deve ser um dos seguintes valores: ${values}',
    notOneOf: 'não pode ser um dos seguintes valores: ${values}'
  },
  string: {
    email: 'E-mail inválido!',
    min: 'Tamanho mínimo de ${min} caracteres',
    max: 'Tamanho máximo de ${max} caracteres'
  },
  number: {
    min: 'Valor inválido (deve ser maior ou igual a ${min})',
    max: 'Valor inválido (deve ser menor ou igual a ${max})'
  },
  date: {
    typeError: 'teste',
    date: '${path} é inválida',
    required: '${path} é inválida',
    oneOf: '${path} é inválida',
    min: '${path} deve ser maior que a data ${min}',
    max: '${path} deve ser menor que a data ${max}',
  },
});


const validaData = (data) => {
  moment.locale('pt-br')

  if (data == null || data.toString().length === 0) {
    return true
  }

  if (moment.isMoment(data)) {
    return data.isValid()
  }
  else {
    if (data && data.toString().replaceAll('_', '').length < 10)
      return false
    let obj = null;
    if (data.toString().length === 10)
      obj = moment(data, 'DD/MM/YYYY');
    else
      obj = moment(data);

    return obj.isValid()
  }
}

const validaDateTime = (data) => {
  console.log('data: ', data);
  moment.locale('pt-br')

  if (data == null || data.toString().length === 0 || data === undefined) {
    return true
  }

  if (moment.isMoment(data)) {
    return data.isValid()
  }
  else {
    if (data && data.toString().length < 16)
      return false
    let obj = null;
    if (data.toString().length === 16)
      obj = moment(data, 'DD/MM/YYYY HH:mm');
    else
      obj = moment(data);

    return obj.isValid()
  }
}

const validaConjuge = (props) => {

  var keys = Object.keys(props)
  var camposConjuge = keys.filter(x => x.includes('conjuge')).filter(x => x !== 'telefone_conjuge')
  let array = []

  for (let i in camposConjuge)
    array.push(props[camposConjuge[i]])

  var retorno = true

  for (let i in array) {

    if (array[i] !== undefined)
      retorno = false
    else
      retorno = true
  }
  return retorno

}


const validaHora = (hora) => {
  const h = hora;
  moment.locale('pt-br')

  if (hora == null || hora.toString().length === 0) {
    return true
  }

  hora = "01/01/0001 " + hora
  let obj = moment(hora, 'DD/MM/YYYY HH:mm');

  return obj.isValid() && !h.includes('24:00')
}

const dataMenorAtual = (data) => {
  moment.locale('pt-br')


  if (data == null || data.toString().length == 0) {
    return true
  }

  if (moment.isMoment(data)) {
    if (data < moment())
      return true

    return false;
  }
  else {
    if (data && data.toString().length < 10)
      return true
    let obj = null
    if (data.toString().length === 10)
      obj = moment(data, 'DD/MM/YYYY');
    else
      obj = moment(data);

    if (obj < moment())
      return true

    return false
  }
}

const dataMesmaSemanaMes = (data1, data2) => {
  if (data1 == null || data1.toString().length == 0
    || data2 == null || data2.toString().length == 0) {
    return false
  }

  const data1M = moment(data1, 'DD/MM/YYYY');
  const data2M = moment(data2, 'DD/MM/YYYY');

  return (data1M.week() === data2M.week()) && (data1M.month() === data2M.month())
}

const periodoData = (dataInicial, dataFinal) => {
  if (dataInicial == null || dataInicial.toString().length == 0
    || dataFinal == null || dataFinal.toString().length == 0) {
    return true
  }

  if (validaData(dataInicial) && validaData(dataFinal)) {
    let dtInicial = moment(dataInicial, 'DD/MM/YYYY').toDate();
    let dtFinal = moment(dataFinal, 'DD/MM/YYYY').toDate();
    return dtInicial <= dtFinal
  }
  else
    return true
}

const periodoHora = (horaFinal, horaInicial) => {
  if (horaInicial == null || horaInicial.toString().length == 0
    || horaFinal == null || horaFinal.toString().length == 0) {
    return true
  }
  if (validaHora(horaInicial) && validaHora(horaFinal)) {
    let diferenca = moment(horaInicial, 'HH:mm').diff(moment(horaFinal, 'HH:mm'), 'minutes')
    return diferenca <= 0
  }
  else
    return true

}

const periodoDateTime = (dataInicial, dataFinal) => {
  if (dataInicial == null || dataInicial.toString().length == 0
    || dataFinal == null || dataFinal.toString().length == 0) {
    return true
  }

  if (validaData(dataInicial) && validaData(dataFinal)) {
    let dtInicial = moment(dataInicial, 'DD/MM/YYYY HH:mm').toDate();
    let dtFinal = moment(dataFinal, 'DD/MM/YYYY HH:mm').toDate();
    return dtInicial <= dtFinal
  }
  else
    return true
}

const validaInteiro = data => {
  const value = parseInt(data)
  var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  if (!data || (value == data && !format.test(data)))
    return true

  return false
}

const validaCNPJ = (cnpj) => {
  if (!cnpj || cnpj.length == 0) {
    return false
  }

  cnpj = cnpj.replace(/[^\d]+/g, '');


  if (cnpj == '') return false;

  if (cnpj.length != 14)
    return false;


  if (cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999")
    return false;


  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(0)) return false;
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1))
    return false;

  return true;
}

function validaCPF(strCPF) {

  if (strCPF == undefined || strCPF.toString() == "")
    return true;

  strCPF = strCPF.toString();
  strCPF = strCPF.replace(/[^0-9]+/g, '')

  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF == "00000000000" || strCPF == "11111111111") return false;


  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto == 10) || (Resto == 11)) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto == 10) || (Resto == 11)) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

function validaEspaco(value = '') {
  if (value.split(' ').length > 1)
    return false

  return true
}

function validaLancamento(fields, lancamentos) {
  let keys = Object.keys(fields)
  keys = keys.filter(key => key === 'cliente' || key === 'tipo_lancamento' || key === 'categoria' || key === 'previsao_vencimento')
  let entries = Object.entries(fields)
  // let teste = Object.entries(fields).map((x,i) => keys.map(y => x[y][i]))
  // console.log('TSTEE ',teste)
  let teste = entries.map(([key, val]) => {
    return [key, val];
  });
  if (!lancamentos) {
    let campos_preencher = fields.liquidacao.filter(x => x.modo_liquidacao)
    if (campos_preencher.length)
      return false
  }


  return true
}


export default Yup
export {
  validaCNPJ, validaCPF, validaData, validaInteiro, periodoData, dataMenorAtual, validaHora, dataMesmaSemanaMes, validaConjuge, validaDateTime, periodoDateTime, validaEspaco, periodoHora,
  validaLancamento
}

