import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import '../assets/css/icones/iconsmind/style.css';
import '../assets/css/icones/simple-line-icons/css/simple-line-icons.css';

const SubMenu = (props) => {
    const [menu, setMenu] = useState([])

    useEffect(() => {
        setMenu(props.data.map(item => ({ fechado: !(item.submenu && item.submenu.filter(item => item.caminho === (props.children.props.active ? props.children.props.active : props.children.props.path)).length > 0), ...item })))
    }, [props.data])

    const toogleFechado = (item, idx, valor) => {
        let novoItem = item
        novoItem.fechado = valor ? valor : !novoItem.fechado
        let cloneMenu = [...menu]
        cloneMenu[idx] = novoItem
        setMenu(cloneMenu)
    }

    if (menu) {
        return (
            <ul className="nav table-wrapper-scroll-y custom-scrollbar sub-menu" >
                {
                    menu.map((subItem, subIndex) => {
                        return (<li className={"nav-item sub-item-menu " + (subItem.permitido == undefined || subItem.permitido == true ? "" : "oculto") + (subItem.submenu && subItem.submenu.length > 0 ? " list-submenu" : "")} key={subIndex} >
                            {
                                subItem.submenu && subItem.submenu.length > 0 ?
                                    <>
                                        <div className={"rotate-arrow-icon " + (subItem.fechado ? " collapsed" : "")} onClick={() => toogleFechado(subItem, subIndex)}>
                                            <span className={"icone-menu " + (subItem.icone ? subItem.icone : " icon-Arrow-Down2")} ></span>
                                            <span className="sub-item-titulo">{subItem.nome}</span>
                                        </div>
                                        <div className={"rotate-arrow-icon " + (subItem.fechado ? " collapsed" : "")}>
                                            <div className={"collapse " + (subItem.fechado ? "" : "show")}>
                                                <ul className="nav table-wrapper-scroll-y custom-scrollbar sub-menu" >
                                                    {
                                                        subItem.submenu.map((item, idx) => {
                                                            return (
                                                                <li className="nav-item sub-item-menu" key={idx} >
                                                                    <Link className={"nav-link" + (item.caminho === (props.children.props.active ? props.children.props.active : props.children.props.path) ? ' sub-active' : '')} to={item.caminho} onClick={() => props.onClick()}>
                                                                        <span className={"icone-menu " + item.icone}></span>
                                                                        <span className="sub-item-titulo">{item.nome}</span>
                                                                    </Link>
                                                                </li>)
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <Link className={"nav-link" + (subItem.caminho === (props.children.props.active ? props.children.props.active : props.children.props.path) ? ' sub-active' : '')} to={subItem.caminho} onClick={() => props.onClick()}>
                                        <span className={"icone-menu " + subItem.icone}></span>
                                        <span className="sub-item-titulo">{subItem.nome}</span>
                                    </Link>
                            }
                        </li>)
                    }
                    )
                }
            </ul >
        )
    } else {

        return (<> </>)
    }


}

export { SubMenu } 