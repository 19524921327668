import React, { useState, useEffect } from 'react'
import { ConsultaRelatorio } from '../../components/RelatorioPadrao'
import { FiltroDataGrid } from '../../components/Filtragens'
import { BotaoPadrao } from '../../components/Botoes'
import { mostrarToast } from '../../components/Toasts'
import { Formik, Form } from "formik";
import { pesquisarDataGrid, listarDataGrid } from '../../util/AcessoApi'

export default (props) => {
    const [dados, setDados] = useState([])
    const [dadosFiltrado, setDadosFiltrado] = useState([])
    const [filtros, setFiltros] = useState([])
    const [filtrosObjs, setFiltrosObjs] = useState([])
    const [atualizar, setAtualizar] = useState(true)
    const [newProps, setNewProps] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [titulo, setTitulo] = useState([])
    const [idFiltroColuna, setIdFiltroColuna] = useState([])
    const [carregando, setCarregando] = useState(true)
    const [carregandoFiltros, setCarregandoFiltros] = useState(true)

    useEffect(() => {
        if (atualizar) {
            if (!props.location.listagem || !props.location.listagem.caminho)
                return

            let clone = { ...props.location.listagem };
            setIdFiltroColuna(props.location.listagem.filtrosBanco)
            setCarregandoFiltros(false)
            setValoresIniciais(props.location.listagem.filtrosValoresIniciais || {})
            setFiltros(props.location.listagem.filtros)
            setFiltrosObjs(props.location.listagem.filtrosObj || [])
            setTitulo(props.location.listagem.titulo || [])
            setAtualizar(false)
            delete clone.dados
            delete clone.filtros
            delete clone.filtrosObj
            setNewProps(clone)
        }
    }, [props])

    useEffect(() => {
        if (!props.location.listagem || !props.location.listagem.caminho) {
            props.history.goBack();
            return;
        }
    }, [])

    const pesquisarImpressao = filtrosImp => {
        const filtros = filtrosImp.filter(x => x.idFiltro).filter(x => !(x.value.length === 0)).filter(x => x.value.constructor.name !== 'SyntheticEvent')

        setCarregando(true)
        listarDataGrid(props.location.listagem.caminho + `/0`,
            (e) => {
                let novosDados = [...props.location.listagem.dados][0];
                if (e.length > 0) {
                    let qtd = e[e.length - 1];
                    if (parseInt(qtd.qtd) > 10000) {
                        mostrarToast('alerta', <><b>Grande quantidade de registros!</b><br /> Lista limitada a 10.000 registros. Utilize filtros.</>);
                    }
                }
                e.pop();
                novosDados.data = e
                setDados([{ ...novosDados }]);

                setTimeout(() => { setCarregando(false) }, e.length > 0 ? e.length / 10 : 100)
            }, () => {
                setCarregando(false)
            }, valoresIniciais.pesquisa || '', [], filtros, [], 10000)
    }

    const setNovoFiltroRelatorio = (coluna, valor) => {
        var valueFiltro

        if (valor && typeof valor === 'object' && (valor.data || valor.data_inicial || valor.data_final) && !coluna.filtro)
            valueFiltro = valor[coluna.tipo]
        else if (valor && typeof valor === 'object' && (valor.data || valor.data_inicial || valor.data_final) && coluna.filtro)
            valueFiltro = valor[coluna.filtro.tipo]
        else if (valor && valor.length > 0 && typeof valor !== 'string')
            valueFiltro = valor.map(x => x.value)
        else if (valor && typeof valor !== 'string')
            valueFiltro = valor
        else if (typeof valor === 'string')
            valueFiltro = valor
        else
            valueFiltro = ''

        var idFiltro = [...idFiltroColuna]

        if (!idFiltroColuna.find(x => x.idFiltro === coluna.idColuna)) {
            idFiltro.push({ idFiltro: coluna.idColuna, value: valueFiltro })
            setIdFiltroColuna(idFiltro)
        }
        else {

            if (idFiltroColuna.find(x => x.idFiltro === coluna.idColuna)) {
                const index = idFiltroColuna.findIndex(x => x.idFiltro === coluna.idColuna)
                idFiltro[index] = { idFiltro: coluna.idColuna, value: valueFiltro.target && valueFiltro.target.value || valueFiltro }
                setIdFiltroColuna(idFiltro)
            }
            else {
                idFiltro.push({ idFiltro: coluna.idColuna, value: valueFiltro })
                setIdFiltroColuna(idFiltro)
            }
        }
    }

    useEffect(() => {
        if (dados.length > 0)
            formatarDados(dados)
    }, [dados])

    useEffect(() => {
        let filtrosUtilizados = []

        valoresIniciais && Object.keys(valoresIniciais).map(item => {
            if (valoresIniciais[item]) {
                const filtroAtual = item === 'pesquisa'
                    ? filtrosObjs.find(filtro => filtro.nome === item)
                    : filtrosObjs.find(filtro => {
                        if (filtro.colunaID && filtro.colunaID.name)
                            return filtro.colunaID.name === item
                        else
                            return filtro.tipo === item
                    })

                switch (typeof valoresIniciais[item]) {
                    case 'object':
                        if (valoresIniciais[item].length)
                            filtrosUtilizados.push({ filtro: filtroAtual.titulo, dado: valoresIniciais[item].map(item => item.label).join(', ') })
                        else if (valoresIniciais[item].label)
                            filtrosUtilizados.push({ filtro: filtroAtual.titulo, dado: valoresIniciais[item].label })
                        break
                    default:
                        filtrosUtilizados.push({ filtro: filtroAtual.titulo, dado: valoresIniciais[item] })
                }
            }
        })

        setFiltros(filtrosUtilizados)

        if (!carregandoFiltros && props.location.listagem && props.location.listagem.caminho)
            pesquisarImpressao(idFiltroColuna)

    }, [valoresIniciais])

    const filtrar = values => setValoresIniciais(values)

    const formatarDados = lista => setDadosFiltrado(lista)

    const limparFiltros = _ => {
        setIdFiltroColuna([])
        filtrar({})
    }

    return (
        <ConsultaRelatorio dados={dadosFiltrado} filtros={filtros} loading={carregando} removerFiltros={() => limparFiltros()} setShowModalFiltros={setShowModal} showModalFiltros={showModal} setFiltros={setFiltros} titulo={titulo} history={props.history}>
            {<Formik
                initialValues={valoresIniciais}
                enableReinitialize={true}
                onSubmit={values => filtrar(values)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        {filtrosObjs && filtrosObjs.filter(item => item.nome !== 'pesquisa').map(item => {
                            return <FiltroDataGrid tipo={item.tipo ? item.tipo : ''}
                                propsFormik={propsFormik}
                                placeholder={item.titulo ? item.titulo : 'Selecione...'}
                                label={item.titulo ? item.titulo : ''}
                                tamanho='col-12'
                                classeTooltip={'tooltip-input-modal'}
                                name={item.colunaID.name || item.colunaID.tipo}
                                setarValor={option => { setNovoFiltroRelatorio(item, option); option && option.target ? propsFormik.setFieldValue(item.colunaID.name || item.colunaID.tipo, option.target.value) : propsFormik.setFieldValue(item.colunaID.name || item.colunaID.tipo, option) }}
                                onSelect={() => { setNovoFiltroRelatorio(item, propsFormik.values) }}
                                onChange={option => { setNovoFiltroRelatorio(item.colunaID, option); option && option.target ? propsFormik.setFieldValue(item.colunaID.name || item.colunaID.tipo, option.target.value) : propsFormik.setFieldValue(item.colunaID.name || item.colunaID.tipo, option) }}
                                multiplo={true}
                                autoFocus />
                        })}
                        {filtrosObjs && filtrosObjs.filter(item => item.nome === 'pesquisa').map(item => {
                            return <FiltroDataGrid tipo={item.tipo ? item.tipo : ''}
                                propsFormik={propsFormik}
                                placeholder={item.titulo ? item.titulo : 'Selecione...'}
                                label={item.titulo ? item.titulo : ''}
                                tamanho='col-12'
                                classeTooltip={'tooltip-input-modal'}
                                name={item.nome}
                                multiplo={true}
                                setarValor={option => { setNovoFiltroRelatorio(item, option); option && option.target ? propsFormik.setFieldValue(item.tipo, option.target.value) : propsFormik.setFieldValue(item.tipo, option) }}
                                onSelect={() => { setNovoFiltroRelatorio(item, propsFormik.values) }}
                                onChange={option => { setNovoFiltroRelatorio(item.colunaID, option); option && option.target ? propsFormik.setFieldValue(item.tipo, option.target.value) : propsFormik.setFieldValue(item.tipo, option) }}
                                autoFocus />
                        })}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>}
        </ConsultaRelatorio>
    )
}