import React, { useState, useEffect } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputTipoRelatorio, InputEstabelecimentoSaude, InputSimNao, InputOperadoraSaude, InputProcedimento, InputSocio, InputSocioAtivos, InputStatusAgendamento, InputCirurgiaoCombo, InputTipoRelatorioPersonalizado } from '../../../components/InputsAutoComplete'
import { InputDatePicker, InputTimePicker } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes'
import { getUsuario } from '../../../util/Configuracoes'
import moment from "moment"
import { MODULOS_SISTEMA } from '../../../util/Enums';

export default props => {
    const startOfMonth = moment().clone().format('DD/MM/YYYY')
    const endOfMonth = moment().clone().format('DD/MM/YYYY')
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Agenda/Central de Reserva')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '', data_inicial: startOfMonth, data_final: endOfMonth })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)
    const user = getUsuario()
    const tipoRelatorio = [
        { label: 'Relatório Agenda/Central de Reserva', value: MODULOS_SISTEMA.RELATORIO_AGENDA_CENTRAL_RESERVA },
    ]

    user.tipo !== 4 && tipoRelatorio.push({ label: 'Relatório Agenda/Estabelecimento de Saúde Descoberto', value: MODULOS_SISTEMA.RELATORIO_ESTABELECIMENTO_SAUDE_DESCOBERTO })

    const [validacao, setValidacao] = useState(Yup.object({
        data_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) }),
        data_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) })
    }))

    const relatoriosPorTipo = propsFormik => [
        {
            titulo: 'Relatório Agenda/Central de Reserva',
            tipo: MODULOS_SISTEMA.RELATORIO_AGENDA_CENTRAL_RESERVA,
            url: '/relatorio/agendamentoSocio',
            campos: [
                <InputSocioAtivos label="Profissional" placeholder="Profissional" name="executante" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputDatePicker label="Data Inicial" name="data_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final" name="data_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputTimePicker
                    label="Hora Inicial" mascara="11:11" name="hora_inicial" type="text" placeholder="Hora Inicial" classeTooltip={'tooltip-input-modal'} obrigatorio={true}
                    setarValor={value => { propsFormik.setFieldValue("hora_inicial", value) }} />,
                <InputTimePicker
                    label="Hora Final" mascara="11:11" name="hora_final" type="text" placeholder="Hora Final" classeTooltip={'tooltip-input-modal'} obrigatorio={true}
                    setarValor={value => { propsFormik.setFieldValue("hora_final", value) }} />,
                <InputStatusAgendamento multiplo label="Status Agendamento" name="status_id" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputOperadoraSaude multiplo label="Operadora de Saúde" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputProcedimento multiplo label="Procedimento" name="procedimento" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputCirurgiaoCombo label="Solicitante" name="solicitante" multiplo placeholder="Solicitante" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Plantão?" name="plantao" type="select" placeholder="Plantão?" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputEstabelecimentoSaude label="Estabelecimento Saúde" placeholder="Estabelecimento Saúde" name="estabelecimento_saude" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo botaoVisivel={false} menuPlacement='top' />
            ],
            classeTr: 'linha-bottom-relatorio',
            colunas: [
                { label: 'Data Inicial', name: 'data_inicial', visivel: true, ordenamento: { tipo: 'data', ativo: false }, width: '7%' },
                { label: 'S.', name: 'sequencial', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '3%' },
                { label: 'Hora Inicial', name: 'hora_inicial', visivel: true, ordenamento: { tipo: 'texto', ativo: false, }, width: '7%' },
                { label: 'Hora Final', name: 'hora_final', visivel: false, ordenamento: { tipo: 'texto', ativo: false, }, width: '7%' },
                { label: 'Estab. Sáude', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Sigla', name: 'sigla', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '5%' },
                { label: 'CRM', name: 'crm', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Profissional', name: 'executante', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CPF', name: 'cpf', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, width: '7%' },
                { label: 'Descrição Proc.', name: 'descricao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Solicitante', name: 'solicitante', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Plantão', name: 'plantao', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Sócio Pref.', name: 'preferencia', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '6%' },
                { label: 'Status', name: 'status_agenda', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro', name: 'data_registro', visivel: false, ordenamento: { tipo: 'data', ativo: false } },
            ],
            data: [],
            agrupado: false,
            colunasCsv: [
                { label: 'Data Inicial', name: 'data_inicial', visivel: true, ordenamento: { tipo: 'data', ativo: false }, width: '7%' },
                { label: 'S.', name: 'sequencial', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '3%' },
                { label: 'Hora Inicial', name: 'hora_inicial', visivel: true, ordenamento: { tipo: 'texto', ativo: false, }, width: '7%' },
                { label: 'Hora Final', name: 'hora_final', visivel: false, ordenamento: { tipo: 'texto', ativo: false, }, width: '7%' },
                { label: 'Estab. Sáude', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Sigla', name: 'sigla', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '5%' },
                { label: 'CRM', name: 'crm', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Profissional', name: 'executante', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CPF', name: 'cpf', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, width: '7%' },
                { label: 'Descrição Proc.', name: 'descricao', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Solicitante', name: 'solicitante', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Plantão', name: 'plantao', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Sócio Pref.', name: 'preferencia', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '6%' },
                { label: 'Status', name: 'status_agenda', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro', name: 'data_registro', visivel: false, ordenamento: { tipo: 'data', ativo: false } },
            ],
            colunasFiltros: [
                { label: 'Profissional', name: 'executante', name_coluna: 'executante' },
                { label: 'Data Inicial', name: 'data_inicial', name_coluna: 'data_inicial' },
                { label: 'Data Final', name: 'data_final', name_coluna: 'data_final' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude', name_coluna: 'estabelecimento_saude' },
                { label: 'Plantão', name: 'plantao', name_coluna: 'plantao' },
                { label: 'Operadora Saúde', name: 'operadora_saude', name_coluna: 'operadora_saude' },
                { label: 'Procedimento', name: 'procedimento', name_coluna: 'procedimento' },
                { label: 'Solicitante', name: 'solicitante', name_coluna: 'solicitante' },
                { label: 'Status Agendamento', name: 'status_id', name_coluna: 'status_id' },

            ]
        },
        {
            titulo: 'Relatório Agenda/Estabelecimento de Saúde Descoberto',
            tipo: MODULOS_SISTEMA.RELATORIO_ESTABELECIMENTO_SAUDE_DESCOBERTO,
            url: '/relatorio/estabelecimentoSaudeDescoberto',
            campos: [
                <InputDatePicker label="Data Inicial" name="data_inicial" type="text" autoFocus
                    setarValor={value => { propsFormik.setFieldValue("data_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final" name="data_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputStatusAgendamento multiplo label="Status Agendamento" name="status_id" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputOperadoraSaude multiplo label="Operadora de Saúde" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputProcedimento multiplo label="Procedimento" name="procedimento" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputCirurgiaoCombo label="Solicitante" name="solicitante" multiplo placeholder="Solicitante" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputEstabelecimentoSaude label="Estabelecimento Saúde" placeholder="Estabelecimento Saúde" name="estabelecimento_saude" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo botaoVisivel={false} menuPlacement='top' />,
                <InputSimNao label="Plantão?" name="plantao" type="select" placeholder="Plantão?" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />
            ],
            colunas: [
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Razão Social', name: 'razao_social', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Nome Fantasia', name: 'nome_fantasia', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CNPJ', name: 'cnpj', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CNES', name: 'cnes', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Inicial Agendamento', name: 'data_inicial', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Sócio Preferencial', name: 'preferencia', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status', name: 'status_agenda', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },

            ],
            data: [],
            agrupado: false,
            colunasCsv: [
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Razão Social', name: 'razao_social', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Nome Fantasia', name: 'nome_fantasia', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CNPJ', name: 'cnpj', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CNES', name: 'cnes', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Inicial Agendamento', name: 'data_inicial', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Sócio Preferencial', name: 'preferencia', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status', name: 'status_agenda', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
            ],
            colunasFiltros: [
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude', name_coluna: 'estabelecimento_saude' },
                { label: 'Data Final', name: 'data_final', name_coluna: 'data_final' },
                { label: 'Data Inicial', name: 'data_inicial', name_coluna: 'data_inicial' },
                { label: 'Data Inicial', name: 'data_inicial', name_coluna: 'data_inicial' },
                { label: '"Operadora de Saúde', name: 'operadora_saude', name_coluna: 'operadora_saude' },
                { label: 'Solicitante', name: 'solicitante', name_coluna: 'solicitante' },
                { label: 'Procedimento', name: 'procedimento', name_coluna: 'procedimento' },
                { label: 'Plantão', name: 'plantao', name_coluna: 'plantao' },
                { label: 'Status Agendamento', name: 'status_id', name_coluna: 'status_id' },
            ]
        }
    ]

    const submit = (values, propsFormik) => {
        setCarregando(true)
        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)

        for (let i = Object.keys(values).length; i > 0; i--)
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                delete values[Object.keys(values)[i]]

        setShowModalFiltros(false)
        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio) {
            setFiltros(Object.keys(values).map((x, idx, array) => {

                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value) && valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                })
            }).filter(x => x.coluna !== false && x.value))
        }
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }

    useEffect(() => {

        if (relatorioAtual.tipo === 1) {
            var dadosFiltros
            for (let i in filtros) {
                if (filtros[i].coluna === 'executante') {
                    dadosFiltros = filtros[i].dado = filtros[i].dado.split(',')
                    for (let j in dadosFiltros)
                        dadosFiltros[j] = dadosFiltros[j].split('-')[0]

                    filtros[i].dado = dadosFiltros.map((x, i) => i === dadosFiltros.length - 1 ? x : x + ', ')

                }

            }
        }
        let cloneRelatorioAtual = { ...relatorioAtual }

        const newFiltro = [...filtros]

        if (user.tipo === 4)
            newFiltro.push({ coluna: 'usuario', value: user.id }, { coluna: 'tipo', value: user.tipo })
        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{ numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data, classeTr: cloneRelatorioAtual.classeTr, colunasCsv: cloneRelatorioAtual.colunasCsv ? cloneRelatorioAtual.colunasCsv : null, agrupado: cloneRelatorioAtual?.agrupado || false }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: newFiltro, usuario_tipo: user.tipo, usuario_id: user.id })
    }, [filtros])

    const changeTipo = (value) => {
        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({})
        // const index = tipoRelatorio.findIndex(x => x.value === value)
        setValuesFiltros({ tipo_relatorio: value, data_inicial: startOfMonth, data_final: endOfMonth })
        setRelatorioSelecionado(value?.value)
    }

    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })}>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        <InputTipoRelatorioPersonalizado label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                            propsFormik={propsFormik} modulos={relatoriosPorTipo(propsFormik).map(x => x.tipo)} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} />
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}
                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}