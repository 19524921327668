
import React, { useState } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'

const ConsultaSocioOcorrencia = (props) => {
    const [data, setData] = useState([])
    const botoes = [{

    }]

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-socio-ocorrencia',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Sigla',
                        accessor: 'sigla',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'sigla',
                            idColuna: 'sigla'
                        }
                    },
                    {
                        Header: 'Sócio',
                        accessor: 'nome',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'socio',
                            idColuna: 'socio_id'
                        }
                    },
                    {
                        Header: 'Tipo Ocorrencia',
                        accessor: 'tipo_ocorrenca',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'tipo_ocorrencia',
                            idColuna: 'tipo_ocorrencia_id'
                        }
                    },
                    {
                        Header: 'Estabelecimento Saúde',
                        visivel: false,
                        accessor: 'estabelecimento_saude',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'estabelecimento_saude',
                            idColuna: 'socio_ocorrencia.estabelecimento_saude_id'
                        }
                    },
                    {
                        Header: 'Implica na Hierarquia ',
                        accessor: 'implica_hierarquia',
                        ordenamento: {
                            tipo: 'combo',
                        },
                        visivel:false,
                        filtro: {
                            idColuna: 'implica_hierarquia',
                            tipo: 'input_sim_nao'
                        },
                        filtroSomenteModal: true
                    },
                    {
                        Header: 'Tipo Filtragem de Data',
                        accessor: 'tipo_data_filtro',
                        visivel: false,
                        filtro: {
                            tipo: 'tipo_data_filtro',
                            idColuna: 'tipo_data_filtro',
                            multiplo: false,
                            valor: { value: 1, label: 'Interposição' },
                        }
                    },
                    {
                        Header: 'Data Inicial',
                        accessor: 'data_inicial',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_inicial',
                            idColuna: 'data_inicio',
                            date: true
                        }
                    },
                    {
                        Header: 'Data Final',
                        accessor: 'data_final',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_final',
                            idColuna: 'data_fim',
                            date: true //PASSAR PARA TODOS OS FILTROS COM DATA
                        }
                    },
                    {
                        Header: 'Data de Registro',
                        accessor: 'data_registro_str',
                        ordenamento: {
                            tipo: 'data',
                        }
                    }
                    
                ],
            },
        ],
        []
    )

    return (
        <>
            <div>
                <DataGridAlternativa data={data}
                    columns={columns}
                    caminhoBase='/administracao/socio-ocorrencia'
                    titulo={'Consulta de Ocorrências '}
                    headerAlternativo={botoes}
                    tituloLista={'Lista Ocorrências'}
                    setData={setData}
                    acoes={true}
                    modulo={props.modulo}
                    caminhoConsultaFiltros='/administracao/socio-ocorrenciaPaginacao'
                    urlCadastro="/administracao/socio-ocorrencia/cadastro" />
            </div>
        </>
    )
}

export default ConsultaSocioOcorrencia