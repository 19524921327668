import React, { useState, useEffect, useRef } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { ReverterExclusao, Visualizar } from '../../../../components/Acoes'
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { get, excluir, putComAcao, put } from '../../../../util/AcessoApi'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens } from '../../../../util/Utilitarios.js'


import { HeaderCadastro } from '../../../../components/Headers'

const Consulta = (props) => {
    const [data, setData] = useState([])
    const [pageIndex, setPageIndex] = useState()
    const [qtdRegistros, setQtdRegistros] = useState()
    const target = useRef(null)

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-auditoria',
                qtdRegistros: qtdRegistros,
                paginacaoManual: true,
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        visivel: false,
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Módulo',
                        accessor: 'modulo',
                        ordenamento: {
                            tipo: 'combo',
                        },
                        filtro: {
                            combo:'combo-alternativa',
                            tipo: 'modulo',
                            idColuna: 'modulo.id'
                        },
                        filtroSomenteModal: true
                    },
                    {
                        Header: 'Código',
                        accessor: 'controle',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Descrição',
                        accessor: 'descricao',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Usuário',
                        accessor: 'usuario',
                        filtro: {
                            combo:'combo',
                            tipo: 'usuario_auditoria',
                            idColuna: 'usuario_id'
                        },
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Data  Ação',
                        accessor: 'data_acao',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            combo:'combo-simples',
                            idColuna: 'auditoria.data_registro',
                            tipo: 'data_inicial'
                        },
                    },
                    
                ]
            }], []
    )

    const reverter = propsAcao => {
        const usuario = getUsuario().id


        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.REVERTER_EXCLUSAO
        }
        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length){
                Promise.resolve(put('/controleAcesso/reverterExclusao', { usuario: usuario, ...propsAcao.row }, true, true))
                .then(data => { target.current.atualizar(); mostrarToast('sucesso', mensagens.sucesso)})
                .catch(erro => { 
                    mostrarToast('erro', erro.response.data.alertas)
                })
                }
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const AcoesObj = [
        (propsAcao) => <ReverterExclusao action={() => reverter(propsAcao)} />
    ]

    return (
        <>
            <div>
                <DataGridAlternativa target={target} data={data} columns={columns} setData={setData} acoesObj={AcoesObj} acoes={true} caminhoBase='/controleacesso/lixeira' ImpressaoOff = {true}
                    tituloLista={'Lixeira'} setPageIndex={setPageIndex} setQtdRegistros={setQtdRegistros} caminhoConsultaFiltros='/controleacesso/lixeira'
                    modulo={props.modulo} titulo={'Consulta Lixeira'} icone={'icon-New-Trash'} />
            </div>
        </>
    )
}

export default Consulta