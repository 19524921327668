import React, { useState, useEffect } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import DataGrid from '../../../../components/DataGrid'
import { consultar } from '../../../../util/AcessoApi'


const ConsultaCbos = (props) => {
    const [data, setData] = useState([])

    useEffect(() => {
    }, [data])
    
    useEffect(() => {
        consultar('/administracao/registroatividades', (dados) => setData(dados.sort((a, b) => b.id - a.id)))
    }, [])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-registro-atividades',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id' ,
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }                  
                    },
                    {
                        Header: 'Usuário',
                        accessor: 'usuario',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'usuario',
                            idColuna: "registro_atividades.usuario_id"
                        }
                    },
                    {
                        Header: 'Data Inicial',
                        accessor: 'data',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_inicial',
                            idColuna: "data_inicial",
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data Final',
                        accessor: 'data_inicial',
                        visivel: false,
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_final',
                            idColuna: "data_final",
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data de Registro',
                        accessor: 'data_registro',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data',
                            idColuna: "data_registro",
                            datePicker: true,
                            date: true
                        }
                    }
                ],
            },
        ],
        []
    )
    const caminho = {
        cadastro: '/administracao/registroatividades/cadastro',
    }

    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} caminhoBase='/administracao/registroatividades' setData={setData} acoes={true} url={caminho} modulo={props.modulo} 
                titulo={'Consulta Registro Atividades'} tituloLista={'Lista Registro Atividades'} urlCadastro='/administracao/registroatividades/cadastro' icone={'icon-Address-Book2'}
                caminhoConsultaFiltros= '/administracao/registroatividadespaginacao'/>
            </div>
        </>
    )
}

export default ConsultaCbos