import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { Link, useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { distinctArr, formatarMoeda } from '../../../../util/Utilitarios';
import { downloadArquivoS3, ordenarArrayObj } from '../../../../util/FuncoesComuns';
import '../../../../assets/css/visualizacao.css';

export default props => {
    console.log('props: ', props);
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/financeiro/lancamentofinanceirovisualizacao', id,
            obj => {
                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Lançamento Financeiro',
                    grupos: [
                        {
                            titulo: 'Dados Lançamento Financeiro',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Empresa', descricao: obj?.empresa?.label, tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Lançamento', descricao: obj.tipo_lancamento.label, tamanho: 'col-lg-4' },
                                { titulo: 'Categoria', descricao: obj.categoria_lancamento.label, tamanho: 'col-lg-4' },
                                { titulo: 'Conta Bancária Empresa', descricao: obj?.conta_bancaria_empresa_completa, tamanho: 'col-lg-4' }, // mexer aqui
                                { titulo: 'Cliente', descricao: obj.cliente_lancamento.label, tamanho: 'col-lg-4' },
                                { titulo: 'CPF/CNPJ', descricao: obj?.cliente_lancamento?.identificacao, tamanho: 'col-lg-4' },
                                { titulo: 'Conta Bancária Cliente', descricao: obj.conta_bancaria_completa, tamanho: 'col-lg-4' }, // mexer aqui
                                { titulo: 'Previsão Vencimento', descricao: obj.previsao_vencimento_lancamento, tamanho: 'col-lg-4' },
                                { titulo: 'Valor R$', descricao: obj.valor_total_formatado, tamanho: 'col-lg-4' },
                                { titulo: 'Descrição', descricao: obj.descricao_lancamento, tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Documento', descricao: obj.tipo_documento_lancamento?.label, tamanho: 'col-lg-4' }, //trazer
                                { titulo: 'NFse/Documento', descricao: obj.nota_fiscal_lancamento, tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Movimento', descricao: obj.tipo_movimento_lancamento, tamanho: 'col-lg-4' },
                                { titulo: 'Parcelado', descricao: obj.parcelado_sim_nao, tamanho: 'col-lg-4' }, // trazer
                                { titulo: 'Data Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                                { titulo: 'Usuário', descricao: obj.usuario, tamanho: 'col-lg-4' },
                            ]
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Dados Liquidação',
                            aposGrupo: <tr> {
                                obj?.liquidacao_visualizacao.length > 0 ?
                                    <>
                                        {/* {
                                            obj.liquidacao_visualizacao.map(x => <tr style={{ textAlign: 'left', fontWeight: 'bold' }} colSpan={6}>
                                                {x.anotacoes != null ? `Anotações: ${x.anotacoes}` : ''}
                                            </tr>)
                                        } */}
                                        <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={11}>Total: {formatarMoeda(obj?.liquidacao_visualizacao.map(item => item.valor).reduce((total, num) => parseFloat(total) + parseFloat(num)))}</td>

                                        {/* <td colSpan={11}></td> */}
                                    </>
                                    : <></>}
                            </tr>,
                            thead:
                                <tr>
                                    {/* <td className='coluna-6'>Item Ref.</td> */}
                                    <td className='coluna-1'>Modo Liq.</td>
                                    <td className='coluna-1'>Data Liq.</td>
                                    <td className='coluna-1'>Data Registro</td>
                                    <td className='coluna-1'>Usuário</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>(+)Acres. R$</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>(-)Desc. R$</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>(+)Juros R$</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>(-)Imp NF R$</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>(-)Glosa R$</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>(-)Outros R$</td>
                                    <td className='coluna-1' style={{ textAlign: 'right' }}>Valor R$</td>
                                </tr>,
                            tbody: obj.liquidacao_visualizacao === null || obj.liquidacao_visualizacao.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :

                                obj.liquidacao_visualizacao.map(x =>
                                    <>
                                        <tr>
                                            <td>{x.modo_liquidacao?.label}</td>
                                            <td>{x.data_liquidacao}</td>
                                            <td>{x.data_registro}</td>
                                            <td>{x.usuario}</td>
                                            <td style={{ textAlign: 'right' }}> {(x.valor_acrescimo)}</td>
                                            <td style={{ textAlign: 'right' }}> {(x.valor_desconto)}</td>
                                            <td style={{ textAlign: 'right' }}> {(x.valor_juros_multas)}</td>
                                            <td style={{ textAlign: 'right' }}> {(x.valor_imposto_nfse)}</td>
                                            <td style={{ textAlign: 'right' }}> {(x.valor_glosa)}</td>
                                            <td style={{ textAlign: 'right' }}> {(x.valor_outros_impostos)}</td>
                                            <td style={{ textAlign: 'right' }}> {formatarMoeda(x.valor)}</td>
                                        </tr>
                                        {
                                            x.anotacoes ?
                                                <tr>
                                                    <td style={{ textAlign: 'left', fontStyle: 'italic', fontSize: 5 }} colspan={12} ><b>Anotações: </b> <span style={{ fontSize: 13 }}>{x.anotacoes}</span></td>
                                                </tr>
                                                : <> </>
                                        }

                                    </>)
                            ,
                            // tfoot:
                            //     obj.liquidacao_visualizacao === null || obj.liquidacao_visualizacao.length < 1 ? (<></>) :

                            //         obj.liquidacao_visualizacao.map(x =>
                            //             <tr>
                            //                 <th>Anotações: </th>
                            //                 <td style={{ textAlign: 'left', fontWeight: 'bold' }} colSpan={11}>{x.anotacoes}</td>
                            //             </tr>
                            //         )
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Cheque(s)',
                            aposGrupo: <tr> {
                                obj?.cheque.length > 0 ?
                                    <>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={11}>Total: {formatarMoeda(obj?.cheque.map(item => item.valor).reduce((total, num) => parseFloat(total) + parseFloat(num)))}</td>
                                    </>
                                    : <></>}
                            </tr>,
                            thead:
                                <tr>

                                    <td className='coluna-1 text-left' >Cod.</td>
                                    <td className='coluna-2'>Banco</td>
                                    <td className='coluna-1'>Número</td>
                                    <td className='coluna-1 text-center'>Data Emissão</td>
                                    <td className='coluna-1 text-center'>Data Compensação</td>
                                    <td className='coluna-1 text-center'>Devolvido?</td>
                                    <td className='coluna-1 text-right'>Valor R$</td>

                                </tr>,
                            tbody: obj.cheque == undefined || obj.cheque.length < 1 ? (<><tr>
                                <td colspan="9">Sem Registro!</td>
                            </tr></>) :
                                obj.cheque.filter(x => x.cheque_lancamento_id).map((x, index) =>
                                    <tr>

                                        <td className='text-left' >{x.id}</td>
                                        <td className=''>{x.banco?.label}</td>
                                        <td>{x.numero}</td>
                                        <td className='text-center'>{x.data_emissao}</td>
                                        <td className='text-center'>{x.data_compensado}</td>
                                        <td className='text-center'>{x.devolvido}</td>
                                        <td className='text-right'>{x.valor_total_formatado}</td>

                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Parcelas',
                            thead:
                                <tr>
                                    <td>#</td>
                                    <td className='coluna-1'>Cod.</td>
                                    <td>P</td>
                                    <td className='coluna-1 text-center'>Aten.(Guias)</td>
                                    <td className='coluna-2 text-center'>Data</td>
                                    <td className='coluna-3'>Cliente</td>
                                    <td className='coluna-2 text-right'>Valor R$</td>
                                    <td className='coluna-1 text-center'>Data Liquidação</td>
                                    <td className='coluna-2 text-right'>Valor Liquidado R$</td>
                                </tr>,
                            tbody: obj.parcelas == undefined || obj.parcelas.length < 1 ? (<><tr>
                                <td colspan="9">Sem Registro!</td>
                            </tr></>) :
                                obj.parcelas.filter(x => x.id).map((x, index) =>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td><Link to={`/financeiro/lancamento/visualizacao/` + x.id} target={'_blank'} className={'rmv-link'}>{x.id}</Link></td>
                                        <td>{x.numero_parcela}</td>
                                        <td className='text-center'>{x.guia_id}</td>
                                        <td className='text-center'>{x.data_prevista}</td>
                                        <td>{x.entidade}</td>
                                        <td className='text-right'>{x.valor_total_formatado}</td>
                                        <td className='text-center'>{x.data_liquidacao}</td>
                                        <td className='text-right'>{x.valor_liquidado}</td>
                                    </tr>)
                        },

                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Histórico de Status',
                            thead:
                                <tr>
                                    <td className='coluna-2'>Status</td>
                                    <td className='coluna-4'>Anotações</td>
                                    <td className='coluna-2'>Usuário</td>
                                    <td className='coluna-1'>Data Registro</td>
                                </tr>,
                            tbody: obj.historico_status == undefined || obj.historico_status.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.historico_status.filter(x => x.status).map(x =>
                                    <tr>
                                        <td>{x.status}</td>
                                        <td>{x.anotacoes}</td>
                                        <td>{x.usuario}</td>
                                        <td>{x.data_registro}</td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Histórico Remoção Liquidação',
                            thead:
                                <tr>
                                    <td className='coluna-4'>Justificativa</td>
                                    <td className='coluna-2'>Usuário</td>
                                    <td className='coluna-1'>Data Registro</td>
                                </tr>,
                            tbody: obj.historico_remocao_liquidacao == undefined || obj.historico_remocao_liquidacao.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.historico_remocao_liquidacao.map(x =>
                                    <tr>
                                        <td>{x.anotacoes}</td>
                                        <td>{x.usuario}</td>
                                        <td>{x.data_registro}</td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Arquivo(s)',
                            thead:
                                <tr>
                                    <td className='coluna-6'>Nome</td>
                                    <td className='coluna-5'>Tamanho</td>
                                    <td className='coluna-1 d-none-print'>Download</td>
                                </tr>,
                            tbody: obj.arquivo == undefined || obj.arquivo.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                ordenarArrayObj(obj.arquivo, 'nome').map(arquivo =>
                                    <tr>
                                        <td>{arquivo.name}</td>
                                        <td>{arquivo.sizeReadable}</td>
                                        <td className="d-none-print"><div className="icone"><button className="btn-acao icon-Download-fromCloud" onClick={() => downloadArquivoS3(arquivo.s3Key)}></button></div></td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'NFSe Emitida(s)',
                            thead:
                                <tr>
                                    <td className='coluna-1'>Número</td>
                                    <td className='coluna-2'>Data Emissão</td>
                                    <td className='coluna-3 '>Tomador/Cliente</td>
                                    <td className='coluna-3 '>Prestador/Empresa</td>
                                    <td className='coluna-3 '>Valor Nota</td>
                                </tr>,
                            tbody: obj.nota_fiscal_emitida == undefined || obj.nota_fiscal_emitida.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                ordenarArrayObj(obj.nota_fiscal_emitida, 'codigo').map(item =>
                                    <tr>
                                        <td>{item.numero}</td>
                                        <td>{item.data_emissao}</td>
                                        <td>{item.tomador}</td>
                                        <td>{item.prestador}</td>
                                        <td>{item.valor_nota}</td>
                                    </tr>)
                        },
                    ]
                } : { titulo: 'Visualização Lançamento Financeiro' })
            })
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

