import { InputPadrao, InputGroupSenha } from '../../../../components/Inputs'
import React, { useState, useEffect } from 'react'
import { Formik, Form } from "formik"
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import { useParams } from "react-router-dom"
import { logout } from '../../../../util/Configuracoes'
import { post } from '../../../../util/AcessoApi'
import { Link } from 'react-router-dom'
import * as Yup from "yup"
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/login.css'

const Submit = (values, setIsSubmit, history) => {
    setIsSubmit(true)
    Promise.resolve(post('/controleAcesso/usuario/alteracaoSenhaComToken', values, false, null, true)).then(function (val) {
        mostrarToast('sucesso', mensagens['sucesso'])
        logout()
        history.push('/login')
    }).catch((e) => {
        setIsSubmit(false)
    });
}
const Validacao = Yup.object({
    novaSenha: Yup.string()
        .required()
        .max(30),
    confirmacaoSenha: Yup.string()
        .required()
        .max(30).test('confirmar-senha', 'Confirmação de senha deve ser igual a Senha', function (value) { return this.parent.novaSenha === value })
})
const NovaSenha = (props) => {
    let { token } = useParams();
    let { history } = props
    const [isSubmit, setIsSubmit] = useState(false)
    useEffect(() => {
        Yup.object(Validacao)
    }, [])

    return (
        <div className='background-img'>
            <div className='overlay'>
                <div className='container-img height content-login container'>
                    <div className='row d-flex justify-content-center page-login'>
                        <div className='card-img align-items-center border-radius-bottom-left-50 col-xl-3 col-md-12 content-logo'>
                            <img src={require('../../../../assets/img/logo.png')} alt='logo' className='img-logo' />
                        </div>
                        <div className='card-form border-radius-top-right-50 col-xl-5 col-md-12 form-login'>
                            <Formik
                                initialValues={{ token: token, novaSenha: "", confirmacaoSenha: "" }}
                                validationSchema={Validacao}
                                onSubmit={(values) => Submit(values, setIsSubmit, history)}>
                                <Form className='form-alteracao-senha'>
                                    <div className='col-lg-12'> <h4>Alteração de Senha</h4><br /></div>
                                    <InputPadrao label='token' name='token' type='hidden' placeholder='token' obrigatorio={false} tamanho='display-none' />
                                    <InputGroupSenha label='Nova senha' name='novaSenha' type="password" placeholder='Nova senha' obrigatorio={true}
                                        classeTooltip={props.modal ? "tooltip-input-modal" : ""} />
                                    <InputGroupSenha label='Confirmação de Senha' name='confirmacaoSenha' type="password" placeholder='Confirmação de Senha' obrigatorio={true}
                                        classeTooltip={props.modal ? "tooltip-input-modal" : ""} />
                                    <div className='col-lg-12'>  <Link to='/login' className='forgot-password' >Lembrou? Fazer login</Link></div>
                                    <div className='direita container-botoes'>
                                        <div className='col-lg-12 mt-8'>
                                            <BotaoPadrao texto='Alterar' disabled={isSubmit} />
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                    <div className='copyright'>
                        © Copyright {new Date().getFullYear()}  <a className='link-login' target='_blank' rel='noopener noreferrer' href='http://www.selectasistemas.com.br/'> Selecta Sistemas.</a> Todos os direitos reservados.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NovaSenha