import React, { useState, useRef } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { Visualizar} from '../../../../components/Acoes'

const ConsultaGuiaMapaHoras = rProps => {
    const [data, setData] = useState([])
    const target = useRef(null)

    const columns = React.useMemo(
        () => [{
            id: 'consulta-referencia',
            columns: [
                {
                    Header: 'Cod. Grupo',
                    accessor: 'codigo_grupo',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                }, {
                    Header: 'Empresa',
                    Placeholder: '',
                    accessor: 'empresa',
                    visivel: true,
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, {
                    Header: 'Cod. de Barras',
                    Placeholder: '',
                    accessor: 'codigo_barras',
                    visivel: true,
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, {
                    Header: 'Paciente',
                    accessor: 'nome_paciente',
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, {
                    Header: 'Data Proc.',
                    accessor: 'data_procedimento',
                    visivel: true,
                    ordenamento: {
                        tipo: 'texto'
                    },
                }, {
                    Header: 'Op. de Saúde',
                    accessor: 'operadora_saude',
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, {
                    Header: 'Estab. de Saúde',
                    accessor: 'estabelecimento_saude',
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, {
                    Header: 'Valor Pago',
                    accessor: 'valor_pago',
                    ordenamento: {
                        tipo: 'texto'
                    },
                }, {
                    Header: 'Data Pag.',
                    accessor: 'data_pagamento',
                    ordenamento: {
                        tipo: 'texto'
                    }
                }, {
                    Header: 'Data Pag. Inicial',
                    accessor: 'data_pagamento_inicial',
                    visivel: false,
                    apenasDataGrid: true,
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'data_pagamento_inicial',
                        datePicker: true,
                        date: true
                    }
                }, {
                    Header: 'Data Pag. Final',
                    accessor: 'data_pagamento_final',
                    visivel: false,
                    apenasDataGrid: true,
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'data_pagamento_final',
                        datePicker: true,
                        date: true
                    }
                }, {
                    Header: 'Tipo de Pag.',
                    accessor: 'tipo_pagamento',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'tipo_guia_pagamento',
                        idColuna: 'tipo_pagamento',
                    }
                }
            ]
        }], []
    )

    const acoesObj = [
        propsAcao => <Visualizar to={'/auditoria/guia-pagamento/visualizacao/' + propsAcao.row.id} />
    ]

    return (
        <div>
            <DataGridAlternativa acoes={true} acoesObj={acoesObj} target={target} data={data} columns={columns} setData={setData} caminhoBase='/auditoria/guiapagamento'
                caminhoConsultaFiltros='/auditoria/guiapagamentopaginacao' modulo={rProps.modulo} titulo={'Consulta Guia Pagamento'} tituloLista={'Lista Guia Pagamento'}
                icone={'icon-Calendar-4'} removerTotalPaginas={true}/>
        </div>
    )
}

export default ConsultaGuiaMapaHoras