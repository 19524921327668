import React, { useState, useEffect } from 'react'
import { ModalPadraoCadastro } from '../../../../components/Modais'
import { VisualizarErro } from '../../../../components/Acoes'
import { reduzTexto } from '../../../../util/FuncoesComuns';
import { FaTimes, FaCheck, FaPrint } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { formatarMoeda } from '../../../../util/Utilitarios';
import { get } from '../../../../util/AcessoApi';

const DetalhesHistorico = (props) => {
    const [itemDetalhes, setItemDetalhes] = useState({});
    const [historicoSocioEquipe, setHistoricoSocioEquipe] = useState([]);
    const [historicoSocioCoop, setHistoricoSocioCoop] = useState([]);

    useEffect(() => {
        setItemDetalhes(props.data);
    }, [props.data])

    return <ModalPadraoCadastro titulo={props.titulo || 'Detalhes'} showModal={props.showModal} setShowModal={props.setShowModal}>
        <div><div className='detalhes'>
            {itemDetalhes.guia && itemDetalhes.guia.map((item, idx) => {
                return (
                    <>
                        {idx === 0 ? <></> : <hr />}
                        <div className='item-detalhes'>
                            <h6><b>{item.titulo}</b></h6>
                            <h6>{item.descricao}</h6>
                            {item.html}
                        </div></>
                )
            })}
        </div>


            {
                itemDetalhes && itemDetalhes.socio_equipe && itemDetalhes.socio_equipe.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Sócio(s) Equipe</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes && itemDetalhes.socio_equipe && itemDetalhes.socio_equipe.map(item => <li className="mb-1">

                            <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                <h6><b>Sócio </b></h6>
                                <h6 className='mb-1'>{item.socio}</h6>
                            </div>
                            <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ flex: 1 }}>
                                    <h6><b>Hora Inicial</b></h6>
                                    <h6 className='mb-1'>{item.hora_inicial}</h6>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <h6><b>Hora Final</b></h6>
                                    <h6 className='mb-1'>{item.hora_final}</h6>
                                </div>
                            </div>
                        </li>)
                    }
                </ul>
            </div>


            {
                itemDetalhes && itemDetalhes.historico_lote && itemDetalhes.historico_lote.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Histórico de Lote</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes && itemDetalhes.historico_lote && itemDetalhes.historico_lote.map(item => <li className="mb-1">
                            <div className='col-hora row-space-between'>

                                <span className='span-hora'>{item.data_registro}</span>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span className={'tag'} style={{ backgroundColor: item.status.cor, color: 'white', marginLeft: 0 }}>{item.status.descricao}</span>
                            </div>
                            <div className='col-hora row-space-between mt-2'>
                                <i><p>{item.lote.numero}</p></i>
                                <i><p>{item.lote.descricao || '---'}</p></i>
                            </div>
                            <div className='mt-2 row-space-between mb-2' style={{ flexDirection: 'row' }}>
                                <div className='item-detalhes' style={{ flex: 1 }}>
                                    <h6><b>Referência</b></h6>
                                    <h6 className='mb-1'>{moment(item.referencia, 'DD/MM/YYYY').format('MM/YYYY')}</h6>
                                </div>
                                <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                    <h6><b>Qtd </b></h6>
                                    <h6 className='mb-1'>{item.quantidade_guia}</h6>
                                </div>
                                <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                    <h6><b>Op. Saúde</b></h6>
                                    <h6 className='mb-1'>{item.operadora_saude}</h6>
                                </div>
                            </div>

                            <div className='mt-2'>
                                <i><p>{item.justificativa_lote}</p></i>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span></span>
                                <span className='span-nome'><b>{`${item.usuario ? item.usuario.toUpperCase() : ''}`}</b></span>
                            </div>
                        </li>)
                    }
                </ul>
            </div>
            {
                itemDetalhes && itemDetalhes.historico_status && itemDetalhes.historico_status.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Histórico de Status</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes && itemDetalhes.historico_status && itemDetalhes.historico_status.map(item => <li className="mb-1">
                            <div className='col-hora row-space-between'>
                                <span className={'tag'} style={{ backgroundColor: item.cor, color: 'white' }}>{item.descricao}</span>
                            </div >
                            <div className='mt-2' style={{ marginLeft: 10 }}>
                                <span className='span-hora'>{item.data_registro}</span>
                            </div>
                            <div className='mt-2'>
                                <i><p>{item.justificativa_status}</p></i>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span></span>
                                <span className='span-nome'><b>{`${(item.usuario || item.usuario_app) ? (item.usuario || item.usuario_app).toUpperCase() : ''}`}</b></span>
                            </div>
                        </li>)
                    }
                </ul>
            </div>
            {
                itemDetalhes.historico_socio_coop?.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Histórico de Sócios Cooperativa</span></h5>
                    </div>
                    : <></>
            }
            <div>
                {
                    itemDetalhes.historico_socio_coop?.map(item =>
                        <div className='detalhes pt-2 pb-3 mb-2'>
                            <div className='col-hora row-space-between mb-2'>
                                <span></span>
                                <span className='span-nome'>{item.data_registro}</span>
                            </div>
                            <div className='bb-1 mb-2'>
                                <div className='col-hora text-center'>
                                    <span><b>{item.socio}</b></span>
                                </div>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span></span>
                                <span className='span-nome'><b>{item.usuario}</b></span>
                            </div>
                        </div>
                    )
                }
            </div>
            {
                itemDetalhes.historico_socio_equipe?.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Histórico de Sócio Equipe</span></h5>
                    </div>
                    : <></>
            }
            <div>
                {
                    itemDetalhes.historico_socio_equipe?.map(item =>
                        <div className='detalhes pt-2 pb-3 mb-2'>
                            <div className='col-hora row-space-between mb-2'>
                                <span></span>
                                <span className='span-nome'>{item.data_registro}</span>
                            </div>
                            {item.socios?.map(socio => <div className='mb-2'>
                                <div className='col-hora text-center'>
                                    <span><b>{socio.sigla}</b>{`${socio.sigla ? ' - ' : ''}${socio.socio}`}</span>
                                </div>
                            </div>)}
                            <div className='col-hora row-space-between bt-1 mt-1 pt-1'>
                                <span></span>
                                <span className='span-nome'><b>{item.usuario}</b></span>
                            </div>
                        </div>
                    )
                }
            </div>
            <div>
                {itemDetalhes?.dados_atendimento && itemDetalhes.dados_atendimento.length > 0 ?
                    <>
                        <div className="modal-header">
                            <h5 className="modal-title"><span>Atendimento Principal</span></h5>
                        </div>
                        <div>
                            <ul className='lista-modal'>
                                {
                                    itemDetalhes?.dados_atendimento && itemDetalhes?.dados_atendimento?.map(item => <li className="mb-1">
                                        <div className='item-detalhes row-space-between'>
                                            <h6><b>Código</b></h6>
                                            <h6><b>Data Registro</b></h6>
                                        </div>
                                        <div className='item-detalhes row-space-between'>
                                            <h6>{item?.id}</h6>
                                            <h6>{item?.data_registro}</h6>
                                        </div>
                                        <hr />
                                        <div className='item-detalhes'>
                                            <h6><b>Paciente</b></h6>
                                        </div>
                                        <div className='item-detalhes'>
                                            <h6>{item.nome_beneficiario || '---'}</h6>
                                        </div>
                                        <hr />
                                        <div className='item-detalhes'>
                                            <h6><b>Profissional(is) Executante(s) Cooperativa (CONVÊNIO)</b></h6>
                                        </div>
                                        <div className='item-detalhes'>
                                            <h6>{item?.socio_externo || '---'}</h6>
                                        </div>
                                        <hr />
                                        <div className='item-detalhes'>
                                            <h6><b>Profissional(is) Executante(s) EQUIPE</b></h6>
                                        </div>
                                        <div className='item-detalhes'>
                                            <h6>{item?.socio_interno || '---'}</h6>
                                        </div>
                                        <hr />
                                        <div className='item-detalhes row-space-between'>
                                            <h6><b>Data Procedimento</b></h6>
                                            <h6><b>Valor R$</b></h6>
                                        </div>
                                        <div className='item-detalhes row-space-between'>
                                            <h6>{item.data_procedimento || '---'}</h6>
                                            <h6>{item.valor_total || '---'}</h6>
                                        </div>
                                        <hr />

                                    </li>)
                                }
                            </ul>
                        </div>
                    </>
                    : <></>}
            </div>
            {
                itemDetalhes && itemDetalhes.lancamentos && itemDetalhes.lancamentos.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Lançamento(s)</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes && itemDetalhes.lancamentos && itemDetalhes.lancamentos.map(item => <li className="mb-1">
                            <div className='col-hora row-space-between'>

                                <span className='span-hora'>{item.data_registro_lancamento}</span>
                            </div>
                            <div className='mt-2 row-space-between mb-2' style={{ flexDirection: 'row' }}>
                                {item.movimento_id ?
                                    <div className='item-detalhes' style={{ flex: 1 }}>
                                        <h6><b>{`Movimento (${item.movimento_id}, ${item.data_movimento} ${item.cliente_movimento ? ', ' + item.cliente_movimento : ''})`}</b></h6>
                                        {/* <h6 className='mb-1'>{moment(item.referencia, 'DD/MM/YYYY').format('MM/YYYY')}</h6> */}
                                    </div>
                                    : <></>}

                            </div>
                            <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                <h6><b>Código Lançamento </b></h6>
                                <h6 className='mb-1'>{item.lancamento_id}</h6>
                            </div>
                            <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                <h6><b>Cliente </b></h6>
                                <h6 className='mb-1'>{(item?.cpf_cnpj ? item?.cpf_cnpj + ' - ' : '') + item.cliente}</h6>
                            </div>
                            <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                <h6><b>Empresa </b></h6>
                                <h6 className='mb-1'>{item.empresa || '--'}</h6>
                            </div>
                            <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                <h6><b>Est. Saúde</b></h6>
                                <h6 className='mb-1'>{item.estabelecimento_saude}</h6>
                            </div>
                            <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                <h6><b>NFSe</b></h6>
                                <h6 className='mb-1'>{item.nfse_documento}</h6>
                            </div>

                            <div className='mt-2 row-space-between mb-2' style={{ flexDirection: 'row' }}>
                                <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                    <h6><b>Data Liquidação</b></h6>
                                    <h6 className='mb-1'>{item.data_liquidacao}</h6>
                                </div>
                                <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                    <h6><b>Valor Liq.</b></h6>

                                    {item.valor_liquidado?.map(x => {
                                        return (
                                            <>
                                                <h6 className='mb-1'>{formatarMoeda(x)}</h6>
                                            </>
                                        )
                                    })}


                                </div>
                                <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                    <h6><b>Modo Liq.</b></h6>


                                    {item.modo_pagamento?.map(x => {
                                        return (
                                            <>
                                                <h6 className='mb-1'>{x}</h6>
                                            </>
                                        )
                                    })}


                                </div>

                            </div>

                            <div className='col-hora row-space-between'>
                                <span></span>
                                <span className='span-nome'><b>{`${item.usuario ? item.usuario.toUpperCase() : ''}`}</b></span>
                            </div>
                        </li>)
                    }
                </ul>
            </div>
            {
                itemDetalhes && itemDetalhes.guia_nfse && itemDetalhes.guia_nfse.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>NFSe</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes && itemDetalhes.guia_nfse && itemDetalhes.guia_nfse.map(item => <li className="mb-1">
                            <div className='col-hora row-space-between'>

                                <span className='span-hora'>{item.data_registro}</span>
                            </div>
                            <div className='item-detalhes mt-0 pl-1' style={{ flex: 1 }}>
                                <h6><b>Código Nfse </b></h6>
                                <h6 className='mb-1'>{item.codigo}</h6>
                            </div>
                            <div className='item-detalhes mt-0 pl-1' style={{ flex: 1 }}>
                                <h6><b>Número Nfse </b></h6>
                                <h6 className='mb-1'>{item.numero}</h6>
                            </div>
                            <div className='item-detalhes mt-0 pl-1' style={{ flex: 1 }}>
                                <h6><b>Código Verificação </b></h6>
                                <h6 className='mb-1'>{item.codigo_verificacao}</h6>
                            </div>
                            <div className='item-detalhes mt-0 pl-1' style={{ flex: 1 }}>
                                <h6><b>Status Nota</b></h6>
                                <h6 style={{ background: item.status_nota?.cor_id, color: 'white', display: 'inline-block', padding: '4px 8px' }} className='mb-1 guia-status'>
                                    {item.status_nota.label}
                                </h6>
                            </div>

                            <div className='item-detalhes mt-0 pl-1' style={{ flex: 1 }}>
                                <h6><b>Status Envio</b></h6>
                                <h6 style={{ background: item.status_envio_nota?.cor_id, color: 'white', display: 'inline-block', padding: '4px 8px' }} className='mb-1 guia-status'>
                                    {item.status_envio_nota.label}
                                </h6>
                            </div>
                            <div className='item-detalhes mt-0 pl-1' style={{ flex: 1 }}>
                                <h6><b>Tomador/Cliente </b></h6>
                                <h6 className='mb-1'>{item.cpf_cnpj + ' - ' + item.tomador}</h6>
                            </div>
                            <div className='item-detalhes mt-0 pl-1' style={{ flex: 1 }}>
                                <h6><b>Prestador/Empresa</b></h6>
                                <h6 className='mb-1'>{item.prestador_razao_social}</h6>
                            </div>

                            <div className='item-detalhes mt-0 pl-1' style={{ flex: 1 }}>
                                <h6><b>Data Emissão</b></h6>
                                <h6 className='mb-1'>{item.data_emissao}</h6>
                            </div>
                            <div className='mt-2 row-space-between mb-2' style={{ flexDirection: 'row' }}>
                                <div className='item-detalhes mt-0 pl-1' style={{ flex: 1 }}>
                                    <h6><b>Valor Nota</b></h6>
                                    <h6 className='mb-1'>{item.valor_nota}</h6>
                                </div>
                                <div className='item-detalhes mt-0 pl-1' style={{ flex: 1 }}>
                                    <h6><b>Valor Tributo</b></h6>
                                    <h6 className='mb-1'>{item.valor_tributo}</h6>
                                </div>
                            </div>
                            {/* <div className='col-hora row-space-between'>
                                <span></span>
                                <span className='span-nome'><b>{`${item.usuario ? item.usuario.toUpperCase() : ''}`}</b></span>
                            </div> */}
                        </li>)
                    }
                </ul>
            </div>
            {
                itemDetalhes && itemDetalhes.mapa_horas && itemDetalhes.mapa_horas.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Mapa de Horas</span></h5>
                    </div>
                    : <></>
            }



            {itemDetalhes.mapa_horas && itemDetalhes.mapa_horas.map((item, idx) => {
                return (
                    <>
                        {idx !== 0 ? <br /> : <></>}
                        <div className='detalhes'>
                            <div className='item-detalhes'>
                                <h6><b>Codigo </b></h6>
                                <h6 className='mb-1'>{item.codigo}</h6>
                                <hr />
                                <h6><b>Referência</b></h6>
                                <h6 className='mb-1'>{item.referencia}</h6>
                                <hr />
                                <h6><b>Anotações</b></h6>
                                <h6 className='mb-1'>{item.anotacoes}</h6>
                                <hr />
                                <h6><b>Data Registro</b></h6>
                                <h6 className='mb-1'>{item.data_registro}</h6>
                                {item.html}
                            </div>
                        </div>
                    </>
                )
            })}

        </div>
    </ModalPadraoCadastro >
}

export default DetalhesHistorico