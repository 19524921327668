import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/icones/iconsmind/style.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function renderTooltip(props, nome, classTooltip) {
    delete props.show;
    return <Tooltip className={classTooltip} {...props}>{nome}</Tooltip>;
}

const IconeTooltip = (props) => {
    const { texto, icone } = props
    const classTooltip = props.classTooltip

    return (
        <OverlayTrigger
        popperConfig={{
            modifiers: {
                preventOverflow: {
                    enabled: false
                }
            }
        }}
            placement={props.placement || "bottom"}
            delay={{ show: 25, hide: 25 }}
            overlay={(props) => renderTooltip(props, texto, classTooltip)}>
            {icone === '' || icone === null || icone === undefined ? <span className={props.className}>{props.char}</span> : <span className={icone + " " + (props.className || '')} />}

        </OverlayTrigger>
    )
}


export { IconeTooltip }