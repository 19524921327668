import React, { useState, useEffect, useRef } from 'react'
import { InputMunicipio, InputTipoEndereco } from '../../../../components/InputsAutoComplete'
import { HeaderCadastro } from '../../../../components/Headers'
import { ModalCadastro } from '../../../../components/Modais'
import { Formik, Form } from 'formik'
import { InputPadrao } from '../../../../components/Inputs'
import { BotaoPadrao } from '../../../../components/Botoes'
import { buscarCep } from '../../../../util/Utilitarios'
import Yup from '../../../../util/Validacoes'
import { removeAcentos } from '../../../../util/FuncoesComuns'
import Atalho from '../../../../util/Atalho'

const Cadastro = props => {
    const [pesquisa, setPesquisa] = useState('')
    const [salvando, setSalvando] = useState(false)

    const [msgBotao, setMsgBotao] = useState('Adicionar')

    const emptyValues = {
        id: null,
        tipo: '',
        cep: '',
        logradouro: '',
        complemento: '',
        numero: '',
        bairro: '',
        municipio: '',
        verificador: null
    }

    const inputRef = useRef(null);

    const [values, setValues] = useState(emptyValues)

    const Submit = (endereco, { resetForm }) => {
        let copiaEnderecos = [...props.enderecos]
        setSalvando(true);

        if (endereco.verificador) {
            let index = copiaEnderecos.findIndex(x => x.verificador === endereco.verificador)
            copiaEnderecos[index] = endereco
        }
        else {
            endereco.verificador = Math.random()
            copiaEnderecos.push(endereco)
        }

        props.setEnderecos(copiaEnderecos)
        resetForm({})

        if (msgBotao === 'Alterar')
            props.setAbrirModal(!props.abrirModal)

        setSalvando(false)

    }

    const Delete = index => {
        let copiaEnderecos = [...props.enderecos]
        copiaEnderecos.splice(index, 1);
        props.setEnderecos(copiaEnderecos)
    }

    return (
        <>
            <HeaderCadastro
                titulo={'Endereços'}
                pesquisa={true}
                valorPesquisa={pesquisa}
                onChangePesquisa={pesquisa => setPesquisa(pesquisa)}
                classeChildren='space-between-100' >
                <BotaoPadrao
                    type='button'
                    click={_ => {
                        setValues(emptyValues)
                        props.setAbrirModal(!props.abrirModal);
                        setMsgBotao('Adicionar')
                    }}
                    classe='btn-primary primary p-5rem height-auto m-r-15'
                    texto={<span> Adicionar</span>} />
            </HeaderCadastro>
            <div className='card mb-20'>
                <div className='card-body'>
                    <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                        <thead>
                            <tr>
                                <th style={{ width: '5%' }}>#</th>
                                <th style={{ width: '20%' }}>Tipo</th>
                                <th style={{ width: '20%' }}>Logradouro</th>
                                <th style={{ width: '20%' }}>Setor</th>
                                <th style={{ width: '20%' }}>Município - UF</th>
                                <th style={{ width: '15%' }} className='text-center'>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.enderecos && props.enderecos.length ?
                                props.enderecos.filter(x =>
                                    removeAcentos(x.tipo.label.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase()))
                                    || removeAcentos(x.logradouro.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase()))
                                    || removeAcentos(x.bairro.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase()))
                                    || removeAcentos(x.municipio.label.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())))
                                    .map((endereco, index) =>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{endereco.tipo.label}</td>
                                            <td>{endereco.logradouro}</td>
                                            <td>{endereco.bairro}</td>
                                            <td>{endereco.municipio ? endereco.municipio.label : null}</td>
                                            <td className='text-center'>
                                                <span className='icon-consulta icon-Pen'
                                                    onClick={_ => { setValues(endereco); props.setAbrirModal(true); setMsgBotao('Alterar') }} />
                                                <span className='icon-consulta icon-Column-2' onClick={target => Delete(index)} />
                                            </td>
                                        </tr>
                                    ) : <tr><td colspan='6' className='text-center'>Nenhum Registro Cadastrado</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <ModalCadastro titulo={'Endereço'} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    enableReinitialize={true}
                    initialValues={values}
                    validationSchema={Yup.object({
                        tipo: Yup.string().ensure().required(),
                        cep: Yup.string().ensure().required(),
                        logradouro: Yup.string().ensure().max(200),
                        complemento: Yup.string().ensure().max(200),
                        numero: Yup.string().ensure().max(200),
                        bairro: Yup.string().ensure().max(200).required(),
                        municipio: Yup.string().nullable().ensure().required()
                    })}
                    onSubmit={Submit}>
                    {propsFormik => <Form>
                        <div className='modal-body-cadastro'>
                            <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' obrigatorio={false} />
                            <InputTipoEndereco label='Tipo' name='tipo' placeholder='Tipo' obrigatorio={true}
                                propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} ref={inputRef} autoFocus focarAposSubmit={true} />
                            <InputPadrao label='CEP' name='cep' type='text' placeholder='CEP' obrigatorio={true} mascara='11111-111'
                                onBlur={e => buscarCep(e, propsFormik)} classeTooltip={'tooltip-input-modal'} />
                            <InputPadrao label='Logradouro' name='logradouro' type='text' placeholder='Logradouro'
                                classeTooltip={'tooltip-input-modal'} />
                            <InputPadrao label='Complemento' name='complemento' type='text' placeholder='Complemento'
                                classeTooltip={'tooltip-input-modal'} />
                            <InputPadrao label='Número' name='numero' type='text' placeholder='Número'
                                classeTooltip={'tooltip-input-modal'} />
                            <InputPadrao label='Setor' name='bairro' type='text' placeholder='Setor' obrigatorio={true}
                                classeTooltip={'tooltip-input-modal'} />
                            <InputMunicipio label='Município - UF' name='municipio' placeholder='Município - UF' obrigatorio={true}
                                propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />
                            <Atalho formik={propsFormik} salvando={salvando} />

                        </div>
                        <div className='direita container-botoes'>
                            <BotaoPadrao texto={msgBotao} />
                        </div>
                    </Form>}
                </Formik>
            </ModalCadastro>
        </>)
}

export default Cadastro 