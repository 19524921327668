import React, { useState, useEffect } from 'react'
import DataGrid from '../../../../components/DataGrid'
import { consultar } from '../../../../util/AcessoApi'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'

export default props => {
    const [data, setData] = useState([])

    useEffect(_ => { consultar('/faturamento/cid10', dados => setData(dados.sort((a, b) => b.id - a.id))) }, [props])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-cid10',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Código CID-10',
                    accessor: 'codigo',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Descrição',
                    accessor: 'descricao',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Data de Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data',
                        idColuna: "data_registro",
                        datePicker: true,
                        date: true
                    }
                }
            ]
        }], []
    )

    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/faturamento/cid10' modulo={props.modulo}
                    titulo={'Consulta CID-10'} tituloLista={'Lista CID-10'}  caminhoConsultaFiltros= '/faturamento/cid10paginacao'
                    urlCadastro='/faturamento/cid10/cadastro' icone={'icon-File-ClipboardFileText'} />
            </div>
        </>
    )
}