import { InputGroupSenha, InputPadrao, InputDatePicker } from '../../../../components/Inputs'
import { InputGrupo, InputSimNao, InputTipoUsuario, InputDepartamento, InputSocio, InputEstabelecimentoSaude, InputCirurgiaoCombo } from '../../../../components/InputsAutoComplete'
import { InputCorteImagemS3 } from '../../../../components/InputCorteImagemS3'
import React, { useState, useEffect } from 'react'
import { HeaderCadastro } from '../../../../components/Headers'
import { Formik, Form } from "formik";
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { useParams } from "react-router-dom"
import { mensagens } from '../../../../util/Utilitarios'
import { Link } from 'react-router-dom'
import { salvar, get } from '../../../../util/AcessoApi'
import Yup, { validaData, periodoData, validaEspaco } from '../../../../util/Validacoes'
import ls from 'local-storage'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/login.css'
import Atalho from '../../../../util/Atalho'

const manter = []

const Submit = (values, setIsSubmit, props, salvarNovoObj) => {
    setIsSubmit(true)
    salvar(values, '/controleAcesso/usuario', (ret) => {
        let usuario = ls.get('usuario')

        if (usuario.id === values.id) {
            usuario.login = values.login
            usuario.nome = values.nome

            ls.set('usuario', usuario)
        }

        if (salvarNovoObj.salvarENovo) {
            salvarNovoObj.setLimparFoto(true)
            salvarNovoObj.propsFormik.resetForm({})
            manter.forEach(x => salvarNovoObj.propsFormik.setFieldValue(x, values[x]))
            setIsSubmit(false)
            salvarNovoObj.setSalvarENovo(false)
            salvarNovoObj.setLimparFoto(false)
        }
        else
            props.history.push('/controleAcesso/usuario/consulta')

        mostrarToast('sucesso', mensagens.sucesso)
    }, err => setIsSubmit(false))
}

const CamposCadastro = props => {
    const [salvarENovo, setSalvarENovo] = useState(false)
    let { id } = useParams()
    let { teste } = useParams()
    const [isSubmit, setIsSubmit] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao, setValidacao] = useState({})
    const { history } = props
    const [usuarioTipo, setUsuarioTipo] = useState(null)
    const [limparFoto, setLimparFoto] = useState(false)

    useEffect(() => {
        if (id) {
            Promise.resolve(get(`/controleAcesso/usuario/${id}`)).then(val => {
                if (!val.login)
                    history.push('/controleAcesso/usuario/consulta')

                setValoresIniciais(val)

                setUsuarioTipo(val.usuario_tipo.length && val.usuario_tipo[0] || null)
            })
        } else {
            setValoresIniciais({
                nome: "",
                login: "",
                email: "",
                grupos: "",
                senha: "",
                confirmar_senha: "",
                foto_perfil: '',
                usuario_tipo: '',
                socio: '',
                estabelecimento_saude: '',
                cirurgiao: ''
            })
        }

        Yup.object(validacao)
    }, [])

    let validacaoInicial = Yup.object({
        nome: Yup.string().required().max(100),
        login: Yup.string().required().max(20).test('Caractere Inválido', 'Usuário não pode ter espaço!', function (value) { return validaEspaco(this.parent.login) }),
        email: Yup.string().required().email(),
        grupos: Yup.string().nullable(),
        vigencia_acesso_inicial: Yup.string().required().nullable()
            .test('Vigência de Acesso Inicial', 'Data inválida!', function (value) { return validaData(this.parent.vigencia_acesso_inicial) })
            .test('Vigência de Acesso Inicial', 'Vigência de Acesso Inicial deve ser anterior à Vigência de Acesso Final!',
                function (value) { return periodoData(value, this.parent.vigencia_acesso_final) }),
        vigencia_acesso_final: Yup.string().nullable()
            .test('Vigência de Acesso Final', 'Data inválida!', function (value) { return validaData(this.parent.vigencia_acesso_final) })
            .test('Vigência de Acesso Final', 'Vigência de Acesso Inicial deve ser anterior à Vigência de Acesso Final!',
                function (value) { return periodoData(this.parent.vigencia_acesso_inicial, value) }),
        ativo: Yup.string().nullable().required(),
        foto_perfil: Yup.string().nullable(),
        usuario_tipo: Yup.string().nullable().required(),
        socio: Yup.string().nullable(),
        estabelecimento_saude: Yup.string().nullable(),
        cirurgiao: Yup.string().nullable(),
    })

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => {
                const salvarNovoObj = {
                    setSalvarENovo,
                    salvarENovo,
                    propsFormik,
                    setLimparFoto
                }
                Submit(values, setIsSubmit, props, salvarNovoObj)
            }}>
            {propsFormik => (
                <Form>
                    <div className={props.modal ? '' : 'card mb-20'}>
                        <div className={props.modal ? '' : 'card-body'}>
                            <div className='row'>
                                <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={false} />
                                <InputCorteImagemS3 width={300} height={300} classe='display-block' label="Foto de Perfil" name="foto_perfil" setSalvando={setIsSubmit} diretorioS3="usuario/DDMMYYYY/"
                                    tamanho='col-12' limparFoto={limparFoto} />
                                <InputPadrao label="Nome" name="nome" type="text" placeholder="Nome" obrigatorio={true} tamanho='col-lg-6' autoFocus maxLength={100}
                                    salvarENovo={salvarENovo} />
                                <InputPadrao label="Usuário" name="login" type="text" placeholder="Usuário" obrigatorio={true} style={{ textTransform: 'lowercase' }} tamanho='col-lg-6' lower={true} maxLength={20} />
                                <InputPadrao label="E-mail" name="email" type="mail" placeholder="E-mail" obrigatorio={true} tamanho='col-lg-6' lower={true} maxLength={100} />
                                <InputGrupo multiplo={true} label="Grupos" name="grupos" placeholder="Grupos" propsFormik={propsFormik} botaoVisivel={true} modal
                                    tamanho='col-lg-6' />
                                <InputGroupSenha label="Senha" name="senha" placeholder="Senha" tamanho={'col-lg-4 ' + (valoresIniciais.id ? 'd-none' : '')} maxLength={30} />
                                <InputGroupSenha label="Confirmar Senha" name="confirmar_senha" placeholder="Confirmar Senha"
                                    tamanho={'col-lg-4 ' + (valoresIniciais.id ? 'd-none' : '')} maxLength={30} />
                                <InputDatePicker label="Vigência de Acesso Inicial" name="vigencia_acesso_inicial" obrigatorio type="text"
                                    setarValor={value => { propsFormik.setFieldValue("vigencia_acesso_inicial", value) }} tamanho={`${id && 'col-lg-3' || 'col-lg-4'}`} />
                                <InputDatePicker label="Vigência de Acesso Final" name="vigencia_acesso_final" type="text"
                                    setarValor={value => { propsFormik.setFieldValue("vigencia_acesso_final", value) }} tamanho={`${id && 'col-lg-3' || 'col-lg-4'}`} />
                                <InputSimNao isClearable={true} tamanho={`${id && 'col-lg-3' || 'col-lg-4'}`} label="Ativo?" noOptionsMessage={_ => ''} name="ativo" type="select"
                                    obrigatorio propsFormik={propsFormik} placeholder="Ativo?" />
                                <InputTipoUsuario usuarioTipo={usuarioTipo} setUsuarioTipo={setUsuarioTipo} label="Tipo" tamanho={`${valoresIniciais.id && 'col-lg-3' || 'col-lg-4'}`} name="usuario_tipo"
                                    obrigatorio propsFormik={propsFormik} />
                                <InputDepartamento label="Departamento" placeholder="Departamento" tamanho={usuarioTipo && usuarioTipo.value === 1 && 'col-lg-4' || 'd-none'}
                                    name="departamento" multiplo botaoVisivel={true} propsFormik={propsFormik} />
                                <InputSocio label="Sócio" name="socio" placeholder="Sócio" propsFormik={propsFormik}
                                    tamanho={usuarioTipo && (usuarioTipo.value === 2 || usuarioTipo.value === 3) && 'col-lg-4' || 'd-none'} />
                                <InputEstabelecimentoSaude obrigatorio tamanho={usuarioTipo && usuarioTipo.value === 4 && 'col-lg-4' || 'd-none'} label="Estabelecimento(s) de Saúde"
                                    name="estabelecimento_saude" placeholder="Estabelecimento de Saúde" propsFormik={propsFormik} multiplo={true} botaoVisivel={true} />
                                <InputCirurgiaoCombo multiplo={true} tamanho={usuarioTipo && usuarioTipo.value === 4 && 'col-lg-4' || 'd-none'} label="Cirurgião" name="cirurgiao" placeholder="Cirurgião"
                                    propsFormik={propsFormik} botaoVisivel={true} />
                                <Atalho formik={propsFormik} salvando={isSubmit} setSalvareNovo={setSalvarENovo} salvarENovo={true} />

                            </div>
                        </div>
                    </div>
                    <div className='separator mt-40 mb-5'></div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Salvar' disabled={isSubmit} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                        {!id && <BotaoPadrao texto='Salvar e Novo' disabled={isSubmit} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                    </div>
                </Form>)}
        </Formik>
    )
}

const Cadastro = props => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <a href='#'>Controle de Acesso</a>,
        () => <Link className="link-active" to={'/controleAcesso/usuario/consulta'}>Usuário</Link>,
    ]

    return (
        <>
            <HeaderCadastro titulo={'Cadastro de Usuário'} link={links} />
            <CamposCadastro {...props} />
        </>
    )
}

export default Cadastro
export { CamposCadastro }