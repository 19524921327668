import React, { useEffect, useState } from 'react'
import { AcessoRapido, AuditoriaAgenda, ProximosAgendamentos, GraficosFaturamento } from '../../components/ContentCards'
import { getUsuario } from '../../util/Configuracoes'
import { get } from '../../util/AcessoApi'

import { Link } from 'react-router-dom'

const Dashboard = (props) => {
    const user = getUsuario()
    const [permissoes, setPermissoes] = useState([])
    const [urlProducaoFaturamento, setUrlProducaoFaturamento] = useState()


    useEffect(() => {
        Promise.resolve(get('/controleAcesso/permissao/obterTodas', { usuario_id: user.id })).then(
            (val) => setPermissoes(val))
    }, [])

    useEffect(() => {
        Promise.resolve(get('/parametros/analisedados/urlProducaoFaturamento', null, null)).then(
            (val) => {
                setUrlProducaoFaturamento(val.url)
            })
    }, [])

    return (<>
        <div className='row'>
            <GraficosFaturamento permissoes={permissoes} urlProducaoFaturamento={urlProducaoFaturamento} />
            {
                user?.tipo != 2 ?
                    <>
                        <AcessoRapido permissoes={permissoes} />
                        <ProximosAgendamentos permissoes={permissoes} />
                        <AuditoriaAgenda permissoes={permissoes} />
                    </> : <></>
            }
        </div>
    </>)
}

export default Dashboard
