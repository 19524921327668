import React, { useState, useEffect } from 'react'
import { ConsultaRelatorio } from '../../components/RelatorioPadrao'
import { FiltroObjeto, metodoFiltragem, toStringFiltro } from '../../components/Filtragens'
import { BotaoPadrao } from '../../components/Botoes'
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";

const ListagemPadrao = (props) => {
    let { config } = useParams();
    const [data, setData] = useState([])
    const [dados, setDados] = useState([])
    const [dadosFiltrado, setDadosFiltrado] = useState([])
    const [filtros, setFiltros] = useState([])
    const [filtrosObjs, setFiltrosObjs] = useState([])
    const [atualizar, setAtualizar] = useState(true)
    const [newProps, setNewProps] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})

    useEffect(() => {
        if (atualizar) {
            if (!props.location.listagem) {
                return;
            }
            
            let clone = { ...props.location.listagem };
            setDados(props.location.listagem.dados)
            setFiltros(props.location.listagem.filtros)
            setFiltrosObjs(props.location.listagem.filtrosObj || [])
            setValoresIniciais(props.location.listagem.filtrosValoresIniciais || {})
            setAtualizar(false)
            delete clone.dados
            delete clone.filtros
            delete clone.filtrosObj
            setNewProps(clone)
        }
    }, [props])

    useEffect(() => {
        if (!props.location.listagem) {
            props.history.goBack()
            return;
        }
    }, [])

    useEffect(() => {
        if (dados.length > 0)
            formatarDados(props.location.listagem.dadosFiltrados)
    }, [dados])

    useEffect(() => {
        let filtrosUtilizados = []
        valoresIniciais && Object.keys(valoresIniciais).map(item => {
            if (valoresIniciais[item]) {
                const filtroAtual = filtrosObjs[filtrosObjs.findIndex(filtro => filtro.nome === item)]
                filtrosUtilizados.push({ filtro: filtroAtual.titulo, dado: toStringFiltro(filtroAtual.tipo, valoresIniciais[item])})
            }
        })
        setFiltros(filtrosUtilizados)
    }, [valoresIniciais])

    const formatarDados = (lista) => {
        let cloneDados = JSON.parse(JSON.stringify(dados))
        if (lista && lista.length > 0) {
            let arrayPesquisado = cloneDados[0];
            arrayPesquisado.data = lista
            cloneDados.splice(0, 1, arrayPesquisado)
        }
        setDadosFiltrado(cloneDados)
    }

    const obterListaDistinct = (coluna) => {
        let item = filtrosObjs.filter(item => {
            return item.nome.toString() === coluna.toString()
        })
        let lista = item[0] && item[0].lista ? item[0].lista : []
        lista = lista.length > 0 ? lista.map((item, idx) => ({ label: item, value: idx })) : lista
        return lista;
    }

    const filtrar = (values, propsFormik) => {
        let cloneDados = JSON.parse(JSON.stringify(dados))
        if (values) {
            let arrayPesquisado = cloneDados[0];
            /*Filtrando duas vezes para diminuir a demora da filtragem global*/
            Object.keys(values).filter(item => {
                const colunaAtual = filtrosObjs[filtrosObjs.findIndex(filtro => filtro.nome === item)]
                if(!colunaAtual.colunas)
                    arrayPesquisado.data = metodoFiltragem[colunaAtual.tipo](arrayPesquisado.data, item, values[item])
            })

            Object.keys(values).filter(item => {
                const colunaAtual = filtrosObjs[filtrosObjs.findIndex(filtro => filtro.nome === item)]
                if(colunaAtual.colunas)
                    arrayPesquisado.data = metodoFiltragem[colunaAtual.tipo](arrayPesquisado.data, colunaAtual.colunas, values[item])
            })

            cloneDados.splice(0, 1, arrayPesquisado)
        }
        
        setDadosFiltrado(cloneDados)
        setValoresIniciais(values || {})
    }

    const limparFiltros = () => {
        setValoresIniciais({})
        filtrar()
    }

    return (
        <ConsultaRelatorio dados={dadosFiltrado} filtros={filtros} removerFiltros={() => limparFiltros()} setShowModalFiltros={setShowModal} showModalFiltros={showModal} tituloData={props.location.listagem && props.location.listagem.tituloData ? props.location.listagem.tituloData : null} setFiltros={setFiltros} titulo={props.location.listagem && props.location.listagem.titulo ? props.location.listagem.titulo : ''} history={props.history}>
            {<Formik
                initialValues={valoresIniciais}
                enableReinitialize={true}
                onSubmit={(values, propsFormik) => filtrar(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        {filtrosObjs && filtrosObjs.map(item => {
                            return <FiltroObjeto tipo={item.tipo ? item.tipo : ''}
                                propsFormik={propsFormik}
                                placeholder={item.titulo ? item.titulo : 'Selecione...'}
                                label={item.titulo ? item.titulo : ''}
                                tamanho='col-12'
                                name={item.nome}
                                onChange={option => {option && option.target ? propsFormik.setFieldValue(item.nome, option.target.value) : propsFormik.setFieldValue(item.nome, option)}}
                                data={obterListaDistinct(item.nome)}
                                multiplo={true}
                                autoFocus />
                        })}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>}
        </ConsultaRelatorio>
    )
}

export default ListagemPadrao