import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { visualizar } from '../../../../util/AcessoApi'

const VisualizacaoCirurgiao = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})

    useEffect(() => {
        if (id) {
            visualizar('/administracao/cirurgiao', id,
                (obj) =>{
                    setDados(obj.id  ? {
                        id,
                        modulo: props.modulo,
                        titulo: 'Visualização de Cirurgião',
                        grupos: [
                            {
                                titulo: 'Dados Gerais',
                                colunas: [
                                    { titulo: 'Código', descricao: obj.id, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Data Registro', descricao: obj.data_registro_formatada, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Apelido', descricao: obj.apelido, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Nome Cirurgião', descricao: obj.nome_cirurgiao, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Conselho Profissional', descricao: obj.descricao + ' - ' + obj.sigla, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'N° Conselho', descricao: obj.crm , tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'RQE', descricao: obj.rqe === null ? "" : obj.rqe, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                ]
                            },
                            {
                                tipoGrupo: 'tabela',
                                titulo: 'Telefones',
                                thead:
                                    <tr>
                                        <td className='coluna-6'>Tipo</td>
                                        <td className='coluna-6'>Telefone</td>
                                    </tr>,
                                tbody:
                                    obj.telefone && obj.telefone.sort().map(x =>
                                        <tr>
                                            <td>{x.descricao}</td>
                                            <td>{x.telefone}</td>
                                        </tr>)
                            },
                            {
                                tipoGrupo: 'tabela',
                                titulo: 'CBOS',
                                thead:
                                    <tr>
                                        <td className='coluna-6'>Código</td>
                                        <td className='coluna-6'>Especialidade</td>
                                    </tr>,
                                tbody:
                                    obj.cirurgiaoCbos && obj.cirurgiaoCbos.sort().map(x =>
                                        <tr>
                                            <td>{x.codigo}</td>
                                            <td>{x.label}</td>
                                        </tr>)
                            },
                            {
                                tipoGrupo: 'tabela',
                                titulo: 'Estabelecimentos de Saúde',
                                thead:
                                    <tr>
                                        <td className='coluna-6'>Hospital</td>
                                        <td className='coluna-6'>CNPJ</td>
                                    </tr>,
                                tbody:
                                    obj.cirurgiaoEstabelecimento && obj.cirurgiaoEstabelecimento.sort().map(x =>
                                        <tr>
                                            <td>{x.label}</td>
                                            <td>{x.cnpj}</td>

                                        </tr>)
                            },
                        ]
                    } : { titulo: "Visualização de Cirurgião" })
                })
        }
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default VisualizacaoCirurgiao