import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { ordenarArrayObj } from '../../../../util/FuncoesComuns';

const Visualizacao = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/faturamento/beneficiario', id,
            obj => {
                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização de Beneficiário',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Nome', descricao: obj.nome, tamanho: 'col-lg-4' },
                                { titulo: 'Nome Social', descricao: obj.nome_social, tamanho: 'col-lg-4' },
                                { titulo: 'Data de Nascimento', descricao: obj.data_nascimento, tamanho: 'col-lg-4' },
                                { titulo: 'CPF', descricao: obj.cpf, tamanho: 'col-lg-4' },
                                { titulo: 'RG', descricao: obj.rg, tamanho: 'col-lg-4' },
                                { titulo: 'CNS', descricao: obj.cns, tamanho: 'col-lg-4' },
                                { titulo: 'Plano', descricao: obj.plano, tamanho: 'col-lg-4' },
                                { titulo: 'N° Carteira', descricao: obj.numero_carteira, tamanho: 'col-lg-4' },
                                { titulo: 'Validade Carteira', descricao: obj.validade_carteira, tamanho: 'col-lg-4' },
                                { titulo: 'Operadora Saúde', descricao: obj.operadora_saude && obj.operadora_saude[0].label || "", tamanho: 'col-lg-4' },
                                { titulo: 'Email', descricao: obj.email, tamanho: 'col-lg-4' },
                                { titulo: 'Data de Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                            ]
                        },
                        {
                            titulo: 'Endereço',
                            colunas: [
                                {
                                    titulo: 'CEP',
                                    descricao: obj.cep,
                                    tamanho: 'col-lg-6'
                                },
                                {
                                    titulo: 'Logradouro',
                                    descricao: obj.logradouro,
                                    tamanho: 'col-lg-6'
                                },
                                {
                                    titulo: 'Complemento',
                                    descricao: obj.complemento,
                                    tamanho: 'col-lg-6'
                                },
                                {
                                    titulo: 'Número',
                                    descricao: obj.numero,
                                    tamanho: 'col-lg-6'
                                },
                                {
                                    titulo: 'Bairro',
                                    descricao: obj.bairro,
                                    tamanho: 'col-lg-6'
                                },
                                {
                                    titulo: 'Município - UF',
                                    descricao: obj.nome_municipio,
                                    tamanho: 'col-lg-6'
                                }
                            ]
                        }, {
                            tipoGrupo: 'tabela',
                            titulo: 'Telefone(s)',
                            thead:
                                <tr>
                                    <td className='coluna-6'>Tipo</td>
                                    <td className='coluna-6'>Telefone</td>
                                </tr>,
                            tbody: obj.fone == undefined || obj.fone.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                ordenarArrayObj(obj.fone, 'id').map(permissao =>
                                    <tr>
                                        <td>{permissao.tipo_telefone.label}</td>
                                        <td>{permissao.telefone}</td>
                                    </tr>)
                        }
                    ]
                } : { titulo: 'Visualização de Beneficiário' })
            })
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default Visualizacao