import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import {
    InputTipoRelatorio, InputSimNao, InputOperadoraSaude, InputProcedimentoCodigo, InputSocioAtivos,
    InputEmpresa, InputAcomodacao, InputTipoAtendimento, InputTipoAtendimentoEquipe,
    InputTipoGuia, InputTipoExecutante, InputEstabelecimentoSaude, InputLoteGuia, InputGuiaStatusRelatorio, InputLoteStatus, InputStatusEnvio, InputCirurgiaoCombo, InputContratoFiltro, InputContratoFiltroParticular,
    InputTipoRelatorioPersonalizado
} from '../../../components/InputsAutoComplete'
import { InputDatePicker, InputTimePicker, InputMonthPicker } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes'
import { getUsuario } from '../../../util/Configuracoes'
import moment from "moment"
import { Link } from 'react-router-dom';
import { convertValueToFloat, formatarMoeda } from '../../../util/Utilitarios';
import { ordenarMultiplo } from '../../../components/Ordenamentos';
import { mostrarToast } from '../../../components/Toasts';
import { ordenarArrayObj } from '../../../util/FuncoesComuns';
import { MODULOS_SISTEMA, RETENCOES } from '../../../util/Enums';
import { TableVirtuoso } from 'react-virtuoso'
import MensagemPadraoRelatorio from '../../../components/RelatorioEspecifico/RelatorioSemDados'
import { InputUsuario } from '../../../components/InputsAutoCompleteV2';

export default props => {
    const startOfMonth = moment().clone().format('DD/MM/YYYY')
    const endOfMonth = moment().clone().format('DD/MM/YYYY')
    const actualMonth = moment().clone().format('MM/YYYY')
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Resumo')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '', data_inicial: startOfMonth, data_final: endOfMonth, competencia: actualMonth, rotina: { value: 'false', label: 'Não' } })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)
    const user = getUsuario()
    const ref = useRef(null)
    const [ordenamento, setOrdenamento] = useState([])

    const tipoRelatorio = [
        { label: 'Resumo Faturamento por Operadora de Saúde', value: MODULOS_SISTEMA.RELATORIO_RESUMO_FATURAMENTO_OPERADORA_SAUDE },
        { label: 'Resumo Faturamento por Estabelecimento de Saúde', value: MODULOS_SISTEMA.RELATORIO_RESUMO_FATURAMENTO_ESTABELECIMENTO_SAUDE },
        { label: 'Relatório Analítico por Estabelecimento de Saúde', value: MODULOS_SISTEMA.RELATORIO_ANALITICO_ESTABELECIMENTO_SAUDE },
        { label: 'Relatório Sintético Produção Digitação', value: MODULOS_SISTEMA.RELATORIO_SINTETICO_PRODUCAO_DIGITACAO },
        { label: 'Relatório Analítico Produção Digitação', value: MODULOS_SISTEMA.RELATORIO_ANALITICO_PRODUCAO_DIGITACAO },
        { label: 'Relatório Sintético Guias Conferidas', value: MODULOS_SISTEMA.RELATORIO_DE_GUIAS_CONFERIDA_SINTETICO },
        { label: 'Relatório Analítico Guias Conferidas', value: MODULOS_SISTEMA.RELATORIO_DE_GUIAS_CONFERIDA_ANALITICO },
    ]

    // user.tipo !== 4 && tipoRelatorio.push({ label: 'Estabelecimento de Saúde Descoberto', value: 2 })

    const [validacao, setValidacao] = useState(Yup.object({
        data_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) }),
        data_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) })
    }))

    const filtrosPadrao = propsFormik => [
        <InputMonthPicker label="Competência" name="competencia" type="text"
            setarValor={value => { propsFormik.setFieldValue("competencia", value) }} classeTooltip={'tooltip-input-modal'} />,
        <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
            setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
        <InputLoteGuia multiplo label="Lote" name="lote" placeholder="Lote" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
        <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputEstabelecimentoSaude label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputContratoFiltro label="Contrato Convênio" placeholder="Contrato Convênio" name="contrato_convenio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputContratoFiltroParticular label="Contrato Particular" placeholder="Contrato Particular" name="contrato_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputGuiaStatusRelatorio multiplo label="Status Guia" name="status" placeholder="Status Guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputStatusEnvio multiplo label="Status Guia Envio" name="status_envio" placeholder="Status Guia Envio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputAcomodacao label="Acomodação" placeholder="Acomodação" name="acomodacao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputCirurgiaoCombo label="Cirurgião" placeholder="Cirurgião" name="cirurgiao" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputSimNao label="Atendimento RN" placeholder="Atendimento RN" name="atendimento_rn" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputSimNao label="Rotina" placeholder="Rotina" name="rotina" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputSimNao label="Particular" placeholder="Particular" name="guia_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputSimNao label="Guia Complementar" placeholder="Guia Complementar" name="guia_complementar" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputSimNao label="Conferida" placeholder="Conferida" name="pos_conferida" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputTipoAtendimento multiplo label="Tipo Atendimento Guia" name="tipo_atendimento_guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} placeholder="Tipo Atendimento Guia" />,
        <InputDatePicker label="Data Inicial Registro" name="data_inicial_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Final Registro" name="data_final_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_final_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Inicial Procedimento" name="data_inicial_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Final Procedimento" name="data_final_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Inicial Entrega" name="data_inicial_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Final Entrega" name="data_final_entrega" type="text" setarValor={val => propsFormik.setFieldValue("data_final_entrega", val)} modal classeTooltip={'tooltip-input-modal'} />,
        <InputTimePicker label="Hora Inicial Guia" mascara="11:11" name="hora_inicial_guia" type="text" placeholder="Hora Inicial Guia" classeTooltip={'tooltip-input-modal'}
            setarValor={value => { propsFormik.setFieldValue("hora_inicial_guia", value) }} />,
        <InputTimePicker label="Hora Final Guia" mascara="11:11" name="hora_final_guia" type="text" placeholder="Hora Final Guia" classeTooltip={'tooltip-input-modal'}
            setarValor={value => { propsFormik.setFieldValue("hora_final_guia", value) }} />,
        <InputProcedimentoCodigo label="Procedimento" name="procedimento" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputSocioAtivos multiplo label="Executante COOPANESTGO" name="executante_coopanest" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputSocioAtivos multiplo label="Executante EQUIPE" name="executante_equipe" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputTipoAtendimentoEquipe label="Tipo Atendimento EQUIPE" name="tipo_atendimento_equipe" placeholder="Tipo Atendimento EQUIPE" multiplo propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
        <InputTipoExecutante label={'Tipo Executante Guia'} placeholder='Tipo Executante Guia' classeTooltip={'tooltip-input-modal'} name={'tipo_executante'} propsFormik={propsFormik} />,
        <InputTipoGuia multiplo label="Tipo Guia" name="tipo_guia" placeholder="Tipo Guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputLoteStatus multiplo label="Status Lote" name="status_lote" placeholder="Status" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
        <InputStatusEnvio multiplo label="Status Lote Envio" name="status_lote_envio" placeholder="Status Lote Envio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,

    ]
    const calculoValorTotal = (dadosAgr, arrayConfigCol) => {

        //TOTAIS CONVENIO
        const totalPacientesConvenio = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.pacientes).filter(y => y.guia_particular == false).map(x => parseFloat(x.pacientes)).reduce((ant, atual) => ant + atual, 0);
        const valorTotalFaturadoConvenio = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.faturado).filter(y => y.guia_particular == false).map(x => parseFloat(x.faturado)).reduce((ant, atual) => ant + atual, 0);
        const totalProcedimentosConvenio = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.procedimentos).filter(y => y.guia_particular == false).map(x => parseFloat(x.procedimentos)).reduce((ant, atual) => ant + atual, 0);


        //TOTAIS PARTICULAR
        const totalPacientesParticular = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.pacientes).filter(y => y.guia_particular == true).map(x => parseFloat(x.pacientes)).reduce((ant, atual) => ant + atual, 0);
        const totalProcedimentosParticular = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.procedimentos).filter(y => y.guia_particular == true).map(x => parseFloat(x.procedimentos)).reduce((ant, atual) => ant + atual, 0);
        const valorTotalFaturadoParticular = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.faturado).filter(y => y.guia_particular == true).map(x => parseFloat(x.faturado)).reduce((ant, atual) => ant + atual, 0);


        const valorTotal = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.valor_total).map(x => parseFloat(x.valor_total)).reduce((ant, atual) => ant + atual, 0);
        const totalGlosa = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.glosa).map(x => parseFloat(x.glosa)).reduce((ant, atual) => ant + atual, 0);
        const totalAcrescimo = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.acrescimo).map(x => parseFloat(x.acrescimo)).reduce((ant, atual) => ant + atual, 0);
        const totalRecebido = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.recebido).map(x => parseFloat(x.recebido)).reduce((ant, atual) => ant + atual, 0);
        const totalFaturado = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.faturado).map(x => parseFloat(x.faturado)).reduce((ant, atual) => ant + atual, 0);

        const totalPacientes = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.pacientes).map(x => parseFloat(x.pacientes)).reduce((ant, atual) => ant + atual, 0);
        const totalProcedimentos = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.procedimentos).map(x => parseFloat(x.procedimentos)).reduce((ant, atual) => ant + atual, 0);

        return (dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.length > 0 ? <>
            <table>
                <tr><td colspan={dadosAgr[0].colunas ? dadosAgr[0].colunas.length : 0} className={'td-data pr-0'}></td></tr>
                <tr className={'tr-data text-right bold'}>
                    <td width={'50px'} className={'td-data pr-0'}></td>
                    <td width={'49%'} className={'td-data pr-0'}><span>Totais: </span></td>
                    {arrayConfigCol[0].filter(x => x.coluna === 'pacientes' && x.visivel).length > 0 ? <td width={'5%'} className={'td-data pr-0'}><span className={'ml-2'}>{totalPacientes}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'procedimentos' && x.visivel).length > 0 ? <td width={'8%'} className={'td-data pr-0'}><span className={'ml-2'}>{totalProcedimentos}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'faturado_formatado' && x.visivel).length > 0 ? <td width={'8%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(totalFaturado)}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'glosa_formatado' && x.visivel).length > 0 ? <td width={'7%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(totalGlosa)}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'acrescimo_formatado' && x.visivel).length > 0 ? <td width={'7%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(totalAcrescimo)}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'recebido_formatado' && x.visivel).length > 0 ? <td width={'7%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(totalRecebido)}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'valor_total_formatado' && x.visivel).length > 0 ? <td width={'8%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(valorTotal)}</span></td> : <></>}
                </tr>
            </table>
            <table style={{ marginTop: 25 }}>
                <td width={'43%'} style={{ verticalAlign: 'baseline' }}>
                    <table style={{ width: '100%' }}>
                        <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Convênio</span></td></tr>
                        <tr className='tr-data titulo-1'>
                            <td className='text-center bold td-data' width={'30%'}>Total Qtde Paciente</td>
                            <td className='bold td-data' width={'30%'}>Total Qtde Procedimentos</td>
                            <td className='text-right bold td-data' width={'30%'}>Total Faturado (R$)</td>
                        </tr>
                        <tr className='tr-data tr-movimento-receita'>
                            <td className='text-center td-data'>{totalPacientesConvenio}</td>
                            <td className='td-data'>{totalProcedimentosConvenio}</td>
                            <td className='text-right td-data'>{formatarMoeda(valorTotalFaturadoConvenio)}</td>
                        </tr>


                    </table>
                </td>
                <td width={'14%'}></td>
                <td width={'43%'} style={{ verticalAlign: 'baseline' }}>
                    <table style={{ width: '100%' }}>
                        <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Particular</span></td></tr>
                        <tr className='tr-data titulo-1'>
                            <td className='text-center bold td-data' width={'30%'}>Total Qtde Paciente</td>
                            <td className='bold td-data' width={'30%'}>Total Qtde Procedimentos</td>
                            <td className='text-right bold td-data' width={'30%'}>Total Faturado (R$)</td>
                        </tr>
                        <tr className='tr-data tr-movimento-receita'>
                            <td className='text-center td-data'>{totalPacientesParticular}</td>
                            <td className='td-data'>{totalProcedimentosParticular}</td>
                            <td className='text-right td-data'>{formatarMoeda(valorTotalFaturadoParticular)}</td>
                        </tr>


                    </table>
                </td>

            </table>
        </> : <></>)
    }

    const calculoValorTotalES = (dadosAgr, arrayConfigCol) => {

        //TOTAIS CONVENIO
        const totalPacientesConvenio = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.map(x => parseFloat(x.pacientes_convenio)).reduce((ant, atual) => ant + atual, 0);
        const valorTotalFaturadoConvenio = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.map(x => parseFloat(x.total_convenio)).reduce((ant, atual) => ant + atual, 0);
        const totalProcedimentosConvenio = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.map(x => parseFloat(x.procedimentos_convenio)).reduce((ant, atual) => ant + atual, 0);


        //TOTAIS PARTICULAR
        const totalPacientesParticular = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.map(x => parseFloat(x.pacientes_particular)).reduce((ant, atual) => ant + atual, 0);
        const totalProcedimentosParticular = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.map(x => parseFloat(x.procedimentos_particular)).reduce((ant, atual) => ant + atual, 0);
        const valorTotalFaturadoParticular = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.map(x => parseFloat(x.total_particular)).reduce((ant, atual) => ant + atual, 0);


        const valorTotal = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.valor_total).map(x => parseFloat(x.valor_total)).reduce((ant, atual) => ant + atual, 0);
        const totalGlosa = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.glosa).map(x => parseFloat(x.glosa)).reduce((ant, atual) => ant + atual, 0);
        const totalAcrescimo = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.acrescimo).map(x => parseFloat(x.acrescimo)).reduce((ant, atual) => ant + atual, 0);
        const totalRecebido = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.recebido).map(x => parseFloat(x.recebido)).reduce((ant, atual) => ant + atual, 0);
        const totalFaturado = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.faturado).map(x => parseFloat(x.faturado)).reduce((ant, atual) => ant + atual, 0);

        const totalPacientes = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.pacientes).map(x => parseFloat(x.pacientes)).reduce((ant, atual) => ant + atual, 0);
        const totalProcedimentos = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.procedimentos).map(x => parseFloat(x.procedimentos)).reduce((ant, atual) => ant + atual, 0);

        return (dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.length > 0 ? <>
            <table>
                <tr><td colspan={dadosAgr[0].colunas ? dadosAgr[0].colunas.length : 0} className={'td-data pr-0'}></td></tr>
                <tr className={'tr-data text-right bold'}>
                    <td width={'50px'} className={'td-data pr-0'}></td>
                    <td width={'49%'} className={'td-data pr-0'}><span>Totais: </span></td>
                    {arrayConfigCol[0].filter(x => x.coluna === 'pacientes' && x.visivel).length > 0 ? <td width={'5%'} className={'td-data pr-0'}><span className={'ml-2'}>{totalPacientes}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'procedimentos' && x.visivel).length > 0 ? <td width={'8%'} className={'td-data pr-0'}><span className={'ml-2'}>{totalProcedimentos}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'faturado_formatado' && x.visivel).length > 0 ? <td width={'8%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(totalFaturado)}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'glosa_formatado' && x.visivel).length > 0 ? <td width={'7%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(totalGlosa)}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'acrescimo_formatado' && x.visivel).length > 0 ? <td width={'7%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(totalAcrescimo)}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'recebido_formatado' && x.visivel).length > 0 ? <td width={'7%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(totalRecebido)}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'valor_total_formatado' && x.visivel).length > 0 ? <td width={'8%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(valorTotal)}</span></td> : <></>}
                </tr>
            </table>
            <table style={{ marginTop: 25 }}>
                <td width={'43%'} style={{ verticalAlign: 'baseline' }}>
                    <table style={{ width: '100%' }}>
                        <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Convênio</span></td></tr>
                        <tr className='tr-data titulo-1'>
                            <td className='text-center bold td-data' width={'30%'}>Qtde. Pac. Conv.</td>
                            <td className='text-center bold td-data' width={'30%'}>Qtde. Proc. Conv.</td>
                            <td className='text-right bold td-data' width={'30%'}>Total Faturado</td>
                        </tr>
                        <tr className='tr-data tr-movimento-receita'>
                            <td className='text-center td-data'>{totalPacientesConvenio}</td>
                            <td className='text-center td-data'>{totalProcedimentosConvenio}</td>
                            <td className='text-right td-data'>{formatarMoeda(valorTotalFaturadoConvenio)}</td>
                        </tr>


                    </table>
                </td>
                <td width={'14%'}></td>
                <td width={'43%'} style={{ verticalAlign: 'baseline' }}>
                    <table style={{ width: '100%' }}>
                        <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Particular</span></td></tr>
                        <tr className='tr-data titulo-1'>
                            <td className='text-center bold td-data' width={'30%'}>Qtde. Pac. Part.</td>
                            <td className='text-center bold td-data' width={'30%'}>Qtde. Proc. Part.</td>
                            <td className='text-right bold td-data' width={'30%'}>Total Faturado</td>
                        </tr>
                        <tr className='tr-data tr-movimento-receita'>
                            <td className='text-center td-data'>{totalPacientesParticular}</td>
                            <td className='text-center td-data'>{totalProcedimentosParticular}</td>
                            <td className='text-right td-data'>{formatarMoeda(valorTotalFaturadoParticular)}</td>
                        </tr>


                    </table>
                </td>

            </table>
        </> : <></>)
    }

    useEffect(() => {
        if (relatorioAtual.data && relatorioAtual.data[0]) {
            let cloneRelatorioAtual = { ...relatorioAtual }
            const dados = cloneRelatorioAtual.data
            cloneRelatorioAtual.data = ordenarMultiplo(dados, ref.current.obterOrdenamentosMultiplos(0))
            setDados([{
                numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: dados, classeTr: cloneRelatorioAtual.classeTr,
                aposAgrupamento: cloneRelatorioAtual.aposAgrupamento, aposRelatorio: cloneRelatorioAtual.aposRelatorio,
                relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico
                    ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento) : null
            }])
            setRelatorioAtual(cloneRelatorioAtual)
        }
    }, [ordenamento])

    const relatoriosPorTipo = propsFormik => [
        {
            titulo: 'Resumo Faturamento por Operadora de Saúde',
            tipo: MODULOS_SISTEMA.RELATORIO_RESUMO_FATURAMENTO_OPERADORA_SAUDE,
            url: '/relatorio/resumoOperadoraSaude',
            aposRelatorio: calculoValorTotal,
            campos: filtrosPadrao(propsFormik),
            classeTr: 'linha-bottom-relatorio',
            colunas: [
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%' },
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '71%' },
                { label: 'Qtd. Pacientes', name: 'pacientes', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '7%' },
                { label: 'Qtd. Procedimentos', name: 'procedimentos', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '8%' },
                // { label: 'Faturado', name: 'faturado_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '8%' },
                // { label: 'Glosa', name: 'glosa_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%' },
                // { label: 'Acrescimo', name: 'acrescimo_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%' },
                // { label: 'Recebido', name: 'recebido_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%' },
                { label: 'Faturado', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '8%' }
            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Lote', name: 'lote', name_coluna: 'lote' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Status Guia', name: 'status', name_coluna: 'status' },
                { label: 'Status Guia Envio', name: 'status_envio', name_coluna: 'status_envio' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Acomodação', name: 'acomodacao_id', name_coluna: 'acomodacao' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Atendimento RN', name: 'atendimento_rn', name_coluna: 'atendimento_rn' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento' },
                { label: 'Executante COOPANESTGO', name: 'executante_coopanest_id', name_coluna: 'executante_coopanest' },
                { label: 'Executante EQUIPE', name: 'executante_equipe_id', name_coluna: 'executante_equipe' },
                { label: 'Tipo Atendimento EQUIPE', name: 'tipo_atendimento_equipe_id', name_coluna: 'tipo_atendimento_equipe' },
                { label: 'Tipo Executante Guia', name: 'guia_tipo_executante_id', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'guia_tipo_id', name_coluna: 'tipo_guia' },
                { label: 'Data Inicial Registro', name: 'data_registro', name_coluna: 'data_inicial_registro' },
                { label: 'Data Final Registro', name: 'data_registro', name_coluna: 'data_final_registro' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Inicial Entrega', name: 'data_entrega', name_coluna: 'data_inicial_entrega' },
                { label: 'Data Final Entrega', name: 'data_entrega', name_coluna: 'data_final_entrega' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial_guia' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final_guia' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Complementar', name: 'guia_complementar', name_coluna: 'guia_complementar' },
                { label: 'Conferida', name: 'pos_conferida', name_coluna: 'pos_conferida' },
                { label: 'Status Lote', name: 'status_lote', name_coluna: 'status_lote' },
                { label: 'Status Lote Envio', name: 'status_lote_envio', name_coluna: 'status_lote_envio' },
                { label: 'Contrato Convênio', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Contrato Particular', name: 'contrato_particular', name_coluna: 'contrato_particular' }
            ]
        },
        {
            titulo: 'Resumo Faturamento por Estabelecimento de Saúde',
            tipo: MODULOS_SISTEMA.RELATORIO_RESUMO_FATURAMENTO_ESTABELECIMENTO_SAUDE,
            url: '/relatorio/resumoEstabelecimentoSaude',
            aposRelatorio: calculoValorTotalES,
            campos: filtrosPadrao(propsFormik),
            classeTr: 'linha-bottom-relatorio',
            colunas: [
                { label: 'Código', name: 'codigo', visivel: false, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Estabelecimento de Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, },
                { label: 'Qtde. Pac. Conv.', name: 'pacientes_convenio', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right' },
                { label: 'Qtde. Pac. Part.', name: 'pacientes_particular', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right' },
                { label: 'Qtde. Proc. Conv.', name: 'procedimentos_convenio', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right' },
                { label: 'Qtde. Proc. Part.', name: 'procedimentos_particular', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right' },
                { label: 'Faturado Conv.', name: 'total_convenio_formatado', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right' },
                { label: 'Faturado Part.', name: 'total_particular_formatado', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right' },
                { label: 'Total', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right' }
            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Lote', name: 'lote', name_coluna: 'lote' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Status Guia', name: 'status', name_coluna: 'status' },
                { label: 'Status Guia Envio', name: 'status_envio', name_coluna: 'status_envio' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Acomodação', name: 'acomodacao_id', name_coluna: 'acomodacao' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Atendimento RN', name: 'atendimento_rn', name_coluna: 'atendimento_rn' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento' },
                { label: 'Executante COOPANESTGO', name: 'executante_coopanest_id', name_coluna: 'executante_coopanest' },
                { label: 'Executante EQUIPE', name: 'executante_equipe_id', name_coluna: 'executante_equipe' },
                { label: 'Tipo Atendimento EQUIPE', name: 'tipo_atendimento_equipe_id', name_coluna: 'tipo_atendimento_equipe' },
                { label: 'Tipo Executante Guia', name: 'guia_tipo_executante_id', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'guia_tipo_id', name_coluna: 'tipo_guia' },
                { label: 'Data Inicial Registro', name: 'data_registro', name_coluna: 'data_inicial_registro' },
                { label: 'Data Final Registro', name: 'data_registro', name_coluna: 'data_final_registro' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Inicial Entrega', name: 'data_entrega', name_coluna: 'data_inicial_entrega' },
                { label: 'Data Final Entrega', name: 'data_entrega', name_coluna: 'data_final_entrega' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial_guia' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final_guia' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Complementar', name: 'guia_complementar', name_coluna: 'guia_complementar' },
                { label: 'Conferida', name: 'pos_conferida', name_coluna: 'pos_conferida' },
                { label: 'Status Lote', name: 'status_lote', name_coluna: 'status_lote' },
                { label: 'Status Lote Envio', name: 'status_lote_envio', name_coluna: 'status_lote_envio' },
                { label: 'Contrato Convênio', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Contrato Particular', name: 'contrato_particular', name_coluna: 'contrato_particular' }
            ]
        },
        {
            titulo: 'Relatório Sintético Produção Digitação',
            tipo: MODULOS_SISTEMA.RELATORIO_SINTETICO_PRODUCAO_DIGITACAO,
            aposRelatorio: dados => {
                return (
                    <><table>
                        <tr><td colspan={12} className={'td-data pr-0 text-right bold'}>Total: {dados[0]?.data.map(x => x.qtd_guias).reduce((total, num) => parseFloat(total) + parseFloat(num))}</td></tr>
                    </table> </>)
            },
            url: '/relatorio/resumoGuiaSintetico',
            campos: [
                <InputMonthPicker label="Competência" name="competencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("competencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputUsuario label="Usuário" name="usuario" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSimNao label="Rotina" placeholder="Rotina" name="rotina" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Particular" placeholder="Particular" name="guia_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Conferida" placeholder="Conferida" name="pos_conferida" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputGuiaStatusRelatorio multiplo label="Status Guia" name="status" placeholder="Status Guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputDatePicker label="Data Inicial Registro" name="data_inicial_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Registro" name="data_final_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_final_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Inicial Procedimento" name="data_inicial_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Procedimento" name="data_final_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Inicial Digitação" name="data_inicial_digitacao" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_digitacao", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Digitação" name="data_final_digitacao" type="text" setarValor={val => propsFormik.setFieldValue("data_final_digitacao", val)} modal classeTooltip={'tooltip-input-modal'} />,
            ],
            classeTr: 'linha-bottom-relatorio',
            colunas: [
                { label: 'Usuário', name: 'usuario', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '93%' },
                { label: 'Qtd. Guias', name: 'qtd_guias', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '7%' },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Status Guia', name: 'status', name_coluna: 'status' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Conferida', name: 'pos_conferida', name_coluna: 'pos_conferida' },
                { label: 'Usuário', name: 'usuario', name_coluna: 'usuario' },
                { label: 'Data Inicial Registro', name: 'data_registro', name_coluna: 'data_inicial_registro' },
                { label: 'Data Final Registro', name: 'data_registro', name_coluna: 'data_final_registro' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Inicial Digitação', name: 'data_registro', name_coluna: 'data_inicial_digitacao' },
                { label: 'Data Final Digitação', name: 'data_registro', name_coluna: 'data_final_digitacao' },

            ]
        },
        {
            titulo: 'Resumo Análitico Produção Digitação',
            tipo: MODULOS_SISTEMA.RELATORIO_ANALITICO_PRODUCAO_DIGITACAO,
            url: '/relatorio/resumoGuiaAnalitico',
            campos: [
                <InputMonthPicker label="Competência" name="competencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("competencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputUsuario label="Usuário" name="usuario" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSimNao label="Conferida" placeholder="Conferida" name="pos_conferida" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Rotina" placeholder="Rotina" name="rotina" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Particular" placeholder="Particular" name="guia_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputGuiaStatusRelatorio multiplo label="Status Guia" name="status" placeholder="Status Guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputDatePicker label="Data Inicial Registro" name="data_inicial_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Registro" name="data_final_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_final_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Inicial Procedimento" name="data_inicial_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Procedimento" name="data_final_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Inicial Digitação" name="data_inicial_digitacao" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_digitacao", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Digitação" name="data_final_digitacao" type="text" setarValor={val => propsFormik.setFieldValue("data_final_digitacao", val)} modal classeTooltip={'tooltip-input-modal'} />,
            ],
            classeTr: 'linha-bottom-relatorio',
            agrupado: true,
            colunas: [
                { label: '', name: 'usuario', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, width: '100%', desabilitarCheckColuna: true },
                // { label: '#', name: '#', visivel: true, alinhamento: 'text-center', classe: 'bold', width: '1%' },
                { label: 'Atend', name: 'guia_id', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', name: 'paciente', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Operadora Saúde', name: 'operadora', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Horário Inserção', name: 'horario_insercao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, },
            ],
            data: [],
            coluna_agrupar: 'guias_csv',
            colunasCsv: [

                { label: 'Usuario', name: 'usuario_csv', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Data Registro', name: 'data_registro_csv', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Atend', name: 'guia_id', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', name: 'paciente', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Operadora Saúde', name: 'operadora', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Horário Inserção', name: 'horario_insercao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, },
            ],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Status Guia', name: 'status', name_coluna: 'status' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Conferida', name: 'pos_conferida', name_coluna: 'pos_conferida' },
                { label: 'Usuário', name: 'usuario', name_coluna: 'usuario' },
                { label: 'Data Inicial Registro', name: 'data_registro', name_coluna: 'data_inicial_registro' },
                { label: 'Data Final Registro', name: 'data_registro', name_coluna: 'data_final_registro' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Inicial Digitação', name: 'data_registro', name_coluna: 'data_inicial_digitacao' },
                { label: 'Data Final Digitação', name: 'data_registro', name_coluna: 'data_final_digitacao' },

            ],
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioGuiaAnalitico(dados, arrayConfigCol, configOrdenamento, htmlHeader),

        },
        {
            titulo: 'Relatório Analítico por Estabelecimento de Saúde',
            tipo: MODULOS_SISTEMA.RELATORIO_ANALITICO_ESTABELECIMENTO_SAUDE,
            url: '/relatorio/relatorioAgrupadorPorEstabelecimentoSaude',
            campos: filtrosPadrao(propsFormik),
            classeTr: 'linha-bottom-relatorio',
            repetirColunas: true,
            agrupado: true,
            ordemColunas: ['estabelecimento_saude', 'empresa'],
            colunasCsv: [{ label: 'Estabelecimento', name: 'estabelecimento_saude', visivel: false, ordenamento: { tipo: 'text', ativo: false }, width: '5%' },
            { label: 'Empresa', name: 'empresa', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '10%' },
            { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '42%' },
            { label: 'Qtd. Paciente', name: 'pacientes', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '7%' },
            { label: 'Qtd. Procedimentos', name: 'procedimentos', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '8%' },
            { label: 'Faturado', name: 'faturado_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '8%' },
            { label: '%', name: 'porcentagem', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '8%' }
            ],
            colunas: [
                // { label: 'Estabelecimento', name: 'estabelecimento_saude', visivel: false, ordenamento: { tipo: 'text', ativo: false }, width: '5%' },
                { label: 'Empresa', name: 'empresa', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '10%' },
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '42%' },
                { label: 'Qtd. Paciente', name: 'pacientes', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '7%' },
                { label: 'Qtd. Procedimentos', name: 'procedimentos', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '8%' },
                { label: 'Faturado', name: 'faturado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '8%' },
                { label: 'Faturado', name: 'faturado_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '8%' },
                { label: '%', name: 'porcentagem', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '8%' }
            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Lote', name: 'lote', name_coluna: 'lote' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Status Guia', name: 'status', name_coluna: 'status' },
                { label: 'Status Guia Envio', name: 'status_envio', name_coluna: 'status_envio' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Acomodação', name: 'acomodacao_id', name_coluna: 'acomodacao' },
                { label: 'Cirurgião', name: 'cirurgiao_id', name_coluna: 'cirurgiao' },
                { label: 'Atendimento RN', name: 'atendimento_rn', name_coluna: 'atendimento_rn' },
                { label: 'Tipo Atendimento Guia', name: 'tipo_atendimento_id', name_coluna: 'tipo_atendimento_guia' },
                { label: 'Procedimento', name: 'procedimento_id', name_coluna: 'procedimento' },
                { label: 'Executante COOPANESTGO', name: 'executante_coopanest_id', name_coluna: 'executante_coopanest' },
                { label: 'Executante EQUIPE', name: 'executante_equipe_id', name_coluna: 'executante_equipe' },
                { label: 'Tipo Atendimento EQUIPE', name: 'tipo_atendimento_equipe_id', name_coluna: 'tipo_atendimento_equipe' },
                { label: 'Tipo Executante Guia', name: 'guia_tipo_executante_id', name_coluna: 'tipo_executante' },
                { label: 'Tipo Guia', name: 'guia_tipo_id', name_coluna: 'tipo_guia' },
                { label: 'Data Inicial Registro', name: 'data_registro', name_coluna: 'data_inicial_registro' },
                { label: 'Data Final Registro', name: 'data_registro', name_coluna: 'data_final_registro' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Inicial Entrega', name: 'data_entrega', name_coluna: 'data_inicial_entrega' },
                { label: 'Data Final Entrega', name: 'data_entrega', name_coluna: 'data_final_entrega' },
                { label: 'Hora Inicial', name: 'hora_inicial', name_coluna: 'hora_inicial_guia' },
                { label: 'Hora Final', name: 'hora_final', name_coluna: 'hora_final_guia' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Complementar', name: 'guia_complementar', name_coluna: 'guia_complementar' },
                { label: 'Conferida', name: 'pos_conferida', name_coluna: 'pos_conferida' },
                { label: 'Status Lote', name: 'status_lote', name_coluna: 'status_lote' },
                { label: 'Status Lote Envio', name: 'status_lote_envio', name_coluna: 'status_lote_envio' },
                { label: 'Contrato Convênio', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Contrato Particular', name: 'contrato_particular', name_coluna: 'contrato_particular' }
            ],
            virtuoso: true,
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioEstabelecimentoEspecifico(dados, arrayConfigCol, configOrdenamento, htmlHeader),
            relatorioEspecificoImpressao: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioEspecificoImpressao(dados, arrayConfigCol, configOrdenamento, htmlHeader)
        },
        {
            titulo: 'Relatório Sintético Guias Conferidas',
            tipo: MODULOS_SISTEMA.RELATORIO_DE_GUIAS_CONFERIDA_SINTETICO,
            aposRelatorio: dados => {
                return (
                    <><table>
                        <tr><td colspan={12} className={'td-data pr-0 text-right bold'}>Total: {dados[0]?.data.map(x => x.qtd_guias).reduce((total, num) => parseFloat(total) + parseFloat(num))}</td></tr>
                    </table> </>)
            },
            url: '/relatorio/resumoguiasconferidassintetico',
            campos: [
                <InputMonthPicker label="Competência" name="competencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("competencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputUsuario label="Usuário" name="usuario" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputSimNao label="Rotina" placeholder="Rotina" name="rotina" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Particular" placeholder="Particular" name="guia_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Conferida" placeholder="Conferida" name="pos_conferida" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                // <InputGuiaStatusRelatorio multiplo label="Status Guia" name="status" placeholder="Status Guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputTimePicker label="Hora Inicial Conferência" mascara="11:11" name="hora_inicial_conferencia" type="text" placeholder="Hora Inicial Conferência" classeTooltip={'tooltip-input-modal'}
                    setarValor={value => { propsFormik.setFieldValue("hora_inicial_conferencia", value) }} />,
                <InputTimePicker label="Hora Final Conferência" mascara="11:11" name="hora_final_conferencia" type="text" placeholder="Hora Final Conferência" classeTooltip={'tooltip-input-modal'}
                    setarValor={value => { propsFormik.setFieldValue("hora_final_conferencia", value) }} />,
                <InputDatePicker label="Data Inicial Conferência" name="data_inicial_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Conferência" name="data_final_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_final_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Inicial Procedimento" name="data_inicial_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Procedimento" name="data_final_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Inicial Digitação" name="data_inicial_digitacao" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_digitacao", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Digitação" name="data_final_digitacao" type="text" setarValor={val => propsFormik.setFieldValue("data_final_digitacao", val)} modal classeTooltip={'tooltip-input-modal'} />,
            ],
            classeTr: 'linha-bottom-relatorio',
            colunas: [
                { label: 'Usuário', name: 'usuario', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '93%' },
                { label: 'Qtd. Guias', name: 'qtd_guias', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '7%' },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Status Guia', name: 'status', name_coluna: 'status' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Conferida', name: 'pos_conferida', name_coluna: 'pos_conferida' },
                { label: 'Usuário', name: 'usuario', name_coluna: 'usuario' },
                { label: 'Hora Inicial Conferencia', name: 'hora_inicial', name_coluna: 'hora_inicial_conferencia' },
                { label: 'Hora Final Conferencia', name: 'hora_final', name_coluna: 'hora_final_conferencia' },
                { label: 'Data Inicial Registro', name: 'data_registro', name_coluna: 'data_inicial_registro' },
                { label: 'Data Final Registro', name: 'data_registro', name_coluna: 'data_final_registro' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Inicial Digitação', name: 'data_registro', name_coluna: 'data_inicial_digitacao' },
                { label: 'Data Final Digitação', name: 'data_registro', name_coluna: 'data_final_digitacao' },

            ]
        },
        {
            titulo: 'Relatório Analítico Guias Conferidas',
            tipo: MODULOS_SISTEMA.RELATORIO_DE_GUIAS_CONFERIDA_ANALITICO,
            url: '/relatorio/resumoguiasconferidasanalitico',
            campos: [
                <InputMonthPicker label="Competência" name="competencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("competencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
                <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                // <InputUsuario label="Usuário" name="usuario" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputUsuario label="Usuário" placeholder="Usuário" name="usuario" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Conferida" placeholder="Conferida" name="pos_conferida" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Rotina" placeholder="Rotina" name="rotina" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                <InputSimNao label="Particular" placeholder="Particular" name="guia_particular" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} />,
                // <InputGuiaStatusRelatorio multiplo label="Status Guia" name="status" placeholder="Status Guia" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} menuPlacement='top' />,
                <InputTimePicker label="Hora Inicial Conferência" mascara="11:11" name="hora_inicial_conferencia" type="text" placeholder="Hora Inicial Conferência" classeTooltip={'tooltip-input-modal'}
                    setarValor={value => { propsFormik.setFieldValue("hora_inicial_conferencia", value) }} />,
                <InputTimePicker label="Hora Final Conferência" mascara="11:11" name="hora_final_conferencia" type="text" placeholder="Hora Final Conferência" classeTooltip={'tooltip-input-modal'}
                    setarValor={value => { propsFormik.setFieldValue("hora_final_conferencia", value) }} />,
                <InputDatePicker label="Data Inicial Conferência" name="data_inicial_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Conferência" name="data_final_registro" type="text" setarValor={val => propsFormik.setFieldValue("data_final_registro", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Inicial Procedimento" name="data_inicial_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Procedimento" name="data_final_procedimento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_procedimento", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Inicial Digitação" name="data_inicial_digitacao" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_digitacao", val)} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Final Digitação" name="data_final_digitacao" type="text" setarValor={val => propsFormik.setFieldValue("data_final_digitacao", val)} modal classeTooltip={'tooltip-input-modal'} />,
            ],
            classeTr: 'linha-bottom-relatorio',
            agrupado: true,
            colunas: [
                { label: '', name: 'usuario', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, width: '100%', desabilitarCheckColuna: true },
                // { label: '#', name: '#', visivel: true, alinhamento: 'text-center', classe: 'bold', width: '1%' },
                { label: 'Atend', name: 'guia_id', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', name: 'paciente', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Operadora Saúde', name: 'operadora', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Horário Inserção', name: 'horario_insercao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, },
            ],
            data: [],
            coluna_agrupar: 'guias_csv',
            colunasCsv: [

                { label: 'Usuario', name: 'usuario_csv', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Data Registro', name: 'data_registro_csv', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Atend', name: 'guia_id', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'Paciente', name: 'paciente', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Operadora Saúde', name: 'operadora', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Proc.', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Horário Inserção', name: 'horario_insercao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, },
            ],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Status Guia', name: 'status', name_coluna: 'status' },
                { label: 'Rotina', name: 'rotina', name_coluna: 'rotina' },
                { label: 'Particular', name: 'guia_particular', name_coluna: 'guia_particular' },
                { label: 'Conferida', name: 'pos_conferida', name_coluna: 'pos_conferida' },
                { label: 'Usuário', name: 'usuario', name_coluna: 'usuario' },
                { label: 'Hora Inicial Conferencia', name: 'hora_inicial', name_coluna: 'hora_inicial_conferencia' },
                { label: 'Hora Final Conferencia', name: 'hora_final', name_coluna: 'hora_final_conferencia' },
                { label: 'Data Inicial Registro', name: 'data_registro', name_coluna: 'data_inicial_registro' },
                { label: 'Data Final Registro', name: 'data_registro', name_coluna: 'data_final_registro' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
                { label: 'Data Inicial Digitação', name: 'data_registro', name_coluna: 'data_inicial_digitacao' },
                { label: 'Data Final Digitação', name: 'data_registro', name_coluna: 'data_final_digitacao' },

            ],
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioGuiaAnalitico(dados, arrayConfigCol, configOrdenamento, htmlHeader),

        },
    ]

    const relatorioEspecificoImpressao = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {

        const limitarCaracteres = (str, limite) => {
            if (str && str?.length <= limite) {
                return str;
            } else if (str && str.length) {
                return str.slice(0, limite) + "...";
            }

            return
        }

        return (
            array && array.map((x, idx) => {

                const idxEmpresa = arrayConfigCol[idx].findIndex(y => y.coluna == 'empresa')
                const idxOperadoraSaude = arrayConfigCol[idx].findIndex(y => y.coluna == 'operadora_saude')
                const idxQuantidadePaciente = arrayConfigCol[idx].findIndex(y => y.coluna == 'pacientes')
                const idxQuantidadeProcedimentos = arrayConfigCol[idx].findIndex(y => y.coluna == 'procedimentos')
                const idxFaturado = arrayConfigCol[idx].findIndex(y => y.coluna == 'faturado_formatado')
                const idxPorcentagem = arrayConfigCol[idx].findIndex(y => y.coluna == 'porcentagem')

                return (
                    <div>
                        {
                            x.data.length > 0 ? x.data.map((grupo, idxG) => {

                                return (<>
                                    {idxG > 0 ? htmlHeader : <></>}
                                    <table className='table-agrupamento mb-2'>
                                        {/* <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                            <td className={` bold td-data text-left`}>{limitarCaracteres(grupo.estabelecimento_saude, 45)}</td>

                                        </tr> */}
                                        <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                            <td className={` bold td-data text-left`} style={{ width: '45%' }}>Estabelecimento Saúde</td>
                                            <td className={` bold td-data text-left`}>Qtd. Pacientes</td>
                                            <td className={` bold td-data text-left`}>Qtd. Procedimentos</td>
                                            <td className={` bold td-data text-right`}>Faturado R$</td>
                                            <td className={` bold td-data text-right`}>Percentual(%)</td>

                                        </tr>
                                        <tr className={'tr-data titulo-1'}>
                                            <td className={` bold td-data text-left`}>{limitarCaracteres(grupo.estabelecimento_saude, 85)}</td>
                                            <td className={` bold td-data text-left`}>{grupo.pacientes}</td>
                                            <td className={` bold td-data text-left`}>{grupo.procedimentos}</td>
                                            <td className={` bold td-data text-right`}>{formatarMoeda(grupo.total_faturado)}</td>
                                            <td className={` bold td-data text-right`}>{grupo.porcentagem_estabelecimento}%</td>

                                        </tr>
                                    </table>

                                    <table style={{ width: '100%' }} className='table-agrupamento mb-2'>

                                        <tr className='titulo-1'>
                                            <td className='text-center bold td-data group-title' width={'1%'}>#</td>

                                            {x.colunas && arrayConfigCol[idx][idxOperadoraSaude]?.visivel ? <td className='text-left bold td-data' width={'25%'}>Operadora Saúde</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxEmpresa]?.visivel ? <td className='text-left bold td-data' width={'5%'}>Empresa</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxQuantidadePaciente]?.visivel ? <td className='bold td-data' width={'5%'}>Qtd. Pacientes</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxQuantidadeProcedimentos]?.visivel ? <td className='bold td-data' width={'5%'}>Qtd. Procedimentos</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxFaturado]?.visivel ? <td className='bold td-data text-right' width={'10%'}>Faturado R$</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxPorcentagem]?.visivel ? <td className='bold td-data text-right' width={'8.5%'}>Percentual(%)</td> : <></>}

                                        </tr>

                                        {
                                            grupo.operadoras.map((operadora, index) => {
                                                return (
                                                    <>
                                                        <tr className={'tr-data bt-1'}>
                                                            <td className='text-center td-data'>{index + 1}</td>
                                                            {x.colunas && arrayConfigCol[idx][idxOperadoraSaude]?.visivel ? <td className='text-left td-data'>{operadora.operadora_saude != null ? limitarCaracteres(operadora.operadora_saude, 55) : 'PARTICULAR'}</td> : <></>}
                                                            {x.colunas && arrayConfigCol[idx][idxEmpresa]?.visivel ? <td className='text-left td-data'>{operadora.empresa}</td> : <></>}
                                                            {x.colunas && arrayConfigCol[idx][idxQuantidadePaciente]?.visivel ? <td className='td-data'>{operadora.pacientes}</td> : <></>}
                                                            {x.colunas && arrayConfigCol[idx][idxQuantidadeProcedimentos]?.visivel ? <td className='td-data'>{operadora.procedimentos}</td> : <></>}
                                                            {x.colunas && arrayConfigCol[idx][idxFaturado]?.visivel ? <td className='td-data text-right '>{formatarMoeda(operadora.faturado) || ''}</td> : <></>}
                                                            {x.colunas && arrayConfigCol[idx][idxPorcentagem]?.visivel ? <td className='td-data text-right'>{operadora.porcentagem} %</td> : <></>}
                                                        </tr>


                                                    </>
                                                )
                                            })}

                                    </table>

                                    {grupo.operadoras.length > 0 ?
                                        <CalcularValoresTotaisPorEstabelecimento dados={grupo.operadoras} arrayConfigCol={arrayConfigCol} dadosAgr={array} index={idx} />
                                        :
                                        <></>
                                    }
                                    {/* {idxG < x.data.length - 1 ? <div className='quebra-de-pagina'></div> : <></>} */}
                                </>
                                )
                            }) :
                                <MensagemPadraoRelatorio />


                        }
                        {x.data.length > 0 ?
                            <CalcularValoresTotaisEstabelecimentos dados={x.data} arrayConfigCol={arrayConfigCol} dadosAgr={array} />
                            :
                            <></>
                        }

                    </div >
                )
            }
            )
        )

    }


    const relatorioEstabelecimentoEspecifico = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {
        const limitarCaracteres = (str, limite) => {
            if (str && str?.length <= limite) {
                return str;
            } else if (str && str.length) {
                return str.slice(0, limite) + "...";
            }

            return
        }

        return (
            array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {

                const idxEmpresa = arrayConfigCol[idx].findIndex(y => y.coluna == 'empresa')
                const idxOperadoraSaude = arrayConfigCol[idx].findIndex(y => y.coluna == 'operadora_saude')
                const idxQuantidadePaciente = arrayConfigCol[idx].findIndex(y => y.coluna == 'pacientes')
                const idxQuantidadeProcedimentos = arrayConfigCol[idx].findIndex(y => y.coluna == 'procedimentos')
                const idxFaturado = arrayConfigCol[idx].findIndex(y => y.coluna == 'faturado_formatado')
                const idxPorcentagem = arrayConfigCol[idx].findIndex(y => y.coluna == 'porcentagem')

                return (
                    <>
                        <div className='delete-starts' />
                        <TableVirtuoso
                            style={{ height: 1000, }}
                            useWindowScroll
                            data={x.data}
                            itemContent={(index, item) => (
                                <>
                                    {index > 0 ? htmlHeader : <></>}
                                    <table className='table-agrupamento mb-2'>

                                        <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                            <td className={` bold td-data text-left`} style={{ width: '45%' }}>Estabelecimento Saúde</td>
                                            <td className={` bold td-data text-left`}>Qtd. Pacientes</td>
                                            <td className={` bold td-data text-left`}>Qtd. Procedimentos</td>
                                            <td className={` bold td-data text-right`}>Faturado R$</td>
                                            <td className={` bold td-data text-right`}>Percentual(%)</td>

                                        </tr>
                                        <tr className={'tr-data titulo-1'}>
                                            <td className={` bold td-data text-left`}>{limitarCaracteres(item.estabelecimento_saude, 85)}</td>
                                            <td className={` bold td-data text-left`}>{item.pacientes}</td>
                                            <td className={` bold td-data text-left`}>{item.procedimentos}</td>
                                            <td className={` bold td-data text-right`}>{formatarMoeda(item.total_faturado)}</td>
                                            <td className={` bold td-data text-right`}>{item.porcentagem_estabelecimento}%</td>

                                        </tr>
                                    </table>

                                    <table style={{ width: '100%' }} className='table-agrupamento mb-2'>

                                        <tr className='titulo-1'>
                                            <td className='text-center bold td-data group-title' width={'1%'}>#</td>

                                            {x.colunas && arrayConfigCol[idx][idxOperadoraSaude]?.visivel ? <td className='text-left bold td-data' width={'25%'}>Operadora Saúde</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxEmpresa]?.visivel ? <td className='text-left bold td-data' width={'5%'}>Empresa</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxQuantidadePaciente]?.visivel ? <td className='bold td-data' width={'5%'}>Qtd. Pacientes</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxQuantidadeProcedimentos]?.visivel ? <td className='bold td-data' width={'5%'}>Qtd. Procedimentos</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxFaturado]?.visivel ? <td className='bold td-data text-right' width={'10%'}>Faturado R$</td> : <></>}
                                            {x.colunas && arrayConfigCol[idx][idxPorcentagem]?.visivel ? <td className='bold td-data text-right' width={'8.5%'}>Percentual(%)</td> : <></>}

                                        </tr>

                                        {
                                            item.operadoras.map((operadora, index) => {
                                                return (
                                                    <>
                                                        <tr className={'tr-data bt-1'}>
                                                            <td className='text-center td-data'>{index + 1}</td>
                                                            {x.colunas && arrayConfigCol[idx][idxOperadoraSaude]?.visivel ? <td className='text-left td-data'>{operadora.operadora_saude != null ? limitarCaracteres(operadora.operadora_saude, 55) : 'PARTICULAR'}</td> : <></>}
                                                            {x.colunas && arrayConfigCol[idx][idxEmpresa]?.visivel ? <td className='text-left td-data'>{operadora.empresa}</td> : <></>}
                                                            {x.colunas && arrayConfigCol[idx][idxQuantidadePaciente]?.visivel ? <td className='td-data'>{operadora.pacientes}</td> : <></>}
                                                            {x.colunas && arrayConfigCol[idx][idxQuantidadeProcedimentos]?.visivel ? <td className='td-data'>{operadora.procedimentos}</td> : <></>}
                                                            {x.colunas && arrayConfigCol[idx][idxFaturado]?.visivel ? <td className='td-data text-right '>{formatarMoeda(operadora.faturado) || ''}</td> : <></>}
                                                            {x.colunas && arrayConfigCol[idx][idxPorcentagem]?.visivel ? <td className='td-data text-right'>{operadora.porcentagem} %</td> : <></>}
                                                        </tr>


                                                    </>
                                                )
                                            })}

                                    </table>

                                    {item.operadoras.length > 0 ?
                                        <CalcularValoresTotaisPorEstabelecimento dados={item.operadoras} arrayConfigCol={arrayConfigCol} dadosAgr={array} index={idx} />
                                        :
                                        <></>
                                    }
                                </>
                            )}
                        />

                        {x.data.length > 0 ?
                            <CalcularValoresTotaisEstabelecimentos dados={x.data} arrayConfigCol={arrayConfigCol} dadosAgr={array} index={idx} />
                            :
                            <></>
                        }
                        <div className='delete-ends' />
                    </>

                )
            }
            )
                :
                <MensagemPadraoRelatorio />
        )
    }

    const CalcularValoresTotaisEstabelecimentos = ({ dados, arrayConfigCol, dadosAgr, ...props }) => {

        let totalParticular = { procedimentos: 0, pacientes: 0, total_faturado: 0 };
        let totalConvenio = { procedimentos: 0, pacientes: 0, total_faturado: 0 };


        for (const item of dados) {
            for (const operadora of item.operadoras) {

                const tipoOperadora = operadora.operadora_saude.includes('PARTICULAR') ? "particular" : "convenio";

                const procedimentos = parseInt(operadora.procedimentos);
                const pacientes = parseInt(operadora.pacientes);
                const totalFaturado = convertValueToFloat(operadora.faturado);

                if (tipoOperadora === "particular") {
                    totalParticular.procedimentos += procedimentos;
                    totalParticular.pacientes += pacientes;
                    totalParticular.total_faturado += totalFaturado;
                } else {
                    totalConvenio.procedimentos += procedimentos;
                    totalConvenio.pacientes += pacientes;
                    totalConvenio.total_faturado += totalFaturado;
                }
            }
        }

        return (
            <>
                <table>
                    <tr><td colspan={dadosAgr[0].colunas ? dadosAgr[0].colunas.length : 0} className={'td-data pr-0'}></td></tr>
                    <tr className={'tr-data text-right bold bt-1 bt-black-1'}></tr>
                    <tr className={'tr-data text-right'}>
                        <td width={'50px'} className={'td-data pr-0'}></td>
                        {/* <td width={'40%'} className={'td-data pr-0'}><span>Totais: </span></td> */}
                        {arrayConfigCol[0].filter(x => x.coluna === 'pacientes' && x.visivel).length > 0 ? <td width={'25%'} className={'td-data pr-0'}>Total Pacientes: <span className={'ml-2 bold'}>{totalParticular.pacientes + totalConvenio.pacientes}</span></td> : <></>}
                        {arrayConfigCol[0].filter(x => x.coluna === 'procedimentos' && x.visivel).length > 0 ? <td width={'25%'} className={'td-data pr-0'}>Total Procedimentos: <span className={'ml-2 bold'}>{totalParticular.procedimentos + totalConvenio.procedimentos}</span></td> : <></>}
                        {arrayConfigCol[0].filter(x => x.coluna === 'faturado_formatado' && x.visivel).length > 0 ? <td width={'25%'} className={'td-data pr-0'}>Total Faturado: <span className={'ml-2 bold'}>{formatarMoeda(totalParticular.total_faturado + totalConvenio.total_faturado)}</span></td> : <></>}
                    </tr>
                </table>

                <table style={{ marginTop: 25 }}>


                    <td width={'43%'} style={{ verticalAlign: 'baseline' }}>
                        <table style={{ width: '100%' }}>
                            <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Convênio</span></td></tr>
                            <tr className='tr-data titulo-1'>
                                <td className='text-center bold td-data' width={'30%'}>Total Qtde. Pac. Conv.</td>
                                <td className='text-center bold td-data' width={'30%'}>Total Qtde. Proc. Conv.</td>
                                <td className='text-right bold td-data' width={'30%'}>Total Faturado</td>
                            </tr>
                            <tr className='tr-data tr-movimento-receita'>
                                <td className='text-center td-data'>{totalConvenio.pacientes}</td>
                                <td className='text-center td-data'>{totalConvenio.procedimentos}</td>
                                <td className='text-right td-data'>{formatarMoeda(totalConvenio.total_faturado)}</td>
                            </tr>


                        </table>
                    </td>
                    <td width={'14%'}></td>
                    <td width={'43%'} style={{ verticalAlign: 'baseline' }}>



                        <table style={{ width: '100%' }}>
                            <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Particular</span></td></tr>
                            <tr className='tr-data titulo-1'>
                                <td className='text-center bold td-data' width={'30%'}>Total Qtde. Pac. Part.</td>
                                <td className='text-center bold td-data' width={'30%'}>Total Qtde. Proc. Part.</td>
                                <td className='text-right bold td-data' width={'30%'}>Total Faturado</td>
                            </tr>
                            <tr className='tr-data tr-movimento-receita'>
                                <td className='text-center td-data'>{totalParticular.pacientes}</td>
                                <td className='text-center td-data'>{totalParticular.procedimentos}</td>
                                <td className='text-right td-data'>{formatarMoeda(totalParticular.total_faturado)}</td>
                            </tr>


                        </table>
                    </td>

                </table>
            </>
        )
    }

    const CalcularValoresTotaisPorEstabelecimento = ({ dados, arrayConfigCol, dadosAgr, index, ...props }) => {

        let totalParticular = { procedimentos: 0, pacientes: 0, total_faturado: 0 };
        let totalConvenio = { procedimentos: 0, pacientes: 0, total_faturado: 0 };

        for (const item of dados) {

            const tipoOperadora = item.operadora_saude.includes('PARTICULAR') ? "particular" : "convenio";

            const procedimentos = parseInt(item.procedimentos);
            const pacientes = parseInt(item.pacientes);
            const totalFaturado = convertValueToFloat(item.faturado);

            if (tipoOperadora === "particular") {
                totalParticular.procedimentos += procedimentos;
                totalParticular.pacientes += pacientes;
                totalParticular.total_faturado += totalFaturado;
            } else {
                totalConvenio.procedimentos += procedimentos;
                totalConvenio.pacientes += pacientes;
                totalConvenio.total_faturado += totalFaturado;
            }
        }

        return (
            <>
                <table style={{ marginTop: 25, marginBottom: 25 }}>

                    <td width={'43%'} style={{ verticalAlign: 'baseline' }}>
                        <table style={{ width: '100%' }}>
                            <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Convênio</span></td></tr>
                            <tr className='tr-data titulo-1'>
                                <td className='text-center bold td-data' width={'30%'}>Qtde. Pac. Conv.</td>
                                <td className='text-center bold td-data' width={'30%'}>Qtde. Proc. Conv.</td>
                                <td className='text-right bold td-data' width={'30%'}>Faturado</td>
                            </tr>

                            <tr className='tr-data tr-movimento-receita'>
                                <td className='text-center td-data'>{totalConvenio.pacientes}</td>
                                <td className='text-center td-data'>{totalConvenio.procedimentos}</td>
                                <td className='text-right td-data'>{formatarMoeda(totalConvenio.total_faturado)}</td>
                            </tr>


                        </table>
                    </td>

                    <td width={'14%'}></td>

                    <td width={'43%'} style={{ verticalAlign: 'baseline' }}>

                        <table style={{ width: '100%' }}>
                            <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Particular</span></td></tr>
                            <tr className='tr-data titulo-1'>
                                <td className='text-center bold td-data' width={'30%'}>Qtde. Pac. Part.</td>
                                <td className='text-center bold td-data' width={'30%'}>Qtde. Proc. Part.</td>
                                <td className='text-right bold td-data' width={'30%'}>Faturado</td>
                            </tr>
                            <tr className='tr-data tr-movimento-receita'>
                                <td className='text-center td-data'>{totalParticular.pacientes}</td>
                                <td className='text-center td-data'>{totalParticular.procedimentos}</td>
                                <td className='text-right td-data'>{formatarMoeda(totalParticular.total_faturado)}</td>
                            </tr>


                        </table>
                    </td>

                </table>
            </>
        )
    }

    const relatorioGuiaAnalitico = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {
        return (
            array && array.map((x, idx) => {

                const idxColuna = arrayConfigCol[idx].findIndex(y => y.coluna == '#')
                const idxGuiaId = arrayConfigCol[idx].findIndex(y => y.coluna == 'guia_id')
                const idxQuantidadePaciente = arrayConfigCol[idx].findIndex(y => y.coluna == 'paciente')
                const idxOperadoraSaude = arrayConfigCol[idx].findIndex(y => y.coluna == 'operadora')
                const idxDataProced = arrayConfigCol[idx].findIndex(y => y.coluna == 'data_procedimento')
                const idxHoraInternacao = arrayConfigCol[idx].findIndex(y => y.coluna == 'horario_insercao')

                return (
                    <div>
                        {
                            x.data.length > 0 ? x.data.map((grupo, idxG) => {

                                const sortedDataEntries = Object.entries(grupo.guias_por_data).sort(([date1], [date2]) => {
                                    return moment(date1, 'DD/MM/YYYY').toDate() - moment(date2, 'DD/MM/YYYY').toDate();
                                });
                                return (<>
                                    {idxG > 0 ? htmlHeader : <></>}
                                    <table className='table-agrupamento mb-2'>
                                        {/* <tr className="group-title">
                                            {idx === 0 && x.colunas && x.colunas.filter(y => !y.lista).map((y, idy) => {
                                                let classes = (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].ordenamento.ativo && arrayConfigCol[idx][idy].coluna === y.name ? 'ordenamento_' + arrayConfigCol[idx][idy].ordenamento.modo : '');
                                                return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) && <td style={{ width: y.width || 'auto' }} className={`bold coluna ${y.alinhamento && y.alinhamento || ''} ${classes}`}>{y.label}</td>
                                            })}
                                        </tr> */}
                                        <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                            {x.colunas && x.colunas.filter((y, idx) => !y.lista && idx > 0).map((y, idy) => {

                                                return (arrayConfigCol[idx][idy] && arrayConfigCol[idx][idy].visivel) ? <td style={{ width: y.width || 'auto' }} className={` bold td-data ${y.alinhamento && y.alinhamento || ''}`}>{grupo[y.name]}</td> : <></>
                                            })}

                                        </tr>
                                    </table>
                                    {



                                        sortedDataEntries.map(([data, guias], index) => {
                                            return (

                                                <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                                                    <tr><td colSpan={3} className='bold'>Data Registro: {data}</td></tr>
                                                    {/* <tr className={'tr-data'}><td colSpan={3} className='td-data'><span className='bold' style={{ width: '100%' }}>Guia(s)</span></td></tr> */}
                                                    <tr className='titulo-1'>
                                                        {x.colunas && arrayConfigCol[idx][idxColuna]?.visivel ? <td className='text-center bold td-data' width={'1%'}>#</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxGuiaId]?.visivel ? <td className='text-left bold td-data' width={'5%'}>Atend.</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxQuantidadePaciente]?.visivel ? <td className='text-left bold td-data' width={'40%'}>Paciente</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxOperadoraSaude]?.visivel ? <td className='bold td-data' width={'30%'}>Operadora Saúde</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxDataProced]?.visivel ? <td className='bold td-data' width={'14%'}>Data Procedimento</td> : <></>}
                                                        {x.colunas && arrayConfigCol[idx][idxHoraInternacao]?.visivel ? <td className='bold td-data' width={'10%'}>Horário Inserção</td> : <></>}

                                                        {/* 
                                                    <td className='text-center bold td-data' width={'1%'}>#</td>
                                                    <td className='text-left bold td-data' width={'5%'}>Atend.</td>
                                                    <td className='text-left bold td-data' width={'40%'}>Paciente</td>
                                                    <td className='bold td-data' width={'30%'}>Operadora Saúde</td>
                                                    <td className='bold td-data' width={'14%'}>Data Procedimento</td>
                                                    <td className='bold td-data' width={'10%'}>Horário Inserção</td> */}
                                                    </tr>
                                                    {guias && guias.length > 0 ? <>
                                                        {
                                                            guias.map((guia, idl) => {
                                                                return (
                                                                    <>
                                                                        <tr><td>{guia.data_registro}</td></tr>
                                                                        <tr className={'tr-data tr-movimento-receita'}>
                                                                            {/* <td className='text-center td-data'>{idl + 1}</td>
                                                                        <td className='text-left td-data'>{guia.guia_id}</td>
                                                                        <td className='text-left td-data'>{guia.paciente}</td>
                                                                        <td className='td-data'>{guia.operadora}</td>
                                                                        <td className='td-data'>{guia.data_procedimento}</td>
                                                                        <td className='td-data'>{guia.horario_insercao}</td> */}

                                                                            {x.colunas && arrayConfigCol[idx][idxColuna]?.visivel ? <td className='text-center td-data'>{idl + 1}</td> : <></>}
                                                                            {x.colunas && arrayConfigCol[idx][idxGuiaId]?.visivel ? <td className='text-left td-data'>{guia.guia_id}</td> : <></>}
                                                                            {x.colunas && arrayConfigCol[idx][idxQuantidadePaciente]?.visivel ? <td className='text-left td-data'>{guia.paciente}</td> : <></>}
                                                                            {x.colunas && arrayConfigCol[idx][idxOperadoraSaude]?.visivel ? <td className='td-data'>{guia.operadora}</td> : <></>}
                                                                            {x.colunas && arrayConfigCol[idx][idxDataProced]?.visivel ? <td className='td-data'>{guia.data_procedimento}</td> : <></>}
                                                                            {x.colunas && arrayConfigCol[idx][idxHoraInternacao]?.visivel ? <td className='td-data'>{guia.horario_insercao}</td> : <></>}
                                                                        </tr>
                                                                    </>)
                                                            })
                                                        }
                                                    </> :
                                                        <></>
                                                    }
                                                </table>

                                            )
                                        })}
                                    <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                                        <tr><td colspan={12} className={'td-data pr-0 text-right bold'}>Total: {Object.values(grupo.guias_por_data).reduce((acc, arr) => acc + arr.length, 0)}</td></tr>
                                    </table>

                                    {idxG < x.data.length - 1 ? <div className='quebra-de-pagina'></div> : <></>}
                                </>
                                )
                            }) :
                                <MensagemPadraoRelatorio />



                        }
                    </div >
                )
            }
            )
        )
    }


    const submit = (values, propsFormik) => {

        // if (values && !values.referencia && (!values.data_inicial_procedimento && !values.data_final_procedimento) && (!values.data_registro_inicial && !values.data_registro_final) && values?.tipo_relatorio?.value !== 3) {
        //     if (values?.tipo_relatorio?.value === 4)
        //         mostrarToast('erro', 'Escolha ao menos um filtro!')

        //     else
        //         mostrarToast('erro', 'Escolha ao menos um filtro! Referência ou Data de Procedimento Inicial e Final')
        // }

        if (values && !values.competencia && !values.referencia && (!values.data_inicial_procedimento && !values.data_final_procedimento) && (!values.data_inicial_registro && !values.data_final_registro) && (!values.data_inicial_entrega && !values.data_final_entrega)) {

            mostrarToast('erro', 'Escolha ao menos um filtro! Referência, Competência, Data Procedimento, Data Registro ou Data Entrega Inicial e Final')

        }
        else if (values && values.competencia && values.referencia && (values.data_inicial_procedimento || values.data_final_procedimento) && (values.data_inicial_registro || values.data_final_registro) && (values.data_inicial_entrega || values.data_final_entrega)) {

            mostrarToast('erro', 'Escolha ao menos um filtro! Referência, Competência, Data Procedimento, Data Registro ou Data Entrega Inicial e Final')

        }
        else {

            setCarregando(true)
            let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)

            for (let i = Object.keys(values).length; i > 0; i--)
                if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                    delete values[Object.keys(values)[i]]

            setShowModalFiltros(false)
            setValuesFiltros(values)
            setRelatorioAtual({ ...novoRelatorio })

            if (values.tipo_relatorio) {
                setFiltros(Object.keys(values).map((x, idx, array) => {

                    const valorAtual = values[x]

                    return (x !== 'tipo_relatorio'
                        && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value) && valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                    {
                        coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                        filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                        name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                        value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                        dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                    })
                }).filter(x => x.coluna !== false && x.value))
            }
            else {
                setRelatorioAtual({})
                setCarregando(false)
                setDados([])
                setFiltros([])
            }
        }
    }

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }
        const newFiltro = [...filtros]

        if (user.tipo === 4)
            newFiltro.push({ coluna: 'usuario', value: user.id }, { coluna: 'tipo', value: user.tipo })


        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{
                    numerarLinha: true, colunas: cloneRelatorioAtual.colunas, colunasCsv: cloneRelatorioAtual.colunasCsv ? cloneRelatorioAtual.colunasCsv : null, data: cloneRelatorioAtual.data, classeTr: cloneRelatorioAtual.classeTr, aposAgrupamento: cloneRelatorioAtual.aposAgrupamento, aposRelatorio: cloneRelatorioAtual.aposRelatorio, agrupado: cloneRelatorioAtual?.agrupado || false, relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento) : null,
                    relatorioEspecificoImpressao: !!cloneRelatorioAtual.relatorioEspecificoImpressao ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecificoImpressao(dados, arrayConfigCol, configOrdenamento) : null, virtuoso: !!cloneRelatorioAtual.virtuoso
                }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: newFiltro, usuario_tipo: user.tipo, usuario_id: user.id })
    }, [filtros])

    const changeTipo = (value) => {
        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({ })
        // const index = tipoRelatorio.findIndex(x => x.value === value)
        setValuesFiltros({ tipo_relatorio: value, data_inicial: startOfMonth, data_final: endOfMonth, competencia: actualMonth, guia_complementar: { label: 'Não', value: 'false' }, rotina: { value: 'false', label: 'Não' } })
        setRelatorioSelecionado(value?.value)
    }
    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros} virtuoso={relatorioAtual.virtuoso}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })} ref={ref} classePersonalizada={relatorioAtual && relatorioAtual.classePersonalizada} ordenarArrayCompleto={relatorioAtual && relatorioAtual.ordenarArrayCompleto}>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        <InputTipoRelatorioPersonalizado label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                            propsFormik={propsFormik} modulos={relatoriosPorTipo(propsFormik).map(x => x.tipo)} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} />
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}
                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}