import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FieldArray } from "formik";
import Yup, { validaData, periodoData } from '../../../../../util/Validacoes';
import { InputPadrao, InputDatePicker, InputNumeroComCombo, InputNumero, TextArea } from '../../../../../components/Inputs'
import { mostrarToast } from '../../../../../components/Toasts'
import { BotaoPadrao } from '../../../../../components/Botoes'
import { useParams } from "react-router-dom";
import { salvar, get } from '../../../../../util/AcessoApi'
import { mensagens } from '../../../../../util/Utilitarios'
import 'moment/locale/pt-br'
import { Link } from 'react-router-dom'
import { HeaderCadastro } from '../../../../../components/Headers'
import Atalho from '../../../../../util/Atalho'
import { ModalPadraoCadastro } from '../../../../../components/Modais'
import { LinkExclusaoArrayTabela, LinkAlteracaoTabela, LinkAdicionarArrayTabela } from '../../../../../components/Acoes'
import { InputMonthPicker, InputPadraoSimples } from '../../../../../components/InputsV2';
import moment from 'moment';

export default props => {
    let { id } = useParams()
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSubmitModal, setIsSubmitModal] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [valoresIniciaisRegra, setValoresIniciaisRegra] = useState({})
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvarENovoModal, setSalvarENovoModal] = useState(false)
    const { history } = props
    const [regras, setRegras] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [dataType, setDataType] = useState([{ label: 'ano(s)', value: 1 }, { label: 'mês(es)', value: 2 }])
    const [referencias, setReferencias] = useState([])

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Administração</span>,
        () => <Link className='link-active' to={'/administracao/ferias/periodo-gozo/consulta'}>Período Aquisitivo</Link>,
    ]

    let validacaoInicial = Yup.lazy(({ qtd_maxima_periodo_socio, ano }) => Yup.object({
        ano: Yup.string().nullable().required(),
        periodo_inicial: Yup.string().required().nullable()
            .test('Período Inicial', 'Data inválida!', function (value) { return validaData(this.parent.periodo_inicial) })
            .test('Período Inicial', 'Período Inicial deve ser anterior à Período Final.', function (value) { return periodoData(value, this.parent.periodo_final) }),
        periodo_final: Yup.string().nullable().required()
            .test('Período Final', 'Data inválida!', function (value) { return validaData(this.parent.periodo_final) })
            .test('Período Final', 'Período Inicial deve ser anterior à Período Final.', function (value) { return validaData(this.parent.periodo_inicial, value) }),
        data_limite_gozo: Yup.string().required().nullable()
            .test('Data Limite de Gozo', 'Data inválida!', function (value) { return validaData(this.parent.data_limite_gozo) }),
        observacao: Yup.string().nullable(),
    })
        .shape({
            qtd_maxima_periodo_socio: Yup.array()
                .of(
                    Yup.object().shape({
                        quantidade_maxima: Yup.string().nullable().required(),
                    })
                ),
        })
    )

    const Submit = (values, propsFormik) => {
        setIsSubmit(true)
        values.regras = regras
        salvar(values, '/administracao/ferias/periodo-gozo', (ret) => {
            mostrarToast('sucesso', mensagens.sucesso)
            if (props.modal) {
                if (salvarENovo) {
                    setValoresIniciais({ ano: "", periodo_inicial: "", periodo_final: "", data_limite_gozo: "", antecedencia_minima_dias: "", qtd_maxima_periodo: "", observacao: "" })
                    setRegras([])
                    setIsSubmit(false)
                    setSalvarENovo(false)
                }
                else {
                    props.setItemSelecionado({ value: values.id, label: values.nome })
                    props.setShow && props.setShow(false)
                }
            }
            else {
                if (salvarENovo) {
                    setValoresIniciais({
                        ano: "", periodo_inicial: "", periodo_final: "", data_limite_gozo: "", antecedencia_minima_dias: "", qtd_maxima_periodo: "", observacao: "",
                        qtd_maxima_periodo_socio: [{ referencia: "", quantidade_maxima: "" }],
                    })
                    setRegras([])
                    setIsSubmit(false)
                    setSalvarENovo(false)
                }
                else
                    history.push('/administracao/ferias/periodo-gozo/consulta')
            }

        }, err => {
            setIsSubmit(false)
        })
    }

    useEffect(() => {
        if (id) {
            Promise.resolve(get('/administracao/ferias/periodo-gozo/' + id)).then(function (obj) {
                if (obj.length === 0)
                    history.push('/administracao/ferias/periodo-gozo/consulta')

                const periodo_gozo = { ...obj }

                delete periodo_gozo.regras

                setValoresIniciais(periodo_gozo)
                setRegras(obj.regras)
            }).catch((e) => {
            });
        } else
            setValoresIniciais({
                ano: "", periodo_inicial: "", periodo_final: "", data_limite_gozo: "", antecedencia_minima_dias: "", qtd_maxima_periodo: "", observacao: "",
                qtd_maxima_periodo_socio: [{ periodo_inicial: "", periodo_final: "", quantidade_maxima: "" }]
            })

        setValoresIniciaisRegra({ id: "", inicio: "", termino: "", dias_direito: "", verificador: "", type_inicio: dataType[0], type_fim: dataType[0] })

    }, [history, id])


    const validarRegras = values => {
        const inicio = values.type_inicio.value === 2 ? values.inicio / 12 : values.inicio
        const fim = values.type_fim.value === 2 ? values.termino / 12 : values.termino

        return regras.find(x => {
            const inicio_regra = x.type_inicio.value === 2 ? x.inicio / 12 : x.inicio
            const fim_regra = x.type_fim.value === 2 ? x.termino / 12 : x.termino

            return (
                ((inicio <= inicio_regra && inicio <= fim_regra && fim >= inicio_regra && fim <= fim_regra) ||
                    (inicio >= inicio_regra && inicio <= fim_regra && fim >= inicio_regra && fim <= fim_regra) ||
                    (inicio >= inicio_regra && inicio <= fim_regra && fim >= inicio_regra && fim >= fim_regra) ||
                    (inicio <= inicio_regra && inicio <= fim_regra && fim >= inicio_regra && fim >= fim_regra)) &&
                values.verificador !== x.verificador
            )
        })
    }

    const alterarRegra = (values, propsFormik) => {
        setIsSubmitModal(true)
        const cloneRegras = [...regras]

        if (validarRegras(values)) {
            setSalvarENovoModal(false)
            setIsSubmitModal(false)
            return mostrarToast('erro', 'Operação bloqueada! Regra já cadastrada neste intervalo.')
        }

        if (!values.verificador) {
            values.verificador = Math.random() * (1000 - 1) + 1
            cloneRegras.push(values)
        }
        else
            cloneRegras[cloneRegras.findIndex(x => x.verificador === values.verificador)] = values

        setRegras(cloneRegras)
        setValoresIniciaisRegra({ id: "", inicio: "", termino: "", dias_direito: "", verificador: "", type_inicio: dataType[0], type_fim: dataType[0] })

        if (!salvarENovoModal)
            setShowModal(!showModal)

        setTimeout(() => { setSalvarENovoModal(false) }, 100);
        setIsSubmitModal(false)
    }

    const excluirRegra = (regra) => {
        const cloneRegras = [...regras]
        const index = cloneRegras.findIndex(x => x.id === regra.id)

        cloneRegras.splice(index, 1)

        setRegras(cloneRegras)
    }

    const calcularReferencia = (propsFormik) => {

        if (propsFormik.values.periodo_inicial && propsFormik.values.periodo_final) {

            let resultado = [];
            let data_inicial = moment(propsFormik.values.periodo_inicial, 'DD/MM/YYYY');
            let data_final = moment(propsFormik.values.periodo_final, 'DD/MM/YYYY');

            let data_atual = moment(data_inicial);
            while (data_atual.isSameOrBefore(data_final)) {
                let primeiro_dia;
                if (resultado.length === 0) {
                    primeiro_dia = moment(data_atual);
                } else {
                    primeiro_dia = moment(data_atual).startOf('month');
                }
                let ultimo_dia = moment(data_atual).endOf('month');

                let periodo = {
                    periodo_inicial: primeiro_dia.format('DD/MM/YYYY'),
                    periodo_final: ultimo_dia.format('DD/MM/YYYY'),
                    quantidade_maxima: ''
                };

                resultado.push(periodo);

                data_atual.add(1, 'month');
            }

            setReferencias(resultado)
            propsFormik.values.qtd_maxima_periodo_socio = resultado

        }

    }

    return (
        <>
            {!props.modal && <HeaderCadastro titulo={"Cadastro Ano (Gozo)/Parâmetros"} link={links} />}
            <Formik
                initialValues={valoresIniciais}
                enableReinitialize={true}
                validationSchema={validacaoInicial}
                onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                {propsFormik => (
                    <Form>
                        <div className={props.modal && props.modal || 'card mb-20'}>
                            <div className={props.modal || 'card-body'}>
                                <div className="row">
                                    <InputPadrao tamanho="col-0" label="Id" name="id" type="hidden" placeholder="Id" obrigatorio="false" />
                                    <InputNumero autoFocus={true} salvarENovo={salvarENovo} label='Ano' propsFormik={propsFormik} name='ano' classDiv='col-lg-3 col-md-4' max={9999} obrigatorio />
                                    <InputDatePicker tamanho={!props.modal && 'col-lg-3 col-md-4'} label="Período de Gozo Inicial" name="periodo_inicial" type="text" obrigatorio="true"
                                        classeTooltip={props.modal && 'tooltip-input-modal'} placeholder="Período de Gozo Inicial"
                                        setarValor={value => { propsFormik.setFieldValue("periodo_inicial", value); calcularReferencia(propsFormik) }} />
                                    <InputDatePicker tamanho={!props.modal && 'col-lg-3 col-md-4'} label="Período de Gozo Final" name="periodo_final" type="text" obrigatorio="true"
                                        setarValor={value => { propsFormik.setFieldValue("periodo_final", value); calcularReferencia(propsFormik) }}
                                        classeTooltip={props.modal && 'tooltip-input-modal'} placeholder="Período de Gozo Final"
                                    />
                                    <InputDatePicker tamanho={!props.modal && 'col-lg-3 col-md-4'} label="Limite para Gozo" name="data_limite_gozo" type="text" obrigatorio="true"
                                        classeTooltip={props.modal && 'tooltip-input-modal'} placeholder="Limite para Gozo"
                                        setarValor={value => { propsFormik.setFieldValue("data_limite_gozo", value) }} />
                                    <InputNumero label='Antecedência Mínima de Solicitação de Férias' propsFormik={propsFormik} name='antecedencia_minima_dias' classDiv='col-lg-3 col-md-4' max={99} />
                                    {/* <InputNumero label='Qtde. Max. Sócio por Período' propsFormik={propsFormik} name='qtd_maxima_periodo' classDiv='col-lg-3 col-md-4' max={99} obrigatorio /> */}
                                    <TextArea label='Observação' name='observacao' type='text' palcelhoder='Observação' tamanho='col-lg-3 col-md-4' />
                                    <Atalho formik={propsFormik} salvando={isSubmit} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                                </div>
                            </div>
                        </div>
                        <div className='row' >
                            <div className=' col-sm-12 col-md-12 col-xs-12 col-lg-6 ' style={{ paddingBottom: '20px' }}>
                                <div className='card b-radius-10' style={{ zIndex: 1, minHeight: '390px' }}>
                                    <div className='card-body'>
                                        <div class="mb-2 row-space-between"><div class="card-title">Parâmetro de gozo (dias) por tempo</div><BotaoPadrao type={'button'} idTooltip={"padrao"} icone='icon-Add' classe={'btn btn-primary primary btn-filter'} click={_ => { setShowModal(!showModal); setValoresIniciaisRegra({ id: "", inicio: "", termino: "", dias_direito: "", verificador: "", type_inicio: dataType[0], type_fim: dataType[0] }) }} /></div>
                                        <div className='dashboard-auditoria'>
                                            <div className='scrollbar-container custom-scrollbar' style={{ maxHeight: '240px' }}>
                                                <table className="table table-sm table-borderless tabela-linha-destaque">
                                                    <thead>
                                                        <th style={{ width: '15%' }}>Inicio</th>
                                                        <th style={{ width: '15%' }}>Fim</th>
                                                        <th style={{ width: '15%' }}>Dias</th>
                                                        <th style={{ width: '15%' }}>Data Registro</th>
                                                        <th className={'text-center'} style={{ width: '40%' }}>Ações</th>
                                                    </thead>
                                                    <tbody style={{ fontSize: 12 }}>
                                                        {
                                                            regras.length && regras.map(x => {
                                                                return (
                                                                    <tr>
                                                                        <td><span>{x.inicio + ' ' + x.type_inicio.label} </span></td>
                                                                        <td><span>{x.termino + ' ' + x.type_fim.label}</span></td>
                                                                        <td><span>{x.dias_direito} dias</span></td>
                                                                        <td><span>{x.data_registro}</span></td>
                                                                        <td className={'text-center'}><LinkAlteracaoTabela action={() => { setValoresIniciaisRegra(x); setShowModal(!showModal) }}></LinkAlteracaoTabela><LinkExclusaoArrayTabela action={() => { excluirRegra(x) }}></LinkExclusaoArrayTabela></td>
                                                                    </tr>
                                                                )
                                                            }) || <tr><td colSpan={5} className="text-center">Renhum registro encontrado</td></tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=' col-sm-12 col-md-12 col-xs-12 col-lg-6 ' style={{ paddingBottom: '20px' }}>
                                <div className='card b-radius-10' style={{ zIndex: 1, minHeight: '390px' }}>
                                    <div className='card-body'>
                                        <div class="mb-2 row-space-between"><div class="card-title">Qtde. Max. Sócio por Mês</div></div>
                                        <div className='dashboard-auditoria'>
                                            <div className='scrollbar-container custom-scrollbar' style={{ maxHeight: '100%' }}>
                                                <FieldArray name="qtd_maxima_periodo_socio">
                                                    {(arrayHelpers => (
                                                        <>
                                                            <div className=''>
                                                                {

                                                                    propsFormik.values && propsFormik.values.periodo_inicial &&  propsFormik.values.periodo_final  ? propsFormik.values.qtd_maxima_periodo_socio.map((item, index) => {


                                                                        return (<>
                                                                            <div className='modal-body-cadastro row mr-0 ml-0 linha-bottom-periodo-gozo'>
                                                                                {/* <div className='row col-sm-12 col-xl-11 col-lg-12 col-md-12'> */}
                                                                                <div className={"form-group col-xl-1 col-lg-1 col-1 pl-0 text-center "}>
                                                                                    <label htmlFor={props.id || props.name}>{index === 0 ? '#' : undefined}</label>
                                                                                    <div className={'text-center vertical-center ' + (index === 0 ? ' mt-3' : '')} style={index != 0 ? { marginTop: 25 } : {}}><span>{index + 1}</span></div>
                                                                                </div>

                                                                                <div className={"form-group col-xl-5 col-lg-1 col-1 pl-0 text-center"}>
                                                                                    <label htmlFor={props.id || props.name}><b>Referência</b> </label>
                                                                                    <div className={'text-center vertical-center ' + (index === 0 ? ' mt-3' : '')} style={index != 0 ? { marginTop: 20 } : {}}><span>{item.periodo_inicial + ' até ' + item.periodo_final}</span></div>
                                                                                </div>
                                                                                {/* <span>{propsFormik.values.qtd_maxima_periodo_socio[index].quantidade_maxima}</span> */}
                                                                                <InputPadraoSimples label='Qtde. Máxima' propsFormik={propsFormik} name={`qtd_maxima_periodo_socio[${index}].quantidade_maxima`} classDiv='col-lg-3 col-md-2' obrigatorio maxLength = {3}
                                                                                    classeTooltip={props.modal && 'tooltip-input-modal'} tamanho={'col-xl-4'} value = {propsFormik.values.qtd_maxima_periodo_socio[index].quantidade_maxima}/>



                                                                                {/* </div> */}
                                                                            </div>
                                                                        </>)
                                                                    }
                                                                    )
                                                                        :
                                                                        <>
                                                                            <h6 className='dp-flex'>Insira Período de Gozo Inicial e Final</h6>
                                                                        </>
                                                                }
                                                            </div>
                                                        </>
                                                    ))}
                                                </FieldArray>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!props.modal && <div className='separator mt-40 mb-5'></div>}
                        <div className='direita container-botoes mb-3 group-salvar-novo'>
                            <BotaoPadrao texto='Salvar' disabled={isSubmit} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                            {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={isSubmit} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                        </div>
                    </Form>
                )}
            </Formik >
            <ModalPadraoCadastro titulo={'Regra de gozo por tempo'} showModal={showModal} setShowModal={setShowModal}>
                <Formik
                    initialValues={valoresIniciaisRegra}
                    validationSchema={Yup.object({
                        inicio: Yup.string().required().nullable(),
                        termino: Yup.string().required().nullable(),
                        dias_direito: Yup.string().nullable().required(),
                        type_inicio: Yup.string().required().nullable(),
                        type_fim: Yup.string().required().nullable(),
                    })}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => alterarRegra(values, propsFormik)}>
                    {propsFormik => {
                        return (<Form>
                            <div>
                                <div>
                                    <div className='row'>
                                        <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' />
                                        <InputNumeroComCombo salvarENovo={salvarENovoModal} autoFocus nameCombo='type_inicio' obrigatorio classeTooltip={'tooltip-input-modal'} tamanho='col-lg-12' label='Início' name='inicio' comboPlaceholder='Selecione' propsFormik={propsFormik} max={99} data={dataType} />
                                        <InputNumeroComCombo nameCombo='type_fim' obrigatorio classeTooltip={'tooltip-input-modal'} tamanho='col-lg-12' label='Fim' name='termino' comboPlaceholder='Selecione' propsFormik={propsFormik} max={99} data={dataType} />
                                        <InputNumero obrigatorio classeTooltip={'tooltip-input-modal'} classDiv={'col-lg-12'} label='Dias' propsFormik={propsFormik} name='dias_direito' max={99} />
                                    </div>
                                </div>
                            </div>
                            <div className='direita container-botoes mb-3 group-salvar-novo'>
                                <BotaoPadrao texto='Salvar' disabled={isSubmitModal} click={_ => setSalvarENovoModal(false)} idTooltip={'salvar'} />
                                <BotaoPadrao texto='Salvar e Novo' disabled={isSubmitModal} click={_ => setSalvarENovoModal(true)} idTooltip={'salvarENovo'} />
                            </div>
                        </Form>)
                    }}
                </Formik>
            </ModalPadraoCadastro>
        </>
    )
}