import { removeAcentos, formatTwoPlace } from '../../../../util/FuncoesComuns'
import { InputPadrao } from '../../../../components/Inputs'
import { InputValorNovo } from '../../../../components/InputsV2'
import { InputAcomodacao } from '../../../../components/InputsAutoComplete'
import { HeaderCadastro } from '../../../../components/Headers'
import { ModalCadastro } from '../../../../components/Modais'
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import Yup from '../../../../util/Validacoes'
import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import Atalho from '../../../../util/Atalho'

export default props => {
    const [pesquisa, setPesquisa] = useState('')
    const [salvando, setSalvando] = useState(false)

    const [msgBotao, setMsgBotao] = useState('Adicionar')

    const emptyValues = {
        id: null,
        verificador: null,
        acomodacao: null,
        percentual: null,
        urgencia: null,
        video: null
    }

    const [values, setValues] = useState(emptyValues)

    const validar = obj => {
        if (props.arrayDados.length && props.arrayDados.filter(x => x.acomodacao.value === obj.acomodacao.value && x.verificador !== obj.verificador) &&
            props.arrayDados.filter(x => x.acomodacao.value === obj.acomodacao.value && x.verificador !== obj.verificador).length)
            return 'Acomodação já parametrizado!'
    }

    const Submit = (obj, { resetForm }) => {
        const resultadoValidacao = validar(obj)
        setSalvando(true);

        if (resultadoValidacao) {
            mostrarToast('erro', resultadoValidacao)
            return
        }

        let copiaArrayDados = [...props.arrayDados]

        if (obj.verificador)
            copiaArrayDados[copiaArrayDados.findIndex(x => x.verificador === obj.verificador)] = obj
        else {
            obj.verificador = Math.random()
            copiaArrayDados.push(obj)
        }

        props.setArrayDados(copiaArrayDados)
        resetForm({})

        if (msgBotao === 'Alterar')
            props.setAbrirModal(!props.abrirModal)

        setSalvando(false)
    }

    const Delete = index => {
        let copiaArrayDados = [...props.arrayDados]
        copiaArrayDados.splice(index, 1);
        props.setArrayDados(copiaArrayDados)
    }

    return (
        <>
            <HeaderCadastro
                pesquisa={true}
                valorPesquisa={pesquisa}
                onChangePesquisa={pesquisa => setPesquisa(pesquisa)}
                classeChildren='space-between-100' >
                <BotaoPadrao type='button' classe='btn-primary primary p-5rem height-auto m-r-15' texto={<><span> Adicionar</span></>}
                    click={_ => { setValues(emptyValues); props.setAbrirModal(!props.abrirModal); setMsgBotao('Adicionar') }} />
            </HeaderCadastro>
            <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                <thead>
                    {
                        <tr>
                            <th style={{ width: '5%' }}>#</th>
                            <th style={{ width: '35%' }}>Acomodação</th>
                            <th style={{ width: '15%', textAlign: 'right' }}>Percentual</th>
                            <th style={{ width: '15%', textAlign: 'right' }}>Urgência</th>
                            <th style={{ width: '15%', textAlign: 'right' }}>Vídeo</th>
                            <th style={{ width: '15%' }} className='text-center'>Ações</th>
                        </tr>
                    }
                </thead>
                <tbody>
                    {
                        props.arrayDados && props.arrayDados.length ?
                            props.arrayDados.filter(x =>
                                removeAcentos(x.acomodacao.label.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())))
                                .map((x, index) =>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{x.acomodacao.label}</td>
                                        <td style={{ textAlign: 'right' }}>{`${x.percentual && formatTwoPlace(x.percentual) + '%' || ''}`}</td>
                                        <td style={{ textAlign: 'right' }}>{`${x.urgencia && formatTwoPlace(x.urgencia) + '%' || ''}`}</td>
                                        <td style={{ textAlign: 'right' }}>{`${x.video && formatTwoPlace(x.video) + '%' || ''}`}</td>
                                        <td className='text-center'>
                                            <span className='icon-consulta icon-Pen'
                                                onClick={_ => { setValues(x); props.setAbrirModal(true); setMsgBotao('Alterar') }} />
                                            <span className='icon-consulta icon-New-Trash' onClick={target => Delete(index)} />
                                        </td>
                                    </tr>
                                )
                            : <tr><td colspan='8' className='text-center'>Nenhum Registro Cadastrado</td></tr>
                    }
                </tbody>
            </table>
            <ModalCadastro titulo={'Tabela'} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    enableReinitialize={true}
                    initialValues={values}
                    validationSchema={Yup.object({
                        acomodacao: Yup.string().ensure().required().nullable(),
                        percentual: Yup.string().ensure().required().nullable(),
                        urgencia: Yup.string().ensure().nullable(),
                        video: Yup.string().ensure().nullable(),
                    })}
                    onSubmit={Submit}>
                    {propsFormik => <Form>
                        <div className='modal-body-cadastro'>
                            <InputPadrao label='Id' name='id' type='hidden' />
                            <InputAcomodacao label='Acomodação' name="acomodacao" propsFormik={propsFormik} obrigatorio {...props} classeTooltip={'tooltip-input-modal'}
                                botaoVisivel={true} modal={true} autoFocus focarAposSubmit={true} />
                            <InputValorNovo value={propsFormik.values.percentual_formatado || propsFormik.values.percentual} label='Percentual' name={`percentual`} classDiv='col-lg-12' propsFormik={propsFormik} isDisable={false} max={999} suffix={'%'} obrigatorio/>    
                            <InputValorNovo value={propsFormik.values.urgencia_formatado || propsFormik.values.urgencia} label='Urgência' name={`urgencia`} classDiv='col-lg-12' propsFormik={propsFormik} isDisable={false} max={999} suffix={'%'}/>
                            <InputValorNovo value={propsFormik.values.video_formatado || propsFormik.values.video} label='Vídeo' name={`video`} classDiv='col-lg-12' propsFormik={propsFormik} isDisable={false} max={999} suffix={'%'}/>
                            <Atalho formik={propsFormik} salvando={salvando} />
                        </div>
                        <div className='direita container-botoes'>
                            <BotaoPadrao texto={msgBotao} />
                        </div>
                    </Form>}
                </Formik>
            </ModalCadastro>
        </>)
}