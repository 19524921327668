import { InputGroupSenha, InputPadrao } from '../../../../components/Inputs'
import { Redirect } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { Link } from 'react-router-dom'
import { post } from '../../../../util/AcessoApi'
import { verificarPermissao } from '../../../../util/Configuracoes'

import Yup from '../../../../util/Validacoes'
import ls from 'local-storage'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/login.css'
import moment from "moment"

const Login = ({ history }) => {
    const [isSubmit, setIsSubmit] = useState(false)
    const [user, setUser] = useState({})

    const Submit = (values) => {
        setIsSubmit(true)
        Promise.resolve(post('/controleAcesso/usuario/signin', values, false, null, true)).then(function (val) {
            ls.set('usuario', val)
            ls.set('rolagem_automatica', false)
            ls.set('ultimo_hora_login', moment())
            verificarPermissao(66, 5, (x) => ls.set('permissaoDashboard', x))
            setUser(val)
        }).catch((e) => setIsSubmit(false));
    }

    return !user.id ? (
        <div className='background-img'>
            <div className='overlay'>
                <div className='container-img height content-login container'>
                    <div className='row d-flex justify-content-center page-login'>
                        <div className='card-img align-items-center border-radius-bottom-left-50 col-xl-3 col-md-12 content-logo'>
                            <img src={require('../../../../assets/img/logo.png')} alt='logo' className='img-logo' />
                        </div>
                        <div className='card-form border-radius-top-right-50 col-xl-5 col-md-12 form-login'>
                            <Formik
                                initialValues={{ login: '', senha: '' }}
                                validationSchema={Yup.object({ login: Yup.string().required().max(20), senha: Yup.string().required().max(30) })}
                                onSubmit={(values) => Submit(values, setIsSubmit, history)}>
                                <Form>
                                    <div className='col-lg-12'> <h4>Login</h4><br /></div>
                                    <InputPadrao label='Usuário' name='login' type='text' placeholder='Usuário' style={{ textTransform: 'lowercase' }} obrigatorio={true} tamanho='col-lg-12' autoFocus />
                                    <InputGroupSenha label='Senha' name='senha' placeholder='Senha' />
                                    <div className='col-lg-12'>  <Link to='/recuperarsenha' className='forgot-password' >Esqueci minha senha</Link></div>
                                    <div className='direita container-botoes'>
                                        <div className='col-lg-12 mt-8'>
                                            <BotaoPadrao texto='Entrar' disabled={isSubmit} />
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                    <div className='copyright'>
                        © Copyright {new Date().getFullYear()}  <a className='link-login' target='_blank' rel='noopener noreferrer' href='http://www.selectasistemas.com.br/'> Selecta Sistemas.</a> Todos os direitos reservados.
                    </div>
                </div>
            </div>
        </div>) : user.tipo === 4 ? <Redirect push to={"/administracao/agendareserva/consulta"} /> : <Redirect push to={"/dashboard"} />
}

export default Login