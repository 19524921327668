import React, { useState, useEffect } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'


const ConsultaFeriados = (props) => {
    const [data, setData] = useState([])

    useEffect(() => {
        consultar('/administracao/feriadospaginacao', (dados) => setData(dados.sort((a, b) => b.id - a.id)))
    }, [])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-feriados',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id' ,
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }                  
                    },
                    {
                        Header: 'Feriado',
                        accessor: 'descricao',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Ano',
                        accessor: 'ano',
                        visivel: false,
                        filtro: {
                            tipo: 'ano_feriado',
                            idColuna: 'ano'
                        },
                        filtroSomenteModal: true
                    },
                    {
                        Header: 'Mês',
                        accessor: 'mes',
                        visivel: false,
                        filtro: {
                            tipo: 'mes_feriado',
                            idColuna: 'data_mes'
                        },
                        filtroSomenteModal: true
                    },
                    {
                        Header: 'Data',
                        accessor: 'data_feriado_formatada',
                        ordenamento: {
                            tipo: 'data'
                        },
                    },
                    {
                        Header: 'Observação',
                        accessor: 'observacao',
                        ordenamento: {
                            tipo: 'texto'
                        },
                    },
                ],
            },
        ],
        []
    )
    const caminho = {
        cadastro: '/administracao/feriados/cadastro',
    }

    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} caminhoBase='/administracao/feriados' setData={setData} acoes={true} url={caminho} modulo={props.modulo} 
                titulo={'Consulta Feriados'} tituloLista={'Lista Feriados'} urlCadastro='/administracao/feriados/cadastro' icone={'icon-Holiday'} caminhoConsultaFiltros='/administracao/feriadosPaginacao'/>
            </div>
        </>
    )
}

export default ConsultaFeriados