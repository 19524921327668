import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FieldArray, FastField, useFormikContext } from "formik";
import Yup, { validaData, periodoData, validaHora, validaCPF, validaCNPJ, periodoHora } from '../../../../util/Validacoes';
import { HeaderCadastro } from '../../../../components/Headers'
import { InputClienteFinanceiro, InputLancamentoSemMovimento, InputModoLiquidacao, InputMovimentoFinanceiroLiquidacao, InputParcela, InputPeriodicidade } from '../../../../components/InputsAutoComplete';
import { InputCurrency, InputDatePicker, InputPadrao, InputSwitch, InputValorNovo, TextArea } from '../../../../components/Inputs';
import { BtnAcao, LinkAlteracaoTabela, LinkAlteracaoTabelaLocal, LinkExclusaoTabela, LinkExclusaoTabelaLocal, LinkVisualizacaoTabela, LinkVisualizacaoTabelaLocal, LinkVisualizacaoTabelaLocalLancamento } from '../../../../components/Acoes';
import { Overlay, Tooltip } from 'react-bootstrap';
import { FiAlertTriangle } from 'react-icons/fi';
import { convertValueToFloat, distinctArr, formatarMoeda, mensagens } from '../../../../util/Utilitarios';
import { BotaoPadrao } from '../../../../components/Botoes';
import moment from 'moment';
import { TabPanel, Tabs } from 'react-tabs';
import { cloneDeep } from 'lodash';
import { mostrarToast } from '../../../../components/Toasts';
import { ModalCadastro, ModalDuasOpcoes } from '../../../../components/Modais';
import { get, validar } from '../../../../util/AcessoApi';


const CadastroLiquidacao = React.forwardRef(({ dadosMovimentoLancamento, ...props }, ref) => {
    let { propsFormik, dataMovimento } = props
    const { values, setFieldValue } = useFormikContext();
    const refLiquidacao = useRef(null)
    const inputValorLiquidacao = useRef([]);
    const inputValores = useRef([
        {
            acrescimo: 0,
            desconto: 0,
            juros_multa: 0,
            imposto_nfse: 0,
            glosas: 0,
            outros_impostos: 0,
        }
    ]);
    // const [tipoLiquidacao, setTipoLiquidacao] = useState('(ÚNICA FORMA)')
    const [arrayRef, setArrayRef] = useState(Array(propsFormik.values.liquidacao ? props.propsFormik.values.liquidacao.length : 1).fill(React.createRef()))
    const [disabled, setDisabled] = useState(true)
    const [valorTotalLiquidacao, setValorTotalLiquidacao] = useState(formatarMoeda(0.00))
    const [minimizar, setMinimizar] = useState([true])
    const [msgValidacao, setMsgValidacao] = useState([])
    const [showModalValidaValor, setShowModalValidaValor] = useState(false)
    const [validaMaior, setValidaMaior] = useState(false)
    const [msgValidacaoGuiaRepetida, setMsgValidacaoGuiaRepetida] = useState([])
    const [showModalValidaGuiaRepetida, setShowModalValidaGuiaRepetida] = useState(false)
    const inputValorAcrescimo = useRef([]);
    const inputValorDesconto = useRef([]);
    const inputValorJurosMulta = useRef([]);
    const inputValorImpostoNFE = useRef([]);
    const inputValorGlosa = useRef([]);
    const inputValorOutrosImpostos = useRef([]);
    const removerLinha = (arrayHelpers, propsFormik, index) => {

        inputValores.current.splice(index, 1)

        const arrayLiquidacao = propsFormik.values.liquidacao;
        if (arrayLiquidacao.length === 1) {
            arrayHelpers.remove(index)
            arrayHelpers.insert(propsFormik.values.liquidacao.length, { procedimento: undefined, quantidade: 1 })

        }
        else {
            if (arrayLiquidacao.length === 1 && arrayLiquidacao[index].modo_liquidacao && arrayLiquidacao[index].modo_liquidacao.value)
                arrayHelpers.insert(propsFormik.values.liquidacao.length, { procedimento: undefined, quantidade: 1 })

            else {
                arrayHelpers.remove(index)
            }
        }
    }
    const calcularValorTotal = _ => {
        const liquidacao = propsFormik.values.liquidacao
        const liquidacao_parcelado = propsFormik.values.liquidacao_parcelado
        let parcelado = propsFormik.values.parcelado_lancamento
        let valorTotal = '0.00'
        if (props.id && !parcelado) {
            valorTotal = liquidacao?.map(x => convertValueToFloat(x.valor_liquidacao)).filter(x => !isNaN(x)).reduce((ant, atual) => ant + atual, 0) || '0.00';

            setValorTotalLiquidacao(formatarMoeda(valorTotal))
        }
        else if (!props.id && !parcelado) {
            valorTotal = liquidacao?.map(x => convertValueToFloat(x.valor_liquidacao)).filter(x => !isNaN(x)).reduce((ant, atual) => ant + atual, 0) || '0.00';
            setValorTotalLiquidacao(formatarMoeda(valorTotal))
        }
        else if (!props.id && parcelado) {
            valorTotal = liquidacao?.map(x => convertValueToFloat(x.valor_liquidacao)).filter(x => !isNaN(x)).reduce((ant, atual) => ant + atual, 0) || '0.00';
            setValorTotalLiquidacao(formatarMoeda(valorTotal))
        }
        else if (props.id && parcelado) {
            valorTotal = liquidacao?.map(x => convertValueToFloat(x.valor_liquidacao)).filter(x => !isNaN(x)).reduce((ant, atual) => ant + atual, 0) || '0.00';
            setValorTotalLiquidacao(formatarMoeda(valorTotal))
        }

    }
    useEffect(_ => calcularValorTotal(), [propsFormik.values.valor_geral_lancamento, propsFormik.values.liquidacao])

    const adicionarLinha = (option, index, arrayHelpers) => {
        propsFormik.values.liquidacao = propsFormik.values.liquidacao.filter(x => x)
        propsFormik.values.liquidacao[index].modo_liquidacao = option
        const arrayLiquidacao = [...propsFormik.values.liquidacao]
        if (arrayLiquidacao && arrayLiquidacao.findIndex(x => !x?.modo_liquidacao || !x?.modo_liquidacao.value) === -1 && (index + 1) === arrayLiquidacao.length) {
            // setArrayRef([...arrayRef, React.createRef()])
            propsFormik.setFieldValue(`liquidacao[${index + 1}]`, { modo_liquidacao: undefined, data_liquidacao: propsFormik?.values?.liquidacao[0]?.data_liquidacao || undefined, valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined })
            // arrayHelpers.insert(propsFormik?.values?.liquidacao?.length, { modo_liquidacao: undefined, data_liquidacao: propsFormik?.values?.liquidacao[0]?.data_liquidacao || undefined, valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined })
        }
    }

    const adicionarLinhaNovo = (arrayHelpers, propsFormik, index) => {

        propsFormik.setFieldValue(`liquidacao[${index + 1}]`, { modo_liquidacao: undefined, data_liquidacao: propsFormik?.values?.liquidacao[0]?.data_liquidacao || undefined, valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined })

    }


    const verificaValor = _ => {
        let valorLiquidado = parseFloat(propsFormik?.values?.liquidacao?.filter(x => x?.valor).reduce((partialSum, a) => partialSum + convertValueToFloat(a?.valor), 0))

        let resultado = []
        for (let i = 0; i < propsFormik?.values?.liquidacao.length; i++) {
            const item = propsFormik?.values?.liquidacao[i];

            const acrescimo = item.acrescimo ? parseFloat(item.acrescimo) : 0
            const juros_multa = item.juros_multa ? parseFloat(item.juros_multa) : 0
            const desconto = item.desconto ? parseFloat(item.desconto) : 0
            const imposto_nfse = item.imposto_nfse ? parseFloat(item.imposto_nfse) : 0
            const glosas = item.glosas ? parseFloat(item.glosas) : 0
            const outros_impostos = item.outros_impostos ? parseFloat(item.outros_impostos) : 0

            let valor = acrescimo + juros_multa - desconto - imposto_nfse - glosas - outros_impostos

            resultado.push(valor)
        }

        let valorRetencoes = resultado.reduce((acc, valor) => acc + valor, 0)

        let valorLancamento = parseFloat(propsFormik?.values?.valor_geral_lancamento)


        switch (true) {
            case valorRetencoes < 0:
                let soma = valorRetencoes * - 1 + valorLiquidado
                if (soma != valorLancamento) {
                    setMsgValidacao(<>Valor Liquidado diferente do Valor Lançado! Deseja continuar ? </>)
                    setShowModalValidaValor(true)
                    setValidaMaior(false)
                }
                break;

            case valorRetencoes > 0:
                if (valorLancamento !== valorLiquidado) {
                    setMsgValidacao(<>Valor Liquidado diferente do Valor Lançado! Deseja continuar ? </>)
                    setShowModalValidaValor(true)
                    setValidaMaior(false)
                }
                break;

            case valorRetencoes == 0:
                if (valorLancamento != valorLiquidado) {
                    setMsgValidacao(<>Valor Liquidado diferente do Valor Lançado! Deseja continuar ? </>)
                    setShowModalValidaValor(true)
                    setValidaMaior(false)
                }
                break;

        }
    }

    const operacoes = [
        { propriedade: 'acrescimo', operacao: 'soma' },
        { propriedade: 'desconto', operacao: 'subtrai' },
        { propriedade: 'juros_multa', operacao: 'soma' },
        { propriedade: 'imposto_nfse', operacao: 'subtrai' },
        { propriedade: 'glosas', operacao: 'subtrai' },
        { propriedade: 'outros_impostos', operacao: 'subtrai' }
    ];

    useEffect(_ => {

        if (!propsFormik.values.parcelado_lancamento) {
            let teste = propsFormik.values.liquidacao?.filter(x => x.acrescimo || x.desconto || x.juros_multa
                || x.imposto_nfse || x.glosas || x.outros_impostos)?.map((_, i) => atualizarValoresLiquidacao(propsFormik.values.liquidacao, i, propsFormik))
        }

    }, [propsFormik.values.valor_geral_lancamento])

    const atualizarValoresLiquidacao = (arrayLiquidacao, index, propsFormik) => {

        let valor_liquidacao = convertValueToFloat(arrayLiquidacao[index].valor_original || 0)

        for (let i = 0; i < operacoes.length; i++) {
            const { propriedade, operacao } = operacoes[i];

            if (arrayLiquidacao[index][propriedade] !== undefined) {
                if (operacao === 'soma') {
                    valor_liquidacao += parseFloat(arrayLiquidacao[index][propriedade]);
                } else if (operacao === 'subtrai') {
                    valor_liquidacao -= parseFloat(arrayLiquidacao[index][propriedade]);
                }
            }
        }
        arrayLiquidacao[index].valor = valor_liquidacao

        propsFormik.setFieldValue(`liquidacao[${index}].valor_liquidacao`, valor_liquidacao.toString().trim())
        propsFormik.setFieldValue(`liquidacao[${index}].valor`, valor_liquidacao.toString().trim())
        propsFormik.setFieldValue(`liquidacao[${index}].valor_liquidacao_formatado`, formatarMoeda(valor_liquidacao.toString()))
        propsFormik.setFieldValue(`liquidacao[${index}].valor_formatado`, valor_liquidacao.toString())
    }

    const checarLiquidado = (data_liquidacao, index) => {

        if (props.id) {
            if (!(props?.valoresIniciais?.liquidacao && props?.valoresIniciais?.liquidacao[index]?.data_liquidacao) && moment(data_liquidacao).isValid()) {
                propsFormik.values.liquidacao[index].liquidar = true
            }
            else {
                propsFormik.values.liquidacao[index].liquidar = false
            }
        }
    }

    const atualizarDataLiquidacao = (data) => {

        const data_atualizada = values.liquidacao.map((item, index) => propsFormik.setFieldValue(`liquidacao[${index}].data_liquidacao`, data));

        return data_atualizada
    };

    if (ref) {
        ref.current = {
            atualizarDataLiquidacao: (data) => atualizarDataLiquidacao(data),
        }
    }
    useEffect(_ => {
        if (props.liquidar && props.id) {

            propsFormik.setFieldValue(`liquidacao`, [{ modo_liquidacao: null, data: propsFormik.values.previsao_vencimento_lancamento, valor_liquidacao: formatarMoeda(convertValueToFloat(propsFormik.values.valor_geral_lancamento).toFixed(2)) || undefined, periodicidade: null, parcela: null, valor_original: convertValueToFloat(propsFormik.values.valor_geral_lancamento) }])//Limpar primeira linha liquidação

        }
    }, [props.liquidar])

    return (
        <>
            {
                !propsFormik.values.parcelado_lancamento ?
                    <>
                        <div className={props.tabLancamento === true ? `mt-40 tab-tittle` : 'mt-40'}>
                            <HeaderCadastro
                                titulo={`Liquidação  ${props.id && propsFormik?.values?.liquidacao && propsFormik?.values?.liquidacao[0]?.numero_parcela ? ` (${propsFormik?.values?.liquidacao && propsFormik?.values?.liquidacao[0]?.numero_parcela}° Parcela )` : ''}`}
                                classeChildren='space-between-100'
                                switch={propsFormik.values ? true : false}
                                liquidar={props.liquidar}
                                setLiquidar={props.setLiquidar}
                            />
                        </div>
                        <>
                            {props.liquidar ?
                                <>
                                    <div className="card mb-20 scroll-x">
                                        <div className="card-body  executantes-internos-float" ref={refLiquidacao}>
                                            <FieldArray name="liquidacao">
                                                {(arrayHelpers => (
                                                    <>
                                                        {props?.propsFormik?.values?.liquidacao && props?.propsFormik?.values?.liquidacao?.length > 0 ?
                                                            props?.propsFormik?.values?.liquidacao?.map((item, index) =>
                                                                <>
                                                                    <>
                                                                        <div className={`modal-header pr-0 pl-0 ${index > 0 ? 'bt-1' : ''}`}>
                                                                            <div className='row col-sm-12 col-xl-11 col-lg-11 col-md-12'>

                                                                                <InputCurrency
                                                                                    className='valor-indicador-azul'
                                                                                    onUpdate={val => {
                                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                        inputValorAcrescimo.current[index] = valor
                                                                                    }}
                                                                                    label='Acrescimo R$'
                                                                                    name={`liquidacao[${index}].acrescimo`}
                                                                                    classeTooltip={'tooltip-input-modal'}
                                                                                    propsFormik={propsFormik}
                                                                                    max={100000000}
                                                                                    value={inputValorAcrescimo.current[index] || propsFormik.values.liquidacao[index].acrescimo || propsFormik.values.liquidacao[index].acrescimo_formatado}
                                                                                    onBlur={(e) => {
                                                                                        if (inputValorAcrescimo.current[index] || inputValorAcrescimo.current[index] === 0) {
                                                                                            propsFormik.values.liquidacao[index].acrescimo = parseFloat(inputValorAcrescimo.current[index] && inputValorAcrescimo.current[index].toString().trim());
                                                                                            propsFormik.values.liquidacao[index].acrescimo_formatado = formatarMoeda(inputValorAcrescimo.current && inputValorAcrescimo.current[index].toString());

                                                                                            atualizarValoresLiquidacao(propsFormik.values.liquidacao, index, propsFormik)
                                                                                        }
                                                                                        inputValorAcrescimo.current[index] = null;
                                                                                    }

                                                                                    }
                                                                                    classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />


                                                                                <InputCurrency
                                                                                    className='valor-indicador-errado'
                                                                                    onUpdate={val => {
                                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                        inputValorDesconto.current[index] = valor
                                                                                    }}
                                                                                    label='Desconto R$'
                                                                                    name={`liquidacao[${index}].desconto`}
                                                                                    classeTooltip={'tooltip-input-modal'}
                                                                                    propsFormik={propsFormik}
                                                                                    max={100000000}
                                                                                    value={inputValorDesconto.current[index] || propsFormik.values.liquidacao[index].desconto_formatado || propsFormik.values.liquidacao[index].desconto}
                                                                                    onBlur={(e) => {
                                                                                        if (inputValorDesconto.current[index] || inputValorDesconto.current[index] === 0) {
                                                                                            propsFormik.values.liquidacao[index].desconto = inputValorDesconto.current[index] && inputValorDesconto.current[index].toString().trim();
                                                                                            propsFormik.values.liquidacao[index].desconto_formatado = formatarMoeda(inputValorDesconto.current && inputValorDesconto.current[index].toString());

                                                                                            atualizarValoresLiquidacao(propsFormik.values.liquidacao, index, propsFormik)
                                                                                        }
                                                                                        inputValorDesconto.current[index] = null;
                                                                                    }

                                                                                    }
                                                                                    classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                                <InputCurrency
                                                                                    className='valor-indicador-azul'
                                                                                    onUpdate={val => {
                                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                        inputValorJurosMulta.current[index] = valor
                                                                                    }}
                                                                                    label='Juros/Multa R$'
                                                                                    name={`liquidacao[${index}].juros_multa`}
                                                                                    classeTooltip={'tooltip-input-modal'}
                                                                                    propsFormik={propsFormik}
                                                                                    max={100000000}
                                                                                    value={inputValorJurosMulta.current[index] || propsFormik.values.liquidacao[index].juros_multa_formatado || propsFormik.values.liquidacao[index].juros_multa}
                                                                                    onBlur={(e) => {
                                                                                        if (inputValorJurosMulta.current[index] || inputValorJurosMulta.current[index] === 0) {
                                                                                            propsFormik.values.liquidacao[index].juros_multa = inputValorJurosMulta.current[index] && inputValorJurosMulta.current[index].toString().trim();
                                                                                            propsFormik.values.liquidacao[index].juros_multa_formatado = formatarMoeda(inputValorJurosMulta.current && inputValorJurosMulta.current[index].toString());

                                                                                            atualizarValoresLiquidacao(propsFormik.values.liquidacao, index, propsFormik)
                                                                                        }
                                                                                        inputValorJurosMulta.current[index] = null;
                                                                                    }

                                                                                    }
                                                                                    classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />


                                                                                <InputCurrency
                                                                                    className='valor-indicador-errado'
                                                                                    onUpdate={val => {
                                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                        inputValorImpostoNFE.current[index] = valor
                                                                                    }}
                                                                                    label='Imposto NFSE R$'
                                                                                    name={`liquidacao[${index}].imposto_nfse`}
                                                                                    classeTooltip={'tooltip-input-modal'}
                                                                                    propsFormik={propsFormik}
                                                                                    max={100000000}
                                                                                    value={inputValorImpostoNFE.current[index] || propsFormik.values.liquidacao[index].imposto_nfse_formatado || propsFormik.values.liquidacao[index].imposto_nfse}
                                                                                    onBlur={(e) => {
                                                                                        if (inputValorImpostoNFE.current[index] || inputValorImpostoNFE.current[index] === 0) {
                                                                                            propsFormik.values.liquidacao[index].imposto_nfse = inputValorImpostoNFE.current[index] && inputValorImpostoNFE.current[index].toString().trim();
                                                                                            propsFormik.values.liquidacao[index].imposto_nfse_formatado = formatarMoeda(inputValorImpostoNFE.current && inputValorImpostoNFE.current[index].toString());

                                                                                            atualizarValoresLiquidacao(propsFormik.values.liquidacao, index, propsFormik)
                                                                                        }
                                                                                        inputValorImpostoNFE.current[index] = null;
                                                                                    }

                                                                                    }
                                                                                    classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                                <InputCurrency
                                                                                    className='valor-indicador-errado'
                                                                                    onUpdate={val => {
                                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                        inputValorGlosa.current[index] = valor
                                                                                    }}
                                                                                    label='Glosa R$'
                                                                                    name={`liquidacao[${index}].glosas`}
                                                                                    classeTooltip={'tooltip-input-modal'}
                                                                                    propsFormik={propsFormik}
                                                                                    max={100000000}
                                                                                    value={inputValorGlosa.current[index] || propsFormik.values.liquidacao[index].glosas_formatado || propsFormik.values.liquidacao[index].glosas}
                                                                                    onBlur={(e) => {
                                                                                        if (inputValorGlosa.current[index] || inputValorGlosa.current[index] === 0) {
                                                                                            propsFormik.values.liquidacao[index].glosas = inputValorGlosa.current[index] && inputValorGlosa.current[index].toString().trim() || 0;
                                                                                            propsFormik.values.liquidacao[index].glosas_formatado = formatarMoeda(inputValorGlosa.current && inputValorGlosa.current[index].toString());

                                                                                            atualizarValoresLiquidacao(propsFormik.values.liquidacao, index, propsFormik)
                                                                                        }
                                                                                        inputValorGlosa.current[index] = null;
                                                                                    }

                                                                                    }
                                                                                    classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />


                                                                                <InputCurrency
                                                                                    className='valor-indicador-errado'
                                                                                    onUpdate={val => {
                                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                        inputValorOutrosImpostos.current[index] = valor
                                                                                    }}
                                                                                    label='Outros R$'
                                                                                    name={`liquidacao[${index}].outros_impostos`}
                                                                                    classeTooltip={'tooltip-input-modal'}
                                                                                    propsFormik={propsFormik}
                                                                                    max={100000000}
                                                                                    value={inputValorOutrosImpostos.current[index] || propsFormik.values.liquidacao[index].outros_impostos_formatado || propsFormik.values.liquidacao[index].outros_impostos}
                                                                                    onBlur={(e) => {
                                                                                        if (inputValorOutrosImpostos.current[index] || inputValorOutrosImpostos.current[index] === 0) {
                                                                                            propsFormik.values.liquidacao[index].outros_impostos = inputValorOutrosImpostos.current[index] && inputValorOutrosImpostos.current[index].toString().trim();
                                                                                            propsFormik.values.liquidacao[index].outros_impostos_formatado = formatarMoeda(inputValorOutrosImpostos.current && inputValorOutrosImpostos.current[index].toString());

                                                                                            atualizarValoresLiquidacao(propsFormik.values.liquidacao, index, propsFormik)
                                                                                        }
                                                                                        inputValorOutrosImpostos.current[index] = null;
                                                                                    }


                                                                                    }
                                                                                    classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    <div className='modal-body-cadastro row mr-0 ml-0'>
                                                                        <div className='row col-sm-12 col-xl-11 col-lg-11 col-md-12'>
                                                                            <InputModoLiquidacao label={index === 0 ? 'Modo Liquidação' : undefined} obrigatorio={true} placeholder='Modo Liquidação' classeTooltip={'tooltip-input-modal'}
                                                                                name={`liquidacao[${index}].modo_liquidacao`} array={true} isClearable={false} propsFormik={props.propsFormik} tamanho="col-sm-12 8 col-xl-4 col-lg-4 col-md-12 pr-0"
                                                                                onChange={(option) => { atualizarDataLiquidacao(propsFormik?.values?.liquidacao[0]?.data_liquidacao) }} onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                                                            />

                                                                            <InputDatePicker tamanho={'col-lg-3 col-sm-12'} label={index === 0 ? "Data" : undefined} name={`liquidacao[${index}].data_liquidacao`} type="text" obrigatorio="true"
                                                                                setarValor={value => {
                                                                                    propsFormik.setFieldValue(`liquidacao[${index}].data_liquidacao`, value)
                                                                                    checarLiquidado(value, index)
                                                                                }} classeTooltip={props.modal && 'tooltip-input-modal'} mostrarFeriado={true} placeholder={'Data'}
                                                                                disabled={index > 0}
                                                                                onBlurData={(data) => atualizarDataLiquidacao(data)}
                                                                                mostrarValidacaoAfter={props.liquidar}
                                                                            />
                                                                            <InputCurrency onUpdate={val => {
                                                                                let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                inputValorLiquidacao.current[index] = valor
                                                                            }}
                                                                                label={index === 0 ? 'Valor R$' : undefined} name={`liquidacao[${index}].valor`}
                                                                                value={inputValorLiquidacao?.current[index] || propsFormik?.values?.liquidacao[index]?.valor_liquidacao_formatado || props?.propsFormik?.values?.liquidacao[index]?.valor_liquidacao}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={props.propsFormik}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorLiquidacao.current[index] || inputValorLiquidacao.current[index] === 0) {
                                                                                        propsFormik.setFieldValue(`liquidacao[${index}].valor_liquidacao`, inputValorLiquidacao.current[index] && inputValorLiquidacao.current[index].toString().trim())
                                                                                        propsFormik.setFieldValue(`liquidacao[${index}].valor_liquidacao_formatado`, formatarMoeda(inputValorLiquidacao.current[index] && inputValorLiquidacao.current[index].toString()))
                                                                                        propsFormik.setFieldValue(`liquidacao[${index}].valor_original`, inputValorLiquidacao.current[index] && inputValorLiquidacao.current[index].toString())
                                                                                        calcularValorTotal()
                                                                                    }
                                                                                    inputValorLiquidacao.current[index] = null;
                                                                                }}
                                                                                classDiv='col-8 col-xl-5 col-lg-3 col-md-12 col-sm-12 pr-0' obrigatorio={true} mostrarValidacaoAfter={props.liquidar} />

                                                                        </div>
                                                                        <div className='col-4 col-xl-1 col-lg-1 col-md-12 mb-0 pr-0 pl-1 pb-3 content-bottom-left ml-1 text-right'>
                                                                            <BtnAcao type={'button'} icone="icon-Add" texto="Adicionar" action={() => adicionarLinhaNovo(arrayHelpers, propsFormik, index)} />
                                                                            <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, props.propsFormik, index)} />
                                                                            {propsFormik.values.liquidacao[index].modo_liquidacao ?
                                                                                <BtnAcao type={'button'} icone={propsFormik?.values?.liquidacao[index]?.show_anotacoes ? 'icon-Yes' : "icon-Note"} texto={`${propsFormik?.values?.liquidacao[index]?.anotacoes ? 'Alterar' : 'Adicionar'} Anotações`} action={() => propsFormik.setFieldValue(`liquidacao[${index}].show_anotacoes`, !propsFormik?.values?.liquidacao[index]?.show_anotacoes)} />
                                                                                :
                                                                                <></>}
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        ((propsFormik?.values?.liquidacao[index]?.anotacoes || propsFormik?.values?.liquidacao[index]?.show_anotacoes) && propsFormik.values.liquidacao[index].modo_liquidacao) && <div className='modal-body-cadastro row mr-0 ml-0'>
                                                                            {propsFormik?.values?.liquidacao[index]?.show_anotacoes ?
                                                                                <TextArea value={propsFormik?.values?.liquidacao[index]?.anotacoes} name={`liquidacao[${index}].anotacoes`} type='text' placeholder='Anotações' tamanho={'col-12'} classeTooltip={'tooltip-input-modal'} rows={2} />
                                                                                : (propsFormik.values.liquidacao[index].modo_liquidacao && parseFloat(convertValueToFloat(propsFormik.values.liquidacao[index].valor)) >= 0) ?
                                                                                    <div className='anotacoes'>
                                                                                        <div className='form-group display-flex col-12 anotacoes-liquidacao'>
                                                                                            <span className='icon-Note'></span>
                                                                                            <h6>{propsFormik?.values?.liquidacao[index]?.anotacoes}</h6>
                                                                                        </div>
                                                                                    </div> : <></>}
                                                                        </div>
                                                                    }
                                                                </>)
                                                            :
                                                            <></>}

                                                    </>
                                                ))}
                                            </FieldArray>
                                        </div>
                                        <ModalDuasOpcoes showModal={showModalValidaValor} setShowModal={setShowModalValidaValor}
                                            funcaoOpcao2={() => { setShowModalValidaValor(false) }}
                                            funcaoOpcao1={() => { setShowModalValidaValor(false) }}
                                            esconderFechar mensagem={<>{msgValidacao}<br /></>}
                                            textoOpcao1={'Fechar'}
                                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                                            textoOpcao2={'Continuar'} />
                                        <ModalDuasOpcoes showModal={showModalValidaGuiaRepetida} setShowModal={setShowModalValidaGuiaRepetida}
                                            funcaoOpcao2={() => { setShowModalValidaGuiaRepetida(false); verificaValor() }}
                                            funcaoOpcao1={() => { setShowModalValidaGuiaRepetida(false) }}
                                            esconderFechar mensagem={<>{msgValidacaoGuiaRepetida}<br /></>}
                                            textoOpcao1={'Fechar'}
                                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                                            textoOpcao2={'Continuar'} />
                                    </div>

                                    <div className='modal-body-cadastro row mr-0 ml-0 mt-2'>
                                        <div className={"col-11 text-right vertical-center"}>
                                            <span>Total: <span className={`ml-1 `}><b>{valorTotalLiquidacao}</b></span></span>
                                        </div>

                                    </div>
                                </>

                                : <></>}

                            <></>
                        </>
                    </>
                    :
                    <></>
            }
        </>


    )
})

const CadastroLiquidacaoParcelado = React.forwardRef(({ dadosMovimentoLancamento, ...props }, ref) => {
    const refLiquidacaoParcelado = useRef(null)
    let { propsFormik } = props
    const inputValorLiquidacaoParcelado = useRef([]);
    const inputValorLiquidaParcela = useRef();
    const [arrayRef, setArrayRef] = useState(Array(propsFormik.values?.liquidacao ? props.propsFormik.values.liquidacao.length : 1).fill(React.createRef()))
    const [minimizar, setMinimizar] = useState([true])
    const [disabled, setDisabled] = useState(false)
    const [valorTotalLiquidacaoParcelado, setValorTotalLiquidacaoParcelado] = useState(formatarMoeda(0.00))
    const [valorTotalLiquidadoParcelado, setValorTotalLiquidadoParcelado] = useState(formatarMoeda(0.00))
    const [msgValidacao, setMsgValidacao] = useState([])
    const [msgValidacaoGuiaRepetida, setMsgValidacaoGuiaRepetida] = useState([])
    const [showModalValidaValor, setShowModalValidaValor] = useState(false)
    const [showModalValidaGuiaRepetida, setShowModalValidaGuiaRepetida] = useState(false)
    const inputValorAcrescimo = useRef([]);
    const inputValorDesconto = useRef([]);
    const inputValorJurosMulta = useRef([]);
    const inputValorImpostoNFE = useRef([]);
    const inputValorGlosa = useRef([]);
    const inputValorOutrosImpostos = useRef([]);

    const removerLinha = (arrayHelpers, propsFormik, index) => {
        propsFormik.setFieldValue(`liquidacao_parcelado`, [])
        inputValorAcrescimo.current.splice(index, 1)
        inputValorDesconto.current.splice(index, 1)
        inputValorJurosMulta.current.splice(index, 1)
        inputValorImpostoNFE.current.splice(index, 1)
        inputValorGlosa.current.splice(index, 1)
        inputValorOutrosImpostos.current.splice(index, 1)

        if (arrayHelpers) {//Removendo por linha parcelado
            arrayHelpers.remove(index)
            propsFormik.setFieldValue(`liquidacao[0].parcela`, propsFormik.values.liquidacao_parcelado.length - 1)
        }
        else {
            propsFormik.setFieldValue(`liquidacao[0]`, [{ data: moment().format('DD/MM/YYYY'), valor: '', periodicidade: null, parcela: null }])
        }
    }

    const removerParcela = (propsFormik, index) => {
        let parcelas = propsFormik.values.liquidacao_parcelado
        parcelas.splice(index, 1)
        propsFormik.setFieldValue('liquidacao_parcelado', parcelas)
        propsFormik.setFieldValue(`liquidacao[0].parcela`, parcelas.length)
    }

    const adicionarLinhaParcelado = (option, index, arrayHelpers) => {

        propsFormik.values.liquida_parcela.parcela = option.target.value
        let linhas = []
        let arrayResult = propsFormik?.values?.liquidacao_parcelado && propsFormik?.values?.liquidacao_parcelado.length ? [...propsFormik?.values?.liquidacao_parcelado] : []
        propsFormik.values.liquidacao_parcelado = propsFormik.values.liquidacao_parcelado ? propsFormik.values.liquidacao_parcelado : [];

        if (option.target.value < arrayResult.length) {
            arrayResult = arrayResult.slice(0, parseInt(option.target.value) - arrayResult.length)
        }
        else if (parseInt(option.target.value) === arrayResult.length)
            return;


        for (let i = 0; i < option.target.value; i++) {
            setArrayRef([...arrayRef, React.createRef()])
            linhas[i] = arrayResult[i] ? arrayResult[i] : { modo_liquidacao: undefined, cliente: undefined, data_liquidacao: undefined, liquidado: false, data_liquidado: undefined, valor: undefined }

        }

        if (!option.target.value) //Para caso o valor no campo parcela seja removido
            propsFormik.setFieldValue('liquidacao_parcelado', {})

        propsFormik.values.liquidacao_parcelado = linhas
        calculaDataLiquidacao()

    }

    const verificaValor = _ => {

        let valorLiquidado = propsFormik?.values?.liquidacao_parcelado?.filter(x => x?.valor_liquidacao).reduce((partialSum, a) => partialSum + convertValueToFloat(a?.valor_liquidacao), 0)
        let valorLancamento = parseFloat(propsFormik?.values?.valor_geral_lancamento)
        if (valorLiquidado !== valorLancamento) {
            setMsgValidacao(<>Valor Liquidado de <b>{formatarMoeda(valorLiquidado)}</b> não é igual ao Valor Lançado de <b>{formatarMoeda(valorLancamento)}</b> deseja continuar ? </>)
            setShowModalValidaValor(true)
        }
    }

    const calcularValorTotalParcelado = _ => {
        const liquidacaoParcelado = propsFormik.values.liquidacao_parcelado
        let valorTotal = liquidacaoParcelado?.map(x => parseFloat(x?.valor_liquidacao || 0)).reduce((ant, atual) => ant + atual, 0);
        setValorTotalLiquidacaoParcelado(formatarMoeda(valorTotal))

    }

    useEffect(_ => {
        calcularValorTotalParcelado()
        if (propsFormik.values.parcelado_lancamento) {
            propsFormik.setFieldValue('liquidacao', [])

        }
        else
            propsFormik.setFieldValue('liquidacao_parcelado', [])

    }, [propsFormik.values?.parcelado_lancamento])

    useEffect(_ => {

        const liquidacaoParcelado = propsFormik.values.liquidacao_parcelado
        const valor = liquidacaoParcelado && Object.keys(liquidacaoParcelado).length > 0 && liquidacaoParcelado?.filter(x => x.liquidado).map(x => parseFloat(x?.valor_liquidacao || 0)).reduce((ant, atual) => ant + atual, 0)
        setValorTotalLiquidadoParcelado(formatarMoeda(valor))

    }, [propsFormik.values?.liquidacao_parcelado])

    const calculaDataLiquidacao = (option, p, valor) => {


        if (p)
            if (propsFormik.values && propsFormik.values.liquida_parcela)
                propsFormik.values.liquida_parcela.periodicidade = option
        let periodicidade = propsFormik?.values?.liquida_parcela?.periodicidade || null
        let data
        if (propsFormik?.values?.liquida_parcela?.data && propsFormik?.values?.liquidacao_parcelado && propsFormik?.values?.liquida_parcela?.periodicidade) {
            data = propsFormik?.values?.liquida_parcela?.data
            for (let idx in propsFormik.values.liquidacao_parcelado) {
                if (idx > 0) {
                    data = moment(data, 'DD/MM/YYYY')
                    data = data.add(parseInt(periodicidade?.dias), 'days').format('DD/MM/YYYY')

                }
                propsFormik.values.liquidacao_parcelado[idx].data = data
            }
        }
        if ((propsFormik?.values?.liquida_parcela?.valor || valor) && parseInt(propsFormik.values.liquida_parcela?.parcela) !== 0) {
            let valorLancamento = convertValueToFloat(propsFormik?.values?.liquida_parcela?.valor)
            let qtdParcela = parseInt(propsFormik?.values?.liquida_parcela?.parcela)
            let valorPorParcela = valorLancamento / qtdParcela
            let valores = Array.isArray(propsFormik?.values?.liquidacao_parcelado) ? propsFormik?.values?.liquidacao_parcelado?.map((x) => ({
                ...x, valor_liquidacao: valorPorParcela,
                valor_liquidacao_formatado: formatarMoeda(valorPorParcela.toFixed(2)),
                valor: valorPorParcela,
                valor_original: valorPorParcela
            })) : [];

            propsFormik.values.liquidacao_parcelado = valores

            calcularValorTotalParcelado()

        }

    }

    const operacoes = [
        { propriedade: 'acrescimo', operacao: 'soma' },
        { propriedade: 'desconto', operacao: 'subtrai' },
        { propriedade: 'juros_multa', operacao: 'soma' },
        { propriedade: 'imposto_nfse', operacao: 'subtrai' },
        { propriedade: 'glosas', operacao: 'subtrai' },
        { propriedade: 'outros_impostos', operacao: 'subtrai' }
    ];


    useEffect(_ => {
        if (propsFormik.values.parcelado_lancamento) {
            propsFormik.setFieldValue('liquida_parcela.valor', convertValueToFloat(propsFormik.values.valor_geral_lancamento))
            inputValorLiquidaParcela.current = convertValueToFloat(propsFormik.values.valor_geral_lancamento)
            calculaDataLiquidacao(null, null, convertValueToFloat(propsFormik.values.valor_geral_lancamento))
            let teste = propsFormik.values.liquidacao_parcelado?.filter(x => x.acrescimo || x.desconto || x.juros_multa
                || x.imposto_nfse || x.glosas || x.outros_impostos)?.map((_, i) => atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, i, propsFormik))
        }

    }, [propsFormik.values?.valor_geral_lancamento])


    const atualizarValoresLiquidacao = React.useMemo(() => {
        return (arrayLiquidacao, index, propsFormik) => {

            if (arrayLiquidacao) {


                let valor_liquidacao = parseFloat(arrayLiquidacao[index]?.valor_original || 0)

                for (let i = 0; i < operacoes.length; i++) {
                    const { propriedade, operacao } = operacoes[i];

                    if (arrayLiquidacao[index][propriedade] !== undefined) {
                        if (operacao === 'soma') {
                            valor_liquidacao += parseFloat(arrayLiquidacao[index][propriedade]);
                        } else if (operacao === 'subtrai') {
                            valor_liquidacao -= parseFloat(arrayLiquidacao[index][propriedade]);
                        }
                    }
                }
                arrayLiquidacao[index].valor = valor_liquidacao

                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor_liquidacao`, valor_liquidacao.toString().trim())
                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor`, valor_liquidacao.toString().trim())
                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor_liquidacao_formatado`, formatarMoeda(valor_liquidacao.toString()))
                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor_formatado`, valor_liquidacao.toString())
            }
        }
    }, [])

    return (
        <>
            {propsFormik.values?.parcelado_lancamento &&
                <>
                    <div className={props.tabLancamento === true ? `mt-40 tab-tittle` : 'mt-40'}>
                        <HeaderCadastro
                            titulo={`LIQUIDAÇÃO PARCELADO`}
                            classeChildren='space-between-100'
                        >
                        </HeaderCadastro>
                    </div>

                    {/* {!minimizar[0] ? */}
                    <>

                        <div className="card mb-20 scroll-x">
                            <div className="card-body  executantes-internos-float" ref={refLiquidacaoParcelado}>
                                <div className='modal-body-cadastro row mr-0 ml-0'>
                                    <div className='row col-sm-12 col-xl-11 col-lg-12 col-md-12'>
                                        <InputDatePicker tamanho={'col-xl-3'} label={"Data"} name={`liquida_parcela.data`} type="text" obrigatorio="true"
                                            setarValor={value => {
                                                propsFormik.setFieldValue(`liquida_parcela.data`, value)
                                            }} classeTooltip={props.modal && 'tooltip-input-modal'} mostrarFeriado={true} placeholder={'Data'}
                                            disabled={disabled}
                                            onChange={value => {
                                                // calculaDataLiquidacao();
                                                propsFormik.setFieldValue(`liquida_parcela.data`, value)
                                            }}
                                            onBlurData={_ => {
                                                calculaDataLiquidacao();
                                            }}
                                        />
                                        <InputCurrency
                                            label={'Valor R$'} name={`liquida_parcela.valor`}
                                            value={inputValorLiquidaParcela?.current || (propsFormik?.values?.liquidacao && propsFormik?.values?.liquidacao[0]?.valor) || propsFormik?.values?.liquida_parcela?.valor || propsFormik?.values?.liquida_parcela?.valor_formatado}
                                            classeTooltip={'tooltip-input-modal'}
                                            propsFormik={props.propsFormik}
                                            disabled={disabled}
                                            onUpdate={val => {
                                                let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                inputValorLiquidaParcela.current = valor
                                                // calculaDataLiquidacao(null, false)
                                            }}
                                            onBlur={(e) => {
                                                if (inputValorLiquidaParcela.current || inputValorLiquidaParcela.current === 0) {
                                                    propsFormik.values.liquida_parcela.valor = inputValorLiquidaParcela.current[0] && inputValorLiquidaParcela.current.toString().trim();
                                                    propsFormik.values.liquida_parcela.valor_formatado = formatarMoeda(inputValorLiquidaParcela.current && inputValorLiquidaParcela.current.toString());
                                                    calculaDataLiquidacao(null, false, e.currentTarget.value)
                                                }
                                                inputValorLiquidaParcela.current = null;
                                            }}
                                            classDiv='col-8 col-xl-3 col-lg-2 col-md-2 pr-0' obrigatorio={true} />
                                        <InputPeriodicidade label={'Periodicidade'} obrigatorio={true} placeholder='Periodicidade' classeTooltip={'tooltip-input-modal'}
                                            name={`liquida_parcela.periodicidade`} propsFormik={propsFormik} tamanho="col-sm-3 8 col-xl-3 col-lg-2 col-md-2 pr-0"
                                            onChange={(option) => {
                                                propsFormik.setFieldValue(`liquida_parcela.periodicidade`, option); calculaDataLiquidacao(option, true);
                                            }}
                                            disabled={disabled}
                                        />

                                        <InputPadrao label="Parcela(s)" name={`liquida_parcela.parcela`} placeholder="Parcela(s)" obrigatorio={true} tamanho="col-sm-3 8 col-xl-3 col-lg-2 col-md-2 pr-0"
                                            onBlur={(option) => { adicionarLinhaParcelado(option, null, null); }} disabled={disabled} type='text' maxLength={2}
                                        />


                                    </div>
                                    <div className='col-4 col-xl-1 col-lg-1 col-md-1 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right'>
                                        <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(null, propsFormik, 0)} />
                                    </div>

                                </div>
                                <FieldArray name="liquidacao_parcelado">
                                    {(arrayHelpers => (
                                        <>
                                            {propsFormik.values.liquidacao_parcelado && propsFormik.values.liquidacao_parcelado.length > 0 ?

                                                <div className='box-liquidacao-parcelado'>
                                                    {propsFormik.values.liquidacao_parcelado.map((item, index) =>
                                                        <>
                                                            <>
                                                                {propsFormik.values.liquidacao_parcelado[index].liquidado ?
                                                                    <div className={`modal-header pr-0 pl-0 ${index > 0 ? 'bt-1' : ''}`}>
                                                                        <div className='row col-sm-12 col-xl-11 col-lg-11 col-md-12'>
                                                                            <InputCurrency
                                                                                className='valor-indicador-azul'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorAcrescimo.current[index] = valor
                                                                                }}
                                                                                label='Acrescimo R$'
                                                                                name={`liquidacao_parcelado[${index}].acrescimo`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorAcrescimo.current[index] || propsFormik.values.liquidacao_parcelado[index]?.acrescimo || propsFormik.values.liquidacao_parcelado[index]?.acrescimo_formatado}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorAcrescimo.current[index] || inputValorAcrescimo.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao_parcelado[index].acrescimo = parseFloat(inputValorAcrescimo.current[index] && inputValorAcrescimo.current[index].toString().trim());
                                                                                        propsFormik.values.liquidacao_parcelado[index].acrescimo_formatado = formatarMoeda(inputValorAcrescimo.current && inputValorAcrescimo.current[index].toString());

                                                                                        atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, index, propsFormik)
                                                                                    }
                                                                                    inputValorAcrescimo.current[index] = null;
                                                                                }

                                                                                }
                                                                                classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />


                                                                            <InputCurrency
                                                                                className='valor-indicador-errado'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorDesconto.current[index] = valor
                                                                                }}
                                                                                label='Desconto R$'
                                                                                name={`liquidacao_parcelado[${index}].desconto`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorDesconto.current[index] || propsFormik.values.liquidacao_parcelado[index]?.desconto_formatado || propsFormik.values.liquidacao_parcelado[index]?.desconto}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorDesconto.current[index] || inputValorDesconto.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao_parcelado[index].desconto = inputValorDesconto.current[index] && inputValorDesconto.current[index].toString().trim();
                                                                                        propsFormik.values.liquidacao_parcelado[index].desconto_formatado = formatarMoeda(inputValorDesconto.current && inputValorDesconto.current[index].toString());

                                                                                        atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, index, propsFormik)
                                                                                    }
                                                                                    inputValorDesconto.current[index] = null;
                                                                                }

                                                                                }
                                                                                classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                            <InputCurrency
                                                                                className='valor-indicador-azul'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorJurosMulta.current[index] = valor
                                                                                }}
                                                                                label='Juros/Multa R$'
                                                                                name={`liquidacao_parcelado[${index}].juros_multa`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorJurosMulta.current[index] || propsFormik.values.liquidacao_parcelado[index]?.juros_multa_formatado || propsFormik.values.liquidacao_parcelado[index]?.juros_multa}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorJurosMulta.current[index] || inputValorJurosMulta.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao_parcelado[index].juros_multa = inputValorJurosMulta.current[index] && inputValorJurosMulta.current[index].toString().trim();
                                                                                        propsFormik.values.liquidacao_parcelado[index].juros_multa_formatado = formatarMoeda(inputValorJurosMulta.current && inputValorJurosMulta.current[index].toString());

                                                                                        atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, index, propsFormik)
                                                                                    }
                                                                                    inputValorJurosMulta.current[index] = null;
                                                                                }

                                                                                }
                                                                                classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />


                                                                            <InputCurrency
                                                                                className='valor-indicador-errado'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorImpostoNFE.current[index] = valor
                                                                                }}
                                                                                label='Imposto NFSE R$'
                                                                                name={`liquidacao_parcelado[${index}].imposto_nfse`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorImpostoNFE.current[index] || propsFormik.values.liquidacao_parcelado[index]?.imposto_nfse_formatado || propsFormik.values.liquidacao_parcelado[index]?.imposto_nfse}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorImpostoNFE.current[index] || inputValorImpostoNFE.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao_parcelado[index].imposto_nfse = inputValorImpostoNFE.current[index] && inputValorImpostoNFE.current[index].toString().trim();
                                                                                        propsFormik.values.liquidacao_parcelado[index].imposto_nfse_formatado = formatarMoeda(inputValorImpostoNFE.current && inputValorImpostoNFE.current[index].toString());

                                                                                        atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, index, propsFormik)
                                                                                    }
                                                                                    inputValorImpostoNFE.current[index] = null;
                                                                                }

                                                                                }
                                                                                classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                            <InputCurrency
                                                                                className='valor-indicador-errado'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorGlosa.current[index] = valor
                                                                                }}
                                                                                label='Glosa R$'
                                                                                name={`liquidacao_parcelado[${index}].glosas`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorGlosa.current[index] || propsFormik.values.liquidacao_parcelado[index]?.glosas_formatado || propsFormik.values.liquidacao_parcelado[index]?.glosas}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorGlosa.current[index] || inputValorGlosa.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao_parcelado[index].glosas = inputValorGlosa.current[index] && inputValorGlosa.current[index].toString().trim() || 0;
                                                                                        propsFormik.values.liquidacao_parcelado[index].glosas_formatado = formatarMoeda(inputValorGlosa.current && inputValorGlosa.current[index].toString());

                                                                                        atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, index, propsFormik)
                                                                                    }
                                                                                    inputValorGlosa.current[index] = null;
                                                                                }

                                                                                }
                                                                                classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                            <InputCurrency
                                                                                className='valor-indicador-errado'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorOutrosImpostos.current[index] = valor
                                                                                }}
                                                                                label='Outros R$'
                                                                                name={`liquidacao_parcelado[${index}].outros_impostos`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorOutrosImpostos.current[index] || propsFormik.values.liquidacao_parcelado[index]?.outros_impostos_formatado || propsFormik.values.liquidacao_parcelado[index]?.outros_impostos}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorOutrosImpostos.current[index] || inputValorOutrosImpostos.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao_parcelado[index].outros_impostos = inputValorOutrosImpostos.current[index] && inputValorOutrosImpostos.current[index].toString().trim();
                                                                                        propsFormik.values.liquidacao_parcelado[index].outros_impostos_formatado = formatarMoeda(inputValorOutrosImpostos.current && inputValorOutrosImpostos.current[index].toString());

                                                                                        atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, index, propsFormik)
                                                                                    }
                                                                                    inputValorOutrosImpostos.current[index] = null;
                                                                                }
                                                                                }
                                                                                classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                        </div>
                                                                    </div> : <></>}
                                                            </>
                                                            <div className='modal-body-cadastro row mr-0 ml-0'>
                                                                <div className='row col-sm-12 col-xl-11 col-lg-12 col-md-12'>
                                                                    <div className={"form-group col-xl-1 col-lg-1 col-1 pl-0 text-center"}>
                                                                        <label htmlFor={props.id || props.name}>{index === 0 ? 'Parcela' : undefined}</label>
                                                                        <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{index + 1}</span></div>
                                                                    </div>
                                                                    <InputModoLiquidacao label={index === 0 ? 'Modo Liquidação' : undefined} obrigatorio={true} placeholder='Modo Liquidação' classeTooltip={'tooltip-input-modal'}
                                                                        array={true} name={`liquidacao_parcelado[${index}].modo_liquidacao`} propsFormik={propsFormik} tamanho="col-sm-3 8 col-xl-4 col-lg-2 col-md-2 pr-0"
                                                                    />
                                                                    <InputDatePicker tamanho={'col-xl-2'} label={index === 0 ? "Data Prevista" : undefined} name={`liquidacao_parcelado[${index}].data`} type="text" obrigatorio="true"
                                                                        setarValor={value => {
                                                                            propsFormik.setFieldValue(`liquidacao_parcelado[${index}].data`, value)
                                                                        }} classeTooltip={props.modal && 'tooltip-input-modal'} mostrarFeriado={true} placeholder='Data'
                                                                    />
                                                                    <InputSwitch label={index === 0 ? 'Liquidado?' : undefined} checked={propsFormik.values.liquidacao_parcelado[index].liquidado && propsFormik.values.liquidacao_parcelado[index].liquidado} quebrarLinha
                                                                        propsFormik={propsFormik} classeLabel={(index === 0 ? '' : 'justify-center-vertical')} classeDiv='form-group ajusta-switch'
                                                                        name={`liquidacao_parcelado[${index}].liquidado`} size="md" tamanho={!props.modal && 'col-xl-1 col-lg-2 col-md-2 col-sm-12 col-12 pr-0'} classeTooltip={props.modal && 'tooltip-input-modal'}
                                                                        onChange={(option) => {
                                                                            propsFormik.setFieldValue(`liquidacao_parcelado[${index}].liquidado`, option)
                                                                            if (option === true) {
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].data_liquidacao`, moment().format('DD/MM/YYYY'))
                                                                                // inputValores.current.push({ acrescimo: 0, desconto: 0, juros_multa: 0, imposto_nfse: 0, glosas: 0, outros_impostos: 0 })
                                                                                inputValorAcrescimo.current.push(0)
                                                                                inputValorDesconto.current.push(0)
                                                                                inputValorJurosMulta.current.push(0)
                                                                                inputValorImpostoNFE.current.push(0)
                                                                                inputValorGlosa.current.push(0)
                                                                                inputValorOutrosImpostos.current.push(0)
                                                                            }
                                                                            else {
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].anotacoes`, null)
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].data_liquidacao`, null)
                                                                            }
                                                                        }}
                                                                    />
                                                                    <InputDatePicker tamanho={'col-8 col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12'} label={index === 0 ? "Data Liquidação" : undefined} name={`liquidacao_parcelado[${index}].data_liquidacao`} type="text" obrigatorio="true"
                                                                        // disabled={(props.idMovimento || props.tabLancamento) ? false : true}
                                                                        onBlurData={(value) => {
                                                                            if (moment(value, 'DD/MM/YYYYY').isValid())
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].liquidado`, true)
                                                                            else
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].liquidado`, false)
                                                                        }}
                                                                        setarValor={value => {
                                                                            propsFormik.setFieldValue(`liquidacao_parcelado[${index}].data_liquidacao`, value)
                                                                        }} classeTooltip={props.modal && 'tooltip-input-modal'} mostrarFeriado={true} placeholder='Data Liquidação'
                                                                    />
                                                                    <InputCurrency onUpdate={val => {
                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                        inputValorLiquidacaoParcelado.current[index] = valor
                                                                    }}
                                                                        label={index === 0 ? 'Valor R$' : undefined} name={`liquidacao_parcelado[${index}].valor_liquidacao`}
                                                                        value={inputValorLiquidacaoParcelado.current[index] || propsFormik.values.liquidacao_parcelado[index].valor_liquidacao_formatado || props.propsFormik.values.liquidacao_parcelado[index].valor_liquidacao}
                                                                        classeTooltip={'tooltip-input-modal'}
                                                                        propsFormik={props.propsFormik}
                                                                        onBlur={(e) => {
                                                                            if (inputValorLiquidacaoParcelado.current[index] || inputValorLiquidacaoParcelado.current[index] === 0) {
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor_liquidacao`, inputValorLiquidacaoParcelado.current[index] && inputValorLiquidacaoParcelado.current[index].toString().trim())
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor_liquidacao_formatado`, formatarMoeda(inputValorLiquidacaoParcelado.current[index] && inputValorLiquidacaoParcelado.current[index].toString()))

                                                                                calcularValorTotalParcelado()
                                                                            }
                                                                            inputValorLiquidacaoParcelado.current[index] = null;

                                                                        }}
                                                                        classDiv='col-8 col-xl-2 col-lg-2 col-md-2  pr-0' />
                                                                </div>
                                                                <div className='col-4 col-xl-1 col-lg-1 col-md-1 mb-0 pr-0 pl-1 pb-3 content-bottom-left ml-2 text-right'>
                                                                    <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerParcela(props.propsFormik, index)} />
                                                                    {propsFormik.values.liquidacao_parcelado[index].liquidado ?
                                                                        <BtnAcao type={'button'} icone={propsFormik.values.liquidacao_parcelado[index].show_anotacoes ? 'icon-Yes' : "icon-Note"} texto={`${propsFormik.values.liquidacao_parcelado[index].anotacoes ? 'Alterar' : 'Adicionar'} Anotações`} action={() => propsFormik.setFieldValue(`liquidacao_parcelado[${index}].show_anotacoes`, !propsFormik.values.liquidacao_parcelado[index].show_anotacoes)} />
                                                                        : <></>}
                                                                </div>
                                                            </div>
                                                            {
                                                                ((propsFormik.values.liquidacao_parcelado[index].anotacoes || propsFormik.values.liquidacao_parcelado[index].show_anotacoes) && propsFormik.values.liquidacao_parcelado[index].liquidado) && <div className='modal-body-cadastro row mr-0 ml-0'>
                                                                    {propsFormik.values.liquidacao_parcelado[index].show_anotacoes ? <TextArea value={propsFormik.values.liquidacao_parcelado[index].anotacoes} name={`liquidacao_parcelado[${index}].anotacoes`} type='text' placeholder='Anotações' tamanho={'col-12'} classeTooltip={'tooltip-input-modal'} rows={2} />
                                                                        : <div className='anotacoes'>
                                                                            <div className='form-group display-flex col-12 anotacoes-liquidacao'>
                                                                                <span className='icon-Note'></span>
                                                                                <h6>{propsFormik.values.liquidacao_parcelado[index].anotacoes}</h6>
                                                                            </div>
                                                                        </div>}
                                                                </div>
                                                            }
                                                        </>)}
                                                </div>
                                                :
                                                <></>}
                                        </>
                                    ))}
                                </FieldArray>

                            </div>
                            <ModalDuasOpcoes showModal={showModalValidaValor} setShowModal={setShowModalValidaValor}
                                funcaoOpcao2={() => { setShowModalValidaValor(false) }}
                                funcaoOpcao1={() => { setShowModalValidaValor(false) }}
                                esconderFechar mensagem={<>{msgValidacao}<br /></>}
                                textoOpcao1={'Fechar'}
                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                textoOpcao2={'Continuar'} />
                            <ModalDuasOpcoes showModal={showModalValidaGuiaRepetida} setShowModal={setShowModalValidaGuiaRepetida}
                                funcaoOpcao2={() => { setShowModalValidaGuiaRepetida(false); verificaValor() }}
                                funcaoOpcao1={() => { setShowModalValidaGuiaRepetida(false) }}
                                esconderFechar mensagem={<>{msgValidacaoGuiaRepetida}<br /></>}
                                textoOpcao1={'Fechar'}
                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                textoOpcao2={'Continuar'} />
                        </div>
                        {propsFormik?.values?.liquidacao_parcelado?.length > 1 || propsFormik?.values?.liquidacao_parcelado && propsFormik?.values?.liquidacao_parcelado[0]?.parcelado ?
                            <div className='modal-body-cadastro row mr-0 ml-0 mt-2'>
                                <div className={"col-11 text-right vertical-center"}>
                                    <span>Total: <span className={`ml-1`}><b>{valorTotalLiquidacaoParcelado}</b></span></span>
                                </div>
                            </div>
                            :
                            <></>
                        }

                    </>
                </>
            }
        </>
    )
})

export { CadastroLiquidacao, CadastroLiquidacaoParcelado }
