import { post, excluir, get } from './AcessoApi'
import { mostrarToast } from '../components/Toasts'
import { getUsuario } from './Configuracoes'
import moment from 'moment'
import LZString from 'lz-string';

const ordenarArrayObj = (array, atributo) => array.sort((a, b) => a[atributo] > b[atributo] ? 1 : b[atributo] > a[atributo] ? -1 : 0)

const downloadArquivoS3 = (s3Key) => {
    Promise.resolve(post('/sistema/aws-s3-obter-arquivo', {
        fileKey: s3Key
    })).then(val => {
        window.open(val.data.returnData, "_blank")
    }).catch((e) => mostrarToast('erro', e))
}

const auditar = (acao, modulo, controle) => {
    const objAuditar = {
        usuario_id: getUsuario().id,
        modulo_id: modulo,
        acao_id: acao,
        controle: controle || null
    }

    post('/controleAcesso/auditoria/salvar', objAuditar)
}

const removeAcentos = (str) => {
    let comAcento = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ'
    let semAcento = 'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr'
    let novaString = ''

    for (let i = 0; i < str.length; i++) {
        let troca = false;

        for (let a = 0; a < comAcento.length; a++)
            if (str.substr(i, 1) === comAcento.substr(a, 1)) {
                novaString += semAcento.substr(a, 1);
                troca = true;
                break;
            }

        if (troca === false)
            novaString += str.substr(i, 1);
    }

    return novaString;
}

const removerCaracteresEspeciais = (str) => {
    return str ? str.replace(/[^\w\s]/gi, '') : str;
}

const emptyObject = obj => {
    for (var key in obj)
        if (obj.hasOwnProperty(key))
            return false;

    return true;
}

const calcularData = (data_base, valor, modo_visualizacao, callback) => {
    let novosFiltros = { modo_visualizacao: modo_visualizacao }
    let tipoContagem = '';
    let today = moment(data_base, 'DD/MM/YYYY');
    switch (modo_visualizacao) {
        case 1:
            today = valor === 0 ? moment() : today;
            tipoContagem = 'days'

            if (valor.toString().includes('/')) {
                novosFiltros = {
                    data_inicial: valor,
                    data_final: valor, ...novosFiltros
                }
            } else {
                today = valor === 0 ? moment() : today;
                novosFiltros = {
                    data_inicial: today.clone().add(valor, tipoContagem).format('DD/MM/YYYY'),
                    data_final: today.clone().add(valor, tipoContagem).format('DD/MM/YYYY'), ...novosFiltros
                }
            }
            break;
        case 2:
            let from_date
            let to_date
            tipoContagem = 'weeks'

            if (valor.toString().includes('/')) {
                today = moment(valor, 'DD/MM/YYYY');
                from_date = today.startOf('week').toDate()
                to_date = today.endOf('week').toDate()
            }
            else {
                from_date = today.startOf('week').toDate()
                to_date = today.endOf('week').toDate()
            }

            novosFiltros = {
                data_inicial: moment(from_date).add(valor, tipoContagem).format('DD/MM/YYYY'),
                data_final: moment(to_date).add(valor, tipoContagem).format('DD/MM/YYYY'), ...novosFiltros
            }
            break;
        case 3:
            tipoContagem = 'months'
            let from_date_month
            let to_date_month

            if (valor.toString().includes('/')) {
                today = moment(valor, 'DD/MM/YYYY');
                from_date_month = today.startOf('month').toDate()
                to_date_month = today.endOf('month').toDate()
            }
            else {
                from_date_month = today.startOf('month').toDate()
                to_date_month = today.endOf('month').toDate()
            }

            novosFiltros = {
                data_inicial: moment(from_date_month).add(valor, tipoContagem).format('DD/MM/YYYY'),
                data_final: moment(to_date_month).add(valor, tipoContagem).endOf('month').format('DD/MM/YYYY'), ...novosFiltros
            }

            break;
        default:
            break;
    }
    return novosFiltros;
}

const formatMoeda = value => {
    return ((value || 0) / 100 * 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })
}

const formatTwoPlace = value => value ? (value / 100 * 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0'

const capitalize = string => string.toLowerCase().replace(/(^|\s)\S/g, l => l.toUpperCase())

const reduzTexto = (text, length) => {
    if (text == null) {
        return "";
    }
    if (text.length <= length) {
        return text;
    }
    text = text.substring(0, length);
    let last = text.lastIndexOf(" ");
    text = text.substring(0, last);
    return text + "...";
}

const obterGuiasManutencao = (propsFormik, setGuias, particular) => {
    let filtragem = { particular: particular, ...propsFormik.values.filtros }

    if (!moment(filtragem.data_inicial_procedimento, 'dd/mm/yyyy').isValid)
        propsFormik.setFieldError("filtros.data_inicial_procedimento", 'Data Inválida')
    if (!moment(filtragem.data_final_procedimento, 'dd/mm/yyyy').isValid)
        propsFormik.setFieldError("filtros.data_final_procedimento", 'Data Inválida')
    if (!moment(filtragem.data_inicial_registro, 'dd/mm/yyyy').isValid)
        propsFormik.setFieldError("filtros.data_inicial_registro", 'Data Inválida')
    if (!moment(filtragem.data_final_registro, 'dd/mm/yyyy').isValid)
        propsFormik.setFieldError("filtros.data_final_registro", 'Data Inválida')

    propsFormik.setFieldTouched("filtros.data_inicial_registro", true)
    propsFormik.setFieldTouched("filtros.data_final_registro", true)
    propsFormik.setFieldTouched("filtros.data_inicial_procedimento", true)
    propsFormik.setFieldTouched("filtros.data_final_procedimento", true)

    if (propsFormik.errors && propsFormik.errors.filtros)
        return;

    if (particular === true) {
        if (filtragem.data_inicial_procedimento && filtragem.data_final_procedimento || filtragem.data_inicial_registro && filtragem.data_final_registro || filtragem.estabelecimento_saude || filtragem.movimento_financeiro) {
            Promise.resolve(get('/faturamento/obterGuiasManutencao', filtragem)).then(val => {
                const guiasTela = [...propsFormik.values['guias']]
                setGuias(val.filter(x => !guiasTela.find(y => y.id === x.id)))
            })
        } else
            mostrarToast('erro', 'Selecione a Data Inicial e Final do Procedimento ou Registro da Guia!')
    } else {
        if (filtragem.data_inicial_procedimento && filtragem.data_final_procedimento || filtragem.data_inicial_registro && filtragem.data_final_registro || filtragem.lote) {
            Promise.resolve(get('/faturamento/obterGuiasManutencao', filtragem)).then(val => {
                const guiasTela = [...propsFormik.values['guias']]
                setGuias(val.filter(x => !guiasTela.find(y => y.id === x.id)))
            })
        } else
            mostrarToast('erro', 'Selecione a Data Inicial e Final do Procedimento ou Registro da Guia!')
    }
}

const obterGuiasManutencaoEspecificas = async (values, funcaoAoObter, particular) => {
    await Promise.resolve(get('/faturamento/obterGuiasManutencao', { particular: particular, ...values })).then(val => {
        funcaoAoObter(val);
    })
}

const obterGuiasConferencia = async (values, funcaoAoObter, particular) => {

    await Promise.resolve(get('/faturamento/obterGuiasConferencia', { ...values })).then(val => {
        funcaoAoObter(val);
    })
}
const obterGuiasConferenciaMapaHoras = async (values, funcaoAoObter, particular) => {

    await Promise.resolve(get('/controleproducao/obterGuiasConferenciaMapaHoras', { ...values })).then(val => {
        funcaoAoObter(val);
    })
}

const incluirGuiaBdManutencao = (propsFormik, guia, guiasState, setGuiasState, setTotalSelecionados) => {
    const guiasSelecionadas = [...guiasState]
    let filtragem = { ...propsFormik.values.filtros }


    const oldGuiasSelecionadas = [...propsFormik.values['guias']]
    const index = oldGuiasSelecionadas.findIndex(x => x.id === guia.id)

    oldGuiasSelecionadas.splice(index, 1)
    setTotalSelecionados(oldGuiasSelecionadas.length)

    propsFormik.setFieldValue(`guias`, propsFormik.values['guias'].filter(x => x.id !== guia.id))

    const guiaFiltrada = filtragemTelaManutencao(filtragem, [guia])

    setGuiasState([...guiasSelecionadas.concat(guiaFiltrada)].sort((a, b) => b.id - a.id))
}

const filtragemTelaManutencao = (filtragem, guias) => {
    const guiaRetorno = []

    for (let i = 0; i < guias.length; i++) {
        if (filtragem.data_inicial_procedimento && !(moment(guias[i].data_procedimento, 'dd/mm/yyyy') >= moment(filtragem.data_inicial_procedimento, 'dd/mm/yyyy')))
            continue
        if (filtragem.data_final_procedimento && !(moment(guias[i].data_procedimento, 'dd/mm/yyyy') <= moment(filtragem.data_final_procedimento, 'dd/mm/yyyy')))
            continue
        if (filtragem.data_inicial_registro && !(moment(guias[i].data_insercao, 'dd/mm/yyyy') >= moment(filtragem.data_inicial_registro, 'dd/mm/yyyy')))
            continue
        if (filtragem.data_final_registro && !(moment(guias[i].data_insercao, 'dd/mm/yyyy') <= moment(filtragem.data_final_registro, 'dd/mm/yyyy')))
            continue
        if (filtragem.estabelecimento_saude && !(filtragem.estabelecimento_saude.find(x => x.value === guias[i].estabelecimento_saude_id)))
            continue
        if (filtragem.lote && !(filtragem.lote.find(x => x.value === guias[i].guia_lote_id)))
            continue
        if (filtragem.operadora_saude && !(filtragem.operadora_saude.find(x => x.value === guias[i].operadora_saude_id)))
            continue
        if (filtragem.socio_cooperativa && !(filtragem.socio_cooperativa.find(x => x.value === guias[i].socio_cooperativa_id)))
            continue
        if (filtragem.socio_equipe && !(filtragem.socio_equipe.find(x => x.value === guias[i].socio_equipe_id)))
            continue
        if (filtragem.status && !(filtragem.status.find(x => x.value === guias[i].status_id)))
            continue
        if (filtragem.tipo_executante && !(filtragem.tipo_executante.find(x => x.value === guias[i].tipo_executante_id)))
            continue

        guiaRetorno.push(guias[i])
    }

    return guiaRetorno.sort((a, b) => b.id - a.id)
}

const incluirGuiaTelaManutencao = (propsFormik, guia, guiasState, setGuiasState, setTotalSelecionados) => {
    const guiasSelecionadas = [...propsFormik.values['guias']]
    const cloneGuias = [...guiasState]
    const index = cloneGuias.findIndex(x => x.id === guia.id)
    cloneGuias.splice(index, 1)

    guiasSelecionadas.push(guia)
    setTotalSelecionados(guiasSelecionadas?.length)
    propsFormik.values.guias = guiasSelecionadas.sort((a, b) => b.id - a.id)
    setGuiasState(cloneGuias)
}

const forceDownload = (url, fileName) => {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const blobURL = URL.createObjectURL(blob);
            const anchorElement = document.createElement('a');
            anchorElement.href = blobURL;
            anchorElement.download = fileName;
            anchorElement.style.display = 'none';
            document.body.appendChild(anchorElement);
            anchorElement.click();
            document.body.removeChild(anchorElement);
            URL.revokeObjectURL(blobURL);
        });
}

const obterHorarioTrabalho = async () => {
    let retorno = {}

    const url = 'https://plasma-utils.s3.us-east-2.amazonaws.com/workDays.json';
    await Promise.resolve(fetch(url)).then(response => response.json())
        .then(data => {
            retorno = data;
        })
        .catch(error => {
            retorno = {}
        });

    return retorno;
}

const isUrgency = async (day, start_time, end_time) => {
    //In this method, I check if the urgency time is greater or smaller than half of the total procedure time.
    const weekWork = await obterHorarioTrabalho();
    const day_num = moment(day, "DD/MM/yyyy").day()
    let procDuration = 0
    let urgencyStart = 0
    let urgencyEnd = 0

    const startProc = convertTimeToFloat(start_time);
    const endProc = convertTimeToFloat(end_time);
    const startWork = convertTimeToFloat(weekWork[day_num].start_time);
    const endWork = convertTimeToFloat(weekWork[day_num].end_time);

    if (startProc > endProc) {
        //calc procDuration based on 24 hours of the day
        procDuration = endProc + (convertTimeToFloat('24:00') - startProc)
        const next_day_num = moment(day, 'DD/MM/yyyy').add(1, 'days').day()
        const nextDay = weekWork[next_day_num];

        if (nextDay.day_off && (convertTimeToFloat('24:00') - startProc) < procDuration / 2)
            return true;

        const nextStartWork = convertTimeToFloat(nextDay.start_time);
        const nextEndWork = convertTimeToFloat(nextDay.end_time);

        const routineStartDiff = (startProc > startWork ? startProc : startWork) - endWork
        const routineEndDiff = nextStartWork - (endProc > nextEndWork ? nextEndWork : endProc)

        //routine diff to calculate time on routine
        const urgencyDayOne = (convertTimeToFloat('23:59') - startProc) + (routineStartDiff < 0 ? routineStartDiff : 0)
        const urgencyDayTwo = endProc + (routineEndDiff < 0 ? routineEndDiff : 0)

        return (urgencyDayOne + urgencyDayTwo) > (procDuration / 2)
    } else {
        if (weekWork[day_num].day_off)
            return true;

        procDuration = endProc - startProc
        urgencyStart = startWork - startProc
        urgencyEnd = endProc - endWork
    }


    const urgencyTime = (urgencyStart > 0 ? urgencyStart : 0) + (urgencyEnd > 0 ? urgencyEnd : 0)

    return urgencyTime > (procDuration / 2)
}

const obterFeriados = (func) => {
    get("/administracao/obterFeriados", {}, true)
        .then((dados) => {
            func(dados)
        })
}

const convertTimeToFloat = (time) => {
    //parse time to float 18:30 = 18,50
    var parts = time.split(":");
    var hours = parseInt(parts[0]);
    var minutes = parseInt(parts[1]);

    var decimal = hours + minutes / 60;
    return parseFloat(decimal.toFixed(2));
}

const isUrgencyOnHoliday = (holidays, day, start_time, end_time) => {
    const startTime = convertTimeToFloat(start_time)
    const endTime = convertTimeToFloat(end_time)
    if (startTime > endTime) {
        const nextDay = moment(day, 'DD/MM/yyyy').add(1, 'days').format("DD/MM/yyyy")
        const dayOneTime = convertTimeToFloat('23:59') - startTime
        const dayTwoTime = endTime - convertTimeToFloat('00:00')
        if ((holidays.findIndex(x => x.feriado_br === day) > -1 && dayOneTime > dayTwoTime) || (holidays.findIndex(x => x.feriado_br === nextDay) > -1 && dayOneTime < dayTwoTime)) {
            return true
        }
    } else {
        return holidays.findIndex(x => x.feriado_br === day) > -1
    }

    return false
}

const obterTextoPorUrl = async url => {

    const response = await fetch(url);
    const text = await response.text();
    return text;
    // return Promise.resolve(fetch(url)
    //     .then(response => response.blob())
    // );
}

const ordenarSiglaModoPagamento = (array) => {
    let siglas = []

    array.forEach(x => {
        if (x.modo_liquidacao && x.modo_liquidacao.sigla) {
            siglas.push(x.modo_liquidacao.sigla)
        }
    });

    // siglas.sort()

    let siglasEmOrdem = siglas.join(',')

    return siglasEmOrdem
}

const debounce = (fn, delay) => {
    let timeout

    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            fn(...args)
        }, delay)
    };
}

function removeContentBetweenDivs(htmlString, startClassName, endClassName) {
    // Crie uma expressão regular para encontrar o conteúdo entre as divs
    var regex = new RegExp('<div[^>]*\\b' + startClassName + '\\b[^>]*>.*?</div>(.*?)<div[^>]*\\b' + endClassName + '\\b[^>]*>', 'gi');

    var modifiedHtmlString = htmlString.replace(regex, '');

    return modifiedHtmlString;
}

const comprimirString = (data) => {
    const compressedData = LZString.compressToBase64(data);
    return compressedData;
};

function formatarErroNota(value) {
    if (Array.isArray(value)) {
        return capitalize(value.join(', '));
    } else {
        return capitalize(value.toString());
    }
}

const stringSizeMB = (str) => {
    const byteLength = Buffer.from(str).length;
    const megabytes = byteLength / (1024 * 1024);
    return megabytes.toFixed(2);
}

const limitarCaracteres = (str, limite) => {
    if (str && str?.length <= limite) {
        return str;
    } else if (str && str.length) {
        return str.slice(0, limite) + "...";
    }
    return str;
}

function getDistinctPropertyValues(array, property) {
    const values = array.map(item => item[property]);
    return [...new Set(values)];
}

export {
    excluir, auditar, removeAcentos, ordenarArrayObj, emptyObject, downloadArquivoS3, calcularData, formatMoeda, formatTwoPlace, capitalize, reduzTexto,
    incluirGuiaTelaManutencao, filtragemTelaManutencao, incluirGuiaBdManutencao, obterGuiasManutencao, obterGuiasManutencaoEspecificas, obterGuiasConferencia, forceDownload, obterFeriados,
    isUrgency, isUrgencyOnHoliday, obterTextoPorUrl, ordenarSiglaModoPagamento, removerCaracteresEspeciais, obterGuiasConferenciaMapaHoras, debounce, removeContentBetweenDivs, comprimirString, formatarErroNota,
    stringSizeMB, limitarCaracteres, getDistinctPropertyValues
}