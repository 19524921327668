import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, useFormikContext, FieldArray } from 'formik'
import Yup, { validaData, validaHora } from '../../../../util/Validacoes'
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from 'react-router-dom'
import {
  get,
  salvar,
  putComAcao,
  validar,
  acoesAgenda,
  validarComPost,
} from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import {
  InputPadrao,
  InputDatePicker,
  InputSwitch,
  InputTimePicker,
  TextArea,
  InputDatePickerMultiplo,
} from '../../../../components/Inputs'
import {
  InputSocioAtivosComCores,
  InputSocioAtivos,
  InputEstabelecimentoSaude,
  InputEmpresa,
  InputSocioEmpresa,
  InputTodosProcedimentos,
  InputStatusAgendamento,
  InputRecorrencio,
  InputIntervaloHorario,
  InputSocio,
  InputCirurgiaoCombo,
} from '../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens, useWindowSize } from '../../../../util/Utilitarios'
import { dataMesmaSemanaMes } from '../../../../util/Validacoes'
import '../../../../assets/css/icones/iconsmind/style.css'
import CadastroProcedimentos, {
  CadastroRapidoProcedimento,
  CadastroRapidoProcedimentoV2,
} from './CadastroProcedimentos'
import CadastroPacientes from './CadastroPacientes'
import {
  ModalCadastro,
  ModalDuasOpcoes,
  ModalEscolha,
} from '../../../../components/Modais'
import { ordenarMultiplo } from '../../../../components/Ordenamentos'
import { BtnAcao, Excluir } from '../../../../components/Acoes'
import { LoadRelatorio } from '../../../../components/Loading'
import { getUsuario } from '../../../../util/Configuracoes'
import Atalho from '../../../../util/Atalho'
import { ACOES } from '../../../../util/Enums'
// import FormikErrorFocus from 'formik-error-focus'
import moment from 'moment'
import { reduzTexto } from '../../../../util/FuncoesComuns'
import { upperCase } from 'lodash'
import { InputNumero } from '../../../../components/InputsV2'

const defaultShouldTriggerErrors = (errors, nextErrors) =>
  !deepEqual(errors, nextErrors)

var deepEqual = function (x, y) {
  if (x === y) {
    return true
  } else if (
    typeof x == 'object' &&
    x != null &&
    typeof y == 'object' &&
    y != null
  ) {
    if (Object.keys(x).length != Object.keys(y).length) return false

    for (var prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) return false
      } else return false
    }

    return true
  } else return false
}

const CadastroAgendaEscalador = (props) => {
  let { id, idDuplicar } = useParams()
  const user = getUsuario()

  const [salvarENovo, setSalvarENovo] = useState(false)
  const [salvando, setSalvando] = useState(false)
  const [valoresIniciais, setValoresIniciais] = useState({})
  const [modalProcedimentos, setModalProcedimentos] = useState(false)
  const [arrayProcedimentos, setArrayProcedimentos] = useState([])
  const [modalPacientes, setModalPacientes] = useState(false)
  const [arrayPacientes, setArrayPacientes] = useState([])
  const [objPaciente, setObjPaciente] = useState({})
  const [formikProcedimentos, setFormikProcedimentos] = useState({})
  const formik = useFormikContext()
  const [refEstabelecimento, setRefEstabelecimento] = useState({})
  const [showModalValidacao, setShowModalValidacao] = useState(false)
  const [showModalInterOcorrencia, setShowModalInterOcorrencia] =
    useState(false)
  const [mensagemModal, setMensagemModal] = useState('')
  const [msgValidacao, setMsgValidacao] = useState([])
  const [tituloValidacao, setTituloValidacao] = useState('')
  const [trocaSocio, setTrocaSocio] = useState(false)

  const [validacao] = useState(
    Yup.object({
      estabelecimento_saude: Yup.string().nullable().required(),
      data_inicial: Yup.string()
        .nullable()
        .required()
        .test('Data Inicial', 'Data inválida!', function (value) {
          return validaData(this.parent.data_inicial)
        }),
      hora_inicial: Yup.string()
        .required()
        .test('Hora Final', 'Hora inválida!', function (value) {
          return validaHora(this.parent.hora_inicial)
        }),
      hora_final: Yup.string()
        .nullable()
        .test('Hora Final', 'Hora inválida!', function (value) {
          return validaHora(this.parent.hora_final)
        }),
      observacao: Yup.string().nullable().max(500),
    }),
  )

  const validarPermissao = (linha, acao, errorAction) => {
    get(
      '/administracao/permissaoAlterarAposData',
      {
        usuario: user.id,
        acao,
        usuario_tipo: user.tipo,
        id: linha.id,
        modulo: 45,
      },
      true,
    )
      .then((result) => {
        return !result || result.length > 0 ? () => { } : errorAction()
      })
      .catch((err) => errorAction())
  }

  const manter = [
    'estabelecimento_saude',
    'cirurgiao',
    'data_inicial',
    'executante',
    'empresa',
  ]

  useEffect(() => {
    // document.getElementById("myAnchor").focus()
    if (refEstabelecimento.current) {
      refEstabelecimento.current.focus()
    }
  }, [refEstabelecimento])

  const funcaoAposSim = (formik) => {
    Submit(formik.values, formik.resetForm)
  }

  const validacaoSubmit = async (values, propsFormik) => {
    setSalvando(true)
    const msg = []

    await validar(
      `/administracao/socio-ocorrencia-verificacao`,
      { modulo: 38, ...values },
      null,
      (e) => {
        setTituloValidacao('Há interposição/ocorrência para os sócios:')
        msg.push(
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: e.response.data.alertas[0].replace(/"/g, ''),
              }}
            />
          </>,
        )
      },
    )

    await validar(
      '/administracao/agendaReservaVerificacao',
      values,
      null,
      (e) => {
        setTituloValidacao('Há interposição/ocorrência para os sócios:')
        msg.push(
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: e.response.data.alertas[0].replace(/"/g, ''),
              }}
            />
          </>,
        )
      },
    )

    await validar(
      '/administracao/agendaVerificacaoClone',
      values,
      null,
      (e) => {
        msg.push(
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: e.response.data.alertas[0].replace(/"/g, ''),
              }}
            />
          </>,
        )
      },
    )

    if (msg.length) {
      msg.push('Deseja continuar?')
      setMsgValidacao(
        msg.map((x) => (
          <>
            {x}
            <br />
          </>
        )),
      )
      setShowModalInterOcorrencia(true)
      setSalvando(false)
    } else Submit(values, propsFormik)
  }

  const Submit = (values, propsFormik) => {
    setShowModalInterOcorrencia(false)
    setSalvando(true)
    salvar(
      {
        pacientes: arrayPacientes,
        procedimentos: arrayProcedimentos,
        ...values,
      },
      '/administracao/agendareserva',
      () => {
        mostrarToast('sucesso', mensagens.sucesso)
        if (props.modal) {
          if (salvarENovo) {
            propsFormik.resetForm({})
            manter.forEach((x) => propsFormik.setFieldValue(x, values[x]))
            setArrayProcedimentos([])
            setArrayPacientes([])
            setSalvando(false)
            setSalvarENovo(false)
          } else {
            props.setShow && props.setShow(false)
            props.funcaoAposFechar()
          }
        } else {
          if (salvarENovo) {
            propsFormik.resetForm({})
            manter.forEach((x) => propsFormik.setFieldValue(x, values[x]))
            setArrayProcedimentos([])
            setArrayPacientes([])
            setSalvando(false)
            setSalvarENovo(false)
          } else {
            props.history.push('/administracao/agendareserva/consulta/')
          }
        }
      },
      () => {
        setSalvando(false)
      },
    )
  }

  useEffect(() => {
    if (id) {
      const url = `/administracao/agendareserva/${id}`
      Promise.resolve(get(url)).then((val) => {
        validarPermissao(val, 3, (_) =>
          props.history.push('/administracao/agendareserva/consulta'),
        )
        setArrayProcedimentos(
          val.procedimentos.map((x) => {
            delete x.paciente_nome
            return { verificador: Math.random(), ...x }
          }),
        )
        setArrayPacientes(
          val.pacientes.map((x) => {
            delete x.beneficiario_nome
            return { verificador: Math.random(), ...x }
          }),
        )
        delete val.procedimentos
        delete val.pacientes
        delete val.status
        delete val.agenda_tipo
        setValoresIniciais(val)
        setValuePlantao(val.plantao)
      })
    } else if (idDuplicar) {
      const url = `/administracao/agendareserva/${idDuplicar}`
      Promise.resolve(get(url)).then((val) => {
        setValoresIniciais({
          executante: val.executante,
          empresa: val.empresa,
          estabelecimento_saude: val.estabelecimento_saude,
          cirurgiao: val.cirurgiao,
          data_inicial: val.data_inicial,
          hora_inicial: val.hora_inicial,
          hora_final: val.hora_final,
          plantao: val.plantao,
        })
      })
    } else {
      setValoresIniciais({
        executante: '',
        equipe_repasse: '',
        empresa: '',
        estabelecimento_saude: '',
        cirurgiao: '',
        data_inicial: '',
        hora_inicial: '',
        hora_final: '',
        observacao: '',
        plantao: false,
      })
    }
  }, [id])

  const settarValorPlantao = (propsFormik) => {
    return !propsFormik.values.plantao
  }

  const onChangeSocio = (propsFormik, options) => {
    const executanteIniciais = valoresIniciais.executante

    if (
      options &&
      executanteIniciais &&
      executanteIniciais.filter(
        (item) => options.findIndex((x) => x.value === item.value) < 0,
      ).length > 0
    ) {
      setTrocaSocio(true)
    } else {
      setTrocaSocio(false)
    }
  }
  const [valuePlantao, setValuePlantao] = useState(false)
  const [valueSequencial, setValueSequencial] = useState(false)

  return (
    <>
      <Formik
        initialValues={valoresIniciais}
        validationSchema={validacao}
        enableReinitialize={true}
        onSubmit={(values, propsFormik) => validacaoSubmit(values, propsFormik)}
      >
        {(propsFormik) => (
          <Form>
            <div className="card mb-20">
              <div className="card-body">
                <div className="row">
                  <InputPadrao
                    label="Id"
                    name="id"
                    type="hidden"
                    placeholder="Id"
                    obrigatorio={true}
                  />

                  <InputEstabelecimentoSaude
                    setReference={setRefEstabelecimento}
                    label="Estabelecimento Saúde"
                    placeholder="Estabelecimento Saúde"
                    name="estabelecimento_saude"
                    propsFormik={propsFormik}
                    obrigatorio={true}
                    tamanho={!props.modal && 'col-lg-4 col-md-12'}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                    focarAposSubmit={true}
                    salvarENovo={salvarENovo}
                    ref={refEstabelecimento}
                    autoFocus
                  />

                  <InputDatePicker
                    tamanho={!props.modal && 'col-lg-4 col-md-7'}
                    label="Data Inicial"
                    name="data_inicial"
                    type="text"
                    obrigatorio="true"
                    setarValor={(value) => {
                      propsFormik.setFieldValue('data_inicial', value)
                    }}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                    mostrarFeriado={true}
                  />

                  <InputTimePicker
                    label="Hora Inicial"
                    mascara="11:11"
                    name="hora_inicial"
                    type="text"
                    placeholder="Hora Inicial"
                    tamanho={!props.modal && 'col-lg-2 col-md-5'}
                    obrigatorio={true}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                    setarValor={(value) => {
                      propsFormik.setFieldValue('hora_inicial', value)
                    }}
                  />
                  <InputTimePicker
                    label="Hora Final"
                    mascara="11:11"
                    name="hora_final"
                    type="text"
                    placeholder="Hora Final"
                    tamanho={!props.modal && 'col-lg-2 col-md-5'}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                    setarValor={(value) => {
                      propsFormik.setFieldValue('hora_final', value)
                    }}
                  />
                  {user.tipo === 4 && (
                    <InputEmpresa
                      label="Empresa"
                      name="empresa"
                      placeholder="Empresa"
                      propsFormik={propsFormik}
                      tamanho={!props.modal && 'col-lg-4 col-md-12'}
                      classeTooltip={props.modal && 'tooltip-input-modal'}
                      salvarENovo={salvarENovo}
                      onChange={(option) =>
                        propsFormik.setFieldValue('empresa', option)
                      }
                    />
                  )}

                  {user.tipo !== 4 && (
                    <InputSocioAtivosComCores
                      label="Sócio"
                      data_inicial={
                        propsFormik.values &&
                          propsFormik.values.data_inicial &&
                          propsFormik.values.hora_inicial &&
                          propsFormik.values.hora_inicial.indexOf('_') == -1
                          ? propsFormik.values.data_inicial +
                          ' ' +
                          propsFormik.values.hora_inicial
                          : ''
                      }
                      agenda_id={propsFormik.values && propsFormik.values.id}
                      name="executante"
                      propsFormik={propsFormik}
                      tamanho={'col-lg-6 col-md-6'}
                      classeTooltip={props.modal && 'tooltip-input-modal'}
                      salvarENovo={salvarENovo}
                      multiplo
                      onChangeSocio={onChangeSocio}
                    />
                  )}
                  <InputSocioAtivos
                    label="Sócio Preferencial"
                    name="preferencia"
                    propsFormik={propsFormik}
                    tamanho={!props.modal && 'col-lg-4 col-md-12'}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                    salvarENovo={salvarENovo}
                    multiplo
                  />
                  {user.tipo !== 4 && (
                    <InputSocioAtivos
                      label="Equipe/Repasse"
                      name="equipe_repasse"
                      propsFormik={propsFormik}
                      tamanho={!props.modal && 'col-lg-4 col-md-12'}
                      classeTooltip={props.modal && 'tooltip-input-modal'}
                      salvarENovo={salvarENovo}
                      multiplo
                    />
                  )}
                  <InputCirurgiaoCombo
                    tamanho="col-xl-5 col-lg-6"
                    label="Cirurgião"
                    name="cirurgiao"
                    multiplo={false}
                    placeholder="Cirurgião"
                    propsFormik={propsFormik}
                    botaoVisivel={true}
                    modal
                  />

                  <InputSwitch
                    label="Plantão?"
                    propsFormik={propsFormik}
                    quebrarLinha
                    classeDiv="form-group ajusta-switch "
                    name="plantao"
                    size="md"
                    tamanho={!props.modal && 'col-lg-1'}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                  />

                  <InputSwitch
                    label="Sequencial?"
                    propsFormik={propsFormik}
                    quebrarLinha
                    classeDiv="form-group ajusta-switch"
                    name="sequencial"
                    size="md"
                    tamanho={!props.modal && 'col-lg-1'}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                  />

                  <TextArea
                    label="Observação"
                    name="observacao"
                    type="text"
                    placeholder="Observação"
                    tamanho={!props.modal && 'col-lg-6 col-md-6'}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                  />
                  {trocaSocio ? (
                    <TextArea
                      label="Justificativa Troca de Sócio"
                      obrigatorio
                      name="justificativa_troca"
                      type="text"
                      placeholder="Justificativa Troca de Sócio"
                      tamanho={!props.modal && 'col-lg-6 col-md-6'}
                      classeTooltip={props.modal && 'tooltip-input-modal'}
                    />
                  ) : (
                    <></>
                  )}
                  {props.duplicar ||
                    id ||
                    !dataMesmaSemanaMes(
                      propsFormik.values.data_inicial,
                      propsFormik.values.data_final,
                    ) ? (
                    <></>
                  ) : (
                    <InputSwitch
                      propsFormik={propsFormik}
                      label="Repetir Semanalmente no Mês?"
                      quebrarLinha
                      classeDiv="form-group "
                      name="semanalmente"
                      size="md"
                      tamanho={!props.modal && 'col-lg-6'}
                      classeTooltip={props.modal && 'tooltip-input-modal'}
                    />
                  )}
                  <Atalho
                    formik={propsFormik}
                    salvando={salvando}
                    setSalvareNovo={setSalvarENovo}
                    salvarENovo={true}
                  />
                </div>
              </div>
            </div>
            <CadastroProcedimentos
              arrayDados={arrayProcedimentos}
              setArrayDados={setArrayProcedimentos}
              abrirModal={modalProcedimentos}
              setAbrirModal={setModalProcedimentos}
              pacientes={arrayPacientes.map((item) => ({
                value: item.item,
                label: item.beneficiario.label,
              }))}
              chamarModalPaciente={() => setModalPacientes(true)}
              pacienteInserido={objPaciente}
              formikProcedimentos={(formik) => setFormikProcedimentos(formik)}
            />

            <CadastroPacientes
              arrayDados={arrayPacientes}
              setArrayDados={setArrayPacientes}
              abrirModal={modalPacientes}
              setAbrirModal={setModalPacientes}
              funcaoAposSalvar={(obj) => {
                setObjPaciente(obj)
                setModalPacientes(false)
              }}
              formikProcedimentos={formikProcedimentos}
              procedimentos={arrayProcedimentos}
              setProcedimentos={setArrayProcedimentos}
            />

            <div className="separator mt-40 mb-5"></div>
            <div className="direita container-botoes mb-3 group-salvar-novo">
              <BotaoPadrao
                texto="Salvar"
                disabled={salvando}
                click={(_) => setSalvarENovo(false)}
                idTooltip={'salvar'}
              />
              {!id && (
                <BotaoPadrao
                  texto="Salvar e Novo"
                  disabled={salvando}
                  click={(_) => setSalvarENovo(true)}
                  idTooltip={'salvarENovo'}
                />
              )}
            </div>
            {/* <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => setSalvando(false)}
                        esconderFechar mensagem={mensagemModal}
                        textoOpcao1={'Cancelar'}
                        classeOpcao1={'btn-sm btn-primary-modal simples'}
                        textoOpcao2={'Continuar'} /> */}
            <ModalDuasOpcoes
              showModal={showModalInterOcorrencia}
              setShowModal={setShowModalInterOcorrencia}
              funcaoOpcao2={() => Submit(propsFormik.values, propsFormik)}
              funcaoOpcao1={() => {
                setSalvando(false)
                setShowModalInterOcorrencia(false)
              }}
              esconderFechar
              mensagem={
                <>
                  {tituloValidacao ? (
                    <>
                      {tituloValidacao} <br />
                      <br />
                    </>
                  ) : (
                    <></>
                  )}
                  {msgValidacao}
                  <br />
                </>
              }
              textoOpcao1={'Fechar'}
              classeOpcao1={'btn-sm btn-primary-modal simples'}
              textoOpcao2={'Continuar'}
            />
            {/* <FormikErrorFocus
                        // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
                        offset={100}
                        align={'top'}
                        focusDelay={200}
                        ease={'linear'}
                        duration={500}
                    /> */}
          </Form>
        )}
      </Formik>
    </>
  )
}

const CadastroSocioAgenda = (props) => {
  const [id, setId] = useState()

  const [salvando, setSalvando] = useState(false)
  const [item, setItem] = useState({})
  const [carregando, setCarregando] = useState(true)
  const refAtualizacao = useRef(null)
  const [valoresIniciais, setValoresIniciais] = useState({})
  const [showModalValidacao, setShowModalValidacao] = useState(false)
  const [mensagemModal, setMensagemModal] = useState('')
  const [trocaSocio, setTrocaSocio] = useState(false)

  const [validacao, setValidacao] = useState({})

  const Submit = (values, propsFormik) => {
    setShowModalInterOcorrencia(false)
    setSalvando(true)
    if (props.modulo_id) {
      putComAcao(
        ACOES.TROCAR_SOCIO,
        props.modulo_id,
        values,
        '/administracao/agendareservasociojustificativarotina',
        () => {
          mostrarToast('sucesso', mensagens.sucesso)
          setSalvando(false)
          props.funcaoAposSalvar()
        },
        (erro) => {
          mostrarToast('erro', mensagens.erro)
          setSalvando(false)
        },
      )
    } else {
      salvar(
        values,
        '/administracao/agendareservasociojustificativarotina',
        () => {
          mostrarToast('sucesso', mensagens.sucesso)
          setSalvando(false)
          props.funcaoAposSalvar()
        },
        (erro) => {
          mostrarToast('erro', mensagens.erro)
          setSalvando(false)
        },
      )
    }
  }

  const validacaoInterposicaoOcorrencia = (values, propsFormik) => {
    const url = `/administracao/agendareserva/${values.id}`
    let ret = {}
    Promise.resolve(get(url)).then((val) => {
      ret = val
      ret.modulo = 38
      ret.executante = values.executante
      validar(
        `/administracao/socio-ocorrencia-verificacao`,
        ret,
        () => ValidarSubmit(values, propsFormik, 2),
        (e) => {
          const alertas = e.response.data.alertas
          setMsgValidacao(
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: alertas[0].replace(/"/g, ''),
                }}
              />
            </>,
          )
          setShowModalInterOcorrencia(true)
          setSalvando(false)
        },
      )
    })
  }

  const [showModalInterOcorrencia, setShowModalInterOcorrencia] =
    useState(false)
  const [msgValidacao, setMsgValidacao] = useState([])

  const ValidarSubmit = (values, propsFormik, nValidacao) => {
    setSalvando(true)

    switch (nValidacao) {
      case 1:
        validacaoInterposicaoOcorrencia(values, propsFormik)
        break
      case 2:
        if (props.rotaValidacao)
          validar(
            props.rotaValidacao,
            values,
            () => Submit(values, propsFormik),
            (e) => {
              setShowModalValidacao(true)
              setMensagemModal(e.response.data.alertas[0])
              setSalvando(false)
            },
          )
        else
          validar(
            '/administracao/verificaAdicionarSocio',
            values,
            () => Submit(values, propsFormik),
            (e) => {
              setShowModalValidacao(true)
              setMensagemModal(e.response.data.alertas[0])
              setSalvando(false)
            },
          )
        break
      default:
        validacaoInterposicaoOcorrencia(values, propsFormik)
        break
    }
  }

  const [agenda, setAgenda] = useState(null)
  const obterAgenda = () => {
    if (!(props.item && props.item.id)) {
      return
    }
    setCarregando(true)
    Promise.resolve(
      get(`/administracao/obterObjParaTrocaAgenda`, { id: props.item.id }),
    ).then((val) => {
      setCarregando(false)
      setValoresIniciais(val)
      setAgenda(val)
    })
    setValidacao(
      Yup.object({
        executante: Yup.string().nullable().required(),
        justificativa_socio: props.justificativa
          ? Yup.string().nullable()
          : Yup.string().nullable(),
      }),
    )
  }

  useEffect(() => {
    if (props.item && props.item.id) {
      setId(props.item.id)
    }
  }, [props.item])

  useEffect(() => {
    setAgenda(null)
    obterAgenda()
  }, [id])

  const onChangeSocio = (options, propsFormik) => {
    const executanteIniciais = valoresIniciais.executante
    if (
      options &&
      executanteIniciais.filter(
        (item) => options.findIndex((x) => x.value === item.value) < 0,
      ).length > 0
    ) {
      setTrocaSocio(true)
    } else {
      setTrocaSocio(false)
    }
  }

  useEffect(() => {
    if (!props.abrirModal)
      setId(null)
  }, [props.abrirModal])

  return (
    <>
      <ModalCadastro
        titulo={props.titulo}
        show={props.abrirModal}
        setShowModal={props.setAbrirModal}
      >
        {agenda && agenda.id ? (
          <>
            <Formik
              initialValues={valoresIniciais}
              validationSchema={validacao}
              enableReinitialize={true}
              onSubmit={(values, propsFormik) =>
                ValidarSubmit(values, propsFormik)
              }
            >
              {(propsFormik) => (
                <div ref={refAtualizacao}>
                  <Form>
                    <div className="modal-body-cadastro">
                      <div class="separator mt-20 mb-20"></div>
                      <div className={'form-group col-12'}>
                        {carregando === true ? (
                          <LoadRelatorio
                            refLoading={refAtualizacao}
                            heigth={320}
                          />
                        ) : props.item && props.item.id ? (
                          <>
                            <InputPadrao
                              label="Id"
                              name="id"
                              type="hidden"
                              placeholder="Id"
                              obrigatorio={true}
                            />
                            <div className="row ">
                              <div className="col-6">
                                <label className={'width-100 bold'}>
                                  Data e horário
                                </label>
                              </div>
                              <div className="col-6">
                                <label className={'width-100 bold'}>
                                  Estab. de Saúde
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <label className={'width-100 mb-20'}>
                                  {agenda.data_hora_inicial}
                                </label>
                              </div>
                              <div className="col-6">
                                <label className={'width-100 bold'}>
                                  {agenda.estabelecimento_saude &&
                                    agenda.estabelecimento_saude.value +
                                    ' - ' +
                                    agenda.estabelecimento_saude.nome_fantasia.split(
                                      ' ',
                                    )[0]}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <label className={'width-100 bold'}>
                                  Cirurgião
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <label className={'width-100 mb-20'}>
                                  {(agenda.cirurgiao &&
                                    agenda.cirurgiao.nome) ||
                                    '--'}
                                </label>
                              </div>
                            </div>
                            <InputSocioAtivosComCores
                              multiplo
                              agenda_id={agenda && agenda.id}
                              data_inicial={agenda && agenda.data_hora_inicial}
                              xheight={800}
                              label={<b>Sócio</b>}
                              name="executante"
                              propsFormik={propsFormik}
                              tamanho={'col-lg-12 p-0'}
                              classeTooltip={'tooltip-input-modal'}
                              autoFocus
                              obrigatorio
                              onChangeSocio={(option) => onChangeSocio(option)}
                            />
                            <TextArea
                              rows={2}
                              label={<b>Justificativa</b>}
                              name="justificativa_socio"
                              type="text"
                              placeholder="Justificativa"
                              tamanho={'col-lg-12 p-0'}
                              classeTooltip={'tooltip-input-modal'}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="separator mt-40 mb-2"></div>
                    {carregando === true ? (
                      <></>
                    ) : (
                      <div className="direita container-botoes mb-3 group-salvar-novo">
                        <BotaoPadrao
                          texto="Salvar"
                          disabled={salvando}
                          click={(_) => { }}
                          idTooltip={'salvar'}
                        />
                      </div>
                    )}
                    <ModalDuasOpcoes
                      showModal={showModalValidacao}
                      setShowModal={setShowModalValidacao}
                      funcaoOpcao2={() => {
                        Submit(propsFormik.values, propsFormik)
                        setShowModalValidacao(false)
                      }}
                      funcaoOpcao1={() => {
                        setSalvando(false)
                        setShowModalValidacao(false)
                      }}
                      esconderFechar
                      mensagem={mensagemModal}
                      textoOpcao1={'Cancelar'}
                      classeOpcao1={'btn-sm btn-primary-modal simples'}
                      textoOpcao2={'Continuar'}
                    />
                    <ModalDuasOpcoes
                      showModal={showModalInterOcorrencia}
                      setShowModal={setShowModalInterOcorrencia}
                      funcaoOpcao1={() => {
                        setSalvando(false)
                        setShowModalInterOcorrencia(false)
                      }}
                      esconderFechar
                      mensagem={
                        <>
                          Há interposição/ocorrência para os sócios: <br />
                          <br />
                          {msgValidacao}
                          <br />
                        </>
                      }
                      textoOpcao1={'Fechar'}
                      classeOpcao1={'btn-sm btn-primary-modal simples'}
                      textoOpcao2={'Continuar'}
                      funcaoOpcao2={() => {
                        setShowModalInterOcorrencia(false)
                        ValidarSubmit(propsFormik.values, propsFormik, 2)
                      }}
                    />
                  </Form>
                </div>
              )}
            </Formik>
          </>
        ) : (
          <></>
        )}
      </ModalCadastro>
    </>
  )
}

const CancelarAgenda = (props) => {
  const [id, setId] = useState()

  const [salvando, setSalvando] = useState(false)
  const [item, setItem] = useState({})
  const [carregando, setCarregando] = useState(true)
  const refAtualizacao = useRef(null)
  const [valoresIniciais, setValoresIniciais] = useState({})
  const [validacao, setValidacao] = useState({})

  const Submit = (values, propsFormik) => {
    setSalvando(true)

    acoesAgenda(
      values,
      '/administracao/cancelarreservasociojustificativa',
      () => {
        mostrarToast('sucesso', mensagens.sucesso)
        setSalvando(false)
        props.funcaoAposSalvar()
      },
      () => {
        setSalvando(false)
      },
      false,
      ACOES.CANCELAR_AGENDA,
    )
  }

  function validarSubmit(values, propsFormik) {
    const objPermissao = {
      usuario_id: getUsuario().id,
      modulo_id: 38,
      acao_id: ACOES.CANCELAR_AGENDA
    }
    get('/controleAcesso/permissao', objPermissao).then(function (val) {
      if (val.length) {
        Submit(values, propsFormik)
      }
      else
        mostrarToast('erro', mensagens['permissao'])

    }).catch((e) => mostrarToast('erro', mensagens['erro']))
  }

  const obterAgenda = () => {
    setCarregando(true)
    Promise.resolve(get(`/administracao/agendareserva/${props.item.id}`)).then(
      (val) => {
        let obj = {}
        const hora = []
        hora.push(val.hora_inicial)

        if (val.hora_final) hora.push(val.hora_final)

        obj.horario = hora.join(' - ')
        obj.solicitante = val.cirurgiao ? val.cirurgiao.label : '--'
        obj.estabelecimento_saude = val.estabelecimento_saude
          ? val.estabelecimento_saude.label
          : '--'
        setItem(obj)

        let valoresIn = {
          id: props.item.id || {},
          justificativa_socio: val.justificativa_socio,
        }

        setValoresIniciais(valoresIn)
        setCarregando(false)
      },
    )

    setValidacao(
      Yup.object({
        justificativa_socio: Yup.string().nullable().required(),
      }),
    )
  }

  useEffect(() => {
    if (props.item && props.item.id) {
      setId(props.item.id)
    }
  }, [props.item])

  useEffect(() => {
    obterAgenda()
  }, [id])

  useEffect(() => {
    if (!props.abrirModal)
      setId(null)
  }, [props.abrirModal])

  return (
    <>
      <ModalCadastro
        titulo={props.titulo}
        show={props.abrirModal}
        setShowModal={props.setAbrirModal}
      >
        <div className="subheader-porte-acomodacao row-space-between">
          <BtnAcao
            type={'button'}
            icone="icon-Arrow-Left focus-button"
            texto="Anterior"
            action={(_) => {
              setValidacao({})
              props.acaoAnte()
            }}
          />
          <BtnAcao
            type={'button'}
            icone="icon-Arrow-Right focus-button"
            texto="Próximo"
            action={(_) => {
              setValidacao({})
              props.acaoProx()
            }}
          />
        </div>
        <Formik
          initialValues={valoresIniciais}
          validationSchema={validacao}
          enableReinitialize={true}
          onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}
        >
          {(propsFormik) => (
            <div ref={refAtualizacao}>
              <Form>
                <div className="modal-body-cadastro">
                  <div class="separator mt-20 mb-20"></div>
                  <div className={'form-group col-12'}>
                    {carregando === true ? (
                      <LoadRelatorio refLoading={refAtualizacao} heigth={320} />
                    ) : props.item && props.item.id ? (
                      <>
                        <InputPadrao
                          label="Id"
                          name="id"
                          type="hidden"
                          placeholder="Id"
                          obrigatorio={true}
                        />
                        <label className={'width-100 bold'}>Ações</label>
                        <div>
                          <props.acoes />
                        </div>
                        <label className={'width-100 bold'}>Horário</label>
                        <label className={'width-100 mb-20'}>
                          {item.horario || '--'}
                        </label>
                        <label className={'width-100 bold'}>
                          Estabelecimento de Saúde
                        </label>
                        <label className={'width-100 mb-20'}>
                          {item.estabelecimento_saude || '--'}
                        </label>
                        <label className={'width-100 bold'}>Solicitante</label>
                        <label className={'width-100 mb-20'}>
                          {item.solicitante || '--'}
                        </label>
                        {
                          <TextArea
                            label={<b>Justificativa</b>}
                            name="justificativa_socio"
                            type="text"
                            placeholder="Justificativa"
                            tamanho={'col-lg-12 p-0'}
                            classeTooltip={'tooltip-input-modal'}
                            obrigatorio
                          />
                        }
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="separator mt-40 mb-2"></div>
                {carregando === true ? (
                  <></>
                ) : (
                  <div className="direita container-botoes mb-3 group-salvar-novo">
                    <BotaoPadrao
                      texto="Salvar"
                      disabled={salvando}
                      click={(_) => { }}
                      idTooltip={'salvar'}
                    />
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </ModalCadastro>
    </>
  )
}

const ConfirmarAgendamento = (props) => {
  const [id, setId] = useState()

  const [salvando, setSalvando] = useState(false)
  const [item, setItem] = useState({})
  const [carregando, setCarregando] = useState(false)
  const refAtualizacao = useRef(null)
  const [valoresIniciais, setValoresIniciais] = useState({})

  const [validacao, setValidacao] = useState({})

  const Submit = (values, propsFormik) => {
    setSalvando(true)

    acoesAgenda(
      values,
      '/administracao/agendareservastatushistorico',
      () => {
        mostrarToast('sucesso', mensagens.sucesso)
        setSalvando(false)
        props.setAbrirModal(false)
        props.funcaoAposSalvar()
      },
      () => {
        setSalvando(false)
      },
      null,
      ACOES.CONFIRMAR_AGENDA,
    )
  }

  const obterAgenda = () => {
    let val = { ...props.item }

    let obj = {}
    const hora = []
    hora.push(val.hora_inicial)

    if (val.hora_final) hora.push(val.hora_final)

    obj.horario = hora.join(' - ')
    obj.solicitante = val.cirurgiao_descricao || '--'
    obj.estabelecimento_saude = val.estabelecimento_saude_descricao || '--'
    setItem(obj)

    let valoresIn = {
      id: props.item.id || {},
      status: 2,
      observacao_status: '',
    }

    setValoresIniciais(valoresIn)

    setValidacao(
      Yup.object({
        observacao_status: Yup.string().nullable(),
      }),
    )
  }

  useEffect(() => {
    if (props.item && props.item.id) {
      setId(props.item.id)
    }
  }, [props.item])

  useEffect(() => {
    obterAgenda()
  }, [id])

  useEffect(() => {
    if (!props.abrirModal)
      setId(null)
  }, [props.abrirModal])

  return (
    <>
      <ModalCadastro
        titulo={'Confirmar Agendamento'}
        show={props.abrirModal}
        setShowModal={props.setAbrirModal}
      >
        <Formik
          initialValues={valoresIniciais}
          validationSchema={validacao}
          enableReinitialize={true}
          onSubmit={(values, propsFormik) => Submit(values, propsFormik)}
        >
          {(propsFormik) => (
            <div ref={refAtualizacao}>
              <Form>
                <div className="modal-body-cadastro">
                  <div class="separator mt-20 mb-20"></div>
                  <div className={'form-group col-12'}>
                    {carregando === true ? (
                      <LoadRelatorio refLoading={refAtualizacao} heigth={320} />
                    ) : props.item && props.item.id ? (
                      <>
                        <InputPadrao
                          label="Id"
                          name="id"
                          type="hidden"
                          placeholder="Id"
                          obrigatorio={true}
                        />
                        <InputPadrao
                          label="Status"
                          name="status"
                          type="hidden"
                          placeholder="Status"
                          obrigatorio={true}
                        />
                        <label className={'width-100 bold'}>Horário</label>
                        <label className={'width-100 mb-20'}>
                          {item.horario || '--'}
                        </label>
                        <label className={'width-100 bold'}>
                          Estabelecimento de Saúde
                        </label>
                        <label className={'width-100 mb-20'}>
                          {item.estabelecimento_saude || '--'}
                        </label>
                        <label className={'width-100 bold'}>Solicitante</label>
                        <label className={'width-100 mb-20'}>
                          {item.solicitante || '--'}
                        </label>
                        <TextArea
                          label={<b>Observação</b>}
                          name="observacao_status"
                          type="text"
                          placeholder="Observação"
                          tamanho={'col-lg-12 p-0'}
                          classeTooltip={'tooltip-input-modal'}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="separator mt-40 mb-2"></div>
                {carregando === true ? (
                  <></>
                ) : (
                  <div className="direita container-botoes mb-3 group-salvar-novo">
                    <BotaoPadrao
                      texto="Confirmar"
                      disabled={salvando}
                      click={(_) => { }}
                    />
                    <BotaoPadrao
                      texto="Cancelar"
                      disabled={salvando}
                      click={(_) => props.setAbrirModal(false)}
                    />
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </ModalCadastro>
    </>
  )
}

const CadastroPreferencial = (props) => {
  const [id, setId] = useState()
  const [salvando, setSalvando] = useState(false)
  const [item, setItem] = useState({})
  const [carregando, setCarregando] = useState(true)
  const refAtualizacao = useRef(null)
  const [valoresIniciais, setValoresIniciais] = useState({})
  const [validacao, setValidacao] = useState({})

  const Submit = (values, propsFormik) => {
    setSalvando(true)

    acoesAgenda(
      values,
      '/administracao/agendaPreferencia',
      () => {
        mostrarToast('sucesso', mensagens.sucesso)
        setSalvando(false)
        props.funcaoAposSalvar()
      },
      () => {
        setSalvando(false)
      },
      null,
      ACOES.PREFERENCIAL,
    )
  }

  const obterAgenda = () => {
    setCarregando(true)
    Promise.resolve(get(`/administracao/agendareserva/${props.item.id}`)).then(
      (val) => {
        let obj = {}
        const hora = []
        hora.push(val.hora_inicial)

        if (val.hora_final) hora.push(val.hora_final)

        obj.horario = hora.join(' - ')
        obj.solicitante = val.cirurgiao ? val.cirurgiao.label : '--'
        obj.estabelecimento_saude = val.estabelecimento_saude
          ? val.estabelecimento_saude.label
          : '--'
        setItem(obj)

        let valoresIn = props.justificativa
          ? {
            agenda_id: props.item.id || {},
            socio: val.preferencia,
            justificativa_socio: val.justificativa_socio,
          }
          : {
            agenda_id: props.item.id || {},
            socio: val.preferencia,
          }

        setValoresIniciais(valoresIn)
        setCarregando(false)
      },
    )

    setValidacao(
      Yup.object({
        socio: Yup.string().nullable().required(),
      }),
    )
  }

  useEffect(() => {
    if (props.item && props.item.id) {
      setId(props.item.id)
    }
  }, [props.item])

  useEffect(() => {
    obterAgenda()
  }, [id])

  useEffect(() => {
    if (!props.abrirModal)
      setId(null)
  }, [props.abrirModal])

  return (
    <>
      <ModalCadastro
        titulo={props.titulo}
        show={props.abrirModal}
        setShowModal={props.setAbrirModal}
      >
        <div className="subheader-porte-acomodacao row-space-between">
          <BtnAcao
            type={'button'}
            icone="icon-Arrow-Left focus-button"
            texto="Anterior"
            action={(_) => {
              setValidacao({})
              props.acaoAnte()
            }}
          />
          <BtnAcao
            type={'button'}
            icone="icon-Arrow-Right focus-button"
            texto="Próximo"
            action={(_) => {
              setValidacao({})
              props.acaoProx()
            }}
          />
        </div>
        <Formik
          initialValues={valoresIniciais}
          validationSchema={validacao}
          enableReinitialize={true}
          onSubmit={(values, propsFormik) => Submit(values, propsFormik)}
        >
          {(propsFormik) => (
            <div ref={refAtualizacao}>
              <Form>
                <div className="modal-body-cadastro">
                  <div class="separator mt-20 mb-20"></div>
                  <div className={'form-group col-12'}>
                    {carregando === true ? (
                      <LoadRelatorio refLoading={refAtualizacao} heigth={320} />
                    ) : props.item && props.item.id ? (
                      <>
                        <InputPadrao
                          label="Id"
                          name="agenda_id"
                          type="hidden"
                          placeholder="Id"
                          obrigatorio={true}
                        />
                        <label className={'width-100 bold'}>Ações</label>
                        <div>
                          <props.acoes />
                        </div>
                        <label className={'width-100 bold'}>Horário</label>
                        <label className={'width-100 mb-20'}>
                          {item.horario || '--'}
                        </label>
                        <label className={'width-100 bold'}>
                          Estabelecimento de Saúde
                        </label>
                        <label className={'width-100 mb-20'}>
                          {item.estabelecimento_saude || '--'}
                        </label>
                        <label className={'width-100 bold'}>Solicitante</label>
                        <label className={'width-100 mb-20'}>
                          {item.solicitante || '--'}
                        </label>
                        <InputSocioAtivos
                          label={<b>Sócio Preferencial</b>}
                          name="socio"
                          propsFormik={propsFormik}
                          tamanho={'col-lg-12 p-0'}
                          classeTooltip={'tooltip-input-modal'}
                          autoFocus
                          multiplo
                          obrigatorio
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="separator mt-40 mb-2"></div>
                {carregando === true ? (
                  <></>
                ) : (
                  <div className="direita container-botoes mb-3 group-salvar-novo">
                    <BotaoPadrao
                      texto="Salvar"
                      disabled={salvando}
                      click={(_) => { }}
                      idTooltip={'salvar'}
                    />
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </ModalCadastro>
    </>
  )
}

const FinalizarAgenda = (props) => {
  const [id, setId] = useState()

  const [salvando, setSalvando] = useState(false)
  const [item, setItem] = useState({})
  const [carregando, setCarregando] = useState(true)
  const refAtualizacao = useRef(null)
  const [valoresIniciais, setValoresIniciais] = useState({})

  const [validacao, setValidacao] = useState({})

  const Submit = (values, propsFormik) => {
    setSalvando(true)

    const obj = {
      id: values.agenda_id,
      data_inicial: item.data_inicial,
      hora_inicial: values.hora_inicial,
      data_final: item.data_final,
      hora_final: values.hora_final,
      executante: values.executante.map((x) => x.value),
      observacao: values.observacao,
    }

    acoesAgenda(
      obj,
      '/administracao/agendaReservaFinalizar',
      () => {
        mostrarToast('sucesso', mensagens.sucesso)
        setSalvando(false)
        props.funcaoAposSalvar()
      },
      () => {
        setSalvando(false)
      },
      null,
      ACOES.FINALIZAR_AGENDA,
    )
  }

  const obterAgenda = () => {
    setCarregando(true)
    Promise.resolve(get(`/administracao/agendareserva/${props.item.id}`)).then(
      (val) => {
        let obj = {
          data_inicial: val.data_inicial,
          data_final: val.data_final,
          hora_inicial: val.hora_inicial || '',
          hora_final: val.hora_final || '',
          solicitante: val.cirurgiao ? val.cirurgiao.label : '--',
          estabelecimento_saude: val.estabelecimento_saude
            ? val.estabelecimento_saude.label
            : '--',
          executante: val.executante,
          agenda_id: val.id,
          observacao: '',
        }

        setItem(obj)

        setValoresIniciais(obj)
        setCarregando(false)
      },
    )

    setValidacao(
      Yup.object({
        hora_inicial: Yup.string()
          .required()
          .test('Hora Final', 'Hora inválida!', function (value) {
            return validaHora(this.parent.hora_inicial)
          }),
        hora_final: Yup.string()
          .nullable()
          .required()
          .test('Hora Final', 'Hora inválida!', function (value) {
            return validaHora(this.parent.hora_final)
          }),
        observacao: Yup.string().nullable().max(255),
      }),
    )
  }

  useEffect(() => {
    if (props.item && props.item.id) {
      setId(props.item.id)
    }
  }, [props.item])

  useEffect(() => {
    obterAgenda()
  }, [id])

  useEffect(() => {
    if (!props.abrirModal)
      setId(null)
  }, [props.abrirModal])

  return (
    <>
      <ModalCadastro
        titulo={props.titulo}
        show={props.abrirModal}
        setShowModal={props.setAbrirModal}
      >
        <div className="subheader-porte-acomodacao row-space-between">
          <BtnAcao
            type={'button'}
            icone="icon-Arrow-Left focus-button"
            texto="Anterior"
            action={(_) => {
              setValidacao({})
              props.acaoAnte()
            }}
          />
          <BtnAcao
            type={'button'}
            icone="icon-Arrow-Right focus-button"
            texto="Próximo"
            action={(_) => {
              setValidacao({})
              props.acaoProx()
            }}
          />
        </div>
        <Formik
          initialValues={valoresIniciais}
          validationSchema={validacao}
          enableReinitialize={true}
          onSubmit={(values, propsFormik) => Submit(values, propsFormik)}
        >
          {(propsFormik) => (
            <div ref={refAtualizacao}>
              <Form>
                <div className="modal-body-cadastro">
                  <div class="separator mt-20 mb-20"></div>
                  <div className={'form-group col-12'}>
                    {carregando === true ? (
                      <LoadRelatorio refLoading={refAtualizacao} heigth={320} />
                    ) : props.item && props.item.id ? (
                      <>
                        <InputPadrao
                          label="Id"
                          name="agenda_id"
                          type="hidden"
                          placeholder="Id"
                          obrigatorio={true}
                        />
                        <InputTimePicker
                          autoFocus
                          tamanho={'col-lg-12 p-0'}
                          label="Hora Inicial"
                          mascara="11:11"
                          name="hora_inicial"
                          type="text"
                          placeholder="Hora Inicial"
                          obrigatorio={true}
                          classeTooltip={'tooltip-input-modal'}
                          setarValor={(value) =>
                            propsFormik.setFieldValue('hora_inicial', value)
                          }
                        />
                        <InputTimePicker
                          label="Hora Final"
                          mascara="11:11"
                          name="hora_final"
                          type="text"
                          placeholder="Hora Final"
                          obrigatorio={true}
                          tamanho={'col-lg-12 p-0'}
                          classeTooltip={'tooltip-input-modal'}
                          setarValor={(value) =>
                            propsFormik.setFieldValue('hora_final', value)
                          }
                        />
                        <label className={'width-100 bold'}>
                          Estabelecimento de Saúde
                        </label>
                        <label className={'width-100 mb-20'}>
                          {item.estabelecimento_saude || '--'}
                        </label>
                        <label className={'width-100 bold'}>Solicitante</label>
                        <label className={'width-100 mb-20'}>
                          {item.solicitante || '--'}
                        </label>
                        <label className={'width-100 bold'}>Executante</label>
                        {(item.executante.length &&
                          item.executante.map((x) => (
                            <label className={'width-100'}>{x.label}</label>
                          ))) || <label className={'width-100'}>--</label>}
                        <TextArea
                          label={<b>Observação</b>}
                          name="observacao"
                          type="text"
                          placeholder="Observação"
                          tamanho={'col-lg-12 p-0 mt-20'}
                          classeTooltip={'tooltip-input-modal'}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="separator mt-40 mb-2"></div>
                {carregando === true ? (
                  <></>
                ) : (
                  <div className="direita container-botoes mb-3 group-salvar-novo">
                    <BotaoPadrao
                      texto="Salvar"
                      disabled={salvando}
                      click={(_) => { }}
                      idTooltip={'salvar'}
                    />
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </ModalCadastro>
    </>
  )
}

const CadastroObservacao = (props) => {
  const [id, setId] = useState()

  const [salvando, setSalvando] = useState(false)
  const [item, setItem] = useState({})
  const [carregando, setCarregando] = useState(true)
  const refAtualizacao = useRef(null)
  const [valoresIniciais, setValoresIniciais] = useState({})

  const [validacao, setValidacao] = useState({})

  const Submit = (values, propsFormik) => {
    setSalvando(true)

    acoesAgenda(
      values,
      '/administracao/agendareserva',
      () => {
        mostrarToast('sucesso', mensagens.sucesso)
        setSalvando(false)
        props.funcaoAposSalvar()
      },
      () => {
        setSalvando(false)
      },
      null,
      ACOES.OBSERVACAO_AGENDA,
    )
  }

  const obterAgenda = useCallback(() => {
    setCarregando(true)
    Promise.resolve(get(`/administracao/agendareserva/${props.item.id}`)).then(
      (val) => {
        let obj = {}
        const hora = []
        hora.push(val.hora_inicial)

        if (val.hora_final) hora.push(val.hora_final)

        obj.horario = hora.join(' - ')
        obj.solicitante = val.cirurgiao ? val.cirurgiao.label : '--'
        obj.estabelecimento_saude = val.estabelecimento_saude
          ? val.estabelecimento_saude.label
          : '--'
        setItem(obj)

        let valoresIn = {
          id: props.item.id || {},
          observacao: val.observacao,
        }

        setValoresIniciais(valoresIn)
        setCarregando(false)
      },
    )

    setValidacao(
      Yup.object({
        observacao: props.observacao
          ? Yup.string().nullable().required()
          : Yup.string().nullable(),
      }),
    )
  }, [id])

  useEffect(() => {
    if (props.item && props.item.id) {
      setId(props.item.id)
    }
  }, [props.item])

  useEffect(() => {
    obterAgenda()
  }, [id])

  useEffect(() => {
    if (!props.abrirModal)
      setId(null)
  }, [props.abrirModal])

  return (
    <>
      <ModalCadastro
        titulo={props.titulo}
        show={props.abrirModal}
        setShowModal={props.setAbrirModal}
      >
        <div className="subheader-porte-acomodacao row-space-between">
          <BtnAcao
            type={'button'}
            icone="icon-Arrow-Left focus-button"
            texto="Anterior"
            action={(_) => {
              setValidacao({})
              props.acaoAnte()
            }}
          />
          <BtnAcao
            type={'button'}
            icone="icon-Arrow-Right focus-button"
            texto="Próximo"
            action={(_) => {
              setValidacao({})
              props.acaoProx()
            }}
          />
        </div>
        <Formik
          initialValues={valoresIniciais}
          validationSchema={validacao}
          enableReinitialize={true}
          onSubmit={(values, propsFormik) => Submit(values, propsFormik)}
        >
          {(propsFormik) => (
            <div ref={refAtualizacao}>
              <Form>
                <div className="modal-body-cadastro">
                  <div class="separator mt-20 mb-20"></div>
                  <div className={'form-group col-12'}>
                    {carregando === true ? (
                      <LoadRelatorio refLoading={refAtualizacao} heigth={320} />
                    ) : props.item && props.item.id ? (
                      <>
                        <InputPadrao
                          label="Id"
                          name="id"
                          type="hidden"
                          placeholder="Id"
                          obrigatorio={true}
                        />
                        <label className={'width-100 bold'}>Ações</label>
                        <div>
                          <props.acoes />
                        </div>
                        <label className={'width-100 bold'}>Horário</label>
                        <label className={'width-100 mb-20'}>
                          {item.horario || '--'}
                        </label>
                        <label className={'width-100 bold'}>
                          Estabelecimento de Saúde
                        </label>
                        <label className={'width-100 mb-20'}>
                          {item.estabelecimento_saude || '--'}
                        </label>
                        <label className={'width-100 bold'}>Solicitante</label>
                        <label className={'width-100 mb-20'}>
                          {item.solicitante || '--'}
                        </label>
                        <TextArea
                          label={<b>Observação</b>}
                          name="observacao"
                          type="text"
                          placeholder="Observação"
                          tamanho={'col-lg-12 p-0'}
                          classeTooltip={'tooltip-input-modal'}
                          obrigatorio
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="separator mt-40 mb-2"></div>
                {carregando === true ? (
                  <></>
                ) : (
                  <div className="direita container-botoes mb-3 group-salvar-novo">
                    <BotaoPadrao
                      texto="Salvar"
                      disabled={salvando}
                      click={(_) => { }}
                      idTooltip={'salvar'}
                    />
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </ModalCadastro>
    </>
  )
}

const TrocarStatus = (props) => {
  const [id, setId] = useState()
  const [salvando, setSalvando] = useState(false)
  const [item, setItem] = useState({})
  const [carregando, setCarregando] = useState(true)
  const refAtualizacao = useRef(null)
  const [valoresIniciais, setValoresIniciais] = useState({})
  const [showModalValidacao, setShowModalValidacao] = useState(false)
  const [mensagemModal, setMensagemModal] = useState('')

  const [validacao, setValidacao] = useState({})

  const Submit = (values, propsFormik) => {
    setSalvando(true)
    let valuesAjustados = { ...values }
    valuesAjustados.status = values.status ? values.status.id : ''

    acoesAgenda(
      valuesAjustados,
      '/administracao/agendareservastatushistorico',
      () => {
        mostrarToast('sucesso', mensagens.sucesso)
        setSalvando(false)
        props.funcaoAposSalvar()
      },
      () => setSalvando(false),
      null,
      ACOES.ALTERAR_STATUS_AGENDA,
    )
  }

  const obterAgenda = () => {
    setCarregando(true)
    Promise.resolve(get(`/administracao/agendareserva/${props.item.id}`)).then(
      (val) => {
        let obj = {}
        const hora = []
        hora.push(val.hora_inicial)

        if (val.hora_final) hora.push(val.hora_final)

        obj.horario = hora.join(' - ')
        obj.solicitante = val.cirurgiao ? val.cirurgiao.label : '--'
        obj.estabelecimento_saude = val.estabelecimento_saude
          ? val.estabelecimento_saude.label
          : '--'
        setItem(obj)

        let valoresIn = props.justificativa
          ? {
            id: props.item.id || {},
            executante: val.executante,
            status: '',
            justificativa_socio: val.justificativa_socio,
          }
          : {
            id: props.item.id || {},
            executante: val.executante,
            status: val.status,
          }

        setValoresIniciais(valoresIn)
        setCarregando(false)
      },
    )

    setValidacao(
      Yup.object({
        status: Yup.string().nullable().required(),
        observacao: Yup.string().nullable(),
      }),
    )
  }

  useEffect(() => {
    if (props.item && props.item.id) {
      setId(props.item.id)
    }
  }, [props.item])

  useEffect(() => {
    obterAgenda()
  }, [id])

  useEffect(() => {
    if (!props.abrirModal)
      setId(null)
  }, [props.abrirModal])

  const funcaoAposSim = (formik) => {
    Submit(formik.values, formik.resetForm)
  }

  return (
    <>
      <ModalCadastro
        titulo={props.titulo}
        show={props.abrirModal}
        setShowModal={props.setAbrirModal}
      >
        <div className="subheader-porte-acomodacao row-space-between">
          <BtnAcao
            type={'button'}
            icone="icon-Arrow-Left focus-button"
            texto="Anterior"
            action={(_) => {
              setValidacao({})
              props.acaoAnte()
            }}
          />
          <BtnAcao
            type={'button'}
            icone="icon-Arrow-Right focus-button"
            texto="Próximo"
            action={(_) => {
              setValidacao({})
              props.acaoProx()
            }}
          />
        </div>
        <Formik
          initialValues={valoresIniciais}
          validationSchema={validacao}
          enableReinitialize={true}
          onSubmit={(values, propsFormik) => Submit(values, propsFormik)}
        >
          {(propsFormik) => (
            <div ref={refAtualizacao}>
              <Form>
                <div className="modal-body-cadastro">
                  <div class="separator mt-20 mb-20"></div>
                  <div className={'form-group col-12'}>
                    {carregando === true ? (
                      <LoadRelatorio refLoading={refAtualizacao} heigth={320} />
                    ) : props.item && props.item.id ? (
                      <>
                        <InputPadrao
                          label="Id"
                          name="id"
                          type="hidden"
                          placeholder="Id"
                          obrigatorio={true}
                        />
                        <label className={'width-100 bold'}>Ações</label>
                        <div>
                          <props.acoes />
                        </div>
                        <label className={'width-100 bold'}>Horário</label>
                        <label className={'width-100 mb-20'}>
                          {item.horario || '--'}
                        </label>
                        <label className={'width-100 bold'}>
                          Estabelecimento de Saúde
                        </label>
                        <label className={'width-100 mb-20'}>
                          {item.estabelecimento_saude || '--'}
                        </label>
                        <label className={'width-100 bold'}>Solicitante</label>
                        <label className={'width-100 mb-20'}>
                          {item.solicitante || '--'}
                        </label>
                        <InputStatusAgendamento
                          label={<b>Status Agendamento</b>}
                          name="status"
                          propsFormik={propsFormik}
                          tamanho={'col-lg-12 p-0'}
                          classeTooltip={'tooltip-input-modal'}
                          autoFocus
                          obrigatorio
                        />

                        <TextArea
                          label={<b>Observação</b>}
                          name="observacao"
                          type="text"
                          placeholder="Observação"
                          tamanho={'col-lg-12 p-0'}
                          classeTooltip={'tooltip-input-modal'}
                          obrigatorio
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="separator mt-40 mb-2"></div>
                {carregando === true ? (
                  <></>
                ) : (
                  <div className="direita container-botoes mb-3 group-salvar-novo">
                    <BotaoPadrao
                      texto="Salvar"
                      disabled={salvando}
                      click={(_) => { }}
                      idTooltip={'salvar'}
                    />
                  </div>
                )}
                <ModalDuasOpcoes
                  showModal={showModalValidacao}
                  setShowModal={setShowModalValidacao}
                  funcaoOpcao2={() => funcaoAposSim(propsFormik)}
                  funcaoOpcao1={() => setSalvando(false)}
                  esconderFechar
                  mensagem={mensagemModal}
                  textoOpcao1={'Cancelar'}
                  classeOpcao1={'btn-sm btn-primary-modal simples'}
                  textoOpcao2={'Continuar'}
                />
              </Form>
            </div>
          )}
        </Formik>
      </ModalCadastro>
    </>
  )
}

const CadastroRapido = (props) => {
  let { id, idDuplicar } = useParams()
  const user = getUsuario()

  const [salvarENovo, setSalvarENovo] = useState(false)
  const [salvando, setSalvando] = useState(false)
  const [valoresIniciais, setValoresIniciais] = useState({})
  const [modalProcedimentos, setModalProcedimentos] = useState(false)
  const [arrayProcedimentos, setArrayProcedimentos] = useState([])
  const [modalPacientes, setModalPacientes] = useState(false)
  const [arrayPacientes, setArrayPacientes] = useState([])
  const [objPaciente, setObjPaciente] = useState({})
  const [formikProcedimentos, setFormikProcedimentos] = useState({})
  const formik = useFormikContext()
  const [refEstabelecimento, setRefEstabelecimento] = useState({})
  const [showModalValidacao, setShowModalValidacao] = useState(false)
  const [mensagemModal, setMensagemModal] = useState('')

  const [validacao] = useState(
    Yup.object({
      estabelecimento_saude: Yup.string().nullable().required(),
      data_inicial: Yup.string()
        .nullable()
        .required()
        .test('Data Inicial', 'Data inválida!', function (value) {
          return validaData(this.parent.data_inicial)
        }),
      hora_inicial: Yup.string()
        .required()
        .test('Hora Final', 'Hora inválida!', function (value) {
          return validaHora(this.parent.hora_inicial)
        }),
      hora_final: Yup.string()
        .nullable()
        .test('Hora Final', 'Hora inválida!', function (value) {
          return validaHora(this.parent.hora_final)
        }),
      observacao: Yup.string().nullable().max(255),
    }),
  )

  const ValidarSubmit = (values, resetForm) => {
    setSalvando(true)
    validar(
      '/administracao/agendaReservaVerificacao',
      values,
      () => Submit(values, resetForm),
      (e) => {
        setShowModalValidacao(true)
        setMensagemModal(e.response.data.alertas[0])
        setSalvando(false)
      },
    )
  }

  const manter = [
    'estabelecimento_saude',
    'cirurgiao',
    'data_inicial',
    'executante',
    'empresa',
  ]

  useEffect(() => {
    // document.getElementById("myAnchor").focus()
    if (refEstabelecimento.current) {
      refEstabelecimento.current.focus()
    }
  }, [refEstabelecimento])

  useEffect(() => {
    setArrayProcedimentos([])
  }, [props.abrirModal])

  const funcaoAposSim = (formik) => {
    Submit(formik.values, formik)
  }

  const Submit = (values, propsFormik) => {
    setSalvando(true)

    salvar(
      { procedimentos: arrayProcedimentos, ...values },
      '/administracao/agendareserva',
      () => {
        mostrarToast('sucesso', mensagens.sucesso)
        propsFormik.resetForm({})
        manter.forEach((x) => propsFormik.setFieldValue(x, values[x]))
        setArrayProcedimentos([])
        setSalvando(false)
        setSalvarENovo(false)
        refEstabelecimento.current.focus()
        props.funcaoAposSalvar()
      },
      () => {
        setSalvando(false)
      },
    )
  }

  useEffect(() => {
    if (id) {
      const url = `/administracao/agendareserva/${id}`
      Promise.resolve(get(url)).then((val) => {
        setArrayProcedimentos(
          val.procedimentos.map((x) => {
            delete x.paciente_nome
            return { verificador: Math.random(), ...x }
          }),
        )
        setArrayPacientes(
          val.pacientes.map((x) => {
            delete x.beneficiario_nome
            return { verificador: Math.random(), ...x }
          }),
        )
        delete val.procedimentos
        delete val.pacientes
        delete val.status
        setValoresIniciais(val)
      })
    } else if (idDuplicar) {
      const url = `/administracao/agendareserva/${idDuplicar}`
      Promise.resolve(get(url)).then((val) => {
        setValoresIniciais({
          executante: val.executante,
          empresa: val.empresa,
          estabelecimento_saude: val.estabelecimento_saude,
          cirurgiao: val.cirurgiao,
          data_inicial: val.data_inicial,
          hora_inicial: val.hora_inicial,
          hora_final: val.hora_final,
          plantao: val.plantao,
        })
      })
    } else {
      setValoresIniciais({
        executante: '',
        equipe_repasse: '',
        empresa: '',
        estabelecimento_saude: '',
        cirurgiao: '',
        data_inicial: '',
        hora_inicial: '',
        hora_final: '',
        observacao: '',
      })
    }
  }, [id])

  const settarValorPlantao = (propsFormik) => {
    propsFormik.values.plantao = !propsFormik.values.plantao
  }

  return (
    <>
      <ModalCadastro
        titulo={'Cadastro Agenda'}
        show={props.abrirModal}
        setShowModal={props.setAbrirModal}
      >
        <Formik
          initialValues={valoresIniciais}
          validationSchema={validacao}
          enableReinitialize={true}
          onSubmit={(values, propsFormik) => ValidarSubmit(values, propsFormik)}
        >
          {(propsFormik) => (
            <Form>
              <div className="modal-body-cadastro">
                <div className="row">
                  <InputPadrao
                    label="Id"
                    name="id"
                    type="hidden"
                    placeholder="Id"
                    obrigatorio={true}
                  />

                  <InputEstabelecimentoSaude
                    setReference={setRefEstabelecimento}
                    label="Estabelecimento Saúde"
                    placeholder="Estabelecimento Saúde"
                    name="estabelecimento_saude"
                    propsFormik={propsFormik}
                    obrigatorio={true}
                    tamanho={'col-lg-12'}
                    classeTooltip={'tooltip-input-modal'}
                    focarAposSubmit={true}
                    salvarENovo={salvarENovo}
                    ref={refEstabelecimento}
                    autoFocus
                  />

                  <InputDatePicker
                    tamanho={'col-lg-12'}
                    label="Data Inicial"
                    name="data_inicial"
                    type="text"
                    obrigatorio="true"
                    setarValor={(value) => {
                      propsFormik.setFieldValue('data_inicial', value)
                    }}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                    mostrarFeriado={true}
                  />

                  <InputTimePicker
                    label="Hora Inicial"
                    mascara="11:11"
                    name="hora_inicial"
                    type="text"
                    placeholder="Hora Inicial"
                    tamanho={'col-lg-12'}
                    obrigatorio={true}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                    setarValor={(value) => {
                      propsFormik.setFieldValue('hora_inicial', value)
                    }}
                  />
                  <InputTimePicker
                    label="Hora Final"
                    mascara="11:11"
                    name="hora_final"
                    type="text"
                    placeholder="Hora Final"
                    tamanho={'col-lg-12'}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                    setarValor={(value) => {
                      propsFormik.setFieldValue('hora_final', value)
                    }}
                  />
                  <InputSocioAtivos
                    label="Sócio"
                    name="executante"
                    propsFormik={propsFormik}
                    tamanho={'col-lg-12'}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                    salvarENovo={salvarENovo}
                    multiplo
                  />

                  <InputCirurgiaoCombo
                    tamanho={'col-lg-12'}
                    label="Cirurgião"
                    name="cirurgiao"
                    multiplo={false}
                    placeholder="Cirurgião"
                    propsFormik={propsFormik}
                    botaoVisivel={true}
                    modal
                  />

                  <TextArea
                    label="Observação"
                    name="observacao"
                    type="text"
                    placeholder="Observação"
                    tamanho={'col-lg-12'}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                  />
                </div>
              </div>
              <CadastroRapidoProcedimento
                arrayDados={arrayProcedimentos}
                setArrayDados={setArrayProcedimentos}
                abrirModal={modalProcedimentos}
                setAbrirModal={setModalProcedimentos}
                pacientes={arrayPacientes.map((item) => ({
                  value: item.item,
                  label: item.beneficiario.label,
                }))}
                chamarModalPaciente={() => setModalPacientes(true)}
                pacienteInserido={objPaciente}
                formikProcedimentos={(formik) => setFormikProcedimentos(formik)}
              />

              <div className="separator mt-20 mb-5"></div>
              <div className="direita container-botoes mb-3 group-salvar-novo">
                <Atalho formik={propsFormik} salvando={salvando} />
                <BotaoPadrao
                  texto="Salvar"
                  disabled={salvando}
                  click={(_) => { }}
                  idTooltip={'salvar'}
                  classeTooltip="tooltip-plantao-es"
                />
              </div>
              <ModalDuasOpcoes
                showModal={showModalValidacao}
                setShowModal={setShowModalValidacao}
                funcaoOpcao2={() => funcaoAposSim(propsFormik)}
                funcaoOpcao1={() => setSalvando(false)}
                esconderFechar
                mensagem={mensagemModal}
                textoOpcao1={'Cancelar'}
                classeOpcao1={'btn-sm btn-primary-modal simples'}
                textoOpcao2={'Continuar'}
              />
            </Form>
          )}
        </Formik>
      </ModalCadastro>
    </>
  )
}

const CadastroEstabelecimentoPlantao = (props) => {
  const [salvarENovo, setSalvarENovo] = useState(false)
  const [salvando, setSalvando] = useState(false)
  const [valoresIniciais, setValoresIniciais] = useState({})
  const [refEstabelecimento, setRefEstabelecimento] = useState({})
  const [showModalValidacao, setShowModalValidacao] = useState(false)
  const [msgValidacao, setMsgValidacao] = useState([])
  const [modalProcedimentos, setModalProcedimentos] = useState(false)
  // const [arrayExecutantes, setArrayExecutantes] = useState([])
  const [formikProcedimentos, setFormikProcedimentos] = useState({})
  const [showModalValidacaoOrdem, setShowModalValidacaoOrdem] = useState(false)
  const [showModalValidacaoSocio, setShowModalValidacaoSocio] = useState(false)
  const [showModalInterOcorrencia, setShowModalInterOcorrencia] =
    useState(false)
  const refInputHorario = useRef(null)
  // const [valoresSocios, setValoresSocios] = useState(null)
  const [permitidoSalvar, setPermitidoSalvar] = useState(true)

  const [validacao] = useState(
    Yup.object({
      estabelecimento_saude: Yup.string().nullable().required(),
      periodo: Yup.string().nullable().required(),
      data: Yup.string()
        .nullable()
        .required()
        .test('Data', 'Data inválida!', function (value) {
          return validaData(this.parent.data)
        }),
      observacao: Yup.string().nullable().max(255),
      data_final: Yup.string()
        .nullable()
        .test('Data Final', 'Data inválida!', function (value) {
          return validaData(this.parent.data_final)
        }),
    }).shape({
      socio: Yup.array().of(
        Yup.object().shape({
          executante: Yup.string().nullable(), // these constraints take precedence
          periodo: Yup.string()
            .nullable()
            .test('Período', 'Campo obrigatório!', function (value) {
              return !this.parent.executante ? true : this.parent.periodo
            }), // these constraints take precedence
        }),
      ),
    }),
  )

  useEffect(() => {
    //refazer focus
    if (refEstabelecimento.current) {
      refEstabelecimento.current.focus()
    }
  }, [refEstabelecimento])

  const validacaoInterposicaoOcorrencia = (values, propsFormik) => {
    // validar(`/administracao/socio-ocorrencia-verificacao`, { modulo: 53, executantes: arrayExecutantes.map(x => x), ...values }, () => validarSubmit(values, propsFormik, 2), (e) => {
    let valoresFormatados = { ...values }
    valoresFormatados.socio = valoresFormatados.socio.filter(
      (item) => item.executante && item.periodo,
    )
    validar(
      `/administracao/socio-ocorrencia-verificacao`,
      { modulo: 53, ...valoresFormatados },
      () => validarSubmit(valoresFormatados, propsFormik, 2),
      (e) => {
        const alertas = e.response.data.alertas
        setMsgValidacao(
          <>
            <div
              dangerouslySetInnerHTML={{ __html: alertas[0].replace(/"/g, '') }}
            />
          </>,
        )
        setShowModalInterOcorrencia(true)
        setSalvando(false)
      },
    )
  }

  const validarSubmit = (values, propsFormik, nValidacao) => {
    setSalvando(true)

    if (!permitidoSalvar) {
      mostrarToast('alerta', 'Sócio não adicionado.')
      setSalvando(false)
      return
    }

    switch (nValidacao) {
      case 1:
        validacaoInterposicaoOcorrencia(values, propsFormik)
        break
      case 2:
        validar(
          '/administracao/plantaoReservaVerificacao',
          { modulo: 53, ...values },
          () => validarRecorrencia(values, propsFormik),
          (e) => {
            const alertas = e.response.data.alertas
            setMsgValidacao(
              <>
                {alertas[0]}
                <br />
                <div dangerouslySetInnerHTML={{ __html: alertas[1] }} />
                <br />
                {alertas[2]}
              </>,
            )
            setShowModalValidacaoSocio(true)
            setSalvando(false)
          },
        )
        break
      default:
        validacaoInterposicaoOcorrencia(values, propsFormik)
        break
    }
  }

  const validarRecorrencia = (values, propsFormik) => {
    if (values.recorrencia && values.id) setShowModalValidacao(true)
    else Submit(values, propsFormik, false)
  }

  const Submit = (values, propsFormik, validado) => {
    setSalvando(true)
    if (validado) {
      let validacaoData = moment(values.data, 'DD/MM/YYYY')
      if (
        values.recorrencia &&
        values.recorrencia.value === 4 &&
        validacaoData.weekday() !== 0 &&
        validacaoData.weekday() !== 6
      )
        while (true) {
          values.data = validacaoData.add(1, 'day').format('DD/MM/YYYY')

          if (validacaoData.weekday() === 6) break

          validacaoData = moment(values.data, 'DD/MM/YYYY')
        }

      if (
        values.recorrencia &&
        values.recorrencia.value === 2 &&
        (validacaoData.weekday() === 0 || validacaoData.weekday() === 6)
      )
        while (true) {
          values.data = validacaoData.add(1, 'day').format('DD/MM/YYYY')

          if (validacaoData.weekday() !== 6 && validacaoData.weekday() !== 0)
            break

          validacaoData = moment(values.data, 'DD/MM/YYYY')
        }

      let valoresFormatados = { ...values }
      valoresFormatados.socio = valoresFormatados.socio.filter(
        (item) => item.executante && item.periodo,
      )

      salvar(
        { ...valoresFormatados },
        '/administracao/plantaoestabelecimento',
        (_) => {
          mostrarToast('sucesso', mensagens.sucesso)
          propsFormik.resetForm({
            values: {
              estabelecimento_saude: values.estabelecimento_saude,
              data: values.data,
              socio: [{ executante: undefined, periodo: undefined }],
            },
          })
          setSalvando(false)
          setSalvarENovo(false)
          // setArrayExecutantes([])
          props.target.current.atualizar()
          if (props.id) props.setAbrirModal(false)
          props.funcaoAposSalvar()
        },
        (_) => setSalvando(false),
      )
    } else {
      const validacaoData = moment(values.data, 'DD/MM/YYYY')
      const msg = []

      if (
        values.recorrencia &&
        values.recorrencia.value === 4 &&
        validacaoData.weekday() !== 0 &&
        validacaoData.weekday() !== 6
      )
        msg.push('O lançamento será realizado no próximo final de semana.')

      if (
        values.recorrencia &&
        values.recorrencia.value === 2 &&
        (validacaoData.weekday() === 0 || validacaoData.weekday() === 6)
      )
        msg.push('O lançamento será realizado no próximo dia útil.')

      if (msg.length) {
        setMsgValidacao(msg)
        setShowModalValidacaoOrdem(true)
      } else {
        setMsgValidacao([])
        Submit(values, propsFormik, true)
      }
    }
  }

  useEffect(() => {
    let id = props.id
    if (id) {
      Promise.resolve(get(`/administracao/plantaoestabelecimento/${id}`)).then(
        (obj) => {
          props.duplicar && delete obj.id
          id = null

          if (obj.socio)
            obj.socio.push({ executante: undefined, periodo: undefined })
          else obj.socio = [{ executante: undefined, periodo: undefined }]

          setValoresIniciais(obj)
        },
      )
    } else {
      setValoresIniciais({
        estabelecimento_saude: '',
        periodo: '',
        data: '',
        data_final: '',
        socio: [{ executante: undefined, periodo: undefined }],
      })
    }
  }, [props.abrirModal, props.id])

  const onChangePeriodo = (option, propsFormik) => {
    if (option) {
      let arrayProcedimentos = propsFormik.values.socio
      arrayProcedimentos = arrayProcedimentos.map((item) =>
        item.periodo ? item : { executante: item.executante, periodo: option },
      )

      propsFormik.setFieldValue('socio', arrayProcedimentos)
    }
  }

  return props.abrirModal ? (
    <Formik
      initialValues={valoresIniciais}
      validationSchema={validacao}
      enableReinitialize={true}
      onSubmit={(values, propsFormik) =>
        validarSubmit(values, propsFormik, true)
      }
    >
      {(propsFormik) => (
        <Form>
          <div className="modal-body-cadastro">
            <div className="row ml-0">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <InputPadrao
                  label="Id"
                  name="id"
                  type="hidden"
                  placeholder="Id"
                />
                <div className="row">
                  <InputEstabelecimentoSaude
                    setReference={setRefEstabelecimento}
                    label="Estabelecimento Saúde"
                    placeholder="Estabelecimento Saúde"
                    name="estabelecimento_saude"
                    propsFormik={propsFormik}
                    obrigatorio={true}
                    tamanho={'col-xl-7'}
                    classeTooltip={'tooltip-input-modal'}
                    focarAposSubmit={true}
                    salvarENovo={salvarENovo}
                    ref={refEstabelecimento}
                    autoFocus
                  />
                  <InputDatePicker
                    tamanho={'col-xl-5'}
                    label="Data"
                    name="data"
                    type="text"
                    obrigatorio="true"
                    setarValor={(value) => {
                      propsFormik.setFieldValue('data', value)
                    }}
                    classeTooltip={props.modal && 'tooltip-input-modal'}
                    mostrarFeriado={true}
                  />
                  <InputIntervaloHorario
                    botaoVisivel={true}
                    label="Período"
                    obrigatorio
                    name={'periodo'}
                    tamanho={'col-xl-6'}
                    propsFormik={propsFormik}
                    salvarENovo={salvarENovo}
                    onChangeValue={(option) =>
                      onChangePeriodo(option, propsFormik)
                    }
                  />
                  <InputDatePicker
                    mostrarFeriado={true}
                    disabled={
                      valoresIniciais.recorrencia
                        ? true
                        : propsFormik.values['recorrencia']
                          ? true
                          : false
                    }
                    label="Data Final"
                    name="data_final"
                    type="text"
                    setarValor={(value) => {
                      propsFormik.setFieldValue('data_final', value)
                      propsFormik.setFieldValue('recorrencia', '')
                    }}
                    tamanho={'col-xl-6'}
                  />
                  <InputRecorrencio
                    isDisabled={
                      (props.id && true) || propsFormik.values['data_final']
                        ? true
                        : false
                    }
                    label="Recorrência (Dentro do Mês)"
                    name={'recorrencia'}
                    tamanho={'col-xl-6'}
                    propsFormik={propsFormik}
                    salvarENovo={salvarENovo}
                    onChange={(option) => {
                      propsFormik.setFieldValue('data_final', '')
                      propsFormik.setFieldValue('recorrencia', option)
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 bl-cinza">
                <CadastroSocioEstabelecimentoPlantao
                  refPeriodo={refInputHorario}
                  alteracao={!!props.id}
                  formikCadastro={propsFormik}
                  setPermitidoSalvar={setPermitidoSalvar}
                  abrirModal={modalProcedimentos}
                  setAbrirModal={setModalProcedimentos}
                  formikProcedimentos={(formik) =>
                    setFormikProcedimentos(formik)
                  }
                  dataPlantao={propsFormik.values.data_inicial}
                />
              </div>
            </div>
          </div>
          <div className="text-right container-botoes mb-3 group-salvar-novo mt-2">
            <Atalho formik={propsFormik} salvando={salvando} />
            <BotaoPadrao
              texto="Salvar"
              disabled={salvando}
              click={(_) => { }}
              idTooltip={'salvar'}
              classeTooltip="tooltip-plantao-es"
              class="btn btn-primary-sm primary"
            />
            <BotaoPadrao
              texto="Cancelar"
              disabled={salvando}
              click={(_) => {
                props.setAbrirModal(false)
                propsFormik.setFieldValue('socios', [
                  { executante: undefined, periodo: undefined },
                ])
              }}
              idTooltip={'cancelar'}
              classeTooltip="tooltip-plantao-es"
              class="btn btn-primary-sm primary"
            />
          </div>
          <ModalEscolha
            showModal={showModalValidacao}
            setShowModal={setShowModalValidacao}
            esconderFechar
            mensagem={'Selecione uma opção:'}
            funcaoCancelar={() => setSalvando(false)}
          >
            <ul class="lista-modal text-center width-100">
              <li
                onClick={(_) => {
                  propsFormik.values.alterar_recorrencia = 3
                  Submit(propsFormik.values, propsFormik)
                  setShowModalValidacao(false)
                }}
              >
                <div className="mb-1 li-titulo">
                  Alterar somente este registro
                </div>
              </li>
              <div className="mt-1" />
              <li
                onClick={(_) => {
                  propsFormik.values.alterar_recorrencia = 2
                  Submit(propsFormik.values, propsFormik)
                  setShowModalValidacao(false)
                }}
              >
                <div className="mb-1 li-titulo">
                  Alterar também próximas recorrências
                </div>
              </li>
            </ul>
          </ModalEscolha>
          <ModalDuasOpcoes
            showModal={showModalValidacaoSocio}
            setShowModal={setShowModalValidacaoSocio}
            funcaoOpcao2={(_) => {
              setShowModalValidacaoSocio(false)
              validarRecorrencia(propsFormik.values, propsFormik)
            }}
            funcaoOpcao1={() => {
              setSalvando(false)
              setShowModalValidacaoSocio(false)
            }}
            esconderFechar
            mensagem={
              <>
                {msgValidacao}
                <br />
                {'Deseja Continuar?'}{' '}
              </>
            }
            textoOpcao1={'Cancelar'}
            classeOpcao1={'btn-sm btn-primary-modal simples'}
            textoOpcao2={'Continuar'}
          />
          <ModalDuasOpcoes
            showModal={showModalValidacaoOrdem}
            setShowModal={setShowModalValidacaoOrdem}
            funcaoOpcao2={(_) => {
              setShowModalValidacaoOrdem(false)
              Submit(propsFormik.values, propsFormik, true)
            }}
            funcaoOpcao1={() => {
              setSalvando(false)
              setShowModalValidacaoOrdem(false)
            }}
            esconderFechar
            mensagem={
              <>
                {msgValidacao}
                <br />
                {'Deseja Continuar?'}{' '}
              </>
            }
            textoOpcao1={'Cancelar'}
            classeOpcao1={'btn-sm btn-primary-modal simples'}
            textoOpcao2={'Continuar'}
          />
          <ModalDuasOpcoes
            showModal={showModalInterOcorrencia}
            setShowModal={setShowModalInterOcorrencia}
            funcaoOpcao1={() => {
              setSalvando(false)
              setShowModalInterOcorrencia(false)
            }}
            esconderFechar
            mensagem={
              <>
                Há interposição/ocorrência para os sócios: <br />
                <br />
                {msgValidacao}
                <br />
                {'Deseja Continuar?'}
              </>
            }
            textoOpcao1={'Fechar'}
            classeOpcao1={'btn-sm btn-primary-modal simples'}
            textoOpcao2={'Continuar'}
            funcaoOpcao2={(_) => {
              setShowModalInterOcorrencia(false)
              validarSubmit(propsFormik.values, propsFormik, 2)
            }}
          />
        </Form>
      )}
    </Formik>
  ) : (
    <></>
  )
}

const CadastroSocioEstabelecimentoPlantao = (props) => {
  const onChangeSocio = (option, propsFormik, arrayHelpers, index) => {
    const arraySocios = [...propsFormik.values.socio]
    if (option) {
      const valorAnterior = { ...propsFormik.values.socio[index] }

      arraySocios[index].executante = option

      propsFormik.setFieldValue('socio', arraySocios)

      if (!valorAnterior.executante || !valorAnterior.executante.value) {
        adicionarLinha(arrayHelpers, propsFormik)
      }
    } else {
      arraySocios[index].executante = undefined
      propsFormik.setFieldValue('socio', arraySocios)
    }
  }

  const adicionarLinha = (arrayHelpers, propsFormik) => {
    const arrayProced = propsFormik.values.socio
    if (
      arrayProced &&
      arrayProced.findIndex((x) => !x.executante || !x.executante.value) === -1
    ) {
      arrayHelpers.insert(propsFormik.values.socio.length, {
        executante: undefined,
        periodo: propsFormik.values.periodo || undefined,
      })
    }
  }

  const removerLinha = (arrayHelpers, propsFormik, index) => {
    const arrayProced = propsFormik.values.socio
    if (arrayProced.length === 1) {
      propsFormik.setFieldValue('socio', [
        { executante: undefined, periodo: undefined },
      ])
    } else {
      if (arrayProced[index].executante && arrayProced[index].executante.value)
        arrayHelpers.remove(index)

      if (
        arrayProced.length === 1 &&
        arrayProced[index].executante &&
        arrayProced[index].executante.value
      )
        arrayHelpers.insert(propsFormik.values.socio.length, {
          executante: undefined,
          periodo: undefined,
        })
    }
  }

  return (
    <>
      <div className="card mt-2">
        <div className="card-body socio-float custom-scrollbar">
          <FieldArray name="socio">
            {(arrayHelpers) => (
              <>
                {props.formikCadastro.values.socio &&
                  props.formikCadastro.values.socio.length > 0 ? (
                  props.formikCadastro.values.socio.map((item, index) => (
                    <div className="modal-body-cadastro row mr-0 ml-0">
                      {/* <InputPadrao label='Id' name='id' type='hidden' /> */}

                      <InputSocioAtivos
                        label={index === 0 ? 'Sócio' : undefined}
                        placeholder="Sócio"
                        name={`socio[${index}].executante`}
                        propsFormik={props.formikCadastro}
                        tamanho={'col-sm-12 col-xl-7 col-lg-6 col-md-5 pl-0'}
                        isDisabled={
                          props.formikCadastro.values.verificarAlteracao
                        }
                        {...props}
                        obrigatorio
                        classeTooltip={'tooltip-input-modal'}
                        onChange={(option) =>
                          onChangeSocio(
                            option,
                            props.formikCadastro,
                            arrayHelpers,
                            index,
                          )
                        }
                        isClearable={false}
                      />

                      <InputIntervaloHorario
                        botaoVisivel={false}
                        label={index === 0 ? 'Período' : undefined}
                        placeholder="Período"
                        atualizarOnFocus={true}
                        classeTooltip={'tooltip-input-modal'}
                        name={`socio[${index}].periodo`}
                        propsFormik={props.formikCadastro}
                        tamanho="col-8 col-xl-4 col-lg-4 col-md-5 pr-0 pl-0"
                        obrigatorio
                      />

                      <div className="col-4 col-xl-1 col-lg-2 col-md-2 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right">
                        <BtnAcao
                          type={'button'}
                          icone="icon-New-Trash"
                          texto="Remover"
                          action={() =>
                            removerLinha(
                              arrayHelpers,
                              props.formikCadastro,
                              index,
                            )
                          }
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </>
            )}
          </FieldArray>
        </div>
      </div>
    </>
  )
}

const CadastroRapidoSuspensa = (props) => {
  let { id, idDuplicar } = useParams()
  const user = getUsuario()

  const [salvarENovo, setSalvarENovo] = useState(false)
  const [salvando, setSalvando] = useState(false)
  const [valoresIniciais, setValoresIniciais] = useState({})
  const [modalProcedimentos, setModalProcedimentos] = useState(false)
  const [arrayProcedimentos, setArrayProcedimentos] = useState([])
  const [modalPacientes, setModalPacientes] = useState(false)
  const [arrayPacientes, setArrayPacientes] = useState([])
  const [objPaciente, setObjPaciente] = useState({})
  const [formikProcedimentos, setFormikProcedimentos] = useState({})
  const [refEstabelecimento, setRefEstabelecimento] = useState({})
  const [showModalValidacao, setShowModalValidacao] = useState(false)
  const [showModalInterOcorrencia, setShowModalInterOcorrencia] =
    useState(false)
  const [valoresProcedimento, setValoresProcedimento] = useState(null)
  const [mensagemModal, setMensagemModal] = useState('')
  const [msgValidacao, setMsgValidacao] = useState([])
  const [tituloValidacao, setTituloValidacao] = useState('')
  const [width, height] = useWindowSize()
  const [dataAtual, setDataAtual] = useState(new moment().format('DD/MM/YYYY'))

  const [validacao] = useState(
    Yup.object({
      estabelecimento_saude: Yup.string().nullable().required(),
      data_inicial: Yup.string().nullable().required(),
      hora_inicial: Yup.string()
        .required()
        .test('Hora Inicial', 'Hora inválida!', function (value) {
          return validaHora(this.parent.hora_inicial)
        }),
      hora_final: Yup.string().test(
        'Hora Final',
        'Hora inválida!',
        function (value) {
          return validaHora(this.parent.hora_final)
        },
      ),
      observacao: Yup.string().nullable().max(500),
    }).shape({
      procedimentos: Yup.array().of(
        Yup.object().shape({
          procedimento: Yup.string().nullable(), // these constraints take precedence
          quantidade: Yup.string()
            .nullable()
            .test('Quantidade', 'Quantidade inválida!', function (value) {
              return !this.parent.procedimento
                ? true
                : parseInt(this.parent.quantidade) > 0
            }), // these constraints take precedence
        }),
      ),
    }),
  )

  const manter = [
    'estabelecimento_saude',
    'cirurgiao',
    'data_inicial',
    'executante',
    'empresa',
  ]

  useEffect(() => {
    if (refEstabelecimento.current) {
      refEstabelecimento.current.focus()
    }
  }, [refEstabelecimento])

  useEffect(() => {
    setArrayProcedimentos([])
  }, [props.abrirModal])

  const funcaoAposSim = (formik) => {
    Submit(formik.values, formik)
  }

  const validacaoSubmit = async (values, propsFormik) => {
    setSalvando(true)
    const msg = []

    for (let i = 0; i < values.data_inicial.length; i++) {
      const newValues = { ...values }

      newValues.data_inicial = values.data_inicial[i]

      await validar(
        `/administracao/socio-ocorrencia-verificacao`,
        { modulo: 38, ...newValues },
        null,
        (e) => {
          setTituloValidacao('Há interposição/ocorrência para os sócios:')
          msg.push(
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: e.response.data.alertas[0].replace(/"/g, ''),
                }}
              />
            </>,
          )
        },
      )

      await validar(
        '/administracao/agendaReservaVerificacao',
        newValues,
        null,
        (e) => {
          setTituloValidacao('Há interposição/ocorrência para os sócios:')
          msg.push(
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: e.response.data.alertas[0].replace(/"/g, ''),
                }}
              />
            </>,
          )
        },
      )

      await validar(
        '/administracao/agendaVerificacaoClone',
        { ...newValues },
        null,
        (e) => {
          msg.push(
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: e.response.data.alertas[0].replace(/"/g, ''),
                }}
              />
            </>,
          )
        },
      )
    }

    if (msg.length) {
      msg.push('Deseja continuar?')
      setMsgValidacao(
        msg.map((x) => (
          <>
            {x}
            <br />
          </>
        )),
      )
      setShowModalInterOcorrencia(true)
      setSalvando(false)
    } else Submit(values, propsFormik)
  }

  const Submit = (values, propsFormik) => {
    setSalvando(true)
    setShowModalInterOcorrencia(false)
    let valoresFormatados = { ...values }
    valoresFormatados.procedimentos = valoresFormatados.procedimentos.filter(
      (item) => item.procedimento && item.quantidade,
    )
    salvar(
      valoresFormatados,
      '/administracao/agendareserva',
      () => {
        mostrarToast('sucesso', mensagens.sucesso)
        propsFormik.resetForm({})
        manter.forEach((x) => propsFormik.setFieldValue(x, values[x]))
        propsFormik.setFieldValue('procedimentos', [
          { procedimento: undefined, quantidade: 1 },
        ])
        setArrayProcedimentos([])
        setSalvando(false)
        setSalvarENovo(false)
        refEstabelecimento.current.focus()

        const scrollOffset = window.pageYOffset
        props.funcaoAposSalvar()

        setTimeout(() => {
          window.scrollTo({
            top: scrollOffset,
            left: 0,
            behavior: 'smooth',
          })
        }, 500)
      },
      () => {
        setSalvando(false)
      },
    )
  }

  useEffect(() => {
    setValoresIniciais({
      executante: '',
      equipe_repasse: '',
      empresa: '',
      estabelecimento_saude: '',
      cirurgiao: '',
      data_inicial: '',
      hora_inicial: '',
      observacao: '',
      sequencial: false,
      procedimentos: [{ procedimento: undefined, quantidade: 1 }],
    })
  }, [id])

  const funcaoCancelar = (propsFormik) => {
    propsFormik.setFieldValue('procedimentos', [
      { procedimento: undefined, quantidade: 1 },
    ])
    props.setAbrirModal(false)
  }

  useEffect(() => {
    setValoresIniciais({
      executante: '',
      equipe_repasse: '',
      empresa: '',
      estabelecimento_saude: '',
      cirurgiao: '',
      data_inicial: '',
      hora_inicial: '',
      observacao: '',
      sequencial: false,
      recorrencia: 1,
      procedimentos: [{ procedimento: undefined, quantidade: 1 }],
    })
  }, [id])

  return (
    <>
      {props.abrirModal ? (
        <Formik
          initialValues={valoresIniciais}
          validationSchema={validacao}
          enableReinitialize={true}
          onSubmit={(values, propsFormik) =>
            validacaoSubmit(values, propsFormik)
          }
        >
          {(propsFormik) => (
            <Form>
              <div className="modal-body-cadastro">
                <div className="row ml-0">
                  <div
                    className={
                      width < 1050 && width > 770
                        ? 'col-12'
                        : 'col-lg-7 col-md-6 col-sm-12'
                    }
                  >
                    <InputPadrao
                      label="Id"
                      name="id"
                      type="hidden"
                      placeholder="Id"
                      obrigatorio={true}
                      tamanho=" m-1"
                    />
                    <div className="row">
                      <InputEstabelecimentoSaude
                        setReference={setRefEstabelecimento}
                        label="Estabelecimento Saúde"
                        placeholder="Estabelecimento Saúde"
                        name="estabelecimento_saude"
                        propsFormik={propsFormik}
                        obrigatorio={true}
                        tamanho={
                          width < 1050 && width > 770
                            ? 'col-7'
                            : 'col-lg-8 col-md-12 col-sm-12'
                        }
                        classeTooltip={'tooltip-input-modal'}
                        focarAposSubmit={true}
                        salvarENovo={salvarENovo}
                        ref={refEstabelecimento}
                        autoFocus
                      />
                      <>
                        {id > 0 || id > 0 ? (
                          <InputDatePicker
                            id={'data_inicial'}
                            navigation
                            tamanho={
                              width < 1050 && width > 770
                                ? 'col-5'
                                : 'col-lg-4 col-md-6 col-sm-12'
                            }
                            label="Datssssa Inicial"
                            name="data_inicial"
                            type="text"
                            obrigatorio="true"
                            autoFocus
                            setarValor={(value) => {
                              propsFormik.setFieldValue('data_inicial', value)
                              setDataAtual(value)
                            }}
                            classeTooltip={props.modal && 'tooltip-input-modal'}
                            mostrarFeriado={true}
                          />
                        ) : (
                          <InputDatePickerMultiplo
                            id={'data_inicial'}
                            propsFormik={propsFormik}
                            navigation
                            tamanho={
                              width < 1050 && width > 770
                                ? 'col-5'
                                : 'col-lg-4 col-md-6 col-sm-12'
                            }
                            label="Data Inicial"
                            name="data_inicial"
                            type="text"
                            obrigatorio="true"
                            autoFocus
                            onChange={(data) => {
                              //propsFormik.setFieldValue("data", data);
                              setDataAtual(data[0] || '')
                            }}
                            classeTooltip={props.modal && 'tooltip-input-modal'}
                            mostrarFeriado={true}
                          />
                        )}
                      </>
                      <InputTimePicker
                        label="Hora Inicial"
                        mascara="11:11"
                        name="hora_inicial"
                        type="text"
                        placeholder="Hora Inicial"
                        tamanho={
                          width < 1050 && width > 770
                            ? 'col-3'
                            : 'col-lg-3 col-md-6 col-sm-12'
                        }
                        obrigatorio={true}
                        classeTooltip={props.modal && 'tooltip-input-modal'}
                        setarValor={(value) => {
                          propsFormik.setFieldValue('hora_inicial', value)
                        }}
                      />
                      <InputTimePicker
                        label="Hora Final"
                        mascara="11:11"
                        name="hora_final"
                        type="text"
                        placeholder="Hora Final"
                        tamanho={
                          width < 1050 && width > 770
                            ? 'col-3'
                            : 'col-lg-3 col-md-6 col-sm-12'
                        }
                        classeTooltip={props.modal && 'tooltip-input-modal'}
                        setarValor={(value) => {
                          propsFormik.setFieldValue('hora_final', value)
                        }}
                      />
                      <InputCirurgiaoCombo
                        tamanho={'col-lg-6 col-md-6'}
                        label="Cirurgião"
                        name="cirurgiao"
                        multiplo={false}
                        placeholder="Cirurgião"
                        propsFormik={propsFormik}
                        botaoVisivel={true}
                        modal
                      />

                      {getUsuario().tipo === 4 ? (
                        <></>
                      ) : (
                        <InputSocioAtivosComCores
                          label="Sócio"
                          data_inicial={
                            propsFormik.values &&
                              propsFormik.values.data_inicial &&
                              propsFormik.values.hora_inicial &&
                              propsFormik.values.hora_inicial.indexOf('_') == -1
                              ? propsFormik.values.data_inicial +
                              ' ' +
                              propsFormik.values.hora_inicial
                              : ''
                          }
                          agenda_id={
                            propsFormik.values && propsFormik.values.id
                          }
                          name="executante"
                          propsFormik={propsFormik}
                          tamanho={'col-lg-6 col-md-6'}
                          classeTooltip={props.modal && 'tooltip-input-modal'}
                          salvarENovo={salvarENovo}
                          multiplo
                        />
                      )}
                      <InputSocioAtivos
                        label="Sócio Preferencial"
                        name="preferencia"
                        propsFormik={propsFormik}
                        tamanho={'col-lg-6 col-md-6'}
                        classeTooltip={'tooltip-input-modal'}
                        autoFocus
                        multiplo
                      />

                      <InputSwitch
                        label="Plantão?"
                        propsFormik={propsFormik}
                        tamanho={' '}
                        quebrarLinha
                        classeDiv="form-group col-lg-2 col-md-3 col-sm-6 text-center"
                        name="plantao"
                        size="md"
                        classeTooltip={props.modal && 'tooltip-input-modal'}
                      />

                      <InputSwitch
                        label="Seq?"
                        propsFormik={propsFormik}
                        tamanho={' '}
                        quebrarLinha
                        classeDiv="form-group col-lg-2 col-md-3 col-sm-6 text-center pl-0"
                        name="sequencial"
                        size="md"
                        classeTooltip={props.modal && 'tooltip-input-modal'}
                      />
                      <InputNumero
                        classDiv={'col-lg-2 col-md-6'}
                        label="Recorrência"
                        max={99}
                        name="recorrencia"
                        placeholder="Recorrência"
                        propsFormik={propsFormik}
                      />
                      <TextArea
                        label="Observação"
                        name="observacao"
                        type="text"
                        placeholder="Observação"
                        tamanho={'col-lg-6 col-md-6'}
                        classeTooltip={props.modal && 'tooltip-input-modal'}
                        rows={2}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      'bl-cinza ' +
                      (width < 1050 && width > 770
                        ? 'col-12'
                        : 'col-lg-5 col-md-6 col-sm-12')
                    }
                  >
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <CadastroRapidoProcedimentoV2
                          arrayDados={arrayProcedimentos}
                          setArrayDados={setArrayProcedimentos}
                          abrirModal={modalProcedimentos}
                          setAbrirModal={setModalProcedimentos}
                          abrirCadastro={props.abrirModal}
                          pacientes={arrayPacientes.map((item) => ({
                            value: item.item,
                            label: item.beneficiario.label,
                          }))}
                          chamarModalPaciente={() => setModalPacientes(true)}
                          formikProcedimentos={(formik) =>
                            setFormikProcedimentos(formik)
                          }
                          itemAlterar={valoresProcedimento}
                          propsFormik={propsFormik}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-right container-botoes mb-3 group-salvar-novo mt-2">
                <Atalho formik={propsFormik} salvando={salvando} />
                <BotaoPadrao
                  texto="Salvar"
                  disabled={salvando}
                  click={(_) => { }}
                  idTooltip={'salvar'}
                  classeTooltip="tooltip-plantao-es"
                  class="btn btn-primary-sm primary"
                />
                <BotaoPadrao
                  texto="Cancelar"
                  type="button"
                  disabled={salvando}
                  click={(_) => funcaoCancelar(propsFormik)}
                  idTooltip={'cancelar'}
                  classeTooltip="tooltip-plantao-es"
                  class="btn btn-primary-sm primary"
                />
              </div>
              {/* <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => setSalvando(false)}
                            esconderFechar mensagem={mensagemModal}
                            textoOpcao1={'Cancelar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} /> */}
              <ModalDuasOpcoes
                showModal={showModalInterOcorrencia}
                setShowModal={setShowModalInterOcorrencia}
                funcaoOpcao2={() => Submit(propsFormik.values, propsFormik)}
                funcaoOpcao1={() => {
                  setSalvando(false)
                  setShowModalInterOcorrencia(false)
                }}
                esconderFechar
                mensagem={
                  <>
                    {tituloValidacao ? (
                      <>
                        {tituloValidacao} <br />
                        <br />
                      </>
                    ) : (
                      <></>
                    )}{' '}
                    {msgValidacao}
                    <br />
                  </>
                }
                textoOpcao1={'Cancelar'}
                classeOpcao1={'btn-sm btn-primary-modal simples'}
                textoOpcao2={'Continuar'}
              />
            </Form>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </>
  )
}

const CadastroTrocaSocio = (props) => {
  const [salvando, setSalvando] = useState(false)
  const [valoresIniciais, setValoresIniciais] = useState({})
  const [arrayProcedimentos, setArrayProcedimentos] = useState([])
  const [showModalValidacao, setShowModalValidacao] = useState(false)
  const [mensagemModal, setMensagemModal] = useState('')
  const [alteracao, setAlteracao] = useState(-1)
  const [dados, setDados] = useState([])
  const [adicionarLinhaSocio, setAdicionarLinhaSocio] = useState(false)
  const [remocaoLinhaSocio, setRemocaoLinhaSocio] = useState()
  const [idConfirmaSocio, setIdConfirmaSocio] = useState()
  const [socioName, setSocioName] = useState([])
  const [justificativas, setJustificativas] = useState([])
  const [alteracaoState, setAlteracaoState] = useState(false)
  const [index, setIndex] = useState()
  const [trocaSocio, setTrocaSocio] = useState()
  const [arrayAlteracao, setArrayAlteracao] = useState([])
  const [erro, setErro] = useState(false)
  const [troca, setTroca] = useState(false)
  const [width, height] = useWindowSize()
  const [linhaAdicionada, setLinhaAdicionada] = useState(false)
  const [indexCopia, setIndexCopia] = useState()
  const [todosExcluidos, setTodosExcluidos] = useState(false)
  const [validacao, setValidacao] = useState({})
  const [msgValidacaoOcorrencia, setMsgValidacaoOcorrencia] = useState([])
  const [showModalInterOcorrencia, setShowModalInterOcorrencia] =
    useState(false)
  const [dadosPlantaoEstabelecimento, setDadosPlantaoEstabelecimento] =
    useState([])
  const [carregando, setCarregando] = useState(false)
  const [trocaSocioAtiva, setTrocaSocioAtiva] = useState(false)
  const refLoading = useRef(null)
  const [verificaValidacao, setVerificaValidacao] = useState(false)

  useEffect(() => {
    if (
      justificativas &&
      Object.keys(justificativas).length > 0 &&
      socioName[index] &&
      trocaSocio === alteracao
    )
      setValidacao(
        Yup.object({
          [socioName[index]]: Yup.string().nullable().required(),
          [justificativas[index]]: Yup.string().nullable().required(),
        }),
      )
    else if (
      justificativas &&
      Object.keys(justificativas).length > 0 &&
      trocaSocio === alteracao
    )
      setValidacao(
        Yup.object({
          [justificativas[index]]: Yup.string().nullable().required(),
        }),
      )
    else if (socioName[index])
      setValidacao(
        Yup.object({
          [socioName[index]]: Yup.string().nullable().required(),
          // [justificativas[alteracao]]: Yup.string().nullable().required(),
        }),
      )
    else setValidacao(Yup.object({}))
  }, [socioName, index, justificativas, alteracao])

  const validacaoInterposicaoOcorrencia = async (
    values,
    propsFormik,
    validacao,
  ) => {
    let id = parseInt(props.item.id)
    let modulo = parseInt(props.modulo_id)
    var objString = props.item.executantes_v2
    var arrayDadosExecutantes = new Function('return [' + objString + '];')()
    let executanteTroca = []
    const msg = []

    arrayDadosExecutantes = ordenarMultiplo(arrayDadosExecutantes, [
      {
        coluna: 'agenda_reserva_executante_id',
        ordenamento: { tipo: 'numero', modo: 'asc' },
      },
    ])

    dados &&
      dados.map((z, i) => {
        if (z.justificativa)
          executanteTroca.push({
            idAtual: parseInt(z.id),
            idAnterior: parseInt(arrayDadosExecutantes[i].id),
            justificativa: z.justificativa,
          })
      })

    if (id) {
      if (modulo === 53) {
        // PLANTÃO ESTABELECIMENTO
        await Promise.resolve(
          get(`/administracao/plantaoestabelecimento/${id}`),
        ).then(async (obj) => {
          for (let i in obj.socio) {
            for (let j in executanteTroca) {
              if (
                parseInt(executanteTroca[j].idAnterior) ===
                obj.socio[i].executante.value
              ) {
                obj.socio[i].executante.value = executanteTroca[j].idAtual
                obj.socio[i].executante.label =
                  dados[i].codigo + '-' + dados[i].descricao
                values.troca[j].periodo = obj.socio[i].periodo
                values.troca[j].ordem = obj.socio[i].ordem
              }
            }
          }
          let copiaDados = [...dados]
          for (let i in copiaDados)
            if (copiaDados[i].new) {
              copiaDados[i].periodo = obj.periodo
              if (obj.socio)
                obj.socio.push({
                  executante: {
                    value: parseInt(copiaDados[i].id),
                    label:
                      copiaDados[i].codigo + ' - ' + copiaDados[i].descricao,
                  },
                  periodo: obj.periodo,
                })
              else {
                let socio = []
                socio.push({
                  executante: {
                    value: parseInt(copiaDados[i].id),
                    label:
                      copiaDados[i].codigo + ' - ' + copiaDados[i].descricao,
                  },
                  periodo: obj.periodo,
                })
                obj.socio = socio
              }
            }
          let sociosInsercao =
            obj &&
            obj.socio &&
            obj.socio.filter(
              (x) =>
                values.executante.filter(
                  (y) => parseInt(x.executante.value) === y.value,
                ).length > 0,
            )

          obj.executantes = obj.socio
          obj.modulo = modulo
          obj.troca = values.troca
          values.data = obj.data
          values.modulo = obj.modulo
          values.periodo = obj.periodo
          values.socio = obj.socio
          values.executante = sociosInsercao
          delete obj.socio

          await validar(
            `/administracao/verificacao-socio-ocorrencia-estabelecimento`,
            obj,
            null,
            (e) => {
              const alertas = e.response.data.alertas
              msg.push(alertas)
              // setMsgValidacaoOcorrencia(<><div dangerouslySetInnerHTML={{ __html: "Há interposição para os sócios: " + alertas[0].replace(/"/g, '') }} /></>)
              // setShowModalInterOcorrencia(true)
              // setSalvando(false)
            },
          )
        })
        return [msg, values]
      }
      // else if (modulo === 49) {//PLANTÃO ALCANÇÁVEIS
      //     Promise.resolve(get(`/administracao/plantaoAlcancavel/${id}`)).then(obj => {

      //         for (let i in obj.socio) {
      //             for (let j in executanteTroca) {
      //                 if (parseInt(executanteTroca[j].idAnterior) === obj.socio[i].value) {
      //                     obj.socio[i].value = executanteTroca[j].idAtual
      //                     obj.socio[i].label = dados[i].codigo + '-' + dados[i].descricao
      //                 }
      //             }
      //         }

      //         obj.modulo = modulo
      //         values.modulo = modulo
      //         values.periodo = obj.periodo
      //         values.socio = obj.socio

      //         validar(`/administracao/verificacao-socio-ocorrencia`, obj , () => { setMsgValidacaoOcorrencia([]); Submit(values, propsFormik) }, (e) => {
      //             const alertas = e.response.data.alertas;
      //             setMsgValidacaoOcorrencia(<><div dangerouslySetInnerHTML={{ __html: alertas[0].replace(/"/g, '') }} /></>)
      //             setShowModalInterOcorrencia(true)
      //             setSalvando(false)
      //         })
      //     })
      // }
    }
  }
  useEffect(() => {
    let copiaDados = [...dados]
    if (
      dadosPlantaoEstabelecimento.socio &&
      dadosPlantaoEstabelecimento.socio.length > 0
    ) {
      for (let i in dadosPlantaoEstabelecimento.socio) {
        for (let j in dados) {
          if (
            dadosPlantaoEstabelecimento.socio[i].executante.value ===
            parseInt(dados[j].id)
          ) {
            if (
              dadosPlantaoEstabelecimento.socio[i].periodo &&
              dadosPlantaoEstabelecimento.socio[i].periodo.label
            ) {
              copiaDados[j].periodo =
                dadosPlantaoEstabelecimento.socio[i].periodo
              copiaDados[j].data_final =
                dadosPlantaoEstabelecimento.data_final

            }
          }
        }
      }
      setDados(copiaDados)
    }
  }, [dadosPlantaoEstabelecimento])

  useEffect(() => {
    let id = parseInt(props.item && props.item.id)
    Promise.resolve(get(`/administracao/plantaoestabelecimento/${id}`)).then(
      (obj) => {
        setDadosPlantaoEstabelecimento(obj)
      },
    )
  }, [props.item])

  const validarSubmit = async (values, resetForm, nValidacao) => {
    const msg = []

    await validarPermissaoTroca(dadosPlantaoEstabelecimento).then(_ => { })
      .catch(e => {
        mostrarToast('erro', e.response.data.alertas)
        throw e
      })

    if (dados.length === 1 && dados[0].id === '')
      return mostrarToast('erro', 'Troca Sócio não pode ser vazia')

    if (
      troca === true &&
      linhaAdicionada === true &&
      alteracao === -1 &&
      indexCopia &&
      dados[indexCopia].id === ''
    )
      return mostrarToast('erro', 'Por favor, confirme a troca antes de salvar')

    if (troca === false && alteracao !== -1)
      return mostrarToast('erro', 'Por favor, confirme a troca antes de salvar')

    if (dados[0].id === null)
      return mostrarToast('erro', 'Troca Sócio não pode ser vazia')

    if (alteracao !== -1 && troca === true)
      return mostrarToast('erro', 'Por favor, confirme a troca antes de salvar')
    else if (dados[0].id !== null) {
      var objString = props.item.executantes_v2
      var arrayDadosExecutantes = new Function('return [' + objString + '];')()
      arrayDadosExecutantes = ordenarMultiplo(arrayDadosExecutantes, [
        {
          coluna: 'agenda_reserva_executante_id',
          ordenamento: { tipo: 'numero', modo: 'asc' },
        },
      ])

      var socioTroca = {}
      let executante = []
      let executanteManterOrdem = []
      let executanteTroca = []
      dados.map((x) => {
        executante.push({
          value: parseInt(x.id),
          label: x.descricao,
        })
      })
      dados.map((x) => {
        executanteManterOrdem.push({
          value: parseInt(x.id),
          label: x.descricao,
        })
      })

      dados &&
        dados.map((z, i) => {
          if (z.justificativa)
            executanteTroca.push({
              idAtual: parseInt(z.id),
              idAnterior: parseInt(arrayDadosExecutantes[i].id),
              justificativa: z.justificativa,
            })
        })

      let socioPlantaoAlcancavel = []

      for (let y in executanteManterOrdem)
        for (let i in dadosPlantaoEstabelecimento.socio) {
          for (let j in executanteTroca) {
            if (
              dadosPlantaoEstabelecimento.socio[i].executante.value ===
              executanteTroca[j].idAnterior
            ) {
              if (
                executanteTroca[j].idAtual === executanteManterOrdem[y].value
              ) {
                executanteManterOrdem[y].ordem =
                  dadosPlantaoEstabelecimento.socio[i].ordem
              }
            }
          }
        }

      socioTroca.data_inicial = props.item.data_inicial
      socioTroca.data_final = props.item.data_final
      socioTroca.hora_final = props.item.hora_final
      socioTroca.hora_inicial = props.item.hora_inicial
      socioTroca.recorrencia = props.item.recorrencia
      socioTroca.id = props.item.id
      socioTroca.executante = executante
      socioTroca.socioAlcancavel = executanteManterOrdem
      socioTroca.troca = executanteTroca
      setSalvando(true)
      // validar('/administracao/plantaoReservaVerificacao', socioTroca, () => { }, (e) => {
      //     setShowModalValidacao(true)
      //     setMensagemModal(e.response.data.alertas[0])
      //     setSalvando(false)
      // })

      if (parseInt(props.modulo_id) === 49) {
        var objSocio = []
        var objPeriodo = []
        Promise.resolve(
          get(`/administracao/plantaoAlcancavel/${props.item.id}`),
        ).then((obj) => {
          objSocio = obj.socio
          objPeriodo = obj.periodo
          // for (let i in obj.socio) {
          //     for (let j in executanteTroca) {
          //         if (parseInt(executanteTroca[j].idAnterior) === obj.socio[i].value) {
          //             obj.socio[i].value = executanteTroca[j].idAtual
          //             obj.socio[i].label = dados[i].codigo + '-' + dados[i].descricao
          //         }
          //     }
          // }

          // obj.modulo = modulo
          // values.modulo = modulo
          // values.periodo = obj.periodo
          // values.socio = obj.socio
          // validar(`/administracao/socio-ocorrencia-verificacao`, obj, () => { setMsgValidacaoOcorrencia([]); Submit(values, propsFormik) }, (e) => {
          //     const alertas = e.response.data.alertas;
          //     setMsgValidacaoOcorrencia(<><div dangerouslySetInnerHTML={{ __html: alertas[0].replace(/"/g, '') }} /></>)
          //     setShowModalInterOcorrencia(true)
          //     setSalvando(false)
          // })
        })

        var objValidacao = {
          id: props.item.id,
          data: socioTroca.data_inicial,
          socio: socioTroca.executante,
          periodo: {
            value: props.itemAcao.periodo,
          },
          recorrencia: (values.recorrencia && values.recorrencia.value) || 0,
        }
        for (let i in objSocio) {
          for (let j in executanteTroca) {
            if (parseInt(executanteTroca[j].idAnterior) === objSocio[i].value) {
              objSocio[i].value = executanteTroca[j].idAtual
              objSocio[i].label = dados[i].codigo + '-' + dados[i].descricao
            }
          }
        }

        // obj.modulo = modulo
        socioTroca.modulo = 49
        socioTroca.periodo = objPeriodo
        socioTroca.periodoInsercao = props.itemAcao.periodo
        socioTroca.socio = objSocio
        socioTroca.ordem = props.itemAcao.ordem
        if (!nValidacao)
          await validarComPost(
            `/administracao/regrasnegocioplantao`,
            { modulo: 49, ...objValidacao },
            null,
            (e) => {
              if (
                e.response &&
                e.response.data &&
                e.response.data.alertas &&
                e.response.data.alertas[0]
              )
                msg.push(
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          e.response &&
                          e.response.data &&
                          e.response.data.alertas &&
                          e.response.data.alertas[0]
                            .replace(/"/g, '')
                            .replace(/\\/g, '')
                            .replace('Error: ', ''),
                      }}
                    />
                  </>,
                )
            },
          )

        if (msg.length) {
          msg.push('Deseja continuar?')
          setMsgValidacaoOcorrencia(
            msg.map((x) => (
              <>
                {x}
                <br />
              </>
            )),
          )
          setShowModalInterOcorrencia(true)
          setSalvando(false)
        } else {
          Submit(socioTroca, resetForm)
        }
        // Promise.resolve(get(`/administracao/plantaoAlcancavel/${props.item.id}`)).then(obj => {
        // Promise.resolve(get(`/administracao/ajustaParaValidacaoInterposicaoPlantao`, { id: props.item.id || 0, data_inicial: socioTroca.data_inicial, socio: socioTroca.executante, periodo: props.itemAcao.periodo, recorrencia: values.recorrencia && values.recorrencia.value || 0 })).then(interposicao => {
        //     const msg = []

        //     // if (socioTroca.troca.length === 0 && socioTroca.executante.length === obj.socio.length) {
        //     //     setSalvando(false)
        //     //     return mostrarToast('erro', 'Por favor, faça ao menos uma troca para salvar')
        //     // }
        //     console.log('INTERPOSICAO ', interposicao)
        //     if (interposicao && interposicao.length) {
        //         const distinct = getUnique(interposicao, 'socio_id')

        //         msg.push('Sócio(s): ')
        //         msg.push(distinct.map(x => x.socio))
        //         msg.push('já escalado(s) neste período.')
        //     }

        //     if (msg.length) {
        //         setMensagemModal(msg)
        //         setShowModalValidacao(true)
        //         // setShowModalValidacaoOrdem(true)
        //     }
        //     else {
        //         setMensagemModal([])
        //         validacaoInterposicaoOcorrencia(socioTroca, resetForm)

        //         // Submit(values, propsFormik, true)
        //     }
        // })
        // })
      }
      if (parseInt(props.modulo_id) === 53) {
        let dadosEstabelecimentoOrdenado = ordenarMultiplo(
          dadosPlantaoEstabelecimento.socio,
          [{ coluna: 'ordem', ordenamento: { tipo: 'numero', modo: 'asc' } }],
        )
        let sociosDiscrepantes
        let socioInserir = []

        for (let i in dadosEstabelecimentoOrdenado) {
          for (let j in socioTroca.troca) {
            if (
              dadosEstabelecimentoOrdenado[i].executante.value ===
              socioTroca.troca[j].idAnterior
            )
              socioInserir.push({
                executante: { value: socioTroca.troca[j].idAtual },
                periodo: dadosEstabelecimentoOrdenado[i].periodo,
              })
          }
        }
        if (dadosPlantaoEstabelecimento.socio)
          sociosDiscrepantes = dados.filter(
            (x) =>
              dadosPlantaoEstabelecimento.socio.filter(
                (y) => parseInt(x.id) === y.executante.value,
              ).length === 0,
          )

        if (socioInserir.length > 0) {
          for (let i in socioInserir) {
            for (let j in sociosDiscrepantes) {
              if (socioInserir[i].executante.value !== sociosDiscrepantes[j].id)
                socioInserir.push({
                  executante: { value: sociosDiscrepantes[j].id },
                  periodo: dadosPlantaoEstabelecimento.periodo,
                })
            }
          }
        } else {
          if (sociosDiscrepantes) {
            socioInserir = sociosDiscrepantes
            for (let i in socioInserir) {
              socioInserir[i] = {
                executante: { value: socioInserir[i].id },
                periodo: dadosPlantaoEstabelecimento.periodo,
              }
            }
          }
          if (!dadosPlantaoEstabelecimento.socio) {
            // CASO NÃO TENHA NENHUM SÓCIO CADASTRADO NO PLANTÃO
            for (let i in dados) {
              socioInserir[i] = {
                executante: {
                  value: dados[i].id,
                  label: dados[i].codigo + ' - ' + dados[i].descricao,
                },
                periodo: dadosPlantaoEstabelecimento.periodo,
                ordem: i,
              }
            }
          }
        }
        // if (socioInserir.length === 0) {
        //     setSalvando(false)
        //     return mostrarToast('erro', 'Por favor, faça ao menos uma troca para salvar')
        // }
        socioTroca.socioInserir = socioInserir

        // Promise.resolve(get(`/administracao/plantaoReservaVerificacao`, { id: props.item.id, modulo: 53, executantes: dadosEstabelecimentoOrdenado, data: socioTroca.data_inicial })).then(interposicao => {
        // if(nValidacao)
        const retornoValidacao = await validacaoInterposicaoOcorrencia(
          socioTroca,
          resetForm,
          nValidacao,
        )

        if (!nValidacao) {
          await validar(
            '/administracao/plantaoReservaVerificacao',
            {
              id: props.item.id,
              modulo: 53,
              socio: socioInserir,
              data: socioTroca.data_inicial,
            },
            null,
            (e) => {
              const alertas = e.response.data.alertas
              msg.push(alertas[1])
              // setMsgValidacaoOcorrencia(<>{alertas[0]}<br /><div dangerouslySetInnerHTML={{ __html: alertas[1] }} /><br />{alertas[2]}</>)
              // setShowModalInterOcorrencia(true)
              // setSalvando(false)
            },
          )

          if (retornoValidacao && retornoValidacao[0].length) {
            msg.push(retornoValidacao[0])
          }
        }

        if (msg.length) {
          setMsgValidacaoOcorrencia(
            <>
              {'Há interposições/ocorrência para os sócios: '}
              <br />
              <div dangerouslySetInnerHTML={{ __html: msg }} />
              <br />
              {'Deseja continuar ?'}
            </>,
          )
          setShowModalInterOcorrencia(true)
        } else Submit(retornoValidacao[1], resetForm)
      }
    }

    //  Submit(socioTroca, propsFormik)
  }

  useEffect(() => {
    if (adicionarLinhaSocio === true) {
      let copiaDados = dados
      copiaDados.push({ descricao: '', id: '', new: true })
      setDados(copiaDados)
      setIndex(dados.length - 1)
      setAdicionarLinhaSocio(false)
    }
    if (remocaoLinhaSocio !== undefined) {
      let copiaDados = dados
      if (dados.length === 1)
        mostrarToast('erro', 'Troca Sócio não pode ser vazia')
      else copiaDados.splice(remocaoLinhaSocio, 1)

      setDados(copiaDados)
      setJustificativas()
      setIndexCopia()
      setLinhaAdicionada(false)
      setAlteracao(-1)
      setRemocaoLinhaSocio()
    }

    let socioObj = {}
    let sociosKeys = []

    dados[0] !== undefined &&
      dados.map((x, i) => {
        socioObj['socio-' + i] = dados[i]
        sociosKeys = Object.keys(socioObj)
      })
    setSocioName(sociosKeys)
  }, [dados, remocaoLinhaSocio, adicionarLinhaSocio])

  const funcaoAposSim = (formik) => {
    var objString = props.item.executantes_v2
    var arrayDadosExecutantes = new Function('return [' + objString + '];')()
    arrayDadosExecutantes = ordenarMultiplo(arrayDadosExecutantes, [
      {
        coluna: 'agenda_reserva_executante_id',
        ordenamento: { tipo: 'numero', modo: 'asc' },
      },
    ])
    var socioTroca = {}
    let executante = []
    let executanteTroca = []
    dados.map((x) => {
      executante.push({
        value: parseInt(x.id),
        label: x.descricao,
      })
    })

    dados &&
      dados.map((z, i) => {
        if (z.justificativa)
          executanteTroca.push({
            idAtual: parseInt(z.id),
            idAnterior: parseInt(arrayDadosExecutantes[i].id),
            justificativa: z.justificativa,
          })
      })

    socioTroca.data_inicial = props.item.data_inicial
    socioTroca.data_final = props.item.data_final
    socioTroca.hora_final = props.item.hora_final
    socioTroca.hora_inicial = props.item.hora_inicial
    socioTroca.recorrencia = props.item.recorrencia
    socioTroca.id = props.item.id
    socioTroca.executante = executante
    socioTroca.troca = executanteTroca

    validacaoInterposicaoOcorrencia(socioTroca, formik)
  }

  const Submit = (values, propsFormik) => {
    setSalvando(true)
    if (props.modulo_id) {
      putComAcao(
        ACOES.TROCAR_SOCIO,
        props.modulo_id,
        values,
        '/administracao/agendareservasociojustificativa',
        () => {
          mostrarToast('sucesso', mensagens.sucesso)
          setSalvando(false)
          props.funcaoAposSalvar()
        },
        (erro) => {
          mostrarToast('erro', mensagens.erro)
          setSalvando(false)
        },
      )
    } else {
      salvar(
        values,
        '/administracao/agendareservasociojustificativa',
        () => {
          mostrarToast('sucesso', mensagens.sucesso)
          setSalvando(false)
          props.funcaoAposSalvar()
        },
        (erro) => {
          mostrarToast('erro', mensagens.erro)
          setSalvando(false)
        },
      )
    }
  }

  const onChange = (e, i, values) => {
    let copiaJustificativaKeys = justificativas

    if (dados[i].status_id) {
      setTrocaSocio(i)
    }

    document.getElementsByName('justificativa-' + alteracao)[0] &&
      document.getElementsByName('justificativa-' + alteracao)[0].focus()

    setJustificativas(copiaJustificativaKeys)
    let index =
      dados.findIndex((x) => x.id === '') === -1
        ? alteracao
        : dados.findIndex((x) => x.id === '')

    setTrocaSocioAtiva(true)
    setIndex(index)
    setTroca(true)
  }

  const Delete = (index) => {
    let copiaArrayDados = [...arrayProcedimentos]
    copiaArrayDados.splice(index, 1)
    setArrayProcedimentos(copiaArrayDados)
  }

  useEffect(() => {
    // setCarregando(true)

    setAlteracao(-1)
    setLinhaAdicionada(false)
    setTroca(false)
    setValoresIniciais([])

    if (props.abrirModal) {
      var objString = props.item.executantes_v2
      var arrayObjString = new Function('return [' + objString + '];')()
      arrayObjString = ordenarMultiplo(arrayObjString, [
        {
          coluna: 'agenda_reserva_executante_id',
          ordenamento: { tipo: 'numero', modo: 'asc' },
        },
      ])

      setDados(arrayObjString)
    }

    // setInterval(() => {
    //     setCarregando(false)

    // }, 2000)
  }, [props.item, props.itemAcao])

  useEffect(() => {
    if (!props.abrirModal) {
      setDados([])
      props.setItemAcao([])
    }
  }, [props.abrirModal])

  const confirmaSocio = (val, valSoc, i) => {
    var objString = props.item.executantes_v2

    var socioAnterior = new Function('return [' + objString + '];')()
    socioAnterior = ordenarMultiplo(socioAnterior, [
      {
        coluna: 'agenda_reserva_executante_id',
        ordenamento: { tipo: 'numero', modo: 'asc' },
      },
    ])

    var objSocio
    valSoc = valSoc && valSoc[0] !== undefined ? valSoc[0] : valSoc

    if (valSoc && i !== alteracao) {
      objSocio = {
        codigo: valSoc.label.split('-')[0],
        descricao: valSoc.label.split('-')[1],
        new: true,
        id: valSoc.value,
      }
      setTodosExcluidos(false)
    } else if (i === alteracao && valSoc) {
      objSocio = {
        codigo: valSoc.label.split('-')[0],
        descricao: valSoc.label.split('-')[1],
        id: valSoc.value,
      }
    } else if (i === alteracao && !valSoc) {
      objSocio = {
        codigo: '',
        descricao: '',
        id: '',
      }
    } else if (dados[0].id === null && !valSoc) {
      //condicao caso todos os dados tenham sido excluídos
      objSocio = {
        codigo: null,
        descricao: null,
        id: null,
        new: true,
      }
    } else {
      objSocio = {
        codigo: '',
        descricao: '',
        new: true,
        id: '',
      }
    }

    let copiaDados = [...dados]
    let erroSocioIgual = false
    var verificaSocioPresenteTroca = dados.filter(
      (x) => parseInt(x.id) === parseInt(valSoc && valSoc.value),
    )

    // if (!trocaSocioAtiva) {
    //     return mostrarToast('erro', 'Por favor, trocar sócio para confirmação')
    // }
    // else {
    //     setTrocaSocioAtiva(false)
    // }

    if (
      verificaSocioPresenteTroca.length > 0 &&
      valSoc &&
      valSoc.value !== parseInt(dados[i].id) &&
      troca
    ) {
      erroSocioIgual = true
      mostrarToast(
        'erro',
        `Sócio ${upperCase(
          verificaSocioPresenteTroca[0].descricao,
        )} já presente neste Plantão`,
      )
      return
    }

    if (valSoc && valSoc.value === parseInt(dados[i].id)) {
      mostrarToast('erro', 'Trocar sócio atual por sócio diferente')
      erroSocioIgual = true
      return
    }
    if ((valSoc && valSoc.value === '') || val === '') {
      if (!val) mostrarToast('erro', 'Campo Justificativa é obrigatório')
      if (!valSoc) mostrarToast('erro', `Por favor, insira algum sócio`)

      erroSocioIgual = true
      return
    } else {
      copiaDados[i] = objSocio
      copiaDados[i].justificativa = val
      setDados(copiaDados)
    }

    if (valSoc && troca === true && val && !erroSocioIgual) {
      setTroca(false)
      setAlteracao(-1)
    }

    if (!val && troca === false && !erroSocioIgual) {
      setAlteracao(-1)
    }

    if (val && dados[i].id !== '' && !erroSocioIgual) {
      setAlteracao(-1)
      setTroca(false)
    }
    if (!val && todosExcluidos === true && !erroSocioIgual) {
      setTroca(false)
      setAlteracao(-1)
      setTodosExcluidos(true)
    }

    if (
      (dados.length === 1 && dados[0].id === null) ||
      (dados[i].id === '' &&
        linhaAdicionada === false &&
        dados.length === 1 &&
        valSoc === undefined)
    ) {
      mostrarToast('erro', `Por favor, insira algum sócio`)
    }
    // else if (dados[i].id === '' && linhaAdicionada === false) {
    //     mostrarToast('erro', `Por favor, insira um sócio para troca`)
    // }
    else if (
      troca === true &&
      linhaAdicionada === false &&
      todosExcluidos === false &&
      !val &&
      !erroSocioIgual &&
      dados.length > 1 &&
      dados[0].id !== ''
    ) {
      mostrarToast(
        'erro',
        `Justificativa obrigatória referente a seguinte troca: "${upperCase(
          socioAnterior[i].descricao,
        )}" por "${dados[i].descricao}"`,
      )
    } else if (
      linhaAdicionada === true &&
      dados[indexCopia].descricao === '' &&
      linhaAdicionada === true &&
      valSoc === undefined
    ) {
      mostrarToast('erro', `Insira um sócio para prosseguir`)
    } else if (
      (linhaAdicionada === true &&
        dados[indexCopia].descricao !== '' &&
        troca === true) ||
      (linhaAdicionada === true && valSoc !== undefined)
    ) {
      setIndexCopia()
      setLinhaAdicionada(false)
      setTroca(false)
      setAlteracao(-1)
    }
  }

  useEffect(() => {
    let socioObj = {}
    let sociosKeys = []
    let justificativaObj = {}
    let justificativaKeys = {}

    dados[0] !== undefined &&
      dados.map((x, i) => {
        socioObj['socio-' + i] = dados[i]

        sociosKeys = Object.keys(socioObj)
      })
    dados[0] !== undefined &&
      dados
        .filter((x) => !x.new)
        .map((y, i) => {
          justificativaObj['justificativa-' + i] = y
          justificativaKeys = Object.keys(justificativaObj)
        })

    setSocioName(sociosKeys)
    setJustificativas(justificativaKeys)
    if (dados[alteracao] && dados[alteracao].id !== '') {
      setValoresIniciais({
        [sociosKeys[alteracao]]: [
          {
            value: dados[alteracao].id,
            label: dados[alteracao].codigo + ' - ' + dados[alteracao].descricao,
          },
        ],
        [justificativaKeys[alteracao]]: dados[alteracao].justificativa
          ? dados[alteracao].justificativa
          : '',
      })
    }
    if (dados[0] && dados[0].id === null) {
      setValoresIniciais({
        [sociosKeys[0]]: null,
      })
    }
  }, [dados, alteracao, idConfirmaSocio, remocaoLinhaSocio])

  const adicionaAlteracao = (linha) => {
    let copiaArrayAlteracao = arrayAlteracao

    copiaArrayAlteracao.push(linha)
    setArrayAlteracao(copiaArrayAlteracao)
  }

  const passFormikValue = (val) => {
    if (val === '' || val === undefined) setErro(true)
    else setErro(false)
  }

  const pularPara = (id, linha, valorSalto) => {
    var element = document.getElementById(id)
    valorSalto = valorSalto && valorSalto !== -1 ? valorSalto : 150
    setTimeout(() => {
      element.scrollTop = element.scrollHeight + valorSalto
    }, 100)

    document.getElementsByName('socio-' + linha)[0] &&
      document.getElementsByName('socio-' + linha)[0].focus()
  }

  const desfazAlteracaoLinha = (linha) => {
    let socioObj = {}
    let sociosKeys = []

    // let objString = props.item.executantes_v2
    // let arrayDadosAntigos = (new Function("return [" + objString + "];")());
    // arrayDadosAntigos = ordenarMultiplo(arrayDadosAntigos, [{ coluna: 'agenda_reserva_executante_id', ordenamento: { tipo: 'numero', modo: 'asc' } }])
    // let copiaDados = dados

    // copiaDados[linha].descricao = arrayDadosAntigos[linha].descricao
    // copiaDados[linha].codigo = arrayDadosAntigos[linha].codigo
    // setDados(copiaDados)

    dados[0] !== undefined &&
      dados.map((x, i) => {
        socioObj['socio-' + i] = dados[i]

        sociosKeys = Object.keys(socioObj)
      })

    setValoresIniciais({
      [sociosKeys[linha]]: [
        {
          value: dados[linha].id,
          label: dados[linha].codigo + ' - ' + dados[linha].descricao,
        },
      ],
      // [justificativaKeys[alteracao]]: dados[alteracao].justificativa ? dados[alteracao].justificativa : ''
    })
  }

  const validarPermissaoTroca = async (linha) => {
    linha = Array.isArray(linha) ? linha[0] : linha
    if (linha)
      return await Promise.resolve(get('/administracao/permissaoTrocarSocioSemRestricao', { usuario: getUsuario(), acao: ACOES.TROCAR_SOCIO_SEM_RESTRICAO, periodo_id: linha.periodo?.value, modulo: props.modulo_id, data: linha.data }, true))
        .then(result => result)
        .catch(err => { throw err })
  }


  const verificaConfirmacao = (valJustificativa, valSocio) => {
    if (
      (troca === false && alteracao !== -1 && valJustificativa) ||
      (troca === false && alteracao !== -1 && !valSocio) ||
      (linhaAdicionada === true && troca === false) ||
      (alteracao !== -1 && troca === true && !valJustificativa) ||
      (troca === true && alteracao !== -1 && !valSocio) ||
      (troca === false && alteracao !== -1 && !valJustificativa) ||
      (troca === false && alteracao !== -1 && !valSocio) ||
      (troca === false && alteracao !== -1 && !valSocio && !valJustificativa)
    )
      mostrarToast('erro', 'Por favor, confirme a troca antes de salvar')

  }

  const desfazerAlteracoes = (_) => {
    let objString = props.item.executantes_v2
    let arrayDadosAntigos = new Function('return [' + objString + '];')()
    arrayDadosAntigos = ordenarMultiplo(arrayDadosAntigos, [
      {
        coluna: 'agenda_reserva_executante_id',
        ordenamento: { tipo: 'numero', modo: 'asc' },
      },
    ])
    setDados(arrayDadosAntigos)
    setIndexCopia()
    setLinhaAdicionada(false)
    setTroca(false)
  }

  return (
    <>
      {props.abrirModal ? (
        <Formik
          initialValues={valoresIniciais}
          validationSchema={validacao}
          enableReinitialize={true}
          onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}
        >
          {(propsFormik) => (
            <Form>
              {width > 1000 ? (
                <>
                  <div className="modal-body-cadastro">
                    <div className="row ml-0" ref={refLoading}>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        {carregando ? (
                          <LoadRelatorio refLoading={refLoading} heigth={150} />
                        ) : (
                          <>
                            <InputPadrao
                              label="Id"
                              name="id"
                              type="hidden"
                              placeholder="Id"
                              obrigatorio={true}
                              tamanho=" m-1"
                            />
                            <div className="card mt-2">
                              <div
                                className="card-troca-socio socio-float custom-scrollbar"
                                id="modal-suspenso"
                              >
                                <table>
                                  {dados !== null && dados[0] !== undefined && (
                                    <>
                                      <thead>
                                        <tr>
                                          <th className="">Período</th>
                                          <th className="">Sócio(s)</th>
                                          <th className="">Justificativa</th>
                                          <th className="text-center">Ações</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {dados.map((item, i) => (
                                          <>
                                            <tr key={i}>
                                              {parseInt(props.modulo_id) !==
                                                53 ||
                                                (item && !item.periodo) ? (
                                                <td
                                                  className=""
                                                  style={{ width: '8%' }}
                                                >
                                                  {props.item.hora_inicial +
                                                    ' - ' +
                                                    props.item.hora_final}
                                                </td>
                                              ) : (
                                                <td
                                                  className=""
                                                  style={{ width: '8%' }}
                                                >
                                                  {item.periodo.label}
                                                </td>
                                              )}
                                              {(alteracao !== i &&
                                                item.descricao !== '' &&
                                                item.descricao !== null) ||
                                                (linhaAdicionada === false &&
                                                  indexCopia === i) ? (
                                                <td
                                                  style={{ width: '30%' }}
                                                  className=""
                                                >
                                                  {item.codigo +
                                                    ' - ' +
                                                    item.descricao}
                                                </td>
                                              ) : (erro === true &&
                                                alteracao === i) ||
                                                item.descricao === null ? (
                                                <td
                                                  style={{ width: '30%' }}
                                                  className=""
                                                >
                                                  <InputSocioAtivos
                                                    id={`socio-${i}`}
                                                    name={`socio-${i}`}
                                                    placeholder="Sócio"
                                                    onChange={(option) => {
                                                      propsFormik.setFieldValue(
                                                        'socio-' + i,
                                                        option,
                                                      )
                                                      onChange &&
                                                        onChange(
                                                          option,
                                                          i,
                                                          propsFormik.values,
                                                        )
                                                    }}
                                                    propsFormik={propsFormik}
                                                    tamanho={'p-0 m-0'}
                                                  />
                                                </td>
                                              ) : (
                                                <td
                                                  style={{ width: '30%' }}
                                                  className=""
                                                >
                                                  <InputSocioAtivos
                                                    name={`socio-${i}`}
                                                    placeholder="Sócio"
                                                    onChange={(option) => {
                                                      propsFormik.setFieldValue(
                                                        'socio-' + i,
                                                        option,
                                                      )
                                                      onChange &&
                                                        onChange(
                                                          option,
                                                          i,
                                                          propsFormik.values,
                                                        )
                                                    }}
                                                    propsFormik={propsFormik}
                                                    tamanho={'p-0 m-0'}
                                                  />
                                                </td>
                                              )}
                                              {(alteracao !== i &&
                                                item.new === undefined) ||
                                                item.descricao === null ? (
                                                <td
                                                  style={{ width: '53%' }}
                                                  className=""
                                                >
                                                  {reduzTexto(
                                                    item.justificativa,
                                                    150,
                                                  )}
                                                </td>
                                              ) : item.new !== undefined ? (
                                                <td
                                                  style={{ width: '53%' }}
                                                  className=""
                                                >
                                                  {''}
                                                </td>
                                              ) : (
                                                <td
                                                  className=""
                                                  style={{ width: '53%' }}
                                                >
                                                  {
                                                    <InputPadrao
                                                      id="justificativa"
                                                      tamanho={'p-0 m-0'}
                                                      name={`justificativa-${i}`}
                                                      style={{}}
                                                      placeholder="Justificativa"
                                                      propsFormik={propsFormik}
                                                    />
                                                  }
                                                </td>
                                              )}

                                              <td
                                                style={{
                                                  display: 'flex',
                                                  marginTop: '15px',
                                                  textAlign: 'right',
                                                  justifyContent: 'flex-end',
                                                }}
                                                className=""
                                              >
                                                {(alteracao === -1 ||
                                                  alteracaoState === false) &&
                                                  linhaAdicionada === false &&
                                                  dados[0].id !== null &&
                                                  dados.length > 0 &&
                                                  dados[0].id !== '' ? (
                                                  <BtnAcao
                                                    id={Math.random()}
                                                    type="button"
                                                    classTooltip={
                                                      'tooltip-input-modal'
                                                    }
                                                    classeContent="ml-1-between"
                                                    icone="icon-Add "
                                                    texto="Adicionar"
                                                    action={(_) => {
                                                      setAdicionarLinhaSocio(
                                                        true,
                                                      )
                                                      setLinhaAdicionada(true)
                                                      setIndexCopia(
                                                        dados.length,
                                                      )
                                                      pularPara(
                                                        'modal-suspenso',
                                                        i,
                                                        150,
                                                      )
                                                    }}
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                                {item.new === undefined &&
                                                  alteracao === -1 &&
                                                  item.descricao !== null &&
                                                  linhaAdicionada === false &&
                                                  dados.length > 0 &&
                                                  dados[i].id !== '' ? (
                                                  <BtnAcao
                                                    type="button"
                                                    classTooltip={
                                                      'tooltip-input-modal'
                                                    }
                                                    classeContent="ml-1-between"
                                                    icone="icon-Pen"
                                                    texto="Alterar"
                                                    action={(_) => {
                                                      passFormikValue(
                                                        item.justificativa,
                                                      )
                                                      setAlteracao(i)
                                                      adicionaAlteracao(i)
                                                      setAlteracaoState(true)
                                                    }}
                                                  />
                                                ) : (alteracao === i &&
                                                  erro === true) ||
                                                  propsFormik.values[
                                                  'justificativa-' + i
                                                  ] ||
                                                  (!propsFormik.values[
                                                    'justificativa-' + i
                                                  ] &&
                                                    alteracao === i) ||
                                                  (linhaAdicionada === true &&
                                                    indexCopia === i) ||
                                                  dados[0].id === null ||
                                                  (dados.length === 1 &&
                                                    dados[0].id === '') ? (
                                                  <>
                                                    {
                                                      <BtnAcao
                                                        type={'button'}
                                                        classTooltip={
                                                          'tooltip-input-modal'
                                                        }
                                                        classeContent="ml-1-between"
                                                        icone={'icon-Yes'}
                                                        texto="Confirmar"
                                                        action={(_) => {
                                                          passFormikValue(
                                                            propsFormik.values[
                                                            'justificativa-' +
                                                            alteracao
                                                            ],
                                                          )
                                                          confirmaSocio(
                                                            propsFormik.values[
                                                            'justificativa-' +
                                                            alteracao
                                                            ],
                                                            propsFormik.values[
                                                            'socio-' + i
                                                            ],
                                                            i,
                                                          )
                                                        }}
                                                      />
                                                    }{' '}
                                                    {linhaAdicionada ===
                                                      false &&
                                                      dados[0].id !== null &&
                                                      dados.length > 1 &&
                                                      dados[0].id !== '' && (
                                                        <BtnAcao
                                                          type={'button'}
                                                          classTooltip={
                                                            'tooltip-input-modal'
                                                          }
                                                          classeContent="ml-1-between"
                                                          icone={'icon-Close'}
                                                          texto="Cancelar"
                                                          action={(_) => {
                                                            setAlteracao(-1)
                                                            setTroca(false)
                                                            desfazAlteracaoLinha(
                                                              i,
                                                            )
                                                          }}
                                                        />
                                                      )}
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                                {alteracao === -1 ||
                                                  alteracaoState === true ? (
                                                  <>
                                                    {
                                                      <Excluir
                                                        type={'button'}
                                                        classTooltip="tooltip-input-modal"
                                                        classeContent="ml-1-between"
                                                        icone="icon-New-Trash"
                                                        texto="Excluir"
                                                        action={() =>
                                                          setRemocaoLinhaSocio(
                                                            i,
                                                          )
                                                        }
                                                      />
                                                    }{' '}
                                                  </>
                                                ) : (
                                                  <>
                                                    {dados.length > 1 &&
                                                      dados[0].id !== '' && (
                                                        <BtnAcao
                                                          id={Math.random()}
                                                          type="button"
                                                          classTooltip={
                                                            'tooltip-input-modal'
                                                          }
                                                          classeContent="ml-1-between"
                                                          icone="icon-Add "
                                                          texto="Adicionar"
                                                          action={(_) => {
                                                            setAdicionarLinhaSocio(
                                                              true,
                                                            )
                                                            pularPara(
                                                              'modal-suspenso',
                                                              i,
                                                              150,
                                                            )
                                                          }}
                                                        />
                                                      )}{' '}
                                                    {
                                                      <Excluir
                                                        type={'button'}
                                                        classTooltip="tooltip-input-modal"
                                                        classeContent="ml-1-between"
                                                        icone="icon-New-Trash"
                                                        texto="Excluir"
                                                        action={() =>
                                                          setRemocaoLinhaSocio(
                                                            i,
                                                          )
                                                        }
                                                      />
                                                    }
                                                  </>
                                                )}
                                              </td>
                                              <td />
                                            </tr>
                                          </>
                                        ))}
                                      </tbody>
                                    </>
                                  )}
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={'row'}>
                  <>
                    <InputPadrao
                      label="Id"
                      name="id"
                      type="hidden"
                      placeholder="Id"
                      obrigatorio={true}
                      tamanho=" m-1"
                    />
                    <div className="card mt-2">
                      <div
                        className="card-troca-socio troca-socio-float custom-scrollbar"
                        id="modal-suspenso"
                        style={{ paddingRight: '0px', paddingLeft: '4.5px' }}
                      >
                        <div
                          className="col-12 "
                          style={{ marginBottom: '30px' }}
                        >
                          <span style={{ fontWeight: 'bold' }}>Período:</span>
                          &nbsp;&nbsp;&nbsp;
                          {props.item.hora_inicial +
                            ' - ' +
                            props.item.hora_final}
                        </div>
                        {dados[0] !== undefined &&
                          dados.map((item, i) => (
                            <>
                              <div
                                className={`col-12 row ${(alteracao !== i &&
                                  item.descricao !== '' &&
                                  item.descricao !== null) ||
                                  (linhaAdicionada === false &&
                                    indexCopia === i)
                                  ? ''
                                  : 'pr-0'
                                  }`}
                                style={{
                                  marginBottom: '15px',
                                  borderBottom: '1px solid #dadada',
                                  margin: '0',
                                  padding: '0',
                                }}
                              >
                                <div
                                  className="col-12 color-gray"
                                  style={{ paddingLeft: '0' }}
                                >
                                  <span>
                                    <b>Sócio:</b>
                                  </span>
                                </div>
                                <div
                                  className={`col-12 color-gray '${(alteracao !== i &&
                                    item.descricao !== '' &&
                                    item.descricao !== null) ||
                                    (linhaAdicionada === false &&
                                      indexCopia === i)
                                    ? ''
                                    : 'pr-0 pl-0'
                                    }`}
                                  style={{
                                    marginTop: '3px',
                                    borderBottom: '1px dashed #dedede',
                                    padding: '0',
                                  }}
                                >
                                  {(alteracao !== i &&
                                    item.descricao !== '' &&
                                    item.descricao !== null) ||
                                    (linhaAdicionada === false &&
                                      indexCopia === i) ? (
                                    <span>
                                      <b>{item.codigo} - </b> {item.descricao}
                                    </span>
                                  ) : (
                                    <InputSocio
                                      name={`socio-${i}`}
                                      placeholder="Sócio"
                                      onChange={(option) => {
                                        propsFormik.setFieldValue(
                                          'socio-' + i,
                                          option,
                                        )
                                        onChange &&
                                          onChange(
                                            option,
                                            i,
                                            propsFormik.values,
                                          )
                                      }}
                                      propsFormik={propsFormik}
                                      tamanho={'mr-0'}
                                    />
                                  )}
                                </div>
                                <div
                                  className="col-12 color-gray"
                                  style={{
                                    marginTop: '7px',
                                    paddingLeft: '0',
                                    padding: '0',
                                  }}
                                >
                                  <span>
                                    <b>Justificativa:</b>
                                  </span>
                                </div>
                                <div
                                  className={`col-12 color-gray ' ${(alteracao !== i &&
                                    item.descricao !== '' &&
                                    item.descricao !== null) ||
                                    (linhaAdicionada === false &&
                                      indexCopia === i)
                                    ? ''
                                    : 'pr-0 pl-0'
                                    }`}
                                  style={{ borderBottom: '1px dashed #dedede' }}
                                >
                                  {(alteracao !== i &&
                                    item.new === undefined) ||
                                    item.descricao === null ? (
                                    reduzTexto(item.justificativa, 150)
                                  ) : item.new !== undefined ? (
                                    ''
                                  ) : (
                                    <InputPadrao
                                      id="justificativa"
                                      style={{ marginBottom: '16px' }}
                                      name={`justificativa-${i}`}
                                      placeholder="Justificativa"
                                      propsFormik={propsFormik}
                                      tamanho={'mr-0'}
                                    />
                                  )}
                                </div>
                                <div className="text-center col-12 ml-15">
                                  {(alteracao === -1 ||
                                    alteracaoState === false) &&
                                    linhaAdicionada === false &&
                                    dados[0].id !== null &&
                                    dados.length > 0 &&
                                    dados[0].id !== '' ? (
                                    <BtnAcao
                                      id={Math.random()}
                                      type="button"
                                      classTooltip={'tooltip-input-modal'}
                                      classeContent="ml-1-between"
                                      icone="icon-Add "
                                      texto="Adicionar"
                                      action={(_) => {
                                        setAdicionarLinhaSocio(true)
                                        setLinhaAdicionada(true)
                                        setIndexCopia(dados.length)
                                        pularPara('modal-suspenso', i, 150)
                                      }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  {item.new === undefined &&
                                    alteracao === -1 &&
                                    item.descricao !== null &&
                                    linhaAdicionada === false &&
                                    dados.length > 0 &&
                                    dados[i].id !== '' ? (
                                    <BtnAcao
                                      type="button"
                                      classTooltip={'tooltip-input-modal'}
                                      classeContent="ml-1-between"
                                      icone="icon-Pen"
                                      texto="Alterar"
                                      action={(_) => {
                                        passFormikValue(item.justificativa)
                                        setAlteracao(i)
                                        adicionaAlteracao(i)
                                        setAlteracaoState(true)
                                      }}
                                    />
                                  ) : (alteracao === i && erro === true) ||
                                    propsFormik.values['justificativa-' + i] ||
                                    (!propsFormik.values[
                                      'justificativa-' + i
                                    ] &&
                                      alteracao === i) ||
                                    (linhaAdicionada === true &&
                                      indexCopia === i) ||
                                    dados[0].id === null ||
                                    (dados.length === 1 &&
                                      dados[0].id === '') ? (
                                    <>
                                      {
                                        <BtnAcao
                                          type={'button'}
                                          classTooltip={'tooltip-input-modal'}
                                          classeContent="ml-1-between"
                                          icone={'icon-Yes'}
                                          texto="Confirmar"
                                          action={(_) => {
                                            passFormikValue(
                                              propsFormik.values[
                                              'justificativa-' + alteracao
                                              ],
                                            )
                                            confirmaSocio(
                                              propsFormik.values[
                                              'justificativa-' + alteracao
                                              ],
                                              propsFormik.values['socio-' + i],
                                              i,
                                            )
                                          }}
                                        />
                                      }{' '}
                                      {linhaAdicionada === false &&
                                        dados[0].id !== null &&
                                        dados.length > 1 &&
                                        dados[0].id !== '' && (
                                          <BtnAcao
                                            type={'button'}
                                            classTooltip={'tooltip-input-modal'}
                                            classeContent="ml-1-between"
                                            icone={'icon-Close'}
                                            texto="Cancelar"
                                            action={(_) => {
                                              setAlteracao(-1)
                                              setTroca(false)
                                              desfazAlteracaoLinha(i)
                                            }}
                                          />
                                        )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {alteracao === -1 ||
                                    alteracaoState === true ? (
                                    <>
                                      {
                                        <Excluir
                                          type={'button'}
                                          classTooltip="tooltip-input-modal"
                                          classeContent="ml-1-between"
                                          icone="icon-New-Trash"
                                          texto="Excluir"
                                          action={() => setRemocaoLinhaSocio(i)}
                                        />
                                      }{' '}
                                    </>
                                  ) : (
                                    <>
                                      {dados.length > 1 &&
                                        dados[0].id !== '' && (
                                          <BtnAcao
                                            id={Math.random()}
                                            type="button"
                                            classTooltip={'tooltip-input-modal'}
                                            classeContent="ml-1-between"
                                            icone="icon-Add "
                                            texto="Adicionar"
                                            action={(_) => {
                                              setAdicionarLinhaSocio(true)
                                              pularPara(
                                                'modal-suspenso',
                                                i,
                                                150,
                                              )
                                            }}
                                          />
                                        )}{' '}
                                      {
                                        <Excluir
                                          type={'button'}
                                          classTooltip="tooltip-input-modal"
                                          classeContent="ml-1-between"
                                          icone="icon-New-Trash"
                                          texto="Excluir"
                                          action={() => setRemocaoLinhaSocio(i)}
                                        />
                                      }
                                    </>
                                  )}
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                  </>
                </div>
              )}

              <div
                className={`${width < 1000
                  ? 'text-center'
                  : 'text-right container-botoes mb-3 group-salvar-novo mt-2'
                  }`}
                style={{ paddingTop: '12px', marginBottom: '20px' }}
              >
                <Atalho />
                <BotaoPadrao
                  texto="Salvar"
                  disabled={salvando}
                  click={(_) => {
                    verificaConfirmacao(
                      propsFormik.values['justificativa-' + alteracao],
                      propsFormik.values['socio-' + alteracao],
                    );
                  }}
                  idTooltip={'salvar'}
                  classeTooltip=""
                  class="btn btn-primary-sm primary"
                />
                <BotaoPadrao
                  texto="Cancelar"
                  disabled={salvando}
                  click={(_) => {
                    setDados(null)
                    props.setAbrirModal(false)
                    setAlteracao(-1)
                    setTroca(false)
                    desfazerAlteracoes()
                  }}
                  idTooltip={'cancelar'}
                  classeTooltip=""
                  class="btn btn-primary-sm primary"
                />
              </div>
              {/* <ModalDuasOpcoes showModal={showModalInterOcorrencia} setShowModal={setShowModalInterOcorrencia}
                            funcaoOpcao1={() => { setSalvando(false); setShowModalInterOcorrencia(false) }}
                            esconderFechar mensagem={<>Há interposição para os sócios: <br /><br />{msgValidacaoOcorrencia}<br /></>}
                            textoOpcao1={'Fechar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'} /> */}
              {/* <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { funcaoAposSim(propsFormik); setShowModalValidacao(false) }} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                            esconderFechar mensagem={mensagemModal}
                            textoOpcao1={'Cancelar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} /> */}
              <ModalDuasOpcoes
                showModal={showModalInterOcorrencia}
                setShowModal={setShowModalInterOcorrencia}
                funcaoOpcao1={() => {
                  setSalvando(false)
                  setShowModalInterOcorrencia(false)
                }}
                esconderFechar
                mensagem={<>{msgValidacaoOcorrencia}</>}
                textoOpcao1={'Fechar'}
                classeOpcao1={'btn-sm btn-primary-modal simples'}
                textoOpcao2={'Continuar'}
                funcaoOpcao2={(_) => {
                  validarSubmit(propsFormik.values, propsFormik, true)
                  setShowModalInterOcorrencia(false)
                }}
              />
            </Form>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </>
  )
}
const Cadastro = (props) => {
  const links = [
    () => <Link to={'/dashboard'}>Dashboard</Link>,
    () => <Link to={'#'}>Administrativo</Link>,
    () => (
      <Link
        className="link-active"
        to={'/administracao/agendareserva/consulta'}
      >
        Agenda/Central de Reserva
      </Link>
    ),
  ]
  return (
    <>
      <HeaderCadastro titulo={'Agendamento'} link={links} />
      <CadastroAgendaEscalador {...props} />
    </>
  )
}
export {
  CancelarAgenda,
  CadastroAgendaEscalador,
  CadastroSocioAgenda,
  ConfirmarAgendamento,
  CadastroPreferencial,
  FinalizarAgenda,
  CadastroObservacao,
  TrocarStatus,
  CadastroRapido,
  CadastroEstabelecimentoPlantao,
  CadastroRapidoSuspensa,
  CadastroTrocaSocio,
}
export default Cadastro
