import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaData } from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { get, salvar, validar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputDatePicker, InputPadrao, InputSwitch, TextArea } from '../../../../components/Inputs'
import { InputOperadoraSaude, InputSimNao } from '../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import Atalho from '../../../../util/Atalho'
import { ModalDuasOpcoes } from '../../../../components/Modais';


const CadastroParametroOperadora = (props) => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao] = useState(Yup.object({
        codigo_prestador: Yup.string()
            .required().trim()
            .max(200),
        operadora_saude_id: Yup.string().max(200).trim(),
        limite_faturamento: Yup.string()
            .required().trim()
            .max(200),
        quantidade_guia_lote: Yup.string()
            .required().trim()
            .max(200),

    }))
    const manter = ['']
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')


    const Submit = (values, propsFormik) => {
        setSalvando(true)
        salvar(
            values,
            '/faturamento/parametroOperadoraSaude',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)

                if (salvarENovo) {
                    propsFormik.resetForm({})
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                }
                else
                    props.history.push('/faturamento/parametroOperadoraSaude/consulta')
            },
            (e) => {
                console.log('e', e)
                setSalvando(false)
            })
    }

    const validarSubmit = (values, propsFormik) => {

        setSalvando(true)
        validar('/faturamento/validaParametroOperadoraSaude', values, () => Submit(values, propsFormik), (e) => {
            setShowModalValidacao(true)
            console.log('E', e.response.data)
            setMensagemModal(e.response.data.alertas[0])
            setSalvando(false)
        })
    }

    const funcaoAposSim = (values, propsFormik) => Submit(values, propsFormik)


    useEffect(() => {
        Promise.resolve(get(`/faturamento/parametroOperadoraSaude/${id}`)).then(obj => {
            if (id && !obj.operadora_saude_id)
                props.history.push('/faturamento/parametroOperadoraSaude/consulta')
            else if (id) {
                setValoresIniciais(obj)
            }
            // else {
            //     setValoresIniciais({
            //         descricao: "",
            //         data_feriado: ""
            //     })
            // }
        })
    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
            {propsFormik => (<Form>
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={props.modal || 'card-body'}>
                        <div className='row'>
                            <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' obrigatorio={false} />
                            <InputOperadoraSaude label="Operadora Saúde" name="operadora_saude_id" propsFormik={propsFormik} tamanho='col-sm-12 col-xl-6 col-lg-6' />
                            <InputPadrao label='Código Prestador Operadora' name='codigo_prestador' type='text' placeholder='Codigo Prestador Operadora' classeTooltip={props.modal && 'tooltip-input-modal'}
                                obrigatorio={true} tamanho={!props.modal && 'col-2 col-sm-12 col-md-6 col-xl-2'} />
                            <InputPadrao label='Limite Faturamento(dias)' name='limite_faturamento' type='text' placeholder='Limite Faturamento' classeTooltip={props.modal && 'tooltip-input-modal'}
                                obrigatorio={true} tamanho={!props.modal && 'col-2 col-sm-12 col-md-6 col-xl-2'} />
                            <InputPadrao label='Qtde. Máxima de Guias Por Lote' name='quantidade_guia_lote' type='text' placeholder='Qtde. Máxima de Guias Por Lote' classeTooltip={props.modal && 'tooltip-input-modal'}
                                obrigatorio={true} tamanho={!props.modal && 'col-2 col-sm-12 col-md-6 col-xl-2'} />
                            <div className='row'>
                                <InputSwitch label='Utiliza Guia?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='utiliza_guia' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputSwitch label='Valida Maior Porte?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='valida_maior_porte' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputSwitch label='Utiliza Senha?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='utiliza_senha' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputSwitch label='Libera Percentual(%)?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='libera_percentual' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputSwitch label='Valida Interposição?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='valida_interposicao' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />

                                <InputSwitch label='Valida Duplicidade?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='valida_duplicidade' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputSwitch label='Procedimento "Não" Ordenados?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='procedimento_ordenados' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputSwitch label='Procedimento Único (50/70/100%)?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='procedimento_unico' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            </div>

                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />

                        </div>
                    </div>
                </div>
                <div className="mt-40">
                    <HeaderCadastro titulo={'Duplicidade De Guias (CAMPOS)'}></HeaderCadastro>

                </div>
                <div className={props.modal && props.modal || 'card mb-20 card-body'}>
                    <div className='row'>
                        <div className='row'>
                            <InputSwitch label='Sócio' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='socio' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSwitch label='Esbelecimento Sáude' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='estabelecimento_saude' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSwitch label='Acomodação' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='acomodacao' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSwitch label='Guia' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='guia' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSwitch label='Matrícula/Carteira' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='matricula' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSwitch label='Nome Paciente' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='nome_paciente' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSwitch label='Hora Inicial' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='hora_inicial' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSwitch label='Hora Final' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='hora_final' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSwitch label='Senha TISS' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='senha_tiss' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSwitch label='Carteira TISS' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora' name='carteira_tiss' size="md" tamanho={!props.modal && 'col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />

                        </div>
                    </div>
                </div>
                <div className="mt-40">
                    <HeaderCadastro></HeaderCadastro>
                </div>
                <div className='card mb-20 card-body'>
                    <TextArea label='Observação' name='observacao' type='text' placeholder='Observação' tamanho={!props.modal && 'col-lg-12 col-md-12'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                </div>
                {!props.modal ? <div className='separator mt-40 mb-5'></div> : <></>}
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
                <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { funcaoAposSim(propsFormik.values, propsFormik); setShowModalValidacao(false); }} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                    esconderFechar mensagem={mensagemModal}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
            </Form>)
            }
        </Formik >
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Faturamento</Link>,
        () => <Link className="link-active" to={'/faturamento/parametroOperadoraSaude/consulta'}>Parâmetro Operadora Saúde</Link>,
    ]
    return (<>
        {!props.modal && <HeaderCadastro titulo={"Cadastro de Parâmetro Operadora Saúde"} link={links} />}
        <CadastroParametroOperadora {...props} />
    </>
    )
}
export { CadastroParametroOperadora }
export default Cadastro
