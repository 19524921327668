import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { get } from '../../../../util/AcessoApi'
import { ordenarArrayObj } from '../../../../util/FuncoesComuns'
import { visualizar } from '../../../../util/AcessoApi'

const VisualizacaoEstabelecimentoSaude = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})


    useEffect(() => {
        if (id) {
            visualizar(`/administracao/estabelecimentoSaude`, id, obj => {
                
                setDados(obj)
            })
        }
    }, [id])

    const data = dados.data_registro ? {
        id,
        modulo: props.modulo,
        titulo: 'Visualização de Estabelecimento Saúde',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Razão Social',
                        descricao: dados.razao_social,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Nome Fantasia',
                        descricao: dados.nome_fantasia,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Identificação',
                        descricao: dados.codigo,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'CNPJ',
                        descricao: dados.cnpj,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'CNES',
                        descricao: dados.cnes,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'E-mail',
                        descricao: dados.email,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Urgência?',
                        descricao: (dados.estabelecimento_saude_urgencia && dados.estabelecimento_saude_urgencia.label) || 'Não',
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Ativo?',
                        descricao: dados.ativo && dados.ativo.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Tipo Estabelecimento',
                        descricao: dados.descricao_tipo_estabelecimento,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Observação',
                        descricao: dados.observacao,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data Registro',
                        descricao: (dados.data_registro),
                        tamanho: 'col-lg-6'
                    }
                ]
            },
            {
                titulo: 'Endereço',
                colunas: [
                    {
                        titulo: 'CEP',
                        descricao: dados.cep,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Logradouro',
                        descricao: dados.logradouro,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Complemento',
                        descricao: dados.complemento,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Número',
                        descricao: dados.numero,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Bairro',
                        descricao: dados.bairro,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Município - UF',
                        descricao: dados.nome_municipio,
                        tamanho: 'col-lg-6'
                    }
                ]
            },
            {
                titulo: 'Diretores',
                colunas: [
                    {
                        titulo: 'Diretor Clínico',
                        descricao: dados.diretor_clinico,
                        tamanho: 'col-lg-4'
                    },
                    {
                        titulo: 'Diretor Técnico',
                        descricao: dados.diretor_tecnico,
                        tamanho: 'col-lg-4'
                    },
                    {
                        titulo: 'Diretor Administrativo',
                        descricao: dados.diretor_administrativo,
                        tamanho: 'col-lg-4'
                    },
                ]
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Telefone(s)',
                thead:
                    <tr>
                        <td className='coluna-6'>Tipo</td>
                        <td className='coluna-6'>Telefone</td>
                    </tr>,
                tbody: dados.fone == undefined || dados.fone.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.fone, 'id').map(x =>
                        <tr>
                            <td>{x.descricao}</td>
                            <td>{x.telefone}</td>
                        </tr>)
            }
        ]
    } : { titulo: 'Visualização de Estabelecimento Saúde' }

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoEstabelecimentoSaude