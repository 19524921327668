import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { get, visualizar } from '../../../../util/AcessoApi'
import { ordenarArrayObj, downloadArquivoS3 } from '../../../../util/FuncoesComuns'
import { distinctArr, formatarMoeda } from '../../../../util/Utilitarios';

const VisualizacaoGuiaParticular = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    const obterHistoricos = async () => {
        var historicos = {
            historico_socios_equipe: []
        };

        await Promise.resolve(get(`/faturamento/historicoSocioEquipe/${id}`)).then(val => {
            historicos['historico_socios_equipe'] = val;
        })

        return historicos;
    }

    useEffect(async () => {
        let object = await obterHistoricos();

        visualizar('/faturamento/guiaParticular', id,
            obj => {

                setDados(obj.data_procedimento ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Guia Particular',
                    grupos: [
                        {
                            titulo: 'Dados Atuais Guia',
                            colunas: [
                                { titulo: 'Codigo', descricao: obj.id, tamanho: 'col-lg-3' },
                                {
                                    titulo: 'Status', descricao: obj.status?.descricao, tamanho: 'col-lg-3', htmlDireto: <div className='guia-legenda'>
                                        <span className='legenda' style={{ background: obj.status.cor && obj.status.cor }}>{obj.status.descricao}</span>
                                    </div>
                                },
                                { titulo: 'Data e Hora Inserção', descricao: obj.data_insercao, tamanho: 'col-lg-3' },
                                { titulo: `${obj.inserido_app ? 'Usuário Inserção App' : 'Usuário Inserção'}`, descricao: obj.inserido_app ? obj.usuario_insercao_app : obj.usuario_insercao, tamanho: 'col-lg-3' },

                            ]
                        },
                        obj.dados_atendimento != null && obj.dados_atendimento.length > 0 ?
                            {
                                titulo: 'Dados Atendimento Principal',
                                colunas: [
                                    { titulo: 'Código', descricao: obj.dados_atendimento[0].value, tamanho: 'col-lg-4' },
                                    { titulo: 'Código de Barras', descricao: obj.dados_atendimento[0].codigo_barras, tamanho: 'col-lg-4' },
                                    { titulo: 'Data Procedimento ', descricao: obj.dados_atendimento[0].data_procedimento, tamanho: 'col-lg-4' },
                                    { titulo: 'Paciente', descricao: obj.dados_atendimento[0].paciente, tamanho: 'col-lg-4' },
                                    { titulo: 'Estabelecimento Saúde', descricao: obj.dados_atendimento[0].estabelecimento_saude, tamanho: 'col-lg-4' },
                                    { titulo: 'Operadora Saúde', descricao: obj.dados_atendimento[0]?.operadora_saude, tamanho: 'col-lg-4' },
                                    { titulo: 'Sócio(s)', descricao: distinctArr(obj.dados_atendimento[0].socio).join(', '), tamanho: 'col-lg-4' },
                                    { titulo: 'Status', descricao: obj.dados_atendimento[0].status, tamanho: 'col-lg-4' },
                                    { titulo: 'Valor Total R$', descricao: obj.dados_atendimento[0].valor_total_formatado, tamanho: 'col-lg-4' },
                                    { titulo: 'Data Registro', descricao: obj.dados_atendimento[0].data_registro, tamanho: 'col-lg-4' },
                                ]
                            } :
                            {
                                tipoGrupo: 'tabela',
                                titulo: 'Dados Atendimento Principal',
                                thead:
                                    <tr>
                                        <td className='coluna-2'></td>
                                    </tr>,

                                tbody: <><tr>
                                    <td colspan="3">Sem Registros!</td>
                                </tr></>
                            },
                        {
                            titulo: 'Dados Gerais Guia',
                            colunas: [
                                { titulo: 'Código Barras', descricao: obj.codigo_barras, tamanho: 'col-lg-4' },
                                { titulo: 'Data Procedimento', descricao: obj.data_procedimento, tamanho: 'col-lg-4' },
                                { titulo: 'Empresa', descricao: obj.empresa_nome, tamanho: 'col-lg-4' },
                                { titulo: 'Estabelecimento de Saúde', descricao: obj.estabelecimento_saude && obj.estabelecimento_saude.label, tamanho: 'col-lg-4' },
                                { titulo: 'Nome Social', descricao: obj.nome_social, tamanho: 'col-lg-4' },
                                { titulo: 'Nome Beneficiário', descricao: obj.nome_paciente && obj.nome_paciente.label, tamanho: 'col-lg-4' },
                                { titulo: 'Data Nascimento', descricao: obj.data_nasc, tamanho: 'col-lg-4' },
                                { titulo: 'Acomodação', descricao: obj.acomodacao && obj.acomodacao.label, tamanho: 'col-lg-4' },
                                { titulo: 'Hora Inicial', descricao: obj.hora_inicial, tamanho: 'col-lg-4' },
                                { titulo: 'Hora Final', descricao: obj.hora_final, tamanho: 'col-lg-4' },
                                { titulo: 'Cirurgião', descricao: obj.cirurgiao && obj.cirurgiao.label, tamanho: 'col-lg-4' },
                                { titulo: 'NFSE Paciente ?', descricao: obj.nfse ? 'SIM' : 'NÃO', tamanho: 'col-lg-4' },
                                { titulo: 'Atendimento RN', descricao: obj.atendimento_rn ? 'SIM' : 'NÃO', tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Honorário', descricao: obj.tipo_honorario && obj.tipo_honorario.label, tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Atendimento Guia', descricao: obj.tipo_atendimento && obj.tipo_atendimento.label, tamanho: 'col-lg-4' },
                                { titulo: 'Sequência Hospital', descricao: obj.seq, tamanho: 'col-lg-4' },
                                { titulo: 'Observação', descricao: obj.observacao, tamanho: 'col-lg-12' },
                                { titulo: 'Guia Princincial', descricao: obj.guia_complementar, tamanho: 'col-lg-4' },
                                { titulo: 'Data Entrega', descricao: obj.data_entrega_formatada, tamanho: 'col-lg-4' },
                                { titulo: `${obj.inserido_app ? 'Usuário Inserção App' : 'Usuário Inserção'}`, descricao: obj.inserido_app ? obj.usuario_insercao_app : obj.usuario_insercao, tamanho: 'col-lg-3' },

                            ]
                        },
                        {
                            titulo: 'Dados NFSE',
                            colunas: [
                                { titulo: 'Nome NFS-e', descricao: obj.nome_nfse, tamanho: 'col-lg-4' },
                                { titulo: 'Data Nascimento', descricao: obj.data_nasc_nfse, tamanho: 'col-lg-4' },
                                { titulo: 'CPF/CNPJ', descricao: obj.cpf_cnpj, tamanho: 'col-lg-4' },
                                { titulo: 'CEP', descricao: obj.cep, tamanho: 'col-lg-4' },
                                { titulo: 'Logradouro', descricao: obj.logradouro, tamanho: 'col-lg-4' },
                                { titulo: 'Complemento', descricao: obj.complemento, tamanho: 'col-lg-4' },
                                { titulo: 'Número', descricao: obj.numero, tamanho: 'col-lg-4' },
                                { titulo: 'Bairro', descricao: obj.bairro, tamanho: 'col-lg-4' },
                                { titulo: 'Município - UF', descricao: obj.municipio.label, tamanho: 'col-lg-4' },
                                { titulo: 'E-mail', descricao: obj.email, tamanho: 'col-lg-4' },
                                { titulo: 'Telefone', descricao: obj.telefone.map(x => x.telefone), tamanho: 'col-lg-4' },
                                { titulo: 'Código de Verificação', descricao: obj.codigo_verificacao, tamanho: 'col-lg-4' },
                                { titulo: 'Número NFS-e', descricao: obj.nfse_numero, tamanho: 'col-lg-4' }
                            ]
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Pacote',
                            thead:
                                <tr>
                                    {/* <td className='coluna-6'>Item Ref.</td> */}
                                    <td className='coluna-2'>Pacote</td>
                                    <td className='coluna-2'>Descrição</td>
                                    <td className='coluna-2'>Valor</td>
                                </tr>,
                            tbody: obj.pacote == undefined || obj.pacote.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.pacote.filter(x => x.id).map(x =>
                                    <tr>
                                        <td>{x.pacote_id && x.pacote_id.label || '---'}</td>
                                        <td>{x.pacote_descricao}</td>
                                        <td>{formatarMoeda(x.pacote_valor)}</td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Procedimento(s)',
                            visivel: obj.procedimentos.length > 0,
                            aposGrupo: <tr>
                                <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={13}>{formatarMoeda(obj.procedimentos.map(item => item.valor_total).reduce((total, num) => parseFloat(total) + parseFloat(num)))}</td>
                            </tr>,
                            thead:
                                <tr>
                                    <td className='coluna-1' colspan="1">Código</td>
                                    <td className='coluna-3' colspan="3">Procedimento</td>
                                    <td className='coluna-1' colspan="1">Porte</td>
                                    <td className='coluna-1' colspan="1">Valor</td>
                                    <td className='coluna-1' colspan="1">Acm(%)</td>
                                    <td className='coluna-1' colspan="1">Perc(%)</td>
                                    <td className='coluna-1' colspan="1">Red/Acrésc.</td>
                                    <td className='coluna-1' colspan="1">Urg.</td>
                                    <td className='coluna-1' colspan="1">Qtde.</td>
                                    <td className='coluna-3' colspan="3" style={{ textAlign: 'right' }}>Valor Total</td>
                                </tr>,
                            tbody: obj?.procedimentos == undefined || obj?.procedimentos.length < 1 || obj?.procedimentos.filter(x => x.id !== null).length === 0 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :

                                obj?.procedimentos.map(x => {
                                    return (
                                        <tr>
                                            <td colspan="1">{x?.codigo}</td>
                                            <td colspan="3">{x?.procedimento?.label}</td>
                                            <td colspan="1">{x?.porte}</td>
                                            <td colspan="1">{x?.valor_porte}</td>
                                            <td colspan="1">{x?.porcentagem_acm}</td>
                                            <td colspan="1">{x.porcentagem && x.porcentagem.label}</td>
                                            <td colspan="1">{x?.reducao_acrescimo && x?.reducao_acrescimo.replace('.', ',') + '%'}</td>
                                            <td colspan="1">{x?.urgencia === true ? x?.urgencia_porcentagem.replace('.', ',') + '%' : ''}</td>
                                            <td colspan="1">{x?.quantidade}</td>
                                            <td colspan="3" style={{ textAlign: 'right' }}>{x?.valor_total && formatarMoeda(x?.valor_total)}</td>
                                        </tr>)
                                })
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Identificação do(s) Profissional(is) Executante(s) Equipe',
                            thead:
                                <tr>
                                    {/* <td className='coluna-6'>Item Ref.</td> */}
                                    <td className='coluna-2'>Data</td>
                                    <td className='coluna-2'>Hora Inicial</td>
                                    <td className='coluna-2'>Hora Final</td>
                                    <td className='coluna-3'>Sócio</td>
                                    <td className='coluna-2'>Tipo Atendimento</td>
                                    <td className='coluna-3'>Empresa</td>

                                </tr>,
                            tbody: obj.executantes_internos == undefined || obj.executantes_internos.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.executantes_internos.filter(x => x.id).map(x =>
                                    <tr>
                                        <td>{x.data}</td>
                                        <td>{x.hora_inicial}</td>
                                        <td>{x.hora_final}</td>
                                        <td>{x.executante && x.executante.label || '---'}</td>
                                        <td>{x.executante_tipo_atendimento && x.executante_tipo_atendimento.label || '---'}</td>
                                        <td>{x.empresa && x.empresa.label || '---'}</td>
                                    </tr>)
                        },
                        // },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Arquivo(s)',
                            thead:
                                <tr>
                                    <td className='coluna-6'>Nome</td>
                                    <td className='coluna-5'>Tamanho</td>
                                    <td className='coluna-1 d-none-print'>Download</td>
                                </tr>,
                            tbody: obj.arquivo == undefined || obj.arquivo.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                ordenarArrayObj(obj.arquivo, 'nome').map(arquivo =>
                                    <tr>
                                        <td>{arquivo.name}</td>
                                        <td>{arquivo.sizeReadable}</td>
                                        <td className="d-none-print"><div className="icone"><button className="btn-acao icon-Download-fromCloud" onClick={() => downloadArquivoS3(arquivo.s3Key)}></button></div></td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Histórico Status',
                            thead:
                                <tr>
                                    {/* <td className='coluna-6'>Item Ref.</td> */}
                                    <td className='coluna-2'>Data</td>
                                    <td className='coluna-2'>Status</td>
                                    <td className='coluna-4'>Justificativa</td>
                                    <td className='coluna-2'>Usuário</td>

                                </tr>,
                            tbody: obj.historico_status == undefined || obj.historico_status.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.historico_status.map(x =>
                                    <tr>
                                        <td>{x?.data_registro}</td>
                                        <td>{x?.descricao}</td>
                                        <td>{x?.justificativa_status}</td>
                                        <td>{x?.usuario}</td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Movimento/Lançamento',
                            aposGrupo: <tr> {
                                obj?.liquidacao_visualizacao.length > 0 ?
                                    <>
                                        {/* <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={9}>Total: {formatarMoeda(obj?.liquidacao_visualizacao.map(item => item.valor_liquidado).map(y => y).reduce((total, num) => parseFloat(total) + parseFloat(num)))}</td> */}
                                        <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={9}>Total: {formatarMoeda(obj?.liquidacao_visualizacao.reduce((total, item) => total + item.valor_liquidado.reduce((subtotal, valor) => subtotal + valor, 0), 0))}</td>
                                    </>
                                    : <></>}
                            </tr>,
                            thead:
                                <tr>
                                    <td className='coluna-1'>Movimento</td>
                                    <td className='coluna-1'>Data Mov.</td>
                                    <td className='coluna-1'>Lançamento</td>
                                    <td className='coluna-2'>Descrição</td>
                                    <td className='coluna-1'>Data Liq.</td>
                                    <td className='coluna-2' style={{ textAlign: 'right' }}>Valor Apre. R$</td>
                                    <td className='coluna-2' style={{ textAlign: 'right' }}>Retenções R$</td>
                                    <td className='coluna-2' style={{ textAlign: 'right' }}>Valor Liq.R$</td>
                                    <td className='coluna-1' style={{ textAlign: 'left' }}>TP</td>
                                </tr>,
                            tbody: obj.liquidacao_visualizacao === null || obj.liquidacao_visualizacao.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :

                                obj.liquidacao_visualizacao.map(x =>
                                    <>
                                        <tr>
                                            <td>{x.movimento_id}</td>
                                            <td>{x.data_movimento}</td>
                                            <td>{x.lancamento_id}</td>
                                            <td>{x.descricao}</td>
                                            <td>{x.data_liquidacao}</td>
                                            <td style={{ textAlign: 'right' }}>{formatarMoeda(x.valor_lancamento)}</td>
                                            <td style={{ textAlign: 'right' }}>{formatarMoeda(x.total_retencoes)}</td>
                                            <td colSpan="1">
                                                <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                    {x.valor_liquidado.map((valor, index) => (
                                                        <li
                                                            key={index}
                                                            style={{
                                                                textAlign: 'right',
                                                                marginTop: index === 0 ? 0 : '10px',
                                                            }}
                                                        >
                                                            {formatarMoeda(valor)}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </td>

                                            <td colSpan="1">
                                                <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                    {x.modo_pagamento.map((z, index) => (
                                                        <li
                                                            key={index}
                                                            style={{
                                                                textAlign: 'left',
                                                                marginTop: index === 0 ? 0 : '10px',
                                                            }}
                                                        >
                                                            {z}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </td>
                                        </tr>

                                    </>)

                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Mapa de Horas',
                            thead:
                                <tr>
                                    {/* <td className='coluna-6'>Item Ref.</td> */}
                                    <td className='coluna-1'>Codigo</td>
                                    <td className='coluna-1'>Referência</td>
                                    <td className='coluna-2'>Anotações</td>
                                    <td className='coluna-2'>Data Registro</td>

                                </tr>,
                            tbody: obj.mapa_horas == undefined || obj.mapa_horas.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.mapa_horas.filter(x => x.codigo).map(x =>
                                    <tr>
                                        <td >{x.codigo}</td>
                                        <td >{x.referencia}</td>
                                        <td >{x.anotacoes}</td>
                                        <td >{x.data_registro}</td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'NFSe Emitida(s)',
                            thead:
                                <tr>
                                    <td className='coluna-1'>Número</td>
                                    <td className='coluna-2'>Data Emissão</td>
                                    <td className='coluna-3 '>Tomador/Cliente</td>
                                    <td className='coluna-3 '>Prestador/Empresa</td>
                                    <td className='coluna-3 '>Valor Nota</td>
                                </tr>,
                            tbody: obj.nota_fiscal_emitida == undefined || obj.nota_fiscal_emitida.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                ordenarArrayObj(obj.nota_fiscal_emitida, 'codigo').map(item =>
                                    <tr>
                                        <td>{item.numero}</td>
                                        <td>{item.data_emissao}</td>
                                        <td>{item.tomador}</td>
                                        <td>{item.prestador}</td>
                                        <td>{item.valor_nota}</td>
                                        {/* <td className="d-none-print"><div className="icone"><button className="btn-acao icon-Download-fromCloud" onClick={() => downloadArquivoS3(arquivo.s3Key)}></button></div></td> */}
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Histórico de Sócios Equipe',
                            thead:
                                <tr>
                                    <td className='coluna-3'>Sócio</td>
                                    <td className='coluna-2'>Usuário</td>
                                    <td className='coluna-1'>Data Registro</td>
                                </tr>,
                            tbody: object.historico_socios_equipe?.length ?
                                object.historico_socios_equipe.map(x =>
                                    <tr>
                                        <td>{x.socios.map(socio => <><p>{`${socio.sigla ? socio.sigla + ' - ' : ''}${socio.socio}`}</p></>)}</td>
                                        <td>{x.usuario}</td>
                                        <td>{x.data_registro}</td>
                                    </tr>) : (<><tr>
                                        <td colspan="3">Sem Registro!</td>
                                    </tr></>)
                        }

                    ]
                } : { titulo: 'Visualização Guia' })
            })
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default VisualizacaoGuiaParticular