import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'

const Visualizacao = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/faturamento/tabelaProcedimento', id,
            obj =>
                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Tabela Procedimento',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Tipo', descricao: obj.tipo.descricao, tamanho: 'col-lg-4' },
                                { titulo: 'Descrição', descricao: obj.descricao, tamanho: 'col-lg-4' },
                                { titulo: 'Código TISS', descricao: obj.codigo_tiss, tamanho: 'col-lg-4' },
                                { titulo: 'Utiliza Agendamento?', descricao: obj.utiliza_agendamento.label, tamanho: 'col-lg-4' },
                                { titulo: 'Data de Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                                { titulo: 'Data Atualização', descricao: obj.data_atualizacao || '--', tamanho: 'col-lg-4' },
                                { titulo: 'Usuário Atualização', descricao: obj.nome || '--', tamanho: 'col-lg-4' },
                            ]
                        }
                    ]
                } : { titulo: 'Visualização Tabela Procedimento' })
        )
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default Visualizacao