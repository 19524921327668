import React, { useCallback, useEffect, useState } from 'react';
import { convertValueToFloat, formatarMoeda } from '../../util/Utilitarios';
import { TableVirtuoso } from 'react-virtuoso';
import MensagemPadraoRelatorio from '../../components/RelatorioEspecifico/RelatorioSemDados'
import { BrowserRouter, Link } from 'react-router-dom'
import { useSortBy } from 'react-table';
import { limitarCaracteres } from '../../util/FuncoesComuns';



const AgrupamentoRelatorio = ({
    htmlHeader,
    itemGeral,
    arrayConfigCol,
    grupo,
    idxLista,
    nomeGrupo,
    configOrdenamento,
    idxGeral,
    nomeAgrupamento,
    LayoutLinha,
    showRemoverFiltros,
    setShowRemoverFiltros,
    removerFiltros
}, ...props) => {
    useEffect(() => {
        if (setShowRemoverFiltros)
            setShowRemoverFiltros(configOrdenamento.arrayOrdenamento[idxGeral].filter(item => item === true).length > 0)
    }, [])

    return (<>
        {idxLista > 0 ? htmlHeader : <></>}
        <table>
            {/* Remover Ordenamentos */}
            {showRemoverFiltros ? <tr><td colSpan={`${itemGeral.colunas ? itemGeral.colunas.filter(y => y.visivel).length : 0}`}
                className={'bold'}>
                <BrowserRouter>
                    <Link to='#' className='d-none-print' onClick={() => removerFiltros(idxGeral)}>Remover ordenamentos</Link>
                </BrowserRouter>
            </td></tr> : <></>}
        </table>
        <table className='table-agrupamento mb-2'>
            <tr className={'tr-data titulo-1 group-title bold coluna'}>
                <td className={` bold td-data text-left`}>{nomeGrupo}</td>
            </tr>
        </table>

        <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
            <thead>
                <tr className='titulo-1 group-title'>
                    {itemGeral.colunas && itemGeral.colunas.map((y, idy) => {
                        let classes = (y.ordenamento ? 'ordenavel ' : '') + (arrayConfigCol[idy] && arrayConfigCol[idy].ordenamento.ativo && arrayConfigCol[idy].coluna === y.name ? 'ordenamento_' + arrayConfigCol[idy].ordenamento.modo : '');
                        return (arrayConfigCol[idy] && arrayConfigCol[idy].visivel) && <td style={{ width: y.width || 'auto' }} onClick={(event) => {
                            if (y.ordenamento) {
                                configOrdenamento.setOrdenamento(idy, itemGeral.data, idxGeral)
                                setShowRemoverFiltros(false);
                            }
                        }} className={`bold td-data ${y.alinhamento && (y.alinhamento || '')} ${classes}`}>{y.label}</td>
                    })}
                </tr>
            </thead>
            <tbody>
                {configOrdenamento.obterDataOrdenado(idxGeral, grupo[nomeAgrupamento]).map((paciente, indexP) => {

                    return LayoutLinha ? <LayoutLinha colunas={itemGeral.colunas} linha={paciente} idxLinha={indexP} arrayConfigCol={arrayConfigCol} /> : (
                        <tr className={'tr-data bt-1'}>
                            {itemGeral.colunas && itemGeral.colunas.map((coluna, idy) => {
                                if (coluna.name === '#' && coluna.idxAgrupamento)
                                    return (arrayConfigCol[idy] && arrayConfigCol[idy].visivel) && <td className={`td-data  ${coluna.alinhamento && coluna.alinhamento || ''} ${coluna.classe && coluna.classe || ''}`}>{idxLista + 1}</td>

                                else if (coluna.name === '#' && !coluna.idxAgrupamento)
                                    return (arrayConfigCol[idy] && arrayConfigCol[idy].visivel) && <td className={`td-data text-center ${coluna.classe && coluna.classe || ''}`}>{indexP + 1}</td>

                                return (arrayConfigCol[idy] && arrayConfigCol[idy].visivel) ? <td className={`td-data ${coluna.alinhamento && coluna.alinhamento || ''} ${coluna.classe && coluna.classe || ''}`}>{
                                    paciente[coluna.name]
                                }</td> : <></>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table >
    </>)
}

const RelatorioLayoutAgrupamento = ({
    array, arrayConfigCol, configOrdenamento, htmlHeader, virtuoso,
    tituloAgrupamento, nomeAgrupamento, LayoutLinha,
    ...props }) => {
    const [showRemoverFiltros, setShowRemoverFiltros] = useState(false);

    const removerFiltros = useCallback((idxGeral) => {
        configOrdenamento.limparOrdenamentos(idxGeral);
        setShowRemoverFiltros(false);
    }, [])

    return (
        array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {
            const idxValor = arrayConfigCol[idx].findIndex(y => y.coluna == 'valor_total_formatado')

            const obterValorTotal = (arr) => {
                let somaValores = 0;

                for (let item of arr) {
                    somaValores += item.valor_total;
                }
                return formatarMoeda(somaValores)
            }

            const obterValorTotalRelatorio = (arr) => {

                let somaValores = 0;

                for (let item of arr) {
                    for (let paciente of item)
                        somaValores += paciente.valor_total;
                }
                return formatarMoeda(somaValores)

            }
            if (x.data.length == 0 || x.data == null) {
                return <MensagemPadraoRelatorio />
            }

            return (
                <div>
                    {
                        virtuoso ? <>
                            <TableVirtuoso
                                style={{ height: 1000, }}
                                useWindowScroll
                                data={x.data}
                                itemContent={(idxG, grupo) => (
                                    (<><AgrupamentoRelatorio
                                        itemGeral={x}
                                        idxGeral={idx}
                                        grupo={grupo}
                                        nomeGrupo={grupo[tituloAgrupamento]}
                                        nomeAgrupamento={nomeAgrupamento}
                                        htmlHeader={htmlHeader}
                                        arrayConfigCol={arrayConfigCol[idx]}
                                        idxLista={idxG}
                                        configOrdenamento={configOrdenamento}
                                        LayoutLinha={LayoutLinha}
                                        showRemoverFiltros={showRemoverFiltros}
                                        setShowRemoverFiltros={setShowRemoverFiltros}
                                        removerFiltros={removerFiltros}
                                    />

                                        {props.aposAgrupamento ?
                                            <props.aposAgrupamento arrayConfigCol={arrayConfigCol[idx]} colunas={x.colunas} array={grupo[nomeAgrupamento]} />
                                            : <>
                                                <table style={{ width: '100%' }} className='table-agrupamento mb-2 bold bt-1'>
                                                    {x.colunas && arrayConfigCol[idx][idxValor]?.visivel ? <tr><td colspan={12} className={'td-data pr-0 text-right bold'}>Valor Total PF: {obterValorTotal(grupo.pacientes.filter((tp, teste) => tp.guia_tipo_executante_id == 1))} </td></tr> : <></>}
                                                </table>
                                                <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                                                    {x.colunas && arrayConfigCol[idx][idxValor]?.visivel ? <tr><td colspan={12} className={'td-data pr-0 text-right bold'}>Valor Total PJ: {obterValorTotal(grupo.pacientes.filter((tp, teste) => tp.guia_tipo_executante_id == 2))} </td></tr> : <></>}
                                                </table>
                                                <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                                                    {x.colunas && arrayConfigCol[idx][idxValor]?.visivel ? <tr><td colspan={12} className={'td-data pr-0 text-right bold'}>Valor Total PF/PJ: {obterValorTotal(grupo.pacientes.filter((tp, teste) => tp.valor_total))} </td></tr> : <></>}
                                                </table>
                                            </>
                                        }
                                    </>)
                                )} />

                        </> :
                            x.data.length > 0 ? x.data.map((grupo, idxG) => {
                                return (<>
                                    {idxG > 0 ? htmlHeader : <></>}
                                    <AgrupamentoRelatorio
                                        itemGeral={x}
                                        idxGeral={idx}
                                        grupo={grupo}
                                        nomeGrupo={grupo[tituloAgrupamento]}
                                        nomeAgrupamento={nomeAgrupamento}
                                        htmlHeader={htmlHeader}
                                        arrayConfigCol={arrayConfigCol[idx]}
                                        idxLista={idxG}
                                        configOrdenamento={configOrdenamento}
                                        LayoutLinha={LayoutLinha}
                                        showRemoverFiltros={showRemoverFiltros}
                                        setShowRemoverFiltros={setShowRemoverFiltros}
                                        removerFiltros={removerFiltros}
                                    />

                                    {props.aposAgrupamento ?
                                        <props.aposAgrupamento arrayConfigCol={arrayConfigCol[idx]} colunas={x.colunas} array={grupo[nomeAgrupamento]} />
                                        : <>
                                            <table style={{ width: '100%' }} className='table-agrupamento mb-2 bold bt-1'>
                                                {x.colunas && arrayConfigCol[idx][idxValor]?.visivel ? <tr><td colspan={12} className={'td-data pr-0 text-right bold'}>Valor Total PF: {obterValorTotal(grupo.pacientes.filter((tp, teste) => tp.guia_tipo_executante_id == 1))} </td></tr> : <></>}
                                            </table>
                                            <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                                                {x.colunas && arrayConfigCol[idx][idxValor]?.visivel ? <tr><td colspan={12} className={'td-data pr-0 text-right bold'}>Valor Total PJ: {obterValorTotal(grupo.pacientes.filter((tp, teste) => tp.guia_tipo_executante_id == 2))} </td></tr> : <></>}
                                            </table>
                                            <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                                                {x.colunas && arrayConfigCol[idx][idxValor]?.visivel ? <tr><td colspan={12} className={'td-data pr-0 text-right bold'}>Valor Total PF/PJ: {obterValorTotal(grupo.pacientes.filter((tp, teste) => tp.valor_total))} </td></tr> : <></>}
                                            </table>
                                        </>
                                    }
                                </>
                                )
                            }) :
                                <MensagemPadraoRelatorio />



                    }
                    {props.aposRelatorio ?
                        <props.aposRelatorio arrayConfigCol={arrayConfigCol[idx]} colunas={x.colunas} dados={x.data} />
                        // props.aposRelatorio(props.dados, arrayConfigCol) 
                        :
                        <table style={{ width: '100%' }} className='table-agrupamento mb-2 bold bt-1'>
                            {x.colunas && arrayConfigCol[idx][idxValor]?.visivel ? <tr><td colspan={12} className={'td-data pr-0 text-right bold'}>Valor Total : {obterValorTotalRelatorio(x.data.map(grupo => grupo.pacientes.filter((tp, teste) => tp.valor_total)))} </td></tr> : <></>}
                        </table>}

                </div >
            )
        }
        ) : <MensagemPadraoRelatorio />


    )
}

export const RelatorioGuiaOperadoraSaude = (props) => <RelatorioLayoutAgrupamento {...props} tituloAgrupamento={"operadora_saude"} nomeAgrupamento={"pacientes"} />
export const RelatorioGuiaSocioEquipe = (props) => <RelatorioLayoutAgrupamento {...props} tituloAgrupamento={"nome_socio"} nomeAgrupamento={"pacientes"} />
export const RelatorioGuiaSocioCoopanest = (props) => <RelatorioLayoutAgrupamento {...props} tituloAgrupamento={"nome_socio"} nomeAgrupamento={"pacientes"} />
export const RelatorioGuiaEstabelecimento = (props) => <RelatorioLayoutAgrupamento {...props} tituloAgrupamento={"estabelecimento_saude"} nomeAgrupamento={"pacientes"} />
export const RelatorioEstabelecimentoAnalitico = (props) => <RelatorioLayoutAgrupamento {...props} tituloAgrupamento={"estabelecimento_saude"} nomeAgrupamento={"pacientes"}
    aposAgrupamento={(props) => <AposAggEstabAnalitico {...props} />}
    aposRelatorio={(props) => <CalcularValoresEstabAnalitico {...props} />}
    LayoutLinha={({ colunas, linha, idxLinha, arrayConfigCol, ...props }) => {
        const idxCodProcedimento = arrayConfigCol.findIndex(y => y.coluna == 'codigo_procedimento')
        const idxProcedimento = arrayConfigCol.findIndex(y => y.coluna == 'procedimento')
        const idxStatus = arrayConfigCol.findIndex(y => y.coluna == 'status')
        const idxJustificativaStatus = arrayConfigCol.findIndex(y => y.coluna == 'justificativa_status')
        const idxValor = arrayConfigCol.findIndex(y => y.coluna == 'valor')

        const obterColSpan = (arr) => {
            let novoArr = arr.filter(x => x.coluna !== 'codigo_procedimento' && x.coluna !== 'procedimento' && x.coluna !== 'status' && x.coluna !== 'justificativa_status' && x.coluna !== 'valor');
            return novoArr.length
        }

        return (<><tr className={'tr-data bt-1'}>
            {colunas && colunas.map((coluna, idy) => {
                if (coluna.name === '#' && !coluna.idxAgrupamento)
                    return (arrayConfigCol[idy] && arrayConfigCol[idy].visivel) && <td className={`td-data text-center ${coluna.classe && coluna.classe || ''}`}>{idxLinha + 1}</td>

                switch (coluna.name) {
                    case 'procedimento':
                        return (arrayConfigCol[idy] && arrayConfigCol[idy].visivel) && <td className={`td-data text-left ${coluna.classe && coluna.classe || ''}`}>{limitarCaracteres(linha.procedimentos[0].procedimento, 25)}</td>
                    case 'codigo_procedimento':
                        return (arrayConfigCol[idy] && arrayConfigCol[idy].visivel) && <td className={`td-data text-left ${coluna.classe && coluna.classe || ''}`}>{linha.procedimentos[0].codigo}</td>
                    case 'valor':
                        return (arrayConfigCol[idy] && arrayConfigCol[idy].visivel) && <td className={`td-data text-right ${coluna.classe && coluna.classe || ''}`}>{formatarMoeda(linha.procedimentos[0].valor)}</td>
                    default:
                        return (arrayConfigCol[idy] && arrayConfigCol[idy].visivel) ? <td className={`td-data ${coluna.alinhamento && coluna.alinhamento || ''} ${coluna.classe && coluna.classe || ''}`}>{
                            linha[coluna.name]
                        }</td> : <></>
                }
            })}
        </tr>
            {
                linha.procedimentos.filter((x, idxProced) => idxProced > 0).map((procedimento, idxP) => {
                    return (
                        <tr className='tr-data' >
                            <td colspan={obterColSpan(arrayConfigCol.filter(coluna => coluna.visivel))}></td>
                            {colunas && arrayConfigCol[idxCodProcedimento]?.visivel ? <td className='td-data text-left'>{procedimento.codigo}</td> : <></>}
                            {colunas && arrayConfigCol[idxProcedimento]?.visivel ? <td className=' text-left td-data'>{limitarCaracteres(procedimento.procedimento, 25)}</td> : <></>}
                            {colunas && arrayConfigCol[idxStatus]?.visivel ? <td className='td-data'></td> : <></>}
                            {colunas && arrayConfigCol[idxJustificativaStatus]?.visivel ? <td className='td-data'></td> : <></>}
                            {colunas && arrayConfigCol[idxValor]?.visivel ? <td className='text-right td-data'>{formatarMoeda(procedimento.valor)}</td> : <></>}
                        </tr>
                    )
                })
            }
        </>)
    }}
/>

const CalcularValoresEstabAnalitico = ({ dados, arrayConfigCol, colunas, ...props }) => {
    let totalParticular = { procedimentos: 0, pacientes: 0, total_faturado: 0 };
    let totalConvenio = { procedimentos: 0, pacientes: 0, total_faturado: 0 };

    dados.forEach((item) => {
        item.pacientes.forEach((paciente) => {
            const guia_particular = paciente.guia_particular ? "particular" : "convenio";

            paciente.procedimentos.forEach((procedimento) => {
                const totalFaturado = convertValueToFloat(procedimento.valor);

                // Usar um operador ternário para determinar a qual total adicionar
                guia_particular === "particular"
                    ? (totalParticular.total_faturado += totalFaturado)
                    : (totalConvenio.total_faturado += totalFaturado);
            });
        });
    });

    return (
        <>
            <table>
                <tr><td colspan={colunas ? colunas.length : 0} className={'td-data pr-0'}></td></tr>
                <tr className={'tr-data text-right bold bt-1 bt-black-1'}>
                    <td width={'50px'} className={'td-data pr-0'}></td>

                    {arrayConfigCol.filter(x => x.coluna === 'valor' && x.visivel).length > 0 ? <td width={'25%'} className={'td-data pr-0 bold'}>Valor Total: <span className={'ml-2 bold'}>{formatarMoeda(totalParticular.total_faturado + totalConvenio.total_faturado)}</span></td> : <></>}
                </tr>
            </table>
        </>
    )
}

const AposAggEstabAnalitico = ({ arrayConfigCol, colunas, array, ...props }) => {
    const idxValor = arrayConfigCol.findIndex(y => y.coluna == 'valor')
    const obterValorTotal = (arr) => {
        let somaValores = 0;
        for (let item of arr) {
            for (let procedimento of item.procedimentos) {
                somaValores += procedimento.valor;
            }
        }
        return formatarMoeda(somaValores)
    }

    return (
        <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
            {colunas && arrayConfigCol[idxValor]?.visivel ? <tr><td colspan={12} className={'td-data pr-0 text-right bold'}>Total: {obterValorTotal(array.filter((x, teste) => x.procedimentos))} </td></tr> : <></>}
        </table>
    )
}