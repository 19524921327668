import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { formatMoeda, formatTwoPlace } from '../../../../util/FuncoesComuns'

export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    const porte = obj => {
        let arrayVisualizacao = []

        if (obj.acomodacoes.length)
            arrayVisualizacao.push({
                tipoGrupo: 'tabela',
                titulo: 'Acomodação',
                thead:
                    <tr>
                        <td className='coluna-2'>Acomodação</td>
                        <td className='coluna-2 text-right'>Percentual</td>
                        <td className='coluna-2 text-right'>Urgência</td>
                        <td className='coluna-2 text-right'>Vídeo</td>
                    </tr>,
                tbody:
                    obj.acomodacoes && obj.acomodacoes.sort().map(x =>
                        <tr>
                            <td>{x.acomodacao.label}</td>
                            <td className='text-right'>{formatTwoPlace(x.percentual) + '%'}</td>
                            <td className='text-right'>{formatTwoPlace(x.urgencia) + '%'}</td>
                            <td className='text-right'>{formatTwoPlace(x.video) + '%'}</td>
                        </tr>)
            })

        if (obj.tabelas.length)
            arrayVisualizacao.push({
                tipoGrupo: 'tabela',
                titulo: 'Tabela',
                thead:
                    <tr>
                        <td className='coluna-5'>Tabela</td>
                        <td className='coluna-3'>Base Cálculo</td>
                    </tr>,
                tbody:
                    obj.tabelas && obj.tabelas.sort().filter(x => !x.tabela_auxiliar).map(x =>
                        <tr>
                            <td>{x.tabela.label}</td>
                            <td>{x.base_calculo.label}</td>
                        </tr>)
            })

        if (obj.pacotes) {
            arrayVisualizacao.push({
                tipoGrupo: 'tabela',
                titulo: 'Pacotes',
                thead:
                    <tr>
                        <td className='coluna-3'>Descrição</td>
                        <td className='coluna-2'>Procedimentos</td>
                        <td className='coluna-2 text-right'>Valor Total</td>
                    </tr>,
                tbody: obj.pacotes && obj.pacotes.map(y => <tr>
                    <td>{y.descricao}</td>
                    <td>{y.procedimentos.map(x => x.procedimento.codigo).join(', ')}</td>
                    <td className='text-right'>{y.valor_total}</td>
                </tr>
                )
            })
        }

        if (obj.procedimentos) {
            arrayVisualizacao.push({
                tipoGrupo: 'tabela',
                titulo: 'Procedimentos',
                thead:
                    <tr>
                        <td className='coluna-1 text-left'>Código</td>
                        <td className='coluna-4'>Descrição</td>
                        <td className='coluna-1 text-center'>Porte</td>
                        <td className='coluna-1 text-right'>Valor</td>
                        <td className='coluna-1 text-right'>Valor Diferenciado</td>
                    </tr>,
                tbody: obj.procedimentos && obj.procedimentos.map(y => <tr>
                    <td className='text-left'>{y.procedimento}</td>
                    <td className='text-left'>{y.descricao}</td>
                    <td className='text-center'>{y.porte}</td>
                    <td className='text-right'>{y.valor_padrao_formatado}</td>
                    <td className='text-right'>{y.valor_diferenciado_formatado}</td>
                </tr>
                )
            })
        }

        if (obj.procedimentos_acomodacoes) {

            obj.procedimentos_acomodacoes && obj.procedimentos_acomodacoes.map(y => {
                arrayVisualizacao.push({
                    tipoGrupo: 'tabela',
                    titulo: 'Procedimento Acomodação - ' + y.acomodacao.label,
                    thead:
                        <tr>
                            <td className='coluna-2'>Procedimento</td>
                            <td className='coluna-2 text-right'>Valor</td>
                        </tr>,
                    tbody: y.procedimentos.map(x =>
                        <tr>
                            <td>{x.procedimento.label}</td>
                            <td className='text-right'>{formatMoeda(x.valor)}</td>
                        </tr>
                    )
                })
            })

        }

        if (obj.tabelas.length) {
            let acomodacoesTab = []
            obj.tabelas.filter(x => x.acomodacoes).map(x => {
                acomodacoesTab = acomodacoesTab.concat(acomodacoesTab, x.acomodacoes)
            })

            let acomIds = acomodacoesTab.filter((item, idx, arrayAtual) => {
                
                
                return arrayAtual.findIndex(x => item.acomodacao.value === x.acomodacao.value) === idx}).map(item => item.acomodacao)
            

            acomIds.map(acom => {
                arrayVisualizacao.push({
                    tipoGrupo: 'tabela',
                    titulo: 'Porte Acomodação - ' + acom.label,
                    thead:
                        <tr>
                            <td className='coluna-4'>Tabela</td>
                            <td className='coluna-3'>Acomodação</td>
                            <td className='coluna-1'>Porte</td>
                            <td className='coluna-2 text-right'>Valor Porte</td>
                            <td className='coluna-1 text-right'>Urgência</td>
                            <td className='coluna-1 text-right'>Vídeo</td>
                        </tr>,
                    tbody: acomodacoesTab.filter(y => y.acomodacao.value === acom.value).map(y => <tr>
                        <td>{y.tabela.label}</td>
                        <td>{y.acomodacao.label}</td>
                        <td>{y.porte}</td>
                        <td className='text-right'>{formatMoeda(y.valor)}</td>
                        <td className='text-right'>{formatTwoPlace(y.urgencia) + '%'}</td>
                        <td className='text-right'>{formatTwoPlace(y.video) + '%'}</td>
                    </tr>
                    )
    
                })
            })
        }

        if (obj.tabelas) {
            let portesTab = []
            obj.tabelas.filter(x => x.portes).map(x => {
                
                portesTab = portesTab.concat(portesTab, x.portes)
            })
            arrayVisualizacao.push({
                tipoGrupo: 'tabela',
                titulo: 'Porte Anestésico',
                thead:
                    <tr>
                        <td className='coluna-4'>Tabela</td>
                        <td className='coluna-2'>Porte</td>
                        <td className='coluna-2 text-right'>Valor Porte</td>
                        <td className='coluna-2 text-right'>Urgência</td>
                        <td className='coluna-2 text-right'>Vídeo</td>
                    </tr>,
                tbody: portesTab.map(y => <tr>
                    <td>{y.tabela.label}</td>
                    <td>{y.porte}</td>
                    <td className='text-right'>{y.valor_formatado}</td>
                    <td className='text-right'>{formatTwoPlace(y.urgencia) + '%'}</td>
                    <td className='text-right'>{formatTwoPlace(y.video) + '%'}</td>
                </tr>
                )
            })
        }

        if (obj.odontologico) {
            arrayVisualizacao.push({
                tipoGrupo: 'tabela',
                titulo: 'Odontológica',
                thead:
                    <tr>
                        <td className='coluna-1'>Hora Inicial</td>
                        <td className='coluna-1'>Hora Final</td>
                        <td className='coluna-2'>Local Atendimento</td>
                        <td className='coluna-1 text-right'>Valor</td>
                        <td className='coluna-1 text-right'>Valor (Med./Oxig.)</td>
                    </tr>,
                tbody: obj.odontologico && obj.odontologico.map(y => <tr>
                    <td>{y.hora_inicial}</td>
                    <td>{y.hora_final}</td>
                    <td>{y.local_atendimento.label}</td>
                    <td className='text-right'>{formatMoeda(y.valor)}</td>
                    <td className='text-right'>{formatMoeda(y.valor_med)}</td>
                </tr>
                )
            })
        }

        return arrayVisualizacao
    }

    useEffect(() => {
        visualizar('/faturamento/contratoparticular', id,
            obj => {
                
                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Contrato',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Estabelecimento Saúde', descricao: obj.estabelecimento_saude.label, tamanho: 'col-lg-4' },
                                { titulo: 'Status', descricao: obj.status.label, tamanho: 'col-lg-4' },
                                { titulo: 'Número', descricao: obj.numero_contrato, tamanho: 'col-lg-4' },
                                { titulo: 'Vigência Inicial', descricao: obj.vigencia_inicial, tamanho: 'col-lg-4' },
                                { titulo: 'Vigência Final', descricao: obj.vigencia_final, tamanho: 'col-lg-4' },
                                { titulo: 'Odontológico', descricao: obj.odontologica.label, tamanho: 'col-lg-4' },
                                { titulo: 'Data de Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                                { titulo: 'Descrição', descricao: obj.descricao, tamanho: 'col-lg-4' },
                            ]
                        }
                    ].concat(porte(obj))
                } : { titulo: 'Visualização Contrato Particular' })
            }
        )
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}