import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import Login from '../areas/controleAcesso/usuario/pages/Login';
import RecuperacaoSenha from '../areas/controleAcesso/usuario/pages/RecuperacaoSenha';
import NovaSenha from '../areas/controleAcesso/usuario/pages/NovaSenha';
import { getAutenticado, logout, getUsuario } from './Configuracoes'
import WebService from '../components/WebService'
import { ACOES } from './Enums'
import { get } from './AcessoApi'
import NaoEncontrado from '../areas/controleAcesso/util/pages/NaoEncontrado'
import Dashboard from '../areas/shared/Dashboard'
import Home from '../areas/shared/Home'

import SemPermissao from '../areas/shared/SemPermissao'
import SemAcesso from '../areas/shared/SemAcesso'
import ListagemPadrao from '../areas/shared/ListagemPadrao'
import ListagemAlternativa from '../areas/shared/ListagemAlternativa'
import moment from "moment"
import ls from 'local-storage'

import CadastroUsuario from '../areas/controleAcesso/usuario/pages/Cadastro'
import ConsultaUsuario from '../areas/controleAcesso/usuario/pages/Consulta'
import AlteracaoSenha from '../areas/controleAcesso/usuario/pages/AlteracaoSenha'
import CadastroExterno from '../areas/controleAcesso/usuario/pages/CadastroExterno'

import ConsultaModulo from '../areas/controleAcesso/modulo/pages/Consulta'
import CadastroModulo from '../areas/controleAcesso/modulo/pages/Cadastro'

import ConsultaGrupo from '../areas/controleAcesso/grupo/pages/Consulta'
import CadastroGrupo from '../areas/controleAcesso/grupo/pages/Cadastro'
import VisualizacaoGrupo from '../areas/controleAcesso/grupo/pages/Visualizacao'

import ConsultaAuditoria from '../areas/controleAcesso/auditoria/pages/Consulta'
import ConsultaAuditoriaApp from '../areas/controleAcesso/auditoria-app/pages/Consulta'

import ConsultaUsuarioApp from '../areas/controleAcesso/usuario-app/pages/Consulta'
import CadastroUsuarioApp from '../areas/controleAcesso/usuario-app/pages/Cadastro'
import VisualizacaoUsuarioApp from '../areas/controleAcesso/usuario-app/pages/Visualizacao'

import ConsultaOperadoraSaude from '../areas/administracao/operadora-saude/pages/Consulta'
import CadastroOperadoraSaude from '../areas/administracao/operadora-saude/pages/Cadastro'
import VisualizacaoOperadoraSaude from '../areas/administracao/operadora-saude/pages/Visualizacao'

import ConsultaCbos from '../areas/parametros/cbos/pages/Consulta'
import Cadastro from '../areas/parametros/cbos/pages/Cadastro'
import VisualizacaoCbos from '../areas/parametros/cbos/pages/Visualizacao'

import ConsultaCirurgiao from '../areas/administracao/cirurgiao/pages/Consulta'
import CadastroCirurgiao from '../areas/administracao/cirurgiao/pages/Cadastro'
import VisualizacaoCirurgiao from '../areas/administracao/cirurgiao/pages/Visualizacao'

import VisualizacaoUsuario from '../areas/controleAcesso/usuario/pages/Visualizacao'

import ConsultaEmpresa from '../areas/administracao/empresa/pages/Consulta'
import CadastroEmpresa from '../areas/administracao/empresa/pages/Cadastro'
import VisualizacaoEmpresa from '../areas/administracao/empresa/pages/Visualizacao'

import ConsultaLiberacaoFerias from '../areas/administracao/liberacao-ferias/Consulta'
import CadastroLiberacaoFerias from '../areas/administracao/liberacao-ferias/Cadastro'
import VisualizacaoLiberacaoFerias from '../areas/administracao/liberacao-ferias/Visualizacao'

import ConsultaSocio from '../areas/administracao/socio/pages/Consulta'
import CadastroSocio from '../areas/administracao/socio/pages/Cadastro'
import VisualizacaoSocio from '../areas/administracao/socio/pages/Visualizacao'

import CadastroEstabelecimentoSaude from '../areas/administracao/estabelecimento_saude/pages/Cadastro'
import ConsultaEstabelecimentoSaude from '../areas/administracao/estabelecimento_saude/pages/Consulta'
import VisualizacaoEstabelecimentoSaude from '../areas/administracao/estabelecimento_saude/pages/Visualizacao'

import ConsultaEscalador from '../areas/administracao/escalador/pages/Consulta'
import CadastroEscalador from '../areas/administracao/escalador/pages/Cadastro'
import VisualizacaoEscalador from '../areas/administracao/escalador/pages/Visualizacao'

import ConsultaTabelaProcedimento from '../areas/faturamento/tabela-procedimento/pages/Consulta'
import CadastroTabelaProcedimento from '../areas/faturamento/tabela-procedimento/pages/Cadastro'
import VisualizacaoTabelaProcedimento from '../areas/faturamento/tabela-procedimento/pages/Visualizacao'

import CadastroMovimentoFinanceiro from '../areas/financeiro/movimento/pages/Cadastro'
import ConsultaMovimentoFinanceiro from '../areas/financeiro/movimento/pages/Consulta'

import VisualizacaoMovimentoFinanceiro from '../areas/financeiro/movimento/pages/Visualizacao'
import VisualizacaoComAssinaturaMovimentoFinanceiro from '../areas/financeiro/movimento/pages/VisualizacaoAssinatura'

import CadastroLancamentoFinanceiro from '../areas/financeiro/lancamento/pages/Cadastro'
import ConsultaLancamentoFinanceiro from '../areas/financeiro/lancamento/pages/Consulta';
import VisualizacaoLancamentoFinanceiro from '../areas/financeiro/lancamento/pages/Visualizacao'

import CadastroContaBancaria from '../areas/financeiro/conta-bancaria/pages/Cadastro';
import ConsultaContaBancaria from '../areas/financeiro/conta-bancaria/pages/Consulta';
import VisualizacaoContaBancaria from '../areas/financeiro/conta-bancaria/pages/Visualizacao';

import CadastroTributo from '../areas/financeiro/tributo/pages/Cadastro';
import ConsultaTributo from '../areas/financeiro/tributo/pages/Consulta';
import VisualizacaoTributo from '../areas/financeiro/tributo/pages/Visualizacao';

import CadastroTributoAliquota from '../areas/financeiro/tributo-aliquota/pages/Cadastro';
import ConsultaTributoAliquota from '../areas/financeiro/tributo-aliquota/pages/Consulta';
import VisualizacaoTributoAliquota from '../areas/financeiro/tributo-aliquota/pages/Visualizacao';

import ConsultaLancamento from '../areas/financeiro/lancamento-financeiro/pages/Consulta'
import CadastroLancamento from '../areas/financeiro/lancamento-financeiro/pages/Cadastro'
import VisualizacaoLancamento from '../areas/financeiro/lancamento-financeiro/pages/Visualizacao'


import CadastroBanco from '../areas/financeiro/banco/pages/Cadastro'
import ConsultaBanco from '../areas/financeiro/banco/pages/Consulta';
import VisualizacaoBanco from '../areas/financeiro/banco/pages/Visualizacao'

import CadastroCliente from '../areas/financeiro/cliente/pages/Cadastro'
import ConsultaCliente from '../areas/financeiro/cliente/pages/Consulta';
import VisualizacaoCliente from '../areas/financeiro/cliente/pages/Visualizacao';

import CadastroCheque from '../areas/financeiro/cheque/pages/Cadastro'
import ConsultaCheque from '../areas/financeiro/cheque/pages/Consulta'
import VisualizacaoCheque from '../areas/financeiro/cheque/pages/Visualizacao'

import CadastroCategoria from '../areas/financeiro/categoria/pages/Cadastro'
import ConsultaCategoria from '../areas/financeiro/categoria/pages/Consulta'
import VisualizacaoCategoria from '../areas/financeiro/categoria/pages/Visualizacao'

import CadastroNotaFiscal from '../areas/financeiro/nota-fiscal/pages/Cadastro'
import ConsultaNotaFiscal from '../areas/financeiro/nota-fiscal/pages/Consulta'
import VisualizacaoNotaFiscal, { VisualizacaoErrosNota } from '../areas/financeiro/nota-fiscal/pages/Visualizacao'

import ConsultaProcedimento from '../areas/faturamento/procedimento/pages/Consulta'
import CadastroProcedimento from '../areas/faturamento/procedimento/pages/Cadastro'
import VisualizacaoProcedimento from '../areas/faturamento/procedimento/pages/Visualizacao'

import AgendaEscalador from '../areas/administracao/agenda-escalador/pages/Consulta'
import CadastroAgendaEscalador from '../areas/administracao/agenda-escalador/pages/Cadastro'

import ConsultaSocioOcorrencia from '../areas/administracao/socio-ocorrencia/pages/Consulta'
import CadastroSocioOcorrencia from '../areas/administracao/socio-ocorrencia/pages/Cadastro'
import VisualizacaoSocioOcorrencia from '../areas/administracao/socio-ocorrencia/pages/Visualizacao'
import ListagemOcorrencia from '../areas/relatorios/socio/Ocorrencias'

import ConsultaSocioTipoOcorrencia from '../areas/administracao/socio-tipo-ocorrencia/pages/Consulta';
import CadastroTipoOcorrencia from '../areas/administracao/socio-tipo-ocorrencia/pages/Cadastro';
import VisualizacaoSocioTipoOcorrencia from '../areas/administracao/socio-tipo-ocorrencia/pages/Visualizacao';

import ConsultaDepartamento from '../areas/administracao/departamento/pages/Consulta'
import CadastroDepartamento from '../areas/administracao/departamento/pages/Cadastro'
import VisualizacaoDepartamento from '../areas/administracao/departamento/pages/Visualizacao'

import ConsultaAcomodacao from '../areas/faturamento/acomodacao/pages/Consulta'
import CadastroAcomodacao from '../areas/faturamento/acomodacao/pages/Cadastro'
import VisualizacaoAcomodacao from '../areas/faturamento/acomodacao/pages/Visualizacao'

import ConsultaBeneficiario from '../areas/faturamento/beneficiario/pages/Consulta'
import CadastroBeneficiario from '../areas/faturamento/beneficiario/pages/Cadastro'
import VisualizacaoBeneficiario from '../areas/faturamento/beneficiario/pages/Visualizacao'
import ConsultaCid10 from '../areas/faturamento/cid10/pages/Consulta'
import CadastroCid10 from '../areas/faturamento/cid10/pages/Cadastro'
import VisualizacaoCid10 from '../areas/faturamento/cid10/pages/Visualizacao'

import ConsultaContrato from '../areas/faturamento/contrato/pages/Consulta'
import CadastroContrato from '../areas/faturamento/contrato/pages/Cadastro'
import VisualizacaoContrato from '../areas/faturamento/contrato/pages/Visualizacao'

import ConsultaContratoParticular from '../areas/faturamento/contrato-particular/pages/Consulta'
import CadastroParticularContrato, { CadastroContratoParticular } from '../areas/faturamento/contrato-particular/pages/Cadastro'
import VisualizacaoContratoParticular from '../areas/faturamento/contrato-particular/pages/Visualizacao'

import ConsultaLote from '../areas/faturamento/lote/pages/Consulta'
import CadastroLote from '../areas/faturamento/lote/pages/Cadastro'
import VisualizacaoLote, { VisualizacaoArquivoXmlLote, VisualizacaoRespostaEnvioLote, VisualizacaoLotesAutomaticos } from '../areas/faturamento/lote/pages/Visualizacao'

import CadastroGuia from '../areas/faturamento/guia/pages/Cadastro'
import VisualizacaoGuia, { VisualizacaoRespostaEnvio, VisualizacaoArquivoXml } from '../areas/faturamento/guia/pages/Visualizacao'
import VisualizacaoGuiaConvenioPagamento from '../areas/faturamento/guia/pages/VisualizacaoGuiaPagamento';
import ConsultaGuia from '../areas/faturamento/guia/pages/Consulta'
import ManutencaoLote from '../areas/faturamento/manutencao-lote/pages/Cadastro'
import ManutencaoStatus from '../areas/faturamento/manutencao-status/pages/Cadastro'
import Conferencia from '../areas/faturamento/conferencia/pages/Cadastro'

import CadastroGuiaParticular from '../areas/faturamento/guia-particular/pages/Cadastro'
import VisualizacaoGuiaParticular from '../areas/faturamento/guia-particular/pages/Visualizacao'
import ConsultaGuiaParticular from '../areas/faturamento/guia-particular/pages/Consulta'
import ManutencaoStatusParticular from '../areas/faturamento/manutencao-status-particular/pages/Cadastro'

import CadastroAnestesiaOdonto from '../areas/faturamento/anestesia-odonto/pages/Cadastro'
import VisualizacaoAnestesiaOdonto from '../areas/faturamento/anestesia-odonto/pages/Visualizacao'
import ConsultaAnestesiaOdonto from '../areas/faturamento/anestesia-odonto/pages/Consulta'

import CadastroPorteParticular from '../areas/faturamento/porte-particular/pages/Cadastro'
import VisualizacaoPorteParticular from '../areas/faturamento/porte-particular/pages/Visualizacao'
import ConsultaPorteParticular from '../areas/faturamento/porte-particular/pages/Consulta'

import ConsultaParametroOperadora from '../areas/faturamento/parametro-operadora-saude/pages/Consulta'
import CadastroParametroOperadora from '../areas/faturamento/parametro-operadora-saude/pages/Cadastro'
import VisualizacaoParametroOperadora from '../areas/faturamento/parametro-operadora-saude/pages/Visualizacao'

import AgendaAssociado from '../areas/administracao/agenda-associado/pages/Consulta'
import CadastroAgendaAssociado from '../areas/administracao/agenda-associado/pages/Cadastro'
import VisualizacaoAgendaAssociado from '../areas/administracao/agenda-associado/pages/Visualizacao'

import RelatorioSocio from '../areas/relatorios/socio/Relatorio'
import RelatorioEscala from '../areas/relatorios/agendamento/Relatorio'
import RelatorioProcedimento from '../areas/relatorios/procedimento/Relatorio'
import RelatorioCirurgiao from '../areas/relatorios/cirurgiao/Relatorio'
import RelatorioPlantao from '../areas/relatorios/plantao/Relatorio'
import RelatorioFerias from '../areas/relatorios/ferias/Relatorio'
import RelatorioMercado from '../areas/relatorios/mercado/Relatorio'
import RelatorioOcorrencia from '../areas/relatorios/ocorrencia/Relatorio'
import RelatorioFaturamento from '../areas/relatorios/guia/Relatorio'
import RelatorioGuiasMapaHoras from '../areas/relatorios/guia_mapa_hora/Relatorio'
import RelatorioFatura from '../areas/relatorios/faturamento/Relatorio'
import RelatorioGuiasEnviadas from '../areas/relatorios/guias_enviadas/Relatorio'
import RelatorioMovimentoFinanceiro from '../areas/relatorios/movimento-financeiro/Relatorio'
import RelatorioLancamentoMovimento from '../areas/relatorios/lancamento/Relatorio'
import RelatorioCliente from '../areas/relatorios/cliente/Relatorio'
import RelatorioContasPagarReceber from '../areas/relatorios/contas_pagar/Relatorio'
import RelatorioLote from '../areas/relatorios/lote/Relatorio'
import RelatorioMapaHora from '../areas/relatorios/guia_mapa_hora/Relatorio'
import RelatorioAuditoria from '../areas/relatorios/auditoria/Relatorio';
import ConsultaRegistroAtividades from '../areas/administracao/registro-atividades/pages/Consulta'
import CadastroRegistroAtividades from '../areas/administracao/registro-atividades/pages/Cadastro'
import VisualizacaoRegistroAtividades from '../areas/administracao/registro-atividades/pages/Visualizacao'
import RelatorioNotaFiscal from '../areas/relatorios/nota-fiscal/Relatorio'
import RelatorioExtratoBancarioDiario from '../areas/relatorios/extrato_bancario/Relatorio'
import RelatorioContasPagarReceberSintetico from '../areas/relatorios/contas_pagar_sintetico/Relatorio'


import ConsultaPlantaoAlcancaveis from '../areas/administracao/plantao-alcancaveis/pages/Consulta'
import CadastroPlantaoAlcancaveis from '../areas/administracao/plantao-alcancaveis/pages/Cadastro'
import VisualizacaoPlantaoAlcancaveis from '../areas/administracao/plantao-alcancaveis/pages/Visualizacao'

import VisualizacaoPlantaoEstabelecimento from '../areas/administracao/plantao-estabelecimento/pages/Visualizacao'

import ConsultaRotinas from '../areas/administracao/rotinas/pages/Consulta'

import ConsultaPeriodoGozo from '../areas/administracao/ferias/periodo-gozo/pages/Consulta'
import CadastroPeriodoGozo from '../areas/administracao/ferias/periodo-gozo/pages/Cadastro'
import VisualizacaoPeriodoGozo from '../areas/administracao/ferias/periodo-gozo/pages/Visualizacao'

import ConsultaLancamentoGozo from '../areas/administracao/ferias/lancamento/pages/Consulta'
import CadastroLancamentoGozo from '../areas/administracao/ferias/lancamento/pages/Cadastro'
import VisualizacaoLancamentoGozo from '../areas/administracao/ferias/lancamento/pages/Visualizacao'


import ConsultaMarcacao from '../areas/administracao/ferias/marcacao/pages/Consulta'
import CadastroMarcacao from '../areas/administracao/ferias/marcacao/pages/Cadastro'
import VisualizacaoMarcacao from '../areas/administracao/ferias/marcacao/pages/Visualizacao'

import ConsultaMercado from '../areas/administracao/ferias/mercado/pages/Consulta'
import CadastroMercado from '../areas/administracao/ferias/mercado/pages/Cadastro'
import VisualizacaoMercado from '../areas/administracao/ferias/mercado/pages/Visualizacao'

import ConsultaGrupoEscala from '../areas/administracao/grupo-escala/pages/Consulta'
import CadastroGrupoEscala from '../areas/administracao/grupo-escala/pages/Cadastro'
import VisualizacaoGrupoEscala from '../areas/administracao/grupo-escala/pages/Visualizacao'

import ConsultaFeriados from '../areas/administracao/feriados/pages/Consulta'
import CadastroFeriados from '../areas/administracao/feriados/pages/Cadastro'
import VisualizacaoFeriados from '../areas/administracao/feriados/pages/Visualizacao'

import ConsultaNotificacao from '../areas/administracao/notificacao/pages/Consulta'
import CadastroNotificacao from '../areas/administracao/notificacao/pages/Cadastro'
import VisualizacaoNotificacao from '../areas/administracao/notificacao/pages/Visualizacao'

import ConsultaDireitoFolga from '../areas/administracao/direito-folga/pages/Consulta'
import CadastroDireitoFolga from '../areas/administracao/direito-folga/pages/Cadastro'
import VisualizacaoDireitoFolga from '../areas/administracao/direito-folga/pages/Visualizacao'

import ConsultaReferenciaProducao from '../areas/controleProducao/referencia-producao/pages/Consulta'
import CadastroReferenciaProducao from '../areas/controleProducao/referencia-producao/pages/Cadastro'
import VisualizacaoReferenciaProducao from '../areas/controleProducao/referencia-producao/pages/Visualizacao'
import ConsultaGuiaMapaHoras from '../areas/controleProducao/guias-mapa-horas/pages/Consulta'
import DetalhesGuia from '../areas/controleProducao/guias-mapa-horas/pages/DetalhesGuia'

import ConsultaGuiaPagamento from '../areas/auditoria/guia-pagamento/pages/Consulta'
import VisualizacaoGuiaPagamento from '../areas/auditoria/guia-pagamento/pages/Visualizacao'

import Lixeira from '../areas/controleAcesso/lixeira/pages/Consulta'

import Rascunho from '../areas/controleAcesso/rascunho/pages/Consulta'

import ProducaoFaturamentoBI from '../areas/analiseDados/producao-faturamento/page'

import Layout from './Layout'
import Recibo from '../components/Recibo';

const PrivateRoute = (propsG) => {
    const { component: Component, fazerAuditoria, configState, ...rest } = propsG
    const [temPermissao, setTemPermissao] = useState(false)
    const [validou, setValidou] = useState(false)
    const [menuAberto, setMenuAberto] = useState(true)

    const user = getUsuario()

    const removerDataConsultaAgenda = (propsG) => {
        if (propsG.modulo != 38) {
            // ls.remove('ultima_data_consulta_agenda')
        }
    }

    const history = useHistory();
    useEffect(() => {


        if (user) {
            removerDataConsultaAgenda(propsG);
            //permitido
            const objPermissao = {
                usuario_id: (user && user.id) || 0,
                modulo_id: propsG.modulo,
                acao_id: propsG.acao
            }

            if (propsG.modulo === undefined || propsG.modulo === 4) {
                setValidou(true)
                setTemPermissao(true)
            }
            else {
                get('/controleAcesso/permissao', objPermissao).then(val => {
                    if (val.length)
                        setTemPermissao(true);
                    else
                        setTemPermissao(false);

                    setValidou(true)
                }).catch(e => {
                    setTemPermissao(false);
                    setValidou(true)
                })
            }

            if (!propsG.path.includes('logout') || !propsG.path.includes('login'))
                ls.set('ultimo_hora_login', moment())

        }
    }, [propsG, propsG.path, user])

    let redirecinarParaHome = propsG.path.includes('dashboard') && ls.get('permissaoDashboard') === false;
    if (redirecinarParaHome) {
        setTimeout(() => {
            window.location.reload();
        }, 1000);
        return (<Redirect to={{ pathname: '/home', state: { from: propsG.location, ...configState } }} />)
    }

    if (!user)
        return (<Redirect to={{ pathname: '/login', state: { from: propsG.location, ...configState } }} />)
    if (validou == true)
        return (<Layout setMenuAberto={setMenuAberto} temPermissao={propsG.modulo == 4 ? true : temPermissao}><Route {...rest} render={props =>
            getAutenticado() ? (<Component menuAberto={menuAberto}  {...props} configState={configState} modulo={propsG.modulo} />) : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
        } /></Layout>)
    else
        return <></>
}
const reload = () => {
    if (window.localStorage) {
        if (!localStorage.getItem('firstLoad')) {
            localStorage['firstLoad'] = true;
            window.location.reload();
        }
        else
            localStorage.removeItem('firstLoad');
    }
}

const LoginRoute = ({ component: Component, realizarLogout, ...rest }) => {

    if (realizarLogout) {
        logout()
        reload()
    }

    return (<Route {...rest} render={props =>
        getAutenticado() ? (<Redirect to={{ pathname: ls.get('permissaoDashboard') === false ? '/home' : '/dashboard', state: { from: props.location } }} />) : (<Component {...props} />)
    } />)
}

const Routes = (rProps) => (
    <BrowserRouter>
        <Switch>
            {/* CONTROLE ACESSO */}
            <PrivateRoute modulo={2} acao={ACOES.INSERIR} path='/controleAcesso/grupo/cadastro' active='/controleAcesso/grupo/consulta' component={(props) => <CadastroGrupo {...props} />} />
            <PrivateRoute modulo={2} acao={ACOES.ALTERAR} path='/controleAcesso/grupo/alteracao/:id' active='/controleAcesso/grupo/consulta' component={(props) => <CadastroGrupo {...props} idSubmenu="" />} />
            <PrivateRoute modulo={2} acao={ACOES.CONSULTAR} path='/controleAcesso/grupo/consulta' component={(props) => <ConsultaGrupo {...props} />} />
            <PrivateRoute modulo={2} acao={ACOES.VISUALIZAR} path='/controleAcesso/grupo/visualizacao/:id' active='/controleAcesso/grupo/consulta' component={(props) => <VisualizacaoGrupo {...props} />} />

            <PrivateRoute modulo={1} acao={ACOES.INSERIR} path='/controleAcesso/usuario/cadastro' active='/controleAcesso/usuario/consulta' component={(props) => <CadastroUsuario {...props} />} />
            <PrivateRoute modulo={1} acao={ACOES.ALTERAR} path='/controleAcesso/usuario/alteracao/:id' active='/controleAcesso/usuario/consulta' component={(props) => <CadastroUsuario {...props} />} />
            <PrivateRoute modulo={1} acao={ACOES.ALTERAR} path='/controleAcesso/usuario/alteracaoSenha' component={(props) => <AlteracaoSenha {...props} />} />
            <PrivateRoute modulo={1} acao={ACOES.CONSULTAR} path='/controleAcesso/usuario/consulta' component={(props) => <ConsultaUsuario {...props} />} />
            <PrivateRoute modulo={1} acao={ACOES.VISUALIZAR} path='/controleAcesso/usuario/visualizacao/:id' active='/controleAcesso/usuario/consulta' component={(props) => <VisualizacaoUsuario {...props} />} />
            <Route path='/controleAcesso/usuarioExterno/cadastro/:id' component={(props) => <CadastroExterno {...props} />} />
            <PrivateRoute modulo={42} acao={ACOES.CONSULTAR} path='/controleAcesso/auditoria/consulta' component={(props) => <ConsultaAuditoria {...props} />} />
            <PrivateRoute modulo={48} acao={ACOES.CONSULTAR} path='/controleAcesso/auditoria-app/consulta' component={(props) => <ConsultaAuditoriaApp {...props} />} />

            <PrivateRoute modulo={44} acao={ACOES.INSERIR} path='/controleAcesso/usuarioApp/cadastro' active='/controleAcesso/usuarioApp/consulta' component={(props) => <CadastroUsuarioApp {...props} />} />
            <PrivateRoute modulo={44} acao={ACOES.ALTERAR} path='/controleAcesso/usuarioApp/alteracao/:id' active='/controleAcesso/usuarioApp/consulta' component={(props) => <CadastroUsuarioApp {...props} idSubmenu="" />} />
            <PrivateRoute modulo={44} acao={ACOES.CONSULTAR} path='/controleAcesso/usuarioApp/consulta' component={(props) => <ConsultaUsuarioApp {...props} />} />
            <PrivateRoute modulo={44} acao={ACOES.VISUALIZAR} path='/controleAcesso/usuarioApp/visualizacao/:id' active='/controleAcesso/usuarioApp/consulta' component={(props) => <VisualizacaoUsuarioApp {...props} />} />

            <PrivateRoute modulo={3} acao={ACOES.INSERIR} path='/controleAcesso/modulo/cadastro' active='/controleAcesso/modulo/consulta' component={(props) => <CadastroModulo {...props} />} />
            <PrivateRoute modulo={3} acao={ACOES.ALTERAR} path='/controleAcesso/modulo/alteracao/:id' active='/controleAcesso/modulo/consulta' component={(props) => <CadastroModulo {...props} idSubmenu="" />} />
            <PrivateRoute modulo={3} acao={ACOES.CONSULTAR} path='/controleAcesso/modulo/consulta' component={(props) => <ConsultaModulo {...props} />} />

            <PrivateRoute modulo={4} path='/semPermissao' component={(props) => <SemPermissao {...props} />} />
            <PrivateRoute modulo={4} path='/semAcesso' component={(props) => <SemAcesso {...props} />} />

            <LoginRoute path='/login' component={(props) => <Login {...props} />} />
            <LoginRoute exact path='/' component={() => <Login />} />
            <LoginRoute path='/logout' component={(props) => <Login {...props} />} realizarLogout={true} />
            <Route path='/recuperarSenha' component={(props) => <RecuperacaoSenha  {...props} />} />
            <Route path='/novasenha/:token' component={(props) => <NovaSenha  {...props} />} />
            {/* ADMINISTRACAO */}
            <PrivateRoute modulo={5} acao={ACOES.INSERIR} path='/administracao/empresa/cadastro' component={(props) => <CadastroEmpresa {...props} />} />
            <PrivateRoute modulo={5} acao={ACOES.ALTERAR} path='/administracao/empresa/alteracao/:id' active='/administracao/empresa/consulta' component={(props) => <CadastroEmpresa {...props} />} />
            <PrivateRoute modulo={5} acao={ACOES.CONSULTAR} path='/administracao/empresa/consulta' component={(props) => <ConsultaEmpresa {...props} />} />
            <PrivateRoute modulo={5} acao={ACOES.VISUALIZAR} path='/administracao/empresa/visualizacao/:id' active='/administracao/empresa/consulta' component={(props) => <VisualizacaoEmpresa {...props} />} />

            <PrivateRoute modulo={6} acao={ACOES.INSERIR} path='/administracao/socio/cadastro' component={(props) => <CadastroSocio {...props} />} />
            <PrivateRoute modulo={6} acao={ACOES.ALTERAR} path='/administracao/socio/alteracao/:id' active='/administracao/socio/consulta' component={(props) => <CadastroSocio {...props} />} />
            <PrivateRoute modulo={6} acao={ACOES.CONSULTAR} path='/administracao/socio/consulta' component={(props) => <ConsultaSocio {...props} />} />
            <PrivateRoute modulo={6} acao={ACOES.VISUALIZAR} path='/administracao/socio/visualizacao/:id' active='/administracao/socio/consulta' component={(props) => <VisualizacaoSocio {...props} />} />

            <PrivateRoute modulo={8} acao={ACOES.INSERIR} path='/administracao/operadoraSaude/cadastro' active='/administracao/operadoraSaude/consulta' component={(props) => <CadastroOperadoraSaude {...props} />} />
            <PrivateRoute modulo={8} acao={ACOES.ALTERAR} path='/administracao/operadoraSaude/alteracao/:id' active='/administracao/operadoraSaude/consulta' component={(props) => <CadastroOperadoraSaude {...props} idSubmenu="" />} />
            <PrivateRoute modulo={8} acao={ACOES.CONSULTAR} path='/administracao/operadoraSaude/consulta' component={(props) => <ConsultaOperadoraSaude {...props} {...rProps} />} />
            <PrivateRoute modulo={8} acao={ACOES.VISUALIZAR} path='/administracao/operadoraSaude/visualizacao/:id' active='/administracao/operadoraSaude/consulta' component={(props) => <VisualizacaoOperadoraSaude {...props} />} />

            <PrivateRoute modulo={9} acao={ACOES.INSERIR} path='/administracao/estabelecimentoSaude/cadastro' component={(props) => <CadastroEstabelecimentoSaude {...props} />} />
            <PrivateRoute modulo={9} acao={ACOES.ALTERAR} path='/administracao/estabelecimentoSaude/alteracao/:id' active='/administracao/estabelecimentoSaude/consulta' component={(props) => <CadastroEstabelecimentoSaude {...props} />} />
            <PrivateRoute modulo={9} acao={ACOES.CONSULTAR} path='/administracao/estabelecimentoSaude/consulta' component={(props) => <ConsultaEstabelecimentoSaude {...props}{...rProps} />} />
            <PrivateRoute modulo={9} acao={ACOES.VISUALIZAR} path='/administracao/estabelecimentoSaude/visualizacao/:id' active='/administracao/estabelecimentoSaude/consulta' component={(props) => <VisualizacaoEstabelecimentoSaude {...props} />} />

            <PrivateRoute modulo={10} acao={ACOES.INSERIR} path='/administracao/cirurgiao/cadastro' component={(props) => <CadastroCirurgiao {...props} />} />
            <PrivateRoute modulo={10} acao={ACOES.ALTERAR} path='/administracao/cirurgiao/alteracao/:id' active='/administracao/cirurgiao/visualizacao' component={(props) => <CadastroCirurgiao {...props} idSubmenu="" />} />
            <PrivateRoute modulo={10} acao={ACOES.CONSULTAR} path='/administracao/cirurgiao/consulta' component={(props) => <ConsultaCirurgiao {...props} />} />
            <PrivateRoute modulo={10} acao={ACOES.VISUALIZAR} path='/administracao/cirurgiao/visualizacao/:id' active='/administracao/cirurgiao/visualizacao' component={(props) => <VisualizacaoCirurgiao {...props} />} />

            <PrivateRoute modulo={11} acao={ACOES.INSERIR} path='/administracao/escalador/cadastro' component={(props) => <CadastroEscalador {...props} />} />
            <PrivateRoute modulo={11} acao={ACOES.ALTERAR} path='/administracao/escalador/alteracao/:id' active='/administracao/cirurgiao/visualizacao' component={(props) => <CadastroEscalador {...props} idSubmenu="" />} />
            <PrivateRoute modulo={11} acao={ACOES.CONSULTAR} path='/administracao/escalador/consulta' component={(props) => <ConsultaEscalador {...props} />} />
            <PrivateRoute modulo={11} acao={ACOES.VISUALIZAR} path='/administracao/escalador/visualizacao/:id' active='/administracao/cirurgiao/visualizacao' component={(props) => <VisualizacaoEscalador {...props} />} />

            <PrivateRoute modulo={29} acao={ACOES.INSERIR} path='/administracao/departamento/cadastro' component={(props) => <CadastroDepartamento {...props} />} />
            <PrivateRoute modulo={29} acao={ACOES.ALTERAR} path='/administracao/departamento/alteracao/:id' active='/administracao/departamento/visualizacao' component={(props) => <CadastroDepartamento {...props} idSubmenu="" />} />
            <PrivateRoute modulo={29} acao={ACOES.CONSULTAR} path='/administracao/departamento/consulta' component={(props) => <ConsultaDepartamento {...props} />} />
            <PrivateRoute modulo={29} acao={ACOES.VISUALIZAR} path='/administracao/departamento/visualizacao/:id' active='/administracao/departamento/visualizacao' component={(props) => <VisualizacaoDepartamento {...props} />} />

            <PrivateRoute modulo={15} acao={ACOES.CONSULTAR} path='/administracao/agendaescalador/consulta' component={(props) => <AgendaEscalador {...props} />} />
            <PrivateRoute modulo={15} acao={ACOES.CONSULTAR} path='/administracao/agendaescalador/cadastro' component={(props) => <CadastroAgendaEscalador {...props} />} />
            <PrivateRoute modulo={15} acao={ACOES.CONSULTAR} path='/administracao/agendaescalador/alteracao/:id' component={(props) => <CadastroAgendaEscalador {...props} />} />

            <PrivateRoute modulo={17} acao={ACOES.INSERIR} path='/administracao/socio-ocorrencia/cadastro' component={(props) => <CadastroSocioOcorrencia {...props} />} />
            <PrivateRoute modulo={17} acao={ACOES.ALTERAR} path='/administracao/socio-ocorrencia/alteracao/:id' active='/faturamento/Procedimento/consulta' component={(props) => <CadastroSocioOcorrencia {...props} />} />
            <PrivateRoute modulo={17} acao={ACOES.CONSULTAR} path='/administracao/socio-ocorrencia/consulta' component={(props) => <ConsultaSocioOcorrencia {...props} />} />
            <PrivateRoute modulo={17} acao={ACOES.VISUALIZAR} path='/administracao/socio-ocorrencia/visualizacao/:id' active='/faturamento/Procedimento/consulta' component={(props) => <VisualizacaoSocioOcorrencia {...props} />} />
            <PrivateRoute modulo={17} acao={ACOES.CONSULTAR} path='/administracao/socio-ocorrencia/ListagemDiaria' component={(props) => <ListagemOcorrencia {...props} />} />

            <PrivateRoute modulo={124} acao={ACOES.INSERIR} path='/administracao/tipo-ocorrencia/cadastro' component={(props) => <CadastroTipoOcorrencia {...props} />} />
            <PrivateRoute modulo={124} acao={ACOES.ALTERAR} path='/administracao/tipo-ocorrencia/alteracao/:id' active='/faturamento/Procedimento/consulta' component={(props) => <CadastroTipoOcorrencia {...props} />} />
            <PrivateRoute modulo={124} acao={ACOES.CONSULTAR} path='/administracao/tipo-ocorrencia/consulta' component={(props) => <ConsultaSocioTipoOcorrencia {...props} />} />
            <PrivateRoute modulo={124} acao={ACOES.VISUALIZAR} path='/administracao/tipo-ocorrencia/visualizacao/:id' active='/faturamento/Procedimento/consulta' component={(props) => <VisualizacaoSocioTipoOcorrencia {...props} />} />

            <PrivateRoute modulo={49} acao={ACOES.CONSULTAR} path='/administracao/plantao-alcancaveis/consulta' component={(props) => <ConsultaPlantaoAlcancaveis {...props} />} />
            <PrivateRoute modulo={49} acao={ACOES.INSERIR} path='/administracao/plantao-alcancaveis/cadastro' component={(props) => <CadastroPlantaoAlcancaveis {...props} />} />
            <PrivateRoute modulo={49} acao={ACOES.ALTERAR} path='/administracao/plantao-alcancaveis/alteracao/:id' active='/administracao/plantao-alcancaveis/consulta' component={(props) => <CadastroPlantaoAlcancaveis {...props} />} />
            <PrivateRoute modulo={49} acao={ACOES.VISUALIZAR} path='/administracao/plantao-alcancaveis/visualizacao/:id' active='/faturamento/plantao-alcancaveis/consulta' component={(props) => <VisualizacaoPlantaoAlcancaveis {...props} />} />
            <PrivateRoute modulo={53} acao={ACOES.CONSULTAR} path='/administracao/plantao-estabelecimento/consulta' configState={{ telaPadrao: 1 }} component={(props) => <ConsultaPlantaoAlcancaveis {...props} />} />

            <PrivateRoute modulo={56} acao={ACOES.INSERIR} path='/administracao/ferias/periodo-gozo/cadastro' component={(props) => <CadastroPeriodoGozo {...props} />} />
            <PrivateRoute modulo={56} acao={ACOES.ALTERAR} path='/administracao/ferias/periodo-gozo/alteracao/:id' active='/administracao/ferias/periodo-gozo/consulta' component={(props) => <CadastroPeriodoGozo {...props} />} />
            <PrivateRoute modulo={56} acao={ACOES.VISUALIZAR} path='/administracao/ferias/periodo-gozo/visualizacao/:id' active='/administracao/ferias/periodo-gozo/consulta' component={(props) => <VisualizacaoPeriodoGozo {...props} />} />
            <PrivateRoute modulo={56} acao={ACOES.CONSULTAR} path='/administracao/ferias/periodo-gozo/consulta' component={(props) => <ConsultaPeriodoGozo {...props} />} />

            <PrivateRoute modulo={58} acao={ACOES.INSERIR} path='/administracao/ferias/lancamento-saldo/cadastro' component={(props) => <CadastroLancamentoGozo {...props} />} />
            <PrivateRoute modulo={58} acao={ACOES.ALTERAR} path='/administracao/ferias/lancamento-saldo/alteracao/:id' active='/administracao/ferias/lancamento-saldo/consulta' component={(props) => <CadastroLancamentoGozo {...props} />} />
            <PrivateRoute modulo={58} acao={ACOES.CONSULTAR} path='/administracao/ferias/lancamento-saldo/consulta' component={(props) => <ConsultaLancamentoGozo {...props} />} />
            <PrivateRoute modulo={58} acao={ACOES.VISUALIZAR} path='/administracao/ferias/lancamento-saldo/visualizacao/:id' active='/administracao/ferias/lancamento-saldo/consulta' component={(props) => <VisualizacaoLancamentoGozo {...props} />} />

            <PrivateRoute modulo={59} acao={ACOES.CONSULTAR} path='/administracao/ferias/marcacao/consulta' component={(props) => <ConsultaMarcacao {...props} />} />
            <PrivateRoute modulo={59} acao={ACOES.INSERIR} path='/administracao/ferias/marcacao/cadastro' component={(props) => <CadastroMarcacao {...props} />} />
            <PrivateRoute modulo={59} acao={ACOES.ALTERAR} path='/administracao/ferias/marcacao/alteracao/:id' active='/administracao/ferias/marcacao/consulta' component={(props) => <CadastroMarcacao {...props} />} />
            <PrivateRoute modulo={59} acao={ACOES.VISUALIZAR} path='/administracao/ferias/marcacao/visualizacao/:id' active='/administracao/ferias/marcacao/consulta' component={(props) => <VisualizacaoMarcacao {...props} />} />

            <PrivateRoute modulo={60} acao={ACOES.CONSULTAR} path='/administracao/ferias/mercado/consulta' component={(props) => <ConsultaMercado {...props} />} />
            <PrivateRoute modulo={60} acao={ACOES.INSERIR} path='/administracao/ferias/mercado/cadastro' component={(props) => <CadastroMercado {...props} />} />
            <PrivateRoute modulo={60} acao={ACOES.ALTERAR} path='/administracao/ferias/mercado/alteracao/:id' active='/administracao/ferias/mercado/consulta' component={(props) => <CadastroMercado {...props} />} />
            <PrivateRoute modulo={60} acao={ACOES.VISUALIZAR} path='/administracao/ferias/mercado/visualizacao/:id' active='/administracao/ferias/mercado/consulta' component={(props) => <VisualizacaoMercado {...props} />} />

            <PrivateRoute modulo={64} acao={ACOES.CONSULTAR} path='/administracao/grupo-escala/consulta' active='/administracao/grupo-escala/consulta' component={(props) => <ConsultaGrupoEscala {...props} />} />
            <PrivateRoute modulo={64} acao={ACOES.INSERIR} path='/administracao/grupo-escala/cadastro' active='/administracao/grupo-escala/consulta' component={(props) => <CadastroGrupoEscala {...props} />} />
            <PrivateRoute modulo={64} acao={ACOES.ALTERAR} path='/administracao/grupo-escala/alteracao/:id' active='/administracao/grupo-escala/consulta' component={(props) => <CadastroGrupoEscala {...props} />} />
            <PrivateRoute modulo={64} acao={ACOES.VISUALIZAR} path='/administracao/grupo-escala/visualizacao/:id' active='/administracao/grupo-escala/consulta' component={(props) => <VisualizacaoGrupoEscala {...props} />} />

            <PrivateRoute modulo={67} acao={ACOES.INSERIR} path='/administracao/feriados/cadastro' component={(props) => <CadastroFeriados {...props} />} />
            <PrivateRoute modulo={67} acao={ACOES.ALTERAR} path='/administracao/feriados/alteracao/:id' active='/feriados/consulta' component={(props) => <CadastroFeriados {...props} idSubmenu="" />} />
            <PrivateRoute modulo={67} acao={ACOES.CONSULTAR} path='/administracao/feriados/consulta' component={(props) => <ConsultaFeriados {...props} />} />
            <PrivateRoute modulo={67} acao={ACOES.VISUALIZAR} path='/administracao/feriados/visualizacao/:id' active='/parametros/feriados/visualizacao' component={(props) => <VisualizacaoFeriados {...props} />} />

            <PrivateRoute modulo={53} acao={ACOES.VISUALIZAR} path='/administracao/plantao-estabelecimento/visualizacao/:id' active='/faturamento/plantao-alcancaveis/consulta' component={(props) => <VisualizacaoPlantaoEstabelecimento {...props} />} />

            <PrivateRoute modulo={54} acao={ACOES.CONSULTAR} path='/administracao/rotinas/consulta' component={props => <ConsultaRotinas {...props} />} />

            <PrivateRoute modulo={71} acao={ACOES.CONSULTAR} path='/administracao/notificacao/consulta' active='/administracao/notificacao/consulta' component={(props) => <ConsultaNotificacao {...props} />} />
            <PrivateRoute modulo={71} acao={ACOES.INSERIR} path='/administracao/notificacao/cadastro' active='/administracao/notificacao/consulta' component={(props) => <CadastroNotificacao {...props} />} />
            <PrivateRoute modulo={71} acao={ACOES.ALTERAR} path='/administracao/notificacao/alteracao/:id' active='/administracao/notificacao/consulta' component={(props) => <CadastroNotificacao {...props} />} />
            <PrivateRoute modulo={71} acao={ACOES.VISUALIZAR} path='/administracao/notificacao/visualizacao/:id' active='/administracao/notificacao/consulta' component={(props) => <VisualizacaoNotificacao {...props} />} />

            <PrivateRoute modulo={72} acao={ACOES.CONSULTAR} path='/administracao/direitofolga/consulta' active='/administracao/direitofolga/consulta' component={(props) => <ConsultaDireitoFolga {...props} />} />
            <PrivateRoute modulo={72} acao={ACOES.INSERIR} path='/administracao/direitofolga/cadastro' active='/administracao/direitofolga/consulta' component={(props) => <CadastroDireitoFolga {...props} />} />
            <PrivateRoute modulo={72} acao={ACOES.ALTERAR} path='/administracao/direitofolga/alteracao/:id' active='/administracao/direitofolga/consulta' component={(props) => <CadastroDireitoFolga {...props} />} />
            <PrivateRoute modulo={72} acao={ACOES.VISUALIZAR} path='/administracao/direitofolga/visualizacao/:id' active='/administracao/direitofolga/consulta' component={(props) => <VisualizacaoDireitoFolga {...props} />} />


            {/* PARÂMETROS */}
            <PrivateRoute modulo={7} acao={ACOES.INSERIR} path='/parametros/cbos/cadastro' component={(props) => <Cadastro {...props} />} />
            <PrivateRoute modulo={7} acao={ACOES.ALTERAR} path='/parametros/cbos/alteracao/:id' active='/cbos/consulta' component={(props) => <Cadastro {...props} idSubmenu="" />} />
            <PrivateRoute modulo={7} acao={ACOES.CONSULTAR} path='/parametros/cbos/consulta' component={(props) => <ConsultaCbos {...props} />} />
            <PrivateRoute modulo={7} acao={ACOES.VISUALIZAR} path='/parametros/cbos/visualizacao/:id' active='/parametros/cbos/visualizacao' component={(props) => <VisualizacaoCbos {...props} />} />

            {/* FATURAMENTO */}
            <PrivateRoute modulo={12} acao={ACOES.INSERIR} path='/faturamento/tabelaProcedimento/cadastro' component={(props) => <CadastroTabelaProcedimento {...props} />} />
            <PrivateRoute modulo={12} acao={ACOES.ALTERAR} path='/faturamento/tabelaProcedimento/alteracao/:id' active='/faturamento/tabelaProcedimento/consulta' component={(props) => <CadastroTabelaProcedimento {...props} />} />
            <PrivateRoute modulo={12} acao={ACOES.CONSULTAR} path='/faturamento/tabelaProcedimento/consulta' component={(props) => <ConsultaTabelaProcedimento {...props} {...rProps} />} />
            <PrivateRoute modulo={12} acao={ACOES.VISUALIZAR} path='/faturamento/tabelaProcedimento/visualizacao/:id' active='/faturamento/tabelaProcedimento/consulta' component={(props) => <VisualizacaoTabelaProcedimento {...props} />} />

            <PrivateRoute modulo={14} acao={ACOES.INSERIR} path='/faturamento/procedimento/cadastro' component={(props) => <CadastroProcedimento {...props} />} />
            <PrivateRoute modulo={14} acao={ACOES.ALTERAR} path='/faturamento/procedimento/alteracao/:id' active='/faturamento/Procedimento/consulta' component={(props) => <CadastroProcedimento {...props} />} />
            <PrivateRoute modulo={14} acao={ACOES.CONSULTAR} path='/faturamento/procedimento/consulta' component={(props) => <ConsultaProcedimento {...props} />} />
            <PrivateRoute modulo={14} acao={ACOES.VISUALIZAR} path='/faturamento/procedimento/visualizacao/:id' active='/faturamento/Procedimento/consulta' component={(props) => <VisualizacaoProcedimento {...props} />} />

            <PrivateRoute modulo={30} acao={ACOES.INSERIR} path='/faturamento/acomodacao/cadastro' component={(props) => <CadastroAcomodacao {...props} />} />
            <PrivateRoute modulo={30} acao={ACOES.ALTERAR} path='/faturamento/acomodacao/alteracao/:id' active='/faturamento/acomodacao/consulta' component={(props) => <CadastroAcomodacao {...props} />} />
            <PrivateRoute modulo={30} acao={ACOES.CONSULTAR} path='/faturamento/acomodacao/consulta' component={(props) => <ConsultaAcomodacao {...props} />} />
            <PrivateRoute modulo={30} acao={ACOES.VISUALIZAR} path='/faturamento/acomodacao/visualizacao/:id' active='/faturamento/acomodacao/consulta' component={(props) => <VisualizacaoAcomodacao {...props} />} />

            <PrivateRoute modulo={31} acao={ACOES.INSERIR} path='/faturamento/cid10/cadastro' component={(props) => <CadastroCid10 {...props} />} />
            <PrivateRoute modulo={31} acao={ACOES.ALTERAR} path='/faturamento/cid10/alteracao/:id' active='/faturamento/cid10/consulta' component={(props) => <CadastroCid10 {...props} />} />
            <PrivateRoute modulo={31} acao={ACOES.CONSULTAR} path='/faturamento/cid10/consulta' component={(props) => <ConsultaCid10 {...props} />} />
            <PrivateRoute modulo={31} acao={ACOES.VISUALIZAR} path='/faturamento/cid10/visualizacao/:id' active='/faturamento/cid10/consulta' component={(props) => <VisualizacaoCid10 {...props} />} />

            <PrivateRoute modulo={34} acao={ACOES.INSERIR} path='/faturamento/contrato/cadastro' component={(props) => <CadastroContrato {...props} />} />
            <PrivateRoute modulo={34} acao={ACOES.ALTERAR} path='/faturamento/contrato/alteracao/:id' active='/faturamento/contrato/consulta' component={(props) => <CadastroContrato {...props} />} />
            <PrivateRoute modulo={34} acao={ACOES.CONSULTAR} path='/faturamento/contrato/consulta' component={(props) => <ConsultaContrato {...props} {...rProps} />} />
            <PrivateRoute modulo={34} acao={ACOES.VISUALIZAR} path='/faturamento/contrato/visualizacao/:id' active='/faturamento/contrato/consulta' component={(props) => <VisualizacaoContrato {...props} />} />

            <PrivateRoute modulo={32} acao={ACOES.INSERIR} path='/faturamento/beneficiario/cadastro' component={(props) => <CadastroBeneficiario {...props} />} />
            <PrivateRoute modulo={32} acao={ACOES.ALTERAR} path='/faturamento/beneficiario/alteracao/:id' active='/faturamento/beneficiario/consulta' component={(props) => <CadastroBeneficiario {...props} />} />
            <PrivateRoute modulo={32} acao={ACOES.CONSULTAR} path='/faturamento/beneficiario/consulta' component={(props) => <ConsultaBeneficiario {...props} />} />
            <PrivateRoute modulo={32} acao={ACOES.VISUALIZAR} path='/faturamento/beneficiario/visualizacao/:id' active='/faturamento/beneficiario/consulta' component={(props) => <VisualizacaoBeneficiario {...props} />} />

            <PrivateRoute modulo={35} acao={ACOES.INSERIR} path='/faturamento/lote/cadastro' component={(props) => <CadastroLote {...props} />} />
            <PrivateRoute modulo={35} acao={ACOES.ALTERAR} path='/faturamento/lote/alteracao/:id' active='/faturamento/lote/consulta' component={(props) => <CadastroLote {...props} />} />
            <PrivateRoute modulo={35} acao={ACOES.CONSULTAR} path='/faturamento/lote/consulta' component={(props) => <ConsultaLote {...props} {...rProps} />} />
            <PrivateRoute modulo={35} acao={ACOES.VISUALIZAR} path='/faturamento/lote/visualizacao/:id' active='/faturamento/lote/consulta' component={(props) => <VisualizacaoLote {...props} {...rProps} />} />
            <PrivateRoute modulo={35} acao={ACOES.VISUALIZAR} path='/faturamento/lote/visualizacaoXmlGerado/:id' active='/faturamento/lote/consulta' component={(props) => <VisualizacaoArquivoXmlLote {...props} />} />
            <PrivateRoute modulo={35} acao={ACOES.VISUALIZAR} path='/faturamento/lote/visualizacaoRetornoEnvio/:id' active='/faturamento/lote/consulta' component={(props) => <VisualizacaoRespostaEnvioLote {...props} />} />
            <PrivateRoute modulo={35} acao={ACOES.VISUALIZAR} path='/faturamento/lote/visualizacaoLotesAutomaticos' active='/faturamento/lote/consulta' component={(props) => <VisualizacaoLotesAutomaticos {...props} />} />


            <PrivateRoute modulo={74} acao={ACOES.INSERIR} path='/faturamento/parametroOperadoraSaude/cadastro' component={(props) => <CadastroParametroOperadora {...props} />} />
            <PrivateRoute modulo={74} acao={ACOES.ALTERAR} path='/faturamento/parametroOperadoraSaude/alteracao/:id' active='/faturamento/parametroOperadoraSaude/consulta' component={(props) => <CadastroParametroOperadora {...props} />} />
            <PrivateRoute modulo={74} acao={ACOES.CONSULTAR} path='/faturamento/parametroOperadoraSaude/consulta' component={(props) => <ConsultaParametroOperadora {...props} />} />
            <PrivateRoute modulo={74} acao={ACOES.VISUALIZAR} path='/faturamento/parametroOperadoraSaude/visualizacao/:id' active='/faturamento/parametroOperadoraSaude/consulta' component={(props) => <VisualizacaoParametroOperadora {...props} />} />

            <PrivateRoute modulo={39} acao={ACOES.INSERIR} path='/administracao/registroatividades/cadastro' component={(props) => <CadastroRegistroAtividades {...props} />} />
            <PrivateRoute modulo={39} acao={ACOES.ALTERAR} path='/administracao/registroatividades/alteracao/:id' active='/administracao/registroatividades/consulta' component={(props) => <CadastroRegistroAtividades {...props} />} />
            <PrivateRoute modulo={39} acao={ACOES.CONSULTAR} path='/administracao/registroatividades/consulta' component={(props) => <ConsultaRegistroAtividades {...props} />} />
            <PrivateRoute modulo={39} acao={ACOES.VISUALIZAR} path='/administracao/registroatividades/visualizacao/:id' active='/administracao/registroatividades/consulta' component={(props) => <VisualizacaoRegistroAtividades {...props} />} />

            <PrivateRoute modulo={38} acao={ACOES.CONSULTAR} path='/administracao/agendareserva/consulta' component={(props) => <AgendaAssociado {...props} />} />
            <PrivateRoute modulo={38} acao={ACOES.INSERIR} path='/administracao/agendareserva/cadastro' component={(props) => <CadastroAgendaAssociado {...props} />} />
            <PrivateRoute modulo={38} acao={ACOES.ALTERAR} path='/administracao/agendareserva/alteracao/:id' active='/administracao/agendareserva/consulta' component={(props) => <CadastroAgendaAssociado {...props} />} />
            <PrivateRoute modulo={38} acao={ACOES.INSERIR} path='/administracao/agendareserva/duplicar/:idDuplicar' active='/administracao/agendareserva/consulta' component={(props) => <CadastroAgendaAssociado {...props} />} />
            <PrivateRoute modulo={38} acao={ACOES.VISUALIZAR} path='/administracao/agendareserva/visualizacao/:id' active='/administracao/agendareserva/consulta' component={(props) => <VisualizacaoAgendaAssociado {...props} />} />

            <PrivateRoute modulo={76} acao={ACOES.INSERIR} path='/faturamento/guia/cadastro' component={(props) => <CadastroGuia {...props} />} />
            <PrivateRoute modulo={76} acao={ACOES.VISUALIZAR} path='/faturamento/guia/visualizacao/:id' active='/faturamento/guia/consulta' component={(props) => <VisualizacaoGuia {...props} />} />
            <PrivateRoute modulo={76} acao={ACOES.VISUALIZAR} path='/faturamento/guia/visualizacaoRetornoEnvio/:id' active='/faturamento/guia/consulta' component={(props) => <VisualizacaoRespostaEnvio {...props} />} />
            <PrivateRoute modulo={76} acao={ACOES.CONSULTAR} path='/faturamento/guia/visualizacaoXmlGerado/:id' active='/faturamento/guia/consulta' component={(props) => <VisualizacaoArquivoXml {...props} />} />
            <PrivateRoute modulo={76} acao={ACOES.CONSULTAR} path='/faturamento/guia/consulta' component={(props) => <ConsultaGuia {...props} {...rProps} />} />
            <PrivateRoute modulo={76} acao={ACOES.ALTERAR} path='/faturamento/guia/alteracao/:id' active='/faturamento/guia/consulta' component={(props) => <CadastroGuia {...props} />} />
            <PrivateRoute modulo={76} acao={ACOES.ALTERAR} path='/faturamento/manutencao-lote/cadastro' active='/faturamento/guia/consulta' component={(props) => <ManutencaoLote {...props} />} />
            <PrivateRoute modulo={76} acao={ACOES.ALTERAR} path='/faturamento/manutencao-status/cadastro' active='/faturamento/guia/consulta' component={(props) => <ManutencaoStatus {...props} />} />
            <PrivateRoute modulo={76} acao={ACOES.ALTERAR} path='/faturamento/conferencia/cadastro' active='/faturamento/guia/consulta' component={(props) => <Conferencia {...props} />} />
            <PrivateRoute modulo={76} acao={ACOES.VISUALIZAR} path='/faturamento/guia/visualizacaopagamento/:id' active='/faturamento/guia/consulta' component={(props) => <VisualizacaoGuiaConvenioPagamento {...props} />} />


            <PrivateRoute modulo={107} acao={ACOES.INSERIR} path='/faturamento/guia-particular/cadastro' component={(props) => <CadastroGuiaParticular {...props} />} />
            <PrivateRoute modulo={107} acao={ACOES.INSERIR} path='/faturamento/guia-particular/visualizacao/:id' active='/faturamento/guia-particular/consulta' component={(props) => <VisualizacaoGuiaParticular {...props} />} />
            <PrivateRoute modulo={107} acao={ACOES.CONSULTAR} path='/faturamento/guia-particular/consulta' component={(props) => <ConsultaGuiaParticular {...props} />} />
            <PrivateRoute modulo={107} acao={ACOES.ALTERAR} path='/faturamento/guia-particular/alteracao/:id' active='/faturamento/guia-particular/consulta' component={(props) => <CadastroGuiaParticular {...props} />} />
            <PrivateRoute modulo={107} acao={ACOES.ALTERAR} path='/faturamento/manutencao-status-particular/cadastro' active='/faturamento/guia-particular/consulta' component={(props) => <ManutencaoStatusParticular {...props} />} />


            <PrivateRoute modulo={79} acao={ACOES.INSERIR} path='/faturamento/porteanestesicoparticular/cadastro' component={(props) => <CadastroPorteParticular {...props} />} />
            <PrivateRoute modulo={79} acao={ACOES.INSERIR} path='/faturamento/porteanestesicoparticular/visualizacao/:id' active='/faturamento/porte-particular/consulta' component={(props) => <VisualizacaoPorteParticular {...props} />} />
            <PrivateRoute modulo={79} acao={ACOES.CONSULTAR} path='/faturamento/porteanestesicoparticular/consulta' component={(props) => <ConsultaPorteParticular {...props} />} />
            <PrivateRoute modulo={79} acao={ACOES.ALTERAR} path='/faturamento/porteanestesicoparticular/alteracao/:id' active='/faturamento/porte-particular/consulta' component={(props) => <CadastroPorteParticular {...props} />} />

            <PrivateRoute modulo={80} acao={ACOES.INSERIR} path='/faturamento/anestesiaodontologia/cadastro' component={(props) => <CadastroAnestesiaOdonto {...props} />} />
            <PrivateRoute modulo={80} acao={ACOES.INSERIR} path='/faturamento/anestesiaodontologia/visualizacao/:id' active='/faturamento/anestesiaodontologia/consulta' component={(props) => <VisualizacaoAnestesiaOdonto {...props} />} />
            <PrivateRoute modulo={80} acao={ACOES.CONSULTAR} path='/faturamento/anestesiaodontologia/consulta' component={(props) => <ConsultaAnestesiaOdonto {...props} />} />
            <PrivateRoute modulo={80} acao={ACOES.ALTERAR} path='/faturamento/anestesiaodontologia/alteracao/:id' active='/faturamento/anestesiaodontologia/consulta' component={(props) => <CadastroAnestesiaOdonto {...props} />} />


            <PrivateRoute modulo={111} acao={ACOES.INSERIR} path='/faturamento/contratoparticular/cadastro' component={(props) => <CadastroContratoParticular {...props} />} />
            <PrivateRoute modulo={111} acao={ACOES.ALTERAR} path='/faturamento/contratoparticular/alteracao/:id' active='/faturamento/contrato/consulta' component={(props) => <CadastroParticularContrato {...props} />} />
            <PrivateRoute modulo={111} acao={ACOES.CONSULTAR} path='/faturamento/contratoparticular/consulta' component={(props) => <ConsultaContratoParticular {...props} />} />
            <PrivateRoute modulo={111} acao={ACOES.VISUALIZAR} path='/faturamento/contratoparticular/visualizacao/:id' active='/faturamento/contrato/consulta' component={(props) => <VisualizacaoContratoParticular {...props} />} />

            <PrivateRoute modulo={73} acao={ACOES.CONSULTAR} path='/controleAcesso/lixeira/consulta' component={(props) => <Lixeira {...props} />} />

            <PrivateRoute modulo={144} acao={ACOES.CONSULTAR} path='/controleAcesso/rascunho/consulta' component={(props) => <Rascunho {...props} />} />

            {/* FINANCEIRO */}

            <PrivateRoute modulo={133} acao={ACOES.INSERIR} path='/financeiro/cheque/cadastro' component={(props) => <CadastroCheque {...props} />} />
            <PrivateRoute modulo={133} acao={ACOES.ALTERAR} path='/financeiro/cheque/alteracao/:id' active='/financeiro/cheque/consulta' component={(props) => <CadastroCheque {...props} />} />
            <PrivateRoute modulo={133} acao={ACOES.CONSULTAR} path='/financeiro/cheque/consulta' component={(props) => <ConsultaCheque {...props} />} />
            <PrivateRoute modulo={133} acao={ACOES.VISUALIZAR} path='/financeiro/cheque/visualizacao/:id' active='/financeiro/cheque/consulta' component={(props) => <VisualizacaoCheque {...props} />} />

            <PrivateRoute modulo={135} acao={ACOES.INSERIR} path='/financeiro/categoria/cadastro' component={(props) => <CadastroCategoria {...props} />} />
            <PrivateRoute modulo={135} acao={ACOES.ALTERAR} path='/financeiro/categoria/alteracao/:id' active='/financeiro/categoria/consulta' component={(props) => <CadastroCategoria {...props} />} />
            <PrivateRoute modulo={135} acao={ACOES.CONSULTAR} path='/financeiro/categoria/consulta' component={(props) => <ConsultaCategoria {...props} />} />
            <PrivateRoute modulo={135} acao={ACOES.VISUALIZAR} path='/financeiro/categoria/visualizacao/:id' active='/financeiro/categoria/consulta' component={(props) => <VisualizacaoCategoria {...props} />} />

            <PrivateRoute modulo={115} acao={ACOES.INSERIR} path='/financeiro/cliente/cadastro' component={(props) => <CadastroCliente {...props} />} />
            <PrivateRoute modulo={115} acao={ACOES.ALTERAR} path='/financeiro/cliente/alteracao/:id' active='/financeiro/cliente/consulta' component={(props) => <CadastroCliente {...props} />} />
            <PrivateRoute modulo={115} acao={ACOES.CONSULTAR} path='/financeiro/cliente/consulta' component={(props) => <ConsultaCliente {...props} />} />
            <PrivateRoute modulo={115} acao={ACOES.VISUALIZAR} path='/financeiro/cliente/visualizacao/:id' active='/financeiro/cliente/consulta' component={(props) => <VisualizacaoCliente {...props} />} />

            <PrivateRoute modulo={116} acao={ACOES.INSERIR} path='/financeiro/lancamento/cadastro' component={(props) => <CadastroLancamentoFinanceiro {...props} />} />
            <PrivateRoute modulo={116} acao={ACOES.ALTERAR} path='/financeiro/lancamento/alteracao/:id' active='/financeiro/lancamento/consulta' component={(props) => <CadastroLancamentoFinanceiro {...props} />} />
            <PrivateRoute modulo={116} acao={ACOES.CONSULTAR} path='/financeiro/lancamento/consulta' component={(props) => <ConsultaLancamentoFinanceiro {...props} />} />
            <PrivateRoute modulo={116} acao={ACOES.VISUALIZAR} path='/financeiro/lancamento/visualizacao/:id' active='/financeiro/lancamento/consulta' component={(props) => <VisualizacaoLancamentoFinanceiro {...props} />} />
            <PrivateRoute modulo={116} acao={ACOES.RECIBO} path='/financeiro/lancamento/recibo/:id' active='/financeiro/lancamento/consulta' component={(props) => <Recibo {...props} />} />

            <PrivateRoute modulo={114} acao={ACOES.INSERIR} path='/financeiro/movimento/cadastro' component={(props) => <CadastroMovimentoFinanceiro {...props} />} />
            <PrivateRoute modulo={114} acao={ACOES.CONSULTAR} path='/financeiro/movimento/consulta' component={(props) => <ConsultaMovimentoFinanceiro {...props} />} />
            <PrivateRoute modulo={114} acao={ACOES.VISUALIZAR} path='/financeiro/movimento/visualizacao/:id' active='/financeiro/movimento/consulta' component={(props) => <VisualizacaoMovimentoFinanceiro {...props} />} />
            <PrivateRoute modulo={114} acao={ACOES.ALTERAR} path='/financeiro/movimento/alteracao/:id' active='/financeiro/movimento/consulta' component={(props) => <CadastroMovimentoFinanceiro {...props} />} />
            <PrivateRoute modulo={114} acao={ACOES.VISUALIZAR_COM_ASSINATURA} path='/financeiro/lancamento/visualizacaoassinatura/:id' active='/financeiro/lancamento/consulta' component={(props) => <VisualizacaoComAssinaturaMovimentoFinanceiro {...props} />} />

            <PrivateRoute modulo={119} acao={ACOES.INSERIR} path='/financeiro/contabancaria/cadastro' component={(props) => <CadastroContaBancaria {...props} />} />
            <PrivateRoute modulo={119} acao={ACOES.ALTERAR} path='/financeiro/contabancaria/alteracao/:id' active='/financeiro/contabancaria/consulta' component={(props) => <CadastroContaBancaria {...props} />} />
            <PrivateRoute modulo={119} acao={ACOES.CONSULTAR} path='/financeiro/contabancaria/consulta' component={(props) => <ConsultaContaBancaria {...props} />} />
            <PrivateRoute modulo={119} acao={ACOES.VISUALIZAR} path='/financeiro/contabancaria/visualizacao/:id' active='/financeiro/lancamento/consulta' component={(props) => <VisualizacaoContaBancaria {...props} />} />

            <PrivateRoute modulo={125} acao={ACOES.INSERIR} path='/financeiro/banco/cadastro' component={(props) => <CadastroBanco {...props} />} />
            <PrivateRoute modulo={125} acao={ACOES.ALTERAR} path='/financeiro/banco/alteracao/:id' active='/financeiro/banco/consulta' component={(props) => <CadastroBanco {...props} />} />
            <PrivateRoute modulo={125} acao={ACOES.CONSULTAR} path='/financeiro/banco/consulta' component={(props) => <ConsultaBanco {...props} />} />
            <PrivateRoute modulo={125} acao={ACOES.VISUALIZAR} path='/financeiro/banco/visualizacao/:id' active='/financeiro/banco/consulta' component={(props) => <VisualizacaoBanco {...props} />} />

            <PrivateRoute modulo={126} acao={ACOES.INSERIR} path='/financeiro/tributo/cadastro' component={(props) => <CadastroTributo {...props} />} />
            <PrivateRoute modulo={126} acao={ACOES.ALTERAR} path='/financeiro/tributo/alteracao/:id' active='/financeiro/tributo/consulta' component={(props) => <CadastroTributo {...props} />} />
            <PrivateRoute modulo={126} acao={ACOES.CONSULTAR} path='/financeiro/tributo/consulta' component={(props) => <ConsultaTributo {...props} />} />
            <PrivateRoute modulo={126} acao={ACOES.VISUALIZAR} path='/financeiro/tributo/visualizacao/:id' active='/financeiro/tributo/consulta' component={(props) => <VisualizacaoTributo {...props} />} />

            <PrivateRoute modulo={168} acao={ACOES.INSERIR} path='/administracao/ferias/liberacaoferias/cadastro' component={(props) => <CadastroLiberacaoFerias {...props} />} />
            <PrivateRoute modulo={168} acao={ACOES.ALTERAR} path='/administracao/ferias/liberacaoferias/alteracao/:id' active='/financeiro/liberacaoferias/consulta' component={(props) => <CadastroLiberacaoFerias {...props} />} />
            <PrivateRoute modulo={168} acao={ACOES.CONSULTAR} path='/administracao/ferias/liberacaoferias/consulta' component={(props) => <ConsultaLiberacaoFerias {...props} />} />
            <PrivateRoute modulo={168} acao={ACOES.VISUALIZAR} path='/administracao/ferias/liberacaoferias/visualizacao/:id' active='/financeiro/liberacaoferias/consulta' component={(props) => <VisualizacaoLiberacaoFerias {...props} />} />

            <PrivateRoute modulo={131} acao={ACOES.INSERIR} path='/financeiro/tributoaliquota/cadastro' component={(props) => <CadastroTributoAliquota {...props} />} />
            <PrivateRoute modulo={131} acao={ACOES.ALTERAR} path='/financeiro/tributoaliquota/alteracao/:id' active='/financeiro/tributoaliquota/consulta' component={(props) => <CadastroTributoAliquota {...props} />} />
            <PrivateRoute modulo={131} acao={ACOES.CONSULTAR} path='/financeiro/tributoaliquota/consulta' component={(props) => <ConsultaTributoAliquota {...props} />} />
            <PrivateRoute modulo={131} acao={ACOES.VISUALIZAR} path='/financeiro/tributoaliquota/visualizacao/:id' active='/financeiro/tributoaliquota/consulta' component={(props) => <VisualizacaoTributoAliquota {...props} />} />

            <PrivateRoute modulo={134} acao={ACOES.INSERIR} path='/financeiro/lancamentofinanceiro/cadastro' component={(props) => <CadastroLancamento {...props} />} />
            <PrivateRoute modulo={134} acao={ACOES.ALTERAR} path='/financeiro/lancamentofinanceiro/alteracao/:id' active='/financeiro/lancamentofinanceiro/consulta' component={(props) => <CadastroLancamento {...props} />} />
            <PrivateRoute modulo={134} acao={ACOES.CONSULTAR} path='/financeiro/lancamentofinanceiro/consulta' component={(props) => <ConsultaLancamento {...props} />} />
            <PrivateRoute modulo={134} acao={ACOES.VISUALIZAR} path='/financeiro/lancamentofinanceiro/visualizacao/:id' active='/financeiro/lancamentofinanceiro/consulta' component={(props) => <VisualizacaoLancamento {...props} />} />

            <PrivateRoute modulo={136} acao={ACOES.INSERIR} path='/financeiro/notafiscal/cadastro' component={(props) => <CadastroNotaFiscal {...props} />} />
            <PrivateRoute modulo={136} acao={ACOES.ALTERAR} path='/financeiro/notafiscal/alteracao/:id' active='/financeiro/notafiscal/consulta' component={(props) => <CadastroNotaFiscal {...props} />} />
            <PrivateRoute modulo={136} acao={ACOES.CONSULTAR} path='/financeiro/notafiscal/consulta' component={(props) => <ConsultaNotaFiscal {...props} />} />
            <PrivateRoute modulo={136} acao={ACOES.VISUALIZAR} path='/financeiro/notafiscal/visualizacao/:id' active='/financeiro/notafiscal/consulta' component={(props) => <VisualizacaoNotaFiscal {...props} />} />
            <PrivateRoute modulo={136} acao={ACOES.VISUALIZAR} path='/financeiro/notafiscal/visualizacaoErroNota/:id' active='/financeiro/notafiscal/consulta' component={(props) => <VisualizacaoErrosNota {...props} />} />

            <PrivateRoute modulo={140} acao={ACOES.INSERIR} path='/controleproducao/referencia/cadastro' component={(props) => <CadastroReferenciaProducao {...props} />} />
            <PrivateRoute modulo={140} acao={ACOES.ALTERAR} path='/controleproducao/referencia/alteracao/:id' active='/controleproducao/referencia/consulta' component={(props) => <CadastroReferenciaProducao {...props} />} />
            <PrivateRoute modulo={140} acao={ACOES.CONSULTAR} path='/controleproducao/referencia/consulta' component={(props) => <ConsultaReferenciaProducao {...props} {...rProps} />} />
            <PrivateRoute modulo={140} acao={ACOES.VISUALIZAR} path='/controleproducao/referencia/visualizacao/:id' active='/controleproducao/referencia/consulta' component={(props) => <VisualizacaoReferenciaProducao {...props} />} />
            <PrivateRoute modulo={145} acao={ACOES.CONSULTAR} path='/controleproducao/guiamapahoras/consulta' active='/controleproducao/guiamapahoras/consulta' component={(props) => <ConsultaGuiaMapaHoras {...props} />} />
            <PrivateRoute modulo={145} acao={ACOES.CONSULTAR} path='/faturamento/guia-socios/alterar' active='/controleproducao/guiamapahoras/consulta' component={(props) => <DetalhesGuia {...props} />} />

            {/* RELATORIOS */}
            <PrivateRoute modulo={27} acao={ACOES.CONSULTAR} path='/relatorios/socio/relatorio' component={props => <RelatorioSocio {...props} />} />
            <PrivateRoute modulo={27} acao={ACOES.CONSULTAR} path='/relatorios/auditoria/relatorio' component={props => <RelatorioAuditoria {...props} />} />
            <PrivateRoute modulo={65} acao={ACOES.CONSULTAR} path='/relatorios/agendamento/relatorio' component={props => <RelatorioEscala {...props} />} />
            <PrivateRoute modulo={37} acao={ACOES.CONSULTAR} path='/relatorios/procedimento/relatorio' component={props => <RelatorioProcedimento {...props} />} />
            <PrivateRoute modulo={41} acao={ACOES.CONSULTAR} path='/relatorios/cirurgiao/relatorio' component={props => <RelatorioCirurgiao {...props} />} />
            <PrivateRoute modulo={51} acao={ACOES.CONSULTAR} path='/relatorios/plantao/relatorio' component={props => <RelatorioPlantao {...props} />} />
            <PrivateRoute modulo={61} acao={ACOES.CONSULTAR} path='/relatorios/ferias/relatorio' component={props => <RelatorioFerias {...props} />} />
            <PrivateRoute modulo={61} acao={ACOES.CONSULTAR} path='/relatorios/mercado/relatorio' component={props => <RelatorioMercado {...props} />} />
            <PrivateRoute modulo={63} acao={ACOES.CONSULTAR} path='/relatorios/ocorrencia/relatorio' component={props => <RelatorioOcorrencia {...props} />} />
            <PrivateRoute modulo={77} acao={ACOES.CONSULTAR} path='/relatorios/guia/relatorio' component={props => <RelatorioFaturamento {...props} />} />
            <PrivateRoute modulo={143} acao={ACOES.CONSULTAR} path='/relatorios/guiasmapahoras/relatorio' component={props => <RelatorioGuiasMapaHoras {...props} />} />
            <PrivateRoute modulo={78} acao={ACOES.CONSULTAR} path='/relatorios/fatura/relatorio' component={props => <RelatorioFatura {...props} />} />
            <PrivateRoute modulo={113} acao={ACOES.CONSULTAR} path='/relatorios/guiasenviadas/relatorio' component={props => <RelatorioGuiasEnviadas {...props} />} />
            <PrivateRoute modulo={117} acao={ACOES.CONSULTAR} path='/relatorios/movimento/relatorio' component={props => <RelatorioMovimentoFinanceiro {...props} />} />
            <PrivateRoute modulo={117} acao={ACOES.CONSULTAR} path='/relatorios/lancamentomovimento/relatorio' component={props => <RelatorioLancamentoMovimento {...props} />} />
            <PrivateRoute modulo={137} acao={ACOES.CONSULTAR} path='/relatorios/cliente/relatorio' component={props => <RelatorioCliente {...props} />} />
            <PrivateRoute modulo={138} acao={ACOES.CONSULTAR} path='/relatorios/contasreceber/relatorio' component={props => <RelatorioContasPagarReceber {...props} />} />
            <PrivateRoute modulo={139} acao={ACOES.CONSULTAR} path='/relatorios/lote/relatorio' component={props => <RelatorioLote {...props} />} />
            <PrivateRoute modulo={139} acao={ACOES.CONSULTAR} path='/relatorios/mapahoras/relatorio' component={props => <RelatorioMapaHora {...props} />} />
            <PrivateRoute modulo={136} acao={ACOES.CONSULTAR} path='/relatorios/notafiscal/relatorio' component={props => <RelatorioNotaFiscal {...props} />} />
            <PrivateRoute modulo={136} acao={ACOES.CONSULTAR} path='/relatorios/extratobancario/relatorio' component={props => <RelatorioExtratoBancarioDiario {...props} />} />
            <PrivateRoute modulo={167} acao={ACOES.CONSULTAR} path='/relatorios/contas_pagar_receber_sintetico/relatorio' component={props => <RelatorioContasPagarReceberSintetico {...props} />} />

            {/* GRAFICOS BI */}
            <PrivateRoute modulo={146} acao={ACOES.CONSULTAR} path='/analisedados/producaofaturamento/grafico' component={props => <ProducaoFaturamentoBI {...props} />} />

            {/*AUDITORIA*/}
            {/* <PrivateRoute modulo={148} acao={ACOES.CONSULTAR} path='/auditoria/guia-pagamento/consulta' active='/auditoria/guia-pagamento/consulta' component={(props) => <GuiaPagamento {...props} />} /> */}
            <PrivateRoute modulo={148} acao={ACOES.CONSULTAR} path='/auditoria/guia-pagamento/consulta' active='/auditoria/guia-pagamento/consulta' component={(props) => <ConsultaGuiaPagamento {...props} />} />
            <PrivateRoute modulo={148} acao={ACOES.VISUALIZAR} path='/auditoria/guia-pagamento/visualizacao/:id' active='/auditoria/guia-pagamento/visualizacao' component={(props) => <VisualizacaoGuiaPagamento {...props} />} />

            {/* GERAL */}
            <PrivateRoute modulo={4} path='/listagem' component={(props) => <ListagemPadrao {...props} />} />
            <PrivateRoute modulo={4} path='/listagemAlternativa' component={(props) => <ListagemAlternativa {...props} />} />
            <PrivateRoute modulo={66} acao={ACOES.CONSULTAR} path='/dashboard' component={() => <Dashboard {...rProps} />} />
            <PrivateRoute path='/home' component={() => <Home />} />

            <Route path="*"><NaoEncontrado /></Route>
        </Switch >
    </BrowserRouter >
)

export default Routes;
export { reload }