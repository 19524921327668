import React, { useState, useEffect } from 'react'
import { HeaderAgenda } from '../../../../components/Headers'
import { InputSimples } from '../../../../components/Inputs'
import { get, salvar } from '../../../../util/AcessoApi'
import '../css/style.css'
import moment from "moment"
import { Link } from 'react-router-dom'
import { BotaoPadrao } from '../../../../components/Botoes'
import { removeAcentos } from '../../../../util/FuncoesComuns'
import { ModalCalendar } from '../../../../components/Modais'
import { ordenarMultiplo } from '../../../../components/Ordenamentos'
import InfiniteCalendar from 'react-infinite-calendar';
import { useWindowSize, useInterval, sortTable } from '../../../../util/Utilitarios'
import { useHistory } from "react-router-dom";

export default props => {
    const history = useHistory();
    const dataCompartilhada = (history.location.state && history.location.state.data) ? moment(history.location.state.data, 'DD/MM/YYYY').format("DD/MM/YYYY") : moment(new Date()).format("DD/MM/YYYY")
    const [dataGeral, setDataGeral] = useState(dataCompartilhada)
    const [dataPlantao, setDataPlantao] = useState(dataCompartilhada)
    const [dataAlcancaveis, setDataAlcancaveis] = useState(dataCompartilhada)
    const [dataEstabelecimento, setDataEstabelecimento] = useState(dataCompartilhada)
    const [dataOcorrencias, setDataOcorrencias] = useState(dataCompartilhada)
    const [plantao, setPlantao] = useState([])
    const [estabelecimento, setEstabelecimento] = useState([])
    const [alcancaveis, setAlcancaveis] = useState([])
    const [ocorrencias, setOcorrencias] = useState([])
    const [tipoOcorrencias, setTipoOcorrencias] = useState([])
    const [change, setChange] = useState('')
    const [pesquisa, setPesquisa] = useState('')
    const [calendarioGeral, setCalendarioGeral] = useState(false)
    const [calendarioPlantao, setCalendarioPlantao] = useState(false)
    const [calendarioEstabelecimento, setCalendarioEstabelecimento] = useState(false)
    const [calendarioAlcancaveis, setCalendarioAlcancaveis] = useState(false)
    const [calendarioOcorrencias, setCalendarioOcorrencias] = useState(false)
    const [width, height] = useWindowSize();
    const [hashPlantao, setHashPlantao] = useState(null)
    const [hashEstabelecimento, setHashEstabelecimento] = useState('')
    const [hashAlcancaveis, setHashAlcancaveis] = useState('')
    const [hashOcorrencias, setHashOcorrencias] = useState('')
    const [ordenamentosUtilizados, setOrdenamentosUtilizados] = useState([])
    const [dadosAusenciasDia, setDadosAusenciasDia] = useState({})
    const [dataReferencia, setDataReferencia] = useState(new moment().format('DD/MM/YYYY'))


    const alterarPlantao = _ => {
        Promise.resolve(get('/administracao/rotinasplantao', { data: dataPlantao }, true)).then(val => setPlantao(val))
        Promise.resolve(get('/administracao/rotinashashplantao', { data: dataPlantao }, true)).then(val => setHashPlantao(val))
    }

    const alterarAlcancaveis = _ => {
        Promise.resolve(get('/administracao/rotinasalcancaveis', { data: dataAlcancaveis }, true)).then(val => setAlcancaveis(val))
        Promise.resolve(get('/administracao/rotinashashalcancaveis', { data: dataAlcancaveis }, true)).then(val => setHashAlcancaveis(val))
    }

    const alterarEstabelecimento = _ => {
        Promise.resolve(get('/administracao/rotinasestabelecimentosaude', { data: dataEstabelecimento }, true)).then(val => setEstabelecimento(val))
        Promise.resolve(get('/administracao/rotinashashestabelecimentosaude', { data: dataEstabelecimento }, true)).then(val => setHashEstabelecimento(val))
    }

    const alterarOcorrencias = _ => {
        Promise.resolve(get('/administracao/rotinasocorrencias', { data: dataOcorrencias }, true)).then(val => setOcorrencias(val))
        Promise.resolve(get('/administracao/rotinashashocorrencias', { data: dataOcorrencias }, true)).then(val => setHashOcorrencias(val))
    }

    const getDadosAusencia = () => {

        Promise.resolve(get(`/administracao/ferias/obterausenciasdia`, { data: dataOcorrencias })).then(obj => {
            setDadosAusenciasDia(obj);
        })
    }

    useEffect(() => {
        getDadosAusencia();
    }, [dataOcorrencias])


    useEffect(() => {
        if (ocorrencias && ocorrencias.length > 0) {
            let arrayTipoOcorrencia = ocorrencias.filter((value, index, self) => self.findIndex(x => x.tipo_ocorrenca_id === value.tipo_ocorrenca_id) === index)
                .map(item => ({ tipo_ocorrencia: item.tipo_ocorrenca, tipo_ocorrencia_id: item.tipo_ocorrenca_id, quantidade: ocorrencias.filter(ocorrencia => ocorrencia.tipo_ocorrenca_id === item.tipo_ocorrenca_id).length }))

            arrayTipoOcorrencia = ordenarMultiplo(arrayTipoOcorrencia, [{ coluna: 'quantidade', ordenamento: { tipo: 'numero', modo: 'desc', ordem: 1 } }])

            setTipoOcorrencias(arrayTipoOcorrencia)
        } else {
            setTipoOcorrencias([])
        }
    }, [ocorrencias])


    useEffect(() => {
        switch (change) {
            case 'plantao':
                alterarPlantao()
                break
            case 'alcancaveis':
                alterarAlcancaveis()
                break
            case 'estabelecimento':
                alterarEstabelecimento()
                break
            case 'ocorrencias':
                alterarOcorrencias()
                break
            default:
                Promise.resolve(get('/administracao/rotinas', { data: dataGeral }, true)).then(val => { setPlantao(val.plantao); setOcorrencias(val.ocorrencias); setEstabelecimento(val.estabelecimento); setAlcancaveis(val.alcancaveis) })
                Promise.resolve(get('/administracao/rotinashash', { data: dataGeral }, true)).then(val => { setHashPlantao(val.plantao); setHashAlcancaveis(val.ocorrencias); setHashEstabelecimento(val.estabelecimento); setHashOcorrencias(val.alcancaveis) })
                break
        }
    }, [dataGeral, dataPlantao, dataAlcancaveis, dataEstabelecimento, dataOcorrencias])

    useInterval(() => {
        if (history.location.pathname.includes('rotinas/consulta')) {
            Promise.resolve(get('/administracao/rotinashashplantao', { data: dataPlantao }, true)).then(val => val !== hashPlantao && alterarPlantao())
            Promise.resolve(get('/administracao/rotinashashalcancaveis', { data: dataAlcancaveis }, true)).then(val => val !== hashAlcancaveis && alterarAlcancaveis())
            Promise.resolve(get('/administracao/rotinashashestabelecimentosaude', { data: dataEstabelecimento }, true)).then(val => val !== hashEstabelecimento && alterarEstabelecimento())
            Promise.resolve(get('/administracao/rotinashashocorrencias', { data: dataOcorrencias }, true)).then(val => val !== hashOcorrencias && alterarOcorrencias())
        }
    }, 10000);

    const sortTableFuncao = (table_id, sortColumn, tipo) => {
        const idxOrdenamento = ordenamentosUtilizados.findIndex(value => value.table_id === table_id);
        if (idxOrdenamento > -1) {
            const novosOrdenamentos = [...ordenamentosUtilizados]
            novosOrdenamentos.splice(idxOrdenamento, 1)
            setOrdenamentosUtilizados([{ table_id, sortColumn, tipo }, ...novosOrdenamentos])
        } else {
            setOrdenamentosUtilizados([{ table_id, sortColumn, tipo }, ...ordenamentosUtilizados])
        }

        sortTable(table_id, sortColumn, tipo);
    }

    const sortTableFuncao2 = (array, setArray, table_id, numColumn, coluna, tipo, ordem) => {
        let arrayClone = [...array]
        const tableData = document.getElementById(table_id).getElementsByTagName('tbody').item(0);
        const thread = document.getElementById(table_id).getElementsByTagName('thead').item(0);

        let temContador = false;
        let indexContador = -1;

        let qtdThs = thread.getElementsByTagName('th').length;
        for (let index = 0; index < qtdThs; index++) {
            if (index === numColumn)
                continue;

            if (thread.getElementsByTagName('th').item(index).innerText.indexOf('↑') > -1) {
                thread.getElementsByTagName('th').item(index).innerText = thread.getElementsByTagName('th').item(index).innerText.replace('↑', '');
            }
            else if (thread.getElementsByTagName('th').item(index).innerText.indexOf('↓') > -1) {
                thread.getElementsByTagName('th').item(index).innerText = thread.getElementsByTagName('th').item(index).innerText.replace('↓', '');
            }

            if (thread.getElementsByTagName('th').item(index).className === 'contador') {
                temContador = true;
                indexContador = index;
            }
        }

        let innerText = thread.getElementsByTagName('th').item(numColumn).innerText;
        let order = 'asc'
        if (innerText.indexOf('↑') > -1) {
            order = 'desc'
            thread.getElementsByTagName('th').item(numColumn).innerText = innerText.replace('↑', '');
        }
        else if (innerText.indexOf('↓') > -1) {
            order = 'asc'
            thread.getElementsByTagName('th').item(numColumn).innerText = innerText.replace('↓', '');
        }
        thread.getElementsByTagName('th').item(numColumn).innerText += order == 'asc' ? '↑' : '↓';

        const idxOrdenamento = ordenamentosUtilizados.findIndex(value => value.table_id === table_id);
        if (idxOrdenamento > -1) {
            const novosOrdenamentos = [...ordenamentosUtilizados]
            novosOrdenamentos.splice(idxOrdenamento, 1)
            setOrdenamentosUtilizados([{ array, setArray, table_id, numColumn, coluna, tipo, order }, ...novosOrdenamentos])
        } else {
            setOrdenamentosUtilizados([{ array, setArray, table_id, numColumn, coluna, tipo, order }, ...ordenamentosUtilizados])
        }

        setArray(ordenarMultiplo(arrayClone, [{ coluna: coluna, ordenamento: { tipo: tipo, modo: ordem || order, ordem: 1 } }]))
    }

    useEffect(() => {
        salvar({}, '/administracao/auditoriarotina');
    }, [])

    const calcularData = (modo, verificador) => {
        switch (verificador) {
            case 'plantao':
                setChange('plantao')
                modo === 'prev' ? setDataPlantao(moment(dataPlantao, 'DD/MM/YYYY').subtract(1, 'days').format('DD/MM/YYYY')) : modo === 'next' ? setDataPlantao(moment(dataPlantao, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY')) : setDataPlantao(modo)
                break
            case 'ocorrencias':
                setChange('ocorrencias')
                modo === 'prev' ? setDataOcorrencias(moment(dataOcorrencias, 'DD/MM/YYYY').subtract(1, 'days').format('DD/MM/YYYY')) : modo === 'next' ? setDataOcorrencias(moment(dataOcorrencias, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY')) : setDataOcorrencias(modo)
                break
            case 'estabelecimento':
                setChange('estabelecimento')
                modo === 'prev' ? setDataEstabelecimento(moment(dataEstabelecimento, 'DD/MM/YYYY').subtract(1, 'days').format('DD/MM/YYYY')) : modo === 'next' ? setDataEstabelecimento(moment(dataEstabelecimento, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY')) : setDataEstabelecimento(modo)
                break
            case 'alcancaveis':
                setChange('alcancaveis')
                modo === 'prev' ? setDataAlcancaveis(moment(dataAlcancaveis, 'DD/MM/YYYY').subtract(1, 'days').format('DD/MM/YYYY')) : modo === 'next' ? setDataAlcancaveis(moment(dataAlcancaveis, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY')) : setDataAlcancaveis(modo)
                break
            default:
                setChange('')
                const data = modo === 'prev' ? moment(dataGeral, 'DD/MM/YYYY').subtract(1, 'days').format('DD/MM/YYYY') : modo === 'next' ? moment(dataGeral, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY') : modo

                setDataGeral(data)
                setDataPlantao(data)
                setDataOcorrencias(data)
                setDataAlcancaveis(data)
                setDataEstabelecimento(data)
                break
        }
    }

    return (
        <>
            <HeaderAgenda className='header-agenda' titulo={'Rotinas'} icone={'icon-Dec'} botoes={
                <>
                    <Link to={{ pathname: '/administracao/socio-ocorrencia/ListagemDiaria', state: { data: dataGeral } }}>
                        <BotaoPadrao click={_ => { }} icone='icon-Letter-Open' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Ocorrências'} idTooltip={"padrao"} />
                    </Link>
                    <Link to={{ pathname: '/administracao/agendareserva/consulta', state: { data: dataGeral } }}>
                        <BotaoPadrao click={_ => { }} icone='icon-Calendar-3' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Agenda'} idTooltip={"padrao"} />
                    </Link>
                    <Link to={{ pathname: '/administracao/plantao-alcancaveis/consulta', state: { data: dataGeral } }}>
                        <BotaoPadrao click={_ => { }} icone='icon-Clock' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Escala Plantão'} idTooltip={"padrao"} />
                    </Link>
                    <Link to={{ pathname: '/administracao/plantao-estabelecimento/consulta', state: { data: dataGeral } }}>
                        <BotaoPadrao click={_ => { }} icone='icon-Building' classe=' btn btn-primary primary btn-filter ' textoTooltip={'Escala Estabelecimento'} idTooltip={"padrao"} />
                    </Link>
                </>
            } />
            <div className='row'>
                <div className={'col-md-12 col-lg-7'}>
                    <InputSimples name="search1" type="text" placeholder={`Pesquisar...`} onChange={e => setPesquisa(e.target.value)} />
                </div>
                <div className={'col-md-12 col-lg-5'}>
                    <div className={'mb-3 paginacao-rotinas'}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: (width < 990 ? 'center' : 'flex-end') }}>
                            <button style={{ fontSize: '15px' }} onClick={_ => calcularData('prev', 'geral')} className="icon-Arrow-Left2 pagination-button icone icone-pagination d-none"></button>
                            <button style={{ fontSize: '15px' }} onClick={_ => calcularData('prev', 'geral')} className="icon-Arrow-Left pagination-button icone icone-pagination"></button>
                            <ModalCalendar show={calendarioGeral} setShowModal={setCalendarioGeral}>
                                <InfiniteCalendar className={calendarioGeral ? "calendario-ativo" : "calendario-oculto"}
                                    locale={{
                                        locale: require('date-fns/locale/pt'),
                                        blank: 'Selecione uma data',
                                        headerFormat: 'ddd, MMM Do',
                                        weekdays: ["Dom", "Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb"],
                                        todayLabel: {
                                            long: 'Hoje',
                                            short: 'Hoje'
                                        },
                                        weekStartsOn: 0,
                                    }}
                                    selected={dataGeral.includes('-') ? new Date() : new Date(dataGeral.split('/')[1] + '/' + dataGeral.split('/')[0] + '/' + dataGeral.split('/')[2])}
                                    onSelect={value => { calcularData(value.getDate().toString().padStart(2, '0') + '/' + (value.getMonth() + 1).toString().padStart(2, '0') + '/' + value.getFullYear(), 'geral'); setCalendarioGeral(!calendarioGeral); }} height={height - 150}
                                    width={width < 501 ? width : 403}
                                />
                            </ModalCalendar>
                            <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={_ => setCalendarioGeral(!calendarioGeral)}>{dataGeral}</span>
                            <button style={{ fontSize: '15px' }} onClick={_ => calcularData('next', 'geral')} className="icon-Arrow-Right pagination-button icone icone-pagination"></button>
                            <button style={{ fontSize: '15px' }} onClick={_ => calcularData('next', 'geral')} className="icon-Arrow-Right2 pagination-button icone icone-pagination d-none"></button>
                        </div>
                        <span style={{ marginRight: (width < 990 ? 'unset' : '95px') }} className={'data-geral'}>{moment(dataGeral, 'DD/MM/YYYY').format('ddd')}</span>
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-lg-6 col-md-12'} style={{ paddingBottom: 15 }}>
                    <div className="card-rotinas card">
                        <div className="card-title">
                            <h5 style={{ fontSize: '1.1rem' }}>Plantonista</h5>
                            <div className={'data-card'}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <button onClick={_ => calcularData('prev', 'plantao')} className="icon-Arrow-Left2 pagination-button icone icone-pagination d-none" />
                                    <button onClick={_ => calcularData('prev', 'plantao')} className="icon-Arrow-Left pagination-button icone icone-pagination" />
                                    <ModalCalendar show={calendarioPlantao} setShowModal={setCalendarioPlantao}>
                                        <InfiniteCalendar className={calendarioPlantao ? "calendario-ativo" : "calendario-oculto"}
                                            locale={{
                                                locale: require('date-fns/locale/pt'),
                                                blank: 'Selecione uma data',
                                                headerFormat: 'ddd, MMM Do',
                                                weekdays: ["Dom", "Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb"],
                                                todayLabel: {
                                                    long: 'Hoje',
                                                    short: 'Hoje'
                                                },
                                                weekStartsOn: 0,
                                            }}
                                            selected={dataPlantao.includes('-') ? new Date() : new Date(dataPlantao.split('/')[1] + '/' + dataPlantao.split('/')[0] + '/' + dataPlantao.split('/')[2])}
                                            onSelect={value => { calcularData(value.getDate().toString().padStart(2, '0') + '/' + (value.getMonth() + 1).toString().padStart(2, '0') + '/' + value.getFullYear(), 'plantao'); setCalendarioPlantao(!calendarioPlantao); }} height={height - 150}
                                            width={width < 501 ? width : 403}
                                        />
                                    </ModalCalendar>
                                    <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={_ => setCalendarioPlantao(!calendarioPlantao)}>{dataPlantao}</span>
                                    <button onClick={_ => calcularData('next', 'plantao')} className="icon-Arrow-Right pagination-button icone icone-pagination" />
                                    <button onClick={_ => calcularData('next', 'plantao')} className="icon-Arrow-Right2 pagination-button icone icone-pagination d-none" />
                                </div>
                                <span>{moment(dataPlantao, 'DD/MM/YYYY').format('ddd')}</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className={'overflow-rotina scrollbar-container custom-scrollbar'}>
                                <table className="table table-sm table-borderless tabela-linha-destaque ordenavel ordenavel table-ptb-0 table-separate-4" id="tbl-plantonista">
                                    <thead style={{ width: '100%' }}>
                                        <th style={{ width: '3%' }}><span className={'titulo-rotinas'}>#</span></th>
                                        <th style={{ width: '35%' }} onClick={() => sortTable('tbl-plantonista', 1, 'socio')}><span className={'titulo-rotinas'}>Sócio</span></th>
                                        <th style={{ width: '17%' }} onClick={() => sortTable('tbl-plantonista', 2)}><span className={'titulo-rotinas'}>Horário</span></th>
                                        <th style={{ width: '17%', textAlign: 'center' }} onClick={() => sortTable('tbl-plantonista', 3, 'numero')}><span className={'titulo-rotinas'}>Ordem</span></th>
                                        <th style={{ width: '11%', textAlign: 'center' }} ><span className={'titulo-rotinas'}>Troca</span></th>
                                        <th style={{ width: '17%' }} onClick={() => sortTable('tbl-plantonista', 5, 'data_hora')}><span className={'titulo-rotinas'}>Data Registro</span></th>
                                    </thead>
                                    <tbody>
                                        {plantao.filter(item => pesquisa ? removeAcentos(item.pesquisa.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())) : true).map((x, index) => {
                                            return <tr>
                                                <td><div style={{ textAlign: 'center' }}><span className={'rotina-dados'}>{(index + 1)}</span></div></td>
                                                <td><div><span className={'rotina-dados'}>{x.sigla && <b>{x.sigla + ' - '}</b>}<span>{x.socio}</span></span></div></td>
                                                <td><div><span className={'rotina-dados'}>{moment(x.data_final, 'DD/MM/YYYY HH:mm').diff(moment(x.data_inicial, 'DD/MM/YYYY HH:mm'), 'hours') > 30 ? x.horario_inicial + ' ' + moment(x.data_inicial, 'DD/MM/YYYY HH:mm').format('ddd') + ' - ' + x.horario_final + ' ' + moment(x.data_final, 'DD/MM/YYYY HH:mm').format('ddd') : x.horario}</span> </div></td>
                                                <td><div style={{ textAlign: 'center' }}><span className={'rotina-dados'}>{x.ordem || ''}</span></div></td>
                                                <td><div style={{ textAlign: 'center' }}><span className={'rotina-dados'}>{x.troca || ''}</span></div></td>
                                                <td><div><span className={'rotina-dados'}>{x.data_registro}</span></div></td>
                                            </tr>
                                        }) || <></>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-lg-6 col-md-12'} style={{ paddingBottom: 15 }}>
                    <div className="card-rotinas card">
                        <div className="card-title">
                            <h5 style={{ fontSize: '1.1rem' }}>Alcançáveis</h5>
                            <div className={'data-card'}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <button onClick={_ => calcularData('prev', 'alcancaveis')} className="icon-Arrow-Left2 pagination-button icone icone-pagination d-none" />
                                    <button onClick={_ => calcularData('prev', 'alcancaveis')} className="icon-Arrow-Left pagination-button icone icone-pagination" />
                                    <ModalCalendar show={calendarioAlcancaveis} setShowModal={setCalendarioAlcancaveis}>
                                        <InfiniteCalendar className={calendarioAlcancaveis ? "calendario-ativo" : "calendario-oculto"}
                                            locale={{
                                                locale: require('date-fns/locale/pt'),
                                                blank: 'Selecione uma data',
                                                headerFormat: 'ddd, MMM Do',
                                                weekdays: ["Dom", "Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb"],
                                                todayLabel: {
                                                    long: 'Hoje',
                                                    short: 'Hoje'
                                                },
                                                weekStartsOn: 0,
                                            }}
                                            selected={dataAlcancaveis.includes('-') ? new Date() : new Date(dataAlcancaveis.split('/')[1] + '/' + dataAlcancaveis.split('/')[0] + '/' + dataAlcancaveis.split('/')[2])}
                                            onSelect={value => { calcularData(value.getDate().toString().padStart(2, '0') + '/' + (value.getMonth() + 1).toString().padStart(2, '0') + '/' + value.getFullYear(), 'alcancaveis'); setCalendarioAlcancaveis(!calendarioAlcancaveis); }} height={height - 150}
                                            width={width < 501 ? width : 403}
                                        />
                                    </ModalCalendar>
                                    <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={_ => setCalendarioAlcancaveis(!calendarioAlcancaveis)}>{dataAlcancaveis}</span>
                                    <button onClick={_ => calcularData('next', 'alcancaveis')} className="icon-Arrow-Right pagination-button icone icone-pagination" />
                                    <button onClick={_ => calcularData('next', 'alcancaveis')} className="icon-Arrow-Right2 pagination-button icone icone-pagination d-none" />
                                </div>
                                <span>{moment(dataAlcancaveis, 'DD/MM/YYYY').format('ddd')}</span>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className={'overflow-rotina scrollbar-container custom-scrollbar'}>
                                <table className="table table-sm table-borderless tabela-linha-destaque ordenavel ordenavel table-ptb-0 table-separate-4" id="tbl-alcancalveis">
                                    <thead style={{ width: '100%' }}>
                                        <th className="contador" style={{ width: '3%' }}>#</th>
                                        <th style={{ width: '35%' }} onClick={() => sortTable('tbl-alcancalveis', 1)}>Nome</th>
                                        <th style={{ width: '17%' }} onClick={() => sortTable('tbl-alcancalveis', 2)}>Horário</th>
                                        <th style={{ width: '17%', textAlign: 'center' }} onClick={() => sortTable('tbl-alcancalveis', 3, 'numero')}>Ordem</th>
                                        <th style={{ width: '11%', textAlign: 'center' }} ><span className={'titulo-rotinas'}>Troca</span></th>
                                        <th style={{ width: '17%' }} onClick={() => sortTable('tbl-alcancalveis', 5, 'data_hora')}>Data registro</th>
                                    </thead>
                                    <tbody>
                                        {alcancaveis.filter(item => pesquisa ? removeAcentos(item.pesquisa.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())) : true).map((x, index) => <tr>
                                            <td><div style={{ textAlign: 'center' }}><span className={'rotina-dados'}>{(index + 1)}</span></div></td>
                                            <td><div><span className={'rotina-dados'}>{x.sigla && <b>{x.sigla + ' - '}</b>}<span>{x.socio}</span></span></div></td>
                                            <td><div><span className={'rotina-dados'}>{moment(x.data_final, 'DD/MM/YYYY HH:mm').diff(moment(x.data_inicial, 'DD/MM/YYYY HH:mm'), 'hours') > 30 ? x.horario_inicial + ' ' + moment(x.data_inicial, 'DD/MM/YYYY HH:mm').format('ddd') + ' - ' + x.horario_final + ' ' + moment(x.data_final, 'DD/MM/YYYY HH:mm').format('ddd') : x.horario}</span> </div></td>
                                            <td><div style={{ textAlign: 'center' }}><span className={'rotina-dados'}>{x.ordem || ''}</span></div></td>
                                            <td><div style={{ textAlign: 'center' }}><span className={'rotina-dados'}>{x.troca || ''}</span></div></td>
                                            <td><div><span className={'rotina-dados'}>{x.data_registro}</span></div></td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-lg-6 col-md-12'} style={{ paddingTop: 15 }}>
                    <div className="card-rotinas card">
                        <div className="card-title" style={{ display: 'flex' }}>
                            <h5 className={'titulo-estabelecimento'}>Estabelecimento Saúde</h5>
                            <div className={'data-card'}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <button onClick={_ => calcularData('prev', 'estabelecimento')} className="icon-Arrow-Left2 pagination-button icone icone-pagination d-none" />
                                    <button onClick={_ => calcularData('prev', 'estabelecimento')} className="icon-Arrow-Left pagination-button icone icone-pagination" />
                                    <ModalCalendar show={calendarioEstabelecimento} setShowModal={setCalendarioEstabelecimento}>
                                        <InfiniteCalendar className={calendarioEstabelecimento ? "calendario-ativo" : "calendario-oculto"}
                                            locale={{
                                                locale: require('date-fns/locale/pt'),
                                                blank: 'Selecione uma data',
                                                headerFormat: 'ddd, MMM Do',
                                                weekdays: ["Dom", "Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb"],
                                                todayLabel: {
                                                    long: 'Hoje',
                                                    short: 'Hoje'
                                                },
                                                weekStartsOn: 0,
                                            }}
                                            selected={dataEstabelecimento.includes('-') ? new Date() : new Date(dataEstabelecimento.split('/')[1] + '/' + dataEstabelecimento.split('/')[0] + '/' + dataEstabelecimento.split('/')[2])}
                                            onSelect={value => { calcularData(value.getDate().toString().padStart(2, '0') + '/' + (value.getMonth() + 1).toString().padStart(2, '0') + '/' + value.getFullYear(), 'estabelecimento'); setCalendarioEstabelecimento(!calendarioEstabelecimento); }} height={height - 150}
                                            width={width < 501 ? width : 403}
                                        />
                                    </ModalCalendar>
                                    <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={_ => setCalendarioEstabelecimento(!calendarioEstabelecimento)}>{dataEstabelecimento}</span>
                                    <button onClick={_ => calcularData('next', 'estabelecimento')} className="icon-Arrow-Right pagination-button icone icone-pagination" />
                                    <button onClick={_ => calcularData('next', 'estabelecimento')} className="icon-Arrow-Right2 pagination-button icone icone-pagination d-none" />
                                </div>
                                <span>{moment(dataEstabelecimento, 'DD/MM/YYYY').format('ddd')}</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className={'overflow-rotina scrollbar-container custom-scrollbar'}>
                                <table className="table table-sm table-borderless tabela-linha-destaque ordenavel ordenavel table-ptb-0 table-separate-4" id="tbl-estabelecimeto">
                                    <thead style={{ width: '100%' }}>
                                        <th className="contador" style={{ width: '3%' }}>#</th>
                                        <th style={{ width: '52%' }}>Nome</th>
                                        <th style={{ width: '17%' }}>Horário</th>
                                        <th style={{ width: '11%', textAlign: 'center' }} ><span className={'titulo-rotinas'}>Troca</span></th>
                                        <th style={{ width: '17%' }}>Data registro</th>
                                    </thead>
                                    <tbody>
                                        {estabelecimento.filter(item => pesquisa ? removeAcentos(item.pesquisa.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())) : true).map((x, index) =>
                                            <>
                                                <tr style={{ backgroundColor: '#f1f1f1' }}>
                                                    <td><div style={{ textAlign: 'center' }}><span className={'rotina-dados'}><b>{(index + 1)}</b></span></div></td>
                                                    <td><div><span className={'rotina-dados'}><b>{x.nome}</b></span></div></td>
                                                    <td><div><span className={'rotina-dados'}><b>{moment(x.data_final, 'DD/MM/YYYY HH:mm').diff(moment(x.data_inicial, 'DD/MM/YYYY HH:mm'), 'hours') > 30 ? x.horario_inicial + ' ' + moment(x.data_inicial, 'DD/MM/YYYY HH:mm').format('ddd') + ' - ' + x.horario_final + ' ' + moment(x.data_final, 'DD/MM/YYYY HH:mm').format('ddd') : x.horario}</b></span> </div></td>
                                                    <td><div><span className={'rotina-dados'}><b></b></span></div></td>
                                                    <td><div><span className={'rotina-dados'}><b>{x.data_registro}</b></span></div></td>
                                                </tr>
                                                {ordenarMultiplo(x.socio, [{ coluna: 'ordem', ordenamento: { tipo: 'numero', modo: 'asc', ordem: 1 } }]).map(y => y.id && <tr>
                                                    <td><div style={{ textAlign: 'center' }}><span className={'rotina-dados'}></span></div></td>
                                                    <td><div><span className={'rotina-dados'}>{y.sigla && <b>{y.sigla + ' - '}</b>}<span>{y.nome}</span> </span></div></td>
                                                    <td><div><span className={'rotina-dados'}>{moment(y.data_final, 'DD/MM/YYYY HH:mm').diff(moment(y.data_inicial, 'DD/MM/YYYY HH:mm'), 'hours') > 30 ? y.horario_inicial + ' ' + moment(y.data_inicial, 'DD/MM/YYYY HH:mm').format('ddd') + ' - ' + y.horario_final + ' ' + moment(y.data_final, 'DD/MM/YYYY HH:mm').format('ddd') : y.horario}</span></div></td>
                                                    <td style={{ textAlign: 'center' }}><div><span className={'rotina-dados'}>{y.troca || ''}</span></div></td>
                                                    <td><div><span className={'rotina-dados'}>{y.data_registro}</span></div></td>
                                                </tr>)}
                                            </>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-lg-6 col-md-12'} style={{ paddingTop: 15 }}>
                    <div className="card-rotinas card">
                        <div className="card-title col-12">
                            <div className='col-4 pl-0'>
                                <h5 style={{ fontSize: '1.1rem', paddingTop: '0px' }}>Férias e Ocorrências</h5>
                            </div>
                            <div className='scroll-horizontal col-4'>
                                {ocorrencias && tipoOcorrencias.map(item =>
                                    <div className='scroll-horizontal-div'><span>( <b>{item.quantidade}</b> ) {item.tipo_ocorrencia}</span></div>)}
                            </div>
                            <div className={'data-card col-4'} style = {{justifyContent: 'flex-end', paddingRight: '30px'}}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <button onClick={_ => calcularData('prev', 'ocorrencias')} className="icon-Arrow-Left2 pagination-button icone icone-pagination d-none" />
                                    <button onClick={_ => calcularData('prev', 'ocorrencias')} className="icon-Arrow-Left pagination-button icone icone-pagination" />
                                    <ModalCalendar show={calendarioOcorrencias} setShowModal={setCalendarioOcorrencias}>
                                        <InfiniteCalendar className={calendarioOcorrencias ? "calendario-ativo" : "calendario-oculto"}
                                            locale={{
                                                locale: require('date-fns/locale/pt'),
                                                blank: 'Selecione uma data',
                                                headerFormat: 'ddd, MMM Do',
                                                weekdays: ["Dom", "Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb"],
                                                todayLabel: {
                                                    long: 'Hoje',
                                                    short: 'Hoje'
                                                },
                                                weekStartsOn: 0,
                                            }}
                                            selected={dataOcorrencias.includes('-') ? new Date() : new Date(dataOcorrencias.split('/')[1] + '/' + dataOcorrencias.split('/')[0] + '/' + dataOcorrencias.split('/')[2])}
                                            onSelect={value => { calcularData(value.getDate().toString().padStart(2, '0') + '/' + (value.getMonth() + 1).toString().padStart(2, '0') + '/' + value.getFullYear(), 'ocorrencias'); setCalendarioOcorrencias(!calendarioOcorrencias); }} height={height - 150}
                                            width={width < 501 ? width : 403}
                                        />
                                    </ModalCalendar>
                                    <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={_ => setCalendarioOcorrencias(!calendarioOcorrencias)}>{dataOcorrencias}</span>
                                    <button onClick={_ => { calcularData('next', 'ocorrencias'); setDataReferencia(new moment(dataReferencia, 'DD/MM/YYYY').subtract(1, 'd').format('DD/MM/YYYY')); }} className="icon-Arrow-Right pagination-button icone icone-pagination" />
                                    <button onClick={_ => { calcularData('next', 'ocorrencias'); setDataReferencia(new moment(dataReferencia, 'DD/MM/YYYY').subtract(1, 'd').format('DD/MM/YYYY')); }} className="icon-Arrow-Right2 pagination-button icone icone-pagination d-none" />
                                </div>
                                <span>{moment(dataOcorrencias, 'DD/MM/YYYY').format('ddd')}</span>
                            </div>

                        </div>
                        <div className="card-body">
                            <div className={'overflow-rotina scrollbar-container custom-scrollbar'}>
                                <table className="table table-sm table-borderless tabela-linha-destaque ordenavel ordenavel table-ptb-0 table-separate-4" id="tbl-ocorrencias">
                                    <thead style={{ width: '100%' }}>
                                        <th className="contador" style={{ width: '3%' }}>#</th>
                                        <th style={{ width: '40%' }} onClick={() => sortTable('tbl-ocorrencias', 1)}>Sócio</th>
                                        <th style={{ width: '20%' }} onClick={() => sortTable('tbl-ocorrencias', 2)}>Horário</th>
                                        <th style={{ width: '17%', textAlign: 'center' }} onClick={() => sortTable('tbl-ocorrencias', 3)}>Tipo</th>
                                        <th style={{ width: '20%' }} onClick={() => sortTable('tbl-ocorrencias', 4, 'data_hora')}>Data registro</th>
                                    </thead>
                                    <tbody>
                                        {ocorrencias.filter(item => pesquisa ? removeAcentos(item.pesquisa.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())) : true).map((x, index) => <tr>
                                            <td><div style={{ textAlign: 'center' }}><span className={'rotina-dados'}>{(index + 1)}</span></div></td>
                                            <td><div><span className={'rotina-dados'}>{x.sigla && <b>{x.sigla + ' - '}</b>}<span>{x.nome}</span></span></div></td>
                                            <td><div><span className={'rotina-dados'}>{moment(x.data_final, 'DD/MM/YYYY HH:mm').diff(moment(x.data_inicial, 'DD/MM/YYYY HH:mm'), 'hours') > 30 ? x.horario_inicial + ' ' + moment(x.data_inicial, 'DD/MM/YYYY HH:mm').format('ddd') + ' - ' + x.horario_final + ' ' + moment(x.data_final, 'DD/MM/YYYY HH:mm').format('ddd') : x.horario}</span> </div></td>
                                            <td><div style={{ textAlign: 'center' }}><span className={'rotina-dados'}>{x.tipo_ocorrenca}</span></div></td>
                                            <td><div><span className={'rotina-dados'}>{x.data_registro}</span></div></td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            <div className='row col-12' style={{ paddingTop: 20 }}>
                                <div className='col-6'>
                                    {dadosAusenciasDia.resumo == undefined ? <></> :
                                        (
                                            <>
                                                <div className='row'>
                                                    <div className='col-3 italic' style={{ fontWeight: 'italic' }}>
                                                        Marcada(s):
                                                    </div>
                                                    {
                                                        dadosAusenciasDia.resumoSaldo.map(x =>
                                                            <div className='col-3 bold'> {x.periodo.toString().split(':')[0]}: {parseFloat(x.horas || 0).toFixed(2) / 6} </div>
                                                        )}
                                                </div>
                                            </>
                                        )
                                    }

                                </div>
                                <div className='col-6'>
                                    {dadosAusenciasDia.resumoSaldo == undefined ? <></> :
                                        (
                                            <>
                                                <div className='row'>
                                                    <div className='col-2 italic' style={{ fontWeight: 'italic' }}>
                                                        Saldo:
                                                    </div>
                                                    {
                                                        dadosAusenciasDia.resumoSaldo.map(x =>
                                                            <div className='col-3 bold'> {x.periodo.toString()}: {parseInt(x.qtd_maxima_periodo || 0) - parseFloat(x.horas ? x.horas : 0).toFixed(2) / 6}</div>
                                                        )}
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                {/* <div>
                                    {dadosAusenciasDia.resumo == undefined ? <></> :
                                        (
                                            <>
                                                <div className='row'>Marcada(s):
                                                    {
                                                        dadosAusenciasDia.resumoSaldo.map(x =>
                                                            <div className='col-5 bold'> {x.periodo.toString().split(':')[0]}: {parseFloat(x.horas || 0).toFixed(2) / 6} </div>
                                                        )}
                                                    { }
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <div >
                                    {dadosAusenciasDia.resumoSaldo == undefined ? <></> :
                                        (
                                            <>
                                                <div className='row'>
                                                    <div className='col-5 bold' style={{ fontWeight:'bold' }}>
                                                        Saldo....:
                                                    </div>
                                                    {
                                                        dadosAusenciasDia.resumoSaldo.map(x =>
                                                            <div className='col-4 bold'>{x.periodo.toString()}: {parseInt(x.qtd_maxima_periodo || 0) - parseFloat(x.horas ? x.horas : 0).toFixed(2) / 6}</div>
                                                        )}
                                                    { }
                                                </div>
                                            </>
                                        )
                                    }
                                </div> */}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}