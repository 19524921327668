import React, { useState, useRef, useEffect, useCallback } from 'react'
import { get } from '../util/AcessoApi'
import { ModalCadastro } from './Modais'
import Accordion from './Accordion'

const ModalLegendaStatus = ({ target, ...props }) => {

    const [statusGuia, setStatusGuia] = useState([])
    const [statusLote, setStatusLote] = useState([])

    const obterStatusGuia = useCallback(_ => {
   
        Promise.resolve(get('/faturamento/guiaStatus', { particular: props.particular }, null)).then(value => {
            setStatusGuia(value)
        })
    }, [])

    const obterStatusLote = useCallback(_ => {
        Promise.resolve(get('/faturamento/guiaStatusLote', props.status_guia_convenio && { particular: false }, null)).then(value => {
            setStatusLote(value)
        })
    }, [])


    useEffect(_ => {
        if (props.abrirModal) {
            obterStatusGuia()
            if (!props.particular)
                obterStatusLote()

        }
    }, [obterStatusGuia, props.abrirModal])

    return (
        <>
            <ModalCadastro titulo={props.titulo} show={props.abrirModal} setShowModal={props.setAbrirModal}>

                <div className='modal-body-cadastro'>
                    <div class="separator mt-20 mb-20"></div>
                    <div className={'form-group col-12'}>
                        <div className="modal-header" style={{ padding: 0, marginBottom: 10 }}>
                            <h5 className="modal-title" ><span>Status Guia</span></h5>
                        </div>
                            {
                                statusGuia && statusGuia.map(x => {
                                    return (
                                        <>
                                            <Accordion
                                                title={x.descricao}
                                                content={x?.observacao}
                                                color={x?.cor}
                                            />
                                        </>
                                    )
                                })
                            }
                            {!props.particular ?
                                <>
                                    <div className="modal-header" style={{ padding: 0, marginBottom: 10, marginTop: 30 }}>
                                        <h5 className="modal-title" ><span>Status Lote</span></h5>
                                    </div>

                                    {statusLote && statusLote.map(x => {
                                        return (
                                            <>
                                                <Accordion
                                                    title={x.descricao}
                                                    content={x?.observacao}
                                                    color={x?.cor_status}
                                                />
                                            </>
                                        )
                                    })
                                    }
                                </>
                                : <></>}
                    </div>
                </div>
            </ModalCadastro >
        </>
    );
}

export default ModalLegendaStatus