import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { salvar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao} from '../../../../components/Inputs'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import Atalho from '../../../../util/Atalho'

const CadastroTipoEscalador = (props) => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao] = useState(Yup.object({
        descricao: Yup.string()
            .required().trim()
            .max(200)
    }))

    const Submit = (values, resetForm) => {
        console.log('submit')
        setSalvando(true)

        salvar(
            values,
            '/parametros/tipoEscalador',
            (obj) => {
                mostrarToast('sucesso', mensagens.sucesso)
                props.setItemSelecionado({ value: obj.id, label: obj.descricao })

                if (salvarENovo) {
                    resetForm({})
                    setSalvando(false)
                }
                else
                    props.setShow(false);
            },
            () => {
                setSalvando(false)
            })
    }

    useEffect(() => {
            setValoresIniciais({
                descricao: ''
            })
    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values,{resetForm}) => Submit(values, resetForm)}>
            {propsFormik => (<Form>
                        <div className='row'>
                            <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' obrigatorio={false} />
                            <InputPadrao label='Descrição' name='descricao' type='text' placeholder='Descrição' classeTooltip={props.modal && 'tooltip-input-modal'} 
                            obrigatorio={true} tamanho='col-12'  autoFocus salvarENovo={salvarENovo}/>
                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo}  salvarENovo={true}/>

                        </div>

                        <div className='direita container-botoes mb-3 group-salvar-novo'>
                            <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} />
                            <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} />
                        </div>
            </Form>)}
        </Formik>
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Administração</Link>,
        () => <Link className="link-active" to={'/administracao/tipoEscalador/consulta'}>Tipo Escalador</Link>,
    ]
    return (<>
        <HeaderCadastro titulo={"Cadastro de Tipo Escalador"} link={links} />
        <div className="card mb-20">
            <div className="card-body">
                <CadastroTipoEscalador {...props} />
            </div>
        </div>
    </>
    )
}
export { CadastroTipoEscalador }
export default Cadastro
