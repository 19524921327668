import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Formik } from "formik";
import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { BotaoPadrao } from '../../../components/Botoes';
import { HeaderCadastro } from '../../../components/Headers';
import { InputPeriodoFerias, InputReferenciaLiberacaoFerias, InputSocioAtivos } from '../../../components/InputsAutoCompleteV2';
import { InputDatePicker, TextArea } from '../../../components/InputsV2';
import { ModalDuasOpcoes } from '../../../components/Modais';
import { mostrarToast } from '../../../components/Toasts';
import { get, salvar } from '../../../util/AcessoApi';
import { getUsuario } from '../../../util/Configuracoes';
import { mensagens, useWindowSize } from '../../../util/Utilitarios';
import Yup, { validaData } from '../../../util/Validacoes';
import copy from 'copy-to-clipboard'
import { BtnAcao } from '../../../components/Acoes';

export default props => {
    let { id } = useParams()
    const [isSubmit, setIsSubmit] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [dataTelefone, setDataTelefone] = useState([])
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [validacaoRegistroUtilizado, setValidacaoRegistroUtilizado] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [showModalToken, setShowModalToken] = useState(false)
    const [tokenGerado, setTokenGerado] = useState("1NA5567")
    const [msgValidacao, setMsgValidacao] = useState(<></>)
    const [width, height] = useWindowSize();
    const user = getUsuario()

    const manter = []

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Administração</span>,
        () => <Link className='link-active' to={'/administracao/liberacaoferias/consulta'}>Liberação de Férias</Link>,
    ]

    let validacaoInicial = Yup.object({
        socio: Yup.object().nullable().required(),
        periodo: Yup.object().nullable().required(),
        usuario: Yup.object().nullable().required(),
        periodo_gozo: Yup.object().nullable().required(),
        data_inicio: Yup.string().nullable().required()
            .test('Data', 'Data inválida!', function (value) {
                return validaData(this.parent.data_inicio);
            }),
        data_termino: Yup.string().nullable().required()
            .test('Data', 'Data inválida!', function (value) {
                return validaData(this.parent.data_termino);
            })
            .test('DataTermino', 'Data de término deve ser maior que a data de início!', function (value) {
                const { data_inicio, data_termino } = this.parent;
                if (!data_inicio || !data_termino) return true;

                const inicio = new Date(data_inicio.split('/').reverse().join('-'));
                const termino = new Date(data_termino.split('/').reverse().join('-'));

                return termino >= inicio;
            }),
        justificativa: Yup.string().nullable()
    });



    const { history } = props

    const validarSubmit = (values, propsFormik) => {
        setIsSubmit(true);

        const moment = require('moment');
        const today = moment().startOf('day').format('DD/MM/YYYY');
        const startDate = moment(values.data_inicio, 'DD/MM/YYYY').startOf('day');


        if (startDate.isBefore(today)) {
            setMsgValidacao(<>A data de início é anterior à data atual. Deseja continuar?</>);
            setOpenModal(true);
        } else {
            Submit(values, propsFormik);
        }
    };


    const Submit = (values, propsFormik) => {
        setIsSubmit(true)

        salvar(values, '/administracao/liberacaoferias', (ret) => {
            mostrarToast('sucesso', mensagens.sucesso)
            setTokenGerado("");

            if (salvarENovo) {
                propsFormik.resetForm({})
                setIsSubmit(false)
            }
            if (ret?.token) {
                setShowModalToken(true);
                setTokenGerado(ret?.token);
            }
        }, err => {
            mostrarToast('erro', mensagens.erro)
            setIsSubmit(false)
        })
    }

    useEffect(() => {
        if (id) {
            Promise.resolve(get('/administracao/liberacaoferias/' + id)).then((res) => {

                if (res.marcado) {
                    setValidacaoRegistroUtilizado(true)
                    setIsSubmit(true)
                }
                if (res.length === 0)
                    history.push('/administracao/liberacaoferias/consulta')
                setValoresIniciais(res)
            }).catch((e) => {
            });
        } else {
            setValoresIniciais({
                socio: "",
                data_inicio: "",
                data_termino: "",
                periodo: "",
                usuario: "",
                justificativa: "",
                periodo_gozo: "",
                usuario: { value: user.id, label: user.nome }
            })
        }

    }, [id])

    const onTokenConfirmed = () => {
        if (!salvarENovo)
            history.push('/administracao/ferias/liberacaoferias/consulta')
        else
            setSalvarENovo(false)
    }

    const copyToken = (text) => {
        copy(text);
        mostrarToast('sucesso', 'Token copiado para a área de transferência!')
    }

    return (
        <>
            {!props.modal && <HeaderCadastro titulo={"Cadastro Liberação de Férias"} link={links} validacaoRegistroUtilizado={validacaoRegistroUtilizado} msgValidacaoRegistroUtilizado='Registro já utilizado em uma marcação' />}
            <Formik
                initialValues={valoresIniciais}
                enableReinitialize={true}
                validationSchema={validacaoInicial}

                onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
                {propsFormik => (
                    <Form>
                        <div className={props.modal && props.modal || 'card mb-20'}>
                            <div className={props.modal || 'card-body'}>
                                <div className="row">
                                    <InputSocioAtivos label="Sócio" name="socio" propsFormik={propsFormik} tamanho={'col-xl-4 col-md-6'} obrigatorio
                                        classeTooltip={props.modal && 'tooltip-input-modal'} salvarENovo={salvarENovo} />

                                    <InputPeriodoFerias obrigatorio label="Período" name="periodo" propsFormik={propsFormik} tamanho={'col-xl-2 col-md-6'}
                                        classeTooltip={props.modal && 'tooltip-input-modal'} salvarENovo={salvarENovo} />

                                    <InputDatePicker
                                        obrigatorio
                                        tamanho={'col-3'}
                                        label="Data de Início"
                                        name="data_inicio"
                                        type="text"
                                        setarValor={(value) => {
                                            propsFormik.setFieldValue('data_inicio', value)
                                        }}
                                        classeTooltip={'tooltip-input-modal'}
                                        mostrarFeriado={true}
                                    />

                                    <InputDatePicker
                                        obrigatorio
                                        tamanho={'col-3'}
                                        label="Data de Término"
                                        name="data_termino"
                                        type="text"
                                        setarValor={(value) => {
                                            propsFormik.setFieldValue('data_termino', value)
                                            propsFormik.setFieldTouched("data_termino", true)
                                        }}
                                        classeTooltip={'tooltip-input-modal'}
                                        mostrarFeriado={true}
                                    />

                                    <InputReferenciaLiberacaoFerias
                                        label='Referência'
                                        name="periodo_gozo"
                                        placeholder='Referência'
                                        propsFormik={propsFormik}
                                        classeTooltip={'tooltip-input-modal'}
                                        obrigatorio
                                        tamanho={'col-lg-3'}
                                        isClearable={false} />

                                    <TextArea
                                        label="Justificativa"
                                        name="justificativa"
                                        type="text"
                                        placeholder="Justificativa"
                                        tamanho={!props.modal && 'col-lg-6 col-md-8'}
                                        classeTooltip={props.modal && 'tooltip-input-modal'}
                                    />

                                </div>
                            </div>

                        </div>


                        {!props.modal && <div className='separator mt-40 mb-5'></div>}
                        <div className='direita container-botoes mb-3 group-salvar-novo'>
                            <BotaoPadrao texto='Salvar' disabledWithoutText={validacaoRegistroUtilizado} disabled={isSubmit} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                            {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={isSubmit} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                        </div>

                        <ModalDuasOpcoes showModal={showModalToken} setShowModal={setShowModalToken} funcaoOpcao2={() => onTokenConfirmed()} esconderFechar mensagem={<>
                            <div className='ml-1'>
                                <span className=''>Token de Liberação:</span>
                                <div className='mt-2 mb-2 display-flex items-center'>
                                    <h5><b>{tokenGerado}</b></h5>
                                    <BtnAcao icone={"icon-Files"} classTooltip="tooltip-input-modal" texto={"Copiar para área de transferência"} action={() => copyToken(tokenGerado)} {...props} />
                                </div>
                                {/* <div className='mt-2'>

                                    <span>
                                        
                                    </span>
                                </div> */}
                            </div>
                        </>} esconderOpcao1={true}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} />

                        <ModalDuasOpcoes showModal={openModal} setShowModal={setOpenModal}
                            funcaoOpcao2={() => {
                                setOpenModal(false);
                                // propsFormik.values.pendente = true;
                                Submit(propsFormik.values, propsFormik);
                            }}
                            funcaoOpcao1={() => { setIsSubmit(false); setOpenModal(false) }}
                            esconderFechar mensagem={<>{<>{'Validações:'} <br /><br /></>} {msgValidacao}<br /></>}
                            textoOpcao1={'Cancelar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} />
                    </Form>
                )}


            </Formik>
        </>
    )
}