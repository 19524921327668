import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FieldArray, FastField, useFormikContext } from "formik";
import Yup, { validaData, periodoData, validaHora, validaCPF, validaCNPJ, periodoHora } from '../../../../util/Validacoes';
import { HeaderCadastro } from '../../../../components/Headers'
import { InputClienteFinanceiro, InputLancamentoSemMovimento, InputModoLiquidacao, InputMovimentoFinanceiroLiquidacao, InputParcela, InputPeriodicidade } from '../../../../components/InputsAutoComplete';
import { InputCurrency, InputDatePicker, InputPadrao, InputSwitch, InputValorNovo, TextArea } from '../../../../components/Inputs';
import { BtnAcao, LinkAlteracaoTabela, LinkAlteracaoTabelaLocal, LinkExclusaoTabela, LinkExclusaoTabelaLocal, LinkVisualizacaoTabela, LinkVisualizacaoTabelaLocal, LinkVisualizacaoTabelaLocalLancamento } from '../../../../components/Acoes';
import { Overlay, Tooltip } from 'react-bootstrap';
import { FiAlertTriangle } from 'react-icons/fi';
import { convertValueToFloat, distinctArr, formatarMoeda, mensagens } from '../../../../util/Utilitarios';
import { BotaoPadrao } from '../../../../components/Botoes';
import moment from 'moment';
import { TabPanel, Tabs } from 'react-tabs';
import { cloneDeep } from 'lodash';
import { mostrarToast } from '../../../../components/Toasts';
import { ModalCadastro, ModalDuasOpcoes } from '../../../../components/Modais';
import { get, validar } from '../../../../util/AcessoApi';
import { ordenarSiglaModoPagamento } from '../../../../util/FuncoesComuns';


const CadastroLiquidacao = React.forwardRef(({ dadosMovimentoLancamento, ...props }, ref) => {
    let { propsFormik, dataMovimento } = props
    const { values, setFieldValue } = useFormikContext();
    const refLiquidacao = useRef(null)
    const inputValorLiquidacao = useRef([]);
    const inputValores = useRef([
        {
            acrescimo: 0,
            desconto: 0,
            juros_multa: 0,
            imposto_nfse: 0,
            glosas: 0,
            outros_impostos: 0,
        }
    ]);
    // const [tipoLiquidacao, setTipoLiquidacao] = useState('(ÚNICA FORMA)')
    const [arrayRef, setArrayRef] = useState(Array(propsFormik.values.liquidacao ? props.propsFormik.values.liquidacao.length : 1).fill(React.createRef()))
    const [disabled, setDisabled] = useState(true)
    const [valorTotalLiquidacao, setValorTotalLiquidacao] = useState(formatarMoeda(0.00))
    const [minimizar, setMinimizar] = useState([true])
    const [msgValidacao, setMsgValidacao] = useState([])
    const [showModalValidaValor, setShowModalValidaValor] = useState(false)
    const [validaMaior, setValidaMaior] = useState(false)
    const [msgValidacaoGuiaRepetida, setMsgValidacaoGuiaRepetida] = useState([])
    const [msgValidacaoGuia, setMsgValidacaoGuia] = useState()
    const [showModalValidaGuiaRepetida, setShowModalValidaGuiaRepetida] = useState(false)
    const inputValorAcrescimo = useRef([]);
    const inputValorDesconto = useRef([]);
    const inputValorJurosMulta = useRef([]);
    const inputValorImpostoNFE = useRef([]);
    const inputValorGlosa = useRef([]);
    const inputValorOutrosImpostos = useRef([]);
    const removerLinha = (arrayHelpers, propsFormik, index) => {

        inputValores.current.splice(index, 1)

        // if (dadosMovimentoLancamento.length > 0) {
        //     dadosMovimentoLancamento.liquidacoes_excluir.push(propsFormik.values.liquidacao[index].id_liquidacao)
        // }
        const arrayLiquidacao = propsFormik.values.liquidacao;
        if (arrayLiquidacao.length === 1) {

            arrayHelpers.remove(index)
            arrayHelpers.insert(propsFormik.values.liquidacao.length, { procedimento: undefined, quantidade: 1 })

        }
        else {
            if (arrayLiquidacao.length === 1 && arrayLiquidacao[index].modo_liquidacao && arrayLiquidacao[index].modo_liquidacao.value)
                arrayHelpers.insert(propsFormik.values.liquidacao.length, { procedimento: undefined, quantidade: 1 })

            else {
                arrayHelpers.remove(index)
            }
        }
    }
    const calcularValorTotal = _ => {
        const liquidacao = propsFormik.values.liquidacao
        const liquidacao_parcelado = propsFormik.values.liquidacao_parcelado
        let parcelado = propsFormik.values.parcelado_lancamento
        let valorTotal = '0.00'
        if (props.id && !parcelado) {
            valorTotal = liquidacao?.map(x => convertValueToFloat(x.valor_liquidacao_formatado || x.valor_liquidacao)).filter(x => !isNaN(x)).reduce((ant, atual) => ant + atual, 0) || '0.00';

            setValorTotalLiquidacao(formatarMoeda(valorTotal))
        }
        else if (!props.id && !parcelado) {
            valorTotal = liquidacao?.map(x => convertValueToFloat(x.valor_liquidacao_formatado || x.valor_liquidacao)).filter(x => !isNaN(x)).reduce((ant, atual) => ant + atual, 0) || '0.00';

            setValorTotalLiquidacao(formatarMoeda(valorTotal))
        }
        else if (!props.id && parcelado) {
            valorTotal = liquidacao?.map(x => parseFloat(x?.valor_liquidacao?.toString()?.includes('R$') ? x?.valor_liquidacao?.replace('R$', '').replace(/\./g, '').replace(',', '.')
                : x?.valor_liquidacao || 0)).filter(x => !isNaN(x)).reduce((ant, atual) => ant + atual, 0) || '0.00';
            setValorTotalLiquidacao(formatarMoeda(valorTotal))
        }
        else if (props.id && parcelado) {
            valorTotal = liquidacao?.map(x => parseFloat(x?.valor || 0)).filter(x => !isNaN(x)).reduce((ant, atual) => ant + atual, 0) || '0.00';
            setValorTotalLiquidacao(formatarMoeda(valorTotal))
        }

    }
    useEffect(_ => calcularValorTotal(), [propsFormik.values.valor_geral_lancamento, propsFormik.values.liquidacao])

    const adicionarLinha = (option, index, arrayHelpers) => {
        propsFormik.values.liquidacao = propsFormik.values.liquidacao.filter(x => x)
        propsFormik.values.liquidacao[index].modo_liquidacao = option
        const arrayLiquidacao = [...propsFormik.values.liquidacao]
        if (arrayLiquidacao && arrayLiquidacao.findIndex(x => !x?.modo_liquidacao || !x?.modo_liquidacao.value) === -1 && (index + 1) === arrayLiquidacao.length) {
            // setArrayRef([...arrayRef, React.createRef()])
            propsFormik.setFieldValue(`liquidacao[${index + 1}]`, { modo_liquidacao: undefined, data_liquidacao: propsFormik?.values?.liquidacao[0]?.data_liquidacao || undefined, valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined })
            // arrayHelpers.insert(propsFormik?.values?.liquidacao?.length, { modo_liquidacao: undefined, data_liquidacao: propsFormik?.values?.liquidacao[0]?.data_liquidacao || undefined, valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined })
        }
    }

    const adicionarLinhaNovo = (arrayHelpers, propsFormik, index) => {

        let primeiroValor = convertValueToFloat(propsFormik.values.liquidacao[index].valor).toFixed(2)

        let valorTotalLancamento = convertValueToFloat(propsFormik.values.valor_geral_lancamento)

        const valorRestante = convertValueToFloat(valorTotalLancamento - primeiroValor)

        propsFormik.setFieldValue(`liquidacao[${index + 1}]`, { modo_liquidacao: undefined, data_liquidacao: propsFormik?.values?.liquidacao[0]?.data_liquidacao || undefined, valor_liquidacao: valorRestante || undefined, valor_liquidacao_formatado: formatarMoeda(valorRestante) || undefined, parcelado: false, periodicidade: undefined, parcela: undefined })
        inputValorLiquidacao.current[index + 1] = valorRestante

        propsFormik.setFieldValue(`liquidacao[${index + 1}].valor_liquidacao`, valorRestante)
        propsFormik.setFieldValue(`liquidacao[${index + 1}].valor`, valorRestante)
        propsFormik.setFieldValue(`liquidacao[${index + 1}].valor_liquidacao_formatado`, formatarMoeda(valorRestante))
        propsFormik.setFieldValue(`liquidacao[${index + 1}].valor_formatado`, valorRestante)
        propsFormik.setFieldValue(`liquidacao[${index + 1}].valor_original`, valorRestante)

    }

    const verificaValor = _ => {
        let valorLiquidado = parseFloat(propsFormik?.values?.liquidacao?.filter(x => x?.valor_liquidacao || x?.valor_liquidacao_formatado).reduce((partialSum, a) => partialSum + convertValueToFloat(a?.valor_liquidacao || a?.valor_liquidacao_formatado), 0))

        let resultado = []
        for (let i = 0; i < propsFormik?.values?.liquidacao.length; i++) {
            const item = propsFormik?.values?.liquidacao[i];

            const acrescimo = item.acrescimo ? parseFloat(item.acrescimo) : 0
            const juros_multa = item.juros_multa ? parseFloat(item.juros_multa) : 0
            const desconto = item.desconto ? parseFloat(item.desconto) : 0
            const imposto_nfse = item.imposto_nfse ? parseFloat(item.imposto_nfse) : 0
            const glosas = item.glosas ? parseFloat(item.glosas) : 0
            const outros_impostos = item.outros_impostos ? parseFloat(item.outros_impostos) : 0

            let valor = acrescimo + juros_multa - desconto - imposto_nfse - glosas - outros_impostos

            resultado.push(valor)
        }

        let valorRetencoes = resultado.reduce((acc, valor) => acc + valor, 0)

        let valorLancamento = parseFloat(propsFormik?.values?.valor_geral_lancamento)


        switch (true) {
            case valorRetencoes < 0:
                let soma = valorRetencoes * - 1 + valorLiquidado
                if (soma != valorLancamento) {
                    setMsgValidacao(<>Valor Liquidado diferente do Valor Lançado! Deseja continuar ? </>)
                    setShowModalValidaValor(true)
                    setValidaMaior(false)
                } else
                    adicionarLiquidacao(props.id && props.id || props.propsFormik.values.id_lancamento_alteracao)
                break;

            case valorRetencoes > 0:
                if (valorLancamento !== valorLiquidado) {
                    setMsgValidacao(<>Valor Liquidado diferente do Valor Lançado! Deseja continuar ? </>)
                    setShowModalValidaValor(true)
                    setValidaMaior(false)
                } else
                    adicionarLiquidacao(props.id && props.id || props.propsFormik.values.id_lancamento_alteracao)
                break;

            case valorRetencoes == 0:
                if (valorLancamento != valorLiquidado) {
                    setMsgValidacao(<>Valor Liquidado diferente do Valor Lançado! Deseja continuar ? </>)
                    setShowModalValidaValor(true)
                    setValidaMaior(false)
                } else
                    adicionarLiquidacao(props.id && props.id || props.propsFormik.values.id_lancamento_alteracao)
                break;

            default:
                adicionarLiquidacao(props.id && props.id || props.propsFormik.values.id_lancamento_alteracao)
                break;
        }

        // if (valorRetencoes < 0) {
        //     let soma = valorRetencoes * - 1 + valorLiquidado
        //     if (soma != valorLancamento) {
        //         setMsgValidacao(<>Valor Liquidado diferente do Valor Lançado! Deseja continuar ? </>)
        //         setShowModalValidaValor(true)
        //         setValidaMaior(false)
        //     }
        // } else {
        //     setMsgValidacao(<>Valor Liquidado diferente do Valor Lançado! Deseja continuar ? </>)
        //     setShowModalValidaValor(true)
        //     setValidaMaior(false)
        // }

        // if (valorRetencoes != 0) {
        //     setMsgValidacao(<>Valor Liquidado diferente do Valor Lançado! Deseja continuar ? </>)
        //     setShowModalValidaValor(true)
        //     setValidaMaior(false)

        // }
        // else if (valorLiquidado !== valorLancamento) {
        //     setMsgValidacao(<>Valor Liquidado diferente do Valor Lançado! Deseja continuar ? </>)
        //     setShowModalValidaValor(true)
        //     setValidaMaior(false)
        // }
        // else {
        //     adicionarLiquidacao(props.id && props.id || props.propsFormik.values.id_lancamento_alteracao)
        // }
    }

    const operacoes = [
        { propriedade: 'acrescimo', operacao: 'soma' },
        { propriedade: 'desconto', operacao: 'subtrai' },
        { propriedade: 'juros_multa', operacao: 'soma' },
        { propriedade: 'imposto_nfse', operacao: 'subtrai' },
        { propriedade: 'glosas', operacao: 'subtrai' },
        { propriedade: 'outros_impostos', operacao: 'subtrai' }
    ];

    useEffect(_ => {
        let idx = propsFormik.values.id_lancamento_alteracao
        if (idx && (dadosMovimentoLancamento[idx]?.valor !== propsFormik.values.valor_geral_lancamento)) {

            return propsFormik.values.liquidacao?.forEach((_, i) => atualizarValoresLiquidacao(propsFormik.values.liquidacao, i, propsFormik))
        }
        else if (!propsFormik.values.parcelado_lancamento && !propsFormik.values.id_lancamento_alteracao) {
            return propsFormik.values.liquidacao?.filter(x => x.acrescimo || x.desconto || x.juros_multa
                || x.imposto_nfse || x.glosas || x.outros_impostos)?.forEach((_, i) => atualizarValoresLiquidacao(propsFormik.values.liquidacao, i, propsFormik))
        }

    }, [propsFormik.values.valor_geral_lancamento])

    const atualizarValoresLiquidacao = (arrayLiquidacao, index, propsFormik) => {

        let valor_liquidacao = convertValueToFloat(propsFormik?.values?.valor_geral_lancamento || 0)

        for (let i = 0; i < operacoes.length; i++) {
            const { propriedade, operacao } = operacoes[i];

            if (arrayLiquidacao[index][propriedade] !== undefined) {
                if (operacao === 'soma') {
                    valor_liquidacao += parseFloat(arrayLiquidacao[index][propriedade]);
                } else if (operacao === 'subtrai') {
                    valor_liquidacao -= parseFloat(arrayLiquidacao[index][propriedade]);
                }
            }
        }
        arrayLiquidacao[index].valor = valor_liquidacao

        propsFormik.setFieldValue(`liquidacao[${index}].valor_liquidacao`, valor_liquidacao.toString().trim())
        propsFormik.setFieldValue(`liquidacao[${index}].valor`, valor_liquidacao.toString().trim())
        propsFormik.setFieldValue(`liquidacao[${index}].valor_liquidacao_formatado`, formatarMoeda(valor_liquidacao.toString()))
        propsFormik.setFieldValue(`liquidacao[${index}].valor_formatado`, valor_liquidacao.toString())
    }

    const handleValidation = async () => {
        await props.setValidarLancamento(true)
        await propsFormik.validateForm()
            .then(errors => {

                if (Object.keys(errors).length > 0) {
                    propsFormik.setErrors(errors);
                    let liquidacoes = Array(errors.liquidacao && errors.liquidacao.length).fill({ modo_liquidacao: true, valor: true, data_liquidacao: true })
                    propsFormik.setTouched({
                        categoria_lancamento: true, cliente_lancamento: true, previsao_vencimento_lancamento: true,
                        tipo_lancamento: true, valor_geral_lancamento: true, liquidacao: liquidacoes
                    })

                } else {
                    propsFormik.setTouched({
                        categoria_lancamento: false, cliente_lancamento: false, previsao_vencimento_lancamento: false,
                        tipo_lancamento: false, valor_geral_lancamento: false, liquidacao: [{ modo_liquidacao: false, valor: false, data_liquidacao: false }]
                    })
                    verificaGuiaAdicionada()

                    props.setValidarLancamento(false)
                }
            })
    };

    useEffect(_ => {
        if (props.liquidar && props.id) {

            propsFormik.setFieldValue(`liquidacao`, [{ modo_liquidacao: null, data: propsFormik.values.previsao_vencimento_lancamento, valor_liquidacao: formatarMoeda(convertValueToFloat(propsFormik.values.valor_geral_lancamento).toFixed(2)) || undefined, periodicidade: null, parcela: null, valor_original: convertValueToFloat(propsFormik.values.valor_geral_lancamento) }])//Limpar primeira linha liquidação

        }
    }, [props.liquidar])


    const adicionarLiquidacao = (alteracao) => {
        // props.setAdicionando(true)
        let keysLancamento = Object.keys(propsFormik.values)
        keysLancamento = keysLancamento.filter(key => key === 'cliente' || key === 'tipo_lancamento' || key === 'categoria' || key === 'previsao_vencimento')
        let verificaVazioLancamento = keysLancamento.map(k => propsFormik.values[k])
        verificaVazioLancamento = verificaVazioLancamento.filter(x => !x)

        let keysLiquidacao = Object.keys(propsFormik.values.liquidacao)
        keysLiquidacao = keysLiquidacao.filter(key => key === 'modo_liquidacao' || key === 'data_liquidacao' || key === 'valor')
        let verificaVazioLiquidacao = keysLiquidacao.map(k => propsFormik.values[k])
        verificaVazioLiquidacao = verificaVazioLiquidacao.filter(x => !x)
        delete propsFormik.errors.liquidacao
        if (!alteracao && alteracao !== 0) {
            if (props.tabLancamento && !verificaVazioLancamento.length) {
                props.setAdicionando(true)

                let arrayLancamento = [...dadosMovimentoLancamento]
                let arrayRetorno = propsFormik?.values?.liquidacao?.filter(item => item.modo_liquidacao && (item.valor_liquidacao || item.valor || item.valor_liquidacao_formatado)).map(itemLiquidado => ({

                    data: itemLiquidado.data || null, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: propsFormik.values.valor_geral_lancamento,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento_empresa?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, tipo_lancamento_descricao: propsFormik?.values?.tipo_lancamento?.label, descricao: propsFormik?.values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: propsFormik?.values?.previsao_vencimento_lancamento, tipo_lancamento: propsFormik?.values?.tipo_lancamento, categoria: propsFormik?.values?.categoria_lancamento, atendimento: propsFormik?.values?.atendimento_lancamento,
                    conta_bancaria: propsFormik?.values?.conta_bancaria_lancamento, tipo_documento: propsFormik?.values?.tipo_documento_lancamento, valor_total_liquidacao: valorTotalLiquidacao, anotacao: itemLiquidado?.anotacoes, arquivos: props.dataArquivos, empresa: propsFormik.values.empresa,
                    tipo_documento_lancamento: propsFormik?.values?.tipo_documento_lancamento, conta_bancaria_lancamento: propsFormik?.values?.conta_bancaria_lancamento,
                    conta_bancaria_lancamento_empresa: propsFormik?.values?.conta_bancaria_lancamento_empresa

                }))[0]
                arrayLancamento = arrayLancamento?.length > 0 ? [...arrayLancamento, arrayRetorno] : [arrayRetorno]
                arrayLancamento = arrayLancamento.flat(2)

                // dadosMovimentoLancamento = [...arrayLancamento]
                // props.setDadosMovimentoLancamento([...arrayLancamento])

                let arrayLiquidacao = propsFormik?.values?.liquidacao?.filter(item => item.modo_liquidacao && (item.valor_liquidacao || item?.valor_liquidacao_formatado)).map(itemLiquidado => ({
                    data: itemLiquidado.data, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: itemLiquidado?.valor_liquidacao || convertValueToFloat(itemLiquidado?.valor_liquidacao_formatado),
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento_empresa?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, descricao: propsFormik?.values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: propsFormik?.values?.previsao_vencimento_lancamento, modo_liquidacao_descricao: itemLiquidado?.modo_liquidacao?.label, modo_liquidacao: itemLiquidado?.modo_liquidacao,
                    tipo_lancamento: propsFormik?.values?.tipo_lancamento, anotacao: itemLiquidado?.anotacoes,
                    acrescimo: itemLiquidado?.acrescimo, desconto: itemLiquidado?.desconto, juros_multa: itemLiquidado?.juros_multa, imposto_nfse: itemLiquidado?.imposto_nfse, glosas: itemLiquidado?.glosas, outros_impostos: itemLiquidado?.outros_impostos, arquivos: props.dataArquivos,
                    retencoes_acrescimos: { acrescimo: itemLiquidado?.acrescimo || 0, desconto: itemLiquidado?.desconto || 0, juros_multa: itemLiquidado?.juros_multa || 0, imposto_nfse: itemLiquidado?.imposto_nfse || 0, glosas: itemLiquidado?.glosas || 0, outros_impostos: itemLiquidado?.outros_impostos || 0 }, empresa: propsFormik.values.empresa

                }))

                let copiaDados = [...arrayLancamento]
                copiaDados[arrayLancamento.length - 1].lancamento_movimento_liquidacao = [...arrayLiquidacao]
                props.setDadosMovimentoLancamento(copiaDados)
                // propsFormik.values.liquidacoes = [...propsFormik?.values?.liquidacao.map(itemLiquidado => ({
                //     data: itemLiquidado.data, cliente: itemLiquidado?.cliente, socio: 'Teste', estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: itemLiquidado?.valor_liquidacao,
                //     data_liquidacao: itemLiquidado?.data_liquidacao, nfse: 'NFSE', parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: itemLiquidado?.periodicidade?.value
                // })), ...propsFormik.values.liquidacoes]
                // propsFormik.values.liquidacoes.push(arrayLiquidacao)
                setValorTotalLiquidacao(0.00)
                propsFormik.setFieldValue('liquidacao', [{ modo_liquidacao: null, data_liquidacao: '', valor: null }])
                mostrarToast('sucesso', 'Operação efetuada com sucesso')
                limparLiquidacao()
            }
            else if (!verificaVazioLancamento.length) {
                props.setAdicionando(true)

                let arrayLancamento = cloneDeep(propsFormik?.values?.arrayLancamentos)

                let arrayRetorno = propsFormik?.values?.liquidacao?.filter(item => item.modo_liquidacao && (item.valor_liquidacao || item.valor)).map(itemLiquidado => ({
                    data: itemLiquidado.data || null, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: propsFormik.values.valor_geral_lancamento,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, tipo_lancamento_descricao: propsFormik?.values?.tipo_lancamento?.label, descricao: propsFormik?.values?.descricao_lancamento,
                    // console.log('conta_bancaria_lancamento: ', conta_bancaria_lancamento);
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: propsFormik?.values?.previsao_vencimento_lancamento, tipo_lancamento: propsFormik?.values?.tipo_lancamento, categoria: propsFormik?.values?.categoria_lancamento, atendimento: propsFormik?.values?.atendimento_lancamento, conta_bancaria: propsFormik?.values?.conta_bancaria_lancamento,
                    tipo_documento: propsFormik?.values?.tipo_documento_lancamento, tipo_documento_lancamento: propsFormik?.values?.tipo_documento_lancamento, valor_total_liquidacao: valorTotalLiquidacao, anotacao: itemLiquidado?.anotacoes, arquivos: props.dataArquivos, empresa: propsFormik.values.empresa,
                    tipo_documento_lancamento: propsFormik?.values?.tipo_documento_lancamento, conta_bancaria_lancamento_empresa: propsFormik?.values?.conta_bancaria_lancamento_empresa, conta_bancaria_lancamento: propsFormik?.values?.conta_bancaria_lancamento

                }))[0]

                arrayLancamento = arrayLancamento?.length > 0 ? [...arrayLancamento, arrayRetorno] : [arrayRetorno]
                arrayLancamento = arrayLancamento.flat(2)
                propsFormik.setFieldValue('arrayLancamentos', [...arrayLancamento])
                let arrayLiquidacao = propsFormik?.values?.liquidacao?.filter(item => item.modo_liquidacao && item.valor_liquidacao).map(itemLiquidado => ({
                    data: itemLiquidado.data, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: (itemLiquidado?.valor_liquidacao || itemLiquidado?.valor_liquidacao_formatado),
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, descricao: propsFormik?.values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: propsFormik?.values?.previsao_vencimento_lancamento, modo_liquidacao_descricao: itemLiquidado?.modo_liquidacao?.label, modo_liquidacao: itemLiquidado?.modo_liquidacao,
                    tipo_lancamento: propsFormik?.values?.tipo_lancamento, anotacao: itemLiquidado?.anotacoes,
                    acrescimo: itemLiquidado?.acrescimo, desconto: itemLiquidado?.desconto, juros_multa: itemLiquidado?.juros_multa, imposto_nfse: itemLiquidado?.imposto_nfse, glosas: itemLiquidado?.glosas, outros_impostos: itemLiquidado?.outros_impostos, arquivos: props.dataArquivos,
                    retencoes_acrescimos: { acrescimo: itemLiquidado?.acrescimo || 0, desconto: itemLiquidado?.desconto || 0, juros_multa: itemLiquidado?.juros_multa || 0, imposto_nfse: itemLiquidado?.imposto_nfse || 0, glosas: itemLiquidado?.glosas || 0, outros_impostos: itemLiquidado?.outros_impostos || 0 }, empresa: propsFormik.values.empresa


                }))
                // propsFormik.values.liquidacoes = [...propsFormik?.values?.liquidacao.map(itemLiquidado => ({
                //     data: itemLiquidado.data, cliente: itemLiquidado?.cliente, socio: 'Teste', estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: itemLiquidado?.valor_liquidacao,
                //     data_liquidacao: itemLiquidado?.data_liquidacao, nfse: 'NFSE', parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: itemLiquidado?.periodicidade?.value
                // })), ...propsFormik.values.liquidacoes]
                let arrayLiquidacoes = propsFormik.values.liquidacoes
                arrayLiquidacoes.push(arrayLiquidacao)
                propsFormik.setFieldValue('liquidacoes', arrayLiquidacoes)
                mostrarToast('sucesso', 'Operação efetuada com sucesso')
                setValorTotalLiquidacao(0.00)
                propsFormik.setFieldValue('liquidacao', [{ modo_liquidacao: null, data_liquidacao: '', valor: null }])
                limparLiquidacao()
            }
            else {
                mostrarToast('erro', 'Preencha os campos obrigatórios(*) para o lançamento')
            }

        } else {
            if (props.tabLancamento && !verificaVazioLancamento.length) {

                props.setAdicionando(true)

                let idxAlteracao = parseInt(propsFormik.values.id_lancamento_alteracao)
                let idLancamento = parseInt(propsFormik.values.id_lancamento)
                let arrayLancamento = [...dadosMovimentoLancamento]
                let arrayRetorno = propsFormik?.values?.liquidacao?.filter(item => item.modo_liquidacao && (item.valor_liquidacao || item.valor)).map(itemLiquidado => ({

                    id: idLancamento, data: itemLiquidado.data || null, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: propsFormik.values.valor_geral_lancamento,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, tipo_lancamento_descricao: propsFormik?.values?.tipo_lancamento?.label, descricao: propsFormik?.values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: propsFormik?.values?.previsao_vencimento_lancamento, tipo_lancamento: propsFormik?.values?.tipo_lancamento, categoria: propsFormik?.values?.categoria_lancamento, atendimento: propsFormik?.values?.atendimento_lancamento, conta_bancaria: propsFormik?.values?.conta_bancaria_lancamento,
                    tipo_documento: propsFormik?.values?.tipo_documento_lancamento, valor_total_liquidacao: valorTotalLiquidacao,
                    numero_parcela: propsFormik?.values?.numero_parcela, anotacao: itemLiquidado?.anotacoes, arquivos: props.dataArquivos, empresa: propsFormik.values.empresa,
                    tipo_documento_lancamento: propsFormik?.values?.tipo_documento_lancamento, conta_bancaria_lancamento_empresa: propsFormik?.values?.conta_bancaria_lancamento_empresa, conta_bancaria_lancamento: propsFormik?.values?.conta_bancaria_lancamento
                }))[0]

                arrayLancamento[idxAlteracao] = arrayRetorno
                arrayLancamento = arrayLancamento.flat(2)

                let arrayLiquidacao = propsFormik?.values?.liquidacao?.filter(item => item.modo_liquidacao && item.valor_liquidacao).map(itemLiquidado => ({
                    id_liquidacao: itemLiquidado.id_liquidacao, data: itemLiquidado.data, cliente: propsFormik?.values?.cliente, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: itemLiquidado?.valor_liquidacao,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria?.value, categoria_id: propsFormik?.values?.categoria?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, descricao: propsFormik?.values?.descricao,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento?.value, data_prevista: propsFormik?.values?.previsao_vencimento, modo_liquidacao_descricao: itemLiquidado?.modo_liquidacao?.label,
                    modo_liquidacao: itemLiquidado?.modo_liquidacao, tipo_lancamento: propsFormik?.values?.tipo_lancamento, anotacao: itemLiquidado?.anotacoes, arquivos: props.dataArquivos,
                    acrescimo: itemLiquidado?.acrescimo, desconto: itemLiquidado?.desconto, juros_multa: itemLiquidado?.juros_multa, imposto_nfse: itemLiquidado?.imposto_nfse, glosas: itemLiquidado?.glosas, outros_impostos: itemLiquidado?.outros_impostos,
                    retencoes_acrescimos: { acrescimo: itemLiquidado?.acrescimo || 0, desconto: itemLiquidado?.desconto || 0, juros_multa: itemLiquidado?.juros_multa || 0, imposto_nfse: itemLiquidado?.imposto_nfse || 0, glosas: itemLiquidado?.glosas || 0, outros_impostos: itemLiquidado?.outros_impostos || 0 }, empresa: propsFormik.values.empresa


                }))
                let copiaDados = [...arrayLancamento]

                copiaDados[idxAlteracao].lancamento_movimento_liquidacao = [...arrayLiquidacao]
                props.setDadosMovimentoLancamento(copiaDados)
                mostrarToast('sucesso', 'Operação efetuada com sucesso')

                setValorTotalLiquidacao(0.00)

                propsFormik.setFieldValue('liquidacao', [{ modo_liquidacao: null, data_liquidacao: '', valor: null }])

                limparLiquidacao()
            }

            else {
                props.setAdicionando(true)

                let idxAlteracao = parseInt(propsFormik.values.id_lancamento_alteracao)
                let arrayLancamento = cloneDeep(propsFormik?.values?.arrayLancamentos)
                let arrayRetorno = propsFormik?.values?.liquidacao?.filter(item => item?.modo_liquidacao && item?.valor_liquidacao).map(itemLiquidado => ({
                    data: itemLiquidado.data || null, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: propsFormik.values.valor_geral_lancamento,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, tipo_lancamento_descricao: propsFormik?.values?.tipo_lancamento?.label, descricao: propsFormik?.values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: propsFormik?.values?.previsao_vencimento_lancamento, tipo_lancamento: propsFormik?.values?.tipo_lancamento, categoria: propsFormik?.values?.categoria_lancamento, atendimento: propsFormik?.values?.atendimento_lancamento,
                    conta_bancaria: propsFormik?.values?.conta_bancaria_lancamento, tipo_documento: propsFormik?.values?.tipo_documento_lancamento, valor_total_liquidacao: valorTotalLiquidacao, anotacao: itemLiquidado?.anotacoes, arquivos: props.dataArquivos, empresa: propsFormik.values.empresa, conta_bancaria_lancamento: propsFormik?.values?.conta_bancaria_lancamento, conta_bancaria_lancamento_empresa: propsFormik?.values?.conta_bancaria_lancamento_empresa
                }))[0]
                arrayLancamento[idxAlteracao] = arrayRetorno

                // arrayLancamento = arrayLancamento?.length > 0 ? [...arrayLancamento, arrayRetorno] : [arrayRetorno]
                arrayLancamento = arrayLancamento.flat(2)

                propsFormik.setFieldValue('arrayLancamentos', [...arrayLancamento])

                let arrayLiquidacao = propsFormik?.values?.liquidacao?.filter(item => item?.modo_liquidacao && item?.valor_liquidacao).map(itemLiquidado => ({
                    data: itemLiquidado.data, cliente: propsFormik?.values?.cliente, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: itemLiquidado?.valor_liquidacao,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria?.value, categoria_id: propsFormik?.values?.categoria?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, descricao: propsFormik?.values?.descricao,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento?.value, data_prevista: propsFormik?.values?.previsao_vencimento_lancamento, modo_liquidacao_descricao: itemLiquidado?.modo_liquidacao?.label, modo_liquidacao: itemLiquidado?.modo_liquidacao,
                    tipo_lancamento: propsFormik?.values?.tipo_lancamento, anotacao: itemLiquidado?.anotacoes, arquivos: props.dataArquivos,
                    acrescimo: itemLiquidado?.acrescimo, desconto: itemLiquidado?.desconto, juros_multa: itemLiquidado?.juros_multa, imposto_nfse: itemLiquidado?.imposto_nfse, glosas: itemLiquidado?.glosas, outros_impostos: itemLiquidado?.outros_impostos,
                    retencoes_acrescimos: { acrescimo: itemLiquidado?.acrescimo || 0, desconto: itemLiquidado?.desconto || 0, juros_multa: itemLiquidado?.juros_multa || 0, imposto_nfse: itemLiquidado?.imposto_nfse || 0, glosas: itemLiquidado?.glosas || 0, outros_impostos: itemLiquidado?.outros_impostos || 0 }, empresa: propsFormik.values.empresa


                }))
                // propsFormik.values.liquidacoes = [...propsFormik?.values?.liquidacao.map(itemLiquidado => ({
                //     data: itemLiquidado.data, cliente: itemLiquidado?.cliente, socio: 'Teste', estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: itemLiquidado?.valor_liquidacao,
                //     data_liquidacao: itemLiquidado?.data_liquidacao, nfse: 'NFSE', parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: itemLiquidado?.periodicidade?.value
                // })), ...propsFormik.values.liquidacoes]
                propsFormik.values.liquidacoes[idxAlteracao] = []
                propsFormik.values.liquidacoes[idxAlteracao] = arrayLiquidacao
                mostrarToast('sucesso', 'Operação efetuada com sucesso')

                setValorTotalLiquidacao(0.00)

                propsFormik.setFieldValue('liquidacao', [{ modo_liquidacao: null, data_liquidacao: '', valor: null }])
                limparLiquidacao()
            }

        }
    }

    const checarLiquidado = (data_liquidacao, index) => {

        if (props.id) {
            if (!(props?.valoresIniciais?.liquidacao && props?.valoresIniciais?.liquidacao[index]?.data_liquidacao) && moment(data_liquidacao).isValid()) {
                propsFormik.values.liquidacao[index].liquidar = true
            }
            else {
                propsFormik.values.liquidacao[index].liquidar = false
            }
        }

    }
    const limparLiquidacao = _ => {


        propsFormik.setFieldValue('liquidacao', [{ data_liquidacao: propsFormik.values.previsao_vencimento_lancamento }])
        propsFormik.setFieldValue('alterando_linha_lancamento', false)
        propsFormik.setFieldValue(`cliente_lancamento`, null)
        propsFormik.setFieldValue(`valor_geral_lancamento`, null)
        propsFormik.setFieldValue(`valor_geral_lancamento_formatado`, null)
        propsFormik.setFieldValue(`tipo_documento_lancamento`, null)
        propsFormik.setFieldValue(`atendimento_lancamento`, null)
        propsFormik.setFieldValue(`estabelecimento_saude`, null)
        propsFormik.setFieldValue(`id_lancamento_alteracao`, null)
        propsFormik.setFieldValue(`nota_fiscal_lancamento`, null)
        propsFormik.setFieldValue(`descricao_lancamento`, null)
        propsFormik.setFieldValue(`empresa`, null)
        // if (propsFormik.values.alterando_linha_lancamento) {
        // propsFormik.setFieldValue(`tipo_lancamento`, null)
        propsFormik.setFieldValue(`categoria_lancamento`, null)
        propsFormik.setFieldValue(`conta_bancaria_lancamento`, null)
        propsFormik.setFieldValue(`conta_bancaria_lancamento_empresa`, null)
        // propsFormik.setFieldValue(`previsao_vencimento_lancamento`, null)
        // propsFormik.setFieldValue('liquidacao', [{ data_liquidacao: null }])

        // }

        props.setAdicionando(false)

        props.setIdAtendimento(null)
        props.limparArquivos(true)

    }
    useEffect(_ => {
        props.id = props.tabLancamento ? null : props.id

    }, [props])

    const atualizarDataLiquidacao = (data) => {

        const data_atualizada = values.liquidacao.map((item, index) => propsFormik.setFieldValue(`liquidacao[${index}].data_liquidacao`, data));

        return data_atualizada
    };

    if (ref) {
        ref.current = {
            atualizarDataLiquidacao: (data) => atualizarDataLiquidacao(data),
        }
    }

    const verificaGuiaAdicionada = _ => {

        let atendimento = propsFormik?.values?.atendimento_lancamento
        let empresa = propsFormik?.values?.empresa
        let tipo_cliente = propsFormik?.values?.objeto_entidade.id_tipo_cliente
        let empresa_entidade = propsFormik?.values?.objeto_entidade.sigla

        let validacao = []

        if (atendimento) {

            if (atendimento.guia_status_id !== 4) {
                validacao.push('Atendimento(Guia) selecionada encontra-se com status diferente de <b>CONFERIDA!</b>')
            }

            if (tipo_cliente == 1 && empresa?.label != empresa_entidade) {
                validacao.push('O Campo Empresa difere da empresa do Atendimento (Guia). Deseja Continuar?')
            }

            if (props.tabLancamento && dadosMovimentoLancamento.length > 0) {
                if (propsFormik?.values?.alterando_linha_lancamento) {
                    let idxAlteracao = propsFormik?.values?.id_lancamento_alteracao
                    atendimento = dadosMovimentoLancamento?.filter((x, idx) => idxAlteracao !== idx && x?.atendimento?.value === atendimento?.value)

                } else
                    atendimento = dadosMovimentoLancamento?.filter(x => x?.atendimento?.value === atendimento?.value)
            }
            else {
                if (propsFormik?.values?.alterando_linha_lancamento) {
                    let idxAlteracao = propsFormik?.values?.id_lancamento_alteracao
                    atendimento = propsFormik.values.arrayLancamentos?.filter((x, idx) => idxAlteracao !== idx && x?.atendimento?.value === atendimento?.value)
                }

                else
                    atendimento = propsFormik.values.arrayLancamentos?.filter(x => x?.atendimento?.value === atendimento?.value)

            }

            if (atendimento?.length > 0) {
                // setShowModalValidaGuiaRepetida(true)
                // setMsgValidacaoGuiaRepetida(<>Guia selecionada encontra-se adicionada! Deseja continuar ?</>)
                validacao.push('Guia selecionada encontra-se adicionada!')

            }
            // else {
            //     verificaValor()
            // }

            if (validacao.length > 0) {

                setShowModalValidaGuiaRepetida(true)

                setMsgValidacaoGuia(
                    <>
                        <div dangerouslySetInnerHTML={{ __html: validacao && validacao.map((x, idx) => idx + 1 + ' - ' + x.replace(/"/g, '') + '<br/><br/>').join('') }} />
                    </>)
            }
            else {
                verificaValor()
            }
        } else
            verificaValor()

    }

    return (
        <>
            {
                !propsFormik.values.parcelado_lancamento ?
                    <>
                        <div className={props.tabLancamento === true ? `mt-40 tab-tittle` : 'mt-40'}>
                            <HeaderCadastro
                                titulo={`Liquidação  ${props.id && propsFormik?.values?.liquidacao && propsFormik?.values?.liquidacao[0]?.numero_parcela ? ` (${propsFormik?.values?.liquidacao && propsFormik?.values?.liquidacao[0]?.numero_parcela}° Parcela )` : ''}`}
                                classeChildren='space-between-100'
                                switch={propsFormik.values && !props.tabLancamento && !props.idMovimento}

                                liquidar={props.liquidar}
                                setLiquidar={props.setLiquidar}
                            >

                            </HeaderCadastro>

                        </div>

                        <>
                            {props.liquidar || props.tabLancamento || props.idMovimento ?
                                <>
                                    <div className="card mb-20 scroll-x">
                                        <div className="card-body  executantes-internos-float" ref={refLiquidacao}>
                                            <FieldArray name="liquidacao">
                                                {(arrayHelpers => (
                                                    <>
                                                        {props?.propsFormik?.values?.liquidacao && props?.propsFormik?.values?.liquidacao?.length > 0 ?
                                                            props?.propsFormik?.values?.liquidacao?.map((item, index) =>
                                                                <>
                                                                    <>
                                                                        <div className={`modal-header pr-0 pl-0 ${index > 0 ? 'bt-1' : ''}`}>
                                                                            <div className='row col-sm-12 col-xl-11 col-lg-11 col-md-12'>

                                                                                <InputCurrency
                                                                                    className='valor-indicador-azul'
                                                                                    onUpdate={val => {
                                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                        inputValorAcrescimo.current[index] = valor
                                                                                    }}
                                                                                    label='Acrescimo R$'
                                                                                    name={`liquidacao[${index}].acrescimo`}
                                                                                    classeTooltip={'tooltip-input-modal'}
                                                                                    propsFormik={propsFormik}
                                                                                    max={100000000}
                                                                                    value={inputValorAcrescimo.current[index] || propsFormik.values.liquidacao[index].acrescimo || propsFormik.values.liquidacao[index].acrescimo_formatado}
                                                                                    onBlur={(e) => {
                                                                                        if (inputValorAcrescimo.current[index] || inputValorAcrescimo.current[index] === 0) {
                                                                                            propsFormik.values.liquidacao[index].acrescimo = parseFloat(inputValorAcrescimo.current[index] && inputValorAcrescimo.current[index].toString().trim());
                                                                                            propsFormik.values.liquidacao[index].acrescimo_formatado = formatarMoeda(inputValorAcrescimo.current && inputValorAcrescimo.current[index].toString());

                                                                                            atualizarValoresLiquidacao(propsFormik.values.liquidacao, index, propsFormik)
                                                                                        }
                                                                                        inputValorAcrescimo.current[index] = null;
                                                                                    }

                                                                                    }
                                                                                    classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />


                                                                                <InputCurrency
                                                                                    className='valor-indicador-errado'
                                                                                    onUpdate={val => {
                                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                        inputValorDesconto.current[index] = valor
                                                                                    }}
                                                                                    label='Desconto R$'
                                                                                    name={`liquidacao[${index}].desconto`}
                                                                                    classeTooltip={'tooltip-input-modal'}
                                                                                    propsFormik={propsFormik}
                                                                                    max={100000000}
                                                                                    value={inputValorDesconto.current[index] || propsFormik.values.liquidacao[index].desconto_formatado || propsFormik.values.liquidacao[index].desconto}
                                                                                    onBlur={(e) => {
                                                                                        if (inputValorDesconto.current[index] || inputValorDesconto.current[index] === 0) {
                                                                                            propsFormik.values.liquidacao[index].desconto = inputValorDesconto.current[index] && inputValorDesconto.current[index].toString().trim();
                                                                                            propsFormik.values.liquidacao[index].desconto_formatado = formatarMoeda(inputValorDesconto.current && inputValorDesconto.current[index].toString());

                                                                                            atualizarValoresLiquidacao(propsFormik.values.liquidacao, index, propsFormik)
                                                                                        }
                                                                                        inputValorDesconto.current[index] = null;
                                                                                    }

                                                                                    }
                                                                                    classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                                <InputCurrency
                                                                                    className='valor-indicador-azul'
                                                                                    onUpdate={val => {
                                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                        inputValorJurosMulta.current[index] = valor
                                                                                    }}
                                                                                    label='Juros/Multa R$'
                                                                                    name={`liquidacao[${index}].juros_multa`}
                                                                                    classeTooltip={'tooltip-input-modal'}
                                                                                    propsFormik={propsFormik}
                                                                                    max={100000000}
                                                                                    value={inputValorJurosMulta.current[index] || propsFormik.values.liquidacao[index].juros_multa_formatado || propsFormik.values.liquidacao[index].juros_multa}
                                                                                    onBlur={(e) => {
                                                                                        if (inputValorJurosMulta.current[index] || inputValorJurosMulta.current[index] === 0) {
                                                                                            propsFormik.values.liquidacao[index].juros_multa = inputValorJurosMulta.current[index] && inputValorJurosMulta.current[index].toString().trim();
                                                                                            propsFormik.values.liquidacao[index].juros_multa_formatado = formatarMoeda(inputValorJurosMulta.current && inputValorJurosMulta.current[index].toString());

                                                                                            atualizarValoresLiquidacao(propsFormik.values.liquidacao, index, propsFormik)
                                                                                        }
                                                                                        inputValorJurosMulta.current[index] = null;
                                                                                    }

                                                                                    }
                                                                                    classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />


                                                                                <InputCurrency
                                                                                    className='valor-indicador-errado'
                                                                                    onUpdate={val => {
                                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                        inputValorImpostoNFE.current[index] = valor
                                                                                    }}
                                                                                    label='Imposto NFSE R$'
                                                                                    name={`liquidacao[${index}].imposto_nfse`}
                                                                                    classeTooltip={'tooltip-input-modal'}
                                                                                    propsFormik={propsFormik}
                                                                                    max={100000000}
                                                                                    value={inputValorImpostoNFE.current[index] || propsFormik.values.liquidacao[index].imposto_nfse_formatado || propsFormik.values.liquidacao[index].imposto_nfse}
                                                                                    onBlur={(e) => {
                                                                                        if (inputValorImpostoNFE.current[index] || inputValorImpostoNFE.current[index] === 0) {
                                                                                            propsFormik.values.liquidacao[index].imposto_nfse = inputValorImpostoNFE.current[index] && inputValorImpostoNFE.current[index].toString().trim();
                                                                                            propsFormik.values.liquidacao[index].imposto_nfse_formatado = formatarMoeda(inputValorImpostoNFE.current && inputValorImpostoNFE.current[index].toString());

                                                                                            atualizarValoresLiquidacao(propsFormik.values.liquidacao, index, propsFormik)
                                                                                        }
                                                                                        inputValorImpostoNFE.current[index] = null;
                                                                                    }

                                                                                    }
                                                                                    classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                                <InputCurrency
                                                                                    className='valor-indicador-errado'
                                                                                    onUpdate={val => {
                                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                        inputValorGlosa.current[index] = valor
                                                                                    }}
                                                                                    label='Glosa R$'
                                                                                    name={`liquidacao[${index}].glosas`}
                                                                                    classeTooltip={'tooltip-input-modal'}
                                                                                    propsFormik={propsFormik}
                                                                                    max={100000000}
                                                                                    value={inputValorGlosa.current[index] || propsFormik.values.liquidacao[index].glosas_formatado || propsFormik.values.liquidacao[index].glosas}
                                                                                    onBlur={(e) => {
                                                                                        if (inputValorGlosa.current[index] || inputValorGlosa.current[index] === 0) {
                                                                                            propsFormik.values.liquidacao[index].glosas = inputValorGlosa.current[index] && inputValorGlosa.current[index].toString().trim() || 0;
                                                                                            propsFormik.values.liquidacao[index].glosas_formatado = formatarMoeda(inputValorGlosa.current && inputValorGlosa.current[index].toString());

                                                                                            atualizarValoresLiquidacao(propsFormik.values.liquidacao, index, propsFormik)
                                                                                        }
                                                                                        inputValorGlosa.current[index] = null;
                                                                                    }

                                                                                    }
                                                                                    classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />


                                                                                <InputCurrency
                                                                                    className='valor-indicador-errado'
                                                                                    onUpdate={val => {
                                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                        inputValorOutrosImpostos.current[index] = valor
                                                                                    }}
                                                                                    label='Outros R$'
                                                                                    name={`liquidacao[${index}].outros_impostos`}
                                                                                    classeTooltip={'tooltip-input-modal'}
                                                                                    propsFormik={propsFormik}
                                                                                    max={100000000}
                                                                                    value={inputValorOutrosImpostos.current[index] || propsFormik.values.liquidacao[index].outros_impostos_formatado || propsFormik.values.liquidacao[index].outros_impostos}
                                                                                    onBlur={(e) => {
                                                                                        if (inputValorOutrosImpostos.current[index] || inputValorOutrosImpostos.current[index] === 0) {
                                                                                            propsFormik.values.liquidacao[index].outros_impostos = inputValorOutrosImpostos.current[index] && inputValorOutrosImpostos.current[index].toString().trim();
                                                                                            propsFormik.values.liquidacao[index].outros_impostos_formatado = formatarMoeda(inputValorOutrosImpostos.current && inputValorOutrosImpostos.current[index].toString());

                                                                                            atualizarValoresLiquidacao(propsFormik.values.liquidacao, index, propsFormik)
                                                                                        }
                                                                                        inputValorOutrosImpostos.current[index] = null;
                                                                                    }


                                                                                    }
                                                                                    classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    <div className='modal-body-cadastro row mr-0 ml-0'>
                                                                        <div className='row col-sm-12 col-xl-11 col-lg-11 col-md-12'>
                                                                            <InputModoLiquidacao label={index === 0 ? 'Modo Liquidação' : undefined} obrigatorio={true} placeholder='Modo Liquidação' classeTooltip={'tooltip-input-modal'}
                                                                                name={`liquidacao[${index}].modo_liquidacao`} array={true} isClearable={false} propsFormik={props.propsFormik} tamanho="col-sm-12 8 col-xl-4 col-lg-4 col-md-12 pr-0"
                                                                                onChange={(option) => { atualizarDataLiquidacao(propsFormik?.values?.liquidacao[0]?.data_liquidacao) }} onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                                                            />

                                                                            <InputDatePicker tamanho={'col-lg-3 col-sm-12'} label={index === 0 ? "Data" : undefined} name={`liquidacao[${index}].data_liquidacao`} type="text" obrigatorio="true"
                                                                                setarValor={value => {
                                                                                    const newLiquidacao = propsFormik.values.liquidacao.map(item => ({
                                                                                        ...item,
                                                                                        data_liquidacao: value,
                                                                                    }));
                                                                                    propsFormik.setFieldValue('liquidacao', newLiquidacao)
                                                                                    checarLiquidado(value, index)
                                                                                }} classeTooltip={props.modal && 'tooltip-input-modal'} mostrarFeriado={true} placeholder={'Data'}
                                                                                disabled={index > 0}
                                                                                onBlurData={(data) => atualizarDataLiquidacao(data)}
                                                                                mostrarValidacaoAfter={props.liquidar}
                                                                            />
                                                                            <InputCurrency onUpdate={val => {
                                                                                let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                inputValorLiquidacao.current[index] = valor
                                                                            }}
                                                                                label={index === 0 ? 'Valor R$' : undefined} name={`liquidacao[${index}].valor`}
                                                                                value={inputValorLiquidacao?.current[index] || propsFormik?.values?.liquidacao[index]?.valor_liquidacao_formatado || props?.propsFormik?.values?.liquidacao[index]?.valor}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={props.propsFormik}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorLiquidacao.current[index] || inputValorLiquidacao.current[index] === 0) {
                                                                                        propsFormik.setFieldValue(`liquidacao[${index}].valor_liquidacao`, inputValorLiquidacao.current[index] && inputValorLiquidacao.current[index].toString().trim())
                                                                                        propsFormik.setFieldValue(`liquidacao[${index}].valor`, inputValorLiquidacao.current[index] && inputValorLiquidacao.current[index].toString().trim())
                                                                                        propsFormik.setFieldValue(`liquidacao[${index}].valor_liquidacao_formatado`, formatarMoeda(inputValorLiquidacao.current[index] && inputValorLiquidacao.current[index].toString()))
                                                                                        propsFormik.setFieldValue(`liquidacao[${index}].valor_formatado`, inputValorLiquidacao.current[index] && inputValorLiquidacao.current[index].toString())
                                                                                        propsFormik.setFieldValue(`liquidacao[${index}].valor_original`, inputValorLiquidacao.current[index] && inputValorLiquidacao.current[index].toString())
                                                                                        // propsFormik.values.liquidacao[index].valor_liquidacao = inputValorLiquidacao.current[index] && inputValorLiquidacao.current[index].toString().trim();
                                                                                        // propsFormik.values.liquidacao[index].valor = inputValorLiquidacao.current[index] && inputValorLiquidacao.current[index].toString().trim();
                                                                                        // propsFormik.values.liquidacao[index].valor_liquidacao_formatado = formatarMoeda(inputValorLiquidacao.current && inputValorLiquidacao.current[index].toString());
                                                                                        // propsFormik.values.liquidacao[index].valor_formatado = formatarMoeda(inputValorLiquidacao.current && inputValorLiquidacao.current[index].toString());
                                                                                        calcularValorTotal()
                                                                                    }
                                                                                    inputValorLiquidacao.current[index] = null;
                                                                                }}
                                                                                classDiv='col-8 col-xl-5 col-lg-3 col-md-12 col-sm-12 pr-0' obrigatorio={true} mostrarValidacaoAfter={props.liquidar} />

                                                                        </div>
                                                                        <div className='col-4 col-xl-1 col-lg-1 col-md-12 mb-0 pr-0 pl-1 pb-3 content-bottom-left ml-1 text-right'>
                                                                            <BtnAcao type={'button'} icone="icon-Add" texto="Adicionar" action={() => adicionarLinhaNovo(arrayHelpers, propsFormik, index)} />
                                                                            <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, props.propsFormik, index)} />
                                                                            {propsFormik.values.liquidacao[index].modo_liquidacao ?
                                                                                <BtnAcao type={'button'} icone={propsFormik?.values?.liquidacao[index]?.show_anotacoes ? 'icon-Yes' : "icon-Note"} texto={`${propsFormik?.values?.liquidacao[index]?.anotacoes ? 'Alterar' : 'Adicionar'} Anotações`} action={() => propsFormik.setFieldValue(`liquidacao[${index}].show_anotacoes`, !propsFormik?.values?.liquidacao[index]?.show_anotacoes)} />
                                                                                :
                                                                                <></>}
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        ((propsFormik?.values?.liquidacao[index]?.anotacoes || propsFormik?.values?.liquidacao[index]?.show_anotacoes) && propsFormik.values.liquidacao[index].modo_liquidacao) && <div className='modal-body-cadastro row mr-0 ml-0'>
                                                                            {propsFormik?.values?.liquidacao[index]?.show_anotacoes ?
                                                                                <TextArea value={propsFormik?.values?.liquidacao[index]?.anotacoes} name={`liquidacao[${index}].anotacoes`} type='text' placeholder='Anotações' tamanho={'col-12'} classeTooltip={'tooltip-input-modal'} rows={2} />
                                                                                : (propsFormik.values.liquidacao[index].modo_liquidacao && parseFloat(convertValueToFloat(propsFormik.values.liquidacao[index].valor)) >= 0) ?
                                                                                    <div className='anotacoes'>
                                                                                        <div className='form-group display-flex col-12 anotacoes-liquidacao'>
                                                                                            <span className='icon-Note'></span>
                                                                                            <h6>{propsFormik?.values?.liquidacao[index]?.anotacoes}</h6>
                                                                                        </div>
                                                                                    </div> : <></>}
                                                                        </div>
                                                                    }
                                                                </>)
                                                            :
                                                            <></>}

                                                    </>
                                                ))}
                                            </FieldArray>
                                            {/* {props.propsFormik.touched.liquidacao && props.propsFormik.touched.liquidacao.filter(x => x && x.modo_liquidacao).length > 0 && props.propsFormik.errors.liquidacao && !Array.isArray(props.propsFormik.errors.liquidacao) ?
                                                <Overlay target={refLiquidacao.current} show={true} placement="bottom">
                                                    {props2 => {
                                                        return (
                                                            <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                                                <div className="error">
                                                                    <FiAlertTriangle className="icone" /> <span>{props.propsFormik.errors.liquidacao}</span></div>
                                                            </Tooltip>)
                                                    }}
                                                </Overlay> : <></>} */}
                                        </div>
                                        {
                                            (props.idMovimento &&
                                                (!props.id && !propsFormik.values.alterando_linha_lancamento) || (props.tabLancamento && !propsFormik.values.alterando_linha_lancamento) ?
                                                <div className='text-right container-botoes mb-3 mt-2 col-lg-12'>
                                                    <BotaoPadrao texto='Adicionar' type={'button'} click={_ => handleValidation()} classeTooltip="tooltip-plantao-es" class="btn btn-primary-sm primary botao-cadastro" />
                                                    <BotaoPadrao texto='Limpar' type={'button'} click={_ => { limparLiquidacao() }} classeTooltip="tooltip-plantao-es" class="btn btn-primary-sm primary botao-cadastro" />

                                                </div>
                                                :
                                                (!props.id && propsFormik.values.alterando_linha_lancamento) || (props.tabLancamento && propsFormik.values.alterando_linha_lancamento) ?
                                                    <div className='text-right container-botoes mb-3 mt-2 col-lg-12'>
                                                        <BotaoPadrao texto='Salvar' type={'button'} click={_ => handleValidation()} classeTooltip="tooltip-plantao-es" class="btn btn-primary-sm primary botao-cadastro" />
                                                        <BotaoPadrao texto='Cancelar' type={'button'} click={_ => { limparLiquidacao() }} classeTooltip="tooltip-plantao-es" class="btn btn-primary-sm primary botao-cadastro" />
                                                    </div>
                                                    :
                                                    <></>
                                            )
                                        }
                                        <ModalDuasOpcoes showModal={showModalValidaValor} setShowModal={setShowModalValidaValor}
                                            funcaoOpcao2={() => { setShowModalValidaValor(false); adicionarLiquidacao(props.id && props.id || props.propsFormik.values.id_lancamento_alteracao) }}
                                            funcaoOpcao1={() => { setShowModalValidaValor(false) }}
                                            esconderFechar mensagem={<>{msgValidacao}<br /></>}
                                            textoOpcao1={'Fechar'}
                                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                                            textoOpcao2={'Continuar'} />
                                        <ModalDuasOpcoes showModal={showModalValidaGuiaRepetida} setShowModal={setShowModalValidaGuiaRepetida}
                                            funcaoOpcao2={() => { setShowModalValidaGuiaRepetida(false); verificaValor() }}
                                            funcaoOpcao1={() => { setShowModalValidaGuiaRepetida(false) }}
                                            esconderFechar mensagem={<>{msgValidacaoGuia}<br /></>}
                                            textoOpcao1={'Fechar'}
                                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                                            textoOpcao2={'Continuar'} />
                                    </div>

                                    <div className='modal-body-cadastro row mr-0 ml-0 mt-2'>
                                        <div className={"col-11 text-right vertical-center"}>
                                            <span>Total: <span className={`ml-1 `}><b>{valorTotalLiquidacao}</b></span></span>
                                        </div>

                                    </div>
                                </>

                                : <></>}

                            <></>
                        </>
                    </>
                    :
                    <></>
            }
        </>


    )
})

const CadastroLiquidacaoParcelado = React.forwardRef(({ dadosMovimentoLancamento, ...props }, ref) => {


    const refLiquidacaoParcelado = useRef(null)
    let { propsFormik } = props
    const inputValorLiquidacaoParcelado = useRef([]);
    const inputValorLiquidaParcela = useRef();
    const [arrayRef, setArrayRef] = useState(Array(propsFormik.values.liquidacao ? props.propsFormik.values.liquidacao.length : 1).fill(React.createRef()))
    const [minimizar, setMinimizar] = useState([true])
    const [disabled, setDisabled] = useState(false)
    const [valorTotalLiquidacaoParcelado, setValorTotalLiquidacaoParcelado] = useState(formatarMoeda(0.00))
    const [valorTotalLiquidadoParcelado, setValorTotalLiquidadoParcelado] = useState(formatarMoeda(0.00))
    const [msgValidacao, setMsgValidacao] = useState([])
    const [msgValidacaoGuiaRepetida, setMsgValidacaoGuiaRepetida] = useState([])
    const [msgValidacaoGuia, setMsgValidacaoGuia] = useState()

    const [showModalValidaValor, setShowModalValidaValor] = useState(false)
    const [showModalValidaGuiaRepetida, setShowModalValidaGuiaRepetida] = useState(false)
    const inputValorAcrescimo = useRef([]);
    const inputValorDesconto = useRef([]);
    const inputValorJurosMulta = useRef([]);
    const inputValorImpostoNFE = useRef([]);
    const inputValorGlosa = useRef([]);
    const inputValorOutrosImpostos = useRef([]);

    const removerLinha = (arrayHelpers, propsFormik, index) => {
        propsFormik.setFieldValue(`liquidacao_parcelado`, [])
        inputValorAcrescimo.current.splice(index, 1)
        inputValorDesconto.current.splice(index, 1)
        inputValorJurosMulta.current.splice(index, 1)
        inputValorImpostoNFE.current.splice(index, 1)
        inputValorGlosa.current.splice(index, 1)
        inputValorOutrosImpostos.current.splice(index, 1)

        if (arrayHelpers) {//Removendo por linha parcelado
            arrayHelpers.remove(index)
            propsFormik.setFieldValue(`liquidacao[0].parcela`, propsFormik.values.liquidacao_parcelado.length - 1)
        }
        else {
            propsFormik.setFieldValue(`liquidacao[0]`, [{ data: '05/01/2023', valor: '', periodicidade: null, parcela: null }])
        }
    }

    const removerParcela = (propsFormik, index) => {
        let parcelas = propsFormik.values.liquidacao_parcelado
        parcelas.splice(index, 1)
        propsFormik.setFieldValue('liquidacao_parcelado', parcelas)
        propsFormik.setFieldValue(`liquidacao[0].parcela`, parcelas.length)
    }

    const adicionarLinhaParcelado = (option, index, arrayHelpers) => {

        propsFormik.values.liquida_parcela.parcela = option.target.value
        let linhas = []
        let arrayResult = propsFormik?.values?.liquidacao_parcelado && propsFormik?.values?.liquidacao_parcelado.length ? [...propsFormik?.values?.liquidacao_parcelado] : []
        propsFormik.values.liquidacao_parcelado = propsFormik.values.liquidacao_parcelado ? propsFormik.values.liquidacao_parcelado : [];

        if (option.target.value < arrayResult.length) {
            arrayResult = arrayResult.slice(0, parseInt(option.target.value) - arrayResult.length)
        }
        else if (parseInt(option.target.value) === arrayResult.length)
            return;


        for (let i = 0; i < option.target.value; i++) {
            setArrayRef([...arrayRef, React.createRef()])
            linhas[i] = arrayResult[i] ? arrayResult[i] : { modo_liquidacao: undefined, cliente: undefined, data_liquidacao: undefined, liquidado: false, data_liquidado: undefined, valor: undefined }
            // propsFormik.values.liquidacao_parcelado[i] = {modo_liquidacao: undefined, cliente: undefined, data_liquidacao: undefined, liquidado: false, data_liquidado: undefined, valor: undefined }
            // (null, {modo_liquidacao: undefined, cliente: undefined, data_liquidacao: undefined, liquidado: false, data_liquidado: undefined, valor: undefined })
        }

        if (!option.target.value) //Para caso o valor no campo parcela seja removido
            propsFormik.setFieldValue('liquidacao_parcelado', {})

        propsFormik.values.liquidacao_parcelado = linhas
        // propsFormik.values.liquidacao = [{}]adicionarLiquidacao
        calculaDataLiquidacao()

    }

    const verificaValor = _ => {

        let valorLiquidado = propsFormik?.values?.liquidacao_parcelado?.filter(x => x?.valor_liquidacao).reduce((partialSum, a) => partialSum + convertValueToFloat(a?.valor_liquidacao), 0)
        let valorLancamento = parseFloat(propsFormik?.values?.valor_geral_lancamento)
        if (valorLiquidado !== valorLancamento) {
            setMsgValidacao(<>Valor Liquidado de <b>{formatarMoeda(valorLiquidado)}</b> não é igual ao Valor Lançado de <b>{formatarMoeda(valorLancamento)}</b> deseja continuar ? </>)
            setShowModalValidaValor(true)
        }
        else
            adicionarLiquidacaoParcelado(props.id && props.id || props.propsFormik.values.id_lancamento_alteracao)
    }

    const verificaGuiaAdicionada = _ => {

        let atendimento = propsFormik?.values?.atendimento_lancamento

        let validacao = []

        if (atendimento) {

            if (atendimento.guia_status_id !== 4) {
                validacao.push('Atendimento(Guia) selecionada encontra-se com status diferente de (<b>CONFERIDA!</>) ')
            }


            if (props.tabLancamento && props.id && dadosMovimentoLancamento.length > 0) {
                atendimento = dadosMovimentoLancamento?.filter(x => x?.atendimento?.value === atendimento?.value)
            }
            else
                atendimento = propsFormik.values.arrayLancamentos?.filter(x => x?.atendimento?.value === atendimento?.value)

            if (atendimento?.length > 0) {
                // setShowModalValidaGuiaRepetida(true)
                // setMsgValidacaoGuiaRepetida(<>Guia selecionada encontra-se adicionada! Deseja continuar ?</>)
                validacao.push('Guia selecionada encontra-se adicionada!')

            }




            if (validacao.length > 0) {

                setShowModalValidaGuiaRepetida(true)
                setMsgValidacaoGuia(
                    <>
                        <div dangerouslySetInnerHTML={{ __html: validacao && validacao.map((x, idx) => idx + 1 + ' - ' + x.replace(/"/g, '') + '<br/><br/>').join('') }} />
                    </>)
            } else {
                verificaValor()
            }


        } else
            verificaValor()

    }

    const handleValidation = async () => {

        await props.setValidarLancamento(true)

        await propsFormik.validateForm().then((errors) => {
            if (Object.keys(errors).length > 0) {
                propsFormik.setErrors(errors);

                let liquidacoes_parcelados = Array(errors.liquidacao_parcelado && errors.liquidacao_parcelado.length)
                    .fill({ modo_liquidacao: true, valor_liquidacao: true, data: true, data_liquidacao: true })
                let liquida_parcela = { data: true, periodicidade: true, parcela: true, valor_liquidacao: true }

                propsFormik.setTouched({
                    categoria_lancamento: true, cliente_lancamento: true, previsao_vencimento_lancamento: true,
                    tipo_lancamento: true, valor_geral_lancamento: true, liquidacao_parcelado: liquidacoes_parcelados, liquida_parcela
                })

            } else {
                verificaGuiaAdicionada()
                propsFormik.setTouched({
                    categoria_lancamento: false, cliente_lancamento: false, previsao_vencimento_lancamento: false,
                    tipo_lancamento: false, valor_geral_lancamento: false
                })
                props.setValidarLancamento(false)

            }

        });
    }

    const adicionarLiquidacaoParcelado = (alteracao) => {
        let idxAlteracao = parseInt(propsFormik.values.id_lancamento_alteracao)
        if (idxAlteracao >= 0 && props.tabLancamento) {
            let idLancamento = parseInt(propsFormik.values.id_lancamento)

            if (props.tabLancamento && (idxAlteracao >= 0 && props.tabLancamento)) {
                props.setAdicionando(true)

                let arrayLancamento = [...dadosMovimentoLancamento]
                let arrayRetorno = propsFormik?.values?.liquidacao_parcelado?.map(itemLiquidado => ({
                    id: idLancamento, data: itemLiquidado.data || null, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: propsFormik.values.valor_geral_lancamento,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, tipo_lancamento_descricao: propsFormik?.values?.tipo_lancamento?.label, descricao: propsFormik?.values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: propsFormik?.values?.previsao_vencimento_lancamento, tipo_lancamento: propsFormik?.values?.tipo_lancamento, categoria: propsFormik?.values?.categoria_lancamento, atendimento: propsFormik?.values?.atendimento_lancamento,
                    parcela: propsFormik.values.liquida_parcela.parcela, conta_bancaria: propsFormik?.values?.conta_bancaria_lancamento, tipo_documento: propsFormik?.values?.tipo_documento_lancamento, periodicidade: propsFormik.values.liquida_parcela.periodicidade, valor_total_liquidacao: valorTotalLiquidadoParcelado, anotacao: itemLiquidado?.anotacoes, arquivos: props.dataArquivos
                }))[0]

                arrayLancamento[idxAlteracao] = arrayRetorno
                arrayLancamento = arrayLancamento.flat(2)

                let arrayLiquidacao = propsFormik?.values?.liquidacao_parcelado.map(itemLiquidado => ({
                    id_liquidacao: itemLiquidado.id_liquidacao, data: itemLiquidado.data, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: parseFloat(itemLiquidado?.valor_liquidacao),
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, descricao: propsFormik?.values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: itemLiquidado?.data, liquidado: itemLiquidado?.liquidado, modo_liquidacao: itemLiquidado?.modo_liquidacao, anotacao: itemLiquidado?.anotacoes,
                    acrescimo: itemLiquidado?.acrescimo, desconto: itemLiquidado?.desconto, juros_multa: itemLiquidado?.juros_multa, imposto_nfse: itemLiquidado?.imposto_nfse, glosas: itemLiquidado?.glosas, outros_impostos: itemLiquidado?.outros_impostos, arquivos: props.dataArquivos

                }))
                let copiaDados = [...arrayLancamento]
                copiaDados[idxAlteracao].lancamento_movimento_liquidacao_parcelado = [...arrayLiquidacao]
                props.setDadosMovimentoLancamento(copiaDados)
                mostrarToast('sucesso', 'Operação efetuada com sucesso')
                setValorTotalLiquidacaoParcelado(0.00)
                propsFormik.values.liquidacao_parcelado = [{ modo_liquidacao: null, data: null, liquidado: null, data_liquidacao: null, valor: null }]
                limparLiquidacaoParcelado()

            }
            else if (!props.tabLancamento) {
                props.setAdicionando(true)

                let arrayLancamento = cloneDeep(propsFormik?.values?.arrayLancamentos)
                let arrayRetorno = propsFormik?.values?.liquidacao_parcelado?.filter(item => item).map(itemLiquidado => ({
                    data: itemLiquidado.data || null, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: propsFormik.values.valor_geral_lancamento,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento_lancamento?.value, tipo_lancamento_descricao: propsFormik?.values?.tipo_lancamento_lancamento?.label,
                    descricao: propsFormik?.values?.descricao_lancamento, tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: propsFormik?.values?.previsao_vencimento_lancamento, tipo_lancamento: propsFormik?.values?.tipo_lancamento, categoria: propsFormik?.values?.categoria_lancamento, atendimento: propsFormik?.values?.atendimento_lancamento,
                    parcela: propsFormik.values.liquida_parcela.parcela, conta_bancaria: propsFormik?.values?.conta_bancaria_lancamento, tipo_documento: propsFormik?.values?.tipo_documento_lancamento, periodicidade: propsFormik.values.liquida_parcela.periodicidade, valor_total_liquidacao: valorTotalLiquidadoParcelado, anotacao: itemLiquidado?.anotacoes, arquivos: props.dataArquivos
                }))[0]
                // let arrayRetorno = propsFormik?.values?.liquidacao_parcelado?.filter(item => item.liquidado)
                //     .map(itemLiquidado => ({ data: itemLiquidado.data, cliente: propsFormik?.values?.cliente, socio: 'Teste',
                //      estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: itemLiquidado?.valor_liquidacao, data_liquidacao: itemLiquidado?.data_liquidacao, 
                //      nfse: propsFormik?.values?.nota_fiscal, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value }))

                arrayLancamento[idxAlteracao] = arrayRetorno
                propsFormik.values.arrayLancamentos = arrayLancamento.flat(2);

                let arrayLiquidacao = propsFormik?.values?.liquidacao_parcelado.map(itemLiquidado => ({
                    data: itemLiquidado.data, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: itemLiquidado?.valor_liquidacao,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao_lancamento?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, descricao: propsFormik?.values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: itemLiquidado?.data, liquidado: itemLiquidado?.liquidado, modo_liquidacao: itemLiquidado?.modo_liquidacao, anotacao: itemLiquidado?.anotacoes,
                    acrescimo: itemLiquidado?.acrescimo, desconto: itemLiquidado?.desconto, juros_multa: itemLiquidado?.juros_multa, imposto_nfse: itemLiquidado?.imposto_nfse, glosas: itemLiquidado?.glosas, outros_impostos: itemLiquidado?.outros_impostos, arquivos: props.dataArquivos

                }))
                propsFormik.values.liquidacoes[idxAlteracao] = []
                propsFormik.values.liquidacoes[idxAlteracao] = arrayLiquidacao
                // propsFormik.values.liquidacoes = propsFormik.values.liquidacoes.flat(2)
                mostrarToast('sucesso', 'Operação efetuada com sucesso')

                setValorTotalLiquidacaoParcelado(0.00)

                // propsFormik.values.liquidacao_parcelado = [{modo_liquidacao: null, data: null, liquidado: null, data_liquidacao: null, valor: null}]
                // propsFormik.values.liquidacao_parcelado = []
                // propsFormik.values.liquida_parcela = {}
                limparLiquidacaoParcelado()

            }
        } else {

            if (props.tabLancamento) {

                props.setAdicionando(true)

                let arrayLancamento = [...dadosMovimentoLancamento]
                let arrayRetorno = propsFormik?.values?.liquidacao_parcelado.map(itemLiquidado => ({
                    cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: propsFormik.values.valor_geral_lancamento,
                    nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: true, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, tipo_lancamento_descricao: propsFormik?.values?.tipo_lancamento?.label, descricao: propsFormik?.values?.descricao,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: propsFormik?.values?.previsao_vencimento_lancamento, tipo_lancamento: propsFormik?.values?.tipo_lancamento, categoria: propsFormik?.values?.categoria_lancamento, atendimento: propsFormik?.values?.atendimento_lancamento,
                    parcela: propsFormik.values.liquida_parcela.parcela, conta_bancaria: propsFormik?.values?.conta_bancaria_lancamento, tipo_documento: propsFormik?.values?.tipo_documento_lancamento, periodicidade: propsFormik.values.liquida_parcela.periodicidade, valor_total_liquidacao: valorTotalLiquidadoParcelado, anotacao: itemLiquidado?.anotacoes, arquivos: props.dataArquivos,
                    lancamento_movimento_liquidacao_parcelado: propsFormik.values.liquidacao_parcelado, data_liquidacao: itemLiquidado?.data_liquidacao
                }))[0]
                // let arrayRetorno = propsFormik?.values?.liquidacao_parcelado?.filter(item => item.liquidado)
                //     .map(itemLiquidado => ({ data: itemLiquidado.data, cliente: propsFormik?.values?.cliente, socio: 'Teste',
                //      estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: itemLiquidado?.valor_liquidacao, data_liquidacao: itemLiquidado?.data_liquidacao, 
                //      nfse: propsFormik?.values?.nota_fiscal, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value }))

                arrayLancamento = arrayLancamento && arrayLancamento.length > 0 ? [...arrayLancamento, arrayRetorno] : [arrayRetorno]
                propsFormik.values.arrayLancamentos = arrayLancamento.flat(2);
                let arrayLiquidacao = propsFormik?.values?.liquidacao_parcelado.map(itemLiquidado => ({
                    data: itemLiquidado.data, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: itemLiquidado?.valor_liquidacao,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao_lancamento?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento_lancamento?.value, descricao: propsFormik?.values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: itemLiquidado?.data, liquidado: itemLiquidado?.liquidado, modo_liquidacao: itemLiquidado?.modo_liquidacao, anotacao: itemLiquidado?.anotacoes,
                    acrescimo: itemLiquidado?.acrescimo, desconto: itemLiquidado?.desconto, juros_multa: itemLiquidado?.juros_multa, imposto_nfse: itemLiquidado?.imposto_nfse, glosas: itemLiquidado?.glosas, outros_impostos: itemLiquidado?.outros_impostos, arquivos: props.dataArquivos,
                    lancamento_movimento_liquidacao_parcelado: propsFormik.values.liquidacao_parcelado


                }))


                let copiaDados = [...arrayLancamento]
                copiaDados[arrayLancamento.length - 1].lancamento_movimento_liquidacao_parcelado = [...arrayLiquidacao]
                props.setDadosMovimentoLancamento(copiaDados)
                mostrarToast('sucesso', 'Operação efetuada com sucesso')

                setValorTotalLiquidacaoParcelado(0.00)

                // propsFormik.values.liquidacao_parcelado = [{modo_liquidacao: null, data: null, liquidado: null, data_liquidacao: null, valor: null}]
                // propsFormik.values.liquidacao_parcelado = []
                // propsFormik.values.liquida_parcela = {}
                limparLiquidacaoParcelado()



            } else {
                props.setAdicionando(true)

                let arrayLancamento = propsFormik?.values?.arrayLancamentos ? [...propsFormik?.values?.arrayLancamentos] : []
                let arrayRetorno = propsFormik?.values?.liquidacao_parcelado?.filter(item => item).map(itemLiquidado => ({
                    data: itemLiquidado.data || null, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: propsFormik.values.valor_geral_lancamento,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento_lancamento?.value, tipo_lancamento_descricao: propsFormik?.values?.tipo_lancamento_lancamento?.label,
                    descricao: propsFormik?.values?.descricao_lancamento, tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: propsFormik?.values?.previsao_vencimento_lancamento, tipo_lancamento: propsFormik?.values?.tipo_lancamento, categoria: propsFormik?.values?.categoria_lancamento, atendimento: propsFormik?.values?.atendimento_lancamento,
                    parcela: propsFormik.values.liquida_parcela.parcela, conta_bancaria: propsFormik?.values?.conta_bancaria_lancamento, tipo_documento: propsFormik?.values?.tipo_documento_lancamento, periodicidade: propsFormik.values.liquida_parcela.periodicidade, valor_total_liquidacao: valorTotalLiquidadoParcelado, anotacao: itemLiquidado?.anotacoes, arquivos: props.dataArquivos
                }))[0]
                // let arrayRetorno = propsFormik?.values?.liquidacao_parcelado?.filter(item => item.liquidado)
                //     .map(itemLiquidado => ({ data: itemLiquidado.data, cliente: propsFormik?.values?.cliente, socio: 'Teste',
                //      estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: itemLiquidado?.valor_liquidacao, data_liquidacao: itemLiquidado?.data_liquidacao, 
                //      nfse: propsFormik?.values?.nota_fiscal, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value }))

                arrayLancamento = arrayLancamento && arrayLancamento.length > 0 ? [...arrayLancamento, arrayRetorno] : [arrayRetorno]
                propsFormik.setFieldValue('arrayLancamentos', arrayLancamento.flat(2))
                let arrayLiquidacao = propsFormik?.values?.liquidacao_parcelado.map(itemLiquidado => ({
                    data: itemLiquidado.data, cliente: propsFormik?.values?.cliente_lancamento, socio: propsFormik.values.socio, estabelecimento_saude: propsFormik?.values?.estabelecimento_saude || null, valor: itemLiquidado?.valor_liquidacao,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: propsFormik?.values?.nota_fiscal_lancamento, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao_lancamento?.value, periodicidade_id: propsFormik?.values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: propsFormik?.values?.conta_bancaria_lancamento?.value, categoria_id: propsFormik?.values?.categoria_lancamento?.value, tipo_lancamento_id: propsFormik?.values?.tipo_lancamento?.value, descricao: propsFormik?.values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: propsFormik?.values?.tipo_documento_lancamento?.value, data_prevista: itemLiquidado?.data, liquidado: itemLiquidado?.liquidado, modo_liquidacao: itemLiquidado?.modo_liquidacao, anotacao: itemLiquidado?.anotacoes,
                    acrescimo: itemLiquidado?.acrescimo, desconto: itemLiquidado?.desconto, juros_multa: itemLiquidado?.juros_multa, imposto_nfse: itemLiquidado?.imposto_nfse, glosas: itemLiquidado?.glosas, outros_impostos: itemLiquidado?.outros_impostos, arquivos: props.dataArquivos

                }))


                let arrayLiquidacoes = propsFormik.values.liquidacoes
                arrayLiquidacoes.push(arrayLiquidacao)
                mostrarToast('sucesso', 'Operação efetuada com sucesso')

                propsFormik.setFieldValue('liquidacoes', arrayLiquidacoes)
                // propsFormik.values.liquidacoes = propsFormik.values.liquidacoes.flat(2)

                setValorTotalLiquidacaoParcelado(0.00)

                // propsFormik.values.liquidacao_parcelado = [{modo_liquidacao: null, data: null, liquidado: null, data_liquidacao: null, valor: null}]
                // propsFormik.values.liquidacao_parcelado = []
                // propsFormik.values.liquida_parcela = {}
                limparLiquidacaoParcelado()

            }
        }


    }

    const calcularValorTotalParcelado = _ => {
        const liquidacaoParcelado = propsFormik.values.liquidacao_parcelado
        let valorTotal = liquidacaoParcelado?.map(x => parseFloat(x?.valor_liquidacao || 0)).reduce((ant, atual) => ant + atual, 0);
        setValorTotalLiquidacaoParcelado(formatarMoeda(valorTotal))

    }

    const verificaCampos = _ => {
        if (!propsFormik.initialValues.parcelado_lancamento && propsFormik.values.parcelado_lancamento) {
            propsFormik.setFieldValue('liquida_parcela', { valor: props.id ? propsFormik?.values?.valor_total_formatado : propsFormik?.values?.valor_geral, data: propsFormik?.initialValues?.liquidacao[0]?.data_liquidacao })
        }
    }
    useEffect(_ => {
        calcularValorTotalParcelado()
        if (propsFormik.values.parcelado_lancamento) {
            propsFormik.setFieldValue('liquidacao', [])

        }
        else
            propsFormik.setFieldValue('liquidacao_parcelado', [])

        // verificaCampos()
        // limparLiquidacaoParcelado()
        // propsFormik.setFieldValue('arrayLancamentos', [])
        // propsFormik.setFieldValue('liquidacoes', [])

    }, [propsFormik.values.parcelado_lancamento])

    useEffect(_ => {

        const liquidacaoParcelado = propsFormik.values.liquidacao_parcelado
        const valor = liquidacaoParcelado && Object.keys(liquidacaoParcelado).length > 0 && liquidacaoParcelado?.filter(x => x.liquidado).map(x => parseFloat(x?.valor_liquidacao || 0)).reduce((ant, atual) => ant + atual, 0)
        setValorTotalLiquidadoParcelado(formatarMoeda(valor))

    }, [propsFormik.values.liquidacao_parcelado])

    const calculaDataLiquidacao = (option, p, valor) => {


        if (p)
            if (propsFormik.values && propsFormik.values.liquida_parcela)
                propsFormik.values.liquida_parcela.periodicidade = option
        let periodicidade = propsFormik?.values?.liquida_parcela?.periodicidade || null
        let data
        if (propsFormik?.values?.liquida_parcela?.data && propsFormik?.values?.liquidacao_parcelado && propsFormik?.values?.liquida_parcela?.periodicidade) {
            data = propsFormik?.values?.liquida_parcela?.data
            for (let idx in propsFormik.values.liquidacao_parcelado) {
                if (idx > 0) {
                    data = moment(data, 'DD/MM/YYYY')
                    data = data.add(parseInt(periodicidade?.dias), 'days').format('DD/MM/YYYY')

                }
                propsFormik.values.liquidacao_parcelado[idx].data = data
            }
        }
        if ((propsFormik?.values?.liquida_parcela?.valor || valor) && parseInt(propsFormik.values.liquida_parcela?.parcela) !== 0) {
            let valorLancamento = parseFloat(propsFormik?.values?.liquida_parcela?.valor && propsFormik?.values?.liquida_parcela?.valor?.toString()?.includes('R$') ? propsFormik?.values?.liquida_parcela?.valor?.replace('R$', '').replace(/\./g, '').replace(',', '.')
                : propsFormik?.values?.liquida_parcela?.valor)
            let qtdParcela = parseInt(propsFormik?.values?.liquida_parcela?.parcela)
            let valorPorParcela = valorLancamento / qtdParcela
            // valorPorParcela = parseFloat(valorPorParcela.toString().replace('.', ',')).toFixed(2)
            let valores = Array.isArray(propsFormik?.values?.liquidacao_parcelado) ? propsFormik?.values?.liquidacao_parcelado?.map((x) => ({
                ...x, valor_liquidacao: valorPorParcela,
                valor_liquidacao_formatado: formatarMoeda(valorPorParcela.toFixed(2)),
                valor: valorPorParcela,
                valor_original: valorPorParcela
            })) : [];

            propsFormik.values.liquidacao_parcelado = valores
            // propsFormik.setFieldValue(`liquidacao_parcelado`, valores)

            calcularValorTotalParcelado()

        }

    }

    const operacoes = [
        { propriedade: 'acrescimo', operacao: 'soma' },
        { propriedade: 'desconto', operacao: 'subtrai' },
        { propriedade: 'juros_multa', operacao: 'soma' },
        { propriedade: 'imposto_nfse', operacao: 'subtrai' },
        { propriedade: 'glosas', operacao: 'subtrai' },
        { propriedade: 'outros_impostos', operacao: 'subtrai' }
    ];

    useEffect(_ => {
        if (propsFormik.values.parcelado_lancamento) {
            propsFormik.setFieldValue('liquida_parcela.valor', convertValueToFloat(propsFormik.values.valor_geral_lancamento))
            inputValorLiquidaParcela.current = convertValueToFloat(propsFormik.values.valor_geral_lancamento)
            calculaDataLiquidacao(null, null, convertValueToFloat(propsFormik.values.valor_geral_lancamento))
            let teste = propsFormik.values.liquidacao_parcelado?.filter(x => x.acrescimo || x.desconto || x.juros_multa
                || x.imposto_nfse || x.glosas || x.outros_impostos)?.map((_, i) => atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, i, propsFormik))
        }

    }, [propsFormik.values.valor_geral_lancamento])

    const atualizarValoresLiquidacao = React.useMemo(() => {
        return (arrayLiquidacao, index, propsFormik) => {

            if (arrayLiquidacao) {


                let valor_liquidacao = parseFloat(arrayLiquidacao[index]?.valor_original || propsFormik?.values?.valor_geral_lancamento || 0)

                for (let i = 0; i < operacoes.length; i++) {
                    const { propriedade, operacao } = operacoes[i];

                    if (arrayLiquidacao[index][propriedade] !== undefined) {
                        if (operacao === 'soma') {
                            valor_liquidacao += parseFloat(arrayLiquidacao[index][propriedade]);
                        } else if (operacao === 'subtrai') {
                            valor_liquidacao -= parseFloat(arrayLiquidacao[index][propriedade]);
                        }
                    }
                }
                arrayLiquidacao[index].valor = valor_liquidacao

                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor_liquidacao`, valor_liquidacao.toString().trim())
                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor`, valor_liquidacao.toString().trim())
                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor_liquidacao_formatado`, formatarMoeda(valor_liquidacao.toString()))
                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor_formatado`, valor_liquidacao.toString())
            }
        }
    }, [])

    const limparLiquidacaoParcelado = _ => {
        propsFormik.setFieldValue('liquidacao_parcelado', [])
        propsFormik.setFieldValue('liquidacao', [{ modo_liquidacao: undefined, data_liquidacao: propsFormik.values.previsao_vencimento_lancamento, valor_liquidacao: undefined }])
        propsFormik.setFieldValue('liquida_parcela', { data: propsFormik.values.previsao_vencimento_lancamento })
        propsFormik.setFieldValue('alterando_linha_lancamento', false)
        propsFormik.setFieldValue(`cliente_lancamento`, null)
        propsFormik.setFieldValue(`estabelecimento_saude`, null)
        propsFormik.setFieldValue(`parcelado_lancamento`, false)
        // propsFormik.setFieldValue(`tipo_lancamento`, null)
        propsFormik.setFieldValue(`valor_geral_lancamento`, null)
        propsFormik.setFieldValue(`valor_geral_lancamento_formatado`, null)
        // propsFormik.setFieldValue(`categoria`, null)
        // propsFormik.setFieldValue(`conta_bancaria`, null)
        propsFormik.setFieldValue(`tipo_documento_lancamento`, null)
        propsFormik.setFieldValue(`atendimento_lancamento`, null)
        propsFormik.setFieldValue(`id_lancamento_alteracao`, null)

        props.setIdAtendimento(null)
        props.limparArquivos(true)
    }

    return (
        <>
            {propsFormik.values.parcelado_lancamento &&
                <>
                    <div className={props.tabLancamento === true ? `mt-40 tab-tittle` : 'mt-40'}>
                        <HeaderCadastro
                            titulo={`LIQUIDAÇÃO PARCELADO`}
                            classeChildren='space-between-100'
                            minimizar
                            idxMinimizar={0}
                            arrayMinimizar={minimizar}
                            setArrayMinimizar={setMinimizar}
                        >
                        </HeaderCadastro>
                    </div>

                    {/* {!minimizar[0] ? */}
                    <>

                        <div className="card mb-20 scroll-x">
                            <div className="card-body  executantes-internos-float" ref={refLiquidacaoParcelado}>
                                <div className='modal-body-cadastro row mr-0 ml-0'>
                                    <div className='row col-sm-12 col-xl-11 col-lg-12 col-md-12'>
                                        <InputDatePicker tamanho={'col-xl-3'} label={"Data"} name={`liquida_parcela.data`} type="text" obrigatorio="true"
                                            setarValor={value => {
                                                propsFormik.setFieldValue(`liquida_parcela.data`, value)
                                            }} classeTooltip={props.modal && 'tooltip-input-modal'} mostrarFeriado={true} placeholder={'Data'}
                                            disabled={disabled}
                                            onChange={value => {
                                                // calculaDataLiquidacao();
                                                propsFormik.setFieldValue(`liquida_parcela.data`, value)
                                            }}
                                            onBlurData={_ => {
                                                calculaDataLiquidacao();
                                            }}
                                        />
                                        <InputCurrency
                                            label={'Valor R$'} name={`liquida_parcela.valor`}
                                            value={inputValorLiquidaParcela?.current || (propsFormik?.values?.liquidacao && propsFormik?.values?.liquidacao[0]?.valor) || propsFormik?.values?.liquida_parcela?.valor || propsFormik?.values?.liquida_parcela?.valor_formatado}
                                            classeTooltip={'tooltip-input-modal'}
                                            propsFormik={props.propsFormik}
                                            disabled={disabled}
                                            onUpdate={val => {
                                                let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                inputValorLiquidaParcela.current = valor
                                                // calculaDataLiquidacao(null, false)
                                            }}
                                            onBlur={(e) => {
                                                if (inputValorLiquidaParcela.current || inputValorLiquidaParcela.current === 0) {
                                                    propsFormik.values.liquida_parcela.valor = inputValorLiquidaParcela.current[0] && inputValorLiquidaParcela.current.toString().trim();
                                                    propsFormik.values.liquida_parcela.valor_formatado = formatarMoeda(inputValorLiquidaParcela.current && inputValorLiquidaParcela.current.toString());
                                                    calculaDataLiquidacao(null, false, e.currentTarget.value)
                                                }
                                                inputValorLiquidaParcela.current = null;
                                            }}
                                            classDiv='col-8 col-xl-3 col-lg-2 col-md-2 pr-0' obrigatorio={true} />
                                        <InputPeriodicidade label={'Periodicidade'} obrigatorio={true} placeholder='Periodicidade' classeTooltip={'tooltip-input-modal'}
                                            name={`liquida_parcela.periodicidade`} propsFormik={propsFormik} tamanho="col-sm-3 8 col-xl-3 col-lg-2 col-md-2 pr-0"
                                            onChange={(option) => {
                                                propsFormik.setFieldValue(`liquida_parcela.periodicidade`, option); calculaDataLiquidacao(option, true);
                                            }}
                                            disabled={disabled}
                                        />

                                        <InputPadrao label="Parcela(s)" name={`liquida_parcela.parcela`} placeholder="Parcela(s)" obrigatorio={true} tamanho="col-sm-3 8 col-xl-3 col-lg-2 col-md-2 pr-0"
                                            onBlur={(option) => { adicionarLinhaParcelado(option, null, null); }} disabled={disabled} type='text' maxLength={2}
                                        />


                                    </div>
                                    <div className='col-4 col-xl-1 col-lg-1 col-md-1 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right'>
                                        <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(null, propsFormik, 0)} />
                                    </div>

                                </div>
                                <FieldArray name="liquidacao_parcelado">
                                    {(arrayHelpers => (
                                        <>
                                            {propsFormik.values.liquidacao_parcelado && propsFormik.values.liquidacao_parcelado.length > 0 ?

                                                <div className='box-liquidacao-parcelado'>
                                                    {propsFormik.values.liquidacao_parcelado.map((item, index) =>
                                                        <>
                                                            <>
                                                                {propsFormik.values.liquidacao_parcelado[index].liquidado ?
                                                                    <div className={`modal-header pr-0 pl-0 ${index > 0 ? 'bt-1' : ''}`}>
                                                                        <div className='row col-sm-12 col-xl-11 col-lg-11 col-md-12'>
                                                                            <InputCurrency
                                                                                className='valor-indicador-azul'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorAcrescimo.current[index] = valor
                                                                                }}
                                                                                label='Acrescimo R$'
                                                                                name={`liquidacao_parcelado[${index}].acrescimo`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorAcrescimo.current[index] || propsFormik.values.liquidacao_parcelado[index]?.acrescimo || propsFormik.values.liquidacao_parcelado[index]?.acrescimo_formatado}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorAcrescimo.current[index] || inputValorAcrescimo.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao_parcelado[index].acrescimo = parseFloat(inputValorAcrescimo.current[index] && inputValorAcrescimo.current[index].toString().trim());
                                                                                        propsFormik.values.liquidacao_parcelado[index].acrescimo_formatado = formatarMoeda(inputValorAcrescimo.current && inputValorAcrescimo.current[index].toString());

                                                                                        atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, index, propsFormik)
                                                                                    }
                                                                                    inputValorAcrescimo.current[index] = null;
                                                                                }

                                                                                }
                                                                                classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />


                                                                            <InputCurrency
                                                                                className='valor-indicador-errado'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorDesconto.current[index] = valor
                                                                                }}
                                                                                label='Desconto R$'
                                                                                name={`liquidacao_parcelado[${index}].desconto`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorDesconto.current[index] || propsFormik.values.liquidacao_parcelado[index]?.desconto_formatado || propsFormik.values.liquidacao_parcelado[index]?.desconto}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorDesconto.current[index] || inputValorDesconto.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao_parcelado[index].desconto = inputValorDesconto.current[index] && inputValorDesconto.current[index].toString().trim();
                                                                                        propsFormik.values.liquidacao_parcelado[index].desconto_formatado = formatarMoeda(inputValorDesconto.current && inputValorDesconto.current[index].toString());

                                                                                        atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, index, propsFormik)
                                                                                    }
                                                                                    inputValorDesconto.current[index] = null;
                                                                                }

                                                                                }
                                                                                classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                            <InputCurrency
                                                                                className='valor-indicador-azul'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorJurosMulta.current[index] = valor
                                                                                }}
                                                                                label='Juros/Multa R$'
                                                                                name={`liquidacao_parcelado[${index}].juros_multa`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorJurosMulta.current[index] || propsFormik.values.liquidacao_parcelado[index]?.juros_multa_formatado || propsFormik.values.liquidacao_parcelado[index]?.juros_multa}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorJurosMulta.current[index] || inputValorJurosMulta.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao_parcelado[index].juros_multa = inputValorJurosMulta.current[index] && inputValorJurosMulta.current[index].toString().trim();
                                                                                        propsFormik.values.liquidacao_parcelado[index].juros_multa_formatado = formatarMoeda(inputValorJurosMulta.current && inputValorJurosMulta.current[index].toString());

                                                                                        atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, index, propsFormik)
                                                                                    }
                                                                                    inputValorJurosMulta.current[index] = null;
                                                                                }

                                                                                }
                                                                                classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />


                                                                            <InputCurrency
                                                                                className='valor-indicador-errado'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorImpostoNFE.current[index] = valor
                                                                                }}
                                                                                label='Imposto NFSE R$'
                                                                                name={`liquidacao_parcelado[${index}].imposto_nfse`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorImpostoNFE.current[index] || propsFormik.values.liquidacao_parcelado[index]?.imposto_nfse_formatado || propsFormik.values.liquidacao_parcelado[index]?.imposto_nfse}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorImpostoNFE.current[index] || inputValorImpostoNFE.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao_parcelado[index].imposto_nfse = inputValorImpostoNFE.current[index] && inputValorImpostoNFE.current[index].toString().trim();
                                                                                        propsFormik.values.liquidacao_parcelado[index].imposto_nfse_formatado = formatarMoeda(inputValorImpostoNFE.current && inputValorImpostoNFE.current[index].toString());

                                                                                        atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, index, propsFormik)
                                                                                    }
                                                                                    inputValorImpostoNFE.current[index] = null;
                                                                                }

                                                                                }
                                                                                classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                            <InputCurrency
                                                                                className='valor-indicador-errado'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorGlosa.current[index] = valor
                                                                                }}
                                                                                label='Glosa R$'
                                                                                name={`liquidacao_parcelado[${index}].glosas`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorGlosa.current[index] || propsFormik.values.liquidacao_parcelado[index]?.glosas_formatado || propsFormik.values.liquidacao_parcelado[index]?.glosas}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorGlosa.current[index] || inputValorGlosa.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao_parcelado[index].glosas = inputValorGlosa.current[index] && inputValorGlosa.current[index].toString().trim() || 0;
                                                                                        propsFormik.values.liquidacao_parcelado[index].glosas_formatado = formatarMoeda(inputValorGlosa.current && inputValorGlosa.current[index].toString());

                                                                                        atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, index, propsFormik)
                                                                                    }
                                                                                    inputValorGlosa.current[index] = null;
                                                                                }

                                                                                }
                                                                                classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />


                                                                            <InputCurrency
                                                                                className='valor-indicador-errado'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorOutrosImpostos.current[index] = valor
                                                                                }}
                                                                                label='Outros R$'
                                                                                name={`liquidacao_parcelado[${index}].outros_impostos`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorOutrosImpostos.current[index] || propsFormik.values.liquidacao_parcelado[index]?.outros_impostos_formatado || propsFormik.values.liquidacao_parcelado[index]?.outros_impostos}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorOutrosImpostos.current[index] || inputValorOutrosImpostos.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao_parcelado[index].outros_impostos = inputValorOutrosImpostos.current[index] && inputValorOutrosImpostos.current[index].toString().trim();
                                                                                        propsFormik.values.liquidacao_parcelado[index].outros_impostos_formatado = formatarMoeda(inputValorOutrosImpostos.current && inputValorOutrosImpostos.current[index].toString());

                                                                                        atualizarValoresLiquidacao(propsFormik.values.liquidacao_parcelado, index, propsFormik)
                                                                                    }
                                                                                    inputValorOutrosImpostos.current[index] = null;
                                                                                }


                                                                                }
                                                                                classDiv='col-8 col-xl-2 col-lg-3 col-md-12 col-sm-12 pr-0 ' />

                                                                        </div>
                                                                    </div> : <></>}
                                                            </>
                                                            <div className='modal-body-cadastro row mr-0 ml-0'>
                                                                <div className='row col-sm-12 col-xl-11 col-lg-12 col-md-12'>
                                                                    <div className={"form-group col-xl-1 col-lg-1 col-1 pl-0 text-center"}>
                                                                        <label htmlFor={props.id || props.name}>{index === 0 ? 'Parcela' : undefined}</label>
                                                                        <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{index + 1}</span></div>
                                                                    </div>
                                                                    <InputModoLiquidacao label={index === 0 ? 'Modo Liquidação' : undefined} obrigatorio={true} placeholder='Modo Liquidação' classeTooltip={'tooltip-input-modal'}
                                                                        // disabled={(props.idMovimento || props.tabLancamento) ? false : true} 
                                                                        rray={true} name={`liquidacao_parcelado[${index}].modo_liquidacao`} propsFormik={propsFormik} tamanho="col-sm-3 8 col-xl-4 col-lg-2 col-md-2 pr-0"
                                                                    />
                                                                    {/* <div className={"form-group col-xl-1 col-lg-1 col-1 pl-0 text-center"}>
                                                            <label htmlFor={props.id || props.name}>{index === 0 ? 'Cliente' : undefined}</label>
                                                            <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{'Neto'}</span></div>
                                                        </div> */}
                                                                    <InputDatePicker tamanho={'col-xl-2'} label={index === 0 ? "Data Prevista" : undefined} name={`liquidacao_parcelado[${index}].data`} type="text" obrigatorio="true"
                                                                        setarValor={value => {
                                                                            propsFormik.setFieldValue(`liquidacao_parcelado[${index}].data`, value)
                                                                        }} classeTooltip={props.modal && 'tooltip-input-modal'} mostrarFeriado={true} placeholder='Data'
                                                                    />
                                                                    <InputSwitch label={index === 0 ? 'Liquidado?' : undefined} checked={propsFormik.values.liquidacao_parcelado[index].liquidado && propsFormik.values.liquidacao_parcelado[index].liquidado} quebrarLinha
                                                                        propsFormik={propsFormik} classeLabel={(index === 0 ? '' : 'justify-center-vertical')} classeDiv='form-group ajusta-switch'
                                                                        // disabled={(props.idMovimento || props.tabLancamento) ? false : true}
                                                                        name={`liquidacao_parcelado[${index}].liquidado`} size="md" tamanho={!props.modal && 'col-xl-1 col-lg-2 col-md-2 col-sm-12 col-12 pr-0'} classeTooltip={props.modal && 'tooltip-input-modal'}
                                                                        onChange={(option) => {
                                                                            propsFormik.setFieldValue(`liquidacao_parcelado[${index}].liquidado`, option)
                                                                            if (option === true) {
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].data_liquidacao`, moment().format('DD/MM/YYYY'))
                                                                                // inputValores.current.push({ acrescimo: 0, desconto: 0, juros_multa: 0, imposto_nfse: 0, glosas: 0, outros_impostos: 0 })
                                                                                inputValorAcrescimo.current.push(0)
                                                                                inputValorDesconto.current.push(0)
                                                                                inputValorJurosMulta.current.push(0)
                                                                                inputValorImpostoNFE.current.push(0)
                                                                                inputValorGlosa.current.push(0)
                                                                                inputValorOutrosImpostos.current.push(0)
                                                                            }
                                                                            else {
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].anotacoes`, null)
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].data_liquidacao`, null)
                                                                            }

                                                                            // propsFormik.liquidacao_parcelado[index].data_liquidacao = moment().format('DD/MM/YYYY')
                                                                        }}
                                                                    />
                                                                    <InputDatePicker tamanho={'col-8 col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12'} label={index === 0 ? "Data Liquidação" : undefined} name={`liquidacao_parcelado[${index}].data_liquidacao`} type="text" obrigatorio="true"
                                                                        // disabled={(props.idMovimento || props.tabLancamento) ? false : true}
                                                                        onBlurData={(value) => {
                                                                            if (moment(value, 'DD/MM/YYYYY').isValid())
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].liquidado`, true)
                                                                            else
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].liquidado`, false)
                                                                        }}
                                                                        setarValor={value => {
                                                                            propsFormik.setFieldValue(`liquidacao_parcelado[${index}].data_liquidacao`, value)
                                                                        }} classeTooltip={props.modal && 'tooltip-input-modal'} mostrarFeriado={true} placeholder='Data Liquidação'
                                                                    />
                                                                    <InputCurrency onUpdate={val => {
                                                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                        inputValorLiquidacaoParcelado.current[index] = valor
                                                                    }}
                                                                        label={index === 0 ? 'Valor R$' : undefined} name={`liquidacao_parcelado[${index}].valor_liquidacao`}
                                                                        value={inputValorLiquidacaoParcelado.current[index] || propsFormik.values.liquidacao_parcelado[index].valor_liquidacao_formatado || props.propsFormik.values.liquidacao_parcelado[index].valor_liquidacao}
                                                                        classeTooltip={'tooltip-input-modal'}
                                                                        propsFormik={props.propsFormik}
                                                                        onBlur={(e) => {
                                                                            if (inputValorLiquidacaoParcelado.current[index] || inputValorLiquidacaoParcelado.current[index] === 0) {
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor_liquidacao`, inputValorLiquidacaoParcelado.current[index] && inputValorLiquidacaoParcelado.current[index].toString().trim())
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor_liquidacao_formatado`, formatarMoeda(inputValorLiquidacaoParcelado.current[index] && inputValorLiquidacaoParcelado.current[index].toString()))
                                                                                propsFormik.setFieldValue(`liquidacao_parcelado[${index}].valor`, inputValorLiquidacaoParcelado.current[index] && inputValorLiquidacaoParcelado.current[index].toString())
                                                                                // propsFormik.values.liquidacao_parcelado[index].valor_liquidacao = inputValorLiquidacaoParcelado.current[index] && inputValorLiquidacaoParcelado.current[index].toString().trim();
                                                                                // propsFormik.values.liquidacao_parcelado[index].valor_liquidacao_formatado = formatarMoeda(inputValorLiquidacaoParcelado.current && inputValorLiquidacaoParcelado.current[index].toString());
                                                                                // propsFormik.values.liquidacao_parcelado[index].valor = formatarMoeda(inputValorLiquidacaoParcelado.current && inputValorLiquidacaoParcelado.current[index].toString());

                                                                                calcularValorTotalParcelado()
                                                                            }


                                                                            inputValorLiquidacaoParcelado.current[index] = null;

                                                                        }}
                                                                        classDiv='col-8 col-xl-2 col-lg-2 col-md-2  pr-0' />
                                                                </div>

                                                                <div className='col-4 col-xl-1 col-lg-1 col-md-1 mb-0 pr-0 pl-1 pb-3 content-bottom-left ml-2 text-right'>
                                                                    <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerParcela(props.propsFormik, index)} />
                                                                    {propsFormik.values.liquidacao_parcelado[index].liquidado ?
                                                                        <BtnAcao type={'button'} icone={propsFormik.values.liquidacao_parcelado[index].show_anotacoes ? 'icon-Yes' : "icon-Note"} texto={`${propsFormik.values.liquidacao_parcelado[index].anotacoes ? 'Alterar' : 'Adicionar'} Anotações`} action={() => propsFormik.setFieldValue(`liquidacao_parcelado[${index}].show_anotacoes`, !propsFormik.values.liquidacao_parcelado[index].show_anotacoes)} />
                                                                        : <></>}
                                                                </div>
                                                            </div>
                                                            {
                                                                ((propsFormik.values.liquidacao_parcelado[index].anotacoes || propsFormik.values.liquidacao_parcelado[index].show_anotacoes) && propsFormik.values.liquidacao_parcelado[index].liquidado) && <div className='modal-body-cadastro row mr-0 ml-0'>
                                                                    {propsFormik.values.liquidacao_parcelado[index].show_anotacoes ? <TextArea value={propsFormik.values.liquidacao_parcelado[index].anotacoes} name={`liquidacao_parcelado[${index}].anotacoes`} type='text' placeholder='Anotações' tamanho={'col-12'} classeTooltip={'tooltip-input-modal'} rows={2} />
                                                                        : <div className='anotacoes'>
                                                                            <div className='form-group display-flex col-12 anotacoes-liquidacao'>
                                                                                <span className='icon-Note'></span>
                                                                                <h6>{propsFormik.values.liquidacao_parcelado[index].anotacoes}</h6>
                                                                            </div>
                                                                        </div>}
                                                                </div>
                                                            }
                                                        </>)}
                                                </div>
                                                :
                                                <></>}
                                        </>
                                    ))}
                                </FieldArray>

                            </div>
                            {(props.idMovimento &&
                                (!props.id && !propsFormik.values.alterando_linha_lancamento) || (props.tabLancamento && !propsFormik.values.alterando_linha_lancamento) ?
                                <div className='text-right container-botoes mb-3 mt-2 col-lg-12'>
                                    <BotaoPadrao texto='Adicionar' type={'button'} click={_ => handleValidation()} classeTooltip="tooltip-plantao-es" class="btn btn-primary-sm primary botao-cadastro" />
                                </div>
                                :
                                !props.id && propsFormik.values.alterando_linha_lancamento || (props.tabLancamento && propsFormik.values.alterando_linha_lancamento) ?
                                    <div className='text-right container-botoes mb-3 mt-2 col-lg-12'>
                                        <BotaoPadrao texto='Salvar' type={'button'} click={_ => { handleValidation() }} classeTooltip="tooltip-plantao-es" class="btn btn-primary-sm primary botao-cadastro" />
                                        <BotaoPadrao texto='Cancelar' type={'button'} click={_ => { limparLiquidacaoParcelado() }} classeTooltip="tooltip-plantao-es" class="btn btn-primary-sm primary botao-cadastro" />
                                    </div>
                                    :
                                    <></>

                            )}
                            <ModalDuasOpcoes showModal={showModalValidaValor} setShowModal={setShowModalValidaValor}
                                funcaoOpcao2={() => { setShowModalValidaValor(false); adicionarLiquidacaoParcelado(props.id && props.id || props.propsFormik.values.id_lancamento_alteracao) }}
                                funcaoOpcao1={() => { setShowModalValidaValor(false) }}
                                esconderFechar mensagem={<>{msgValidacao}<br /></>}
                                textoOpcao1={'Fechar'}
                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                textoOpcao2={'Continuar'} />
                            <ModalDuasOpcoes showModal={showModalValidaGuiaRepetida} setShowModal={setShowModalValidaGuiaRepetida}
                                funcaoOpcao2={() => { setShowModalValidaGuiaRepetida(false); verificaValor() }}
                                funcaoOpcao1={() => { setShowModalValidaGuiaRepetida(false) }}
                                esconderFechar mensagem={<>{msgValidacaoGuia}<br /></>}
                                textoOpcao1={'Fechar'}
                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                textoOpcao2={'Continuar'} />
                        </div>
                        {propsFormik?.values?.liquidacao_parcelado?.length > 1 || propsFormik?.values?.liquidacao_parcelado && propsFormik?.values?.liquidacao_parcelado[0]?.parcelado ?
                            <div className='modal-body-cadastro row mr-0 ml-0 mt-2'>
                                <div className={"col-11 text-right vertical-center"}>
                                    <span>Total: <span className={`ml-1`}><b>{valorTotalLiquidacaoParcelado}</b></span></span>
                                </div>
                            </div>
                            :
                            <></>
                        }

                    </>
                </>
            }
        </>
    )
})

const MovimentoLancamentos = React.forwardRef(({ propsFormik, ...props }, ref) => {

    // let { propsFormik } = props
    const [idAlteracaoEmTela, setIdAlteracaoEmTela] = useState(-1)
    const [idxEmTela, setIdxEmTela] = useState(-1)
    const [alterarEmTela, setAlterarEmTela] = useState(false)
    const [showModalVisualizacao, setShowModalVisualizacao] = useState(false)
    const [itemVisualizar, setItemVisualizar] = useState({})
    const [showModalValidaExclusao, setShowModalValidaExclusao] = useState(false)
    const [showModalAdicionarLancamento, setShowModalAdicionarLancamento] = useState(false)
    const [msgValidacao, setMsgValidacao] = useState([])
    const [totalRecebido, setTotalRecebido] = useState(0)
    const [totalLancado, setTotalLancado] = useState(0)
    const [arrayLancamentos, setArrayLancamentos] = useState([])
    const [arrayLiquidacoes, setArrayLiquidacoes] = useState(null)
    const [arrayReceitas, setArrayReceitas] = useState([])
    const [arrayRetencoes, setArrayRetencoes] = useState([])


    useEffect(() => {
        calcularTotalGeral()
    }, [propsFormik.values.receita, propsFormik.values.retencao, arrayReceitas, arrayRetencoes])


    const calcularTotalGeral = (valorLiquidacoes) => {
        let valoresReceita = []
        let valoresRetencao = []
        if (arrayReceitas?.length > 0) {
            valoresReceita = arrayReceitas

        } else {
            valoresReceita = propsFormik?.values?.receita?.filter(x => x.valor_receita != undefined)

        }
        // if (arrayRetencoes && arrayRetencoes[0]?.movimento_retencao?.filter(x => x?.id).length > 0) {
        //     valoresRetencao = arrayRetencoes

        // } else {
        //     valoresRetencao = propsFormik?.values?.retencao?.filter(x => x.valor_retencao != undefined)

        // }
        // console.log('VALOES ', valoresReceita)

        let valorTotalRecebido = valoresReceita?.reduce((result, obj) => {
            return (result + parseFloat(obj.valor_receita || 0))
        }, 0)
        // let valorTotalRetencao = valoresRetencao?.reduce((result, obj) => {
        //     return (result + parseFloat(obj.valor_retencao || 0))
        // }, 0)

        setTotalRecebido(valorTotalRecebido)
    }

    useEffect(() => {

        if (propsFormik.values.liquidacoes) {

            let copiaLiquidacoes = propsFormik.values.liquidacoes ? [...propsFormik.values.liquidacoes] : []
            setArrayLiquidacoes(copiaLiquidacoes)

        }

    }, [propsFormik.values.liquidacoes])


    useEffect(_ => {
        if (propsFormik.values.arrayLancamentos) {
            let copiaLancamentos = propsFormik.values.arrayLancamentos ? [...propsFormik.values.arrayLancamentos] : []

            // copiaLancamentos = copiaLancamentos.filter(x => x?.id_lancamento)
            // let copiaLiquidacoes = arrayLiquidacoes ? [...arrayLiquidacoes] : []
            // copiaLancamentos.push(propsFormik.values.arrayLancamentos ? propsFormik.values.arrayLancamentos[propsFormik.values.arrayLancamentos.length - 1] : {})
            // copiaLiquidacoes.push(propsFormik.values.liquidacoes ? propsFormik.values.liquidacoes[propsFormik.values.liquidacoes.length - 1] : propsFormik.values.liquidacoes)

            setArrayLancamentos(copiaLancamentos)


        }
    }, [propsFormik.values.arrayLancamentos])

    useEffect(() => {

        if (props.tabLancamento && idxEmTela >= 0) {
            if (!props.dadosMovimentoLancamento[idxEmTela]?.parcelado || props.dadosMovimentoLancamento[idxEmTela].id) {
                propsFormik.setFieldValue(`liquidacao`, [{}])
                propsFormik.setFieldValue(`cliente_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.cliente)
                propsFormik.setFieldValue(`tipo_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.tipo_lancamento)
                propsFormik.setFieldValue(`estabelecimento_saude`, props.dadosMovimentoLancamento[idxEmTela]?.estabelecimento_saude)
                propsFormik.setFieldValue(`valor_geral_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.valor)
                propsFormik.setFieldValue(`categoria_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.categoria)
                propsFormik.setFieldValue(`previsao_vencimento_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.data_prevista)
                propsFormik.setFieldValue(`descricao_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.descricao)
                propsFormik.setFieldValue(`nota_fiscal_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.nfse || props.dadosMovimentoLancamento[idxEmTela]?.nota_fiscal)
                propsFormik.setFieldValue(`conta_bancaria_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.conta_bancaria_lancamento || props.dadosMovimentoLancamento[idxEmTela]?.conta_bancaria)
                propsFormik.setFieldValue(`conta_bancaria_lancamento_empresa`, props.dadosMovimentoLancamento[idxEmTela]?.conta_bancaria_lancamento_empresa)
                propsFormik.setFieldValue(`tipo_documento_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.tipo_documento_lancamento)
                propsFormik.setFieldValue(`atendimento_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.atendimento)
                propsFormik.setFieldValue('alterando_linha_lancamento', true)
                propsFormik.setFieldValue(`parcelado_lancamento`, false)
                propsFormik.setFieldValue('id_lancamento_alteracao', idxEmTela)
                propsFormik.setFieldValue(`numero_parcela`, props.dadosMovimentoLancamento[idxEmTela]?.numero_parcela)
                propsFormik.setFieldValue(`id_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.id)
                propsFormik.setFieldValue(`socio`, props.dadosMovimentoLancamento[idxEmTela]?.socio)
                propsFormik.setFieldValue(`empresa`, props.dadosMovimentoLancamento[idxEmTela]?.empresa)

                let lancamentos = []
                for (let i in props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao) {
                    lancamentos.push({
                        id_liquidacao: props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao[i]?.id_liquidacao,
                        data_liquidacao: props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao[i]?.data_liquidacao,
                        valor: convertValueToFloat(props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao[i]?.valor),
                        valor_original: convertValueToFloat(props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao[i]?.valor),
                        modo_liquidacao: props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao[i]?.modo_liquidacao,
                        valor_liquidacao: convertValueToFloat(props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao[i]?.valor),
                        acrescimo: props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao[i]?.acrescimo,
                        desconto: props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao[i]?.desconto,
                        juros_multa: props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao[i]?.juros_multa,
                        imposto_nfse: props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao[i]?.imposto_nfse,
                        glosas: props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao[i]?.glosas,
                        outros_impostos: props.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao[i]?.outros_impostos,
                        anotacoes: props.dadosMovimentoLancamento[idxEmTela]?.anotacao ||
                            props.dadosMovimentoLancamento[idxEmTela]?.lancamento_movimento_liquidacao[i]?.anotacoes
                    })
                }
                // lancamentos.push({ modo_liquidacao: null, data_liquidacao: null, valor: null })
                propsFormik.setFieldValue(`liquidacao`, lancamentos)
            } else {


                propsFormik.setFieldValue(`liquidacao_parcelado`, [{}])
                propsFormik.setFieldValue(`cliente_lancamento`, props?.dadosMovimentoLancamento[idxEmTela]?.cliente)
                propsFormik.setFieldValue(`tipo_lancamento`, props?.dadosMovimentoLancamento[idxEmTela]?.tipo_lancamento)
                propsFormik.setFieldValue(`estabelecimento_saude`, props?.dadosMovimentoLancamento[idxEmTela]?.estabelecimento_saude)
                propsFormik.setFieldValue(`valor_geral_lancamento`, props?.dadosMovimentoLancamento[idxEmTela]?.valor)
                propsFormik.setFieldValue(`categoria_lancamento`, props?.dadosMovimentoLancamento[idxEmTela]?.categoria)
                propsFormik.setFieldValue(`nota_fiscal_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.nfse)
                propsFormik.setFieldValue(`conta_bancaria_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.conta_bancaria_lancamento || props.dadosMovimentoLancamento[idxEmTela]?.conta_bancaria)
                propsFormik.setFieldValue(`conta_bancaria_lancamento_empresa`, props?.dadosMovimentoLancamento[idxEmTela]?.conta_bancaria_lancamento_empresa)
                propsFormik.setFieldValue(`tipo_documento_lancamento`, props?.dadosMovimentoLancamento[idxEmTela]?.tipo_documento_lancamento)
                propsFormik.setFieldValue(`atendimento_lancamento`, props?.dadosMovimentoLancamento[idxEmTela]?.atendimento)
                propsFormik.setFieldValue(`liquida_parcela.periodicidade`, props?.dadosMovimentoLancamento[idxEmTela]?.periodicidade)
                propsFormik.setFieldValue(`liquida_parcela.data`, props?.dadosMovimentoLancamento[idxEmTela]?.data_prevista)
                propsFormik.setFieldValue(`liquida_parcela.parcela`, props?.dadosMovimentoLancamento[idxEmTela]?.parcela)
                propsFormik.setFieldValue(`liquida_parcela.valor`, props?.dadosMovimentoLancamento[idxEmTela]?.valor)
                propsFormik.setFieldValue(`descricao_lancamento`, props?.dadosMovimentoLancamento[idxEmTela]?.descricao)
                propsFormik.setFieldValue(`previsao_vencimento_lancamento`, props?.dadosMovimentoLancamento[idxEmTela]?.data_prevista)
                propsFormik.setFieldValue(`empresa`, props?.dadosMovimentoLancamento[idxEmTela]?.empresa)
                propsFormik.setFieldValue(`parcelado_lancamento`, true)
                propsFormik.setFieldValue('alterando_linha_lancamento', true)
                propsFormik.setFieldValue('id_lancamento_alteracao', idxEmTela)
                propsFormik.setFieldValue(`id_lancamento`, props.dadosMovimentoLancamento[idxEmTela]?.id)

                const lancamentos = props?.dadosMovimentoLancamento[idxEmTela].lancamento_movimento_liquidacao_parcelado.map(x => ({
                    id_liquidacao: x.id_liquidacao,
                    data_liquidacao: x.data_liquidacao,
                    valor: x?.valor,
                    modo_liquidacao: x.modo_liquidacao,
                    valor_liquidacao: x?.valor?.toString(),
                    valor_original: x?.valor_original?.toString(),
                    valor_liquidacao_formatado: formatarMoeda(parseFloat(x?.valor).toFixed(2)),
                    data: x?.data_prevista,
                    liquidado: x?.liquidado,
                    acrescimo: x?.acrescimo,
                    desconto: x?.desconto,
                    juros_multa: x?.juros_multa,
                    imposto_nfse: x?.imposto_nfse,
                    glosas: x?.glosas,
                    outros_impostos: x?.outros_impostos,
                    anotacoes: props.dadosMovimentoLancamento[idxEmTela]?.anotacao || x?.anotacoes

                }));
                propsFormik.setFieldValue("liquidacao_parcelado", lancamentos);
                // propsFormik.values.liquidacao_parcelado = [...lancamentos]
            }


        }
        else if (idxEmTela >= 0) {

            if (!propsFormik.values.arrayLancamentos[idxEmTela]?.parcelado) {

                propsFormik.setFieldValue(`liquidacao`, [{}])
                propsFormik.setFieldValue(`cliente_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.cliente)
                propsFormik.setFieldValue(`tipo_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.tipo_lancamento)
                propsFormik.setFieldValue(`estabelecimento_saude`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.estabelecimento_saude)
                propsFormik.setFieldValue(`valor_geral_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.valor)
                propsFormik.setFieldValue(`categoria_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.categoria)
                propsFormik.setFieldValue(`previsao_vencimento_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.data_prevista)
                propsFormik.setFieldValue(`descricao_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.descricao)
                propsFormik.setFieldValue(`nota_fiscal_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.nfse_documento)
                propsFormik.setFieldValue(`conta_bancaria_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.conta_bancaria_lancamento || propsFormik?.values?.arrayLancamentos[idxEmTela]?.conta_bancaria)
                propsFormik.setFieldValue(`conta_bancaria_lancamento_empresa`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.conta_bancaria_lancamento_empresa)
                propsFormik.setFieldValue(`tipo_documento_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.tipo_documento_lancamento)
                propsFormik.setFieldValue(`atendimento_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.atendimento)
                propsFormik.setFieldValue(`empresa`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.empresa)
                propsFormik.setFieldValue('alterando_linha_lancamento', true)
                propsFormik.setFieldValue('id_lancamento_alteracao', idxEmTela)
                propsFormik.setFieldValue('naoAlterar', propsFormik?.values?.arrayLancamentos[idxEmTela]?.naoAlterar || false)
                let lancamentos = []

                for (let i in propsFormik.values.liquidacoes[idxEmTela]) {
                    lancamentos.push({
                        data_liquidacao: propsFormik?.values?.liquidacoes[idxEmTela][i]?.data_liquidacao, valor: propsFormik?.values?.liquidacoes[idxEmTela][i]?.valor,
                        modo_liquidacao: { value: propsFormik?.values?.liquidacoes[idxEmTela][i]?.modo_liquidacao_id, label: propsFormik?.values?.liquidacoes[idxEmTela][i]?.modo_liquidacao_descricao },
                        valor_liquidacao: propsFormik?.values?.liquidacoes[idxEmTela][i]?.valor,
                        valor_original: propsFormik?.values?.liquidacoes[idxEmTela][i]?.valor_original,
                        anotacoes: propsFormik?.values?.arrayLancamentos[idxEmTela]?.anotacao || propsFormik?.values?.liquidacoes[idxEmTela][i]?.anotacoes
                    })
                }
                // lancamentos.push({ modo_liquidacao: null, data_liquidacao: null, valor: null })
                // lancamentos.push({ modo_liquidacao: null, data_liquidacao: propsFormik?.values?.liquidacoes[0][0]?.data_liquidacao, valor: null })

                propsFormik.setFieldValue(`liquidacao`, lancamentos)

            }
            else {

                propsFormik.setFieldValue(`liquidacao_parcelado`, [{}])
                propsFormik.setFieldValue(`cliente_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela].cliente)
                propsFormik.setFieldValue(`tipo_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela].tipo_lancamento)
                propsFormik.setFieldValue(`estabelecimento_saude`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.estabelecimento_saude)
                propsFormik.setFieldValue(`valor_geral_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela].valor)
                propsFormik.setFieldValue(`categoria_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.categoria)
                propsFormik.setFieldValue(`conta_bancaria_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.conta_bancaria_lancamento || propsFormik?.values?.arrayLancamentos[idxEmTela]?.conta_bancaria)
                propsFormik.setFieldValue(`conta_bancaria_lancamento_empresa`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.conta_bancaria_lancamento_empresa)
                propsFormik.setFieldValue(`tipo_documento_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.tipo_documento_lancamento)
                propsFormik.setFieldValue(`atendimento_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.atendimento)
                propsFormik.setFieldValue(`liquida_parcela.periodicidade`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.periodicidade)
                propsFormik.setFieldValue(`liquida_parcela.data`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.data)
                propsFormik.setFieldValue(`liquida_parcela.parcela`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.parcela)
                propsFormik.setFieldValue(`liquida_parcela.valor`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.valor)
                propsFormik.setFieldValue(`descricao_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.descricao)
                propsFormik.setFieldValue(`previsao_vencimento_lancamento`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.data_prevista)
                propsFormik.setFieldValue(`empresa`, propsFormik?.values?.arrayLancamentos[idxEmTela]?.empresa)
                propsFormik.setFieldValue('alterando_linha_lancamento', true)
                propsFormik.setFieldValue('parcelado_lancamento', true)
                propsFormik.setFieldValue('id_lancamento_alteracao', idxEmTela)
                let lancamentos = []
                for (let i in propsFormik.values.liquidacoes[idxEmTela]) {
                    lancamentos.push({
                        data_liquidacao: propsFormik?.values?.liquidacoes[idxEmTela][i]?.data_liquidacao,
                        valor: propsFormik?.values?.liquidacoes[idxEmTela][i]?.valor,
                        modo_liquidacao: propsFormik?.values?.liquidacoes[idxEmTela][i]?.modo_liquidacao,
                        valor_liquidacao: propsFormik?.values?.liquidacoes[idxEmTela][i]?.valor.toString(),
                        valor_original: propsFormik?.values?.liquidacoes[idxEmTela][i]?.valor_original.toString(),
                        valor_liquidacao_formatado: formatarMoeda(parseFloat(propsFormik?.values?.liquidacoes[idxEmTela][i]?.valor).toFixed(2)),
                        data: propsFormik?.values?.liquidacoes[idxEmTela][i]?.data_prevista,
                        liquidado: propsFormik?.values?.liquidacoes[idxEmTela][i]?.liquidado,
                        anotacoes: propsFormik?.values?.arrayLancamentos[idxEmTela]?.anotacao || propsFormik?.values?.liquidacoes[idxEmTela][i]?.anotacoes

                    })
                }
                // lancamentos.push({ modo_liquidacao: null, data_liquidacao: null, valor: null })
                propsFormik.setFieldValue(`liquidacao_parcelado`, lancamentos)
            }

        }

    }, [idAlteracaoEmTela, idxEmTela])

    const obterArquivos = (lanc) => {
        props.limparArquivos(false)
        props.setDataArquivos(undefined)
        if (lanc?.id && !lanc?.arquivos) {
            Promise.resolve(get(`/financeiro/lancamentoarquivos/${lanc?.id}`)).then(val => {
                if (val && val.length > 0) {
                    props.setDataArquivos(val)
                } else {
                    props.setDataArquivos([])
                }
            })
        } else if (lanc?.arquivos && lanc.arquivos.length > 0)
            setTimeout(() => {
                props.setDataArquivos(lanc.arquivos)
            }, 100)
        else
            props.setDataArquivos([])
    }

    useEffect(_ => {
        if (props.idMovimento) {

            Promise.resolve(get(`/financeiro/obtervaloresverificacao/${props.idMovimento}`)).then(val => {
                setArrayReceitas(val?.receita.map(x => x))
                // setArrayRetencoes(val?.map(x => x.retencao))

            }).catch(e => console.log('ERRO ', e))
            Promise.resolve(get(`/financeiro/movimentolancamento/${props.idMovimento}`)).then(val => {

                propsFormik.setFieldValue('arrayLancamentos', val.map(x => ({
                    id: x.id,
                    movimento_financeiro_id: x.movimento_financeiro_id,
                    data: x.data_prevista || null, cliente: x.cliente, socio: x.socio, estabelecimento_saude: x.estabelecimento_saude.value, valor: x.valor,
                    data_liquidacao: x?.data_liquidacao, nfse: x.nota_fiscal, parcelado: x.parcelado, periodicidade_id: x.periodicidade?.value,
                    conta_bancaria_id: x.conta_bancaria?.value, categoria_id: x?.categoria?.value, tipo_lancamento_id: x.tipo_lancamento?.value, tipo_lancamento_descricao: x?.tipo_lancamento?.label, descricao: x.descricao,
                    tipo_documento_movimento_financeiro_id: props.propsFormik?.values?.tipo_conta?.value, data_prevista: x.data_prevista, tipo_lancamento: x?.tipo_lancamento, categoria: x?.categoria, atendimento: x?.atendimento,
                    conta_bancaria: x.conta_bancaria, tipo_documento: props.propsFormik?.values?.tipo_conta, valor_total_liquidacao: x?.valor_total_liquidacao, naoAlterar: true,
                    lancamento_movimento_liquidacao: x.lancamento_movimento_liquidacao

                })))

                propsFormik.setFieldValue('liquidacoes', val.map(x => !x.parcelado ? x.lancamento_movimento_liquidacao : x.lancamento_movimento_liquidacao_parcelado).map(subarray =>
                    subarray?.map(objeto => ({
                        ...objeto, modo_liquidacao_id: objeto?.modo_liquidacao?.value, modo_liquidacao_descricao: objeto?.modo_liquidacao?.label, id_lancamento: val.id
                    })))
                )

                setArrayLancamentos(val.map(x => ({
                    id: x.id,
                    movimento_financeiro_id: x.movimento_financeiro_id,
                    data: x.data_prevista || null, cliente: x.cliente, socio: x.socio, estabelecimento_saude: x.estabelecimento_saude, valor: x.valor,
                    data_liquidacao: x?.data_liquidacao, nfse: x.nota_fiscal, parcelado: x.parcelado, periodicidade_id: x.periodicidade?.value,
                    conta_bancaria_id: x.conta_bancaria?.value, categoria_id: x?.categoria?.value, tipo_lancamento_id: x.tipo_lancamento?.value, tipo_lancamento_descricao: x?.tipo_lancamento?.label, descricao: x.descricao,
                    tipo_documento_movimento_financeiro_id: props.propsFormik?.values?.tipo_conta?.value, data_prevista: x.data_prevista, tipo_lancamento: x?.tipo_lancamento, categoria: x?.categoria, atendimento: x?.atendimento,
                    conta_bancaria: x.conta_bancaria, tipo_documento: props.propsFormik?.values?.tipo_conta, valor_total_liquidacao: x?.valor_total_liquidacao, naoAlterar: true,
                    lancamento_movimento_liquidacao: x.lancamento_movimento_liquidacao

                })))
                setArrayLiquidacoes(val.map(x => !x.parcelado ? x.lancamento_movimento_liquidacao : x.lancamento_movimento_liquidacao_parcelado).map(subarray =>
                    subarray?.map(objeto => ({
                        ...objeto, modo_liquidacao_id: objeto?.modo_liquidacao?.value, modo_liquidacao_descricao: objeto?.modo_liquidacao?.label, id_lancamento: val.id
                    }))
                ))

            }).catch(e => console.log('ERRO ', e))
        }

    }, [props.idMovimento])

    // useEffect(_ => {
    //     if (arrayLancamentos.length > 0) {
    //         propsFormik.setFieldValue('arrayLancamentos', arrayLancamentos)
    //         propsFormik.setFieldValue('liquidacoes', arrayLiquidacoes)
    //     }
    // }, [arrayLancamentos, arrayLiquidacoes])

    useEffect(() => {
        if (!propsFormik?.values.alterando_linha_lancamento)
            setIdxEmTela(-1)

    }, [propsFormik?.values.alterando_linha_lancamento])

    const calculaValorLiquidacao = useCallback(valor_verificado => {

        let valorLiquidacoes

        if (props.tabLancamento) {
            valorLiquidacoes = props.dadosMovimentoLancamento?.filter(x => x.tipo_lancamento?.value === 1)?.map(x => x?.valor_total_liquidacao).map(x => convertValueToFloat(x) || 0).reduce((ant, atual) => ant + atual, 0);
            props.setValorTotalLancamento(valorLiquidacoes)
        } else {
            valorLiquidacoes = propsFormik?.values?.arrayLancamentos?.filter(x => x.tipo_lancamento?.value === 1)?.map(item => item?.valor_total_liquidacao)?.map(valor => convertValueToFloat(valor) || 0).reduce((ant, atual) => ant + atual, 0);

        }
        return valorLiquidacoes ? formatarMoeda(valorLiquidacoes) : 'R$ 0,00'

    }, [props.tabLancamento, props.dadosMovimentoLancamento, propsFormik.values.arrayLancamentos])

    const removerLancamento = idx => {
        if (props.tabLancamento) {

            let copiaDados = [...props.dadosMovimentoLancamento]
            copiaDados.splice(idx, 1)
            props.setDadosMovimentoLancamento(copiaDados)
            mostrarToast('sucesso', 'Operação efetuada com sucesso')


        } else {
            let copiaDadosLancamento = [...propsFormik.values.arrayLancamentos]
            let copiaDadosLiquidacao = [...propsFormik.values.liquidacoes]
            copiaDadosLancamento.splice(idx, 1)
            copiaDadosLiquidacao.splice(idx, 1)
            setArrayLancamentos(copiaDadosLancamento)
            setArrayLiquidacoes(copiaDadosLiquidacao)
            propsFormik.setFieldValue('arrayLancamentos', copiaDadosLancamento)
            propsFormik.setFieldValue('liquidacoes', copiaDadosLiquidacao)
            mostrarToast('sucesso', 'Operação efetuada com sucesso')


        }
    }
    const validaExclusaoLancamento = (idx, movimento) => {

        let contemLiquidacoesParcelado = []

        if (!movimento)
            contemLiquidacoesParcelado = propsFormik?.values?.liquidacoes[idx]?.filter(x => x.liquidado).length > 1
        else {
            let copiaDados = [...props.dadosMovimentoLancamento]
            contemLiquidacoesParcelado = copiaDados[idx]?.lancamento_movimento_liquidacao_parcelado?.filter(x => x.liquidado).length > 1

        }


        if (contemLiquidacoesParcelado) {
            setMsgValidacao(<>Lançamento possui mais de uma liquidação deseja continuar ? </>)
            setShowModalValidaExclusao(true)
        }
        else
            removerLancamento(idx)

    }
    const verificaValorTotal = useCallback(_ => {

        if (parseFloat(propsFormik?.values?.arrayLancamentos?.filter(x => x?.valor).reduce((partialSum, a) => partialSum + parseFloat(a?.valor), 0)) > 95500.00)
            return 'color-valor-liquidacao-acima'

        else if (parseFloat(propsFormik?.values?.arrayLancamentos?.filter(x => x?.valor).reduce((partialSum, a) => partialSum + parseFloat(a?.valor), 0)) === 95500.00)
            return 'color-valor-liquidacao-normalizado'

        else if (parseFloat(propsFormik?.values?.arrayLancamentos?.filter(x => x?.valor).reduce((partialSum, a) => partialSum + parseFloat(a?.valor), 0)) === 0.00)
            return ''

        else if (parseFloat(propsFormik?.values?.arrayLancamentos?.filter(x => x?.valor).reduce((partialSum, a) => partialSum + parseFloat(a?.valor), 0)) < 95500.00)
            return 'color-valor-liquidacao-abaixo'
        else
            return ''
    }, [propsFormik.values.arrayLancamentos])

    function sortTable(table_id, sortColumn, tipo) {
        var tableData = document.getElementById(table_id).getElementsByTagName('tbody').item(0);
        var thread = document.getElementById(table_id).getElementsByTagName('thead').item(0);
        let temContador = false;
        let indexContador = -1;

        let qtdThs = thread.getElementsByTagName('th').length;
        for (let index = 0; index < qtdThs; index++) {
            if (index === sortColumn)
                continue;

            if (thread.getElementsByTagName('th').item(index).innerText.indexOf('↑') > -1) {
                thread.getElementsByTagName('th').item(index).innerText = thread.getElementsByTagName('th').item(index).innerText.replace('↑', '');
            }
            else if (thread.getElementsByTagName('th').item(index).innerText.indexOf('↓') > -1) {
                thread.getElementsByTagName('th').item(index).innerText = thread.getElementsByTagName('th').item(index).innerText.replace('↓', '');
            }

            if (thread.getElementsByTagName('th').item(index).className === 'contador') {
                temContador = true;
                indexContador = index;
            }
        }

        let innerText = thread.getElementsByTagName('th').item(sortColumn).innerText;
        let order = 'ASC'
        if (innerText.indexOf('↑') > -1) {
            order = 'DESC'
            thread.getElementsByTagName('th').item(sortColumn).innerText = innerText.replace('↑', '');
        }
        else if (innerText.indexOf('↓') > -1) {
            order = 'ASC'
            thread.getElementsByTagName('th').item(sortColumn).innerText = innerText.replace('↓', '');
        }
        thread.getElementsByTagName('th').item(sortColumn).innerText += order == 'ASC' ? '↑' : '↓';
        var rowData = tableData.getElementsByTagName('tr');
        for (var i = 0; i < rowData.length - 1; i++) {
            for (var j = 0; j < rowData.length - (i + 1); j++) {
                if (tipo && tipo == 'data') {
                    if (order == 'ASC' ?
                        moment(rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY') < moment(rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY')
                        : moment(rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY') > moment(rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY')
                    ) {
                        tableData.insertBefore(rowData.item(j + 1), rowData.item(j));
                    }
                }
                else if (tipo && tipo == 'data_hora') {
                    if (order == 'ASC' ?
                        moment(rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY HH:mm') < moment(rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY HH:mm')
                        : moment(rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY HH:mm') > moment(rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText, 'DD/MM/YYYY HH:mm')
                    ) {
                        tableData.insertBefore(rowData.item(j + 1), rowData.item(j));
                    }
                }
                else if (tipo && tipo == 'numero') {
                    var a = rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText;
                    var b = rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText;
                    if (order == 'ASC' ?
                        Number(a) < Number(b)
                        : Number(a) > Number(b)
                    ) {
                        tableData.insertBefore(rowData.item(j + 1), rowData.item(j));
                    }
                }
                else if (tipo && tipo == 'socio') {
                    var a = rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText;
                    a = a.split(/\s+/).join('')
                    var b = rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText;
                    b = b.split(/\s+/).join('')
                    a = a.indexOf('-') > -1 ? a.split('-')[1] : a;
                    b = b.indexOf('-') > -1 ? b.split('-')[1] : b;
                    if (order == 'ASC' ?
                        a < b
                        : a > b
                    ) {
                        tableData.insertBefore(rowData.item(j + 1), rowData.item(j));
                    }
                }
                else if (order == 'ASC' ?
                    rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText < rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText
                    : rowData.item(j).getElementsByTagName('td').item(sortColumn).innerText > rowData.item(j + 1).getElementsByTagName('td').item(sortColumn).innerText
                ) {
                    tableData.insertBefore(rowData.item(j + 1), rowData.item(j));
                }
            }
        }


        if (temContador && rowData.length > 1) {
            for (var i = 0; i < rowData.length; i++) {
                rowData.item(i).getElementsByTagName('td').item(indexContador).innerText = i + 1
            }
        }
    }


    return (
        <>
            {props.tabLancamento === true || !props.id ?
                <>
                    <div className={props.tabLancamento === true ? `mt-40 tab-tittle` : 'mt-40'}>
                        <HeaderCadastro titulo={`Lançamentos (Movimentos Diários)`} classeChildren='space-between-100'>
                            <BotaoPadrao texto="Buscar Lançamento" type="button" click={_ => { setShowModalAdicionarLancamento(true) }}></BotaoPadrao>
                        </HeaderCadastro>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className='card b-radius-10' style={{ zIndex: 1, minHeight: '458px', marginTop: '10px' }}>
                                <div className='card-body'>
                                    <div class="mb-2 row-space-between"><div class="card-title"></div></div>
                                    <div className='dashboard-auditoria'>
                                        <Tabs style={{ backgroundColor: '#F8F8F8' }}>
                                            <TabPanel>
                                                <div className='scrollbar-container custom-scrollbar' style={{ maxHeight: '308px' }} >
                                                    <table className="table table-sm table-borderless tabela-linha-destaque ordenavel ordenavel" id="tbl-marcacoes">
                                                        <thead>
                                                            <th className="contador" onClick={() => sortTable('tbl-marcacoes', 0, 'numero')}>#</th>
                                                            <th onClick={() => sortTable('tbl-marcacoes', 2)}>Lanç.</th>
                                                            <th onClick={() => sortTable('tbl-marcacoes', 2)}>Parcela</th>
                                                            <th onClick={() => sortTable('tbl-marcacoes', 2)}>Aten.(Guias)</th>
                                                            <th onClick={() => sortTable('tbl-marcacoes', 3, 'data_hora')}>Data</th>
                                                            <th onClick={() => sortTable('tbl-marcacoes', 3, 'data_hora')}>Cliente</th>
                                                            <th onClick={() => sortTable('tbl-marcacoes', 3, 'data_hora')}>Sócio(s)</th>
                                                            <th onClick={() => sortTable('tbl-marcacoes', 3, 'data_hora')}>Estabelecimento Saúde</th>
                                                            <th onClick={() => sortTable('tbl-marcacoes', 3, 'data_hora')}>Valor R$</th>
                                                            <th onClick={() => sortTable('tbl-marcacoes', 3, 'data_hora')}>TP</th>
                                                            <th onClick={() => sortTable('tbl-marcacoes', 3, 'data_hora')}>Data Liquidação</th>
                                                            <th onClick={() => sortTable('tbl-marcacoes', 3, 'data_hora')}>NFSe</th>
                                                            <th className="text-center">Ações</th>
                                                        </thead>
                                                        <tbody style={{ fontSize: 12 }}>
                                                            {
                                                                props?.tabLancamento && props.dadosMovimentoLancamento?.length > 0 ?

                                                                    props.dadosMovimentoLancamento.map((x, i) =>
                                                                        <tr className={`${i === idxEmTela && propsFormik.values.alterando_linha_lancamento ? 'row-selected' : ''}`}>
                                                                            <td><span class="font-weight-medium">{i + 1}</span></td>
                                                                            <td><span class="font-weight-medium">{x?.id || x?.id_lancamento}</span></td>
                                                                            <td><span class="font-weight-medium">{x?.numero_parcela}</span></td>
                                                                            <td><span class="font-weight-medium">{x?.atendimento?.value}</span></td>
                                                                            {/* <td><span class="font-weight-medium">{1}</span></td> */}
                                                                            <td><span class="font-weight-medium">{x?.data}</span></td>
                                                                            <td><span class="font-weight-medium">{x?.cliente?.label}</span></td>
                                                                            <td><span class="font-weight-medium">{x?.socio}</span></td>
                                                                            <td><span class="font-weight-medium">{x?.estabelecimento_saude?.label}</span></td>
                                                                            <td><span class="font-weight-medium">{formatarMoeda(x.tipo_lancamento?.value === 1 ? convertValueToFloat(x?.valor_total_liquidacao) : -1 * convertValueToFloat(x?.valor_total_liquidacao))}</span></td>
                                                                            <td><span class="font-weight-medium">{ordenarSiglaModoPagamento(x.parcelado ? x.lancamento_movimento_liquidacao_parcelado : x?.lancamento_movimento_liquidacao)}</span></td>
                                                                            <td><span class="font-weight-medium">{x?.data_liquidacao}</span></td>
                                                                            <td><span class="font-weight-medium">{x?.nfse || x?.nota_fiscal}</span></td>
                                                                            <td className="text-center">
                                                                                <LinkVisualizacaoTabelaLocalLancamento textoInline='' action={() => { setItemVisualizar({ liquidacoes: !props.dadosMovimentoLancamento[i].parcelado ? props.dadosMovimentoLancamento[i].lancamento_movimento_liquidacao : props.dadosMovimentoLancamento[i].lancamento_movimento_liquidacao_parcelado, ...x }); setShowModalVisualizacao(true); }} idItem={x?.id} descricao={x?.descricao_auditoria} url='/administracao/ferias/marcacao' acaoAposExclusao={() => { }} acaoAposErro={() => { }}></LinkVisualizacaoTabelaLocalLancamento>
                                                                                <LinkAlteracaoTabelaLocal textoInline='' action={() => { setAlterarEmTela(true); setIdAlteracaoEmTela(x?.id); setIdxEmTela(i); obterArquivos(x) }}>
                                                                                </LinkAlteracaoTabelaLocal>
                                                                                <LinkExclusaoTabelaLocal textoInline='' texto='' idItem={x?.id} descricao={`${x?.cliente?.label}`} url='/financeiro/excluirmovimentolancamento'
                                                                                    action={() => {

                                                                                        validaExclusaoLancamento(i, props.tabLancamento ? true : false)
                                                                                    }}
                                                                                    acaoAposExclusao={() => {
                                                                                        const novos_lancamentos = [...props.dadosMovimentoLancamento]
                                                                                        novos_lancamentos.splice(i, 1)
                                                                                        props.setDadosMovimentoLancamento(novos_lancamentos)
                                                                                        mostrarToast('sucesso', mensagens['sucesso'])
                                                                                    }}
                                                                                    acaoAposErro={(e) => {
                                                                                        if (e?.response && e?.response?.data && e?.response?.data?.erros)
                                                                                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                                                                                        else
                                                                                            mostrarToast('erro', mensagens.erro)
                                                                                    }}></LinkExclusaoTabelaLocal>
                                                                            </td>
                                                                            <ModalDuasOpcoes showModal={showModalValidaExclusao} setShowModal={setShowModalValidaExclusao}
                                                                                funcaoOpcao2={() => { setShowModalValidaExclusao(false); removerLancamento(i); mostrarToast('sucesso', 'Operação efetuada com sucesso') }}
                                                                                funcaoOpcao1={() => { setShowModalValidaExclusao(false) }}
                                                                                esconderFechar mensagem={<>{msgValidacao}<br /></>}
                                                                                textoOpcao1={'Fechar'}
                                                                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                                                                textoOpcao2={'Continuar'} />
                                                                        </tr>
                                                                    )
                                                                    :
                                                                    !props?.tabLancamento && arrayLancamentos && arrayLancamentos?.length > 0 ?

                                                                        arrayLancamentos?.map((x, i) =>
                                                                            <tr className={`${i === idxEmTela && propsFormik.values.alterando_linha_lancamento ? 'row-selected' : ''}`}>
                                                                                <td><span class="font-weight-medium">{i + 1}</span></td>
                                                                                <td><span class="font-weight-medium">{x?.id || x?.id_lancamento}</span></td>
                                                                                <td><span class="font-weight-medium">{x?.parcela}</span></td>
                                                                                <td><span class="font-weight-medium">{x?.atendimento?.value}</span></td>
                                                                                <td><span class="font-weight-medium">{x?.data}</span></td>
                                                                                <td><span class="font-weight-medium">{x?.cliente?.label}</span></td>
                                                                                <td><span class="font-weight-medium">{x?.socio}</span></td>
                                                                                <td><span class="font-weight-medium">{x?.estabelecimento_saude?.label}</span></td>
                                                                                <td><span class="font-weight-medium">{formatarMoeda(convertValueToFloat(x?.valor_total_liquidacao))}</span></td>
                                                                                <td><span class="font-weight-medium">{ordenarSiglaModoPagamento(x?.lancamento_movimento_liquidacao)}</span></td>
                                                                                <td><span class="font-weight-medium">{x?.data_liquidacao}</span></td>
                                                                                <td><span class="font-weight-medium">{x?.nfse || x?.nota_fiscal}</span></td>
                                                                                <td className="text-center">
                                                                                    <LinkVisualizacaoTabelaLocalLancamento action={() => { setItemVisualizar({ liquidacoes: propsFormik.values.liquidacoes[i], ...x }); setShowModalVisualizacao(true); }} idItem={x?.id} descricao={x?.descricao_auditoria} url='/administracao/ferias/marcacao' acaoAposExclusao={() => { }} acaoAposErro={() => { }}></LinkVisualizacaoTabelaLocalLancamento>
                                                                                    {!x.naoAlterar ?
                                                                                        <>
                                                                                            <LinkAlteracaoTabelaLocal action={() => {
                                                                                                setAlterarEmTela(true); setIdAlteracaoEmTela(x?.id); setIdxEmTela(i); obterArquivos(x?.id)

                                                                                            }}></LinkAlteracaoTabelaLocal>
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                                    <LinkExclusaoTabelaLocal textoInline='excluir' action={() => {
                                                                                        if (x.naoAlterar)
                                                                                            mostrarToast('erro', 'Ação não permitida')
                                                                                        else
                                                                                            validaExclusaoLancamento(i, props.tabLancamento ? true : false)
                                                                                    }
                                                                                    } idItem={x?.id} descricao={x?.descricao_auditoria} url='/administracao/ferias/marcacao' acaoAposExclusao={() => { removerLancamento(i) }} acaoAposErro={() => { }}></LinkExclusaoTabelaLocal>
                                                                                </td>
                                                                                <ModalDuasOpcoes showModal={showModalValidaExclusao} setShowModal={setShowModalValidaExclusao}
                                                                                    funcaoOpcao2={() => { setShowModalValidaExclusao(false); removerLancamento(i) }}
                                                                                    funcaoOpcao1={() => { setShowModalValidaExclusao(false) }}
                                                                                    esconderFechar mensagem={<>{msgValidacao}<br /></>}
                                                                                    textoOpcao1={'Fechar'}
                                                                                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                                                                                    textoOpcao2={'Continuar'} />
                                                                            </tr>

                                                                        )
                                                                        :
                                                                        <tr>
                                                                            <td colspan="4"><span className="font-weight-medium">Nenhum registro encontrado.</span></td>
                                                                        </tr>
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                                <div className=' row mr-0 ml-0 mt-2 valor-total-lancamento' >
                                    <div className={"col-12 text-right vertical-center"} style={{ marginTop: 10 }}>
                                        <span className='mb-2'>Valor Verificado: <span className={`ml-1 ${convertValueToFloat(totalRecebido).toFixed(2) - convertValueToFloat(calculaValorLiquidacao(true)) == 0 ? 'valor-indicador-certo' : 'valor-indicador-errado'}`}><b>{formatarMoeda(convertValueToFloat(totalRecebido).toFixed(2) - convertValueToFloat(calculaValorLiquidacao(true)))}</b></span></span>
                                        <span>Total Liquidado: <span className={`ml-1`}><b>{calculaValorLiquidacao(false)}</b></span></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <ModalLancamento showModal={showModalVisualizacao} setShowModal={setShowModalVisualizacao} item={itemVisualizar} />
                    <ModalAddLancamento showModal={showModalAdicionarLancamento} setShowModal={setShowModalAdicionarLancamento} arrayLancamentos={arrayLancamentos} setArrayLancamentos={setArrayLancamentos} arrayLiquidacoes={arrayLiquidacoes} setArrayLiquidacoes={setArrayLiquidacoes} propsFormik={propsFormik} dadosMovimentoLancamento={props.dadosMovimentoLancamento} setDadosMovimentoLancamento={props.setDadosMovimentoLancamento} idMovimento={props.id || props.idMovimento} />
                </>
                : <></>
            }
        </>

    )
})

const ModalLancamento = ({ item, ...props }) => {
    const [dadosLancamento, setDadosLancamento] = useState()
    useEffect(() => {
        setDadosLancamento([
            { titulo: 'Atendimento', descricao: item.atendimento?.label },
            { titulo: 'Cliente', descricao: item.cliente?.label },
            { titulo: 'Valor R$', descricao: formatarMoeda(item.valor) },
            { titulo: 'Sócio(s)', descricao: item.socio },
            { titulo: 'Tipo Lançamento', descricao: item.tipo_lancamento?.label },
            { titulo: 'Categoria', descricao: item.categoria?.label },
            { titulo: 'Conta Bancária', descricao: item.conta_bancaria?.label },
            { titulo: 'Descrição', descricao: item.descricao },
            { titulo: 'Tipo Documento', descricao: item.tipo_documento?.label },
            { titulo: 'NFSe/Documento', descricao: item.nfse },
            { titulo: 'Previsão Vencimento', descricao: item.data_prevista },
            { titulo: 'Parcelado?', descricao: item.parcelado ? 'Sim' : 'Não' },
            {
                html: () => {
                    let liquidacoes = item.liquidacoes ? item.liquidacoes.filter(y => y).map(x => ({
                        data: x.data_liquidacao, modo_liquidacao: x.modo_liquidacao?.label, valor: x.valor,
                        acrescimo: x.acrescimo_formatado, desconto: x.desconto_formatado, juros_multa: x.juros_multa_formatado, imposto_nfse: x.imposto_nfse_formatado,
                        glosas: x.glosas_formatado, outros_impostos: x.outros_impostos_formatado, parcelado: x.parcelado, anotacoes: x.anotacoes || x.anotacao
                    })) : []
                    return <div>
                        <h5>Liquidações</h5>
                        {liquidacoes.map((x, ix) => {
                            return <div className='detalhes mb-1'>
                                <div className={'item-detalhes row'} style={{ marginLeft: 1 }}>
                                    <div className={x.parcelado ? 'col-6' : ''}>
                                        <h6><b>Modo Liquidação</b></h6>
                                        <h6>{x.modo_liquidacao || '--'}</h6>
                                    </div>
                                    {x.parcelado ? <div className='col-6'>
                                        <h6><b>Parcela</b></h6>
                                        <h6>{ix + 1}</h6>
                                    </div> : <></>}
                                </div>
                                <div className={'item-detalhes row'}>
                                    <div className='col-6'>
                                        <h6><b>Data Liquidação</b></h6>
                                        <h6>{x.data || '--'}</h6>
                                    </div>
                                    <div className='col-6'>
                                        <h6><b>Valor R$</b></h6>
                                        <h6><b className='valor-indicador-certo'>{formatarMoeda(x.valor)}</b></h6>
                                    </div>
                                    <div className='col-6'>
                                        <h6><b>Acréscimo R$</b></h6>
                                        <h6><b className='valor-indicador-certo'>{(x.acrescimo)}</b></h6>
                                    </div>
                                    <div className='col-6'>
                                        <h6><b>Desconto R$</b></h6>
                                        <h6><b className='valor-indicador-certo'>{(x.desconto)}</b></h6>
                                    </div>
                                    <div className='col-6'>
                                        <h6><b>Juros/Multas R$</b></h6>
                                        <h6><b className='valor-indicador-certo'>{(x.juros_multa)}</b></h6>
                                    </div>
                                    <div className='col-6'>
                                        <h6><b>Imposto NFSE R$</b></h6>
                                        <h6><b className='valor-indicador-certo'>{(x.imposto_nfse)}</b></h6>
                                    </div>
                                    <div className='col-6'>
                                        <h6><b>Glosas R$</b></h6>
                                        <h6><b className='valor-indicador-certo'>{(x.glosas)}</b></h6>
                                    </div>
                                    <div className='col-6'>
                                        <h6><b>Outros R$</b></h6>
                                        <h6><b className='valor-indicador-certo'>{(x.outros_impostos)}</b></h6>
                                    </div>
                                </div>
                                <div className={'item-detalhes row'} style={{ marginLeft: 1 }}>
                                    <div className={''}>
                                        <h6><b>Anotações</b></h6>
                                        <h6 style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{x.anotacoes}</h6>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                }
            }
        ])
    }, [item])

    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Informações Lançamento'} show={props.showModal} setShowModal={props.setShowModal} >
                <div className='detalhes'>
                    {dadosLancamento ?

                        dadosLancamento.map((item, idx) => {
                            return (
                                <>
                                    {idx === 0 ? <></> : <hr />}
                                    {item.html ? item.html() : <div className={'item-detalhes'}>
                                        <h6><b>{item.titulo}</b></h6>
                                        {item?.titulo?.includes('Valor R$') || item.titulo.includes('Data') ?
                                            <h6 >
                                                <b className='valor-indicador-certo'>{item.descricao}</b>
                                            </h6>
                                            :
                                            <h6>{item.descricao}</h6>}
                                        {item.html}
                                    </div>}
                                </>
                            )
                        })
                        : <></>
                    }
                </div>
            </ModalCadastro >

        </>
    )
}

const ModalAddLancamento = ({ target, idLancamento, ...props }) => {
    const refMovimentoFInanceiro = useRef(null)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({
        lancamento: undefined,
    })
    const [valorTotalLiquidacao, setValorTotalLiquidacao] = useState(0)
    const [dadosLancamento, setDadosLancamento] = useState()
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [dados, setDados] = useState({});

    // useEffect(() => {
    //     if (idLancamento)
    //         Promise.resolve(get('/financeiro/dadoslancamentoliquidacao', { id: idLancamento }).then((item) => {
    //             setDadosLancamento(item && item[0])

    //         }))
    // }, [idLancamento])

    useEffect(() => {
        if (!props.showModal) {
            setDados({})
        }
    }, [props.showModal])


    const validarSubmit = (values, propsFormik) => {
        setSalvando(true)
        const lgtLista = props.dadosMovimentoLancamento && props.dadosMovimentoLancamento.length > 0
        if ((lgtLista && props.dadosMovimentoLancamento.findIndex(x => x.id === values.lancamento.value) == -1) || !lgtLista) {
            validar(`/financeiro/validaraddlancamento`, { id: values.lancamento.value }, () => Submit(values, propsFormik), (e) => {
                const alertas = e.response.data.alertas;
                if (Array.isArray(alertas) && alertas && alertas.length > 0) {
                    alertas.map(x => {
                        mostrarToast('erro', x)
                    })
                } else {
                    mostrarToast('erro', 'Operação bloqueada! Ocorreu um erro inesperado.')
                }
                setSalvando(false)
            })
        } else {
            setSalvando(false)
            mostrarToast('erro', 'Operação bloqueada! Lançamento já foi adicionado à lista.')
        }

    }

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        Promise.resolve(get(`/financeiro/lancamentoLista/${values.lancamento.value}`)).then(val => {
            if (val && val[0]) {
                let arrayLancamento = props.dadosMovimentoLancamento ? [...props.dadosMovimentoLancamento] : []
                val[0].movimento_financeiro_id = props.idMovimento
                arrayLancamento.push(val[0])
                if (props.dadosMovimentoLancamento)
                    props.setDadosMovimentoLancamento(arrayLancamento)
                else {

                    let arrayLancamento = val.map(x => ({
                        movimento_financeiro_id: props.idMovimento, id_lancamento: x.id,
                        data: x.data_prevista || null, cliente: x.cliente, socio: x.socio, estabelecimento_saude: x.estabelecimento_saude, valor: x.valor,
                        data_liquidacao: x?.data_liquidacao, nfse: x.nota_fiscal || x?.nfse_documento, parcelado: x.parcelado, periodicidade_id: x.periodicidade?.value,
                        conta_bancaria_id: x.conta_bancaria?.value, categoria_id: x?.categoria?.value, tipo_lancamento_id: x.tipo_lancamento?.value, tipo_lancamento_descricao: x?.tipo_lancamento?.label, descricao: x.descricao,
                        tipo_documento_movimento_financeiro_id: props.propsFormik?.values?.tipo_conta?.value, data_prevista: x.data_prevista, tipo_lancamento: x?.tipo_lancamento, categoria: x?.categoria, atendimento: x?.atendimento,
                        conta_bancaria: x.conta_bancaria, tipo_documento: props.propsFormik?.values?.tipo_conta, valor_total_liquidacao: x?.valor_total_liquidacao, naoAlterar: true
                    }))
                    let liquidacoes = val.map(x => !x.parcelado ? x.lancamento_movimento_liquidacao : x.lancamento_movimento_liquidacao_parcelado)
                    liquidacoes = liquidacoes?.map(subarray =>
                        subarray?.map(objeto => ({
                            ...objeto, modo_liquidacao_id: objeto?.modo_liquidacao?.value, modo_liquidacao_descricao: objeto?.modo_liquidacao?.label
                        }))
                    );

                    let copiaLancamentos = props.arrayLancamentos ? [...props.arrayLancamentos] : []
                    let copiaLiquidacoes = props.arrayLiquidacoes ? [...props.arrayLiquidacoes] : []
                    copiaLancamentos.push(arrayLancamento)
                    copiaLiquidacoes.push(liquidacoes)
                    props.setArrayLancamentos(copiaLancamentos.flatMap(x => x))
                    props.setArrayLiquidacoes(copiaLiquidacoes)
                    props.propsFormik.setFieldValue('arrayLancamentos', copiaLancamentos.flatMap(x => x))
                    props.propsFormik.setFieldValue('liquidacoes', copiaLiquidacoes)
                }
            }
            setSalvando(false)
            props.setShowModal(false)
        })
    }


    let validacaoInicial = Yup.lazy(({ liquidacao }) => {
        return Yup.object({
            lancamento: Yup.object().nullable().required()
        })
    })

    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Buscar Lançamento'} show={props.showModal} setShowModal={props.setShowModal}>
                <Formik
                    initialValues={valoresIniciais}
                    validationSchema={validacaoInicial}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
                    {propsFormik => (
                        <div>

                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div>
                                        <div className='' style={{ padding: 10 }}>
                                            <div ref={refMovimentoFInanceiro}>

                                                <InputLancamentoSemMovimento label='Lançamento' tamanho={'col-lg-12 p-0'} name='lancamento' placeholder='Lançamento' propsFormik={propsFormik}
                                                    obrigatorio={true} classeTooltip={'tooltip-input-modal'}
                                                    autofocus onChange={(option) => {
                                                        propsFormik.setFieldValue('lancamento', option)
                                                        setDados(option)
                                                    }}
                                                />
                                                {/* {propsFormik.errors.movimento_financeiro && !Array.isArray(propsFormik.errors.movimento_financeiro) ?
                                                    <Overlay target={refMovimentoFInanceiro.current} show={true} placement="bottom">
                                                        {props2 => {
                                                            return (
                                                                <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                                                    <div className="error">
                                                                        <FiAlertTriangle className="icone" /> <span>{propsFormik.errors.movimento_financeiro}</span></div>
                                                                </Tooltip>)
                                                        }}
                                                    </Overlay> : <></>} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator mt-20 mb-20"></div>
                                <div className='col-12 '>
                                    <div className='detalhes '>
                                        <div className='item-detalhes display-flex col-12 row'>
                                            <div className='col-12'>
                                                <h6><b>Empresa</b></h6>
                                                <h6 >{dados?.empresa || '--'}</h6>
                                            </div>
                                            <div className='col-12'>
                                                <h6><b>Atend.</b></h6>
                                                <h6 >{dados?.atendimento || '--'}</h6>
                                            </div>
                                            <div className='col-12'>
                                                <h6><b>Cliente</b></h6>
                                                <h6 >{dados?.label || '--'}</h6>
                                            </div>
                                            <div className='col-12'>
                                                <h6><b>Estabelecimento Saúde</b></h6>
                                                <h6 >{dados?.estabelecimento_saude || '--'}</h6>
                                            </div>
                                            <div className='col-12'>
                                                <h6><b>Sócio(s)</b></h6>
                                                <h6 >{dados.socio || '--'}</h6>
                                            </div>
                                            <div className='col-6'>
                                                <h6><b>Data Liq.</b></h6>
                                                <h6 >{dados?.data_liquidacao || '--'}</h6>
                                            </div>
                                            <div className='col-6'>
                                                <h6><b>Valor Liq.</b></h6>
                                                <h6 ><b className='valor-indicador-certo'>{dados?.valor_liquidado ? (dados?.valor_liquidado + ' - ' + dados?.modo_pagamento) : '--'}</b></h6>
                                            </div>
                                            <div className='col-12'>
                                                <h6><b>Usuário</b></h6>
                                                <h6 >{dados?.usuario || '--'}</h6>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="separator mt-20 mb-20"></div>
                                </div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Adicionar' disabled={salvando} click={_ => {

                                    }} idTooltip={"salvar"} />
                                </div>

                            </Form>
                            <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { Submit(propsFormik.values, propsFormik); setShowModalValidacao(false) }} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                                esconderFechar mensagem={<>{<>{'Validações da Liquidação:'} <br /><br /></>} {mensagemModal}<br />
                                    {'Deseja Continuar?'}</>}
                                textoOpcao1={'Cancelar'}
                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                textoOpcao2={'Continuar'} />
                        </div>
                    )}
                </Formik>
            </ModalCadastro >
        </>
    )
}


export { CadastroLiquidacao, CadastroLiquidacaoParcelado, MovimentoLancamentos }
