import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
var format = require('xml-formatter');


export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/financeiro/cliente', id,
            obj => {

                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Cliente',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj?.id, tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Cliente', descricao: obj?.tipo_cliente?.label, tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Pessoa', descricao: obj?.tipo_pessoa?.label, tamanho: 'col-lg-4' },
                                { titulo: 'Sigla/Fantasia/Nome Social', descricao: obj?.sigla, tamanho: 'col-lg-4' },
                                { titulo: 'Nome/Razão', descricao: obj?.cliente, tamanho: 'col-lg-4' },
                                { titulo: 'CPF/CNPJ', descricao: obj?.cpf_cnpj, tamanho: 'col-lg-4' },
                                { titulo: 'Data Nascimento', descricao: obj?.data_nascimento, tamanho: 'col-lg-4' },
                                { titulo: 'Telefone', descricao: obj?.fone.map(x => x.telefone).join(', '), tamanho: 'col-lg-4' },
                                { titulo: 'E-mail', descricao: obj?.email, tamanho: 'col-lg-4' },
                                { titulo: 'Portal', descricao: obj?.portal, tamanho: 'col-lg-4' },
                                { titulo: 'Registro Conselho', descricao: obj?.registro_conselho, tamanho: 'col-lg-4' },
                                { titulo: 'Identificador', descricao: obj?.identificador, tamanho: 'col-lg-4' },
                                { titulo: 'Responsável', descricao: obj?.responsavel, tamanho: 'col-lg-4' },
                                { titulo: 'Insc. Municipal', descricao: obj?.inscricao_municipal, tamanho: 'col-lg-4' },
                                { titulo: 'Insc. Estadual', descricao: obj?.inscricao_estadual, tamanho: 'col-lg-4' },
                                { titulo: 'Observação', descricao: obj?.observacao, tamanho: 'col-lg-4' },
                                { titulo: 'Data Registro', descricao: obj?.data_registro, tamanho: 'col-lg-4' },
                            ]
                        },
                        {
                            titulo: 'Endereço',
                            colunas: [
                                { titulo: 'CEP', descricao: obj?.cep, tamanho: 'col-lg-4' },
                                { titulo: 'Logradouro', descricao: obj?.logradouro, tamanho: 'col-lg-4' },
                                { titulo: 'Complemento', descricao: obj?.complemento, tamanho: 'col-lg-4' },
                                { titulo: 'Número', descricao: obj?.numero, tamanho: 'col-lg-4' },
                                { titulo: 'Bairro', descricao: obj?.bairro, tamanho: 'col-lg-4' },
                                { titulo: 'Município', descricao: obj?.municipio?.label, tamanho: 'col-lg-4' },
                            ]
                        },
                    ]
                } : { titulo: 'Visualização Cliente' })

            })

    }, [id])


    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}
