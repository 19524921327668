import React, { useState, useEffect } from 'react'
import { HeaderCadastro } from '../../../../components/Headers'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { get, excluir, consultar, validaCadastro, reenvioDados } from '../../../../util/AcessoApi'
import { BtnAcao } from '../../../../components/Acoes'
import { Alterar, Excluir, Visualizar, Validar, Reenvio } from '../../../../components/Acoes'
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens, useWindowSize, useInterval } from '../../../../util/Utilitarios.js'

const ConsultaUsuario = (props) => {
    const [data, setData] = useState([])

    useEffect(() => {
        consultar('/controleAcesso/usuario', (dados) => setData(dados))
    }, [])

    useEffect(() => {
    }, [data])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-usuario',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Nome',
                        accessor: 'nome',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Login',
                        accessor: 'login',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Tipo',
                        accessor: 'tipo_usuario',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'tipo_usuario',
                            idColuna: 'usuario_tipo_id'
                        }
                    },
                    {
                        Header: 'Grupo',
                        accessor: 'grupo_id',
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'texto',
                        },
                        visivel: false,
                        filtro: {
                            tipo: 'grupo',
                            idColuna: 'grupo_id'
                        }
                    },
                    {
                        Header: 'Ativo?',
                        accessor: 'ativo',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'usuario_ativo',
                            idColuna: 'ativo'
                        }
                    },
                    {
                        Header: 'Externo?',
                        accessor: 'externo',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'usuario_externo',
                            idColuna: 'externo'
                        }
                    },
                    {
                        Header: 'E-mail',
                        accessor: 'email',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Data de Registro',
                        accessor: 'data_registro',
                        ordenamento: {
                            tipo: 'data',
                        }
                    }
                ],
            },
        ],
        []
    )

    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/controleAcesso/usuario', propsAcao.id,
                    () => {
                        getApi(setData, '/controleAcesso/usuario')
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    })
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const maisAcoesObj = [

        (propsAcao) => <Visualizar to={'/controleAcesso/usuario/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/controleAcesso/usuario/alteracao/' + propsAcao.id} />,
        (propsAcao) => <Excluir to={'/controleAcesso/usuario/consulta/'} action={() => exclusao(propsAcao)} />,
        (propsAcao) => propsAcao.row.valida_cadastro === false ? <Validar to={'/controleAcesso/usuario/consulta/'} action={() => validaCadastro('/controleAcesso/usuario', '/validaUsuario', { id: propsAcao.id, valida_cadastro: true },
            () => {
                // setData([])
                getApi(setData, '/controleAcesso/usuario')
                mostrarToast('sucesso', mensagens['sucesso'])

            })} /> : <Reenvio to={'/controleAcesso/usuario/consulta/'} action={() => reenvioDados('/controleAcesso/usuario/consulta', '/controleAcesso/usuario/reenvia', { id: propsAcao.id },
                () => {
                    // setData([])
                    getApi(setData, '/controleAcesso/usuario')
                    mostrarToast('sucesso', mensagens['sucesso'])
                })} />
    ]
    return (
        <>
            <div>
                <DataGridAlternativa data={data}
                    columns={columns}
                    caminhoBase='/controleAcesso/usuario'
                    caminhoConsultaFiltros= '/controleAcesso/usuariopaginacao'
                    setData={setData}
                    acoes={true}
                    acoesObj={maisAcoesObj}
                    url={{ cadastro: '/controleAcesso/usuario/cadastro' }}
                    modulo={props.modulo}
                    titulo={'Consulta Usuário'}
                    tituloLista={'Lista Usuário'}
                    urlCadastro='/controleAcesso/usuario/cadastro'
                    icone={'icon-Add-User'} />
            </div>
        </>
    )
}

export default ConsultaUsuario