import { InputPadrao, InputNumero, InputValor, InputSwitch } from '../../../../components/Inputs'
import { InputBanco, InputTipoConta, InputClienteFinanceiro, InputTributoEsfera } from '../../../../components/InputsAutoComplete'
import React, { useState, useEffect } from 'react'
import Yup, { validaData } from '../../../../util/Validacoes'
import { HeaderCadastro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'

const CadastroTributo = props => {
    let { id } = useParams()
    const date = new Date()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [mudouStatus, setMudouStatus] = useState(false)

    useEffect(_ => {
        if (!props.modal && id) {

            Promise.resolve(get(`/financeiro/tributo/${id}`)).then(obj => {
                setValoresIniciais(obj)
            })
        } else {
            setValoresIniciais({
                descricao: '',
                ordenamento: '',
                tributo_esfera: '',
            })
        }
    }, [id])



    const [validacao] = useState(
        Yup.lazy(({ }) => Yup.object({

            tributo_esfera: Yup.object().nullable().required(),
            ordenamento: Yup.string().nullable(),
            descricao: Yup.string().nullable().required(),

        })
        ))


    const manter = []


    const Submit = (values, propsFormik) => {
        setSalvando(true)
        salvar(values,
            '/financeiro/tributo',
            obj => {
                if (props.modal) {
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else {
                        props.setItemSelecionado({ value: obj.item_selecionado.value, label: obj.item_selecionado.label })
                        props.setShow && props.setShow(false)
                    }
                }
                else {
                    mostrarToast('sucesso', mensagens.sucesso)
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        document.getElementsByName('tributo_esfera')[0].focus()

                    }
                    else
                        props.history.push('/financeiro/tributo/consulta')
                }
            },

            (err) => {
                console.log('err: ', err);
                setSalvando(false)
            })
    }

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Financeiro</span>,
        () => <Link className='link-active' to={'/financeiro/tributo/consulta'}>Tributo</Link>,
    ]

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => {
                return (<Form>
                    <div className={props.modal && props.modal || 'card mb-20'}>
                        <div className={props.modal || 'card-body'}>
                            <div className='row'>
                                <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' />
                                <InputTributoEsfera label='Tributo Esfera' autofocus name='tributo_esfera' placeholder='Tributo Esfera' obrigatorio={true} propsFormik={propsFormik}
                                    tamanho={!props.modal && 'col-3 col-xl-3 col-sm-12'} classeTooltip={props.modal && 'tooltip-input-modal'} focarAposSubmit={true} salvarENovo={salvarENovo} />
                                <InputPadrao label='Descrição' name='descricao' type='text' placeholder='Descrição' classeTooltip={'tooltip-input-modal'} tamanho="col-6 col-xl-6 col-sm-12 " />
                                <InputPadrao label='Ordenamento' name='ordenamento' type='number' placeholder='Ordenamento' classeTooltip={'tooltip-input-modal'} tamanho="col-3 col-xl-3 col-sm-12" />

                            </div>
                        </div>
                    </div>
                    {!props.modal && <div className='separator mt-40 mb-5'></div>}
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                        {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                    </div>
                </Form>)
            }}
        </Formik>
    )
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Financeiro</span>,
        () => <Link className='link-active' to={'/financeiro/tributo/consulta'}>Tributo</Link>,
    ]
    return (<>
        {!props.modal && <HeaderCadastro titulo={"Cadastro Tributo"} link={links} />}
        <CadastroTributo {...props} />
    </>
    )
}
export { CadastroTributo }
export default Cadastro