import { removeAcentos } from '../../../../util/FuncoesComuns'
import { InputPadrao, InputSwitch} from '../../../../components/Inputs'
import { InputContrato, InputBeneficiario, InputOperadoraSaude } from '../../../../components/InputsAutoComplete'
import { HeaderCadastro } from '../../../../components/Headers'
import { ModalCadastro } from '../../../../components/Modais'
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import Yup from '../../../../util/Validacoes'
import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import Atalho from '../../../../util/Atalho'
import { get } from '../../../../util/AcessoApi'


export default props => {
    const [pesquisa, setPesquisa] = useState('')
    const [salvando, setSalvando] = useState(false)

    const [msgBotao, setMsgBotao] = useState('Adicionar')

    const emptyValues = {
        id: null,
        beneficiario: null,
        contrato: null,
        atendimento: null,
        matricula: null,
        particular: false
    }

    const [values, setValues] = useState(emptyValues)

    useEffect(() => {
        // console.log('props.arrayDados 2: ', props.arrayDados)
    }, [props.arrayDados])

    const Submit = (obj, { resetForm }) => {
        let copiaArrayDados = [...props.arrayDados]

        
        if (obj.verificador) {
            copiaArrayDados[copiaArrayDados.findIndex(x => x.verificador === obj.verificador)] = obj
            
            if (props.procedimentos && props.procedimentos.length) {
                let cloneProcedimentos = [...props.procedimentos]
                cloneProcedimentos = cloneProcedimentos.map(item => {
                    if(item.paciente && item.paciente.value === obj.item)
                        item.paciente = item.paciente && item.paciente.value ? { value: obj.item, label: obj.beneficiario.label } : item.paciente

                    return item
                })
                props.setProcedimentos(cloneProcedimentos)
            }
            
        } else {
            obj.item = copiaArrayDados.length > 0 ? copiaArrayDados[copiaArrayDados.length - 1].item + 1 : 1
            obj.verificador = Math.random()
            copiaArrayDados.push(obj)
            props.funcaoAposSalvar(obj)
        }
        
        props.setArrayDados(copiaArrayDados)
        // resetForm({})
        
        if (msgBotao === 'Alterar')
        props.setAbrirModal(!props.abrirModal)
        
        if (props.formikProcedimentos.setFieldValue){
            props.formikProcedimentos.setFieldValue('paciente', {value: obj.item, label: obj.beneficiario.label})
        }

        props.setAbrirModal(false)
    }

    const Delete = index => {
        let copiaArrayDados = [...props.arrayDados]

        if (props.procedimentos && props.procedimentos.length) {
            let cloneProcedimentos = [...props.procedimentos]
            cloneProcedimentos = cloneProcedimentos.map(item => {
                item.paciente = item.paciente && item.paciente.value && ( item.paciente.value === copiaArrayDados[index].item ) ? null : item.paciente
                return item
            })
            props.setProcedimentos(cloneProcedimentos)
        }


        copiaArrayDados.splice(index, 1);
        props.setArrayDados(copiaArrayDados)
    }

    const settarValorParticular = (propsFormik) => {
        propsFormik.values.particular = !propsFormik.values.particular
    }

    const obterDadosBeneficiario = (propsFormik, option) => {
        // propsFormik.setFieldValue("data_inicial", value)
        if(option) 
        Promise.resolve(get(`/faturamento/beneficiario/${option.value}`)).then(val2 => {
            propsFormik.setFieldValue('matricula', val2.numero_carteira || "")
            propsFormik.setFieldValue('operadora_saude', val2.operadora_saude && val2.operadora_saude.length ? val2.operadora_saude[0] : "")
        })
    }

    return (
        <>
            <div className="mt-40">
                <HeaderCadastro
                    titulo={'Pacientes Agendamento'}
                    classeChildren='space-between-100' >
                    <BotaoPadrao type='button' classe='btn-primary primary p-5rem height-auto m-r-15' texto={<><span> Adicionar</span></>}
                        click={_ => { setValues(emptyValues); props.setAbrirModal(!props.abrirModal); setMsgBotao('Adicionar') }} />
                </HeaderCadastro>
            </div>
            <div className="card mb-20">
                <div className="card-body">
                    <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                        <thead>
                            {
                                <tr>
                                    <th style={{ width: '5%' }}>#</th>
                                    <th style={{ width: '30%' }}>Paciente</th>
                                    <th style={{ width: '20%' }}>Contrato</th>
                                    <th style={{ width: '10%' }}>Atendimento</th>
                                    <th style={{ width: '10%' }}>Matricula</th>
                                    <th style={{ width: '10%' }}>Particular?</th>
                                    <th style={{ width: '15%' }} className='text-center'>Ações</th>
                                </tr>
                            }
                        </thead>
                        <tbody>
                            {
                                props.arrayDados && props.arrayDados.length ?
                                    props.arrayDados.filter(x =>
                                        removeAcentos(x.beneficiario ? x.beneficiario.label.toLowerCase() : '').includes(removeAcentos(pesquisa.toLowerCase())))
                                        .map((x, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{x.beneficiario ? x.beneficiario.label : '--'}</td>
                                                <td>{x.contrato ? x.contrato.label : '--'}</td>
                                                <td>{x.atendimento || '--'}</td>
                                                <td>{x.matricula || '--'}</td>
                                                <td>{x.particular ? 'Sim' : 'Não'}</td>
                                                <td className='text-center'>
                                                    <span className='icon-consulta icon-Pen'
                                                        onClick={_ => { setValues(x); props.setAbrirModal(true); setMsgBotao('Alterar') }} />
                                                    <span className='icon-consulta icon-New-Trash' onClick={target => Delete(index)} />
                                                </td>
                                            </tr>
                                        )
                                    : <tr><td colspan='7' className='text-center'>Nenhum Registro Cadastrado</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <ModalCadastro titulo={'Paciente Agendamento'} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    enableReinitialize={true}
                    initialValues={values}
                    validationSchema={Yup.object({
                        beneficiario: Yup.string().required().nullable(),
                        matricula: Yup.string().nullable(),
                        contrato: Yup.string().nullable(),
                        operadora_saude: Yup.string().nullable(),
                        atendimento: Yup.string().nullable()
                    })}
                    onSubmit={Submit}>
                    {propsFormik => <Form>
                        <div className='modal-body-cadastro'>
                            <InputBeneficiario label="Paciente" name="beneficiario" botaoVisivel propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} autoFocus focarAposSubmit={true} onChange={(option) => obterDadosBeneficiario(propsFormik, option)} obrigatorio />
                            
                            <InputPadrao label='Atendimento' name='atendimento' type='text' placeholder='Atendimento' classeTooltip={'tooltip-input-modal'} />

                            <InputPadrao label='Matrícula' name='matricula' type='text' placeholder='Número Carteira' classeTooltip={'tooltip-input-modal'} />

                            <InputContrato label="Contrato" name="contrato" propsFormik={propsFormik} classeTooltip={props.modal && 'tooltip-input-modal'} />

                            {propsFormik.values.particular ?  <></> : <InputOperadoraSaude label="Operadora de Saúde" name="operadora_saude" propsFormik={propsFormik} classeTooltip={props.modal && 'tooltip-input-modal'} />}

                            <InputSwitch propsFormik={propsFormik} label='Particular?' quebrarLinha classeDiv='form-group' name='particular' size="md" tamanho={'col-lg-1'} classeTooltip={'tooltip-input-modal'} />

                            <Atalho formik={propsFormik} salvando={salvando}/>
                        </div>
                        <div className='direita container-botoes'>
                            <BotaoPadrao texto={msgBotao} />
                        </div>
                    </Form>}
                </Formik>
            </ModalCadastro>
        </>)
}