import { get } from '../../../../util/AcessoApi'
import React, { useState, useEffect, useRef } from 'react'
import { InputSearch } from '../../../../components/Inputs';
import { ModalPadraoCadastro } from '../../../../components/Modais'
import { mostrarToast } from '../../../../components/Toasts';
import { distinctArr, convertValueToFloat, formatarMoeda, mensagens, useWindowSize } from '../../../../util/Utilitarios';
import { LembretesBadget, RegistroAtividadeBadget, ResumoBadget, ResumoGruposBadget } from '../../../administracao/lembrete/pages/LembreteNaAgenda';

const InformacoesMovimento = ({ idMovimento, ...props }) => {

    const [itemDetalhes, setItemDetalhes] = useState({});
    const [infoMovimento, setInfoMovimento] = useState({});
    const header = useRef(null)
    const [width, height] = useWindowSize();
    const body = document.getElementsByTagName('body')[0];
    const [className, setClassName] = useState([]);

    useEffect(() => {
        if (idMovimento)
            Promise.resolve(get('/financeiro/informacoesmovimento', { id: idMovimento }).then((item) => {
                setInfoMovimento(item && item[0])

            }))
    }, [idMovimento])

    useEffect(() => {
        setItemDetalhes(props.itemDetalhes)
    }, [props.itemDetalhes])

    let dados = {}

    dados.dados_movimento = [

        { titulo: 'Código', descricao: infoMovimento?.value },
        { titulo: 'Entidade', descricao: infoMovimento?.entidade?.label },
        { titulo: 'Categoria', descricao: infoMovimento?.categoria?.label },
        { titulo: 'Data', descricao: infoMovimento?.data },
        { titulo: 'Valor R$', descricao: infoMovimento?.valor },
        { titulo: 'Descrição', descricao: infoMovimento?.descricao },
        { titulo: 'Número Documento', descricao: infoMovimento?.numero_documento },
        { titulo: 'Conta Bancária', descricao: infoMovimento?.conta_bancaria },
        { titulo: 'Anotações', descricao: infoMovimento?.observacao },
        { titulo: 'Valor Recebido R$', descricao: infoMovimento?.total_geral },
        { titulo: 'Status', descricao: infoMovimento?.status },
        { titulo: 'Usuário Inserção', descricao: infoMovimento?.usuario },
        { titulo: 'Data Registro', descricao: infoMovimento?.data_registro },
    ]

    return (
        <>
            <div className={(className.length > 0 ? ' headerBlank ' : '')} id='headerBlank' style={{ height: header.current || className.length > 0 ? header.current.scrollHeight : 0, position: className.length === 0 ? 'absolute' : 'unset' }}></div>
            <div className={"" + className.join(' ')} id="myHeader" ref={header}>

                <div className={"col-12 row " + (className.length > 0 ? ' mr-0 ml-0 ' : '')} style={{ width: '100%' }}>
                    <div className=" pr-0 mb-2 ">
                        <div className={`pr-0 ${props.titulo.length > 26 ? 'col-lg-12' : 'col-lg-4'} col-sm-12 col-md-12 ${props.classeChildren || ''}`}>
                            <div>
                                <h4>
                                    <div>
                                        {/* <span className={"mr-2 " + (props.icone || 'icon-Calendar-3')} /> */}
                                        <span style={width < 400 ? { fontSize: '20px' } : {}}>{props.titulo}</span>
                                    </div>
                                </h4>
                            </div>
                        </div>

                    </div>


                    <div className='col-12'>
                        <div className='detalhes '>
                            <div className='item-detalhes display-flex col-12 row'>
                                {infoMovimento?.value && dados?.dados_movimento ?

                                    dados?.dados_movimento.map((item, idx) => {
                                        return (
                                            <>
                                                {/* {idx === 0 ? <></> : <hr />} */}

                                                <div className='col-4'>
                                                    <h6><b>{item.titulo}</b></h6>
                                                    {item?.titulo?.includes('Valor R$') || item?.titulo?.includes('Data') && !item?.titulo?.includes('Data Registro') || item?.titulo?.includes('Valor Recebido R$') ? <h6 ><b className='valor-indicador-certo'>{item.descricao}</b></h6> : <h6 >{item.descricao}</h6>}
                                                    {item.html}
                                                </div>

                                            </>
                                        )
                                    })
                                    : <></>
                                }
                            </div>

                        </div>
                    </div>

                    {props.textoComplemento}
                    {props.pesquisa && <InputSearch value={props.valorPesquisa} onChange={(value) => props.onChangePesquisa(value.target.value)} />}
                    <div className='lh-2 ml-3'>
                        {props.children}
                    </div>
                </div>
                {/* <div className={`header-botoes botoes-animation pl-0 ${props.titulo.length > 26 ? 'col-lg-6' : 'col-lg-8'} col-sm-12 col-md-12 text-right-md`}>
                                {props.urlCadastro ? <Link to={props.urlCadastro}><BotaoPadrao texto="Cadastrar" /></Link> : <></>}
                                {props.botoes}
                            </div> */}
                <div className={''}></div>
            </div>
            {props.modalHeader}
        </>
    )
}

const DadosAtendimento = (props) => {
    const [itemDetalhes, setItemDetalhes] = useState({});
    let { propsFormik } = props

    useEffect(() => {
        if (props.idAtendimento != 0 && props.idAtendimento != undefined) {
            Promise.resolve(get('/financeiro/dadosatendimento', { id: props.idAtendimento })).then((val) => {
                setItemDetalhes(val[(val?.length - 1) || 0])
                setarCamposLancamento(val[(val?.length - 1) || 0])
            }).catch((e) => { console.log(e) })
        } else
            setarCamposLancamento(null)
    }, [props.idAtendimento])

    const setarCamposLancamento = val => {
        if (!propsFormik.values.alterando_linha_lancamento && !propsFormik.values.user_name) {
            propsFormik.setFieldValue('cliente_lancamento', (val && val?.cliente?.value ? val?.cliente : null) || null)
            // propsFormik.setFieldValue('tipo_lancamento', val?.tipo_lancamento)
            propsFormik.setFieldValue('nota_fiscal_lancamento', val?.nota_fiscal || null)
            propsFormik.setFieldValue('tipo_documento_lancamento', val?.tipo_documento || null)
            propsFormik.setFieldValue('estabelecimento_saude', val?.estabelecimento_saude_label || null)
            propsFormik.setFieldValue('empresa', val?.empresa || null)
            propsFormik.setFieldValue('socio', distinctArr(val?.socio).join(', ') || null)
            propsFormik.setFieldValue('valor_geral_lancamento_formatado', formatarMoeda(convertValueToFloat(val?.valor_total_formatado).toFixed(2)) || null)
            propsFormik.setFieldValue('valor_geral_lancamento', convertValueToFloat(val?.valor_total) || null)
            propsFormik.setFieldValue('liquida_parcela.valor', formatarMoeda(convertValueToFloat(val?.valor_total_formatado).toFixed(2)) || null)
            propsFormik.setFieldValue('liquidacao[0].valor', formatarMoeda(convertValueToFloat(val?.valor_total_formatado).toFixed(2)) || null)
            propsFormik.setFieldValue('liquidacao[0].valor_liquidacao', convertValueToFloat(val?.valor_total_formatado).toFixed(2) || null)
            propsFormik.setFieldValue('liquidacao[0].valor_liquidacao_formatado', formatarMoeda(convertValueToFloat(val?.valor_total_formatado).toFixed(2)) || null)
            propsFormik.setFieldValue('liquidacao[0].valor_original', (convertValueToFloat(val?.valor_total).toFixed(2)) || null)
        }

    }
    let dados = {}

    dados.dados_atendimento = [

        { titulo: 'Código', descricao: itemDetalhes?.value },
        { titulo: 'Código Barras', descricao: itemDetalhes?.codigo_barras },
        { titulo: 'Data Procedimento', descricao: itemDetalhes?.data_procedimento },
        { titulo: 'Paciente', descricao: itemDetalhes?.paciente },
        { titulo: 'Estabelecimento Saude', descricao: itemDetalhes?.estabelecimento_saude },
        { titulo: 'Operadora Saude', descricao: itemDetalhes?.operadora_saude },
        { titulo: 'Sócio(s)', descricao: distinctArr(itemDetalhes?.socio).join(', ') },
        { titulo: 'Status', descricao: itemDetalhes?.status },
        { titulo: 'Valor Total', descricao: itemDetalhes?.valor_total_formatado },
    ]

    return <ModalPadraoCadastro titulo={props.titulo || 'Dados Atendimento'} showModal={props.showModal} setShowModal={props.setShowModal}>
        <div>

            {itemDetalhes?.value && dados?.dados_atendimento ?
                <div className='detalhes'>
                    {dados?.dados_atendimento.map((item, idx) => {
                        return (
                            <>
                                {idx === 0 ? <></> : <hr />}

                                <div className='item-detalhes'>
                                    <h6><b>{item.titulo}</b></h6>
                                    <h6>{item.descricao}</h6>
                                    {item.html}
                                </div>

                            </>
                        )
                    })}
                </div> :
                <>
                    <div className=''>
                        <h6 className='content-center'>Sem Registros!</h6>
                    </div>

                </>}

        </div>
    </ModalPadraoCadastro >
}
export { DadosAtendimento, InformacoesMovimento }