import React, { useState, useRef, useEffect } from 'react'
import { ModalCadastro } from './Modais'
import { BotaoPadrao } from './Botoes'
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import { mostrarToast } from '../components/Toasts'
import { post } from '../util/AcessoApi'
import { useField, useFormikContext } from "formik";
import { FiAlertTriangle } from "react-icons/fi";
import { Overlay, Tooltip } from 'react-bootstrap';
import axios from 'axios'
import ContentLoader from "react-content-loader"
const image2base64 = require('image-to-base64');

const InputCorteImagemS3 = (props) => {
    const [field, meta] = useField(props);
    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    const target = useRef(null);
    const form = useFormikContext();

    const [showModal, setShowModal] = useState(false)
    const [srcImg, setSrcImg] = useState('')
    const [imgCroppedSrc, setImgCroppedSrc] = useState('')
    const [base64Crop, setBase64Crop] = useState('')
    const refCanvas = useRef(null)
    const refImg = useRef(null)
    const refDropzone = useRef(null)
    const [urlRetorno, setUrlRetorno] = useState('')
    const [scale, setScale] = useState(1)
    const [carregando, setCarregando] = useState(true)

    useEffect(() => {

        setTimeout(() => {
            if (typeof field.value !== 'object')
                setCarregando(false)
        }, 4000)

        if (field.value && field.value.s3_key && !srcImg) {
            obterUrlImagem(field.value.s3_key)
        }
    }, [field])


    const openModal = (imagem) => {
        setSrcImg(imagem)
        setShowModal(true)
    }

    const setImage = (imagem) => {
        setSrcImg(imagem)
        upload(imagem, imagem)
        setImgCroppedSrc(true)
    }

    const handleDrop = (dropped) => {
        const image = dropped[0]
        async function read(file) {
            let Reader = new FileReader();
            Reader.onload = function (event) {
                var i = new Image();
                i.onload = function () {
                    if ((i.width === props.width) && (i.height === props.height)) {
                        setImage(image)
                    } else {
                        openModal(image)
                    }
                };
                i.src = event.target.result;
            };
            Reader.readAsDataURL(file);
        }

        read(image)
    }

    const onScaleChange = (scaleChangeEvent) => {
        const scaleValue = parseFloat(scaleChangeEvent.target.value);
        setScale(scaleValue)
    }

    const getResizedCanvas = (canvas, newWidth, newHeight) => {
        var tmpCanvas = document.createElement('canvas');
        tmpCanvas.width = newWidth;
        tmpCanvas.height = newHeight;

        var ctx = tmpCanvas.getContext('2d');
        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

        return tmpCanvas;
    }

    const cortar = () => {
        const canvas = refCanvas.current.getImageScaledToCanvas().toDataURL()
        const imgToResize = refCanvas.current.getImageScaledToCanvas()
        const nwCanvas = getResizedCanvas(imgToResize, props.width, props.height)
        nwCanvas.toBlob((retorno) => {
            upload(retorno)
        })

        if (imgCroppedSrc) {
            removerArquivo(field.value.s3_key)
        }
        setBase64Crop(canvas)
        setImgCroppedSrc(true)
        setShowModal(false)
    }

    const upload = (blob, imagem) => {
        imagem = imagem || srcImg
        props.setSalvando(true)
        Promise.resolve(post('/sistema/aws-s3', {
            fileName: imagem.name,
            fileType: imagem.type,
            fileExtension: imagem.name.split('.').pop(),
            diretorioS3: 'imagens/' + props.diretorioS3,
        })).then(val => {
            var returnData = val.data.returnData;
            var signedRequest = returnData.signedRequest;
            form.setFieldValue(field.name, { nome_arquivo: imagem.name, s3_key: returnData.fileKey })

            var options = {
                headers: {
                    'Content-Type': imagem.type
                },
                onUploadProgress: function (progressEvent) {
                }
            };

            axios.put(signedRequest, blob, options)
                .then(result => {
                    props.setSalvando(false)
                })
                .catch(error => {
                    props.setSalvando(false)
                    mostrarToast('erro', JSON.stringify(error));
                })
        }).catch((e) => mostrarToast('erro', e))
    }

    const obterTamanho = () => {
        let scala = props.width > props.height ? 300 / props.width : 300 / props.height;
        let retorno = { width: scala * props.width, height: scala * props.height, border: 0 }
        retorno.border = [(330 - retorno.width) / 2, (330 - retorno.height) / 2]
        return retorno
    }

    const limparCampo = () => {
        form.setFieldValue(field.name, '')
        setImgCroppedSrc(false)
        setUrlRetorno('')
        setBase64Crop('')
        setTimeout(() => {
            form.setFieldTouched(field.name, true)
        }, 200)
    }

    const obterUrlImagem = (s3Key) => {
        if (!s3Key)
            setCarregando(false)
        else
            Promise.resolve(post('/sistema/aws-s3-obter-arquivo', {
                fileKey: s3Key
            })).then(val => {
                setUrlRetorno(val.data.returnData)
                image2base64(val.data.returnData).then(
                    (response) => {
                        setSrcImg('data:image/png;base64,' + response);
                        setCarregando(false)
                    }
                )
                    .catch(
                        (error) => {
                            mostrarToast('erro', 'Ocorreu um erro ao carregar a imagem')
                        }
                    )
            }).catch((e) => mostrarToast('erro', e))
    }

    useEffect(() => {if (props.limparFoto)limparCampo()}, [props.limparFoto])

    const removerArquivo = (s3key) => {
        Promise.resolve(post('/sistema/aws-s3-excluir-arquivo', {
            fileKey: s3key,
        })).catch((e) => {
            mostrarToast('erro', 'Ocorreu um erro ao tentar excluir')
        })
    }

    return <>
        <div className={'form-group ' + (props.tamanho || 'col-6')}>
            <label className={props.classe}>{props.label} {props.obrigatorio != undefined ? "*" : ""}</label>
            {carregando ?
                <ContentLoader
                    speed={3}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#e4e4e4"
                >
                    <rect x="0" y="0" rx="5" ry="5" width="150" height="150" />
                </ContentLoader>
                : <Dropzone
                    onDrop={handleDrop}
                    ref={refDropzone}
                    noKeyboard
                    style={{ width: '250px', height: '250px', backgroundImage: `url(${srcImg})` }}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps(urlRetorno || base64Crop ? { onClick: event => { setShowModal(true); event.stopPropagation() } } : {})} className='container-dropzone-img'>
                            <div className='dropzone-img' ref={target}>
                                {imgCroppedSrc || urlRetorno ?
                                    <BotaoPadrao texto='x' type='button' classe='btn-remove-img primary' disabled={false} click={event => { event.stopPropagation(); limparCampo() }} />
                                    : <></>}
                                <img ref={refImg} src={base64Crop || urlRetorno || require('../assets/img/imagem_fundo.png')} alt='Alt' className={'img-result-crop image ' + (imgCroppedSrc ? '' : 'inicial')} />
                                {imgCroppedSrc ?
                                    <div className="middle">
                                        <div className="text">Alterar</div>
                                    </div>
                                    : <></>}
                            </div>
                            <input {...getInputProps()} accept='image/png, image/jpeg' />
                        </div>
                    )}
                </Dropzone>}


            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={"error-tooltip " + classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>

        <ModalCadastro show={showModal} setShowModal={setShowModal} titulo='Ajustar Imagem'>
            <div className='content-corte-imagem'>
                <div className='text-center container-corte-canvas'>
                    <AvatarEditor
                        {...obterTamanho()}
                        color={[200, 200, 200, 0.5]} // RGBA
                        scale={scale}
                        ref={refCanvas}
                        rotate={0} image={srcImg} />
                    <BotaoPadrao texto='Alterar Imagem' classe='btn-alterar-img' disabled={false} click={_ => refDropzone.current.open()} />

                </div>
                <div className='text-right mt-20'>
                    <input style={{ width: '100%' }} className='input-ranger' type="range" value={scale} name="points" min="1" max="10" onChange={onScaleChange} />
                </div>

                <div className='text-right mt-20'>
                    <BotaoPadrao texto='Cortar' disabled={false} click={_ => cortar()} />
                </div>
            </div>
        </ModalCadastro>
    </>
}

export { InputCorteImagemS3 }