import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaData } from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { get, salvar, validar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputDatePicker, InputPadrao, InputDatePickerMultiplo, InputSummernote, InputSwitch } from '../../../../components/Inputs'
import { InputSocio, InputTipoDireitoFolga } from '../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens, useWindowSize } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import Atalho from '../../../../util/Atalho'
import moment from 'moment';
import { ModalDuasOpcoes } from '../../../../components/Modais';

const CadastroDireitoFerias = (props) => {

    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao] = useState(Yup.object({
        observacao: Yup.string().trim().max(200).nullable(),
        socio: Yup.string().nullable().required(),
        data: Yup.string().required().nullable(),
        tipo_direito_folga: Yup.string().required().nullable(),

    }))
    const [dataAtual, setDataAtual] = useState(new moment().format('DD/MM/YYYY'))
    const [width, height] = useWindowSize();
    const [alteracao, setAlteracao] = useState(false)
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')

    const manter = ['data']

    const Submit = (values, propsFormik) => {
    
        setSalvando(true)
        salvar(
            values,
            '/administracao/direitofolga',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)

                if (salvarENovo) {
                    propsFormik.resetForm({})
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                }
                else
                    props.history.push('/administracao/direitofolga/consulta')
            },
            () => {
                setSalvando(false)
            })
    }

    const validarSubmit = (values, propsFormik) => {

        setSalvando(true)
        validar('/administracao/validarDireitoFolga', values, () => Submit(values, propsFormik), (e) => {
            setShowModalValidacao(true)
            console.log('E', e.response.data)
            setMensagemModal(e.response.data.alertas[0])
            setSalvando(false)
        })
    }

    const funcaoAposSim = (values, propsFormik) => Submit(values, propsFormik)

    useEffect(() => {
        Promise.resolve(get(`/administracao/direitofolga/${id}`)).then(obj => {
            if (id && !obj.socio)
                props.history.push('/administracao/direitofolga/consulta')
            else if (id) {
                obj.data = obj.data_formatada
                setAlteracao(true)
                setValoresIniciais(obj)
            }
            else {
                setValoresIniciais({
                    data: '',
                    socio: '',
                    observacao: '',
                    tipo_direito_folga: ''
                })
            }
        })
    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
            {propsFormik => (<Form>
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={props.modal || 'card-body'}>
                        <div className='row'>
                            <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' obrigatorio={false} />
                            {!alteracao ?
                                <InputDatePickerMultiplo id={'input-data-marcacao'} propsFormik={propsFormik} navigation tamanho={width < 1800 ? 'col-lg-12' : 'col-lg-6 col-md-6'} label="Data" name="data" type="text" obrigatorio="true" autoFocus
                                    onChange={(data) => {
                                        //propsFormik.setFieldValue("data", data);
                                        // setDataReferencia(data[0] || '');
                                        setDataAtual(data[0] || '');
                                    }} classeTooltip={props.modal && 'tooltip-input-modal'}
                                />
                                :

                                <InputDatePicker label="Data" name="data" type="text" obrigatorio autoFocus
                                    setarValor={value => propsFormik.setFieldValue("data", value)} tamanho={'col-lg-4'} classeTooltip={props.modal && 'tooltip-input-modal'} />}

                            <InputTipoDireitoFolga label="Tipo Direito" name="tipo_direito_folga" placeholder="Tipo Direito" propsFormik={propsFormik} obrigatorio={true} tamanho={alteracao ? 'col-lg-8 col-sm-12' : 'col-lg-6 col-sm-12'} />
                            <InputSocio label="Sócio" name="socio" placeholder="Sócio" propsFormik={propsFormik} obrigatorio={true} tamanho='col-lg-10' multiplo={!alteracao} />
                            <InputSwitch label='Utilizado/Marcado' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch ' name='utilizado_marcado' size="md" tamanho={'col-lg-2'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSummernote label="Observação" placeholder='Observação' name="observacao" propsFormik={propsFormik} tamanho={"col-lg-12"} config={{ heightMin: 500 }} {...props} />

                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />

                        </div>
                    </div>
                </div>
                {!props.modal ? <div className='separator mt-40 mb-5'></div> : <></>}
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}

                </div>
                <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { funcaoAposSim(propsFormik.values, propsFormik); setShowModalValidacao(false); }} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                    esconderFechar mensagem={mensagemModal}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
            </Form>)
            }
        </Formik >
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Administrativo</Link>,
        () => <Link className="link-active" to={'/administracao/direitofolga/consulta'}>Feriados</Link>,
    ]
    return (<>
        {!props.modal && <HeaderCadastro titulo={"Cadastro de Direito Folga"} link={links} />}
        <CadastroDireitoFerias {...props} />
    </>
    )
}
export { CadastroDireitoFerias }
export default Cadastro
