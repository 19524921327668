import { InputPadrao, InputNumero, InputValor, InputSwitch, InputDecimal, InputPadraoSimples } from '../../../../components/Inputs'
import { InputBanco, InputTipoConta, InputClienteFinanceiro, InputTributoTipo, InputMunicipio, InputEstado, InputTodasCategorias, InputMunicipioGOPrimeiro } from '../../../../components/InputsAutoComplete'
import React, { useState, useEffect, useRef } from 'react'
import Yup, { validaData } from '../../../../util/Validacoes'
import { HeaderCadastro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form, FieldArray } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'
import { BtnAcao } from '../../../../components/Acoes'
import { Overlay, Tooltip } from 'react-bootstrap'
import { FiAlertTriangle } from 'react-icons/fi'

const CadastroTributoAliquota = props => {
    let { id } = useParams()
    const date = new Date()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const aliquotaRef = useRef(null)


    useEffect(_ => {
        const tributosVariaveis = [{ municipio: undefined, aliquota: undefined }]
        if (!props.modal && id) {
            Promise.resolve(get(`/financeiro/tributoaliquota/${id}`)).then(obj => {
                obj.tributo_variavel = [...(obj.tributo_variavel ? obj.tributo_variavel : []), ...tributosVariaveis];
                if (obj.tributo_variavel && obj.tributo_variavel.length > 0) {
                    obj.tributo_variavel.map((x, idx) => {
                        obj[`tributo_variavel[${idx}].aliquota`] = x.aliquota
                    })
                }
                setValoresIniciais(obj)
            })
        } else {
            setValoresIniciais({
                tributo_variavel: tributosVariaveis,
            })
        }
    }, [id])



    const [validacao] = useState(Yup.lazy(({tributo_variavel}) => Yup.object({
        descricao: Yup.string().nullable().required(),
        aliquota: Yup.string().nullable().required(),
        tributo_tipo: Yup.object().nullable().required(),
    }).shape({
        tributo_variavel: Yup.array()
            .of(
                Yup.object().shape({
                    municipio: Yup.object().nullable().test('Município', 'Campo Obrigatório!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '');
                        return tributo_variavel[index]?.aliquota ? value : true
                    }),
                    aliquota: Yup.string().nullable().test('Alíquota', 'Campo Obrigatório!', function (value) {
                        const index = this.path.replace(/[^0-9]+/g, '');
                        return tributo_variavel[index]?.municipio ? value : true
                    }),
                })
            )
    })))

    const manter = []

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        salvar(values,
            '/financeiro/tributoaliquota',
            obj => {
                if (props.modal) {
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else {
                        props.setItemSelecionado({ value: obj.item_selecionado.value, label: obj.item_selecionado.label })
                        props.setShow && props.setShow(false)
                    }
                }
                else {
                    mostrarToast('sucesso', mensagens.sucesso)
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                    }
                    else
                        props.history.push('/financeiro/tributoaliquota/consulta')
                }
            },

            (err) => {
                console.log('err: ', err);
                setSalvando(false)
            })
    }

    const removerLinha = (arrayHelpers, propsFormik, index) => {

        const arrayProced = propsFormik.values.tributo_variavel;
        if (arrayProced.length === 1) {
            propsFormik.setFieldValue('tributo_variavel', [{ municipio: undefined, aliquota: undefined }])
        } else {
            if (index + 1 !== arrayProced.length)
                arrayHelpers.remove(index)

            if (arrayProced.length === 1 && arrayProced[index].municipio && arrayProced[index].municipio.value)
                arrayHelpers.insert(propsFormik.values.tributo_variavel.length, { municipio: undefined, aliquota: undefined })
        }
    }

    const adicionarLinha = (arrayHelpers, propsFormik, index) => {
        if (propsFormik.values.tributo_variavel.length - 1 === index)
            arrayHelpers.insert(propsFormik.values.tributo_variavel.length, { municipio: undefined, aliquota: undefined })
    }

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => {
                return (<Form>
                    <div className={'card mb-20'}>
                        <div className={'card-body'}>
                            <div className='row'>
                                <InputPadrao
                                    label='Descrição'
                                    name='descricao'
                                    type='text'
                                    placeholder='Descrição'
                                    propsFormik={propsFormik}
                                    tamanho={'col-lg-4'}
                                    obrigatorio={true} autoFocus />

                                <div ref={aliquotaRef} className='col-xl-2 mb-2'>
                                    <InputDecimal label='Alíquota (%)' casasDecimais={2} step="-1" propsFormik={propsFormik} name='aliquota' classDiv={'mb-0'} max={100} obrigatorio={true} />
                                    {propsFormik.errors.aliquota ?
                                        <Overlay target={aliquotaRef.current} show={true} placement="bottom">
                                            {props2 => {
                                                return (
                                                    <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                                        <div className="error">
                                                            <FiAlertTriangle className="icone" /> <span>{propsFormik.errors.aliquota}</span></div>
                                                    </Tooltip>)
                                            }}
                                        </Overlay> : <></>}
                                </div>

                                <InputValor label='Limite Isenção (R$)' name='limite_isencao' placeholder='R$ 0,00' classDiv={'col-lg-2'} tamanho={'col-lg-2'} propsFormik={propsFormik} />

                                <InputTodasCategorias label='Categoria' name='categoria' placeholder='Categoria' propsFormik={propsFormik} tamanho={'col-lg-4'} />

                                <InputTributoTipo label='Tipo Tributo' name='tributo_tipo' placeholder='Tipo Tributo' obrigatorio={true} propsFormik={propsFormik}
                                    tamanho={'col-lg-4'} />

                                <InputSwitch label='Tributo Variável' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='variavel' size="md" tamanho={'col-lg-1'} />

                                <InputSwitch label='Incide Folha' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch-operadora ' name='incide_folha' size="md" tamanho={'col-lg-1'} />
                            </div>
                        </div>
                    </div>
                    {propsFormik.values.variavel ?
                        <>
                            <div className="mt-40">
                                <HeaderCadastro
                                    titulo={'Tributo Variável'}
                                    classeChildren='space-between-100'>
                                </HeaderCadastro>
                            </div>
                            <div className="card mb-20 scroll-x">
                                <div className="card-body  executantes-internos-float">
                                    <FieldArray name="tributo_variavel">
                                        {(arrayHelpers => (
                                            <>
                                                {propsFormik.values.tributo_variavel && propsFormik.values.tributo_variavel.length > 0 ?
                                                    propsFormik.values.tributo_variavel.map((item, index) => <div className='modal-body-cadastro row mr-0 ml-0'>

                                                        <div className='row col-sm-11 col-xl-10 col-lg-6 col-md-8'>
                                                            <div className={"form-group col-1 pl-0 text-center"}>
                                                                <label htmlFor={props.id || props.name}>{index === 0 ? '#' : undefined}</label>
                                                                <div className={'text-center vertical-center' + (index === 0 ? ' mt-3' : '')}><span>{index + 1}</span></div>
                                                            </div>
                                                            <div className={"row col-sm-11 col-xl-11 col-lg-6 col-md-8 mr-0 pr-0"}>
                                                                <InputMunicipioGOPrimeiro tamanho={"col-8"} label={index === 0 ? 'Município - UF' : undefined} name={`tributo_variavel[${index}].municipio`} placeholder='Município - UF' obrigatorio={true}
                                                                    propsFormik={propsFormik} onChange={(val) => { propsFormik.setFieldValue(`tributo_variavel[${index}].municipio`, val); adicionarLinha(arrayHelpers, propsFormik, index) }} />
                                                                
                                                                <div id={`div-aliquota-${index}`} className='col-4 mb-2'>
                                                                    <InputDecimal label={index === 0 ? 'Alíquota (%)' : undefined} placeholder='Alíquota(%)' casasDecimais={2} propsFormik={propsFormik} name={`tributo_variavel[${index}].aliquota`} onBlur={(a) => {
                                                                        propsFormik.setFieldValue(`tributo_variavel[${index}].aliquota`, a.target.value);
                                                                    }} classDiv={'mb-0'} obrigatorio />

                                                                    {propsFormik.errors?.tributo_variavel && propsFormik.errors.tributo_variavel[index]?.aliquota ?
                                                                        <Overlay target={document.getElementById(`div-aliquota-${index}`)} show={true} placement="bottom">
                                                                            {props2 => {
                                                                                return (
                                                                                    <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                                                                        <div className="error">
                                                                                            <FiAlertTriangle className="icone" /> <span>{propsFormik.errors.tributo_variavel[index]?.aliquota}</span></div>
                                                                                    </Tooltip>)
                                                                            }}
                                                                        </Overlay> : <></>}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='col-2 mb-0 pr-0 pl-1 pb-3 content-bottom-center justify-left'>
                                                            <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, propsFormik, index)} />
                                                        </div>
                                                    </div>)
                                                    :
                                                    <></>}
                                            </>
                                        ))}
                                    </FieldArray>
                                </div>
                            </div>
                        </>
                        : <></>}

                    <div className='separator mt-40 mb-5'></div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                        {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                    </div>
                </Form>)
            }}
        </Formik>
    )
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Financeiro</span>,
        () => <Link className='link-active' to={'/financeiro/tributoaliquota/consulta'}>Tributo Alíquota</Link>,
    ]
    return (<>
        {!props.modal && <HeaderCadastro titulo={"Cadastro Tributo Alíquota"} link={links} />}
        <CadastroTributoAliquota {...props} />
    </>
    )
}
export { CadastroTributoAliquota }
export default Cadastro