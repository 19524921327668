import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'

const VisualizacaoUsuario = (props) => {
    let { id } = useParams()
    const [dados, setDados] = useState()

    useEffect(_ => {
        if (id) {
            visualizar('/controleAcesso/grupo', id,
                grupo => {
                    let length = 0

                    grupo.modulos &&
                        grupo.modulos
                            .forEach(x => x.acoes
                                .forEach(y => y.idPermissao && y.idPermissao.toString().length > length ? length = y.idPermissao.toString().length : length = length))
                    setDados(grupo && {
                        id,
                        modulo: props.modulo,
                        titulo: 'Visualização de Grupo',
                        grupos: [
                            {
                                titulo: 'Dados Gerais',
                                colunas: [
                                    {
                                        titulo: 'Código',
                                        descricao: grupo.id,
                                        tamanho: 'col-lg-4'
                                    },
                                    {
                                        titulo: 'Descrição',
                                        descricao: grupo.descricao,
                                        tamanho: 'col-lg-4'
                                    },
                                    {
                                        titulo: 'Data de Registro',
                                        descricao: grupo.data_registro,
                                        tamanho: 'col-lg-4'
                                    }
                                ]
                            },
                            {
                                tipoGrupo: 'tabela',
                                titulo: 'Permissões',
                                thead:
                                    <tr>
                                        <td className='coluna-2'>Código</td>
                                        <td className='coluna-5'>Módulo</td>
                                        <td className='coluna-5'>Ação</td>
                                    </tr>,
                                tbody:
                                    grupo.modulos
                                    && grupo.modulos.map(x => x.acoes.filter(y => y.permitido)).filter(x => x.length).length
                                    && grupo.modulos.map(x => x.acoes.filter(y => y.permitido).map(y => {
                                        return (
                                            <tr>
                                                <td>{y.idPermissao.toString().padStart(length, '0')}</td>
                                                <td>{x.descricao}</td>
                                                <td>{y.descricao}</td>
                                            </tr>)
                                    }))
                            }
                        ]
                    } || { titulo: 'Visualização de Grupo' })
                })
        }
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default VisualizacaoUsuario