import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar, get } from '../../../../util/AcessoApi'
import { ordenarArrayObj, downloadArquivoS3 } from '../../../../util/FuncoesComuns'
import { formatarMoeda } from '../../../../util/Utilitarios';
import { ModalPadraoCadastro } from '../../../../components/Modais'
import { VisualizarErro } from '../../../../components/Acoes'
import { FaTimes } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
var format = require('xml-formatter');

const VisualizacaoGuia = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    const obterHistoricos = async () => {
        var historicos = {
            historico_socios_coop: [],
            historico_socios_equipe: []
        };
        await Promise.resolve(get(`/faturamento/historicoSocioCoop/${id}`)).then(val => {
            historicos['historico_socios_coop'] = val;
        })

        await Promise.resolve(get(`/faturamento/historicoSocioEquipe/${id}`)).then(val => {
            historicos['historico_socios_equipe'] = val;
        })

        return historicos;
    }

    useEffect(async () => {
        let object = await obterHistoricos();

        visualizar('/faturamento/guiaalteracao', id,
            obj => {
                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Guia',
                    grupos: [
                        {
                            titulo: 'Dados Atuais Guia',
                            colunas: [
                                { titulo: 'Codigo', descricao: obj.id, tamanho: 'col-lg-3' },
                                {
                                    titulo: 'Status', descricao: obj.status?.descricao, tamanho: 'col-lg-3', htmlDireto: <div className='guia-legenda'>
                                        <span className='legenda' style={{ background: obj.status.cor && obj.status.cor }}>{obj.status?.descricao}</span>
                                    </div>
                                },
                                { titulo: 'Lote', descricao: obj.lote?.descricao_lote, tamanho: 'col-lg-3' },
                                {
                                    titulo: 'Status Lote', descricao: obj?.lote?.lote_status, tamanho: 'col-lg-3', htmlDireto: <div className='guia-legenda'>
                                        <span className='legenda' style={{ background: obj?.lote?.cor_lote && obj?.lote?.cor_lote }}>{obj?.lote?.lote_status}</span>
                                    </div>
                                },
                                { titulo: 'Data e Hora Inserção', descricao: obj.data_registro, tamanho: 'col-lg-3' },
                                { titulo: 'Usuário Inserção', descricao: obj.usuario_insercao, tamanho: 'col-lg-3' },
                                { titulo: 'Protocolo Envio', descricao: obj.protocolo, tamanho: 'col-lg-3' },
                                { titulo: 'Data e Hora Envio', descricao: obj.data_envio_xml, tamanho: 'col-lg-3' },



                            ]
                        },
                        {
                            titulo: 'Dados Gerais Guia',
                            colunas: [
                                { titulo: 'Código Barras', descricao: obj.codigo_barras, tamanho: 'col-lg-4' },
                                { titulo: 'Data Procedimento', descricao: obj.data_procedimento, tamanho: 'col-lg-4' },
                                { titulo: 'Empresa', descricao: obj.empresa?.label, tamanho: 'col-lg-4' },
                                { titulo: 'Operadora de Saúde', descricao: obj.operadora_saude?.label, tamanho: 'col-lg-4' },
                                { titulo: 'Estabelecimento de Saúde', descricao: obj.estabelecimento_saude?.label, tamanho: 'col-lg-4' },
                                { titulo: 'Guia Atribuída pela operadora(Guia Principal)', descricao: obj.guia_principal, tamanho: 'col-lg-4' },
                                { titulo: 'Senha', descricao: obj.senha, tamanho: 'col-lg-4' },
                                { titulo: 'Carteira/Matrícula', descricao: obj.matricula, tamanho: 'col-lg-4' },
                                { titulo: 'Cartão Nacional de Saúde', descricao: obj.cartao_sus, tamanho: 'col-lg-4' },
                                { titulo: 'Nome Social', descricao: obj.nome_social, tamanho: 'col-lg-4' },
                                { titulo: 'Nome Beneficiário', descricao: obj?.nome_beneficiario, tamanho: 'col-lg-4' },
                                { titulo: 'Data Nascimento', descricao: obj.data_nascimento, tamanho: 'col-lg-4' },
                                { titulo: 'Acomodação', descricao: obj.acomodacao?.label, tamanho: 'col-lg-4' },
                                { titulo: 'Hora Inicial', descricao: obj.hora_inicial, tamanho: 'col-lg-4' },
                                { titulo: 'Hora Final', descricao: obj.hora_final, tamanho: 'col-lg-4' },
                                { titulo: 'Cirurgião', descricao: obj.cirurgiao?.label, tamanho: 'col-lg-4' },
                                { titulo: 'Atendimento RN', descricao: obj.atendimento_rn, tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Atendimento Guia', descricao: obj.tipo_atendimento?.label, tamanho: 'col-lg-4' },
                                { titulo: 'Sequência Hospital', descricao: obj.sequencia_hospital, tamanho: 'col-lg-4' },
                                { titulo: 'Observação', descricao: obj.observacao, tamanho: 'col-lg-12' },
                                { titulo: 'Data Entrega', descricao: obj.data_entrega, tamanho: 'col-lg-4' },
                            ]
                        },
                        {
                            titulo: 'Informações TISS',
                            visivel: obj.tipo_guia === null ? false : obj.tipo_guia && obj.tipo_guia === 2 ? true : false,
                            colunas: [
                                {
                                    titulo: 'Tipo Guia',
                                    descricao: obj.tipo_guia && obj.tipo_guia,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Número Guia Prestador',
                                    descricao: obj.numero_prestador,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Número Guia Solicitação Internação',
                                    descricao: obj.numero_internacao,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Início Faturamento',
                                    descricao: obj.inicio_faturamento,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Fim Faturamento',
                                    descricao: obj.fim_faturamento,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Motivo Encerramento',
                                    descricao: obj.motivo_encerramento,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Indicador Acidente',
                                    descricao: obj.indicador_acidente,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Tipo Faturamento',
                                    descricao: obj.tipo_faturamento,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Tipo Internação',
                                    descricao: obj.tipo_internacao,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Regime Internação',
                                    descricao: obj.regime_internacao,
                                    tamanho: 'col-lg-3'
                                },
                            ]
                        },
                        {
                            titulo: 'Informações TISS',
                            visivel: obj.tipo_guia_id === null ? false : obj.tipo_guia_id && obj.tipo_guia_id === 3 ? true : false,
                            colunas: [
                                {
                                    titulo: 'Tipo Guia',
                                    descricao: obj.tipo_guia && obj.tipo_guia,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Número Guia Prestador',
                                    descricao: obj.numero_prestador,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Número Guia Principal',
                                    descricao: obj.numero_principal_sadt,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Tipo Atendimento',
                                    descricao: obj.tipo_atendimento_tiss,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Indicação Acidente',
                                    descricao: obj.indicador_acidente,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Tipo Consulta',
                                    descricao: obj.tipo_consulta,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Motivo Encerramento',
                                    descricao: obj.motivo_encerramento,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Regime Atendimento',
                                    descricao: obj.regime_atendimento,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Saúde Ocupacional',
                                    descricao: obj.saude_ocupacional,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Indicador Cobertura Especial',
                                    descricao: obj.cobertura_especial,
                                    tamanho: 'col-lg-3'
                                },

                            ]
                        },
                        {
                            titulo: 'Informações TISS',
                            visivel: obj.tipo_guia_id === null ? false : obj.tipo_guia_id && obj.tipo_guia_id === 4 ? true : false,
                            colunas: [
                                {
                                    titulo: 'Tipo Guia',
                                    descricao: obj.tipo_guia && obj.tipo_guia,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Número Guia Prestador',
                                    descricao: obj.guia_tiss && obj.guia_tiss.numero_prestador,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Número Guia Solicitação Internação',
                                    descricao: obj.guia_tiss && obj.guia_tiss.numero_internacao,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Início Faturamento',
                                    descricao: obj.guia_tiss && obj.guia_tiss.inicio_faturamento,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Fim Faturamento',
                                    descricao: obj.guia_tiss && obj.guia_tiss.fim_faturamento,
                                    tamanho: 'col-lg-3'
                                },

                            ]
                        },
                        {
                            titulo: 'Informações TISS',
                            visivel: obj.tipo_guia_id === null ? false : obj.tipo_guia_id && obj.tipo_guia_id === 1 ? true : false,
                            colunas: [
                                {
                                    titulo: 'Tipo Guia',
                                    descricao: obj.tipo_guia && obj.tipo_guia,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Número Guia Prestador',
                                    descricao: obj.guia_tiss && obj.guia_tiss.numero_prestador,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Indicação Acidente',
                                    descricao: obj.guia_tiss && obj.guia_tiss.indicacao_acidente,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Indicador Cobertura Especial',
                                    descricao: obj.guia_tiss && obj.guia_tiss.indicador_cobertura_especial,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Regime Atendimento',
                                    descricao: obj.guia_tiss && obj.guia_tiss.regime_atendimento,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Saúde Ocupacional',
                                    descricao: obj.guia_tiss && obj.guia_tiss.saude_ocupacional,
                                    tamanho: 'col-lg-3'
                                },
                                {
                                    titulo: 'Tipo Consulta',
                                    descricao: obj.guia_tiss && obj.guia_tiss.tipo_consulta,
                                    tamanho: 'col-lg-3'
                                },

                            ]
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Procedimento(s)',
                            visivel: obj.procedimentos.length > 0,
                            aposGrupo: <tr>
                                <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={13}>{formatarMoeda(obj.procedimentos.map(item => item.valor_total).reduce((total, num) => parseFloat(total) + parseFloat(num)))}</td>
                            </tr>,
                            thead:
                                <tr>
                                    <td className='coluna-1' colspan="1">Código</td>
                                    <td className='coluna-3' colspan="3">Procedimento</td>
                                    <td className='coluna-1' colspan="1">Porte</td>
                                    <td className='coluna-1' colspan="1">Valor</td>
                                    <td className='coluna-1' colspan="1">Acm(%)</td>
                                    <td className='coluna-1' colspan="1">Perc(%)</td>
                                    <td className='coluna-1' colspan="1">Red/Acrésc.</td>
                                    <td className='coluna-1' colspan="1">Urg.</td>
                                    <td className='coluna-1' colspan="1">Qtde.</td>
                                    <td className='coluna-3' colspan="3" style={{ textAlign: 'right' }}>Valor Total</td>
                                </tr>,
                            tbody: obj?.procedimentos == undefined || obj?.procedimentos.length < 1 || obj?.procedimentos.filter(x => x.id !== null).length === 0 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :

                                obj?.procedimentos.map(x => {
                                    return (
                                        <tr>
                                            <td colspan="1">{x?.codigo}</td>
                                            <td colspan="3">{x?.procedimento?.label}</td>
                                            <td colspan="1">{x?.porte_anestesico}</td>
                                            <td colspan="1">{x?.valor}</td>
                                            <td colspan="1">{x?.porcentagem_acm}</td>
                                            <td colspan="1">{x?.porcentagem?.label}</td>
                                            <td colspan="1">{x?.reducao_acrescimo && x?.reducao_acrescimo.replace('.', ',') + '%'}</td>
                                            <td colspan="1">{x?.urgencia === true ? x?.urgencia_porcentagem.replace('.', ',') + '%' : ''}</td>
                                            <td colspan="1">{x?.quantidade}</td>
                                            <td colspan="3" style={{ textAlign: 'right' }}>{x?.valor_total && formatarMoeda(x?.valor_total)}</td>
                                        </tr>)
                                })
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Arquivo(s)',
                            thead:
                                <tr>
                                    <td className='coluna-6'>Nome</td>
                                    <td className='coluna-5'>Tamanho</td>
                                    <td className='coluna-1 d-none-print'>Download</td>
                                </tr>,
                            tbody: obj.arquivo == undefined || obj.arquivo.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                ordenarArrayObj(obj.arquivo, 'nome').map(arquivo =>
                                    <tr>
                                        <td>{arquivo.name}</td>
                                        <td>{arquivo.sizeReadable}</td>
                                        <td className="d-none-print"><div className="icone"><button className="btn-acao icon-Download-fromCloud" onClick={() => downloadArquivoS3(arquivo.s3Key)}></button></div></td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Identificação do(s) Profissional(is) Executante(s) Cooperativa',
                            thead:
                                <tr>
                                    {/* <td className='coluna-1'>Item Ref.</td> */}
                                    <td className='coluna-6'>Sócio</td>
                                    <td className='coluna-2'>Tipo Pessoa</td>
                                    <td className='coluna-3'>Empresa</td>

                                </tr>,
                            tbody: obj.executantes_externos == undefined || obj.executantes_externos.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.executantes_externos.filter(x => x.id).map(x =>
                                    <tr>
                                        {/* <td>{x.proced_ref || ''}</td> */}
                                        <td><b style={{ color: '#1E90FF' }}>{x.executante?.label}</b></td>
                                        <td style={{ color: '#1E90FF' }}>{x.tipo_pessoa?.label}</td>
                                        <td style={{ color: '#1E90FF' }}>{x.empresa?.label}</td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Identificação do(s) Profissional(is) Executante(s) Equipe',
                            thead:
                                <tr>
                                    {/* <td className='coluna-6'>Item Ref.</td> */}
                                    <td className='coluna-2'>Data</td>
                                    <td className='coluna-2'>Hora Inicial</td>
                                    <td className='coluna-2'>Hora Final</td>
                                    <td className='coluna-3'>Sócio</td>
                                    <td className='coluna-2'>Tipo Atendimento</td>
                                    <td className='coluna-3'>Empresa</td>

                                </tr>,
                            tbody: obj.executantes_internos == undefined || obj.executantes_internos.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.executantes_internos.filter(x => x.id).map(x =>
                                    <tr>
                                        <td style={{ color: '#1E90FF' }}>{x.data}</td>
                                        <td style={{ color: '#1E90FF' }}>{x.hora_inicial}</td>
                                        <td style={{ color: '#1E90FF' }}>{x.hora_final}</td>
                                        <td><b style={{ color: '#1E90FF' }}>{x.executante?.label}</b></td>
                                        <td style={{ color: '#1E90FF' }}>{x.tipo_atendimento?.label}</td>
                                        <td style={{ color: '#1E90FF' }}>{x.empresa?.label}</td>
                                    </tr>)
                        },
                        {
                            titulo: 'Lote',
                            visivel: obj.guia_lote_id ? true : false,
                            colunas: [
                                { titulo: 'Codigo', descricao: obj.guia_lote_id, tamanho: 'col-lg-3' },
                                { titulo: 'Número do Lote', descricao: obj.lote?.numero_lote, tamanho: 'col-lg-3', cor: obj.cor },
                                { titulo: 'Empresa', descricao: obj.lote?.empresa_lote, tamanho: 'col-lg-3' },
                                { titulo: 'Referência', descricao: obj.lote?.referencia_lote, tamanho: 'col-lg-3' },
                                { titulo: 'Status do Lote', descricao: obj.lote?.lote_status, tamanho: 'col-lg-3' },
                                { titulo: 'Op. de Sáude', descricao: obj.lote?.operadora_saude_lote, tamanho: 'col-lg-3' },

                            ]
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Mapa de Horas',
                            thead:
                                <tr>
                                    {/* <td className='coluna-6'>Item Ref.</td> */}
                                    <td className='coluna-1'>Codigo</td>
                                    <td className='coluna-1'>Referência</td>
                                    <td className='coluna-2'>Anotações</td>
                                    <td className='coluna-2'>Data Registro</td>

                                </tr>,
                            tbody: obj.mapa_horas == undefined || obj.mapa_horas.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.mapa_horas.filter(x => x.codigo).map(x =>
                                    <tr>
                                        <td >{x.codigo}</td>
                                        <td >{x.referencia}</td>
                                        <td >{x.anotacoes}</td>
                                        <td >{x.data_registro}</td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'NFSe Emitida(s)',
                            thead:
                                <tr>
                                    <td className='coluna-1'>Número</td>
                                    <td className='coluna-2'>Data Emissão</td>
                                    <td className='coluna-3 '>Tomador/Cliente</td>
                                    <td className='coluna-3 '>Prestador/Empresa</td>
                                    <td className='coluna-3 '>Valor Nota</td>
                                </tr>,
                            tbody: obj.nota_fiscal_emitida == undefined || obj.nota_fiscal_emitida.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                ordenarArrayObj(obj.nota_fiscal_emitida, 'codigo').map(item =>
                                    <tr>
                                        <td>{item.numero}</td>
                                        <td>{item.data_emissao}</td>
                                        <td>{item.tomador}</td>
                                        <td>{item.prestador}</td>
                                        <td>{item.valor_nota}</td>
                                    </tr>)
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Histórico de Sócios Cooperativa',
                            thead:
                                <tr>
                                    <td className='coluna-3'>Sócio</td>
                                    <td className='coluna-2'>Usuário</td>
                                    <td className='coluna-1'>Data Registro</td>
                                </tr>,
                            tbody: object.historico_socios_coop?.length ?
                                object.historico_socios_coop.map(x =>
                                    <tr>
                                        <td>{x.socio}</td>
                                        <td>{x.usuario}</td>
                                        <td>{x.data_registro}</td>
                                    </tr>) : (<><tr>
                                        <td colspan="3">Sem Registro!</td>
                                    </tr></>)
                        }, {
                            tipoGrupo: 'tabela',
                            titulo: 'Histórico de Sócios Equipe',
                            thead:
                                <tr>
                                    <td className='coluna-3'>Sócio</td>
                                    <td className='coluna-2'>Usuário</td>
                                    <td className='coluna-1'>Data Registro</td>
                                </tr>,
                            tbody: object.historico_socios_equipe?.length ?
                                object.historico_socios_equipe.map(x =>
                                    <tr>
                                        <td>{x.socios.map(socio => <><p>{`${socio.sigla ? socio.sigla + ' - ' : ''}${socio.socio}`}</p></>)}</td>
                                        <td>{x.usuario}</td>
                                        <td>{x.data_registro}</td>
                                    </tr>) : (<><tr>
                                        <td colspan="3">Sem Registro!</td>
                                    </tr></>)
                        }
                    ]
                } : { titulo: 'Visualização Guia' })

            })
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

const VisualizacaoRespostaEnvio = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    const mensagens = obj => {
        let arrayVisualizacao = []

        if (obj.guias.length) {
            obj.guias.map((x, idx) => {
                arrayVisualizacao.push({
                    tipoGrupo: 'tabela',
                    titulo: 'Guia ' + x.id_guia,
                    thead:
                        <tr>
                            <td className='coluna-1'>Código</td>
                            <td className='coluna-5'>Mensagem</td>
                        </tr>,
                    tbody:
                        x.mensagens && x.mensagens.sort().map((y, idy) =>
                            <tr>
                                <td className=''>{y.codigo}</td>
                                <td className=''>{y.mensagem}</td>
                            </tr>)
                })
            })
        }

        return arrayVisualizacao
    }

    useEffect(() => {
        visualizar('/faturamento/envioXmlObterListaRetornoGuia', id, obj => {
            obj.guias = obj;
            setDados(obj && obj[0] && obj[0].id_envio ? {
                id,
                modulo: props.modulo,
                titulo: 'Visualização de Retorno XML',
                grupos: [
                    {
                        titulo: 'Dados Envio Guia',
                        colunas: [
                            { titulo: 'Guia', descricao: obj[0].id_guia, tamanho: 'col-lg-3' },
                            { titulo: 'Status Envio', descricao: obj[0].status_guia, tamanho: 'col-lg-3' },
                            { titulo: 'Último Envio', descricao: obj[0].data_registro, tamanho: 'col-lg-3' },
                            { titulo: 'Quantidade Guias', descricao: 1, tamanho: 'col-lg-3' },
                            { titulo: 'Usuário', descricao: obj[0].nome, tamanho: 'col-lg-3' },
                        ]
                    }
                ].concat(mensagens(obj))
            } : { titulo: 'Visualização de Retorno XML' })
        })
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

const VisualizacaoErro = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [item, setItem] = useState(null)
    const [dados, setDados] = useState([])

    useEffect(() => {
        if (item)
            Promise.resolve(get('/faturamento/envioXmlObterListaRetorno', item)).then(val => {
                setDados(val);
            })
    }, [item])

    useEffect(() => {
        if (!showModal) {
            setDados([]);
            setItem(null)
        }
    }, [showModal])

    return <>
        <VisualizarErro action={() => { setShowModal(true); props.action(setItem) }} />
        <ModalPadraoCadastro titulo={props.titulo || 'Visualização de Erro'} showModal={showModal} setShowModal={setShowModal}>
            <div>
                {
                    item && item.id_lote ? <div className='col-hora row-space-between pl-2 pr-2 mb-2'>
                        <span><b>Lote: </b>{item.id_lote}</span>
                        <span><b>Data Envio: </b>{dados && dados.length > 0 ? dados[0].data_registro : '--'}</span>
                    </div> : <></>
                }

                <ul className='lista-modal'>
                    {dados && dados.map((x, index) =>
                        <li className="mb-1">
                            <div className='col-hora row-space-between'>
                                <span className={''}><b>{"Guia " + x.id_guia}</b></span> <span className='span-hora'>{x.data_registro}</span>
                            </div>
                            {dados[index].mensagens && dados[index].mensagens.map((y, idy) => <>
                                {idy > 0 ? <hr /> : <></>}
                                <div className='mt-2'>
                                    <i><p><span className='color-red'><FaTimes /></span> {(y.codigo && y.codigo.length ? <><b>{y.codigo + ' - '}</b> <>{y.mensagem}</></> : <>{y.mensagem}</>)}</p></i>
                                </div>
                            </>)}
                        </li>)}
                </ul>
                {!dados || dados.length === 0 ? <div className='row'><div className='col-12 text-center color-gray'><span>Nenhum registro encontrado</span></div></div> : <></>}

            </div>
        </ModalPadraoCadastro>

    </>
}

const VisualizacaoArquivoXml = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/faturamento/obterXmlGuia', id, obj => {
            setDados(obj && obj.id ? {
                id,
                modulo: props.modulo,
                titulo: 'Visualização de XML Gerado',
                grupos: [
                    {
                        titulo: 'Dados Guia',
                        colunas: [
                            { titulo: 'Guia', descricao: obj.id, tamanho: 'col-lg-4' },
                            { titulo: 'Status Envio', descricao: obj.status, tamanho: 'col-lg-4' },
                            { titulo: 'Ultimo Envio', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                            { titulo: 'Usuário Envio', descricao: obj.nome, tamanho: 'col-lg-4' }
                        ]
                    },
                    {
                        titulo: 'XML Guia',
                        colunas: [
                            {
                                titulo: 'XML', descricao: <div style={{ whiteSpace: 'pre-wrap' }}>{obj.xml_envio ? format(obj.xml_envio, {
                                    indentation: '   ',
                                    collapseContent: true,
                                    lineSeparator: '\n'
                                }) : 'Nenhum XML encontrado'}</div>, tamanho: 'col-lg-12'
                            }
                        ]
                    }
                ]
            } : { titulo: 'Visualização de XML Gerado' })
        })
    }, [id])


    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default VisualizacaoGuia
export { VisualizacaoErro, VisualizacaoRespostaEnvio, VisualizacaoArquivoXml }