import React, { useCallback, useEffect, useState } from 'react';
import { convertValueToFloat, formatarMoeda } from '../../util/Utilitarios';
import { TableVirtuoso } from 'react-virtuoso';
import MensagemPadraoRelatorio from '../../components/RelatorioEspecifico/RelatorioSemDados'
import { BrowserRouter, Link } from 'react-router-dom'
import { useSortBy } from 'react-table';
import { getDistinctPropertyValues, limitarCaracteres } from '../../util/FuncoesComuns';

const relatorioFluxoCaixa = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {

    return (
        array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {

            const idxCategoriaColuna = arrayConfigCol[idx].findIndex(y => y.coluna == 'categoria');
            const idxJaneiro = arrayConfigCol[idx].findIndex(y => y.coluna == 'janeiro');
            const idxFevereiro = arrayConfigCol[idx].findIndex(y => y.coluna == 'fevereiro');
            const idxMarco = arrayConfigCol[idx].findIndex(y => y.coluna == 'marco');
            const idxAbril = arrayConfigCol[idx].findIndex(y => y.coluna == 'abril');
            const idxMaio = arrayConfigCol[idx].findIndex(y => y.coluna == 'maio');
            const idxJunho = arrayConfigCol[idx].findIndex(y => y.coluna == 'junho');
            const idxJulho = arrayConfigCol[idx].findIndex(y => y.coluna == 'julho');
            const idxAgosto = arrayConfigCol[idx].findIndex(y => y.coluna == 'agosto');
            const idxSetembro = arrayConfigCol[idx].findIndex(y => y.coluna == 'setembro');
            const idxOutubro = arrayConfigCol[idx].findIndex(y => y.coluna == 'outubro');
            const idxNovembro = arrayConfigCol[idx].findIndex(y => y.coluna == 'novembro');
            const idxDezembro = arrayConfigCol[idx].findIndex(y => y.coluna == 'dezembro');

            return (<>
                {x.data.map((item, index) => {
                    return <>
                        {index > 0 ? htmlHeader : <></>}
                        <table className='table-agrupamento mb-2'>
                            <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                <td className={`bold td-data text-left`} style={{ width: '45%' }}>{item.empresa} </td>
                            </tr>
                        </table>

                        <table className='table-agrupamento mb-2'>
                            <tr className={'tr-data group-title bold '}>
                                <td className={`bold td-data text-left`} style={{ width: '45%' }}>RECEITA</td>
                            </tr>
                        </table>

                        <table style={{ width: '100%', textAlign: 'center', fontSize: '0.6rem' }} className='table-agrupamento mb-2'>
                            <thead>
                                <tr className={'titulo-1'}>
                                    <td className='bold td-data' width={'10%'}>Categoria</td>
                                    {x.colunas && arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='bold td-data'>Janeiro</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='bold td-data'>Fevereiro</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxMarco]?.visivel ? <td className='bold td-data'>Março</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxAbril]?.visivel ? <td className='bold td-data'>Abril</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxMaio]?.visivel ? <td className='bold td-data'>Maio</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxJunho]?.visivel ? <td className='bold td-data'>Junho</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxJulho]?.visivel ? <td className='bold td-data'>Julho</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='bold td-data'>Agosto</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='bold td-data'>Setembro</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='bold td-data'>Outubro</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='bold td-data'>Novembro</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='bold td-data'>Dezembro</td> : <></>}

                                </tr>
                            </thead>
                            <tbody>
                                {item.receitas.length > 0 ? item.receitas.map((receita) => (
                                    receita.categorias.map((categoria, idxCategoria) => (
                                        <>
                                            {/* Linha da Categoria */}
                                            <tr key={idxCategoria} className={'tr-data bt-1'}>
                                                <td className='text-left td-data bold' colSpan={13}>{categoria.categoria_nome}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={13}>&nbsp;</td>
                                            </tr>

                                            {/* Linha dos valores Liquidados */}
                                            <tr key={`liq-${idxCategoria}`} className={'tr-data bt-1'}>
                                                <td className='text-left td-data'>Realizado</td>
                                                {arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.janeiro)}</td> : null}
                                                {arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.fevereiro)}</td> : null}
                                                {arrayConfigCol[idx][idxMarco]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.marco)}</td> : null}
                                                {arrayConfigCol[idx][idxAbril]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.abril)}</td> : null}
                                                {arrayConfigCol[idx][idxMaio]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.maio)}</td> : null}
                                                {arrayConfigCol[idx][idxJunho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.junho)}</td> : null}
                                                {arrayConfigCol[idx][idxJulho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.julho)}</td> : null}
                                                {arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.agosto)}</td> : null}
                                                {arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.setembro)}</td> : null}
                                                {arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.outubro)}</td> : null}
                                                {arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.novembro)}</td> : null}
                                                {arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.dezembro)}</td> : null}
                                            </tr>

                                            {/* Linha dos valores Previstos */}
                                            <tr key={`prev-${idxCategoria}`} className={'tr-data bt-1'}>
                                                <td className='text-left td-data'>Previsto</td>
                                                {arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.janeiro)}</td> : null}
                                                {arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.fevereiro)}</td> : null}
                                                {arrayConfigCol[idx][idxMarco]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.marco)}</td> : null}
                                                {arrayConfigCol[idx][idxAbril]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.abril)}</td> : null}
                                                {arrayConfigCol[idx][idxMaio]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.maio)}</td> : null}
                                                {arrayConfigCol[idx][idxJunho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.junho)}</td> : null}
                                                {arrayConfigCol[idx][idxJulho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.julho)}</td> : null}
                                                {arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.agosto)}</td> : null}
                                                {arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.setembro)}</td> : null}
                                                {arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.outubro)}</td> : null}
                                                {arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.novembro)}</td> : null}
                                                {arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.dezembro)}</td> : null}
                                            </tr>

                                            {/* Linha do Total Mês */}
                                            <tr key={`total-${idxCategoria}`} className={'tr-data bt-1'}>
                                                <td className='bold text-left td-data'>TOTAL MÊS</td>
                                                {arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.janeiro + categoria.valores_previstos.janeiro)}</td> : null}
                                                {arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.fevereiro + categoria.valores_previstos.fevereiro)}</td> : null}
                                                {arrayConfigCol[idx][idxMarco]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.marco + categoria.valores_previstos.marco)}</td> : null}
                                                {arrayConfigCol[idx][idxAbril]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.abril + categoria.valores_previstos.abril)}</td> : null}
                                                {arrayConfigCol[idx][idxMaio]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.maio + categoria.valores_previstos.maio)}</td> : null}
                                                {arrayConfigCol[idx][idxJunho]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.junho + categoria.valores_previstos.junho)}</td> : null}
                                                {arrayConfigCol[idx][idxJulho]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.julho + categoria.valores_previstos.julho)}</td> : null}
                                                {arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.agosto + categoria.valores_previstos.agosto)}</td> : null}
                                                {arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.setembro + categoria.valores_previstos.setembro)}</td> : null}
                                                {arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.outubro + categoria.valores_previstos.outubro)}</td> : null}
                                                {arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.novembro + categoria.valores_previstos.novembro)}</td> : null}
                                                {arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.dezembro + categoria.valores_previstos.dezembro)}</td> : null}
                                            </tr>
                                            <tr>
                                                <td colSpan={13}>&nbsp;</td>
                                            </tr>
                                        </>
                                    ))
                                )) :
                                    <tr>
                                        <td colSpan='13' className='text-center td-data'>Nenhum Registro Encontrado</td>
                                    </tr>
                                }
                            </tbody>
                        </table>

                        <table className='table-agrupamento mb-2'>
                            <tr className={'tr-data group-title bold '}>
                                <td className={`bold td-data text-left`} style={{ width: '45%' }}>DESPESA</td>
                            </tr>
                        </table>

                        <table style={{ width: '100%', textAlign: 'center', fontSize: '0.6rem' }} className='table-agrupamento mb-2'>
                            <thead>
                                <tr className={'titulo-1'}>
                                    <td className='bold td-data' width={'10%'}>Categoria</td>
                                    {x.colunas && arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='bold td-data'>Janeiro</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='bold td-data'>Fevereiro</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxMarco]?.visivel ? <td className='bold td-data'>Março</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxAbril]?.visivel ? <td className='bold td-data'>Abril</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxMaio]?.visivel ? <td className='bold td-data'>Maio</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxJunho]?.visivel ? <td className='bold td-data'>Junho</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxJulho]?.visivel ? <td className='bold td-data'>Julho</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='bold td-data'>Agosto</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='bold td-data'>Setembro</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='bold td-data'>Outubro</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='bold td-data'>Novembro</td> : <></>}
                                    {x.colunas && arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='bold td-data'>Dezembro</td> : <></>}

                                </tr>
                            </thead>
                            <tbody>
                                {item.despesas.length > 0 ? item.despesas.map((despesa) => (
                                    despesa.categorias.map((categoria, idxCategoria) => (
                                        <>
                                            {/* Linha da Categoria */}
                                            <tr key={idxCategoria} className={'tr-data bt-1'}>
                                                <td className='text-left td-data bold' colSpan={13}>{categoria.categoria_nome}</td>
                                            </tr>

                                            {/* Linha dos valores Liquidados */}
                                            <tr key={`liq-${idxCategoria}`} className={'tr-data bt-1'}>
                                                <td className='text-left td-data'>Realizado</td>
                                                {arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.janeiro)}</td> : null}
                                                {arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.fevereiro)}</td> : null}
                                                {arrayConfigCol[idx][idxMarco]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.marco)}</td> : null}
                                                {arrayConfigCol[idx][idxAbril]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.abril)}</td> : null}
                                                {arrayConfigCol[idx][idxMaio]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.maio)}</td> : null}
                                                {arrayConfigCol[idx][idxJunho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.junho)}</td> : null}
                                                {arrayConfigCol[idx][idxJulho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.julho)}</td> : null}
                                                {arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.agosto)}</td> : null}
                                                {arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.setembro)}</td> : null}
                                                {arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.outubro)}</td> : null}
                                                {arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.novembro)}</td> : null}
                                                {arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.dezembro)}</td> : null}
                                            </tr>

                                            {/* Linha dos valores Previstos */}
                                            <tr key={`prev-${idxCategoria}`} className={'tr-data bt-1'}>
                                                <td className='text-left td-data'>Previsto</td>
                                                {arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.janeiro)}</td> : null}
                                                {arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.fevereiro)}</td> : null}
                                                {arrayConfigCol[idx][idxMarco]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.marco)}</td> : null}
                                                {arrayConfigCol[idx][idxAbril]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.abril)}</td> : null}
                                                {arrayConfigCol[idx][idxMaio]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.maio)}</td> : null}
                                                {arrayConfigCol[idx][idxJunho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.junho)}</td> : null}
                                                {arrayConfigCol[idx][idxJulho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.julho)}</td> : null}
                                                {arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.agosto)}</td> : null}
                                                {arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.setembro)}</td> : null}
                                                {arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.outubro)}</td> : null}
                                                {arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.novembro)}</td> : null}
                                                {arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.dezembro)}</td> : null}
                                            </tr>

                                            {/* Linha do Total Mês */}
                                            <tr key={`total-${idxCategoria}`} className={'tr-data bt-1'} style={{ marginBottom: '10px' }}>
                                                <td className='bold text-left td-data'>TOTAL MÊS</td>
                                                {arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.janeiro + categoria.valores_previstos.janeiro)}</td> : null}
                                                {arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.fevereiro + categoria.valores_previstos.fevereiro)}</td> : null}
                                                {arrayConfigCol[idx][idxMarco]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.marco + categoria.valores_previstos.marco)}</td> : null}
                                                {arrayConfigCol[idx][idxAbril]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.abril + categoria.valores_previstos.abril)}</td> : null}
                                                {arrayConfigCol[idx][idxMaio]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.maio + categoria.valores_previstos.maio)}</td> : null}
                                                {arrayConfigCol[idx][idxJunho]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.junho + categoria.valores_previstos.junho)}</td> : null}
                                                {arrayConfigCol[idx][idxJulho]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.julho + categoria.valores_previstos.julho)}</td> : null}
                                                {arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.agosto + categoria.valores_previstos.agosto)}</td> : null}
                                                {arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.setembro + categoria.valores_previstos.setembro)}</td> : null}
                                                {arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.outubro + categoria.valores_previstos.outubro)}</td> : null}
                                                {arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.novembro + categoria.valores_previstos.novembro)}</td> : null}
                                                {arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.dezembro + categoria.valores_previstos.dezembro)}</td> : null}
                                            </tr>
                                            <tr>
                                                <td colSpan={13}>&nbsp;</td>
                                            </tr>
                                        </>
                                    ))
                                )) :
                                    <tr>
                                        <td colSpan='13' className='text-center td-data'>Nenhum Registro Encontrado</td>
                                    </tr>}
                            </tbody>

                        </table>


                    </>

                })}
            </>)

        }) : <MensagemPadraoRelatorio />
    )
}

const relatorioFluxoCaixaVirtuoso = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {

    return (
        array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {

            const idxJaneiro = arrayConfigCol[idx].findIndex(y => y.coluna == 'janeiro');
            const idxFevereiro = arrayConfigCol[idx].findIndex(y => y.coluna == 'fevereiro');
            const idxMarco = arrayConfigCol[idx].findIndex(y => y.coluna == 'marco');
            const idxAbril = arrayConfigCol[idx].findIndex(y => y.coluna == 'abril');
            const idxMaio = arrayConfigCol[idx].findIndex(y => y.coluna == 'maio');
            const idxJunho = arrayConfigCol[idx].findIndex(y => y.coluna == 'junho');
            const idxJulho = arrayConfigCol[idx].findIndex(y => y.coluna == 'julho');
            const idxAgosto = arrayConfigCol[idx].findIndex(y => y.coluna == 'agosto');
            const idxSetembro = arrayConfigCol[idx].findIndex(y => y.coluna == 'setembro');
            const idxOutubro = arrayConfigCol[idx].findIndex(y => y.coluna == 'outubro');
            const idxNovembro = arrayConfigCol[idx].findIndex(y => y.coluna == 'novembro');
            const idxDezembro = arrayConfigCol[idx].findIndex(y => y.coluna == 'dezembro');

            return (<>
                <div className='delete-starts' />
                <TableVirtuoso
                    style={{ height: 1000, overflowY: 'hidden' }}
                    useWindowScroll
                    data={x.data}
                    itemContent={(index, item) => (
                        <>
                            {index > 0 ? htmlHeader : <></>}
                            <table className='table-agrupamento mb-2'>
                                <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                    <td className={`bold td-data text-left`} style={{ width: '45%' }}>{item.empresa} </td>
                                </tr>
                            </table>

                            <table className='table-agrupamento mb-2'>
                                <tr className={'tr-data group-title bold '}>
                                    <td className={`bold td-data text-left`} style={{ width: '45%' }}>RECEITA</td>
                                </tr>
                            </table>

                            <table style={{ width: '100%', textAlign: 'center', }} className='table-agrupamento mb-2'>
                                <thead>
                                    <tr className={'titulo-1'}>
                                        <td className='bold td-data' width={'10%'}>Categoria</td>
                                        {x.colunas && arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='bold td-data'>Janeiro</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='bold td-data'>Fevereiro</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxMarco]?.visivel ? <td className='bold td-data'>Março</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxAbril]?.visivel ? <td className='bold td-data'>Abril</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxMaio]?.visivel ? <td className='bold td-data'>Maio</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxJunho]?.visivel ? <td className='bold td-data'>Junho</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxJulho]?.visivel ? <td className='bold td-data'>Julho</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='bold td-data'>Agosto</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='bold td-data'>Setembro</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='bold td-data'>Outubro</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='bold td-data'>Novembro</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='bold td-data'>Dezembro</td> : <></>}

                                    </tr>
                                </thead>
                                <tbody>
                                    {item.receitas.length > 0 ? item.receitas.map((receita) => (
                                        receita.categorias.map((categoria, idxCategoria) => (
                                            <>
                                                {/* Linha da Categoria */}
                                                <tr key={idxCategoria} className={'tr-data bt-1'}>
                                                    <td className='text-left td-data bold' colSpan={13}>{categoria.categoria_nome}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={13}>&nbsp;</td>
                                                </tr>

                                                {/* Linha dos valores Liquidados */}
                                                <tr key={`liq-${idxCategoria}`} className={'tr-data bt-1'}>
                                                    <td className='text-left td-data'>Realizado</td>
                                                    {arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.janeiro)}</td> : null}
                                                    {arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.fevereiro)}</td> : null}
                                                    {arrayConfigCol[idx][idxMarco]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.marco)}</td> : null}
                                                    {arrayConfigCol[idx][idxAbril]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.abril)}</td> : null}
                                                    {arrayConfigCol[idx][idxMaio]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.maio)}</td> : null}
                                                    {arrayConfigCol[idx][idxJunho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.junho)}</td> : null}
                                                    {arrayConfigCol[idx][idxJulho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.julho)}</td> : null}
                                                    {arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.agosto)}</td> : null}
                                                    {arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.setembro)}</td> : null}
                                                    {arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.outubro)}</td> : null}
                                                    {arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.novembro)}</td> : null}
                                                    {arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.dezembro)}</td> : null}
                                                </tr>

                                                {/* Linha dos valores Previstos */}
                                                <tr key={`prev-${idxCategoria}`} className={'tr-data bt-1'}>
                                                    <td className='text-left td-data'>Previsto</td>
                                                    {arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.janeiro)}</td> : null}
                                                    {arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.fevereiro)}</td> : null}
                                                    {arrayConfigCol[idx][idxMarco]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.marco)}</td> : null}
                                                    {arrayConfigCol[idx][idxAbril]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.abril)}</td> : null}
                                                    {arrayConfigCol[idx][idxMaio]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.maio)}</td> : null}
                                                    {arrayConfigCol[idx][idxJunho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.junho)}</td> : null}
                                                    {arrayConfigCol[idx][idxJulho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.julho)}</td> : null}
                                                    {arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.agosto)}</td> : null}
                                                    {arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.setembro)}</td> : null}
                                                    {arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.outubro)}</td> : null}
                                                    {arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.novembro)}</td> : null}
                                                    {arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.dezembro)}</td> : null}
                                                </tr>

                                                {/* Linha do Total Mês */}
                                                <tr key={`total-${idxCategoria}`} className={'tr-data bt-1'}>
                                                    <td className='bold text-left td-data'>TOTAL MÊS</td>
                                                    {arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.janeiro + categoria.valores_previstos.janeiro)}</td> : null}
                                                    {arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.fevereiro + categoria.valores_previstos.fevereiro)}</td> : null}
                                                    {arrayConfigCol[idx][idxMarco]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.marco + categoria.valores_previstos.marco)}</td> : null}
                                                    {arrayConfigCol[idx][idxAbril]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.abril + categoria.valores_previstos.abril)}</td> : null}
                                                    {arrayConfigCol[idx][idxMaio]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.maio + categoria.valores_previstos.maio)}</td> : null}
                                                    {arrayConfigCol[idx][idxJunho]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.junho + categoria.valores_previstos.junho)}</td> : null}
                                                    {arrayConfigCol[idx][idxJulho]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.julho + categoria.valores_previstos.julho)}</td> : null}
                                                    {arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.agosto + categoria.valores_previstos.agosto)}</td> : null}
                                                    {arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.setembro + categoria.valores_previstos.setembro)}</td> : null}
                                                    {arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.outubro + categoria.valores_previstos.outubro)}</td> : null}
                                                    {arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.novembro + categoria.valores_previstos.novembro)}</td> : null}
                                                    {arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.dezembro + categoria.valores_previstos.dezembro)}</td> : null}
                                                </tr>
                                                <tr>
                                                    <td colSpan={13}>&nbsp;</td>
                                                </tr>
                                            </>
                                        ))
                                    )) :
                                        <tr>
                                            <td colSpan='13' className='text-center td-data'>Nenhum Registro Encontrado</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>

                            <table className='table-agrupamento mb-2'>
                                <tr className={'tr-data group-title bold '}>
                                    <td className={`bold td-data text-left`} style={{ width: '45%' }}>DESPESA</td>
                                </tr>
                            </table>

                            <table style={{ width: '100%', textAlign: 'center', }} className='table-agrupamento mb-2'>
                                <thead>
                                    <tr className={'titulo-1'}>
                                        <td className='bold td-data' width={'10%'}>Categoria</td>
                                        {x.colunas && arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='bold td-data'>Janeiro</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='bold td-data'>Fevereiro</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxMarco]?.visivel ? <td className='bold td-data'>Março</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxAbril]?.visivel ? <td className='bold td-data'>Abril</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxMaio]?.visivel ? <td className='bold td-data'>Maio</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxJunho]?.visivel ? <td className='bold td-data'>Junho</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxJulho]?.visivel ? <td className='bold td-data'>Julho</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='bold td-data'>Agosto</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='bold td-data'>Setembro</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='bold td-data'>Outubro</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='bold td-data'>Novembro</td> : <></>}
                                        {x.colunas && arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='bold td-data'>Dezembro</td> : <></>}

                                    </tr>
                                </thead>
                                <tbody>
                                    {item.despesas.length > 0 ? item.despesas.map((despesa) => (
                                        despesa.categorias.map((categoria, idxCategoria) => (
                                            <>
                                                {/* Linha da Categoria */}
                                                <tr key={idxCategoria} className={'tr-data bt-1'}>
                                                    <td className='text-left td-data bold' colSpan={13}>{categoria.categoria_nome}</td>
                                                </tr>

                                                {/* Linha dos valores Liquidados */}
                                                <tr key={`liq-${idxCategoria}`} className={'tr-data bt-1'}>
                                                    <td className='text-left td-data'>Realizado</td>
                                                    {arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.janeiro)}</td> : null}
                                                    {arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.fevereiro)}</td> : null}
                                                    {arrayConfigCol[idx][idxMarco]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.marco)}</td> : null}
                                                    {arrayConfigCol[idx][idxAbril]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.abril)}</td> : null}
                                                    {arrayConfigCol[idx][idxMaio]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.maio)}</td> : null}
                                                    {arrayConfigCol[idx][idxJunho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.junho)}</td> : null}
                                                    {arrayConfigCol[idx][idxJulho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.julho)}</td> : null}
                                                    {arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.agosto)}</td> : null}
                                                    {arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.setembro)}</td> : null}
                                                    {arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.outubro)}</td> : null}
                                                    {arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.novembro)}</td> : null}
                                                    {arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_liquidados.dezembro)}</td> : null}
                                                </tr>

                                                {/* Linha dos valores Previstos */}
                                                <tr key={`prev-${idxCategoria}`} className={'tr-data bt-1'}>
                                                    <td className='text-left td-data'>Previsto</td>
                                                    {arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.janeiro)}</td> : null}
                                                    {arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.fevereiro)}</td> : null}
                                                    {arrayConfigCol[idx][idxMarco]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.marco)}</td> : null}
                                                    {arrayConfigCol[idx][idxAbril]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.abril)}</td> : null}
                                                    {arrayConfigCol[idx][idxMaio]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.maio)}</td> : null}
                                                    {arrayConfigCol[idx][idxJunho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.junho)}</td> : null}
                                                    {arrayConfigCol[idx][idxJulho]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.julho)}</td> : null}
                                                    {arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.agosto)}</td> : null}
                                                    {arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.setembro)}</td> : null}
                                                    {arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.outubro)}</td> : null}
                                                    {arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.novembro)}</td> : null}
                                                    {arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='text-right td-data'>{formatarMoeda(categoria.valores_previstos.dezembro)}</td> : null}
                                                </tr>

                                                {/* Linha do Total Mês */}
                                                <tr key={`total-${idxCategoria}`} className={'tr-data bt-1'} style={{ marginBottom: '10px' }}>
                                                    <td className='bold text-left td-data'>TOTAL MÊS</td>
                                                    {arrayConfigCol[idx][idxJaneiro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.janeiro + categoria.valores_previstos.janeiro)}</td> : null}
                                                    {arrayConfigCol[idx][idxFevereiro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.fevereiro + categoria.valores_previstos.fevereiro)}</td> : null}
                                                    {arrayConfigCol[idx][idxMarco]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.marco + categoria.valores_previstos.marco)}</td> : null}
                                                    {arrayConfigCol[idx][idxAbril]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.abril + categoria.valores_previstos.abril)}</td> : null}
                                                    {arrayConfigCol[idx][idxMaio]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.maio + categoria.valores_previstos.maio)}</td> : null}
                                                    {arrayConfigCol[idx][idxJunho]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.junho + categoria.valores_previstos.junho)}</td> : null}
                                                    {arrayConfigCol[idx][idxJulho]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.julho + categoria.valores_previstos.julho)}</td> : null}
                                                    {arrayConfigCol[idx][idxAgosto]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.agosto + categoria.valores_previstos.agosto)}</td> : null}
                                                    {arrayConfigCol[idx][idxSetembro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.setembro + categoria.valores_previstos.setembro)}</td> : null}
                                                    {arrayConfigCol[idx][idxOutubro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.outubro + categoria.valores_previstos.outubro)}</td> : null}
                                                    {arrayConfigCol[idx][idxNovembro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.novembro + categoria.valores_previstos.novembro)}</td> : null}
                                                    {arrayConfigCol[idx][idxDezembro]?.visivel ? <td className='bold text-right td-data'>{formatarMoeda(categoria.valores_liquidados.dezembro + categoria.valores_previstos.dezembro)}</td> : null}
                                                </tr>
                                                <tr>
                                                    <td colSpan={13}>&nbsp;</td>
                                                </tr>
                                            </>
                                        ))
                                    )) :
                                        <tr>
                                            <td colSpan='13' className='text-center td-data'>Nenhum Registro Encontrado</td>
                                        </tr>}
                                </tbody>

                            </table>

                        </>
                    )}
                />
                <div className='delete-ends' />

            </>)
        }) : <MensagemPadraoRelatorio />
    )
}


export { relatorioFluxoCaixa, relatorioFluxoCaixaVirtuoso }