import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
var format = require('xml-formatter');


export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/financeiro/tributoaliquota', id,
            obj => {

                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Tributo Alíquota',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Descrição', descricao: obj.descricao, tamanho: 'col-lg-4' },
                                { titulo: 'Categoria', descricao: obj.categoria && obj.categoria.label, tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Tributo', descricao: obj.tributo_tipo && obj.tributo_tipo.label, tamanho: 'col-lg-4' },
                                { titulo: 'Alíquota', descricao: obj.aliquota_formatado, tamanho: 'col-lg-4' },
                                { titulo: 'Limite Isenção', descricao: obj.limite_isencao_formatado, tamanho: 'col-lg-4' },
                                { titulo: 'Variável', descricao: obj.visu_variavel , tamanho: 'col-lg-4' },
                                { titulo: 'Incide Folha', descricao: obj.visu_incide_folha , tamanho: 'col-lg-4' },
                                { titulo: 'Data de Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                            ]
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Tributo Variável',
                            thead:
                                <tr>
                                    <td className='coluna-1'>#</td>
                                    <td className='coluna-6'>Município</td>
                                    <td className='coluna-4'>Alíquota</td>
                                    <td className='coluna-4'>Data Registro</td>
                                </tr>,
                            tbody: obj.tributo_variavel === null || obj.tributo_variavel.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :

                                obj.tributo_variavel.map((x, idx) =>
                                    <>
                                        <tr>
                                            <td style={{width: '50px'}}>{idx+1}</td>
                                            <td>{x.municipio?.label}</td>
                                            <td>{x.aliquota_formatada}</td>
                                            <td>{x.data_registro}</td>
                                        </tr>
                                    </>)
                            ,
                        }
                    ]
                } : { titulo: 'Visualização Tributo Alíquota' })

            })

    }, [id])


    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}
