import React, { useState, useEffect } from 'react'
import { ModalPadraoCadastro } from '../../../../components/Modais'

import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar, get, consultar } from '../../../../util/AcessoApi'
import { ordenarArrayObj, downloadArquivoS3 } from '../../../../util/FuncoesComuns'

const ATIVIDADE_HISTORICO_SOCIO = {
    ADICIONOU: 1,
    REMOVEU: 2,
    TROCOU: 3,
}

const DetalhesAgendamento = (props) => {
    const [itemDetalhes, setItemDetalhes] = useState({});

    useEffect(() => {
        setItemDetalhes(props.itemDetalhes)
    }, [props.itemDetalhes])

    return <ModalPadraoCadastro titulo={props.titulo || 'Detalhes Agendamento'} showModal={props.showModal} setShowModal={props.setShowModal}>
        <div><div className='detalhes'>
            {itemDetalhes.agendamento && itemDetalhes.agendamento.map((item, idx) => {
                return (
                    <>
                        {idx === 0 ? <></> : <hr />}
                        <div className='item-detalhes'>
                            <h6><b>{item.titulo}</b></h6>
                            <h6>{item.descricao}</h6>
                            {item.html}
                        </div></>
                )
            })}
        </div>
            {
                itemDetalhes.trocaSocioHistorico && itemDetalhes.trocaSocioHistorico.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Histórico de Sócios</span></h5>
                    </div>
                    : <></>
            }
            <div>
                {
                    itemDetalhes.trocaSocioHistorico && itemDetalhes.trocaSocioHistorico.map(item =>
                        <div className='detalhes pt-2 pb-3 mb-2'>
                            <div className='col-hora row-space-between mb-2'>
                                <span></span>
                                <span className='span-nome'>{item.data_registro}</span>
                            </div>
                            {
                                item.historico.map(itemTroca => <div className='bb-1 mb-2'>
                                    <div className='col-hora text-center'>
                                        <span><b>{itemTroca.nome_atual}</b></span>
                                    </div>
                                    <div className='mt-2 mb-2 text-center'>
                                        <i className='icon-Arrow-Up fw'></i>
                                    </div>
                                    <div className='col-hora text-center'>
                                        <span>{itemTroca.nome_anterior}</span>
                                    </div>
                                </div>)
                            }
                            <div className='mt-3'>
                                <i><p>{item.justificativa}</p></i>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span></span>
                                <span className='span-nome'><b>{item.usuario}</b></span>
                            </div>
                        </div>
                    )
                }
            </div>
            {
                itemDetalhes.historico && itemDetalhes.historico.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Histórico de Status</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes.historico && itemDetalhes.historico.map(item => <li className="mb-1">
                            <div className='col-hora row-space-between'>
                                <span className={'tag'} style={{ background: item.cor, color: 'white' }}>{item.descricao}</span> <span className='span-hora'>{item.data_registro}</span>
                            </div>
                            <div className='mt-2'>
                                <i><p>{item.observacao}</p></i>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span></span>
                                <span className='span-nome'><b>{`${item.usuario ? item.usuario.toUpperCase() : ''} ${item.usuario_app_id ? '(APP)' : ""}`}</b></span>
                            </div>
                        </li>)
                    }
                </ul>
            </div>

            {
                itemDetalhes.historico_data && itemDetalhes.historico_data.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Histórico de Data</span></h5>
                    </div>
                    : <></>
            }
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes.historico_data && itemDetalhes.historico_data.map(item => <li className="mb-1">
                            <div className='col-hora row-space-between'>
                                <span></span> <span className='span-hora'>{item.data_registro}</span>
                            </div>
                            <div className='mt-2 row-space-between mb-2' style={{ flexDirection: 'row' }}>
                                <div className='item-detalhes' style={{ flex: 1 }}>
                                    <h6><b>Data Inicial</b></h6>
                                    <h6 className='mb-1'>{item.data_inicial}</h6>
                                </div>
                                <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                    <h6><b>Data Final</b></h6>
                                    <h6 className='mb-1'>{item.data_final}</h6>
                                </div>
                            </div>
                            <div className='col-hora row-space-between'>
                                <span></span>
                                {/* <span className='span-nome'><b>{item.usuario}</b></span> */}
                                <span className='span-nome'><b>{`${item.usuario ? item.usuario.toString().toUpperCase() : ''} ${item.usuario_app_id ? '(APP)' : ""}`}</b></span>
                            </div>
                        </li>)
                    }
                </ul>
            </div>
        </div>
    </ModalPadraoCadastro >
}


const Atividade = (props) => {

    let historico = props.historico.map(item => {
        let atividade;

        if (item.nome_atual && item.nome_anterior)
            atividade = ATIVIDADE_HISTORICO_SOCIO.TROCOU
        else if (item.nome_anterior)
            atividade = ATIVIDADE_HISTORICO_SOCIO.REMOVEU
        else if (item.nome_atual)
            atividade = ATIVIDADE_HISTORICO_SOCIO.ADICIONOU

        return {
            atividade: atividade,
            ...item
        }
    });



    return historico && historico.length > 0 ? historico.map(item => {
        switch (item.atividade) {
            case ATIVIDADE_HISTORICO_SOCIO.REMOVEU:
                return <><p className='mb-1'><b>Remoção: </b><span>{item.nome_anterior}</span></p></>
            case ATIVIDADE_HISTORICO_SOCIO.ADICIONOU:
                return <><p className='mb-1'><b>Adição: </b><span>{item.nome_atual}</span></p></>
            default:
                return <><p className='mb-1'><b>Troca: </b><span>{item.nome_anterior}</span><i className='icon-Arrow-Right fw ml-2 mr-2'></i><span>{item.nome_atual}</span></p></>
        }
    }) : '--'
}

const VisualizacaoAgenda = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})

    useEffect(() => {
        if (id) {
            visualizar(`/administracao/agendareserva`, id, obj => {
                let cloneObj = { ...obj }
                Promise.resolve(get(`/administracao/agendareservastatushistorico/${id}`)).then(val2 => {
                    cloneObj.historico = val2;
                    Promise.resolve(get(`/administracao/agendareservahistoricodata/${id}`)).then(val3 => {
                        cloneObj.historico_data = val3;
                        consultar(`/administracao/trocasociohistorico`, (val) => {
                            let trocaSocio = [];
                            val.filter(x => x.historico?.length).map(item => {
                                trocaSocio.push({
                                    titulo: 'Data',
                                    descricao: item.data_registro,
                                    tamanho: 'col-lg-6'
                                },
                                    {
                                        titulo: 'Usuário',
                                        descricao: item.usuario,
                                        tamanho: 'col-lg-6'
                                    },
                                    {
                                        titulo: 'Atividade',
                                        descricao: <Atividade historico={item.historico} />,
                                        tamanho: 'col-lg-6'
                                    },
                                    { titulo: 'Justificativa', descricao: item.justificativa, tamanho: 'col-lg-6' },
                                    { titulo: '', descricao: '', tamanho: 'col-lg-12 bb-1' })
                            })
                            cloneObj.trocaSocioHistorico = trocaSocio;

                            setDados(cloneObj)
                        }, () => {
                            setDados(cloneObj)
                        }, { id })
                    })
                })
            })
        }
    }, [id])
    const data = dados.id ? {
        titulo: 'Visualização de Agenda',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Estabelecimento de Saúde',
                        descricao: dados.estabelecimento_saude.label, tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Empresa',
                        descricao: dados.empresa ? dados.empresa.label : '--', tamanho: 'col-lg-6'
                    },
                    { titulo: 'Data Inicial', descricao: dados.data_inicial ? dados.data_inicial + ' ' + dados.hora_inicial : '--', tamanho: 'col-lg-6' },
                    { titulo: 'Data Final', descricao: dados.data_final ? dados.data_final + ' ' + dados.hora_final : '--', tamanho: 'col-lg-6' },
                    { titulo: 'Cirurgião', descricao: dados.cirurgiao ? dados.cirurgiao.label || '--' : '--', tamanho: 'col-lg-6' },
                    { titulo: 'Plantão?', descricao: dados.plantao ? "Sim" : "Não", tamanho: 'col-lg-6' },
                    { titulo: 'Sequencial?', descricao: dados.sequencial ? "Sim" : "Não", tamanho: 'col-lg-6' },
                    { titulo: 'Sócio(s)', descricao: dados.executante && dados.executante.length > 0 ? dados.executante.map(item => <><p className='mb-1'>{item.label}</p></>) : '--', tamanho: 'col-lg-6' },
                    { titulo: 'Sócio Preferencial', descricao: dados.preferencia && dados.preferencia.length > 0 ? dados.preferencia.map(item => <><p className='mb-1'>{item.label}</p></>) : '--', tamanho: 'col-lg-6' },
                    { titulo: 'Equipe/Repasse', descricao: dados.equipe_repasse && dados.equipe_repasse.length > 0 ? dados.equipe_repasse.map(item => <><p className='mb-1'>{item.label}</p></>) : '--', tamanho: 'col-lg-6' },
                    { titulo: 'Observação', descricao: dados.observacao || <i>Não há Observações</i>, tamanho: 'col-lg-6' },
                ]
            }, {
                tipoGrupo: 'tabela',
                titulo: 'Procedimento(s)',
                thead:
                    <tr>
                        <td className='coluna-5'>Paciente</td>
                        <td className='coluna-5'>Procedimento</td>
                        <td className='coluna-1 text-center'>Hora</td>
                        <td className='coluna-1 text-center'>Qtd</td>
                    </tr>,
                tbody: dados.procedimentos == undefined || dados.procedimentos.length < 1 ? (<><tr>
                    <td colspan="4">Sem Registro!</td>
                </tr></>) :
                    dados.procedimentos.map(item => {
                        let hora = []

                        if (item.hora_inicial)
                            hora.push(item.hora_inicial)

                        if (item.hora_final)
                            hora.push(item.hora_final)


                        return <tr>
                            <td>{item.paciente ? item.paciente.label : '--'}</td>
                            <td>{item.procedimento ? item.procedimento.label : '--'}</td>
                            <td className='text-center'>{hora.length > 0 ? hora.join(' - ') : '--'}</td>
                            <td className='text-center'>{item.quantidade}</td>
                        </tr>
                    })
            }, {
                tipoGrupo: 'tabela',
                titulo: 'Paciente(s)',
                thead:
                    <tr>
                        <td className='coluna-5'>Paciente</td>
                        <td className='coluna-3'>Contrato</td>
                        <td className='coluna-2 text-center'>Atendimento</td>
                        <td className='coluna-1 text-center'>Matrícula</td>
                        <td className='coluna-1 text-center'>Particular?</td>
                    </tr>,
                tbody: dados.pacientes == undefined || dados.pacientes.length < 1 ? (<><tr>
                    <td colspan="5">Sem Registro!</td>
                </tr></>) :
                    dados.pacientes.map(item =>
                        <tr>
                            <td>{item.beneficiario ? item.beneficiario.label : '--'}</td>
                            <td>{item.contrato ? item.contrato.label : '--'}</td>
                            <td className='text-center'>{item.atendimento || '--'}</td>
                            <td className='text-center'>{item.matricula || '--'}</td>
                            <td className='text-center'>{item.particular ? 'SIM' : 'NÃO'}</td>
                        </tr>)
            },
            {
                titulo: 'Histórico de Sócios',
                colunas: dados.trocaSocioHistorico
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Histórico de Status',
                thead:
                    <tr>
                        <td className='coluna-2'>Status</td>
                        <td className='coluna-3'>Usuário</td>
                        <td className='coluna-2'>Data Alteração</td>
                        <td className='coluna-5'>Observação</td>
                    </tr>,
                tbody: dados.historico == undefined || dados.historico.length < 1 ? (<><tr>
                    <td colspan="4">Sem Registro!</td>
                </tr></>) :
                    dados.historico.map(item =>
                        <tr>
                            <td>{item.descricao || '--'}</td>
                            <td>{item.usuario || '--'}</td>
                            <td>{item.data_registro || '--'}</td>
                            <td>{item.observacao || '--'}</td>
                        </tr>)
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Histórico de Data',
                thead:
                    <tr>
                        <td className='coluna-2'>Data Inicial</td>
                        <td className='coluna-2'>Data Final</td>
                        <td className='coluna-3'>Usuário</td>
                        <td className='coluna-2'>Data Registro</td>
                    </tr>,
                tbody: dados.historico_data == undefined || dados.historico_data.length < 1 ? (<><tr>
                    <td colspan="4">Sem Registro!</td>
                </tr></>) :
                    dados.historico_data.map(item =>
                        <tr>
                            <td>{item.data_inicial || '--'}</td>
                            <td>{item.data_final || '--'}</td>
                            <td>{item.usuario || '--'}</td>
                            <td>{item.data_registro || '--'}</td>
                        </tr>)
            }
        ]
    } : { titulo: 'Visualização de Agenda' }

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoAgenda

export { DetalhesAgendamento }