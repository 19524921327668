import React, { useState, useEffect, useRef, useCallback } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar, postComAcao, put, putComAcao } from '../../../../util/AcessoApi'
import { Alterar, Excluir, Visualizar, Imprimir, BtnAcao, Btn, Detalhes, AlteracaoStatusLote, EnviarLoteCoop, VisualizarArquivo, VisualizarErro, VisualizarRelacaoGuias, BtnAcaoConfirmar, VisualizarGuiasMapaHora } from '../../../../components/Acoes'
import { BotaoPadrao } from '../../../../components/Botoes'
import moment from 'moment';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES, SERVICE, TIPO_ARQUIVO } from '../../../../util/Enums'
import { get, excluir, salvar, validar } from '../../../../util/AcessoApi'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens, useWindowSize } from '../../../../util/Utilitarios.js'
import { InputPadrao, TextArea } from '../../../../components/Inputs'
import { InputLoteStatus } from '../../../../components/InputsAutoComplete'
import Yup from '../../../../util/Validacoes';
import { ModalCadastro, ModalDuasOpcoes } from '../../../../components/Modais'
import { Formik, Form } from "formik";
import { LoadRelatorio } from '../../../../components/Loading'
import { useHistory, Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { forceDownload } from '../../../../util/FuncoesComuns'
import { toast } from 'react-toastify'
import { InputGuia, InputGuiaExtra } from '../../../../components/InputsAutoCompleteV2'



const ConsultaReferenciaProducao = rProps => {
    const [data, setData] = useState([])
    const target = useRef(null)
    const [modalAlteracaoStatus, setModalAlteracaoStatus] = useState(false)
    const [itemTrocarStauts, setItemTrocarStatus] = useState({})
    const [showModalArquivos, setShowModalArquivos] = useState()
    const [showModalAddGuias, setShowModalAddGuias] = useState()
    const [itemDetalhes, setItemDetalhes] = useState({})
    const [width] = useWindowSize()
    const history = useHistory()

    const columns = React.useMemo(
        () => [{
            id: 'consulta-referencia',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Referência',
                    accessor: 'referencia',
                    ordenamento: {
                        tipo: 'data'
                    }
                },
                {
                    Header: 'Data Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    },
                },
                {
                    Header: 'Data Registro Inicial',
                    accessor: 'data_inicial',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'data_registro_inicial',
                        datePicker: true,
                        date: true
                    }
                }, {
                    Header: 'Data Registro Final',
                    accessor: 'data_final',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'data_registro_final',
                        datePicker: true,
                        date: true
                    }
                },
            ]
        }], []
    )

    const acoesObj = [
        (propsAcao) => <Visualizar to={'/controleproducao/referencia/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/controleproducao/referencia/alteracao/' + propsAcao.row.id} />,
        (propsAcao) => <Excluir to={'/controleproducao/referencia/consulta/'} action={() => exclusao(propsAcao)} />,
        (propsAcao) => <OutrasAcoes propsAcao={propsAcao} />
    ]

    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: rProps.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/controleproducao/referencia', propsAcao.row.id,
                    () => {
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const OutrasAcoes = ({ propsAcao, ...props }) => {
        return (
            <Dropdown >
                <DropdownTrigger>
                    <Btn icone='icon-ellipsi' texto="Mais Ações" />
                </DropdownTrigger>
                <DropdownContent>
                    <div className="card-acoes-dropdown">
                        <div className="ajusta-dropdown-consulta-guia">
                            {[
                                <BtnAcao classeToltip='' icone='icon-Files' texto='Gerar Arquivos' action={() => { setShowModalArquivos(true); setItemDetalhes({ ...propsAcao.row }) }} />,
                                <BtnAcao classeToltip='' icone='icon-File-TextImage' texto='Adicionar/Remover Guias' action={() => { setShowModalAddGuias(true); setItemDetalhes({ ...propsAcao.row }) }} />

                            ]}
                        </div>
                    </div>
                </DropdownContent>
            </Dropdown>
        )
    }

    const acoesObj2 = [ // AÇÕES PARA TELA MENOR 1920
        (propsAcao) => <Visualizar to={'/controleproducao/referencia/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/controleproducao/referencia/alteracao/' + propsAcao.row.id} />,
        (propsAcao) => <Excluir to={'/controleproducao/referencia/consulta/'} action={() => exclusao(propsAcao)} />,
        (propsAcao) => <BtnAcao classeToltip='' icone='icon-Files' texto='Gerar Arquivos' action={() => { setShowModalArquivos(true); setItemDetalhes({ ...propsAcao.row }) }} />,
        (propsAcao) => <BtnAcao classeToltip='' icone='icon-File-TextImage' texto='Adicionar/Remover Guias' action={() => { setShowModalAddGuias(true); setItemDetalhes({ ...propsAcao.row }) }} />,

    ]

    return (
        <>
            <div>
                <DataGridAlternativa target={target} acoesObj={width <= 1822 ? acoesObj2 : acoesObj} data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/controleproducao/referencia'
                    caminhoConsultaFiltros='/controleproducao/referenciapaginacao' modulo={rProps.modulo} titulo={'Consulta Referência Produção'} tituloLista={'Lista Referência Produção'}
                    urlCadastro='/controleproducao/referencia/cadastro' icone={'icon-Calendar-4'} />
                <ModalVersoes titulo={'Arquivos'} target={target} history={history} showModal={showModalArquivos} setShowModal={setShowModalArquivos} consultaRef={target} webService={rProps} itemDetalhes={itemDetalhes} />
                <ModalAddGuias titulo={'Adicionar/Remover Guias'} target={target} history={history} showModal={showModalAddGuias} setShowModal={setShowModalAddGuias} consultaRef={target} webService={rProps} itemDetalhes={itemDetalhes} />
            </div>
        </>
    )

}

const ModalVersoes = ({ consultaRef, webService, ...props }) => {

    const desfazerArquivo = (propsAcao) => {
        const toastify = toast('Aguarde...', { isLoading: true })
        props.setShowModal(false);
        putComAcao(ACOES.DESFAZER_MAPA_HORAS, webService.modulo, { ...propsAcao }, '/controleAcesso/desfazerMapaHoras',
            data => {
                consultaRef.current.atualizar();
                toast.update(toastify, { autoClose: 5000, type: 'success', render: mensagens.sucesso, isLoading: false });
            })
    }

    return (

        <>
            <ModalCadastro titulo={props.titulo || 'Informações Lançamento'} show={props.showModal} setShowModal={props.setShowModal} >
                {
                    <>
                        <div>
                            <ul className='lista-modal'>
                                {
                                    <li className="mb-1">
                                        <div className='col-hora row-space-between'>

                                            <span className='span-hora'>{props?.itemDetalhes.data_registro}</span>
                                        </div>
                                        <div className='mt-2 row-space-between mb-2' style={{ flexDirection: 'row' }}>
                                            <div className='item-detalhes' style={{ flex: 1 }}>
                                                <h6><b>Referência</b></h6>
                                                <h6 className='mb-1'>{props?.itemDetalhes.referencia}</h6>
                                            </div>
                                            <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                                <h6><b>Conferido?</b></h6>
                                                <h6 className='mb-1'>{props?.itemDetalhes.mapa_horas_conferido ? 'SIM' : 'NÃO'}</h6>
                                            </div>
                                        </div>

                                        <div className='mt-2'>
                                            <i><p>{props?.itemDetalhes.anotacoes}</p></i>
                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className='item-detalhes' >
                            <div className='detalhes'>
                                <table>
                                    <tr>
                                        {<th>Mapa de Horas</th>}
                                    </tr>

                                    <tr>
                                        {!props?.itemDetalhes.mapa_horas_conferido ?
                                            <>
                                                <td>Mapa de Horas Conferência</td>

                                                <BtnAcao classeToltip='' icone='icon-File-Clipboard' texto='Gerar Mapa de Horas para Conferência' action={() => { props.setShowModal(false); webService.obterWebService(JSON.stringify({ "ProcessType": "mapa_horas", "IntegrationType": "mapa_horas", "Key": props?.itemDetalhes.id.toString(), "usuarioId": getUsuario().id, "moduloId": 140, "acaoId": 47 }), SERVICE.DOCWAGE) }} />
                                            </>
                                            :
                                            <></>
                                        }
                                    </tr>
                                    <tr>
                                        {!props?.itemDetalhes.mapa_horas_conferido ?
                                            <>
                                                <td>Gerar Arquivo Definitivo</td>
                                                <BtnAcaoConfirmar classeToltip='' icone='icon-File-ClipboardFileText' mensagemConfirmacao="Confirme para gerar o arquivo definitivo" texto='Gerar Mapa de Horas Definitivo' action={() => { props.setShowModal(false); webService.obterWebService(JSON.stringify({ "ProcessType": "mapa_horas", "IntegrationType": "mapa_horas", "Key": props?.itemDetalhes.id.toString(), "usuarioId": getUsuario().id, "moduloId": 140, "acaoId": 44, "LastProgress": true }), SERVICE.DOCWAGE, () => props.target.current.atualizar()) }} />
                                            </>
                                            :
                                            <>
                                                <td>Desfazer o arquivo definitivo</td>
                                                <BtnAcaoConfirmar mensagemConfirmacao="Confirme para desfazer o arquivo definitivo" classeToltip='' icone='icon-Close' texto='Desfazer Mapa de Horas Definitivo' action={() => desfazerArquivo(props?.itemDetalhes)} />
                                            </>
                                        }
                                    </tr>
                                    {props?.itemDetalhes.mapa_horas_conferido ?
                                        <tr>
                                            <td>Visualizar Guia(s)</td>
                                            {/* propsAcao.row.id.toString() */}
                                            <VisualizarGuiasMapaHora action={() => props.history.push({ pathname: '/relatorios/mapahoras/relatorio', state: { id_ref_producao: props?.itemDetalhes.id, item: props?.itemDetalhes, tipo_arquivo: TIPO_ARQUIVO['MAPA_DE_HORAS'] } })} />
                                        </tr> : <></>}


                                </table>
                            </div>
                        </div>

                        <div className='item-detalhes' >
                            <div className='detalhes'>
                                <table>
                                    <tr>
                                        {<th>Extra</th>}
                                    </tr>
                                    <tr>
                                        <td>Gerar Arquivo</td>
                                        <BtnAcao classeToltip='' icone='icon-File-ClipboardFileText' texto='Gerar Extra' action={() => { props.setShowModal(false); webService.obterWebService(JSON.stringify({ "ProcessType": "extra", "IntegrationType": "extra", "Key": props?.itemDetalhes.id.toString(), "usuarioId": getUsuario().id, "moduloId": 140, "acaoId": 81 }), SERVICE.DOCWAGE) }} />
                                    </tr>
                                    <tr>
                                        <td>Visualizar Guia(s)</td>
                                        <VisualizarGuiasMapaHora action={() => props.history.push({ pathname: '/relatorios/mapahoras/relatorio', state: { id_ref_producao: props?.itemDetalhes.id, item: props?.itemDetalhes, tipo_arquivo: TIPO_ARQUIVO['EXTRA'] } })} />
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div className='item-detalhes' >
                            <div className='detalhes'>
                                <table>
                                    <tr>
                                        {<th>Ajuda</th>}
                                    </tr>
                                    <tr>
                                        <td>Gerar Arquivo</td>
                                        <BtnAcao classeToltip='' icone='icon-File-ClipboardFileText' texto='Gerar Ajuda' action={() => { props.setShowModal(false); webService.obterWebService(JSON.stringify({ "ProcessType": "ajuda", "IntegrationType": "ajuda", "Key": props?.itemDetalhes.id.toString(), "usuarioId": getUsuario().id, "moduloId": 140, "acaoId": 82 }), SERVICE.DOCWAGE) }} />
                                    </tr>
                                    <tr>
                                        <td>Visualizar Guia(s)</td>
                                        <VisualizarGuiasMapaHora action={() => props.history.push({ pathname: '/relatorios/mapahoras/relatorio', state: { id_ref_producao: props?.itemDetalhes.id, item: props?.itemDetalhes, tipo_arquivo: TIPO_ARQUIVO['AJUDA'] } })} />
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div className='item-detalhes' >
                            <div className='detalhes'>
                                <table>
                                    <tr>
                                        {<th>Terceiro Hospital</th>}
                                    </tr>
                                    <tr>
                                        <td>Gerar Arquivo</td>
                                        <BtnAcao classeToltip='' icone='icon-File-ClipboardFileText' texto='Gerar Ajuda' action={() => { props.setShowModal(false); webService.obterWebService(JSON.stringify({ "ProcessType": "terceiro_hospital", "IntegrationType": "terceiro_hospital", "Key": props?.itemDetalhes.id.toString(), "usuarioId": getUsuario().id, "moduloId": 140, "acaoId": 83 }), SERVICE.DOCWAGE) }} />
                                    </tr>
                                    <tr>
                                        <td>Visualizar Guia(s)</td>
                                        <VisualizarGuiasMapaHora action={() => props.history.push({ pathname: '/relatorios/mapahoras/relatorio', state: { id_ref_producao: props?.itemDetalhes.id, item: props?.itemDetalhes, tipo_arquivo: TIPO_ARQUIVO['TERCEIRO_HOSPITAL'] } })} />
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </>

                }
            </ModalCadastro >

        </>
    )
}

const ModalAddGuias = ({ consultaRef, webService, ...props }) => {
    const [salvando, setSalvando] = useState(false)
    const [carregando, setCarregando] = useState(true)
    const [valoresIniciais, setValoresIniciais] = useState({ guias: ''})
    const [validacao] = useState(Yup.object({
        guias: Yup.object().nullable()
    }))

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        let valuesAjustados = { referencia_id: props?.itemDetalhes.id, ...values }

        postComAcao(
            ACOES.ADICIONAR_GUIAS_EXTRA,
            null,
            valuesAjustados,
            '/controleproducao/extraguias',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                setSalvando(false)
                webService.obterWebService(JSON.stringify({ "ProcessType": "extra", "IntegrationType": "extra", "Key": props?.itemDetalhes.id.toString(), "usuarioId": getUsuario().id, "moduloId": 140, "acaoId": 81 }), SERVICE.DOCWAGE)
            },
            (err) => {
                setSalvando(false)
            })
    }

    useEffect(() => {
        if(props.showModal)
            obterValoresIniciais()
        else
            setValoresIniciais({})
    }, [props.showModal])

    const obterValoresIniciais = useCallback(() => {
        setCarregando(true)
            Promise.resolve(get(`/controleproducao/obterGuiasExtra/${props?.itemDetalhes.id}`)).then((val) => {
                setCarregando(false)
                setValoresIniciais(val)
            }).catch(() => {
                setCarregando(false)
                setValoresIniciais([])
            })
    }, [props.showModal])

    return (

        <>
            <ModalCadastro titulo={props.titulo} show={props.showModal} setShowModal={props.setShowModal} >
                {
                    <>
                        <div>
                            <ul className='lista-modal'>
                                {
                                    <li className="mb-1">
                                        <div className='col-hora row-space-between'>

                                            <span className='span-hora'>{props?.itemDetalhes.data_registro}</span>
                                        </div>
                                        <div className='mt-2 row-space-between mb-2' style={{ flexDirection: 'row' }}>
                                            <div className='item-detalhes' style={{ flex: 1 }}>
                                                <h6><b>Referência</b></h6>
                                                <h6 className='mb-1'>{props?.itemDetalhes.referencia}</h6>
                                            </div>
                                            <div className='item-detalhes mt-0 bl-cinza pl-1' style={{ flex: 1 }}>
                                                <h6><b>Conferido?</b></h6>
                                                <h6 className='mb-1'>{props?.itemDetalhes.mapa_horas_conferido ? 'SIM' : 'NÃO'}</h6>
                                            </div>
                                        </div>

                                        <div className='mt-2'>
                                            <i><p>{props?.itemDetalhes.anotacoes}</p></i>
                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>

                        <Formik
                            initialValues={valoresIniciais}
                            validationSchema={validacao}
                            enableReinitialize={true}
                            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                            {propsFormik => (
                                // <div ref={refAtualizacao}>
                                <div>
                                    <Form>
                                        <div className='modal-body-cadastro'>
                                            <div className={'form-group col-12'}>
                                                {
                                                    // carregando === true ? <LoadRelatorio refLoading={refAtualizacao} heigth={320} /> : 
                                                    <>
                                                        <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                                                        <InputGuiaExtra label={<b>Guia</b>} name="guias" propsFormik={propsFormik} tamanho={'col-lg-12 p-0'}
                                                            classeTooltip={'tooltip-input-modal'} placeholder="Guias" multiplo={true} autoFocus obrigatorio isLoading={carregando} isDisabled={carregando} />
                                                    </>}
                                            </div>
                                        </div>
                                        <div className='separator mt-40 mb-2'></div>

                                        <div className='direita container-botoes mb-3 group-salvar-novo'>
                                            <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                        </div>
                                    </Form>
                                </div>
                            )}
                        </Formik>

                    </>

                }
            </ModalCadastro >

        </>
    )
}

export default ConsultaReferenciaProducao