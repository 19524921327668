import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'

const VisualizacaoUsuario = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        if (id) {
            visualizar('/administracao/operadoraSaude', id,
                obj =>
                    setDados(obj.razao_social ? {
                        id,
                        modulo: props.modulo,
                        titulo: 'Visualização de Operadora de Saúde',
                        grupos: [
                            {
                                titulo: 'Dados Gerais',
                                colunas: [
                                    { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                    { titulo: 'Razão Social', descricao: obj.razao_social, tamanho: 'col-lg-4' },
                                    { titulo: 'Nome Fantasia', descricao: obj.nome_fantasia, tamanho: 'col-lg-4' },
                                    { titulo: 'CNPJ', descricao: obj.cnpj, tamanho: 'col-lg-4' },
                                    { titulo: 'Inscrição ANS', descricao: obj.inscricao_ans, tamanho: 'col-lg-4' },
                                    { titulo: 'E-mail', descricao: obj.email, tamanho: 'col-lg-4' },
                                    { titulo: 'Site', descricao: obj.site, tamanho: 'col-lg-4' },
                                    { titulo: 'Ativo?', descricao: obj.ativo && obj.ativo.label || '', tamanho: 'col-lg-4' },
                                    { titulo: 'Código', descricao: obj.codigo, tamanho: 'col-lg-4' },
                                    { titulo: 'Código Cooperativa', descricao: obj.codigo_coopanestgo, tamanho: 'col-lg-4' },
                                    { titulo: 'Observação', descricao: obj.observacao, tamanho: 'col-lg-4' },
                                    { titulo: 'Data Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' }
                                ]
                            },
                            {
                                tipoGrupo: 'tabela',
                                titulo: 'Telefones',
                                thead:
                                    <tr>
                                        <td className='coluna-6'>Tipo</td>
                                        <td className='coluna-6'>Telefone</td>
                                    </tr>,
                                tbody:
                                    obj.telefone && obj.telefone.sort().map(x =>
                                        <tr>
                                            <td>{x.descricao}</td>
                                            <td>{x.telefone}</td>
                                        </tr>)
                            },
                            {
                                tipoGrupo: 'tabela',
                                titulo: 'Endereços',
                                thead:
                                    <tr>
                                        <td className='coluna-1'>Tipo</td>
                                        <td className='coluna-2'>CEP</td>
                                        <td className='coluna-2'>Logradouro</td>
                                        <td className='coluna-2'>Complemento</td>
                                        <td className='coluna-1'>Número</td>
                                        <td className='coluna-2'>Bairro</td>
                                        <td className='coluna-2'>Município</td>
                                    </tr>,
                                tbody:
                                    obj.endereco && obj.endereco.sort().map(x =>
                                        <tr>
                                            <td>{x.tipo.descricao}</td>
                                            <td>{x.cep}</td>
                                            <td>{x.logradouro}</td>
                                            <td>{x.complemento}</td>
                                            <td>{x.numero}</td>
                                            <td>{x.bairro}</td>
                                            <td>{x.municipio.nome}</td>
                                        </tr>)
                            }
                        ]
                    } : { titulo: 'Visualização de Operadora de Saúde' })
            )
        }
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default VisualizacaoUsuario