import React, { useState, useRef } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import {
    Alterar, Excluir, Visualizar, AlteracaoLote, AlteracaoStatus, HistoricoStatus, Btn, GuiaComplementar, VisualizarErro, VisualizarArquivo, EnviarLoteCoop,
    FechamentoMovimentoFinanceiro, DetalhesMovimentoFinanceiro, VisualizacaoComAssinatura, LançamentoFinanceiro, AlterarMovimentoFinanceiro, AlterarPersonalizado
} from '../../../../components/Acoes'
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { get, excluir, salvar, validar } from '../../../../util/AcessoApi'
import { getUsuario } from '../../../../util/Configuracoes';
import { ACOES } from '../../../../util/Enums';
import { Link } from 'react-router-dom';
import { mensagens, useWindowSize } from '../../../../util/Utilitarios';
import { mostrarToast } from '../../../../components/Toasts';
import { useHistory } from 'react-router-dom'
import { FechamentoModal, DetalhesModal } from '../../movimento/pages/Tab'

const ConsultaMovimento = (props) => {
    const [data, setData] = useState([])
    const history = useHistory()
    const { width } = useWindowSize()
    const target = useRef(null)
    const [showModalFechamento, setShowModalFechamento] = useState(false)
    const [showId, setIdMovimento] = useState()
    const [itemDetalhes, setItemDetalhes] = useState()
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-movimento-financeiro',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Data',
                        accessor: 'data',
                        ordenamento: {
                            tipo: 'data'
                        }
                    },
                    {
                        Header: 'Entidade(Empresa/Sócio)',
                        accessor: 'entidade',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'entidade_financeiro',
                            idColuna: 'cliente.id',
                        }
                    },
                    {
                        Header: 'Descrição',
                        accessor: 'descricao',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Categoria',
                        accessor: 'categoria',
                        visivel: false,
                        filtro: {
                            tipo: 'categoria_financeiro',
                            idColuna: 'categoria_id'
                        }
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'status_movimento',
                            idColuna: 'status_id'
                        },
                        html: item => <span className={'tag'} style={{ backgroundColor: item.cor, color: 'white' }}>{item.status}</span>
                    },
                    {
                        Header: 'Valor',
                        accessor: 'valor_total_formatado',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Valor Recebido',
                        accessor: 'total_geral',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Valor Liquidado',
                        accessor: 'valor_liquidado',
                        ordenamento: {
                            tipo: 'numero'
                        }
                    },
                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro',
                        ordenamento: {
                            tipo: 'data'
                        }
                    },
                    {
                        Header: 'Data Movimento Inicial',
                        accessor: 'data_i',
                        visivel: false,
                        filtro: {
                            tipo: 'data_movimento_inicial',
                            idColuna: 'data_movimento_inicial',
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data Movimento Final',
                        accessor: 'data_f',
                        visivel: false,
                        filtro: {
                            tipo: 'data_movimento_final',
                            idColuna: 'data_movimento_final',
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data Registro Inicial',
                        accessor: 'data_registro_inicial',
                        visivel: false,
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_inicial',
                            idColuna: 'data_registro_inicial',
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data Registro Final',
                        accessor: 'data_registro_final',
                        visivel: false,
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_final',
                            idColuna: 'data_registro_final',
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Usuário',
                        accessor: 'usuario',
                        visivel: false,
                        filtro: {
                            tipo: 'usuario',
                            idColuna: 'usuario_id'
                        }
                    },
                ],
            },
        ],
        []
    )

    const objPermissao = {
        usuario_id: getUsuario().id,
        modulo_id: props.modulo,
        acao_id: ACOES.EXCLUIR
    }


    const exclusao = async (propsAcao) => {


        try {
            const permissao = await get('/controleAcesso/permissao', objPermissao)

            if (permissao.length) {

                const validarExistenciaLancamento = await get('/financeiro/validarlancamento', { id: propsAcao.row.id })
                if (!validarExistenciaLancamento.length) {

                    const verificarStatus = await get('/financeiro/validaralteracaomovimento', { id: propsAcao.row.id })

                    if (verificarStatus[0].status_id === 1) {

                        excluir('/financeiro/movimentofinanceiro', propsAcao.row.id,
                            () => {
                                // getApi(setData, '/faturamento/guia')
                                target.current.atualizar();
                                mostrarToast('sucesso', mensagens['sucesso'])
                            },
                            (e) => {
                                if (e.response && e.response.data && e.response.data.erros)
                                    e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                                else
                                    mostrarToast('erro', mensagens.erro)
                            }, propsAcao.row.descricao_auditoria)

                    } else {
                        mostrarToast('erro', 'Operação não permitida! Movimento Financeiro Fechado ou Conferido.')
                    }

                }
                else {
                    mostrarToast('erro', 'Operação não permitida! Existe Lançamentos ligados ao Movimento.')
                }
            } else
                mostrarToast('erro', mensagens['permissao'])
        }
        catch (e) {
            console.log('e: ', e);
            mostrarToast('erro', mensagens['erro']);
        }

    }

    const alteracao = (propsAcao) => {

        get('/financeiro/validaralteracaomovimento', { id: propsAcao.row.id }).then(function (val) {
            if (val[0].status_id != 3) {
                props.history.push('/financeiro/movimento/alteracao/' + propsAcao.row.id)
            }
            else
                mostrarToast('erro', 'Operação não permitida! Movimento Financeiro Fechado.')

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const OutrasAcoes = ({ propsAcao, ...props }) => {
        return (
            <Dropdown >
                <DropdownTrigger>
                    <Btn icone='icon-ellipsi' texto="Mais Ações" />
                </DropdownTrigger>
                <DropdownContent>
                    <div className="card-acoes-dropdown">
                        <div className="ajusta-dropdown-consulta-guia">
                            {[
                                <Excluir to={'/financeiro/movimento/consulta/'} action={() => exclusao(propsAcao)} />,

                                <FechamentoMovimentoFinanceiro action={async () => {
                                    await Promise.resolve(get('/controleAcesso/permissao', objPermissao).then(function (val) {

                                        if (val.length) {
                                            setShowModalFechamento(true)
                                            setIdMovimento(propsAcao.row.id)

                                        }
                                        else {
                                            mostrarToast('erro', 'O usuário não possui permissão para esta ação! Contate um administrador.')
                                        }

                                    }).catch((e) => mostrarToast('erro', mensagens['erro'])))

                                }} />,
                                <DetalhesMovimentoFinanceiro action={() => {
                                    setShowModalDetalhes(true)
                                    obterDetalhes(propsAcao)
                                }} />,
                                <VisualizacaoComAssinatura to={'/financeiro/lancamento/visualizacaoassinatura/' + propsAcao.row.id} />,
                                <LançamentoFinanceiro action={async () => {

                                    get('/financeiro/validaralteracaomovimento', { id: propsAcao.row.id }).then(function (val) {
                                        if (val[0].status_id == 1) {
                                            Promise.resolve(get('/controleAcesso/permissao', objPermissao).then(function (val) {

                                                if (val.length) {
                                                    history.push({ pathname: '/financeiro/lancamento/cadastro', state: { idMovimento: propsAcao.row.id } })
                                                }
                                                else {
                                                    mostrarToast('erro', 'O usuário não possui permissão para esta ação! Contate um administrador.')
                                                }

                                            }).catch((e) => mostrarToast('erro', mensagens['erro'])))
                                        }
                                        else
                                            mostrarToast('erro', 'Operação não permitida! Movimento Financeiro Fechado/Conferido.')

                                    }).catch((e) => mostrarToast('erro', mensagens['erro']))


                                }} />,


                            ]}
                        </div>
                    </div>
                </DropdownContent>
            </Dropdown>
        )

    }

    const acoesObj = [ // AÇÕES PARA TELA 1920
        (propsAcao) => <Visualizar to={'/financeiro/movimento/visualizacao/' + propsAcao.row.id} />,
        (propsAcao) => <AlterarPersonalizado icone={'icon-Pen'} to={propsAcao.row.status_id === 1 &&
            '/financeiro/movimento/alteracao/' + propsAcao.id} action={() => { propsAcao.row.status_id !== 1 && mostrarToast('erro', 'Não permitido alterar um movimento com status (FECHADO OU CONFERIDO)') }} />,
        // (propsAcao) => <Alterar to={'/financeiro/movimento/alteracao/' + propsAcao.row.id} />,
        (propsAcao) => <OutrasAcoes propsAcao={propsAcao} />

    ]

    const acoesObj2 = [ // AÇÕES PARA TELA MENOR 1920
        (propsAcao) => <Visualizar to={'/financeiro/movimento/visualizacao/' + propsAcao.row.id} />,
        (propsAcao) => <AlterarPersonalizado icone={'icon-Pen'} to={propsAcao.row.status_id === 1 &&
            '/financeiro/movimento/alteracao/' + propsAcao.id} action={() => { propsAcao.row.status_id !== 1 && mostrarToast('erro', 'Não permitido alterar um movimento com status (FECHADO OU CONFERIDO)') }} />,
        (propsAcao) => <Excluir to={'/financeiro/movimento/consulta/'} action={() => exclusao(propsAcao)} />,
        (propsAcao) => <FechamentoMovimentoFinanceiro action={async () => {
            await Promise.resolve(get('/controleAcesso/permissao', objPermissao).then(function (val) {

                if (val.length) {
                    setShowModalFechamento(true)
                    setIdMovimento(propsAcao.row.id)

                }
                else {
                    mostrarToast('erro', 'O usuário não possui permissão para esta ação! Contate um administrador.')
                }

            }).catch((e) => mostrarToast('erro', mensagens['erro'])))

        }} />,
        (propsAcao) => <DetalhesMovimentoFinanceiro action={() => {
            setShowModalDetalhes(true)
            obterDetalhes(propsAcao)
        }} />,
        (propsAcao) => <VisualizacaoComAssinatura to={'/financeiro/lancamento/visualizacaoassinatura/ ' + propsAcao.row.id} />,
        <LançamentoFinanceiro action={() => {
        }} />,
    ]



    const obterDetalhes = async (propsAcao) => {
        let { id } = propsAcao.row
        setItemDetalhes({ mf_historico_status: [{ titulo: 'Carregando...' }] })
        let movimento = {}

        await Promise.resolve(get(`/financeiro/obterhistoricostatusmovimento/${id}`)).then(val2 => {
            movimento.mf_historico_status = val2;
        })

        setItemDetalhes(movimento)
    }

    return (
        <div>
            <DataGridAlternativa target={target} acoesObj={width <= 1822 ? acoesObj2 : acoesObj} data={data} columns={columns} caminhoBase='/financeiro/movimento' setData={setData} acoes={true} modulo={props.modulo}
                titulo={'Consulta Movimento Financeiro'} tituloLista={'Lista Movimento Financeiro'} urlCadastro='/financeiro/movimento/cadastro' icone={'icon-Receipt'} caminhoConsultaFiltros='/financeiro/movimentofinanceiropaginacao' />
            <FechamentoModal target={target} titulo={'Fechamento do Movimento Financeiro'} showModal={showModalFechamento} setShowModal={setShowModalFechamento} rowId={showId} />
            <DetalhesModal titulo={'Detalhes'} data={itemDetalhes} showModal={showModalDetalhes} setShowModal={setShowModalDetalhes} />
        </div>
    )
}

export default ConsultaMovimento