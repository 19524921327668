import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { ordenarArrayObj } from '../../../../util/FuncoesComuns'

const VisualizacaoUsuario = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})

    useEffect(() => {
        if (id)
            visualizar(`/controleAcesso/usuario`, id, obj => {
                setDados(obj)

            })
    }, [id])

    const tipoUsuario = (dados, tabela) => {
        if (dados.usuario_tipo[0].value === 1 && tabela)
            return {
                tipoGrupo: 'tabela',
                titulo: 'Departamento',
                thead:
                    <tr>
                        <td className='coluna-6'>Código</td>
                        <td className='coluna-6'>Descrição</td>
                    </tr>,
                tbody: dados.departamento == undefined || dados.departamento.length < 1 ? (<><tr>
                    <td colspan="2">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.departamento, 'id').map(item =>
                        <tr>
                            <td>{item.value}</td>
                            <td>{item.label}</td>
                        </tr>)
            }
        else if (dados.usuario_tipo[0].value === 4 && tabela) {
            return [
                {
                    tipoGrupo: 'tabela',
                    titulo: 'Estabelecimento Saúde',
                    thead:
                        <tr>
                            <td className='coluna-6'>Código</td>
                            <td className='coluna-6'>Nome Fantasia</td>
                        </tr>,
                    tbody: dados.estabelecimento_saude == undefined || dados.estabelecimento_saude.length < 1 ? (<><tr>
                        <td colspan="2">Sem Registro!</td>
                    </tr></>) :
                        ordenarArrayObj(dados.estabelecimento_saude, 'id').map(item =>
                            <tr>
                                <td>{item.value}</td>
                                <td>{item.label}</td>
                            </tr>)
                },
                {
                    tipoGrupo: 'tabela',
                    titulo: 'Cirurgião',
                    thead:
                        <tr>
                            <td className='coluna-6'>Código</td>
                            <td className='coluna-6'>Cirurgião</td>
                        </tr>,
                    tbody: dados.estabelecimento_saude == undefined || dados.cirurgiao.length < 1 ? (<><tr>
                        <td colspan="2">Sem Registro!</td>
                    </tr></>) :
                        ordenarArrayObj(dados.cirurgiao, 'id').map(item =>
                            <tr>
                                <td>{item.value}</td>
                                <td>{item.label}</td>
                            </tr>)
                }
            ]
        }
        else if ((dados.usuario_tipo.value === 2 || dados.usuario_tipo.value === 3) && !tabela) {
            return {
                titulo: 'Sócio',
                descricao: dados.socio.label,
                tamanho: 'col-lg-6'
            }
        }
    }

    const data = dados.nome ? {
        titulo: 'Visualização de Usuário',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Nome',
                        descricao: dados.nome,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Login',
                        descricao: dados.login,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'E-mail',
                        descricao: dados.email,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Vigência de Acesso Inicial',
                        descricao: dados.vigencia_acesso_inicial,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Vigência de Acesso Final',
                        descricao: dados.vigencia_acesso_ativo,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Ativo?',
                        descricao: dados.ativo.label,
                        tamanho: 'col-lg-6'
                    }, {
                        titulo: 'Tipo',
                        descricao: dados.usuario_tipo[0].label,
                        tamanho: 'col-lg-6'
                    },
                    tipoUsuario(dados, false),
                    {
                        titulo: 'Data de Registro',
                        descricao: dados.data_registro,
                        tamanho: 'col-lg-6'
                    }
                ]
            }, {
                tipoGrupo: 'tabela',
                titulo: 'Grupos',
                thead:
                    <tr>
                        <td className='coluna-6'>Código</td>
                        <td className='coluna-6'>Descrição</td>
                    </tr>,
                tbody: dados.grupos == undefined || dados.grupos.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.grupos, 'id').map(item =>
                        <tr>
                            <td>{item.value}</td>
                            <td>{item.label}</td>
                        </tr>)
            }
        ].concat(tipoUsuario(dados, true))
    } : { titulo: 'Visualização de Usuário' }

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoUsuario