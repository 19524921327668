import { InputNumero, InputPadrao, InputTelefone, TextArea } from '../../../../components/Inputs'
import React, { useState, useEffect } from 'react'
import Yup, { validaCNPJ } from '../../../../util/Validacoes'
import { HeaderCadastro } from '../../../../components/Headers'
import CadastroEndereco from '../../../parametros/endereco/pages/Cadastro'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens, ajustarDadosTelefone } from '../../../../util/Utilitarios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css'
import Atalho from '../../../../util/Atalho'
import { InputSimNao } from '../../../../components/InputsAutoComplete'

export default props => {
    let { id } = useParams()
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({value: 'true', label: 'Sim'})
    const [dataTelefone, setDataTelefone] = useState([])
    const [abrirModal, setAbrirModal] = useState(false)
    const [enderecos, setEnderecos] = useState([])
    const [validacao] = useState(Yup.object({
        razao_social: Yup.string().required().nullable().max(200).trim(),
        nome_fantasia: Yup.string().required().nullable().max(200).trim(),
        cnpj: Yup.string().required().nullable().test('CNPJ', 'CNPJ inválido!', function (value) { return validaCNPJ(this.parent.cnpj) }),
        inscricao_ans: Yup.string().nullable().max(50),
        telefone: Yup.string().nullable(),
        email: Yup.string().nullable().email().max(200),
        site: Yup.string().nullable().max(200),
        observacao: Yup.string().nullable().max(500),
        ativo: Yup.string().nullable()
    }))

    const manter = []

    const Submit = (values, propsFormik) => {
        setSalvando(true)

        let obj = { ...values }
        obj.endereco = [...enderecos]
        obj.telefone = ajustarDadosTelefone(dataTelefone.length > 0 ? dataTelefone : values.telefone)

        salvar(
            obj,
            '/administracao/operadoraSaude',
            obj => {
                if (props.modal) {
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        setEnderecos([])
                        setDataTelefone([])
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else {
                        props.setItemSelecionado({ value: obj.id, label: obj.nome_fantasia })
                        props.setShow && props.setShow(false)
                    }
                }
                else {
                    mostrarToast('sucesso', mensagens.sucesso)
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        setEnderecos([])
                        setDataTelefone([])
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else
                        props.history.push('/administracao/operadoraSaude/consulta')
                }
            },
            () => setSalvando(false))
    }

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Administrativo</span>,
        () => <Link className='link-active' to={'/administracao/operadoraSaude/consulta'}>Operadora de Saúde</Link>,
    ]

    useEffect(() => {
        if (id) {
            Promise.resolve(get(`/administracao/operadoraSaude/${id}`)).then(obj => {
                if (id && !obj.id)
                    props.history.push('/faturamento/tabelaProcedimento/consulta')

                obj && setDataTelefone(obj.telefone)
                obj && setEnderecos(obj.endereco)

                delete obj.telefone
                delete obj.endereco

                setValoresIniciais(obj)
            })
        } else {
            setValoresIniciais({
                razao_social: "",
                nome_fantasia: "",
                cnpj: "",
                inscricao_ans: "",
                telefone: "",
                email: "",
                site: "",
                ativo: { value: true, label: 'Sim' },
                codigo_coopanestgo: "",
                observacao: "",
            })
        }
    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>
                {!props.modal && <HeaderCadastro titulo={"Cadastro Operadora de Saúde"} link={links} />}
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={props.modal || 'card-body'}>
                        <div className='row'>
                            <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' />
                            <InputPadrao label='Razão Social' name='razao_social' type='text' placeholder='Razão Social' obrigatorio={true} tamanho={!props.modal && 'col-lg-4'}
                                classeTooltip={props.modal && 'tooltip-input-modal'} autoFocus salvarENovo={salvarENovo} />
                            <InputPadrao label='Nome Fantasia' name='nome_fantasia' type='text' placeholder='Nome Fantasia' obrigatorio={true} tamanho={!props.modal && 'col-lg-4'}
                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputPadrao label='CNPJ' name='cnpj' type='text' placeholder='CNPJ' obrigatorio={true} tamanho={!props.modal && 'col-lg-4'} mascara="11.111.111/1111-11"
                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputPadrao label='Inscrição ANS' name='inscricao_ans' type='text' placeholder='Inscrição ANS' tamanho={!props.modal && 'col-lg-3'}
                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputTelefone label='Telefone' name='telefone' type='text' placeholder='Telefone' tamanho={!props.modal && 'col-lg-3'} data={dataTelefone}
                                classeTooltip={props.modal && 'tooltip-input-modal'} setData={setDataTelefone} />
                            <InputPadrao label='E-mail' name='email' type='text' placeholder='E-mail' tamanho={!props.modal && 'col-lg-3'} lower={true}
                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputPadrao label='Site' name='site' type='text' placeholder='Site' tamanho={!props.modal && 'col-lg-3'} lower={true}
                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputSimNao label="Ativo?" name="ativo" placeholder="Ativo?" tamanho={!props.modal && 'col-lg-2'} classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} isClearable={true}/>
                            <InputNumero label='Identificação' propsFormik={propsFormik} name='codigo' classDiv={!props.modal && 'col-lg-2' || 'col-lg-12'} max={9999999999}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <InputNumero label='Código Cooperativa' propsFormik={propsFormik} name='codigo_coopanestgo' classDiv={!props.modal && 'col-lg-2' || 'col-lg-12'} max={9999999999}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} />                     
                            <TextArea label='Observação' name='observacao' type='text' placeholder='Observação' tamanho={!props.modal && 'col-lg-6'} rows={2}
                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                        </div>
                    </div>
                </div>
                {!props.modal &&
                    <div className='mt-40'>
                        <CadastroEndereco setEnderecos={setEnderecos} enderecos={enderecos} abrirModal={abrirModal} setAbrirModal={setAbrirModal} />
                    </div>}
                {!props.modal && <div className='separator mt-40 mb-5'></div>}
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)}
        </Formik>
    )
}