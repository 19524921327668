import { InputPadrao, InputDatePicker } from '../../../../components/Inputs'
import { InputValorNovo } from '../../../../components/InputsV2'
import { removeAcentos, formatMoeda } from '../../../../util/FuncoesComuns'
import { HeaderCadastro } from '../../../../components/Headers'
import Yup, { validaData, periodoData } from '../../../../util/Validacoes'
import { ModalCadastro } from '../../../../components/Modais'
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { Formik, Form } from 'formik'
import React, { useState } from 'react'
import moment from "moment"
import Atalho from '../../../../util/Atalho'


export default props => {
    const [pesquisa, setPesquisa] = useState('')
    const [msgBotao, setMsgBotao] = useState('Adicionar')
    const [salvando, setSalvando] = useState(false)

    const emptyValues = {
        id: null,
        verificador: null,
        data_inicio: null,
        data_fim: null,
        valor: null
    }

    const [values, setValues] = useState(emptyValues)

    const validar = obj => {
        let msg = []

        if (props.dataInicio && props.dataFim) {
            const inicio = moment(obj.data_inicio, 'DD/MM/YYYY')
            const fim = moment(obj.data_fim, 'DD/MM/YYYY')
            const contratoInicio = moment(props.dataInicio, 'DD/MM/YYYY')
            const contratoFim = moment(props.dataFim, 'DD/MM/YYYY')

            if (inicio < contratoInicio || inicio > contratoFim || fim < contratoInicio || fim > contratoFim)
                msg.push('Datas da consulta devem estar dentro da vigência do contrato!')
        }

        if (props.arrayDados.length) {
            const inicio = moment(obj.data_inicio, 'DD/MM/YYYY')
            const fim = moment(obj.data_fim, 'DD/MM/YYYY')

            const result = props.arrayDados.filter(x => {
                const arrayInicio = moment(x.data_inicio, 'DD/MM/YYYY')
                const arrayFim = moment(x.data_fim, 'DD/MM/YYYY')

                return (inicio >= arrayInicio && inicio <= arrayFim || fim > arrayInicio && fim <= arrayFim) && obj.verificador !== x.verificador
            })

            if (result.length)
                msg.push('Datas da consulta não podem ter interposição!')
        }

        return msg
    }

    const Submit = (obj, { resetForm }) => {
        const resultadoValidacao = validar(obj)
        setSalvando(true);

        if (resultadoValidacao.length) {
            resultadoValidacao.map(x => mostrarToast('erro', x))
            return
        }

        let copiaArrayDados = [...props.arrayDados]

        if (obj.verificador)
            copiaArrayDados[copiaArrayDados.findIndex(x => x.verificador === obj.verificador)] = obj
        else {
            obj.verificador = Math.random()
            copiaArrayDados.push(obj)
        }

        props.setArrayDados(copiaArrayDados)
        resetForm({})

        if (msgBotao === 'Alterar')
            props.setAbrirModal(!props.abrirModal)

        setSalvando(false)
    }

    const Delete = index => {
        let copiaArrayDados = [...props.arrayDados]
        copiaArrayDados.splice(index, 1);
        props.setArrayDados(copiaArrayDados)
    }

    return (
        <>
            <HeaderCadastro
                pesquisa={true}
                valorPesquisa={pesquisa}
                onChangePesquisa={pesquisa => setPesquisa(pesquisa)}
                classeChildren='space-between-100' >
                <BotaoPadrao type='button' classe='btn-primary primary p-5rem height-auto m-r-15' texto={<><span> Adicionar</span></>}
                    click={_ => { setValues(emptyValues); props.setAbrirModal(!props.abrirModal); setMsgBotao('Adicionar') }} />
            </HeaderCadastro>
            <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                <thead>
                    <tr>
                        <th style={{ width: '5%' }}>#</th>
                        <th style={{ width: '30%' }}>Data Início</th>
                        <th style={{ width: '30%' }}>Data Fim</th>
                        <th style={{ width: '20%' }}>Valor</th>
                        <th style={{ width: '15%' }} className='text-center'>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.arrayDados && props.arrayDados.length ?
                            props.arrayDados.filter(x =>
                                removeAcentos(x.data_inicio.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase()))
                                || removeAcentos(x.data_fim.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())))
                                .map((x, index) =>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{x.data_inicio}</td>
                                        <td>{x.data_fim}</td>
                                        <td>{x.valor && formatMoeda(x.valor)} </td>
                                        <td className='text-center'>
                                            <span className='icon-consulta icon-Pen'
                                                onClick={_ => { setValues(x); props.setAbrirModal(true); setMsgBotao('Alterar') }} />
                                            <span className='icon-consulta icon-New-Trash' onClick={target => Delete(index)} />
                                        </td>
                                    </tr>
                                )
                            : <tr><td colspan='5' className='text-center'>Nenhum Registro Cadastrado</td></tr>
                    }
                </tbody>
            </table>
            <ModalCadastro titulo={'Contrato Consulta'} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    enableReinitialize={true}
                    initialValues={values}
                    validationSchema={Yup.object({
                        data_inicio: Yup.string().ensure().required().nullable()
                            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicio) })
                            .test('Data Inicial', 'Data Inicial deve ser anterior à Data Final.', function (value) { return periodoData(value, this.parent.data_fim) }),
                        data_fim: Yup.string().ensure().required().nullable()
                            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_fim) })
                            .test('Data Final', 'Data Inicial deve ser anterior à Data Final.', function (value) { return periodoData(this.parent.data_inicio, value) }),
                        valor: Yup.string().ensure().required().nullable(),
                    })}
                    onSubmit={Submit}>
                    {propsFormik => <Form>
                        <div className='modal-body-cadastro'>
                            <InputPadrao label='Id' name='id' type='hidden' />
                            <InputDatePicker label="Data Inicial" name="data_inicio" type="text" classeTooltip={'tooltip-input-modal'} obrigatorio autoFocus
                                setarValor={value => { propsFormik.setFieldValue("data_inicio", value) }} placeholder="Data Inicial" tamanho='col-lg-12' focarAposSubmit={true} />
                            <InputDatePicker label="Data Final" name="data_fim" type="text" classeTooltip={'tooltip-input-modal'} obrigatorio tamanho='col-lg-12'
                                setarValor={value => { propsFormik.setFieldValue("data_fim", value) }} placeholder="Data Final" />
                            <InputValorNovo value={propsFormik.values.valor_formatado || propsFormik.values.valor} label='Valor' name={`valor`} classDiv='col-lg-12' propsFormik={propsFormik} isDisable={false} max={999} prefix={'R$ '} obrigatorio />
                            <Atalho formik={propsFormik} salvando={salvando} />
                        </div>
                        <div className='direita container-botoes'>
                            <BotaoPadrao texto={msgBotao} />
                        </div>
                    </Form>}
                </Formik>
            </ModalCadastro>
        </>)
}