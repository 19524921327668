import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
var format = require('xml-formatter');


export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/financeiro/cheque', id,
            obj => {

                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Cheque',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj?.id, tamanho: 'col-lg-4' },
                                { titulo: 'Banco', descricao: obj?.banco.label, tamanho: 'col-lg-4' },
                                { titulo: 'Agência', descricao: obj?.agencia_formatada, tamanho: 'col-lg-4' },
                                { titulo: 'Conta', descricao: obj?.conta_formatada, tamanho: 'col-lg-4' },
                                { titulo: 'Valor', descricao: obj?.valor_total_formatado, tamanho: 'col-lg-4' },
                                { titulo: 'Número', descricao: obj?.numero, tamanho: 'col-lg-4' },
                                { titulo: 'Nominal', descricao: obj?.nominal, tamanho: 'col-lg-4' },
                                { titulo: 'Data Emissão', descricao: obj?.data_emissao, tamanho: 'col-lg-4' },
                                { titulo: 'Data Predatado', descricao: obj?.data_predatado, tamanho: 'col-lg-4' },
                                { titulo: 'Data Compensado', descricao: obj?.data_compensado, tamanho: 'col-lg-4' },
                                { titulo: 'Data Devolução', descricao: obj?.data_devolucao, tamanho: 'col-lg-4' },
                                { titulo: 'Motivo Devolução', descricao: obj?.motivo_devolucao, tamanho: 'col-lg-4' },
                                { titulo: 'Data Registro', descricao: obj?.data_registro, tamanho: 'col-lg-4' },
                            ]
                        }
                    ]
                } : { titulo: 'Visualização Cheque' })

            })

    }, [id])


    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}
