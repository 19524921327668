import React, { useState, useEffect } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'

export default props => {
    const [data, setData] = useState([])

    useEffect(() => {
        consultar('/faturamento/porteanestesico', (dados) => setData(dados.sort((a, b) => b.id - a.id)))
    }, [])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-porte-anestesico',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },{
                        Header: 'Ano',
                        accessor: 'ano',
                        ordenamento: {
                            tipo: 'numero'
                        },
                        filtro: {
                            tipo: 'ano',
                            idColuna: 'ano',
                        }
                    },{
                        Header: 'Inicio',
                        accessor: 'inicio',
                        ordenamento: {
                            tipo: 'data'
                        }
                    },{
                        Header: 'Fim',
                        accessor: 'fim',
                        ordenamento: {
                            tipo: 'data'
                        }
                    },{
                        Header: 'Acomodação(ões)',
                        accessor: 'acomodacoes',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'acomodacao',
                            idColuna: 'acomodacao',
                        }
                    },{
                        Header: 'Tabela(s)',
                        accessor: 'tabelas',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'tabela',
                            idColuna: 'tabela',
                        }
                    },{
                        Header: 'Data de Registro',
                        accessor: 'data_registro',
                        ordenamento: {
                            tipo: 'data'
                        }
                    }
                ],
            },
        ],
        []
    )

    const caminho = {cadastro: '/faturamento/porteanestesico/cadastro',}

    return (
            <div>
                <DataGridAlternativa data={data} columns={columns} caminhoBase='/faturamento/porteanestesicoparticular' setData={setData} acoes={true} url={caminho} modulo={props.modulo}
                    titulo={'Consulta Porte Anestésico Particular'} tituloLista={'Lista Porte Anestésico Particular'} urlCadastro='/faturamento/porteanestesicoparticular/cadastro' icone={'icon-File-ClipboardTextImage'} caminhoConsultaFiltros='/faturamento/porteanestesicoparticularpaginacao' />
            </div>
    )
}