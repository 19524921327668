import { InputCpfCnpj, InputDatePicker, InputPadrao, InputSwitch, InputTelefone, TextArea } from '../../../../components/Inputs'
import React, { useState, useEffect, useRef, useMemo, useDebugValue } from 'react'
import { Formik, Form } from "formik"
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { get, salvar, validar } from '../../../../util/AcessoApi'
import { Link, useParams } from "react-router-dom"
import { ajustarDadosTelefone, buscarCep, mensagens } from '../../../../util/Utilitarios'
import Yup, { validaCNPJ, validaCPF } from '../../../../util/Validacoes'
import '../../../../assets/css/ios-switch/clean-switch.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Atalho from '../../../../util/Atalho'
import { HeaderCadastro } from '../../../../components/Headers'
import { InputMunicipio, InputTipoCliente, InputTipoExecutante, InputMunicipioGOPrimeiro } from '../../../../components/InputsAutoComplete'
import { ModalDuasOpcoes } from '../../../../components/Modais'

const CadastroCliente = (props) => {

    let { id } = useParams()
    let { idModal } = props
    const [dataTelefone, setDataTelefone] = useState([])
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao] = useState(Yup.object({
        tipo_pessoa: Yup.object().required().nullable(),
        tipo_cliente: Yup.object().required().nullable(),
        nome: Yup.string().required().nullable(),
        cpf_cnpj: Yup.string().nullable().test('CPF/CNPJ', 'CPF/CNPJ inválido', function (value) { return value && value.replace(/\D/g, '').length > 11 ? validaCNPJ(this.parent.cpf_cnpj) : validaCPF(this.parent.cpf_cnpj) })

    }))
    const [showModalValidaCpfCnpj, setShowModalValidaCpfCnpj] = useState(false)
    const [msgValidacao, setMsgValidacao] = useState([])


    const inputCpfCnpj = useRef();

    const validarSubmit = async (values, propsFormik) => {
        setSalvando(true)

        Promise.resolve(validar('/financeiro/validarCpfCnpjCliente', values, () => Submit(values, propsFormik), (e) => {
            setShowModalValidaCpfCnpj(true)
            setMsgValidacao(<><div dangerouslySetInnerHTML={{ __html: e?.response && e?.response?.data?.alertas.map(x => x.replace(/"/g, '') + '<br/><br/>').join('') }} /></>)
            setSalvando(false)
        }))
    }

    const manter = []

    const Submit = (values, propsFormik) => {
        setSalvando(true)

        values.fone = ajustarDadosTelefone(dataTelefone.length > 0 ? dataTelefone : values.telefone)

        salvar(
            values,
            '/financeiro/cliente',
            (obj) => {
                if (props.modal) {
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else if (!idModal) {
                        props.setItemSelecionado({ value: obj.item_selecionado.value, label: obj.item_selecionado.label })
                        props.setShow && props.setShow(false)
                    }
                    else {

                        if (props.idTela && props.rotaTela) {
                            values.idTela = props.idTela
                            salvar(
                                values,
                                props.rotaTela, _ => {
                                    propsFormik.resetForm({})
                                    props.setShow && props.setShow(false)
                                    mostrarToast('sucesso', mensagens.sucesso)
                                },
                                (err) => { setSalvando(false); console.log('ERR ', err) })
                        }
                        // propsFormik.resetForm({})
                        // props.setShow && props.setShow(false)
                        // mostrarToast('sucesso', mensagens.sucesso)

                    }
                } else {
                    mostrarToast('sucesso', mensagens.sucesso)
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        // manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else
                        props.history.push('/financeiro/cliente/consulta')

                    setSalvando(false)
                }

            },

            (err) => {
                console.log('err: ', err);
                mostrarToast('erro', mensagens.erro)
                setSalvando(false)
            })
    }


    useEffect(_ => {
        if (props.modal && idModal)
            id = idModal
        if ((!props.modal && id) || idModal) {

            Promise.resolve(get(`/financeiro/cliente/${id}`)).then(obj => {
                setValoresIniciais(obj)
                if (obj.fone)
                    setDataTelefone(obj.fone)
            })
        } else {
            setValoresIniciais({
                tipo_pessoa: { value: 1, label: 'Pessoa Física' },
                tipo_cliente: { value: 7, label: 'BENEFICIÁRIO' },
                sigla: '',
                nome: '',
                cpf_cnpj: '',
                data_nascimento: '',
                email: '',
                registro_conselho: '',
                identificador: '',
                responsavel: '',
                portal: '',
                inscricao_municipal: '',
                cep: '',
                logradouro: '',
                complemento: '',
                numero: '',
                setor: '',
                municipio: '',
                observacao: ''

            })
        }
    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
            {propsFormik => (<Form>
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={props.modal || 'card-body'}>
                        <div className='row'>

                            <InputTipoExecutante name='tipo_pessoa' label='Tipo Pessoa' placeholder='Tipo Pessoa' propsFormik={propsFormik} obrigatorio
                                tamanho={!props.modal && 'col-lg-2'} classeTooltip={props.modal && 'tooltip-input-modal'} autoFocus />

                            <InputPadrao tamanho={!props.modal && 'col-lg-2'} label="Sigla/Fantasia/Nome Social" name="sigla" type="text" placeholder="Sigla/Fantasia/Nome Social" classeTooltip={props.modal && 'tooltip-input-modal'} />

                            <InputPadrao tamanho={!props.modal && 'col-lg-4'} label="Nome/Razão" name="nome" type="text" placeholder="Nome/Razão" classeTooltip={props.modal && 'tooltip-input-modal'}
                                obrigatorio />
                            <InputCpfCnpj
                                tamanho={!props.modal && "col-lg-3 col-xl-2"}
                                label="CPF/CNPJ"
                                name="cpf_cnpj"
                                type="text"
                                mascara
                                value={propsFormik.values.cpf_cnpj}
                                placeholder="CPF/CNPJ"
                                classeTooltip={props.modal && 'tooltip-input-modal'} />

                            <InputDatePicker
                                label='Data Nascimento'
                                name='data_nascimento'
                                placeholder='Data Nascimento'
                                tamanho={!props.modal && 'col-lg-2'}
                                propsFormik={propsFormik}
                                setarValor={value => {
                                    propsFormik.setFieldValue("data_nascimento", value)
                                }}
                            />

                            <InputTelefone
                                tamanho={!props.modal && "col-xl-3 col-lg-3"}
                                label="Telefone"
                                name="telefone"
                                type="text"
                                placeholder="Telefone" data={dataTelefone} setData={setDataTelefone}
                            />
                            <InputPadrao label='E-mail' name='email' type='text' placeholder='E-mail'
                                classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-3'} />

                            <InputPadrao label='Portal' name='portal' type='text' placeholder='Portal'
                                classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2'} />

                            <InputPadrao label='Registro Conselho' name='registro_conselho' type='text' placeholder='Registro Conselho'
                                classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2'} />

                            <InputPadrao label='Identificador' name='identificador' type='text' placeholder='Identificador'
                                classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2'} />

                            <InputPadrao label='Responsável' name='responsavel' type='text' placeholder='Responsável'
                                classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2'} />

                            <InputPadrao label='Inscrição Municipal' name='inscricao_municipal' type='text' placeholder='Inscrição Municipal'
                                classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2'} />

                            <InputPadrao label='Inscrição Estadual' name='inscricao_estadual' type='text' placeholder='Inscrição Estadual'
                                classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2'} />

                            <InputPadrao label='CEP' name='cep' type='text' placeholder='CEP' mascara='11111-111'
                                onBlur={e => buscarCep(e, propsFormik)} classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2'} />

                            <InputPadrao label='Logradouro' name='logradouro' type='text' placeholder='Logradouro'
                                classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-4'} />

                            <InputPadrao label='Complemento' name='complemento' type='text' placeholder='Complemento'
                                classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-3'} />

                            <InputPadrao label='Número' name='numero' type='text' placeholder='Número'
                                classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2'} />

                            <InputPadrao label='Setor' name='bairro' type='text' placeholder='Setor'
                                classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-2'} />

                            <InputMunicipioGOPrimeiro label='Município - UF' name='municipio' placeholder='Município - UF'
                                propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-3'} />

                            {console.log('props: ', props)}
                            {!props.rotaTela ? <InputTipoCliente name='tipo_cliente' label='Tipo Cliente' placeholder='Tipo Cliente' propsFormik={propsFormik} obrigatorio
                                tamanho={!props.modal && 'col-lg-2'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                : <></>}
                            <TextArea performance={true} label='Observação' name='observacao' type='text' placeholder='Observação'
                                tamanho={!props.modal && 'col-lg-12'} classeTooltip={'tooltip-input-modal'} />

                        </div>
                    </div>
                </div>
                {!props.modal && <div className='separator mt-40 mb-5'></div>}
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {(props.modal || !id) && (!props.rotaTela) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
                <ModalDuasOpcoes showModal={showModalValidaCpfCnpj} setShowModal={setShowModalValidaCpfCnpj}
                    funcaoOpcao2={() => { Submit(propsFormik.values, propsFormik); setShowModalValidaCpfCnpj(false) }}
                    funcaoOpcao1={() => { setShowModalValidaCpfCnpj(false) }}
                    esconderFechar mensagem={<>{msgValidacao}<br /></>}
                    textoOpcao1={'Fechar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
            </Form>)}
        </Formik>
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Financeiro</span>,
        () => <Link className='link-active' to={'/financeiro/cliente/consulta'}>Cliente</Link>,
    ]
    return (<>
        {!props.modal && <HeaderCadastro titulo={"Cadastro Cliente"} link={links} />}
        <CadastroCliente {...props} />
    </>
    )
}
export { CadastroCliente }
export default Cadastro


