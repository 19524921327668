import React, { useEffect, useState } from 'react'
import { BtnAcao, BtnAcaoConfirmarModal, BtnAcaoLink, Excluir } from '../../../../components/Acoes'
import { CadastroModalLembrete } from '../../lembrete/pages/Cadastro'
import { CadastroModalRegistroAtividade } from '../../registro-atividades/pages/Cadastro'
import { ModalPadraoCadastro } from '../../../../components/Modais'
import '../../../../assets/css/lembrete-agenda.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconeTooltip } from '../../../../components/Icones';
import { get } from '../../../../util/AcessoApi'
import { excluir } from '../../../../util/FuncoesComuns'
import { BotaoPadrao } from '../../../../components/Botoes'
import { getUsuario } from '../../../../util/Configuracoes'
import { ConsoleView } from 'react-device-detect'

const DetalhesLembrete = (props) => {
    const [itemDetalhes, setItemDetalhes] = useState({});

    useEffect(() => {
        setItemDetalhes(props.itemDetalhes)
    }, [props.itemDetalhes])

    return <>
        <div className={'card-right custom-scrollbar' + (props.showLembrete ? ' filtro-ativo' : '')}>
            <div>
                <LembretesNaAgenda filtros={props.filtros} dados={props.itemDetalhes} reload={props.reload} clickCadastroLembrete={props.clickCadastroLembrete} {...props}></LembretesNaAgenda>
            </div>
        </div>
    </>
}

const DetalhesResumoAgrupado = (props) => {
    return <>
        <div className={'card-right custom-scrollbar' + (props.showResumo ? ' filtro-ativo' : '')}>
            <div>
                {Object.keys(props.itemDetalhesResumo).length > 0 ? <ResumoNaAgenda filtros={props.filtros} dados={props.itemDetalhesResumo} reload={props.reload} {...props}></ResumoNaAgenda> : <></>}
                {props.itemDetalhesLembrete.length > 0 ? <hr></hr> : <></>}
                {props.itemDetalhesLembrete.length > 0 ? <LembretesNaAgenda btnClose={false} filtros={props.filtros} dados={props.itemDetalhesLembrete} reload={props.reload} {...props}></LembretesNaAgenda> : <></>}
                {props.itemDetalhesAtividade.length > 0 ? <hr className={props.itemDetalhesLembrete.length > 0 ? 'mt-65' : ''}></hr> : <></>}
                {props.itemDetalhesAtividade.length > 0 ? <RegistroAtividadeNaAgenda btnClose={false} filtros={props.filtros} dados={props.itemDetalhesAtividade} reload={props.reload} {...props}></RegistroAtividadeNaAgenda> : <></>}
                <div className='mt-100'></div>
            </div >
        </div >
    </>
}


const DetalhesResumo = (props) => {
    const [itemDetalhes, setItemDetalhes] = useState({});

    useEffect(() => {
        setItemDetalhes(props.itemDetalhes)
    }, [props.itemDetalhes])

    return <>
        <div className={'card-right custom-scrollbar' + (props.showResumo ? ' filtro-ativo' : '')}>
            <div>
                <ResumoNaAgenda filtros={props.filtros} dados={props.itemDetalhes} reload={props.reload} {...props}></ResumoNaAgenda>
            </div>
        </div>
    </>
}

const DetalhesResumoGrupos = (props) => {
    const [itemDetalhes, setItemDetalhes] = useState({});

    useEffect(() => {
        setItemDetalhes(props.itemDetalhes)
    }, [props.itemDetalhes])

    return <>
        <div className={'card-right custom-scrollbar' + (props.showResumoGrupos ? ' filtro-ativo' : '')}>
            <div>
                <ResumoGrupos filtros={props.filtros} dados={props.itemDetalhes} reload={props.reload} {...props}></ResumoGrupos>
            </div>
        </div>
    </>
}

const DetalhesResumoEscala = (props) => {

    const [itemDetalhes, setItemDetalhes] = useState({});

    useEffect(() => {
        setItemDetalhes(props.itemDetalhes)
    }, [props.itemDetalhes])

    return <>
        <div className={'card-right custom-scrollbar' + (props.showResumoEscalas ? ' filtro-ativo' : '')}>
            <div>
                <ResumoEscalas filtros={props.filtros} dados={props.itemDetalhes} reload={props.reload} {...props}></ResumoEscalas>
            </div>
        </div>
    </>
}

const DetalhesRegistroAtividade = (props) => {
    const [itemDetalhes, setItemDetalhes] = useState({});

    useEffect(() => {
        setItemDetalhes(props.itemDetalhes)
    }, [props.itemDetalhes])

    return <>
        <div style={{ overflowWrap: 'anywhere' }} className={'card-right custom-scrollbar ' + (props.showRegistro ? ' filtro-ativo' : '')}>
            <div>
                <RegistroAtividadeNaAgenda filtros={props.filtros} dados={props.itemDetalhes} reload={props.reload} clickCadastroLembrete={props.clickCadastroLembrete} {...props}></RegistroAtividadeNaAgenda>
            </div>
        </div>
    </>
}

const LembretesBadget = (props) => {
    const [dados, setDados] = useState({})
    const user = getUsuario()

    const reload = () => {
        if (!(props.filtros && props.filtros.data_inicial)) {
            return false;
        }

        Promise.resolve(get('/administracao/obterLembretePorDia', { usuario_id: user.id, ...props.filtros })).then(val => {
            if (val && val.length)
                setDados(val)
            else {
                props.setShowLembrete(false)
                setDados({})
            }
        })
    }
    useEffect(() => {
        reload();
    }, [props.filtros])

    useEffect(() => {
        reload();
    }, [props.atualizarLembrete])

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                props.setShowLembrete(false)
        }, false)
    }, [])

    if (dados.length > 0)
        return (<>
            <span class={"tag tag-lembrete"} onClick={_ => { if (!props.showLembrete) { props.setShowRegistro(false); props.setFiltroAberto(false); props.setShowResumo && props.setShowResumo(false); props.setShowResumoGrupos && props.setShowResumoGrupos(false) } props.setShowLembrete(!props.showLembrete) }}>
                <span class="fonte-branca">
                    {dados.length} <i className='icon-Bell'></i></span> </span>
            <DetalhesLembrete filtros={props.filtros} setShowLembrete={props.setShowLembrete} showLembrete={props.showLembrete} clickCadastroLembrete={props.clickCadastroLembrete} itemDetalhes={dados} reload={reload} />
        </>
        )
    else
        return (<></>)
}

const ResumoBadget = (props) => {
    const [dados, setDados] = useState({})
    const user = getUsuario()

    const reload = () => {
        if (!(props.filtros && props.filtros.data_inicial)) {
            return false;
        }

        Promise.resolve(get('/administracao/obterresumodia', { usuario_id: user.id, ...props.filtros })).then(val => {
            if (val && val.length) {
                const result = val.reduce(function (r, a) {
                    r[a.agenda_tipo] = r[a.agenda_tipo] || [];
                    r[a.agenda_tipo].push(a);
                    return r;
                }, Object.create(null))

                setDados(result)
            }
            else {
                props.setShowResumo(false)
                setDados({})
            }
        })
    }

    useEffect(() => { reload() }, [props.filtros])

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                props.setShowResumo(false)
        }, false)
    }, [])

    if (Object.keys(dados).length > 0)
        return (<>
            <span class={"tag tag-resumo"} onClick={_ => { if (!props.showResumo) { props.setShowLembrete(false); props.setShowRegistro(false); props.setFiltroAberto(false); props.setShowResumoGrupos && props.setShowResumoGrupos(false) } props.setShowResumo(!props.showResumo) }}>
                <span class="fonte-branca">{Object.keys(dados).length} <i className='icon-Clock'></i></span> </span>
            <DetalhesResumo filtros={props.filtros} setShowResumo={props.setShowResumo} showResumo={props.showResumo} itemDetalhes={dados} reload={reload} />
        </>
        )
    else
        return (<></>)
}

const ResumoAgrupadoBadget = (props) => {
    const [dadosResumo, setDadosResumo] = useState({})
    const [dadosAtividade, setDadosAtividade] = useState({})
    const [dadosLembrete, setDadosLembrete] = useState({})
    const user = getUsuario()

    const reload = () => {
        if (!(props.filtros && props.filtros.data_inicial)) {
            return false;
        }

        Promise.all([
            get('/administracao/obterresumodia', { usuario_id: user.id, ...props.filtros }),
            get('/administracao/obterLembretePorDia', { usuario_id: user.id, ...props.filtros }),
            get('/administracao/obterRegistroAtividadePorDia', { usuario_id: user.id, ...props.filtros })
        ]).then(([resumo, lembrete, atividade]) => {
            const processarResultado = (val) => {
                return val.reduce(function (r, a) {
                    r[a.agenda_tipo] = r[a.agenda_tipo] || []
                    r[a.agenda_tipo].push(a);
                    return r;
                }, Object.create(null))
            }

            resumo.length > 0 ? setDadosResumo(processarResultado(resumo)) : setDadosResumo({})
            atividade.length > 0 ? setDadosAtividade(atividade) : setDadosAtividade({})
            lembrete.length > 0 ? setDadosLembrete(lembrete) : setDadosLembrete({})
        }).catch(error => {
            console.error('Erro ao buscar os resumos:', error);
            props.setShowResumoAgrupado(false);
            setDadosResumo({});
            setDadosAtividade({});
            setDadosLembrete({});
        });
    }

    useEffect(() => {
        reload()
    }, [props.filtros])

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                props.setShowResumo(false)
        }, false)
    }, [])

    useEffect(() => {
        reload();
    }, [props.atualizarLembrete, props.atualizarRegistro])


    if (Object.keys(dadosResumo).length > 0)
        return (<>
            <span class={"tag tag-resumo"} onClick={_ => { if (!props.showResumo) { props.setShowLembrete(false); props.setShowRegistro(false); props.setFiltroAberto(false); props.setShowResumoGrupos && props.setShowResumoGrupos(false) } props.setShowResumo(!props.showResumo) }}>
                <span class="fonte-branca">{Object.keys(dadosResumo).length} <i className='icon-Clock'></i></span> </span>
            <DetalhesResumoAgrupado filtros={props.filtros} setShowResumo={props.setShowResumo} showResumo={props.showResumo} itemDetalhesResumo={dadosResumo} itemDetalhesLembrete={dadosLembrete} itemDetalhesAtividade={dadosAtividade} reload={reload} />
        </>
        )
    else
        return (<></>)
}

const ResumoGruposBadget = (props) => {
    const [dados, setDados] = useState({})
    const user = getUsuario()

    const reload = () => {
        if (!(props.filtros && props.filtros.data_inicial)) {
            return false;
        }

        Promise.resolve(get('/administracao/obterresumogrupos', { usuario_id: user.id, ...props.filtros })).then(val => {
            if (val && val.length) {
                setDados(val)
            }
            else {
                props.setShowResumoGrupos(false)
                setDados({})
            }
        })
    }

    useEffect(() => { reload() }, [props.filtros])

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                props.setShowResumoGrupos(false)
        }, false)
    }, [])

    if (dados.length > 0)
        return (<>
            <span class={"tag tag-resumo-grupos"} onClick={_ => { if (!props.showResumoGrupos) { props.setShowLembrete(false); props.setShowRegistro(false); props.setFiltroAberto(false); props.setShowResumo && props.setShowResumo(false) } props.setShowResumoGrupos(!props.showResumoGrupos) }}>
                <span class="fonte-branca">{dados.length} <i className='icon-Mens'></i></span> </span>
            <DetalhesResumoGrupos filtros={props.filtros} setShowResumoGrupos={props.setShowResumoGrupos} showResumoGrupos={props.showResumoGrupos} itemDetalhes={dados} reload={reload} />
        </>
        )
    else
        return (<></>)
}

const RegistroAtividadeBadget = (props) => {
    const [dados, setDados] = useState({})
    const user = getUsuario()

    const reload = () => {
        if (!(props.filtros && props.filtros.data_inicial)) {
            return false;
        }

        Promise.resolve(get('/administracao/obterRegistroAtividadePorDia', { usuario_id: user.id, ...props.filtros })).then(val => {
            if (val && val.length)
                setDados(val)
            else {
                props.setShowRegistro(false)
                setDados({})
            }
        })
    }

    useEffect(() => {
        reload();
    }, [props.filtros])

    useEffect(() => {
        reload()
    }, [props.atualizarRegistro])

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                props.setShowRegistro(false)
        }, false)
    }, [])

    if (dados.length > 0)
        return (<>
            <span class={"tag tag-registro"} onClick={() => { if (!props.showRegistro) { props.setShowLembrete(false); props.setFiltroAberto(false); props.setShowResumo && props.setShowResumo(false); props.setShowResumoGrupos && props.setShowResumoGrupos(false) }; props.setShowRegistro(!props.showRegistro) }}>
                <span class="fonte-branca">
                    {dados.length} <i className='icon-Address-Book2'></i> </span> </span>
            <DetalhesRegistroAtividade filtros={props.filtros} setShowRegistro={props.setShowRegistro} showRegistro={props.showRegistro} clickCadastroLembrete={props.clickCadastroLembrete} itemDetalhes={dados} reload={reload} />
        </>
        )
    else
        return (<></>)
}


const ResumoEscalasBadget = (props) => {

    const [dados, setDados] = useState({})
    const user = getUsuario()

    const reload = () => {
        if (!(props.filtros && props.filtros.data_inicial)) {
            return false;
        }

        Promise.resolve(get('/administracao/obterresumoescalas', { usuario_id: user.id, ...props.filtros })).then(val => {
            if (val && val.length) {
                setDados(val)
            }
            else {
                props.setShowResumoEscalas(false)
                setDados({})
            }
        })
    }

    useEffect(() => { reload() }, [props.filtros])

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                props.setShowResumoEscalas(false)
        }, false)
    }, [])

    if (dados.length > 0)
        return (<>
            <span class={"tag tag-resumo-escalas"} onClick={_ => { if (!props.showResumoEscalas) { props.setShowLembrete(false); props.setShowRegistro(false); props.setFiltroAberto(false); props.setShowResumo && props.setShowResumo(false); props.setShowResumoGrupos(false) } props.setShowResumoEscalas(!props.showResumoEscalas) }}>
                <span class="fonte-branca">{dados.length} <i className='icon-Mens'></i></span> </span>
            <DetalhesResumoEscala filtros={props.filtros} setShowResumoEscalas={props.setShowResumoEscalas} showResumoEscalas={props.showResumoEscalas} itemDetalhes={dados} reload={reload} />
        </>
        )
    else
        return (<></>)
}


const LembretesNaAgenda = (props) => {
    const [showModalCadastrarLembrete, setShowModalCadastrarLembrete] = useState(false)
    const [idAlteracao, setIdAlteracao] = useState(0)

    return (<>
        <div className='filtro'>
            {props.btnClose === false ? <></> : <button class="close" onClick={() => props.setShowLembrete(false)}><span aria-hidden="true">×</span></button>}
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center' style={{ fontSize: '14px' }}>Lembretes:</span>
            </div>
        </div>
        {
            props.dados && props.dados.map((x) =>
                <div class="detalhes pt-2 pb-3 mb-2 detalhes-lembrete">
                    <span className="mb-4" style={{ fontSize: '14px' }}>{x.descricao}</span>
                    <div className='col-hora row-space-between mb-1 mt-2'>
                        <span></span>
                        <span className="span-nome" style={{ fontSize: '14px' }}><b>{x.nome}</b></span>
                    </div>
                    {x.alteravel && <div className="container-botao-direita card-row-agenda">
                        <div className="mr-2">
                            <BtnAcao classeContent="ml-1-between" icone="icon-Pen" texto="Alterar" action={_ => { setShowModalCadastrarLembrete(true); setIdAlteracao(x.id) }} />
                        </div>
                        <BtnAcao classeContent="ml-1-between" icone="icon-Close" texto="Excluir"
                            action={() => {
                                excluir('/administracao/lembrete', x.id, props.reload);
                            }} />

                    </div>}
                </div>
            ).concat(<>
                <CadastroModalLembrete idAlteracao={idAlteracao} dataAtual={props.filtros.data_inicial} abrirModal={showModalCadastrarLembrete} setAbrirModal={setShowModalCadastrarLembrete} funcaoAposSalvar={props.reload} />
                <div className="botao-cadastro-lembrete"><BotaoPadrao textoTooltip="Novo lembrete" icone='icon-Bell' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={() => { setShowModalCadastrarLembrete(true); setIdAlteracao(0) }} /></div></>)
        }
    </>)
}

const ResumoNaAgenda = (props) => {
    return (<>
        <div className='filtro'>
            <button class="close" onClick={() => props.setShowResumo(false)}><span aria-hidden="true">×</span></button>
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center' style={{ fontSize: '14px' }}>Resumo Escalas</span>
            </div>
        </div>
        {
            Object.keys(props.dados).map(x => <div class="detalhes pt-2 pb-3 mb-2 detalhes-lembrete">
                <div className='row-space-between'>
                    <span className="mb-2 bold" style={{ fontSize: '14px' }}>{x}</span>
                </div>
                {
                    props.dados[x].map(y => <div className='col-hora row-space-between mb-1 mt-2' style={{ flexDirection: 'column' }}>
                        <span className="mb-2 bold" style={{ fontSize: '14px' }}>{y.horario}</span>
                        <span className="mb-2" style={{ fontSize: '14px' }}>{y.socio.socio}</span>
                        <span className="mb-2" style={{ fontSize: '14px' }}>{y.socio.troca}</span>
                    </div>)
                }
            </div>)
        }
    </>)
}

const ResumoGrupos = (props) => {
    return (<>
        <div className='filtro'>
            <button class="close" onClick={() => props.setShowResumoGrupos(false)}><span aria-hidden="true">×</span></button>
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center' style={{ fontSize: '14px' }}>Grupo Escala</span>
            </div>
        </div>
        <div class="detalhes pt-2 pb-3 mb-2 detalhes-lembrete">
            {
                props.dados.map(x => <>
                    <div className='row-space-between'>
                        <span className="bold" style={{ fontSize: '14px' }}>{x.descricao_grupo}</span>
                    </div>
                    <div className='col-hora row-space-between mb-3 mt-1' style={{ flexDirection: 'column' }}>
                        <span className="mb-2" style={{ fontSize: '14px' }}>{x.siglas}</span>
                    </div>
                </>)
            }
        </div>
    </>)
}

const ResumoEscalas = (props) => {
    return (<>
        <div className='filtro'>
            <button class="close" onClick={() => props.setShowResumoEscalas(false)}><span aria-hidden="true">×</span></button>
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center' style={{ fontSize: '14px' }}>Escala (Escaladores)</span>
            </div>
        </div>
        {
            Object.keys(props.dados).map(x => <div class="detalhes pt-2 pb-3 mb-2 detalhes-lembrete">
                {
                    <div className='col-hora row-space-between mb-1 mt-2' style={{ flexDirection: 'column' }}>
                        <span className="mb-2 bold" style={{ fontSize: '14px' }}>{props.dados[x].nome}</span>
                        <span className="mb-2" style={{ fontSize: '14px' }}>{props.dados[x].data_inicial + ' - ' + props.dados[x].hora_inicial}</span>
                        <span className="mb-2 " style={{ fontSize: '14px' }}>{props.dados[x].data_final + ' - ' + props.dados[x].hora_final}</span>
                    </div>
                }
            </div>)
        }
    </>)
}

const RegistroAtividadeNaAgenda = (props) => {
    const [showModalCadastrarLembrete, setShowModalCadastrarLembrete] = useState(false)
    const [idAlteracao, setIdAlteracao] = useState(0)
    
    return (<>
        <div className='filtro'>
            {props.btnClose === false ? <></> : <button class="close" onClick={() => props.setShowRegistro(false)}><span aria-hidden="true">×</span></button>}
            <div className='titulo-filtro row-space-between'>
                <span className='vertical-center' style={{ fontSize: '14px' }}>Registros Ativade:</span>
            </div>
        </div>
        {
            props.dados && props.dados.map((x) =>
                <div class="detalhes pt-2 pb-3 mb-2 detalhes-lembrete">
                    <span className="mb-4" style={{ fontSize: '14px' }}><b>{x.data}</b></span>
                    <div className='col-hora row-space-between mb-1 mt-2'>
                        <span className="span-nome" style={{ fontSize: '14px', width: '100%' }}><div dangerouslySetInnerHTML={{ __html: x.conteudo.replace(/(<? *script)/gi, 'illegalscript') }} ></div></span>
                    </div>
                    <span className="span-nome" style={{ fontSize: '14px' }}><b>{x.nome}</b></span>
                    <div className="container-botao-direita card-row-agenda">
                        <div className="mr-2">
                            <BtnAcao classeContent="ml-1-between" icone="icon-Pen" texto="Alterar" action={_ => { setShowModalCadastrarLembrete(true); setIdAlteracao(x.id) }} classTooltip="tooltip-input-modal z-index-max " />
                        </div>
                        <BtnAcaoConfirmarModal classeContent="ml-1-between z-index-max " icone="icon-New-Trash" texto="Excluir" classTooltip="tooltip-input-modal z-index-max "
                            action={() => {
                                excluir('/administracao/registroatividades', x.id, props.reload);
                            }} />
                    </div>
                </div>
            ).concat(<>
                <CadastroModalRegistroAtividade id={idAlteracao} modal idAlteracao={idAlteracao} dataAtual={props.filtros.data_inicial} abrirModal={showModalCadastrarLembrete} setAbrirModal={setShowModalCadastrarLembrete} funcaoAposSalvar={props.reload} />
                <div className="botao-cadastro-lembrete"><BotaoPadrao textoTooltip="Novo Registro" icone='icon-Address-Book2' classe=' btn btn-primary primary btn-filter ' idTooltip={"padrao"} click={() => { setShowModalCadastrarLembrete(true); setIdAlteracao(0) }} /></div></>)
        }
    </>)
}

export { LembretesNaAgenda, LembretesBadget, RegistroAtividadeBadget, DetalhesLembrete, ResumoBadget, ResumoGruposBadget, ResumoEscalasBadget, ResumoAgrupadoBadget }
export default LembretesNaAgenda