import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'

export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/faturamento/cid10', id,
            obj =>
                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização CID-10',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Código CID-10', descricao: obj.codigo, tamanho: 'col-lg-4' },
                                { titulo: 'Descrição', descricao: obj.descricao, tamanho: 'col-lg-4' },
                                { titulo: 'Data de Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                            ]
                        }
                    ]
                } : { titulo: 'Visualização CID-10' })
        )
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}