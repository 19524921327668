import React from 'react'

const ListaSimples = (props) => {
    const {data, setData} = props;
    const removerLinha = (idx) => {
        setData(data.filter((item, idx2) => idx2 !== idx));
    }
    return (<ul className='lista-cards'>
        {data.map((item, idx) => {
        return <li>{props.numerada ? <div className='lista-number'><span>{idx + 1}</span></div> : <></>}{props.render(item, idx)}
        {props.excluivel ? <button className='remove-li' onClick={() => {props.funcaoRemover ? props.funcaoRemover(idx) : removerLinha(idx)}}><span>x</span></button> : <></>}</li>
        })}
    </ul>)
}

const ListaModal = (props) => {
    const {data, setData} = props;
    const removerLinha = (idx) => {
        setData(data.filter((item, idx2) => idx2 !== idx));
    }
    return (<ul className='lista-modal'>
        {data.map((item, idx) => {
        return <li onClick={() => item.onClick ? item.onClick : {}}>{props.numerada ? <div className='lista-number'><span>{idx + 1}</span></div> : <></>}{props.render(item, idx)}
        {props.excluivel ? <button className='remove-li' onClick={() => {props.funcaoRemover ? props.funcaoRemover(idx) : removerLinha(idx)}}><span>x</span></button> : <></>}</li>
        })}
    </ul>)
}

export { ListaSimples }