import React, { useState, useEffect, useRef } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { RecuperarRascunho, Visualizar, VersaoRascunho, RecuperarVersaoRascunho } from '../../../../components/Acoes'
import { getUsuario, getApi, verificarPermissao } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { get, excluir, putComAcao, put, getApiExterna } from '../../../../util/AcessoApi'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens } from '../../../../util/Utilitarios.js'


import { HeaderCadastro } from '../../../../components/Headers'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ModalCadastro } from '../../../../components/Modais'
import moment from 'moment'

const Consulta = (props) => {
    const [data, setData] = useState([])
    const [pageIndex, setPageIndex] = useState()
    const [qtdRegistros, setQtdRegistros] = useState()
    const [showModalVersoes, setShowModalVersoes] = useState()
    const [versoesRascunho, setVersoesRascunho] = useState([])
    const [hashRascunho, setHashRascunho] = useState('')

    const target = useRef(null)
    const history = useHistory()

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-auditoria',
                qtdRegistros: qtdRegistros,
                paginacaoManual: true,
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        // visivel: false,
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Módulo',
                        accessor: 'modulo',
                        ordenamento: {
                            tipo: 'combo',
                        },
                        filtro: {
                            combo: 'combo-alternativa',
                            tipo: 'modulo',
                            idColuna: 'modulo.id'
                        },
                        filtroSomenteModal: true
                    },
                    // {
                    //     Header: 'Código',
                    //     accessor: 'controle',
                    //     ordenamento: {
                    //         tipo: 'numero',
                    //         ativo: true,
                    //         modo: 'desc',
                    //     }
                    // },
                    {
                        Header: 'Ação',
                        accessor: 'acao',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Usuário',
                        accessor: 'usuario',
                        filtro: {
                            combo: 'combo',
                            tipo: 'usuario_auditoria',
                            idColuna: 'usuario_id'
                        },
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Controle',
                        accessor: 'controle',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Identificador',
                        accessor: 'identificador',
                        ordenamento: {
                            tipo: 'texto',
                        }
                    },
                    {
                        Header: 'Data  Ação',
                        accessor: 'data_registro',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            combo: 'combo-simples',
                            idColuna: 'auditoria.data_registro',
                            tipo: 'data_inicial'
                        },
                    },

                ]
            }], []
    )

    const obterVersoesRascunho = (propsAcao) => {
        getApiExterna(process.env.REACT_APP_DRAFT_API + `/drafter/draft/${propsAcao.row.hash}/versions`, result => {
            if (result.data.length > 0) {
                setVersoesRascunho(result.data)
                setHashRascunho(propsAcao.row.hash)
            } else {
                throw Error('Error')
            }

        }, e => console.log('EERO ', e))
    }

    const montarForm = obj => {
        history.push({ pathname: obj.path, state: { guid: obj.id, values: obj.objeto } })

    }

    const reverter = propsAcao => {
        const usuario = getUsuario().id

        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.RECUPERAR_RASCUNHO
        }
        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length) {
                getApiExterna(process.env.REACT_APP_DRAFT_API + '/drafter/draft/' + propsAcao.row.hash, result => montarForm(result.data), e => console.log('EERO ', e))
            }
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const AcoesObj = [
        (propsAcao) => <RecuperarRascunho action={() => reverter(propsAcao)} />,
        (propsAcao) => <VersaoRascunho action={() => {
            setShowModalVersoes(true)
            obterVersoesRascunho(propsAcao)
        }} />
    ]

    return (
        <>
            <div>
                <DataGridAlternativa target={target} data={data} columns={columns} setData={setData} acoesObj={AcoesObj} acoes={true} caminhoBase='/controleacesso/rascunho' ImpressaoOff = {true}
                    tituloLista={'Rascunho'} setPageIndex={setPageIndex} setQtdRegistros={setQtdRegistros} caminhoConsultaFiltros='/controleacesso/rascunho'
                    modulo={props.modulo} titulo={'Consulta Rascunho'} icone={'icon-Box-withFolders'} />
                <ModalVersoes titulo={'Versões Rascunho'} showModal={showModalVersoes} setShowModal={setShowModalVersoes} versoesRascunho={versoesRascunho} />
            </div>
        </>
    )
}

const ModalVersoes = (props) => {

    const history = useHistory()

    const { versoesRascunho } = props
    const [versoes, setVersoes] = useState([])

    useEffect(() => {
        if (versoesRascunho.length > 0) {
            let obj = versoesRascunho.map(x => {


                return {
                    data_modificacao: moment(x.lastModified).format('DD/MM/YYYY HH:mm:ss'),
                    versao: x.versionId,
                    hash: x.key.replace('.json', ''),
                    ultima_versao: x.isLatest
                }
            })

            setVersoes(obj)

        }

    }, [versoesRascunho])

    const montarForm = obj => {
        history.push({ pathname: obj.path, state: { guid: obj.id, values: obj.objeto } })

    }

    const reverterRascunho = (versionId, hash) => {

        getApiExterna(process.env.REACT_APP_DRAFT_API + `/drafter/draft/${hash}/version/${versionId}`, result => {
            montarForm(result.data)
        }, e => console.log('EERO ', e))
    }


    return (

        <>
            <ModalCadastro titulo={props.titulo || 'Informações Lançamento'} show={props.showModal} setShowModal={props.setShowModal} setVersoes={setVersoes} >
                {
                    versoes && versoes.length > 0 ?
                        versoes.map(x => {
                            return (
                                <>
                                    <div className='item-detalhes' >
                                        <div className='detalhes'>
                                            <table>
                                                <tr>
                                                    {x.ultima_versao && <th>Data Modificação <span className="ultima_modificacao"></span></th>}
                                                    {!x.ultima_versao && <th>Data Modificação</th>}
                                                </tr>
                                                <tr>
                                                    <td>{x.data_modificacao}</td>
                                                    <td><RecuperarVersaoRascunho action={() => {
                                                        reverterRascunho(x.versao, x.hash)
                                                    }} /></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )
                        })

                        : <><h6>Carregando...</h6></>
                }
            </ModalCadastro >

        </>
    )
}

export default Consulta