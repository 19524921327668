import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import ContentLoader from "react-content-loader"
import { meses, useWindowSize } from '../util/Utilitarios'

const actualDate = new Date();

const LoadingCombo = ({ label, ...props }) => {
    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    let placeholder = props.placeholder || label;
    let minLength = props.minLength || 0;
    const loadOptionFunc = props.loadOptions || {};
    var inputFocado;
    delete props.loadOptions
    delete props.minLength
    delete props.classeTooltip
    let classTamanho = props.tamanho ? props.tamanho : "col-12";

    return (<div className={"form-group " + classTamanho}>
        {<label htmlFor={props.id || props.name}>{label}{props.obrigatorio != undefined ? "*" : ""}</label>}
        <div className={"input-group"}>
            <div style={{paddingTop: '14px'}}><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> <span>Carregando...</span></div>
        </div>
    </div>)
}

const LoadRelatorio = props => {
    const [parametros, setParamentros] = useState({})
    const [width] = useWindowSize();


    useEffect(() => {
        setParamentros(props.refLoading.current ? {
            width: props.refLoading.current.clientWidth - 45,
            heigth: props.heigth && props.heigth || 550,
            qtdLinhas: 13,
        } : { width: 0, height: 0 })

    }, [props.refLoading.current, width])

    const linhas = _ => {
        let array = []

        for (let index = 15; index < 2015; index += 40)
            array.push(<rect x="0" y={index} rx="5" ry="5" width={parametros.width} height="20" />)

        return array
    }

    return (
        <ContentLoader speed={2} width={parametros.width} height={parametros.heigth} viewBox={`0 0 ${parametros.width} ${parametros.heigth}`}
            backgroundColor="#f3f3f3" foregroundColor="#d8d6d6">
            {linhas()}
        </ContentLoader>
    )
}

export { LoadRelatorio, LoadingCombo }