import React, { useState, useRef } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { Excluir, BtnAcao, PopoverConsultaLancamento } from '../../../../components/Acoes'
import { getUsuario } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { get, excluir } from '../../../../util/AcessoApi'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens, useWindowSize } from '../../../../util/Utilitarios.js'
import { useHistory } from 'react-router-dom'

const ConsultaGuiaMapaHoras = rProps => {
    const [data, setData] = useState([])
    const target = useRef(null)
    const history = useHistory()

    const columns = React.useMemo(
        () => [{
            id: 'consulta-referencia',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'mapa_hora_id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Guia',
                    Placeholder: 'Guia',
                    accessor: 'guia_id',
                    visivel: true,
                    html: item => {
                        return (
                            <>
                                <span className='icones-consulta-lancamento min-width-125'>
                                    {/* <span className='status-lancamento' style={{ background: item.guia_particular_object?.cor }}></span> */}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ marginTop: 8 }}>{item.id}</span>
                                        <span style={{ marginLeft: '5px', marginTop: 8 }}>
                                            <PopoverConsultaLancamento id={item.guia_particular_object?.id} copiado={false} onToggle={() => { }}
                                                titulo={
                                                    <>
                                                        <div className='text-center color-gray' style={{ fontSize: 13 }}>
                                                            <b>Tipo Guia:</b>
                                                        </div>
                                                    </>}
                                                info={
                                                    <div style={{ marginTop: '-15px', padding: '10px', marginLeft: '30px' }}>
                                                        <span><b>{item.guia_particular_object?.descricao}</b></span>
                                                        <br />
                                                    </div>
                                                }>
                                                <span style={{ color: item.guia_particular_object?.cor, fontWeight: 'bold' }}>
                                                    {item.guia_particular_object?.id == 1 ? 'P' : 'C'}
                                                </span>
                                            </PopoverConsultaLancamento>
                                        </span>
                                    </div>
                                </span>


                            </>
                        )
                    }
                },
                {
                    Header: 'Código',
                    Placeholder: 'Para mais de um código utilize "," ',
                    accessor: 'id_guia',
                    visivel: false,
                    apenasDataGrid: true,
                    filtro: {
                        tipo: 'pesquisa-geral',
                        idColuna: 'guia_ids',
                    }
                },
                {
                    Header: 'Cod. Barras',
                    accessor: 'codigo_barras',
                    html: item => <div className='min-width-125' ><span >{item.codigo_barras}</span></div>,
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Op./Est. de Saúde',
                    accessor: 'convenio',
                    visivel: true,
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Paciente',
                    accessor: 'nome_beneficiario',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Cirurgião',
                    accessor: 'nome_cirurgiao',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    visivel: false,
                    filtro: {
                        tipo: 'cirurgiao',
                        idColuna: 'gd.cirurgiao_id',
                    }
                },
                {
                    Header: 'Data Procedimento',
                    accessor: 'data_procedimento',
                    html: item => {
                        return (
                            <>
                                <span className='icones-consulta-lancamento min-width-125'>
                                    {/* <span className='status-lancamento' style={{ background: item.guia_particular_object?.cor }}></span> */}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ marginLeft: '5px', marginTop: 8 }}>
                                            <PopoverConsultaLancamento id={item.guia_particular_object?.id} copiado={false} onToggle={() => { }}
                                                titulo={
                                                    <>
                                                        <div className='text-center color-gray' style={{ fontSize: 13 }}>
                                                            <b>Dta. Proc./Hora:</b>
                                                        </div>
                                                    </>}
                                                info={
                                                    <div style={{ marginTop: '-15px', padding: '10px', marginLeft: '30px' }}>
                                                        <span>
                                                            <span dangerouslySetInnerHTML={{ __html: item.data_proced_concat }} />

                                                        </span>
                                                        <br />
                                                    </div>
                                                }>
                                                <span style={{ fontWeight: 'bold' }}>
                                                    {item.data_procedimento}
                                                </span>
                                            </PopoverConsultaLancamento>
                                        </span>
                                    </div>
                                </span>


                            </>
                        )
                    },
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Sócio Equipe',
                    accessor: 'executantes_internos',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Ref. Produção',
                    accessor: 'referencia_producao',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'referencia_producao',
                        idColuna: 'referencia_producao',
                    }
                },
                {
                    Header: 'Tipo Arquivo',
                    accessor: 'tipo_arquivo',
                    visivel: true,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'tipo_arquivo',
                        idColuna: 'mhd.tipo_arquivo_id',
                    }
                },
                {
                    Header: 'Data Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    },
                },
                {
                    Header: 'Sócio Equipe',
                    accessor: 'executante_interno',
                    visivel: false,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'socio_equipe',
                        idColuna: 's.id'
                    }
                },
                {
                    Header: 'Empresa',
                    accessor: 'empresa',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'empresa',
                        idColuna: 'gd.empresa_id',
                    }
                },
                {
                    Header: 'Op. de Saúde',
                    accessor: 'operadora_saude',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    html: item => <div className='min-width-125' ><span >{item.operadora_saude}</span></div>,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'operadora_saude',
                        idColuna: 'gd.operadora_saude_id'
                    }
                }, {
                    Header: 'Estab. de Saúde',
                    accessor: 'estabelecimento_saude',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'estabelecimento_saude',
                        idColuna: 'gd.estabelecimento_saude_id'
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'guia_status_convenio',
                        idColuna: 'guia_status.id',

                    }
                },
                {
                    Header: 'Data Procedimento inicial',
                    accessor: 'data_inicial_procedimento',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial_procedimento',
                        idColuna: 'data_procedimento_inicial',
                        datePicker: true,
                        date: true

                    }
                }, {
                    Header: 'Data Procedimento Final',
                    accessor: 'data_final_procedimento',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final_procedimento',
                        idColuna: 'data_procedimento_final',
                        datePicker: true,
                        date: true
                    }
                },
                {
                    Header: 'Hora Procedimento Inicial',
                    accessor: 'hora_inicial_procedimento',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'hora_inicial_procedimento',
                        idColuna: 'hora_procedimento_inicial',
                        timePicker: true
                    }
                }, {
                    Header: 'Hora Procedimento Final',
                    accessor: 'hora_final_procedimento',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'hora_final_procedimento',
                        idColuna: 'hora_procedimento_final',
                        timePicker: true
                    }
                },
                {
                    Header: 'Data Registro Inicial',
                    accessor: 'data_inicial',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'data_registro_inicial',
                        datePicker: true,
                        date: true
                    }
                }, {
                    Header: 'Data Registro Final',
                    accessor: 'data_final',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'data_registro_final',
                        datePicker: true,
                        date: true

                    }
                }, {
                    Header: 'Hora Registro Inicial',
                    accessor: 'hora_inicial_registro',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'hora_inicial_registro',
                        idColuna: 'hora_registro_inicial',
                        timePicker: true
                    }
                }, {
                    Header: 'Hora Registro Final',
                    accessor: 'hora_final_registro',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'hora_final_registro',
                        idColuna: 'hora_registro_final',
                        timePicker: true
                    }
                },
                {
                    Header: 'Usuario Inserção',
                    accessor: 'usuario_insercao',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'usuario_top',
                        idColuna: ' gd.usuario_id_insercao',
                    }
                },
                {
                    Header: 'Particular?',
                    accessor: 'particular',
                    visivel: false,
                    filtroSomenteModal: true,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'input_sim_nao',
                        idColuna: 'particular',
                        name: `input_sim_nao_${Math.floor(Math.random() * 1000)}`,
                    }
                },
            ]
        }], []
    )

    const exclusao = (propsAcao) => {
        excluir('/controleproducao/guiamapahora', propsAcao?.row?.mapa_hora_id,
            () => {
                target.current.atualizar();
                mostrarToast('sucesso', mensagens['sucesso'])
            },
            (e) => {
                if (e.response && e.response.data && e.response.data.erros)
                    e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                else
                    mostrarToast('erro', mensagens.erro)
            }, propsAcao.row.descricao_auditoria)
    }

    const acoesObj = [
        (props) => <BtnAcao icone={'icon-File-Search'} texto={'Visualizar'} action={async () => {
            history.push({ pathname: '/faturamento/guia-socios/alterar', state: { visualizacao: true, id: props.id, mapa_hora_id: props.row.mapa_hora_id, producao_mapa_horas: true, particular: props.row.guia_particular } })
        }} />,
        (props) => <BtnAcao icone={'icon-Pen'} texto={'Alterar'} action={async () => {
            history.push({ pathname: '/faturamento/guia-socios/alterar', state: { id: props.id, mapa_hora_id: props.row.mapa_hora_id, producao_mapa_horas: true, alteracao: true, particular: props.row.guia_particular } })
        }} />,
        (propsAcao) => <Excluir to={'/controleproducao/guiamapahora/consulta'} action={() => exclusao(propsAcao)} />,
    ]

    return (
        <>
            <div>
                <DataGridAlternativa target={target} acoesObj={acoesObj} data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/controleproducao/guiamapahoras' ImpressaoOff={true}
                    caminhoConsultaFiltros='/controleproducao/guiamapahorapaginacao' modulo={rProps.modulo} titulo={'Consulta Guia Mapa Hora'} tituloLista={'Lista Guia Mapa Hora'}
                    icone={'icon-Calendar-4'} filtroAtivo={true} nomeSession={'ultimos_filtros_guias_mapa_horas'} />
            </div>
        </>
    )
}

export default ConsultaGuiaMapaHoras