import React, { useState, useRef, useEffect } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { Alterar, Excluir, Visualizar, AlteracaoLote, AlteracaoStatus, HistoricoStatus, Btn, GuiaComplementar, RemoverGuiaComplementar, BtnAcao, LinkArquivos, AlterarComPermissao, AlterarSocioGuia } from '../../../../components/Acoes'
import { BotaoPadrao } from '../../../../components/Botoes'
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES, MODULO } from '../../../../util/Enums'
import { get, excluir, salvar, validar, putComAcao } from '../../../../util/AcessoApi'
import { mostrarToast } from '../../../../components/Toasts'
import { ModalCadastro, ModalDuasOpcoes, ModalVisualizacao } from '../../../../components/Modais'
import { distinctArr, mensagens, useWindowSize } from '../../../../util/Utilitarios.js'
import { Formik, Form } from "formik";
import Yup from '../../../../util/Validacoes';
import { LoadRelatorio } from '../../../../components/Loading'
import { InputDatePicker, InputPadrao, InputTimePicker, TextArea } from '../../../../components/Inputs'
import { InputGuia, InputGuiaStatus, InputGuiaStatusParticular, InputLoteGuia } from '../../../../components/InputsAutoComplete'
import moment from 'moment';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import DetalhesHistorico from '../../guia/pages/DetalhesHistorico'
import { useHistory } from 'react-router-dom'
import InputArquivoS3 from '../../../../components/InputArquivoS3V2'
import ModalLegendaStatus from '../../../../components/ModalLegenda'

const modulo = 107

const ConsultaGuiaParticular = props => {
    const [data, setData] = useState([])

    const [modalAlteracaoStatus, setModalAlteracaoStatus] = useState(false)
    const [modalComplemento, setModalComplemento] = useState(false)
    const [itemTrocarStatus, setItemTrocarStatus] = useState({})
    const [itemComplemento, setItemComplemento] = useState({})
    const target = useRef(null)
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [itemDetalhes, setItemDetalhes] = useState([])
    const [width] = useWindowSize()
    const [dados, setDados] = useState({})
    const history = useHistory()
    const [showModalAposSalvar, setShowModalAposSalvar] = useState(false)
    const [avisoSalvar, setAvisoSalvar] = useState()
    const [modalInserirArquivos, setModalInserirArquivos] = useState(false)
    const [itemArquivos, setItemArquivos] = useState({})
    const [modalLegendaStatus, setModalLegendaStatus] = useState(false)


    useEffect(() => {
        if (history.location.state) {
            setAvisoSalvar(history.location.state)
            setShowModalAposSalvar(true)
            history.push()
        }
    }, [history])

    useEffect(() => {
        get('/faturamento/subtituloCor', null, null, null).then(val => {
            if (val && val.length) {
                setDados(val)
            }
            else {
                setDados({})
            }
        })
    }, [])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-guia',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    html: item => <span>{item.id}<div className='guia-complementar' style={item.complementar != null ? { background: item.cor_guia_complementar } : { background: '' }}></div></span>,
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Código',
                    Placeholder: 'Para mais de um código utilize "," ',
                    accessor: 'id_guia',
                    visivel: false,
                    apenasDataGrid: true,
                    filtro: {
                        tipo: 'pesquisa-geral',
                        idColuna: 'guia_id',
                    }
                },
                {
                    Header: 'Paciente',
                    accessor: 'beneficiario',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'beneficiario',
                        idColuna: 'guia.beneficiario_id',
                    }
                }, {
                    Header: 'Data Proc.',
                    accessor: 'data_procedimento',
                    html: item => <div className='min-width-170' ><span >{item.data_hora_procedimento}</span></div>,
                    ordenamento: {
                        tipo: 'data'
                    }
                },
                {
                    Header: 'Referência (LOTE)',
                    accessor: 'referencia',
                    visivel: false,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    // filtro: {
                    //     tipo: 'referencia_lote',
                    //     idColuna: 'referencia',
                    //     monthPicker: true,
                    // }
                }, {
                    Header: 'Lote',
                    accessor: 'guia_lote_id',
                    visivel: false,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    // filtro: {
                    //     tipo: 'lote',
                    //     idColuna: 'guia_lote_id'
                    // }
                },
                {
                    Header: 'Sócio Equipe',
                    accessor: 'executante_interno',
                    visivel: false,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'socio_equipe',
                        idColuna: 'guia_procedimento_profissional.socio_id'
                    }
                }, {
                    Header: 'Estab. de Saúde',
                    accessor: 'estabelecimento_saude',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'estabelecimento_saude',
                        idColuna: 'guia.estabelecimento_saude_id'
                    }

                },
                {
                    Header: 'Sócio(s)',
                    accessor: 'socios',
                    html: item => <span>{distinctArr(item.socios).join(', ')}</span>,
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Empresa',
                    accessor: 'empresa',
                    visivel: false,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'empresa',
                        idColuna: 'guia.empresa_id',
                    }
                },
                {
                    Header: 'Contrato',
                    accessor: 'contrato',
                    visivel: false,

                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'contrato_particular_filtro',
                        idColuna: 'guia.contrato_id',

                    }
                },
                {
                    Header: 'Cirurgião',
                    accessor: 'cirurgiao',
                    visivel: false,

                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'cirurgiao',
                        idColuna: 'guia.cirurgiao_id',
                    }
                },
                {
                    Header: 'Complementar?',
                    accessor: 'complementar',
                    visivel: false,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'input_sim_nao',
                        idColuna: 'guia_complementar',
                        boolean: true
                    },
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    html: item => <span className='guia-status' style={{ background: item.cor_status }}>{item.status}</span>,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'guia_status_particular',
                        idColuna: 'guia_status.id'
                    }
                }, {
                    Header: 'Valor',
                    accessor: 'valor_total_formatado',
                    html: item => <div className='min-width-125' ><span >{item.valor_total_formatado}</span></div>,
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'N° NFSE',
                    accessor: 'nfse_numero_agg',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Data Procedimento inicial',
                    accessor: 'data_inicial_procedimento',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial_procedimento',
                        idColuna: 'data_procedimento_inicial',
                        datePicker: true,
                        date: true

                    }
                }, {
                    Header: 'Data Procedimento Final',
                    accessor: 'data_final_procedimento',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final_procedimento',
                        idColuna: 'data_procedimento_final',
                        datePicker: true,
                        date: true
                    }
                },
                {
                    Header: 'Hora Procedimento Inicial',
                    accessor: 'hora_inicial_procedimento',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'hora_inicial_procedimento',
                        idColuna: 'hora_procedimento_inicial',
                        timePicker: true
                    }
                }, {
                    Header: 'Hora Procedimento Final',
                    accessor: 'hora_final_procedimento',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'hora_final_procedimento',
                        idColuna: 'hora_procedimento_final',
                        timePicker: true
                    }
                },
                {
                    Header: 'Data Registro',
                    accessor: 'data_registro_formatada',
                    html: item => <div className='min-width-125' ><span >{item.data_registro_formatada}</span></div>,
                    ordenamento: {
                        tipo: 'data'
                    }
                },
                {
                    Header: 'Data Registro Inicial',
                    accessor: 'data_inicial',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'data_registro_inicial',
                        datePicker: true,
                        date: true
                    }
                }, {
                    Header: 'Data Registro Final',
                    accessor: 'data_final',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'data_registro_final',
                        datePicker: true,
                        date: true

                    }
                }, {
                    Header: 'Hora Registro Inicial',
                    accessor: 'hora_inicial_registro',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'hora_inicial_registro',
                        idColuna: 'hora_registro_inicial',
                        timePicker: true
                    }
                }, {
                    Header: 'Hora Registro Final',
                    accessor: 'hora_final_registro',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'hora_final_registro',
                        idColuna: 'hora_registro_final',
                        timePicker: true
                    }
                }, {

                    Header: 'Usuario Inserção',
                    accessor: 'usuario_insercao',
                    visivel: false,

                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'usuario_top',
                        idColuna: ' guia.usuario_id_insercao',

                    }
                },
                {
                    Header: 'Usuario Alteração',
                    accessor: 'usuario_id_alteracao',
                    visivel: false,

                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'usuario_alteracao_top',
                        idColuna: ' guia.usuario_id_alteracao',
                    }
                },
                {
                    Header: 'Data Alteração Inicial',
                    accessor: 'data_ultima_alteracao_inical',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'data_alteracao_inicial',
                        name: `data_alteracao_inicial`,
                        datePicker: true,
                        date: true
                    }
                }, {
                    Header: 'Data Alteração Final',
                    accessor: 'data_ultima_alteracao_final',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'data_alteracao_final',
                        name: `data_alteracao_final`,
                        datePicker: true,
                        date: true

                    }
                }
            ]
        }], []
    )

    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/faturamento/guia-particular', propsAcao.row.id,
                    () => {
                        // getApi(setData, '/faturamento/guia')
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const OutrasAcoes = ({ propsAcao, ...props }) => {
        return (
            <Dropdown >
                <DropdownTrigger>
                    <Btn icone='icon-ellipsi ml-15' texto="Mais Ações" />
                </DropdownTrigger>
                <DropdownContent>
                    <div className="card-acoes-dropdown">
                        <div className="ajusta-dropdown">
                            {[
                                <AlteracaoStatus action={() => {
                                    setItemTrocarStatus(propsAcao.row);
                                    setModalAlteracaoStatus(true)
                                }} />,
                                <HistoricoStatus action={() => {
                                    obterDetalhes(propsAcao.row);
                                    setShowModalDetalhes(true);
                                }} />,
                                !propsAcao.row.guia_complementar && <GuiaComplementar action={() => {
                                    setItemComplemento(propsAcao.row);
                                    setModalComplemento(true);
                                }} />,
                                propsAcao.row.guia_complementar && <RemoverGuiaComplementar action={() => {
                                    removerComplento(propsAcao, target)
                                }} />,
                                <LinkArquivos action={async () => {
                                    await Promise.resolve(get('/controleAcesso/permissao', objPermissaoArquivos).then(function (val) {
                                        if (val.length > 0) {

                                            setItemArquivos(propsAcao.row)
                                            setModalInserirArquivos(true)
                                        }
                                        else {
                                            mostrarToast('erro', 'O usuário não possui permissão para esta ação! Contate um administrador.')
                                        }
                                    }).catch((e) => mostrarToast('erro', mensagens['erro'])))
                                }} />,
                                <AlterarSocioGuia action={async () => {
                                    // history.push({ pathname: '/faturamento/conferencia/cadastro', state: { id: propsAcao.row.id } })
                                    get('/controleAcesso/permissao', { usuario_id: getUsuario().id, modulo_id: 107, acao_id: ACOES.ALTERAR_SOCIO_GUIA }).then(function (val) {
                                        if (val.length)
                                            history.push({ pathname: '/faturamento/guia-socios/alterar', state: { id: propsAcao.row.id, alteracao_socio_guia: true, alteracao: true, particular: true, rotaConsulta: '/faturamento/guia-particular/consulta' } })

                                        else
                                            mostrarToast('erro', mensagens['permissao'])
                                    }).catch((e) => mostrarToast('erro', mensagens['erro']))

                                }} />,
                            ]}
                        </div>
                    </div>
                </DropdownContent>
            </Dropdown>
        )
    }
    const objPermissaoArquivos = {
        usuario_id: getUsuario().id,
        modulo_id: 107,
        acao_id: ACOES.INSERIR_ARQUIVOS_GUIA_PARTICULAR
    }

    const acoesObj = [
        (propsAcao) => <Visualizar to={'/faturamento/guia-particular/visualizacao/' + propsAcao.row.id} />,
        (propsAcao) => <AlterarComPermissao to={'/faturamento/guia-particular/alteracao/' + propsAcao.row.id} modulo={modulo} acao={propsAcao.row.acao_id} target={'_self'} />,
        (propsAcao) => <Excluir to={'/faturamento/guia-particular/consulta/'} action={() => exclusao(propsAcao)} />,
        (propsAcao) => <OutrasAcoes propsAcao={propsAcao} />

    ]

    const acoesObj2 = [
        propsAcao => <Visualizar to={'/faturamento/guia-particular/visualizacao/' + propsAcao.row.id} />,
        propsAcao => <AlterarComPermissao to={'/faturamento/guia-particular/alteracao/' + propsAcao.row.id} modulo={modulo} acao={propsAcao.row.acao_id} target={'_self'} />,
        propsAcao => <Excluir to={'/faturamento/guia-particular/consulta/'} action={() => exclusao(propsAcao)} />,
        propsAcao => <AlteracaoStatus action={() => {
            setItemTrocarStatus(propsAcao.row);
            setModalAlteracaoStatus(true)
        }} />,
        (propsAcao) => <HistoricoStatus action={() => {
            obterDetalhes(propsAcao.row);
            setShowModalDetalhes(true);

        }} />,
        (propsAcao) => !propsAcao.row.guia_complementar && <GuiaComplementar action={() => {
            setItemComplemento(propsAcao.row);
            setModalComplemento(true);
        }} />,
        (propsAcao) => propsAcao.row.guia_complementar && <RemoverGuiaComplementar action={() => {
            removerComplento(propsAcao, target)
        }} />,
        (propsAcao) => <BtnAcao icone="icon-Upload-toCloud" texto="Inserir Arquivos" to={''} action={async () => {

            await Promise.resolve(get('/controleAcesso/permissao', objPermissaoArquivos).then(function (val) {
                if (val.length > 0) {

                    setItemArquivos(propsAcao.row)
                    setModalInserirArquivos(true)
                }
                else {
                    mostrarToast('erro', 'O usuário não possui permissão para esta ação! Contate um administrador.')
                }
            }).catch((e) => mostrarToast('erro', mensagens['erro'])))
        }} />,
        (propsAcao) => <AlterarSocioGuia action={async () => {
            // history.push({ pathname: '/faturamento/conferencia/cadastro', state: { id: propsAcao.row.id } })
            get('/controleAcesso/permissao', { usuario_id: getUsuario().id, modulo_id: 107, acao_id: ACOES.ALTERAR_SOCIO_GUIA }).then(function (val) {
                if (val.length)
                    history.push({ pathname: '/faturamento/guia-socios/alterar', state: { id: propsAcao.row.id, alteracao_socio_guia: true, alteracao: true, particular: true, rotaConsulta: '/faturamento/guia-particular/consulta' } })

                else
                    mostrarToast('erro', mensagens['permissao'])
            }).catch((e) => mostrarToast('erro', mensagens['erro']))

        }} />,
    ]

    const obterDetalhes = async (props) => {
        let { id } = props
        setItemDetalhes({ guia: [{ titulo: 'Carregando...' }] })
        let dados = {}
        dados.guia = [
            { titulo: 'Código', descricao: id },
            { titulo: 'Código Barras', descricao: props.codigo_barras },
            { titulo: 'Empresa', descricao: props.empresa },
            { titulo: 'Paciente', descricao: props.nome_beneficiario_detalhes },
            { titulo: 'Data Procedimento', descricao: props.data_procedimento },
            { titulo: `${props.inserido_app ? 'Usuário Inserção App' : 'Usuário Inserção'}`, descricao: props.inserido_app ? props.usuario_insercao_app : props.usuario_insercao },
        ]

        await Promise.resolve(get(`/faturamento/obterhistoricoguiastatus/${id}`)).then(val2 => {
            dados.historico_status = val2;
        })

        await Promise.resolve(get(`/faturamento/obterhistoricoguialote/${id}`)).then(val2 => {
            dados.historico_lote = val2;
        })

        await Promise.resolve(get(`/faturamento/obterLancamentosGuiaParticular/${id}`)).then(val2 => {
            dados.lancamentos = val2;
        })
        await Promise.resolve(get(`/faturamento/obterNfseGuia/${id}`)).then(val2 => {
            dados.guia_nfse = val2;
        })

        await Promise.resolve(get(`/faturamento/obterMapaHorasGuia/${id}`)).then(val2 => {

            dados.mapa_horas = val2;
        })

        await Promise.resolve(get(`/faturamento/obterGuiaComplementar/${id}`)).then(val2 => {
            dados.dados_atendimento = val2;
        })

        await Promise.resolve(get(`/faturamento/historicoSocioCoop/${id}`)).then(val => {
            dados.historico_socio_coop = val;
        })

        await Promise.resolve(get(`/faturamento/historicoSocioEquipe/${id}`)).then(val => {
            dados.historico_socio_equipe = val;
        })



        setItemDetalhes(dados)
    }

    const subtitulo = () => {

        return (
            <div className='guia-legenda'>
                <span className='legenda' style={{ background: dados && dados.length > 0 ? dados[0].cor : '' }}>Guia Complementar</span>
            </div>

        )
    }

    return (
        <div>
            <DataGridAlternativa target={target} data={data} columns={columns} acoesObj={width <= 1822 ? acoesObj2 : acoesObj} acoes={true} caminhoBase='/faturamento/guia-particular' setData={setData} modulo={props.modulo} ImpressaoOff={true}
                colunaStatus={'status'} idStatus={'id_status'} corStatus={'cor_status'} guiaComplementarCor={'cor_guia_complementar'} subtitulo={subtitulo} manutencaoStatus='/faturamento/manutencao-status-particular/cadastro'
                titulo={'Consulta Guia Particular'} tituloLista={'Lista Guia Particular'} urlCadastro='/faturamento/guia-particular/cadastro' icone={'icon-Professor'} caminhoConsultaFiltros='/faturamento/guiaparticularpaginacao'
                filtroAtivo={true} nomeSession={'ultimos_filtros_guia_particular'} helpComponent={{ icone: 'icon-Note', action: _ => setModalLegendaStatus(true) }} />
            <DetalhesHistorico titulo={'Detalhes'} data={itemDetalhes} showModal={showModalDetalhes} setShowModal={setShowModalDetalhes} />
            <ModalArquivos target={target} titulo={'Inserir/Alterar Arquivos Guia'} abrirModal={modalInserirArquivos} setAbrirModal={setModalInserirArquivos} item={itemArquivos} setItem={setItemArquivos} />
            <TrocarStatus target={target} titulo={'Alteração de Status Guia'} abrirModal={modalAlteracaoStatus} setAbrirModal={setModalAlteracaoStatus} item={itemTrocarStatus} />
            <ModalLegendaStatus target={target} titulo={'Legenda Status'} abrirModal={modalLegendaStatus} setAbrirModal={setModalLegendaStatus} item={itemTrocarStatus} particular={true} />
            <Complemento target={target} titulo={'Guia Complementar'} abrirModal={modalComplemento} setAbrirModal={setModalComplemento} item={itemComplemento} />
            <ModalDuasOpcoes showModal={showModalAposSalvar} setShowModal={setShowModalAposSalvar}
                funcaoOpcao1={() => { setShowModalAposSalvar(false); }}
                esconderFechar mensagem={<>
                    Guia:
                    <br />
                    <br />
                    <b>{avisoSalvar?.guia} </b> - {avisoSalvar?.paciente}
                    <br />

                </>}
                textoOpcao1={'OK'} esconderOpcao2
                classeOpcao1={'btn-sm btn-primary-modal primary'} />
        </div>
    )
}

const TrocarStatus = ({ target, ...props }) => {

    const [salvando, setSalvando] = useState(false)
    const [carregando, setCarregando] = useState(false)
    const refAtualizacao = useRef(null)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')

    const [validacao] = useState(Yup.object({
        status: Yup.object().nullable().required()
    }))

    useEffect(() => {
        const item = props.item
        if (item.id_status) {
            setValoresIniciais({ status: { value: item.id_status, label: item.status } })
        }
        else
            setValoresIniciais({})

    }, [props.item])

    const Submit = (values, propsFormik) => {
        if (values.status && parseInt(values.status.value) === 13)
            if (!values.data && values.hora)
                values.data_hora_guia_baixada = moment(values.hora, 'HH:mm').format('DD/MM/YYYY HH:mm')

            else if (values.data && !values.hora)
                values.data_hora_guia_baixada = moment(`${values.data} ${moment().format('HH:mm')}`, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')

            else
                values.data_hora_guia_baixada = moment().format('DD/MM/YYYY HH:mm')
        setSalvando(true)
        salvar(
            { id: props.item.id, ...values },
            '/faturamento/guiaparticularalterarstatus',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                props.setAbrirModal(false)
                target.current.atualizar()
                setSalvando(false)
            },
            (err) => {
                setSalvando(false)
            })
    }

    const validarSubmit = async (values, propsFormik) => {
        setSalvando(true)
        if ((valoresIniciais.status && valoresIniciais.status.value === 13) && (values.status && values.status.value !== 13)) {
            setMensagemModal(<><div className='mb-1'><b>Guia encontra-se com status "Baixada"<br /></b></div></>)
            setShowModalValidacao(true)
            setSalvando(false)
        } else
            Submit(values, propsFormik)

    }

    const funcaoAposSim = (formik) => {
        setShowModalValidacao(false)
        Submit(formik.values, formik.resetForm)
    }

    return (
        <>
            <ModalCadastro titulo={props.titulo} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    initialValues={valoresIniciais}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
                    {propsFormik => (
                        <div ref={refAtualizacao}>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <div className={'form-group col-12'}>
                                        {carregando === true ? <LoadRelatorio refLoading={refAtualizacao} heigth={320} /> : <>

                                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                                            <InputGuiaStatusParticular label={<b>Status</b>} name="status" propsFormik={propsFormik} tamanho={'col-lg-12 p-0'}
                                                classeTooltip={'tooltip-input-modal'} placeholder="Status" autoFocus obrigatorio />
                                            {propsFormik.values.status && parseInt(propsFormik.values.status.value) === 13 && //Status Guia "baixada"
                                                <>
                                                    <InputDatePicker tamanho={!props.modal && 'col-lg-12 p-0'} label="Data" name="data" type="text"
                                                        classeTooltip={props.modal && 'tooltip-input-modal'} placeholder="Data"
                                                        setarValor={value => { propsFormik.setFieldValue("data", value) }} />
                                                    <InputTimePicker
                                                        label="Hora" mascara="11:11" name="hora" type="text" placeholder="Hora" tamanho={!props.modal && "col-lg-12 p-0"} classeTooltip={props.modal && 'tooltip-input-modal'}
                                                        setarValor={value => {
                                                            propsFormik.setFieldValue("hora", value)
                                                        }}
                                                    />
                                                </>}
                                            <TextArea label={<b>Justificativa</b>} name='justificativa' type='text' placeholder='Justificativa' tamanho={!props.modal && 'col-lg-12 p-0'} rows={3}
                                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                                        </>}
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                            <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                                esconderFechar mensagem={<>{<>{'Validações do Lote:'} <br /><br /></>} {mensagemModal}<br />
                                    {'Deseja Continuar?'}</>}
                                textoOpcao1={'Cancelar'}
                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                textoOpcao2={'Continuar'} />
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

const Complemento = ({ target, ...props }) => {
    const [salvando, setSalvando] = useState(false)
    const [carregando, setCarregando] = useState(false)
    const refAtualizacao = useRef(null)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [valueGuia, setValueGuia] = useState({})
    const [mostrarDetalhes, setMostrarDetalhes] = useState(false)

    const [validacao] = useState(Yup.object({
        guia: Yup.object().nullable().required()
    }))

    useEffect(() => {
        const item = props.item

        if (item.id_status) {
            setValoresIniciais({ status: { value: item.id_status, label: item.status } })
        }
        else
            setValoresIniciais({})

    }, [props.item])

    let dados = {}

    dados.dados_guia = [

        { titulo: 'Código', descricao: valueGuia?.value },
        { titulo: 'Código Barras', descricao: valueGuia?.codigo_barras },
        { titulo: 'Paciente', descricao: valueGuia?.nome_beneficiario },
        { titulo: 'Data Procedimento', descricao: valueGuia?.data_procedimento },
        { titulo: 'Estabelecimento Saude', descricao: valueGuia?.estabelecimento_saude },
        { titulo: 'Status', descricao: valueGuia?.status, cor: valueGuia?.cor },
        { titulo: 'Valor Total', descricao: valueGuia?.valor_total_formatado },
    ]

    const Submit = (values) => {
        setSalvando(true)
        putComAcao(ACOES.GUIA_COMPLEMENTAR, 107,
            { id: props.item.id, guia_convenio: values.guia.value, },
            '/faturamento/guiacomplementar',
            _ => {
                mostrarToast('sucesso', mensagens.sucesso)
                props.setAbrirModal(false)
                target.current.atualizar()
                setValueGuia({})
                setSalvando(false)
            },
            err => setSalvando(false))
    }

    return (
        <>
            <ModalCadastro titulo={props.titulo} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    initialValues={valoresIniciais}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => Submit(values)}>
                    {propsFormik => (
                        <div ref={refAtualizacao}>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <div className={'form-group col-12'}>
                                        {carregando === true ? <LoadRelatorio refLoading={refAtualizacao} heigth={320} /> : <>

                                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />

                                            <InputGuia label={<b>Guia</b>} name="guia" propsFormik={propsFormik} tamanho={'col-lg-12 p-0'}
                                                classeTooltip={'tooltip-input-modal'} placeholder="Guia" autoFocus obrigatorio onChange={(option) => {
                                                    setValueGuia(option)
                                                    option === null ? setMostrarDetalhes(false) : setMostrarDetalhes(true)
                                                }} />
                                            <div>

                                            </div>
                                            {
                                                dados?.dados_guia && dados?.dados_guia[0].descricao != undefined ?
                                                    <div>
                                                        <div className='separator mt-40 mb-2'></div>
                                                        <br />
                                                        <h5 className="modal-title"><span>Dados da Guia</span></h5>
                                                        <br />
                                                    </div>
                                                    : <></>
                                            }
                                            <div>
                                                <ul className='lista-modal'>
                                                    {mostrarDetalhes === true && dados?.dados_guia[0].descricao != undefined ? <div className='detalhes'>
                                                        {dados?.dados_guia && dados?.dados_guia.map((item, idx) => {
                                                            return (
                                                                <>
                                                                    {idx === 0 ? <></> : <hr />}
                                                                    <div className='item-detalhes'>
                                                                        <h6><b>{item.titulo}</b></h6>
                                                                        {item.cor ? <h6 style={{ color: item.cor }}>{item.descricao}</h6> : <h6>{item.descricao}</h6>}
                                                                        {item.html}
                                                                    </div></>
                                                            )
                                                        })}
                                                    </div> : <></>
                                                    }
                                                </ul>
                                            </div>
                                        </>}
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>
                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

const ModalArquivos = ({ target, ...props }) => {
    const [salvando, setSalvando] = useState(false)
    const [carregando, setCarregando] = useState(false)
    const refAtualizacao = useRef(null)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [dataArquivos, setDataArquivos] = useState(null)
    const [limparArquivos, setLimparArquivos] = useState(false)
    const refArquivo = useRef(null)

    const [validacao] = useState(Yup.object({}))

    const Submit = (values, propsFormik) => {
        values.arquivo = dataArquivos
        setSalvando(true)
        let valuesAjustados = { ...values }
        valuesAjustados.status = values.status ? values.status.id : ''
        putComAcao(
            ACOES.INSERIR_ARQUIVOS_GUIA_PARTICULAR,
            107,
            { id: props.item.id, ...values },
            '/faturamento/guiaParticularInserirArquivos',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                props.setAbrirModal(false)
                setSalvando(false)
                props.setItem(null)
                setDataArquivos(null)
                // target.current.atualizar()
            },
            (erro) => {
                console.log('ERRO ', erro)
                setSalvando(false)
                mostrarToast('erro', mensagens.erro);
            })
    }

    useEffect(() => {
        if (props.item && props.item.id) {
            obterArquivo()
        }
    }, [props.item])

    const obterArquivo = async () => {
        setCarregando(true)
        await Promise.resolve(get(`/faturamento/guiaParticularArquivos/${props.item.id}`)).then(val => {
            setDataArquivos([...val] || [])
            setCarregando(false)
        })
    }
    useEffect(_ => {
        if (!props.abrirModal && refArquivo.current)
            refArquivo.current.limparArquivos()

    }, [props.abrirModal])

    return (
        <>
            <ModalCadastro titulo={props.titulo} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    initialValues={valoresIniciais}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                    {_ => (
                        <div ref={refAtualizacao}>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <div className={'form-group col-12'}>
                                        {carregando === true ? <LoadRelatorio refLoading={refAtualizacao} heigth={320} /> : <>

                                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                                            <label className={'width-100 bold'}>Código Guia</label>
                                            <label className={'width-100 mb-20'}>{props.item ? props.item.id || '--' : '--'}</label>
                                            <label className={'width-100 bold'}>Código Barras</label>
                                            <label className={'width-100 mb-20'}>{props.item ? props.item.codigo_barras || '--' : '--'}</label>
                                            <label className={'width-100 bold'}>Nome</label>
                                            <label className={'width-100 mb-20'}>{props.item ? props.item.nome_beneficiario || '--' : '--'}</label>
                                            <label className={'width-100 bold'}>Data Procedimento</label>
                                            <label className={'width-100 mb-20'}>{props.item ? props.item.data_procedimento || '--' : '--'}</label>
                                            <label className={'width-100 bold'}>Operadora de Saúde</label>
                                            <label className={'width-100 mb-20'}>{props.item ? props.item.operadora_saude || '--' : '--'}</label>
                                            <label className={'width-100 bold'}>Status</label>
                                            <label className={'width-50 mb-20 tag'} style={{ backgroundColor: props.item.cor_status, color: 'white' }}>{props.item ? props.item.status || '--' : '--'}</label>
                                            <div className="">
                                                {dataArquivos != null ?
                                                    <InputArquivoS3
                                                        ref={refArquivo}
                                                        name='arquivo'
                                                        placeholder='Arquivo'
                                                        tamanho='col-12'
                                                        diretorioS3='guia/DDMMYYYY/'
                                                        modulo={MODULO["/faturamento/guiaparticular"]}
                                                        tamanhoMinimo={0}
                                                        tamanhoMaximoEmMB={300}
                                                        setDataArquivos={setDataArquivos}
                                                        dataArquivos={dataArquivos != null ? dataArquivos : []}
                                                        setIsSubmit={dataArquivos.length > 0 ? setSalvando : () => { }}
                                                        limparCampos={false}
                                                    /> : <></>}
                                            </div>
                                        </>}
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

const removerComplento = (propsAcao, target) => {


    putComAcao(ACOES.REMOVER_GUIA_COMPLEMENTAR, 107, { id: propsAcao.row.id }, '/faturamento/removerguiacomplementar',
        () => {
            mostrarToast('sucesso', mensagens.sucesso)
            target.current.atualizar()
        }, err => { mostrarToast('erro', mensagens.erro) })

}

export default ConsultaGuiaParticular