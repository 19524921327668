import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { array } from 'yup';
import { ordenarArrayObj } from '../../../../util/FuncoesComuns';
var format = require('xml-formatter');

export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()
    useEffect(() => {
        visualizar('/faturamento/lote', id,
            obj =>
                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Lote',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Número', descricao: obj.numero_lote, tamanho: 'col-lg-4' },
                                { titulo: 'Referência', descricao: obj.referencia && obj.referencia.split('/')[1] + '/' + obj.referencia.split('/')[2] || '', tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Guia', descricao: obj.guia_tipo && obj.guia_tipo.label, tamanho: 'col-lg-4' },
                                { titulo: 'Quantidade de Guias', descricao: obj.quantidade_guia && obj.quantidade_guia, tamanho: 'col-lg-4' },
                                { titulo: 'Tipo Executante', descricao: obj.tipo_executante && obj.tipo_executante.label, tamanho: 'col-lg-4' },
                                { titulo: 'Status', descricao: obj.status_lote && obj.status_lote.label, tamanho: 'col-lg-4' },
                                { titulo: 'Operadora Saúde', descricao: obj.operadora_saude && obj.operadora_saude.label || '', tamanho: 'col-lg-4' },
                                { titulo: 'Contrato', descricao: obj.contrato && obj.contrato.label || '', tamanho: 'col-lg-4' },
                                { titulo: 'Empresa', descricao: obj.empresa && obj.empresa.label || '', tamanho: 'col-lg-4' },
                                { titulo: 'Protocolo', descricao: obj.protocolo, tamanho: 'col-lg-4' },
                                { titulo: 'Data de Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                            ]
                        },
                        {
                            tipoGrupo: 'tabela',
                            titulo: 'Retorno Envio',
                            visivel: true,
                            thead:
                                <tr>
                                    {/* <td className='coluna-6'>Item Ref.</td> */}
                                    <td className='coluna-3'>Código Envio</td>
                                    <td className='coluna-3'>Quantidade Guias</td>
                                    <td className='coluna-3'>Status Lote</td>
                                    <td className='coluna-3'>Usuário</td>

                                </tr>,
                            tbody: obj.retorno_envio == undefined || obj.retorno_envio.length < 1 ? (<><tr>
                                <td colspan="3">Sem Registro!</td>
                            </tr></>) :
                                obj.retorno_envio.filter(x => x.id_lote).map(x =>
                                    <tr>
                                        <td>{x.id_envio}</td>
                                        <td>{x.qtd_guias}</td>
                                        <td>{x.status_lote}</td>
                                        <td>{x.nome}</td>
                                    </tr>)
                        },
                    ]
                } : { titulo: 'Visualização Lote' })

        )

    }, [id])


    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

const VisualizacaoArquivoXmlLote = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/faturamento/obterXmlGuiaLote', id, obj => {
            if (obj && obj[0])
                obj = obj[0]

            let guias = []
            if (obj.guias && obj.guias.length > 0) {
                guias = obj.guias.map(x => {
                    const formato = x.xml_envio ? format(x.xml_envio, {
                        indentation: '   ',
                        collapseContent: true,
                        lineSeparator: '\n'
                    }) : 'Nenhum XML encontrado'

                    return {
                        titulo: 'Xml Guia - ' + x.guia_id,
                        colunas: [
                            {
                                titulo: 'XML', descricao: <div style={{ whiteSpace: 'pre-wrap' }}>{formato}</div>, tamanho: 'col-lg-12'
                            }
                        ]
                    }
                })
            }
            setDados(obj && obj.id ? {
                id,
                modulo: props.modulo,
                titulo: 'Visualização de XML Gerado - Lote',
                grupos: [
                    {
                        titulo: 'Dados Lote',
                        colunas: [
                            { titulo: 'Lote', descricao: obj.id, tamanho: 'col-lg-4' },
                            { titulo: 'Status', descricao: obj.status_lote, tamanho: 'col-lg-4' },
                            { titulo: 'Último Envio', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                            { titulo: 'Usuário Envio', descricao: obj.nome, tamanho: 'col-lg-4' },
                        ]
                    }
                ].concat(guias)
            } : { titulo: 'Visualização de XML Gerado' })
        })
    }, [id])


    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

const VisualizacaoRespostaEnvioLote = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    const mensagens = obj => {
        let arrayVisualizacao = []

        if (obj.guias.length) {
            obj.guias.map((x, idx) => {
                arrayVisualizacao.push({
                    tipoGrupo: 'tabela',
                    titulo: 'Guia ' + x.id_guia,
                    thead:
                        <tr>
                            <td className='coluna-1'>Código</td>
                            <td className='coluna-5'>Mensagem</td>
                        </tr>,
                    tbody:
                        x.mensagens && x.mensagens.sort().map((y, idy) =>
                            <tr>
                                <td className=''>{y.codigo}</td>
                                <td className=''>{y.mensagem}</td>
                            </tr>)
                })
            })
        }

        return arrayVisualizacao
    }

    useEffect(() => {
        visualizar('/faturamento/envioXmlObterListaRetornoLote', id, obj => {
            setDados(obj && obj[0] && obj[0].id_envio ? {
                id,
                modulo: props.modulo,
                titulo: 'Visualização de Retorno XML',
                grupos: [
                    {
                        titulo: 'Dados Envio Lote',
                        colunas: [
                            { titulo: 'Lote', descricao: obj[0].id_lote, tamanho: 'col-lg-3' },
                            { titulo: 'Status Envio', descricao: obj[0].status_lote, tamanho: 'col-lg-3' },
                            { titulo: 'Último Envio', descricao: obj[0].data_registro, tamanho: 'col-lg-3' },
                            { titulo: 'Quantidade Guias', descricao: obj[0].qtd_guias, tamanho: 'col-lg-3' },
                            { titulo: 'Usuário', descricao: obj[0].nome, tamanho: 'col-lg-3' },
                        ]
                    }
                ].concat(mensagens(obj[0]))
            } : { titulo: 'Visualização de Retorno XML' })
        })
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

const VisualizacaoLotesAutomaticos = props => {

    let { id } = useParams();
    const [dados, setDados] = useState()
    const history = useHistory();
    const dadosLote = (history.location.state && history.location.state)
    const { infos } = dadosLote || {}
    useEffect(() => {
        setDados(dadosLote ? {
            id,
            modulo: props.modulo,
            titulo: 'Visualização Lote(s) Automáticos',
            grupos: [
                {
                    tipoGrupo: 'tabela',
                    titulo: 'Lote(s) Gerado(s)',
                    visivel: true,
                    thead:
                        <tr>
                            <td className='coluna-1'>#</td>
                            <td className='coluna-1'>Código Lote</td>
                            <td className='coluna-1'>Número</td>
                            <td className='coluna-1'>Referência</td>
                            <td className='coluna-3'>Operadora Saúde</td>
                            <td className='coluna-2'>Contrato</td>
                            <td className='coluna-2'>Empresa</td>
                            <td className='coluna-1'>Tipo Pessoa</td>
                            <td className='coluna-1'>Qtd Guias</td>
                            <td className='coluna-2'>Data Registro</td>

                        </tr>,
                    tbody: infos == undefined || infos.length < 1 ? (<><tr>
                        <td colspan="3">Sem Registro!</td>
                    </tr></>) :
                        infos.map((x, idx) =>
                            <>
                                <tr>
                                    <td>{idx + 1}</td>
                                    <td>{x.BatchId}</td>
                                    <td>{x.Number}</td>
                                    <td>{x.Reference}</td>
                                    <td>{x?.HealthOperator || ''}</td>
                                    <td>{x?.ContractName}</td>
                                    <td>{x?.Company || ''}</td>
                                    <td>{x?.PerformerTypeName}</td>
                                    <td>{x?.GuidesAmount}</td>
                                    <td>{x?.RegisterDate}</td>

                                </tr>
                            </>
                        )
                },
            ]
        } : { titulo: 'Visualização Lotes Automáticos' })

    }, [id])


    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export { VisualizacaoArquivoXmlLote, VisualizacaoRespostaEnvioLote, VisualizacaoLotesAutomaticos }