import React, { useCallback, useEffect, useState } from 'react';
import { convertValueToFloat, formatarMoeda } from '../../util/Utilitarios';
import { TableVirtuoso } from 'react-virtuoso';
import MensagemPadraoRelatorio from '../../components/RelatorioEspecifico/RelatorioSemDados'
import { BrowserRouter, Link } from 'react-router-dom'
import { useSortBy } from 'react-table';
import { getDistinctPropertyValues, limitarCaracteres } from '../../util/FuncoesComuns';

const relatorioExtratoBancarioDiarioEspecifico = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {

    return (
        array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {

            const idxBancoContaDesc = arrayConfigCol[idx].findIndex(y => y.coluna == 'banco_conta_descricao')
            const idxAgencia = arrayConfigCol[idx].findIndex(y => y.coluna == 'agencia')
            const idxSaldo = arrayConfigCol[idx].findIndex(y => y.coluna == 'saldo')
            const idxReferencia = arrayConfigCol[idx].findIndex(y => y.coluna == 'referencia')
            const idxConta = arrayConfigCol[idx].findIndex(y => y.coluna == 'conta')
            const idxTipoConta = arrayConfigCol[idx].findIndex(y => y.coluna == 'tipo_conta')
            const idxAtivo = arrayConfigCol[idx].findIndex(y => y.coluna == 'ativo')

            const valorTotalGeral = x.data.reduce((acc, obj) => convertValueToFloat(acc) + convertValueToFloat(obj.saldo_total_empresa), 0);

            return (<>
                {x.data.map((item, index) => {
                    return <>
                        {index > 0 ? htmlHeader : <></>}
                        <table className='table-agrupamento mb-2'>

                            <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                <td className={` bold td-data text-left`} style={{ width: '45%' }}>{item.empresa} </td>
                            </tr>
                        </table>

                        <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                            {
                                item.bancos.map((banco, index) => {

                                    const valorTotalBanco = banco?.banco_contas.reduce((acc, obj) => {
                                        if (obj && typeof obj.saldo_bancario === 'number') {
                                            return acc + obj.saldo_bancario;
                                        }
                                        return acc;
                                    }, 0);
                                    return (
                                        <>
                                            <tr >
                                                <td className={`bold td-data text-left`} colSpan={5}>
                                                    {banco.banco}
                                                </td>

                                            </tr>

                                            <tr className='titulo-1'>
                                                <td className='text-center bold td-data group-title' width={'1%'}>#</td>

                                                {x.colunas && arrayConfigCol[idx][idxAgencia]?.visivel ? <td className='text-left bold td-data' width={'35%'}>Descrição Conta</td> : <></>}
                                                {x.colunas && arrayConfigCol[idx][idxBancoContaDesc]?.visivel ? <td className='text-left bold td-data' width={'10%'}>Agência</td> : <></>}
                                                {x.colunas && arrayConfigCol[idx][idxConta]?.visivel ? <td className='bold td-data' width={'10%'}>Conta</td> : <></>}
                                                {x.colunas && arrayConfigCol[idx][idxReferencia]?.visivel ? <td className='bold td-data' width={'10%'}>Referência</td> : <></>}
                                                {x.colunas && arrayConfigCol[idx][idxTipoConta]?.visivel ? <td className='bold td-data text-center' width={'10%'}>Tipo Conta</td> : <></>}
                                                {x.colunas && arrayConfigCol[idx][idxAtivo]?.visivel ? <td className='bold td-data text-right' width={'10%'}>Status Conta</td> : <></>}
                                                {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? <td className='bold td-data text-center' width={'15%'}>Saldo</td> : <></>}

                                            </tr>

                                            {
                                                banco.banco_contas.map((banco_contas, index) => {
                                                    return (
                                                        <>
                                                            <tr className={'tr-data bt-1'}>
                                                                <td className='text-center td-data'>{index + 1}</td>
                                                                {x.colunas && arrayConfigCol[idx][idxAgencia]?.visivel ? <td className='text-left td-data'>{banco_contas.banco_conta_descricao}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxBancoContaDesc]?.visivel ? <td className='text-left td-data'>{banco_contas.agencia}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxConta]?.visivel ? <td className='td-data'>{banco_contas.conta}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxReferencia]?.visivel ? <td className='td-data'>{banco_contas.referencia}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxTipoConta]?.visivel ? <td className='td-data text-left '>{banco_contas.tipo_conta}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxAtivo]?.visivel ? <td className='td-data text-right'>{banco_contas.ativo}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? <td className='td-data text-right'>{formatarMoeda(banco_contas.saldo_bancario)}</td> : <></>}
                                                            </tr>

                                                        </>
                                                    )
                                                })}

                                            <tr>
                                                <td className='td-data'></td>
                                                {x.colunas && arrayConfigCol[idx][idxAgencia]?.visivel ? <td className='td-data'></td> : null}
                                                {x.colunas && arrayConfigCol[idx][idxBancoContaDesc]?.visivel ? <td className='td-data'></td> : null}
                                                {x.colunas && arrayConfigCol[idx][idxConta]?.visivel ? <td className='td-data'></td> : null}
                                                {x.colunas && arrayConfigCol[idx][idxReferencia]?.visivel ? <td className='td-data'></td> : null}
                                                {x.colunas && arrayConfigCol[idx][idxTipoConta]?.visivel ? <td className='td-data'></td> : null}
                                                {x.colunas && arrayConfigCol[idx][idxAtivo]?.visivel ? <td className='td-data'></td> : null}
                                                {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? (
                                                    <td className='td-data text-right'><b>Total Conta: {formatarMoeda(valorTotalBanco)}</b>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        </>

                                    )
                                })
                            }

                        </table>
                        <table className='table-agrupamento mb-2'>
                            <tr>
                                {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? (
                                    <td className='td-data text-right'><b>Total Empresa: {formatarMoeda(item.saldo_total_empresa)}</b>
                                    </td>
                                ) : null}

                            </tr>
                        </table>
                    </>

                })}

                <table className='table-agrupamento mb-2'>
                    <tr>
                        {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? (
                            <td className='td-data text-right'><b>Total Geral: {formatarMoeda(valorTotalGeral)}</b>
                            </td>
                        ) : null}

                    </tr>
                </table>
            </>)

        }) : <MensagemPadraoRelatorio />
    )
}

const relatorioExtratoBancarioDiarioVirtuoso = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {

    return (
        array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {

            const idxBancoContaDesc = arrayConfigCol[idx].findIndex(y => y.coluna == 'banco_conta_descricao')
            const idxAgencia = arrayConfigCol[idx].findIndex(y => y.coluna == 'agencia')
            const idxSaldo = arrayConfigCol[idx].findIndex(y => y.coluna == 'saldo')
            const idxReferencia = arrayConfigCol[idx].findIndex(y => y.coluna == 'referencia')
            const idxConta = arrayConfigCol[idx].findIndex(y => y.coluna == 'conta')
            const idxTipoConta = arrayConfigCol[idx].findIndex(y => y.coluna == 'tipo_conta')
            const idxAtivo = arrayConfigCol[idx].findIndex(y => y.coluna == 'ativo')

            const valorTotalGeral = x.data.reduce((acc, obj) => convertValueToFloat(acc) + convertValueToFloat(obj.saldo_total_empresa), 0);

            return (<>
                <div className='delete-starts' />
                <TableVirtuoso
                    style={{ height: 1000, overflowY: 'hidden' }}
                    useWindowScroll
                    data={x.data}
                    itemContent={(index, item) => (
                        <>
                            {index > 0 ? htmlHeader : <></>}
                            <table className='table-agrupamento mb-2'>

                                <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                    <td className={` bold td-data text-left`} style={{ width: '45%' }}>{item.empresa} </td>
                                </tr>
                            </table>

                            <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                                {
                                    item.bancos.map((banco, index) => {

                                        const valorTotalBanco = banco?.banco_contas.reduce((acc, obj) => {
                                            if (obj && typeof obj.saldo_bancario === 'number') {
                                                return acc + obj.saldo_bancario;
                                            }
                                            return acc;
                                        }, 0);

                                        const valorResgate = banco?.banco_contas.reduce((acc, obj) => {
                                            if (obj && typeof obj.resgate === 'number') {
                                                return acc + obj.resgate;
                                            }
                                            return acc;
                                        }, 0);

                                        const valorAplicacao = banco?.banco_contas.reduce((acc, obj) => {
                                            if (obj && typeof obj.aplicacao === 'number') {
                                                return acc + obj.aplicacao;
                                            }
                                            return acc;
                                        }, 0);

                                        return (<>
                                            <tr >
                                                <td className={`bold td-data text-left`} colSpan={5}>
                                                    {banco.banco}
                                                </td>

                                            </tr>

                                            <tr className='titulo-1'>
                                                <td className='text-center bold td-data group-title' width={'1%'}>#</td>

                                                {x.colunas && arrayConfigCol[idx][idxAgencia]?.visivel ? <td className='text-left bold td-data' width={'35%'}>Descrição Conta</td> : <></>}
                                                {x.colunas && arrayConfigCol[idx][idxBancoContaDesc]?.visivel ? <td className='text-left bold td-data' width={'10%'}>Agência</td> : <></>}
                                                {x.colunas && arrayConfigCol[idx][idxConta]?.visivel ? <td className='bold td-data' width={'10%'}>Conta</td> : <></>}
                                                {x.colunas && arrayConfigCol[idx][idxReferencia]?.visivel ? <td className='bold td-data' width={'10%'}>Referência</td> : <></>}
                                                {x.colunas && arrayConfigCol[idx][idxTipoConta]?.visivel ? <td className='bold td-data text-center' width={'10%'}>Tipo Conta</td> : <></>}
                                                {x.colunas && arrayConfigCol[idx][idxAtivo]?.visivel ? <td className='bold td-data text-right' width={'10%'}>Status Conta</td> : <></>}
                                                {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? <td className='bold td-data text-center' width={'15%'}>Saldo</td> : <></>}

                                            </tr>

                                            {
                                                banco.banco_contas.map((banco_contas, index) => {
                                                    return (
                                                        <>
                                                            <tr className={'tr-data bt-1'}>
                                                                <td className='text-center td-data'>{index + 1}</td>
                                                                {x.colunas && arrayConfigCol[idx][idxAgencia]?.visivel ? <td className='text-left td-data'>{banco_contas.banco_conta_descricao}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxBancoContaDesc]?.visivel ? <td className='text-left td-data'>{banco_contas.agencia}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxConta]?.visivel ? <td className='td-data'>{banco_contas.conta}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxReferencia]?.visivel ? <td className='td-data'>{banco_contas.referencia}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxTipoConta]?.visivel ? <td className='td-data text-left '>{banco_contas.tipo_conta}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxAtivo]?.visivel ? <td className='td-data text-right'>{banco_contas.ativo}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? <td className='td-data text-right'>{formatarMoeda(banco_contas.saldo_bancario)}</td> : <></>}
                                                            </tr>


                                                        </>
                                                    )
                                                })}

                                            {[
                                                { label: 'Aplicação', value: valorAplicacao },
                                                { label: 'Resgate', value: valorResgate },
                                            ].map((total, i) => (
                                                <tr key={i}>
                                                    <td className='td-data'></td>
                                                    {x.colunas && arrayConfigCol[idx][idxAgencia]?.visivel && <td className='td-data'></td>}
                                                    {x.colunas && arrayConfigCol[idx][idxBancoContaDesc]?.visivel && <td className='td-data'></td>}
                                                    {x.colunas && arrayConfigCol[idx][idxConta]?.visivel && <td className='td-data'></td>}
                                                    {x.colunas && arrayConfigCol[idx][idxReferencia]?.visivel && <td className='td-data'></td>}
                                                    {x.colunas && arrayConfigCol[idx][idxTipoConta]?.visivel && <td className='td-data'></td>}
                                                    {x.colunas && arrayConfigCol[idx][idxAtivo]?.visivel && <td className='td-data'></td>}
                                                    {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel && (
                                                        <td className='td-data text-right'>
                                                            <b>{total.label}: {formatarMoeda(total.value)}</b>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}

                                            <tr>
                                                <td className='td-data'></td>
                                                {x.colunas && arrayConfigCol[idx][idxAgencia]?.visivel ? <td className='td-data'></td> : null}
                                                {x.colunas && arrayConfigCol[idx][idxBancoContaDesc]?.visivel ? <td className='td-data'></td> : null}
                                                {x.colunas && arrayConfigCol[idx][idxConta]?.visivel ? <td className='td-data'></td> : null}
                                                {x.colunas && arrayConfigCol[idx][idxReferencia]?.visivel ? <td className='td-data'></td> : null}
                                                {x.colunas && arrayConfigCol[idx][idxTipoConta]?.visivel ? <td className='td-data'></td> : null}
                                                {x.colunas && arrayConfigCol[idx][idxAtivo]?.visivel ? <td className='td-data'></td> : null}
                                                {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? (
                                                    <td className='td-data text-right'><b>Total Conta: {formatarMoeda(valorTotalBanco)}</b>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        </>)
                                    })
                                }

                            </table>
                            <table className='table-agrupamento mb-2'>
                                <tr>
                                    {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? (
                                        <td className='td-data text-right' style={{ borderTop: '1px #000 dashed' }}>
                                            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                <b>Total Empresa: {formatarMoeda(item.saldo_total_empresa)}</b>
                                            </div>
                                        </td>
                                    ) : null}

                                </tr>
                            </table>
                        </>
                    )}
                />

                {/* <table className='table-agrupamento mb-2'>
                    <tr>
                        {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? (
                            <td className='td-data text-right'><b>Total Geral: {formatarMoeda(valorTotalGeral)}</b>
                            </td>
                        ) : null}

                    </tr>
                </table> */}
                <div className='delete-ends' />

            </>)
        }) : <MensagemPadraoRelatorio />
    )
}

export { relatorioExtratoBancarioDiarioEspecifico, relatorioExtratoBancarioDiarioVirtuoso }