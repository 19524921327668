import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { get, salvar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao } from '../../../../components/Inputs'
import { InputSimNao } from '../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import Atalho from '../../../../util/Atalho'

const CadastroCbos = (props) => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao] = useState(Yup.object({
        descricao: Yup.string()
            .required().trim()
            .max(200),
        codigo: Yup.string()
            .required().trim()
            .max(200),
        especialidade_medica: Yup.string()
            .required()
    }))

    const manter = ['especialidade_medica']

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        salvar(
            values,
            '/parametros/cbos',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)

                if (salvarENovo) {
                    propsFormik.resetForm({})
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                }
                else
                    props.history.push('/parametros/cbos/consulta')
            },
            () => {
                setSalvando(false)
            })
    }

    useEffect(() => {
        Promise.resolve(get(`/parametros/cbos/${id}`)).then(obj => {
            if (id && !obj.codigo)
                props.history.push('/parametros/cbos/consulta')
            else if (id) {
                setValoresIniciais(obj)
            }
            else {
                let obj = {
                    value: "false",
                    label: "Não"
                }
                setValoresIniciais({
                    descricao: "",
                    codigo: "",
                    especialidade_medica: obj,
                })
            }
        })
    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={props.modal || 'card-body'}>
                        <div className='row'>
                            <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' obrigatorio={false} />
                            <InputPadrao label='Código' name='codigo' type='text' placeholder='Código' classeTooltip={props.modal && 'tooltip-input-modal'}
                                obrigatorio={true} tamanho={!props.modal && 'col-6 col-sm-12 col-md-6 col-xl-6'} autoFocus salvarENovo={salvarENovo} />
                            <InputPadrao label='Descrição' name='descricao' type='text' placeholder='Descrição' classeTooltip={props.modal && 'tooltip-input-modal'}
                                obrigatorio={true} tamanho={!props.modal && 'col-6 col-sm-12 col-md-6 col-xl-6'} />
                            <InputSimNao isClearable={false} tamanho={!props.modal && 'col-6 col-sm-12 col-md-6 col-xl-6'} label="Especialidade Médica?"
                                noOptionsMessage={() => ""} classeTooltip={props.modal && 'tooltip-input-modal'} obrigatorio={true} name="especialidade_medica"
                                type="select" propsFormik={propsFormik} placeholder="Especialidade Médica?" />
                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo}  salvarENovo={true} />

                        </div>
                    </div>
                </div>
                {!props.modal ? <div className='separator mt-40 mb-5'></div> : <></>}
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'}/>
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)
            }
        </Formik >
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Administrativo</Link>,
        () => <Link className="link-active" to={'/parametros/cbos/consulta'}>CBOS</Link>,
    ]
    return (<>
        {!props.modal && <HeaderCadastro titulo={"Cadastro de CBOS"} link={links} />}
        <CadastroCbos {...props} />
    </>
    )
}
export { CadastroCbos }
export default Cadastro
