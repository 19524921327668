import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaCPF, validaData, dataMenorAtual, validaConjuge } from '../../../../util/Validacoes';
import { InputPadrao, InputTelefone, InputDatePicker, InputSwitch, TextArea, InputGroupSenha } from '../../../../components/Inputs'
import { InputEstado, InputSexo, InputEspecialidadeMedica, InputMunicipio, InputPais, InputTipoDocumento, InputEstadoCivil, InputRaca, InputCBOS, InputSocioStatus } from '../../../../components/InputsAutoComplete'
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { useParams } from "react-router-dom";
import { get, salvar } from '../../../../util/AcessoApi'
import { mensagens, ajustarDadosTelefone } from '../../../../util/Utilitarios'
import { Link } from 'react-router-dom'
import { HeaderCadastro } from '../../../../components/Headers'
import CadastroBeneficiario from '../../../parametros/dependente/pages/Cadastro'
import CadastroEndereco from '../../../parametros/endereco/pages/Cadastro'
import CadastroSocioEmpresa from './SocioEmpresa'
import CadastroSocioGrupo from './SocioGrupo'
import InputArquivoS3 from '../../../../components/InputArquivoS3'
import { MODULO } from '../../../../util/Enums'
import cloneDeep from 'lodash/cloneDeep';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../../../assets/css/tabs.css'
import Atalho from '../../../../util/Atalho'
import ls from 'local-storage'



const CadastroSocio = (props) => {
    let { id } = useParams();
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [isSubmit, setDisable] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [abrirModalEndereco, setAbrirModalEndereco] = useState(false)
    const [abrirModalDependente, setAbrirModalDependente] = useState(false)
    const [abrirModalGrupo, setAbrirModalGrupo] = useState(false)
    const [abrirModalSocioEmpresa, setAbrirModalSocioEmpresa] = useState(false)
    const [dependentes, setDependentes] = useState([])
    const [endereco, setEndereco] = useState([])
    const [dadosEmpresa, setDadosEmpresa] = useState([])
    const [dadosOcorrencia, setDadosOcorrencia] = useState([])
    const [dataTelefone, setDataTelefone] = useState([])
    const [dataTelefoneConjuge, setDataTelefoneConjuge] = useState([])
    const [dadosGrupo, setDadosGrupo] = useState([])
    const [dataArquivos, setDataArquivos] = useState(null)
    const [validacao, setValidacao] = useState({})
    const [tabIndex, setTabIndex] = useState(0)
    const [classTab, setClassTab] = useState([])

    const validacaoInicial = {

        nome: Yup.string()
            .required()
            .max(100),
        cpf: Yup.string().test('CPF', 'CPF inválido!', function (value) { return validaCPF(this.parent.cpf) })
            .nullable().required()
            .max(100),
        sexo: Yup.string().nullable().required(),
        estado_civil: Yup.string().nullable(),
        data_nascimento: Yup.string().nullable().required().test('Data Nascimento', 'Data inválida!', function (value) { return validaData(this.parent.data_nascimento) }).test('Data Expedição', 'Data deve ser menor ou igual a Data Atual!', function (value) { return dataMenorAtual(this.parent.data_nascimento) }),
        crm: Yup.string().nullable().max(15).required(),
        estado_crm: Yup.string().nullable().required(),
        socio_cbos: Yup.string().nullable().required(),
        tipo_documento: Yup.string().nullable().required(),
        numero_documento: Yup.string().nullable().max(100).required(),
        estado_documento: Yup.string().nullable().required(),
        data_expedicao: Yup.string().nullable().required().test('Data Expedição', 'Data inválida!', function (value) { return validaData(this.parent.data_expedicao) }).test('Data Expedição', 'Data deve ser menor ou igual a Data Atual!', function (value) { return dataMenorAtual(this.parent.data_expedicao) }),
        pais_emissor: Yup.string().nullable().required(),
        inss: Yup.string().nullable().max(100),
        titulo_eleitor: Yup.string().nullable().max(100),
        pais: Yup.string().nullable().required(),
        raca: Yup.string().nullable().required(),
        nome_mae: Yup.string().nullable().max(100),
        nome_pai: Yup.string().nullable().max(100),
        telefone: Yup.string().nullable().required(),
        email: Yup.string().nullable().email().max(200),
        login_coopanest: Yup.string().nullable(),
        senha_coopanest: Yup.string().nullable(),
        data_hierarquizacao: Yup.string().nullable().test('Data Hierarquizacao', 'Data inválida!', function (value) { return validaData(this.parent.data_hierarquizacao) }).test('Data Hierarquizacao', 'Data deve ser menor ou igual a Data Atual!', function (value) { return dataMenorAtual(this.parent.data_hierarquizacao) }),
        status: Yup.string().nullable().required(),
        data_alinhamento_economico: Yup.string().nullable()
        .test('Data Alinhamneto Economico', 'Data inválida!', function (value) { return validaData(this.parent.data_alinhamento_economico) }),
        
        //VALIDAÇÃO CÔNJUGE
        data_nascimento_conjuge: Yup.string().nullable().test('Data Nascimento Conjuge', 'Data inválida!', function (value) { return validaData(this.parent.data_nascimento_conjuge) }).test('Data Expedição', 'Data deve ser menor ou igual a Data Atual!', function (value) { return dataMenorAtual(this.parent.data_nascimento_conjuge) }),
       
        // VALIDA DADOS ADMISSÃO
        data_admissao: Yup.string().nullable().required().test('Data Admissão', 'Data inválida!', function (value) { return validaData(this.parent.data_admissao) }),
        data_demissao: Yup.string().nullable().test('Data Demissão', 'Data inválida!', function (value) { return validaData(this.parent.data_demissao) }),
            
        
        
        
        //     .test('Data Nascimento', 'Data inválida!', function (value) { return validaData(this.parent.data_nascimento_conjuge) }).test('Data Expedição', 'Data deve ser menor ou igual a Data Atual!', function (value) { return dataMenorAtual(this.parent.data_nascimento_conjuge) }),
        // tipo_documento_conjuge: Yup.string().nullable().test('Testando', 'Campo Obrigatório!', function (value) {
        //     return validaConjuge(this.parent, this.parent.tipo_documento_conjuge)
        // }),
        // numero_documento_conjuge: Yup.string().nullable().test('Testando', 'Campo Obrigatório!', function (value) {
        //     return validaConjuge(this.parent, this.parent.numero_documento_conjuge)
        // }),
        // estado_documento_conjuge: Yup.string().nullable().test('Testando', 'Campo Obrigatório!', function (value) {
        //     return validaConjuge(this.parent, this.parent.estado_documento_conjuge)
        // }),
        // data_expedicao_conjuge: Yup.string().nullable().test('Testando', 'Campo Obrigatório!', function (value) {
        //     return validaConjuge(this.parent, this.parent.data_expedicao_conjuge)
        // }).test('Data Expedição', 'Data inválida!', function (value) { return validaData(this.parent.data_expedicao_conjuge) }).test('Data Expedição', 'Data deve ser menor ou igual a Data Atual!', function (value) { return dataMenorAtual(this.parent.data_expedicao_conjuge) }),
        // pais_emissor_conjuge: Yup.string().nullable().test('Testando', 'Campo Obrigatório!', function (value) {
        //     return validaConjuge(this.parent, this.parent.pais_emissor_conjuge)
        // }),
        // pais_conjuge: Yup.string().nullable().test('Testando', 'Campo Obrigatório!', function (value) {
        //     return validaConjuge(this.parent, this.parent.pais_conjuge)
        // })

    }


    useEffect(() => {
        if (!id)
            setDataArquivos([])

        //setClassTab(["tab-error", "", "", "", "", "", "", ""])

        setValidacao(Yup.object(cloneDeep(validacaoInicial)))
    }, [])



    const { history } = props

    const manter = []

    const Submit = (values, dataTelefone, dataTelefoneConjuge, endereco, dadosEmpresa, dadosGrupo, dadosOcorrencia, propsFormik) => {
        try {
            setTabIndex(0);
            setDisable(true)

            values.fone = ajustarDadosTelefone(dataTelefone.length > 0 ? dataTelefone : values.telefone)

            values.telefone_conjuge = ajustarDadosTelefone(dataTelefoneConjuge.length > 0 ? dataTelefoneConjuge : values.telefone_conjuge)

            values.empresa = dadosEmpresa

            values.enderecos = endereco
            values.dependentes = dependentes
            values.grupo = dadosGrupo
            values.ocorrencias = []
            values.arquivo = dataArquivos

            salvar(
                values,
                '/administracao/socio',
                () => {
                    mostrarToast('sucesso', mensagens.sucesso)
                    ls.remove('ultimo_cache_socios_ativos');

                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setEndereco([])
                        setDataTelefone([])
                        setDadosEmpresa([])
                        setDataTelefoneConjuge([])
                        setDadosGrupo([])
                        setDependentes([])
                        setDadosOcorrencia([])
                        setDisable(false)
                        setSalvarENovo(false)
                    }
                    else
                        props.history.push('/administracao/socio/consulta')
                },
                (err) => {
                    let obj = JSON.parse(err.response.data.erros);

                    for (let i = 0; i < obj.length; i++) {
                        const item = obj[i];
                        if (item.mensagem) {
                            propsFormik.setFieldError(item.campo, item.mensagem)
                            mostrarToast('erro', item.mensagem)
                        }
                        else
                            mostrarToast('erro', item)
                    }
                    const unique = [...new Set(obj.map(item => item.tabIndex))];
                    let array = [];
                    for (let i = 0; i < unique.length; i++) {
                        array[unique[i]] = "tab-error";
                    }

                    setTabIndex(unique[0] === undefined ? 5 : unique[0]);
                    setClassTab(array)
                    setDisable(false)
                }, true)
        } catch (ex) {
            alert(ex.toString())
        }
    }

    // const verificaValidacao = (erros) => {

    //     // var arrayErrros = Object.keys(erros.errors)
    //     // const arrayIndex = [{ name: 'nome', index: 0 }, { name: 'cpf', index: 0 }, { name: 'sexo', index: 0 }, { name: 'data_nascimento', index: 0 }, { name: 'crm', index: 0 }, { name: 'estado_crm', index: 0 }, { name: 'cpf', index: 0 },
    //     // { name: 'nome_conjuge', index: 1 }, { name: 'sexo_conjuge', index: 1 }, { name: 'cpf_conjuge', index: 1 }, { name: 'data_nascimento_conjuge', index: 1 }, { name: 'tipo_documento_conjuge', index: 1 }, { name: 'numero_documento_conjuge', index: 1 }, { name: 'estado_documento_conjuge', index: 1 }, { name: 'data_expedicao_conjuge', index: 1 }, { name: 'pais_emissor_conjuge', index: 1 }, { name: 'pais_conjuge', index: 1 },
    //     // { name: 'data_hierarquizacao', index: 6 }]


    //     // for (let i in arrayIndex) {
    //     //     for (let j in arrayErrros) {
    //     //         if (arrayIndex[i].name === arrayErrros[j]) {
    //     //             return setTabIndex(arrayIndex[i].index)
    //     //         }
    //     //     }
    //     // }

    // }

    useEffect(() => {
        if (id) {
            Promise.resolve(get(`/administracao/socio/${id}`)).then(function (val) {
                if (val.length === 0)
                    history.push('/administracao/socio/consulta')

                let obj = { ...val }

                setDataArquivos(val.arquivo)

                setValoresIniciais(obj)

                props.setSubtitulo(obj.nome)

                if (obj.fone)
                    setDataTelefone(obj.fone)
                if (obj.dependentes)
                    setDependentes(obj.dependentes)
                if (obj.telefone_conjuge !== undefined) {
                    setDataTelefoneConjuge(obj.telefone_conjuge)
                }
                if (obj.enderecos)
                    setEndereco(obj.enderecos)
                if (obj.empresa)
                    setDadosEmpresa(obj.empresa)
                if (obj.grupo)
                    setDadosGrupo(obj.grupo)

            }).catch((e) => {


            });

        } else {
            let objEstado = {
                value: "GO",
                label: "Goiás"
            }
            let objPais = {
                value: 1703,
                label: "Brasil"
            }
            setValoresIniciais({
                nome: "",
                login_coopanest: "",
                senha_coopanest: "",
                data_admissao: "",
                crm: '',
                cpf: '',
                numero_documento: '',
                estado_crm: objEstado,
                estado_documento: objEstado,
                pais_emissor: objPais,
                data_nascimento: null,
            })

        }

    }, [history, id])

    const erroContrato = (propsFormik) => {
        const erros = propsFormik.errors;
        return (erros.nome || erros.sexo || erros.raca || erros.cpf || erros.data_nascimento || erros.crm || erros.estado_crm || erros.socio_cbos || erros.tipo_documento
            || erros.numero_documento || erros.estado_documento || erros.data_expedicao || erros.pais_emissor || erros.pais || erros.telefone)
    }

    if (valoresIniciais.nome === undefined)
        return <></>
    else
        return (
            <Formik
                initialValues={valoresIniciais} validationSchema={validacao} enableReinitialize={true}
                onSubmit={(values, propsFormik) => Submit(values, dataTelefone, dataTelefoneConjuge, endereco, dadosEmpresa, dadosGrupo, dadosOcorrencia, propsFormik)}>
                {propsFormik => (
                    <Form className={props.modal !== undefined ? "full" : ""}>
                        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)} default={true}>
                            <TabList>
                                <Tab className={"react-tabs__tab " + (erroContrato(propsFormik) ? 'tab-error' : '')}><span className={"icone-tab icon-Medical-Sign "}></span>Dados Pessoais</Tab>
                                <Tab className={"react-tabs__tab " + classTab[1]}><span className={"icone-tab icon-Couple-Sign "}></span>Dados do Cônjuge</Tab>
                                <Tab className={"react-tabs__tab " + classTab[2]}><span className={"icone-tab icon-Add-User "}></span>Dependentes</Tab>
                                <Tab className={"react-tabs__tab " + classTab[3]}><span className={"icone-tab icon-Home "}></span>Endereços</Tab>
                                <Tab className={"react-tabs__tab " + classTab[4]}><span className={"icone-tab icon-Building "}></span>Sócio da Empresa</Tab>
                                <Tab className={"react-tabs__tab " + classTab[5]}><span className={"icone-tab icon-Building "}></span>Dados do Grupo</Tab>
                                <Tab className={"react-tabs__tab " + (propsFormik.touched.data_admissao && propsFormik.errors.data_admissao ? 'tab-error' : '')}><span className={"icone-tab icon-Building "}></span>Dados de Admissão</Tab>
                                <Tab className={"react-tabs__tab " + classTab[6]}><span className={"icone-tab icon-Building "}></span>Acesso Coopanest</Tab>
                            </TabList>
                            <TabPanel >
                                <div className='card mb-20'>
                                    <div className='card-body'>
                                        <h2><span>Dados Pessoais</span></h2>
                                        <div className="row">
                                            <InputPadrao
                                                tamanho="col-0"
                                                label="Id"
                                                name="id"
                                                type="hidden"
                                                placeholder="Id" />
                                            <InputPadrao
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Nome"
                                                name="nome"
                                                type="text"
                                                placeholder="Nome" obrigatorio autoFocus
                                                salvarENovo={salvarENovo}
                                                onChange={e => {
                                                    props.setSubtitulo(e.currentTarget.value)
                                                    propsFormik.handleChange(e)
                                                }}
                                            />
                                            <InputPadrao
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Sigla"
                                                name="sigla"
                                                type="text"
                                                onKeyDown={() => { if (propsFormik.values["sigla"]) propsFormik.values["sigla"] = propsFormik.values["sigla"].toString().toUpperCase(); }}
                                                placeholder="Sigla"
                                            />
                                            <InputSexo
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Sexo"
                                                name="sexo"
                                                placeholder="Sexo" obrigatorio
                                                propsFormik={propsFormik}
                                            />
                                            <InputRaca label="Raça" name="raca" placeholder="Raça" obrigatorio propsFormik={propsFormik} tamanho="col-xl-3 col-lg-6" />

                                            <InputPadrao
                                                mascara="111.111.111-11"
                                                tamanho="col-xl-3 col-lg-6"
                                                label="CPF"
                                                name="cpf"
                                                type="text" obrigatorio
                                                placeholder="CPF"
                                            />
                                            <InputEstadoCivil
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Estado Cívil"
                                                name="estado_civil"
                                                placeholder="Estado Cívil"
                                                propsFormik={propsFormik}
                                                botaoVisivel={true} />
                                            <InputDatePicker
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Data de Nascimento"
                                                name="data_nascimento" obrigatorio
                                                type="text"
                                                setarValor={value => {
                                                    propsFormik.setFieldValue("data_nascimento", value)
                                                }}
                                                placeholder="Data de Nascimento"
                                            />
                                            <InputPadrao
                                                tamanho="col-xl-3 col-lg-6"
                                                label="CRM"
                                                name="crm"
                                                type="text" obrigatorio
                                                placeholder="CRM"
                                            />
                                            <InputEstado
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Estado (CRM)"
                                                name="estado_crm" obrigatorio
                                                placeholder="Estado (CRM)"
                                                propsFormik={propsFormik}
                                            />
                                            <InputEspecialidadeMedica
                                                tamanho="col-xl-4 col-lg-6"
                                                label="Especialidade Médica"
                                                name="socio_cbos" obrigatorio
                                                multiplo={true}
                                                placeholder="Especialidade Médica"
                                                propsFormik={propsFormik}
                                                botaoVisivel={true}
                                                modal />
                                            <InputTipoDocumento label="Tipo Documento" name="tipo_documento" placeholder="Tipo Documento" obrigatorio propsFormik={propsFormik} tamanho="col-xl-3 col-lg-6" />
                                            <InputPadrao label='Nº Doc.' name='numero_documento' type='text' obrigatorio classeTooltip={'tooltip-input-modal'} tamanho="col-xl-2 col-lg-6" />
                                            <InputEstado label="UF" name="estado_documento" placeholder="Estado" obrigatorio propsFormik={propsFormik} tamanho="col-xl-3 col-lg-6 " />
                                            <InputDatePicker label="Data Expedição" name="data_expedicao" obrigatorio type="text" setarValor={value => { propsFormik.setFieldValue("data_expedicao", value) }} tamanho="col-xl-3 col-lg-6"
                                                placeholder="Data Expedição" />
                                            <InputPais label="País Emissor" name="pais_emissor" placeholder="País Emissor" obrigatorio propsFormik={propsFormik} tamanho="col-xl-3 col-lg-6" />
                                            <InputPadrao
                                                tamanho="col-xl-3 col-lg-6"
                                                label="NIT/PIS/PASEP"
                                                name="inss"
                                                type="text"
                                                placeholder="INSS"
                                            />
                                            <InputPadrao
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Titulo de Eleitor"
                                                name="titulo_eleitor"
                                                type="text"
                                                placeholder="Titulo de Eleitor"
                                            />
                                            <InputPais
                                                tamanho="col-xl-3 col-lg-6"
                                                label='Nacionalidade'
                                                name='pais'
                                                placeholder='Nacionalidade'
                                                obrigatorio
                                                propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} />
                                            <InputMunicipio
                                                tamanho="col-xl-3 col-lg-6"
                                                label='Naturalidade'
                                                name='municipio_naturalidade'
                                                placeholder='Naturalidade'
                                                propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} />
                                            <InputPadrao
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Nome da Mãe"
                                                name="nome_mae"
                                                type="text"
                                                placeholder="Nome do Mãe"
                                            />
                                            <InputPadrao
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Nome do Pai"
                                                name="nome_pai"
                                                type="text"
                                                placeholder="Nome do Pai"
                                            />
                                            <InputPadrao
                                                tamanho="col-xl-3 col-lg-6"
                                                label="E-mail"
                                                name="email"
                                                type="text"
                                                placeholder="E-mail"
                                            />
                                            <InputTelefone
                                                tamanho="col-xl-3 col-lg-3"
                                                label="Telefone"
                                                name="telefone"
                                                type="text"
                                                placeholder="Telefone" obrigatorio="true" data={dataTelefone} setData={setDataTelefone}
                                            />
                                            <InputSocioStatus
                                                isClearable={true}
                                                tamanho={'col-lg-3'}
                                                label="Status"
                                                noOptionsMessage={_ => ''}
                                                name="status"
                                                type="select"
                                                obrigatorio="true"
                                                propsFormik={propsFormik}
                                                placeholder="Status" />
                                            {dataArquivos != null ?
                                                <InputArquivoS3
                                                    label='Arquivo'
                                                    name='arquivo'
                                                    placeholder='Arquivo'
                                                    tamanho='col-lg-12'
                                                    diretorioS3='socio/DDMMYYYY/'
                                                    modulo={MODULO["/administracao/socio"]}
                                                    /*tiposAceitos={['image/png', '.pdf', 'audio/*']} */
                                                    tamanhoMinimo={0}
                                                    tamanhoMaximoEmMB={300}
                                                    /*qtdMaximaArquivos={2} */
                                                    setDataArquivos={setDataArquivos}
                                                    dataArquivos={dataArquivos != null ? dataArquivos : []}
                                                    setIsSubmit={setDisable} /> : <></>}


                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='card'>
                                    <div className='card-body'>
                                        <h2><span>Dados do Cônjuge</span></h2>
                                        <div className="row">
                                            <InputPadrao
                                                tamanho="col-0"
                                                label="Id"
                                                name="id_conjuge"
                                                type="hidden"
                                                placeholder="Id" />
                                            <InputPadrao
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Nome"
                                                name="nome_conjuge"
                                                type="text"
                                                placeholder="Nome" obrigatorio autoFocus
                                            />
                                            <InputSexo
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Sexo"
                                                name="sexo_conjuge"
                                                placeholder="Sexo" obrigatorio
                                                propsFormik={propsFormik}
                                            />
                                            <InputPadrao
                                                mascara="111.111.111-11"
                                                tamanho="col-xl-3 col-lg-6"
                                                label="CPF"
                                                name="cpf_conjuge"
                                                type="text"
                                                placeholder="CPF"
                                            />
                                            <InputDatePicker
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Data de Nascimento"
                                                name="data_nascimento_conjuge" obrigatorio
                                                type="text"
                                                setarValor={value => {

                                                    propsFormik.setFieldValue("data_nascimento_conjuge", value)
                                                }}
                                                placeholder="Data de Nascimento"
                                            />
                                            <InputTipoDocumento
                                                label="Tipo Documento"
                                                name="tipo_documento_conjuge"
                                                placeholder="Tipo Documento"
                                                obrigatorio
                                                propsFormik={propsFormik}
                                                tamanho="col-xl-3 col-lg-6"
                                            />
                                            <InputPadrao label='Nº Doc.' name='numero_documento_conjuge' type='text' obrigatorio classeTooltip={'tooltip-input-modal'} tamanho="col-xl-3 col-lg-6" />
                                            <InputEstado label="UF" name="estado_documento_conjuge" placeholder="Estado" obrigatorio propsFormik={propsFormik} tamanho="col-xl-3 col-lg-6" />
                                            <InputDatePicker label="Data Expedição" name="data_expedicao_conjuge" obrigatorio type="text" setarValor={value => { propsFormik.setFieldValue("data_expedicao_conjuge", value) }} tamanho="col-xl-3 col-lg-6"
                                                placeholder="Data Expedição" />
                                            <InputPais label="País Emissor" name="pais_emissor_conjuge" placeholder="País Emissor" obrigatorio propsFormik={propsFormik} tamanho="col-xl-3 col-lg-6" />
                                            <InputCBOS
                                                tamanho="col-xl-3 col-lg-6"
                                                label='Profissão'
                                                name='profissao_conjuge'
                                                placeholder='Profissão'
                                                botaoVisivel={true}
                                                propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} />
                                            <InputPais
                                                tamanho="col-xl-3 col-lg-6"
                                                label='Nacionalidade'
                                                name='pais_conjuge'
                                                placeholder='Nacionalidade'
                                                obrigatorio
                                                propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} />
                                            <InputMunicipio
                                                tamanho="col-xl-3 col-lg-6"
                                                label='Naturalidade'
                                                name='municipio_naturalidade_conjuge'
                                                placeholder='Naturalidade'
                                                propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} />
                                            <InputPadrao
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Nome da Mãe"
                                                name="nome_mae_conjuge"
                                                type="text"
                                                placeholder="Nome do Mãe"
                                            />
                                            <InputPadrao
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Nome do Pai"
                                                name="nome_pai_conjuge"
                                                type="text"
                                                placeholder="Nome do Pai"
                                            />
                                            <InputTelefone
                                                tamanho="col-xl-3 col-lg-6"
                                                label="Telefone"
                                                name="telefone_conjuge"
                                                type="text"
                                                placeholder="Telefone" data={dataTelefoneConjuge} setData={setDataTelefoneConjuge}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='padding-40'>
                                    <CadastroBeneficiario setDependentes={setDependentes} dependentes={dependentes} modal abrirModal={abrirModalDependente} setAbrirModal={setAbrirModalDependente} />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='padding-40'>
                                    <CadastroEndereco setEnderecos={setEndereco} enderecos={endereco} abrirModal={abrirModalEndereco} setAbrirModal={setAbrirModalEndereco} />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='padding-40'>
                                    <CadastroSocioEmpresa setData={setDadosEmpresa} data={dadosEmpresa} abrirModal={abrirModalSocioEmpresa} setAbrirModal={setAbrirModalSocioEmpresa} />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='padding-40'>
                                    <CadastroSocioGrupo setData={setDadosGrupo} data={dadosGrupo} abrirModal={abrirModalGrupo} setAbrirModal={setAbrirModalGrupo} />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='card mb-20'>
                                    <div className='card-body pt-2 pb-2'>
                                        <h4><span>Dados da Admissão</span></h4>
                                        <div className="row">
                                            <InputPadrao
                                                tamanho="col-0"
                                                label="Id"
                                                name="id_hierarquização"
                                                type="hidden"
                                                placeholder="Id" />

                                            <InputDatePicker autoFocus tamanho="col-xl-3 col-lg-6" label="Data Admissão" name="data_admissao" type="text" setarValor={value => { propsFormik.setFieldValue("data_admissao", value) }} placeholder="Data Admissão" obrigatorio />
                                            <InputDatePicker tamanho="col-xl-3 col-lg-6" label="Data Demissão" name="data_demissao" type="text" setarValor={value => { propsFormik.setFieldValue("data_demissao", value) }} placeholder="Data Demissão" />
                                            <TextArea label={'Observação'} rows={2} name='observacao_admissao' type='text' placeholder='Observação' tamanho={'col-xl-6 col-lg-6 p-0'} classeTooltip={'tooltip-input-modal'} />
                                        </div>
                                    </div>
                                    <div className='card-body pt-2 pb-2'>
                                        <h4><span>Dados da Hierarquização</span></h4>
                                        <div className="row">
                                            <InputDatePicker tamanho="col-xl-3 col-lg-6" label="Data" name="data_hierarquizacao" type="text" setarValor={value => { propsFormik.setFieldValue("data_hierarquizacao", value) }} placeholder="Data" />
                                            <InputPadrao tamanho="col-xl-3 col-lg-6" label='Posição' name='posicao_hierarquizacao' type='text' />
                                            <TextArea label={'Observação'} rows={2} name='observacao_hierarquizacao' type='text' placeholder='Observação' tamanho={'col-xl-6 col-lg-6 p-0'} classeTooltip={'tooltip-input-modal'} />
                                        </div>
                                    </div>
                                    <div className='card-body pt-2 pb-2'>
                                        <h4><span>Alinhamento Econômico para Férias</span></h4>
                                        <div className="row">
                                            <InputDatePicker tamanho="col-xl-3 col-lg-6" label="Data Alinhamento Econômico" name="data_alinhamento_economico" type="text" setarValor={value => { propsFormik.setFieldValue("data_alinhamento_economico", value) }} placeholder="Data Alinhamento Econômico" />
                                            <TextArea label={'Observação'} rows={2} name='observacao_alinhamento' type='text' placeholder='Observação' tamanho={'col-xl-6 col-lg-6 p-0'} classeTooltip={'tooltip-input-modal'} />

                                        </div>
                                    </div>
                                    <div className='card-body pt-2 pb-2'>
                                        <h4><span>Outros</span></h4>
                                        <div className="row">
                                            <InputSwitch label='Participa da Rotina Parcial?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch ' name='participa_rotina' size="md" tamanho='col-lg-4 col-xl-3 justify-left' classeTooltip={'tooltip-input-modal'} />
                                            <TextArea label={'Observação'} rows={2} name='observacao_outros' type='text' placeholder='Observação' tamanho={'col-xl-6 col-lg-6 p-0'} classeTooltip={'tooltip-input-modal'} />

                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='card mb-20'>
                                    <div className='card-body'>
                                        <h2><span>Acesso Coopanest</span></h2>
                                        <div className="row">
                                            <InputPadrao label="Usuário" name="login_coopanest" type="text" placeholder="Usuário" style={{ textTransform: 'lowercase' }} tamanho='col-lg-4' lower={true} maxLength={20} />
                                            <InputGroupSenha label="Senha" name="senha_coopanest" placeholder="Senha" tamanho={'col-lg-4 '} maxLength={30} />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                        <Atalho formik={propsFormik} salvando={isSubmit} setSalvareNovo={setSalvarENovo} salvarENovo={true} />

                        <div className='separator mt-40 mb-5'></div>
                        <div className='direita container-botoes mb-3 group-salvar-novo'>
                            <BotaoPadrao texto='Salvar' disabled={isSubmit} click={() => { setSalvarENovo(false) }} idTooltip={'salvar'} />
                            {!id && <BotaoPadrao texto='Salvar e Novo' disabled={isSubmit} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                        </div>
                    </Form>
                )
                }
            </Formik >);
}

const Cadastro = (props) => {
    const [subtitulo, setSubtitulo] = useState('')
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Administração</span>,
        () => <Link className="link-active" to={'/administracao/socio/consulta'}>Sócio</Link>,
    ]

    return (
        <>
            <HeaderCadastro titulo={"Cadastro de Sócio"} link={links} subtitulo={subtitulo} />
            <CadastroSocio {...props} setSubtitulo={setSubtitulo} />
        </>
    )
}

export { CadastroSocio }
export default Cadastro