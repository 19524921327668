import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'

const VisualizacaoEscalador = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})

    useEffect(() => {
        if (id) {
            visualizar(`/administracao/escalador`, id, obj => {
                setDados(obj)
            })
        }
    }, [id])

    const data = dados.data_registro ? {
        titulo: 'Visualização de Escalador',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Código',
                        descricao: dados.id,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Sócio',
                        descricao: dados.socio.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Usuário',
                        descricao: dados.usuario ? dados.usuario.label : '--',
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Tipo Escalador',
                        descricao: dados.tipo_escalador.label,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data Inicial',
                        descricao: dados.data_inicial,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data Final',
                        descricao: dados.data_final,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Observação',
                        descricao: dados.observacao,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data de Registro',
                        descricao: dados.data_registro,
                        tamanho: 'col-lg-6'
                    }
                ]
            }
        ]
    } : { titulo: 'Visualização de Escalador'}

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoEscalador