import React, { useState } from 'react'
import { InputGrupoEmpresa } from '../../../../components/InputsAutoComplete'
import { HeaderCadastro } from '../../../../components/Headers'
import { ModalCadastro } from '../../../../components/Modais'
import { Formik, Form } from 'formik'
import { InputPadrao, InputDatePicker } from '../../../../components/Inputs'
import { BotaoPadrao } from '../../../../components/Botoes'
import Yup, { validaData, dataMenorAtual, periodoData } from '../../../../util/Validacoes'
import { removeAcentos } from '../../../../util/FuncoesComuns'
import Atalho from '../../../../util/Atalho'

const Cadastro = props => {
    const [pesquisa, setPesquisa] = useState('')
    const [msgBotao, setMsgBotao] = useState('Adicionar')
    const [salvando, setSalvando] = useState(false)

    const emptyValues = {

    }

    const [values, setValues] = useState(emptyValues)
    const Submit = (socioGrupo, { resetForm }) => {
        setSalvando(true);

        let copiaDependentes = [...props.data]

        if (socioGrupo.verificador) {
            let index = copiaDependentes.findIndex(x => x.verificador === socioGrupo.verificador)
            copiaDependentes[index] = socioGrupo
        }
        else {
            socioGrupo.verificador = Math.random()
            copiaDependentes.push(socioGrupo)
        }

        props.setData(copiaDependentes)
        resetForm({})

        if (msgBotao === 'Alterar')
            props.setAbrirModal(!props.abrirModal)


        setSalvando(false)
    }
    const Delete = index => {
        let copiaDependentes = [...props.data]
        copiaDependentes.splice(index, 1);
        props.setData(copiaDependentes)
    }

    return (
        <>
            <HeaderCadastro
                titulo={'Sócio da Grupo'}
                pesquisa={true}
                valorPesquisa={pesquisa}
                onChangePesquisa={pesquisa => setPesquisa(pesquisa)}
                classeChildren='space-between-100' >
                <BotaoPadrao
                    type='button'
                    click={_ => {
                        setValues(emptyValues)
                        props.setAbrirModal(!props.abrirModal);
                        setMsgBotao('Adicionar')
                    }}
                    classe='btn-primary primary p-5rem height-auto m-r-15'
                    texto={<><span> Adicionar</span> <span class='icon-Users fw'>+</span></>} />
            </HeaderCadastro>
            <div className='card mb-20'>
                <div className='card-body'>
                    <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                        <thead>
                            <tr>
                                <th style={{ width: '5%' }}>#</th>
                                <th style={{ width: '35%' }}>Grupo</th>
                                <th style={{ width: '10%' }}>Data Entrada</th>
                                <th style={{ width: '10%' }}>Data Saída</th>
                                <th style={{ width: '20%' }}>Observação</th>
                                <th style={{ width: '20%' }} className='text-center'>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.length ?
                                props.data.filter(x => removeAcentos(x.observacao ? x.observacao.toLowerCase() : "").includes(removeAcentos(pesquisa.toLowerCase()))
                                    || removeAcentos(x.grupo.label.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())))
                                    .map((socioGrupo, index) =>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{socioGrupo.grupo.label}</td>
                                            <td>{socioGrupo.data_inicial}</td>
                                            <td>{socioGrupo.data_final}</td>
                                            <td>{socioGrupo.observacao}</td>
                                            <td className='text-center'>
                                                <span className='icon-consulta icon-Pen'
                                                    onClick={_ => { setValues(socioGrupo); props.setAbrirModal(true); setMsgBotao('Alterar') }} />
                                                <span className='icon-consulta icon-Column-2' onClick={target => Delete(index)} />
                                            </td>
                                        </tr>
                                    ) : <tr><td colspan='6' className='text-center'>Nenhum Registro Cadastrado</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <ModalCadastro titulo={'Dados do Grupo'} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    enableReinitialize={true}
                    initialValues={values}
                    validationSchema={Yup.object({
                        id: Yup.string().nullable(),
                        grupo: Yup.string().nullable().required(),
                        data_inicial: Yup.string().nullable().required().test('Data Início', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) }).test('Data Inicial', 'Data inicial deve ser menor ou igual a Data Final!', function (value) { return periodoData(this.parent.data_inicial, this.parent.data_final) }),
                        data_final: Yup.string().nullable().test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) }),
                        observacao: Yup.string().nullable().max(100)
                    })}
                    onSubmit={Submit}>
                    {propsFormik => <Form>
                        <div className='modal-body-cadastro'>
                            <InputPadrao label='Id' name='id' type='hidden' obrigatorio={false} />
                            <InputGrupoEmpresa label="Grupo" name="grupo" placeholder="Grupo" obrigatorio={true} classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} autoFocus focarAposSubmit={true} />
                            <InputDatePicker label="Data Inicial" name="data_inicial" type="text" classeTooltip={'tooltip-input-modal'} setarValor={value => { propsFormik.setFieldValue("data_inicial", value) }} placeholder="Data Inicial" obrigatorio={true} />
                            <InputDatePicker label="Data Final" name="data_final" type="text" classeTooltip={'tooltip-input-modal'} setarValor={value => { propsFormik.setFieldValue("data_final", value) }} placeholder="Data Final" />
                            <InputPadrao label='Observação' name='observacao' type='text' classeTooltip={'tooltip-input-modal'} />
                            <Atalho formik={propsFormik} salvando={salvando} />
                        </div>
                        <div className='direita container-botoes'>
                            <BotaoPadrao texto={msgBotao} />
                        </div>
                    </Form>}
                </Formik>
            </ModalCadastro>
        </>)
}

export default Cadastro