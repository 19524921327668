import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'

const Visualizacao = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/faturamento/procedimento', id,
            obj =>
                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização de Procedimento',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Tabela', descricao: obj.tabela_procedimento.descricao, tamanho: 'col-lg-4' },
                                { titulo: 'Código Procedimento', descricao: obj.codigo, tamanho: 'col-lg-4' },
                                { titulo: 'Código Tabela TISS', descricao: obj.codigo_tabela_tiss, tamanho: 'col-lg-4' },
                                { titulo: 'Descrição', descricao: obj.descricao, tamanho: 'col-lg-4' },
                                { titulo: 'Porte Fração', descricao: obj.porte_fracao_visualizacao, tamanho: 'col-lg-4' },
                                { titulo: 'Porte Procedimento', descricao: obj.porte, tamanho: 'col-lg-4' },
                                { titulo: 'Valor Porte', descricao: obj.valor_porte_visualizacao && 'R$ ' + obj.valor_porte_visualizacao, tamanho: 'col-lg-4' },
                                { titulo: 'Custo Operacional', descricao: obj.custo_operacional_visualizacao && 'R$ ' + obj.custo_operacional_visualizacao, tamanho: 'col-lg-4' },
                                { titulo: 'Número Auxiliar', descricao: obj.numero_auxiliar, tamanho: 'col-lg-4' },
                                { titulo: 'Porte Anestésico', descricao: obj.porte_anestesico, tamanho: 'col-lg-4' },
                                { titulo: 'Incidência', descricao: obj.incidencia, tamanho: 'col-lg-4' },
                                { titulo: 'Filme', descricao: obj.filme_visualizacao, tamanho: 'col-lg-4' },
                                { titulo: 'Unidade Radiofármaco', descricao: obj.unidade_radiofarmaco, tamanho: 'col-lg-4' },
                                { titulo: 'Data de Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                                { titulo: 'Data Atualização', descricao: obj.data_atualizacao || '--', tamanho: 'col-lg-4' },
                                { titulo: 'Usuário Atualização', descricao: obj.nome || '--', tamanho: 'col-lg-4' }
                            ]
                        }
                    ]
                } : { titulo: 'Visualização de Procedimento' })
        )
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default Visualizacao