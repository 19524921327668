import { removeAcentos, formatTwoPlace } from '../../../../util/FuncoesComuns'
import { InputPadrao, InputPercentual, InputTimePicker } from '../../../../components/Inputs'
import { InputTodosProcedimentos, InputPorArrayBtn, InputPorArray, InputSocioAtivos } from '../../../../components/InputsAutoComplete'
import { HeaderCadastro, HeaderH5Cadastro } from '../../../../components/Headers'
import { ModalCadastro } from '../../../../components/Modais'
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import Yup from '../../../../util/Validacoes'
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { Formik, Form, useFormikContext, FieldArray, Field } from 'formik'
import Atalho from '../../../../util/Atalho'
import { BtnAcao, BtnAcaoRef } from '../../../../components/Acoes'
import { ref } from 'yup'

export default props => {
    const [pesquisa, setPesquisa] = useState('')
    const [stateFormik, setStateFormik] = useState({})
    const target = useRef(null)
    const [isSubmit, setIsSubmit] = useState(false)

    const [msgBotao, setMsgBotao] = useState('Adicionar')

    const emptyValues = {
        id: null,
        procedimento: null,
        quantidade: null,
        paciente: null
    }

    const [values, setValues] = useState(emptyValues)

    useEffect(() => {
        const value2 = { ...values }
        value2.paciente = { value: 1, label: 'Testando' }
    }, [props.pacienteInserido])

    const Submit = (obj, { resetForm }) => {
        let copiaArrayDados = [...props.arrayDados]

        if (obj.verificador)
            copiaArrayDados[copiaArrayDados.findIndex(x => x.verificador === obj.verificador)] = obj
        else {
            obj.verificador = Math.random()
            copiaArrayDados.push(obj)
        }

        props.setArrayDados(copiaArrayDados)
        resetForm({})

        if (msgBotao === 'Alterar')
            props.setAbrirModal(!props.abrirModal)
    }

    const Delete = index => {
        let copiaArrayDados = [...props.arrayDados]
        copiaArrayDados.splice(index, 1);
        props.setArrayDados(copiaArrayDados)
    }

    const onChangeProced = (option, propsFormik) => {
        try {
            propsFormik.setFieldValue('procedimento', option);
            if (option)
                propsFormik.setFieldValue('quantidade', 1);
            else
                propsFormik.setFieldValue('quantidade', null);
        } catch (err) {
            console.log('eee', err)
        }

    }


    return (
        <>
            <div className="mt-40">
                <HeaderCadastro
                    titulo={'Procedimentos'}
                    classeChildren='space-between-100' >
                    <BotaoPadrao type='button' classe='btn-primary primary p-5rem height-auto m-r-15' texto={<><span> Adicionar</span></>}
                        click={_ => { setValues(emptyValues); props.setAbrirModal(!props.abrirModal); setMsgBotao('Adicionar') }} />
                </HeaderCadastro>
            </div>
            <div className="card mb-20">
                <div className="card-body">
                    <table className='table tabela-endereco table-responsive-md table-striped tabela' >
                        <thead>
                            {
                                <tr>
                                    <th style={{ width: '5%' }}>#</th>
                                    <th style={{ width: '30%' }}>Paciente</th>
                                    <th style={{ width: '30%' }}>Procedimento</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Hora</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Qtd</th>
                                    <th style={{ width: '15%' }} className='text-center'>Ações</th>
                                </tr>
                            }
                        </thead>
                        <tbody>
                            {
                                props.arrayDados && props.arrayDados.length ?
                                    props.arrayDados.filter(x =>
                                        removeAcentos(x.procedimento.label.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())))
                                        .map((x, index) => {
                                            let hora = [];

                                            if (x.hora_final)
                                                hora.push(x.hora_inicial)
                                            if (x.hora_final)
                                                hora.push(x.hora_final)

                                            return <tr>
                                                <td>{index + 1}</td>
                                                <td>{x.paciente ? x.paciente.label : '--'}</td>
                                                <td>{x.procedimento.label}</td>
                                                <td style={{ textAlign: 'center' }}>{hora.length ? hora.join(' - ') : '--'}</td>
                                                <td style={{ textAlign: 'center' }}>{x.quantidade}</td>
                                                <td className='text-center'>
                                                    <span className='icon-consulta icon-Pen'
                                                        onClick={_ => { setValues(x); props.setAbrirModal(true); setMsgBotao('Alterar') }} />
                                                    <span className='icon-consulta icon-New-Trash' onClick={target => Delete(index)} />
                                                </td>
                                            </tr>
                                        }
                                        )
                                    : <tr><td colspan='6' className='text-center'>Nenhum Registro Cadastrado</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <ModalCadastro titulo={'Procedimento'} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                <Formik
                    enableReinitialize={true}
                    initialValues={values}
                    validationSchema={Yup.object({
                        paciente: Yup.string().ensure().nullable(),
                        procedimento: Yup.string().ensure().required().nullable(),
                        quantidade: Yup.string().ensure().nullable(),
                    })}
                    onSubmit={Submit}>
                    {propsFormik => <Form>
                        <div className='modal-body-cadastro'>
                            <InputPadrao label='Id' name='id' type='hidden' />
                            <InputPorArrayBtn
                                propsFormik={propsFormik}
                                ref={target}
                                placeholder='Paciente Agendamento'
                                label='Paciente Agendamento'
                                tamanho='col-12'
                                name='paciente'
                                value={propsFormik.getFieldProps('paciente').value}
                                onChange={option => { option && option.target ? propsFormik.setFieldValue('paciente', option.target.value) : propsFormik.setFieldValue('paciente', option) }}
                                data={props.pacientes}
                                autoFocus focarAposSubmit={true}
                                mostrarSemDados={true} botaoVisivel={true} action={() => { props.chamarModalPaciente(); props.formikProcedimentos(propsFormik); }} />
                            <InputTodosProcedimentos onChange={(option) => onChangeProced(option, propsFormik)} label='Procedimento' name="procedimento" propsFormik={propsFormik} obrigatorio {...props} classeTooltip={'tooltip-input-modal'} modal={true} />
                            <InputPadrao label='Quantidade' name='quantidade' type='number' placeholder='Quantidade' tamanho={'col-md-12'} classeTooltip={'tooltip-input-modal'} />
                            <InputTimePicker
                                label="Hora Inicial" mascara="11:11" name="hora_inicial" type="text" placeholder="Hora Inicial" tamanho={'col-lg-12'} classeTooltip={'tooltip-input-modal'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("hora_inicial", value)
                                }}
                            />

                            <InputTimePicker
                                label="Hora Final" mascara="11:11" name="hora_final" type="text" placeholder="Hora Final" tamanho={'col-lg-12'} classeTooltip={'tooltip-input-modal'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("hora_final", value)
                                }}
                            />
                            <Atalho formik={propsFormik} salvando={isSubmit} />


                        </div>
                        <div className='direita container-botoes'>
                            <BotaoPadrao texto={msgBotao} />
                        </div>
                    </Form>}
                </Formik>
            </ModalCadastro>
        </>)
}

const CadastroRapidoProcedimento = props => {
    const [pesquisa, setPesquisa] = useState('')
    const [stateFormik, setStateFormik] = useState({})
    const [alteracao, setAlteracao] = useState(false)
    const target = useRef(null)

    const [msgBotao, setMsgBotao] = useState('Adicionar')

    const emptyValues = {
        id: null,
        procedimento: null,
        quantidade: null
    }

    const [values, setValues] = useState(emptyValues)

    useEffect(() => {
        const value2 = { ...values }
        value2.paciente = { value: 1, label: 'Testando' }
    }, [props.pacienteInserido])

    useEffect(() => {
        if (props.itemAlterar) {
            setValues(props.itemAlterar)
            setAlteracao(true)
        }
    }, [props.itemAlterar])

    const Submit = (obj, { resetForm }) => {
        let copiaArrayDados = [...props.arrayDados]

        if (obj.verificador)
            copiaArrayDados[copiaArrayDados.findIndex(x => x.verificador === obj.verificador)] = obj
        else {
            obj.verificador = Math.random()
            copiaArrayDados.push(obj)
        }

        props.setArrayDados(copiaArrayDados)
        resetForm({})
        setValues(emptyValues)
        setAlteracao(false)

        if (msgBotao === 'Alterar')
            props.setAbrirModal(!props.abrirModal)
    }

    const Delete = index => {
        let copiaArrayDados = [...props.arrayDados]
        copiaArrayDados.splice(index, 1);
        props.setArrayDados(copiaArrayDados)
    }

    const onChangeProced = (option, propsFormik) => {
        if (option) {
            propsFormik.setFieldValue('procedimento', option);
            propsFormik.setFieldValue('quantidade', 1);
        }
        else {
            propsFormik.setFieldValue('procedimento', null);
            propsFormik.setFieldValue('quantidade', null);
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={values}
                validationSchema={Yup.object({
                    procedimento: Yup.string().ensure().required().nullable(),
                    quantidade: Yup.string().ensure().nullable(),
                })}
                onSubmit={Submit}>
                {propsFormik => <Form>
                    <div className='modal-body-cadastro row mr-0 ml-0'>
                        <InputTodosProcedimentos label='Procedimento' id="procedimento" name="procedimento" propsFormik={propsFormik} obrigatorio tamanho="col-12 pl-0 pr-0"
                            {...props} classeTooltip={'tooltip-input-modal'} modal={true} onChange={(option) => onChangeProced(option, propsFormik)} />
                        <InputPadrao label='Quantidade' name='quantidade' type='number' placeholder='Qtde' classeTooltip={'tooltip-input-modal'} tamanho="col-5 pl-0 pr-0" />

                        <div className='form-group col-2 text-right pr-0'>
                            <BotaoPadrao textoTooltip={alteracao ? "Alterar" : "Adicionar"} icone={alteracao ? 'icon-Pen' : 'icon-Add'} classe=' btn btn-primary primary btn-filter mt-20' idTooltip={"padrao"} />
                            {/* <BotaoPadrao texto={"+"} /> */}
                        </div>
                    </div>
                </Form>}
            </Formik>
        </>)
}

const CadastroRapidoProcedimentoV2 = React.forwardRef((props, ref) => {
    const [alteracao, setAlteracao] = useState(false)
    const schema = Yup.object().shape({
        procedimentos: Yup.array()
            .of(
                Yup.object().shape({
                    procedimento: Yup.string().nullable().required(), // these constraints take precedence
                    quantidade: Yup.string().nullable().required(), // these constraints take precedence
                })
            )
    });

    useEffect(() => {
        if (props.abrirCadastro)
            props.propsFormik.setFieldValue('procedimentos', [{ procedimento: undefined, quantidade: 1 }])
    }, props.abrirCadastro)

    const [values, setValues] = useState({})

    useEffect(() => {
        if (props.itemAlterar) {
            setValues(props.itemAlterar)
            setAlteracao(true)
        }
    }, [props.itemAlterar])

    const onChangeProced = (option, propsFormik, arrayHelpers, index) => {
        const arrayProcedimentos = [...propsFormik.values.procedimentos]
        if (option) {
            const valorAnterior = { ...propsFormik.values.procedimentos[index] }

            arrayProcedimentos[index].procedimento = option
            if (!arrayProcedimentos[index].quantidade)
                arrayProcedimentos[index].quantidade = 1

            propsFormik.setFieldValue('procedimentos', arrayProcedimentos);

            if (!valorAnterior.procedimento || !valorAnterior.procedimento.value)
                adicionarLinha(arrayHelpers, propsFormik)
        }
        else {
            arrayProcedimentos[index].procedimento = undefined
            propsFormik.setFieldValue('procedimentos', arrayProcedimentos);
        }
    }

    const onChangeQtd = (event, propsFormik, arrayHelpers, index) => {
        const arrayProcedimentos = [...propsFormik.values.procedimentos]
        if (event.target.value) {
            const valorAnterior = { ...propsFormik.values.procedimentos[index] }

            arrayProcedimentos[index].quantidade = event.target.value
            propsFormik.setFieldValue('procedimentos', arrayProcedimentos);

            if (!valorAnterior.procedimento || !valorAnterior.quantidade)
                adicionarLinha(arrayHelpers, propsFormik)
        }
        else {
            arrayProcedimentos[index].quantidade = undefined
            propsFormik.setFieldValue('procedimentos', arrayProcedimentos);
        }
    }

    const onBlurQtd = (event, propsFormik, arrayHelpers, index) => {
        const arrayProcedimentos = [...propsFormik.values.procedimentos]
        if (!event.target.value && arrayProcedimentos[index].procedimento && arrayProcedimentos[index].procedimento.value) {
            arrayProcedimentos[index].quantidade = 1
            propsFormik.setFieldValue('procedimentos', arrayProcedimentos);
        }
    }

    const adicionarLinha = (arrayHelpers, propsFormik) => {
        const arrayProced = propsFormik.values.procedimentos;
        if (arrayProced && arrayProced.findIndex(x => !x.procedimento || !x.procedimento.value || !x.quantidade) === -1) {
            arrayHelpers.insert(propsFormik.values.procedimentos.length, { procedimento: undefined, quantidade: 1 })
        }
    }

    const removerLinha = (arrayHelpers, propsFormik, index) => {
        const arrayProced = propsFormik.values.procedimentos;
        if (arrayProced[index].procedimento && arrayProced[index].procedimento.value)
            arrayHelpers.remove(index)

        if (arrayProced.length === 1 && arrayProced[index].procedimento && arrayProced[index].procedimento.value)
            arrayHelpers.insert(propsFormik.values.procedimentos.length, { procedimento: undefined, quantidade: 1 })
    }

    return (
        <>
            <div className="card mt-2">
                <div className="card-body procedimento-float custom-scrollbar">
                    <FieldArray name="procedimentos">
                        {(arrayHelpers => (
                            <>
                                {props.propsFormik.values.procedimentos && props.propsFormik.values.procedimentos.length > 0 ?
                                    props.propsFormik.values.procedimentos.map((item, index) => <div className='modal-body-cadastro row mr-0 ml-0'>
                                        <InputTodosProcedimentos label={index === 0 ? 'Procedimento' : undefined} name={`procedimentos[${index}].procedimento`}
                                            propsFormik={props.propsFormik} obrigatorio tamanho="col-sm-12 col-xl-9 col-lg-8 col-md-8 pl-0" {...props} classeTooltip={'tooltip-input-modal'} modal={true} isClearable={false} placeholder="Procedimento"
                                            onChange={(option) => onChangeProced(option, props.propsFormik, arrayHelpers, index)} className={'procedimento-linha'} autoFocus />

                                        <InputPadrao label={index === 0 ? 'Qtde' : undefined} name={`procedimentos[${index}].quantidade`} type='number'
                                            placeholder='Qtde' classeTooltip={'tooltip-input-modal'} obrigatorio tamanho="col-8 col-xl-2 col-lg-2 col-md-2 pl-0 pr-0" onChange={(event) => {
                                                onChangeQtd(event, props.propsFormik, arrayHelpers, index)
                                            }} onBlur={(event) => onBlurQtd(event, props.propsFormik, arrayHelpers, index)} />

                                        <div className='col-4 col-xl-1 col-lg-2 col-md-2 mb-0 pr-0 pl-1 pb-3 content-bottom-center text-right'>
                                            <BtnAcao type={'button'} icone="icon-New-Trash" texto="Remover" action={() => removerLinha(arrayHelpers, props.propsFormik, index)} />
                                        </div>
                                    </div>)
                                    :
                                    <></>}
                            </>
                        ))}
                    </FieldArray>
                </div>
            </div>
        </>)
})

export { CadastroRapidoProcedimento, CadastroRapidoProcedimentoV2 }