import React, { useState, useRef, useEffect, useMemo } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useField, useFormikContext } from "formik";
import { FiAlertTriangle } from "react-icons/fi";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Overlay, Tooltip } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import MaskedInput from 'react-maskedinput'
import InfiniteCalendar, {
    Calendar,
    defaultMultipleDateInterpolation,
    withMultipleDates,
    withDateSelection
} from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import '../assets/css/time-picker.css';
import { ModalTelefone, ModalCalendar } from './Modais'
import TimeKeeper from 'react-timekeeper';
import moment from "moment"
import 'moment/locale/pt-br'
import { useWindowSize, useInterval, convertValueToFloat } from '../util/Utilitarios'
import { formatMoeda, formatTwoPlace, removeAcentos } from '../util/FuncoesComuns.js'
import { getUsuario } from '../util/Configuracoes'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { get } from '../util/AcessoApi'
import Switch from "react-switch"
import { BotaoPadrao } from './Botoes'
import CreatableSelect from 'react-select/creatable';
import { mostrarToast } from './Toasts'

import { withProps } from 'recompose';
// import CurrencyInput from 'react-currency-input-field';
// import CurrencyInput from 'react-currency-masked-input'
import CurrencyInput from 'react-currency-input';
import { useCallback } from 'react';
import IntlCurrencyInput from "react-intl-currency-input"
// import CpfCnpjInput from 'react-cpf-cnpj-input'


// Enhance Day component to display selected state based on an array of selected dates
const enhanceDay = highlighted => withProps(props => ({
    isHighlighted: highlighted.indexOf(props.date) !== -1,
}
));


// Enhancer to highlight multiple dates
export const withHighlightedDates = withProps(({
    highlighted,
    DayComponent,
}) => ({
    DayComponent: enhanceDay(highlighted)(DayComponent),
}));

var flag = 1

const InputPadrao = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const [mascara, setMascara] = useState(props.mascara)
    const target = useRef(null);
    const [blured, setBlured] = useState(false)
    const name = props.name
    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    field.value = field.value || '';
    let placeholder = props.placeholder || label;
    delete props.classeTooltip
    let classTamanho = props.tamanho ? props.tamanho : "col-12";
    const form2 = useFormikContext();
    const [ultimaTecla, setUltimaTecla] = useState("")

    const validacao = (char, value) => {
        if (props.charValidacao(char)) {
            setMascara(props.mascaraReplace)
        } else {
            setMascara(props.mascara)
        }
        return props.charValidacao(char);
    }

    const trocarValorInputTelefone = () => {
        if (!props.mascaraTelefone)
            return
        let v = field.value.toString();
        var r = v.replace(/\D/g, "");
        r = r.replace(/^0/, "");
        if (r.length > 10) {
            // 11+ digits. Format as 5+4.
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        }
        else if (r.length > 5) {
            // 6..10 digits. Format as 4+4
            r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        }
        else if (r.length > 2) {
            // 3..5 digits. Add (0XX..)
            r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        }
        else {
            // 0..2 digits. Just add (0XX
            r = r.replace(/^(\d*)/, "($1");
        }
        if (ultimaTecla === "Backspace" && (r.slice(-1) === "-" || r.slice(-1) === "(" || r.slice(-1) === ")" || r.slice(-1) === " ")) {
            r = r.substring(0, r.length - 1)
            return;
        }
        form2.setFieldValue(field.name, field.value.length < 1 ? "" : r)
    }

    useEffect(() => {
        trocarValorInputTelefone()
    }, [field.value])

    useEffect(() => {
        if (props.salvarENovo && props.autoFocus)
            target.current.focus()
    }, [props.salvarENovo])

    return (
        <div className={"form-group " + classTamanho}>
            {(!label && props.type !== "hidden") || props.type === "hidden" ? <></> : <label htmlFor={props.id || props.name}>{label}{props.obrigatorio != undefined ? " *" : ""}</label>}
            {props.mascara && props.mascara != false ?
                <div ref={target}>
                    <MaskedInput mask={mascara} formatCharacters={{ '?': { validate(char, value) { return validacao(char, value) } } }} className={"form-control" + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")} placeholder={placeholder} {...field} {...props} />
                </div>
                :
                <input onKeyDown={(e) => { if (props.mascaraTelefone) { setUltimaTecla(e.nativeEvent.key); } }} ref={target} onFocus={() => { setBlured(true) }} className={"form-control" + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")} placeholder={placeholder} {...field} {...props} />
            }
            {(meta.touched && meta.error) || ((props.obrigatorio && props?.propsFormik?.submitCount > 0) && meta.error) ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={`error-${name} error-tooltip ` + classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    );
}

const InputPadraoSimples = ({ label, performance, ...props }) => {
    const form2 = useFormikContext();
    let [field, meta] = useField(props);
    const [valorCampo, setValorCampo] = useState(undefined)
    const [count, setCount] = useState(0)
    useEffect(() => {
        if (count <= 1 && form2.values[props.name] && performance) {
            setValorCampo(form2.values[props.name])
            setCount(count + 1)
        } else if (!form2.values[props.name]) {
            setValorCampo("")
        }
    }, [form2.values[props.name]])
    const target = useRef(null);
    const name = props.name
    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    field.value = field.value || '';
    let placeholder = props.placeholder || label;
    delete props.classeTooltip
    let classTamanho = props.tamanho ? props.tamanho : "col-12";
    field = performance ? { onChange: (e) => setValorCampo(e.target.value), onBlur: (e) => { form2.setFieldValue(props.name, e.target.value) }, value: valorCampo } : field

    useEffect(() => {
        if (props.salvarENovo && props.autoFocus)
            target.current.focus()
    }, [props.salvarENovo])

    return (
        <div className={"form-group " + classTamanho}>
            {(!label && props.type !== "hidden") || props.type === "hidden" ? <></> : <label htmlFor={props.id || props.name}>{label}{props.obrigatorio != undefined ? " *" : ""}</label>}
            <input ref={target} className={"form-control" + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")} placeholder={placeholder} {...field} {...props} />
            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={`error-${name} error-tooltip ` + classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    );
}

const InputMascaraSimples = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const [mascara, setMascara] = useState(props.mascara)
    const target = useRef(null);
    const name = props.name
    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    field.value = field.value || '';
    let placeholder = props.placeholder || label;
    delete props.classeTooltip
    let classTamanho = props.tamanho ? props.tamanho : "col-12";

    const validacao = useCallback((char, value) => {
        if (props.charValidacao(char)) {
            setMascara(props.mascaraReplace)
        } else {
            setMascara(props.mascara)
        }
        return props.charValidacao(char);
    }, [])

    useEffect(() => {
        if (props.salvarENovo && props.autoFocus)
            target.current.focus()
    }, [props.salvarENovo])

    return (
        <div className={"form-group " + classTamanho}>
            {(!label && props.type !== "hidden") || props.type === "hidden" ? <></> : <label htmlFor={props.id || props.name}>{label}{props.obrigatorio != undefined ? " *" : ""}</label>}
            {
                <div ref={target}>
                    <MaskedInput mask={mascara} formatCharacters={{ '?': { validate(char, value) { return validacao(char, value) } } }} className={"form-control" + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")} placeholder={placeholder} {...field} {...props} />
                </div>
            }
            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={`error-${name} error-tooltip ` + classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    );
}

const InputDatePickerMultiplo = React.forwardRef((props, ref) => {
    const [width, height] = useWindowSize();
    const [field, meta] = useField(props);
    const [showCalendar, setShowCalendar] = useState(false);
    const [dataSelecionada, setDataSelecionada] = useState([]);
    const [diaSelecionado, setDiaSelecionado] = useState([])
    const [mascara, setMascara] = useState('11/11/1111')
    const [datasEmSelecao, setDatasEmSelecao] = useState([]);
    const refInputData = useRef(null)
    const [highlightedDays, setHighlightedDays] = useState([])

    useEffect(() => {
        if (props.mostrarFeriado) {
            get("/administracao/obterFeriados", {}, true)
                .then((dados) => {
                    setHighlightedDays(dados.map(item => item.feriado_eua))
                })
        }
    }, [props.mostrarFeriado])

    useEffect(() => {
        let convertidas = [];
        for (let index = 0; index < datasEmSelecao.length; index++) {
            convertidas.push(moment(datasEmSelecao[index], 'DD/MM/YYYY').toDate());
        }
        setDataSelecionada(convertidas);
        if (refInputData && refInputData.current && refInputData.current.atualizarDatas) {
            refInputData.current.atualizarDatas(convertidas);
        }
        if (props.onChange) {
            props.onChange(datasEmSelecao);
        }

        props.propsFormik.setFieldValue(props.name, datasEmSelecao);
    }, [datasEmSelecao])



    useEffect(() => {
        if (field.value) {
            setDatasEmSelecao(field.value)
        }
    }, [])

    const action = (input) => {
        setShowCalendar(!showCalendar)
    }

    useEffect(() => {
        try {
            verificaTooltip = Array.from(document.getElementsByClassName("sem-tooltip"))
            verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "fade error-tooltip tooltip-input-modal show tooltip bs-tooltip-bottom")
        }
        catch (err) {

        }


        var timePicker = false
        if (document.getElementsByClassName("ReactModal__Content--after-open")[0]) //verifica se o time picker está aberto
            timePicker = true
        else
            timePicker = false


        var verificaTooltip = []

        if (timePicker && props.modal) {
            verificaTooltip = Array.from(document.getElementsByClassName("error-tooltip "))
            if (showCalendar)
                verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "sem-tooltip")

        }
        if (timePicker && !props.modal) {
            verificaTooltip = Array.from(document.getElementsByClassName("fade error-tooltip  show tooltip bs-tooltip-bottom"))
            if (showCalendar)
                verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "sem-tooltip error-tooltip")
        }
        else {
            verificaTooltip = Array.from(document.getElementsByClassName("sem-tooltip"))
            verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "fade error-tooltip tooltip-input-modal show tooltip bs-tooltip-bottom")

        }

    }, [action])


    const MultipleDatesCalendar = withMultipleDates(withHighlightedDates(Calendar));

    const infitinitCalendar = useMemo(() => <>
        <div className="bottom-stuff">
            <ModalCalendar show={showCalendar} setShowModal={setShowCalendar}>
                <InfiniteCalendar
                    Component={MultipleDatesCalendar}
                    highlighted={highlightedDays}
                    interpolateSelection={defaultMultipleDateInterpolation}
                    className={showCalendar ? "calendario-ativo" : "calendario-oculto"}
                    locale={{
                        locale: require('date-fns/locale/pt'),
                        blank: 'Selecione uma data',
                        headerFormat: 'ddd, MMM Do',
                        // weekdays: ["Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb", "Dom"],
                        weekdays: ["Dom", "Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb"],
                        todayLabel: {
                            long: 'Hoje',
                            short: 'Hoje'
                        },
                        weekStartsOn: 0,
                    }}
                    selected={dataSelecionada}
                    afterSelect={(value) => {
                    }}
                    onSelect={(value) => {
                        let valorAtual = [...datasEmSelecao, moment(value).format('DD/MM/YYYY')];
                        let duplicates = valorAtual.filter((item, index) => valorAtual.indexOf(item) != index)
                        let arrayDesordenado = valorAtual.filter(item => !duplicates.includes(item));
                        setDatasEmSelecao(arrayDesordenado.sort((a, b) => moment(a, 'DD/MM/YYYY') - moment(b, 'DD/MM/YYYY')))
                    }}
                    height={height - 210}
                    width={width < 501 ? width : 403}
                />
                <div className="bottom">
                    <BotaoPadrao classe={"btn btn-sm btn-primary-modal simples"} type="button" texto='Fechar' click={_ => { setShowCalendar(!showCalendar) }} idTooltip={"fechar"} />
                    <BotaoPadrao type="button" texto='Selecionar' click={_ => { setShowCalendar(!showCalendar) }} idTooltip={"selecionar"} />
                </div>
            </ModalCalendar>
        </div>
    </>, [showCalendar, dataSelecionada])


    return (<>
        {infitinitCalendar}
        <InputGroupBtnDataMultipla
            ref={refInputData}
            mascara={mascara}
            datasEmSelecao={datasEmSelecao}
            setDatasEmSelecao={setDatasEmSelecao}
            action={(target) => { action(target) }}

            texto={''} icone={(showCalendar ? 'icon-Calendar-4' : "icon-Calendar-4")} disabled={false} {...props} {...field} /></>);
})

const InputDatePicker = (props) => {
    const [width, height] = useWindowSize();
    const [field, meta] = useField(props);
    const [showCalendar, setShowCalendar] = useState(false);
    const [dataSelecionada, setDataSelecionada] = useState(new Date());
    const [diaSelecionado, setDiaSelecionado] = useState(new Date())
    const [highlightedDays, setHighlightedDays] = useState([])
    const [lastValue, setLastValue] = useState(field.value)

    useEffect(() => {
        if (props.mostrarFeriado) {
            get("/administracao/obterFeriados", {}, true)
                .then((dados) => {
                    setHighlightedDays(dados.map(item => item.feriado_eua))
                })
        }
    }, [props.mostrarFeriado])

    const action = (input) => {
        setShowCalendar(!showCalendar)
    }

    const decrement = (input) => {
        let data = moment(field.value, 'DD/MM/YYYY').subtract(1, 'd');
        props.setarValor(moment(data).format("DD/MM/YYYY"));
    }

    const increment = (input) => {
        let data = moment(field.value, 'DD/MM/YYYY').add(1, 'days');
        props.setarValor(moment(data).format("DD/MM/YYYY"));
    }

    useEffect(() => {
        try {
            verificaTooltip = Array.from(document.getElementsByClassName("sem-tooltip"))
            verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "fade error-tooltip tooltip-input-modal show tooltip bs-tooltip-bottom")
        }
        catch (err) {

        }


        var timePicker = false
        if (document.getElementsByClassName("ReactModal__Content--after-open")[0]) //verifica se o time picker está aberto
            timePicker = true
        else
            timePicker = false


        var verificaTooltip = []

        if (timePicker && props.modal) {
            verificaTooltip = Array.from(document.getElementsByClassName("error-tooltip "))
            if (showCalendar)
                verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "sem-tooltip")

        }
        if (timePicker && !props.modal) {
            verificaTooltip = Array.from(document.getElementsByClassName("fade error-tooltip  show tooltip bs-tooltip-bottom"))
            if (showCalendar)
                verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "sem-tooltip error-tooltip")
        }
        else {
            verificaTooltip = Array.from(document.getElementsByClassName("sem-tooltip"))
            verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "fade error-tooltip tooltip-input-modal show tooltip bs-tooltip-bottom")

        }

    }, [action])


    useEffect(() => {
        var dataSemFormatacao = field.value && new Date(dataSelecionada)
        var options = { weekday: 'long' }
        var retorno = new Intl.DateTimeFormat('pt-BR', options).format(dataSemFormatacao)
        if (flag > 1 && field.value !== '')
            setDiaSelecionado(retorno.substring(0, 3))
        else
            setDiaSelecionado('')

        flag = flag + 1

        if (props.changed)
            setLastValue(field.value)
    }, [dataSelecionada, field.value])


    useEffect(() => {
        if (field.value != null && field.value.toString().length > 10) {
            props.setarValor(moment(field.value).subtract(-1, "days").format('DD/MM/YYYY'))
        }

        if (field.value != null && field.value.toString().indexOf('_') < 0) {
            let data = moment(field.value, 'DD/MM/YYYY');
            if (data.isValid()) {
                if (props.flag) {
                    props.functionAposSetarValor && props.functionAposSetarValor(data.format('DD/MM/YYYY'))
                }
                else if (props.flag === false)
                    props.setFlag(true)

                setDataSelecionada(data.toDate())
            }
        }

        if (props.onChange && (!field.value || field.value.indexOf('_') < 0 && new moment(field.value, 'DD/MM/YYYY').isValid()))
            props.onChange(field.value);


    }, [field.value])

    const myBlur = () => {
        var changed = false

        if (lastValue != field.value)
            changed = true

        setLastValue(field.value)

        if (field && field.value) {

            let containMonth = !isNaN(parseInt(field.value.split('/')[1])) //verifica se após a primeira barra '/' contém um número válido, significando que há um mês 
            let month = field.value.split('/')[1]
            let year = field.value.split('/')[2]// Obter Ano 
            let day = field.value.split('/')[0]

            let isValidYear = true

            if (month && month.includes('_')) { //Verificação em caso de mês com apenas 1 dígito
                month = '0' + month.replace('_', '')
                if (month.includes('00')) //Verificação em caso de único dígito seja 0 para não formatar para 00 no mês 
                    month = month.replace('00', '01')
            }
            else if (parseInt(month) > 12)
                mostrarToast('erro', 'Data Inválida')

            if (year && year.includes('_')) { //Verificação em caso de ano incompleto
                isValidYear = false
            }

            function ajustaData() {
                let data = ''
                if (!containMonth) {// se não existir um mês formatado, para que fique com o mês atual
                    if (parseInt(day) === 31 && !moment({ days: field.value }).isValid())//SE NÃO EXISTIR DIA 31 NO MÊS SETAR 30 
                        data = moment({ days: 30 }).format(`DD/MM/YYYY`)
                    else
                        data = moment({ days: day }).format(`DD/MM/YYYY`)


                }
                else //senão setar mês escolhido pelo usuário
                    data = moment(field.value, ['DD/MM/YYYY', 'DD', 'DD/MM']).format('DD/MM/YYYY') //Se ano for válido passar ano escolhido senão atual 

                return data;
            }

            if (field.value) {
                var dataFormatada = ajustaData()
                props.setarValor(dataFormatada)
            }
        }
        if (props.onBlurData)
            props.onBlurData(dataFormatada, changed)
    }

    return (<>
        <ModalCalendar show={showCalendar} setShowModal={setShowCalendar}>
            <InfiniteCalendar
                Component={withDateSelection(withHighlightedDates(Calendar))}
                highlighted={highlightedDays}
                className={showCalendar ? "calendario-ativo" : "calendario-oculto"}
                locale={{
                    locale: require('date-fns/locale/pt'),
                    blank: 'Selecione uma data',
                    headerFormat: 'ddd, MMM Do',
                    // weekdays: ["Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb", "Dom"],
                    weekdays: ["Dom", "Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb"],
                    todayLabel: {
                        long: 'Hoje',
                        short: 'Hoje'
                    },
                    weekStartsOn: 0,
                }}
                selected={dataSelecionada}
                onSelect={(value) => {
                    var dateFormat = moment(value).format("DD/MM/YYYY");
                    var changed = false

                    if (lastValue != dateFormat)
                        changed = true

                    setLastValue(dateFormat)

                    props.setarValor(dateFormat, changed);
                    setShowCalendar(!showCalendar);
                    setDataSelecionada(value)
                }}
                height={height - 150}
                width={width < 501 ? width : 403}
            />
        </ModalCalendar>

        <InputGroupBtnData
            onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
            myBlur={myBlur}
            mascara='11/11/1111'
            placeholder={props.placeholder}
            diaSemana={diaSelecionado}
            action={(target) => { action(target) }}
            texto={''} icone={(showCalendar ? 'icon-Calendar-4' : "icon-Calendar-4")} disabled={props.disabled || false} {...props} {...field} /></>);
}

const InputMonthPicker = (props) => {
    const [width, height] = useWindowSize();
    const [field, meta] = useField(props);
    const [showCalendar, setShowCalendar] = useState(false);
    const [dataSelecionada, setDataSelecionada] = useState(new Date());

    const action = (input) => {
        setShowCalendar(!showCalendar)
    }

    useEffect(() => {
        if (field.value != null && field.value.toString().length > 10) {
            props.setarValor(moment(field.value).subtract(-1, "days").format('MM/YYYY'))
        }

        if (field.value != null && field.value.toString().indexOf('_') < 0) {
            let data = moment(field.value, 'MM/YYYY');
            if (data.isValid()) {
                setDataSelecionada(data.toDate())
            }
        }
    }, [field.value])

    return (<>
        <ModalCalendar show={showCalendar} setShowModal={setShowCalendar}>
            <InfiniteCalendar
                className={'input-calendario-mes ' + (showCalendar ? "calendario-ativo" : "calendario-oculto")}
                display='years'
                min={(props.min && new Date(props.min, 0, 1)) || new Date(new Date().getFullYear(), 0, 1)}
                max={(props.max && new Date(props.max, 11, 31)) || new Date(new Date().getFullYear(), 11, 31)}
                locale={{
                    locale: require('date-fns/locale/pt'),
                    blank: 'Selecione um Mês',
                    headerFormat: 'ddd, MMM Do',
                    todayLabel: {
                        long: 'Hoje',
                        short: 'Hoje'
                    },
                    weekStartsOn: 0,
                }}
                selected={dataSelecionada}
                onSelect={(value) => { props.setarValor(moment(value).format("MM/YYYY")); setShowCalendar(!showCalendar); setDataSelecionada(value); }}
                height={height - 150}
                width={width < 501 ? width : 403} />
        </ModalCalendar>

        <InputGroupBtn
            mascara='11/1111'
            action={(target) => { action(target) }}
            texto={''} icone={(showCalendar ? 'icon-Calendar-4' : "icon-Calendar-4")} disabled={false} {...props} {...field} /></>);
}

const InputGroupBtn = ({ label, action, texto, icone, ...props }) => {
    const [field, meta] = useField(props);
    const [mascara, setMascara] = useState(props.mascara)
    const [ultimaTecla, setUltimaTecla] = useState("")

    const target = useRef(null);
    const form2 = useFormikContext();

    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    field.value = field.value == undefined ? '' : field.value.toString()
    field.value = field.value.length < 1 ? '' : field.value;
    delete props.classeTooltip
    let placeholder = props.placeholder || label;
    delete props.placeholder
    let classTamanho = props.tamanho ? props.tamanho : "col-12";

    const validacao = (char, value) => {
        if (props.charValidacao(char)) {
            setMascara(props.mascaraReplace)
        } else {
            setMascara(props.mascara)
        }
        return props.charValidacao(char);
    }


    const trocarValorInputTelefone = () => {
        if (!props.mascaraTelefone)
            return
        let v = field.value.toString();
        var r = v.replace(/\D/g, "");
        r = r.replace(/^0/, "");
        if (r.length > 10) {
            // 11+ digits. Format as 5+4.
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        }
        else if (r.length > 5) {
            // 6..10 digits. Format as 4+4
            r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        }
        else if (r.length > 2) {
            // 3..5 digits. Add (0XX..)
            r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        }
        else {
            // 0..2 digits. Just add (0XX
            r = r.replace(/^(\d*)/, "($1");
        }
        if (ultimaTecla === "Backspace" && (r.slice(-1) === "-" || r.slice(-1) === "(" || r.slice(-1) === ")" || r.slice(-1) === " ")) {
            r = r.substring(0, r.length - 1)
            return;
        }
        form2.setFieldValue(field.name, field.value.length < 1 ? "" : r)

    }
    useEffect(() => {
        trocarValorInputTelefone()
    }, [field.value])



    return (
        <div className={"form-group " + classTamanho}>
            {props.type !== "hidden" ? (!label || label.length === 0 ? <></> : <label htmlFor={props.id || props.name}>{label} {props.obrigatorio != undefined ? "*" : ""}</label>) : <></>}
            {props.mascaraTimePicker && delete props.mascara}
            <div className="input-group" ref={target}>
                {props.mascaraTelefone ?
                    <input className={"form-control"}  {...field} {...props} placeholder={placeholder} onKeyDown={(e) => setUltimaTecla(e.nativeEvent.key)} />
                    : (props.mascara && props.mascara != false ?
                        <MaskedInput mask={mascara || props.mascara} formatCharacters={{ '?': { validate(char, value) { return validacao(char, value) } } }} className={"form-control" + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")} {...props} {...field} onBlur={() => props.alternativeBlur && props.alternativeBlur()} placeholder={placeholder} />
                        :
                        <input className={"form-control" + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")}  {...field} {...props} placeholder={placeholder} />
                    )
                }
                <div className="input-group-prepend">
                    <button type="button" className="input-group-btn btn-group-padrao" onClick={() => action(target)}><span>{texto}</span><span className={icone}></span></button>
                </div>
            </div>

            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={"error-tooltip " + classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    );
}

const InputGroupBtnData = ({ label, action, increment, decrement, texto, icone, ...props }) => {

    const [field, meta] = useField(props);
    const [mascara, setMascara] = useState(props.mascara)
    const [ultimaTecla, setUltimaTecla] = useState("")

    const target = useRef(null);
    const form2 = useFormikContext();

    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    field.value = field.value == undefined ? '' : field.value.toString()
    field.value = field.value.length < 1 ? '' : field.value;
    delete props.classeTooltip
    let placeholder = props.placeholder || label;
    delete props.placeholder
    let classTamanho = props.tamanho ? props.tamanho : "col-12";

    const validacao = (char, value) => {
        if (props.charValidacao(char)) {
            setMascara(props.mascaraReplace)
        } else {
            setMascara(props.mascara)
        }
        return props.charValidacao(char);
    }


    const incrementData = (input) => {

        let data = moment(field.value, 'DD/MM/YYYY').add(1, 'days');
        props.setarValor(moment(data).format("DD/MM/YYYY"));
    }

    const decrementData = (input) => {

        let data = moment(field.value, 'DD/MM/YYYY').subtract(1, 'days');
        props.setarValor(moment(data).format("DD/MM/YYYY"));
    }


    const trocarValorInputTelefone = () => {
        if (!props.mascaraTelefone)
            return
        let v = field.value.toString();
        var r = v.replace(/\D/g, "");
        r = r.replace(/^0/, "");
        if (r.length > 10) {
            // 11+ digits. Format as 5+4.
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        }
        else if (r.length > 5) {
            // 6..10 digits. Format as 4+4
            r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        }
        else if (r.length > 2) {
            // 3..5 digits. Add (0XX..)
            r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        }
        else {
            // 0..2 digits. Just add (0XX
            r = r.replace(/^(\d*)/, "($1");
        }
        if (ultimaTecla === "Backspace" && (r.slice(-1) === "-" || r.slice(-1) === "(" || r.slice(-1) === ")" || r.slice(-1) === " ")) {
            r = r.substring(0, r.length - 1)
            return;
        }
        form2.setFieldValue(field.name, field.value.length < 1 ? "" : r)

    }
    useEffect(() => {
        trocarValorInputTelefone()
    }, [field.value])


    return (
        <div className={"form-group " + classTamanho}>
            {props.type !== "hidden" ? (!label || label.length === 0 ? <></> : <label htmlFor={props.id || props.name}>{label} {props.obrigatorio != undefined && props.obrigatorio != false ? "*" : ""}</label>) : <></>}

            <div className="input-group" ref={target}>

                {props.navigation ? (<div className="input-group-prepend">
                    <button type="button" className="input-group-btn btn-group-navigator" style={{ borderRight: 0 }} onClick={() => decrementData(decrement)}><span>{texto}</span><span className={'icon-Arrow-Left icone-alinhado'}></span></button>
                </div>) : <></>}
                {props.mascaraTelefone ?
                    <input ref={props.targetFocus} className={"form-control retira-borda-direita"}  {...field} {...props} placeholder={placeholder} onKeyDown={(e) => setUltimaTecla(e.nativeEvent.key)} />
                    : (props.mascara && props.mascara != false ?
                        <MaskedInput ref={props.targetFocus} mask={mascara || props.mascara} formatCharacters={{ '?': { validate(char, value) { return validacao(char, value) } } }} className={"form-control" + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")} {...props} {...field} onBlur={() => props.myBlur()} placeholder={placeholder} />
                        :
                        <input className={"form-control " + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")}  {...field} {...props} placeholder={placeholder} />
                    )

                }
                {props.navigation ? (<div className="input-group-prepend">
                    <button disabled={props.disabled || false} type="button" className="input-group-btn btn-group-navigator" style={{ borderLeft: 0 }} onClick={() => incrementData(increment)}><span>{texto}</span><span className={'icon-Arrow-Right icone-alinhado'}></span></button>
                </div>) : (<></>)}

                <div className="input-group-prepend">
                    <button disabled={props.disabled || false} type="button" className="input-group-btn btn-group-padrao" onClick={() => action(target)}><span>{texto}</span><span className={icone}></span><p className='ajusta-texto-dia'>{props.diaSemana.toString()}</p></button>
                </div>
            </div>
            {(props?.mostrarValidacaoAfter && meta.error) || (meta && meta.touched && meta.error) ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={"error-tooltip " + classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    );
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

const InputGroupBtnDataMultipla = React.forwardRef((props, ref) => {
    const [field, meta] = useField(props);
    const target = useRef(null);
    const form2 = useFormikContext();

    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    field.value = field.value == undefined ? '' : field.value.toString()
    field.value = field.value.length < 1 ? '' : field.value;
    let placeholder = props.placeholder || props.label;
    delete props.placeholder
    let classTamanho = props.tamanho ? props.tamanho : "col-12";

    const [ultimaVezAdicionouMascara, setUltimaVezAdicionouMascara] = useState(moment())


    function setarMascara() {
        let v = getLastValue();
        let novoValor = v;
        if (v.length == 2) {
            v = v + '/';
            novoValor = v.length < 1 ? "" : v;
        }
        if (v.length == 5) {
            v = v + '/';
            novoValor = v.length < 1 ? "" : v;
        }
        if (v.length >= 10) {
            v = v.substring(0, 9);
            novoValor = v.length < 1 ? "" : v;
        }
        return novoValor;
    }

    ref.current = {
        atualizarDatas: (datas) => {
            let novoArray = [];
            for (let index = 0; index < datas.length; index++) {
                const element = datas[index];
                let label = moment(element, 'DD/MM/YYYY ').format('DD/MM/YYYY (ddd)');
                let newValue = { value: element, label: label };
                novoArray.push({ value: element, label: label })
            }
            setValue(novoArray)
            handleChange(novoArray)
            //props.propsFormik.setFieldValue(props.name, novoArray);
        },
    }

    const [value, setValue] = useState([])
    const [inputValue, setInputValue] = useState('')
    const handleChange = (value, actionMeta) => {
        value = value ? value : []
        let newValues = value.map(x => moment(x.value).format('DD/MM/YYYY')).sort((a, b) => moment(a, 'DD/MM/YYYY') - moment(b, 'DD/MM/YYYY')).filter(onlyUnique);


        if (newValues.join('') !== props.datasEmSelecao.join(''))
            props.setDatasEmSelecao(value == null ? [] : value.map(x => moment(x.value).format('DD/MM/YYYY')).sort((a, b) => moment(a, 'DD/MM/YYYY') - moment(b, 'DD/MM/YYYY')).filter(onlyUnique));

    };




    const handleInputChange = (inputValue) => {
        setInputValue(inputValue);
    };

    const getLastValue = () => {
        return inputValue;
    }
    const handleKeyDown = (event, a, b) => {
        if (!getLastValue()) return;
        let podeContinuar = false;

        if (isNaN(event.key) === false || event.key === '/' || event.key === 'Backspace' || event.key === 'Enter' || event.key === 'Tab') {
            podeContinuar = true;
        }

        if (!podeContinuar) {
            setInputValue('');
            return;
        }
        if (event.key !== 'Backspace')
            setInputValue(setarMascara());


        switch (event.key) {
            case 'Enter':
            case 'Tab':
                let valorFormatado = '';
                let label = '';
                if (moment(inputValue, 'DD/MM/YYYY').isValid() === false) {
                    mostrarToast('erro', "informe uma data válida.");
                    return;
                }
                let valorFormatadoddd = moment(inputValue, 'DD/MM/YYYY').format('DD/MM/YYYY (ddd)');
                valorFormatado = moment(inputValue, 'DD/MM/YYYY').format('DD/MM/YYYY');
                if (props.datasEmSelecao.includes(valorFormatado)) {
                    mostrarToast('erro', `A data ${valorFormatadoddd} já foi adicionada.`);
                    return;
                }
                setInputValue('');
                let novoValue = [...props.datasEmSelecao, valorFormatado];


                novoValue = novoValue.sort((a, b) => moment(a, 'DD/MM/YYYY') - moment(b, 'DD/MM/YYYY')).filter(onlyUnique)
                if (novoValue.join('') === props.datasEmSelecao.join(''))
                    return;

                props.setDatasEmSelecao(novoValue);
                //props.propsFormik.setFieldValue(props.name, novoValue);

                event.preventDefault();
        }
    };
    const components = {
        DropdownIndicator: null,
        width: 400
    };
    const style = {
        control: base => ({
            ...base,
            borderRadius: 0,
        })
    };
    const createOption = (label) => ({
        label,
        value: label,
    });
    return (
        <div className={"form-group " + classTamanho} >
            {props.type !== "hidden" ? (!props.label || props.label.length === 0 ? <></> : <label htmlFor={props.id || props.name}>{props.label} {props.obrigatorio != undefined ? "*" : ""}</label>) : <></>}

            <div className="input-group" ref={target}>

                <div style={{ flex: 10 }}>
                    <CreatableSelect
                        id={props.id || 'data-multi-' + Math.random()}
                        components={components}
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        ref={ref}
                        menuIsOpen={false}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder={props.label}
                        value={value}
                    />
                </div>

                <div className="input-group-prepend">
                    <button type="button" className="input-group-btn btn-group-padrao" onClick={() => props.action(props.target)}><span>{props.texto}</span><span className={props.icone}></span></button>
                </div>
            </div>

            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={"error-tooltip " + classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    );
})

const InputTelefone = (props) => {
    const [field, meta, helpers] = useField(props);
    const [showModal, setShowModal] = useState(false);
    const [value, setValue] = useState('');
    const form = useFormikContext();
    const [mascaraTelefone, setMascaraTelefone] = useState(true)

    const action = (input) => {
        setValue(input.current.value)
        setShowModal(true)
    }

    const mudarValorCampoTelefone = (novoValor) => {
        form.setFieldValue(field.name, novoValor)
    }

    useEffect(() => {
        if (props.data.length > 0) {
            setMascaraTelefone(false)
            setTimeout(() => {
                mudarValorCampoTelefone(props.data.length > 0 ? props.data.map((item) => item.telefone).join(', ') : "")
            }, 100);
        } else {
            setMascaraTelefone(true)
        }
    }, [props.data])

    return (<>
        <ModalTelefone titulo='Telefones' show={showModal} setShowModal={setShowModal} data={props.data} setData={props.setData} inicial={field} setValue={setValue} setFieldValue={() => mudarValorCampoTelefone('')} />
        <InputGroupBtn
            mascaraTelefone={mascaraTelefone}
            action={(target) => { action(target) }}
            texto={props.data.length > 0 ? '' : '+'} icone={props.data.length > 0 ? 'icon-Pencil' : 'icon-Smartphone-3'} disabled={props.data.length > 0} {...props} {...field} /></>);
}

const InputAutocompleteFormik = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const [mostrarErro, setMostrarErro] = useState(true);
    const target = useRef(null);
    const inputTarget = useRef(null);
    const [blured, setBlured] = useState(false)
    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    field.value = field.value || '';
    let placeholder = props.placeholder || label;
    const [msgDigitando, setMsgDigitando] = useState('');
    const [loading, setLoading] = useState(false);
    let minLength = props.minLength || 0;
    const loadOptionFunc = props.loadOptions || {};
    var inputFocado;
    delete props.loadOptions
    delete props.minLength
    delete props.classeTooltip
    field.onBlur = () => { setMostrarErro(true) }
    const [menuPlacement, setMenuPlacement] = useState('auto')

    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        if (minLength > 0) {
            if (inputValue.length >= minLength) {
                const retorno = loadOptionFunc(inputValue, callback)
                setLoading(false)
                setMsgDigitando(`Nenhum registro encontrado`)
                return retorno
            } else {
                setMsgDigitando(`Digite ${minLength} caractere(s) para buscar`)
                setLoading(true)
                return Promise.resolve([])
            }
        } else {
            const retorno = loadOptionFunc(inputValue, callback)
            setLoading(false)
            setMsgDigitando(`Nenhum registro encontrado`)
            return retorno
        }
    }
    useEffect(() => {
        if ((props.focarAposSubmit == true && Object.keys(props.propsFormik.errors).length == 0) || props.autoFocus) {
            if (!props?.focusSubmit) {
                inputTarget.current.focus();
            }
        }
    }, [props.propsFormik.isSubmitting])

    useEffect(() => {
        if (props.salvarENovo && props.autoFocus) {
            inputTarget.current.focus()

        }
    }, [props.salvarENovo])

    useEffect(() => {
        if (!props.adicionando && props.autoFocus) {

            inputTarget.current.focus()
        }
    }, [props.adicionando])

    let classTamanho = props.tamanho ? props.tamanho : "col-12";
    return (
        <div className={"form-group " + classTamanho}>
            {props.type !== "hidden" ? (!label || label.length === 0 ? <></> : <label htmlFor={props.id || props.name}>{label} {props.obrigatorio === true ? "*" : ""}</label>) : <></>}
            <div ref={target}>
                <AsyncSelect classNamePrefix="react-select" className={"react-select  " + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")}
                    cacheOptions
                    noOptionsMessage={props.noOptionsMessage ? props.noOptionsMessage : () => msgDigitando}
                    noResultsText=""
                    loadOptions={loadOptions}
                    defaultOptions
                    onFocus={() => { setBlured(true); setMostrarErro(false); props.onFocus && props.onFocus() }}
                    loadingMessage={() => 'Carregando'}
                    placeholder={placeholder}
                    styles={props.selectStyle ? {
                        ...props.selectStyle,
                        menuPortal: (base) => ({ ...base, zIndex: document.body.classList.contains("ReactModal__Body--open") ? 999999 : 1029 })
                    } : {
                        menuPortal: (base) => ({ ...base, zIndex: document.body.classList.contains("ReactModal__Body--open") ? 999999 : 1029 })
                    }}
                    menuPortalTarget={document.body}
                    isLoading={() => loading}
                    {...field} {...props} ref={inputTarget} />
            </div>
            {(props.array ? (meta.touched && meta.error) : (blured && meta.error)) || ((props.obrigatorio && props.propsFormik.submitCount > 0) && meta.error) ? (
                <Overlay target={target.current} show={mostrarErro} placement="bottom">
                    {props => {
                        delete props.show
                        return (
                            <Tooltip id={Math.random()} className={"error-tooltip " + classeTooltip} {...props}>
                                <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                            </Tooltip>)
                    }}
                </Overlay>
            ) : null}
        </div>
    );
}

const InputAutocomplete = (props) => {
    if (!props.propsFormik)
        return <InputAutocompleteArray {...props} />
    else
        return <InputAutocompleteFormik {...props} />
}

const InputAutocompleteArray = ({ label, ...props }) => {
    let newField = {}
    const [msgDigitando, setMsgDigitando] = useState('');
    const [loading, setLoading] = useState(false);
    let minLength = props.minLength || 0;
    const target = useRef(null);
    const loadOptionFunc = props.loadOptions || {};
    delete props.loadOptions
    delete props.minLength
    const [menuPlacement, setMenuPlacement] = useState('auto')

    let placeholder = props.placeholder || props.label;
    // delete props.classeTooltip

    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        if (minLength > 0) {
            if (inputValue.length >= minLength) {
                const retorno = loadOptionFunc(inputValue, callback)
                setLoading(false)
                setMsgDigitando(`Nenhum registro encontrado`)
                return retorno
            } else {
                setMsgDigitando(minLength > 1 ? `Digite ${minLength} caractere(s) para buscar` : `Digite para buscar`)
                setLoading(true)
                return Promise.resolve([])
            }
        } else {
            const retorno = loadOptionFunc(inputValue, callback)
            setLoading(false)
            setMsgDigitando(`Nenhum registro encontrado`)
            return retorno
        }
    }

    let classTamanho = props.tamanho ? props.tamanho : "col-12";

    return (
        <div className={"form-group " + classTamanho}>
            {props.type !== "hidden" && label ? <label htmlFor={props.id || props.name}>{label} {props.obrigatorio != undefined ? "*" : ""}</label> : <></>}
            <div ref={target}>
                <AsyncSelect classNamePrefix="react-select" className={"react-select  " + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")}
                    defaultOptions={props.defaultOptions || true}
                    noOptionsMessage={() => msgDigitando}
                    noResultsText=""
                    loadingMessage={() => 'Carregando'}
                    loadOptions={loadOptions}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: document.body.classList.contains("ReactModal__Body--open") ? 999999 : 1029 })
                    }}
                    menuPortalTarget={document.body}
                    placeholder={placeholder}
                    onChange={() => { }}
                    isLoading={() => loading}
                    {...newField}
                    {...props}
                    cacheOptions
                />
            </div>
        </div>
    );
}

const InputAutocompleteArrayBtn = ({ action, texto, ...props }) => {
    let newField = {}
    let botaoVisivel = props.botaoVisivel != undefined ? props.botaoVisivel : false

    let placeholder = props.placeholder || props.label;
    // delete props.classeTooltip

    let classTamanho = props.tamanho ? props.tamanho : "col-12";

    return (<>
        <div className={"form-group " + classTamanho}>
            {props.type !== "hidden" && props.label ? <label htmlFor={props.id || props.name}>{props.label} {props.obrigatorio != undefined ? "*" : ""}</label> : <></>}
            <div className="input-group">
                <AsyncSelect classNamePrefix="react-select" className={"form-control-autocomplete react-select  " + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")}
                    noOptionsMessage={() => "Nenhum registro encontrado"}
                    noResultsText=""
                    placeholder={placeholder}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: document.body.classList.contains("ReactModal__Body--open") ? 999999 : 1029 })
                    }}
                    menuPortalTarget={document.body}
                    {...newField}
                    {...props}
                    cacheOptions
                />
                <div className="input-group-prepend">
                    {botaoVisivel ? <button type="button" className="input-group-btn btn-group-padrao" onClick={() => action()}><span>{texto}</span><span className={"icon-Add"}></span></button> : ""}
                </div>
            </div>
        </div>
    </>
    );
}

const InputAutocompleteBtn = ({ label, action, texto, ...props }) => {
    const [field, meta] = useField(props);
    const [mostrarErro, setMostrarErro] = useState(true);
    const target = useRef(null)
    const [blured, setBlured] = useState(false)
    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    field.value = field.value || '';
    delete props.classeTooltip
    delete props.ref
    let botaoVisivel = props.botaoVisivel != undefined ? props.botaoVisivel : false
    let classTamanho = props.tamanho ? props.tamanho : "col-12"
    const inputTarget = useRef(null)
    const [msgDigitando, setMsgDigitando] = useState('');
    const [loading, setLoading] = useState(false);
    let minLength = props.minLength || 0;
    const loadOptionFunc = props.loadOptions || {};
    delete props.loadOptions
    delete props.minLength
    delete props.classeTooltip
    field.onBlur = () => { setMostrarErro(true) }

    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        if (minLength > 0) {
            if (inputValue.length >= minLength) {
                const retorno = loadOptionFunc(inputValue, callback)
                setLoading(false)
                setMsgDigitando(`Nenhum registro encontrado`)
                return retorno
            } else {
                setMsgDigitando(`Digite ${minLength} caractere(s) para buscar`)
                setLoading(true)
                return Promise.resolve([])
            }
        } else {
            const retorno = loadOptionFunc(inputValue, callback)
            setLoading(false)
            setMsgDigitando(`Nenhum registro encontrado`)
            return retorno
        }
    }

    const setReference = () => {
        if (props.setReference && typeof props.setReference === 'function')
            props.setReference(inputTarget)
    }

    useEffect(() => {
        if ((props.focarAposSubmit == true && Object.keys(props.propsFormik.errors).length == 0) || props.salvarENovo && props.autoFocus) {
            inputTarget.current.focus();
        }
    }, [props.propsFormik?.isSubmitting])

    useEffect(() => {
        if (props.setReference != undefined)
            setReference()
    }, [inputTarget])


    const focar = () => {
        if (inputTarget)
            inputTarget.current.focus()
    }



    return (
        <div className={"form-group " + classTamanho}>
            {props.type !== "hidden" && label !== undefined ? <label htmlFor={props.id || props.name}>{label} {props.obrigatorio != undefined ? "*" : ""}</label> : <></>}
            <div className="input-group" ref={target}>
                <AsyncSelect classNamePrefix="react-select" className={"form-control-autocomplete react-select  " + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")}
                    defaultOptions={props.defaultOptions || true}
                    noOptionsMessage={() => msgDigitando}
                    noResultsText=""
                    onFocus={() => { setBlured(true); setMostrarErro(false) }}
                    loadOptions={loadOptions}
                    loadingMessage={() => 'Carregando'}
                    isLoading={() => loading}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: document.body.classList.contains("ReactModal__Body--open") ? 999999 : 1029 })
                    }}
                    menuPortalTarget={document.body}
                    {...field} {...props}
                    ref={inputTarget}
                />
                <div className="input-group-prepend">
                    {botaoVisivel ? <button type="button" className="input-group-btn btn-group-padrao" onClick={() => action()}><span>{texto}</span><span className={"icon-Add"}></span></button> : ""}
                </div>
            </div>
            {(props.array ? (meta.touched && meta.error) : (blured && meta.error)) || ((props.obrigatorio && props.propsFormik.submitCount > 0) && meta.error) ? (
                <>
                    <Overlay target={target.current} show={mostrarErro} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={"error-tooltip " + classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    )
}

const InputSwitch = ({ key, classe, ...props }) => {
    const [field, meta] = useField(props);
    const target = useRef(null);
    return (
        <div className={props.classeDiv + ' ' + (props.tamanho ? props.tamanho : 'col-lg-12')}>
            <label htmlFor="material-switch" className={"text-center " + props.classeLabel}>
                {props.label}{props.obrigatorio != undefined ? "*" : ""}
                {props.quebrarLinha ? <br /> : <></>}
                <Switch
                    id={'tooltip-' + key}
                    onColor="#8d8c9e"
                    checked={props.propsFormik && props.propsFormik.values[props.name]}
                    onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
                    onHandleColor="#04022b"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    disabled={props.disabled || false}
                    className="react-switch mt-2"
                    {...props}
                />
            </label>

            {
                meta.touched && meta.error ? (
                    <>
                        <Overlay target={target.current} show={true} placement="bottom">
                            {props => {
                                delete props.show
                                return (
                                    <Tooltip id={Math.random()} className="error-tooltip" {...props}>
                                        <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                    </Tooltip>)
                            }}
                        </Overlay>
                    </>
                ) : null
            }
        </div>
    )
}

// const InputSwitch = ({ key, classe, ...props }) => {
//     const [field, meta] = useField(props);
//     const target = useRef(null);

//     useEffect(() => {
//         console.log('TARGET', target.current.focus)
//         if (target.current) {
//             target.current.focus();
//         }
//       }, [target.current]);

//     return (
//         <div className={props.classeDiv || '' + " " + (props.tamanho ? props.tamanho : 'col-lg-12')}>
//             {props.type !== "hidden" ? <label htmlFor={props.id || props.name}>{props.label}{props.obrigatorio != undefined ? "*" : ""}</label> : <></>}
//             {props.quebrarLinha ? <br /> : <></>}
//             <label className={"cl-switch label-switch " + (classe ? classe : 'cl-switch-large')}>
//                 <input ref={target}  id={'tooltip-' + key} type="checkbox"  className={"form-control" + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")}  {...field} {...props} />
//                 <span className="switcher"></span>
//             </label>
//             {
//                 meta.touched && meta.error ? (
//                     <>
//                         <Overlay target={target.current} show={true} placement="bottom">
//                             {props => {
//                                 delete props.show
//                                 return (
//                                     <Tooltip id={Math.random()} className="error-tooltip" {...props}>
//                                         <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
//                                     </Tooltip>)
//                             }}
//                         </Overlay>
//                     </>
//                 ) : null
//             }
//         </div>
//     )
// }

const InputSwitchSemFormik = ({ key, classe, ...props }) => {
    const target = useRef(null);

    return (
        <div className={props.classeDiv + " " + (props.tamanho ? props.tamanho : 'col-lg-12')}>
            {props.type !== "hidden" && props.label ? <label htmlFor={props.id || props.name}>{props.label}{props.obrigatorio != undefined ? "*" : ""}</label> : <></>}
            {props.quebrarLinha ? <br /> : <></>}
            <label className={"cl-switch label-switch " + (classe ? classe : 'cl-switch-large')}>
                <input ref={target} id={'tooltip-' + key} type="checkbox" className={"form-control" + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")} {...props} />
                <span className="switcher"></span>
            </label>
        </div>
    )
}

const InputSimples = ({ ...props }) => {
    return (
        <div className="form-group">
            {props.label ? <label htmlFor={props.id || props.name}>{props.label} {props.obrigatorio != undefined ? "*" : ""}</label> : <></>}
            <input className={"form-control" + (props.lower ? " text-lowercase" : "") + (props.upper ? "text-upercase" : "")} {...props} />
        </div>
    );
}


const InputSimplesPesquisaConsulta = ({ onEnterPress, ...props }) => {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            // Chama a função de pesquisa quando a tecla Enter é pressionada
            onEnterPress && onEnterPress();
        }
    };
    return (
        <div className="form-group">
            {props.label ? (
                <label htmlFor={props.id || props.name}>
                    {props.label} {props.obrigatorio !== undefined ? '*' : ''}
                </label>
            ) : (
                <></>
            )}
            <input
                className={
                    'form-control' +
                    (props.lower ? ' text-lowercase' : '') +
                    (props.upper ? ' text-uppercase' : '')
                }
                onKeyDown={handleKeyDown} // Adiciona o evento de teclado
                onBlur={(e) => {
                    onEnterPress && onEnterPress(); // Função de pesquisa
                }}
                {...props}
            />
        </div>
    );
}

const InputGroupPadrao = ({ Icone, label, ...props }) => {
    const [field, meta] = useField(props);
    const target = useRef(null);
    field.value = field.value || '';

    return (
        <div className="form-group">
            <label htmlFor={props.id || props.name}>{label} {props.obrigatorio != undefined ? "*" : ""}</label>

            <div className="input-group mb-3">
                <input ref={target} className="form-control"  {...field} {...props} />
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1"><Icone /></span>
                </div>
            </div>
            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => (
                            <Tooltip id={Math.random()} className="error-tooltip" {...props}>
                                <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                            </Tooltip>
                        )}
                    </Overlay>
                </>
            ) : null}
        </div>
    );
}

const InputGroupSenha = ({ Icone, label, ...props }) => {
    const [field, meta] = useField(props);
    const [verSenha, setVerSenha] = useState(false);
    const target = useRef(null);
    const name = props.name
    const classTamanho = props.tamanho ? props.tamanho : "col-12";

    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    delete props.classeTooltip

    field.value = field.value || '';

    return (
        <div className={`form-group ${classTamanho}`}>
            <label htmlFor={props.id || props.name}>{label} {props.obrigatorio != false && props.obrigatorio != undefined ? "*" : ""}</label>

            <div ref={target} className="input-group">
                <input className="form-control"  {...field} {...props} type={!verSenha ? "password" : "text"} />
                <div className="input-group-prepend" onClick={() => setVerSenha(!verSenha)}>
                    <span className="input-group-text" id="basic-addon1">{!verSenha ? <FaEye className="color-primary cursor-pointer" /> : <FaEyeSlash className="color-primary cursor-pointer" />}</span>
                </div>
            </div>
            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={`error-${name} error-tooltip ` + classeTooltip} {...props}>
                                    <div className={"error"}><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>
                            )
                        }}
                    </Overlay>
                </>
            ) : null}

        </div>
    );
}

const InputSearch = (props) => {
    return (<>
        <div className="d-flex float-md-left mr-1 mb-1 align-top">
            <input type="text" className="pesquisa-consulta" name="keyword" placeholder="Pesquisar..." {...props} /></div>
    </>)
}
const InputValorNovo = (props) => {
    const [field, meta] = useField(props);
    const target = useRef(null);

    return (
        <div className={`form-group ${props.classDiv || ''}`}>
            {props.label ? <label htmlFor={props.id || props.name}>{props.label || ''}{props.obrigatorio && "*"}</label> : <></>}
            <CurrencyInput
                id={props.id || props.name}
                name={props.name}
                placeholder={props.placeholder || props.label}
                className={'form-control'}
                decimalsLimit={2}
                prefix={props.prefix || ''}
                suffix={props.suffix || ''}
                value={props.value}
                onBlur={props.onBlur}
                onChange={props.onChange}
                onChangeEvent={(e, value) => { props.propsFormik.setFieldValue(props.name, value.replace('R$ ', '').replace(/\./g, '').replace(',', '.')); props.propsFormik.setFieldValue(props.name + '_formatado', value) }}
                decimalScale={2}
                fixedDecimalLength={1}
                decimalSeparator="," thousandSeparator="."
                intlConfig={{ locale: 'pt-BR', style: 'currency', currency: 'BRL' }}
            />
            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={`error-${props.name} error-tooltip ` + props.classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    )
}

const ControlledInput = ({ onUpdate, ...props }) => {

    const [value, setState] = useState(props.value);
    const [field, meta] = useField(props);
    const target = useRef(null);

    useEffect(_ => setState(props.value), [props.value])

    const handleChange = e => {
        setState(e);
        onUpdate(e);
    };
    var _fixSelection = (function () {
        var _SELECTABLE_TYPES = /text|password|search|tel|url/;
        return function fixSelection(dom, fn) {
            var validType = _SELECTABLE_TYPES.test(dom.type),
                selection = {
                    start: validType ? dom.selectionStart : 0,
                    end: validType ? dom.selectionEnd : 0
                };
            if (validType && fn instanceof Function) fn(dom);
            return selection;
        };
    }());
    function getCaretPosition(dom) {
        var selection, sel;
        if ('selectionStart' in dom) {
            return _fixSelection(dom).start;
        } else { // IE below version 9
            selection = document.selection;
            if (selection) {
                sel = selection.createRange();
                sel.moveStart('character', -dom.value.length);
                return sel.text.length;
            }
        }
        return -1;
    }
    return (
        <div className={`form-group ${props.classDiv || ''}`}>
            {props.label ? <label htmlFor={props.id || props.name}>{props.label || ''}{props.obrigatorio && "*"}</label> : <></>}
            <CurrencyInput
                id={Math.random()}
                name={props.name}
                value={value}
                onChange={handleChange}
                placeholder={props.placeholder || props.label}
                className={'form-control'}
                decimalsLimit={2}
                prefix={props.prefix || ''}
                suffix={props.suffix || ''}
                onBlur={props.onBlur}
                selectionType='range'
                onFocus={props.onFocus}
                onSelect={props.onFocus}
                //onChange={props.onChange}
                //onChangeEvent={(e, value) => { props.propsFormik.setFieldValue(props.name, value.replace('R$ ', '').replace(/\./g, '').replace(',', '.')) }}
                decimalScale={2}
                fixedDecimalLength={1}
                decimalSeparator="," thousandSeparator="."
            //intlConfig={{ locale: 'pt-BR', style: 'currency', currency: 'BRL' }}
            />
            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={`error-${props.name} error-tooltip ` + props.classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    )
}
const InputValor = props => {
    const VALID_FIRST = /^[1-9]{1}$/
    const VALID_NEXT = /^[0-9]{1}$/
    const DELETE_KEY_CODE = 8
    const BACKSPACE_KEY_CODE = 46
    const [valueDisplay, setValueDisplay] = useState('')
    const [value, setValue] = useState(0)
    const [field, meta] = useField(props);
    const target = useRef(null);
    const name = props.name

    useEffect(() => {
        if (!props.propsFormik.values[props.name]) {
            setValueDisplay('')
            setValue(0)
        }
        else {
            setValueDisplay(formatMoeda(props.propsFormik.values[props.name]))
            setValue(Math.round(props.propsFormik.values[props.name] * 100))
        }

    }, [props.name && props.propsFormik && props.propsFormik.values && props.propsFormik.values[props.name]])

    const keyDown = event => {
        const { key, keyCode } = event

        if ((!props.propsFormik.values[props.name] && !VALID_FIRST.test(key)) ||
            (props.propsFormik.values[props.name] && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE && keyCode !== BACKSPACE_KEY_CODE))
            return

        const valueString = value && value.toString() || ''
        let nextValue

        if (window.getSelection().toString()) {
            if ((keyCode === BACKSPACE_KEY_CODE || keyCode == DELETE_KEY_CODE)) {
                if (window.getSelection().toString() === valueDisplay) {
                    setValue(0)
                    setValueDisplay('')
                    props.propsFormik.setFieldValue(props.name, '')
                    return
                }

                const nextValueString = (valueDisplay.substring(0, event.target.selectionStart) + valueDisplay.substring(event.target.selectionEnd))
                    .split('R$').join('').split('.').join('').split(',').join('')
                nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
            }
            else {
                const nextValueString = (valueDisplay.substring(0, event.target.selectionStart) + key + valueDisplay.substring(event.target.selectionEnd))
                    .split('R$').join('').split('.').join('').split(',').join('')
                nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
            }
        }
        else if (keyCode !== DELETE_KEY_CODE && keyCode !== BACKSPACE_KEY_CODE) {
            const nextValueString = !value && key || `${valueString}${key}`
            nextValue = Number.parseInt(nextValueString, 10)
        } else {
            if (event.target.selectionStart < 4 && keyCode === DELETE_KEY_CODE || event.target.selectionStart < 3 && keyCode === BACKSPACE_KEY_CODE)
                return

            let nextValueString

            if (keyCode === DELETE_KEY_CODE)
                nextValueString = (valueDisplay.substring(0, (event.target.selectionStart - 1)) + valueDisplay.substring(event.target.selectionStart))
                    .split('R$').join('').split('.').join('').split(',').join('')
            else
                nextValueString = (valueDisplay.substring(0, event.target.selectionStart) + valueDisplay.substring((event.target.selectionStart + 1)))
                    .split('R$').join('').split('.').join('').split(',').join('')

            nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
        }

        if (nextValue > props.max)
            return

        props.propsFormik.setFieldValue(props.name, nextValue / 100)
        setValue(nextValue)
        setValueDisplay((nextValue / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }))
    }

    return (
        <div className={`form-group ${props.classDiv || ''}`}>
            <label htmlFor={props.id || props.name}>{props.label || ''}{props.obrigatorio && "*"}</label>
            <input ref={target} className={"form-control"} value={valueDisplay} onKeyDown={keyDown} placeholder={props.placeholder || props.label} {...props} />
            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={`error-${name} error-tooltip ` + props.classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    )
}

const InputDecimal = props => {
    const VALID_FIRST = /^[0-9]{1}$/
    const VALID_NEXT = /^[0-9]{1}$/
    const DELETE_KEY_CODE = 8
    const TAB_KEY_CODE = 9
    const BACKSPACE_KEY_CODE = 46
    const [value, setValue] = useState('')
    const [meta] = useField(props);
    const target = useRef(null);
    const name = props.name
    const [displayValue, setDisplayValue] = useState('')
    const casaDecimais = props.casasDecimais || 4
    const divisor = parseInt('1'.padEnd(casaDecimais + 1, '0'))

    useEffect(() => {

        try {
            if (isNaN(parseInt(props.propsFormik.values[props.name])))
                return

            if (!props.propsFormik.values[props.name] && parseInt(props.propsFormik.values[props.name]) != 0) {
                setValue('')
                setDisplayValue('')
            }
            else {
                setValue((props.propsFormik.values[props.name] * divisor))
                setDisplayValue(props.propsFormik.values[props.name].split('.').join(','))
            }
        }
        catch (err) {

        }


    }, [props && props.name && props.propsFormik && props.propsFormik.values && props.propsFormik.values[props.name]])

    const keyDown = event => {
        try {
            const { key, keyCode } = event
            const valueString = value && Math.round(value).toString() || ''
            let nextValue

            if (props.propsFormik.values[props.name] != 0 && ((!props.propsFormik.values[props.name] && !VALID_FIRST.test(key)) ||
                (props.propsFormik.values[props.name] && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE && keyCode !== BACKSPACE_KEY_CODE))) {
                return;
            }

            if (window.getSelection().toString()) {
                if ((keyCode === BACKSPACE_KEY_CODE || keyCode == DELETE_KEY_CODE)) {
                    if (window.getSelection().toString() === displayValue) {
                        setValue('')
                        setDisplayValue('')
                        props.propsFormik.values[props.name] = ''
                        return
                    }

                    const nextValueString = (displayValue.substring(0, event.target.selectionStart) + displayValue.substring(event.target.selectionEnd))
                        .split(',').join('')
                    nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
                }
                else {
                    const nextValueString = (displayValue.substring(0, event.target.selectionStart) + key + displayValue.substring(event.target.selectionEnd))
                        .split(',').join('')
                    nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
                }
            }
            else if (keyCode !== DELETE_KEY_CODE && keyCode !== BACKSPACE_KEY_CODE && keyCode !== TAB_KEY_CODE) {
                const nextValueString = !value && key || `${valueString}${key}`
                nextValue = Number.parseInt(nextValueString, 10)

            } else {
                let nextValueString

                if (keyCode === DELETE_KEY_CODE)
                    nextValueString = (displayValue.substring(0, (event.target.selectionStart - 1)) + displayValue.substring(event.target.selectionStart))
                        .split(',').join('')
                else
                    nextValueString = (displayValue.substring(0, event.target.selectionStart) + displayValue.substring((event.target.selectionStart + 1)))
                        .split(',').join('')

                nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
            }

            if (nextValue / divisor > props.max)
                return

            if (nextValue === 0) {
                props.propsFormik.values[props.name] = '0.00'
                setValue(nextValue)
                setDisplayValue('0,00')
                return
            }

            props.propsFormik.values[props.name] = nextValue && (nextValue / divisor).toFixed(casaDecimais) || ''
            setValue(nextValue)
            setDisplayValue(nextValue && (nextValue / divisor).toFixed(casaDecimais).toString().split('.').join(',') || '')
        }
        catch (err) {

        }
    }

    return (
        <div className={`form-group ${props.classDiv || ''}`}>
            {props.label ? <label htmlFor={props.id || props.name}>{props.label || ''}{props.obrigatorio && " *"}</label> : <></>}
            <input ref={target} className={"form-control"} type='numeric' value={displayValue} onKeyDown={keyDown} placeholder={props.placeholder || props.label} onBlur={(x) => props.onBlur ? props.onBlur(x) : undefined} />
            {(meta.touched && meta.error) || ((props.obrigatorio && props?.propsFormik?.submitCount > 0) && meta.error) ? (
                <Overlay target={target.current} show={true} placement="bottom">
                    {props => {
                        delete props.show
                        return (
                            <Tooltip id={Math.random()} className={`error-${name} error-tooltip ` + props.classeTooltip} {...props}>
                                <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                            </Tooltip>)
                    }}
                </Overlay>
            ) : null}
        </div>
    )
}

const InputNumero = ({ ...props }) => {
    const VALID_FIRST = /^[0-9]{1}$/
    const VALID_NEXT = /^[0-9]{1}$/
    const DELETE_KEY_CODE = 8
    const BACKSPACE_KEY_CODE = 46
    const [field, meta] = useField(props);
    const target = useRef(null);
    const name = props.name
    const [value, setValue] = useState('')

    useEffect(() => {
        if (!props.propsFormik.values[props.name]) {
            setValue('')
            props.salvarENovo && target.current.focus()
        }
        else
            setValue(props.propsFormik.values[props.name])

    }, [props.propsFormik && props.propsFormik.values && props.propsFormik.values[props.name]])

    const keyDown = event => {
        const { key, keyCode } = event
        const valueString = props.propsFormik.values[props.name] && props.propsFormik.values[props.name].toString() || ''
        let nextValue

        if ((!props.propsFormik.values[props.name] && !VALID_FIRST.test(key)) ||
            (props.propsFormik.values[props.name] && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE && keyCode !== BACKSPACE_KEY_CODE))
            return

        if (window.getSelection().toString()) {
            if ((keyCode === BACKSPACE_KEY_CODE || keyCode == DELETE_KEY_CODE)) {
                if (window.getSelection().toString() === value) {
                    setValue('')
                    props.propsFormik.values[props.name] = ''
                    return
                }

                const nextValueString = (value.toString().substring(0, event.target.selectionStart) + value.toString().substring(event.target.selectionEnd))
                nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
            }
            else {
                const nextValueString = (value.toString().substring(0, event.target.selectionStart) + key + value.toString().substring(event.target.selectionEnd))
                nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
            }
        }
        else if (keyCode !== DELETE_KEY_CODE && keyCode !== BACKSPACE_KEY_CODE) {
            const nextValueString = !props.propsFormik.values[props.name] && key || `${valueString}${key}`
            nextValue = Number.parseInt(nextValueString, 10)
        } else {
            let nextValueString

            if (keyCode === DELETE_KEY_CODE)
                nextValueString = (value.toString().substring(0, (event.target.selectionStart - 1)) + value.toString().substring(event.target.selectionStart))
            else
                nextValueString = (value.toString().substring(0, event.target.selectionStart) + value.toString().substring((event.target.selectionStart + 1)))

            nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
        }

        if (nextValue > props.max)
            return

        if (nextValue == 0 && keyCode === DELETE_KEY_CODE) {
            props.propsFormik.values[props.name] = null
            setValue('')
        }
        if (Number.isNaN(nextValue)) {
            props.propsFormik.values[props.name] = null
            setValue('')
        }
        else {
            props.propsFormik.values[props.name] = nextValue
            setValue(nextValue)
        }
    }

    return (
        <div className={`form-group ${props.classDiv || ''}`}>
            <label htmlFor={props.id || props.name}>{props.label || ''}{props.obrigatorio && "*"}</label>
            <input autoFocus={props.autoFocus || false} ref={target} className={"form-control"} type='numeric' value={value} onKeyDown={keyDown} placeholder={props.placeholder || props.label} {...props} />
            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props2 => {
                            delete props2.show
                            return (
                                <Tooltip id={Math.random()} className={`error-${name} error-tooltip ` + props.classeTooltip} {...props2}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    )
}

const InputNumeroComCombo = ({ label, autoFocus, ...props }) => {
    let newField = {}
    const [loading, setLoading] = useState(false);
    const VALID_FIRST = /^[0-9]{1}$/
    const VALID_NEXT = /^[0-9]{1}$/
    const DELETE_KEY_CODE = 8
    const BACKSPACE_KEY_CODE = 46
    const target = useRef(null);
    const [value, setValue] = useState('')
    const [field, meta] = useField(props)
    const classeTooltip = props.classeTooltip

    let classTamanho = props.tamanho ? props.tamanho : "col-12";

    const promiseOptions = inputValue => Promise.resolve(props.data).then(value => filtrar(value, inputValue))
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos(i.label.toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    useEffect(() => {
        if (!props.propsFormik.values[props.name]) {
            setValue('')
            props.salvarENovo && target.current.focus()
        }
        else {
            setValue(props.propsFormik.values[props.name])
        }

    }, [props.propsFormik.values[props.name]])

    const keyDown = event => {
        const { key, keyCode } = event
        const valueString = props.propsFormik.values[props.name] && props.propsFormik.values[props.name].toString() || ''
        let nextValue

        if ((!props.propsFormik.values[props.name] && !VALID_FIRST.test(key)) ||
            (props.propsFormik.values[props.name] && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE && keyCode !== BACKSPACE_KEY_CODE))
            return

        if (window.getSelection().toString()) {
            if ((keyCode === BACKSPACE_KEY_CODE || keyCode == DELETE_KEY_CODE)) {
                if (window.getSelection().toString() === value) {
                    setValue('')
                    props.propsFormik.values[props.name] = ''
                    return
                }

                const nextValueString = (value.toString().substring(0, event.target.selectionStart) + value.toString().substring(event.target.selectionEnd))
                nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
            }
            else {
                const nextValueString = (value.toString().substring(0, event.target.selectionStart) + key + value.toString().substring(event.target.selectionEnd))
                nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
            }
        }
        else if (keyCode !== DELETE_KEY_CODE && keyCode !== BACKSPACE_KEY_CODE) {
            const nextValueString = !props.propsFormik.values[props.name] && key || `${valueString}${key}`
            nextValue = Number.parseInt(nextValueString, 10)
        } else {
            let nextValueString

            if (keyCode === DELETE_KEY_CODE)
                nextValueString = (value.toString().substring(0, (event.target.selectionStart - 1)) + value.toString().substring(event.target.selectionStart))
            else
                nextValueString = (value.toString().substring(0, event.target.selectionStart) + value.toString().substring((event.target.selectionStart + 1)))

            nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
        }

        if (nextValue > props.max)
            return

        if (nextValue == 0 && keyCode === DELETE_KEY_CODE) {
            props.propsFormik.values[props.name] = null
            setValue('')
        }
        if (Number.isNaN(nextValue)) {
            props.propsFormik.values[props.name] = null
            setValue('')
        }
        else {
            props.propsFormik.values[props.name] = nextValue
            setValue(nextValue)
        }
    }


    return (
        <div className={"form-group " + classTamanho}>
            {props.type !== "hidden" && label ? <label htmlFor={props.id || props.name}>{label} {props.obrigatorio != undefined ? "*" : ""}</label> : <></>}
            <div style={{ display: 'flex' }}>
                <input style={{ flex: 2 }} autoFocus={autoFocus} ref={target} className={"form-control"} type='numeric' value={value} onKeyDown={keyDown} placeholder={props.placeholder || label} />
                {meta.touched && meta.error ? (
                    <>
                        <Overlay target={target.current} show={true} placement="bottom">
                            {props2 => {
                                delete props2.show
                                return (
                                    <Tooltip id={Math.random()} className={`error-${props.name} error-tooltip ` + props.classeTooltip} {...props2}>
                                        <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                    </Tooltip>)
                            }}
                        </Overlay>
                    </>
                ) : null}
                <div style={{ flex: 1 }} >
                    <AsyncSelect classNamePrefix="react-select" className={"react-select  " + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")}
                        name={props.nameCombo}
                        defaultOptions={props.defaultOptions || true}
                        noResultsText=""
                        loadingMessage={() => 'Carregando'}
                        loadOptions={promiseOptions}
                        isSearchable={false}
                        defaultValue={props.data[0]}
                        onChange={option => props.propsFormik.values[props.nameCombo] = option}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: document.body.classList.contains("ReactModal__Body--open") ? 999999 : 1029 })
                        }}
                        menuPortalTarget={document.body} placeholder={props.comboPlaceholder} isLoading={() => loading}  {...newField} {...props} cacheOptions
                    />
                </div>
            </div>
        </div>
    );
}

const InputPercentual = props => {
    const VALID_FIRST = /^[1-9]{1}$/
    const VALID_NEXT = /^[0-9]{1}$/
    const DELETE_KEY_CODE = 8
    const BACKSPACE_KEY_CODE = 46
    const [valueDisplay, setValueDisplay] = useState('')
    const [value, setValue] = useState(0)
    const [field, meta] = useField(props);
    const target = useRef(null);
    const name = props.name

    useEffect(() => {
        if (!props.propsFormik.values[props.name]) {
            setValueDisplay('')
            setValue(0)
        }
        else {
            setValueDisplay(formatTwoPlace(props.propsFormik.values[props.name]) + '%')
            setValue(Math.round(props.propsFormik.values[props.name] * 100))
        }

    }, [props.propsFormik.values[props.name]])

    const keyDown = event => {
        const { key, keyCode } = event

        if ((!props.propsFormik.values[props.name] && !VALID_FIRST.test(key)) ||
            (props.propsFormik.values[props.name] && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE && keyCode !== BACKSPACE_KEY_CODE))
            return

        const valueString = value && value.toString() || ''
        let nextValue

        if (window.getSelection().toString()) {
            if ((keyCode === BACKSPACE_KEY_CODE || keyCode == DELETE_KEY_CODE)) {
                if (window.getSelection().toString() === valueDisplay) {
                    setValue(0)
                    setValueDisplay('')
                    props.propsFormik.values[props.name] = ''
                    return
                }

                const nextValueString = (valueDisplay.toString().substring(0, event.target.selectionStart) + valueDisplay.toString().substring(event.target.selectionEnd))
                    .split('%').join('').split('.').join('').split(',').join('')
                nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
            }
            else {
                const nextValueString = (valueDisplay.toString().substring(0, event.target.selectionStart) + key + valueDisplay.toString().substring(event.target.selectionEnd))
                    .split('%').join('').split('.').join('').split(',').join('')
                nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
            }
        }
        else if (keyCode !== DELETE_KEY_CODE && keyCode !== BACKSPACE_KEY_CODE) {
            const nextValueString = !value && key || `${valueString}${key}`
            nextValue = Number.parseInt(nextValueString, 10)
        } else {
            let nextValueString

            if (keyCode === DELETE_KEY_CODE)
                nextValueString = (valueDisplay.toString().substring(0, (event.target.selectionStart - 2)) + valueDisplay.toString().substring(event.target.selectionStart))
                    .split('%').join('').split('.').join('').split(',').join('')
            else
                nextValueString = (valueDisplay.toString().substring(0, event.target.selectionStart) + valueDisplay.toString().substring((event.target.selectionStart + 1)))
                    .split('%').join('').split('.').join('').split(',').join('')

            nextValue = nextValueString && Number.parseInt(nextValueString, 10) || 0
        }

        if (nextValue / 100 > props.max)
            return

        props.propsFormik.values[props.name] = nextValue / 100
        setValue(nextValue)
        setValueDisplay((nextValue / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%')
    }

    return (
        <div className={`form-group ${props.classDiv || ''}`}>
            <label htmlFor={props.id || props.name}>{props.label || ''}{props.obrigatorio && "*"}</label>
            <input ref={target} className={"form-control"} value={valueDisplay} onKeyDown={keyDown} placeholder={props.placeholder || props.label} {...props} />

            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={`error-${name} error-tooltip ` + props.classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    )
}

const TextArea = ({ label, performance, ...props }) => {
    const form2 = useFormikContext();
    let [field, meta] = useField(props);
    const [valorCampo, setValorCampo] = useState(undefined)
    const [count, setCount] = useState(0)
    useEffect(() => {
        if (count <= 1 && form2.values[props.name] && performance) {
            setValorCampo(form2.values[props.name])
            setCount(count + 1)
        }
    }, [form2.values[props.name]])

    const target = useRef(null);
    const [blured, setBlured] = useState(false)
    const name = props.name
    const [mostrarErro, setMostrarErro] = useState(true);

    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    field.value = field.value || '';
    let placeholder = props.placeholder || label;
    delete props.classeTooltip
    field.onBlur = () => { setMostrarErro(true) }
    field = performance ? { onChange: (e) => setValorCampo(e.target.value), onBlur: (e) => { form2.setFieldValue(props.name, e.target.value); setMostrarErro(true) }, value: valorCampo } : field


    let classTamanho = props.tamanho ? props.tamanho : "col-12";
    return (
        <div className={"form-group " + classTamanho}>
            {props.type !== "hidden" && label ? <label htmlFor={props.id || props.name}>{label}{props.obrigatorio ? "*" : ""}</label> : <></>}
            <textarea onFocus={() => { setBlured(true); setMostrarErro(false) }} ref={target} rows={props.rows || 3} className={"form-control" + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")} placeholder={placeholder} {...field} {...props} />
            {(blured && meta.error) || (props.propsFormik && props.propsFormik.submitCount > 0 && meta.error) ? (

                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={`error-${name} error-tooltip ` + classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    );
}

const InputTimePicker = (props) => {
    const [field, meta] = useField(props);
    const now = moment()
    var hora = now.hour() + ':' + now.minutes().toString().padStart(2, '0');
    const [teste, setTeste] = useState(false)
    now.format("HH:mm:ss") // 13:00:00
    const [time, setTime] = useState(hora)
    const [showTime, setShowTime] = useState(false)
    const [dataSelecionada, setHoraSelecionada] = useState();
    const [lastValue, setLastValue] = useState(field.value)

    const action = () => {
        setShowTime(!showTime)
    }
    useEffect(() => {
        var timePicker = false
        if (document.getElementsByClassName("css-nakgy8-TimeKeeper")[0]) //verifica se o time picker está aberto
            timePicker = true
        else
            timePicker = false

        var verificaTooltip = []

        if (timePicker && props.modal) {
            verificaTooltip = Array.from(document.getElementsByClassName("error-tooltip "))
            verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "sem-tooltip")

        }
        if (timePicker && !props.modal) {
            verificaTooltip = Array.from(document.getElementsByClassName("fade error-tooltip  show tooltip bs-tooltip-bottom"))
            verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "sem-tooltip error-tooltip")
        }
        else {
            verificaTooltip = Array.from(document.getElementsByClassName("sem-tooltip"))
            verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "fade error-tooltip tooltip-input-modal show tooltip bs-tooltip-bottom")

        }

    }, [action])

    useEffect(() => {
        if (props.aoAlterarValor)
            props.aoAlterarValor(field.value)

        if (props.changed)
            setLastValue(field.value)

        if (field.value != null && field.value.toString().length > 4) {
            props.setarValor(field.value)
        }

        if (field.value != null && field.value.toString().indexOf('_') < 0) {
            let hora = moment(field.value, 'HH:mm');
            if (hora.isValid()) {
                setHoraSelecionada(hora)
                setTime(field.value)

            }
        }

    }, [field.value])

    const myBlur = _ => {
        var changed = false

        if (lastValue != field.value)
            changed = true

        setLastValue(field.value)

        let horaSemFormatacao = field.value && field.value.split('_')[0].replace(':', '')
        function ajustaHora() {
            let hora = moment({ hour: horaSemFormatacao }).format('HH:mm');
            return hora;
        }

        if (horaSemFormatacao && horaSemFormatacao.length <= 2) {

            setTeste(true)
            var horaFormatada = ajustaHora()
            setHoraSelecionada(horaFormatada)
            setTime(horaFormatada)
            props.setarValor(horaFormatada)
            if (props.propsFormik)
                props.propsFormik.values[props.name] = horaFormatada

        }

        if (props.myBlur) {
            props.myBlur(changed)
        }

    }

    return (
        <>
            {/* <div className={!props.modal && 'teste'}> */}
            <InputGroupBtn
                mascara={'11:11'}
                alternativeBlur={_ => myBlur()}
                action={(target) => { action(target) }}
                texto={""} icone={(showTime ? 'icon-Clock' : "icon-Clock")} disabled={false} {...props} {...field} />
            <div class="time-center">
                {showTime &&
                    <TimeKeeper
                        time={time}
                        hour24Mode
                        onChange={(newTime) => { props.setarValor(moment(newTime).format("HH:mm")); setHoraSelecionada(newTime) }}
                        onDoneClick={() => {
                            setShowTime(false);
                            if (!field.value || (field.value && moment(field.value, 'HH:mm').isValid() == false)) {
                                props.setarValor(moment().format("HH:mm"));
                                setHoraSelecionada(moment().format("HH:mm"))
                            }
                        }}
                        switchToMinuteOnHourSelect
                        closeOnMinuteSelect
                    />
                }
            </div>
        </>
    )
}

const InputDateTimePicker = (props) => {
    const [width, height] = useWindowSize();
    const [field, meta] = useField(props);
    const [showCalendar, setShowCalendar] = useState(false);
    const [dataSelecionada, setDataSelecionada] = useState(new Date());
    const [showTime, setShowTime] = useState(false)
    const [horaSelecionada, setHoraSelecionada] = useState();
    const now = moment()
    const [highlightedDays, setHighlightedDays] = useState([])

    useEffect(() => {
        if (props.mostrarFeriado) {
            get("/administracao/obterFeriados", {}, true)
                .then((dados) => {
                    setHighlightedDays(dados.map(item => item.feriado_eua))
                })
        }
    }, [props.mostrarFeriado])


    const action = (input) => {
        setShowCalendar(!showCalendar)
    }
    var hora = now.hour() + ':' + now.minutes().toString().padStart(2, '0');


    const [time, setTime] = useState(hora)


    useEffect(() => {
        var timePicker = false
        if (document.getElementsByClassName("ReactModal__Content--after-open")[0]) //verifica se o time picker está aberto
            timePicker = true
        else
            timePicker = false


        var verificaTooltip = []

        if (timePicker && props.modal) {
            verificaTooltip = Array.from(document.getElementsByClassName("error-tooltip "))
            verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "sem-tooltip")

        }
        if (timePicker && !props.modal) {
            verificaTooltip = Array.from(document.getElementsByClassName("fade error-tooltip  show tooltip bs-tooltip-bottom"))
            verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "sem-tooltip error-tooltip")
        }
        else {
            verificaTooltip = Array.from(document.getElementsByClassName("sem-tooltip"))
            verificaTooltip && verificaTooltip.length && verificaTooltip.map(x => x.className = "fade error-tooltip tooltip-input-modal show tooltip bs-tooltip-bottom")

        }

    }, [action])

    useEffect(() => {
        if (field.value != null && field.value.toString().length > 10)
            props.setarValor(field.value)

        if (field.value != null && field.value.toString().indexOf('_') < 0) {
            let data = moment(field.value, 'DD/MM/YYYY HH:mm');
            if (data.isValid()) {
                setDataSelecionada(data.toDate())
            }
        }
    }, [field.value])
    return (<>
        <ModalCalendar show={showCalendar} setShowModal={setShowCalendar}>
            <InfiniteCalendar
                Component={withDateSelection(withHighlightedDates(Calendar))}
                highlighted={highlightedDays}
                className={showCalendar ? "calendario-ativo" : "calendario-oculto"}
                locale={{
                    locale: require('date-fns/locale/pt'),
                    blank: 'Selecione uma data',
                    headerFormat: 'ddd, MMM Do',
                    // weekdays: ["Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb", "Dom"],
                    weekdays: ["Dom", "Seg", "Terc", "Quart", "Quin", "Sexta", "Sáb"],
                    todayLabel: {
                        long: 'Hoje',
                        short: 'Hoje'
                    },
                    weekStartsOn: 0,
                }}
                selected={dataSelecionada}
                onSelect={(value) => { props.setarValor(moment(value).format("DD/MM/YYYY HH:mm")); setDataSelecionada(value); }}
                height={height < 680 ? height - 390 : height - 550}
                width={width < 401 ? width : 403}
            />
            <div className={'ajusta-time-picker-modal'}>
                <TimeKeeper
                    time={time}
                    hour24Mode
                    onChange={(newTime) => { props.setarValor(moment(newTime).format("DD/MM/YYYY HH:mm")); setHoraSelecionada(newTime) }}
                    onDoneClick={() => { setShowCalendar(!showCalendar); setShowTime(false); }}
                    switchToMinuteOnHourSelect
                    closeOnMinuteSelect
                />
            </div>
        </ModalCalendar>

        <InputGroupBtn
            mascara="11/11/1111 11:11"
            action={(target) => { action(target) }}
            texto={''} icone={(showCalendar ? 'icon-Calendar-4' : "icon-Calendar-4")} disabled={false} {...props} {...field} /></>);
}

const InputAutocompleteGenerico = props => {
    const [data, setData] = useState([])
    const user = getUsuario()
    const [renderizar, setRenderizar] = useState(false)

    useEffect(() => {
        get(props.caminhoDados, user, true)
            .then((dados) => {
                setData(dados)
                setRenderizar(true)
            })
    }, [])

    const filtrar = (inputValue) => {
        if (data) {
            return data.filter(i => {
                return removeAcentos(i.label.toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()))
            }
            ).slice(0, 10);
        }
    }

    const loadOptions = (inputValue, callback) => callback(filtrar(inputValue))

    if (renderizar)
        return (
            <InputAutocompleteArray
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                loadOptions={loadOptions}
                propsFormik={props.propsFormik}
                {...props} />)
    else
        return (<></>)
}

const InputSummernote = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const target = useRef(null);
    const [blured, setBlured] = useState(false)
    const name = props.name
    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    field.value = field.value || '';
    field.model = field.value || '';

    const [state, setState] = React.useState({ value: null });

    const handleChange = value => props.propsFormik.setFieldValue(props.name, value)


    let classTamanho = props.tamanho ? props.tamanho : "col-12";

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    return (
        <div className={"form-group " + classTamanho}>
            {props.type !== "hidden" ? <label htmlFor={props.id || props.name}>{label}{props.obrigatorio != undefined ? "*" : ""}</label> : <></>}
            <div className="input-group" ref={target}>
                <ReactQuill
                    className="col-12 ajusta-summernote"
                    theme="snow"
                    value={field.value}
                    onChange={handleChange}
                    placeholder={props.placeholder}
                    modules={modules}

                    formats={formats}
                />
                {/* <FroalaEditorComponent tag='textarea' {...field} config={config} onModelChange={value => props.propsFormik.setFieldValue(props.name, value)}/> */}
            </div>
            {(blured && meta.error) || (props.propsFormik.submitCount > 0 && meta.error) ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={`error-${name} error-tooltip ` + classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    );
}

const InputCheckboxSm = ({ key, checked, ...props }) => {
    const target = useRef(null);

    return (
        <div className={props.classeDiv + " checkbox-container " + (props.tamanho ? props.tamanho : 'col-lg-112')}>
            <label>
                <input ref={target} id={'tooltip-' + Math.random()} checked={checked} type="checkbox" className={"form-control hidden-checkbox"} {...props} />
                <div className="styled-checkbox" data-checked={checked}>
                    <svg className="icon-checkbox" viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </svg>
                </div>
            </label>
        </div>
    )
}

const InputSwitchSemFormikkk = ({ key, classe, ...props }) => {
    const target = useRef(null);

    return (
        <div className={props.classeDiv + " " + (props.tamanho ? props.tamanho : 'col-lg-12')}>
            {props.type !== "hidden" && props.label ? <label htmlFor={props.id || props.name}>{props.label}{props.obrigatorio != undefined ? "*" : ""}</label> : <></>}
            {props.quebrarLinha ? <br /> : <></>}
            <label className={"cl-switch label-switch " + (classe ? classe : 'cl-switch-large')}>
                <input ref={target} id={'tooltip-' + key} type="checkbox" className={"form-control" + (props.lower ? " text-lowercase" : "") + (props.upper ? " text-upercase" : "")} {...props} />
                <span className="switcher"></span>
            </label>
        </div>
    )
}
const InputCurrency = ({ onUpdate, ...props }) => {

    const [field, meta] = useField(props);
    const [value, setState] = useState(props.value);

    const target = useRef(null);
    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: null,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    useEffect(_ => setState(props.value), [props.value])

    const handleChange = (event, value, maskedValue) => {
        event.preventDefault();

        setState(value);
        onUpdate(maskedValue);

    };

    return (
        <div className={`form-group ${props.classDiv || ''}`} ref={target}>
            {props.label ? <label style={{ fontWeight: props.negrito && 'bold' }} htmlFor={props.id || props.name}>{props.label || ''}{props.obrigatorio && "*"}</label> : <></>}
            <IntlCurrencyInput
                value={value}
                id={Math.random()}
                name={props.name}
                onBlur={props.onBlur}
                currency="BRL"
                config={currencyConfig} className={'form-control ' + props.className}
                isDisabled={props.disabled}
                disabled={props.disabled}
                onChange={handleChange}
                onFocus={(x) => props.propsFormik.setFieldTouched(props.name, true)} />
            {props?.mostrarValidacaoAfter && meta.error || (meta.error && (parseFloat(value) === 0 || !convertValueToFloat(value) && meta.touched)) ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={`error-${props.name} error-tooltip z-index-max ` + props.classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    );
}
const InputCpfCnpj = ({ label, action, texto, icone, ...props }) => {

    const [field, meta] = useField(props);
    const [ultimaTecla, setUltimaTecla] = useState("")

    const target = useRef(null);
    const form2 = useFormikContext();

    let classeTooltip = props.classeTooltip || ''
    classeTooltip = classeTooltip ? classeTooltip.toString() : ""
    field.value = field.value == undefined ? '' : field.value.toString()
    field.value = field.value.length < 1 ? '' : field.value;
    delete props.classeTooltip
    let placeholder = props.placeholder || label;
    delete props.placeholder
    let classTamanho = props.tamanho ? props.tamanho : "col-12";

    const trocarValorInputTelefone = () => {

        let v = field.value.toString();
        var r = v.replace(/\D/g, "");
        if (r.length > 11)
            r = r.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2}).*/, "$1.$2.$3/$4-$5");

        else if (r.length <= 11)
            r = r.replace(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, function (_, g1, g2, g3, g4) {
                var mascara = '';
                if (g1) mascara += g1 + '.';
                if (g2) mascara += g2 + '.';
                if (g3) mascara += g3 + '-';
                if (g4) mascara += g4;
                return mascara;
            })

        if (ultimaTecla === "Backspace" && (r.slice(-1) === "-" || r.slice(-1) === "." || r.slice(-1) === "/")) {
            r = r.substring(0, r.length - 1)
            return;
        }
        form2.setFieldValue(field.name, field.value.length < 1 ? "" : r)

    }
    useEffect(() => {
        trocarValorInputTelefone()
    }, [field.value])



    return (
        <div className={"form-group " + classTamanho}>
            {props.type !== "hidden" ? (!label || label.length === 0 ? <></> : <label htmlFor={props.id || props.name}>{label} {props.obrigatorio != undefined ? "*" : ""}</label>) : <></>}
            {props.mascaraTimePicker && delete props.mascara}
            <div className="input-group" ref={target}>
                <input className={"form-control"}  {...field} {...props} placeholder={placeholder} onKeyDown={(e) => setUltimaTecla(e.nativeEvent.key)} />
            </div>

            {meta.touched && meta.error ? (
                <>
                    <Overlay target={target.current} show={true} placement="bottom">
                        {props => {
                            delete props.show
                            return (
                                <Tooltip id={Math.random()} className={"error-tooltip " + classeTooltip} {...props}>
                                    <div className="error"><FiAlertTriangle className="icone" /> {meta.error}</div>
                                </Tooltip>)
                        }}
                    </Overlay>
                </>
            ) : null}
        </div>
    );
}

export {
    InputGroupSenha,
    InputGroupPadrao,
    InputPadrao,
    InputSimples,
    InputSimplesPesquisaConsulta,
    InputSwitch,
    InputSearch,
    InputAutocomplete,
    InputTelefone,
    InputDatePicker,
    InputTimePicker,
    InputAutocompleteBtn,
    InputValor,
    InputPercentual,
    InputDecimal,
    TextArea,
    InputNumero,
    InputAutocompleteArray,
    InputSwitchSemFormik,
    InputMonthPicker,
    InputAutocompleteGenerico,
    InputSummernote,
    InputDateTimePicker,
    InputAutocompleteArrayBtn,
    InputCheckboxSm,
    InputNumeroComCombo,
    InputDatePickerMultiplo,
    InputValorNovo,
    InputMascaraSimples,
    InputPadraoSimples,
    ControlledInput,
    InputCurrency,
    InputCpfCnpj
}
