import { InputPadrao } from '../../../../components/Inputs'
import { InputValorNovo } from '../../../../components/InputsV2'
import { removeAcentos, formatMoeda, formatTwoPlace } from '../../../../util/FuncoesComuns'
import { HeaderCadastro } from '../../../../components/Headers'
import { ModalCadastro } from '../../../../components/Modais'
import { BotaoPadrao } from '../../../../components/Botoes'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import React, { useState, useEffect } from 'react'
import Yup from '../../../../util/Validacoes'
import { Formik, Form } from 'formik'
import { BtnAcao } from '../../../../components/Acoes'
import Atalho from '../../../../util/Atalho'

export default props => {
    const [pesquisa, setPesquisa] = useState('')
    const [tabIndex, setTabIndex] = useState(0)
    const [btn, setBtn] = useState('')
    const [salvando, setSalvando] = useState(false)

    const [values, setValues] = useState({
        id: null,
        valor_porte: null,
        porte: null,
        urgencia: null,
        video: null,
    })

    const findIndexDados = obj => {
        const index = []
        index.push(props.arrayDados.findIndex(x => x.tabela_procedimento.id === obj.id_tabela_procedimento))
        index.push(props.arrayDados[index[0]].array_acomodacao.findIndex(x => x.acomodacao.id === parseInt(obj.id_acomodacao)))
        index.push(props.arrayDados[index[0]].array_acomodacao[index[1]].array_porte.findIndex(x => parseInt(x.porte) === parseInt(obj.porte)))

        return index
    }

    const changeArrayDados = obj => {
        let copiaArrayDados = [...props.arrayDados]
        const index = findIndexDados(obj)

        copiaArrayDados[index[0]].array_acomodacao[index[1]].array_porte[index[2]] = obj

        props.setArrayDados(copiaArrayDados)
    }

    const Submit = (obj, { resetForm }) => {
        changeArrayDados(obj)
        resetForm({})
        props.setAbrirModal(!props.abrirModal)
    }

    const changePorte = (btn, obj) => {
        const copiaArrayDados = [...props.arrayDados]
        const objPorte = { ...obj }

        if (btn === 'next')
            objPorte.porte = parseInt(obj.porte) === 8 ? 0 : (parseInt(obj.porte) + 1)
        else
            objPorte.porte = parseInt(obj.porte) === 0 ? 8 : (parseInt(obj.porte) - 1)

        const index = findIndexDados(objPorte)
        const newObj = copiaArrayDados[index[0]].array_acomodacao[index[1]].array_porte[index[2]]

        setValues(newObj)
        changeArrayDados(obj)
    }

    const changeAcomodacao = (btn, obj) => {
        const copiaArrayDados = [...props.arrayDados]
        const objAcomodacao = { ...obj }

        const index = findIndexDados(obj)

        if (btn === 'next')
            objAcomodacao.id_acomodacao = parseInt(index[1] + 1) === copiaArrayDados[0].array_acomodacao.length ?
                copiaArrayDados[0].array_acomodacao[0].acomodacao.id :
                copiaArrayDados[0].array_acomodacao[index[1] + 1].acomodacao.id
        else
            objAcomodacao.id_acomodacao = parseInt(index[1]) === 0 ?
                copiaArrayDados[0].array_acomodacao[copiaArrayDados[0].array_acomodacao.length - 1].acomodacao.id :
                copiaArrayDados[0].array_acomodacao[index[1] - 1].acomodacao.id

        let newIndex = findIndexDados(objAcomodacao)
        let newObj = copiaArrayDados[newIndex[0]].array_acomodacao[newIndex[1]].array_porte[newIndex[2]]

        setValues(newObj)
        changeArrayDados(obj)

        objAcomodacao.porte = 8
        newIndex = findIndexDados(objAcomodacao)
        newObj = copiaArrayDados[newIndex[0]].array_acomodacao[newIndex[1]].array_porte[newIndex[2]]
        changePorte('next', newObj)
    }

    useEffect(() => {
        props.attValues()
    }, [props.acomodacao, props.tabela])

    return (
        <>
            <HeaderCadastro
                pesquisa={true}
                valorPesquisa={pesquisa}
                onChangePesquisa={pesquisa => setPesquisa(pesquisa)}
                classeChildren='space-between-100' >
            </HeaderCadastro>
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>
                    {
                        props.arrayDados.length && props.arrayDados.map(tabela =>
                            <Tab className={"react-tabs__tab tab-porte-acomodacao"}> {tabela.tabela_procedimento && tabela.tabela_procedimento.label} </Tab>
                        )
                    }
                </TabList>
                {
                    props.arrayDados.length && props.arrayDados.map(tabela =>
                        <TabPanel>
                            <div className="row">
                                {
                                    tabela.array_acomodacao && tabela.array_acomodacao.length ?
                                        tabela.array_acomodacao.filter(x =>
                                            removeAcentos(x.acomodacao.label.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase())))
                                            .map(x =>
                                                <div className={`${tabela.array_acomodacao.length > 1 && 'col-lg-6' || 'col-lg-12'} margin-top-1`}>
                                                    <div className='card mb-20'>
                                                        <div className='card-body'>
                                                            <div className={'titulo-acomodacao'}><span>{x.acomodacao.label && x.acomodacao.label}</span></div>
                                                            <table className='table tabela-porte-acomodacao table-responsive-md table-striped tabela' >
                                                                <thead>
                                                                    {
                                                                        <tr>
                                                                            <th style={{ width: '20%' }}>Porte</th>
                                                                            <th style={{ width: '20%', textAlign: 'right' }}>Valor Porte</th>
                                                                            <th style={{ width: '20%', textAlign: 'right' }}>Urgência</th>
                                                                            <th style={{ width: '20%', textAlign: 'right' }}>Vídeo</th>
                                                                            <th style={{ width: '20%' }} className='text-center'>Ações</th>
                                                                        </tr>
                                                                    }
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        x.array_porte.sort((a, b) => (a.porte > b.porte) ? 1 : ((b.porte > a.porte) ? -1 : 0))
                                                                            .map(z =>
                                                                                <tr>
                                                                                    <td>{z.porte}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{z.valor_porte && formatMoeda(z.valor_porte) || ''}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{`${z.urgencia && formatTwoPlace(z.urgencia) + '%' || ''}`}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{`${z.video && formatTwoPlace(z.video) + '%' || ''}`}</td>
                                                                                    <td className='text-center'>
                                                                                        <span className='icon-consulta icon-Pen'
                                                                                            onClick={_ => { setValues(z); props.setAbrirModal(true) }} />
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        : <tr><td colspan='7' className='text-center'>Nenhum Registro Cadastrado</td></tr>
                                }
                            </div>
                        </TabPanel>
                    ) || <></>
                }
            </Tabs>
            <ModalCadastro show={props.abrirModal} setShowModal={props.setAbrirModal}
                titulo={props.arrayDados.find(x => x.tabela_procedimento.id === parseInt(values.id_tabela_procedimento)) &&
                    props.arrayDados.find(x => x.tabela_procedimento.id === parseInt(values.id_tabela_procedimento)).tabela_procedimento.label}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ ...values }}
                    validationSchema={Yup.object({
                        porte: Yup.string().ensure().required().nullable(),
                        valor_porte: Yup.string().ensure().required().nullable(),
                        urgencia: Yup.string().ensure().nullable(),
                        video: Yup.string().ensure().nullable(),
                    })}
                    onSubmit={Submit}>
                    {propsFormik => <Form>
                        <div className='modal-body-cadastro'>
                            <InputPadrao label='Id' name='id' type='hidden' />
                            <InputPadrao label='Porte' name='porte' type='hidden' />
                            <InputValorNovo value={propsFormik.values.valor_porte_formatado || propsFormik.values.valor_porte} label='Valor Porte' name={`valor_porte`} classDiv='col-lg-12' propsFormik={propsFormik} isDisable={false} max={999} prefix={'R$ '} autoFocus focarAposSubmit={true} />    
                            <InputValorNovo value={propsFormik.values.urgencia_formatado || propsFormik.values.urgencia} label='Urgência' name={`urgencia`} classDiv='col-lg-12' propsFormik={propsFormik} isDisable={false} max={999} suffix={'%'}/>    
                            <InputValorNovo value={propsFormik.values.video_formatado || propsFormik.values.video} label='Vídeo' name={`video`} classDiv='col-lg-12' propsFormik={propsFormik} isDisable={false} max={999} suffix={'%'}/>    
                        </div>
                        <div className='separator mt-20 mb-20'></div>
                        <div className='subheader-porte-acomodacao row-space-between'>
                            <BtnAcao type={'button'} icone="icon-Arrow-Left focus-button" texto="Anterior" action={_ => changePorte('prev', propsFormik.values)} />
                            <span className='vertical-center'>Porte - {values.porte}</span>
                            <BtnAcao type={'button'} icone="icon-Arrow-Right focus-button" texto="Próximo" action={_ => changePorte('next', propsFormik.values)} />
                        </div>
                        <div className='subheader-porte-acomodacao row-space-between'>
                            <BtnAcao type={'button'} icone="icon-Arrow-Left focus-button" texto="Anterior" action={_ => changeAcomodacao('prev', propsFormik.values)} />
                            <span className='vertical-center'>
                                {props.arrayDados[0].array_acomodacao.find(x => x.acomodacao.id === parseInt(values.id_acomodacao)).acomodacao.label}
                            </span>
                            <BtnAcao type={'button'} icone="icon-Arrow-Right focus-button" texto="Próximo" action={_ => changeAcomodacao('next', propsFormik.values)} />
                            <Atalho formik={propsFormik} salvando={salvando} />

                        </div>
                        <div className='direita container-botoes'>
                            <BotaoPadrao texto={'Salvar'} />
                        </div>
                    </Form>}
                </Formik>
            </ModalCadastro>
        </>
    )
}