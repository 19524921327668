import React, { useState } from 'react';
import '../assets/css/accordion.css'
const Accordion = ({ title, content, color }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion">
            <div className="accordion-header " onClick={handleToggle}>
                <div className='text-left flex'>
                    <div className="color-dot" style={{ backgroundColor: color }}>
                    </div>
                    <span className='bold'>{title}</span>
                </div>
                <span className={`accordion-icon ${isOpen ? 'open' : ''}`}></span>
            </div>
            {isOpen && (
                <div className="accordion-content">
                    {content}
                </div>
            )}
        </div>
    )
};

const SimpleAccordion = ({ title, content, color, cardClass, headClass, contentClass }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`accordion ` + cardClass}>
            <div className={`accordion-header ` + headClass} onClick={handleToggle}>
                <div className='text-left flex'>
                    <span className='bold'>{title}</span>
                </div>
                <span className={`accordion-icon ${isOpen ? 'open' : ''}`}></span>
            </div>
            {isOpen && (
                <div className={`accordion-content ` + contentClass}>
                    {content}
                </div>
            )}
        </div>
    )
};

export default Accordion;
export { SimpleAccordion };