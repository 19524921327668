import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { visualizar } from '../../../../util/AcessoApi'

const VisualizacaoNotificacao = (props) => {

    let { id } = useParams();
    const [dados, setDados] = useState({})

    useEffect(() => {
        if (id) {
            visualizar('/administracao/notificacao', id,
                (obj) =>{
                    setDados(obj.id  ? {
                        id,
                        modulo: props.modulo,
                        titulo: 'Visualização de Notificação',
                        grupos: [
                            {
                                titulo: 'Dados Gerais',
                                colunas: [
                                    { titulo: 'Código', descricao: obj.id, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Data Registro', descricao: obj.data_registro_formatada, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Título', descricao: obj.titulo, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Descrição', descricao: obj.descricao, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6', html: true},
                                    { titulo: 'Enviada ?', descricao: obj.enviado, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Data Envio', descricao: obj.data_envio_formatada || '---', tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Usuário ', descricao: obj.nome_usuario, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                ]
                            },
                            {
                                tipoGrupo: 'tabela',
                                titulo: 'Sócio(s)',
                                thead:
                                    <tr>
                                        <td className='coluna-4'>Nome</td>
                                        <td className='coluna-4'>Visualizado(app) ?</td>
                                        <td className='coluna-4'>Data Visualização</td>

                                    </tr>,
                                tbody:
                                    obj.socio_id && obj.socio_id.sort().map(x =>
                                        <tr>
                                            <td>{x.label}</td>
                                            <td>{x.visualizado}</td>
                                            <td>{x.data_visualizacao_formatada || '---'}</td>

                                        </tr>)
                            },
                        ]
                    } : { titulo: "Visualização de Notificação" })
                })
        }
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default VisualizacaoNotificacao