import { InputPadrao, InputTelefone, TextArea } from '../../../../components/Inputs'
import React, { useState, useEffect } from 'react'
import Yup from '../../../../util/Validacoes'
import { HeaderCadastro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'

const Cadastro = props => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao] = useState(Yup.object({
        descricao: Yup.string().required().nullable().max(200).trim(),
        observacao: Yup.string().max(500).nullable(),
    }))

    const manter = []

    const Submit = (values, propsFormik) => {
        setSalvando(true)

        salvar(
            { ...values },
            '/administracao/departamento',
            obj => {
                if (props.modal) {
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else {
                        props.setItemSelecionado({ value: obj.id, label: obj.descricao })
                        props.setShow && props.setShow(false)
                    }
                }
                else {
                    mostrarToast('sucesso', mensagens.sucesso)
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                    }
                    else
                        props.history.push('/administracao/departamento/consulta')
                }
            },
            () => setSalvando(false))
    }

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Administrativo</span>,
        () => <Link className='link-active' to={'/administracao/departamento/consulta'}>Departamento</Link>,
    ]

    useEffect(() => {
        if (!props.modal && id)
            Promise.resolve(get(`/administracao/departamento/${id}`)).then(obj => {
                if (!obj.id)
                    props.history.push('/administracao/departamento/consulta')
                setValoresIniciais(obj)
            })
    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>
                {!props.modal && <HeaderCadastro titulo={"Cadastro Departamento"} link={links} />}
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={props.modal || 'card-body'}>
                        <div className='row'>
                            <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' />
                            <InputPadrao label='Descrição' name='descricao' type='text' obrigatorio={true} tamanho={!props.modal && 'col-lg-12'} autoFocus
                                salvarENovo={salvarENovo} classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <TextArea label='Observação' name='observacao' type='text' placeholder='Observação' tamanho={!props.modal && 'col-lg-12'} rows={2}
                                classeTooltip={props.modal && 'tooltip-input-modal'} />
                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo}  salvarENovo={true} />
                        </div>
                    </div>
                </div>
                {!props.modal && <div className='separator mt-40 mb-5'></div>}
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'}/>
                    {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)}
        </Formik>
    )
}

export default Cadastro