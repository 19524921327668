
import React, { useState, useEffect } from 'react'
import { HeaderCadastro } from '../../../../components/Headers'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'


const ConsultaEscalador = (props) => {
    const [data, setData] = useState([])

    useEffect(() => {
        consultar('/administracao/escalador', (dados) => setData(dados))
    }, [])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-escalador',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Sigla',
                        accessor: 'sigla',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'sigla',
                            idColuna: 'sigla'
                        }
                    },
                    {
                        Header: 'Sócio',
                        accessor: 'usuario_nome',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'usuario_socio',
                            idColuna: 'escalador.socio_id'
                        }
                    },
                    {
                        Header: 'Tipo Escalador',
                        accessor: 'descricao',
                        ordenamento: {
                            tipo: 'texto',
                        },
                        filtro: {
                            tipo: 'tipo_escalador',
                            idColuna: 'tipo_escalador.id'
                        }
                    },
                    {
                        Header: 'Data Inicial',
                        accessor: 'data_inicial',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_inicial',
                            idColuna: "data_inicial",
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data Final',
                        accessor: 'data_final',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_final',
                            idColuna: "data_final",
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data de Registro',
                        accessor: 'data_registro',
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data',
                            idColuna: "data_registro",
                            datePicker: true,
                            date: true
                        }
                    }
                ],
            },
        ],
        []
    )

    return (
        <>
                    <div>
                        <DataGridAlternativa data={data}
                            columns={columns}
                            caminhoBase='/administracao/escalador'
                            caminhoConsultaFiltros= '/administracao/escaladorpaginacao'
                            titulo={'Consulta de Escalador'}
                            tituloLista={'Lista Escalador'}
                            setData={setData}
                            acoes={true}
                            modulo={props.modulo}
                            urlCadastro="/administracao/escalador/cadastro" />
                            
                    </div>
        </>
    )
}

export default ConsultaEscalador