import React, { useState, useEffect, useRef } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'
import { Alterar, Excluir, Visualizar, Imprimir, BtnAcao, Btn, Detalhes, AlteracaoStatusLote, EnviarLoteCoop, VisualizarArquivo, VisualizarErro, VisualizarRelacaoGuias } from '../../../../components/Acoes'
import { BotaoPadrao } from '../../../../components/Botoes'
import moment from 'moment';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { get, excluir, salvar, validar } from '../../../../util/AcessoApi'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens, useWindowSize } from '../../../../util/Utilitarios.js'
import { InputPadrao, TextArea } from '../../../../components/Inputs'
import { InputLoteStatus } from '../../../../components/InputsAutoComplete'
import Yup from '../../../../util/Validacoes';
import { ModalCadastro, ModalDuasOpcoes } from '../../../../components/Modais'
import { Formik, Form } from "formik";
import { LoadRelatorio } from '../../../../components/Loading'
import { useHistory, Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';



const ConsultaCheque = rProps => {
    const [data, setData] = useState([])
    const target = useRef(null)
    const [modalAlteracaoStatus, setModalAlteracaoStatus] = useState(false)
    const [itemTrocarStauts, setItemTrocarStatus] = useState({})
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [itemDetalhes, setItemDetalhes] = useState([])
    const [width] = useWindowSize()
    const history = useHistory()


    // useEffect(_ => { consultar('/faturamento/lote', dados => setData(dados.sort((a, b) => b.id - a.id))) }, [rProps])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-lote',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Banco',
                    accessor: 'banco',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'banco',
                        idColuna: 'banco_id',

                    }
                },
           
                {
                    Header: 'Agência',
                    accessor: 'agencia_formatada',
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Conta',
                    accessor: 'conta_formatada',
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Número de Cheque',
                    accessor: 'numero',
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Valor',
                    accessor: 'valor_formatado',
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Data Emissão',
                    accessor: 'data_emissao',
                    ordenamento: {
                        tipo: 'data'
                    },
                },
                {
                    Header: 'Data Emissao Inicial',
                    accessor: 'data_emissao_inicial',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_emissao_inicial',
                        idColuna: 'data_emissao_inicial',
                        datePicker: true,
                        date: true

                    }
                },
                {
                    Header: 'Data Emissao Final',
                    accessor: 'data_emissao_final',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_emissao_final',
                        idColuna: 'data_emissao_final',
                        datePicker: true,
                        date: true

                    }
                },
                {
                    Header: 'Data Compensação',
                    accessor: 'data_compensado',
                    ordenamento: {
                        tipo: 'data'
                    },
                },
                {
                    Header: 'Devolvido ?',
                    accessor: 'devolvido',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'input_sim_nao',
                        idColuna: 'data_devolucao',

                    }
                },
                {
                    Header: 'Data Compensação Inicial',
                    accessor: 'data_compensacao_inicial',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_compensacao_inicial',
                        idColuna: 'data_compensacao_inicial',
                        datePicker: true,
                        date: true

                    }
                },
                {
                    Header: 'Data Compensação Final',
                    accessor: 'data_compensacao_final',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_compensacao_final',
                        idColuna: 'data_compensacao_final',
                        datePicker: true,
                        date: true

                    }
                },
                {
                    Header: 'Data Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    },
                },
                {
                    Header: 'Data Registro Inicial',
                    accessor: 'data_inicial',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'data_registro_inicial',
                        datePicker: true,
                        date: true

                    }
                }, {
                    Header: 'Data Registro Final',
                    accessor: 'data_final',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'data_registro_final',
                        datePicker: true,
                        date: true

                    }
                },
                // {
                //     Header: 'Hora Registro Inicial',
                //     accessor: 'hora_inicial_registro',
                //     visivel: false,
                //     ordenamento: {
                //         tipo: 'data'
                //     },
                //     filtro: {
                //         tipo: 'hora_inicial_registro',
                //         idColuna: 'hora_registro_inicial',
                //         timePicker: true
                //     }
                // }, {
                //     Header: 'Hora Registro Final',
                //     accessor: 'hora_final_registro',
                //     visivel: false,
                //     ordenamento: {
                //         tipo: 'data'
                //     },
                //     filtro: {
                //         tipo: 'hora_final_registro',
                //         idColuna: 'hora_registro_final',
                //         timePicker: true
                //     }
                // },
            ]
        }], []
    )

    const acoesObj = [
        (propsAcao) => <Visualizar to={'/financeiro/cheque/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/financeiro/cheque/alteracao/' + propsAcao.row.id} />,
        (propsAcao) => <Excluir to={'financeiro/cheque/consulta/'} action={() => exclusao(propsAcao)} />,
    ]

    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: rProps.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/financeiro/cheque', propsAcao.row.id,
                    () => {
                        // getApi(setData, '/faturamento/guia')
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    return (
        <>
            <div>
                <DataGridAlternativa target={target} acoesObj={acoesObj} data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/financeiro/chueque'
                    caminhoConsultaFiltros='/financeiro/chequepaginacao' modulo={rProps.modulo} titulo={'Consulta Cheque'} tituloLista={'Lista Cheque'}
                    urlCadastro='/financeiro/cheque/cadastro' icone={'icon-File-HorizontalText'} />
            </div>
        </>
    )

}

export default ConsultaCheque