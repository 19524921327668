import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
import { formatMoeda, formatTwoPlace } from '../../../../util/FuncoesComuns'

export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    const porte = obj => {
        let arrayVisualizacao = []

        if (obj.contrato_acomodacao.length)
            arrayVisualizacao.push({
                tipoGrupo: 'tabela',
                titulo: 'Acomodação',
                thead:
                    <tr>
                        <td className='coluna-2'>Acomodação</td>
                        <td className='coluna-2 text-right'>Percentual</td>
                        <td className='coluna-2 text-right'>Urgência</td>
                        <td className='coluna-2 text-right'>Vídeo</td>
                    </tr>,
                tbody:
                    obj.contrato_acomodacao && obj.contrato_acomodacao.sort().map(x =>
                        <tr>
                            <td>{x.acomodacao.label}</td>
                            <td className='text-right'>{formatTwoPlace(x.percentual) + '%'}</td>
                            <td className='text-right'>{formatTwoPlace(x.urgencia) + '%'}</td>
                            <td className='text-right'>{formatTwoPlace(x.video) + '%'}</td>
                        </tr>)
            })

        if (obj.contrato_tabela.length)
            arrayVisualizacao.push({
                tipoGrupo: 'tabela',
                titulo: 'Tabela',
                thead:
                    <tr>
                        <td className='coluna-5'>Tabela</td>
                        <td className='coluna-3'>Base Cálculo</td>
                    </tr>,
                tbody:
                    obj.contrato_tabela && obj.contrato_tabela.sort().map(x =>
                        <tr>
                            <td>{x.tabela_procedimento.label}</td>
                            <td>{x.base_calculo.label}</td>
                        </tr>)
            })

        if (obj.procedimentos) {
            arrayVisualizacao.push({
                tipoGrupo: 'tabela',
                titulo: 'Procedimentos',
                thead:
                    <tr>
                        <td className='coluna-1 text-left'>Código</td>
                        <td className='coluna-4'>Descrição</td>
                        <td className='coluna-1 text-center'>Porte</td>
                        <td className='coluna-1 text-right'>Valor</td>
                        <td className='coluna-1 text-right'>Valor Diferenciado</td>
                    </tr>,
                tbody: obj.procedimentos && obj.procedimentos.map(y => <tr>
                    <td className='text-left'>{y.procedimento}</td>
                    <td className='text-left'>{y.descricao}</td>
                    <td className='text-center'>{y.porte}</td>
                    <td className='text-right'>{y.valor_padrao_formatado}</td>
                    <td className='text-right'>{y.valor_diferenciado_formatado}</td>
                </tr>
                )
            })
        }

        if (obj.contrato_porte_acomodacao) {
            let tabelas = obj.contrato_porte_acomodacao.map(x => x.tabela_procedimento);
            let acomodacoes = obj.contrato_porte_acomodacao.length > 0 ? obj.contrato_porte_acomodacao[0].array_acomodacao.map(x => x.acomodacao) : [];
            acomodacoes.map(x => {
                arrayVisualizacao.push({
                    tipoGrupo: 'tabela',
                    titulo: 'Porte Acomodação - ' + x.label,
                    thead:
                        <tr>
                            <td className='coluna-4'>Tabela</td>
                            <td className='coluna-3'>Acomodação</td>
                            <td className='coluna-1'>Porte</td>
                            <td className='coluna-2 text-right'>Valor Porte</td>
                            <td className='coluna-1 text-right'>Urgência</td>
                            <td className='coluna-1 text-right'>Vídeo</td>
                        </tr>,
                    tbody: tabelas.map(y => {
                        const idxTabela = obj.contrato_porte_acomodacao.findIndex(z => z.tabela_procedimento.id === y.id)
                        const idxAcom = obj.contrato_porte_acomodacao[idxTabela].array_acomodacao.findIndex(a => a.acomodacao.value === x.value)
                        return obj.contrato_porte_acomodacao[idxTabela].array_acomodacao[idxAcom].array_porte.map(z =>
                            <tr>
                                <td>{y.label}</td>
                                <td>{x.label}</td>
                                <td>{z.porte}</td>
                                <td className='text-right'>{formatMoeda(z.valor_porte)}</td>
                                <td className='text-right'>{formatTwoPlace(z.urgencia) + '%'}</td>
                                <td className='text-right'>{formatTwoPlace(z.video) + '%'}</td>
                            </tr>
                        )
                    })
                })
            })
        }

        if (obj.contrato_porte_anestesico.length)
            arrayVisualizacao.push({
                tipoGrupo: 'tabela',
                titulo: 'Porte Anestésico',
                thead:
                    <tr>
                        <td className='coluna-4'>Tabela</td>
                        <td className='coluna-2'>Porte</td>
                        <td className='coluna-2 text-right'>Valor Porte</td>
                        <td className='coluna-2 text-right'>Urgência</td>
                        <td className='coluna-2 text-right'>Vídeo</td>
                    </tr>,
                tbody:
                    obj.contrato_porte_anestesico && obj.contrato_porte_anestesico.map(x => x.porte.map(y =>
                        <tr>
                            <td>{x.tabela_procedimento.label}</td>
                            <td>{y.porte}</td>
                            <td className='text-right'>{formatMoeda(y.valor_porte)}</td>
                            <td className='text-right'>{formatTwoPlace(y.urgencia) + '%'}</td>
                            <td className='text-right'>{formatTwoPlace(y.video) + '%'}</td>
                        </tr>))
            })

        if (obj.contrato_porte_consulta.length)
            arrayVisualizacao.push({
                tipoGrupo: 'tabela',
                titulo: 'Consulta',
                thead:
                    <tr>
                        <td className='coluna-4'>Data Inicio</td>
                        <td className='coluna-4'>Data Fim</td>
                        <td className='coluna-4 text-right'>Valor</td>
                    </tr>,
                tbody:
                    obj.contrato_porte_anestesico && obj.contrato_porte_consulta.map(x =>
                        <tr>
                            <td>{x.data_inicio}</td>
                            <td>{x.data_fim}</td>
                            <td className='text-right'>{formatMoeda(x.valor)}</td>
                        </tr>)
            })

        return arrayVisualizacao
    }

    useEffect(() => {
        visualizar('/faturamento/contrato', id,
            obj => {
                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Contrato',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Operadora Saúde', descricao: obj.operadora_saude.label, tamanho: 'col-lg-4' },
                                { titulo: 'Status', descricao: obj.contrato_status.label, tamanho: 'col-lg-4' },
                                { titulo: 'Número', descricao: obj.numero, tamanho: 'col-lg-4' },
                                { titulo: 'Vigência Inicial', descricao: obj.vigencia_inicial, tamanho: 'col-lg-4' },
                                { titulo: 'Vigência Final', descricao: obj.vigencia_final, tamanho: 'col-lg-4' },
                                { titulo: 'Anestesista?', descricao: obj.anestesista.label, tamanho: 'col-lg-4' },
                                { titulo: 'Consulta?', descricao: obj.consulta.label, tamanho: 'col-lg-4' },
                                { titulo: 'Acréscimo Redução', descricao: (obj.acrescimo_reducao || 0) + '%', tamanho: 'col-lg-4' },
                                { titulo: 'Valor UCO', descricao: formatMoeda(obj.valor_uco), tamanho: 'col-lg-4' },
                                { titulo: 'Valor Filme', descricao: formatMoeda(obj.valor_filme), tamanho: 'col-lg-4' },
                                { titulo: 'Particular?', descricao: obj.particular.label, tamanho: 'col-lg-4' },
                                { titulo: 'Data de Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                                { titulo: 'Identificador Subdivisao', descricao: obj.codigo_subdivisao, tamanho: 'col-lg-4' },
                                { titulo: 'Descrição', descricao: obj.descricao, tamanho: 'col-lg-4' },
                                { titulo: 'Cód. Prestador Operadora', descricao: obj.codigo_prestador, tamanho: 'col-lg-4' },
                                { titulo: 'Qtde. Máxima de Guias Por Lote', descricao: obj.qtd_guias_por_fatura, tamanho: 'col-lg-4' },
                                { titulo: 'Máscara Carteira/Matricula TISS', descricao: obj.mascara_carteira, tamanho: 'col-lg-4' },
                                { titulo: 'Limite para Faturamento (Dias)', descricao: obj.dias_limite_faturamento, tamanho: 'col-lg-4' },
                                { titulo: 'Envia Diversos?', descricao: obj.envia_diversos ? 'Sim' : 'Não', tamanho: 'col-lg-4' },
                                { titulo: 'Nome Paciente', descricao: obj.paciente, tamanho: 'col-lg-4' },
                                { titulo: 'Observação', descricao: obj.observacao, tamanho: 'col-lg-4' },
                            ]
                        },
                        {
                            titulo: 'Parâmetros Comprovante de Anestesia',
                            colunas: [
                                { titulo: 'Utiliza Guia?', descricao: obj.utiliza_guia ? 'Sim' : 'Não', tamanho: 'col-lg-4' },
                                { titulo: 'Procedimentos "Não" Ordenados?', descricao: obj.proced_nao_ordenados ? 'Sim' : 'Não', tamanho: 'col-lg-4' },
                                { titulo: 'Valida Maior Porte?', descricao: obj.valida_maior_porte ? 'Sim' : 'Não', tamanho: 'col-lg-4' },
                                { titulo: 'Valida Interposição?', descricao: obj.valida_interposicao ? 'Sim' : 'Não', tamanho: 'col-lg-4' },
                                { titulo: 'Utiliza Senha?', descricao: obj.utiliza_senha ? 'Sim' : 'Não', tamanho: 'col-lg-4' },
                                { titulo: 'Valida Duplicidade?', descricao: obj.valida_duplicidade ? 'Sim' : 'Não', tamanho: 'col-lg-4' },
                                { titulo: 'Procedimento Único(50/70/100%)?', descricao: obj.procedimento_unico ? 'Sim' : 'Não', tamanho: 'col-lg-4' },
                                { titulo: 'Liberar Percentual?', descricao: obj.liberar_percentual ? 'Sim' : 'Não', tamanho: 'col-lg-4' },
                                { titulo: 'Utiliza Matrícula?', descricao: obj.utiliza_matricula ? 'Sim' : 'Não', tamanho: 'col-lg-4' },
                                { titulo: 'Utiliza DN?', descricao: obj.utiliza_dn ? 'Sim' : 'Não', tamanho: 'col-lg-4' },
                            ]
                        },
                        {

                            titulo: 'Duplicidade De Guias (CAMPOS)',
                            colunas: [
                                { titulo: 'Sócio', descricao: obj.socio ? "Sim" : "Não", tamanho: 'col-lg-6' },
                                { titulo: 'Estabelecimento de Saúde', descricao: obj.estabelecimento_saude ? "Sim" : "Não", tamanho: 'col-lg-6' },
                                { titulo: 'Acomodação', descricao: obj.acomodacao ? "Sim" : "Não", tamanho: 'col-lg-6' },
                                { titulo: 'Guia', descricao: obj.guia ? "Sim" : "Não", tamanho: 'col-lg-6' },
                                { titulo: 'Matrícula/Carteira', descricao: obj.matricula_carteira ? "Sim" : "Não", tamanho: 'col-lg-6' },
                                { titulo: 'Nome Paciente', descricao: obj.nome_paciente ? "Sim" : "Não", tamanho: 'col-lg-6' },
                                { titulo: 'Data Procedimento', descricao: obj.data_procedimento ? "Sim" : "Não", tamanho: 'col-lg-6' },
                                { titulo: 'Hora Inicial', descricao: obj.hora_inicial ? "Sim" : "Não", tamanho: 'col-lg-6' },
                                { titulo: 'Hora Final', descricao: obj.hora_final ? "Sim" : "Não", tamanho: 'col-lg-6' },
                                { titulo: 'Senha', descricao: obj.senha_tiss ? "Sim" : "Não", tamanho: 'col-lg-6' },
                                { titulo: 'Procedimento', descricao: obj.procedimento ? "Sim" : "Não", tamanho: 'col-lg-6' },
                            ]
                        }
                    ].concat(porte(obj))
                } : { titulo: 'Visualização Contrato' })
            }
        )
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}