import React, { useEffect, useState, useRef } from 'react'
import { Redirect } from 'react-router-dom';
import styled from 'styled-components'
import { useTable, useFilters, useGlobalFilter, usePagination } from 'react-table'
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { BotaoPadrao } from './Botoes';
import { InputSimples } from './Inputs'
import { FiltroObjeto, metodoFiltragem, pesquisaGlobal, Ordenaveis } from './Filtragens'
import '../assets/css/data-grid.css';
import '../assets/css/icones/iconsmind/style.css';
import { Alterar, Excluir, Visualizar, Imprimir, BtnAcao, Btn } from './Acoes'
import { ACOES } from '../util/Enums'
import { getUsuario, getApi } from '../util/Configuracoes'
import { get, excluir } from '../util/AcessoApi'
import { mostrarToast } from './Toasts'
import { mensagens, useWindowSize, useInterval } from '../util/Utilitarios.js'
import { HeaderConsulta } from './Headers'
import { ModalCadastro } from './Modais'
import ContentLoader from "react-content-loader"
import { ordenarMultiplo } from './Ordenamentos'


/* Opções:
    Header: Define o título
    accessor: Define o nome da coluna para acesso no data,
    filtros: define os filtros as serem utilizados, baseando se no tipo definido dentro de filtragens
    apenasDataGrid: A coluna aparecerá apenas na Data Grid
    apenasRelatorio: A coluna aparecerá apenas no Relatório
*/

const Styles = styled.div``

function Table(props) {
    const { columns, data, acoes, acoesObj, maisAcoesObj, acoesComReenvio, cadastro, tituloLista } = props
    const [newData, setNewData] = useState([])
    const [filtrosAbertos, setFiltrosAbertos] = useState([])
    const [pesquisa, setPesquisa] = useState([])
    const [impressao, setImpressao] = useState(false)
    const [filtrosColuna, setFiltroColuna] = useState([])
    const [colunasOrdenaveis, setColunasOrdenaveis] = useState([])
    const [arrayFiltros, setArrayFiltros] = useState([])
    const [abrirModal, setAbrirModal] = useState(false)
    const [parametrosLoading, setParametrosLoading] = useState({})
    const [carregando, setCarregando] = useState(true)
    const [semRegistro, setSemRegistro] = useState(false)
    const [pesquisaAnterior, setPesquisaAnterior] = useState([])
    const [arrayOrdenamento, setArrayOrdenamento] = useState([])
    const [arrayOrdenamentoModal, setArrayOrdenamentoModal] = useState([])
    const [arrayConfigCol, setArrayConfigCol] = useState([])
    const [ordenamentoExiste, setOrdenamentoExiste] = useState([])
    const [colunasSetadasComOrdenamento, setColunasSetadasComOrdenamento] = useState([])


    const ref = useRef({ current: { clientWidth: 0 } })
    function distinct(value, index, self) {
        return self.indexOf(value) === index;
    }


    const obterListaDistinct = (coluna) => {
        let item = filtrosColuna.filter(item => {
            return item.nome.toString() === coluna.toString()
        })
        let lista = item[0] && item[0].lista ? item[0].lista : []
        lista = lista.length > 0 ? lista.map((item, idx) => ({ label: item, value: idx })) : lista

        return lista;
    }
    const obterColunasOrdenaveis = () => {

        let comboAsc = columns[0].columns.filter(coluna => coluna.ordenamento).map((column, idx) => ({ label: column.Header + '- Asc', value: column.accessor + '_asc' }))
        let comboDesc = columns[0].columns.filter(coluna => coluna.ordenamento).map((column, idx) => ({ label: column.Header + "- Desc", value: column.accessor + '_desc' }))
        var combo = comboAsc.concat(comboDesc)
        combo.sort((a, b) => {
            return +(a.value > b.value) || (a.value === b.value && a.label === a.label) - 1
        })
        return combo;

    }

    useEffect(() => {
        if (columns[0].columns.length > 0)
            setFiltrosAbertos(Array(columns[0].columns.length).fill(false))

        setFiltroColuna(columns[0].columns.filter(coluna => coluna.filtro).map(coluna => {
            return {
                aberto: true,
                titulo: coluna.Header,
                nome: coluna.accessor,
                tipo: coluna.filtro && coluna.filtro.tipo ? coluna.filtro.tipo : '',
                lista: data.map(item => item[coluna.accessor]).filter(distinct)
            }
        }
        ))

        setColunasOrdenaveis(columns[0].columns.filter(coluna => coluna.ordenamento).map(coluna => {

            return {
                aberto: true,
                titulo: coluna.Header,
                nome: coluna.accessor,
                tipo: coluna.ordenamento && coluna.ordenamento.tipo ? coluna.ordenamento.tipo : '',
                lista: data.map(item => item[coluna.accessor]).filter(distinct)
            }
        }
        ))

        setNewData(data)

        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                setFiltrosAbertos(Array(columns[0].columns.length).fill(false))
        }, false);
        let preArrayOrdenamento = columns.length ? Array(columns[0].columns).fill({}) : []
        preArrayOrdenamento = columns.length && columns[0].columns.map((ag, idxAg) => {
            let contador = 0;
            if (ag.ordenamento && ag.ordenamento.ativo)
                contador++
            return {
                coluna: ag.accessor,
                ordenamento: ag.ordenamento && ag.ordenamento.ativo ? {
                    modo: ag.ordenamento.modo || 'asc',
                    tipo: ag.ordenamento.tipo || 'texto',
                    ativo: true,
                    ordem: contador
                } : {
                    modo: 'asc',
                    tipo: ag.ordenamento ? ag.ordenamento.tipo || 'texto' : 'texto',
                    ativo: false
                }
            }
        })
        setArrayOrdenamento(preArrayOrdenamento.length && preArrayOrdenamento.map(item => item && item.ordenamento.ativo))
        setArrayOrdenamentoModal(preArrayOrdenamento.length && preArrayOrdenamento.map(item => item))
        setArrayConfigCol(preArrayOrdenamento)
    }, [data])

    const obterDataOrdenado = (arrayOrder, arrayConfig) => {
        setArrayOrdenamento(arrayOrder)
        setArrayConfigCol(arrayConfig)

        if (arrayOrder && arrayOrder.filter(item => item === true).length > 0) {
            let ordenamentos = arrayConfig.filter((item, index) => item.ordenamento.ativo).sort((a, b) => { return a.ordenamento.ordem - b.ordenamento.ordem })

            return ordenarMultiplo(newData, ordenamentos)
        }
        return newData
    }




    const limparOrdenamentos = () => {
        let cloneArray = []
        cloneArray = Array(arrayOrdenamento.length).fill(false)
        let cloneArrayConfig = arrayConfigCol
        cloneArrayConfig = cloneArrayConfig.map(item => {
            item.ordenamento.ativo = false
            item.ordenamento.modo = 'asc'
            delete item.ordenamento.ordem

            return item
        })
        setArrayOrdenamento(cloneArray)
        setArrayConfigCol(cloneArrayConfig)
    }

    const verificaPaginacaoManual = () => {

        let retorno = columns[0].paginacaoManual
        return retorno
    }



    const manualGlobalFilter = () => true


    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        page,
        preGlobalFilteredRows,
        headerGroups,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, }
    } = useTable(
        {
            columns,
            data: newData,
            manualGlobalFilter,
            initialState: {
                defaultPageSize: 20,
                hiddenColumns: columns.map(item => item.columns.filter(coluna => (
                    (coluna.apenasRelatorio === true) && (coluna.apenasDataGrid === undefined || coluna.apenasDataGrid === true))).map(coluna => coluna.accessor)).flat(1)
            },
            manualPagination: verificaPaginacaoManual(),

        },
        useFilters,
        useGlobalFilter,
        usePagination
    )

    useInterval(() => {
        if (pesquisa !== "" && pesquisa !== pesquisaAnterior) {
            setCarregando(true)
            filtrar()
            setCarregando(false)
        }

        setPesquisaAnterior(pesquisa)
    }, 1000);


    useEffect(() => {
        if (props.setPageIndex)
            props.setPageIndex(pageIndex)

    }, [pageIndex])


    const pesquisar = (dados) => {

        if (pesquisa && pesquisa.length > 0) {
            let dadosPesquisados = pesquisaGlobal(dados, columns[0].columns.map(item => item.accessor), pesquisa);
            setNewData(dadosPesquisados)
            setSemRegistro(dadosPesquisados.length !== 0)
        }
        else
            setNewData(dados)
    }

    const filtrar = () => {

        if (arrayFiltros.length > 0) {
            let arrayPesquisado = [...data];
            arrayFiltros.map(item => {
                const colunaAtual = columns[0].columns[columns[0].columns.findIndex(item2 => item2.accessor === item.coluna)]
                arrayPesquisado = metodoFiltragem[colunaAtual.filtro.tipo](arrayPesquisado, item.coluna, arrayFiltros[arrayFiltros.findIndex(item2 => item2.coluna === item.coluna)].filtro)
            })
            pesquisar(arrayPesquisado);
        } else {
            pesquisar(data);
        }
    }

    const setNovoFiltro = (coluna, valor) => {
        const cloneArray = [...arrayFiltros]
        if (cloneArray.length > 0 && arrayFiltros.findIndex(item => item.coluna === coluna) > -1) {
            if (valor)
                cloneArray.splice(arrayFiltros.findIndex(item => item.coluna === coluna), 1, { coluna, filtro: valor })
            else
                cloneArray.splice(arrayFiltros.findIndex(item => item.coluna === coluna), 1)
        } else if (valor)
            cloneArray.push({ coluna, filtro: valor })
        setArrayFiltros(cloneArray)
    }

    const triggerFiltro = (idx) => {
        let cloneFiltrosAbertos = [...filtrosAbertos]
        cloneFiltrosAbertos.splice(idx, 1, !cloneFiltrosAbertos[idx])
        setFiltrosAbertos(cloneFiltrosAbertos)
    }

    useEffect(() => {
        filtrar()
    }, [arrayFiltros])

    const limparFiltros = (index) => {
        let cloneFiltros = []
        if (index) {
            cloneFiltros = [...arrayFiltros]
            cloneFiltros.splice(index, 1)
        }
        setArrayFiltros(cloneFiltros)
    }

    const obterValoresFiltros = () => {
        let retorno = {}
        arrayFiltros.map((item, idx) => {
            retorno[item.coluna] = item.filtro
            return
        })

        if (pesquisa && pesquisa.length > 0)
            retorno['pesquisa'] = pesquisa || ''

        return retorno
    }

    const obterLinhaLoading = () => {
        let linhas = [];
        let valorY = 20;
        for (let index = 0; index < parametrosLoading.qtdLinhas; index++) {
            linhas.push((<rect x={0} y={valorY} rx="3" ry="3" width={parametrosLoading.width} height="20" />))
            valorY = valorY + 50;
        }
        return linhas;
    }

    const obterFiltrosUtilizados = () => {
        const filtrosUtilizados = [...filtrosColuna]

        filtrosUtilizados.push({
            titulo: 'Pesquisa',
            nome: 'pesquisa',
            colunas: columns[0].columns && columns[0].columns.map(item => item.accessor),
            tipo: 'texto-global'
        })

        return filtrosUtilizados
    }

    const obterLoading = () => {
        if (carregando) {

            return (<tr>
                <td colspan={parametrosLoading.colspan}>

                    <ContentLoader
                        speed={3}
                        width={parametrosLoading.width}
                        height={parametrosLoading.heigth}
                        viewBox={"0 0 " + parametrosLoading.width + " " + parametrosLoading.heigth}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#e4e4e4"
                    >


                        {obterLinhaLoading()}

                    </ContentLoader>
                </td>
            </tr>)

        }
    }

    const validarImpressao = () => {
        if (props.qtdRegistros && Math.ceil(props.qtdRegistros / 10) > 1000) {
            setAbrirModal(true)
            mostrarToast('erro', <><b>Ação não permitida!</b><br /> Grande quantidade de registros. Utilize os filtros para que a consulta tenha menos do que 1000 páginas</>);
        } else {
            setImpressao(true)
        }
    }

    const firstPageRows = page
    const [width] = useWindowSize();
    let focus = true

    const headerGroupsFiltered = headerGroups.map(headerGroup => headerGroup.headers.filter(column => (typeof column.Header == "string")).length > 0 ? headerGroup : null)
    useEffect(() => {
        setParametrosLoading(ref.current ? { width: ref.current.clientWidth - 30, heigth: 600, qtdLinhas: 13, colspan: props.columns[0].columns.length + 1 } : { width: 0, height: 0 })

    }, [ref.current.clientWidth, width])

    const setOrdenamento = (idxColuna, idxOrdenamento) => {
        let ultima = 0;
        const cloneArrayConfigCol = arrayConfigCol

        for (let i = 0; i < arrayConfigCol.length; i++) {
            if (cloneArrayConfigCol[i].ordenamento.ordem && cloneArrayConfigCol[i].ordenamento.ordem > ultima)
                ultima = cloneArrayConfigCol[i].ordenamento.ordem
        }


        let cloneArray = [...arrayConfigCol]
        let cloneIniciarOrdenamento = [...arrayOrdenamento]

        if (cloneArray.length > 0) {
            let objOrdenamento = { ...cloneArray[idxOrdenamento] }
            if (!objOrdenamento.ordenamento.ativo) {
                objOrdenamento.ordenamento.ativo = true
                objOrdenamento.ordenamento.ordem = ultima + 1
            } else
                objOrdenamento.ordenamento.modo = objOrdenamento.ordenamento.modo === 'asc' ? 'desc' : 'asc'

            cloneArray[idxOrdenamento] = objOrdenamento
            cloneIniciarOrdenamento[idxOrdenamento] = true
        }
        setNewData(obterDataOrdenado(cloneIniciarOrdenamento, cloneArray))
    }

    const setOrdenamentoModal = (option) => {
        let ultima = 0;
        const cloneArrayConfigCol = arrayConfigCol

        for (let i = 0; i < arrayConfigCol.length; i++) {
            if (cloneArrayConfigCol[i].ordenamento.ordem && cloneArrayConfigCol[i].ordenamento.ordem > ultima)
                ultima = cloneArrayConfigCol[i].ordenamento.ordem
        }


        let cloneArray = [...arrayConfigCol]
        let cloneIniciarOrdenamento = [...arrayOrdenamento]

        if (option) {
            for (let i = 0; i < option.length; i++) {
                var index = cloneArray.findIndex(x => x.coluna === option[i].value.replace('_desc', '').replace('_asc', ''))

                if (cloneArray.length > 0 && arrayFiltros.findIndex(item => item.coluna === cloneArray[index].coluna) > -1) {
                    if (option)
                        cloneArray.splice(arrayFiltros.findIndex(item => item.coluna === cloneArray[index].coluna), 1, { ordenamento: option })
                    else
                        cloneArray.splice(arrayFiltros.findIndex(item => item.coluna === cloneArray[index].coluna), 1)
                } else if (option)
                    cloneArray.push({ ordenamento: option })

                if (cloneArray.length > 0) {

                    var objOrdenamento = { ...cloneArray[index] }
                    if (!objOrdenamento.ordenamento.ativo) {
                        objOrdenamento.ordenamento.ativo = true
                        objOrdenamento.ordenamento.ordem = ultima + 1
                        objOrdenamento.ordenamento.modo = option[i].value.includes('asc') ? 'asc' : 'desc'
                    } else
                        objOrdenamento.ordenamento.modo = option[i].value.includes('asc') ? 'asc' : 'desc'

                    cloneArray[index] = objOrdenamento
                    cloneIniciarOrdenamento[index] = true
                    objOrdenamento = { ordenamento: option }
                    // existeOrdenamento && existeOrdenamento.coluna === cloneArray[0].coluna 
                }
            }
        }

        setNewData(obterDataOrdenado(cloneIniciarOrdenamento, cloneArray))
        setOrdenamentoExiste(objOrdenamento)
    }

    useEffect(() => {

        const cloneObjOrdenamento = { ...ordenamentoExiste }
        cloneObjOrdenamento.ordenamento = arrayConfigCol.filter(x => x.ordenamento.ativo === true).map((x) => ({
            label: (<div>{columns[0].columns.find(coluna => coluna.accessor === x.coluna).Header}{x.ordenamento.modo.toLowerCase().indexOf("desc") > 0 ? <span className="icon-Up icone-ordenamento"></span> : <span className="icon-Down icone-ordenamento"></span>}</div>),
            value: x.coluna + "_" + x.ordenamento.modo
        }))
        setOrdenamentoExiste(cloneObjOrdenamento)
    }, [arrayOrdenamentoModal])

    useEffect(() => {
        if (ordenamentoExiste === undefined) {
            limparOrdenamentos()
        }
    }, [ordenamentoExiste])

    const handleInputChange = (e) => {
        setPesquisa(e.target.value || undefined);
    }

    return (
        <>
            {impressao ? <Redirect push to={{
                pathname: '/listagem',
                listagem: props.listaPersonalizada ? props.listaPersonalizada : {
                    dados: props.listaPersonalizada && props.listaPersonalizada.dados ? props.listaPersonalizada.dados : [{
                        agrupamento: '',
                        colunas: columns[0].columns && columns[0].columns.filter(item => (item.apenasRelatorio !== false && item.apenasDataGrid !== true))
                            .map(item => {
                                return {
                                    label: item.Header,
                                    name: item.accessor,
                                    totalizar: item.totalizar,
                                    alinhamento: 'text-left',
                                    visivel: true,
                                    ordenamento: item.ordenamento
                                }
                            }),
                        data: data
                    }],
                    titulo: tituloLista,
                    filtros: [],
                    filtrosObj: obterFiltrosUtilizados(),
                    filtrosValoresIniciais: obterValoresFiltros(),
                    dadosFiltrados: newData
                }
            }} /> : <></>}
            <div className="row-space-between row">
                <div className="col-lg-6 col-sm-12">
                    <InputSimples
                        name="search1"
                        type="text"
                        value={pesquisa || ''}
                        onChange={handleInputChange.bind(this)}
                        placeholder={`${preGlobalFilteredRows.length} registros...`}
                    />
                </div>
                <div className="col-lg-6 col-sm-12">
                    <div className={(width < 990 ? ' text-center mb-3' : ' text-right')}>
                        <div className="row d-none d-md-block">
                            <div className="col-12">
                                <button className="icon-Arrow-Left2 pagination-button icone icone-pagination" onClick={() => gotoPage(0)} disabled={false}></button><button className="icon-Arrow-Left pagination-button icone icone-pagination" onClick={() => previousPage()} disabled={false}></button> {pageIndex + 1}<button className="icon-Arrow-Right pagination-button icone icone-pagination" onClick={() => nextPage()} disabled={false}></button><button className="icon-Arrow-Right2 pagination-button icone icone-pagination" onClick={() => gotoPage(props.qtdRegistros ? Math.round(props.qtdRegistros / 10) - 1 : pageCount - 1)} disabled={false}></button>
                                <span className="numero-pagina">Total de páginas {props.qtdRegistros ? Math.round(props.qtdRegistros / 10) : pageOptions.length} </span>
                            </div>
                        </div>
                        <div className="row d-block d-md-none d-lg-none d-xl-none">
                            <div className="col-12">
                                <button className="icon-Arrow-Left2 pagination-button icone icone-pagination" onClick={() => gotoPage(0)} disabled={false}></button><button className="icon-Arrow-Left pagination-button icone icone-pagination" onClick={() => previousPage()} disabled={false}></button> {pageIndex + 1}<button className="icon-Arrow-Right pagination-button icone icone-pagination" onClick={() => nextPage()} disabled={false}></button><button className="icon-Arrow-Right2 pagination-button icone icone-pagination" onClick={() => gotoPage(props.qtdRegistros ? Math.round(props.qtdRegistros / 10) - 1 : pageCount - 1)} disabled={false}></button>
                            </div>
                            <div className="col-12 mt-1">
                                <span className="numero-pagina">Total de páginas {props.qtdRegistros ? Math.round(props.qtdRegistros / 10) : pageOptions.length} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card inline-table">
                <div className="card-body">
                    <div className="row-space-between">
                        <div className="">{(arrayOrdenamento.length && arrayOrdenamento.filter(item => item === true).length)
                            && <BtnAcao icone='icon-A-Z' texto="Remover Ordenamento" cortarIcone action={() => limparOrdenamentos()} /> || <></>}{arrayFiltros && arrayFiltros.length > 0 ? <BtnAcao icone='icon-Filter-2' texto={"Remover Filtros(" + arrayFiltros.length + ")"} cortarIcone action={() => limparFiltros()} /> : <></>}</div>
                        <div className=""><Imprimir action={() => validarImpressao()} /></div>
                    </div>
                    <table {...getTableProps()} className="table data-grid table-responsive-md" ref={ref}>
                        <thead>
                            {headerGroupsFiltered.map((headerGroup, idx) => headerGroup != null ? (
                                <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                                    {headerGroup.headers.map((column, idx) => {
                                        const colunaAtual = { idy: columns[0].columns.findIndex(item => item.accessor === column.id), ...columns[0].columns[columns[0].columns.findIndex(item => item.accessor === column.id)] }
                                        const idxFiltro = arrayFiltros.findIndex(item => item.coluna === column.id)
                                        const filtroExiste = arrayFiltros[idxFiltro] && arrayFiltros[idxFiltro].filtro
                                        let classes
                                        let labelOrdenamento
                                        return (
                                            <>
                                                <th {...column.getHeaderProps()} onClick={_ => colunaAtual.ordenamento && setOrdenamento(colunaAtual.idy, idx)} className={colunaAtual.visivel === false ? 'none-coluna ' : ''}>
                                                    {columns && columns[0].columns.map((y, idy) => {
                                                        if (y.accessor === column.id) {
                                                            classes = (y.alinhamento || '') + ' ' + (y.ordenamento ? 'ordenavel ' : '') +
                                                                (arrayConfigCol.length && arrayConfigCol[idy] && arrayConfigCol[idy].ordenamento.ativo &&
                                                                    arrayConfigCol[idy].coluna === y.accessor ? 'ordenamento_' + arrayConfigCol[idy].ordenamento.modo :
                                                                    'nao');

                                                            labelOrdenamento = () => arrayConfigCol.length && arrayConfigCol[idy] &&
                                                                <label style={{ width: y.width || 'auto' }}
                                                                    className={`bold coluna ${y.alinhamento && y.alinhamento || ''} ${classes}`}>{y.label}</label>
                                                        }
                                                    })}
                                                    <div className={`th-filter ${classes}    ${colunaAtual.visivel === false ? 'none-coluna ' : ''}`}>
                                                        <div className={'group-title-consulta'}>
                                                            {colunaAtual.filtro && !colunaAtual.filtroSomenteModal && <Dropdown key={idx} active={filtrosAbertos.length > 0 && filtrosAbertos[idx]}>
                                                                <DropdownTrigger>
                                                                    <i className={'icon-Filter-2 cursor-pointer ' + (filtroExiste ? 'filtrado' : '')/* ------- */} onClick={() => triggerFiltro(idx)} />
                                                                </DropdownTrigger>
                                                                <DropdownContent>
                                                                    <div className="card-filtro-dropdown">
                                                                        <div>
                                                                            {colunaAtual.filtro && filtrosColuna.length > 0 ? <FiltroObjeto placeholder={colunaAtual.Header ?
                                                                                colunaAtual.Header : 'Selecione...'} tipo={colunaAtual.filtro.tipo ? colunaAtual.filtro.tipo : ''} tamanho='-'
                                                                                value={filtroExiste ? arrayFiltros[idxFiltro].filtro : ''} data={obterListaDistinct(column.id)} multiplo={true}
                                                                                onChange={option => setNovoFiltro(column.id, option)} autoFocus /> : <></>}
                                                                        </div>
                                                                        <div className='mt-2 text-center'>
                                                                            <BotaoPadrao classe='btn-sm btn-primary-filtro simples' texto='Limpar' click={() => limparFiltros(idxFiltro)} />
                                                                            <BotaoPadrao classe='btn-sm btn-primary-filtro simples' texto='Fechar' click={() => triggerFiltro(idx)} />
                                                                        </div>
                                                                    </div>
                                                                </DropdownContent>
                                                            </Dropdown>}
                                                            {column.render('Header')}
                                                            {labelOrdenamento()}
                                                        </div>
                                                    </div>
                                                </th>


                                            </>
                                        )
                                    })}
                                    {acoes ? <th className="th-acoes">Ações</th> : <></>}
                                </tr>
                            ) : null)}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {obterLoading()}
                            {firstPageRows.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()} key={i}>
                                        {row.cells.map((cell, i) => {
                                            return <td className={'responsive-td ' + ' ' + (row.cells[i].column.classeTd || '') + (row.cells[i].column.visivel === false ? ' none-coluna' : '') + (row.cells[i].column.alinhamento || '')}
                                                {...cell.getCellProps()} key={i}><div className={'responsive-header'}>{cell.column.Header}</div><div>{cell.render('Cell')}
                                                    <span className={cell.row.original.valida_cadastro === false && cell.column.Header === 'Nome' ? 'tag-data-grid' : ''}>{cell.row.original.valida_cadastro === false && cell.column.Header === 'Nome' ? ' pendente' : ''}</span> </div></td>
                                        })}
                                        {(acoes && width > 1607 || width <= 1334 || columns[0].columns.length < 8 || row.cells[0].column.ajuste === false) && props.acoesObj.length > 0 ?
                                            <td className="text-center responsive-actions">
                                                {acoesObj.map((Acao, i) => {
                                                    return <Acao id={row.original.id} row={row.original} key={i} />
                                                })}
                                            </td>
                                            : (acoes && width <= 1607 && (width > 1334 && width < 1350 || columns[0].columns.length >= 8 || row.cells[0].column.ajuste === true)) && props.acoesObj.length > 0 ?
                                                <td className="text-center responsive-actions">
                                                    {<Dropdown key={i}>
                                                        <DropdownTrigger>
                                                            <Btn icone='icon-ellipsi' texto="Mais Ações" />
                                                        </DropdownTrigger>
                                                        {row.original.valida_cadastro === false || row.original.valida_cadastro === true ?
                                                            <DropdownContent>
                                                                <div className="card-acoes-dropdown">
                                                                    <div className="ajusta-dropdown">
                                                                        {acoesObj.map((Acao, i) => {
                                                                            return <Acao id={row.original.id} row={row.original} key={i} />
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </DropdownContent> :
                                                            <DropdownContent className="ajusta-acoes">
                                                                <div className="card-acoes-dropdown">
                                                                    <div className="ajusta-dropdown">
                                                                        {acoesObj.map((Acao, i) => {
                                                                            return <Acao id={row.original.id} row={row.original} key={i} />
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </DropdownContent>}
                                                    </Dropdown>}
                                                </td> : null}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <br />
                </div>
            </div>
            <div className='row mt-20 paginacao-responsive'>
                <div className="col-lg-6 col-sm-12">
                    <div className={(width < 990 ? ' text-center mb-3' : ' text-right')}>
                        <div className="row d-none d-md-block">
                            <div className="col-12">
                                <button className="icon-Arrow-Left2 pagination-button icone icone-pagination" onClick={() => gotoPage(0)} disabled={false}></button><button className="icon-Arrow-Left pagination-button icone icone-pagination" onClick={() => previousPage()} disabled={false}></button> {pageIndex + 1}<button className="icon-Arrow-Right pagination-button icone icone-pagination" onClick={() => nextPage()} disabled={false}></button><button className="icon-Arrow-Right2 pagination-button icone icone-pagination" onClick={() => gotoPage(props.qtdRegistros ? Math.round(props.qtdRegistros / 10) - 1 : pageCount - 1)} disabled={false}></button>
                                <span className="numero-pagina">Total de páginas {props.qtdRegistros ? Math.round(props.qtdRegistros / 10) : pageOptions.length} </span>
                            </div>
                        </div>
                        <div className="row d-block d-md-none d-lg-none d-xl-none">
                            <div className="col-12">
                                <button className="icon-Arrow-Left2 pagination-button icone icone-pagination" onClick={() => gotoPage(0)} disabled={props.qtdRegistros ? pageIndex === 0 ? true : false : !canPreviousPage}></button><button className="icon-Arrow-Left pagination-button icone icone-pagination" onClick={() => previousPage()} disabled={false}></button> {pageIndex + 1}<button className="icon-Arrow-Right pagination-button icone icone-pagination" onClick={() => nextPage()} disabled={false}></button><button className="icon-Arrow-Right2 pagination-button icone icone-pagination" onClick={() => gotoPage(props.qtdRegistros ? Math.round(props.qtdRegistros / 10) - 1 : pageCount - 1)} disabled={false}></button>
                            </div>
                            <div className="col-12 mt-1">
                                <span className="numero-pagina">Total de páginas {props.qtdRegistros ? Math.round(props.qtdRegistros / 10) : pageOptions.length} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {columns[0].id === 'consulta-auditoria' || width <= 1334 ?
                <div className={'div-filtro-datagrid'}>
                    <BtnAcao icone='icon-Filter-2 filtro-datagrid-responsive' action={_ => setAbrirModal(true)} />
                </div>
                : <></>}
            <ModalCadastro titulo={'Filtros'} show={abrirModal} setShowModal={setAbrirModal} >

                {
                    headerGroupsFiltered.map(headerGroup => headerGroup != null &&
                        headerGroup.headers.map((column, index) => {
                            const colunaAtual = columns[0].columns[columns[0].columns.findIndex(item => item.accessor === column.id)]
                            const idxFiltro = arrayFiltros.findIndex(item => item.coluna === column.id)
                            const filtroExiste = arrayFiltros[idxFiltro] && arrayFiltros[idxFiltro].filtro

                            const changeFocus = _ => {
                                if (focus) {
                                    focus = false
                                    return true
                                }
                                return false
                            }

                            return (
                                colunaAtual.filtro && filtrosColuna.length > 0 &&
                                <FiltroObjeto placeholder={colunaAtual.Header ?
                                    colunaAtual.Header : 'Selecione...'} tipo={colunaAtual.filtro.tipo ? colunaAtual.filtro.tipo : ''} tamanho='-'
                                    value={filtroExiste ? arrayFiltros[idxFiltro].filtro : ''} data={obterListaDistinct(column.id)} multiplo={true}
                                    onChange={option => setNovoFiltro(column.id, option)} autoFocus={changeFocus()} label={column.render('Header')} />
                            )
                        })
                    )
                }
                {width <= 1334 ?
                    <>
                        <div className="ajusta-modal-ordenamento">
                            <h5>Ordenamento</h5>
                        </div>
                        <Ordenaveis placeholder={'Selecione...'} id="ordenaveis" tamanho='-' value={ordenamentoExiste ? ordenamentoExiste.ordenamento : ''} data={obterColunasOrdenaveis()} multiplo={true} icone={'icon-A-Z '} onChange={option => setOrdenamentoModal(option)} />

                    </>
                    : <></>}
                <BotaoPadrao texto='OK' click={_ => setAbrirModal(false)} />

            </ModalCadastro>
        </>
    )
}

function DataGrid(props) {
    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir(props.caminhoBase, propsAcao.id,
                    () => {
                        getApi(props.setData, props.caminhoBase)
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const acoesObj = [
        (propsAcao) => <Visualizar to={props.caminhoBase + '/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={props.caminhoBase + '/alteracao/' + propsAcao.id} />,
        (propsAcao) => <Excluir to={props.caminhoBase + '/consulta/'} action={() => exclusao(propsAcao)} />

    ]

    return (
        <Styles>
            <HeaderConsulta titulo={props.titulo} urlCadastro={props.urlCadastro} icone={props.icone} {...props} />
            <Table columns={props.columns} data={props.data.length > 0 ? props.data : []} acoes={props.acoes} acoesObj={props.acoesObj ? props.acoesObj : acoesObj} qtdRegistros={props.qtdRegistros}
                setPageIndex={props.setPageIndex} cadastro={`${props.caminhoBase}/cadastro`} tituloLista={props.tituloLista || props.titulo} listaPersonalizada={props.listaPersonalizada} />
        </Styles>
    )
}

export default DataGrid
export { Table }