import React, { useState, useEffect, useRef } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar, putComAcao } from '../../../../util/AcessoApi'
import {
    Alterar, Excluir, Visualizar, Imprimir, BtnAcao, Btn, Detalhes, AlteracaoStatusLote, EnviarLoteCoop, VisualizarArquivo, VisualizarErro, VisualizarRelacaoGuias,
    RelatorioLote, RemoverGuias, PopoverConsultaLote, AdicionarGuiaLote
} from '../../../../components/Acoes'
import { BotaoPadrao } from '../../../../components/Botoes'
import moment from 'moment';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES, MODULO } from '../../../../util/Enums'
import { get, excluir, salvar, validar } from '../../../../util/AcessoApi'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens, useWindowSize } from '../../../../util/Utilitarios.js'
import { InputDatePicker, InputMonthPicker, InputNumero, InputPadrao, TextArea } from '../../../../components/Inputs'
import { InputContrato, InputEmpresa, InputGuiaStatus, InputLoteStatus, InputOperadoraSaude, InputTipoExecutante, InputTipoGuia } from '../../../../components/InputsAutoComplete'
import Yup from '../../../../util/Validacoes';
import { ModalCadastro, ModalDuasOpcoes } from '../../../../components/Modais'
import { Formik, Form } from "formik";
import { LoadRelatorio } from '../../../../components/Loading'
import DetalhesLote from './DetalhesLote'
import { VisualizacaoErro } from '../../guia/pages/Visualizacao'
import { useHistory, Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { ModalGerarLotes } from './Cadastro'
import { InputGuiaLote, InputLoteGuia } from '../../../../components/InputsAutoCompleteV2'


const Consulta = rProps => {
    const [data, setData] = useState([])
    const target = useRef(null)
    const [modalAlteracaoStatus, setModalAlteracaoStatus] = useState(false)
    const [itemTrocarStauts, setItemTrocarStatus] = useState({})
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [itemDetalhes, setItemDetalhes] = useState([])
    const [dadosLote, setDadosLote] = useState({})
    const { width } = useWindowSize()
    const history = useHistory()
    const [showModalGerarLotes, setShowModalGerarLotes] = useState(false)
    const [showModalAdicionarGuia, setShowModalAdicionarGuia] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const usuario = getUsuario()


    useEffect(_ => { consultar('/faturamento/lote', dados => setData(dados.sort((a, b) => b.id - a.id))) }, [rProps])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-lote',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    html: item => {
                        return (
                            <div>
                                <span>
                                    {item.id}
                                    {
                                        <PopoverConsultaLote id={item.id}
                                            titulo={
                                                <>
                                                    <div className='text-center color-gray' style={{ fontSize: 13 }}>
                                                        <b>Usuário Registro:</b>
                                                    </div>
                                                </>}
                                            info={
                                                <div style={{ marginTop: '-15px', padding: '10px' }}>
                                                    <span><b>{item.usuario}</b></span>
                                                </div>
                                            }>
                                            {<span className={item.usuario ? 'icon-User dimgrey-color' : ''} style={{ marginLeft: 10 }} ></span>}
                                        </PopoverConsultaLote>



                                    }
                                </span>
                            </div>
                        )
                    },
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Lote',
                    accessor: 'numero_lote',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Referência',
                    accessor: 'referencia',
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'referencia_lote',
                        idColuna: 'referencia',
                        monthPicker: true
                    }
                },
                {
                    Header: 'Operadora Saúde',
                    accessor: 'operadora_saude',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'operadora_saude',
                        idColuna: 'operadora_saude.id'
                    }
                },
                {
                    Header: 'Contrato',
                    accessor: 'contrato',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'contrato_filtro',
                        idColuna: 'guia_lote.contrato_id'
                    },
                },
                {
                    Header: 'Empresa',
                    accessor: 'empresa',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'empresa',
                        idColuna: 'empresa.id'
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    html: item => <OverlayTrigger
                        popperConfig={{
                            modifiers: {
                                preventOverflow: {
                                    enabled: false
                                }
                            }
                        }}
                        id={Math.random()}
                        placement="bottom"
                        delay={{ show: 25, hide: 25 }}
                        overlay={(props) => <Tooltip id={'tooltip ' + props.id ? props.id : 'tooltip ' + Math.random()} {...props}>{item.status}</Tooltip>}>
                        <div className='guia-status-ellipsis' style={{ background: item.cor_status }}>{item.status}</div>
                    </OverlayTrigger>,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'status_lote',
                        idColuna: 'guia_lote_status.id'
                    }
                },
                {
                    Header: 'Status Envio',
                    accessor: 'status_envio',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'status_envio',
                        idColuna: 'guia_lote.status_envio_id'
                    }
                }, {
                    Header: 'Tipo Executante',
                    accessor: 'tipo_executante',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'tipo_executante',
                        idColuna: 'guia_tipo_executante.id'
                    }
                },

                {
                    Header: 'Guias no Lote',
                    accessor: 'qtd_guias_no_lote',
                    ordenamento: {
                        tipo: 'numero'
                    }
                },
                {
                    Header: 'Data de Registro',
                    html: item => <div className='min-width-125' ><span >{item.data_registro}</span></div>,
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    }
                },
                {
                    Header: 'Data Registro Inicial',
                    accessor: 'data_inicial',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'data_registro_inicial',
                        datePicker: true,
                        date: true

                    }
                }, {
                    Header: 'Data Registro Final',
                    accessor: 'data_final',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'data_registro_final',
                        datePicker: true,
                        date: true

                    }
                }, {
                    Header: 'Hora Registro Inicial',
                    accessor: 'hora_inicial_registro',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'hora_inicial_registro',
                        idColuna: 'hora_registro_inicial',
                        timePicker: true
                    }
                }, {
                    Header: 'Hora Registro Final',
                    accessor: 'hora_final_registro',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'hora_final_registro',
                        idColuna: 'hora_registro_final',
                        timePicker: true
                    }
                },
                {
                    Header: 'Usuario Inserção',
                    accessor: 'usuario_insercao',
                    visivel: false,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'usuario_filtro_lote_top',
                        idColuna: ' guia_lote.usuario_registro_id',
                        multiplo:false,
                        valor: { value: usuario.id, label: `${usuario.nome}` },
                    }
                },
            ]
        }], []
    )

    const TrocarStatusLote = ({ target, ...props }) => {
        const [salvando, setSalvando] = useState(false)
        const [item, setItem] = useState({})
        const [carregando, setCarregando] = useState(false)
        const refAtualizacao = useRef(null)
        const [valoresIniciais, setValoresIniciais] = useState({})
        const [showModalValidacao, setShowModalValidacao] = useState(false)
        const [mensagemModal, setMensagemModal] = useState('')

        const [validacao] = useState(Yup.object({
            status: Yup.object().nullable().required(),
            justificativa: Yup.string().required()
        }))

        useEffect(() => {
            const item = props.item
            if (item.id_status) {
                setValoresIniciais({ status: { value: item.id_status, label: item.status } })
            }
            else
                setValoresIniciais({})

        }, [props.item])

        const Submit = (values, propsFormik) => {
            setSalvando(true)
            salvar(
                { id: props.item.id, ...values },
                '/faturamento/lotealterarstatus',
                () => {
                    mostrarToast('sucesso', mensagens.sucesso)
                    props.setAbrirModal(false)
                    target.current.atualizar()
                    setSalvando(false)
                },
                (err) => {
                    setSalvando(false)
                })
        }

        const funcaoAposSim = (formik) => {
            setShowModalValidacao(false)
            Submit(formik.values, formik.resetForm)
        }

        return (
            <>
                <ModalCadastro titulo={props.titulo} show={props.abrirModal} setShowModal={props.setAbrirModal}>
                    <Formik
                        initialValues={valoresIniciais}
                        validationSchema={validacao}
                        enableReinitialize={true}
                        onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                        {propsFormik => (
                            <div ref={refAtualizacao}>
                                <Form>
                                    <div className='modal-body-cadastro'>
                                        <div class="separator mt-20 mb-20"></div>
                                        <div className={'form-group col-12'}>
                                            {carregando === true ? <LoadRelatorio refLoading={refAtualizacao} heigth={320} /> : <>

                                                <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                                                <InputLoteStatus label={<b>Status</b>} name="status" propsFormik={propsFormik} tamanho={'col-lg-12 p-0'}
                                                    classeTooltip={'tooltip-input-modal'} placeholder="Status" autoFocus obrigatorio />
                                                <TextArea label={<b>Justificativa</b>} obrigatorio={true} propsFormik={propsFormik} name='justificativa' type='text' placeholder='Justificativa' tamanho={!props.modal && 'col-lg-12 p-0'} rows={3}
                                                    classeTooltip={'tooltip-input-modal'} />
                                            </>}
                                        </div>
                                    </div>
                                    <div className='separator mt-40 mb-2'></div>

                                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                                        <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                    </div>
                                </Form>
                                <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                                    esconderFechar mensagem={<>{<>{'Validações do Lote:'} <br /><br /></>} {mensagemModal}<br />
                                        {'Deseja Continuar?'}</>}
                                    textoOpcao1={'Cancelar'}
                                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                                    textoOpcao2={'Continuar'} />
                            </div>
                        )}
                    </Formik>
                </ModalCadastro>
            </>
        );
    }

    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: rProps.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/faturamento/lote', propsAcao.row.id,
                    () => {
                        // getApi(setData, '/faturamento/guia')
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const obterDetalhes = async (props) => {
        let { id } = props
        setItemDetalhes({ lote: [{ titulo: 'Carregando...' }] })
        let dados = {}
        dados.lote = [
            { titulo: 'Código', descricao: id },
            { titulo: 'Lote', descricao: props.numero_lote },
            { titulo: 'Referência', descricao: props.referencia },
            // { titulo: 'Data Procedimento', descricao: props.data_procedimento },
        ]

        await Promise.resolve(get(`/faturamento/obterhistoricolotestatus/${id}`)).then(val2 => {
            dados.historico_status = val2;
        })
        await Promise.resolve(get(`/faturamento/envioXmlObterListaRetornoLote/${id}`)).then(val2 => {
            if (val2.length)
                dados.envio = [
                    { titulo: 'Lote', descricao: val2[0].id_lote },
                    { titulo: 'Quantidade Guias', descricao: val2[0].qtd_guias },
                    { titulo: 'Status', descricao: val2[0].status_lote },
                    { titulo: 'Usuário', descricao: val2[0].nome },
                    // { titulo: 'Data Procedimento', descricao: props.data_procedimento },
                ]
            else
                dados.envio = null
        })

        setItemDetalhes(dados)
        setDadosLote({ lote_id: id, operadora_saude_id: props.operadora_saude_id, contrato_id: props.contrato_id })
    }

    const removeAllGuias = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: rProps.modulo,
            acao_id: ACOES.REMOVER_GUIAS
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length) {
                get('/faturamento/validarRemoverGuiasLote', { id: propsAcao.row.id }).then(val => {
                    const guiasEnviadasCoopanest = val.filter(x => x.status_guia == 7)

                    const statusLote = val.filter(x => x.status_lote == 1)

                    if (!guiasEnviadasCoopanest.length > 0 && statusLote.length > 0) {

                        putComAcao(ACOES.REMOVER_GUIAS, rProps.modulo, propsAcao.row, '/faturamento/removerGuiasLote',
                            () => {
                                target.current.atualizar();
                                mostrarToast('sucesso', mensagens['sucesso'])
                            }, (e) => {
                                mostrarToast('erro', mensagens.erro)
                            })

                    } else {
                        mostrarToast('erro', 'Operação Bloqueada! O Status do Lote ou da Guia não permite ação.')

                    }
                })

            } else
                mostrarToast('erro', mensagens['permissao'])


        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const OutrasAcoes = ({ propsAcao, ...props }) => {

        return (
            <Dropdown >
                <DropdownTrigger>
                    <Btn icone='icon-ellipsi' texto="Mais Ações" />
                </DropdownTrigger>
                <DropdownContent>
                    <div className="card-acoes-dropdown">
                        <div className="ajusta-dropdown">
                            {[
                                <Detalhes action={() => {
                                    obterDetalhes(propsAcao.row);
                                    setShowModalDetalhes(true);

                                }} />,
                                <AlteracaoStatusLote action={() => {
                                    setItemTrocarStatus(propsAcao.row)
                                    setModalAlteracaoStatus(true)
                                }} />,
                                propsAcao.row.envia_xml ? <EnviarLoteCoop disabled={disabledButton} action={() => {
                                    setDisabledButton(true)
                                    rProps.obterWebService(JSON.stringify({
                                        "integrationType": "lote",
                                        "key": JSON.stringify({ "ID_LOTE": propsAcao.row.id, "ID_USUARIO": getUsuario().id }),
                                        "usuarioId": getUsuario().id, "moduloId": 108, "acaoId": 32
                                    }), null, _ => setDisabledButton(false))
                                }} /> : undefined,
                                <Link to={{ pathname: '/faturamento/lote/visualizacaoXmlGerado/' + propsAcao.row.id }} target={'_blank'}><VisualizarArquivo action={() => { }} /></Link>,
                                <Link to={{ pathname: '/faturamento/lote/visualizacaoRetornoEnvio/' + propsAcao.row.id }} target={'_blank'}><VisualizarErro action={() => { }} /></Link>,
                                // <Link to={{ pathname: 'relatorios/lote/relatorio/' + propsAcao.row.id }} target={'_blank'}><RelatorioLote action={() => { }} /></Link>,
                                <VisualizarRelacaoGuias action={() => history.push({ pathname: '/relatorios/lote/relatorio', state: { idLote: propsAcao.row.id, item: propsAcao.row } })} />,
                                <RelatorioLote action={() => history.push({ pathname: '/relatorios/lote/relatorio', state: { idLote: propsAcao.row.id, item: propsAcao.row } })} />,
                                <RemoverGuias action={() => {
                                    removeAllGuias(propsAcao)

                                }} />,

                                propsAcao?.row?.guia_lote_status_id !== 7 ?
                                    <AdicionarGuiaLote action={() => {
                                        setShowModalAdicionarGuia(true)
                                        obterDetalhes(propsAcao.row);
                                    }} /> : <></>
                            ]}
                        </div>
                    </div>
                </DropdownContent>
            </Dropdown>
        )


    }


    const acoesObj = [
        (propsAcao) => <Visualizar to={'/faturamento/lote/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/faturamento/lote/alteracao/' + propsAcao.row.id} />,
        (propsAcao) => <Excluir to={'faturamento/lote/consulta/'} action={() => exclusao(propsAcao)} />,
        (propsAcao) => <OutrasAcoes propsAcao={propsAcao} />
    ]

    const acoesObj2 = [
        (propsAcao) => <Visualizar to={'/faturamento/lote/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/faturamento/lote/alteracao/' + propsAcao.row.id} />,
        (propsAcao) => <Excluir to={'faturamento/lote/consulta/'} action={() => exclusao(propsAcao)} />,
        (propsAcao) => <Detalhes action={() => {
            obterDetalhes(propsAcao.row);
            setShowModalDetalhes(true);

        }} />,
        (propsAcao) => <AlteracaoStatusLote action={() => {
            setItemTrocarStatus(propsAcao.row)
            setModalAlteracaoStatus(true)
        }} />,
        (propsAcao) => propsAcao.row.envia_xml ? <EnviarLoteCoop disabled={disabledButton} action={() => {
            setDisabledButton(true)
            rProps.obterWebService(JSON.stringify({
                "integrationType": "lote",
                "key": JSON.stringify({ "ID_LOTE": propsAcao.row.id, "ID_USUARIO": getUsuario().id }),
                "usuarioId": getUsuario().id, "moduloId": 108, "acaoId": 32
            }), null, _ => setDisabledButton(false))
        }} /> : undefined,
        (propsAcao) => <Link to={{ pathname: '/faturamento/lote/visualizacaoXmlGerado/' + propsAcao.row.id }} target={'_blank'}><VisualizarArquivo action={() => { }} /></Link>,
        (propsAcao) => <Link to={{ pathname: '/faturamento/lote/visualizacaoRetornoEnvio/' + propsAcao.row.id }} target={'_blank'}><VisualizarErro action={() => { }} /></Link>,
        (propsAcao) => <VisualizarRelacaoGuias action={() => { }} />,
        (propsAcao) =>
            // parseInt(propsAcao.row.qtd_guias_no_lote) > 0 ?
            <RemoverGuias action={() => {
                removeAllGuias(propsAcao)

            }} />,
        (propsAcao) => propsAcao.row.guia_lote_status_id !== 7 ?
            // parseInt(propsAcao.row.qtd_guias_no_lote) > 0 ?
            <AdicionarGuiaLote action={() => {
                setShowModalAdicionarGuia(true)
                obterDetalhes(propsAcao.row);
            }} />
            : <></>
    ]

    return (
        <>
            <div>
                <DataGridAlternativa target={target} data={data} acoesObj={width <= 1822 ? acoesObj2 : acoesObj} columns={columns} setData={setData} acoes={true}
                    nomeSession={'ultimos_filtros_lote'} filtroAtivo={true}
                    caminhoConsultaFiltros='/faturamento/lotepaginacao' modulo={rProps.modulo} gerarLotes={() => setShowModalGerarLotes(true)}
                    colunaStatus={'status'} idStatus={'id_status'} corStatus={'cor_status'} titulo={'Consulta Lote'} tituloLista={'Lista Lote'}
                    urlCadastro='/faturamento/lote/cadastro' icone={'icon-Big-Data'} inicializarFiltrado = {true} />
                <DetalhesLote titulo={'Detalhes'} data={itemDetalhes} showModal={showModalDetalhes} setShowModal={setShowModalDetalhes} />
                <ModalGerarLotes showModalGerarLotes={showModalGerarLotes} setShowModalGerarLotes={setShowModalGerarLotes} target={target} history={history} rProps={rProps} />
                <TrocarStatusLote target={target} titulo={'Alteração de Status Lote'} abrirModal={modalAlteracaoStatus} setAbrirModal={setModalAlteracaoStatus} item={itemTrocarStauts} />
                <ModalAdicionarGuia target={target} titulo={'Adicionar Guia ao Lote'} showModal={showModalAdicionarGuia} setShowModal={setShowModalAdicionarGuia} data={itemDetalhes} dadosLote={dadosLote} />
            </div>
        </>
    )



}

const ModalAdicionarGuia = ({ target, ...props }) => {
    const [itemDetalhes, setItemDetalhes] = useState({});
    const [salvando, setSalvando] = useState(false)
    const [validacao] = useState(Yup.object({
        guia: Yup.object().nullable().required(),
    }))

    useEffect(() => {
        setItemDetalhes(props.data)
    }, [props.data])

    const objPermissao = {
        usuario_id: getUsuario().id,
        modulo_id: 35,
        acao_id: ACOES.ADICIONAR_GUIA
    }

    const Submit = (values, propsFormik) => {

        setSalvando(true)
        values.lote_id = props.dadosLote.lote_id

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length) {
                putComAcao(ACOES.ADICIONAR_GUIA, MODULO["/faturamento/lote"], values, '/faturamento/adicionarGuiaLote',
                    () => {
                        mostrarToast('sucesso', mensagens.sucesso)
                        target.current.atualizar();
                        props.setShowModal(false)
                        setSalvando(false)
                    },
                    (erro) => {
                        console.log('erro: ', erro);
                        setSalvando(false)
                    })

            } else {
                mostrarToast('erro', mensagens['permissao'])
                setSalvando(false)
            }

        }).catch((e) => mostrarToast('erro', mensagens['erro']))




    }

    return (

        <>
            <ModalCadastro titulo={props.titulo} show={props.showModal} setShowModal={props.setShowModal}  >
                <Formik
                    initialValues={{ guia: undefined }}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <div className='detalhes'>
                                        {itemDetalhes.lote && itemDetalhes.lote.map((item, idx) => {
                                            return (
                                                <>
                                                    {idx === 0 ? <></> : <hr />}
                                                    <div className='item-detalhes'>
                                                        <h6><b>{item.titulo}</b></h6>
                                                        <h6>{item.descricao}</h6>
                                                        {item.html}
                                                    </div></>
                                            )
                                        })}
                                    </div>

                                </div>
                                <div className='separator mt-40 mb-2'></div>
                                <div className={'form-group col-12'}>
                                    <InputGuiaLote label='Guia' tamanho={'col-lg-12 p-0'} name='guia' placeholder='Guia' propsFormik={propsFormik} dadosLote={props?.dadosLote}
                                        classeTooltip={'tooltip-input-modal'} obrigatorio autoFocus />
                                </div>
                                <div className='separator mt-40 mb-2'></div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro >

        </>
    )
}

export default Consulta