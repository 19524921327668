import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import { consultar } from '../../../util/AcessoApi'
import moment from 'moment'
import { useHistory } from "react-router-dom";

const ListagemOcorrencia = (props) => {
    const history = useHistory();
    const [data, setData] = useState([])
    const [atualizado, setAtualizado] = useState(false)
    const dataCompartilhada = (history.location.state && history.location.state.data) ? moment(history.location.state.data, 'DD/MM/YYYY').format("DD/MM/YYYY") : moment(new Date()).format("DD/MM/YYYY")

    useEffect(() => {
        consultar('/administracao/socio-ocorrencia', (dados) => {
            setData(dados.sort((a, b) => b.id - a.id));
            setAtualizado(true);
        }, {}, { data: dataCompartilhada })
    }, [])

    return (

        atualizado > 0 ? <Redirect push to={{
            pathname: '/listagem',
            listagem: {
                dados: [{
                    agrupamento: '',
                    colunas: [{
                        label: 'Código',
                        name: 'id',
                        alinhamento: 'text-left',
                        visivel: true
                    },
                    {
                        label: 'Sócio',
                        name: 'nome',
                        alinhamento: 'text-left',
                        visivel: true
                    },
                    {
                        label: 'Tipo Ocorrência',
                        name: 'tipo_ocorrenca',
                        alinhamento: 'text-left',
                        visivel: true
                    },
                    {
                        label: 'Data Inicial',
                        name: 'data_inicial',
                        alinhamento: 'text-left',
                        visivel: true
                    },
                    {
                        label: 'Data Final',
                        name: 'data_final',
                        alinhamento: 'text-left',
                        visivel: true
                    },
                    ],
                    data: data
                }],
                titulo: 'Lista Ocorrências',
                goBackAlternativo: true,
                filtros: undefined,
                filtrosObj: undefined,
                filtrosValoresIniciais: [],
                dadosFiltrados: data,
                tituloData: dataCompartilhada
            }
        }} /> : <></>
    )
}

export default ListagemOcorrencia