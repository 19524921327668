import React, { useState, useEffect } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'

const Consulta = (props) => {
    const [data, setData] = useState([])

    useEffect(() => {
        consultar('/administracao/departamento', (dados) => setData(dados.sort((a, b) => b.id - a.id)))
    }, [])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-departamento',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo:true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Descrição',
                    accessor: 'descricao',
                    ordenamento: {
                        tipo: 'texto',
                    }
                },
                {
                    Header: 'Data de Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data',
                        idColuna: "data_registro",
                        datePicker: true,
                        date: true
                    }
                }
            ]
        }], []
    )

    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} setData={setData} 
                caminhoConsultaFiltros= '/administracao/departamentopaginacao'
                acoes={true} caminhoBase='/administracao/departamento' modulo={props.modulo} titulo={'Consulta Departamento'} 
                tituloLista={'Lista Departamento'}
                urlCadastro='/administracao/departamento/cadastro' icone='icon-Geo3-' />
            </div>
        </>
    )
}

export default Consulta