import React, { useState, useEffect } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import { InputTipoRelatorio, InputEstabelecimentoSaude, InputSimNao, InputOperadoraSaude, InputProcedimento, InputSocio, InputSocioAtivos, InputStatusAgendamento, InputCirurgiaoCombo, InputTipoRelatorioPersonalizado } from '../../../components/InputsAutoComplete'
import { InputDatePicker, InputMonthPicker, InputTimePicker } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes'
import { getUsuario } from '../../../util/Configuracoes'
import moment from "moment"
import { InputClienteFinanceiroV3, InputEmpresa, InputStatusEnvioNota, InputStatusNota } from '../../../components/InputsAutoCompleteV2';
import { convertValueToFloat, formatarMoeda } from '../../../util/Utilitarios';
import { MODULOS_SISTEMA } from '../../../util/Enums';

export default props => {
    const startOfMonth = moment().clone().format('DD/MM/YYYY')
    const endOfMonth = moment().clone().format('DD/MM/YYYY')
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Agenda/Central de Reserva')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({})
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)
    const user = getUsuario()
    // const objPermissao = {
    //     usuario_id: getUsuario().login,
    //     modulo_id: props.modulos,
    //     acao_id: ACOES.CONSULTAR
    // }
    const tipoRelatorio = [
        { label: 'Notas Fiscais', value: MODULOS_SISTEMA.RELATORIO_DE_NOTAS_FISCAIS },
        { label: 'DMS - Relatório de Conferência', value: MODULOS_SISTEMA.RELATORIO_DMS },
    ]

    const [validacao, setValidacao] = useState(
        Yup.object().shape({
            data_inicial: Yup.string().when(['data_final', 'data_emissao_inicial', 'data_emissao_final', 'data_emissao_referencia'], {
                is: (data_final, data_emissao_inicial, data_emissao_final, data_emissao_referencia) => {
                    return !data_final && !data_emissao_inicial && !data_emissao_final && !data_emissao_referencia;
                },
                then: Yup.string().required()
            }),
            data_final: Yup.string().when(['data_inicial', 'data_emissao_inicial', 'data_emissao_final', 'data_emissao_referencia'], {
                is: (data_inicial, data_emissao_inicial, data_emissao_final, data_emissao_referencia) => {
                    return !data_inicial && !data_emissao_inicial && !data_emissao_final && !data_emissao_referencia;
                },
                then: Yup.string().required()
            }),
            data_emissao_inicial: Yup.string().when(['data_inicial', 'data_final', 'data_emissao_final', 'data_emissao_referencia'], {
                is: (data_inicial, data_final, data_emissao_final, data_emissao_referencia) => {
                    return !data_inicial && !data_final && !data_emissao_final && !data_emissao_referencia;
                },
                then: Yup.string().required()
            }),
            data_emissao_final: Yup.string().when(['data_inicial', 'data_final', 'data_emissao_inicial', 'data_emissao_referencia'], {
                is: (data_inicial, data_final, data_emissao_inicial, data_emissao_referencia) => {
                    return !data_inicial && !data_final && !data_emissao_inicial && !data_emissao_referencia;
                },
                then: Yup.string().required()
            }),
            data_emissao_referencia: Yup.string().when(['data_inicial', 'data_final', 'data_emissao_inicial', 'data_emissao_final'], {
                is: (data_inicial, data_final, data_emissao_inicial, data_emissao_final) => {
                    return !data_inicial && !data_final && !data_emissao_inicial && !data_emissao_final;
                },
                then: Yup.string().required()
            })
        }, [
            ['data_inicial', 'data_final'],
            ['data_inicial', 'data_emissao_inicial'],
            ['data_inicial', 'data_emissao_final'],
            ['data_inicial', 'data_emissao_referencia'],
            ['data_final', 'data_emissao_inicial'],
            ['data_final', 'data_emissao_final'],
            ['data_final', 'data_emissao_referencia'],
            ['data_emissao_inicial', 'data_emissao_final'],
            ['data_emissao_inicial', 'data_emissao_referencia'],
            ['data_emissao_final', 'data_emissao_referencia']
        ])
    )

    const relatoriosPorTipo = propsFormik => [
        {
            titulo: 'Relatório Notas Fiscais',
            tipo: MODULOS_SISTEMA.RELATORIO_DE_NOTAS_FISCAIS,
            url: '/relatorio/notafiscal',
            numerarLinha: true,
            aposRelatorio: calculoValorTotalFinal,

            campos: [
                <InputMonthPicker label="Emissão Referência" name="data_emissao_referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_emissao_referencia", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputEmpresa label="Prestador/Empresa" placeholder="Prestador/Empresa" name="empresa" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputClienteFinanceiroV3 label="Tomador/Cliente" placeholder="Tomador/Cliente" name="cliente" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputDatePicker label="Data Registro Inicial" name="data_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro Final" name="data_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Emissão Inicial" name="data_emissao_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_emissao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Emissão Final" name="data_emissao_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_emissao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputStatusNota label="Status NFSe" placeholder="Status NFSe" name="status_nota" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputStatusEnvioNota label="Status Envio" placeholder="Status Envio" name="status_envio" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
            ],
            classeTr: 'linha-bottom-relatorio',
            colunas: [
                { label: 'Cód', name: 'id', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'N° Nota', name: 'numero', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Registro', name: 'data_registro_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false, } },
                { label: 'Cliente/Tomador', name: 'tomador', visivel: true, ordenamento: { tipo: 'texto', ativo: false, } },
                { label: 'CPF/CNPJ', name: 'cpf_cnpj', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Prestador', name: 'prestador', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Emissão', name: 'data_emissao_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false } },
                { label: 'Valor Serviços', name: 'valor_nota_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor Tributos', name: 'valor_tributo_formatado', true: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor Dedução', name: 'valor_deducao_formatado', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor Desconto', name: 'valor_desconto_formatado', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status NFSe', name: 'status_nota', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status Envio', name: 'status_envio', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Cancelada ?', name: 'cancelada', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
            ],
            data: [],
            agrupado: false,
            colunasCsv: [
                { label: 'N° Nota', name: 'numero', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'Data Registro', name: 'data_registro_formatada', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cliente/Tomador', name: 'tomador', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'CPF/CNPJ', name: 'cpf_cnpj', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Prestador', name: 'prestador', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Data Emissão', name: 'data_emissao_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Valor Serviços', name: 'valor_nota_formatado', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Valor Tributos', name: 'valor_tributo_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Dedução', name: 'valor_deducao_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Valor Desconto', name: 'valor_desconto_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Status NFSe', name: 'status_nota', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Status Envio', name: 'status_envio', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cancelada ?', name: 'cancelada', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
            ],
            colunasFiltros: [
                { label: 'Prestador/Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Tomador/Cliente', name: 'cliente_id', name_coluna: 'cliente' },
                { label: 'Data Inicial', name: 'data_inicial', name_coluna: 'data_inicial' },
                { label: 'Data Final', name: 'data_final', name_coluna: 'data_final' },
                { label: 'Referência Emissão', name: 'data_emissao_referencia', name_coluna: 'data_emissao_referencia' },
                { label: 'Data Emissão Inicial', name: 'data_emissao_inicial', name_coluna: 'data_emissao_inicial' },
                { label: 'Data Emissão Final', name: 'data_emissao_final', name_coluna: 'data_emissao_final' },
                { label: 'Status Envio', name: 'nota_fiscal_status_envio_id', name_coluna: 'status_envio' },
                { label: 'Status Nota', name: 'nota_fiscal_status_id', name_coluna: 'status_nota' },
            ]
        },
        {
            titulo: 'DMS - Relatório de Conferência',
            tipo: MODULOS_SISTEMA.RELATORIO_DMS,
            url: '/relatorio/dmsnotafiscal',
            aposAgrupamento: calculoValorTotal,
            campos: [
                <InputMonthPicker label="Emissão Referência" name="data_emissao_referencia" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_emissao_referencia", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputEmpresa label="Prestador/Empresa" placeholder="Prestador/Empresa" name="empresa" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                <InputClienteFinanceiroV3 label="Tomador/Cliente" placeholder="Tomador/Cliente" name="cliente" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputDatePicker label="Data Registro Inicial" name="data_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Registro Final" name="data_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Emissão Inicial" name="data_emissao_inicial" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_emissao_inicial", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputDatePicker label="Data Emissão Final" name="data_emissao_final" type="text"
                    setarValor={value => { propsFormik.setFieldValue("data_emissao_final", value) }} modal classeTooltip={'tooltip-input-modal'} />,
                <InputStatusNota label="Status NFSe" menuPlacement={'top'} placeholder="Status NFSe" name="status_nota" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
                <InputStatusEnvioNota label="Status Envio" menuPlacement={'top'} placeholder="Status Envio" name="status_envio" propsFormik={propsFormik}
                    classeTooltip={'tooltip-input-modal'} multiplo />,
            ],
            classeTr: 'linha-bottom-relatorio',
            agrupamento: [{ coluna: 'prestador', descricao: 'empresa_descricao' }],
            repetirColunas: true,
            agrupado: false,
            numerarLinha: false,
            colunas: [
                { label: 'Cód', name: 'id', visivel: false, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Dia', name: 'dia_emissao', visivel: true, ordenamento: { tipo: 'numero', ativo: false } },
                { label: 'N° Nota', name: 'numero', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'N° RPS', name: 'numero_rps', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Cliente/Tomador', name: 'tomador', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CPF/CNPJ', name: 'cpf_cnpj', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Município Tomador', name: 'municipio', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Modalidade ISS', name: 'iss_modalidade', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Status NFSe', name: 'status_nota', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor Serviços R$', name: 'valor_nota_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Base de Cálculo R$', name: 'servico_valor_base_calculo_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'IRF R$', name: 'valor_irf', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'PIS R$', name: 'valor_pis', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'COFINS R$', name: 'valor_cofins', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'CSLL R$', name: 'valor_csll', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
            ],
            colunasCsv: [
                { label: 'Dia', name: 'dia_emissao', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'N° Nota', name: 'numero', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                { label: 'N° RPS', name: 'numero_rps', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Cliente/Tomador', name: 'tomador', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'CPF/CNPJ', name: 'cpf_cnpj', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Município Tomador', name: 'municipio', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Prestador', name: 'prestador', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'Modalidade ISS', name: 'iss_modalidade', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Data Emissão', name: 'data_emissao_formatada', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Valor Serviços R$', name: 'valor_nota_formatado', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                { label: 'Base de Cálculo R$', name: 'servico_valor_base_calculo_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'IRF R$', name: 'valor_irf', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'PIS R$', name: 'valor_pis', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'COFINS R$', name: 'valor_cofins', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                { label: 'CSLL R$', name: 'valor_csll', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Prestador/Empresa', name: 'empresa_id', name_coluna: 'empresa' },
                { label: 'Tomador/Cliente', name: 'cliente_id', name_coluna: 'cliente' },
                { label: 'Data Inicial', name: 'data_inicial', name_coluna: 'data_inicial' },
                { label: 'Data Final', name: 'data_final', name_coluna: 'data_final' },
                { label: 'Referência Emissão', name: 'data_emissao_referencia', name_coluna: 'data_emissao_referencia' },
                { label: 'Data Emissão Inicial', name: 'data_emissao_inicial', name_coluna: 'data_emissao_inicial' },
                { label: 'Data Emissão Final', name: 'data_emissao_final', name_coluna: 'data_emissao_final' },
                { label: 'Status Envio', name: 'nota_fiscal_status_envio_id', name_coluna: 'status_envio' },
                { label: 'Status Nota', name: 'nota_fiscal_status_id', name_coluna: 'status_nota' },
            ]
        }
    ]

    const submit = (values, propsFormik) => {
        setCarregando(true)
        let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)
        for (let i = Object.keys(values).length; i > 0; i--) {
            if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || (!values[Object.keys(values)[i]] && !Object.keys(values)[i]?.includes('data')))
                delete values[Object.keys(values)[i]]

        }

        setShowModalFiltros(false)
        // if ([1, 2].includes(values.tipo_relatorio?.value)) {
        //     filtrosObrigatorios = {
        //         data_inicial: '', data_final: '', data_emissao_inicial: '', data_emissao_final: '', data_emissao_referencia: moment().format('MM/yyyy')
        //     }
        // }
        setValuesFiltros(values)
        setRelatorioAtual({ ...novoRelatorio })

        if (values.tipo_relatorio) {
            setFiltros(Object.keys(values).map((x, idx, array) => {

                const valorAtual = values[x]

                return (x !== 'tipo_relatorio'
                    && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value) && valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                {
                    coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                    filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                    name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                    value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                    dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                })
            }).filter(x => x.coluna !== false && x.value))
        }
        else {
            setRelatorioAtual({})
            setCarregando(false)
            setDados([])
            setFiltros([])
        }
    }


    const calculoValorTotal = (dadosAgr, arrayConfigCol) => {
        const valorTotalNota = dadosAgr && dadosAgr.filter(item => item.valor_nota).map(x => parseFloat(x.valor_nota)).reduce((ant, atual) => ant + atual, 0);
        const valorTotalBaseCalculo = dadosAgr && dadosAgr.filter(item => item.servico_valor_base_calculo_formatado).map(x => convertValueToFloat(x.servico_valor_base_calculo_formatado)).reduce((ant, atual) => ant + atual, 0);
        const valorTotalIrf = dadosAgr && dadosAgr.filter(item => item.valor_irf).map(x => convertValueToFloat(x.valor_irf)).reduce((ant, atual) => ant + atual, 0);
        const valorTotalPis = dadosAgr && dadosAgr.filter(item => item.valor_pis).map(x => convertValueToFloat(x.valor_pis)).reduce((ant, atual) => ant + atual, 0);
        const valorTotalCofins = dadosAgr && dadosAgr.filter(item => item.valor_cofins).map(x => convertValueToFloat(x.valor_cofins)).reduce((ant, atual) => ant + atual, 0);
        const valorTotalCsll = dadosAgr && dadosAgr.filter(item => item.valor_csll).map(x => convertValueToFloat(x.valor_csll)).reduce((ant, atual) => ant + atual, 0);
        let valoresTotais = [valorTotalNota, valorTotalBaseCalculo, valorTotalIrf, valorTotalPis, valorTotalCofins, valorTotalCsll]
        return (dadosAgr && dadosAgr?.length > 0 && arrayConfigCol?.colunas?.filter(x => x.name === 'valor_nota_formatado' && x.visivel).length > 0 ? <>
            <tr>
                <td colspan={arrayConfigCol?.colunas.filter(x => x.visivel).length}>
                    <table>
                        <tr><td className={'td-data pr-0'}></td></tr>
                        <tr className={'tr-data text-right bold bt-1'}>
                            <td className={'td-data pr-0 text-right'}><span>Quantidade: </span></td>
                            <td className={'td-data'} width={'10%'}>{dadosAgr.length}</td>
                        </tr>
                        <tr><td className={'td-data pr-0'}></td></tr>
                        <tr className={'tr-data text-right bold bt-1'}>
                            <td className={'td-data pr-0 text-right'}><span>Total Valor Base Cálculo R$: </span></td>
                            <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalBaseCalculo)}</td>
                        </tr>
                        <tr className={'tr-data text-right bold bt-1'}>
                            <td className={'td-data pr-0 text-right'}><span>Total Valor IRF R$: </span></td>
                            <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalIrf)}</td>
                        </tr>
                        <tr className={'tr-data text-right bold bt-1'}>
                            <td className={'td-data pr-0 text-right'}><span>Total Valor PIS R$: </span></td>
                            <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalPis)}</td>
                        </tr>
                        <tr className={'tr-data text-right bold bt-1'}>
                            <td className={'td-data pr-0 text-right'}><span>Total Valor COFINS R$: </span></td>
                            <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalCofins)}</td>
                        </tr>
                        <tr className={'tr-data text-right bold bt-1'}>
                            <td className={'td-data pr-0 text-right'}><span>Total Valor CSLL R$: </span></td>
                            <td className={'td-data'} width={'10%'}>{formatarMoeda(valorTotalCsll)}</td>
                        </tr>

                    </table>
                </td>
            </tr>
        </> : <></>)
    }

    const calculoValorTotalFinal = (dadosAgr, arrayConfigCol) => {
        const valorTotal = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.valor_nota_formatado).map(x => convertValueToFloat(x.valor_nota_formatado)).reduce((ant, atual) => ant + atual, 0);

        return (dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.length > 0 && arrayConfigCol[0].filter(x => x.coluna === 'valor_nota_formatado' && x.visivel).length > 0 ? <>
            <table>
                <tr><td colspan={dadosAgr[0].colunas ? arrayConfigCol[0].filter(x => x.visivel).length : 0} className={'td-data pr-0'}></td></tr>
                <tr className={'tr-data text-right bold bt-1 bt-black-1'}>
                    <td colspan={1} className={'td-data pr-0'}><span>Valor Total: </span><span className={'ml-2'}>{formatarMoeda(valorTotal)}</span></td>
                </tr>
            </table>
        </> : <></>)
    }

    useEffect(() => {

        let cloneRelatorioAtual = { ...relatorioAtual }

        const newFiltro = [...filtros]

        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{ numerarLinha: cloneRelatorioAtual?.numerarLinha || false, colunas: cloneRelatorioAtual.colunas, data: cloneRelatorioAtual.data, classeTr: cloneRelatorioAtual.classeTr, colunasCsv: cloneRelatorioAtual.colunasCsv ? cloneRelatorioAtual.colunasCsv : null, aposRelatorio: cloneRelatorioAtual.aposRelatorio, aposAgrupamento: cloneRelatorioAtual.aposAgrupamento, agrupado: cloneRelatorioAtual?.agrupado || false }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: newFiltro, usuario_tipo: user.tipo, usuario_id: user.id })
    }, [filtros])

    const changeTipo = (value) => {
        let filtrosObrigatorios

        if ([MODULOS_SISTEMA.RELATORIO_DE_NOTAS_FISCAIS, MODULOS_SISTEMA.RELATORIO_DMS].includes(value?.value)) {
            filtrosObrigatorios = {
                data_inicial: '', data_final: '', data_emissao_inicial: '', data_emissao_final: '', data_emissao_referencia: moment().format('MM/yyyy'), status_nota: value?.value === MODULOS_SISTEMA.RELATORIO_DMS ? [{ value: 3, label: 'Emitida' }, { value: 7, label: 'Substituta' }] : null
            }
        }

        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({})
        // const index = tipoRelatorio.findIndex(x => x.value === value)

        setValuesFiltros({ tipo_relatorio: value, ...filtrosObrigatorios })
        setRelatorioSelecionado(value?.value)
    }

    return (
        < ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros}
            loading={carregando} repetirColunas={relatorioAtual && relatorioAtual.repetirColunas} agrupamento={relatorioAtual && relatorioAtual.agrupamento ? relatorioAtual.agrupamento : undefined} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })
            }>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        <InputTipoRelatorioPersonalizado label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                            propsFormik={propsFormik} modulos={relatoriosPorTipo(propsFormik).map(x => x.tipo)} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} />
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}
                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}