import React, { useState, useEffect, useRef } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'
import { Alterar, Excluir, Visualizar, Imprimir, BtnAcao, Btn, Detalhes, AlteracaoStatusLote, EnviarLoteCoop, VisualizarArquivo, VisualizarErro, VisualizarRelacaoGuias } from '../../../../components/Acoes'
import { BotaoPadrao } from '../../../../components/Botoes'
import moment from 'moment';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES } from '../../../../util/Enums'
import { get, excluir, salvar, validar } from '../../../../util/AcessoApi'
import { mostrarToast } from '../../../../components/Toasts'
import { mensagens, useWindowSize } from '../../../../util/Utilitarios.js'
import { InputPadrao, TextArea } from '../../../../components/Inputs'
import { InputLoteStatus } from '../../../../components/InputsAutoComplete'
import Yup from '../../../../util/Validacoes';
import { ModalCadastro, ModalDuasOpcoes } from '../../../../components/Modais'
import { Formik, Form } from "formik";
import { LoadRelatorio } from '../../../../components/Loading'
import { useHistory, Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';



const ConsultaCliente = rProps => {
    const [data, setData] = useState([])
    const target = useRef(null)
    const [modalAlteracaoStatus, setModalAlteracaoStatus] = useState(false)
    const [itemTrocarStauts, setItemTrocarStatus] = useState({})
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [itemDetalhes, setItemDetalhes] = useState([])
    const [width] = useWindowSize()
    const history = useHistory()


    // useEffect(_ => { consultar('/faturamento/lote', dados => setData(dados.sort((a, b) => b.id - a.id))) }, [rProps])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-lote',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Sigla/Fantasia/Nome Social',
                    accessor: 'nome_social_fantasia_sigla',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Nome/Razão Social',
                    accessor: 'nome',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'CPF/CNPJ',
                    accessor: 'cpf_cnpj',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Tipo Cliente',
                    accessor: 'tipo_cliente',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'tipo_cliente',
                        idColuna: 'cliente.id_tipo_cliente'
                    }
                },
                {
                    Header: 'Tipo Pessoa',
                    accessor: 'tipo_pessoa',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'tipo_executante',
                        idColuna: 'cliente.id_tipo_pessoa'
                    }
                },
                {
                    Header: 'Município',
                    accessor: 'municipio',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    visivel: false,
                    filtro: {
                        tipo: 'municipio_go_primeiro',
                        idColuna: 'municipio.codigo_ibge'
                    }
                },
                {
                    Header: 'Data Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    },
                },
                {
                    Header: 'Data Registro Inicial',
                    accessor: 'data_inicial',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'data_registro_inicial',
                        datePicker: true,
                        date: true

                    }
                }, {
                    Header: 'Data Registro Final',
                    accessor: 'data_final',
                    visivel: false,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'data_registro_final',
                        datePicker: true,
                        date: true

                    }
                },
                // {
                //     Header: 'Hora Registro Inicial',
                //     accessor: 'hora_inicial_registro',
                //     visivel: false,
                //     ordenamento: {
                //         tipo: 'data'
                //     },
                //     filtro: {
                //         tipo: 'hora_inicial_registro',
                //         idColuna: 'hora_registro_inicial',
                //         timePicker: true
                //     }
                // }, {
                //     Header: 'Hora Registro Final',
                //     accessor: 'hora_final_registro',
                //     visivel: false,
                //     ordenamento: {
                //         tipo: 'data'
                //     },
                //     filtro: {
                //         tipo: 'hora_final_registro',
                //         idColuna: 'hora_registro_final',
                //         timePicker: true
                //     }
                // },
            ]
        }], []
    )

    const acoesObj = [
        (propsAcao) => <Visualizar to={'/financeiro/cliente/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <Alterar to={'/financeiro/cliente/alteracao/' + propsAcao.row.id} />,
        (propsAcao) => <Excluir to={'financeiro/cliente/consulta/'} action={() => exclusao(propsAcao)} />,
    ]

    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: rProps.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length) {
                get('/financeiro/validaExclusaoCliente', { id: propsAcao.row.id }).then(function (val) {
                    if (!val.length) {
                        excluir('/financeiro/cliente', propsAcao.row.id,
                            () => {
                                // getApi(setData, '/faturamento/guia')
                                target.current.atualizar();
                                mostrarToast('sucesso', mensagens['sucesso'])
                            },
                            (e) => {
                                if (e.response && e.response.data && e.response.data.erros)
                                    e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                                else
                                    mostrarToast('erro', mensagens.erro)
                            }, propsAcao.row.descricao_auditoria)
                    } else {
                        mostrarToast('erro', 'Ação não permitida! Cliente ligado a um Movimento ou Lançamento')
                    }

                }).catch((e) => mostrarToast('erro', mensagens['erro']))
            } else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    return (
        <>
            <div>
                <DataGridAlternativa target={target} acoesObj={acoesObj} data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/financeiro/cliente'
                    caminhoConsultaFiltros='/financeiro/clientepaginacao' modulo={rProps.modulo} titulo={'Consulta Cliente'} tituloLista={'Lista Cliente'}
                    urlCadastro='/financeiro/cliente/cadastro' icone={'icon-Business-ManWoman'} />
            </div>
        </>
    )

}

export default ConsultaCliente