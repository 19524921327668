import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { visualizar } from '../../../../util/AcessoApi'
import { obterDataFormatada } from '../../../../util/Utilitarios'
import { ordenarArrayObj, downloadArquivoS3 } from '../../../../util/FuncoesComuns'

const VisualizacaoGrupoEscala = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState(null)


    useEffect(() => {
        if (id) {
            visualizar(`/administracao/grupo-escala`, id, obj => {
                
                setDados(obj)
            })
        }
    }, [id])


    const data = dados && dados.descricao ? {
        id,
        modulo: props.modulo,
        titulo: 'Visualização Grupo Escala',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Descricao',
                        descricao: dados.descricao,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Observacao',
                        descricao: dados.observacao,
                        tamanho: 'col-lg-6'
                    }
                ]
            },
            {
                tipoGrupo: 'tabela',
                titulo: 'Sócio(s)',
                thead:
                    <tr>
                        <td className='coluna-3'>Sócio</td>
                        <td className='coluna-3'>Trabalha final de semana?</td>
                        <td className='coluna-3'>Ordem</td>
                        <td className='coluna-3'>Observação</td>

                    </tr>,
                tbody: dados.socios == undefined || dados.socios.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    dados.socios.map(socio =>
                        <tr>
                            <td>{socio.socio.label}</td>
                            <td>{socio.trabalha_final_de_semana.label}</td>
                            <td>{socio.ordenamento}</td>
                            <td>{socio.observacao}</td>
                        </tr>)
            }
        ]
    } : { titulo: 'Visualização Grupo Escala' }


    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoGrupoEscala