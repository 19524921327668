import React, { useState, useEffect } from 'react'
import DataGrid from '../../../../components/DataGrid'
import { consultar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'

const Consulta = (props) => {
    const [data, setData] = useState([])

    useEffect(() => { consultar('/controleAcesso/grupo', dados => setData(dados.sort((a, b) => b.id - a.id))) }, [])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-grupo',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Descrição',
                    accessor: 'descricao',
                    ordenamento: {
                        tipo: 'texto',
                    }
                },
                {
                    Header: 'Data de Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data',
                    }
                }
            ]
        }], []
    )

    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/controleAcesso/grupo' 
                tituloLista={'Lista Grupo'} caminhoConsultaFiltros= '/controleAcesso/grupopaginacao'
                modulo={props.modulo} titulo={'Consulta Grupo'} urlCadastro='/controleAcesso/grupo/cadastro' icone={'icon-Business-ManWoman'} />
            </div>
        </>
    )
}

export default Consulta