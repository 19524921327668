import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, FieldArray, FastField } from "formik";
import { BotaoPadrao } from '../../../../components/Botoes'
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { ModalDuasOpcoes, ModalEscolha } from '../../../../components/Modais';
import { get, salvar, validar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao, InputDatePicker, TextArea, InputTimePicker, InputSwitch, InputTelefone, InputValor, InputSwitchSemFormik, InputValorNovo, InputPadraoSimples, InputMascaraSimples, ControlledInput, InputCurrency, InputCpfCnpj } from '../../../../components/Inputs'
import { InputEntidade, InputSimNao, InputContaBancaria, InputCategoriaAlternativo, InputContaBancariaCliente } from '../../../../components/InputsAutoComplete'
import '../../../../assets/css/icones/iconsmind/style.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Fechamento, ValorRecebido } from './Tab';
import CadastroLancamentoFinanceiro, { Lancamento } from '../../lancamento/pages/Cadastro'
import Yup, { validaData } from '../../../../util/Validacoes';
import { convertValueToFloat, formatarMoeda, mensagens } from '../../../../util/Utilitarios'
import Atalho from '../../../../util/Atalho'
import InputArquivoS3 from '../../../../components/InputArquivoS3';
import { MODULO } from '../../../../util/Enums';
import moment from 'moment';
import Rascunho from '../../../../components/Rascunho';
import { useHistory } from 'react-router-dom'
import useIdle from '../../../../components/Idle'


const CadastroMovimentoFinanceiro = (props) => {
    let { id } = useParams()

    const [valoresIniciais, setValoresIniciais] = useState({})
    const [dadosMovimentoLancamento, setDadosMovimentoLancamento] = useState([])
    const [tabIndex, setTabIndex] = useState(0)
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valorReceita, setValorReceita] = useState(0)
    const [valorRetencao, setValorRetencao] = useState(0)
    const [dataArquivos, setDataArquivos] = useState(null)
    const [limparArquivos, setLimparArquivos] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [msgValidacao, setMsgValidacao] = useState('')
    const [statusInicial, setStatusInicial] = useState()
    const [tituloValidacao, setTituloValidacao] = useState('')
    const [valorTotalLancamento, setValorTotalLancamento] = useState(0)
    const [validarLancamento, setValidarLancamento] = useState(false)
    const [validaCamposPreenchidos, setValidaCamposPreenchidos] = useState(false)
    const [mensagemCamposPreenchidos, setMensagemCamposPreenchidos] = useState('')
    const [preencheuPrevisao, setPreencheuPrevisao] = useState(false)
    const manter = [{ name: 'categoria_lancamento' }, { name: 'tipo_lancamento' }, { name: 'categoria' }]
    const history = useHistory()
    const inputValorMovimento = useRef();

    const isIdle = useIdle(10000);

    let validacao = Yup.lazy(({ liquidacao, liquidacao_parcelado, arrayLancamentos, liquida_parcela, parcelado_lancamento }) => {
        let index = liquidacao && liquidacao.length > 0 && liquidacao?.findIndex(x => !x.modo_liquidacao)
        return Yup.object({
            entidade: Yup.object().nullable().required(),
            categoria: Yup.object().nullable().required(),
            data: Yup.string().nullable().required(),
            // .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data) })
            // valor: Yup.string().nullable().test('Valor Geral', 'Campo Obrigatório!', function (value) { return !(parseFloat(value || 0) === 0) }),
            cliente_lancamento: Yup.string().nullable().test('Cliente Lançamento ', 'Campo Obrigatório!', function (value) { return validarLancamento ? value : true }),
            // valor_geral_lancamento: Yup.string().nullable().test('Valor Geral', 'Campo Obrigatório!', function (value) { return !(parseFloat(value || 0) === 0)}),
            tipo_lancamento: Yup.string().nullable().test('Tipo Lançamento ', 'Campo Obrigatório!', function (value) { return validarLancamento ? value : true }),
            categoria_lancamento: Yup.string().nullable().test('Categproa Lançamento ', 'Campo Obrigatório!', function (value) { return validarLancamento ? value : true }),
            previsao_vencimento_lancamento: Yup.string().nullable().test('Data', 'Data inválida!', function (value) { return validaData(this.parent.previsao_vencimento_lancamento) })
                .test('Data', 'Campo Obrigatório!', function (value) { return validarLancamento ? value : true }),
        }).shape({
            liquidacao: Yup.array()
                .of(
                    Yup.object().shape({
                        modo_liquidacao: Yup.object().nullable().when('valor', {
                            is: () => !parcelado_lancamento && validarLancamento && (liquidacao && !liquidacao[0].modo_liquidacao || (liquidacao && !liquidacao[index]?.modo_liquidacao && liquidacao[index]?.valor)),
                            then: Yup.object().required(),
                            otherwise: Yup.object().nullable()
                        }),
                        valor: Yup.string().nullable().test('Valor Liquidação', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !parcelado_lancamento && validarLancamento && (liquidacao.length - 1 != index || parseInt(index) === 0) ? value : true
                        }),
                        data_liquidacao: Yup.string().nullable().test('Data Liquidação', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !parcelado_lancamento && validarLancamento && (liquidacao[index].modo_liquidacao || parseInt(index) === 0) ? value : true

                        }),
                    })
                ),
            liquidacao_parcelado: Yup.array()
                .of(
                    Yup.object().shape({
                        modo_liquidacao: Yup.object().nullable().test('Modo Liquidação', 'Campo Obrigatório!', function (value) {

                            const index = this.path.replace(/[^0-9]+/g, '');
                            // return !((!value && parcelado_lancamento)
                            //     || (!liquidacao_parcelado[index]?.modo_liquidacao && liquidacao_parcelado[index]?.data_liquidacao))
                            return parcelado_lancamento && validarLancamento && (liquidacao_parcelado[index]?.data_liquidacao || parseInt(index) === 0) ? value : true

                        }),
                        data: Yup.string().nullable().test('Data Prevista', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(!value && parcelado_lancamento && validarLancamento)
                        }),
                        data_liquidacao: Yup.string().nullable().test('Data Liquidação', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return parcelado_lancamento && validarLancamento && (liquidacao_parcelado[index].liquidado || parseInt(index) === 0) ? value : true

                        }),
                        valor_liquidacao: Yup.string().nullable().test('Valor Liquidação', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(!convertValueToFloat(value) && parcelado_lancamento && validarLancamento)
                        }), // these constraints take precedence
                    })
                ),
            liquida_parcela: Yup.object().shape({
                data: Yup.string().nullable().test('Data', 'Campo Obrigatório!', value => parcelado_lancamento && validarLancamento ? value : true),
                periodicidade: Yup.object().nullable().test('Periodicidade', 'Campo Obrigatório!', value => parcelado_lancamento && validarLancamento ? value : true),
                parcela: Yup.string().nullable().test('Número', 'Campo Obrigatório!', value => parcelado_lancamento && validarLancamento ? value : true),
                valor: Yup.string().nullable().test('Valor', 'Campo Obrigatório!', value => parcelado_lancamento && validarLancamento ? !(Number.isNaN(convertValueToFloat(value))) : true),
            })
        }, ['data', 'periodicidade', 'numero_parcela', 'valor'])
    })

    const validarCamposPreenchidos = (values, propsFormik) => {

        const requiredFields = ["cliente_lancamento", "valor_geral_lancamento", "previsao_vencimento_lancamento"];

        let possuiCampoObrigatorioPreenchido = requiredFields.filter((fieldName) => {

            if (fieldName === 'valor_geral_lancamento')
                return !!parseFloat(values[fieldName])
            if (fieldName === 'previsao_vencimento_lancamento' && !preencheuPrevisao && dadosMovimentoLancamento.filter(x => !x.id).length < 1)
                return false
            else
                return !!values[fieldName]

        })

        if (possuiCampoObrigatorioPreenchido?.length > 0 && dadosMovimentoLancamento.filter(x => !x.id).length < 1) {
            setValidaCamposPreenchidos(true)
            const formattedNames = possuiCampoObrigatorioPreenchido.map((name) => {
                if (name === "tipo_lancamento") {
                    return "Tipo de lançamento"
                } else {
                    const firstWord = name.split("_")[0]
                    return `${firstWord.charAt(0).toUpperCase()}${firstWord.slice(1)}`
                }
            })
            setMensagemCamposPreenchidos(<><div dangerouslySetInnerHTML={{ __html: `Há campos preenchidos(<b>${formattedNames.join(", ")}</b>), porém não foram adicionados. Deseja continuar ?` }} /></>)
        }
        else
            verificaValidacoes(values, propsFormik)

    }
    const verificaValidacoes = (values, propsFormik) => {

        const hasLiquidacao = values.liquidacao && values.liquidacao[0] && values.liquidacao[0].modo_liquidacao;
        const hasLiquidacaoParcelado = values.liquidacao_parcelado && values.liquidacao_parcelado[0] && values.liquidacao_parcelado[0].modo_liquidacao;
        const hasLiquidaParcela = values.liquida_parcela && values.liquida_parcela.parcela && values.liquida_parcela.periodicidade;

        if (hasLiquidacao || hasLiquidacaoParcelado || hasLiquidaParcela) {
            mostrarToast('erro', 'Adicione o Lançamento atual antes de continuar');
        } else {
            setValidarLancamento(false)
            setTimeout(_ => propsFormik.submitForm(values, propsFormik), 50)
        }
    }

    useEffect(_ => {
        var objLista = {
            receita: [{ forma_receita: undefined, valor_receita: undefined }],
            retencao: [{ forma_retencao: undefined, valor_retencao: undefined }]
        }

        if (id && !history.location?.state?.values) {
            Promise.resolve(get(`/financeiro/movimentofinanceiro/${id}`).then(val => {
                if (val && val.length === 0)
                    props.history.push('/financeiro/movimento/consulta')
                else {
                    setDataArquivos([...val.arquivo] || [])
                    delete val.arquivo
                    let liquidacao = [{ data_liquidacao: val.data }]
                    let liquida_parcela = { data: val.data }
                    val.receita = [...(val.receita || []), ...objLista.receita];
                    val.retencao = [...(val.retencao || []), ...objLista.retencao];
                    setValorReceita(parseFloat(val.valor_total_receita || 0))
                    setValorRetencao(parseFloat(val.valor_total_retencao || 0))
                    setStatusInicial(val.status_id)
                    val.previsao_vencimento_lancamento = val.data
                    val.liquida_parcela = liquida_parcela
                    val.liquidacao = liquidacao
                    val.liquidacao_parcelado = liquidacao
                    val.tipo_lancamento = { value: 1, label: 'RECEITA' }
                    setValoresIniciais(val)
                }
            }))

            if (!history.location?.state?.values?.additionalValues)
                Promise.resolve(get(`/financeiro/movimentolancamento/${id}`)).then(val => {
                    if (val)
                        setDadosMovimentoLancamento(val)

                })
        } else {
            setDataArquivos([])
            setValorTotalLancamento(0)
            setValoresIniciais({
                tipo_lancamento: { value: 1, label: 'RECEITA' },
                receita: [{ forma_receita: undefined, valor_receita: undefined }],
                retencao: [{ forma_retencao: undefined, valor_retencao: undefined }],
                liquidacao: [{ modo_liquidacao: undefined, data: "", valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined, data_liquidacao: "" }],
                liquida_parcela: { data: undefined, parcela: "", periodicidade: undefined, valor: "" }

            })
        }

    }, [id])

    useEffect(_ => {
        if (history.location?.state?.values) {

            setValoresIniciais(history.location?.state?.values)
            if (history.location?.state?.values?.additionalValues) {
                console.log('history.location?.state?.values?.additionalValues: ', history.location?.state?.values?.additionalValues);

                setDadosMovimentoLancamento([...history.location?.state?.values?.additionalValues])

            }
        }

    }, [history.location?.state?.values])

    const setValueNumero = (propsFormik, value) => {
        const date = new Date();
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const currentDate = `${year}${month}${day}${hours}${minutes}${seconds}`;
        propsFormik.setFieldValue('numero_documento', currentDate + value?.sigla)
    }

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        if (statusInicial != 3) {
            values.arquivo = dataArquivos
            values.array_movimento_lancamentos = JSON.stringify(dadosMovimentoLancamento)
            salvar(
                values,
                '/financeiro/movimentofinanceiro',
                () => {
                    mostrarToast('sucesso', mensagens.sucesso)
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        setDadosMovimentoLancamento([])
                        manter.map(x => propsFormik.setFieldValue(x.name, values[x.name]))

                        setSalvando(false)
                        setSalvarENovo(false)
                        setLimparArquivos(true)
                    }
                    else
                        props.history.push('/financeiro/movimento/consulta')

                    setSalvando(false)
                },
                (err) => {
                    console.log('err: ', err);
                    setSalvando(false)
                })
        } else {
            setSalvando(false)
            mostrarToast('erro', 'Operação não permitida! Movimento Financeiro Fechado.')
        }
    }

    const validarSubmit = async (values, propsFormik) => {
        setSalvando(true)
        const msg = []

        let validacaoLancamento = { arrayLancamentos: [] }
        let valuesParametro = [...dadosMovimentoLancamento]
        validacaoLancamento.arrayLancamentos = valuesParametro.map(x => ({ atendimento: x.atendimento, id: x.id }))

        let validacaoMovimento = {
            id: values.id,
            categoria: values.categoria,
            receita: values.receita,
            retencao: values.retencao,
            status_id: values.status_id,
            data: values.data
        }

        await validar(`/financeiro/validarmovimento`, { ...validacaoMovimento }, () => { }, (e) => {
            const alertas = e?.response?.data?.alertas;
            msg.push(<><div dangerouslySetInnerHTML={{ __html: alertas?.map(x => x.replace(/"/g, '') + '<br/><br/>').join('') }} /></>)
        })

        await validar('/financeiro/validaGuiaBaixada', { ...validacaoLancamento }, () => { }, (e) => {
            setTituloValidacao('Validação Lançamento');
            msg.push(<><div dangerouslySetInnerHTML={{ __html: e?.response?.data?.alertas[0].replace(/"/g, '') }} /></>)

        })
        if (msg.length > 0) {
            setTituloValidacao(<div><div style={{ textAlign: 'center' }}><b>Validações</b></div></div>);
            msg.push('Deseja continuar?')
            setMsgValidacao(msg.map(x => <>{x}<br /></>))
            setShowModal(true)
            setSalvando(false)
        }
        else
            Submit(values, propsFormik)

    }

    useEffect(() => {
        Promise.resolve(get(`/financeiro/obtervalortotallancamento/${id}`)).then(item => {
            if (item.valor != null)
                setValorTotalLancamento(item.valor);
        })
    }, [])

    const erroMovimento = (propsFormik) => {
        const erros = propsFormik.errors;
        return (erros.entidade || erros.categoria || erros.vigencia_inicial || erros.valor || erros.data)
    }
    const [dadosRascunho, setDadosRascunho] = useState([])


    useEffect(_ => {
        setDadosRascunho([...dadosMovimentoLancamento])
    }, [dadosMovimentoLancamento])

    const memoizedAdditionalValues = useMemo(() => [...dadosRascunho], [dadosRascunho]);

    return (
        <>

            <Formik
                initialValues={valoresIniciais}
                validationSchema={validacao}
                enableReinitialize={true}
                onSubmit={(values, propsFormik) => { validarSubmit(values, propsFormik) }}>
                {propsFormik =>
                (<>
                    <Rascunho modulo={114} history={history} idle={isIdle} additionalValues={memoizedAdditionalValues} identificador={propsFormik.values?.numero_documento} formik={propsFormik} />
                    <Form>
                        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)} default={true}>
                            <TabList>
                                <Tab className={"react-tabs__tab " + (erroMovimento(propsFormik) ? 'tab-error' : '')}><span className='icone-tab icon-Receipt'></span>Movimento Financeiro</Tab>
                                <Tab className={"react-tabs__tab "}><span className='icone-tab icon-Receipt-3'></span>Lançamentos (R$)</Tab>
                                <Tab className={`react-tabs__tab`}><span className='icone-tab icon-Pinch'></span>Fechamento</Tab>
                            </TabList>
                            <TabPanel >
                                <div className='card mb-20'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <InputEntidade
                                                label='Entidade'
                                                name='entidade'
                                                placeholder='Entidade'
                                                tamanho="col-xl-4 col-lg-6"
                                                propsFormik={propsFormik}
                                                isClearable={false}
                                                obrigatorio
                                                onChange={p => {
                                                    propsFormik.setFieldValue('entidade', p); setValueNumero(propsFormik, p); propsFormik.setFieldValue('objeto_entidade', p)
                                                    propsFormik.setFieldValue('conta_bancaria', null)
                                                }}
                                            />

                                            <InputCategoriaAlternativo
                                                obrigatorio={true}
                                                label='Categoria'
                                                name='categoria'
                                                placeholder='Categoria'
                                                tamanho="col-xl-4 col-lg-6"
                                                propsFormik={propsFormik}
                                                botaoVisivel={true}
                                                setarSeForUm={id && id ? false : true}
                                            />

                                            <InputDatePicker
                                                obrigatorio="true"
                                                label='Data'
                                                name='data'
                                                placeholder='Data'
                                                tamanho={'col-lg-2'}
                                                propsFormik={propsFormik}
                                                setarValor={value => {
                                                    propsFormik.setFieldValue("data", value)
                                                }}
                                                onBlurData={(value) => {
                                                    propsFormik.setFieldValue('previsao_vencimento_lancamento', value);
                                                    propsFormik.setFieldValue('liquidacao[0].data_liquidacao', value)
                                                    propsFormik.setFieldValue('liquida_parcela[0].data', value)
                                                }}
                                            />
                                            <InputCurrency onUpdate={val => {
                                                let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                inputValorMovimento.current = valor
                                            }}
                                                label={'Valor R$'} name={`valor`}
                                                value={inputValorMovimento.current || formatarMoeda(propsFormik?.values?.valor) || formatarMoeda(propsFormik?.values?.valor_formatado)}
                                                classeTooltip={'tooltip-input-modal'}
                                                propsFormik={propsFormik}
                                                onBlur={(e) => {
                                                    if (inputValorMovimento.current || inputValorMovimento.current === 0) {
                                                        propsFormik.values.valor = inputValorMovimento.current && inputValorMovimento.current.toString().trim();
                                                        propsFormik.values.valor_formatado = formatarMoeda(inputValorMovimento.current && convertValueToFloat(inputValorMovimento.current.toString()));
                                                    }

                                                    inputValorMovimento.current = null;
                                                }}
                                                classDiv='col-2 col-xl-2 col-lg-2 pr-0' obrigatorio={true} />
                                            <InputPadrao
                                                label='Descrição'
                                                name='descricao'
                                                type='text'
                                                placeholder='Descrição'
                                                tamanho={'col-lg-4'}
                                                classeTooltip={props.modal && 'tooltip-input-modal'} />

                                            <InputPadrao
                                                label='Número do Documento'
                                                name='numero_documento'
                                                type='text'
                                                placeholder='Número do Documento'
                                                tamanho={'col-lg-2'}
                                                classeTooltip={props.modal && 'tooltip-input-modal'} />

                                            {/* <InputContaBancaria
                                                label='Conta Bancária'
                                                name='conta_bancaria'
                                                placeholder='Conta Bancária'
                                                tamanho="col-xl-4 col-lg-6"
                                                propsFormik={propsFormik}
                                                ordenarPorCliente
                                                botaoVisivel={true}
                                                modal /> */}
                                            <InputContaBancariaCliente
                                                label='Conta Bancária'
                                                name="conta_bancaria"
                                                placeholder="Conta Bancária"
                                                propsFormik={propsFormik} botaoVisivel={propsFormik.values?.entidade}
                                                modal
                                                tamanho={!props.modal && 'col-lg-3'}
                                                classeTooltip={props.modal && 'tooltip-input-modal'} />

                                            <TextArea performance={true} label='Anotações'
                                                name='observacao'
                                                type='text'
                                                placeholder='Anotações'
                                                tamanho={'col-xl-4 col-lg-6 col-md-12'}
                                                classeTooltip={'tooltip-input-modal'} />

                                            {dataArquivos != null ?
                                                <InputArquivoS3
                                                    label='Arquivos'
                                                    name='arquivo'
                                                    placeholder='Arquivo'
                                                    tamanho='col-12'
                                                    diretorioS3='movimentofinanceiro/DDMMYYYY/'
                                                    modulo={MODULO["/financeiro/movimentofinanceiro"]}
                                                    tamanhoMinimo={0}
                                                    tamanhoMaximoEmMB={300}
                                                    setDataArquivos={setDataArquivos}
                                                    dataArquivos={dataArquivos != null ? dataArquivos : []}
                                                    setIsSubmit={dataArquivos.length > 0 ? setSalvando : () => { }}
                                                    limparCampos={limparArquivos}
                                                /> : <></>}
                                        </div>
                                        <div className='separator mt-40 mb-2' />
                                        <br />
                                        <div>
                                            <h3>Valores Recebidos (R$)</h3>
                                        </div>
                                        <div>
                                            <ValorRecebido propsFormik={propsFormik} valorTotalReceita={valorReceita} valorTotalRetencao={valorRetencao} />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel >
                                <div>
                                    <Lancamento propsFormik={propsFormik} valorTotalLancamento={valorTotalLancamento} tabLancamento={true} tabLancamentocss={'tab-lancamento '} idMovimento={id}
                                        dadosMovimentoLancamento={dadosMovimentoLancamento} setDadosMovimentoLancamento={setDadosMovimentoLancamento} setValidarLancamento={setValidarLancamento}
                                        setPreencheuPrevisao={setPreencheuPrevisao} setValorTotalLancamento={setValorTotalLancamento} />
                                </div>

                            </TabPanel>
                            <TabPanel >
                                <div>
                                    <Fechamento propsFormik={propsFormik} valorTotalLancamento={valorTotalLancamento} dadosMovimentoLancamento={dadosMovimentoLancamento} />
                                </div>
                            </TabPanel>
                        </Tabs>

                        <ModalDuasOpcoes showModal={showModal} setShowModal={setShowModal} funcaoOpcao2={_ => { setShowModal(false); Submit(propsFormik.values, propsFormik) }}
                            funcaoOpcao1={_ => { setSalvando(false); setShowModal(false) }}
                            esconderFechar mensagem={<>{tituloValidacao ? <>{tituloValidacao} <br /><br /></> : <></>} {msgValidacao}<br /></>}
                            textoOpcao1={'Cancelar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} />
                        <ModalDuasOpcoes showModal={validaCamposPreenchidos} setShowModal={setValidaCamposPreenchidos} funcaoOpcao2={_ => { setValidaCamposPreenchidos(false); verificaValidacoes(propsFormik.values, propsFormik) }}
                            funcaoOpcao1={_ => { setSalvando(false); setValidaCamposPreenchidos(false) }}
                            esconderFechar mensagem={<> {mensagemCamposPreenchidos}<br /></>}
                            textoOpcao1={'Cancelar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} />
                        {!props.modal && <div className='separator mt-40 mb-5'></div>}
                        <div className='direita container-botoes mb-3 group-salvar-novo'>
                            <BotaoPadrao type='button' texto='Salvar' disabled={salvando} click={_ => { validarCamposPreenchidos(propsFormik.values, propsFormik); setSalvarENovo(false) }} />
                            {(props.modal || !id) && <BotaoPadrao type='button' texto='Salvar e Novo' disabled={salvando} click={_ => { validarCamposPreenchidos(propsFormik.values, propsFormik); setSalvarENovo(true) }} />}
                        </div>
                    </Form >
                </>
                )
                }

            </Formik >
        </>
    )


}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Financeiro</Link>,
        () => <Link className="link-active" to={'/financeiro/movimento/consulta/'}>Movimento Financeiro</Link>,
    ]
    return (<>
        <HeaderCadastro titulo={"Cadastro Movimento Financeiro"} link={links} />
        <CadastroMovimentoFinanceiro {...props} />
    </>
    )
}

const CadastroCategoria = (props) => {

    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao] = useState(Yup.object({
        categoria: Yup.string()
            .required().trim()
            .max(200)
    }))

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        salvar(
            values,
            '/movimentofinanceiro/categoria',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)

                if (salvarENovo) {
                    propsFormik.resetForm({})
                    // manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                }
                else
                    props.history.push('/financeiro/movimento/consulta')
            },
            () => {
                setSalvando(false)
            })
    }

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>
                <div className={props.modal && props.modal || 'card mb-20'}>
                    <div className={props.modal || 'card-body'}>
                        <div className='row'>
                            <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' obrigatorio={false} />
                            <InputPadrao label='Categoria' name='categoria' type='text' placeholder='Categoria' classeTooltip={props.modal && 'tooltip-input-modal'}
                                obrigatorio={true} tamanho={!props.modal && 'col-6 col-sm-12 col-md-6 col-xl-6'} autoFocus salvarENovo={salvarENovo} />
                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />

                        </div>
                    </div>
                </div>
                {!props.modal ? <div className='separator mt-40 mb-5'></div> : <></>}
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)
            }
        </Formik >
    );
}

export { CadastroMovimentoFinanceiro, CadastroCategoria }
export default Cadastro
