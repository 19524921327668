import React from 'react';
import { convertValueToFloat, formatarMoeda } from '../../util/Utilitarios';

const agruparPor = (array, chave) => {
    return array.reduce((agrupamento, item) => {
        const valor = item[chave];
        if (!agrupamento[valor]) {
            agrupamento[valor] = [];
        }
        agrupamento[valor].push(item);
        return agrupamento;
    }, {});
};

const relatorioContaReceberSintetico = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {
    const empresasAgrupadas = agruparPor(array[0]?.data || [], 'empresa');
    const idxNumCol = arrayConfigCol[0].findIndex(y => y.coluna == '#');
    const idxNumeroConta = arrayConfigCol[0].findIndex(y => y.coluna == 'numero_conta');
    const idxContaGrupo = arrayConfigCol[0].findIndex(y => y.coluna == 'subcategoria');
    const idxTotalValor = arrayConfigCol[0].findIndex(y => y.coluna == 'total_valor');

    let totalGeral = 0;

    const empresasRelatorio = Object.keys(empresasAgrupadas).map((empresa) => {
        const categoriasPrincipaisAgrupadas = agruparPor(empresasAgrupadas[empresa], 'categoria_principal');
        let totalEmpresa = 0;

        const empresaComponent = (
            <>
                <table className='table-agrupamento mb-2'>
                    <tr className={'tr-data titulo-1 group-title bold coluna'}>
                        <td className={`bold td-data text-left`} style={{ width: '45%' }}>EMPRESA: {empresa}</td>
                    </tr>
                </table>
                <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                    {Object.keys(categoriasPrincipaisAgrupadas).map((categoriaPrincipal) => {
                        const categoriasAgrupadas = agruparPor(categoriasPrincipaisAgrupadas[categoriaPrincipal], 'categoria');

                        return (
                            <>
                                <table className='table-dados'>
                                    <tr>
                                        <td className={`bold td-data text-left m-20`} style={{ width: '45%' }}>GRUPO CONTA PRINCIPAL: {categoriaPrincipal}</td>
                                    </tr>
                                </table>

                                {Object.keys(categoriasAgrupadas).map((categoria) => {
                                    const itens = categoriasAgrupadas[categoria];
                                    const totalCategoria = itens.reduce((acc, item) => acc + convertValueToFloat(item.total_valor), 0);
                                    totalEmpresa += totalCategoria;

                                    return (
                                        <>
                                            <table className='table-dados'>
                                                <tr className='titulo-1'>
                                                    {arrayConfigCol[0][idxNumCol]?.visivel ? <td className='text-left bold td-data' width={'1%'}>#</td> : null}
                                                    {arrayConfigCol[0][idxNumeroConta]?.visivel ? <td className='text-left bold td-data' width={'33%'}>Código</td> : null}
                                                    {arrayConfigCol[0][idxContaGrupo]?.visivel ? <td className='text-left bold td-data' width={'33%'}>Grupo Conta</td> : null}
                                                    {arrayConfigCol[0][idxTotalValor]?.visivel ? <td className='text-left bold td-data' width={'33%'}>Valor</td> : null}
                                                </tr>

                                                {itens.map((item, idx) => (
                                                    <tr className={'tr-data bt-1'} key={idx}>
                                                        {arrayConfigCol[0][idxNumCol]?.visivel ? <td className='text-left td-data' width={'1%'}>{idx + 1}</td> : null}
                                                        {arrayConfigCol[0][idxNumeroConta]?.visivel ? <td className='text-left td-data'>{item.numero_conta}</td> : null}
                                                        {arrayConfigCol[0][idxContaGrupo]?.visivel ? <td className='text-left td-data'>{item.subcategoria}</td> : null}
                                                        {arrayConfigCol[0][idxTotalValor]?.visivel ? <td className='text-left td-data'>{item.total_valor}</td> : null}
                                                    </tr>
                                                ))}
                                            </table>
                                            <table className='table-dados'>
                                                <tr>
                                                    <td className='td-data'></td>
                                                    <td className='td-data'></td>
                                                    {arrayConfigCol[0][idxTotalValor]?.visivel ? (
                                                        <td className='td-data text-right' style={{ width: '33%' }}>
                                                            <b>TOTAL GRUPO CONTA PRINCIPAL: {formatarMoeda(totalCategoria)}</b>
                                                        </td>
                                                    ) : null}
                                                </tr>
                                            </table>
                                        </>
                                    );
                                })}
                            </>
                        );
                    })}
                </table>

                <table className='table-agrupamento mb-2' style={{ borderTop: '0.25px dashed', paddingTop: '15px' }}>
                    <tr>
                        <td className='td-data'></td>
                        <td className='td-data'></td>
                        {arrayConfigCol[0][idxTotalValor]?.visivel ? (
                            <td className='td-data text-right'><b>TOTAL EMPRESA: {formatarMoeda(totalEmpresa)}</b></td>
                        ) : null}
                    </tr>
                </table>
            </>
        );

        totalGeral += totalEmpresa;
        return empresaComponent;
    });

    return (
        <>
            {empresasRelatorio}

            <table className='table-agrupamento mb-2' style={{ borderTop: '2px solid black', marginTop: '20px' }}>
                <tr>
                    <td className='td-data'></td>
                    <td className='td-data'></td>
                    {arrayConfigCol[0][idxTotalValor]?.visivel ? (
                        <td className='td-data text-right'><b>TOTAL GERAL: {formatarMoeda(totalGeral)}</b></td>
                    ) : null}
                </tr>
            </table>
        </>
    );
};

export { relatorioContaReceberSintetico };
