import React, { useState, useEffect } from 'react';
import { HeaderCadastro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form } from "formik";
import { InputPadrao } from '../../../../components/Inputs'
import { BotaoPadrao } from '../../../../components/Botoes'
import { useParams } from "react-router-dom";
import { mensagens } from '../../../../util/Utilitarios'
import { post } from '../../../../util/AcessoApi'
import { Link } from 'react-router-dom'
import Yup from '../../../../util/Validacoes';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Atalho from '../../../../util/Atalho'

const Submit = (values, setIsSubmit, props) => {
    setIsSubmit(true)

    Promise.resolve(post('/controleAcesso/modulo/salvar', values)).then(val => {
        mostrarToast('sucesso', mensagens['sucesso'])
        props.history.push('/controleAcesso/modulo/consulta')
    }).catch((e) => setIsSubmit(false))
}
const CamposCadastro = props => {
    let validacaoInicial = Yup.object({ descricao: Yup.string().required().max(200) })
    let { id } = useParams()
    props = { id, ...props }
    const [isSubmit, setIsSubmit] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const { history } = props

    useEffect(() => {
        if (id) {
            Promise.resolve(post('/controleAcesso/modulo/obter', { id })).then(val => {
                if (val.length === 0)
                    history.push('/controleAcesso/modulo/consulta')

                setValoresIniciais(val[0])
            })
        } else {
            setValoresIniciais({ descricao: "" })
        }

    }, [history, id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={(values) => Submit(values, setIsSubmit, props)}>
            {propsFormik => (
                <Form>
                    <div className='row'>
                        <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={false} />
                        <InputPadrao label="Descrição" name="descricao" type="text" placeholder="Descrição" obrigatorio={true} tamanho='col-lg-12' autoFocus />
                        <Atalho formik={propsFormik} salvando={false} />
                    </div>
                    <div className="direita container-botoes mb-3"><BotaoPadrao texto="Salvar" disabled={isSubmit} idTooltip={'salvar'} /></div>
                </Form>
            )}
        </Formik>
    );
}
const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <a href='#'>Controle Acesso</a>,
        () => <Link className="link-active" to={'/controleAcesso/modulo/consulta'}>Módulo</Link>,
    ]

    return (
        <>
            <HeaderCadastro titulo={'Cadastro de Módulo'} link={links} />
            <div className="card">
                <div className="card-body">
                    <CamposCadastro {...props} />
                </div>
            </div>
        </>
    )
}

export default Cadastro
export { CamposCadastro }