import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { visualizar } from '../../../../util/AcessoApi'
import { obterDataFormatada } from '../../../../util/Utilitarios'
import { ordenarArrayObj } from '../../../../util/FuncoesComuns'


const VisualizacaoUsuario = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState({})


    useEffect(() => {
        if (id) {
            visualizar(`/administracao/empresa`, id, obj => {
                setDados(obj)
            })
        }
    }, [id])


    const data = dados ? {
        id,
        modulo: props.modulo,
        titulo: 'Visualização da Empresa',
        grupos: [
            {
                titulo: 'Dados Gerais',
                colunas: [
                    {
                        titulo: 'Razão Social',
                        descricao: dados.razao_social,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Nome Fantasia',
                        descricao: dados.nome,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'CNPJ',
                        descricao: dados.cnpj,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'E-mail',
                        descricao: dados.email,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Inscrição Municipal',
                        descricao: dados.inscricao_municipal,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Código Tributação Município',
                        descricao: dados.codigo_tributario_municipio,
                        tamanho: 'col-lg-6'
                    }
                    ,
                    {
                        titulo: 'Início Faturamento',
                        descricao: obterDataFormatada(dados.inicio_funcionamento),
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Data Registro',
                        descricao: dados.data_registro_formatada,
                        tamanho: 'col-lg-6'
                    }
                ]
            },
            {
                titulo: 'Endereço',
                colunas: [
                    {
                        titulo: 'CEP',
                        descricao: dados.cep,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Logradouro',
                        descricao: dados.logradouro,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Complemento',
                        descricao: dados.complemento,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Número',
                        descricao: dados.numero,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Bairro',
                        descricao: dados.bairro,
                        tamanho: 'col-lg-6'
                    },
                    {
                        titulo: 'Município - UF',
                        descricao: dados.nome_municipio,
                        tamanho: 'col-lg-6'
                    }
                ]
            }, {
                tipoGrupo: 'tabela',
                titulo: 'Telefone(s)',
                thead:
                    <tr>
                        <td className='coluna-6'>Tipo</td>
                        <td className='coluna-6'>Telefone</td>
                    </tr>,
                tbody: dados.fone == undefined || dados.fone.length < 1 ? (<><tr>
                    <td colspan="3">Sem Registro!</td>
                </tr></>) :
                    ordenarArrayObj(dados.fone, 'id').map(permissao =>
                        <tr>
                            <td>{permissao.tipo_telefone.label}</td>
                            <td>{permissao.telefone}</td>
                        </tr>)
            }
        ]
    } : { titulo: 'Visualização da Empresa' }

    return (
        <VisualizacaoPadrao data={data} id={id} {...props} />
    )
}

export default VisualizacaoUsuario