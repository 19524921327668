import { InputSwitch, InputPadrao, InputSearch } from '../../../../components/Inputs'
import { removeAcentos, ordenarArrayObj, emptyObject } from '../../../../util/FuncoesComuns'
import React, { useState, useEffect } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { HeaderCadastro } from '../../../../components/Headers'
import { ModalCadastro } from '../../../../components/Modais'
import { Formik, Form } from "formik"
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { get, salvar } from '../../../../util/AcessoApi'
import { useParams } from "react-router-dom"
import { mensagens } from '../../../../util/Utilitarios'
import { Link } from 'react-router-dom'
import Yup from '../../../../util/Validacoes'
import '../../../../assets/css/ios-switch/clean-switch.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { SimpleAccordion } from '../../../../components/Accordion'

const Cadastro = (props) => {
    let { id } = useParams()

    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao] = useState(Yup.object({ descricao: Yup.string().required().max(200) }))
    const [abrirModal, setAbrirModal] = useState(false)
    const [grupoObj, setGrupoObj] = useState({})
    const [moduloAtual, setModuloAtual] = useState({})
    const [pesquisaAcao, setPesquisaAcao] = useState('')
    const [pesquisaModulo, setPesquisaModulo] = useState('')
    const [acoesPorModulo, setAcoesPorModulo] = useState([])
    const [agrupamentos, setAgrupamentos] = useState([])
    const [salvando, setSalvando] = useState(false)
    const [checkedGeral, setCheckedGeral] = useState(false)
    const [checkedAgg, setCheckedAgg] = useState([])
    const [salvarENovo, setSalvarENovo] = useState(false)

    const setUseStatePermissoes = (copiaGrupoObj, copiaAcoesPorModulo) => {
        setGrupoObj(copiaGrupoObj)
        setAcoesPorModulo(copiaAcoesPorModulo)
    }

    const setAcao = (idModulo, idAcao) => {
        let copiaGrupoObj = { ...grupoObj }
        let copiaAcoesPorModulo = [...acoesPorModulo]
        let indexModuloGeral = copiaAcoesPorModulo.findIndex(x => x.id === idModulo)
        let indexModulo = copiaGrupoObj.modulos.findIndex(x => x.id === idModulo)
        let moduloAtual = { ...copiaGrupoObj.modulos[indexModulo] }
        let indexAcao = moduloAtual.acoes.findIndex(x => x.id === idAcao)
        let acaoAtual = { ...moduloAtual.acoes[indexAcao] }
        let moduloGeralAtual = { ...copiaAcoesPorModulo[indexModuloGeral] }

        acaoAtual.permitido = !acaoAtual.permitido
        moduloAtual.acoes[indexAcao] = acaoAtual
        moduloGeralAtual.todasPermissoes = moduloAtual.acoes
            .filter(x => x.permitido && removeAcentos(x.descricao.toLowerCase())
                .includes(removeAcentos(pesquisaAcao.toLowerCase()))).length
            ===
            moduloAtual.acoes.filter(x => removeAcentos(x.descricao.toLowerCase())
                .includes(removeAcentos(pesquisaAcao.toLowerCase()))).length

        copiaGrupoObj.modulos[indexModulo] = moduloAtual
        copiaAcoesPorModulo[indexModuloGeral] = moduloGeralAtual

        setUseStatePermissoes(copiaGrupoObj, copiaAcoesPorModulo)
    }

    const setTodasAcoesPorModulo = (idModulo) => {
        let copiaGrupoObj = { ...grupoObj }
        let copiaAcoesPorModulo = [...acoesPorModulo]
        let indexModulo = copiaGrupoObj.modulos.findIndex(x => x.id === idModulo)
        let indexGlobal = copiaAcoesPorModulo.findIndex(x => x.id === idModulo)
        let moduloAtual = { ...copiaGrupoObj.modulos[indexModulo] }
        let globalAtual = { ...copiaAcoesPorModulo[indexGlobal] }


        moduloAtual.acoes
            .filter(acao => removeAcentos(acao.descricao.toLowerCase()).includes(removeAcentos(pesquisaAcao.toLowerCase())))
            .forEach((acao) => acao.permitido = !globalAtual.todasPermissoes)

        globalAtual.todasPermissoes = !globalAtual.todasPermissoes

        copiaGrupoObj.modulos[indexModulo] = moduloAtual
        copiaAcoesPorModulo[indexGlobal] = globalAtual

        setUseStatePermissoes(copiaGrupoObj, copiaAcoesPorModulo)
    }

    const obterCheckedAgg = () => {
        if (agrupamentos.length > 0) {
            let agg = agrupamentos.map(agrupamento => {
                let modulosAgg = acoesPorModulo.filter(x => x.agrupamento_id === agrupamento.id && removeAcentos(x.descricao.toLowerCase())
                    .includes(removeAcentos(pesquisaModulo.toLowerCase())))
                agrupamento['todasPermissoes'] = modulosAgg.length === modulosAgg.filter(modulo => modulo.todasPermissoes).length
                return agrupamento;
            })
            setAgrupamentos(agg)
        }
    }

    const setTodasAcoesGlobal = _ => {
        let todasPermissoes = acoesPorModulo.filter((item) => removeAcentos(item.descricao.toLowerCase())
            .includes(removeAcentos(pesquisaModulo.toLowerCase()))).length
            ===
            acoesPorModulo.filter(modulo => modulo.todasPermissoes && removeAcentos(modulo.descricao.toLowerCase())
                .includes(removeAcentos(pesquisaModulo.toLowerCase()))).length
        let copiaGrupoObj = { ...grupoObj }
        let copiaAcoesPorModulo = [...acoesPorModulo]

        copiaAcoesPorModulo
            .forEach(modulo => {
                if (removeAcentos(modulo.descricao.toLowerCase())
                    .includes(removeAcentos(pesquisaModulo.toLowerCase())))
                    modulo.todasPermissoes = !todasPermissoes
            })

        copiaGrupoObj.modulos
            .forEach(modulo => {
                if (removeAcentos(modulo.descricao.toLowerCase())
                    .includes(removeAcentos(pesquisaModulo.toLowerCase())))
                    modulo.acoes.forEach(acao => acao.permitido = !todasPermissoes)
            })

        setUseStatePermissoes(copiaGrupoObj, copiaAcoesPorModulo)
    }

    const setTodasAcoesAgrupamento = (idAgg) => {
        let todasPermissoes = acoesPorModulo.filter((item) => item.agrupamento_id === idAgg && removeAcentos(item.descricao.toLowerCase())
            .includes(removeAcentos(pesquisaModulo.toLowerCase()))).length
            ===
            acoesPorModulo.filter(modulo => modulo.agrupamento_id === idAgg && modulo.todasPermissoes && removeAcentos(modulo.descricao.toLowerCase())
                .includes(removeAcentos(pesquisaModulo.toLowerCase()))).length
        let copiaGrupoObj = { ...grupoObj }
        let copiaAcoesPorModulo = [...acoesPorModulo]

        copiaAcoesPorModulo.filter(x => x.agrupamento_id === idAgg)
            .forEach(modulo => {
                if (removeAcentos(modulo.descricao.toLowerCase())
                    .includes(removeAcentos(pesquisaModulo.toLowerCase())))
                    modulo.todasPermissoes = !todasPermissoes
            })

        copiaGrupoObj.modulos.filter(x => x.agrupamento_id === idAgg)
            .forEach(modulo => {
                if (removeAcentos(modulo.descricao.toLowerCase())
                    .includes(removeAcentos(pesquisaModulo.toLowerCase())))
                    modulo.acoes.forEach(acao => acao.permitido = !todasPermissoes)
            })

        setUseStatePermissoes(copiaGrupoObj, copiaAcoesPorModulo)

        // const novoAgg = agrupamentos.map(x => {
        //     if(x.id === idAgg){
        //         x.todasPermissoes = !x.todasPermissoes
        //     }

        //     return {...x, todasPermissoes: !x.todasPermissoes}
        // })
        // setAgrupamentos(novoAgg)
        // obterCheckedAgg()
    }

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Controle Acesso</span>,
        () => <Link className="link-active" to={'/controleAcesso/grupo/consulta'}>Grupo</Link>,
    ]

    const Submit = (values, propsFormik) => {
        setSalvando(true)

        let copiaGrupoObj = { ...grupoObj }

        copiaGrupoObj.descricao = values.descricao

        setGrupoObj(copiaGrupoObj)

        const manter = []

        salvar(
            copiaGrupoObj,
            '/controleAcesso/grupo',
            () => {
                mostrarToast('sucesso', mensagens['sucesso'])
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                }
                else
                    props.history.push('/controleAcesso/grupo/consulta')
            },
            () => {
                setSalvando(false)
            })
    }

    const atualizarAcaoPesquisa = (idModulo, pesquisa) => {

        let copiaAcoesPorModulo = [...acoesPorModulo]
        let indexModulo = grupoObj.modulos.findIndex(x => x.id === idModulo)

        copiaAcoesPorModulo[indexModulo].todasPermissoes = grupoObj.modulos[indexModulo].acoes
            .filter(x => x.permitido && removeAcentos(x.descricao.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase()))).length
            ===
            grupoObj.modulos[indexModulo].acoes
                .filter(x => removeAcentos(x.descricao.toLowerCase()).includes(removeAcentos(pesquisa.toLowerCase()))).length

        setPesquisaAcao(pesquisa)
        setAcoesPorModulo(copiaAcoesPorModulo)
    }

    useEffect(() => {
        Promise.resolve(get(`/controleAcesso/grupo/${id}`)).then(grupo => {
            if (emptyObject(grupo))
                props.history.push('/controleAcesso/grupo/consulta')
            else {
                setGrupoObj(grupo)
                setAgrupamentos(grupo.agrupamentos)
                setAcoesPorModulo(grupo.modulos.map(modulo => {
                    return {
                        id: modulo.id,
                        todasPermissoes: modulo.acoes.filter(acao => acao.permitido).length === modulo.acoes.length,
                        descricao: modulo.descricao,
                        agrupamento_id: modulo.agrupamento_id
                    }
                }))

                setValoresIniciais({ id: grupo.id || 0, descricao: grupo.descricao || '' })
            }
        })
    }, [id])

    useEffect(() => {
        setCheckedGeral(
            acoesPorModulo
                .filter(modulo => removeAcentos(modulo.descricao.toLowerCase())
                    .includes(removeAcentos(pesquisaModulo.toLowerCase()))
                    && modulo.todasPermissoes).length
            ===
            acoesPorModulo
                .filter(modulo => removeAcentos(modulo.descricao.toLowerCase())
                    .includes(removeAcentos(pesquisaModulo.toLowerCase()))).length)
        obterCheckedAgg()
    }, [acoesPorModulo, pesquisaModulo])



    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            <Form>
                <HeaderCadastro titulo={'Cadastro de Grupo'} link={links} />
                <div className="card mb-20">
                    <div className="card-body">
                        <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio="false" />
                        <InputPadrao label="Descrição" name="descricao" type="text" placeholder="Descrição" obrigatorio="true" autoFocus salvarENovo={salvarENovo} />
                    </div>
                </div>
                <div className="mt-40">
                    <HeaderCadastro titulo={'Permissões'}
                        pesquisa={true}
                        valorPesquisa={pesquisaModulo}
                        onChangePesquisa={(pesquisa) => setPesquisaModulo(pesquisa)}>
                        <InputSwitch
                            name='pesquisa-geral'
                            onChange={() => setTodasAcoesGlobal()}
                            checked={checkedGeral} />
                    </HeaderCadastro>
                </div>
                {agrupamentos && agrupamentos.map((agrupamento, idxAgg) => {
                    const modulosFiltrados = agrupamento && grupoObj.modulos ? ordenarArrayObj(grupoObj.modulos.filter(x => x.agrupamento_id === agrupamento.id), 'descricao')
                        .filter(item => removeAcentos(item.descricao.toLowerCase()).includes(removeAcentos(pesquisaModulo.toLowerCase()))) : []

                    return modulosFiltrados.length > 0 ? <div className='row'>
                        {idxAgg > 0 ? <div className='col-12 mb-2'><div className='separator'></div></div> : <></>}

                        <div className='col-12 mt-1 display-flex'><h4 className='mt-2'><span>{agrupamento.descricao}</span></h4><div>
                            <InputSwitch
                                name='switch-agrupamento'
                                onChange={() => setTodasAcoesAgrupamento(agrupamento.id)}
                                checked={agrupamento.todasPermissoes} /></div>
                        </div>
                        <div className='col-12 mb-2'><div className='separator'></div></div>
                        <div className='col-12'><div className='row'>
                            {
                                modulosFiltrados.map((item, index) => {
                                    let acoesPai = item.acoes.filter(x => x.acao_pai_id).map(x => x.acao_pai_id);
                                    let acoesFormatadas = item.acoes.filter(x => !acoesPai.includes(x.id));
                                    let permissoes = acoesFormatadas.filter(x => x.permitido).length
                                    let total = acoesFormatadas.length

                                    return <div key={index} className="card-pequeno col-md-6 col-sm-12 pr-15 pl-15" onClick={() => { setModuloAtual(item); setAbrirModal(!abrirModal); setPesquisaAcao('') }}>
                                        <div className={"card row-space-between " + (moduloAtual.id === item.id ? "ativo" : "")}>
                                            <div className="row-space-between modulos-permissao">
                                                <div>
                                                    <h4>{item.descricao}</h4>
                                                </div>
                                                <div className="row-end">
                                                    <div className="row-start">
                                                        <InputSwitch
                                                            name={"id" + item.id}
                                                            key={item.id}
                                                            onChange={() => setTodasAcoesPorModulo(item.id)}
                                                            checked={permissoes === total} />
                                                    </div>
                                                    <CircularProgressbar
                                                        value={permissoes}
                                                        maxValue={total}
                                                        styles={buildStyles({
                                                            pathColor: "#020029",
                                                            textColor: "#020029"
                                                        })}
                                                        text={`${permissoes}/${total}`}
                                                        className="CircularProgressbar" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div></div>
                    </div> : <></>
                })}
                <div className="separator mt-40 mb-5"></div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} />}
                </div>
                <ModalCadastro titulo={moduloAtual.descricao} show={abrirModal} setShowModal={setAbrirModal}>
                    <div className="modal-body-cadastro">
                        <div className="row-space-between p-20 bt-2">
                            <InputSearch autoFocus value={pesquisaAcao} onChange={(value) => atualizarAcaoPesquisa(moduloAtual.id, value.target.value)} />
                            <InputSwitch
                                tamanho={'no-class'}
                                name={"acoes"}
                                size="md"
                                onChange={() => setTodasAcoesPorModulo(moduloAtual.id)}
                                checked={moduloAtual.acoes &&
                                    moduloAtual.acoes.
                                        filter(acao => removeAcentos(acao.descricao.toLowerCase()).includes(removeAcentos(pesquisaAcao.toLowerCase())) && acao.permitido)
                                        .length ==
                                    moduloAtual.acoes.
                                        filter(acao => removeAcentos(acao.descricao.toLowerCase()).includes(removeAcentos(pesquisaAcao.toLowerCase()))).length
                                }
                            />
                        </div>
                        {moduloAtual.acoes && moduloAtual.acoes.
                            filter(item => !item.acao_pai_id && removeAcentos(item.descricao.toLowerCase()).includes(removeAcentos(pesquisaAcao.toLowerCase())))
                            .map(item => {
                                const subAcao = moduloAtual.acoes.filter(x => x.acao_pai_id === item.id);
                                return subAcao && subAcao.length > 0 ? <div className="row-space-between ptb-15 bb-1">
                                    <SimpleAccordion
                                        title={<span className="titulo-acao">{item.descricao}</span>}
                                        cardClass={'total-width'}
                                        content={subAcao.map(x => {
                                            return <div className="row-space-between ptb-15 plr-20 bb-1">
                                            <span className="titulo-acao">{x.descricao}</span>
                                            <InputSwitch
                                                tamanho={x.id}
                                                name={"acao-" + x.id}
                                                size="md"
                                                checked={x.permitido}
                                                onChange={() => setAcao(moduloAtual.id, x.id)} />
                                        </div>
                                        })}
                                    />
                                </div> :
                                    <div className="row-space-between ptb-15 plr-20 bb-1" style={item.acao_pai_id ? { backgroundColor: '#f0f0f1' } : {}}>
                                        <span className="titulo-acao">{item.descricao}</span>
                                        <InputSwitch
                                            tamanho={item.id}
                                            name={"acao-" + item.id}
                                            size="md"
                                            checked={item.permitido}
                                            onChange={() => setAcao(moduloAtual.id, item.id)} />
                                    </div>
                            })}
                    </div>
                </ModalCadastro>
            </Form>
        </Formik>
    )
}

const CadastroGrupoModal = (props) => {
    let { id } = useParams()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [validacao] = useState(Yup.object({
        descricao: Yup.string()
            .required().trim()
            .max(200)
    }))

    const manter = []

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        salvar(
            values,
            '/controleAcesso/grupo',
            (obj) => {
                mostrarToast('sucesso', mensagens.sucesso)
                props.setItemSelecionado({ value: obj.id, label: obj.descricao })

                if (salvarENovo) {
                    propsFormik.resetForm({})
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                }
                else
                    props.setShow(false);
            },
            () => {
                setSalvando(false)
            })
    }

    useEffect(() => {
        setValoresIniciais({
            descricao: ''
        })
    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>

                <div className='row'>
                    <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' obrigatorio={false} />
                    <InputPadrao autoFocus label='Descrição' name='descricao' type='text' classeTooltip={props.modal && 'tooltip-input-modal'}
                        placeholder='Descrição' obrigatorio={true} tamanho='col-12' salvarENovo={salvarENovo} />
                </div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
            </Form>)}
        </Formik>
    );
}

export default Cadastro
export { CadastroGrupoModal }