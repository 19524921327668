import { InputPadrao, InputNumero, InputMonthPicker, TextArea, InputDatePicker } from '../../../../components/Inputs'
import { InputEmpresa, InputOperadoraSaude, InputTipoGuia, InputTipoExecutante, InputLoteStatus, InputContrato, InputGuiaStatus, InputSocio, InputContratoFiltro } from '../../../../components/InputsAutoComplete'
import { InputUsuario } from '../../../../components/InputsAutoCompleteV2'
import React, { useState, useEffect } from 'react'
import Yup, { validaData } from '../../../../util/Validacoes'
import { HeaderCadastro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form } from 'formik'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get, post } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'
import { ModalCadastro } from '../../../../components/Modais'
import { toast } from 'react-toastify';
import { getUsuario } from '../../../../util/Configuracoes'
import { ACOES, SERVICE } from '../../../../util/Enums'
import moment from 'moment'
import { obterTextoPorUrl } from '../../../../util/FuncoesComuns'
import { InputDateTimePicker } from '../../../../components/InputsV2'


export default props => {
    let { id } = useParams()
    const date = new Date()

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [possuiPermissaoAlterar, setPossuiPermissaoAlterar] = useState(false)

    const [valoresIniciais, setValoresIniciais] = useState({
        numero_lote: date.getFullYear().toString() + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0') +
            date.getHours().toString().padStart(2, '0') + date.getMinutes().toString().padStart(2, '0') +
            Math.floor(Math.random() * 101).toString().padStart(3, '0'),
        quantidade_guia: 99,
        referencia: moment().format('MM/YYYY')
    })
    const [mudouStatus, setMudouStatus] = useState(false)

    useEffect(_ => {
        Promise.resolve(get('/controleAcesso/permissao', { usuario_id: getUsuario().id, modulo_id: 35, acao_id: 58 })).then(function (val) {
            setPossuiPermissaoAlterar(val.length > 0)
        }).catch(e => console.log('ERRO OBTER PERMISSÃO', e))
    }, [])


    const [validacao] = useState(
        Yup.lazy(({ status_lote }) => Yup.object({
            referencia: Yup.string().required()
                .test('Referência', 'Referência inválida!', function (value) { return validaData(this.parent.referencia ? '01/' + this.parent.referencia : this.parent.referencia) }),
            operadora_saude: Yup.string().nullable(),
            empresa: Yup.string().nullable(),
            guia_tipo: Yup.string().nullable(),
            numero_lote: Yup.string().nullable().required().max(15),
            protocolo: Yup.string().nullable().max(15),
            quantidade_guia: Yup.string().nullable(),
            tipo_executante: Yup.string().nullable(),
            status_lote: Yup.string().nullable(),
            justificativa_status: Yup.string()
                .test('Justificativa', 'Campo Obrigatório!', function (value) {
                    return !status_lote ? true : status_lote.value === this.parent.guia_lote_status_id ? true : !this.parent.guia_lote_status_id ? true : status_lote.value !== this.parent.guia_lote_status_id && !value ? false : true
                }),

        })
        ))

    const manter = ['referencia', 'operadora_saude', 'empresa']

    const Submit = (values, propsFormik) => {
        setSalvando(true)

        values.referencia = values.referencia && '01/' + values.referencia || values.referencia

        salvar(
            { ...values },
            '/faturamento/lote',
            obj => {
                if (props.modal) {
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, x === 'referencia' && (values[x].split('/')[1].toString() + '/' + values[x].split('/')[2].toString()) || values[x]))
                        propsFormik.setFieldValue('numero_lote', date.getFullYear().toString() + date.getMonth().toString().padStart(2, '0') +
                            date.getDate().toString().padStart(2, '0') + Math.floor(Math.random() * 101).toString().padStart(3, '0'))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else {
                        props.setItemSelecionado({ value: obj.id, label: obj.numero_lote })
                        props.setShow && props.setShow(false)
                    }
                }
                else {
                    mostrarToast('sucesso', mensagens.sucesso)
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, x === 'referencia' && (values[x].split('/')[1].toString() + '/' + values[x].split('/')[2].toString()) || values[x]))
                        propsFormik.setFieldValue('numero_lote', date.getFullYear().toString() + date.getMonth().toString().padStart(2, '0') +
                            date.getDate().toString().padStart(2, '0') + Math.floor(Math.random() * 101).toString().padStart(3, '0'))
                        setSalvando(false)
                    }
                    else
                        props.history.push('/faturamento/lote/consulta')
                }
            },
            () => setSalvando(false))
    }

    useEffect(_ => {
        if (!props.modal && id)
            Promise.resolve(get(`/faturamento/lote/${id}`)).then(obj => {
                console.log('OBJETO ', obj)
                if (!obj.id)
                    props.history.push('/faturamento/lote/consulta')

                obj.referencia = obj.referencia && obj.referencia.split('/')[1] + '/' + obj.referencia.split('/')[2] || obj.referencia
                setValoresIniciais(obj)
            })
    }, [id])

    const changeStatus = option => option && option.value !== valoresIniciais.guia_lote_status_id ? setMudouStatus(true) : setMudouStatus(false)


    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Faturamento</span>,
        () => <Link className='link-active' to={'/faturamento/lote/consulta'}>Lote</Link>,
    ]

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => {
                return (<Form>
                    {!props.modal && <HeaderCadastro titulo={"Cadastro Lote"} link={links} />}
                    <div className={props.modal && props.modal || 'card mb-20'}>
                        <div className={props.modal || 'card-body'}>
                            <div className='row'>
                                <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' />
                                <InputPadrao label='Número' name='numero_lote' type='text' placeholder='Número' obrigatorio={true} autoFocus focarAposSubmit={true}
                                    tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} salvarENovo={salvarENovo} />
                                <InputMonthPicker label="Referência" name="referencia" disabled={!possuiPermissaoAlterar} type="text" setarValor={value => { propsFormik.setFieldValue("referencia", value) }}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} max={new Date().getFullYear() + 5} min={new Date().getFullYear() - 5} obrigatorio={true}
                                    tamanho={!props.modal && 'col-lg-3' || 'col-lg-12'} />
                                <InputTipoGuia label="Tipo Guia" name="guia_tipo" propsFormik={propsFormik} tamanho={!props.modal && 'col-lg-3' || 'col-lg-12'} />
                                <InputNumero label='Qtd. Guias' propsFormik={propsFormik} name='quantidade_guia' classDiv={!props.modal && 'col-lg-3' || 'col-lg-12'} max={100000}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputTipoExecutante label="Tipo Executante" name="tipo_executante" placeholder="Tipo Executante" propsFormik={propsFormik} tamanho={!props.modal && 'col-lg-3' || 'col-lg-12'}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <InputLoteStatus label="Status" name="status_lote" placeholder="Status" propsFormik={propsFormik} tamanho={!props.modal && 'col-lg-3' || 'col-lg-12'}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} onChange={option => { propsFormik.setFieldValue('status_lote', option); changeStatus(option) }} />
                                {mudouStatus && id && <TextArea rows={2} propsFormik={propsFormik} label={'Justificativa'} obrigatorio={true} name='justificativa_status' type='text' placeholder='Justificativa' tamanho={'col-lg-6 p-0'} classeTooltip={'tooltip-input-modal'} />}
                                <InputOperadoraSaude label="Operadora Saúde" name="operadora_saude" propsFormik={propsFormik} tamanho={!props.modal && 'col-lg-6' || 'col-lg-12'}
                                    botaoVisivel={true} modal={true} />
                                <InputContratoFiltro label="Contrato" name="contrato" propsFormik={propsFormik} tamanho={!props.modal && 'col-lg-6' || 'col-lg-12'} />
                                <InputEmpresa label="Empresa" name="empresa" placeholder="Empresa" classeTooltip={props.modal && 'tooltip-input-modal'} botaoVisivel={true} modal={true}
                                    propsFormik={propsFormik} tamanho={!props.modal && 'col-lg-2' || 'col-lg-12'} />
                                <InputPadrao label='Protocolo' name='protocolo' type='text' placeholder='Protocolo' tamanho={!props.modal && 'col-lg-4' || 'col-lg-12'}
                                    classeTooltip={props.modal && 'tooltip-input-modal'} />
                                <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />

                            </div>
                        </div>
                    </div>
                    {!props.modal && <div className='separator mt-40 mb-5'></div>}
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                        {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                    </div>
                </Form>)
            }}
        </Formik >
    )
}

const ModalGerarLotes = props => {



    const [salvando, setSalvando] = useState(false)
    const [possuiPermissaoAlterar, setPossuiPermissaoAlterar] = useState(false)
    const [validacao] = useState(Yup.object({
        referencia: Yup.string().required().nullable(),
        qtd_guias: Yup.number().required(),
        status: Yup.array().required().nullable(),
    }))
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [usuario, setUsuario] = useState({})

    useEffect(_ => {
        var user = getUsuario()
        console.log('user: ', user);
        Promise.resolve(get('/controleAcesso/permissao', { usuario_id: user.id, modulo_id: 35, acao_id: 58 })).then(function (val) {
            setPossuiPermissaoAlterar(val.length > 0)
        }).catch(e => console.log('EERO OBTER PERMISSÃO', e))

        var newValues = {
            referencia: moment().format('MM/YYYY'),
            tipo_guia: '',
            socio: '',
            qtd_guias: 99,
            tipo_pessoa: '',
            status: [{ value: 4, label: 'Conferida' }],
            operadora_saude: '',
            empresa: [{ value: 1, label: 'EA' }, { value: 2, label: 'ALFA' }, { value: 3, label: 'SAAG' }],
            contrato: '',
            data_digitacao_inicial: '',
            data_digitacao_final: '',
            data_procedimento_inicial: '',
            data_procedimento_final: '',
            data_inicial_ultima_alteracao: '',
            data_final_ultima_alteracao: '',
            usuario_alteracao: { value: user.id, label: user.nome }

        }
        setValoresIniciais(newValues)
        setUsuario(getUsuario())
    }, [])


    const Msg = (obj) => {
        return <>
            <div>
                <h6><b>{obj.obj.title}</b></h6>
                <span>{obj.obj.subtitle}</span>
            </div>
        </>
    }

    const gerarLotes = async (values, propsFormik) => {
        setSalvando(true)
        let params_formatted = {
            status: values?.status?.map(x => x?.value),
            empresa: values?.empresa ? values?.empresa?.map(x => x?.value) : null,
            referencia: moment(values?.referencia, 'MM/YYYY').format('DD/MM/YYYY'),
            tipo_pessoa: values?.tipo_pessoa?.value,
            tipo_guia: values?.tipo_guia?.value,
            socio: values?.socio?.value,
            qtd_guias: values?.qtd_guias,
            contrato: values?.contrato ? values?.contrato?.map(x => x?.value) : null,
            operadora_saude: values?.operadora_saude?.value,
            data_digitacao_inicial: values?.data_digitacao_inicial,
            data_digitacao_final: values?.data_digitacao_final,
            data_procedimento_inicial: values?.data_procedimento_inicial,
            data_procedimento_final: values?.data_procedimento_final,
            data_inicial_ultima_alteracao: values?.data_inicial_ultima_alteracao,
            data_final_ultima_alteracao: values?.data_final_ultima_alteracao,
            usuario_alteracao: values?.usuario_alteracao?.value,
            usuario_registro_id: getUsuario().id
        }
        props.rProps.obterWebService(JSON.stringify({ "integrationType": "mapa_horas", "Key": JSON.stringify(params_formatted), "usuarioId": getUsuario().id, "moduloId": 35, "acaoId": ACOES.GERAR_LOTES_AUTOMATICOS }), SERVICE.BATCH_OF_GUIDES, (async objRetorno => {
            let data = await obterRetornoLotesGerados(objRetorno)
            console.log('objRetorno: ', objRetorno);
            if (objRetorno.type === 'success')
                props.history.push({ pathname: '/faturamento/lote/visualizacaoLotesAutomaticos', target: '_blank', state: { infos: data } })

        }))

        setTimeout(_ => setSalvando(false), 4000)

    }

    const obterRetornoLotesGerados = async obj => {
        try {
            if (obj.type === 'error' || obj.type === 'info') {
                setSalvando(false)
                return;
            }
            return JSON.parse(await obterTextoPorUrl(obj?.url))
        }
        catch (e) {
            console.log('ERRO 233 LOTE CADASTRO', e)
        }
    }


    return (
        <>
            <ModalCadastro
                titulo={'Gerar Lotes'}
                show={props.showModalGerarLotes}
                setShowModal={props.setShowModalGerarLotes}
            >

                <>
                    <Formik
                        initialValues={valoresIniciais}
                        validationSchema={validacao}
                        enableReinitialize={true}
                        onSubmit={(values, propsFormik) => { gerarLotes(values, propsFormik) }
                            // ValidarSubmit(values, propsFormik)
                        }
                    >
                        {(propsFormik) => (
                            <div>
                                <Form>
                                    <div className="modal-body-cadastro">
                                        <div class="separator mt-20 mb-20"></div>
                                        <div className={'form-group col-12'}>

                                            <InputMonthPicker autoFocus={true} label="Referência" disabled={!possuiPermissaoAlterar} name="referencia" type="text" setarValor={value => { propsFormik.setFieldValue("referencia", value) }}
                                                classeTooltip={'tooltip-input-modal'} max={new Date().getFullYear() + 5} min={new Date().getFullYear() - 5}
                                                tamanho={'col-12 p-0'} obrigatorio />
                                            <InputTipoGuia tamanho="col-12 p-0" label="Tipo de Guia" name="tipo_guia" multiplo={false} placeholder="Tipo de Guia"
                                                propsFormik={propsFormik} botaoVisivel={true} modal />
                                            <InputNumero salvarENovo={''} label='Qtd. Guias' propsFormik={propsFormik} name='qtd_guias' classDiv='col-12 p-0' max={9999} obrigatorio />
                                            <InputSocio label="Sócio" name="socio" placeholder="Sócio" propsFormik={propsFormik} tamanho='col-12 p-0' />
                                            <InputTipoExecutante name='tipo_pessoa' label='Tipo Pessoa' placeholder='Tipo Pessoa' propsFormik={propsFormik}
                                                tamanho={'col-lg-12 p-0'} classeTooltip={'tooltip-input-modal'} />
                                            <InputGuiaStatus label={'Status'} name="status" propsFormik={propsFormik} tamanho={'col-12 p-0'} multiplo
                                                classeTooltip={'tooltip-input-modal'} placeholder="Status" obrigatorio />
                                            <InputOperadoraSaude label="Operadora Saúde" name="operadora_saude" propsFormik={propsFormik} tamanho={'col-12 p-0'} />
                                            <InputContratoFiltro label="Contrato" placeholder="Contrato" name="contrato" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} tamanho={'col-12 p-0'} multiplo />
                                            <InputEmpresa label="Empresa" name="empresa" placeholder="Empresa" multiplo classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} tamanho={'col-12 p-0'} />
                                            <InputUsuario label="Usuário Guia Alteração" name="usuario_alteracao" placeholder="Usuário Guia Alteração" classeTooltip={'tooltip-input-modal'} propsFormik={propsFormik} tamanho={'col-12 p-0'} />
                                            <InputDatePicker
                                                tamanho={'col-12 p-0'}
                                                label="Data Digitação Inicial"
                                                name="data_digitacao_inicial"
                                                type="text"
                                                setarValor={(value) => {
                                                    propsFormik.setFieldValue('data_digitacao_inicial', value)
                                                }}
                                                classeTooltip={'tooltip-input-modal'}
                                                mostrarFeriado={true}
                                            />
                                            <InputDatePicker
                                                tamanho={'col-12 p-0'}
                                                label="Data Digitação Final"
                                                name="data_digitacao_final"
                                                type="text"
                                                setarValor={(value) => {
                                                    propsFormik.setFieldValue('data_digitacao_final', value)
                                                }}
                                                classeTooltip={'tooltip-input-modal'}
                                                mostrarFeriado={true}
                                            />

                                            <InputDatePicker
                                                tamanho={'col-12 p-0'}
                                                label="Data Procedimento Inicial"
                                                name="data_procedimento_inicial"
                                                type="text"
                                                setarValor={(value) => {
                                                    propsFormik.setFieldValue('data_procedimento_inicial', value)
                                                }}
                                                classeTooltip={'tooltip-input-modal'}
                                                mostrarFeriado={true}
                                            />
                                            <InputDatePicker
                                                tamanho={'col-12 p-0'}
                                                label="Data Procedimento Final"
                                                name="data_procedimento_final"
                                                type="text"
                                                setarValor={(value) => {
                                                    propsFormik.setFieldValue('data_procedimento_final', value)
                                                }}
                                                classeTooltip={'tooltip-input-modal'}
                                                mostrarFeriado={true}
                                            />
                                            <InputDatePicker
                                                tamanho="col-12 p-0"
                                                label=" Data Inicial (Alteração)"
                                                name="data_inicial_ultima_alteracao"
                                                type="text"
                                                setarValor={value => {
                                                    propsFormik.setFieldValue("data_inicial_ultima_alteracao", value)
                                                }}
                                                classeTooltip={'tooltip-input-modal'}
                                                placeholder="Data Inicial (Alteração)"
                                            />
                                            <InputDatePicker
                                                tamanho="col-12 p-0"
                                                label="Data Final (Alteração)"
                                                name="data_final_ultima_alteracao"
                                                type="text"
                                                setarValor={value => {
                                                    propsFormik.setFieldValue("data_final_ultima_alteracao", value)
                                                }}
                                                classeTooltip={'tooltip-input-modal'}
                                                placeholder="Data Final (Alteração)"
                                            />
                                        </div>
                                    </div>
                                    <div className="separator mt-40 mb-2"></div>

                                    <div className="direita container-botoes mb-3 group-salvar-novo">
                                        <BotaoPadrao
                                            texto="Gerar"
                                            disabled={salvando}
                                            click={(_) => { }}
                                            idTooltip={'salvar'}
                                        />
                                    </div>
                                </Form>
                            </div>
                        )}
                    </Formik>
                </>
            </ModalCadastro>
        </>
    )
}
export { ModalGerarLotes }