import React, { useState, useEffect } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'

const Consulta = props => {
    const [data, setData] = useState([])

    useEffect(_ => { consultar('/faturamento/acomodacao', dados => setData(dados.sort((a, b) => b.id - a.id))) }, [props])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-acomodacao',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Descrição',
                    accessor: 'descricao',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Utiliza?',
                    accessor: 'utiliza',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Código TISS',
                    accessor: 'codigo_tiss',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Abreviação',
                    accessor: 'abreviacao',
                    ordenamento: {
                        tipo: 'texto'
                    }
                },
                {
                    Header: 'Ordenamento',
                    accessor: 'ordenamento',
                    ordenamento: {
                        tipo: 'numero'
                    }
                },
                {
                    Header: 'Codigo Cooperativa',
                    accessor: 'codigo_coopanest',
                    ordenamento: {
                        tipo: 'numero'
                    }
                },
                {
                    Header: 'Data de Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data',
                        idColuna: "data_registro",
                        datePicker: true,
                        date: true
                    }
                }
            ]
        }], []
    )

    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/faturamento/acomodacao' modulo={props.modulo}
                    titulo={'Consulta Acomodação'} tituloLista={'Lista Acomodação'}  caminhoConsultaFiltros= '/faturamento/acomodacaopaginacao'
                    urlCadastro='/faturamento/acomodacao/cadastro' icone={'icon-File-ClipboardFileText'} />
            </div>
        </>
    )
}

export default Consulta