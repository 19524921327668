import React, { useRef, useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint from 'react-to-print'
import { meses } from '../util/Utilitarios'
import moment from 'moment'
import { postComAcao } from '../util/AcessoApi'
import { ACOES } from '../util/Enums'
import { useLocation } from "react-router-dom"
import { Imprimir } from './Acoes'

export default React.forwardRef(({ ...props }, ref) => {
    const reference = useRef()
    const btnPrint = useRef()
    const [dadosRecibo, setDadosRecibo] = useState(null)
    const location = useLocation()
    const actualDate = new Date()

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const dataStr = query.get('data')
        const data = JSON.parse(decodeURIComponent(dataStr))

        console.log(data)

        postComAcao(ACOES.RECIBO, 116, data, '/financeiro/recibo',
            (obj) => { console.log(obj); setDadosRecibo(obj) },
            (erro) => { })
    }, [])

    return dadosRecibo ? (
        <div className="card margin-recibo" ref={reference}>
            <ReactToPrint
                trigger={() => <button className={"hidden d-none"} ref={btnPrint} />}
                content={() => reference.current} pageStyle={`@page { size: ${props.orientacao || 'a4 auto'} }`} />
            {
                <div className="card-body">
                    {/* ---------------------------------IMPRESSAO--------------------------------- */}
                    <div className={'d-block-print'}>
                        <div className={'col-12 row mt-3'}>
                            <div className="col-8">
                                {<h5> Nº {dadosRecibo.lancamento_id}</h5>}
                            </div>
                            {dadosRecibo.tipo_assinatura === 'empresa' &&
                                <div className="col-4 text-right">
                                    <img alt='logo' className="logo-menu-equipe" src={require('../assets/img/logo-equipe.png')}></img>
                                </div>
                            }
                        </div>
                        <div className="flex-column content-center mb-80 ">
                            <label className='bold fs-20 uppercase'>Recibo</label>
                            <label className='bold fs-20'>{dadosRecibo.valor} </label>
                        </div>
                    </div>
                    {/* --------------------------------------TELA-------------------------------------- */}
                    <div className={'d-none-print colored'}>
                        <div className={'col-12 row mr-0 ml-0 mb-1 pr-0 pl-0'}>
                            <div className="col-12 row-space-between pr-0 pl-0">
                                <div>
                                </div>
                                <div className="text-right">
                                    <Imprimir action={() => btnPrint.current.click()} />
                                </div>
                            </div>
                            <div className="titulo-visualizacao">
                                <h5> Nº {dadosRecibo.lancamento_id}</h5>
                            </div>
                        </div>

                        {dadosRecibo.tipo_assinatura === 'empresa' &&
                            <div className={'col-12 row mt-3 mb-5'}>
                                <div className="col-12 text-right">
                                    <img alt='logo' className="logo-menu-equipe" src={require('../assets/img/logo-equipe.png')}></img>
                                </div>
                            </div>
                        }
                        <div className="flex-column content-center mb-80 ">
                            <label className='bold fs-20 uppercase'>Recibo</label>
                            <label className='bold fs-20'>{dadosRecibo.valor} </label>
                        </div>
                    </div>
                    {
                        dadosRecibo.guia_id > 0 ?
                            <div className={"dados-visualizacao lh-1-5"}>
                                {/* ---------------------------------COM GUIA--------------------------------- */}
                                <>
                                    <div className="row mb-40 mlr-0">
                                        <div className='fs-20 text-justify'>
                                            Recebi de <b className='uppercase'>{dadosRecibo.nome_pagador}</b>
                                            {dadosRecibo.cpf_pagador && ', CPF/CNPJ: '}{dadosRecibo.cpf_pagador && <b> {dadosRecibo.cpf_pagador}</b>}
                                            {dadosRecibo.endereco_pagador && ', residente em '}{dadosRecibo.endereco_pagador && <b>{dadosRecibo.endereco_pagador}</b>}
                                            {dadosRecibo.valor && ', a importância de '}{dadosRecibo.valor && <b className='uppercase'>{dadosRecibo.valor + ' (' + dadosRecibo.valor_escrito + ')'}</b>}{dadosRecibo.valor && ' em pagamento pelo atendimento'}
                                            {
                                                dadosRecibo.beneficiario_e_pagador && ' realizado em sua pessoa' ||
                                                <>
                                                    {dadosRecibo.nome_beneficiario && ' realizado em '}{dadosRecibo.nome_beneficiario && <b className='uppercase'>{dadosRecibo.nome_beneficiario}</b>}
                                                    {dadosRecibo.cpf_beneficiario && ' CPF: '}{dadosRecibo.cpf_beneficiario && <b>{dadosRecibo.cpf_beneficiario}</b>}
                                                </>
                                            }
                                            , que esteve sob meus cuidados profissionais
                                            {dadosRecibo.data_procedimento && ' na data '}{dadosRecibo.data_procedimento && <b>{dadosRecibo.data_procedimento}</b>}
                                            {dadosRecibo.estabelecimento_saude && ', no(a) '}{dadosRecibo.estabelecimento_saude && <b className='uppercase'>{dadosRecibo.estabelecimento_saude}</b>}
                                            {dadosRecibo.cirurgiao && ', com médico cirurgião Dr(a). '}{dadosRecibo.cirurgiao && <b className='uppercase'>{dadosRecibo.cirurgiao}</b>}.
                                        </div>
                                    </div>
                                    {dadosRecibo.procedimento && dadosRecibo.procedimento.length ?
                                        <>
                                            <div className="row fs-20 mb-10 mlr-0">
                                                PARA OS PROCEDIMENTOS:
                                            </div>
                                            <div className="row fs-20 flex-column mlr-0">
                                                {dadosRecibo.procedimento.map(x => <b>{x}</b>)}
                                            </div>
                                        </> : <></>}
                                    <div className="row-end mt-100 mlr-0">
                                        <label className='fs-20'>GOIÂNIA, {moment(dadosRecibo.data, 'DD/MM/YYYY').toDate().getDate().toString().padStart(2, '0') + ' de ' + (meses[moment(dadosRecibo.data, 'DD/MM/YYYY').toDate().getMonth() + 1] || meses.default) + ' de ' + moment(dadosRecibo.data, 'DD/MM/YYYY').toDate().getFullYear()}</label>
                                    </div>
                                    <div className="assinatura padding-100 pdb-0">
                                        <span className='linha-assinatura-recibo'>
                                            <span className='ptb-15'>Emitido por <b>{dadosRecibo.assinatura}</b></span>
                                        </span>
                                    </div>
                                    <div className='row-center'>
                                        {dadosRecibo.tipo_assinatura == 'empresa' && dadosRecibo.empresa && <label>{dadosRecibo.empresa}</label>}
                                        {dadosRecibo.endereco_assinatura && <label>{dadosRecibo.tipo_assinatura == 'empresa' && dadosRecibo.empresa && ', '}{dadosRecibo.endereco_assinatura}</label>}
                                        {dadosRecibo.telefone_assinatura && <label>, {dadosRecibo.telefone_assinatura}</label>}
                                    </div>
                                </>
                            </div>
                            :
                            <div className={"dados-visualizacao lh-1-5"}>
                                {/* ---------------------------------SEM GUIA--------------------------------- */}
                                <>
                                    <div className="row mb-40 mlr-0">
                                        <div className='fs-20 text-justify'>
                                            Recebi de <b className='uppercase'>{dadosRecibo.nome_pagador}</b>
                                            {dadosRecibo.cpf_pagador && ', CPF/CNPJ: '}{dadosRecibo.cpf_pagador && <b> {dadosRecibo.cpf_pagador}</b>}
                                            {dadosRecibo.endereco_pagador && ', residente em '}{dadosRecibo.endereco_pagador && <b>{dadosRecibo.endereco_pagador}</b>}
                                            {dadosRecibo.valor && ', a importância de '}{dadosRecibo.valor && <b className='uppercase'>{dadosRecibo.valor + ' (' + dadosRecibo.valor_escrito + ')'}</b>}{dadosRecibo.valor && ' em pagamento pelo atendimento'}
                                            {dadosRecibo.estabelecimento_saude && ', no(a) '}{dadosRecibo.estabelecimento_saude && <b className='uppercase'>{dadosRecibo.estabelecimento_saude}</b>}.
                                        </div>
                                    </div>
                                    <div className="row-end mt-100 mlr-0">
                                        <label className='fs-20'>GOIÂNIA, {moment(dadosRecibo.data, 'DD/MM/YYYY').toDate().getDate().toString().padStart(2, '0') + ' de ' + (meses[moment(dadosRecibo.data, 'DD/MM/YYYY').toDate().getMonth() + 1] || meses.default) + ' de ' + moment(dadosRecibo.data, 'DD/MM/YYYY').toDate().getFullYear()}</label>
                                    </div>
                                    <div className="assinatura padding-100 pdb-0">
                                        <span className='linha-assinatura-recibo'>
                                            <span className='ptb-15'>Emitido por <b>{dadosRecibo.assinatura}</b></span>
                                        </span>
                                    </div>
                                    <div className='row-center'>
                                        {dadosRecibo.empresa && <label>{dadosRecibo.empresa}</label>}
                                        {dadosRecibo.endereco_assinatura && <label>, {dadosRecibo.endereco_assinatura}</label>}
                                        {dadosRecibo.telefone_assinatura && <label>, {dadosRecibo.telefone_assinatura}</label>}
                                    </div>
                                </>
                            </div>
                    }



                </div>
            }
        </div>
    ) : null
})