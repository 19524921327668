import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaCNPJ } from '../../../../util/Validacoes';
import { InputPadrao, InputTelefone, TextArea } from '../../../../components/Inputs'
import { mostrarToast } from '../../../../components/Toasts'
import { BotaoPadrao } from '../../../../components/Botoes'
import { useParams } from "react-router-dom";
import { salvar, get, validar } from '../../../../util/AcessoApi'
import axios from 'axios'
import { mensagens, ajustarDadosTelefone } from '../../../../util/Utilitarios'
import 'moment/locale/pt-br'
import { InputMunicipio, InputSimNao, InputTipoEstabelecimento } from '../../../../components/InputsAutoComplete'
import { Link } from 'react-router-dom'
import { HeaderCadastro } from '../../../../components/Headers'
import Atalho from '../../../../util/Atalho'
import { ModalDuasOpcoes } from '../../../../components/Modais'

const manter = ['estabelecimento_saude_urgencia', 'tipo_estabelecimento']

const CadastroHospital = (props) => {
    const [salvarENovo, setSalvarENovo] = useState(false)
    let { id } = useParams();
    const [isSubmit, setIsSubmit] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [dataTelefone, setDataTelefone] = useState([])
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    let validacaoInicial = Yup.object().shape({
        razao_social: Yup.string().trim().required().max(100).nullable(),
        nome_fantasia: Yup.string().trim().required().max(100).nullable(),
        cep: Yup.string().max(100).nullable(),
        logradouro: Yup.string().max(100).nullable(),
        complemento: Yup.string().max(70).nullable(),
        numero: Yup.string().max(30).nullable(),
        bairro: Yup.string().max(60).nullable(),
        municipio: Yup.string().required().nullable(),
        telefone: Yup.string(),
        email: Yup.string().nullable().max(200).email(),
        site: Yup.string().nullable().max(100),
        cnpj: Yup.string().nullable()
            .max(20).test('CNPJ', 'CNPJ inválido!', function (value) {
                if (this.parent.cnpj)
                    return validaCNPJ(this.parent.cnpj)
                else
                    return true;
            }),
        cnes: Yup.string().nullable().trim().max(100),
        diretor_clinico: Yup.string().nullable().max(100),
        diretor_tecnico: Yup.string().nullable().max(100),
        diretor_administrativo: Yup.string().nullable().max(100),
        estabelecimento_saude_urgencia: Yup.string().nullable().max(100),
        tipo_estabelecimento: Yup.string().nullable().max(100),
        ativo: Yup.string().nullable(),
        observacao: Yup.string().nullable()
    }, ['cnes', 'cnpj'])

    const { history } = props

    const buscarCep = (e, propsFormik) => {
        if (propsFormik.values.cep && propsFormik.values.cep.length > 0)
            axios.get('https://viacep.com.br/ws/' + propsFormik.values.cep + '/json/')
                .then(function (response) {
                    if (response.data.erro) {
                        mostrarToast('erro', 'CEP não encontrado')
                        return;
                    }
                    propsFormik.setFieldValue('logradouro', response.data.logradouro)
                    let obj = {
                        value: response.data.ibge,
                        label: response.data.localidade.toString().toUpperCase() + " - " + response.data.uf
                    }
                    propsFormik.setFieldValue('municipio', obj)
                    propsFormik.setFieldValue('bairro', response.data.bairro)
                });
    }

    useEffect(() => {
        if (id) {
            Promise.resolve(get('/administracao/estabelecimentoSaude/' + id)).then(function (obj) {
                if (obj.length === 0)
                    history.push('/administracao/estabelecimentoSaude/consulta')

                setDataTelefone(obj.fone)
                setValoresIniciais(obj)
            })
        } else {
            setValoresIniciais({
                razao_social: "",
                nome_fantasia: "",
                cnpj: "",
                cnes: "",
                telefone: "",
                email: "",
                site: "",
                diretor_administrativo: '',
                diretor_tecnico: '',
                diretor_clinico: '',
                tipo_estabelecimento: '',
                estabelecimento_saude_urgencia: { value: 'false', label: 'Não' },
                ativo: { value: 'true', label: 'Sim' }
            })
        }

    }, [history, id])

    const ValidarSubmit = (values, propsFormik) => {
        setIsSubmit(true)
        validar('/administracao/validarCnpjCnes', values, () => Submit(values, propsFormik), (e) => {
            setShowModalValidacao(true)
            setMensagemModal(e.response.data.alertas[0])
            setIsSubmit(false)
        })
    }

    const funcaoAposSim = (formik) => Submit(formik.values, formik.resetForm)

    const Submit = (values, propsFormik) => {
        values.telefone = dataTelefone.length > 0 ? dataTelefone : values.telefone;
        values.fone = ajustarDadosTelefone(values.telefone)
        setIsSubmit(true)
        salvar(values, '/administracao/estabelecimentoSaude', (ret) => {
            mostrarToast('sucesso', mensagens.sucesso)

            if (salvarENovo) {
                propsFormik.resetForm({})
                setDataTelefone([])
                manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                setIsSubmit(false)
                setSalvarENovo(false)
            }
            else
                history.push('/administracao/estabelecimentoSaude/consulta')
        }, () => {
            setIsSubmit(false)
        })
    }

    if (valoresIniciais.nome_fantasia === undefined)
        return <></>
    else
        return (
            <Formik
                initialValues={valoresIniciais}
                validationSchema={validacaoInicial}
                enableReinitialize={true}
                onSubmit={(values, propsFormik) => ValidarSubmit(values, propsFormik)}>
                {propsFormik => (
                    <Form className={props.modal != undefined ? "full" : ""}>
                        <div className={props.modal && props.modal || 'card mb-20'}>
                            <div className={props.modal || 'card-body'}>
                                <div className="row">
                                    <InputPadrao
                                        tamanho="col-0"
                                        label="Id"
                                        name="id"
                                        type="hidden"
                                        placeholder="Id" obrigatorio="false" />
                                    <InputPadrao
                                        tamanho="col-lg-4 "
                                        label="Razão Social"
                                        name="razao_social"
                                        type="text"
                                        placeholder="Razão Social" obrigatorio="true"
                                        autoFocus
                                        salvarENovo={salvarENovo} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputPadrao
                                        tamanho="col-lg-4 "
                                        label="Nome Fantasia"
                                        name="nome_fantasia"
                                        type="text"
                                        placeholder="Nome Fantasia" obrigatorio="true" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputPadrao
                                        mascara="11.111.111/1111-11"
                                        tamanho="col-lg-2 "
                                        label="CNPJ"
                                        name="cnpj"
                                        type="text"
                                        placeholder="CNPJ" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputPadrao
                                        tamanho="col-lg-2 "
                                        label="Identificação"
                                        name="codigo"
                                        type="text"
                                        placeholder="Código"
                                    />
                                    <InputPadrao
                                        tamanho="col-lg-2 "
                                        label="CNES"
                                        name="cnes"
                                        type="text"
                                        placeholder="CNES"
                                    />
                                    <InputPadrao
                                        mascara="11111-111"
                                        tamanho="col-lg-2 col-sm-6"
                                        label="CEP"
                                        name="cep"
                                        type="text"
                                        placeholder="CEP" onBlur={e => buscarCep(e, propsFormik)} classeTooltip={props.modal && 'tooltip-input-modal'}
                                    />
                                    <InputPadrao
                                        tamanho="col-lg-4 col-sm-6"
                                        label="Logradouro"
                                        name="logradouro"
                                        type="text"
                                        placeholder="Logradouro" classeTooltip={props.modal && 'tooltip-input-modal'}
                                    />
                                    <InputPadrao
                                        tamanho="col-lg-4 col-sm-6"
                                        label="Complemento"
                                        name="complemento"
                                        type="text"
                                        placeholder="Complemento"
                                    />
                                    <InputPadrao
                                        tamanho="col-lg-2 col-sm-6"
                                        label="Número"
                                        name="numero"
                                        type="text"
                                        placeholder="Número" classeTooltip={props.modal && 'tooltip-input-modal'}
                                    />
                                    <InputPadrao
                                        tamanho="col-lg-2 col-sm-6"
                                        label="Bairro"
                                        name="bairro"
                                        type="text"
                                        placeholder="Bairro" classeTooltip={props.modal && 'tooltip-input-modal'}
                                    />
                                    <InputMunicipio tamanho="col-lg-3 col-sm-12" label='Município - UF' name='municipio' placeholder='Município - UF' obrigatorio={true}
                                        propsFormik={propsFormik} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputTelefone
                                        tamanho="col-lg-2 col-sm-6"
                                        label="Telefone"
                                        name="telefone"
                                        type="text"
                                        placeholder="Telefone"
                                        obrigatorio="true"
                                        data={dataTelefone}
                                        setData={setDataTelefone} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputPadrao
                                        tamanho="col-lg-3 "
                                        label="E-mail"
                                        name="email"
                                        type="text"
                                        placeholder="E-mail" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputPadrao
                                        tamanho="col-lg-2 "
                                        label="Site"
                                        name="site"
                                        type="text"
                                        placeholder="Site" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputSimNao
                                        isClearable={true}
                                        tamanho="col-lg-2 "
                                        label="Urgência?"
                                        name="estabelecimento_saude_urgencia"
                                        type="select"
                                        propsFormik={propsFormik}
                                        placeholder="Urgência?" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputPadrao
                                        tamanho="col-lg-4 "
                                        label="Diretor Clínico"
                                        name="diretor_clinico"
                                        type="select"
                                        placeholder="Diretor Clínico" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputPadrao
                                        tamanho="col-lg-4 "
                                        label="Diretor Técnico"
                                        name="diretor_tecnico"
                                        type="select"
                                        placeholder="Diretor Técnico" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputPadrao
                                        isClearable={true}
                                        tamanho="col-lg-3"
                                        label="Diretor Administrativo"
                                        name="diretor_administrativo"
                                        type="select"
                                        placeholder="Diretor Administrativo" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputTipoEstabelecimento
                                        isClearable={true}
                                        tamanho="col-lg-3 "
                                        label="Tipo Estabelecimento"
                                        name="tipo_estabelecimento"
                                        type="select"
                                        propsFormik={propsFormik}
                                        placeholder="Tipo Estabelecimento" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputSimNao
                                        isClearable={true}
                                        tamanho="col-lg-2 "
                                        label="Ativo?"
                                        name="ativo"
                                        type="select"
                                        propsFormik={propsFormik}
                                        placeholder="Ativo?" classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <TextArea label='Observação' name='observacao' type='text' placeholder='Observação' tamanho={'col-lg-4'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <Atalho formik={propsFormik} salvando={isSubmit} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                                </div>
                            </div>
                        </div>
                        {!props.modal && <div className='separator mt-40 mb-5'></div>}
                        <div className='direita container-botoes mb-3 group-salvar-novo'>
                            <BotaoPadrao texto='Salvar' disabled={isSubmit} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                            {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={isSubmit} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                        </div>
                        <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => { setShowModalValidacao(false); setIsSubmit(false) }}
                            esconderFechar mensagem={mensagemModal}
                            textoOpcao1={'Cancelar'}
                            classeOpcao1={'btn-sm btn-primary-modal simples'}
                            textoOpcao2={'Continuar'} />
                    </Form>
                )}
            </Formik >
        )
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Administração</Link>,
        () => <Link className="link-active" to={'/administracao/estabelecimentoSaude/consulta'}>Estabelecimento de Saúde</Link>,
    ]

    return (<>
        {!props.modal && <HeaderCadastro titulo={"Cadastro Estabelecimento de Saúde"} link={links} />}
        <CadastroHospital {...props} />
    </>)
}

export { CadastroHospital }
export default Cadastro