import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaData, periodoData, validaHora } from '../../../../util/Validacoes';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { ModalDuasOpcoes, ModalEscolha } from '../../../../components/Modais';
import { get, salvar, validar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao, InputDatePicker, TextArea, InputTimePicker, InputSwitch } from '../../../../components/Inputs'
import { InputSocio, InputTipoOcorrencia, InputSimNao, InputRecorrencio } from '../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import '../../../../assets/css/icones/iconsmind/style.css'
import InputArquivoS3 from '../../../../components/InputArquivoS3'
import { MODULO } from '../../../../util/Enums'
import Atalho from '../../../../util/Atalho'
import moment from "moment"
import { InputEstabelecimentoSaude } from '../../../../components/InputsAutoCompleteV2';


const CadastroSocioOcorrencia = (props) => {
    let { id } = useParams()

    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [objAlteracao, setObjAlteracao] = useState({})
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [showModalInsercaoMultiplo, setShowModalInsercaoMultiplo] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [dadosHierarquia, setDadosHierarquia] = useState({})
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [dataArquivos, setDataArquivos] = useState(null)
    const [limparArquivos, setLimparArquivos] = useState(false)
    const [carregandoAlteracao, setCarregandoAlteracao] = useState(false)
    const [qtdInsercao, setQtdInsercao] = useState(0)
    const [showModalInterAgenda, setShowModalInterAgenda] = useState(false)
    const [msgValidacao, setMsgValidacao] = useState(<></>)

    let validacaoInicial = Yup.object({
        socio: Yup.string().nullable().required(),
        tipo_ocorrencia: Yup.string().nullable().required(),
        inicio: Yup.string().nullable().required().test('Data Início', 'Data inválida!', function (value) { return validaData(this.parent.inicio) }).test('Data Inicial', 'Data inicial deve ser menor ou igual a Data Final!', function (value) { return periodoData(this.parent.inicio, this.parent.termino) }),
        termino: Yup.string().nullable().test('Data Término', 'Data inválida!', function (value) { return validaData(this.parent.termino) }).test('Data Término', 'Preencha o Término.', function (value) {
            if (this.parent?.recorrencia) {
                return true
            }
            else if (!this.parent.recorrencia && this.parent.termino) {

                return true
            } else
                return false
        }),
        observacao: Yup.string().nullable(),
        implica_hierarquia: Yup.string().nullable().required(),
        hora_inicio: Yup.string().nullable().test('Hora Inícial', 'Hora Inválida!', function (value) { return validaHora(this.parent.hora_inicio) }),
        hora_final: Yup.string().nullable().test('Hora Final', 'Hora Inválida!', function (value) { return validaHora(this.parent.hora_final) }),
        arquivo: Yup.string().nullable(),
        recorrencia: Yup.object().nullable(),

    })

    const validarSubmit = (values, propsFormik, nValidacao) => {
        setSalvando(true)
        switch (nValidacao) {
            case 1:
                PrimeiraValidacao(values, propsFormik)
                break;
            case 2:
                validarMultiplos(values, propsFormik)
                break;
            case 3:
                ValidarInterposicaoAgenda(values, propsFormik)
                break;
            default:
                PrimeiraValidacao(values, propsFormik)
                break;
        }
    }

    const ValidarInterposicaoAgenda = (values, propsFormik) => {
        validar('/administracao/validarOcorrenciaAgendamento', { modulo: 17, ...values }, () => Submit(values, propsFormik), (e) => {
            setShowModalInterAgenda(true)
            setMsgValidacao(<><div dangerouslySetInnerHTML={{ __html: e.response.data.alertas[0].replace(/"/g, '') }} /></>)
            setSalvando(false)
        })
    }

    const PrimeiraValidacao = (values, propsFormik) => {
        validar('/administracao/socio-ocorrencia', values, () => validarSubmit(values, propsFormik, 2), (e) => {
            setShowModalValidacao(true)
            setMensagemModal(e.response.data.alertas[0])
            setSalvando(false)
        })
    }

    const validarMultiplos = (values, propsFormik) => {
        const inicio = moment(values.inicio, 'DD/MM/YYYY')
        const termino = values.termino.length > 0 ? moment(values.termino, 'DD/MM/YYYY') : undefined
        let horaInicial = values.hora_inicio && values.hora_inicio.length > 0 ? moment(values.hora_inicio, 'HH:mm') : undefined
        let horaFinal = values.hora_final && values.hora_final.length > 0 ? moment(values.hora_final, 'HH:mm') : undefined

        let diferenca = termino ? termino.diff(inicio, 'days') : 0;
        if (diferenca > 0 && values.implica_hierarquia && values.implica_hierarquia.value === 'false') {
            diferenca = horaInicial && horaFinal && horaFinal < horaInicial ? diferenca - 1 : diferenca;
            setQtdInsercao(diferenca + 1);
            setShowModalInsercaoMultiplo(true);
        } else {
            validarSubmit(values, propsFormik, 3)
        }
    }

    const funcaoAposSim = (formik) => {
        Submit(formik.values, formik.reseForm)
    }

    const manter = ['socio', 'tipo_ocorrencia', 'implica_hierarquia', 'interfere_agendamento']

    const Submit = (values, propsFormik) => {
        setSalvando(true)
        values.arquivo = dataArquivos

        salvar(
            values,
            '/administracao/socio-ocorrencia',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (salvarENovo) {
                    propsFormik.resetForm({})
                    manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                    setSalvando(false)
                    setSalvarENovo(false)
                    setLimparArquivos(true)
                    setShowModalInterAgenda(false)
                    setShowModalValidacao(false)
                }
                else
                    props.history.push('/administracao/socio-ocorrencia/consulta')
            },
            () => {
                setSalvando(false)
            })
    }

    useEffect(() => {
        if (!id)
            setDataArquivos([])
    }, [])

    useEffect(() => {
        if (id) {
            setCarregandoAlteracao(true);
            Promise.resolve(get(`/administracao/socio-ocorrencia/${id}`)).then(val => {
                setDataArquivos(val.arquivo)
                setObjAlteracao({ ...val })
                delete val.data_registro
                delete val.verificador
                delete val.dias_impactantes

                if (val.implica_hierarquia && val.implica_hierarquia.value === true) {
                    obterHierarquizacao(val.socio)
                }
                setValoresIniciais(val)
            })
        } else {
            setValoresIniciais({
                implica_hierarquia: { value: 'false', label: 'Não' },
                interfere_agendamento: true,
                recorrencia: undefined,
                estabelecimento_saude: undefined,
                inicio: "",
                termino: ""
            })
        }

    }, [id])

    const calcularDataNova = (propsFormik, data_hierarquia, diasImplica) => {
        if (id && carregandoAlteracao)
            setCarregandoAlteracao(false);

        const dataInicio = propsFormik.values.inicio
        const dataFim = propsFormik.values.termino || propsFormik.values.inicio
        let diferencaDias = diasImplica || moment(dataFim, 'DD/MM/YYYY')
            .diff(moment(dataInicio, 'DD/MM/YYYY'), 'days')
        let novaData = moment(data_hierarquia || dadosHierarquia.data_inicial, 'DD/MM/YYYY').add(diferencaDias || 1, 'days').format('DD/MM/YYYY')
        const novoDadosHierarquia = {
            data_inicial: data_hierarquia || dadosHierarquia.data_inicial,
            dias: diferencaDias || 1,
            data_final: novaData
        }
        if (id && objAlteracao.dias_impactantes && !diasImplica) {
            diferencaDias = propsFormik.values.termino === propsFormik.values.inicio ? 0 : (diferencaDias + 1) - objAlteracao.dias_impactantes
            novaData = moment(data_hierarquia || dadosHierarquia.data_inicial, 'DD/MM/YYYY').add(diferencaDias, 'days').format('DD/MM/YYYY')
            novoDadosHierarquia.dias = diferencaDias;
            novoDadosHierarquia.data_final = propsFormik.values.termino === propsFormik.values.inicio ? undefined : novaData;
        }
        setDadosHierarquia(novoDadosHierarquia)
        //apenas passará valor caso não venha dias impactantes

        if (!carregandoAlteracao && !diasImplica)
            propsFormik.setFieldValue("dias_impactantes", novoDadosHierarquia.dias)

        propsFormik.setFieldValue("nova_data_hierarquizacao", novoDadosHierarquia.data_final)
    }

    const obterHierarquizacao = (option, propsFormik) => {
        if (option && option.value) {
            Promise.resolve(get(`/administracao/socioHierarquizacao/${option.value}`)).then(val => {
                if (val.length > 0) {
                    const obj = val[0]

                    if (propsFormik && propsFormik.values.inicio)
                        calcularDataNova(propsFormik, obj.data_formatada)
                    else
                        setDadosHierarquia({
                            data_inicial: obj.data_formatada,
                            dias: '--',
                            data_final: '--'
                        })
                }
            })
        } else {
            setDadosHierarquia({
                data_inicial: '--',
                dias: '--',
                data_final: '--'
            })
        }
    }

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
            {propsFormik => (<Form>
                <div className={props.modal ? '' : 'card mb-20'}>
                    <div className={props.modal ? '' : 'card-body'}>
                        <div className='row'>
                            <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />

                            <InputSocio label="Sócio" name="socio" placeholder="Sócio" propsFormik={propsFormik} obrigatorio={true} tamanho='col-lg-4'
                                autoFocus onChangeValue={(option) => {
                                    if (propsFormik.values.implica_hierarquia && propsFormik.values.implica_hierarquia.value === 'true') {
                                        obterHierarquizacao(option, propsFormik)
                                        propsFormik.values.inicio && calcularDataNova(propsFormik)
                                    }
                                }}
                                salvarENovo={salvarENovo} />

                            <InputSimNao
                                label='Implica na Hierarquia?'
                                name='implica_hierarquia'
                                placeholder='Implica na Hierarquia?'
                                obrigatorio
                                botaoVisivel={true}
                                propsFormik={propsFormik}
                                onChangeValue={(option) => option.value && option.value === 'true' && obterHierarquizacao(propsFormik.values.socio, propsFormik)}
                                classeTooltip={'tooltip-input-modal'} tamanho="col-lg-2 col-md-5" />

                            <InputTipoOcorrencia label="Tipo Ocorrência" name="tipo_ocorrencia" propsFormik={propsFormik} obrigatorio={true} tamanho='col-lg-3' />


                            <InputRecorrencio isDisabled={(id && true) || propsFormik.values['termino'] ? true : false} label="Recorrência (Dentro do Mês)" name={'recorrencia'} propsFormik={propsFormik}
                                salvarENovo={salvarENovo} tamanho={'col-lg-3'} onChange={option => { propsFormik.setFieldValue('termino', ''); propsFormik.setFieldValue('recorrencia', option) }} />

                            <InputDatePicker tamanho="col-lg-2 col-md-7" label="Início" name="inicio" type="text" obrigatorio="true"
                                onChange={(data) => { propsFormik.values.inicio && calcularDataNova(propsFormik) }}
                                setarValor={value => {
                                    propsFormik.setFieldValue("inicio", value)
                                    propsFormik.values.inicio && calcularDataNova(propsFormik)
                                    if(!propsFormik.values?.recorrencia?.value) {
                                        propsFormik.setFieldValue("termino", value);

                                    }
                                }} classeTooltip={props.modal && 'tooltip-input-modal'}
                            />

                            <InputTimePicker
                                label="Hora Inicial" name="hora_inicio" type="text" placeholder="Inicial" tamanho={!props.modal && "col-lg-1 col-md-5"} classeTooltip={props.modal && 'tooltip-input-modal'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("hora_inicio", value)
                                }}
                            />

                            <InputDatePicker tamanho="col-lg-2 col-md-7" label="Término" name="termino" type="text" obrigatorio={!propsFormik.values.recorrencia}
                                disabled={
                                    valoresIniciais.recorrencia
                                        ? true
                                        : propsFormik.values['recorrencia']?.value
                                            ? true
                                            : false
                                }
                                onChange={(data) => { propsFormik.values.inicio && calcularDataNova(propsFormik) }}
                                setarValor={value => {
                                    propsFormik.setFieldValue("termino", value);
                                    propsFormik.setFieldValue('recorrencia', '')
                                    propsFormik.values.inicio && calcularDataNova(propsFormik)
                                }} classeTooltip={props.modal && 'tooltip-input-modal'}
                            />

                            <InputTimePicker
                                label="Hora Final" name="hora_final" type="text" placeholder="Final" tamanho={!props.modal && "col-lg-1 col-md-5"} classeTooltip={props.modal && 'tooltip-input-modal'}
                                setarValor={value => {
                                    propsFormik.setFieldValue("hora_final", value)
                                }}
                            />

                            <InputSwitch label='Interfere Agendamento/Plantão?' propsFormik={propsFormik} quebrarLinha classeDiv='form-group ajusta-switch ' name='interfere_agendamento' size="md" tamanho="col-lg-3 col-md-7" classeTooltip={props.modal && 'tooltip-input-modal'} />

                            <InputEstabelecimentoSaude array label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" classeTooltip={props.modal && 'tooltip-input-modal'} tamanho="col-xl-3 col-lg-6"  />

                            <TextArea label='Observação' name='observacao' type='text' placeholder='Observação' tamanho='col-lg-6' />
                            {dataArquivos != null ?
                                <InputArquivoS3
                                    label='Arquivo'
                                    name='arquivo'
                                    placeholder='Arquivo'
                                    tamanho='col-lg-6'
                                    diretorioS3='socio-ocorrencia/DDMMYYYY/'
                                    modulo={MODULO["/administracao/socio-ocorrencia"]}
                                    /*tiposAceitos={['image/png', '.pdf', 'audio/*']} */
                                    tamanhoMinimo={0}
                                    tamanhoMaximoEmMB={300}
                                    /*qtdMaximaArquivos={2} */
                                    setDataArquivos={setDataArquivos}
                                    dataArquivos={dataArquivos != null ? dataArquivos : []}
                                    setIsSubmit={dataArquivos.length > 0 ? setSalvando : () => { }}
                                    limparCampos={limparArquivos}
                                /> : <></>}

                            <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />
                            {dadosHierarquia.data_inicial && ((valoresIniciais.implica_hierarquia && valoresIniciais.implica_hierarquia.value === true) || (propsFormik.values.implica_hierarquia && propsFormik.values.implica_hierarquia.value === 'true')) ?
                                <>
                                    <div className={"form-group col-lg-3 col-md-4"}>
                                        <label>Data Hierarquização</label>
                                        <span style={{ display: 'flex' }}><b>{dadosHierarquia.data_inicial || '--'}</b></span>
                                    </div>

                                    <InputPadrao label="Dias Impactantes" name="dias_impactantes" tamanho="col-lg-3 col-md-3" type="decimal" placeholder="Dias Impactantes" onBlur={() => {
                                        calcularDataNova(propsFormik, undefined, propsFormik.values.dias_impactantes)
                                    }} />

                                    <InputDatePicker tamanho="col-lg-4 col-md-5" label="Nova Data Hierarquização" name="nova_data_hierarquizacao" type="text"
                                        setarValor={value => {
                                            propsFormik.setFieldValue("nova_data_hierarquizacao", value);
                                        }} classeTooltip={props.modal && 'tooltip-input-modal'}
                                    />
                                </>
                                : <></>}

                        </div>
                    </div>
                </div>
                <div className='separator mt-40 mb-5'></div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
                <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                    esconderFechar mensagem={mensagemModal}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
                <ModalEscolha showModal={showModalInsercaoMultiplo} setShowModal={setShowModalInsercaoMultiplo} esconderFechar mensagem={'Selecione uma opção:'}
                    confirmar={true} funcaoConfirmar={() => { validarSubmit(propsFormik.values, propsFormik, 3); setShowModalInsercaoMultiplo(false); }}
                    funcaoCancelar={() => { setShowModalInsercaoMultiplo(false); setSalvando(false) }}>
                    <ul class="lista-modal text-center width-100">
                        <div className='mt-1' />
                        <li className={propsFormik.values.tipo_insercao && propsFormik.values.tipo_insercao === 2 ? 'excluir-active' : ''} onClick={_ => propsFormik.setFieldValue('tipo_insercao', 2)}>
                            <div className='mb-1 li-titulo'>Criar {qtdInsercao} ocorrências.</div>
                        </li>
                        <div className='mt-1' />
                        <li className={!propsFormik.values.tipo_insercao || propsFormik.values.tipo_insercao === 1 ? 'excluir-active' : ''} onClick={_ => propsFormik.setFieldValue('tipo_insercao', 1)}>
                            <div className='mb-1 li-titulo'>Apenas uma ocorrência.</div>
                        </li>
                    </ul>
                </ModalEscolha>
                <ModalDuasOpcoes showModal={showModalInterAgenda} setShowModal={setShowModalInterAgenda}
                    funcaoOpcao2={() => Submit(propsFormik.values, propsFormik)}
                    funcaoOpcao1={() => { setSalvando(false); setShowModalInterAgenda(false) }}
                    esconderFechar mensagem={<>{<>{'Há Agenda(s)/Escala(s):'} <br /><br /></>} {msgValidacao}<br /></>}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
            </Form>)}
        </Formik>
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Administrativo</Link>,
        () => <Link className="link-active" to={'/administracao/socio-ocorrencia/consulta'}>Ocorrência</Link>,
    ]
    return (<>
        <HeaderCadastro titulo={"Cadastro de Ocorrência"} link={links} />
        <CadastroSocioOcorrencia {...props} />
    </>
    )
}
export { CadastroSocioOcorrencia }
export default Cadastro
