import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'
var format = require('xml-formatter');


export default props => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        visualizar('/controleproducao/referencia', id,
            obj => {

                setDados(obj.id ? {
                    id,
                    modulo: props.modulo,
                    titulo: 'Visualização Referencia Produção',
                    grupos: [
                        {
                            titulo: 'Dados Gerais',
                            colunas: [
                                { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-4' },
                                { titulo: 'Referência', descricao: obj.referencia, tamanho: 'col-lg-4' },
                                { titulo: 'Anotações', descricao: obj.anotacoes, tamanho: 'col-lg-4' },
                                { titulo: 'Data Registro', descricao: obj.data_registro, tamanho: 'col-lg-4' },
                            ]
                        },
                    ]
                } : { titulo: 'Visualização Referencia Produção' })

            })

    }, [id])


    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}
