import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { visualizar } from '../../../../util/AcessoApi'

const VisualizacaoDireitoFolga = (props) => {

    let { id } = useParams();
    const [dados, setDados] = useState({})

    useEffect(() => {
        if (id) {
            visualizar('/administracao/direitofolga', id,
                (obj) =>{
                    setDados(obj.id  ? {
                        id,
                        modulo: props.modulo,
                        titulo: 'Visualização Direito Férias',
                        grupos: [
                            {
                                titulo: 'Dados Gerais',
                                colunas: [
                                    { titulo: 'Código', descricao: obj.id, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Data Registro', descricao: obj.data_registro_formatada, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Sócio', descricao: obj.socio.label  , tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Mudança/Troca', descricao: obj.troca  , tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Data', descricao: obj.data_formatada, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6'},
                                    { titulo: 'Tipo Direito Folga ', descricao: obj.tipo_direito_folga.label, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Utilizado/Marcado ', descricao: obj.marcacao, tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6' },
                                    { titulo: 'Observação', descricao: obj.observacao || '---', tamanho: 'col-sm-12 col-md-6 col-lg-6 col-xl-6',html: true},
                                ]
                            },
                        ]
                    } : { titulo: "Visualização Direito Férias" })
                })
        }
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default VisualizacaoDireitoFolga