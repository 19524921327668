import React, { useState, useEffect, useRef } from 'react'
import DataGrid from '../../../../components/DataGrid'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar, getComAuditoria, post, postApiExterna, postComAcao, putComAcao } from '../../../../util/AcessoApi'
import { Alterar, Excluir, Visualizar, Imprimir, BtnAcao, Btn, Detalhes, AlteracaoStatusLote, EnviarLoteCoop, VisualizarArquivo, VisualizarErro, VisualizarRelacaoGuias, TrocarStatusNota, TrocarStatusEnvio, AlterarNota, EnviarNota, CancelarNota, VisualizarErroNota, SubstituirNota, VisualizarNota, AtualizarStatusNota, EnviarNotaEmail, VisualizarNotaFiscal } from '../../../../components/Acoes'
import { BotaoPadrao } from '../../../../components/Botoes'
import moment from 'moment';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { getUsuario, getApi } from '../../../../util/Configuracoes'
import { ACOES, MODULO } from '../../../../util/Enums'
import { get, excluir, salvar, validar } from '../../../../util/AcessoApi'
import { mostrarToast, mostrarToastNf } from '../../../../components/Toasts'
import { convertValueToFloat, formatarMoeda, mensagens, useWindowSize } from '../../../../util/Utilitarios.js'
import { InputPadrao, TextArea } from '../../../../components/Inputs'
import { InputLoteStatus } from '../../../../components/InputsAutoComplete'
import Yup from '../../../../util/Validacoes';
import { ModalCadastro, ModalDuasOpcoes } from '../../../../components/Modais'
import { Formik, Form } from "formik";
import { LoadRelatorio } from '../../../../components/Loading'
import { useHistory, Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { InputNotaFiscalEnviada, InputStatusEnvioNota, InputStatusNota } from '../../../../components/InputsAutoCompleteV2.js'
import { DetalhesNota } from './Visualizacao.js'



const ConsultaNotaFiscal = rProps => {
    const [data, setData] = useState([])
    const target = useRef(null)
    const [modalAlteracaoStatus, setModalAlteracaoStatus] = useState(false)
    const [itemTrocarStauts, setItemTrocarStatus] = useState({})
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [itemDetalhes, setItemDetalhes] = useState([])
    const [width] = useWindowSize()
    const history = useHistory()
    const [showModalStatusNota, setShowModalStatusNota] = useState(false)
    const [showModalStatusEnvio, setShowModalStatusEnvio] = useState(false)
    const [showModalSubstituirNota, setShowModalSubstituirNota] = useState(false)
    const [showModalCancelarNota, setShowModalCancelarNota] = useState(false)
    const [idNota, setIdNota] = useState(null)


    // useEffect(_ => { consultar('/faturamento/lote', dados => setData(dados.sort((a, b) => b.id - a.id))) }, [rProps])

    const columns = React.useMemo(
        () => [{
            id: 'consulta-lote',
            columns: [
                {
                    Header: 'Código',
                    accessor: 'id',
                    ordenamento: {
                        tipo: 'numero',
                        ativo: true,
                        modo: 'desc',
                    }
                },
                {
                    Header: 'Número',
                    accessor: 'numero',
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Código Verificação',
                    accessor: 'codigo_verificacao',
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Data Emissão',
                    accessor: 'data_emissao',
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_emissao',
                        idColuna: 'data_emissao',
                        datePicker: true,
                        date: true

                    }
                },
                {
                    Header: 'Tomador/Cliente',
                    accessor: 'cliente',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'cliente',
                        idColuna: 'cliente_id'
                    },
                },
                {
                    Header: 'Valor Lançamento',
                    accessor: 'valor_lancamento_formatado',
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Valor Tributos',
                    accessor: 'valor_tributo_formatado',
                    ordenamento: {
                        tipo: 'texto'
                    },
                },

                {
                    Header: 'Prestador/Empresa',
                    accessor: 'empresa',
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'empresa',
                        idColuna: 'empresa_id'
                    }
                },
                {
                    Header: 'Valor Nota',
                    accessor: 'valor_nota_formatado',
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Usuário Nota Fiscal',
                    accessor: 'usuario',
                    visivel: false,
                    apenasDataGrid: true,
                    filtro: {
                        tipo: 'usuario',
                        idColuna: 'usuario_id'
                    },
                    ordenamento: {
                        tipo: 'texto'
                    },
                },
                {
                    Header: 'Status Nota',
                    accessor: 'status_nota',
                    html: item => <OverlayTrigger
                        popperConfig={{
                            modifiers: {
                                preventOverflow: {
                                    enabled: false
                                }
                            }
                        }}
                        id={Math.random()}
                        placement="bottom"
                        delay={{ show: 25, hide: 25 }}
                        overlay={(props) => <Tooltip id={'tooltip ' + props.id ? props.id : 'tooltip ' + Math.random()} {...props}>{item.status_nota?.status}</Tooltip>}>
                        <div className='guia-status-ellipsis' style={{ background: item.status_nota?.cor }}>{item.status_nota?.status}</div>
                    </OverlayTrigger>,
                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'status_nota',
                        idColuna: 'nota_fiscal_status_id'
                    },
                },
                {
                    Header: 'Status Envio',
                    accessor: 'status_envio',
                    html: item => <OverlayTrigger
                        popperConfig={{
                            modifiers: {
                                preventOverflow: {
                                    enabled: false
                                }
                            }
                        }}
                        id={Math.random()}
                        placement="bottom"
                        delay={{ show: 25, hide: 25 }}
                        overlay={(props) => <Tooltip id={'tooltip ' + props.id ? props.id : 'tooltip ' + Math.random()} {...props}>{item.status_envio?.status}</Tooltip>}>
                        <div className='guia-status-ellipsis' style={{ background: item.status_envio?.cor || '#000' }}>{item.status_envio?.status}</div>
                    </OverlayTrigger>,

                    ordenamento: {
                        tipo: 'texto'
                    },
                    filtro: {
                        tipo: 'status_envio_nota',
                        idColuna: 'nota_fiscal_status_envio_id'
                    },

                },
                {
                    Header: 'Data Registro',
                    accessor: 'data_registro',
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_inicial',
                        idColuna: 'data_registro_inicial',
                        datePicker: true,
                        date: true

                    }
                },
                {
                    Header: 'Data Registro',
                    accessor: 'data_registro_final',
                    visivel: false,
                    apenasDataGrid: true,
                    ordenamento: {
                        tipo: 'data'
                    },
                    filtro: {
                        tipo: 'data_final',
                        idColuna: 'data_registro_final',
                        datePicker: true,
                        date: true

                    }
                },


            ]
        }], []
    )

    const alteracao = async (propsAcao) => {

        await validar('/financeiro/validarAlteracaoNota', { id: propsAcao.row.id }, (val) => {
            if (!val?.length) {
                history.push('/financeiro/notafiscal/alteracao/' + propsAcao.row.id)
            }
            else
                mostrarToast('erro', 'Operação não permitida! Lançamento Liquidado.')

        }, (e) => mostrarToast('erro', e.response.data.alertas[0]))
    }
    const visualizarNotaAcao = linha => {

        if (verificaNotaVisualizar(linha)) {
            window.open("http://www2.goiania.go.gov.br/sistemas/snfse/asp/snfse00200w0.asp?inscricao=" + linha.inscricao_municipal + "&nota=" + linha.numero + "&verificador=" + linha.codigo_verificacao, '_blank');

        } else if (linha.url_danfse)
            window.open(linha.url_danfse, '_blank');

    }
    const verificaNotaVisualizar = linha => {

        if (linha && (!linha.url_danfse && linha.codigo_verificacao && linha.numero && linha.inscricao_municipal) || linha.url_danfse)
            return true

        return false
    }
    const VisualizarNotaAcao = propsAcao => {
        if (!verificaNotaVisualizar(propsAcao?.row))
            return <Visualizar to={'/financeiro/notafiscal/visualizacao/' + propsAcao.id} />
        else
            return <VisualizarNotaFiscal action={_ => visualizarNotaAcao(propsAcao.row)} />
    }

    const acoesObj = [
        (propsAcao) => VisualizarNotaAcao(propsAcao),
        (propsAcao) => <AlterarNota action={() => { possuiPermissaoEnvioAlteracao(propsAcao.row) ? alteracao(propsAcao) : mostrarToast('erro', "Ação não permitida" + ` para nota com status ${propsAcao.row?.status_nota?.status}!`) }} />,
        (propsAcao) => <Excluir to={'financeiro/notafiscal/consulta/'} action={() => exclusao(propsAcao)} />,
        (propsAcao) => <OutrasAcoes propsAcao={propsAcao} />

    ]

    const acoesObj2 = [
        (propsAcao) => <OutrasAcoesTelasMenores propsAcao={propsAcao} />

    ]

    const OutrasAcoes = ({ propsAcao, ...props }) => {
        return (
            <Dropdown >
                <DropdownTrigger>
                    <Btn icone='icon-ellipsi' texto="Mais Ações" />
                </DropdownTrigger>
                <DropdownContent>
                    <div className="card-acoes-dropdown">
                        <div className="ajusta-dropdown-consulta-guia">
                            {[
                                <Detalhes action={() => {
                                    obterDetalhes(propsAcao.row.id)
                                    setShowModalDetalhes(true)
                                }} />,
                                <TrocarStatusNota texto={'Trocar Status Nota'} action={() => { setShowModalStatusNota(true); setIdNota(propsAcao.id) }} />,
                                <TrocarStatusEnvio texto={'Trocar Status Envio'} action={() => { setShowModalStatusEnvio(true); setIdNota(propsAcao.id) }} />,
                                <EnviarNotaEmail icone={'icon-Mail-Send'} texto={'Enviar Para Email'} action={() => {
                                    possuiPermissaoCancelarSubstituir(propsAcao.row) ? propsAcao.row?.tomador_email ? Promise.resolve(postComAcao(ACOES.ENVIAR_EMAIL_NOTA_FISCAL, 136, { email: [propsAcao.row?.tomador_email], id: propsAcao.id }, '/financeiro/enviarNotaEmail', val => { target.current.atualizar(); mostrarToast('sucesso', 'Nota enviada para o email com sucesso!') },
                                        erro => {
                                            mostrarToast('erro', erro.response.data?.alertas[0])
                                            target.current.atualizar()
                                        })) : mostrarToast('erro', "Email do Tomador não informado para realizar envio!")
                                        : mostrarToast('erro', "Ação não permitida" + ` para nota com status ${propsAcao.row?.status_nota?.status}!`)
                                }} />,
                                <EnviarNota texto={'Enviar Nota'} action={() => {
                                    possuiPermissaoEnvioAlteracao(propsAcao.row) ? postComAcao(ACOES.EMITIR_NOTA_FISCAL, 136, { id: propsAcao.id, usuario_id: getUsuario()?.id }, `/financeiro/emitirNota`,
                                        (val) => { target.current.atualizar(); mostrarToastNf(val?.codigo, val?.mensagem) },
                                        (erro) => {
                                            console.log('erro: ', erro);
                                            target.current.atualizar()
                                            mostrarToast('info', erro.response.data?.alertas[0])

                                        })
                                        : mostrarToast('erro', "Ação não permitida" + ` para nota com status ${propsAcao.row?.status_nota?.status}!`)
                                }
                                } />,
                                <AtualizarStatusNota texto={'Atualizar Nota'} action={() => {
                                    possuiPermissaoEnvioAlteracao(propsAcao.row) ? Promise.resolve(post(`/financeiro/verificarStatusNota`, { ref: propsAcao.id, usuario_id: getUsuario()?.id }).then((val) => { target.current.atualizar(); mostrarToastNf(val?.codigo, val?.mensagem) }).catch((erro) => {
                                        target.current.atualizar()
                                        if (erro.response.data?.alertas)
                                            mostrarToast('erro', erro.response.data?.alertas[0])

                                    }))
                                        : mostrarToast('erro', "Ação não permitida" + ` para nota com status ${propsAcao.row?.status_nota?.status}!`)
                                }} />,
                                <Link to={{ pathname: '/financeiro/notafiscal/visualizacaoErroNota/' + propsAcao.row.id }} target={'_blank'}><VisualizarErro action={() => { }} /></Link>,
                                <SubstituirNota icone={'icon-Arrow-Around'} action={() => { if (possuiPermissaoCancelarSubstituir(propsAcao.row)) { setShowModalSubstituirNota(true); setIdNota(propsAcao.id) } else mostrarToast('erro', "Ação não permitida para nota com status diferente de 'Enviada'!") }} />,
                                <CancelarNota icone={'icon-Delete-Window'} action={() => { if (possuiPermissaoCancelarSubstituir(propsAcao.row)) { setShowModalCancelarNota(true); setIdNota(propsAcao.id) } else mostrarToast('erro', "Ação não permitida para nota com status diferente de 'Enviada'") }} />,
                            ]}
                        </div>
                    </div>
                </DropdownContent>
            </Dropdown>
        )
    }

    const OutrasAcoesTelasMenores = ({ propsAcao, ...props }) => {
        return (
            <Dropdown >
                <DropdownTrigger>
                    <Btn icone='icon-ellipsi' texto="Mais Ações" />
                </DropdownTrigger>
                <DropdownContent>
                    <div className="card-acoes-dropdown">
                        <div className="ajusta-dropdown-consulta-guia">
                            {[
                                VisualizarNotaAcao(propsAcao),
                                <AlterarNota action={() => { possuiPermissaoEnvioAlteracao(propsAcao.row) ? alteracao(propsAcao) : mostrarToast('erro', "Ação não permitida" + ` para nota com status ${propsAcao.row?.status_nota?.status}!`) }} />,
                                <Excluir to={'financeiro/notafiscal/consulta/'} action={() => exclusao(propsAcao)} />,
                                <Detalhes action={() => {
                                    obterDetalhes(propsAcao.row.id)
                                    setShowModalDetalhes(true)
                                }} />,
                                <TrocarStatusNota texto={'Trocar Status Nota'} action={() => { setShowModalStatusNota(true); setIdNota(propsAcao.id) }} />,
                                <TrocarStatusEnvio texto={'Trocar Status Envio'} action={() => { setShowModalStatusEnvio(true); setIdNota(propsAcao.id) }} />,
                                <EnviarNotaEmail icone={'icon-Mail-Send'} texto={'Enviar Para Email'} action={() => {
                                    possuiPermissaoCancelarSubstituir(propsAcao.row) ? propsAcao.row?.tomador_email ? Promise.resolve(postComAcao(ACOES.ENVIAR_EMAIL_NOTA_FISCAL, 136, { email: [propsAcao.row?.tomador_email], id: propsAcao.id }, '/financeiro/enviarNotaEmail', val => { target.current.atualizar(); mostrarToast('sucesso', 'Nota enviada para o email com sucesso!') },
                                        erro => {
                                            mostrarToast('erro', erro.response.data?.alertas[0])
                                            target.current.atualizar()
                                        })) : mostrarToast('erro', "Email do Tomador não informado para realizar envio!")
                                        : mostrarToast('erro', "Ação não permitida" + ` para nota com status ${propsAcao.row?.status_nota?.status}!`)
                                }} />,
                                <EnviarNota texto={'Enviar Nota'} action={() => {
                                    possuiPermissaoEnvioAlteracao(propsAcao.row) ? postComAcao(ACOES.EMITIR_NOTA_FISCAL, 136, { id: propsAcao.id, usuario_id: getUsuario()?.id }, `/financeiro/emitirNota`,
                                        (val) => { target.current.atualizar(); mostrarToastNf(val?.codigo, val?.mensagem) },
                                        (erro) => {
                                            console.log('erro: ', erro);
                                            target.current.atualizar()
                                            mostrarToast('info', erro.response.data?.alertas[0])

                                        })
                                        : mostrarToast('erro', "Ação não permitida" + ` para nota com status ${propsAcao.row?.status_nota?.status}!`)
                                }
                                } />,
                                <AtualizarStatusNota texto={'Atualizar Nota'} action={() => {
                                    possuiPermissaoEnvioAlteracao(propsAcao.row) ? Promise.resolve(post(`/financeiro/verificarStatusNota`, { ref: propsAcao.id, usuario_id: getUsuario()?.id }).then((val) => { target.current.atualizar(); mostrarToastNf(val?.codigo, val?.mensagem) }).catch((erro) => {
                                        target.current.atualizar()
                                        if (erro.response.data?.alertas)
                                            mostrarToast('erro', erro.response.data?.alertas[0])

                                    }))
                                        : mostrarToast('erro', "Ação não permitida" + ` para nota com status ${propsAcao.row?.status_nota?.status}!`)
                                }} />,
                                <Link to={{ pathname: '/financeiro/notafiscal/visualizacaoErroNota/' + propsAcao.row.id }} target={'_blank'}><VisualizarErro action={() => { }} /></Link>,
                                <SubstituirNota icone={'icon-Arrow-Around'} action={() => { if (possuiPermissaoCancelarSubstituir(propsAcao.row)) { setShowModalSubstituirNota(true); setIdNota(propsAcao.id) } else mostrarToast('erro', "Ação não permitida para nota com status diferente de 'Enviada'!") }} />,
                                <CancelarNota icone={'icon-Delete-Window'} action={() => { if (possuiPermissaoCancelarSubstituir(propsAcao.row)) { setShowModalCancelarNota(true); setIdNota(propsAcao.id) } else mostrarToast('erro', "Ação não permitida para nota com status diferente de 'Enviada'") }} />,
                            ]}
                        </div>
                    </div>
                </DropdownContent>
            </Dropdown>
        )
    }

    const obterDetalhes = async (id) => {
        setItemDetalhes({ nota_fiscal: [{ titulo: 'Carregando...' }] })
        let dados = {}
        await Promise.resolve(get(`/financeiro/notafiscal/${id}`)).then(val => {
            dados.nota_fiscal = [
                { titulo: 'Cód.', descricao: val.id },
                { titulo: 'Número NFS-e', descricao: val.numero || '--' },
                { titulo: 'Código Verificação', descricao: val.codigo_verificacao || '--' },
                { titulo: 'Data Emissão', descricao: val.data_emissao || '--' },
                { titulo: 'Status Nota', descricao: '', html: <span className={'tag'} style={{ background: val.cor_status?.cor_id, color: 'white' }}>{val.cor_status?.descricao}</span> },
                { titulo: 'Status Envio', descricao: '', html: <span className={'tag'} style={{ background: val.cor_status_envio?.cor_id, color: 'white' }}>{val.cor_status_envio?.descricao}</span> },
                { titulo: 'Tomador/Cliente', descricao: val.cliente?.label },
                { titulo: 'Prestador/Empresa', descricao: val.empresa?.label },
                { titulo: 'Valor (R$)', descricao: val.valor_nota_formatado },
                { titulo: 'Valor Tributo (R$)', descricao: formatarMoeda(val?.tributos?.reduce((acc, x) => acc + (convertValueToFloat(x?.valor_tributo || 0)), 0)) },
            ]
        })

        await Promise.resolve(get(`/financeiro/notafiscalguia/${id}`)).then(val2 => {
            dados.nota_guia = val2;
        })
        await Promise.resolve(get(`/financeiro/notafiscallancamentos/${id}`)).then(val2 => {
            dados.nota_lancamento = val2;
        })

        await Promise.resolve(get(`/financeiro/notafiscalhistoricostatus/${id}`)).then(val2 => {
            dados.historico = val2;
        })

        await Promise.resolve(get(`/financeiro/notafiscalhistoricostatusenvio/${id}`)).then(val2 => {
            dados.historico_envio = val2;
        })

        setItemDetalhes(dados)
    }

    const maisAcoesObj = [
        (propsAcao) => <VisualizarNota action={() => {
            if (!propsAcao.row.url_danfse) {
                history.push({ pathname: '/financeiro/notafiscal/visualizacao/' + propsAcao.id })
            }
            else
                history.push({ pathname: propsAcao.row.url_danfse })

        }} />,
        (propsAcao) => <Visualizar to={'/financeiro/notafiscal/visualizacao/' + propsAcao.id} />,
        (propsAcao) => <AlterarNota action={() => { possuiPermissaoEnvioAlteracao(propsAcao.row) ? alteracao(propsAcao) : mostrarToast('erro', "Ação não permitida" + ` para nota com status ${propsAcao.row?.status_nota?.status}!`) }} />,
        (propsAcao) => <Excluir to={'/financeiro/notafiscal/consulta/'} action={() => exclusao(propsAcao)} />,
        (propsAcao) => <Detalhes action={() => {
        }} />,
        (propsAcao) => <TrocarStatusNota icone={'icon-Check'} texto={'Trocar Status Nota'} action={() => { setShowModalStatusNota(true); setIdNota(propsAcao.id) }} />,
        (propsAcao) => <TrocarStatusEnvio icone={'icon-Check'} texto={'Trocar Status Envio'} action={() => { setShowModalStatusEnvio(true); setIdNota(propsAcao.id) }} />,
        (propsAcao) => <EnviarNotaEmail icone={'icon-Email'} texto={'Enviar Para Email'} action={() => {
            possuiPermissaoCancelarSubstituir(propsAcao.row) ? propsAcao.row?.tomador_email ? Promise.resolve(postComAcao(ACOES.ENVIAR_EMAIL_NOTA_FISCAL, 136, { email: [propsAcao.row?.tomador_email], id: propsAcao.id }, '/financeiro/enviarNotaEmail', val => { target.current.atualizar(); mostrarToast('sucesso', 'Nota enviada para o email com sucesso!') },
                erro => {
                    mostrarToast('erro', erro.response.data?.alertas[0])
                    target.current.atualizar()
                })) : mostrarToast('erro', "Email do Tomador não informado para realizar envio!")
                : mostrarToast('erro', "Ação não permitida" + ` para nota com status ${propsAcao.row?.status_nota?.status}!`)
        }} />,
        (propsAcao) => <EnviarNota icone={'icon-Check'} texto={'Enviar Nota'} action={() => {
            possuiPermissaoEnvioAlteracao(propsAcao.row) ? postComAcao(ACOES.EMITIR_NOTA_FISCAL, 136, { id: propsAcao.id }, `/financeiro/emitirNota`,
                (val) => { target.current.atualizar(); mostrarToastNf(val?.codigo, val?.mensagem) },
                (erro) => {
                    target.current.atualizar()
                    mostrarToast('info', erro.response.data?.alertas[0])

                })
                : mostrarToast('erro', "Ação não permitida" + ` para nota com status ${propsAcao.row?.status_nota?.status}!`)
        }
        } />,
        (propsAcao) => <AtualizarStatusNota icone={'icon-Check'} texto={'Atualizar Status Nota'} action={() => {
            possuiPermissaoEnvioAlteracao(propsAcao.row) ? Promise.resolve(post(`/financeiro/verificarStatusNota`, { ref: propsAcao.id, usuario_id: getUsuario()?.id }).then((val) => { target.current.atualizar(); mostrarToastNf(val?.codigo, val?.mensagem) }).catch((erro) => {
                target.current.atualizar()
                if (erro.response.data?.alertas)
                    mostrarToast('erro', erro.response.data?.alertas[0])

            }))
                : mostrarToast('erro', "Ação não permitida" + ` para nota com status ${propsAcao.row?.status_nota?.status}!`)
        }} />,
        (propsAcao) => <Link to={{ pathname: '/financeiro/notafiscal/visualizacaoErroNota/' + propsAcao.row.id }} target={'_blank'}><VisualizarErro action={() => { }} /></Link>,
        (propsAcao) => <SubstituirNota icone={'icon-Arrow-Around'} action={() => { if (possuiPermissaoCancelarSubstituir(propsAcao.row)) { setShowModalSubstituirNota(true); setIdNota(propsAcao.id) } else mostrarToast('erro', "Ação não permitida para nota com status diferente de 'Enviada'!") }} />,
        (propsAcao) => <CancelarNota icone={'icon-Delete-Window'} action={() => { if (possuiPermissaoCancelarSubstituir(propsAcao.row)) { setShowModalCancelarNota(true); setIdNota(propsAcao.id) } else mostrarToast('erro', "Ação não permitida para nota com status diferente de 'Enviada'") }} />


    ]

    const possuiPermissaoEnvioAlteracao = (obj) => obj?.status_nota?.id === 1 ? true : false
    const possuiPermissaoCancelarSubstituir = (obj) => (obj?.status_envio?.id === 5 || obj?.status_envio?.id === 2) && (obj?.status_nota?.id === 2 || obj?.status_nota?.id === 3 || obj?.status_nota?.id === 7) ? true : false
    const possuiPermissaoErros = (obj) => obj?.status__envio_nota?.id === 3 ? true : false


    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: rProps.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/financeiro/notafiscal', propsAcao.row.id,
                    () => {
                        // getApi(setData, '/faturamento/guia')
                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    return (
        <>
            <div>
                <DataGridAlternativa target={target} acoesObj={width <= 1920 ? acoesObj2 : acoesObj} data={data} columns={columns} setData={setData} acoes={true} caminhoBase='/financeiro/notafiscal'
                    caminhoConsultaFiltros='/financeiro/notafiscalpaginacao' modulo={rProps.modulo} titulo={'Consulta Nota Fiscal'} tituloLista={'Lista Nota Fiscal'}
                    urlCadastro='/financeiro/notafiscal/cadastro' icone={'icon-File-HorizontalText'} />
                <ModalTrocarStatusNota target={target} showModal={showModalStatusNota} setShowModal={setShowModalStatusNota} idNota={idNota} />
                <ModalTrocarStatusEnvio target={target} showModal={showModalStatusEnvio} setShowModal={setShowModalStatusEnvio} idNota={idNota} />
                <ModalSubstituirNota target={target} showModal={showModalSubstituirNota} setShowModal={setShowModalSubstituirNota} idNota={idNota} />
                <ModalCancelarNota target={target} showModal={showModalCancelarNota} setShowModal={setShowModalCancelarNota} idNota={idNota} />
                <DetalhesNota setShowModal={setShowModalDetalhes} showModal={showModalDetalhes} itemDetalhes={itemDetalhes} />

            </div>
        </>
    )

}

const ModalTrocarStatusNota = ({ idNota, target, detalhesLancamento, ...props }) => {

    const [salvando, setSalvando] = useState(false)
    const [validacao] = useState(Yup.object({
        status_nota: Yup.object().nullable().required()
    }))

    const refMovimento = useRef(null)

    const checkTrocaStatus = (values, propsFormik) => {

        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: 136,
            acao_id: ACOES.TROCAR_STATUS_NOTA
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {

            Submit(values, propsFormik)
        })
    }

    const Submit = (values, propsFormik) => {

        setSalvando(true)

        values.idNota = idNota

        putComAcao(ACOES.TROCAR_STATUS_NOTA, 136, values, '/financeiro/trocarStatusNota',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                target.current.atualizar();
                props.setShowModal(false)
                setSalvando(false)
            },
            (erro) => {
                setSalvando(false)
                mostrarToast('erro', mensagens.erro)
            })
    }

    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Trocar Status Nota'} show={props.showModal} setShowModal={props.setShowModal}>
                <Formik
                    initialValues={{}}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => checkTrocaStatus(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>

                                    <div className={'form-group col-12'}>
                                        <div ref={refMovimento}>
                                            <InputStatusNota label='Status Nota' tamanho={'col-lg-12 p-0'} name='status_nota' placeholder='Status Nota' propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} obrigatorio autoFocus />

                                        </div>
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

const ModalTrocarStatusEnvio = ({ idNota, target, ...props }) => {

    const [salvando, setSalvando] = useState(false)
    const [validacao] = useState(Yup.object({
        status_envio: Yup.object().nullable().required()
    }))

    const refMovimento = useRef(null)


    const checkTrocaStatus = (values, propsFormik) => {

        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: 136,
            acao_id: ACOES.TROCAR_STATUS_ENVIO
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length > 0)
                Submit(values, propsFormik)
            else
                mostrarToast('erro', 'Ação não permitida!')
        })
    }


    const Submit = (values, propsFormik) => {

        setSalvando(true)

        values.idNota = idNota

        putComAcao(ACOES.TROCAR_STATUS_ENVIO, 136, values, '/financeiro/trocarStatusEnvioNota',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                target.current.atualizar();
                props.setShowModal(false)
                setSalvando(false)
            },
            (erro) => {
                setSalvando(false)
                mostrarToast('erro', mensagens.erro)
            })
    }

    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Trocar Status Envio'} show={props.showModal} setShowModal={props.setShowModal}>
                <Formik
                    initialValues={{}}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => checkTrocaStatus(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    {/* <DetalhesLancamnetoFinanceiro detalhesLancamento={detalhesLancamento} /> */}
                                    <div className={'form-group col-12'}>
                                        <div ref={refMovimento}>
                                            <InputStatusEnvioNota label='Status Envio' tamanho={'col-lg-12 p-0'} name='status_envio' placeholder='Status Envio' propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} obrigatorio autoFocus />

                                        </div>
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

const ModalSubstituirNota = ({ idNota, target, ...props }) => {
    const [salvando, setSalvando] = useState(false)
    const [validacao] = useState(Yup.object({
        nota_substituta: Yup.object().nullable().required(),
        nota_substituida: Yup.object().nullable().required()
    }))

    const refMovimento = useRef(null)


    const checkTrocaStatus = (values, propsFormik) => {

        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: 136,
            acao_id: ACOES.SUBSTITUIR_NOTA
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length > 0)
                Submit(values, propsFormik)
            else
                mostrarToast('erro', 'Ação não permitida!')
        })
    }


    const Submit = (values, propsFormik) => {

        setSalvando(true)

        values.idNota = idNota

        putComAcao(ACOES.SUBSTITUIR_NOTA, 136, values, '/financeiro/substituirNota',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                target.current.atualizar();
                props.setShowModal(false)
                setSalvando(false)
            },
            (erro) => {
                setSalvando(false)
                mostrarToast('erro', mensagens.erro)
            })
    }

    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Substituir Nota'} show={props.showModal} setShowModal={props.setShowModal}>
                <Formik
                    initialValues={{}}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => checkTrocaStatus(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <div className={'form-group col-12'}>
                                        <div ref={refMovimento}>
                                            <InputNotaFiscalEnviada label='Nota Substituida' valor_setado={idNota} tamanho={'col-lg-12 p-0'} name='nota_substituida' placeholder='Nota Substituida' propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} obrigatorio autoFocus />
                                            <InputNotaFiscalEnviada label='Nota Substituta' tamanho={'col-lg-12 p-0'} name='nota_substituta' placeholder='Nota Substituta' propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} obrigatorio autoFocus empresa={true} idNota={idNota} />
                                            <TextArea label={<b>Anotação</b>} obrigatorio name='anotacao' type='text' placeholder='Anotação' tamanho={!props.modal && 'col-lg-12 p-0'} rows={2}
                                                classeTooltip={'tooltip-input-modal'} />

                                        </div>
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

const ModalCancelarNota = ({ idNota, target, ...props }) => {
    const [salvando, setSalvando] = useState(false)
    const [validacao] = useState(Yup.object({
        nota_cancelamento: Yup.object().nullable().required(),
        justificativa: Yup.string().nullable().required()
    }))

    const refMovimento = useRef(null)


    const checkTrocaStatus = (values, propsFormik) => {

        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: 136,
            acao_id: ACOES.SUBSTITUIR_NOTA
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length > 0)
                Submit(values, propsFormik)
            else
                mostrarToast('erro', 'Ação não permitida!')
        })
    }


    const Submit = (values, propsFormik) => {

        setSalvando(true)

        values.idNota = idNota

        putComAcao(ACOES.CANCELAR_NOTA, 136, values, '/financeiro/cancelarNota',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                target.current.atualizar();
                props.setShowModal(false)
                setSalvando(false)
            },
            (erro) => {
                setSalvando(false)
                mostrarToast('erro', mensagens.erro)
            })
    }

    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Cancelar Nota'} show={props.showModal} setShowModal={props.setShowModal}>
                <Formik
                    initialValues={{}}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => checkTrocaStatus(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <div className={'form-group col-12'}>
                                        <div ref={refMovimento}>
                                            <InputNotaFiscalEnviada label='Nota Cancelar' valor_setado={idNota} tamanho={'col-lg-12 p-0'} name='nota_cancelamento' placeholder='Nota Cancelar' propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} obrigatorio autoFocus />
                                            <TextArea label={<b>Justificativa</b>} obrigatorio name='justificativa' type='text' placeholder='Justificativa' tamanho={!props.modal && 'col-lg-12 p-0'} rows={3}
                                                classeTooltip={'tooltip-input-modal'} />
                                        </div>
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}




export default ConsultaNotaFiscal