import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from "formik";
import Yup, { validaData, periodoData, validaHora, periodoHora } from '../../../../util/Validacoes';
import { ModalDuasOpcoes } from '../../../../components/Modais';
import { mostrarToast } from '../../../../components/Toasts'
import { useParams } from "react-router-dom";
import { get, salvar, validar } from '../../../../util/AcessoApi'
import { HeaderCadastro } from '../../../../components/Headers'
import { Link } from 'react-router-dom'
import { InputPadrao, InputDatePicker, InputSwitch, InputTimePicker } from '../../../../components/Inputs'
import { InputEscalador } from '../../../../components/InputsAutoComplete'
import { BotaoPadrao } from '../../../../components/Botoes'
import { mensagens } from '../../../../util/Utilitarios'
import { dataMesmaSemanaMes } from '../../../../util/Validacoes'
import '../../../../assets/css/icones/iconsmind/style.css'
import Atalho from '../../../../util/Atalho'


const CadastroAgendaEscalador = (props) => {
    let { id } = props

    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')

    const [validacao] = useState(Yup.object({
        escalador: Yup.string().nullable().required(),
        data_inicial: Yup.string().nullable().required()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) }).test('Data Inicial', 'Data inicial deve ser menor ou igual a Data Final!', function (value) { return periodoData(this.parent.data_inicial, this.parent.data_final) }),
        data_final: Yup.string().required()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) }),
        hora_inicial: Yup.string().required()
            .test('Hora Inicial', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_inicial) }).test('Hora Inicial', 'Hora Inicial deve ser menor que Hora Final com Datas no mesmo período', function(){ console.log('this.parent', this.parent); return (this.parent.data_inicial === this.parent.data_final) ? periodoHora(this.parent.hora_final, this.parent.hora_inicial) : true}),
        hora_final: Yup.string().required()
            .test('Hora Final', 'Hora inválida!', function (value) { return validaHora(this.parent.hora_final) }),
        observacao: Yup.string().nullable().max(255)
    }))

    const ValidarSubmit = (values, resetForm) => {
        setSalvando(true)
        validar('/administracao/agendaEscaladorVerificacao', values, () => Submit(values, resetForm), (e) => {
            setShowModalValidacao(true)
            setMensagemModal(e.response.data.alertas[0])
            setSalvando(false)
        })
    }

    const funcaoAposSim = (formik) => {
        Submit(formik.values, formik.resetForm)
    }

    const manter = []

    const Submit = (values, propsFormik) => {
        salvar(
            values,
            '/administracao/agendaescalador',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                if (props.modal) {
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else {
                        props.setShow && props.setShow(false)
                        props.funcaoAposFechar()
                    }
                } else {
                    if (salvarENovo) {
                        propsFormik.resetForm({})
                        manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                        setSalvando(false)
                        setSalvarENovo(false)
                    }
                    else
                        props.history.push('/administracao/agendaescalador/consulta')
                }
            },
            () => {
                setSalvando(false)
            })
    }

    useEffect(() => {
        if (id) {
            Promise.resolve(get(`/administracao/agendaescalador/${id}`)).then(val => {
                if (!val.escalador)
                    props.history.push('/administracao/agendaescalador/consulta')

                val.data_inicial = val.data_inicial_formatada
                val.data_final = val.data_final_formatada

                if (props.duplicar) {
                    delete val.id
                    delete val.data_inicial
                    delete val.data_final
                    delete val.observacao
                }

                setValoresIniciais({ data_inicial: '', data_final: '', ...val })
            })
        } else {
            setValoresIniciais({
                escalador: '',
                data_inicial: '',
                hora_inicial: '',
                data_final: '',
                hora_final: '',
                observacao: '',
                semanalmente: false
            })
        }

    }, [id])

    const settarValorSemanalmente = (propsFormik) => {
        propsFormik.values.semanalmente = !propsFormik.values.semanalmente
    }

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => ValidarSubmit(values, propsFormik)}>
            {propsFormik => (<Form>

                <div className='row'>
                    <InputPadrao label="Id" name="id" type="hidden" placeholder="Id" obrigatorio={true} />
                    <InputEscalador label="Escalador" name="escalador" propsFormik={propsFormik} obrigatorio={true} tamanho={!props.modal && 'col-lg-6 col-md-12'}
                        classeTooltip={props.modal && 'tooltip-input-modal'} autoFocus salvarENovo={salvarENovo} />
                    <InputDatePicker tamanho={!props.modal && "col-lg-4 col-md-7"} label="Data Inicial" name="data_inicial" type="text" obrigatorio="true"
                        setarValor={value => {
                            propsFormik.setFieldValue("data_inicial", value)
                        }} classeTooltip={props.modal && 'tooltip-input-modal'}
                    />
                    <InputTimePicker
                        label="Hora Inicial" mascara="11:11" name="hora_inicial" type="text" placeholder="Hora Inicial" tamanho={!props.modal && "col-lg-2 col-md-5"} obrigatorio={true} classeTooltip={props.modal && 'tooltip-input-modal'}
                        setarValor={value => {
                            propsFormik.setFieldValue("hora_inicial", value)
                        }}
                    />
                    {/* <InputPadrao label="Hora Inicial" mascara="11:11" name="hora_inicial" type="text" placeholder="Hora Inicial" tamanho={!props.modal && "col-lg-2 col-md-5"} obrigatorio={true} classeTooltip={props.modal && 'tooltip-input-modal'} /> */}
                    <InputDatePicker tamanho={!props.modal && "col-lg-4 col-md-7"} label="Data Final" name="data_final" type="text" obrigatorio="true"
                        setarValor={value => {
                            propsFormik.setFieldValue("data_final", value)
                        }} classeTooltip={props.modal && 'tooltip-input-modal'}
                    />
                    <InputTimePicker
                        label="Hora Final" mascara="11:11" name="hora_final" type="text" placeholder="Hora Final" tamanho={!props.modal && "col-lg-2 col-md-5"} obrigatorio={true} classeTooltip={props.modal && 'tooltip-input-modal'}
                        setarValor={value => {
                            propsFormik.setFieldValue("hora_final", value)
                        }}
                    />
                    {/* <InputPadrao label="Hora Final" mascara="11:11" name="hora_final" type="text" placeholder="Hora Final" tamanho={!props.modal && "col-lg-2 col-md-5"} obrigatorio={true} classeTooltip={props.modal && 'tooltip-input-modal'} /> */}
                    <InputPadrao label='Observação' name='observacao' type='text' placeholder='Observação' tamanho={!props.modal && 'col-lg-6 col-md-12'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                    {props.duplicar || id || !dataMesmaSemanaMes(propsFormik.values.data_inicial, propsFormik.values.data_final) ? <></> : <InputSwitch  propsFormik={propsFormik} label='Repetir Semanalmente no Mês?' quebrarLinha classeDiv='form-group' name='semanalmente' size="md" tamanho={!props.modal && 'col-lg-6'} classeTooltip={props.modal && 'tooltip-input-modal'} />}
                    <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo}  salvarENovo={true}/>
                </div>
                <div className='direita container-botoes mb-3 group-salvar-novo'>
                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                    {!id && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />}
                </div>
                <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => funcaoAposSim(propsFormik)} funcaoOpcao1={() => {setSalvando(false);setShowModalValidacao(false)}}
                    esconderFechar mensagem={mensagemModal}
                    textoOpcao1={'Cancelar'}
                    classeOpcao1={'btn-sm btn-primary-modal simples'}
                    textoOpcao2={'Continuar'} />
            </Form>)}
        </Formik>
    );
}

const Cadastro = (props) => {
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <Link to={'#'}>Administrativo</Link>,
        () => <Link className="link-active" to={'/administracao/agendaescalador/consulta'}>Agenda Escalador</Link>,
    ]
    return (<>
        <HeaderCadastro titulo={"Cadastro de Agenda"} link={links} />
        <div className="card mb-20">
            <div className="card-body">
                <CadastroAgendaEscalador {...props} />
            </div>
        </div>
    </>
    )
}
export { CadastroAgendaEscalador }
export default Cadastro
