import React, { useState, useEffect } from 'react'
import VisualizacaoPadrao from '../../../../components/VisualizacaoPadrao'
import { useParams } from "react-router-dom";
import { visualizar } from '../../../../util/AcessoApi'

const Visualizacao = (props) => {
    let { id } = useParams();
    const [dados, setDados] = useState()

    useEffect(() => {
        if (id) {
            visualizar('/administracao/departamento', id,
                obj => 
                    setDados(obj.id ? {
                        id,
                        modulo: props.modulo,
                        titulo: 'Visualização de Departamento',
                        grupos: [
                            {
                                titulo: 'Dados Gerais',
                                colunas: [
                                    { titulo: 'Código', descricao: obj.id, tamanho: 'col-lg-6' },
                                    { titulo: 'Descrição', descricao: obj.descricao, tamanho: 'col-lg-6' },
                                    { titulo: 'Observação', descricao: obj.observacao, tamanho: 'col-lg-6' },
                                    { titulo: 'Data Registro', descricao: obj.data_registro, tamanho: 'col-lg-6' }
                                ]
                            },
                            
                        ]
                    } : { titulo: 'Visualização de Departamento' })
            )
        }
    }, [id])

    return dados ? <VisualizacaoPadrao data={dados} {...props} /> : null
}

export default Visualizacao