import React from 'react'
import { AcessoRapido, AuditoriaAgenda, ProximosAgendamentos } from '../../components/ContentCards'
import { Link } from 'react-router-dom'

const Home = (props) => (
    <>
        <div className='row'>
            <div className='col-lg-12 col-md-12'>
                <h2>Bem vindo!</h2>
            </div>
        </div>
    </>
    
)

export default Home
