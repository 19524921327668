import React, { useState, useEffect } from 'react'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { consultar } from '../../../../util/AcessoApi'

const ConsultaParametroOperadora = (props) => {
    const [data, setData] = useState([])

    useEffect(() => {
        consultar('/administracao/feriadospaginacao', (dados) => setData(dados.sort((a, b) => b.id - a.id)))
    }, [])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-parametro-operadora',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Operadora de Saúde',
                        accessor: 'nome_fantasia',
                        ordenamento: {
                            tipo: 'texto',

                        },
                        filtro: {
                            combo: 'combo-alternativa',
                            tipo: 'operadora_saude',
                            idColuna: 'operadora_saude_id'
                        },
                    },
                    {
                        Header: 'Data Registro',
                        accessor: 'data_registro_formatada',
                        ordenamento: {
                            tipo: 'data'
                        },
                        filtro: {
                            tipo: 'data_inicial',
                            idColuna: 'parametro_operadora_saude.data_registro',
                            date: true

                        }
                    },
                    {
                        Header: 'Data Atualização Cooperativa',
                        accessor: 'data_atualizacao',
                        ordenamento: {
                            tipo: 'data'
                        },
                        filtro: {
                            tipo: 'data',
                            idColuna: 'parametro_operadora_saude.data_atualizacao',
                            date: true
                        }
                    },
                    {
                        Header: 'Usuário Atualização',
                        accessor: 'nome',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'usuario',
                            idColuna: 'parametro_operadora_saude.usuario_atualizacao_id'
                        }
                    },
                ],
            },
        ],
        []
    )
    const caminho = {
        cadastro: '/faturamento/parametroOperadoraSaude/cadastro',
    }

    return (
        <>
            <div>
                <DataGridAlternativa data={data} columns={columns} caminhoBase='/faturamento/parametroOperadoraSaude' setData={setData} acoes={true} url={caminho} modulo={props.modulo}
                    titulo={'Consulta Parâmetro Operadora Saúde'} tituloLista={'Lista Parâmetro Operadora Saúde'} urlCadastro='/faturamento/parametroOperadoraSaude/cadastro' icone={'icon-Holiday'} caminhoConsultaFiltros='/faturamento/parametroOperadoraSaudePaginacao' />
            </div>
        </>
    )
}

export default ConsultaParametroOperadora