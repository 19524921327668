import React from 'react';
import '../css/nao-encontrado.css';

const NaoEncontrado = () => {
    return (
        <div className="height content-center container-404">
            <div className="row d-flex justify-content-center">
                <div className="col-12">
                    <div className="row justify-content-center">
                        <h3 className='label-404'>404</h3>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-12">
                        <div className="row justify-content-center">
                            <h3 className='path-404'>Página não Encontrada</h3>
                        </div>
                    </div>
                    <div className="col-12"><div className="row justify-content-center"><button onClick={() => window.history.back()} className='btn secondary'>Voltar</button></div></div>
                </div>
            </div>
        </div>
    )
}

export default NaoEncontrado